import { Field } from 'formik';
import React from 'react';
import HdSwitchWrapper from '../../UIElements/HdSwitchWrapper';
import { getDataIdGenerator } from '../../../utils/generateDataId';

interface HdFormikSwitchWrapperProps {
  dataId?: string;
  fieldName: string;
  disabled?: boolean;
  label: string;
  labelClasses?: string;
  children?: React.ReactNode;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (checked: boolean) => void;
}

export default function HdFormikSwitchWrapper({
  dataId,
  fieldName,
  children,
  label,
  disabled = false,
  className,
  labelClasses,
  onChange
}: HdFormikSwitchWrapperProps) {

  const dataIdGenerator = getDataIdGenerator(dataId);

  return (
    <Field name={fieldName}>
      {({ field, form }) => (
        <HdSwitchWrapper
          id={fieldName}
          dataId={dataIdGenerator(fieldName)}
          label={label}
          labelClasses={labelClasses}
          className={className}
          switchProps={{
            name: fieldName,
            checked: !!field.value
          }}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(event.target.checked);
            } else {
              form.setFieldValue(field.name, event.target.checked);
            }
          }}
        >
          {children}
        </HdSwitchWrapper>
      )}
    </Field>
  );
}
