import React from 'react';
import clsx from 'clsx';

import Days from './MonthView/Days';
import Weekdays from './MonthView/Weekdays';
import { TitleGroupProps } from './shared/interfaces';

interface MonthViewProps<T> extends TitleGroupProps<T> {
  activeStartDate: T;
}

export default function MonthView<T>(props: MonthViewProps<T>) {
  function renderWeekdays() {
    return <Weekdays />;
  }

  function renderDays() {
    return <Days<T> {...props} />;
  }

  const className = 'react-calendar__month-view';

  return (
    <div className={clsx(className)}>
      {renderWeekdays()}
      <div className='react-calendar__divider' />
      {renderDays()}
    </div>
  );
}
