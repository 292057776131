import { ESCAPE } from '@angular/cdk/keycodes';
import {
  ConnectedPositionStrategy,
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core';

import { hasOverflowingText } from '../../react/legacy-utils/dom';
import { OverlayPosition } from '../overlay/types';
import { getDirFromPositionPair } from '../overlay/utils';
import {
  EDGE_TOOLTIP_FALLBACK_POSITIONS,
  EDGE_TOOLTIP_OVERLAY_POSITION_MAPPING,
  TOOLTIP_FALLBACK_POSITIONS,
  TOOLTIP_OVERLAY_POSITION_MAPPING
} from '../popup/constants';
import { PopupPosition, TooltipType } from '../popup/models';
import { isFallbackAlignedToPopupPosition } from '../popup/utils';
import { TooltipContent } from './tooltip-content';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tooltip]',
  exportAs: 'tooltip'
})
// tslint:disable-next-line: directive-class-suffix
export class Tooltip implements OnDestroy {

  // -------------------------------------------------------------------------
  // Properties
  // -------------------------------------------------------------------------

  private tooltip: TooltipContent;
  private visible: boolean;

  get isVisible() {
    return this.visible;
  }

  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal;

  private _documentKeydownListener: () => void;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------

  constructor(
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) public document: Document,
    private renderer: Renderer2,
    private _zone: NgZone,
    private _cdRef: ChangeDetectorRef,
    private _overlay: Overlay,
    public el: ElementRef) {
  }

  // -------------------------------------------------------------------------
  // Inputs / Outputs
  // -------------------------------------------------------------------------

  @Input('tooltip')
  content: string | TooltipContent;

  @Input()
  tooltipDisabled: boolean;

  @Input()
  tooltipAnimation = true;

  @Input()
  tooltipType: TooltipType = 'arrow';

  @Input()
  extraOffsetX = 0;

  @Input()
  extraOffsetY = 0;

  @Input()
  showOnEllipsis = false;

  @Input()
  tooltipPlacement: PopupPosition = 'below';

  @Input()
  closeOnEscape = true;

  @Input()
  closeOnClick = false;

  @Input()
  useFallbackPosition = true;

  @Input()
  overrideFallbackToTooltipPosition = false;

  tooltipStringComponent: ComponentRef<TooltipContent>;

  // -------------------------------------------------------------------------
  // Public Methods
  // -------------------------------------------------------------------------

  @HostListener('focusin')
  @HostListener('mouseenter')
  show(): void {
    if (this.showOnEllipsis) {
      const el: HTMLElement = this.viewContainerRef.element.nativeElement;
      const ellipsized = hasOverflowingText(el, this.document);

      if (!ellipsized) {
        return;
      }
    }

    if (this.tooltipDisabled || this.visible) {
      return;
    }

    this.visible = true;
    if (typeof this.content === 'string') {
      const factory = this.resolver.resolveComponentFactory(TooltipContent);
      if (!this.visible) {
        return;
      }

      if (!this.tooltipStringComponent) {
        this.tooltipStringComponent = this.viewContainerRef.createComponent(factory);
        this.tooltip = this.tooltipStringComponent.instance;
      }

      this.tooltip.content = this.content as string;
    } else {
      this.tooltip = this.content;
    }

    this._createOverlay().attach(this._portal);

    this.handleDocEvents();
  }

  handleDocEvents() {
    this._documentKeydownListener = this.renderer.listen(
      'document',
      'keydown',
      (e: any) => this.handleKeydown(e));
  }

  handleKeydown(event) {
    if (this.visible && event.keyCode === ESCAPE && this.closeOnEscape) {
      this._zone.run(() => {
        this.hide();
        event.stopPropagation();
      });
    }
  }

  unHandleDocEvents() {
    if (this._documentKeydownListener) {
      this._documentKeydownListener();
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  hide(): void {
    if (!this.visible || !this._overlayRef) {
      return;
    }

    this.visible = false;

    this._overlayRef.detach();

    this.unHandleDocEvents();

    this._cdRef.markForCheck();
  }

  @HostListener('click')
  onClick() {
    if (this.closeOnClick) {
      this.hide();
    }
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const overlayConfig = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(overlayConfig);

      this._portal = new TemplatePortal(this.tooltip.templateRef, this.viewContainerRef);
    }

    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
  }

  private _getPositionStrategy(): ConnectedPositionStrategy {
    const popupPosition: OverlayPosition = this.tooltipType === 'arrow'
      ? TOOLTIP_OVERLAY_POSITION_MAPPING[this.tooltipPlacement]
      : EDGE_TOOLTIP_OVERLAY_POSITION_MAPPING[this.tooltipPlacement];

    const fallbackPositions = this.tooltipType === 'arrow'
      ? TOOLTIP_FALLBACK_POSITIONS
      : EDGE_TOOLTIP_FALLBACK_POSITIONS;

    const strategy = this._overlay.position().connectedTo(
      this.el,
      { originX: popupPosition.originX, originY: popupPosition.originY },
      { overlayX: popupPosition.overlayX, overlayY: popupPosition.overlayY }
    ).withOffsetX(
      this.getOffsetXFromPosition(popupPosition) + this.extraOffsetX
    ).withOffsetY(
      this.getOffsetYFromPosition(popupPosition) + this.extraOffsetY
    );

    if (this.useFallbackPosition) {
      if (this.overrideFallbackToTooltipPosition) {
        fallbackPositions.filter((fallbackPosition) =>
          isFallbackAlignedToPopupPosition(this.tooltipPlacement, fallbackPosition)
        ).map((fallbackPosition) =>
          this._attachFallbackToPositionStrategy(strategy, fallbackPosition)
        );
      }

      // Default fallback positions
      fallbackPositions.slice(0, 4).map((fallbackPosition) =>
        this._attachFallbackToPositionStrategy(strategy, fallbackPosition)
      );
    }

    strategy.onPositionChange.subscribe(change => {
      this.updatePositionClasses(change.connectionPair);
    });

    return strategy;
  }

  private _attachFallbackToPositionStrategy(strategy: ConnectedPositionStrategy, fallbackPosition: OverlayPosition) {
    strategy.withFallbackPosition(
      { originX: fallbackPosition.originX, originY: fallbackPosition.originY },
      { overlayX: fallbackPosition.overlayX, overlayY: fallbackPosition.overlayY },
      this.getOffsetXFromPosition(fallbackPosition),
      this.getOffsetYFromPosition(fallbackPosition)
    );
  }

  updatePositionClasses(connectionPair: ConnectionPositionPair) {
    const direction: any = getDirFromPositionPair(connectionPair);
    const element = this._overlayRef.overlayElement.querySelector('.tooltip-wrapper');

    element.classList.remove(
      't-start',
      't-end',
      't-top',
      't-bottom',
      't-center',
      'a-start',
      'a-end',
      'a-top',
      'a-bottom',
      'a-center');

    element.classList.add(`t-${direction.position}`);

    if (this.tooltipType === 'arrow') {
      element.classList.add(`a-${direction.align}`, 'tooltip-with-arrow');
    }
  }

  reposition() {
    if (this._overlayRef) {
      this._overlayRef.updatePosition();
    }
  }

  getOffsetXFromPosition(position: OverlayPosition): number {
    if ((position.originX === 'start' || position.originX === 'end') && (position.overlayX === 'start' || position.overlayX === 'end')) {
      return 1;
    }
    return 0;
  }

  getOffsetYFromPosition(position: OverlayPosition): number {
    if ((position.originY === 'top' || position.originY === 'bottom') && (position.overlayY === 'top' || position.overlayY === 'bottom')) {
      return 1;
    }
    return 0;
  }

  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
    this.unHandleDocEvents();
  }
}
