import { Component, ElementRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { NestedFieldExplorer } from '../../react/components/SchemaBuilder/SourceSchema/JsonFieldExplorerDialog/NestedFieldExplorer';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'json-struct-fields-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class JsonStructFieldsDialogReactComponent implements OnChanges {
  @Input() open: boolean;
  @Input() field: any;
  @Input() childAccessor: string;
  @Output() onClose = new EventEmitter();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges(changes) {
    if (changes.open && !this.open) {
      this._reactBridgeService.unmount();
      return;
    }

    this._reactBridgeService.mount(
      NestedFieldExplorer,
      this._el.nativeElement,
      {
        open: true,
        parentPath: 'root',
        onClose: this.onClose.emit.bind(this.onClose),
        field: this.field,
        childAccessor: this.childAccessor
      }
    );
  }
}
