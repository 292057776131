import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAccessTokenField } from './utils/access-token';


export const BUILD_KITE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAccessTokenField({
        hint: 'The API Access Token obtained from the Personal Settings → API Access Tokens → New API Access Token → Enter Description → Select Organizations & Scopes → Create New API Access Token → Copy.',
      })
    }
  ],
  [
    {
      key: 'organizationSlug',
      fieldId: 'organization-slug',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'organizationSlug', label: 'Organization Slug', required: true },
      hint: 'The Organization Slug obtained from the URL of the Home Page.',
      buildParamsKey: 'organization_slug',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
