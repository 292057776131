import { EntityStatus } from '../../../../app/core/models/entity-status';

export class DestinationTable {
  id: number;
  name: string;
  syncTill: Date;
  lastSuccessfulSyncTS: number;
  deferredTill: Date;
  destinationSyncStatus: EntityStatus;
  failureMessage: string;
  dedupEnabled?: boolean;
}

export enum PageContext {
   DESTINATION = 'destination',
   PIPELINE = 'pipeline'
}
