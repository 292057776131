// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.tourWrapper_TOgGd {\n  margin: 16px;\n}\n.tourWrapper_TOgGd.sideBySide_eSxYY {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n}\n\n.tourDirection_U494t.right_YAk1p {\n  transform: scaleX(-1);\n}\n.tourDirection_U494t.left_n_a1v {\n  transform: scaleY(-1);\n}\n.tourDirection_U494t.rotateright_af8SW {\n  transform: rotate(90deg);\n}\n.tourDirection_U494t.rotateleft_FF2r1 {\n  transform: scaleX(-1) rotate(90deg);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tourWrapper": "tourWrapper_TOgGd",
	"sideBySide": "sideBySide_eSxYY",
	"tourDirection": "tourDirection_U494t",
	"right": "right_YAk1p",
	"left": "left_n_a1v",
	"rotateright": "rotateright_af8SW",
	"rotateleft": "rotateleft_FF2r1"
};
export default ___CSS_LOADER_EXPORT___;
