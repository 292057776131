import { DestinationConfigWarningField, DestinationConfigWarningFieldEnum } from './model';

export const TRACKER_DEST_CONFIG_UPDATE_WARNING = '[Dest config update warning] show warning window';
export const TRACKER_DEST_CONFIG_UPDATE_SUBMIT = '[Dest config update warning] submit click';
export const TRACKER_DEST_CONFIG_UPDATE_SUBMIT_SUCCESS = '[Dest config update warning] submit success';
export const TRACKER_DEST_CONFIG_UPDATE_SUBMIT_ERROR = '[Dest config update warning] submit error';
export const TRACKER_DEST_CONFIG_UPDATE_CANCEL = '[Dest config update warning] cancel click';

export const DESTINATION_CONFIG_WARNING_FIELDS: { [key: string]: DestinationConfigWarningField } = {
  [DestinationConfigWarningFieldEnum.BUCKET]: {
    title: 'Bucket',
    description: 'The configuration changes will affect all connected Pipelines and may lead to Events loss. ',
    value: DestinationConfigWarningFieldEnum.BUCKET
  },
  [DestinationConfigWarningFieldEnum.PROJECT_ID]: {
    title: 'Project ID',
    description: 'The configuration changes will affect all connected Pipelines and may lead to Events loss. ',
    value: DestinationConfigWarningFieldEnum.PROJECT_ID
  },
  [DestinationConfigWarningFieldEnum.DATASET_NAME]: {
    title: 'Dataset ID',
    description: 'The configuration changes will create new tables in your new Dataset for all connected Pipelines. It may also lead to Events loss.',
    value: DestinationConfigWarningFieldEnum.DATASET_NAME
  }
};
