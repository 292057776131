// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.drawer_M78YQ {\n  width: 464px;\n}\n\n.drawerHeader_eJA4R {\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n\n.jumpToastContainer_Dkzef {\n  width: 100%;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  height: 0;\n  z-index: 1;\n  top: 16px;\n}\n.jumpToastContainer_Dkzef .jumpToast_AtZ6W {\n  position: absolute;\n  min-height: fit-content;\n  padding: 4px 20px;\n  background: var(--primary-color);\n  color: var(--light-text);\n  border-radius: 20px;\n  box-shadow: var(--shadow-default);\n}\n\n.notificationsContainer_aiubb {\n  overflow: auto;\n  max-height: 100%;\n  padding-bottom: 44px;\n}\n\n.newNotificationSeparator_ClT_f {\n  width: 100%;\n  height: 1px;\n  border-bottom: solid 1px var(--deferred-2-color);\n  color: var(--deferred-2-color);\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  position: relative;\n}\n.newNotificationSeparator_ClT_f .text__8EzS {\n  font-size: 10px;\n  font-weight: 600;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  padding: 2px 4px;\n  border: solid 1px var(--deferred-2-color);\n  border-radius: 4px;\n  background: var(--surface-bg-color);\n  position: absolute;\n  right: 16px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": "drawer_M78YQ",
	"drawerHeader": "drawerHeader_eJA4R",
	"jumpToastContainer": "jumpToastContainer_Dkzef",
	"jumpToast": "jumpToast_AtZ6W",
	"notificationsContainer": "notificationsContainer_aiubb",
	"newNotificationSeparator": "newNotificationSeparator_ClT_f",
	"text": "text__8EzS"
};
export default ___CSS_LOADER_EXPORT___;
