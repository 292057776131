// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dialogBody_g2ZKR {\n  padding: 24px 24px 0;\n}\n\n.dialogFooter_J0KGK {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.header_sqR3W {\n  display: flex;\n  justify-content: space-between;\n}\n\n.closeIcon__vowp {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}\n\n.titleContainer_P8lw5 {\n  min-height: 40px;\n}\n\ntextarea {\n  padding: 16px;\n  resize: vertical;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n}\n\ntextarea.hd-form-control__LlP3 {\n  height: auto;\n  margin-top: 20px;\n  width: 100%;\n  overflow: auto;\n}\n\n.promoContent_fxzI4 {\n  border-radius: 6px;\n  background-color: var(--success-faded-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogBody": "dialogBody_g2ZKR",
	"dialogFooter": "dialogFooter_J0KGK",
	"header": "header_sqR3W",
	"closeIcon": "closeIcon__vowp",
	"titleContainer": "titleContainer_P8lw5",
	"hd-form-control": "hd-form-control__LlP3",
	"promoContent": "promoContent_fxzI4"
};
export default ___CSS_LOADER_EXPORT___;
