import { FieldTypes, SourceFormFieldDefinition } from '../models';


export const NETSUITE_ERP_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'accountId',
      fieldId: 'account-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'accountId',
        label: 'Account Id',
        required: true
      },
      hint: 'Your NetSuite account ID. You can find this at Setup > Integration > SOAP Web Services Preferences, Account ID field.',
      buildParamsKey: 'netsuite_account_id',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'subdomain',
      fieldId: 'subdomain',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'subdomain',
        label: 'Subdomain',
        required: true,
        suffixText: '.app.netsuite.com'
      },
      hint:
        'The subdomain of your Netsuite account URL.' +
        ' For example, in https://<b>xyz</b>.app.netsuite.com,the subdomain is <i>xyz.</i>',
      buildParamsKey: 'netsuite_webservice_url_subdomain',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'consumerKey',
      fieldId: 'consumer-key',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'consumerKey',
        label: 'Consumer Key',
        required: true
      },
      hint: 'The consumer key generated in NetSuite during integration record setup.',
      buildParamsKey: 'netsuite_consumer_key',
      externalDocLink:
        'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_4395597766.html',
      validations: [
        {
          type: 'required'
        }
      ]
    },

    {
      key: 'consumerSecret',
      fieldId: 'consumer-secret',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'consumerSecret',
        label: 'Consumer Secret',
        required: true
      },
      buildParamsKey: 'netsuite_consumer_secret',
      hint: 'The consumer secret associated with the consumer key which was displayed on the confirmation page during setup.',
      externalDocLink:
        'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_4395597766.html',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'tokenId',
      fieldId: 'token-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'tokenId', label: 'Token Id', required: true },
      buildParamsKey: 'netsuite_token_id',
      hint: 'The token ID in NetSuite for the user, integration role, and application combination.',
      externalDocLink:
        'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_4395630653.html',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'tokenSecret',
      fieldId: 'token-secret',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'tokenSecret',
        label: 'Token Secret',
        required: true
      },
      buildParamsKey: 'netsuite_token_secret',
      hint: 'The token secret in NetSuite for the user, integration role, and application combination.',
      externalDocLink:
        'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_4395630653.html',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
