import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterContainerReactComponent } from './toaster-container-react.component';

@NgModule({
  imports: [ CommonModule ],
  declarations: [
    ToasterContainerReactComponent
  ],
  exports: [
    ToasterContainerReactComponent
  ]
})
export class ToasterModule {
}
