export const TRACKER_ASK_SUPPORT_DIALOG_OPEN = '[Let Support Know] show dialog';
export const TRACKER_ASK_SUPPORT_CANCEL = '[Let Support Know] cancel';
export const TRACKER_ASK_SUPPORT_CANCEL_CLICK = '[Let Support Know] cancel click';
export const TRACKER_ASK_SUPPORT_ESC_CLICK = '[Let Support Know] escape click';
export const TRACKER_ASK_SUPPORT_OUTSIDE_CLICK = '[Let Support Know] outside click';
export const TRACKER_ASK_SUPPORT_CONTACT_SUPPORT_CLICK = '[Let Support Know] contact support click';

export const ASK_SUPPORT_SHOWN = 'general.askSupportShown';

export const ASK_SUPPORT_ON_ERROR_DELAY = 2000;

export const OFFICE_HOURS = [
  {
    startDay: 1,
    endDay: 6,
    startHour: 0,
    endHour: 0,
    startMinute: 30,
    endMinute: 30
  }
];
