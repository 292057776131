import { Directive, Input, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[faGroupContent]'})
export class FaGroupContentDirective implements OnChanges {
  @Input() faGroupContent: TemplateRef<any>;
  @Input() group: any;
  @Input() even: boolean;
  @Input() index: number;
  @Input() active: boolean;

  constructor(
    private _viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(changes: any) {
    this._viewContainer.clear();
    this._viewContainer.createEmbeddedView(
      this.faGroupContent,
      {
        $implicit: this.group,
        even: this.even,
        index: this.index,
        active: this.active
      }
    );
  }
}
