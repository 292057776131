import { InclusionListState } from '../../../../../../app/core/models/inclusion-list-v2';
import { OptionsType } from '../../../../../components/UIElements/HdDropDown';
import { GenericKeyValueType } from '../../../../../generics';

export enum GoogleAdsFormFieldNames {
  REPORT_CATEGORY = 'reportCategory',
  SELECTED_PREDEFINED_REPORTS = 'selectedPredefinedReport',
  SELECTED_CUSTOM_REPORT = 'selectedCustomReport',
  CONVERSION_WINDOW = 'conversionWindow',
  AGGREGATION_WINDOW = 'aggregationWindow',
  SELECTED_DIMENSIONS = 'selectedDimensions',
  SELECTED_METRICS = 'selectedMetrics',
  ATTRIBUTED_RESOURCES = 'attributedResources',
  ATTRIBUTED_RESOURCE_FIELDS = 'attributedResourceFields',
  SELECTED_SEGMENTS = 'selectedSegments',
  SEGMENTED_RESOURCES = 'segmentedResources',
  SEGMENTED_RESOURCE_FIELDS = 'segmentedResourceFields',
  ATTRIBUTED_AND_SEGMENTED_RESOURCE_FIELDS = 'attributedAndSegmentedResourceFields',
  SOURCE_VERSION = 'sourceVersion',
  SELECTED_CUSTOMERS = 'selectedCustomers',
  CUSTOMER_ID = 'customerId',
  O_AUTH_TOKEN_ID = 'oauthTokenId',
  MANAGERS = 'managers',
  HISTORICAL_SYNC_DURATION = 'historicalSyncDuration',
  GOOGLE_ADS_API_VERSION = 'apiVersion'
}

export enum GoogleAdsReportCategory {
  STANDARD_REPORTS = 'standard_report',
  CUSTOM_REPORTS = 'custom_report'
}

export interface GoogleAdsFormState extends GenericKeyValueType<string, any> {
  [GoogleAdsFormFieldNames.O_AUTH_TOKEN_ID]: number;
  [GoogleAdsFormFieldNames.SOURCE_VERSION]: number | null;
  [GoogleAdsFormFieldNames.SELECTED_CUSTOMERS]: InclusionListState;
  [GoogleAdsFormFieldNames.REPORT_CATEGORY]: GoogleAdsReportCategory;
  [GoogleAdsFormFieldNames.CONVERSION_WINDOW]: GoogleAdsConversionWindowType;

  [GoogleAdsFormFieldNames.GOOGLE_ADS_API_VERSION]: GoogleAdsApiVersion;

  // standard reports form state
  [GoogleAdsFormFieldNames.SELECTED_PREDEFINED_REPORTS]: string[];
  [GoogleAdsFormFieldNames.AGGREGATION_WINDOW]: string | null;

  // custom reports form state
  [GoogleAdsFormFieldNames.SELECTED_CUSTOM_REPORT]: string | null;
  [GoogleAdsFormFieldNames.SELECTED_SEGMENTS]: GoogleAdsResourceFieldData[];
  [GoogleAdsFormFieldNames.SELECTED_DIMENSIONS]: GoogleAdsResourceFieldData[];
  [GoogleAdsFormFieldNames.SELECTED_METRICS]: GoogleAdsResourceFieldData[];
  [GoogleAdsFormFieldNames.SEGMENTED_RESOURCES]: GoogleAdsSegmentedResources;
  [GoogleAdsFormFieldNames.ATTRIBUTED_RESOURCES]: GoogleAdsAttributedResources;
  [GoogleAdsFormFieldNames.ATTRIBUTED_AND_SEGMENTED_RESOURCE_FIELDS]: GoogleAdsAttributedAndSegmentedResource[];
  [GoogleAdsFormFieldNames.MANAGERS]?: GoogleAdsManagersFormFieldType;
}

export type GoogleAdsConversionWindowType =
  | {
      id: number;
      name: string;
    }
  | number;
export type GoogleAdsCustomFieldsData = Record<string, GoogleAdsFieldData>;
export type GoogleAdsIncompatibleFields = Record<string, string[]>;
export type GoogleAdsResourceFields = GenericKeyValueType<string, any>;
export type GoogleAdsAttributedResources = string[];
export type GoogleAdsSegmentedResources = string[];
export type GoogleAdsAttributedAndSegmentedResource = OptionsType;
export type GoogleAdsIdType = string | number;
export type GoogleAdsManagersFormFieldType = { id: GoogleAdsIdType; canManage: boolean }[];

export interface GoogleAdsResourceFieldData extends GoogleAdsFieldData {
  value: string;
  id: string;
  disabled?: boolean;
}

export interface GoogleAdsFieldData {
  name: string;
  description: string;
  category: string;
  type: string;
}

export interface GoogleAdsFormMetaData extends GenericKeyValueType<string, any> {
  reportOptions: string[];
  conversionWindowOptions: {
    id: number;
    name: string;
  }[];
  customFieldsData: GoogleAdsCustomFieldsData;
  apiVersions: GoogleAdsApiVersion[];
}

export interface GoogleAdsApiVersion {
  id: string;
  name: string;
  helperText: string;
  banner?: {
    message: string;
    level: string;
    doc?: {
      text: string;
      url: string;
    };
  };
}

export interface GoogleAdResourceAPIResponse {
  attributes: string[];
  metrics: string[];
  resourceName: string;
  segments: string[];
  attributedResources: GoogleAdsAttributedResources;
  attributedResourceFields: GoogleAdsResourceFields;
  incompatibleFields: GoogleAdsIncompatibleFields;
  segmentedResources: GoogleAdsSegmentedResources;
  segmentedResourceFields: GoogleAdsResourceFields;
}

export interface GoogleAdsCustomReport {
  report: string;
  segments: string[];
  dimensions: string[];
  metrics: string[];
  attributed_resource_fields: string[];
  segmented_resource_fields: string[];
}

export interface SelectedCustomer {
  customer_id: GoogleAdsIdType;
  included_list: GoogleAdsIdType[];
  excluded_list: GoogleAdsIdType[];
  can_manage: boolean;
  selected: boolean;
}

export interface GoogleAdsConfigPayload {
  customer_id: GoogleAdsIdType;
  is_custom_report: boolean;
  aggregation_window: string;
  conversion_window: number;
  predefined_reports?: string[];
  selected_customers: SelectedCustomer[];
  custom_reports?: GoogleAdsCustomReport[];
  api_version: string;
}

export interface GoogleAdsConfigResponse extends GenericKeyValueType<string, any> {
  historical_load_unit: string;
  historical_load_duration: number;
  is_custom_report: boolean;
  oauth_token_id: number;
  aggregation_window: string;
  all_available_data: boolean;
  conversion_window: number;
  custom_reports?: GoogleAdsCustomReport[];
  selected_customers: SelectedCustomer[];
  predefined_reports?: string[];
  json_parsing_strategy?: string;
  authenticated_user?: string;
  auth_environment?: string;
  api_version?: string;
}

export interface GoogleAdsManagerProfileResponseV2 {
  basic_info: {
    name: string;
    id: GoogleAdsIdType;
  };
  managing_clients: boolean;
  managed_customers_count: number;
}

export interface GoogleAdwardsManagerProfilesAPIResponse {
  customer_id: GoogleAdsIdType;
  descriptive_name: string;
  can_manage_clients: boolean;
  total_num_entries: number;
}

export type GoogleAdsManagerProfileResponseV1 = GenericKeyValueType<
  GoogleAdsIdType,
  GoogleAdwardsManagerProfilesAPIResponse[]
>;

export interface GoogleAdsManagerProfilesAPIResponse {
  count: number;
  success: boolean;
  data: GoogleAdsManagerProfileResponseV2[] | GoogleAdsManagerProfileResponseV1;
}

export interface GoogleAdsCustomerProfilesAPIResponseV2 {
  id: GoogleAdsIdType;
  name: string;
}

export interface GoogleAdwardsCustomerProfilesAPIResponse {
  customer_id: GoogleAdsIdType;
  descriptive_name: string;
}

export type GoogleAdsCustomerProfileResponseV1 = GenericKeyValueType<
  GoogleAdsIdType,
  GoogleAdwardsCustomerProfilesAPIResponse[]
>;

export interface GoogleAdsCustomerProfilesAPIResponse {
  count: number;
  success: boolean;
  data: GoogleAdsCustomerProfilesAPIResponseV2[] | GoogleAdsCustomerProfileResponseV1;
}

export type ManagerCustomerMap = GenericKeyValueType<
  GoogleAdsIdType,
  GoogleAdsCustomerProfilesAPIResponseV2[]
>;
