// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progress_m82jW {\n  outline: 4px solid var(--default-secondary-color);\n  background-color: var(--default-secondary-color);\n  border-radius: 50%;\n}\n.progress_m82jW svg {\n  color: #ffffff !important;\n}\n\n.shallowTooltip_w4FAa {\n  position: absolute;\n  width: 70%;\n  height: 20px;\n  top: var(--spacer);\n  z-index: 1;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "progress_m82jW",
	"shallowTooltip": "shallowTooltip_w4FAa"
};
export default ___CSS_LOADER_EXPORT___;
