import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

interface FormObserverProps {
  formInteraction: Function;
  formFieldFocusIn: Function;
  fieldNameMapping?: object;
  formValueChanges?: Function;
}

// eslint-disable-next-line react/function-component-definition
const FormObserver: React.FC<FormObserverProps> = ({
  formInteraction,
  formFieldFocusIn,
  fieldNameMapping,
  formValueChanges
}) => {
  const { values }: any = useFormikContext();

  const prevValues = useRef(values);

  useEffect(() => {
    if (typeof formValueChanges === 'function') {
      formValueChanges();
    }
  }, [values]);

  useEffect(() => {
    const fields = Object.keys(values);
    const eventListeners = [];

    if (fields.length > 0) {
      fields.forEach(fieldName => {
        const selector = `[id="${fieldName}"]`;
        const interactiveDOMElement: HTMLElement = document.querySelector(selector);

        if (interactiveDOMElement) {
          const focusOutCallback = focusOutHandler(fieldName);
          const focusInCallback = focusInHandler(fieldName);
          interactiveDOMElement.addEventListener('focusin', focusInCallback);
          interactiveDOMElement.addEventListener('focusout', focusOutCallback);

          eventListeners.push({ interactiveDOMElement, focusInCallback, focusOutCallback });
        }
      });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      eventListeners.forEach(({ interactiveDOMElement, focusInCallback, focusOutCallback }) => {
        interactiveDOMElement.removeEventListener('focusin', focusInCallback);
        interactiveDOMElement.removeEventListener('focusout', focusOutCallback);
      });
    };
  });

  const focusOutHandler = (fieldName: string) => (event: FocusEvent) => {
    event.preventDefault();

    if (values[fieldName] !== prevValues.current[fieldName]) {
      formInteraction(getMappedFieldName(fieldName));
    }
    prevValues.current = { ...values };
  };

  const focusInHandler = (fieldName: string) => (event: FocusEvent) => {
    event.preventDefault();
    formFieldFocusIn(getMappedFieldName(fieldName));
    prevValues.current = { ...values };
  };

  const getMappedFieldName = fieldName => {
    let interactedFieldName = fieldName;
    if (fieldNameMapping && fieldNameMapping[fieldName] !== undefined) {
      interactedFieldName = fieldNameMapping[fieldName];
    }
    return interactedFieldName;
  };

  return null;
};

export default FormObserver;
