import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieModule } from 'ngx-lottie';
import { NgxMdModule } from 'ngx-md';

import { HdA11yModule } from '../a11y/a11y.module';
import { ReplayReasonDetailsDialogReactComponent } from '../dialog/replay-reason-details-dialog-react.component';
import { ReplaySamplesDialogReactComponent } from '../dialog/replay-samples-dialog-react.component';
import { MenuModule } from '../menu/menu.module';
import { HdMomentModule } from '../moment/moment.module';
import { PaginationModule } from '../pagination/pagination.module';
import { PipeModule } from '../pipe/pipe.module';
import { PopupModule } from '../popup/popup.module';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { FailedEventTypesTriggerComponent } from './failed-event-types-trigger/failed-event-types-trigger.component';
import { FailedEventTypesWidgetComponent } from './failed-event-types-widget/failed-event-types-widget.component';
import { ReplayProgressComponent } from './replay-progress/replay-progress.component';


const IMPORTS = [
  CommonModule,
  FormsModule,
  RouterModule,
  PopupModule,
  NgxMdModule,
  TooltipModule,
  SharedModule,
  PaginationModule,
  MenuModule,
  HdA11yModule,
  HdMomentModule,
  ClipboardModule,
  ClipboardModule,
  PipeModule,
  LottieModule
];

const DECLARATIONS = [
  FailedEventTypesTriggerComponent,
  FailedEventTypesWidgetComponent,
  ReplayProgressComponent,
  ReplaySamplesDialogReactComponent,
  ReplayReasonDetailsDialogReactComponent
];

@NgModule({
  imports: IMPORTS,
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class SidelineModule {}
