// How to use:
// componentMethod = debounce(this, _privateComponentMethod, 2000);
// Now use componentMethod in your component


export const debounce = (componentRef, fn: Function, time = 300) => {
  let timer: any;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(componentRef, args);
    }, time);
  };
};
