import React, { useRef } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { validationSchema } from './config';
import {
  HdFormikFileField,
  HdFormikTextField,
  HdFormikSwitch
} from '../../../components/FormikElements';
import ErrorFocus from '../../../utils/ErrorFocus';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import {
  HdButton,
  HdDocLink,
  HdFormControl,
  HdPane,
  HdCopyIcon
} from '../../../components/UIElements';
import styles from './styles.module.scss';

export function SamlForm({ samlData, samlFormError, onUpdateConfig, dataId }) {
  const formikRef = useRef<FormikProps<any>>();

  const handleSubmit = () => {
    onUpdateConfig(formikRef.current.values);
  };

  useDontMountAtFirst(() => {
    formikRef.current.setValues({
      ...formikRef.current.values,
      ...samlData
    });
  }, [samlData]);

  const dataIdGenerator = getDataIdGenerator(dataId, 'samlConfig');

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={validationSchema}
      initialValues={samlData}
      onSubmit={handleSubmit}
    >
      {props => {
        const isEmailRequired = !props.values?.pickUserId;
        return (
          <>
            <Box
              component='form'
              className='pl-8 pb-7 mt-8'
              onSubmit={props.handleSubmit}
              noValidate
              data-id={dataIdGenerator('form')}
            >
              <ErrorFocus formik={props} onFormError={() => {}} />

              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <HdFormControl className='mb-7'>
                    <HdFormikSwitch
                      labelClasses={styles.switchLabel}
                      fieldName='autoProvisioning'
                      label='Enable Automatic Provisioning'
                    >
                      Allows new users of your organization to join the workspace without an
                      invitation.
                    </HdFormikSwitch>
                  </HdFormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <HdFormControl className='mb-2'>
                    <Field
                      component={HdFormikFileField}
                      name='sslCertificate'
                      required
                      label='Upload X.509 Certificate for IdP'
                    />
                  </HdFormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      component={HdFormikTextField}
                      required
                      name='idpEntityId'
                      label='IdP Entity Id'
                      helperText='Entity Provider for your IdP'
                    />
                  </HdFormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      component={HdFormikTextField}
                      required
                      name='loginURL'
                      label='Login URL'
                      helperText='URL for signing into the remote authentication system'
                    />
                  </HdFormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      component={HdFormikTextField}
                      required
                      disabled
                      endAdornment={
                        <HdCopyIcon
                          toastMessage='Relay State copied to clipboard'
                          copyText={props.values.relayState}
                          dataId={dataIdGenerator('relay-state')}
                        />
                      }
                      name='relayState'
                      label='Relay State'
                      helperText='Relay State needs to be provided in your IdP configuration.'
                    />
                  </HdFormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      component={HdFormikTextField}
                      required
                      disabled
                      endAdornment={
                        <HdCopyIcon
                          toastMessage='SAML Metadata URL copied to clipboard'
                          copyText={props.values.samlMetadataURL}
                          dataId={dataIdGenerator('metadata-url')}
                        />
                      }
                      name='samlMetadataURL'
                      label='SAML Meta Data'
                      helperText='SAML Metadata URL for IdP initiated SSO'
                    />
                  </HdFormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <HdFormControl>
                    <HdFormikSwitch
                      labelClasses={styles.switchLabel}
                      fieldName='pickUserId'
                      label='Pick Email ID from saml2:NameID Element'
                    >
                      Email ID will be picked from SAML Assertion
                    </HdFormikSwitch>
                  </HdFormControl>
                </Grid>
              </Grid>

              <div className='mt-1 mb-6'>
                <span className='text-bold pr-2 border-right mr-2'>IdP Mapping Attributes</span>

                <HdDocLink
                  section='authentication_settings'
                  label='Read More'
                  docLink='/account-management/team-settings/enforcing-saml-sso-across-your-team/#configuring-saml-sso-in-hevo'
                  dataId={dataIdGenerator('sso')}
                />
              </div>

              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <HdFormControl>
                    <Field
                      component={HdFormikTextField}
                      required={isEmailRequired}
                      name='email'
                      label='Email'
                    />
                  </HdFormControl>
                </Grid>

                <Grid item xs={6} md={4}>
                  <HdFormControl>
                    <Field component={HdFormikTextField} name='firstName' label='First Name' />
                  </HdFormControl>
                </Grid>

                <Grid item xs={6} md={4}>
                  <HdFormControl>
                    <Field component={HdFormikTextField} name='lastName' label='Last Name' />
                  </HdFormControl>
                </Grid>
              </Grid>

              {!!samlFormError && (
                <HdPane variant='error-faded' iconClasses='error-filled'>
                  {samlFormError}
                </HdPane>
              )}
            </Box>
            <div className='border-bottom' />

            <div className='d-flex justify-end mt-5'>
              <HdButton
                type='submit'
                onClick={() => props.handleSubmit()}
                dataId={dataIdGenerator('submit')}
              >
                Configure SAML SSO Login
              </HdButton>
            </div>
          </>
        );
      }}
    </Formik>
  );
}
