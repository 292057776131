// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.innerIcon_VOjM5 {\n  display: flex;\n  text-decoration: none;\n}\n.innerIcon_VOjM5:hover {\n  text-decoration: none !important;\n}\n\n.dropdownRow_BlXJ2 {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  font-weight: 600;\n}\n\n.dropdownText_YhVin {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.deleteIconColor_oHjmb {\n  color: var(--error-color) !important;\n}\n\n.closeBtn__hlbO {\n  position: absolute;\n  top: 12px;\n  right: 12px;\n  color: var(--text-default-color);\n  font-size: 20px;\n}\n\n.closeBtnSpan_k3diV {\n  font-size: 12px !important;\n}\n\n.bottomContainer_kuNGp {\n  display: flex;\n  justify-content: flex-end;\n  border-top: 1px solid var(--divider-color);\n}\n\n.dialogContainer_q8gI6 {\n  width: 520px;\n}\n\n.dialogHeader_za9xO {\n  background: var(--surface-bg-color);\n  top: 0;\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  z-index: 5;\n}\n\n.accountTypeDropdownRoot_nitNX {\n  flex: 1;\n  border-right: 1px solid var(--divider-color);\n}\n\n.root_V_LjZ {\n  border: 1px solid var(--divider-color);\n  border-radius: 8px;\n}\n.root_V_LjZ:hover {\n  border-color: var(--text-default-color);\n}\n\n.focused_f_qCP {\n  border-color: var(--text-default-color);\n}\n\n.error_xXfik {\n  border-color: var(--error-color) !important;\n}\n\n.accountsLabel_ct7oO {\n  position: absolute;\n  padding: 0 8px;\n  z-index: 10;\n  color: var(--text-secondary-color);\n  background-color: var(--surface-bg-color);\n  transform: translate(8px, -8px);\n}\n\n.labelFocused_olLak {\n  color: var(--text-default-color);\n}\n\n.asterisk_ZFoUU {\n  padding-left: 2px;\n}\n\n.authAccountsDropDownRoot_BC7Kb {\n  flex: 3;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerIcon": "innerIcon_VOjM5",
	"dropdownRow": "dropdownRow_BlXJ2",
	"dropdownText": "dropdownText_YhVin",
	"deleteIconColor": "deleteIconColor_oHjmb",
	"closeBtn": "closeBtn__hlbO",
	"closeBtnSpan": "closeBtnSpan_k3diV",
	"bottomContainer": "bottomContainer_kuNGp",
	"dialogContainer": "dialogContainer_q8gI6",
	"dialogHeader": "dialogHeader_za9xO",
	"accountTypeDropdownRoot": "accountTypeDropdownRoot_nitNX",
	"root": "root_V_LjZ",
	"focused": "focused_f_qCP",
	"error": "error_xXfik",
	"accountsLabel": "accountsLabel_ct7oO",
	"labelFocused": "labelFocused_olLak",
	"asterisk": "asterisk_ZFoUU",
	"authAccountsDropDownRoot": "authAccountsDropDownRoot_BC7Kb"
};
export default ___CSS_LOADER_EXPORT___;
