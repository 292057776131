export function range(start: number, end: number) {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
}

export function convert1Dto2DArray(array: any[], noOfColumns: number) {
  let count = 0;
  const twoDArray = [];
  for (let i = 0; i < array.length / noOfColumns; i++) {
    const row = [];
    for (let j = 0; j < noOfColumns; j++) {
      row.push(array[count++]);
      if (count === array.length) {
        break;
      }
    }
    twoDArray.push(row);
  }
  return twoDArray;
}

export function hasDuplicateItems(array: any[]) {
  return new Set(array).size !== array.length;
}

export function removeDuplicatesFromArrayOfObjects(arr: any[], uniqueKeys: string[]) {
  return arr.filter((obj, index, filteredArr) => {
    return filteredArr.findIndex((elem) => {
      return uniqueKeys.every(key => obj[key] === elem[key]);
    }) === index;
  });
}

export function getSortedListFromReferenceList(list, referenceList, keySelector: (item: any) => any) {
  const sortedList = [ ...list ];

  sortedList.sort((a, b) => {
    const aIndex = referenceList.indexOf(keySelector(a));
    const bIndex = referenceList.indexOf(keySelector(b));

    if (aIndex === bIndex) {
      return 0;
    }

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });

  return sortedList;
}

export function shuffle<T = any>(list: T[]) {
  return list.sort(() => Math.random() - .5);
}

export function getArrayFromSize(size: number) {
  return Array.from(Array(size).keys());
}
