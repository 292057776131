export const SETUP_PERSONA_REQUIRED = 'setup_persona_required';

export const POST_LOGIN_REDIRECT_URL = 'next_url';

export const DESIGNATIONS = [
  { id: 'backend', name: 'Backend Engineer' },
  { id: 'frontend', name: 'Frontend Engineer' },
  { id: 'designer', name: 'Product Designer' },
  { id: 'product_manager', name: 'Product Manager' },
  { id: 'senior_engineer', name: 'Senior Engineer' },
  { id: 'marketing', name: 'Product Marketing' },
  { id: 'talent_acquisition', name: 'Talent Acquisition' },
  { id: 'analyst', name: 'Business and Data Analyst' },
  { id: 'analytics_head', name: 'Head Of Data Analytics' },
  { id: 'data_engineer', name: 'Data Engineer' },
  { id: 'data_engineer_lead', name: 'Lead Data Engineer' },
  { id: 'software', name: 'Software Engineer' },
  { id: 'lead_engineer', name: 'Lead Engineer' },
  { id: 'business_associate', name: 'Business Associate' },
  { id: 'solutions_expert', name: 'Solutions Expert' },
  { id: 'facility_manager', name: 'Facility Manager' },
  { id: 'tech_head', name: 'Head Of Technology' },
  { id: 'managing_director', name: 'Managing Director' },
  { id: 'vp_engineering', name: 'VP Engineering' },
  { id: 'ceo_co_founder', name: 'CEO & Co-Founder' },
  { id: 'cto_co_founder', name: 'CTO & Co-Founder' },
  { id: 'ceo', name: 'CEO' },
  { id: 'cto', name: 'CTO' },
  { id: 'other', name: 'Other' }
];
