import { Box, FormControl, FormControlProps, styled } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';

interface HdFormControlProps extends FormControlProps {
  className?: string;
  dataId?:string;
}

export default function HdFormControl({ className = '', children, ...props }: HdFormControlProps) {
  return (
    <StyledFormControl className={className} {...props}>
      {children}
    </StyledFormControl>
  );
}

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
  position: 'relative',
  marginBottom: 'calc(var(--spacer) * 1.34)'
}));

export function HdFormButtonWrapper({
  className,
  children
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return <Box className={`${styles.buttonWrapper} ${className}`}> {children} </Box>;
}
