import { numFormatter } from '../../../react/legacy-utils/number';


export class SidelineGroupReplayProgress {
  sourceObjectId: number;
  totalNoOfRecords: number;
  noOfRecordsReplayed: number;

  getProgress(): number {
    return this.noOfRecordsReplayed / this.totalNoOfRecords;
  }

  getDisplayString(): string {
    return `${ numFormatter(this.noOfRecordsReplayed) } of ${ numFormatter(this.totalNoOfRecords) } events replayed`;
  }

  isCompleted(): boolean {
    return this.noOfRecordsReplayed >= this.totalNoOfRecords;
  }

  getRemainingFormatted(): string | number {
    return numFormatter(this.totalNoOfRecords - this.noOfRecordsReplayed);
  }

  getRemainingRecordsCount(): number {
    return this.totalNoOfRecords - this.noOfRecordsReplayed;
  }
}
