// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.filtersContainer_VUB0j {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px 12px;\n  min-height: 46px;\n}\n.filtersContainer_VUB0j .appliedFiltersContainer_V_Ivf {\n  flex: 1;\n  display: flex;\n  align-items: center;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG .appliedFilter__CAb_ {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG .cloudwatchStatus_wLOmm {\n  display: flex;\n  align-items: center;\n  white-space: pre;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG .cloudwatchStatus_wLOmm .editIcon_vXf3a {\n  margin-right: -4px;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG .refreshButton_kiHH2 {\n  margin-right: -4px;\n  margin-left: -4px;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG .shimmerBig_PVzkZ {\n  width: 120px;\n  height: 12px;\n}\n.filtersContainer_VUB0j .filterWrapper_H2ItG .shimmerSmall_qzkbZ {\n  width: 80px;\n  height: 12px;\n}\n.filtersContainer_VUB0j.filtersDialog_WmOKE {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: var(--surface-bg-color);\n  padding: 16px 20px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersContainer": "filtersContainer_VUB0j",
	"appliedFiltersContainer": "appliedFiltersContainer_V_Ivf",
	"filterWrapper": "filterWrapper_H2ItG",
	"appliedFilter": "appliedFilter__CAb_",
	"cloudwatchStatus": "cloudwatchStatus_wLOmm",
	"editIcon": "editIcon_vXf3a",
	"refreshButton": "refreshButton_kiHH2",
	"shimmerBig": "shimmerBig_PVzkZ",
	"shimmerSmall": "shimmerSmall_qzkbZ",
	"filtersDialog": "filtersDialog_WmOKE"
};
export default ___CSS_LOADER_EXPORT___;
