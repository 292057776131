import { WorkspaceObjDefinition } from './model';

export const getFormattedDataForDropdown = (data: WorkspaceObjDefinition) =>
  data.projects.map(elem => ({
    name: `${elem.value} ${elem.key}`,
    id: elem.key,
    workspace_id: data.workspace_id,
    ...elem
  }));

export const moveWorkspaceDataToSelectedProjects = data => {
  const selectedProjects = {};
  data.forEach(projects => {
    if (projects.length) {
      selectedProjects[projects[0].workspace_id] = cleanProjectsObj(projects);
    }
  });
  return selectedProjects;
};

const cleanProjectsObj = projects =>
  projects.map(project => ({
    key: project.key,
    first: project.first,
    second: project.second,
    value: project.value
  }));
