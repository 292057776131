import React from 'react';
import clsx from 'clsx';
import { TitleProps } from './shared/interfaces';
import { useDateTimeAdapter } from '../useDateTimeAdapter';

export default function Tile<T>(props: TileProps<T>) {
  const { dateTimeAdapter } = useDateTimeAdapter<T>();
  const { children, classes, date, formatAbbr, maxDate, minDate, onClick, style } = props;

  return (
    <button
      className={clsx(classes)}
      disabled={
        (minDate && dateTimeAdapter.compare(minDate, date) > 0) ||
        (maxDate && dateTimeAdapter.compare(maxDate, date) < 0)
      }
      onClick={onClick && (event => onClick(date, event))}
      style={style}
      type='button'
    >
      {formatAbbr ? (
        <abbr className='react-calendar__tile-content' aria-label={formatAbbr}>
          {children}
        </abbr>
      ) : (
        <span className='react-calendar__tile-content'>{children}</span>
      )}
    </button>
  );
}

interface TileProps<T> extends TitleProps<T> {
  children: React.ReactElement;
  formatAbbr?: string;
}
