// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.progress_iwOdZ {\n  outline: 4px solid var(--default-secondary-color);\n  background-color: var(--default-secondary-color);\n  border-radius: 50%;\n}\n.progress_iwOdZ svg {\n  color: #ffffff !important;\n}\n\n.field_Y1raD {\n  z-index: 1;\n}\n\n.fieldError_oZpIy,\n.fieldHelp__jL3m {\n  z-index: 0;\n  display: inline-block;\n  font-size: 83% !important;\n  min-height: 20px;\n  font-weight: 600 !important;\n  padding-left: 8.8px !important;\n  margin-top: 4px !important;\n}\n\n.fieldError_oZpIy {\n  color: var(--error-color) !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "progress_iwOdZ",
	"field": "field_Y1raD",
	"fieldError": "fieldError_oZpIy",
	"fieldHelp": "fieldHelp__jL3m"
};
export default ___CSS_LOADER_EXPORT___;
