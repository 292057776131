import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import styles from './styles.module.scss';
import { HdIcon, HdTooltip } from '../../../../components/UIElements';
import HdShortcut from '../../../../components/UIElements/HdShortcut';
import useKeyboardShortcutListener from '../../../../hooks/useKeyboardShortcutListener';

interface AppbarItemProps {
  navActive?: boolean;
  label?: string;
  iconName?: string;
  shortcut?: any;
  className?: string;
  drawerLink?: string;
  isExternalLink?: boolean;
  styles?: any;
  activeDrawerLink?: string;
  onClick?: Function;
  to?: string;
  [key: string]: any;
}

export function AppbarItem({
  navActive,
  label,
  iconName,
  shortcut,
  className,
  drawerLink,
  activeDrawerLink,
  onClick,
  isExternalLink = false,
  to,
  ...props
}: AppbarItemProps) {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  let drawerPath;
  if (drawerLink) {
    params.set('drawer', drawerLink);

    drawerPath = `${location.pathname}?${params.toString()}`;
  }
  const handleDrawerOpen = e => {
    onClick(drawerLink || to);

    if (drawerLink) {
      history.push({
        search: params.toString()
      });
      e.preventDefault();
      return;
    }

    if (!isExternalLink) {
      history.push(`${to}`);
      e.preventDefault();
    }
  };

  useKeyboardShortcutListener(handleDrawerOpen, shortcut?.command, {
    priority: 0,
    terminal: 'match'
  });

  const drawerIsActive = drawerLink && drawerLink === activeDrawerLink;

  return (
    <a
      {...props}
      href={to || drawerPath}
      className={clsx(
        navActive && styles.active,
        styles.appbarItem,
        drawerIsActive && styles.open,
        className
      )}
      onClick={handleDrawerOpen}
    >
      <HdTooltip title={<HdShortcut command={shortcut?.command} />} disabled={!shortcut}>
        <div className={styles.appbarLinkWrapper}>
          <HdIcon className={`${styles.appbarLinkIcon} hd-icon`} name={iconName} />

          <span>{label}</span>
        </div>
      </HdTooltip>
    </a>
  );
}
