export const DEFAULT_WEBHOOK_EVENT_NAME_PATH = 'event';
export const DEFAULT_WEBHOOK_PROPERTIES_PATH = 'properties';
export const API_KEY_DIALOG_MESSAGE = 'Hevo does not store the secret key for security reasons. You must, therefore,' +
  '  download these credentials now as you will not be able to do so later';
export const API_KEY_SUCCESS_MESSAGE = 'API key added successfully.';

// eslint-disable-next-line no-shadow
export enum WebhookAuthEnum {
  NO_AUTH = 'NO_AUTH',
  BASIC_AUTH = 'BASIC_AUTH'
}
