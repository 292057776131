export interface PriorityQueueItem<T> {
  data: T;
  priority: number;
}

export class PriorityQueue<T> {
  private _store: PriorityQueueItem<T>[] = [];

  constructor(private comparator: (obj: T) => string) {
  }

  insert(item: PriorityQueueItem<T>) {
    let insertIndex = this._store.findIndex((i) => {
      return item.priority < i.priority;
    });

    if (insertIndex === -1) {
      insertIndex = this._store.length;
    }

    this._store.splice(insertIndex, 0, item);
  }

  pop(): PriorityQueueItem<T> {
    return this._store.pop();
  }

  remove(id) {
    const index = this._store
      .findIndex((ele) => this.comparator(ele.data) === id);
    this.removeAt(index);
  }

  removeAt(index) {
    this._store.splice(index, 1);
  }

  peek(): PriorityQueueItem<T> {
    return this._store[this._store.length - 1];
  }

  items() {
    return this._store;
  }

  clear() {
    this._store = [];
  }

  size() {
    return this._store.length;
  }
}
