/* eslint-disable import/no-cycle */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { getArrayFromObj } from '../../../../../legacy-utils/object';
import {
  REST_API_REQUEST_METHODS,
  DEFAULT_KEY_VALUE_DATA,
  DEFAULT_QUERY_PARAMS_DATA,
  DEFAULT_REST_API_STARTING_PAGE_NO,
  OFFSET_UNITS,
  QUERY_PARAM_TYPES
} from '../../../../../../app/nodes/nodes-settings/rest-api/constants';
import {
  AuthType,
  PaginationTypesEnum,
  PostBodyTypeEnum,
  QueryParam,
  QueryParamType,
  OffsetUnit
} from '../../../../../../app/nodes/nodes-settings/rest-api/models';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import AuthAPI from '../../../../auth/AuthAPI';

export let _restApiConfig: RestApiReactConfigHelper;

export class RestApiReactConfigHelper implements SourceTypeConfigHelper {
  // eslint-disable-next-line no-empty-function
  constructor() {
    _restApiConfig = this;
  }

  getDefaultConfig() {
    return {
      method: REST_API_REQUEST_METHODS[0],
      initialPageNumber: DEFAULT_REST_API_STARTING_PAGE_NO,
      paginationType: PaginationTypesEnum.NO_PAGINATION,
      extractPageNoFromResponse: false,
      queryParams: this.buildQueryParamsObj([]),
      headers: this.buildObj({}),
      formData: this.buildObj({}),
      postBodyType: PostBodyTypeEnum.JSON,
      isPaginationUrlBased: false,
      offsetIncrementValue: 0,
      authorizationType: AuthType.NO_AUTH
    };
  }

  buildConnectionParams(data: any): any {
    const config: { [key: string]: any } = {
      url: data.url,
      method: data.method?.value,
      data_root: data.dataRoot,
      auth_type: data.authorizationType,
      headers: (data.headers || [])
        .filter((header: any[]) => !!header[0])
        .reduce((acc, cur) => {
          acc[cur[0]] = cur[1];
          return acc;
        }, {}),
      query_params: (data.queryParams || [])
        .filter((param: QueryParam) => !!param.key)
        .map((param: QueryParam) => ({
          type: param.type?.value,
          key: param.key,
          value: param.value,
          format: param.type?.value === 'date' ? param.format : undefined,
          offset_unit:
            param.type?.value === 'date' && param.offsetUnit ? param.offsetUnit.value : undefined,
          offset_value: param.type?.value === 'date' ? param.offsetValue : undefined
        }))
    };

    if (data.authorizationType === AuthType.BASIC_AUTH) {
      config.basic_auth_user = data.basicAuthUser;
      config.basic_auth_password = data.basicAuthPass;
    } else if (data.authorizationType === AuthType.OAUTH2) {
      config.oauth_token_id = data.token?.id;
    }

    config.pagination_type = data.paginationType;

    if (data.paginationType !== PaginationTypesEnum.NO_PAGINATION) {
      switch (data.paginationType) {
        case PaginationTypesEnum.SESSION: {
          config.is_pagination_url_based = data.isPaginationUrlBased;
          if (!data.isPaginationUrlBased) {
            config.page_query_param = data.sessionPageNoQueryParam;
            config.page_size = data.pageSize;
            config.page_response_field_param = data.sessionPageResponseField;
            config.next_session_last_from_prev_response = data.nextSessionLastFromPrevResponse;
          } else {
            config.page_response_field_param = data.nextUrlResponseField;
          }
          break;
        }
        case PaginationTypesEnum.PAGE_NO: {
          config.page_query_param = data.pageNoQueryParam;
          config.start_page_no = data.initialPageNumber;
          config.page_size = data.pageSize;
          config.extract_page_no_from_response = data.extractPageNoFromResponse || false;
          if (data.extractPageNoFromResponse) {
            config.page_response_field_param = data.pageResponseField;
            config.page_no_increment_for_next_page = data.incrementPageNo;
          }
          break;
        }
        case PaginationTypesEnum.LIMIT_OFFSET: {
          config.offset_query_param = data.offsetQueryParam;
          config.offset_initial_value = data.offsetInitialValue;
          config.offset_increment_value = data.offsetIncrementValue || 0;
          config.limit_query_param = data.limitQueryParam;
          config.limit = data.limitInitialValue;
          break;
        }
        default:
          break;
      }
    }

    if (data.method?.value === 'POST') {
      config.post_body_type = data.postBodyType;
      if (data.postBodyType === PostBodyTypeEnum.FORM_DATA) {
        config.post_body = (data.formData || [])
          .filter((formArr: any[]) => !!formArr[0])
          .reduce((acc, cur) => {
            acc[cur[0]] = cur[1];
            return acc;
          }, {});
      } else {
        config.post_body = data.jsonData;
      }
    }

    return config;
  }

  getConfigFromRawData(sourceConfig: any, metaData: any) {
    let queryParams;
    let headers;
    let postBody;

    try {
      queryParams = JSON.parse(sourceConfig.query_params);
    } catch (e) {
      queryParams = [];
    }

    try {
      headers = JSON.parse(sourceConfig.headers);
    } catch (e) {
      headers = {};
    }

    const config: { [key: string]: any } = {
      url: sourceConfig.url,
      method: REST_API_REQUEST_METHODS.find((method: any) => method.value === sourceConfig.method),
      dataRoot: sourceConfig.data_root,
      authorizationType: sourceConfig.auth_type || AuthType.NO_AUTH,
      paginationType: sourceConfig.pagination_type || PaginationTypesEnum.NO_PAGINATION,
      queryParams: this.buildQueryParamsObj(queryParams),
      headers: this.buildObj(headers),
      postBodyType: sourceConfig.post_body_type || PostBodyTypeEnum.JSON
    };

    if (sourceConfig.auth_type === AuthType.OAUTH2) {
      config.token = this.getTokenFromList(metaData.tokenList, sourceConfig.oauth_token_id);
    } else if (sourceConfig.auth_type === AuthType.BASIC_AUTH) {
      config.authorizationType = AuthType.BASIC_AUTH;
      config.basicAuthUser = sourceConfig.basic_auth_user;
      config.basicAuthPass = sourceConfig.basic_auth_password;
      if (!sourceConfig.basic_auth_user || !sourceConfig.basic_auth_password) {
        config.authorizationType = AuthType.NO_AUTH;
      }
    }

    this.setPaginationSettings(config, sourceConfig);

    if (sourceConfig.post_body_type === PostBodyTypeEnum.FORM_DATA) {
      try {
        postBody = JSON.parse(sourceConfig.post_body);
      } catch (e) {
        postBody = {};
      }
      config.formData = this.buildObj(postBody);
    } else {
      // for creating a default form in case user switch from json to form data.
      config.formData = this.buildObj({});
      config.jsonData = sourceConfig.post_body || '';
    }

    return config;
  }

  buildObj(dataMap: any): any[][] {
    if (!dataMap || !Object.keys(dataMap).length) {
      const dataArr: any[][] = [];
      this.appendDataRow(dataArr, [...DEFAULT_KEY_VALUE_DATA]);
      return dataArr;
    }

    const op: any[][] = getArrayFromObj(dataMap);
    this.appendDataRow(op, [...DEFAULT_KEY_VALUE_DATA]);

    return op;
  }

  setPaginationSettings(config: any, rawData: any) {
    switch (config.paginationType) {
      case PaginationTypesEnum.SESSION: {
        config.isPaginationUrlBased = rawData.is_pagination_url_based || false;

        if (!config.isPaginationUrlBased) {
          config.sessionPageNoQueryParam = rawData.page_query_param;
          config.pageSize = rawData.page_size;
          config.sessionPageResponseField = rawData.page_response_field_param;
          config.nextSessionLastFromPrevResponse = rawData.next_session_last_from_prev_response;
        } else {
          config.nextUrlResponseField = rawData.page_response_field_param;
        }
        break;
      }
      case PaginationTypesEnum.PAGE_NO: {
        config.pageNoQueryParam = rawData.page_query_param;
        config.initialPageNumber =
          !isNaN(parseInt(rawData.start_page_no, 10))
            ? rawData.start_page_no
            : DEFAULT_REST_API_STARTING_PAGE_NO;
        config.pageSize = rawData.page_size;
        if (typeof rawData.extract_page_no_from_response === 'boolean') {
          config.extractPageNoFromResponse = rawData.extract_page_no_from_response;
        } else {
          config.extractPageNoFromResponse = rawData.page_response_field_param;
        }

        if (config.extractPageNoFromResponse) {
          config.pageResponseField = rawData.page_response_field_param;
          config.incrementPageNo = rawData.page_no_increment_for_next_page;
        }
        break;
      }
      case PaginationTypesEnum.LIMIT_OFFSET: {
        config.offsetQueryParam = rawData.offset_query_param;
        config.offsetInitialValue = rawData.offset_initial_value;
        config.offsetIncrementValue = rawData.offset_increment_value;
        config.limitQueryParam = rawData.limit_query_param;
        config.limitInitialValue = rawData.limit;
        break;
      }
      default:
        break;
    }
  }

  buildQueryParamsObj(queryParams: any[]): QueryParam[] {
    let params: QueryParam[] = [];
    if (!queryParams || !queryParams.length) {
      this.appendDataRow(params, { ...DEFAULT_QUERY_PARAMS_DATA });
      return params;
    }

    params = queryParams.map((param: any) => ({
      type: QUERY_PARAM_TYPES.find((type: QueryParamType) => type.value === param.type),
      key: param.key,
      value: param.value,
      format: param.format,
      offsetUnit: OFFSET_UNITS.find((unit: OffsetUnit) => unit.value === param.offset_unit),
      offsetValue: param.offset_value
    }));

    this.appendDataRow(params, { ...DEFAULT_QUERY_PARAMS_DATA });
    return params;
  }

  appendDataRow(dataArr: any[], data: any) {
    dataArr.push(data);
  }

  staticDependenciesResolver(sourceType: string) {
    const redirectUrl = window.location.href;
    return AuthAPI.getAuthTokens(
      SOURCE_TYPES[sourceType].authProvider,
      redirectUrl,
      redirectUrl
    ).then((res: any) => ({
      tokenList: res?.data?.tokens || []
    }));
  }

  getTokenFromList(tokenList: any[], id: number) {
    return tokenList.find((token: any) => token.id === id);
  }
}
