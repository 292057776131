export enum calendarViews {
  MONTH = 'month',
  YEAR = 'year',
  MULTI_YEAR = 'multi-year',
  DAY = 'day'
}

export const viewsMetaData: {
  [key: string]: {
    drillUpView?: calendarViews;
    valueType: calendarViews;
    drillDownView?: calendarViews;
  };
} = {
  [calendarViews.MONTH]: {
    valueType: calendarViews.DAY,
    drillUpView: calendarViews.MULTI_YEAR
  },
  [calendarViews.YEAR]: {
    valueType: calendarViews.MONTH,
    drillUpView: calendarViews.MONTH,
    drillDownView: calendarViews.MONTH
  },
  [calendarViews.MULTI_YEAR]: {
    valueType: calendarViews.YEAR,
    drillUpView: calendarViews.MONTH,
    drillDownView: calendarViews.YEAR
  }
};
