import { useHistory } from '@useHistory/useHistoryCompat';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { RecentEntitiesService } from '../../../app/core/service/recent-entities.service';
import useService from '../../hooks/useService';
import { ActivationStatusService } from '../../../app/core/service/activation-status.service';

export function useDrawerActivationGuard() {
  const history = useHistory();
  const recentEntitiesService = useService(RecentEntitiesService);
  const activationStatusService = useService(ActivationStatusService);

  return () => {
    const shouldShowActivation = activationStatusService.shouldShowActivation();

    if (!shouldShowActivation) {
      history.push('/');
      return false;
    }

    const recentActivation = recentEntitiesService.getRecent(HevoEntity.ACTIVATION.value);
    const recentTarget = recentEntitiesService.getRecent(HevoEntity.ACTIVATION_TARGET.value);

    if (!recentActivation && !recentTarget) {
      history.push('/activation');
      return false;
    }

    return true;
  };
}
