<div
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)"
  #container>

  <div
    class="dialog-content"
    #content
    [hoverTracker]="hoverTrackerAction"
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>

    <div class="dialog-header dialog-header--absolute" tabindex="0">
      <button
        hd-icon-btn
        [hoverTracker]="closeCTAHoverTrackerAction"
        (click)="hide()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <div class="dialog-body">
      <div class="center-flex-row justify-center mb-5">
        <hd-rounded-icon
          [containerBg]="iconContainerClass"
          [iconName]="iconName">
        </hd-rounded-icon>
      </div>

      <span *ngIf="showTitle" class="confirmation-title">
        {{ title }}
      </span>

      <div class="confirmation-body">
        <ng-container *ngIf="data.bodyTemplate">
          <ng-container
            [ngTemplateOutlet]="data.bodyTemplate"
            [ngTemplateOutletContext]="{ $implicit: data.bodyTemplateData }">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="data.bodyComponent">
          <ng-template
            [ngComponentOutlet]="data.bodyComponent"
            [ngComponentOutletInjector]="bodyComponentInjector">
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!data.bodyTemplate && !data.bodyComponent">
          <span [innerHTML]="body"></span>
        </ng-container>
      </div>

      <div
        *ngIf="showConfirmationSubtext"
        class="confirmation-subtext">
        <span class="text-bold">{{ positiveBtnText }}</span>
        if you still want to proceed
      </div>

      <form-error *ngIf="confirmJobError" class="mt-5 mb-0">
        {{ confirmJobError }}
      </form-error>

      <div class="d-flex align-items-start mt-5">
        <button
          type="button"
          hdButton
          variation="outline"
          class="mr-3"
          [hoverTracker]="negativeCTAHoverTrackerAction"
          (click)="onNegativeClick()">
          {{ negativeBtnText }}
        </button>

        <button
          *ngIf="focusPositiveBtnOnRender"
          type="button"
          cdkFocusInitial
          hdButton
          [hoverTracker]="positiveCTAHoverTrackerAction"
          [color]="positiveBtnClass"
          [variation]="positiveBtnVariation"
          [disabled]="runningConfirmJob"
          [showProgress]="runningConfirmJob"
          (click)="onPositiveClick()">
          {{ positiveBtnText }}
        </button>

        <button
          type="button"
          *ngIf="!focusPositiveBtnOnRender"
          hdButton
          [hoverTracker]="positiveCTAHoverTrackerAction"
          [color]="positiveBtnClass"
          [variation]="positiveBtnVariation"
          [disabled]="runningConfirmJob"
          [showProgress]="runningConfirmJob"
          (click)="onPositiveClick()">
          {{ positiveBtnText }}
        </button>
      </div>
    </div>

  </div>
</div>
