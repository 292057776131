// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.formErrorWrapper_rcs62 {\n  display: flex;\n  padding: 8px 12px;\n  border-radius: 6px;\n  align-items: flex-start;\n  color: var(--light-text);\n  background: var(--error-color);\n}\n.formErrorWrapper_rcs62.fadedError_IaMp_ {\n  background: var(--error-color);\n  background: var(--error-faded-color-2);\n  border: solid 1px var(--error-border-color-1);\n  color: var(--error-color);\n}\n.formErrorWrapper_rcs62 .content_M1lRk {\n  flex: 1;\n  align-self: center;\n  word-wrap: break-word;\n  min-width: 0;\n  font-weight: 600;\n  text-align: left;\n}\n.formErrorWrapper_rcs62 .thumbnail_SHmUj {\n  margin-right: 12px;\n  flex-shrink: 0;\n  font-size: 20px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formErrorWrapper": "formErrorWrapper_rcs62",
	"fadedError": "fadedError_IaMp_",
	"content": "content_M1lRk",
	"thumbnail": "thumbnail_SHmUj"
};
export default ___CSS_LOADER_EXPORT___;
