import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { SharedModule } from '../shared/shared.module';
import { AlertSnackbarComponent } from './alert-snackbar/alert-snackbar.component';
import { SnackbarActionDirective } from './snackbar/snackbar-action.directive';
import { SnackbarThumbnailDirective } from './snackbar/snackbar-thumbnail.directive';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TooltipModule } from '../tooltip/tooltip.module';

const DIRECTIVES = [
  SnackbarComponent,
  AlertSnackbarComponent,
  SnackbarThumbnailDirective,
  SnackbarActionDirective
];

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        SharedModule,
        TooltipModule
    ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class SnackbarModule {
}
