import { Injectable } from '@angular/core';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';

import { ToasterService } from '../../toaster/toaster.service';

@Injectable()
export class ClipboardResponseService {
  constructor(
    private _clipboardService: ClipboardService,
    private _toasterService: ToasterService
  ) {
    this.handleClipboardResponse();
  }

  handleClipboardResponse() {
    this._clipboardService.copyResponse$.subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this._toasterService.pop('success', undefined, res.successMessage);
      }
    });
  }
}
