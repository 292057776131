import React, { useState } from 'react';
import { HdMenu, HdMenuItem } from '../../../../../components/UIElements';
import HdMenuTrigger from '../../../../../components/UIElements/HdMenuTrigger';

export function FilterMenu({ filters, updateFilter }) {
  /**
   * to maintain menu states
   */
  const [authorExpanded, setAuthorExpanded] = useState(false);
  const [severityExpanded, setSeverityExpanded] = useState(false);

  const toggleAuthorMenuVisibility = () => {
    setAuthorExpanded(prev => !prev);
  };

  const toggleSeverityMenuVisibility = () => {
    setSeverityExpanded(prev => !prev);
  };

  const setMenuItem = (selectedFilterIndex, selected) => {
    if (selectedFilterIndex === 0) {
      setAuthorExpanded(false);
    }
    if (selectedFilterIndex === 1) {
      setSeverityExpanded(false);
    }
    updateFilter(selectedFilterIndex, selected);
  };

  const [authorList, severityList] = filters;

  return (
    <>
      <HdMenuTrigger
        onForceClose={() => {
          setAuthorExpanded(false);
        }}
        id='author-filter'
        dataId='author-list'
        onClick={toggleAuthorMenuVisibility}
      >
        Author
      </HdMenuTrigger>

      <HdMenu
        target='#author-filter'
        open={authorExpanded}
        onClose={() => setAuthorExpanded(false)}
      >
        {authorList.options.map(author => (
          <HdMenuItem
            key={author.name}
            selected={authorList.isOptionActive(author)}
            onClick={() => setMenuItem(0, author)}
          >
            {author.name}
          </HdMenuItem>
        ))}
      </HdMenu>

      <HdMenuTrigger
        onForceClose={() => {
          setSeverityExpanded(false);
        }}
        id='severity-filter'
        dataId='severity-list'
        onClick={toggleSeverityMenuVisibility}
      >
        Severity
      </HdMenuTrigger>

      <HdMenu
        target='#severity-filter'
        open={severityExpanded}
        onClose={() => setSeverityExpanded(false)}
      >
        {severityList.options.map(severity => (
          <HdMenuItem
            key={severity.name}
            selected={severityList.isOptionActive(severity)}
            onClick={() => setMenuItem(1, severity)}
          >
            {severity.name}
          </HdMenuItem>
        ))}
      </HdMenu>
    </>
  );
}
