import { FieldProps, getIn } from 'formik';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import {
  preventEnteringParticularChar,
  preventEnteringSpecialChar
} from '../../../utils/customValidator';
import { FormikHelperText } from '../../../utils/FormikHelperText';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdTextField } from '../../UIElements/HdTextField';
import useDebounce from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_TIME } from '../../../../app/core/constants';

interface InputFieldInterface {
  id: string;
  label: string;
  placeholder?: string;
  variant: 'outlined' | 'standard' | 'filled';
  helperText?: React.ReactNode;
  className?: string;
  type?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  prefixIcon?: string;
  prefixText?: string;
  suffixText?: string;
  onBlur?: Function;
  debounceUpdates?: boolean;
  onChange?: Function;
  onFocus?: Function;
  hideHelperMessageIfEmpty?: boolean;
  multiline?: boolean;
  disallowChar?: string;
  dataId?: string;
}

// eslint-disable-next-line react/function-component-definition
const HdFormikTextField: React.FC<InputFieldInterface & FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  placeholder,
  id,
  variant = 'outlined',
  helperText = '',
  suffixText,
  prefixIcon,
  prefixText,
  startAdornment,
  hideHelperMessageIfEmpty = false,
  endAdornment,
  onBlur,
  debounceUpdates = false,
  onChange,
  onFocus,
  disallowChar,
  multiline = false,
  dataId,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string>(field.value || '');
  const debouncedInputValue = useDebounce(inputValue, QUERY_DEBOUNCE_TIME);
  const showError =
    (touched[field.name] && !!errors[field.name]) ||
    (getIn(errors, field.name) && getIn(touched, field.name));

  useEffect(() => {
    if (debouncedInputValue !== field.value) {
      setInputValue(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    if (debounceUpdates && debouncedInputValue !== field.value) {
      setFieldValue(field.name, debouncedInputValue);
      if (onChange) {
        onChange(debouncedInputValue);
      }
    }
  }, [debounceUpdates, debouncedInputValue, field.name]);
  let conditionalProps = {};

  if (props.type === 'number') {
    conditionalProps = { onKeyDown: preventEnteringSpecialChar };
  }

  if (disallowChar) {
    conditionalProps = { onKeyDown: event => preventEnteringParticularChar(event, disallowChar) };
  }

  const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const onChangeEvent = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    if (!debounceUpdates) {
      try {
        setFieldValue(field.name, e.target.value);
      } catch (err) {
        console.log(err);
      }
      if (onChange) {
        onChange(e);
      }
    }
  };

  const onFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const dataIdGenerator = useCallback(
    getDataIdGenerator(`${dataId ? `${dataId}-` : ''}${field.name}`, 'textField'),
    [field.name]
  );

  return (
    <HdTextField
      id={id || field.name}
      label={label}
      placeholder={placeholder || label}
      variant={variant}
      helperText={FormikHelperText({
        helperText,
        showError,
        hideHelperMessageIfEmpty,
        fieldName: field.name,
        errorMessage: getIn(errors, field.name)
      })}
      {...field}
      {...props}
      dataId={dataIdGenerator('')}
      InputProps={{
        startAdornment,
        inputProps: { ...props },
        endAdornment
      }}
      value={sanitiseInput(inputValue)}
      error={showError}
      autoComplete='off'
      className={props.className}
      onBlur={onBlurEvent}
      onChange={onChangeEvent}
      onFocus={onFocusEvent}
      multiline={multiline}
      suffixText={suffixText}
      prefixIcon={prefixIcon}
      prefixText={prefixText}
      {...conditionalProps}
    />
  );
};

export default HdFormikTextField;

export const shouldUpdateFormikTextFastField = (nextProps, currentProps) =>
  nextProps.name !== currentProps.name ||
  nextProps.required !== currentProps.required ||
  nextProps.disabled !== currentProps.disabled ||
  nextProps.maxLength !== currentProps.maxLength ||
  nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
  Object.keys(nextProps).length !== Object.keys(currentProps).length ||
  getIn(nextProps.formik.values, currentProps.name) !==
    getIn(currentProps.formik.values, currentProps.name) ||
  getIn(nextProps.formik.errors, currentProps.name) !==
    getIn(currentProps.formik.errors, currentProps.name) ||
  getIn(nextProps.formik.touched, currentProps.name) !==
    getIn(currentProps.formik.touched, currentProps.name);

const sanitiseInput = (value: string | number) => typeof value === 'number' ? value : (value || '')
