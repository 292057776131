import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[flexibleAutoCompleteOrigin]',
  exportAs: 'flexibleAutoCompleteOrigin',
})
export class FlexibleAutoCompleteOrigin {
  constructor(
    /** Reference to the element on which the directive is applied. */
    public elementRef: ElementRef<HTMLElement>) { }
}
