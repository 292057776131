import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalBlockingLoaderService } from '../../core/service/global-blocking-loader.service';

@Component({
  selector: 'global-blocking-loader',
  templateUrl: './global-blocking-loader.component.html',
  styleUrls: [ './global-blocking-loader.component.scss' ]
})
export class GlobalBlockingLoaderComponent implements OnInit, OnDestroy {
  show = false;

  private _destroyed$ = new Subject<void>();

  constructor(
    private _loaderService: GlobalBlockingLoaderService
  ) {
  }

  ngOnInit() {
    this._loaderService.loaderState.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((state) => {
      this.show = state.show;
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
