import { Component, ElementRef, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { LogExpiryHandlerDialog, LogExpiryHandlerDialogProps } from '../../react/components/Dialog/LogExpiryHandlerDialog';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'log-expiry-handler-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class LogExpiryHandlerDialogReactComponent implements OnChanges {
  @Input() open: boolean;
  @Input() taskName: string;
  @Input() failedTasks: string[];
  @Input() integrationId: number;
  @Input() sourceType: string;

  @Output() onClose = new EventEmitter<void>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount<LogExpiryHandlerDialogProps>(
      LogExpiryHandlerDialog,
      this._el.nativeElement,
      {
        open: this.open,
        onClose: this.onClose.emit.bind(this.onClose),
        taskName: this.taskName,
        failedTasks: this.failedTasks,
        integrationId: this.integrationId,
        sourceType: this.sourceType
      }
    );
  }
}
