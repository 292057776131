// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.refreshAdornment_Zgn6E {\n  width: 100%;\n  padding: 12px 16px;\n  display: flex;\n  cursor: pointer;\n}\n\n.errorCaption_BU8qW {\n  color: var(--error-color) !important;\n}\n\n.paddedHdMessage_ka1Uk {\n  font-weight: 600;\n  padding: 12px 16px;\n}\n\n.checkAgainButton_aLVw_ {\n  padding: 4px 8px;\n  margin-top: -2px;\n  background: var(--experiment-btn-bg-color);\n  color: var(--text-primary-color);\n  border: 1px solid var(--divider-color);\n}\n\n.checkAgainButton_aLVw_:hover {\n  background: var(--surface-bg-color) !important;\n}\n\n.animatedLoadingIcon_fZlxK {\n  animation: spin_l719g 2s linear infinite;\n}\n\n@keyframes spin_l719g {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshAdornment": "refreshAdornment_Zgn6E",
	"errorCaption": "errorCaption_BU8qW",
	"paddedHdMessage": "paddedHdMessage_ka1Uk",
	"checkAgainButton": "checkAgainButton_aLVw_",
	"animatedLoadingIcon": "animatedLoadingIcon_fZlxK",
	"spin": "spin_l719g"
};
export default ___CSS_LOADER_EXPORT___;
