import { Typography } from '@mui/material';
import React from 'react';
import HdLink from '../UIElements/HdLink';
import styles from './styles.module.scss';

interface OptionInterface {
  option: { name: string; subtext?: string; desc?: string; url?: string };
  subtextKey?: string;
  descKey?: string;
  [key: string]: any;
}

// eslint-disable-next-line react/function-component-definition
const CustomOptionWithSubtext: React.FC<OptionInterface> = ({ option, subtextKey, descKey }) => {
  const { name, subtext, desc, url } = option;

  return (
    <>
      <Typography variant='body2' component='span' className='text-default'>
        {name}{' '}
        {desc || typeof option[descKey] === 'string'
          ? `- ${typeof option[descKey] === 'string' ? option[descKey] : desc}`
          : ''}
      </Typography>

      {subtext || typeof option[subtextKey] === 'string' ? (
        <Typography variant='body2' component='span' className={styles.textSecondary}>
          • {typeof option[subtextKey] === 'string' ? option[subtextKey] : subtext}
        </Typography>
      ) : null}

      {url ? (
        <HdLink href={url} icon='new-window' target='_blank' dataId='dropdown-custom-option' />
      ) : null}
    </>
  );
};

export default CustomOptionWithSubtext;
