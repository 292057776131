import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subject, timer } from 'rxjs';
import { catchError, filter, share, startWith, switchMap, tap } from 'rxjs/operators';
import { UsageAlertData } from '../../../react/containers/payment/models/usage-status';
import { UsageAlertDataFactory } from '../../../react/containers/payment/utils/usage-alert';
import { USAGE_STATUS_DATA_POLL_INTERVAL, USAGE_STATUS_KEY } from '../constants';
import { User } from '../models/user';
import { muteFirst } from '../../../react/legacy-utils/rxjs-observables';
import { AuthService } from './auth.service';
import { PaymentService } from './payment.service';
import { SettingsStorageService } from './settings-storage.service';
import { UserService } from './user.service';


@Injectable({ providedIn: 'root' })
export class UsageAlertService {
  private _usageAlert$ = new BehaviorSubject<UsageAlertData>(null);
  private _needUsageAlertData$ = new BehaviorSubject(true);
  private _goToCreditSection$ = new Subject();

  private _user: User;

  private _usageAlertDataEffect$ = this._needUsageAlertData$.pipe(
    filter(require => require),
    switchMap(() => {
      return timer(0, USAGE_STATUS_DATA_POLL_INTERVAL).pipe(switchMap(() => {
        return this._paymentService.getUsageAlert().pipe(
          tap((rawData: any) => {
            this._usageAlert$.next(UsageAlertDataFactory(rawData.data));
          }),
          catchError((err) => {
            return of(err);
          })
        );
      }));
    }),
    startWith(null),
    share()
  );

  private _clearDataEffect$ = this._authService.beforeLogoutSubject.pipe(
    tap(() => {
      this._usageAlert$.next(null);
    }),
    share(),
    startWith(null)
  );

  public usageAlert$ = muteFirst(
    combineLatest([
      this._usageAlertDataEffect$,
      this._clearDataEffect$
    ]),
    this._usageAlert$
  );

  public goToCreditSection$ = this._goToCreditSection$.asObservable();

  constructor(
    private _paymentService: PaymentService,
    private _storageService: SettingsStorageService,
    private _userService: UserService,
    private _authService: AuthService
  ) {
    this._user = this._userService.getUser();
  }

  storeStatusLocally() {
    this._storageService.applySettings(USAGE_STATUS_KEY, this._usageAlert$.getValue().status);
  }

  getStoredStatus(): string {
    return this._storageService.getSettings(USAGE_STATUS_KEY);
  }

  clearUsageAlertData() {
    this._usageAlert$.next(null);
  }

  fetchUsageAlertData() {
    this._needUsageAlertData$.next(true);
  }

  goToCreditSection() {
    this._goToCreditSection$.next();
  }
}
