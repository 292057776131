import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DestinationTypeConfigHelper } from '../../../../../../app/nodes/destination-settings-form/destination-type-config-helper';
import { S3_BUCKET_REGIONS } from '../../../../../../app/nodes/nodes-settings/s3-base/constants';
import { S3_DEST_FILE_FORMATS } from './constants';

export class DestinationS3ConfigHelper implements DestinationTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      awsAccessKey: rawData.aws_access_key,
      awsSecretAccessKey: rawData.aws_secret_key,
      bucket: rawData.s3_bucket,
      prefix: rawData.s3_path_prefix,
      gzip: coerceBooleanProperty(rawData.should_gzip_file),
      bucketRegion: S3_BUCKET_REGIONS.find((region: any) => {
        return region.value === rawData.s3_bucket_region;
      }),
      fileFormat: S3_DEST_FILE_FORMATS.find((format: any) => {
        return format.value === rawData.file_type;
      })
    };
  }

  getDefaultConfig() {
    return {
      gzip: true
    };
  }

  buildConnectionParams(data: any) {
    const params: any = {
      aws_access_key: data.awsAccessKey,
      aws_secret_key: data.awsSecretAccessKey,
      s3_bucket: data.bucket,
      s3_path_prefix: data.prefix || undefined,
      s3_bucket_region: data.bucketRegion?.value,
      should_gzip_file: !!data.gzip,
      file_type: data.fileFormat ? data.fileFormat.value : undefined,
    };

    return params;
  }
}
