import { Injectable } from '@angular/core';
import { isReactFeature, ReactFeatureFlag } from '../../core/models/ansible-config';

@Injectable({ providedIn: 'root' })
export class ShortcutsDialogHandlerReactService {
  private _keyboardShortcutsDialogOpen = false;
  private _advancedGlobalSearchDialogOpen = false;

  isKeyboardShortcutDialogOpen() {
    return this._keyboardShortcutsDialogOpen;
  }

  closeKeyboardShortcutDialog() {
    this._keyboardShortcutsDialogOpen = false;
  }

  openKeyboardShortcutDialog() {
    this._keyboardShortcutsDialogOpen = true;
  }

  closeGlobalSearchDialog() {
    this._advancedGlobalSearchDialogOpen = false;
  }

  openGlobalSearchDialog() {
    this._advancedGlobalSearchDialogOpen = true;
  }

  isAdvancedGlobalSearchDialogOpen() {
    return this._advancedGlobalSearchDialogOpen;
  }
}
