import React, { useEffect, useState } from 'react';
import { TroubleshootGuideService } from '../../../../app/core/service/troubleshoot-guide.service';
import { TROUBLESHOOT_GUIDE_DRAWER_CLOSE_CLICK } from './constants';
import { TroubleshootGuide } from '../../../components/Docs/TroubleshootGuide';
import { HdIcon, HdIconButton } from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useService from '../../../hooks/useService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { DrawerWrapper } from '../DrawerWrapper';
import { ReactDrawerBaseProps } from '../interface';
import { useHideDrawer } from '../useHideDrawer';
import styles from './styles.module.scss';

export function TroubleshootDrawer({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const [ troubleshootLink, setTroubleshootLink ] = useState(null);

  const { hideDrawer } = useHideDrawer();
  const { eventTrack } = useAnalyticsTracker();

  const troubleshootGuideService = useService(TroubleshootGuideService);

  const hide = () => {
    troubleshootGuideService.setTroubleshootVisibleStatus(false);
    hideDrawer();
  };

  const onCloseClick = () => {
    eventTrack({
      action: TROUBLESHOOT_GUIDE_DRAWER_CLOSE_CLICK,
      properties: {
        docLink: troubleshootLink
      }
    });

    hide();
  };

  useEffect(() => {
    const sub = troubleshootGuideService.troubleshootLink$.subscribe((link) => {
      setTroubleshootLink(link);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const dataIdGenerator = getDataIdGenerator('troubleshoot-guide-drawer');

  return (
    <DrawerWrapper
      className={styles.troubleshootDrawer}
      drawerDirection={drawerDirection}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      springValues={springValues}
      hide={hide}
    >
      <div className='py-4 px-5'>
        <div className='center-flex-row justify-between'>
          <div className='drawer-title text-transform-none'>
            Troubleshooting Guide
          </div>

          <HdIconButton
            dataId={dataIdGenerator('close')}
            className='ml-3'
            onClick={onCloseClick}
          >
            <HdIcon name='close' size={2} />
          </HdIconButton>
        </div>
      </div>

      <div className='drawer-body'>
        <TroubleshootGuide
          docLink={troubleshootLink}
        />
      </div>
    </DrawerWrapper>
  );
}
