import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { takeUntil } from 'rxjs/operators';
import { MapperService } from '../../core/service/mapper.service';
import { RefreshService } from '../../core/service/refresh.service';
import { getSuccessMessageFromObj } from '../../../react/legacy-utils/request';
import { DESTINATION_TYPES } from '../../nodes/destination-type/model';
import { SourceObject } from '../../pipeline/source-objects/models/source-object';
import { FIREBOLT_MAP_EVENTS_DOC_LINK } from '../../schema-builder/constants';
import { AUTO_MAPPING_DOC_LINK } from '../../shared/automapping-control-pane/constants';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { SidelineMessage } from '../../sideline/models/sideline-message';
import { fadeAnim, popupAnim } from '../animations';

import { DialogBase } from '../dialog-base';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import {
  TRACKING_MAP_FAILED_EVENTS_DIALOG_CLOSE_CLICK,
  TRACKING_MAP_FAILED_EVENTS_DIALOG_DOC_LINK_CLICK,
  TRACKING_MAP_FAILED_EVENTS_DIALOG_OBJECT_AUTOMAP_CLICK,
  TRACKING_MAP_FAILED_EVENTS_DIALOG_PIPELINE_AUTOMAP_CLICK,
  TRACKING_MAP_FAILED_EVENTS_DIALOG_SECONDARY_CLICK
} from './constants';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'auto-map-failed-events-dialog',
  templateUrl: './auto-map-failed-events-dialog.component.html',
  styleUrls: [ './auto-map-failed-events-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class AutoMapFailedEventsDialogComponent extends DialogBase implements OnInit {
  pipeline: any;
  event: SidelineMessage;
  sourceObject: SourceObject;
  successMsg: string;
  error: any;

  autoMappingDocLink = AUTO_MAPPING_DOC_LINK;
  fireboltMapEventsDocLink = FIREBOLT_MAP_EVENTS_DOC_LINK;
  runningReq = false;
  isFireboltDest = false;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: any,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _router: Router,
    private _refreshService: RefreshService,
    private _mapperService: MapperService,
    private _angulartics: Angulartics2
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  show() {
    super.show();
    this.pipeline = this._data.pipeline;
    this.isFireboltDest = this.pipeline.dest_type === DESTINATION_TYPES['FIREBOLT'].name;
    this.event = this._data.event;
    this.sourceObject = this._data.object;
  }

  autoMapObject() {
    this.runningReq = true;
    if (!this.sourceObject) {
      this._angulartics.eventTrack.next({
        action: TRACKING_MAP_FAILED_EVENTS_DIALOG_PIPELINE_AUTOMAP_CLICK
      });

      if (this.pipeline?.auto_mapping_status === 'MAP_ALL') {
        this.successMsg = 'Auto Mapping is already enabled for this pipeline.';
        this.runningReq = false;
      } else if (!(this.pipeline?.auto_mapping_status === 'MAP_ALL')) {
        this._refreshService.refreshMappingRequest();
        this._refreshService.sendAutoMappingAPIResponse$.subscribe((res) => {
          if (res.success) {
            this.successMsg = getSuccessMessageFromObj(res.success);
          } else {
            this.error = res.error;
          }
          this.runningReq = false;
        });
      }

    } else {
      this._angulartics.eventTrack.next({
        action: TRACKING_MAP_FAILED_EVENTS_DIALOG_OBJECT_AUTOMAP_CLICK
      });

      this._mapperService.updateObjectTypeAutoMappingStatus(
        this.pipeline?.id,
        this.sourceObject.id
      ).pipe(
        takeUntil(this._destroyed$)
      ).subscribe((res) => {
          this.successMsg = getSuccessMessageFromObj(res);
          this.runningReq = false;
        },
        (err) => {
          this.error = err;
          this.runningReq = false;
        });
    }
  }

  autoMapPipeline() {
    this._angulartics.eventTrack.next({
      action: TRACKING_MAP_FAILED_EVENTS_DIALOG_PIPELINE_AUTOMAP_CLICK
    });

    this.runningReq = true;
    if (this.pipeline?.auto_mapping_status === 'MAP_ALL') {
      this.runningReq = false;
      this.hide();
    } else if (!(this.pipeline?.auto_mapping_status === 'MAP_ALL')) {
      this._refreshService.refreshMappingRequest();
      this._refreshService.sendAutoMappingAPIResponse$.subscribe((res) => {
        if (res.success) {
          this.successMsg = getSuccessMessageFromObj(res.success);
        } else {
          this.error = res.error;
        }
        this.runningReq = false;
        this.hide();
      });
    }
  }

  onSecondaryClick() {
    this._angulartics.eventTrack.next({
      action: TRACKING_MAP_FAILED_EVENTS_DIALOG_SECONDARY_CLICK
    });

    this.hide();
    this._router.navigate(
      [ 'pipeline', this.pipeline?.seq_id, 'schema-mapper', 'details' ],
      {
        queryParams: {
          event: this.event?.schemaName,
          events_page: 0,
          events_status_group: 'UNMAPPED',
          dialog_click: true
        }
      }
    );
  }

  onNegativeClick() {
    this._angulartics.eventTrack.next({
      action: TRACKING_MAP_FAILED_EVENTS_DIALOG_CLOSE_CLICK
    });
    this.hide();
  }

  clickTracking() {
    this._angulartics.eventTrack.next({
      action: TRACKING_MAP_FAILED_EVENTS_DIALOG_DOC_LINK_CLICK
    });
  }

  resetDialog() {
    this.error = undefined;
    this.successMsg = undefined;
  }
}
