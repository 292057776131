import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { RxRequestService } from './rx-request.service';

export let _globalConfigService;

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService {
  globalConfig: { [key: string]: any } = {};

  private _globalConfigEffect$ = this._requestService.get(
    this._appConfig.getGlobalConfigURL(),
    {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false
      }
    }
  ).pipe(
    map((res) => {
      this.globalConfig = res.data;
    }),
    share()
  );

  constructor(
    private _requestService: RxRequestService,
    private _appConfig: AppConfig
  ) {
    _globalConfigService = this;
  }

  getConfig<T>(key: string): Observable<T> {
    const existingConfig: T = this.globalConfig[key];
    if (typeof existingConfig !== 'undefined') {
      return of(existingConfig);
    }

    return this._globalConfigEffect$.pipe(
      map(() => {
        return this.globalConfig[key];
      })
    );
  }
}
