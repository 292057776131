<div
  class="hd-icon-btn-ripple"
  mat-ripple
  [matRippleTrigger]="_getHostElement()"
  [matRippleCentered]="true"
  [matRippleAnimation]="{enterDuration: 200}">
</div>

<div class="hd-icon-btn-overlay"></div>

<ng-content></ng-content>
