import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AnsibleBoolean } from '../../core/models/ansible-config';
import { DialogOverlayService } from '../../dialog/dialog-overlay.service';
import { ProductTourDialogData } from './interface';
import { ProductTourDialogReactComponent } from './product-tour-dialog-react.component';
import { ProductTourDialogComponent } from './product-tour-dialog.component';

declare const __AUTOMATION_TEST_ENABLED__: AnsibleBoolean;
export interface ProductTourQueueItem {
  data: ProductTourDialogData;
  _onClose$: Subject<void>;
  onClose: () => Observable<void>;
  _onOpen$: Subject<void>;
  onOpen: () => Observable<void>;
}

@Injectable()
export class ProductTourDialogService {

  queue: ProductTourQueueItem[] = [];

  constructor(
    private _dialogOverlayService: DialogOverlayService
  ) { }

  start(data: ProductTourDialogData) {
    if (!data || !data.steps || !data.steps.length) {
      return;
    }

    const queueItem = this.createQueueItem(data);

    if (this.queue.length === 0) {
      this.showDialog(queueItem);
    }
    this.queue.push(queueItem);

    return queueItem;
  }

  createQueueItem(data: ProductTourDialogData) :  ProductTourQueueItem {
    return ({
      data,
      _onClose$: new Subject<void>(),
      onClose: function () { return this._onClose$.asObservable() },
      _onOpen$: new Subject<void>(),
      onOpen: function () { return this._onOpen$.asObservable() },
     });
  }


  showDialog(queueItem: ProductTourQueueItem) {

    if (__AUTOMATION_TEST_ENABLED__ === AnsibleBoolean.TRUE) {
      return;
    }

    const dialogRef = this._dialogOverlayService.open(
      queueItem.data?.renderReact ? ProductTourDialogReactComponent : ProductTourDialogComponent,
      queueItem.data,
      {
        hasBackdrop: false
      }
    );

    dialogRef.overlayRef.hostElement.classList.add('product-tour-overlay-wrapper');

    dialogRef.onClose().subscribe(res => {
      queueItem._onClose$.next(res);
      this.queue.shift();
      if (this.queue.length > 0) {
        this.showDialog(this.queue[0]);
      }
    });

    dialogRef.onOpen().subscribe(res=> {
      queueItem._onOpen$.next(res);
    });

    return dialogRef;
  }

  destroy(item: ProductTourQueueItem) {
    const itemIndex = this.queue.findIndex((queueItem) => queueItem === item);
    if (itemIndex === -1) {
      return;
    }

    this.queue.splice(itemIndex, 1);
  }
}

