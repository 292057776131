import React, { useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import HdFormikTextField from '../../../../../components/FormikElements/HdFormikTextField';
import HdFormikPasswordField from '../../../../../components/FormikElements/HdFormikPasswordField';
import DestinationsAPI from '../../../DestinationsAPI';
import { ConfigByType } from '../configByType';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import HdFormikDropDown from '../../../../../components/FormikElements/HdFormikDropDown';
import { LoadedAt } from '../../../../../components/NodeConfigOptions';
import CustomOptionWithSubtext from '../../../../../components/DropDownCustomOptions/CustomOptionWithSubtext';
import { ConfigDestinationTypeBaseProps } from '../interface';

interface DatabaseUser {
  name: string;
  id: string;
}

interface Engine {
  name: string;
  id: string;
  region: string;
}

interface BucketRegion {
  name: string;
  value: string;
}

export default function FireBolt({
  setFormError,
  isEditing,
  maskSecretFields
}: ConfigDestinationTypeBaseProps) {
  const formikProps = useFormikContext<{
    username: string;
    password: string;
    database: DatabaseUser;
    engine: Engine | null;
    bucketRegion: BucketRegion | null;
  }>();

  const [databases, setDatabases] = useState([]);
  const [engines, setEngines] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGetRegions = useMemo(ConfigByType.FIREBOLT.regionList, []);

  useEffect(() => {
    getFireboltDatabasesAndEngines();
  }, []);

  const getFireboltDatabasesAndEngines = async () => {
    if (!formikProps.values.username || !formikProps.values.password) {
      return;
    }
    try {
      const { data } = await DestinationsAPI.getFireboltDatabasesAndEngines(
        formikProps.values.username,
        formikProps.values.password
      );
      setDatabases(data.databases);
      setEngines(data.engines);
    } catch (error) {
      setFormError(error);
    }
  };

  const onDatabaseSelect = async (dbUser: DatabaseUser | null) => {
    if (!dbUser) {
      return;
    }
    try {
      const { data } = await DestinationsAPI.getFireboltDefaultEngine(
        formikProps.values.username,
        formikProps.values.password,
        dbUser.name
      );
      // If we have default engine for the database, we find the engine ID in the engines state array and set the value in formik
      if (data) {
        const engine = engines.find((eg: DatabaseUser) => eg.id === data);
        if (engine) {
          formikProps.setFieldValue('engine', engine);
          const bucketRegions = ConfigByType.FIREBOLT.regionList();
          const engineRegion = bucketRegions.find(region => region.value === engine.region);
          if (engineRegion) {
            formikProps.setFieldValue('bucketRegion', engineRegion);
          }
        }
      }
    } catch (error) {
      setFormError(error);
    }
  };

  const resetDatabaseAndEngine = () => {
    setDatabases([]);
    setEngines([]);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='username'
              label='Username'
              helperText='The Username of your Firebolt account.'
              component={HdFormikTextField}
              required
              onChange={e => {
                formikProps.setValues({
                  ...formikProps.values,
                  username: e.target.value,
                  database: null,
                  engine: null
                });
                resetDatabaseAndEngine();
              }}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='password'
              label='Password'
              helperText='The Password of your Firebolt account.'
              component={HdFormikPasswordField}
              required
              masked={maskSecretFields}
              onChange={e => {
                formikProps.setValues({
                  ...formikProps.values,
                  password: e.target.value,
                  database: null,
                  engine: null
                });
                resetDatabaseAndEngine();
              }}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='database'
              options={databases}
              required
              label='Database Name'
              helperText='Database which you want to connect'
              component={HdFormikDropDown}
              asyncMethod={getFireboltDatabasesAndEngines}
              onChangeEventHandler={selected => {
                formikProps.setValues({
                  ...formikProps.values,
                  database: selected,
                  engine: null,
                  bucketRegion: null
                });
                onDatabaseSelect(selected);
              }}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='engine'
              options={engines}
              required
              label='Engine Name'
              helperText='Use an Engine which is connected to your Firebolt database'
              component={HdFormikDropDown}
              asyncMethod={getFireboltDatabasesAndEngines}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='bucket'
              label='Bucket Name'
              helperText='The S3 bucket that you want to use with Firebolt'
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='bucketRegion'
              options={memoizedGetRegions}
              required
              label='Bucket Region'
              helperText='The region of your AWS S3 bucket'
              component={HdFormikDropDown}
              CustomOption={CustomOptionWithSubtext}
              valueAccessor={option => option?.value}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='awsAccessKey'
              label='Access Key ID'
              helperText={`The ID of your AWS S3 bucket's access key`}
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={6}>
          <HdFormControl>
            <Field
              name='awsSecretAccessKey'
              label='Secret Access Key'
              helperText='The access key of your AWS S3 bucket'
              required
              masked={maskSecretFields}
              component={HdFormikPasswordField}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <AdvancedConfig showBorderBottom>
        <LoadedAt disabled={!!isEditing} />
      </AdvancedConfig>
    </>
  );
}
