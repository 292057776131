import React from 'react';
import { getArrayFromSize } from '../../legacy-utils/array';
import {
  CRON_DAYS,
  CRON_TYPE_DAILY,
  CRON_TYPE_WEEKLY,
  DEFAULT_CRON_TYPE,
  DEFAULT_FREQUENCY_CHIPS,
  DEFAULT_FREQUENCY_TYPE,
  DEFAULT_FREQUENCY_VALUE,
  DEFAULT_MAX_FREQUENCY,
  DEFAULT_MIN_FREQUENCY,
  FREQUENCY_TYPE_HOURS,
  FREQUENCY_TYPE_MINUTES,
  FREQUENCY_TYPES,
  STRICT_CRON_TYPES
} from '../../../app/dialog/execution-policy-dialog/constants';
import {
  CronTime,
  PolicyTypeEnum,
  FrequencyType,
  CronDay,
  CronType,
  FrequencyChip
} from '../../../app/dialog/execution-policy-dialog/models';
import { Policy } from './models';

export const ExecutionPolicyContext = React.createContext({
  tooltipData: {
    message: '',
    showTooltip: false
  },
  setTooltipData: prev => {}
});

export function frequencyOptionFactory(
  value: number,
  frequencyType: FrequencyType,
  displayText: string,
  disabled = false
): FrequencyChip {
  return {
    value,
    frequencyType,
    displayText,
    disabled
  };
}

export function getExecutionPolicyFromRawData(
  rawData: any,
  frequencyConfig?: any,
  defaultFrequencyChips = [...DEFAULT_FREQUENCY_CHIPS],
  displayedFrequencies = [...DEFAULT_FREQUENCY_CHIPS]
): Policy {
  const policyObj: Policy = {
    id: null,
    message: '',
    status: '',
    type: PolicyTypeEnum.NO_SCHEDULE,
    frequencyType: DEFAULT_FREQUENCY_TYPE,
    frequencyValue: DEFAULT_FREQUENCY_VALUE,
    minFrequency: DEFAULT_MIN_FREQUENCY,
    maxFrequency: DEFAULT_MAX_FREQUENCY,
    cronType: DEFAULT_CRON_TYPE,
    cronDays: [],
    cronTime: null,
    cronTimeList: [],
    destinationId: null,
    destTables: [],
    suggestedFrequencies: defaultFrequencyChips,
    displayedFrequencies,
    suggestedCronTimeList: [],
    isDailyCronConfigurable: false,
    showStreamingOption: false,
    streamingScheduleEnabled: false
  };

  if (typeof frequencyConfig === 'object' && frequencyConfig !== null) {
    if (frequencyConfig.min_frequency) {
      policyObj.minFrequency = frequencyConfig.min_frequency;
    }

    if (frequencyConfig.max_frequency) {
      policyObj.maxFrequency = frequencyConfig.max_frequency;
    }

    if (!rawData?.frequency_value && frequencyConfig.default_frequency) {
      policyObj.frequencyValue = frequencyConfig.default_frequency;
    }

    if (frequencyConfig.is_daily_cron_configurable) {
      policyObj.isDailyCronConfigurable = true;
    }

    if (frequencyConfig.suggested_frequencies) {
      policyObj.suggestedFrequencies = frequencyConfig.suggested_frequencies.map(frequency =>
        getFrequencyOption(frequency)
      );
      policyObj.displayedFrequencies = [...policyObj.suggestedFrequencies];
    }

    if (frequencyConfig.show_streaming_option) {
      policyObj.showStreamingOption = frequencyConfig.show_streaming_option;
    }

    if (frequencyConfig.streaming_schedule_enabled) {
      policyObj.streamingScheduleEnabled = frequencyConfig.streaming_schedule_enabled;
    }
  }

  if (!rawData || !Object.keys(rawData).length || rawData.type === PolicyTypeEnum.NO_SCHEDULE) {
    policyObj.type = PolicyTypeEnum.NO_SCHEDULE;
    // Defaults for when model and workflow are currently not scheduled
    policyObj.frequencyType = FREQUENCY_TYPE_HOURS;
    policyObj.frequencyValue = 1;
    return policyObj;
  }

  policyObj.id = rawData.id;
  policyObj.status = rawData.status;
  policyObj.message = rawData.message;
  policyObj.suggestedCronTimeList = getDefaultCronTimeList();

  if (rawData.type === PolicyTypeEnum.FREQUENCY) {
    policyObj.type = PolicyTypeEnum.FREQUENCY;

    if (!rawData.frequency_type) {
      return policyObj;
    }

    policyObj.frequencyValue = rawData.frequency_value;
    policyObj.frequencyType = FREQUENCY_TYPES.find(
      (type: FrequencyType) => type.value === rawData.frequency_type
    );
  } else if (rawData.type === PolicyTypeEnum.CRON) {
    policyObj.cronType = STRICT_CRON_TYPES.find(
      (type: CronType) => type.value === rawData.cron_type
    );

    if (!policyObj.cronType) {
      return policyObj;
    }

    policyObj.type = PolicyTypeEnum.CRON;

    if (!rawData.cron_type) {
      return policyObj;
    }

    policyObj.cronTimeList = (rawData.time_hour_list || []).map((timeHour: number) => ({
      hour: timeHour,
      minute: rawData.time_minute
    }));

    if (policyObj.cronType.value === 'WEEKLY') {
      policyObj.cronDays = rawData.days.map((day: number) => CRON_DAYS[day]);
    }
  } else {
    policyObj.type = PolicyTypeEnum.LOAD;
    policyObj.destinationId = rawData.destination_id;
    policyObj.destTables = rawData.dest_tables;
  }

  return policyObj;
}

export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function getFrequencyClone(policy: Policy) {
  const clone = deepClone(policy);
  // Reassign maxFrequency as Infinity is converted to null by JSON.stringify
  clone.maxFrequency = policy.maxFrequency || DEFAULT_MAX_FREQUENCY;
  return clone;
}

export function getFrequencyOption(value: number): FrequencyChip {
  const frequencyType = getFrequencyType(value);
  const displayText = getFrequencyDisplayText(value, true);

  let frequencyVal: number;
  if (frequencyType === FREQUENCY_TYPE_MINUTES) {
    frequencyVal = value / FREQUENCY_TYPE_MINUTES.minSeconds;
  } else {
    frequencyVal = value / FREQUENCY_TYPE_HOURS.minSeconds;
  }

  return frequencyOptionFactory(frequencyVal, frequencyType, displayText);
}

export function getFrequencyType(value: number): FrequencyType {
  if (value >= FREQUENCY_TYPE_HOURS.minSeconds) {
    return FREQUENCY_TYPE_HOURS;
  }

  return FREQUENCY_TYPE_MINUTES;
}

export function getFrequencyDisplayText(value: number, showAbbreviation: boolean): string {
  let frequencyVal;

  if (value >= FREQUENCY_TYPE_HOURS.minSeconds) {
    frequencyVal = value / FREQUENCY_TYPE_HOURS.minSeconds;

    if (frequencyVal > 1) {
      return frequencyVal + (!showAbbreviation ? ' Hours' : ' Hrs');
    }

    return frequencyVal + (!showAbbreviation ? ' Hour' : ' Hr');
  }

  frequencyVal = value / FREQUENCY_TYPE_MINUTES.minSeconds;
  if (frequencyVal > 1) {
    return frequencyVal + (!showAbbreviation ? ' Minutes' : ' Mins');
  }

  return frequencyVal + (!showAbbreviation ? ' Minute' : ' Min');
}

export function getDefaultCronTimeList() {
  return getArrayFromSize(24).map(hour => ({
    hour,
    minute: 0
  }));
}

export function isValidCronTime(cronTime) {
  return cronTime && typeof cronTime.hour === 'number' && typeof cronTime.minute === 'number';
}

export function getValidCronTimeList(policyObj: Policy) {
  return (policyObj.cronTimeList || []).filter(cronTime => isValidCronTime(cronTime));
}

export function isValidFrequencyValue(policyObj: Policy) {
  const { frequencyValue, frequencyType, maxFrequency, minFrequency } = policyObj;
  if (
    !frequencyValue ||
    !frequencyType ||
    frequencyValue <= 0 ||
    !Number.isInteger(frequencyValue)
  ) {
    return false;
  }

  if (maxFrequency && frequencyValue * frequencyType.minSeconds > maxFrequency) {
    return false;
  }

  return !(minFrequency && frequencyValue * frequencyType.minSeconds < minFrequency);
}

export function getPolicyMessage(policyObj: Policy, isStreaming?: boolean): string {
  if (isStreaming) {
    return 'continuously';
  }

  if (policyObj.type === PolicyTypeEnum.FREQUENCY) {
    if (!isValidFrequencyValue(policyObj)) {
      return undefined;
    }

    return [
      'every',
      getFrequencyDisplayText(policyObj.frequencyValue * policyObj.frequencyType.minSeconds, false)
    ].join(' ');
  }

  if (policyObj.type === PolicyTypeEnum.CRON) {
    if (!policyObj.cronTime && !policyObj.cronTimeList) {
      return undefined;
    }

    if (policyObj.cronType && policyObj.cronType.name === CRON_TYPE_DAILY.name) {
      const validDataArr = getValidCronTimeList(policyObj);
      if (validDataArr && validDataArr.length) {
        const message = validDataArr.reduce((acc: string, cronTime, index) => {
          if (index === validDataArr.length - 1) {
            acc += `${getTimeString(cronTime.hour)}:${getTimeString(cronTime.minute)} UTC.`;
          } else {
            acc += `${getTimeString(cronTime.hour)}:${getTimeString(cronTime.minute)} UTC, `;
          }
          return acc;
        }, '');

        return `Daily at ${message}`;
      }
    }

    if (policyObj.cronType && policyObj.cronType.name === CRON_TYPE_WEEKLY.name) {
      if (!policyObj.cronDays) {
        return undefined;
      }

      let daysString = 'on every ';

      policyObj.cronDays.sort((day1: CronDay, day2: CronDay) => (day1.value < day2.value ? -1 : 1));

      policyObj.cronDays?.forEach((cronDay, index) => {
        daysString += cronDay.name;
        if (index !== policyObj.cronDays.length - 1) {
          daysString += ', ';
        }
      });

      const validCronTimeArr = getValidCronTimeList(policyObj);
      if (validCronTimeArr && validCronTimeArr.length) {
        return `${[daysString, 'at', getTimeString(validCronTimeArr[0].hour)].join(' ')}:${[
          getTimeString(validCronTimeArr[0].minute),
          'UTC'
        ].join(' ')}`;
      }
    }
  }

  if (policyObj.type === PolicyTypeEnum.LOAD) {
    if (!policyObj.destTables || policyObj.destTables.length === 0) {
      return undefined;
    }

    return ['on load in', policyObj.destTables[0]].join(' ');
  }

  return undefined;
}

export function getTimeString(number: number) {
  if (typeof number !== 'number') {
    return '';
  }

  if (number < 10) {
    return `0${number}`;
  }

  return number.toString();
}

export function getPolicyRawData(policyObj: Policy): any {
  const data: any = {
    type: policyObj.type
  };

  if (policyObj.type === PolicyTypeEnum.FREQUENCY) {
    data.frequency_value = policyObj.frequencyValue;
    data.frequency_type = policyObj.frequencyType.value;

    return data;
  }

  if (policyObj.type === PolicyTypeEnum.CRON) {
    data.cron_type = policyObj.cronType.value;

    data.time_hour_list = getValidCronTimeList(policyObj).map(cronTime => cronTime.hour);
    data.time_minute = policyObj.cronTimeList[0].minute || 0;

    if (data.cron_type === 'WEEKLY') {
      data.days = policyObj.cronDays.map((day: CronDay) => day.value);
    }

    return data;
  }

  if (policyObj.type === PolicyTypeEnum.LOAD) {
    data.destination_id = policyObj.destinationId;
    data.dest_tables = policyObj.destTables;

    return data;
  }

  if (policyObj.type === PolicyTypeEnum.NO_SCHEDULE) {
    return {};
  }

  return data;
}

export const getDaysPlaceholder = (value: CronDay[]): string => {
  if (!value || !value.length) {
    return '';
  }

  if (value.length === 1) {
    return value[0].name;
  }

  if (value.length === CRON_DAYS.length) {
    return 'All Days';
  }

  return `${value.length} Days`;
};

export const getCronTimeDisplayValue = (time: CronTime) => {
  if (!time || typeof time.hour !== 'number' || typeof time.minute !== 'number') {
    return '';
  }

  return `${time.hour.toString().padStart(2, '0')} : ${time.minute.toString().padStart(2, '0')}`;
};
