import { AppConfig } from '../../../app/core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../../../app/core/constants';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';
import { createHttpParams } from '../../legacy-utils/request';
import { WorkflowFactory, WorkflowsFactory } from './factory/workflow';
import { WorkflowRunHistoryFactory } from './factory/workflow-run-history';

const appConfig = new AppConfig();

const WorkflowAPI = {
  workflowsURL: appConfig.getWorkflowsURL(),

  getWorkflowPathById(workflowId: number) {
    return `${this.workflowsURL}/${workflowId}`;
  },

  addWorkflow() {
    return courier.post(this.workflowsURL, BACKGROUND_NETWORK_REQ_OPTIONS, {}).then((res: any) =>
      res?.data
        ? {
            rawData: res.data,
            workflow: WorkflowFactory(res.data)
          }
        : undefined
    );
  },

  getWorkflow(workflowId: number, showLoading = true) {
    const requestUrl = this.getWorkflowPathById(workflowId);

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading
      },
      networkOptions: {
        params: createHttpParams({
          include_steps: true
        })
      }
    };

    return courier
      .get(requestUrl, options)
      .then((res: any) => (res?.data ? WorkflowFactory(res.data) : undefined));
  },

  getWorkflowBySeqId(seqId: number, showLoading = true) {
    const requestUrl = `${this.workflowsURL}/seq-id/${seqId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading,
        showErrorMsg: showLoading
      },
      networkOptions: {
        params: createHttpParams({
          include_steps: true
        })
      }
    };

    return courier
      .get(requestUrl, options)
      .then((res: any) => (res?.data ? WorkflowFactory(res.data) : undefined));
  },

  getWorkflowHistoryCount(workflowId: number, status?: string): Promise<number> {
    const requestUrl = `${this.getWorkflowPathById(workflowId)}/history/count`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          status
        })
      }
    };

    return courier.get(requestUrl, options).then(res => res?.data?.count);
  },

  getWorkflowRunHistory(
    workflowId: number,
    start: number = 0,
    limit: number = 20,
    status?: string
  ) {
    const requestUrl = `${this.getWorkflowPathById(workflowId)}/history`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          start,
          limit,
          status
        })
      }
    };

    return courier
      .get(requestUrl, options)
      .then((res: any) => WorkflowRunHistoryFactory(res?.data));
  },

  deleteWorkflow(workflowId: number) {
    return courier.delete(this.getWorkflowPathById(workflowId));
  },

  updateWorkflow(params: any, workflowId: number, showLoading = true) {
    const requestUrl = this.getWorkflowPathById(workflowId);

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading,
        showSuccessMsg: showLoading
      }
    };

    return courier.put(requestUrl, options, params);
  },

  getWorkflows(showLoading = true) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading
      }
    };

    return courier.get(this.workflowsURL, options).then((res: any) => WorkflowsFactory(res?.data));
  },

  pauseWorkflow(workflowId: number) {
    const requestUrl = `${this.getWorkflowPathById(workflowId)}/pause`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return courier.put(requestUrl, options);
  },

  resumeWorkflow(workflowId: number) {
    const requestUrl = `${this.getWorkflowPathById(workflowId)}/resume`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return courier.put(requestUrl, options);
  },

  runNow(workflowId: number) {
    const requestUrl = `${this.getWorkflowPathById(workflowId)}/instant-run`;

    return courier.put(requestUrl);
  },

  schedule(workflowId: number, body: any) {
    const requestUrl = `${ this.workflowsURL  }/${ workflowId }/schedule`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.put(requestUrl, options, body);
  }
};

export default WorkflowAPI;
