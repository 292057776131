import clsx from 'clsx';
import React, { useCallback } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';
import HdIcon from '../HdIcon';
import { HdIconButton } from '../HdIconButton';
import { HdTooltip } from '../HdTooltip';

export interface HdTagProps {
  dataId: string;
  children: React.ReactNode;
  onClose?: Function;
  className?: string;
  iconButtonClassName?: string;
  iconClassName?: string;
  onClick?: Function;
}

export const getHdTagDataId = (dataId) => {
  return getDataIdGenerator(dataId, 'tag');
};

export default function HdTag({
  dataId,
  children,
  onClose,
  iconClassName,
  iconButtonClassName,
  className,
  onClick = () => {}
}: HdTagProps) {
  const dataIdGenerator = useCallback(getHdTagDataId(dataId), [dataId]);

  return (
    <div
      role='button'
      tabIndex={0}
      className={clsx(
        styles.tag,
        !onClose && styles.withoutCloseButton,
        className
      )}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          onClick(event);
        }
      }}
      onClick={event => onClick(event)}
      data-id={dataIdGenerator('button')}
    >
      <HdTooltip title={children} enableOnTextOverflow>
        <span className='text-ellipsis' data-id={dataIdGenerator('content')}>{children}</span>
      </HdTooltip>

      {onClose ? (
        <HdIconButton
          onClick={event => onClose(event)}
          className={`${styles.closeButton} ${iconButtonClassName}`}
          dataId={dataIdGenerator('clear')}
        >
          <HdIcon name='close' className={iconClassName} size={1} dataId={dataIdGenerator('clearIcon')} />
        </HdIconButton>
      ) : null}
    </div>
  );
}
