import { Component, Inject, OnInit } from '@angular/core';

import { Dialog } from '../models';
import { fadeAnim } from '../animations';

import { DOCUMENT } from '@angular/common';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { DialogOverlayRef } from '../dialog-overlay-ref';

import { DialogBase } from '../dialog-base';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { Angulartics2 } from 'angulartics2';
import {
  TRACKER_ASK_SUPPORT_CANCEL,
  TRACKER_ASK_SUPPORT_CANCEL_CLICK,
  TRACKER_ASK_SUPPORT_CONTACT_SUPPORT_CLICK,
  TRACKER_ASK_SUPPORT_DIALOG_OPEN,
  TRACKER_ASK_SUPPORT_ESC_CLICK,
  TRACKER_ASK_SUPPORT_OUTSIDE_CLICK
} from './constants';
import { AskSupportData, AskSupportDialogVersion } from './interface';
import { askSupportPopupAnimation } from './animations';

@Component({
  selector: 'ask-support-dialog',
  templateUrl: './ask-support-dialog.component.html',
  styleUrls: [ './ask-support-dialog.component.scss' ],
  animations: [ askSupportPopupAnimation(), fadeAnim() ]
})
export class AskSupportDialogComponent extends DialogBase implements OnInit, Dialog {
  backdropCloseDisabled = true;
  initialPositionX: number;
  initialPositionY: number;
  finalPositionX: number;
  finalPositionY: number;
  askSupportData: AskSupportData & AskSupportDialogVersion;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public data: { askSupportData: AskSupportData, launcher: any },
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _angulartics: Angulartics2
  ) {
    super(document, _dialogRef, data, _keyboardShortcuts);
    this.askSupportData = { ...data.askSupportData, dialogVersion: 2 };
    this._calculateInitialPosition();
  }

  private _calculateInitialPosition() {
    const pageBoundingRect = document.body.getBoundingClientRect();
    const launcherBoundingRect = this.data.launcher.getBoundingClientRect();

    this.initialPositionX = (launcherBoundingRect.x + launcherBoundingRect.width / 2) - pageBoundingRect.width / 2;
    this.initialPositionY = (launcherBoundingRect.y + launcherBoundingRect.height / 2) - pageBoundingRect.height / 2;

    this.finalPositionX = this.initialPositionX;
    this.finalPositionY = this.initialPositionY;
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  show() {
    this._angulartics.eventTrack.next({
      action: TRACKER_ASK_SUPPORT_DIALOG_OPEN,
      properties: {
        ...this.askSupportData
      }
    });

    super.show();
  }

  onPositiveClick() {
    this._angulartics.eventTrack.next({
      action: TRACKER_ASK_SUPPORT_CONTACT_SUPPORT_CLICK,
      properties: {
        ...this.askSupportData
      }
    });

    const pageBoundingRect = document.body.getBoundingClientRect();
    const supportPadding = 20;

    this.finalPositionX = pageBoundingRect.width / 2 - supportPadding;
    this.finalPositionY = pageBoundingRect.height / 2 - supportPadding;

    this.hide(true);
  }

  onEscapeClick() {
    this._angulartics.eventTrack.next({
      action: TRACKER_ASK_SUPPORT_ESC_CLICK,
      properties: {
        ...this.askSupportData
      }
    });

    super.onEscapeClick();
  }

  onOutsideClick() {
    this._angulartics.eventTrack.next({
      action: TRACKER_ASK_SUPPORT_OUTSIDE_CLICK,
      properties: {
        ...this.askSupportData
      }
    });

    super.onOutsideClick();
  }

  onCancelClick() {
    this._angulartics.eventTrack.next({
      action: TRACKER_ASK_SUPPORT_CANCEL_CLICK,
      properties: {
        ...this.askSupportData
      }
    });

    this.onNegativeClick();
  }

  onNegativeClick(dialogResponse?: any) {
    this._angulartics.eventTrack.next({
      action: TRACKER_ASK_SUPPORT_CANCEL,
      properties: {
        ...this.askSupportData
      }
    });

    super.onNegativeClick(dialogResponse);
  }
}
