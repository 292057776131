<ng-container *ngFor="let action of renderedBulkActions | slice: 0 : inlineSize">
  <div
    [tooltip]="rbacActionBlockedTooltip"
    [tooltipDisabled]="!isActionBlockedByRbac()"
    class="d-inline-block mr-3">
    <button
      type="button"
      hdButton
      variation="outline"
      [icon]="action.iconName"
      [disabled]="isBulkActionDisabled(action.id)"
      (click)="onBulkActionClick(action.id)">
      {{ action.actionDisplayName }}
    </button>
  </div>
</ng-container>

<button
  *ngIf="renderedBulkActions.length > inlineSize"
  type="button"
  hdButton
  variation="outline"
  direction="right"
  icon="dropdown-arrow"
  [hdMenuTrigger]="moreActions">
  More
</button>

<hd-menu #moreActions>
  <hd-menu-item
    *ngFor="let action of renderedBulkActions | slice: inlineSize : renderedBulkActions.length"
    [disabled]="isBulkActionDisabled(action.id)"
    [tooltip]="rbacActionBlockedTooltip"
    [tooltipDisabled]="!isActionBlockedByRbac()"
    [iconName]="action.hasIcon ? action.iconName : null"
    (select)="onBulkActionClick(action.id)">
    {{ action.actionDisplayName }}
  </hd-menu-item>
</hd-menu>
