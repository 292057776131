import React from 'react';
import BaseModal, { BaseModalProps } from '../BaseModal';

export default function HdModal({ contentClassName, children, ...rest }: BaseModalProps) {
  return (
    <BaseModal {...rest} contentClassName={`dialog-content ${contentClassName}`}>
      {children}
    </BaseModal>
  );
}
