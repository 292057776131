import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestinationService } from '../../core/service/destination.service';
import { wrapIntoObservable } from '../../hd-wizard/utils';
import { DestinationType } from '../destination-type/model';
import { NodeSettingsFormService } from '../node-settings-form.service';
import { DestinationConfigHelperService } from './destination-config-helper.service';
import { DestinationConfigData, DestinationFormData } from './destination-form-data';
import { DestinationTypeConfigHelper } from './destination-type-config-helper';


@Injectable()
export class DestinationSettingsService extends NodeSettingsFormService<DestinationConfigHelperService, DestinationType> {
  constructor(
    protected _destinationConfigHelperService: DestinationConfigHelperService,
    private _destinationService: DestinationService
  ) {
    super(_destinationConfigHelperService);
  }

  getSampleSettings$(destinationTypeMetaData: DestinationType) {
    return forkJoin([
      this._getSampleDestination$(destinationTypeMetaData),
      this._getExistingNodes$(destinationTypeMetaData)
    ]);
  }

  protected _getConfigFromRawData(destinationType: DestinationType, rawData: any, metaData: any, isUpdateMode = false) {
    const configHelperInstance = this._getConfigHelperInstance(destinationType.name);

    const config: any = {
      loadedAt: destinationType.populateLoadedAt
        ? (
          typeof rawData.loaded_at !== 'undefined'
            ? rawData.loaded_at
            : false
        )
        : null,
      ...(configHelperInstance
        ? configHelperInstance.getConfigFromRawData(rawData, metaData, isUpdateMode)
        : {})
    };

    // const requireAuth = destinationType.requireAuth || destinationType.requireServiceAuth;

    // // if (requireAuth) {
    // //   config.authorisedAccount = rawData.authenticated_user;
    // // }

    if ('authenticated_user' in rawData) {
      config.authorisedAccount = rawData.authenticated_user;
    }

    if ('oauth_token_id' in rawData) {
      config.oauthTokenId = rawData.oauth_token_id;
    }

    if ('security_service_account_id' in rawData) {
      config.serviceAccountId = rawData.security_service_account_id;
    }

    return config;
  }

  protected _getDefaultConfig(destinationType: DestinationType, metaData: any): DestinationConfigData {
    const configHelperInstance = this._getConfigHelperInstance(destinationType.name);

    return {
      loadedAt: destinationType.populateLoadedAt ? true : null,
      ...(configHelperInstance ? configHelperInstance.getDefaultConfig(metaData) : {})
    };
  }

  protected _getConfigFromInitialData(destinationType: DestinationType, rawData: any, metaData: any, isUpdatingDestination = false) {
    const configHelperInstance = this._configHelperService.getConfigHelper(destinationType.name);

    const config: any = {
      ...(configHelperInstance
        ? configHelperInstance.getConfigFromInitialData(rawData, metaData)
        : {})
    };

    const requireAuth = destinationType.requireAuth || destinationType.requireServiceAuth;

    if (requireAuth) {
      config.authorisedAccount = rawData.authenticated_user;
    }

    if ('oauth_token_id' in rawData) {
      config.oauthTokenId = rawData.oauth_token_id;
    }

    if ('security_service_account_id' in rawData) {
      config.serviceAccountId = rawData.security_service_account_id;
    }

    return config;
  }

  protected _getExistingNodes$(destinationType: DestinationType): Observable<any[]> {
    return this._destinationService.getDestinations().pipe(
      map((res: any) => {
        if (!res || !res.data) {
          return [];
        }

        return res.data.filter((item: any) => {
          return item.type === destinationType.name;
        });
      })
    );
  }

  private _getSampleDestination$(destinationType: DestinationType): Observable<any> {
    return this._destinationService.getSampleSettings().pipe(
      map((res: any) => {
        if (!res || !res.data) {
          return;
        }

        return {
          ...res.data[destinationType.name]
        };
      })
    );
  }

  resolveDependenciesBeforeTesting(destinationTypeIdentifier: string, destinationFormData: DestinationFormData, isUpdateMode = false) {
    const configHelperInstance = this._configHelperService.getConfigHelper(destinationTypeIdentifier);

    const testDestinationDependenciesResolver = (configHelperInstance as DestinationTypeConfigHelper).testDestinationDependenciesResolver;
    let request$;

    if (typeof testDestinationDependenciesResolver !== 'function' || isUpdateMode) {
      request$ = of({});
    } else {
      request$ = wrapIntoObservable(testDestinationDependenciesResolver.call(configHelperInstance,
        destinationFormData));
    }

    return request$.pipe(
      map((updatedFormData: any) => ({
        ...destinationFormData,
        ...updatedFormData
      }))
    );
  }

  resolveDependenciesBeforeSaving(destinationTypeIdentifier: string, destinationFormData: DestinationFormData) {
    const configHelperInstance = this._configHelperService.getConfigHelper(destinationTypeIdentifier);

    const saveDestinationDependenciesResolver = (configHelperInstance as DestinationTypeConfigHelper).saveDestinationDependenciesResolver;
    let request$;

    if (typeof saveDestinationDependenciesResolver !== 'function') {
      request$ = of({});
    } else {
      request$ = wrapIntoObservable(saveDestinationDependenciesResolver.call(configHelperInstance,
        destinationFormData));
    }

    return request$.pipe(
      map((updatedFormData: any) => ({
        ...destinationFormData,
        ...updatedFormData
      }))
    );
  }

  getInitialFrozenFields(destinationTypeIdentifier: string) {
    const configHelperInstance = this._configHelperService.getConfigHelper(destinationTypeIdentifier);

    return configHelperInstance.getInitialFrozenFields();
  }
}
