export const PROFILE_DEFAULT_CUSTOM_FIELD_TYPE = 'profile_custom';
export const EVENT_DEFAULT_CUSTOM_FIELD_TYPE = 'event_custom';

export const DEFAULT_CUSTOM_FIELD_TYPE = 'CUSTOM';

export const TARGET_OBJECT_CUSTOM_FIELD_TYPES = [
  {
    name: 'Custom Event',
    value: EVENT_DEFAULT_CUSTOM_FIELD_TYPE
  },
  {
    name: 'Custom Profile',
    value: PROFILE_DEFAULT_CUSTOM_FIELD_TYPE
  }
];
