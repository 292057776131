import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TeamService } from './team.service';


@Injectable()
export class TeamDetailsResolver implements Resolve<any> {
  constructor(
    private _teamService: TeamService
  ) {
  }

  resolve(): Observable<any> {
    return this._teamService.getTeamDetails().pipe(
      map((res: any) => res.data),
      catchError((err: any) => {
        return of(null);
      })
    );
  }
}
