import {
  SingleLevelSelectorCursorPaginationHelper,
  SingleLevelSelectorOffsetPaginationHelper
} from '../../../app/shared/single-level-selector/pagination-helpers';

export function getSingleLevelSelectorPaginationStrategy(paginationStrategy: 'cursor' | 'offset') {
  if (paginationStrategy === 'cursor') {
    return new SingleLevelSelectorCursorPaginationHelper();
  }

  return new SingleLevelSelectorOffsetPaginationHelper();
}
