import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { BehaviorSubject, of, Subscription, throwError, timer } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { EntityUIState } from '../../app/core/models/entitiy-ui-state';
import {
  GetList,
  ListActions,
  ListError,
  ListResponse,
  RefreshList,
  ResetList
} from '../../app/pagination/list-conductor/list-actions';
import {
  ListConductor,
  ListConductorOptions
} from '../../app/pagination/list-conductor/list-conductor';
import useDontMountAtFirst from './useDontMountAtFirst';
import useService from './useService';

export interface UseQueryProps<T> {
  dataSource$: (params?: any) => Promise<any>;
  pollDataSource$?: (data: any) => Promise<any>;
  pollDataMergeFn?: (oldData: any, newData: any) => any;
  pollInterval?: number;
  pollUntil?: (data: T) => boolean;
  pollDelay?: number;
  noPollingOnEmptyData?: boolean;
  emptyAssertion?: (data: T) => boolean;
  fetchSuccess?: (data: T) => void;
  fetchError?: (error: any) => void;
  paginated?: boolean;
  listConductorOptions?: ListConductorOptions;
  resetOnFetch?: boolean;
  initialData?: T;
}

const INITIAL_STATE = {
  uiState: EntityUIState.NEW,
  error: null,
  data: null
};

interface QueryState<T = any> {
  uiState: EntityUIState;
  error?: any;
  data?: T;
}

enum QueryActionKind {
  WRITE_DATA = 'WRITE_DATA',
  RESET = 'RESET',
  SET_DATA = 'SET_DATA'
}

interface QueryAction {
  type: QueryActionKind;
  data?: any;
}

function stateReducer(state: QueryState, action: QueryAction): QueryState {
  const { type, data } = action;
  switch (type) {
    case QueryActionKind.RESET:
      return {
        ...INITIAL_STATE
      };
    case QueryActionKind.SET_DATA:
      return {
        ...state,
        ...data
      };
    case QueryActionKind.WRITE_DATA:
      return {
        ...state,
        data: data.updateFunction(state.data)
      };
    default:
      return state;
  }
}

export function useQuery<T = any>(props: UseQueryProps<T>) {
  const {
    dataSource$,
    pollDataSource$,
    pollDataMergeFn,
    pollInterval,
    pollUntil,
    pollDelay,
    noPollingOnEmptyData = false,
    emptyAssertion,
    fetchSuccess,
    fetchError,
    paginated,
    listConductorOptions,
    resetOnFetch = true,
    initialData
  } = props;

  const initialState = {
    ...INITIAL_STATE
  };

  if (initialData) {
    initialState.data = initialData;
  }

  const [state, dispatch]: [QueryState, Function] = useReducer(stateReducer, { ...initialState });

  const fetchEffectSub = useRef(Subscription.EMPTY);
  const pollEffectSub = useRef(Subscription.EMPTY);
  const fetchedOnce = useRef(false);
  const pollConfigSubject = useRef(
    new BehaviorSubject({
      delay: pollDelay || pollInterval,
      interval: pollInterval
    })
  );

  const _route = useService(ActivatedRoute);
  const _router = useService(Router);
  const ngZone = useService(NgZone);
  const _doFetch = useRef(null);

  const listConductorRef = useRef(
    !paginated
      ? null
      : new ListConductor(
          {
            ...listConductorOptions
          },
          _route,
          _router
        )
  );

  _doFetch.current = useCallback(
    (params?: any) => {
      fetchEffectSub.current.unsubscribe();
      pollEffectSub.current.unsubscribe();

      const fetchEffect = () =>
        fromPromise(
          dataSource$(paginated ? listConductorRef.current.getListMetaNetworkParams() : params)
        );

      fetchEffectSub.current = fetchEffect()
        .pipe(
          map((data: any) => {
            if (paginated) {
              listConductorRef.current.dispatch(new ListResponse(data.responseMetaData));

              dispatch({
                type: QueryActionKind.SET_DATA,
                data: {
                  data: data.items
                }
              });

              return data.items;
            }

            dispatch({
              type: QueryActionKind.SET_DATA,
              data: {
                uiState:
                  emptyAssertion && emptyAssertion(data) ? EntityUIState.EMPTY : EntityUIState.IDLE,
                error: null,
                data
              }
            });

            return data;
          }),
          tap(data => {
            if (!pollInterval || (noPollingOnEmptyData && !data)) {
              return;
            }

            if (typeof pollUntil === 'function' && !pollUntil(data)) {
              return;
            }

            pollEffectSub.current = pollConfigSubject.current
              .pipe(
                switchMap(pollConfig => timer(pollConfig.delay, pollConfig.interval)),
                switchMap(() => {
                  const pollEffect = pollDataSource$
                    ? fromPromise(pollDataSource$(data))
                    : fetchEffect();

                  return pollEffect.pipe(
                    map(pollResult => {
                      const newData = pollDataMergeFn
                        ? pollDataMergeFn(data, pollResult)
                        : pollResult;

                      dispatch({
                        type: QueryActionKind.SET_DATA,
                        data: {
                          data: newData
                        }
                      });

                      return newData;
                    }),
                    catchError(() => of())
                  );
                }),
                takeWhile(val => (typeof pollUntil === 'function' ? pollUntil(val) : true))
              )
              .subscribe();
          }),
          catchError(error => {
            if (typeof fetchError === 'function') {
              fetchError(error);
            }

            if (paginated) {
              listConductorRef.current.dispatch(new ListError(error));
              dispatch({
                type: QueryActionKind.SET_DATA,
                data: {
                  data: null
                }
              });

              return throwError(error);
            }

            dispatch({
              type: QueryActionKind.SET_DATA,
              data: {
                uiState: EntityUIState.ERRORED,
                error,
                data: null
              }
            });

            return throwError(error);
          }),
          take(1),
          tap(data => {
            if (typeof fetchSuccess === 'function') {
              fetchSuccess(data);
            }

            fetchedOnce.current = true;
          })
        )
        .subscribe();
    },
    [props]
  );

  const fetch = (params?: any) => {
    if (fetchedOnce.current && resetOnFetch === false) {
      refetch(params);
      return;
    }

    fetchedOnce.current = false;

    if (paginated) {
      listConductorRef.current.dispatch(new GetList());
      return;
    }

    dispatch({
      type: QueryActionKind.SET_DATA,
      data: {
        uiState: EntityUIState.LOADING,
        error: null,
        data: null
      }
    });

    _doFetch.current(params);
  };

  const refetch = (params?: any) => {
    if (paginated) {
      listConductorRef.current.dispatch(new RefreshList());
      return;
    }

    dispatch({
      type: QueryActionKind.SET_DATA,
      data: {
        uiState: EntityUIState.REFRESHING,
        error: null
      }
    });

    _doFetch.current(params);
  };

  const reset = () => {
    fetchedOnce.current = false;

    if (paginated) {
      listConductorRef.current.dispatch(new ResetList());
      return;
    }

    dispatch({
      type: QueryActionKind.RESET,
      data: {}
    });
  };

  const writeData = updateFunction => {
    dispatch({
      type: QueryActionKind.WRITE_DATA,
      data: {
        updateFunction
      }
    });
  };

  const restartPolling = (newDelay = 0, newPollInterval = pollInterval) => {
    pollConfigSubject.current.next({
      delay: newDelay,
      interval: newPollInterval
    });
  };

  useEffect(() => {
    if (!listConductorRef.current) {
      return;
    }

    const listStateSub = listConductorRef.current.listState$.subscribe(listState => {
      dispatch({
        type: QueryActionKind.SET_DATA,
        data: {
          uiState: listState.state,
          error: listState.error
        }
      });
    });

    const listChangesSub = listConductorRef.current.changes$.subscribe(listState => {
      _doFetch.current();
    });

    return () => {
      listStateSub.unsubscribe();
      listChangesSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    return () => {
      fetchEffectSub.current.unsubscribe();
      pollEffectSub.current.unsubscribe();

      if (listConductorRef.current) {
        listConductorRef.current.disconnect();
      }
    };
  }, []);

  useDontMountAtFirst(() => {
    pollConfigSubject.current.next({
      delay: pollDelay ? pollDelay : 0,
      interval: pollInterval
    });
  }, [pollInterval, pollDelay]);

  const dispatchListAction = (action: ListActions) => {
    ngZone.run(() => {
      listConductorRef.current.dispatch(action);
    });
  };

  return {
    uiState: state.uiState,
    error: state.error,
    data: state.data,
    fetch,
    refetch,
    reset,
    restartPolling,
    writeData,
    dispatchListAction,
    listConductor: listConductorRef.current
  };
}
