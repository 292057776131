import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import styles from './styles.module.scss';
import HdModal from '../../../components/UIElements/HdModal';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import SearchArea from '../../../components/SearchArea';
import {
  GlobalSearchEmptyResult,
  GlobalSearchEntities,
  GlobalSearchExcerpt,
  GlobalSearchExcerptShimmer,
  GlobalSearchFooter
} from './Components';
import { ShortcutsDialogHandlerReactService } from '../../../../app/shortcuts/service/shortcuts-dialog-handler-react.service';
import useService from '../../../hooks/useService';
import {
  GlobalSearchDocLinksResponse,
  GlobalSearchEntityAlgoliaSearchResultData,
  GlobalSearchEntityData,
  GlobalSearchEntityDestinationTypeData,
  GlobalSearchEntitySourceTypeData,
  GlobalSearchFilterEntitiesTracker,
  GlobalSearchMenuItemType
} from './models';
import {
  DEFAULT_DOC_LINKS,
  ENTITY_VIEW_ORDER,
  GLOBAL_SEARCH_DELAY,
  RBAC_PROTECTED_ROUTES
} from './constants';
import {
  getEntityTrackingData,
  getScrollPosition,
  getUIRenderingData,
  getValidItemIndex
} from './util';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import {
  GLOBAL_SEARCH_DEFAULT_STATE,
  GlobalSearchEntity,
  GlobalSearchState,
  GlobalSearchReducerActions,
  GlobalSearchReducerType,
  GlobalSearchReducer,
  GlobalSearchReducerActionsFactory
} from './GlobalSearchReducer';
import { fetchAlgoliaResults, fetchEntitiesData, fetchEntitiesStaticDocs } from './apiUtil';
import { getDocLink } from '../../../utils/docLinkHandler';
import { GlobalSearchCreateNewEntityService } from '../../../../app/pipeline/service/global-search-create-new-entity.service';
import {
  TRACKER_GLOBAL_SEARCH_OPEN,
  TRACKER_GLOBAL_SEARCH_CLOSE,
  TRACKER_GLOBAL_SEARCH_NO_RESULT,
  TRACKER_GLOBAL_SEARCH_SELECT_ITEM,
  TRACKER_GLOBAL_SEARCH_FILTER_ENTITIES,
  TRACKER_GLOBAL_ATTEMPT_TO_CREATE_PIPELINE_WITH_NO_PERMISSION,
  TRACKER_GLOBAL_SEARCH_ATTEMPT_TO_CREATE_DESTINATION_WITH_NO_PERMISSION,
  TRACKER_GLOBAL_SEARCH_ATTEMPT_TO_ACCESS_ROUTE_WITH_NO_PERMISSION,
  GLOBAL_SEARCH_UTM_MEDIUM
} from './tracking';
import useHasPermission from '../../../hooks/useHasPermission';
import { RbacPermissions } from '../../../../app/core/models/user';
import { isAbsoluteURL } from '../../../utils/urlUtil';
import { isEqual } from '../../../legacy-utils/equality';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { SessionTrackingAngularticsService } from '../../../../app/core/service/session-tracking-angulartics.service';
import {
  TRACKER_CREATE_PIPELINE_CLICK,
  TRACKER_SELECT_DEST_TYPE,
  TRACKER_SELECT_DISABLED_SOURCE_TYPE,
  TRACKER_SELECT_SOURCE_TYPE,
  TRACKER_SOURCE_TYPE_CLICK
} from '../../../../app/nodes/tracking';
import { uuidv4 } from '../../../legacy-utils/uuid';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';

const dataIdGenerator = getDataIdGenerator('globalSearchDialog');

export default function AdvancedGlobalSearchDialog({ history, onSearchChange = undefined }) {
  const [currentActiveEntity, setCurrentActiveEntity] = useState<GlobalSearchEntityData>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [docLinksMap, setDocLinks] = useState<GlobalSearchDocLinksResponse>(DEFAULT_DOC_LINKS);
  const searchAreaRef = useRef<any>();

  const [state, dispatch]: [GlobalSearchState, Function] = useReducer<GlobalSearchReducerType>(
    GlobalSearchReducer,
    GLOBAL_SEARCH_DEFAULT_STATE
  );
  const reducerActions = useRef<GlobalSearchReducerActions>(
    GlobalSearchReducerActionsFactory(dispatch)
  );

  const activateStatusService = useService(ActivationStatusService);

  const firstLoadCompleted = useRef(false);
  const bodyRef = useRef<HTMLDivElement>(null);
  const menuItems = useRef<Set<{ current: GlobalSearchMenuItemType }>>(new Set());
  const searchInteraction = useRef<boolean>(false);

  const _shortcutsDialogHandlerReactService = useService(ShortcutsDialogHandlerReactService);
  const analyticsTracker = useAnalyticsTracker();
  const _sessionTrackingAngularticsService = useService(SessionTrackingAngularticsService);
  const location = useLocation();
  const globalSearchCreateNewEntityService = useService(GlobalSearchCreateNewEntityService);

  const { hasPermission } = useHasPermission();
  const { isWorkFlowsEnabled } = useTeamSettings();
  const _activationStatusService = useService(ActivationStatusService);
  const isActivationEnabled = _activationStatusService.shouldShowActivation();

  const advancedGlobalSearchSessionUUID = useRef(uuidv4());

  const getCommonTrackingProperties = () => ({
    uuid: advancedGlobalSearchSessionUUID.current
  });

  const trackEvent = (eventName: string, properties: object) => {
    analyticsTracker.eventTrack({
      action: eventName,
      properties: { ...properties, ...getCommonTrackingProperties() }
    });
  };

  useEffect(() => {
    fetchEntitiesData(
      reducerActions.current,
      activateStatusService.shouldShowActivation(),
      isWorkFlowsEnabled
    );

    trackEvent(TRACKER_GLOBAL_SEARCH_OPEN, {});

    fetchEntitiesStaticDocs().then(res => setDocLinks(res.data));

    return () => {
      trackEvent(TRACKER_GLOBAL_SEARCH_CLOSE, {});
    };
  }, []);

  useEffect(() => {
    updateScrollPosition();
  }, [currentIndex]);

  const onSearchKeydown = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'Enter': {
        e.preventDefault();
        Array.from(menuItems.current)[currentIndex]?.current?.select();
        break;
      }

      case 'ArrowUp': {
        e.preventDefault();
        previousItem();
        break;
      }

      case 'ArrowDown': {
        e.preventDefault();
        nextItem();
        break;
      }

      default:
        break;
    }
  };

  const nextItem = () => {
    if (menuItems.current.size > 0) {
      const nextValidIndex = getValidItemIndex(menuItems.current, currentIndex, 'next');

      if (nextValidIndex !== undefined) {
        setCurrentIndex(nextValidIndex);
      }
    }
  };

  const previousItem = () => {
    if (menuItems.current.size > 0) {
      const previousValidIndex = getValidItemIndex(menuItems.current, currentIndex, 'prev');

      if (previousValidIndex !== undefined) {
        setCurrentIndex(previousValidIndex);
      }
    }
  };

  const focusItem = () => {
    if (menuItems.current) {
      const items = Array.from(menuItems.current);
      items.forEach(item => item.current?.classList.remove(styles.focused));

      let activeItem = items[currentIndex || 0];

      if (activeItem.current.disabled) {
        const nextValidIndex = getValidItemIndex(menuItems.current, currentIndex || 0, 'next');

        if (nextValidIndex !== undefined) {
          activeItem = items[nextValidIndex];
          setCurrentIndex(nextValidIndex);
        } else {
          return;
        }
      }

      if (activeItem?.current) {
        activeItem.current?.classList.add(styles.focused);
        setCurrentActiveEntity(activeItem.current.entity);
      }
    }
  };

  const updateScrollPosition = () => {
    if (menuItems.current.size > 0) {
      const scrollPosition = getScrollPosition(menuItems.current, bodyRef.current, currentIndex);

      if (scrollPosition !== undefined) {
        bodyRef.current.scrollTop = scrollPosition;
      }
    }
  };

  const onEntityClick = useCallback(entity => {
    const { entityType } = entity;
    if (entityType === GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS) {
      onEntitySelect(entity, false);
    } else {
      const newCurrentIndex = Array.from(menuItems.current).findIndex(menuItem =>
        isEqual(entity, menuItem.current.entity)
      );

      if (newCurrentIndex >= 0) {
        setCurrentIndex(newCurrentIndex);
      }
    }

    searchAreaRef.current.focus();
  }, []);

  const onEntitySelect = useCallback(
    (entity, isOverviewRoute = true, route = {}) => {
      const data = getEntityTrackingData(entity, isOverviewRoute, route);
      let { url } = data;
      const { trackingData } = data;

      trackEvent(TRACKER_GLOBAL_SEARCH_SELECT_ITEM, {
        ...trackingData,
        searchTerm: state.filters.search,
        ...(entity.entityType === GlobalSearchEntity.SOURCE_TYPES
          ? { sourceType: entity.source_type }
          : {}),
        ...(entity.entityType === GlobalSearchEntity.DESTINATION_TYPES
          ? { destinationType: entity.dest_type }
          : {})
      });

      switch (entity.entityType) {
        case GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS: {
          if (entity.category === 'docs') {
            return window.open(getDocLink(url, '', {}));
          }

          if (entity.category === 'product') {
            const rbacPermission = RBAC_PROTECTED_ROUTES.find(item =>
              item.route.includes((entity as GlobalSearchEntityAlgoliaSearchResultData).url)
            );

            if (rbacPermission && !hasPermission(rbacPermission.permission)) {
              trackEvent(TRACKER_GLOBAL_SEARCH_ATTEMPT_TO_ACCESS_ROUTE_WITH_NO_PERMISSION, {
                ...trackingData,
                route: url
              });

              // eslint-disable-next-line consistent-return
              return;
            }
          }

          if (entity.category === 'product' && isAbsoluteURL(url)) {
            return window.open(url);
          }

          break;
        }

        case GlobalSearchEntity.SOURCE_TYPES: {
          // start the session
          _sessionTrackingAngularticsService.startSessionAndTrack({
            action: TRACKER_CREATE_PIPELINE_CLICK,
            properties: {
              utmMedium: GLOBAL_SEARCH_UTM_MEDIUM,
              ...getCommonTrackingProperties()
            }
          });

          const analyticsProperties = {
            searchTerm: state.filters.search,
            searchResultEmpty: false,
            searchResultCount: state.sourceTypes.filteredEntities.length,
            sourceType: (entity as GlobalSearchEntitySourceTypeData).source_type,
            utmMedium: GLOBAL_SEARCH_UTM_MEDIUM
          };
          trackEvent(TRACKER_SOURCE_TYPE_CLICK, {
            ...analyticsProperties
          });

          if (entity?.is_disabled || entity?.is_upcoming) {
            // eslint-disable-next-line consistent-return
            if (entity?.is_disabled) {
              trackEvent(TRACKER_SELECT_DISABLED_SOURCE_TYPE, {
                ...analyticsProperties
              });
            }

            // eslint-disable-next-line consistent-return
            return;
          }

          if (hasPermission(RbacPermissions.PIPELINE_CREATE)) {
            trackEvent(TRACKER_SELECT_SOURCE_TYPE, {
              ...analyticsProperties
            });

            globalSearchCreateNewEntityService.selectSourceType({
              entity,
              fastTravel: false
            });
          } else {
            trackEvent(TRACKER_GLOBAL_ATTEMPT_TO_CREATE_PIPELINE_WITH_NO_PERMISSION, {
              sourceType: (entity as GlobalSearchEntitySourceTypeData).source_type,
              ...trackingData
            });

            // eslint-disable-next-line consistent-return
            return;
          }

          break;
        }

        case GlobalSearchEntity.DESTINATION_TYPES: {
          if (hasPermission(RbacPermissions.DESTINATION_CREATE)) {
            trackEvent(TRACKER_SELECT_DEST_TYPE, {
              destType: entity?.dest_type,
              searchTerm: state.filters.search,
              utmMedium: GLOBAL_SEARCH_UTM_MEDIUM,
              showPreferredDestinationsView: false
            });

            globalSearchCreateNewEntityService.selectDestinationType({
              entity,
              fastTravel: false
            });
          } else {
            trackEvent(TRACKER_GLOBAL_SEARCH_ATTEMPT_TO_CREATE_DESTINATION_WITH_NO_PERMISSION, {
              destinationType: (entity as GlobalSearchEntityDestinationTypeData).dest_type,
              ...trackingData
            });

            // eslint-disable-next-line consistent-return
            return;
          }

          break;
        }

        default:
          break;
      }

      if (url.startsWith('?')) {
        url = `${history.location.pathname}${url}`;
      }

      return navigate(url);
    },
    [state.filters.search, state.sourceTypes.filteredEntities.length]
  );

  const onSearch = useCallback((value: string) => {
    searchInteraction.current = true;

    reducerActions.current.filterData({ search: value || '' });
    fetchAlgoliaResults(value, reducerActions.current, isWorkFlowsEnabled, isActivationEnabled);

    menuItems.current = new Set();
    setCurrentIndex(0);

    if (onSearchChange) {
      onSearchChange(value);
    }
  }, []);

  const {
    showBody,
    loadingData,
    hasSearchResults,
    showExcerptShimmer,
    renderedItemsCount,
    filteredExistingEntitiesCount
  } = useMemo(() => {
    const data = getUIRenderingData(state);

    return { ...data };
  }, [state]);

  const getEntitiesTrackingData = (): GlobalSearchFilterEntitiesTracker => ({
    hits: renderedItemsCount,
    searchTerm: state.filters.search,
    algoliaSearchResults: state.algoliaSearchResults.filteredEntities.length,
    existingEntities: filteredExistingEntitiesCount,
    sourceRecommendations: state.sourceTypes.filteredEntities
      .map(entity => entity.source_type)
      .join(','),
    destinationRecommendations: state.destinationTypes.filteredEntities
      .map(entity => entity.dest_type)
      .join(',')
  });

  const sortMenuItems = () => {
    if (!firstLoadCompleted.current) {
      firstLoadCompleted.current = true;

      const items = Array.from(menuItems.current).sort((a, b) => {
        const aSortOrder = ENTITY_VIEW_ORDER[a.current.entity.entityType];
        const bSortOrder = ENTITY_VIEW_ORDER[b.current.entity.entityType];

        return aSortOrder - bSortOrder;
      });

      menuItems.current.clear();

      items.forEach(item => {
        menuItems.current.add(item);
      });
    }
  };

  useDontMountAtFirst(() => {
    if (!loadingData && menuItems.current.size > 0) {
      trackEvent(TRACKER_GLOBAL_SEARCH_FILTER_ENTITIES, {
        ...getEntitiesTrackingData()
      });
    }

    if (!loadingData && menuItems.current.size === 0) {
      trackEvent(TRACKER_GLOBAL_SEARCH_NO_RESULT, {
        searchTerm: state.filters.search
      });
    }
  }, [loadingData]);

  useDontMountAtFirst(() => {
    if (!loadingData && menuItems.current.size > 0) {
      sortMenuItems();
      focusItem();
    }
  }, [loadingData, currentIndex]);

  const addMenuItemRef = useCallback(
    (itemRef: { current: GlobalSearchMenuItemType }) => {
      menuItems.current.add(itemRef);

      if (menuItems.current.size === renderedItemsCount && !loadingData) {
        focusItem();
      }
    },
    [renderedItemsCount, loadingData]
  );

  const navigate = useCallback(
    (url: string) => {
      history.replace(`/${url}`);
      _shortcutsDialogHandlerReactService.closeGlobalSearchDialog();
    },
    [history]
  );

  const showPipelines = state.pipelines.loadingData || state.pipelines.filteredEntities.length > 0;
  const showModels = state.models.loadingData || state.models.filteredEntities.length > 0;
  const showWorkflows = state.workflows.loadingData || state.workflows.filteredEntities.length > 0;
  const showDestinations =
    state.destinations.loadingData || state.destinations.filteredEntities.length > 0;
  const showActivations =
    state.activations.loadingData || state.activations.filteredEntities.length > 0;
  const showTargets = state.targets.loadingData || state.targets.filteredEntities.length > 0;

  const showSelectSourceList =
    !state.pipelines.loadingData &&
    ((!!state.filters.search && state.sourceTypes.loadingData) ||
      state.sourceTypes.filteredEntities.length > 0);

  const showSelectDestinationList =
    !state.destinations.loadingData &&
    ((!!state.filters.search && state.destinationTypes.loadingData) ||
      state.destinationTypes.filteredEntities.length > 0);

  const showDocResults =
    state.algoliaSearchResults.loadingData || state.algoliaSearchResults.entities.length > 0;

  const showEntities = loadingData || hasSearchResults;
  const showNoResultFound = !loadingData && !hasSearchResults;

  const showExcerpt =
    showExcerptShimmer ||
    (!showNoResultFound &&
      currentActiveEntity &&
      currentActiveEntity.entityType !== GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS);
  const showExcerptLoading = showExcerpt && loadingData;
  const showExcerptComponent = showExcerpt && !loadingData;

  return (
    <HdModal
      open={_shortcutsDialogHandlerReactService.isAdvancedGlobalSearchDialogOpen()}
      onClose={() => _shortcutsDialogHandlerReactService.closeGlobalSearchDialog()}
      placement='center'
      contentClassName={styles.dialogContent}
    >
      <div data-id={dataIdGenerator('')}>
        <div
          role='button'
          className={clsx({
            [styles.dialogHeader]: true,
            [styles.withNoBody]: !showBody
          })}
          tabIndex={0}
          data-id={dataIdGenerator('input-container')}
        >
          <SearchArea
            dataId={dataIdGenerator('')}
            autofocus
            onSearch={onSearch}
            keyDown={onSearchKeydown}
            placeholder='Search Anything'
            collapsible={false}
            debounceInterval={GLOBAL_SEARCH_DELAY}
            wrapperClassName={styles.searchWrapper}
            onRefUpdate={ref => {
              searchAreaRef.current = ref;
            }}
          />
        </div>

        {showBody ? (
          <div className={`${styles.dialogBody} d-flex`}>
            {showEntities ? (
              <div
                className={clsx({
                  [styles.entities]: true,
                  [styles.fullWidth]: !showExcerpt
                })}
              >
                <div ref={bodyRef} className={styles.itemsContainer}>
                  {showSelectSourceList ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.SOURCE_TYPES}
                      showFilteredEntitiesCount={false}
                      entityType={GlobalSearchEntity.SOURCE_TYPES}
                      entity={state[GlobalSearchEntity.SOURCE_TYPES]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showPipelines ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.PIPELINES}
                      entityType={GlobalSearchEntity.PIPELINES}
                      entity={state[GlobalSearchEntity.PIPELINES]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showSelectDestinationList ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.DESTINATION_TYPES}
                      showFilteredEntitiesCount={false}
                      entityType={GlobalSearchEntity.DESTINATION_TYPES}
                      entity={state[GlobalSearchEntity.DESTINATION_TYPES]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showDestinations ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.DESTINATIONS}
                      entityType={GlobalSearchEntity.DESTINATIONS}
                      entity={state[GlobalSearchEntity.DESTINATIONS]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showModels ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.MODELS}
                      entityType={GlobalSearchEntity.MODELS}
                      entity={state[GlobalSearchEntity.MODELS]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showWorkflows ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.WORKFLOWS}
                      entityType={GlobalSearchEntity.WORKFLOWS}
                      entity={state[GlobalSearchEntity.WORKFLOWS]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showActivations ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.ACTIVATIONS}
                      entityType={GlobalSearchEntity.ACTIVATIONS}
                      entity={state[GlobalSearchEntity.ACTIVATIONS]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showTargets ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.TARGETS}
                      entityType={GlobalSearchEntity.TARGETS}
                      entity={state[GlobalSearchEntity.TARGETS]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      className='border-bottom pb-4'
                    />
                  ) : null}

                  {showDocResults ? (
                    <GlobalSearchEntities
                      key={GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS}
                      entityType={GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS}
                      entity={state[GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]}
                      menuItems={menuItems.current}
                      addMenuItemRef={addMenuItemRef}
                      onEntitySelect={onEntitySelect}
                      onEntityClick={onEntityClick}
                      dataIdGenerator={dataIdGenerator}
                      navigate={navigate}
                      location={location}
                      showViewAllEntitiesCTA={false}
                      showFilteredEntitiesCount={false}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            {showExcerptLoading ? (
              <div className={styles.excerpt}>
                <GlobalSearchExcerptShimmer />
              </div>
            ) : null}

            {showExcerptComponent ? (
              <div className={styles.excerpt}>
                <GlobalSearchExcerpt
                  entity={currentActiveEntity}
                  navigate={navigate}
                  docLinksMap={docLinksMap}
                  onEntitySelect={onEntitySelect}
                  dataId={dataIdGenerator('excerpt')}
                  uuid={advancedGlobalSearchSessionUUID.current}
                />
              </div>
            ) : null}

            {showNoResultFound ? (
              <GlobalSearchEmptyResult
                searchTerm={state.filters.search}
                uuid={advancedGlobalSearchSessionUUID.current}
              />
            ) : null}
          </div>
        ) : null}

        <GlobalSearchFooter />
      </div>
    </HdModal>
  );
}
