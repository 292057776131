import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthPages } from '../../react/containers/auth/AuthPages';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'auth-pages-react',
  template: '',
  providers: [ BridgeService ]
})
export class AuthPagesReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      AuthPages,
      this._el.nativeElement,
      {}
    );
  }
}
