import { EnumVal } from './object-enum';

export class EntityStatus extends EnumVal {
  constructor(
    public name: string,
    public value: string,
    public colorType?: string,
    public description?: string
  ) {
    super(name);
  }
}
