import { StandardSourceType } from '../interfaces/source-type';
import { EntityTileProps } from '../../../../../components/Tiles/model';

export function SourceTypesTilesFactory(
  sourceTypes: StandardSourceType[],
  isFreePlanCustomer: boolean,
  disableSources: boolean,
  isEnterpriseEnabled: boolean
): EntityTileProps[] {
  return sourceTypes.map((sourceType, index) => ({
    id: `${sourceType.sourceType}-${index}`,
    sortOrder: sourceType.sortOrder,
    thumbnail: sourceType.sourceTypeLogoUrl,
    darkModeThumbnail: sourceType.sourceTypeDarkModeLogoUrl,
    title: sourceType.sourceTypeDisplay,
    data: sourceType,
    metaData: {
      primaryColor: sourceType.primaryColor,
      darkModePrimaryColor: sourceType.darkModePrimaryColor,
      bottomBadges: isEnterpriseEnabled ?  [
        {
          title: sourceType.isEdge ? 'Edge' : 'Standard',
          color: sourceType.isEdge ? 'purple-2' : 'success-1'
        }
      ] : [],
      badgeMetaData: {
        showNewBadge: false,
        showFailedBadge: false,
        showBetaBadge: sourceType.isBeta,
        showUpcomingBadge: sourceType.isUpcoming,
        showPrivateBetaBadge: sourceType.isPrivateBeta,
        showFreeBadge: showFreeBadge(sourceType, isFreePlanCustomer),
        showPaidBadge: showPaidSourceBadge(sourceType, isFreePlanCustomer),
        showOngoingTrialBadge: showOngoingTrialBadge(sourceType),
        showEarlyAccessBadge: showEarlyAccessBadge(sourceType)
      },
      docLink: sourceType.docLink,
      validationText: null,
      faded: false,
      selected: false,
      tileIcon: null
    },
    disabled: disableSources
  }));
}

export function showFreeBadge(sourceType: StandardSourceType, isFreePlanCustomer: boolean): boolean {
  if (!isFreePlanCustomer || showEarlyAccessBadge(sourceType)) {
    return false;
  }

  return (
    sourceType.isFree &&
    (sourceType.daysLeftInTrial === undefined || sourceType.daysLeftInTrial <= 0)
  );
}

export function showPaidSourceBadge(
  sourceType: StandardSourceType,
  isFreePlanCustomer: boolean
): boolean {
  if (!isFreePlanCustomer || showEarlyAccessBadge(sourceType)) {
    return false;
  }

  return (
    !sourceType.isFree &&
    (sourceType.daysLeftInTrial === undefined || sourceType.daysLeftInTrial <= 0)
  );
}

export function showOngoingTrialBadge(sourceType: StandardSourceType) {
  if (showEarlyAccessBadge(sourceType)) {
    return false;
  }

  return !sourceType.isDisabled && !sourceType.isUnused && sourceType.daysLeftInTrial > 0;
}

export function showEarlyAccessBadge(sourceType: StandardSourceType) {
  return sourceType.isEarlyAccess;
}
