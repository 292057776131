import { Component, ElementRef, OnInit } from '@angular/core';
import ShortcutsDialog from '../../react/containers/shortcuts/ShortcutsDialog';
import { BridgeService } from '../react-bridge/services/bridge.service';
import { ShortcutsDialogHandlerReactService } from './service/shortcuts-dialog-handler-react.service';


@Component({
  selector: 'keyboard-shortcuts-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class KeyboardShortcutsDialogReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef,
    private _shortcutsDialogHandlerReactService: ShortcutsDialogHandlerReactService
  ) {
  }

  ngOnInit() {
    this.renderComponent();
  }

  private renderComponent() {
    this._reactBridgeService.mount(ShortcutsDialog, this._el.nativeElement, {
      isOpen: this._shortcutsDialogHandlerReactService.isKeyboardShortcutDialogOpen.bind(this._shortcutsDialogHandlerReactService),
      openDialog: this._shortcutsDialogHandlerReactService.openKeyboardShortcutDialog.bind(this._shortcutsDialogHandlerReactService),
      closeDialog: this._shortcutsDialogHandlerReactService.closeKeyboardShortcutDialog.bind(this._shortcutsDialogHandlerReactService)
    });
  }
}
