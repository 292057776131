import { appendQueryParams } from './url';
import { createHttpParams } from './request';
import { User } from '../../app/core/models/user';

export function appendTrackingParams(path: string, section: string, user: User, properties?: { [key: string]: any }) {
  let params = createHttpParams(properties || {});
  params = params.set('ui_section', section);
  params = params.set('app_user', user.email);
  return appendQueryParams(path, params.toString());
}
