export class NodeStrings {
  static emptyDatasetAlertMessage = 'Datasets without tables will not be included in the Pipeline. ' +
    'Any new tables in the selected datasets will automatically be included in the Pipeline as and when they are added to BigQuery.';

  static getConnectionErrorSupportMessage(nodeType: string, nodeCategory: string, error: string) {
    return `Hi there, I am getting the following error while setting up ` +
      `${ nodeType } ${ nodeCategory } connection. ` +
      `Can you help me out?\n "${ error }"`;
  }

  static getPageTimeoutSupportMessage(nodeType: string, nodeCategory: string) {
    return `Hey, I am facing issues while connecting my ${ nodeType } ` +
    `${ nodeCategory }. Can you help?`;
  }
}
