import React from 'react';

export const ERROR_OUTLINE_STYLE: React.CSSProperties = {
  borderRadius: 'var(--border-radius-md)',
  outline: '1px solid var(--error-color)',
  outlineOffset: '-1px'
};

export const ERROR_BORDER_STYLE: React.CSSProperties = {
  borderRadius: 'var(--border-radius-md)',
  border: '1px solid var(--error-color)'
};
