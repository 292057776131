import { ActivatedRoute } from '@angular/router';
import { GOOGLE_LOGIN_ERROR_QUERY_PARAM } from '../constants';

export function getGoogleLoginError(route: ActivatedRoute) {
  try {
    const errorEncoded = route.snapshot.queryParamMap.get(GOOGLE_LOGIN_ERROR_QUERY_PARAM);

    if (typeof errorEncoded === 'string' && errorEncoded.length) {
      return atob(errorEncoded);
    }
  } catch (e) {}
}

export function getDecodedStateObject(encodedState: string) {
  const decodedState = atob(encodedState);
  const decodedStateString = decodedState.split('|')[0];

  try {
    return JSON.parse(decodedStateString);
  } catch (e) {
    return null;
  }
}

export function getErrorRedirectURLFromEncodedState(encodedState: string) {
  const decodedStateObject = getDecodedStateObject(encodedState);

  if (decodedStateObject && decodedStateObject['error_path']) {
    return decodedStateObject['error_path'];
  } else {
    return '/login';
  }
}
