import { FieldTypes, SourceFormFieldDefinition } from '../models';


export const ZENDESK_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'subDomain',
      fieldId: 'sub-domain',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'subDomain',
        label: 'Subdomain',
        suffixText: '.zendesk.com',
        required: true
      },
      hint: 'The subdomain of your Zendesk account. For example, in https://xyz.zendesk.com, the domain is xyz.',
      buildParamsKey: 'sub_domain',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'email',
      fieldId: 'email',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'email', label: 'Email', required: true },
      hint: 'The login email ID for your Zendesk account.',
      buildParamsKey: 'email',
      validations: [
        {
          type: 'required'
        },
        {
          type: 'email'
        }
      ]
    }
  ],
  [
    {
      key: 'token',
      fieldId: 'token',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'token', label: 'API token', required: true },
      hint: 'Your Zendesk API token',
      sbsDocLink: '/sources/crm/zendesk/#generating-a-zendesk-api-token',
      buildParamsKey: 'token',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
