import { FilterOption } from '../models/filter-option';


export const FILTER_ALL_KEY = 'filter_all';
export const DEFAULT_GROUP_NAME = 'Default Group';
export const DEFAULT_GROUP_KEY = 'default_group';

export const MOCK_LIST_FILTER_OPTIONS: FilterOption<string>[] = [
  {
    name: 'Active',
    value: 'ACTIVE'
  },
  {
    name: 'Inactive',
    value: 'INACTIVE'
  },
  {
    name: 'Failed',
    value: 'FAILED'
  },
  {
    name: 'Has Failed Events',
    value: 'HAS_FAILED_EVENTS'
  },
  {
    name: 'Needs Mapping',
    value: 'AWAITING_MAPPING'
  }
];
