// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.editorWrapper_EW6MC {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  height: 300px;\n  overflow: hidden;\n}\n.editorWrapper_EW6MC.autoHeight_FCPw_ {\n  height: auto;\n}\n\n.editor_SxsXb {\n  overflow: hidden;\n  flex: 1;\n  width: 100%;\n  z-index: 1;\n}\n\n.floatingActions_NaFOI {\n  position: absolute;\n  right: 16px;\n  top: 8px;\n  z-index: 2;\n}\n\n.floatingAction_ONOZa + .floatingAction_ONOZa {\n  margin-top: 16px;\n}\n\n.resizeCtrlV_n6IpM {\n  flex-shrink: 0;\n  background: var(--surface-bg-alternate-color);\n  border-top: solid 1px var(--divider-color);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: row-resize;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorWrapper": "editorWrapper_EW6MC",
	"autoHeight": "autoHeight_FCPw_",
	"editor": "editor_SxsXb",
	"floatingActions": "floatingActions_NaFOI",
	"floatingAction": "floatingAction_ONOZa",
	"resizeCtrlV": "resizeCtrlV_n6IpM"
};
export default ___CSS_LOADER_EXPORT___;
