import React, { useState } from 'react';
import { getErrorMessageFromObj, getSuccessMessageFromObj } from '../../legacy-utils/request';
import FormApiSuccess from '../../components/FormApiSuccess';
import { HdResolvedComponent } from '../../components/Routing/HdResolvedComponent';
import { HdButton } from '../../components/UIElements';
import HdIcon from '../../components/UIElements/HdIcon';
import HdPane from '../../components/UIElements/HdPane';
import { useEmailVerifiedGuard } from '../../hooks/useEmailVerifiedGuard';
import { getDataIdGenerator } from '../../utils/generateDataId';
import AuthAPI from '../auth/AuthAPI';

export function EmailVerificationBlock() {
  const { executeEmailVerifiedGuard } = useEmailVerifiedGuard();

  return (
    <HdResolvedComponent
      Component={EmailVerificationBlockInner}
      canActivate={[ executeEmailVerifiedGuard ]}
    />
  );
}

export function EmailVerificationBlockInner() {
  const [ sendingLink, setSendingLink ] = useState(false);
  const [ resendSuccess, setResendSuccess ] = useState(null);
  const [ resendError, setResendError ] = useState(null);

  const resendVerificationLink = () => {
    setSendingLink(true);

    AuthAPI.resendVerificationLink().then(
      (res: any) => {
        setSendingLink(false);
        setResendSuccess(getSuccessMessageFromObj(res));
      },
      (err) => {
        setSendingLink(false);
        setResendError(getErrorMessageFromObj(err));
      }
    );
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const dataIdGenerator = getDataIdGenerator('email-verification-block');

  return (
    <div className='no-item-box errored'>
      <div className='no-item-box-icon-container'>
        <HdIcon className='no-item-box-icon' name='email-warning' />
      </div>

      <div className='no-item-box-title'>Your Email Is Not Verified</div>

      <div className='no-item-box-desc'>
        Please verify your email to start moving all your data to your Destinations.
      </div>

      <div className='no-item-actions-container'>
        <HdButton
          dataId={dataIdGenerator('resend-link')}
          showProgress={sendingLink}
          onClick={resendVerificationLink}
        >
          Resend Verification Link
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('refresh-page')}
          variation='outline'
          className='ml-3'
          onClick={refreshPage}
        >
          Refresh Page
        </HdButton>
      </div>

      {resendSuccess ? (
        <FormApiSuccess
          className='mt-6'
          message={resendSuccess}
          onClose={() => setResendSuccess(null)}
        />
      ) : null}

      {resendError ? (
        <HdPane
          className='mt-6'
          variant='error'
          icon='error-filled'
        >
          {resendError}
        </HdPane>
      ) : null}
    </div>
  );
}
