import { Slide } from '@mui/material';
import React from 'react';
import ScrollToTopButton from '../ScrollToTopButton';
import { HdLink, HdIcon } from '../UIElements';
import HdIndeterminateProgressBar from '../UIElements/HdProgressBar/IntedeterminateProgressBar';
import { NotificationComponent } from './Notification';
import { NotificationShimmer } from './NotificationShimmer';
import styles from './styles.module.scss';
import { useNotifications } from './hooks/useNotifications';
import HdLinearProgress from '../UIElements/HdLinearProgress';

export function NotificationsList({ dataSource, entity }) {
  const {
    loadingTop,
    initialLoading,
    newCount,
    getMostRecent,
    notifications,
    loadingBottom,
    canTriggerNotificationsFetchAPI,
    topSentryRef,
    bottomSentryRef,
    delimiterPosition
  } = useNotifications({
    dataSource,
    entity
  });

  return (
    <>
      {!initialLoading && loadingTop ? (
        <HdLinearProgress className='drawer-header__progress-bar' />
      ) : null}

      <Slide
        direction='down'
        in={!loadingTop && !initialLoading && !!newCount}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.jumpToastContainer}>
          <div className={styles.jumpToast}>
            <span>{newCount} New Alerts.</span>

            <HdLink
              dataId='get-most-recent'
              tag='a'
              color='light'
              className='ml-1 text-link'
              onClick={() => {
                getMostRecent();
              }}
            >
              Refresh
            </HdLink>
          </div>
        </div>
      </Slide>

      {!initialLoading && notifications && notifications.length ? (
        <div
          className={styles.notificationsContainer}
          onScroll={() => {
            if (!loadingBottom && !loadingTop) {
              canTriggerNotificationsFetchAPI.current = true;
            }
          }}
          id='notifications-scroll-container'
        >
          <div ref={topSentryRef} />
          {notifications.slice(0, delimiterPosition).map((notification, index) => (
            <NotificationComponent
              key={notification.id}
              data={notification}
              noBorder={index === delimiterPosition - 1}
            />
          ))}

          {delimiterPosition && delimiterPosition < notifications.length ? (
            <div className={styles.newNotificationSeparator} data-id='delimiter-position'>
              <div className={styles.text}>Older Alerts</div>
            </div>
          ) : null}

          {notifications.slice(delimiterPosition, notifications.length).map(notification => (
            <NotificationComponent key={notification.id} data={notification} noBorder={false} />
          ))}

          {loadingBottom && <NotificationShimmer />}

          <div ref={bottomSentryRef} />

          {!loadingTop && (
            <ScrollToTopButton
              variant='default'
              scrollViewportId='notifications-scroll-container'
            />
          )}
        </div>
      ) : null}

      {initialLoading ? (
        <div className='drawer-loading' data-id='indeterminate-loader'>
          <HdIndeterminateProgressBar>Hevo is loading recent alerts...</HdIndeterminateProgressBar>
        </div>
      ) : null}

      {!initialLoading && !notifications.length ? (
        <div className='drawer-list__empty-data no-item-box' data-id='no-item-box'>
          <div className='no-item-box-icon-container'>
            <HdIcon name='alerts' className='no-item-box-icon' />
          </div>
          <div className='no-item-box-title'>No Alerts Found</div>
        </div>
      ) : null}
    </>
  );
}
