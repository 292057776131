import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const QUICKBASE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPITokenField({
        hint: 'The API key generated from Home → My Preferences → Manage User Tokens → New user Token.',
        externalDocLink: 'https://developer.quickbase.com/auth'
      })
    }
  ],
  [
    {
      key: 'appId',
      fieldId: 'appid',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'appId', label: 'App ID', required: true },
      hint: 'The App ID generated from My Apps → Your required App → 9-digit ID in URL of the App Page.',
      buildParamsKey: 'app_id',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'subdomain',
      fieldId: 'subdomain',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'subdomain', label: 'Subdomain', required: true },
      hint: 'The Subdomain generated from My Apps → Your required App → name of the domain in URL of the App Page.',
      buildParamsKey: 'subdomain',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
