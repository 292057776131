import { getBarGraphDisplayTime } from '../../../legacy-utils/bar-graph';
import { ActivationStatsDataPoint } from '../ActivationSyncHistory/models/stats-data';


export function StatsDataPointsFactory(rawData: any[]): ActivationStatsDataPoint[] {
  if (!rawData) {
    return [];
  }

  return rawData.map(rawStats => StatsDataPointFactory(rawStats));
}

export function StatsDataPointFactory(rawData: any): ActivationStatsDataPoint {
  if (!rawData) {
    return;
  }

  return {
    startTime: rawData.start_time,
    endTime: rawData.end_time,
    total: rawData.total,
    retry: rawData.retry || 0,
    failed: rawData.failed || 0,
    syncBehaviourStats: {
      archive: rawData.archive || 0,
      delete: rawData.delete || 0,
      insert: rawData.insert || 0,
      update: rawData.update || 0,
      upsert: rawData.upsert || 0
    },
    timeDisplay: getBarGraphDisplayTime(rawData.start_time, rawData.end_time)
  };
}
