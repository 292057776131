import { SourceFormFieldDefinition } from '../../models';
import { TemplateConfig } from './template-config';

export default function modifyFieldDefinition(
  template: SourceFormFieldDefinition,
  templateConfig: TemplateConfig
): SourceFormFieldDefinition {
  const { docLink, sbsDocLink, externalDocLink, widget, buildParamsKey } = templateConfig;
  const updatedTemplate = { ...template };

  if (docLink) {
    updatedTemplate.docLink = docLink;
  }

  if (sbsDocLink) {
    updatedTemplate.sbsDocLink = sbsDocLink;
  }

  if (externalDocLink) {
    updatedTemplate.externalDocLink = externalDocLink;
  }

  if (widget) {
    updatedTemplate.widget = widget;
  }

  if (buildParamsKey) {
    updatedTemplate.buildParamsKey = buildParamsKey;
  }

  return updatedTemplate;
}
