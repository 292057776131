import { Injectable } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { PriorityQueue, PriorityQueueItem } from '../../../react/legacy-utils/priority-queue';
import { BannerOutlet } from '../banner-outlet/banner-outlet';
import { DEFAULT_BANNER_PRIORITY } from '../constants';
import { BannerNotification, BannerNotifier } from '../interface';


@Injectable()
export class BannerService {
  private _pQueue = new PriorityQueue<BannerNotification<any>>((ele) => ele.id);
  private _notifiers: BannerNotifier[] = [];
  private _notificationSubject = new Subject<BannerNotification<any>>();
  private _notificationCloseSubject = new Subject<void>();
  private _outlet: BannerOutlet;
  private _pendingNotification: BannerNotification<any>;
  private _currentItem: PriorityQueueItem<BannerNotification<any>>;

  private _pushNotificationEffect$ = this._notificationSubject.pipe(
    tap((notification) => {
      /**
       * If current notification has same id as the requested notification
       * and replace is false do nothing
       */
      if (notification.replace === false && this._currentItem && this._currentItem.data.id === notification.id) {
        return;
      }

      /**
       * If new notification's id is same as the one that's visible
       * or one that is in queue remove current item or the queue ref.
       * This will ensure that new notification
       * replaces the previously opened/queued one.
       */
      this._removeNotificationIfQueued(notification.id);

      this._pQueue.insert({
        data: notification,
        priority: this._getNotifierById(notification.id).priority
      });
    })
  );

  constructor() {
    this._handleNotifications();
  }

  registerOutlet(outlet: BannerOutlet) {
    this._outlet = outlet;
    if (this._pendingNotification) {
      this._outlet.showNotification({
        notification: this._pendingNotification,
        notifier: this._getNotifierById(this._pendingNotification.id)
      });
    }

    this._outlet.closeObs().pipe(
      tap(() => {
        this._notificationCloseSubject.next();
      }),
      takeUntil(this._outlet.destroyed$)
    ).subscribe();
  }

  registerNotifiers(notifiers: BannerNotifier[]) {
    notifiers.forEach((notifier) => {
      this.registerNotifier(notifier);
    });
  }

  registerNotifier(notifier: BannerNotifier) {
    if (!notifier.templateRef && !notifier.component) {
      throw new Error('BannerNotifier expects a templateRef or component');
    }

    const n = { ...notifier };

    if (!notifier.priority) {
      n.priority = DEFAULT_BANNER_PRIORITY;
    }

    this._notifiers.push(n);
  }

  notify<T>(notification: BannerNotification<T>) {
    this._notificationSubject.next(notification);
  }

  removeNotification(id: string) {
    this._removeNotificationIfQueued(id);
  }

  private _removeNotificationIfQueued(id: string) {
    if (this._currentItem && this._currentItem.data.id === id) {
      this._currentItem = undefined;

      if (typeof this._outlet !== 'undefined') {
        this._outlet.clear();
      }
      return;
    }

    const existingRef = this._pQueue.items().findIndex((item) => {
      return item.data.id === id;
    });

    if (existingRef >= 0) {
      this._pQueue.removeAt(existingRef);
    }
  }

  clear() {
    this._pQueue.clear();
    this._pendingNotification = null;
    this._currentItem = null;

    if (typeof this._outlet !== 'undefined') {
      this._outlet.clear();
    }
  }

  private _handleNotifications() {
    combineLatest([
      this._pushNotificationEffect$,
      this._notificationCloseSubject.pipe(
        startWith(null),
        tap(() => {
          this._currentItem = null;
        })
      )
    ]).pipe(
      tap(() => {
        const pqItem: PriorityQueueItem<BannerNotification<any>> = this._pQueue.peek();
        let showNotification = false;

        if (!pqItem) {
          return;
        }

        if (!this._currentItem || pqItem.priority > this._currentItem.priority) {
          showNotification = true;
        }

        if (showNotification) {
          this._insertOpenedItemBackIntoQueue();
          this._currentItem = pqItem;
          this._pQueue.pop();
          this._showNotification(pqItem.data);
        }
      })
    ).subscribe();
  }

  private _insertOpenedItemBackIntoQueue() {
    if (this._currentItem) {
      this._pQueue.insert(this._currentItem);
    }
  }

  private _getNotifierById(id: string) {
    return this._notifiers.find((n) => {
      return n.id === id;
    });
  }

  private _showNotification(n: BannerNotification<any>) {
    if (typeof this._outlet === 'undefined') {
      this._pendingNotification = n;
      return;
    }

    this._outlet.showNotification({
      notification: n,
      notifier: this._getNotifierById(n.id)
    });
  }
}
