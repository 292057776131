export const DEFAULT_NOTIFICATIONS_PAGE_SIZE = 10;

export const SEVERITY_CLASS_MAP: { [key: string]: string } = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warning',
  WARNING: 'warning',
  CRITICAL: 'error',
  ERROR: 'error'
};
