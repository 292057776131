import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPRequestMethodEnum, NetworkRequestOptions } from '../models/request';
import { convertToPromise } from '../../../react/legacy-utils/promise';
import { RxRequestService } from './rx-request.service';

export let courier: CourierService;

export interface CourierNetworkRequestOptions extends NetworkRequestOptions {
  signal?: AbortSignal;
}

@Injectable({ providedIn: 'root' })
export class CourierService {
  constructor(
    private _rxRequestService: RxRequestService,
    private _ngZone: NgZone
  ) {
    courier = this;
  }

  get(url: string, networkOpts: CourierNetworkRequestOptions = {}) {
    return this._convertToPromise(this._rxRequestService.get(url, networkOpts), networkOpts);
  }

  post(url: string, networkOpts: CourierNetworkRequestOptions = {}, params?: any) {
    return this._convertToPromise(this._rxRequestService.post(url, networkOpts, params), networkOpts);
  }

  put(url: string, networkOpts: CourierNetworkRequestOptions = {}, params?: any) {
    return this._convertToPromise(this._rxRequestService.put(url, networkOpts, params), networkOpts);
  }

  delete(url: string, networkOpts: CourierNetworkRequestOptions = {}) {
    return this._convertToPromise(this._rxRequestService.delete(url, networkOpts), networkOpts);
  }

  sendRequest(
    method: HTTPRequestMethodEnum,
    url: string,
    params?: any,
    opts: NetworkRequestOptions = {}) {
    return this._convertToPromise(this._rxRequestService.sendRequest(method, url, params, opts), opts);
  }

  sendBeacon(url: string, data: any) {
    if (navigator && typeof navigator.sendBeacon === 'function') {
      try {
        return navigator.sendBeacon(
          url,
          data
        );
      } catch (e) {
        return false;
      }
    }
  }

  private _convertToPromise(observable: Observable<any>, networkOpts: CourierNetworkRequestOptions) {
    return this._ngZone.runTask(() => {
      return convertToPromise(observable, networkOpts.signal);
    });
  }
}
