import { Component, Input } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Subscription, timer } from 'rxjs';
import { TRACKING_ON_HOVER_REPLAY_PROGRESS } from '../constants';
import { SidelineGroupReplayProgress } from '../models/sideline-group-replay-progress';


@Component({
  selector: 'replay-progress',
  templateUrl: './replay-progress.component.html',
  styleUrls: [ './replay-progress.component.scss' ]
})

export class ReplayProgressComponent {
  @Input() replayProgress: SidelineGroupReplayProgress;

  private _subscription: Subscription;

  constructor(private _angulartics: Angulartics2) { }

  replayProgressTracking() {
    this._subscription = timer(500).subscribe(() => {
      this._angulartics.eventTrack.next({
        action: TRACKING_ON_HOVER_REPLAY_PROGRESS
      });
    });
  }

  checkHoverTime() {
    this._subscription.unsubscribe();
  }
}
