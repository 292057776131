import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { MenuItemTypeMap } from '@mui/material/MenuItem/MenuItem';
import React, { ReactElement, useMemo, PropsWithChildren } from 'react';
import {
  MenuItem,
  MenuItemProps,
  styled,
  PopperProps,
  ClickAwayListener,
  MenuList,
  Popper,
  TooltipProps
} from '@mui/material';
import { RbacPermissions } from '../../../../app/core/models/user';
import useHasPermission from '../../../hooks/useHasPermission';
import { RBAC_DISABLED_MESSAGE } from '../../../../app/core/models/rbac';
import { HdTooltip } from '../HdTooltip';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export interface HdMenuPopperProps extends PopperProps {
  onClose?: (e?: MouseEvent) => void;
  target: string;
  dataId?: string;
  children: React.ReactNode;
}

export type HdMenuProps = PropsWithChildren<HdMenuPopperProps & { menuListClassName?: string }>;

const HdPopper = styled(Popper)(({ theme }) => ({
  boxShadow: 'var(--shadow-dropdown)',
  borderRadius: 'var(--border-radius-md)',
  maxWidth: 250,
  zIndex: 'var(--zindex-tooltip-react)',
  border: 'solid 1px var(--divider-color)',
  background: 'var(--surface-bg-secondary-color)',
  marginTop: theme.spacing(1),
  minWidth: 160,
  backgroundColor: 'var(--surface-bg-secondary-color)',
  flexDirection: 'column',
  display: 'flex',
  overflow: 'hidden',
  margin: 0,
  transition: 'none !important',
  transformOrigin: 'unset !important',
  '& .MuiList-root': {
    overflowY: 'auto',
    maxHeight: '220px',
    width: '100%',
    padding: 0
  }
}));

// eslint-disable-next-line react/function-component-definition
export const HdMenuPopper = ({
  open,
  onClose,
  children,
  placement = 'bottom-end',
  target,
  dataId = '',
  ...props
}: HdMenuPopperProps) => {
  const focusTarget = () => {
    const elementId = target?.split('#')[1] || null;
    if (elementId) {
      document.getElementById(elementId).focus();
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      onClose();
      focusTarget();
    }
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.stopPropagation();
      onClose();
      focusTarget();
    }
  };

  const anchorEl = useMemo(() => document.querySelector(target), [open]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {anchorEl ? (
        <HdPopper open={open} anchorEl={anchorEl} {...props}>
          <div className='menu-container' data-id={dataId}>
            <ClickAwayListener onClickAway={onClose}>
              <div onKeyDown={handleListKeyDown}>{children}</div>
            </ClickAwayListener>
          </div>
        </HdPopper>
      ) : null}
    </>
  );
};

// eslint-disable-next-line react/function-component-definition
export const HdMenu = ({ open, children, menuListClassName = '', ...props }: HdMenuProps) => (
  <HdMenuPopper open={open} {...props}>
    <MenuList className={menuListClassName} autoFocusItem={open}>
      {children}
    </MenuList>
  </HdMenuPopper>
);

interface HdRbacMenuItemProps extends MenuItemProps {
  rbacPermission: RbacPermissions;
  tooltipContent?: ReactElement | string;
  disabledToolTipContent?: ReactElement | string;
  placement?: TooltipProps['placement'];
  dataId?: string;
}

export function HdRbacMenuItem({
  rbacPermission,
  tooltipContent,
  disabledToolTipContent,
  disabled,
  placement,
  ...props
}: HdRbacMenuItemProps) {
  const { hasPermission } = useHasPermission();
  const isRbacActionEnabled = hasPermission(rbacPermission);

  const tooltip = useMemo(() => {
    if (!isRbacActionEnabled) {
      return RBAC_DISABLED_MESSAGE;
    }

    if (disabled) {
      return disabledToolTipContent;
    }

    return tooltipContent;
  }, [disabled, disabledToolTipContent, isRbacActionEnabled, tooltipContent]);

  const isDisabled = !isRbacActionEnabled || disabled;

  return (
    <HdTooltip title={tooltip} disabled={!tooltip} placement={placement}>
      <span>
        <HdMenuItem disabled={isDisabled} {...props} />
      </span>
    </HdTooltip>
  );
}
interface HdMenuItemProps {
  dataId?: string;
}

const dataIdGenerator = getDataIdGenerator('menu-item');
export const HdMenuItem: ExtendButtonBase<MenuItemTypeMap<HdMenuItemProps>> = styled(
  ({ dataId = '', ...props }: HdMenuItemProps) => (
    <MenuItem disableRipple data-id={dataIdGenerator(dataId)} {...props} />
  )
)(() => ({
  '&.MuiMenuItem-root': {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '12px 16px',
    color: 'var(--text-default-color)',
    textShadow: 'none',
    flexShrink: 0,
    fontWeight: 600
  },
  '&.Mui-selected:not(.Mui-disabled)': {
    background: 'var(--primary-faded-color)',
    position: 'relative',

    '&::after': {
      fontFamily: '"hevo-pack"',
      content: '"\\e989"',
      position: 'absolute',
      right: 'calc(var(--spacer) * 0.75)',
      color: 'var(--primary-color)'
    }
  },
  '&.MuiMenuItem-root:&:hover:not(.active):not(.Mui-disabled),&:focus:not(.active):not(.origin-mouse):not(.Mui-disabled)':
    {
      background: 'var(--hover-bg-color)'
    },
  '&.MuiMenuItem-root.warning': {
    color: 'var(--warning-color)'
  },
  '&.MuiMenuItem-root.error': {
    color: 'var(--error-color)'
  },
  '&.MuiMenuItem-root.has-separator': {
    borderTop: 'solid 1px var(--divider-color)'
  },
  '&.Mui-disabled': {
    color: 'var(--text-secondary-color)',
    opacity: 1,
    cursor: 'default'
  },
  '&.MuiMenuItem-root .hevo-icon': {
    marginRight: 'calc(var(--spacer) * .75)'
  }
}));
