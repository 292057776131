export const TIMESTAMP_FORMATS = {
  lastDay: '[Yesterday], HH:mm:SS',
  sameDay: '[Today],  HH:mm:SS',
  lastWeek: 'DD/MM/YYYY,  HH:mm:SS',
  sameElse: 'DD/MM/YYYY,  HH:mm:SS'
};

export const PIPELINE_FILTER_KEY = 'pipeline';
export const DESTINATION_FILTER_KEY = 'destination';
export const STATUS_FILTER_KEY = 'status';
export const TIME_FILTER_KEY = 'time';
export const JOB_SEARCH_FILTER_KEY = 'search';
export const DEFAULT_PAGE = 0;
export const DEFAULT_LIMIT = 20;
export const PAGINATION_STRATEGY = 'offset';

export const DEFAULT_FILTERS_SELECTION = {
  [PIPELINE_FILTER_KEY]: [],
  [DESTINATION_FILTER_KEY]: [],
  [STATUS_FILTER_KEY]: [],
  [JOB_SEARCH_FILTER_KEY]: ''
};

export const STAT_ICON_MAP = {
  startedAt: 'time',
  duration: 'time-left',
  objectsSuccess: 'checked-tick',
  objectsQueued: 'pause',
  objectsFailed: 'error-outline',
  eventsIngested: 'jobs',
  eventsLoaded: 'load-status',
  eventsFailed: 'error-outline',
  billableEvents: 'cards-payment',
  nonBillableEvents: 'no-cards'
};

export const SUCCESS_ICON_SVG =
  'https://res.cloudinary.com/hevo/image/upload/v1702564812/hevo-website/icons/progress-success_d3ejyf.svg';

export const IN_PROGRESS_ICON_SVG =
  'https://res.cloudinary.com/hevo/image/upload/v1702564811/hevo-website/icons/progress-in_progress_nea7fk.svg';

export const SUCCESS_ICON_DARK_MODE_SVG =
  'https://res.cloudinary.com/hevo/image/upload/v1704220334/hevo-website/icons/progress_complete_dark_mode_negplu.svg';
export const IN_PROGRESS_ICON_DARK_MODE_SVG =
  'https://res.cloudinary.com/hevo/image/upload/v1704220334/hevo-website/icons/progress_in_progress_dark_mode_tew3kb.svg';
