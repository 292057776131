import React, { useEffect, useState } from 'react';
import { Popover, styled } from '@mui/material';
import styles from '../ProductTourPopover/styles.module.scss';
import SVGMask from '../SVGMask';
import useForceUpdate from '../../hooks/useForceUpdate';
import { AnchorShape } from '../../../app/product-tour/product-tour-dialog/interface';
import { useProductTour } from './useProductTour';
import { TourStep, TourStepType } from '../../../app/product-tour-v2/model';
import { TourStepProps } from './model';

// eslint-disable-next-line no-shadow
export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export const ARROW_ICON =
  'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-end-in_b9v9xn.svg';

export function ProductTourPopover() {
  const {
    activeStep,
    showNextStep,
    showPrevStep,
    endCurrentTour,
    activeTour,
    tourConductorMetadata
  } = useProductTour();
  const [tourElements, setTourElements] = useState<{
    [tourElement: string]: {
      stepData: TourStep;
      index: number;
    };
  }>({});

  const [currentStep, setCurrentStep] = useState<TourStep>();

  const forceUpdate = useForceUpdate();

  const handleResize = () => {
    forceUpdate();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentStep(activeStep);
    if (activeStep?.type === TourStepType.TOUR_CARD) {
      setTourElements(values => ({
        ...values,
        [activeStep.id]: {
          stepData: activeStep,
          index: tourConductorMetadata?.stepIndex
        }
      }));
    }

    if (!activeTour) {
      setTimeout(() => {
        setTourElements({});
      }, 0);
    }
  }, [activeStep, tourConductorMetadata?.stepIndex, activeTour]);

  return (
    <>
      {Object.entries(tourElements).map(([stepId, data]) => {
        const isOpen = currentStep?.id === stepId;
        let props: any = {
          currentStep: data.stepData,
          currentIndex: data.index,
          activeTour
        };
        if (isOpen) {
          props = {
            ...props,
            endCurrentTour,
            showNextStep,
            showPrevStep
          };
        } else {
          props = {
            ...props,
            endCurrentTour: () => {},
            showNextStep: () => {},
            showPrevStep: () => {}
          };
        }

        return <TourCardComponent key={stepId} open={isOpen} {...props} />;
      })}
    </>
  );
}

function TourCardComponent({
  currentStep,
  currentIndex,
  endCurrentTour,
  showNextStep,
  showPrevStep,
  activeTour,
  open
}: any) {
  const anchorElement = document.querySelector(`[id="${currentStep?.id}"]`);

  if (!anchorElement) {
    return null;
  }

  // extract all the props for current render step item
  const {
    anchorShape = 'rectangle' as AnchorShape,
    anchorInteractive = false,
    anchorPadding,
    anchorAbsolute,
    anchorPosition,
    anchorOrigin,
    transformOrigin,
    arrowOrigin = { vertical: '', horizontal: '' },
    popoverOrigin = { vertical: 0, horizontal: 0 }
  } = currentStep?.tourCardProps || {};

  const getArrowOriginMetaDeta = () => {
    let className = '';
    const positionBottom =
      arrowOrigin.vertical === Position.BOTTOM ||
      (arrowOrigin.vertical === Position.CENTER && arrowOrigin.horizontal === Position.RIGHT);

    if (arrowOrigin.vertical === Position.TOP && arrowOrigin.horizontal === Position.RIGHT) {
      className = styles[arrowOrigin.horizontal];
    } else {
      className = styles.show;
    }

    if (arrowOrigin.vertical === Position.BOTTOM) {
      className = styles[arrowOrigin.horizontal];
    }

    if (arrowOrigin.vertical === Position.CENTER) {
      className = styles[`rotate${arrowOrigin.horizontal}`];
    }
    return {
      className,
      // eslint-disable-next-line no-nested-ternary
      position: positionBottom ? Position.BOTTOM : arrowOrigin.vertical !== '' ? Position.TOP : ''
    };
  };

  const anchorDimensions: DOMRect = anchorElement.getBoundingClientRect();
  const anchorRelativePosition = { top: anchorDimensions.top, left: anchorDimensions.left };
  const arrowOriginMetaData = getArrowOriginMetaDeta();
  const TourComponent = currentStep.component as React.FC<TourStepProps>;

  const popoverFlexRowClass = arrowOrigin.vertical === Position.CENTER ? styles.sideBySide : '';
  const popoverOriginPadding = `${popoverOrigin.vertical}px ${popoverOrigin.horizontal}px`;

  return (
    <div>
      {open && (
        <SVGMask
          sizes={anchorDimensions}
          padding={anchorPadding || 0}
          anchorShape={anchorShape}
          highlightedAreaClickable={anchorInteractive}
        />
      )}

      <StyledPopover
        open={open}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorReference={anchorAbsolute || anchorPosition ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={anchorPosition || anchorRelativePosition}
        anchorEl={anchorElement}
      >
        <div style={{ padding: popoverOriginPadding }}>
          <div className={`${styles.tourWrapper} ${popoverFlexRowClass}`}>
            {arrowOriginMetaData.position === Position.TOP && (
              <div className={`${styles.tourDirection} ${arrowOriginMetaData.className}`}>
                <img alt='tour-direction' src={ARROW_ICON} />
              </div>
            )}

            <div className={styles.tourContainer}>
              {TourComponent ? (
                <TourComponent
                  endTour={endCurrentTour}
                  nextStep={showNextStep}
                  prevStep={showPrevStep}
                  stepId={currentStep.id}
                  stepIndex={currentIndex}
                  activeTour={activeTour}
                  {...(currentStep.componentProps || {})}
                />
              ) : null}
            </div>

            {arrowOriginMetaData.position === Position.BOTTOM && (
              <div className={`${styles.tourDirection} ${arrowOriginMetaData.className}`}>
                <img alt='tour-direction' src={ARROW_ICON} />
              </div>
            )}
          </div>
        </div>
      </StyledPopover>
    </div>
  );
}

export default ProductTourPopover;

const StyledPopover = styled(Popover)(() => ({
  position: 'relative',
  '& .MuiBackdrop-root': {
    display: 'none'
  },
  '& .MuiPaper-root': {
    position: 'fixed',
    zIndex: 'var(--zindex-modal-backdrop)'
  }
}));
