import { Component, ElementRef, OnInit } from '@angular/core';
import { ToasterContainer } from '../../react/components/Toaster/ToasterContainer';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'toaster-container-react',
  template: '',
  providers: [ BridgeService ]
})
export class ToasterContainerReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      ToasterContainer,
      this._el.nativeElement,
      {}
    );
  }
}
