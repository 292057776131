import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DEFAULT_TIMEZONE } from '../constants';
import { TeamSettingsService } from './team-settings.service';


@Injectable()
export class UserTimezoneService {
  constructor(
    private _teamSettingsService: TeamSettingsService
  ) {
  }

  setTimezone(zoneId: string) {
    return this._teamSettingsService.applyTeamSettings({ timezone: zoneId });
  }

  getSelectedTimezone(): Observable<string> {
    return this._teamSettingsService.getTeamSettings(false).pipe(
      map((res) => {
        return res.data.timezone;
      })
    );
  }

  setDefaultTimezone(): Observable<any> {
    return this.getSelectedTimezone().pipe(
      filter((timezoneId: string) => {
        return !timezoneId || timezoneId === DEFAULT_TIMEZONE;
      }),
      switchMap(() => {
        try {
          return this.setTimezone(this.getLocalTimezone());
        } catch {
          return of();
        }
      })
    );
  }

  getLocalTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
