// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.fieldDataType_MzrVm {\n  margin-left: 4px;\n  flex-shrink: 2;\n  color: var(--text-secondary-color);\n  font-size: 10px;\n  font-weight: 400;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n}\n\n.treeViewPadding_GSR5Z {\n  padding: 8px 8px 20px 8px;\n}\n\n.dialogBody_l1tKk {\n  max-height: 550px;\n  overflow: auto;\n}\n\n.nodePaddingLeft_Ss_Y5 {\n  padding-left: 18px;\n}\n\n.nodePaddingTop_hB9PZ {\n  padding-top: 12px;\n}\n\n.toggleArrow_wCN1f {\n  margin-right: 4px;\n  font-size: 14px;\n  transform: rotate(-90deg);\n  flex-shrink: 0;\n}\n\n.toggleArrowToggled_LO9qm {\n  transform: rotate(0deg);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldDataType": "fieldDataType_MzrVm",
	"treeViewPadding": "treeViewPadding_GSR5Z",
	"dialogBody": "dialogBody_l1tKk",
	"nodePaddingLeft": "nodePaddingLeft_Ss_Y5",
	"nodePaddingTop": "nodePaddingTop_hB9PZ",
	"toggleArrow": "toggleArrow_wCN1f",
	"toggleArrowToggled": "toggleArrowToggled_LO9qm"
};
export default ___CSS_LOADER_EXPORT___;
