import { useEffect, useState } from 'react';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import {
  CRON_TYPE_FIXED_INTERVAL,
  FREQUENCY_TYPE_HOURS,
  FREQUENCY_TYPE_MINUTES
} from '../../../app/dialog/execution-policy-dialog/constants';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import useSettingsStorageService from '../../hooks/services/useSettingsStorageService';
import {
  CUSTOM_OPTION,
  DESTINATION_RUN_DAILY_POP_UP_SHOWN,
  DESTINATION_TOOLTIP_MESSAGE,
  SOURCE_RUN_DAILY_POP_UP_SHOWN,
  SOURCE_TOOLTIP_MESSAGE,
  STREAMING_OPTION,
  TRACKER_CHANGE_SCHEDULE_DIALOG_CUSTOM_CHIP_CLICK
} from './constants';
import { SchedulerType, Policy } from './models';
import { PolicyTypeEnum } from '../../../app/dialog/execution-policy-dialog/models';
import { getFrequencyClone } from './utils';
import { getExecutionPolicyFormInitialValues } from './form-helper';
import { STREAMING_FREQUENCY } from '../pipeline/create/PipelineSettings/PipelineSchedule/constant';

export default function useScheduler({ policy, entity, schedulerType, formikRef }) {
  const analyticsTracker = useAnalyticsTracker();
  const { getSettings, applySettings } = useSettingsStorageService();
  const [selectedOption, setSelectedOption] = useState(null);
  const [policyObj, setPolicyObj] = useState<Policy>(getFrequencyClone(policy));
  const [isCustom, setIsCustom] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [tooltipData, setTooltipData] = useState({
    message: '',
    showTooltip: false
  });

  useEffect(() => {
    const shouldAddCustomOption =
      policyObj.suggestedFrequencies[policyObj.suggestedFrequencies.length - 1]?.value !==
      CUSTOM_OPTION.value;

    if (shouldAddCustomOption) {
      setPolicyObj(prev => ({
        ...prev,
        suggestedFrequencies: [...prev.suggestedFrequencies, CUSTOM_OPTION],
        displayedFrequencies: [...prev.displayedFrequencies, CUSTOM_OPTION]
      }));

      if (policy?.showStreamingOption) {
        setPolicyObj(prev => ({
          ...prev,
          suggestedFrequencies: [STREAMING_OPTION, ...prev.suggestedFrequencies],
          displayedFrequencies: [STREAMING_OPTION, ...prev.displayedFrequencies]
        }));
      }
    }
  }, []);

  useEffect(() => {
    setSelectedFrequency();
  }, [policyObj.type]);

  const setSelectedFrequency = () => {
    if (policyObj.type === PolicyTypeEnum.FREQUENCY) {
      if (
        policy.showStreamingOption &&
        policy.streamingScheduleEnabled &&
        policyObj.frequencyValue === STREAMING_FREQUENCY.frequencyValue &&
        policyObj.frequencyType.value === STREAMING_FREQUENCY.frequencyType.value
      ) {
        setIsStreaming(true);
        setSelectedOption(STREAMING_OPTION);
        return;
      }

      const selectedFrequencyOption = policyObj.suggestedFrequencies.find(
        (frequency: any) =>
          frequency.value === policyObj.frequencyValue &&
          frequency.frequencyType.value === policyObj.frequencyType.value
      );

      if (selectedFrequencyOption) {
        setIsCustom(false);
        setIsStreaming(false);
        setSelectedOption(selectedFrequencyOption);
        return;
      }

      setIsCustom(true);
      setSelectedOption(CUSTOM_OPTION);

      if (policyObj.frequencyType.name === FREQUENCY_TYPE_MINUTES.name) {
        setPolicyObj(prev => ({
          ...prev,
          frequencyValue: undefined
        }));
      }

      setPolicyObj(prev => ({
        ...prev,
        type: PolicyTypeEnum.FREQUENCY,
        cronType: CRON_TYPE_FIXED_INTERVAL
      }));
    } else {
      setIsCustom(true);
      setIsStreaming(false);
      setSelectedOption(CUSTOM_OPTION);
    }
  };

  const handleOptionSelection = (option: any) => {
    if (!option) {
      setIsCustom(false);
      setPolicyObj(prev => ({
        ...prev,
        frequencyValue: undefined,
        frequencyType: undefined,
        type: undefined
      }));
      return;
    }

    setSelectedOption(option);

    if (option.value === STREAMING_OPTION.value) {
      setIsCustom(false);
      setIsStreaming(true);
      return;
    }

    if (option.value !== CUSTOM_OPTION.value) {
      setIsCustom(false);
      setIsStreaming(false);
      setPolicyObj(prev => ({
        ...prev,
        type: PolicyTypeEnum.FREQUENCY,
        frequencyValue: option.value,
        frequencyType: option.frequencyType
      }));
      return;
    }

    formikRef.current?.resetForm({
      values: {
        ...formikRef.current?.values,
        ...getExecutionPolicyFormInitialValues(schedulerType)
      }
    });

    analyticsTracker.eventTrack({
      action: TRACKER_CHANGE_SCHEDULE_DIALOG_CUSTOM_CHIP_CLICK,
      properties: {
        entity
      }
    });

    onCustomOptionSelection();
    setIsCustom(true);
    setIsStreaming(false);

    if (schedulerType === SchedulerType.CronType) {
      setPolicyObj(prev => ({
        ...prev,
        type: PolicyTypeEnum.FREQUENCY,
        cronType: CRON_TYPE_FIXED_INTERVAL
      }));
    } else {
      setPolicyObj(prev => ({
        ...prev,
        frequencyValue: undefined,
        type: PolicyTypeEnum.FREQUENCY,
        frequencyType: FREQUENCY_TYPE_HOURS
      }));
    }
  };

  const onCustomOptionSelection = () => {
    let dailyPopupKey: string;

    switch (entity) {
      case HevoEntity.PIPELINE.value: {
        dailyPopupKey = SOURCE_RUN_DAILY_POP_UP_SHOWN;
        setTooltipData({
          showTooltip: false,
          message: SOURCE_TOOLTIP_MESSAGE
        });
        break;
      }
      case HevoEntity.DESTINATION.value: {
        dailyPopupKey = DESTINATION_RUN_DAILY_POP_UP_SHOWN;
        setTooltipData({
          showTooltip: false,
          message: DESTINATION_TOOLTIP_MESSAGE
        });
        break;
      }
      default:
        break;
    }

    if (dailyPopupKey) {
      if (!getSettings(dailyPopupKey)) {
        setTooltipData(prev => ({
          ...prev,
          showTooltip: true
        }));
      }

      applySettings(dailyPopupKey, true);
    }
  };

  return {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    isStreaming,
    tooltipData,
    setTooltipData,
    setIsCustom,
    handleOptionSelection
  };
}
