import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RecentEntitiesService } from './recent-entities.service';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class ModelService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _recentEntitiesService: RecentEntitiesService
  ) {
  }

  private modelsUrl: string = this._appConfig.getModelsURL();

  getModels(modelIds?: number[]): Observable<any> {
    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          model_id: modelIds
        })
      },
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(this.modelsUrl, options);
  }

  getModel(modelId: any, showLoading = true): Observable<any> {
    const requestUrl = this.modelsUrl + '/' + modelId;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: showLoading
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  deleteModel(modelId: any, options: any = {}): Observable<any> {
    const requestUrl = this.modelsUrl + '/' + modelId;

    return this._rxRequestService.delete(requestUrl, options);
  }

  runNow(modelId: any) {
    const requestUrl = this.modelsUrl + `/${ modelId }/instant-run`;

    return this._rxRequestService.put(requestUrl);
  }

  pauseModel(modelId: any): Observable<any> {
    const requestUrl = this.modelsUrl + `/${ modelId }/pause`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return this._rxRequestService.put(requestUrl, options);
  }

  resumeModel(modelId: any): Observable<any> {
    const requestUrl = this.modelsUrl + `/${ modelId }/resume`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return this._rxRequestService.put(requestUrl, options);
  }

  stopRunningQuery(modelId: number, historyId: number): Observable<void> {
    const requestUrl = this.modelsUrl + `/${ modelId }/history/${ historyId }`;

    return this._rxRequestService.delete(requestUrl);
  }

  getModelCount(status?: string): Observable<number> {
    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          status: status
        })
      },
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      }
    };

    const requestUrl = this.modelsUrl + '/count';

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res) => res.data.count)
    );
  }
}
