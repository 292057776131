import React from 'react';
import { connect, FormikContextType } from 'formik';
import { flatten } from './flattenObject';

class ErrorFocus extends React.Component<{
  formik: FormikContextType<{}>;
  onFormError?: Function;
}> {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors, initialValues, values } = prevProps.formik;

    if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
      const errorKeys = flatten(errors);
      if (prevProps.onFormError) {
        prevProps.onFormError(Object.keys(errorKeys));
      }

      const firstErrorField = [
        ...new Set([...Object.keys(flatten(initialValues)), ...Object.keys(flatten(values))])
      ]
        .filter(fieldName => errorKeys[fieldName])
        .map(fieldName => document.querySelector(`[id="${fieldName}"]`))
        .filter(elem => !!elem)
        .sort((a, b) =>
          // tslint:disable-next-line:no-bitwise
          a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1
        )[0];

      if (firstErrorField) {
        (firstErrorField as HTMLElement).focus();
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(React.memo(ErrorFocus));
