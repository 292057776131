import { InputAdornment } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import HdDropDown, { HdDropDownProps } from '../HdDropDown';
import HdIcon from '../HdIcon';
import { PopperComponentProps, StyledInput } from '../HdMultiSelectDropDown/elements';
import styles from './styles.module.scss';

export interface HdDropdownInlineProps extends HdDropDownProps {
  className?: string;
  dropdownClassName?: string;
}

export const HdInlineDropdown = React.forwardRef(
  (
    { className = '', dropdownClassName = '', ...dropdownProps }: HdDropdownInlineProps,
    ref: any
  ) => {
    /**
     * added callback for popper to avoid re-rendering of the dropdown
     * which will cause scroll to top event if callback is not used
     */
    const PopperComponentCallback = useCallback(
      popperProps => PopperComponent({ ...popperProps }),
      []
    );

    return (
      <div className={className} ref={ref}>
        <HdDropDown
          {...dropdownProps}
          className={clsx(dropdownProps.selectMode && styles.selectModeDropdown, dropdownClassName)}
          renderInline
          InputComponent={InputComponent}
          PopperComponent={PopperComponentCallback}
          searchableInput
          paperClasses={styles.inlineDropdownPaper}
        />
      </div>
    );
  }
);

function InputComponent(props) {
  const { placeholder, InputProps, inputProps, selectMode, ...others } = props;

  return (
    <StyledInput
      className={styles.inlineDropdownInput}
      {...others}
      value={null}
      autoFocus
      placeholder={placeholder}
      ref={InputProps.ref}
      startAdornment={
        <InputAdornment position='start'>
          <HdIcon name='search' />
        </InputAdornment>
      }
      // disabled is false so user can search the list even dropdown/options are disabled
      inputProps={{ ...inputProps, disabled: false }}
    />
  );
}

function PopperComponent(props: PopperComponentProps) {
  const {
    disablePortal,
    anchorEl,
    open,
    popperWidth,
    hoverOptionRef,
    HelperDocumentAdornment,
    setHoverOption,
    children,
    autoWidthPaperMode,
    ...other
  } = props;
  return (
    <div {...other}>
      <div>{children}</div>
    </div>
  );
}
