import { CleverTapConfigHelper } from '../create/ConfigTarget/CleverTap/configHelper';
import { FreshdeskConfigHelper } from '../create/ConfigTarget/Freshdesk/configHelper';
import { GoogleSheetsConfigHelper } from '../create/ConfigTarget/GoogleSheets/google-sheet-config-helper';
import { KlaviyoConfigHelper } from '../create/ConfigTarget/Klaviyo/configHelper';
import { MarketoConfigHelper } from '../create/ConfigTarget/Marketo/configHelper';
import { MixpanelConfigHelper } from '../create/ConfigTarget/Mixpanel/configHelper';
import { ZendeskConfigHelper } from '../create/ConfigTarget/Zendesk/configHelper';
import { NodeTypeConfigHelperConstructor } from '../../../../app/nodes/node-config-helper';
import { AuthAccountEnvironment } from '../../../../app/nodes/select-auth-account-environment/interface';
import { AuthAccountEnvironmentMetaData } from '../../../../app/nodes/source-type/source-type';


declare const __HIDDEN_TARGETS__: { [key: string]: boolean };

export interface TargetType {
  name: string;
  displayName?: string;
  requireAuth?: boolean;
  requireServiceAuth?: boolean;
  authProvider?: string;
  isAuthTypeGoogle?: boolean;
  isTargetConfigEditable?: boolean;
  docLink?: string;
  primaryColor?: string;
  darkModePrimaryColor?: string;
  authAccountsEnvironmentsMetaData?: { [key: string]: AuthAccountEnvironmentMetaData };
  configHelper?: NodeTypeConfigHelperConstructor;
  isHidden?: boolean;
  isCrmTarget?: boolean;
}

export enum TargetTypesEnum {
  SALESFORCE = 'SALESFORCE',
  HUBSPOT = 'HUBSPOT',
  INTERCOM = 'INTERCOM',
  ZENDESK = 'ZENDESK',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  MARKETO = 'MARKETO',
  MAILCHIMP = 'MAILCHIMP',
  KLAVIYO = 'KLAVIYO',
  MIXPANEL = 'MIXPANEL',
  FRESHDESK = 'FRESHDESK',
  CLEVERTAP = 'CLEVERTAP'
}

export const TARGET_TYPES: { [key: string]: TargetType } = {
  [TargetTypesEnum.SALESFORCE]: {
    name: TargetTypesEnum.SALESFORCE.toString(),
    displayName: 'Salesforce',
    requireAuth: true,
    authProvider: 'SALESFORCE',
    docLink: '/activate/activate-targets/salesforce/',
    primaryColor: 'rgb(0,163,230)',
    darkModePrimaryColor: '#00a1e0',
    authAccountsEnvironmentsMetaData: {
      [AuthAccountEnvironment.PRODUCTION]: {
        label: 'Production',
        description: 'The environment where live business data is stored'
      },
      [AuthAccountEnvironment.TESTING]: {
        label: 'Sandbox',
        description: 'An isolated environment used for development and testing'
      }
    },
    isCrmTarget: true
  },
  [TargetTypesEnum.HUBSPOT]: {
    name: TargetTypesEnum.HUBSPOT.toString(),
    displayName: 'HubSpot',
    requireAuth: true,
    authProvider: 'HUBSPOT_TARGET_II',
    docLink: '/activate/activate-targets/hubspot/',
    primaryColor: 'rgb(255, 122, 89)',
    darkModePrimaryColor: '#FF7A59',
    isCrmTarget: true
  },
  [TargetTypesEnum.INTERCOM]: {
    name: TargetTypesEnum.INTERCOM.toString(),
    displayName: 'Intercom',
    requireAuth: true,
    authProvider: 'INTERCOM_TARGET',
    docLink: '/activate/activate-targets/intercom/',
    primaryColor: 'rgb(30, 141, 237)',
    darkModePrimaryColor: '#53AAF5',
    isHidden: __HIDDEN_TARGETS__?.INTERCOM
  },
  [TargetTypesEnum.ZENDESK]: {
    name: TargetTypesEnum.ZENDESK.toString(),
    displayName: 'Zendesk',
    requireAuth: false,
    isTargetConfigEditable: true,
    docLink: '/activate/activate-targets/zendesk/',
    primaryColor: 'rgb(0, 55, 62)',
    darkModePrimaryColor: '#3F8C9E',
    configHelper: ZendeskConfigHelper
  },
  [TargetTypesEnum.GOOGLE_SHEETS]: {
    name: TargetTypesEnum.GOOGLE_SHEETS.toString(),
    displayName: 'Google Sheets',
    requireAuth: true,
    requireServiceAuth: true,
    authProvider: 'GOOGLE_SHEETS_TARGET',
    isAuthTypeGoogle: true,
    docLink: '/activate/activate-targets/google-sheets/',
    primaryColor: 'rgb(34, 165, 101)',
    darkModePrimaryColor: '#23A566',
    configHelper: GoogleSheetsConfigHelper
  },
  [TargetTypesEnum.MARKETO]: {
    name: TargetTypesEnum.MARKETO.toString(),
    displayName: 'Marketo',
    requireAuth: false,
    isTargetConfigEditable: true,
    docLink: '/activate/activate-targets/marketo/',
    primaryColor: '#5B4C9E',
    darkModePrimaryColor: '#B6B6B6',
    configHelper: MarketoConfigHelper
  },
  [TargetTypesEnum.MAILCHIMP]: {
    name: TargetTypesEnum.MAILCHIMP.toString(),
    displayName: 'Mailchimp',
    requireAuth: true,
    authProvider: 'MAILCHIMP',
    docLink: '/activate/activate-targets/mailchimp/',
    primaryColor: 'rgb(104, 76, 70)',
    darkModePrimaryColor: '#F3BE77'
  },
  [TargetTypesEnum.KLAVIYO]: {
    name: TargetTypesEnum.KLAVIYO.toString(),
    displayName: 'Klaviyo',
    requireAuth: false,
    isTargetConfigEditable: true,
    docLink: '/activate/activate-targets/klaviyo/',
    primaryColor: '#232426',
    darkModePrimaryColor: '#FFFFFF',
    configHelper: KlaviyoConfigHelper
  },
  [TargetTypesEnum.MIXPANEL]: {
    name: TargetTypesEnum.MIXPANEL.toString(),
    displayName: 'Mixpanel',
    requireAuth: false,
    isTargetConfigEditable: true,
    docLink: '/activate/activate-targets/mixpanel/',
    primaryColor: '#00B6E8',
    darkModePrimaryColor: '#27B4E1',
    configHelper: MixpanelConfigHelper
  },
  [TargetTypesEnum.FRESHDESK]: {
    name: TargetTypesEnum.FRESHDESK.toString(),
    displayName: 'Freshdesk',
    requireAuth: false,
    isTargetConfigEditable: true,
    docLink: '/activate/activate-targets/',
    primaryColor: 'rgb(0, 133, 93)',
    darkModePrimaryColor: '#4AEA96',
    configHelper: FreshdeskConfigHelper
  },
  [TargetTypesEnum.CLEVERTAP]: {
    name: TargetTypesEnum.CLEVERTAP.toString(),
    displayName: 'CleverTap',
    requireAuth: false,
    isTargetConfigEditable: true,
    docLink: '/activate/activate-targets/',
    primaryColor: 'rgb(72, 26, 188)',
    darkModePrimaryColor: '#8C6AFF',
    configHelper: CleverTapConfigHelper
  }
};

