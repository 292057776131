import * as Yup from 'yup';
import { S3_DEST_FILE_FORMATS } from './constants';
import { S3_BUCKET_REGIONS } from '../../../../../../app/nodes/nodes-settings/s3-base/constants';
import { DestinationConfigMetaData } from '../interface';
import S3 from './index';

export const S3_CONFIG: DestinationConfigMetaData = {
  formComponent: S3,
  initialValues: {
    awsAccessKey: '',
    awsSecretAccessKey: '',
    bucket: '',
    prefix: '',
    bucketRegion: null,
    fileFormat: null,
    gzip: true
  },
  validationSchema: Yup.object({
    awsAccessKey: Yup.string().trim().required(),
    awsSecretAccessKey: Yup.string().trim().required(),
    bucket: Yup.string().trim().required(),
    bucketRegion: Yup.object().required(),
    fileFormat: Yup.object().required()
  }),
  fieldNameMapping: {
    awsAccessKey: 'aws-access-key',
    awsSecretAccessKey: 'aws-secret-access-key',
    bucket: 'bucket',
    prefix: 'prefix',
    bucketRegion: 'bucket-region',
    fileFormat: 'file-format',
    gzip: 'gzip'
  },
  regionList: () =>
    S3_BUCKET_REGIONS.map(elem => ({
      name: elem.name,
      value: elem.value,
      subtext: elem.value
    })),
  fileFormatList: () => S3_DEST_FILE_FORMATS,
  getFormData(formData) {
    return formData;
  }
};
