import { Injectable, ViewContainerRef } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { UpgradeToLiveChatDialogComponent } from './upgrade-to-live-chat-dialog.component';

@Injectable()
export class UpgradeToLiveChatDialogService {
  constructor(private _dialogOverlayService: DialogOverlayService) {
  }

  show(currentPlan: string, isOwner: boolean, viewContainerRef?: ViewContainerRef) {
    return this._dialogOverlayService.open(
      UpgradeToLiveChatDialogComponent, { currentPlan, isOwner }, { viewContainerRef }
    );
  }
}
