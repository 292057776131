export class UsageAlertBannerTrackingActions {
  static open = '[Usage Alert] banner open';

  static close = '[Usage Alert] banner close';

  static hover = '[Usage Alert] banner hover';

  static closeClick = '[Usage Alert] banner close click';

  static closeHover = '[Usage Alert] banner close hover';

  static upgradePlanClick = '[Usage Alert] banner upgrade plan click';

  static setOnDemandCreditClick = '[Usage Alert] banner set on demand credit click';

  static redeemOnDemandCreditClick = '[Usage Alert] banner redeem on demand credit click';

  static hikeOnDemandCreditClick = '[Usage Alert] banner raise on demand credit click';

  static upgradePlanHover = '[Usage Alert] banner upgrade plan hover';

  static setOnDemandCreditHover = '[Usage Alert] banner set on demand credit hover';

  static redeemOnDemandCreditHover = '[Usage Alert] banner redeem on demand credit hover';

  static hikeOnDemandCreditHover = '[Usage Alert] banner raise on demand credit hover';
}
