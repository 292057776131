import { Typography } from '@mui/material';
import { sanitize } from 'dompurify';
import React, { useEffect } from 'react';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import FormError from '../../../FormError';
import { HdHoverTracker } from '../../../Tracking/HoverTracker';
import { HdButton, HdIcon, HdIconButton } from '../../../UIElements';
import HdModal from '../../../UIElements/HdModal';
import { ConfirmDialogCommonProps, DialogCommonProps } from '../interface';
import { useConfirmDialog } from '../useConfirmDialog';
import styles from './styles.module.scss';

export interface ConfirmDialogV1Config extends ConfirmDialogCommonProps {
  subtitle?: string;
  dataId?: string;
}

export function ConfirmDialogV1({
  open,
  title = 'Alert',
  subtitle = null,
  body = 'Are you Sure?',
  positiveButtonText = 'Confirm',
  negativeButtonText = 'Cancel',
  onPositiveClickCallback,
  onPositiveClick,
  bodyComponentProps = {},
  BodyComponent = null,
  onClose,
  onPositiveClickSuccess = null,
  hoverTrackerAction,
  closeCTAHoverTrackerAction,
  negativeCTAHoverTrackerAction,
  positiveCTAHoverTrackerAction,
  modalProps,
  dataId
}: ConfirmDialogV1Config & DialogCommonProps) {
  const { processing, onErrorMessage, onPositiveButtonClick, setError } = useConfirmDialog({
    onPositiveClick,
    onPositiveClickCallback,
    onPositiveClickSuccess,
    onClose
  });

  useEffect(() => {
    if (!open && onErrorMessage) {
      setError(null);
    }
  }, [open]);

  const dataIdGenerator = getDataIdGenerator(dataId, 'confirmDialogV1');

  return (
    <HdModal
      {...modalProps}
      dialogHoverTrackingAction={hoverTrackerAction}
      open={!!open}
      onClose={() => onClose()}
      dataId={dataIdGenerator('')}
    >
      <>
        <div className={`dialog-header ${subtitle ? styles.modalHeader : ''}`}>
          <div className='dialog-title'>{title}</div>

          <HdHoverTracker trackingAction={closeCTAHoverTrackerAction}>
            <HdIconButton
              verticalEdge='both'
              horizontalEdge='both'
              className='ml-2'
              onClick={() => onClose()}
              dataId={dataIdGenerator('close')}
            >
              <HdIcon name='close' size={3} />
            </HdIconButton>
          </HdHoverTracker>

          {subtitle ? (
            <>
              <div className={styles.lineBreak} />

              <Typography variant='caption'>{subtitle}</Typography>
            </>
          ) : null}
        </div>

        <div className='dialog-body'>
          {!!BodyComponent && !!open ? (
            <BodyComponent {...bodyComponentProps} />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: sanitize(body) }} />
          )}

          {onErrorMessage ? <FormError className='mt-5 mb-0'>{onErrorMessage}</FormError> : null}
        </div>

        <div className='dialog-footer'>
          <div>
            <HdHoverTracker trackingAction={negativeCTAHoverTrackerAction}>
              <HdButton
                onClick={() => onClose()}
                variation='outline'
                dataId={dataIdGenerator('cancel')}
              >
                {negativeButtonText}
              </HdButton>
            </HdHoverTracker>

            <HdHoverTracker trackingAction={positiveCTAHoverTrackerAction}>
              <HdButton
                onClick={onPositiveButtonClick}
                disabled={processing}
                showProgress={processing}
                dataId={dataIdGenerator('confirm')}
              >
                {positiveButtonText}
              </HdButton>
            </HdHoverTracker>
          </div>
        </div>
      </>
    </HdModal>
  );
}
