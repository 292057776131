import {
  ConnectedPosition,
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollDispatcher,
} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { asapScheduler, merge, of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

import { OverlayPosition } from '../../overlay/types';
import { getDirFromPositionPair, getOffsetXFromPosition, getOffsetYFromPosition } from '../../overlay/utils';
import { MenuItem } from '../menu-item';
import { HdMenuComponent } from './hd-menu.component';


@Directive({
  selector: '[hdMenuTrigger]',
  exportAs: 'hdMenuTrigger',
  host: {
    '[attr.disabled]': 'disabled || null'
  }
})
export class HdMenuTriggerDirective implements OnInit, OnDestroy, AfterContentInit {
  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal<void>;
  @HostBinding('class.active') private _menuOpen = false;
  private _closeSubscription = Subscription.EMPTY;
  private _hoverSubscription = Subscription.EMPTY;
  private _docClickHandler: any;
  private _openedBy: 'mouse' | 'touch' | null = null;

  @Input('addFocusClass') private addFocusClass = true;
  @Input('hdMenuTrigger') menu: HdMenuComponent;

  _disabled = false;

  @HostBinding('class.disabled')
  @Input('disabled')
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Output() onMenuOpen = new EventEmitter<void>();
  @Output() onMenuClose = new EventEmitter<void>();

  constructor(
    private _el: ElementRef,
    private _overlay: Overlay,
    private renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef,
    private _scrollDispatcher: ScrollDispatcher,
    @Optional() private _parentMenu: HdMenuComponent,
    @Optional() @Self() private _menuItemInstance: MenuItem,
    private _ngZone: NgZone) {
    if (_menuItemInstance) {
      _menuItemInstance._triggersSubmenu = this.triggersSubmenu();
    }
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    e['targetDirective'] = this;
    this.toggleMenu();
  }

  /** Handles mouse presses on the trigger. */
  @HostListener('mousedown', ['$event']) _handleMousedown(event: MouseEvent): void {
    this._openedBy = event.button === 0 ? 'mouse' : null;
  }

  toggleMenu(): void {
    return this._menuOpen ? this.closeMenu() : this.openMenu();
  }

  openMenu(): void {
    if (this._menuOpen) {
      return;
    }

    this._createOverlay().attach(this._portal);
    this._closeSubscription = this._menuClosingActions().subscribe(() => {
      if (this.disabled) {
        return;
      }
      this.closeMenu();
    });

    this.handleDocEvents();

    this._initMenu();
  }

  closeMenu(): void {
    this.onMenuClose.emit();
    this._destroyMenu();
  }

  private _menuClosingActions() {
    const backdrop = this._overlayRef!.backdropClick();
    const detachments = this._overlayRef!.detachments();
    const menuClose = this.menu.close;
    const hover = this._parentMenu ? this._parentMenu._hovered().pipe(
      filter(active => active !== this._menuItemInstance),
      filter(() => this._menuOpen)
    ) : of();

    return merge(backdrop, detachments, hover, menuClose);
  }


  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const overlayConfig = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(overlayConfig);

      this._portal = new TemplatePortal(this.menu.templateRef, this._viewContainerRef);
    }

    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
  }

  private _getPositionStrategy(): FlexibleConnectedPositionStrategy {
    const overlayPosition: OverlayPosition = this.menu.overlayPosition;
    const fallbackPositions: OverlayPosition[] = this.menu.fallbackPositions;

    let positions: ConnectedPosition[] = [
      {...overlayPosition, ...this.getOffsetFromPosition(overlayPosition)},
      {...fallbackPositions[1], ...this.getOffsetFromPosition(fallbackPositions[1])},
      {...fallbackPositions[2], ...this.getOffsetFromPosition(fallbackPositions[2])},
      {...fallbackPositions[3], ...this.getOffsetFromPosition(fallbackPositions[3])}
    ];

    if (this.triggersSubmenu()) {
      positions = [
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top'
        }
      ];
    }


    const strategy = this._overlay.position()
      .flexibleConnectedTo(this._el)
      .withPositions(positions);

    const scrollableAncestors = this._scrollDispatcher
      .getAncestorScrollContainers(this._el);

    // scrollableAncestors = this._getNonScrollingAncestors(scrollableAncestors);

    strategy.withScrollableContainers(scrollableAncestors);

    strategy.positionChanges.subscribe(change => {
      this.updatePositionClasses(change.connectionPair);
      if (
        change.scrollableViewProperties.isOverlayClipped
        && change.scrollableViewProperties.isOriginClipped
        && this._menuOpen) {
        this._ngZone.run(() => {
          this.menu.close.emit();
        });
      }
    });

    return strategy;
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this._handleHover();
  }

  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
      this.unhandleDocEvents();
    }

    this._cleanUpSubscriptions();
  }

  private _cleanUpSubscriptions(): void {
    this._closeSubscription.unsubscribe();
    this._hoverSubscription.unsubscribe();
  }

  private _destroyMenu(): void {
    if (this._overlayRef && this._menuOpen) {

      this._menuOpen = false;
      this._closeSubscription.unsubscribe();
      this._overlayRef.detach();

      if (!this._openedBy) {
        this.focus();
      }

      this._openedBy = null;

      this.unhandleDocEvents();
    }
  }

  private _initMenu(): void {
    this.menu.parentMenu = this.triggersSubmenu() ? this._parentMenu : undefined;
    this._menuOpen = true;
    this.onMenuOpen.emit();
    this.menu.focusFirstItem(this._openedBy);
  }

  onDocumentClick(e: any) {
    if (this._menuOpen && !this._el.nativeElement.contains(e.target)) {
      this.menu.close.emit();
    }
  }

  unhandleDocEvents() {
    if (this._docClickHandler) {
      this._docClickHandler();
    }
  }

  handleDocEvents() {
    this._docClickHandler = this.renderer.listen('document', 'click', (e: any) => {
      this.onDocumentClick(e);
    });
  }

  focus() {
    this._el.nativeElement.focus();
  }

  getOffsetFromPosition(position: OverlayPosition) {
    return {
      offsetX: this.getOffsetXFromPosition(position),
      offsetY: this.getOffsetYFromPosition(position)
    };
  }


  getOffsetXFromPosition(position: OverlayPosition): number {
    let offset: number = this.menu.offsetX;
    if (this.menu.displayType === 'tooltip') {
      offset = getOffsetXFromPosition(position, offset) || 0;
    }
    return offset;
  }

  getOffsetYFromPosition(position: OverlayPosition): number {
    let offset: number = this.menu.offsetY;
    if (this.menu.displayType === 'tooltip') {
      offset = getOffsetYFromPosition(position, offset) || 0;
    }
    return offset;
  }

  updatePositionClasses(connectionPair: ConnectionPositionPair) {
    const direction: any = getDirFromPositionPair(connectionPair);
    const element = this._overlayRef.overlayElement.querySelector('.hd-menu');

    element.classList.remove(
      'p-start',
      'p-end',
      'p-top',
      'p-bottom',
      'p-center',
      'a-start',
      'a-end',
      'a-top',
      'a-bottom',
      'a-center');

    element.classList.add(`p-${direction.position}`, `a-${direction.align}`);
  }

  /** Handles the cases where the user hovers over the trigger. */
  private _handleHover() {
    // Subscribe to changes in the hovered item in order to toggle the panel.
    if (!this.triggersSubmenu()) {
      return;
    }

    this._hoverSubscription = this._parentMenu._hovered()
      // Since we might have multiple competing triggers for the same menu (e.g. a sub-menu
      // with different data and triggers), we have to delay it by a tick to ensure that
      // it won't be closed immediately after it is opened.
      .pipe(
        filter(active => active === this._menuItemInstance),
        delay(0, asapScheduler)
      )
      .subscribe(() => {
        this._openedBy = 'mouse';
        this.openMenu();
      });
  }

  /** Whether the menu triggers a sub-menu or a top-level one. */
  triggersSubmenu(): boolean {
    return !!(this._menuItemInstance && this._parentMenu);
  }
}
