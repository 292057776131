export class SetupGuideTracking {
  static DOCS_LOADED = '[Side-By-Side Docs] loaded successfully';
  static DOCS_ERRORED = '[Side-By-Side Docs] loading failed';

  static DOCS_LINK_CLICK = '[Side-By-Side Docs] link click';
  static DOCS_SCROLL = '[Side-By-Side Docs] scroll event';
  static DOCS_SCROLL_TOP_CLICK = '[Side-By-Side Docs] scroll top click';
  static DOCS_SHARE_BUTTON_CLICK = '[Side-By-Side Docs] share button click';
  static DOCS_SKIP_TO_SECTION_CLICK = '[Side-By-Side Docs] skip to section click';
  static DOCS_HEADER_CLICK = '[Side-By-Side Docs] header click';
  static DOCS_TABS_LOADED = '[Side-By-Side Docs] tabs loaded';
  static DOCS_TABS_INTERACTION = '[Side-By-Side Docs] tabs interaction';
}
