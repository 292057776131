import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss';
import HdIcon from '../UIElements/HdIcon';
import { HdIconButton } from '../UIElements';

interface ScrollToTopButtonProps {
  scrollViewportId: string;
  variant?: 'default' | 'compact';
  className?: string;
}

export default function ScrollToTopButton({
  scrollViewportId,
  variant = 'default',
  className = ''
}: ScrollToTopButtonProps) {
  const [scrollViewport, setScrollViewport] = useState<HTMLElement>(null);
  const [defaultPaddingBottom, setDefaultPaddingBottom] = useState(null);
  const scrollToTopButtonRef = useRef<HTMLDivElement>(null);
  const scrollViewportPosition = useRef(null);
  const buttonEdgeOffset = 20;

  /**
   * callback on viewport scroll
   * buttonVisibleThreshold = show scroll to top button when half of the viewport's height is scrolled
   * enforce a padding of 20px at bottom of viewport to create room for button
   */
  const onViewportScroll = () => {
    const buttonVisibleThreshold = scrollViewport ? scrollViewport.clientHeight / 2 : 100;

    if (scrollViewport) {
      if (scrollViewport.scrollTop > buttonVisibleThreshold) {
        setDefaultPaddingBottom(scrollViewport.style.paddingBottom);
        scrollViewport.style.paddingBottom = `${
          scrollToTopButtonRef.current.offsetHeight + buttonEdgeOffset
        }px`;
        scrollViewportPosition.current = scrollViewport.parentElement.style.position;
        scrollViewport.parentElement.style.position = 'relative';
        scrollToTopButtonRef.current?.classList.remove(styles.hidden);
      } else {
        scrollViewport.style.paddingBottom = defaultPaddingBottom;
        scrollViewport.parentElement.style.position = scrollViewportPosition.current;
        scrollToTopButtonRef.current?.classList.add(styles.hidden);
      }
    }
  };

  const scrollToTop = () => {
    if (scrollViewport) {
      scrollViewport.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    const viewport = document.getElementById(scrollViewportId);

    if (viewport) {
      setScrollViewport(viewport);
    }
  }, []);

  useEffect(() => {
    if (scrollViewport) {
      scrollViewport.addEventListener('scroll', onViewportScroll);
    }

    return () => {
      if (scrollViewport) {
        scrollViewport.removeEventListener('scroll', onViewportScroll);
      }
    };
  }, [scrollViewport]);

  return (
    /**
     * portal being used to inject scroll to top button always as a direct child node
     * of scroll viewport, which gives flexibility to use listener as independent component
     */
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {scrollViewport ? (
        <div
          ref={scrollToTopButtonRef}
          className={`${styles.scrollToTopButtonWrapper} ${styles.hidden} ${className} `}
          data-id={`${scrollViewportId}-scroll-top-wrapper`}
        >
          <HdIconButton
            className={styles.scrollToTopButton}
            onClick={scrollToTop}
            dataId={`${scrollViewportId}-scroll-top`}
          >
            <HdIcon size={variant === 'compact' ? 2 : 3} className={styles.icon} name='up-arrow' />
          </HdIconButton>
        </div>
      ) : null}
    </>
  );
}
