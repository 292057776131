import { DOCUMENT } from '@angular/common';
import { Component, DoCheck, Inject, Input, OnInit } from '@angular/core';
import { Popup } from './popup';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: [ './popup.component.scss' ]
})
export class PopupComponent extends Popup implements OnInit, DoCheck {
  popupContainerClass = '';
  troubleshootPopupHeight;
  troubleshootPopupWidth;
  troubleshootPopupRight;

  @Input() popupWrapperClasses = '';

  constructor(
    @Inject(DOCUMENT) private _document: any
  ) {
    super();
  }

  ngOnInit() {
    if (this.popupClasses?.includes('tooltip-popup')) {
      this.popupContainerClass = 'tooltip-popup-container';
    } else if (this.popupClasses?.includes('troubleshoot-popup')) {
      this.popupContainerClass = 'troubleshoot-popup-container';
    }

    if (this.popupWrapperClasses) {
      this.popupContainerClass = `${this.popupContainerClass} ${this.popupWrapperClasses}`;
    }
  }

  ngDoCheck() {
    if (this.popupClasses?.includes('troubleshoot-popup')) {
      // Setting the height of the popup
      const dashboardOutletContainer =
        (this._document.querySelector('.dashboard-outlet-container') as HTMLElement).getBoundingClientRect();
      this.troubleshootPopupHeight = dashboardOutletContainer.height;

      // Fetching the width of the dashboard
      const dashboardContainer =
        (this._document.querySelector('.dashboard-container') as HTMLElement).getBoundingClientRect();

      // Setting the width of the popup - 30% of dashboard width
      this.troubleshootPopupWidth = Math.round(dashboardContainer.width * .3);

      if (this.troubleshootPopupWidth < 420) {
        this.troubleshootPopupWidth = 420;
      }

      /*
       * Calculating and setting the right position of the popup relative to the Help btn in the top bar
       * */
      const halfTroubleshootPopup = this.troubleshootPopupWidth * .5;
      const helpButton = (this._document.querySelector('.help-btn') as HTMLElement).getBoundingClientRect();
      const popupTriggerRight = dashboardContainer.width - helpButton.left + 16 + 12;

      this.troubleshootPopupRight = halfTroubleshootPopup - popupTriggerRight;
    }
  }
}
