/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React, { useState } from 'react';
import { HdIcon } from '../../../UIElements';
import styles from './styles.module.scss';

export function TreeView({ node, childrenAccessor }) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div>
      <div
        onClick={() => setCollapsed(state => !state)}
        className={clsx(styles.nodePaddingTop, {
          [styles.nodePaddingLeft]: !node[childrenAccessor]
        })}
      >
        {node[childrenAccessor] && (
          <HdIcon
            name='solid-dropdown'
            className={clsx(styles.toggleArrow, {
              [styles.toggleArrowToggled]: collapsed
            })}
          />
        )}

        {node.name}

        <span className={styles.fieldDataType}>
          {node.type}

          {node.value_type && <span>({node.value_type})</span>}
        </span>
      </div>

      {collapsed &&
        node[childrenAccessor] &&
        (node[childrenAccessor] || []).map((childNode, i) => (
          <div key={i} className={styles.nodePaddingLeft}>
            <TreeView node={childNode} childrenAccessor={childrenAccessor} />
          </div>
        ))}
    </div>
  );
}
