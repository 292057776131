import React, { useEffect, useMemo, useState } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { HevoEntity } from '../../../../../../app/core/models/hevo-entity';
import {
  PIPELINE_SCHEDULE_FREQUENCY_CHIPS,
  PIPELINE_POLICY_MESSAGE_PREFIX,
  PIPELINE_SCHEDULE_FREQUENCY_CHIPS_FOR_LOG_BASED
} from '../../../../../../app/dialog/execution-policy-dialog/constants';
import { InlineScheduler } from '../../../../scheduler/InlineScheduler';
import styles from './styles.module.scss';
import { FrequencyType } from '../../../../../../app/dialog/execution-policy-dialog/models';
import {
  PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES,
  PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES_FOR_LOG_BASED
} from './constant';
import PipelinesAPI from '../../../PipelinesAPI';
import { HdDocLink, HdPane } from '../../../../../components/UIElements';

interface InlineSchedulerProps {
  className?: string;
  formikRef: any;
  updateValidationSchema?: Function;
  entityDetails?: any;
  oldSchedulerFlow: boolean;
  showStreamingOption?: boolean;
  defaultFrequency: { frequencyValue: number; frequencyType: FrequencyType };
  isEdit: boolean;
  disablePositiveCTACallback?: Function;
}

export function InlineSchedulerWrapper({
  className = '',
  oldSchedulerFlow,
  formikRef,
  updateValidationSchema,
  entityDetails,
  defaultFrequency,
  showStreamingOption = false,
  disablePositiveCTACallback,
  isEdit
}: InlineSchedulerProps) {
  const { values } = useFormikContext<any>();
  const [quotaLimitWarningObj, setQuotaLimitWarningObj] = useState(null);
  const schedulerEntityDetails = oldSchedulerFlow
    ? {
        ...entityDetails,
        frequency_config: {
          ...entityDetails.frequency_config,
          show_streaming_option: showStreamingOption,
          streaming_schedule_enabled: !!entityDetails.config?.streaming_schedule_enabled
        }
      }
    : {
        ...entityDetails,
        execution_policy: entityDetails?.execution_policy || {},
        frequency_config: {
          suggested_frequencies: showStreamingOption
            ? PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES_FOR_LOG_BASED
            : PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES,
          show_streaming_option: showStreamingOption,
          min_frequency: 1800,
          max_frequency: 86400,
          is_daily_cron_configurable: true,
          streaming_schedule_enabled: !!entityDetails.config?.streaming_schedule_enabled
        }
      };

  useEffect(() => {
    if (isEdit) {
      getQuotaLimit();
    }
  }, [isEdit]);

  const getQuotaLimit = () => {
    PipelinesAPI.getQuotaLimit(entityDetails.id)
      .then((res: any) => {
        setQuotaLimitWarningObj(res.data);
      })
      .catch(() => {
        setQuotaLimitWarningObj(null);
      });
  };

  const canShowQuotaAlert = useMemo(() => {
    if (isEdit) {
      const { frequencyValue = null, frequencyType = null } = values;
      if (!frequencyValue || frequencyValue === 'Streaming') {
        return false;
      }

      const selectedFrequencyValue = entityDetails.execution_policy.frequency;
      const defaultFrequencyValue = entityDetails.frequency_config.default_frequency;
      const newFrequencyValue = frequencyValue * frequencyType.minSeconds;

      return (
        quotaLimitWarningObj?.message &&
        (newFrequencyValue < selectedFrequencyValue || newFrequencyValue < defaultFrequencyValue)
      );
    }

    return false;
  }, [quotaLimitWarningObj, values.frequencyValue, isEdit]);

  const frequencyChips = showStreamingOption
    ? PIPELINE_SCHEDULE_FREQUENCY_CHIPS_FOR_LOG_BASED
    : PIPELINE_SCHEDULE_FREQUENCY_CHIPS;

  return (
    <InlineScheduler
      title='Scheduled'
      subTitle='The ingestion frequency for all the objects in the Pipeline.'
      entityDetails={schedulerEntityDetails}
      frequencyChips={frequencyChips}
      hideCTA
      optionsClassName={styles.pipelineSchedulerOptions}
      defaultFrequency={defaultFrequency}
      className={className}
      policyMessagePrefix={PIPELINE_POLICY_MESSAGE_PREFIX}
      entity={HevoEntity.PIPELINE.value}
      formikRef={formikRef}
      updateValidationSchema={updateValidationSchema}
      isEdit={isEdit}
      disablePositiveCTACallback={disablePositiveCTACallback}
    >
      {canShowQuotaAlert && (
        <HdPane
          dataId='pipeline_quota_limit_alert'
          icon='warning'
          variant='warning-faded'
          className='mb-5'
        >
          <span className='text-body-1 text-secondary'>
            {quotaLimitWarningObj.message}

            {quotaLimitWarningObj.docs && quotaLimitWarningObj.docs.url && (
              <HdDocLink
                dataId='pipeline_quota_limit_alert'
                label={quotaLimitWarningObj.docs.displayText}
                docLink={quotaLimitWarningObj.docs.url}
                section='pipeline_quota_limit_alert'
                className='ml-1'
              />
            )}
          </span>
        </HdPane>
      )}
    </InlineScheduler>
  );
}
