import { ClickAwayListener, PopperPlacementType } from '@mui/material';
import React from 'react';
import { HdDropdownInlineProps, HdInlineDropdown } from '../HdInlineDropdown';
import { StyledPopper } from '../HdMultiSelectDropDown/elements';
import styles from './styles.module.scss';

export interface HdDropdownWithExternalTriggerProps extends HdDropdownInlineProps {
  onClose: () => void;
  open: boolean;
  placement?: PopperPlacementType;
  target: string;
  tabIndex?: number;
}

export function HdDropdownWithExternalTrigger({
  onClose,
  open,
  target,
  tabIndex = 0,
  placement = 'bottom-start',
  onChangeEventHandler,
  ...dropdownInlineProps
}: HdDropdownWithExternalTriggerProps) {
  const focusTarget = () => {
    const elementId = target?.split('#')[1] || null;
    if (elementId) {
      document.getElementById(elementId).focus();
    }
  };

  const onChangeEventHandlerCallback = selected => {
    if (selected) {
      if (onChangeEventHandler) {
        onChangeEventHandler(selected);
      }
      if (!dropdownInlineProps.multiple) {
        onClose();
      }
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.stopPropagation();
      onClose();
      focusTarget();
    }
    if (event.key === 'Tab') {
      onClose();
      focusTarget();
    }
  };

  return (
    <div role='button' tabIndex={tabIndex} aria-describedby={dropdownInlineProps.id}>
      <StyledPopper
        open={open}
        anchorEl={document.querySelector(target)}
        placement={placement}
        id={dropdownInlineProps.id}
        onKeyUp={handleListKeyDown}
      >
        <ClickAwayListener onClickAway={onClose}>
          <HdInlineDropdown
            {...dropdownInlineProps}
            onChangeEventHandler={onChangeEventHandlerCallback}
            className={styles.dropdownInline}
          />
        </ClickAwayListener>
      </StyledPopper>
    </div>
  );
}
