import { Directive, Input, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[faHelpContent]'})
export class FaHelpContentDirective implements OnChanges {
  @Input() faHelpContent: TemplateRef<any>;
  @Input() option: any;

  constructor(
    private _viewContainer: ViewContainerRef
  ) {
  }

  ngOnChanges(changes: any) {
    if (!changes.faHelpContent && !changes.option) {
      return;
    }
    this._viewContainer.clear();
    this._viewContainer.createEmbeddedView(
      this.faHelpContent,
      {
        $implicit: this.option
      }
    );
  }
}
