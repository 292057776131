export enum ConnectionType {
  CONNECTION_STRING = 'CONNECTION_STRING',
  ENTER_FIELDS_MANUALLY = 'ENTER_FIELDS_MANUALLY'
}

export enum MongodbVariations {
  ATLAS = 'ATLAS'
}

export interface MongoDbFormState {
  host: string;
  hosts: string[];
  isConnectionUri: string;
  isServerMongoDBAtlas: boolean;
  port: string;
  databaseName: string;
  useSSH: boolean;
  user: string;
  password: string;
  renderOldDbNameField: boolean;
  loadAllDb: boolean;
  URI: string;
  sourceVersion: number;
  isSrvConn: boolean;
  databaseNames: any[];
}

export enum MongoParsingErrors {
  MULTIPLE_PORT_ERROR = 'multiplePortError',
  INVALID_URI = 'invalidURI',
  PASSWORD_REQUIRED = 'passwordRequired',
  NOT_FOUND_ERROR = 'notFoundError'
}
