import clsx from 'clsx';
import React from 'react';

export function GenericPageShimmer({
  className = ''
}: {
  className?: string;
}) {
  return (
    <div className={clsx('center-flex-col justify-center h-100', className)}>
      <div>
        <div className='shimmer shimmer-line' style={{ width: '100px', height: '8px' }} />
        <div className='shimmer shimmer-line mt-3' style={{ width: '300px', height: '12px' }} />
      </div>

      <div className='mt-7'>
        <div className='shimmer shimmer-line' style={{ width: '100px', height: '8px' }} />
        <div className='shimmer shimmer-line mt-3' style={{ width: '300px', height: '12px' }} />
      </div>
    </div>
  );
}
