export function fetchMetricFilterClauseData(data) {
  const metricFilterClauses: any[] = [];

  if (data.metricFilterClauses !== undefined) {
    data.metricFilterClauses.forEach(metricFilterClause => {
      if (metricFilterClause.filters === undefined || !metricFilterClause.filters.length) {
        return;
      }

      const filters: any[] = metricFilterClause.filters
        .filter((filter: any) => !!filter.unit)
        .map((filter: any) => ({
          metricName: filter.unit.key,
          not: filter.includeExclude?.value,
          operator: filter.operator?.value,
          comparisonValue: filter.expression
        }));

      if (filters && filters.length) {
        metricFilterClauses.push({
          operator: metricFilterClause.operator?.value,
          filters
        });
      }
    });
  }

  return metricFilterClauses;
}

export function fetchDimensionFilterClauseData(data) {
  const dimensionFilterClauses = [];

  if (data.dimensionFilterClauses !== undefined) {
    data.dimensionFilterClauses.forEach(dimensionFilterClause => {
      if (dimensionFilterClause.filters === undefined || !dimensionFilterClause.filters.length) {
        return;
      }

      const filters: any[] = dimensionFilterClause.filters
        .filter((filter: any) => !!filter.unit)
        .map((filter: any) => ({
          dimensionName: filter.unit.key,
          not: filter.includeExclude?.value,
          operator: filter.operator?.value,
          caseSensitive: filter.caseSensitive,
          expressions: filter.expression?.split(',')
        }));

      if (filters && filters.length) {
        dimensionFilterClauses.push({
          operator: dimensionFilterClause.operator?.value,
          filters
        });
      }
    });
  }
  return dimensionFilterClauses;
}
