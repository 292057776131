// Slackmarkdown https://github.com/blockmar/slackdown/blob/master/src/slackdown.js
const RE_ALPHANUMERIC = new RegExp('^\\w?$'),
    RE_TAG = new RegExp('<(.+?)>', 'g'),
    RE_BOLD = new RegExp('\\*([^\\*]+?)\\*', 'g'),
    RE_ITALIC = new RegExp('_([^_]+?)_', 'g'),
    RE_FIXED = new RegExp('`([^`]+?)`', 'g'),
    RE_NEWLINE = new RegExp('\n', 'g');

const pipeSplit = function(payload) {
    return payload.split('|');
};

const payloads = function(tag: any, start?: number) {
    if (!start) {
        start = 0;
    }
    const length = tag.length;
    return pipeSplit(tag.substr(start, length - start));
};

const tag = function(tag: any, attributes?: any, payload?: any) {
    if (!payload) {
        payload = attributes;
        attributes = {};
    }

    let html = '<'.concat(tag);
    for (const attribute in attributes) {
        if (attributes.hasOwnProperty(attribute)) {
            html = html.concat(' ', attribute, '="', attributes[attribute], '"');
        }
    }
    return html.concat('>', payload, '</', tag, '>');
};

const matchTag = function(match: any) {
    const action = match[1].substr(0, 1);
    let p;

    switch (action) {
        case '!':
            return tag('span', { class: 'slack-cmd' }, payloads(match[1], 1)[0]);
        case '#':
            p = payloads(match[1], 2);
            return tag('span', { class: 'slack-channel'}, (p.length === 1 ? p[0] : p[1]));
        case '@':
            p = payloads(match[1], 2);
            return tag('span', { class: 'slack-user' }, (p.length === 1 ? p[0] : p[1]));
        default:
            p = payloads(match[1]);

            return tag('a', { href: p[0], class: 'text-link' }, (p.length === 1 ? p[0] : p[1]));
    }
};

const notAlphanumeric = function(input: any) {
    return !RE_ALPHANUMERIC.test(input);
};

export const notRepeatedChar = function(trigger: any, input: any) {
    return !trigger || trigger !== input;
};

export const safeMatch = function(match: any, tag: any, trigger?: any) {
    let prefix_ok = match.index === 0;
    let postfix_ok = match.index === match.input.length - match[0].length;

    if (!prefix_ok) {
        const charAtLeft = match.input.substr(match.index - 1, 1);
        prefix_ok = notAlphanumeric(charAtLeft) && notRepeatedChar(trigger, charAtLeft);
    }

    if (!postfix_ok) {
        const charAtRight = match.input.substr(match.index + match[0].length, 1);
        postfix_ok = notAlphanumeric(charAtRight) && notRepeatedChar(trigger, charAtRight);
    }

    if (prefix_ok && postfix_ok) {
        return tag;
    }
    return false;
};

const matchBold = function(match: any) {
    return safeMatch(match, tag('strong', payloads(match[1])), '*');
};

const matchItalic = function(match: any) {
    return safeMatch(match, tag('em', payloads(match[1])), '_');
};

const matchFixed = function(match: any) {
    return safeMatch(match, tag('code', payloads(match[1])));
};

const matchNewline = function(match: any) {
    return '<br>';
};

export function parseSlackdown(text: any) {

    if (typeof text === 'string') {
        const patterns = [
            {p: RE_TAG, cb: matchTag},
            {p: RE_BOLD, cb: matchBold},
            {p: RE_ITALIC, cb: matchItalic},
            {p: RE_FIXED, cb: matchFixed},
            {p: RE_NEWLINE, cb: matchNewline}
        ];

        for (let p = 0; p < patterns.length; p++) {

            const pattern = patterns[p];
            const original = text;
            let result: any;

            while ((result = pattern.p.exec(original)) !== null) {
                const replace = pattern.cb(result);

                if (replace) {
                    text = text.replace(result[0], replace);
                }
            }
        }
    }

    return text;
}
