import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalBlockingLoaderComponent } from './global-blocking-loader/global-blocking-loader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { IconModule } from '../icon/icon.module';
import { ActionSpinnerComponent } from './action-spinner/action-spinner.component';
import { DotPulseComponent } from './dot-pulse/dot-pulse.component';
import { ProgressSpinnerDeterminateComponent } from './progress-spinner-determinate/progress-spinner-determinate.component';

const DIRECTIVES = [
  GlobalBlockingLoaderComponent,
  SpinnerComponent,
  ActionSpinnerComponent,
  DotPulseComponent,
  ProgressSpinnerDeterminateComponent
];

@NgModule({
  imports: [
    CommonModule,
    IconModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class LoaderModule { }
