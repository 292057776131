import clsx from 'clsx';
import React from 'react';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';
import { calendarViews } from '../shared/const';

import { TitleProps } from '../shared/interfaces';

import Tile from '../Tile';

const className = 'react-calendar__month-view__days__day';

interface DayProps<T> extends TitleProps<T> {
  currentMonthIndex: number;
}

export default function Day<T>({ classes, currentMonthIndex, ...otherProps }: DayProps<T>) {
  const { date } = otherProps;
  const { dateTimeAdapter } = useDateTimeAdapter<T>();

  const dayDisplay = dateTimeAdapter.getDate(date).toString();

  return (
    <Tile
      {...otherProps}
      classes={clsx(
        className,
        classes,
        dateTimeAdapter.isWeekend(date) && `${className}--weekend`,
        dateTimeAdapter.getMonth(date) !== currentMonthIndex && `${className}--neighboringMonth`
      )}
      view={calendarViews.MONTH}
    >
      <>{dayDisplay}</>
    </Tile>
  );
}
