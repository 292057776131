import { WorkflowHistoryStatusEnum, WorkflowRunHistoryItem } from '../models/workflow-run-history';

export function WorkflowRunHistoryFactory(rawData: any): WorkflowRunHistoryItem[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((rawHistory: any) => WorkflowRunHistoryItemFactory(rawHistory));
}

export function WorkflowRunHistoryItemFactory(rawData: any) {
  if (!rawData) {
    return;
  }

  const item = new WorkflowRunHistoryItem();

  item.startedTs = rawData.created_ts;
  item.runTime = rawData.run_time;
  item.failureMessage = rawData.failure_message;
  item.status = WorkflowHistoryStatusEnum[rawData.status];

  return item;
}
