import { Injectable } from '@angular/core';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

@Injectable()
export class XeroConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      tenants: rawData.tenants
    };
  }

  getDefaultConfig(metaData: any) {
    return {
      tenants: metaData.tenantList
    };
  }

  buildConnectionParams(data: any) {
    return {
      tenants: data.tenants
    };
  }

  metaDataResolver(sourceType, authData: any, rawData: any) {
    return PipelinesAPI.getXeroTenants(authData.oauthTokenId).then(({ data }) => ({
      tenantList: data.authorised_tenants.map(tenant => ({ ...tenant, name: tenant.tenant_name }))
    }));
  }
}
