import React from 'react';
import { PipelineData } from '../../models';
import { NotificationView } from '../notification-view';
import useEntityLogo from '../../../../hooks/useEntityLogo';
import { HdIcon } from '../../../UIElements';
import styles from './styles.module.scss';

export function PipelineView({ data }: NotificationView<PipelineData>) {
  const sourceThumbnail = useEntityLogo(data.sourceTypeLogoUrl, data.sourceTypeDarkModeLogoUrl);
  const destinationThumbnail = useEntityLogo(data.destTypeLogoUrl, data.destTypeDarkModeLogoUrl);

  return (
    <div className={styles.pipelineViewContainer}>
      <div className={styles.nodeInfo}>
        {data.sourceTypeLogoUrl ? (
          <div className={styles.nodeImage}>
            <img src={sourceThumbnail} alt='' />
          </div>
        ) : null}

        <div className={styles.nodeName}>{data.sourceName}</div>
      </div>

      <HdIcon name='back' size={0} className={styles.connectionIndicator} />

      <div className={styles.nodeInfo}>
        {data.destTypeLogoUrl ? (
          <div className={styles.nodeImage}>
            <img src={destinationThumbnail} alt='' />
          </div>
        ) : null}

        <div className={styles.nodeName}>{data.destName}</div>
      </div>
    </div>
  );
}
