export class SidelineStrings {
  static stopReplayTitle = 'Stop Replaying Events';
  static stopReplayMsg = 'This action will stop replaying failed Events. Do you want to continue?';
  static stopReplayNegativeBtn = 'No';
  static stopReplayPositiveBtn = 'Yes, Stop Replaying Events';

  static replayResponse = 'Events have been submitted to be replayed';
  static skipResponse = 'Events have been submitted to be skipped';
  static skipTitle = 'SKIP EVENTS';
  static skipNegativeBtn = 'No';
  static skipPositiveBtn = 'YES, GO AHEAD';

  static eagerEventsDisabledMessage = 'Samples will be available soon.';

  static skipMessage(objectName?: string, schemaName?: string) {
    if (objectName && !schemaName) {
      return `Failed Events for ${objectName} will be skipped and purged permanently. Do you really want to continue?`;
    }
    if (schemaName) {
      return `These Events will be skipped and purged permanently. Do you really want to skip failed Events for ${ schemaName }?`;
    }
    return 'All failed Events for this Pipeline will be skipped and purged permanently. Do you really want to continue?';
  }
}
