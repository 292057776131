import React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { getDataId } from '../../../utils/generateDataId';

const switchWidth = 34;
const switchHeight = 20;
const thumbSize = 16;

export const HdSwitch = styled(({ dataId, ...props }: SwitchProps & { dataId: string }) => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    {...props}
    inputProps={{
      // @ts-ignore
      'data-id': getDataId('toggle')(dataId)('switch'),
      ...props
    }}
  />
))(({ theme }) => ({
  width: switchWidth,
  height: switchHeight,
  padding: 0,
  overflow: 'visible',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '80ms',
    '&.Mui-checked': {
      transform: 'translateX(calc(var(--spacer) * 0.9))',
      color: 'var(--light-text)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--primary-color)',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      },
      '&:hover .MuiTouchRipple-root': {
        opacity: 0.1,
        boxShadow: '0px 0px 0px var(--spacer) var(--primary-color)'
      }
    },
    '&.Mui-focusVisible .MuiTouchRipple-root, &:hover .MuiTouchRipple-root': {
      opacity: 0.4,
      boxShadow: '0px 0px 0px var(--spacer) var(--ripple-bg-color)'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
      cursor: 'not-allowed'
    },
    '& input': {
      width: '300%',
      height: '100%',
      left: '-100%'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    transition: 'opacity,transform 0ms cubic-bezier(0,0,.2,1)',
    boxShadow: 'var(--shadow-default)',
    width: thumbSize,
    height: thumbSize
  },
  '& .Mui-disabled .MuiSwitch-thumb': {
    backgroundColor: 'hsla(0, 0%, 100%, .5)'
  },
  '& .MuiSwitch-track': {
    width: switchWidth,
    height: switchHeight,
    borderRadius: switchHeight / 2,
    opacity: 1,
    border: 'none',
    backgroundColor: 'var(--divider-color)',
    transition: theme.transitions.create(['background-color', 'border'])
  }
}));
