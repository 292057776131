import { FieldTypes, SourceFormFieldDefinition } from '../../models';
import modifyFieldDefinition from './modify-field-definition';
import { TemplateConfig } from './template-config';

export default function AccessTokenTemplate(templateConfig: TemplateConfig): Array<Array<SourceFormFieldDefinition>> {
  return [
    [
      getAccessTokenField(templateConfig)
    ]
  ];
}

export function getAccessTokenField(templateConfig: TemplateConfig): SourceFormFieldDefinition {
  const { label = 'Access Token', hint } = templateConfig;

  const template: SourceFormFieldDefinition = {
    key: 'accessToken',
    fieldId: 'access-token',
    widget: FieldTypes.PASSWORD_FIELD,
    fieldType: 'string',
    widgetOptions: { name: 'accessToken', label: label, required: true },
    hint: hint,
    buildParamsKey: 'access_token',
    validations: [
      {
        type: 'required'
      }
    ]
  };

  return modifyFieldDefinition(template, templateConfig);
}
