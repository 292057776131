<div class="no-item-box errored {{ mode }}">
  <no-item-box-icon-container [iconName]="getErrorIcon()"></no-item-box-icon-container>

  <div class="no-item-box-content">
    <span class="no-item-box-title">Sorry, Something Went Wrong!</span>

    <span class="no-item-box-desc">
      {{ getErrorMsg() }}
      <button
        *ngIf="mode === 'compact'"
        hdLink
        type="button"
        (click)="tryAgain.emit()">
        Try Again
      </button>
    </span>
  </div>

  <button
    *ngIf="mode === 'full'"
    hdButton
    class="mt-7"
    type="button"
    dataId="retry-connection"
    (click)="tryAgain.emit()">
    Try Again
  </button>
</div>
