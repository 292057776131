import {
  SNOWFLAKE_HOST_URL_PREFIX,
  SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT,
  SNOWFLAKE_HOST_URL_REGEX,
  SNOWFLAKE_HOST_URL_SUFFIX
} from './constants';

export function extractAccountAndRegionFromSnowflakeHostURL(snowflakeHostURL: string) {
  const urlMatch = snowflakeHostURL.match(SNOWFLAKE_HOST_URL_REGEX);
  if (!urlMatch) {
    return {};
  }

  let snowflakeAccountUrl: string = urlMatch[0];
  snowflakeAccountUrl = snowflakeAccountUrl.replace(SNOWFLAKE_HOST_URL_PREFIX, '');
  snowflakeAccountUrl = snowflakeAccountUrl.replace(SNOWFLAKE_HOST_URL_SUFFIX, '');

  let regionId;
  let cloudProvider;
  let isPrivateLink = false;
  const [accountName, b, c, d] = snowflakeAccountUrl.split('.');

  if (
    b &&
    b !== SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT &&
    c &&
    c !== SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT
  ) {
    regionId = b;
    cloudProvider = c;
    isPrivateLink = c === 'aws' && d === SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT;
  }

  if (b && b !== SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT && !d) {
    regionId = b;
    isPrivateLink = c === SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT;
  }

  if (b === SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT && !c && !d) {
    isPrivateLink = true;
  }

  if (regionId && cloudProvider) {
    regionId = `${regionId}.${cloudProvider}`;
  }

  return { accountName, regionId, isPrivateLink };
}
