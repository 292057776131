import React from 'react';
import clsx from 'clsx';
import { NodeLogo, NodeLogoProps } from '../../../../components/NodeLogo';
import { HdTooltip } from '../../../../components/UIElements';
import { getDataIdGenerator } from '../../../../utils/generateDataId';

interface NodeLogoWithTextProps {
  zIndex?: number;
  roundedBorders: boolean;
  size: number;
  option: NodeLogoProps;
  name: string;
  className?: string;
  logoClassName?: string;
  textClassName?: string;
  dataId?: '';
}

export function NodeLogoWithText({
  option,
  roundedBorders,
  logoClassName = '',
  className = '',
  size,
  zIndex,
  name,
  dataId = '',
  textClassName = ''
}: NodeLogoWithTextProps) {
  const dataIdGenerator = getDataIdGenerator(dataId);
  return (
    <div className={clsx('center-flex-row', className)}>
      <NodeLogo
        className={logoClassName}
        roundedBorders={roundedBorders}
        logoURL={option.logoURL}
        size={size}
        darkModeLogoURL={option.darkModeLogoURL}
        primaryColor={option.primaryColor}
        darkModePrimaryColor={option.darkModePrimaryColor}
      />
      <HdTooltip enableOnTextOverflow title={name} zIndex={zIndex}>
        <span
          data-id={dataIdGenerator('node-logo-text')}
          className={clsx('text-ellipsis', textClassName)}
        >
          {name}
        </span>
      </HdTooltip>
    </div>
  );
}
