import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { ACCOUNT_EXEC_SCHEDULE_CALL_LINK } from '../../../../app/core/constants';
import { NPSService } from '../../../../app/core/service/nps.service';
import { UserService } from '../../../../app/core/service/user.service';
import { StarWidget } from '../../../components/StarWidget';
import { StarSize } from '../../../components/StarWidget/models';
import { HdButton, HdIcon, HdIconButton, HdModal, HdTextField } from '../../../components/UIElements';
import { useScheduleCall } from '../../../hooks/useScheduleCall';
import useService from '../../../hooks/useService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { CAPTERRA_FEEDBACK_URL, RATING_STAR_IMAGES } from './constants';
import { NPSFeedbackPlaceholder, NPSFeedbackQuestion } from './models';
import styles from './styles.module.scss';

export interface NPSDialogProps {
  open: boolean;
  feedbackId: string;
  onClose: () => any;
}

export const dataIdGenerator = getDataIdGenerator('nps-dialog');

export function NPSDialog(props: NPSDialogProps) {
  const [ isThankYouFeedbackCardVisible, setIsThankYouFeedbackCardVisible ] = useState(false);

  const onClose = () => {
    props.onClose();
    setIsThankYouFeedbackCardVisible(false);
  };

  return (
    <HdModal
      open={props.open}
      onClose={onClose}
      contentClassName='border-lg'
      styling={{ width: isThankYouFeedbackCardVisible ? '395px' : '485px' }}
    >
      <NPSDialogInner
        {...props}
        isThankYouFeedbackCardVisible={isThankYouFeedbackCardVisible}
        setIsThankYouFeedbackCardVisible={setIsThankYouFeedbackCardVisible}
        onClose={onClose}
      />
    </HdModal>
  );
}

export function NPSDialogInner({
  isThankYouFeedbackCardVisible,
  setIsThankYouFeedbackCardVisible,
  feedbackId,
  onClose
}: NPSDialogProps & {
  isThankYouFeedbackCardVisible: boolean;
  setIsThankYouFeedbackCardVisible: (v: boolean) => void;
}) {
  const npsService = useService(NPSService);
  const userService = useService(UserService);

  const { openScheduleCallLink } = useScheduleCall();

  const [ rating, setRating ] = useState(npsService.getNpsScore());
  const [ comment, setComment ] = useState('');

  const remindMeLaterClick = () => {
    npsService.remind3rdPartyRatingLater(rating).subscribe();

    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const submitFeedback = () => {
    npsService.updateRating(feedbackId, rating, comment).subscribe();

    npsService.setNpsScore(0);

    if (rating !== 8) {
      setIsThankYouFeedbackCardVisible(true);
    } else {
      onClose();
    }
  };

  const onRatingClicked = (_rating) => {
    setRating(_rating);
    npsService.setNpsScore(_rating);
  };

  const { placeholderText, titleText } = useMemo(() => {
    if (rating >= 9) {
      return {
        titleText: NPSFeedbackQuestion.LIKELY,
        placeholderText: NPSFeedbackPlaceholder.LIKELY
      };
    }

    if (rating <= 6) {
      return {
        titleText: NPSFeedbackQuestion.NOT_LIKELY,
        placeholderText: NPSFeedbackPlaceholder.NOT_LIKELY
      };
    }

    return {
      titleText: NPSFeedbackQuestion.AVERAGE,
      placeholderText: NPSFeedbackPlaceholder.AVERAGE
    };
  }, [ rating ]);

  return (<>
    {isThankYouFeedbackCardVisible ? (
      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div className={styles.closeIcon}>
          <HdIconButton
            verticalEdge='both'
            horizontalEdge='both'
            onClick={() => onClose()}
            dataId={dataIdGenerator('close')}
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>
        </div>

        <div className='d-flex justify-center'>
          <img
            data-id={dataIdGenerator('star-icon')}
            alt=''
            style={{ height: '48px' }}
            src={RATING_STAR_IMAGES[rating]}
          />
        </div>

        {rating >= 9 ? (
          <div className='center-flex-col'>
            <div
              className='text-subheading-2 text-center mt-6'
              data-id={ dataIdGenerator('title') }>
              <span data-id={ dataIdGenerator('title-positive') }>
                Thank you for your feedback! <span style={ { fontSize: '16px' } }>🤩</span>
              </span>
            </div>
          </div>
        ) : null}

        <div
          className={ clsx('text-center mx-auto', rating >= 9 ? 'text-secondary mt-3' : 'text-default mt-4') }
          data-id={ dataIdGenerator('subtext')}
          style={{ maxWidth: '90%' }}>
          {rating < 8 ? (
            <span data-id={dataIdGenerator('subtext-negative')}>
              We apologize for falling short of your expectations. Please share your feedback in a call so we can address your concerns and improve your experience.
            </span>
          ) : (
            <span data-id={dataIdGenerator('subtext-positive')}>
              Can you help us spread the word by leaving some happy comments about Hevo on G2?
            </span>
          )}
        </div>

        {rating >= 9 ? (
          <>
            <div className='center-flex-row justify-center mt-5'>
              <span className={clsx('d-flex p-2', styles.promoContent)}>
                <HdIcon
                  name='coupon'
                  className='mr-2'/>
                <span className='text-body-2'>Submit your review and earn a $20 gift voucher.</span>
              </span>
            </div>

            <div className='center-flex-col mt-6 mb-4'>
              <HdButton
                dataId={dataIdGenerator('capterra')}
                tag='a'
                target='_blank'
                href={CAPTERRA_FEEDBACK_URL}
                variation='outline'
                iconSrc='https://res.cloudinary.com/hevo/image/upload/v1661186322/dashboard/capterra_dyfq0a.svg'
              >
                Capterra
              </HdButton>
            </div>

            <div className='d-flex justify-center mb-5'>
              <HdButton
                dataId={dataIdGenerator('remind-later')}
                variation='flat'
                onClick={remindMeLaterClick}
              >
                Remind me later
              </HdButton>
            </div>
          </>
        ) : (
          <div className='d-flex justify-center my-5'>
            <HdButton
              dataId={dataIdGenerator('schedule-call')}
              icon='schedule-call'
              onClick={() => {
                const user = userService.getUser();

                const userNameParts = user.name.split(' ');

                const searchParams = new URLSearchParams({
                  email: user.email
                });

                if (user.name && user.name !== user.email) {
                  const firstName = userNameParts.slice(0, userNameParts.length - 1 || 1).join(' ');
                  const lastName = userNameParts.slice(userNameParts.length - 1 || userNameParts.length).join(' ');
                  searchParams.append('firstname', firstName);

                  if (lastName) {
                    searchParams.append('lastname', lastName);
                  }
                }

                openScheduleCallLink(
                  `https://meetings.hubspot.com/sherly-angel/nps-based-scheduler?${searchParams}`,
                  '[NPS Dialog]: Schedule Call Click',
                  'nps-dialog'
                );

                onClose();
              }}
            >
              Schedule a Call with Us
            </HdButton>
          </div>
        ) }
      </div>
    ) : (<>
      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div className={styles.header}>
          <StarWidget
            type={StarSize.LARGE}
            dataId={dataIdGenerator('')}
            selectedStars={rating}
            onRatingClick={onRatingClicked}
          />

          <div className={styles.closeIcon}>
            <HdIconButton
              verticalEdge='both'
              horizontalEdge='both'
              onClick={() => onClose()}
              dataId={dataIdGenerator('close')}
            >
              <HdIcon name='close' size={3} />
            </HdIconButton>
          </div>
        </div>

        <div className={styles.titleContainer}>
          <div className='text-subheading-3 mt-5'>
            {titleText}
          </div>
        </div>

        <HdTextField
          name='comment'
          className='mt-5 mb-7'
          rows={4}
          resize='none'
          inputProps={{ maxLength: 1999 }}
          value={comment}
          onChange={e => {
            setComment(e.target.value);
          }}
          placeholder={placeholderText}
          multiline
          dataId={dataIdGenerator('comment')}
        />
      </div>

      <div className={clsx('dialog-footer', styles.dialogFooter)}>
        <HdButton
          dataId={dataIdGenerator('submit')}
          onClick={submitFeedback}
        >
          Submit
        </HdButton>
      </div>
    </>)}
  </>);
}
