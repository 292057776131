/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { VALID_EMAIL_REGEX } from '../../../../app/core/constants';
import useToaster from '../../../hooks/useToaster';
import FormError from '../../FormError';
import TeamAPI from '../../../containers/team/TeamAPI';
import RoundedIcon from '../../RoundedIcon';
import { HdButton, HdCopyIcon, HdIcon, HdTooltip } from '../../UIElements';
import { HdCheckbox } from '../../UIElements/HdCheckbox';
import HdModal from '../../UIElements/HdModal';
import { HdTextField } from '../../UIElements/HdTextField';
import styles from './styles.module.scss';
import { getDataIdGenerator } from '../../../utils/generateDataId';

interface Props {
  open: boolean;
  onClose: Function;
  entity: string;
  sourceId?: string;
  children?: any;
  renderAsDialog?: boolean;
  copyMessage?: string;
  entityType: string;
}

const dataIdGenerator = getDataIdGenerator('invite-user');

export default function InviteMemberModal({
  open,
  onClose,
  copyMessage,
  renderAsDialog = true,
  entity,
  children,
  sourceId,
  entityType
}: Props) {
  const toaster = useToaster();
  const [invitedEmailIds, setInvitedEmailIds] = useState('');
  const [shouldAddTeamMember, setShouldAddTeamMember] = useState(true);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [markTextBoxErrored, setMarkTextBoxErrored] = useState(false);
  const [disableShareButton, setDisableShareButton] = useState(false);

  useEffect(() => {
    if (!open) {
      setInvitedEmailIds('');
      setShouldAddTeamMember(true);
      setValidationErrorMessage('');
      setMarkTextBoxErrored(false);
      setDisableShareButton(false);
    }
  }, [open]);

  const inviteEmailsChangeHandler = e => {
    const { value } = e.target;
    setInvitedEmailIds(value);
  };

  const addTeamMemberCheckboxHandler = () => {
    setShouldAddTeamMember(prev => !prev);
  };

  const shareClickHandler = async (): Promise<any> => {
    setMarkTextBoxErrored(true);
    if (!invitedEmailIds.length) {
      return {};
    }

    let isInvalidEmail = false;
    const emailsIds = invitedEmailIds.split(',');

    emailsIds.forEach(email => {
      if (!VALID_EMAIL_REGEX.test(email)) {
        if (!isInvalidEmail) {
          setValidationErrorMessage(`Invalid Email: ${email}`);
          isInvalidEmail = true;
        }
      }
    });

    if (!isInvalidEmail) {
      const payload = {
        entity,
        entityType,
        sourceId,
        shouldInviteMembers: shouldAddTeamMember,
        toEmails: emailsIds
      };
      try {
        setDisableShareButton(true);
        await TeamAPI.shareSetupGuide(payload);
        toaster.pop('success', undefined, 'Documentation shared over email successfully.');
        onClose();
      } catch ({ error: { error_message }, message }) {
        toaster.pop('error', undefined, error_message || message);
      } finally {
        setDisableShareButton(false);
      }
    }

    return {};
  };

  const renderModel = () => (
    <div className={styles.inviteMemberModalContainer}>
      <HdIcon name='close' className={styles.closeIcon} size={3} onClick={onClose} />

      <div className={styles.iconWrapper}>
        <RoundedIcon iconName='add-user' />
      </div>

      <div className='text-center text-subheading-3'>
        Got stuck? Ask your team member(s) for help.
      </div>

      <div className='text-center text-secondary mt-2'>
        Share with or Invite a team member via email or chat.
      </div>

      <div className={styles.textInputWrapper}>
        <HdTextField
          label='Email ID'
          placeholder='Email ID'
          helperText="Enter your team member's email IDs. Use a comma to separate multiple IDs."
          prefixIcon='email'
          dataId={dataIdGenerator('emails-input')}
          value={invitedEmailIds}
          onChange={inviteEmailsChangeHandler}
          error={markTextBoxErrored && !invitedEmailIds.length}
        />
      </div>

      <HdCheckbox
        label={
          <span className={`text-body-2 ml-2 ${styles.checkboxLabel}`}>
            Add the team member(s) to your Hevo workspace.
          </span>
        }
        dataId={dataIdGenerator('invite-users')}
        checked={shouldAddTeamMember}
        onChange={addTeamMemberCheckboxHandler}
      />

      <div className={`text-body-1 ${styles.copyTextWrapper}`}>
        <HdTooltip title='Share via chat'>
          <HdCopyIcon
            className={`${styles.closeIcon} ${styles.copyIcon}`}
            copyText={copyMessage}
            dataId={dataIdGenerator('copy-message')}
            toastMessage='Text copied to clipboard'
          />
        </HdTooltip>

        {children}
      </div>

      <div className={`text-caption-1 mx-2 ${styles.copyHelperText}`}>
        Copy the message to share it over chat without adding the team member(s) to your Hevo
        workspace.
      </div>

      {!!validationErrorMessage && <FormError className='mt-7'>{validationErrorMessage}</FormError>}

      <div className={styles.shareButtonSection}>
        <HdButton
          onClick={shareClickHandler}
          icon='share'
          disabled={disableShareButton}
          dataId={dataIdGenerator('share')}
        >
          Share
        </HdButton>
      </div>
    </div>
  );

  if (renderAsDialog) {
    return (
      <HdModal open={open} variant='custom'>
        {renderModel()}
      </HdModal>
    );
  }

  return renderModel();
}
