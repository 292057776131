// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_TeRku {\n  flex-wrap: wrap;\n}\n.modalHeader_TeRku .lineBreak__z3eq {\n  flex-basis: 100%;\n  height: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_TeRku",
	"lineBreak": "lineBreak__z3eq"
};
export default ___CSS_LOADER_EXPORT___;
