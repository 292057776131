import React from 'react';
import { FieldProps, getIn } from 'formik';
import HdDatePicker from '../../UIElements/HdDatePicker';
import { FormikHelperText } from '../../../utils/FormikHelperText';

interface HdFormikDatePickerProps extends FieldProps {
  id: string;
  label: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  showTime?: boolean;
  offsetUTC?: string;
  disabled?: boolean;
  required?: boolean;
  onChangeEventHandler?: Function;
  iconSize?: number;
  helperText?: React.ReactNode;
}

export default function HdFormikDatePicker({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  placeholder,
  disabled,
  minDate,
  maxDate,
  id,
  onChangeEventHandler,
  iconSize,
  required = false,
  helperText = ' ',
  ...otherProps
}: HdFormikDatePickerProps) {
  const showError =
    (touched[field.name] && !!errors[field.name]) ||
    (getIn(errors, field.name) && getIn(touched, field.name));

  return (
    <HdDatePicker
      dataId={field.name}
      id={id || field.name}
      value={field.value}
      label={label}
      disabled={disabled}
      required={required}
      minDate={minDate}
      maxDate={maxDate}
      iconSize={iconSize}
      onChange={date => {
        if (onChangeEventHandler) {
          onChangeEventHandler(date);
          return;
        }
        setFieldValue(field.name, date);
      }}
      placeholder={placeholder || label}
      helperText={FormikHelperText({
        helperText,
        showError,
        fieldName: field.name,
        errorMessage: errors[field.name]
      })}
      {...otherProps}
      onBlur={() => {
        setFieldTouched(field.name);
      }}
      error={showError}
    />
  );
}
