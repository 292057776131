import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HdA11yModule } from '../a11y/a11y.module';
import { IpModule } from '../ip/ip.module';
import { SharedModule } from '../shared/shared.module';
import { MatSlideToggleModule } from '../slide-toggle/slide-toggle-module';
import { IconModule } from '../icon/icon.module';
import { PopupModule } from '../popup/popup.module';

import { FormErrorComponent } from './form-error/form-error.component';
import { HDFormChangeNotifierDirective, HDFormControlChangeNotifierDirective } from './hd-form-control-change-notifier.directive';

const DIRECTIVES = [
  FormErrorComponent,
  HDFormControlChangeNotifierDirective,
  HDFormChangeNotifierDirective
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    IpModule,
    SharedModule,
    HdA11yModule,
    IconModule,
    PopupModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class HdFormModule { }
