import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../constants';
import { OffsetViews } from '../models/offset-view';
import { NetworkRequestOptions } from '../models/request';
import { getOffsetViewUpdatePayload } from '../../../react/legacy-utils/offset-view';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class RxTaskService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  @Output() needOverviewPageRefresh = new EventEmitter<void>();

  getIntegrationUrl(id: any): string {
    return `${ this._appConfig.getIntegrationsURL() }/${ id }`;
  }

  changePosition(pipelineId: any, taskName: string, offsetView: OffsetViews): Observable<any> {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/tasks/${ encodeURIComponent(taskName) }/offset`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const params = {
      offset: getOffsetViewUpdatePayload(offsetView),
      skip_tracking: true,
      only_offset: true
    };

    return this._rxRequestService.put(requestUrl, options, params);
  }

  getTasksLoadProgress(pipelineId: number) {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/task-category-progress`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getHistoricalLoadProgress(pipelineId: number) {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/historical-load-status`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

}
