import { UserService } from '../../../app/core/service/user.service';
import useService from '../useService';
import useTeamSettings from './useTeamSettingsService';

export default function useUserService() {
  const userService = useService(UserService);
  const { isRBACEnabled } = useTeamSettings();

  return {
    user: userService.getUser(),
    isCurrentUserOwner: userService.getUser()?.isOwner,
    rbacPermissions: userService.getPermissions(),
    rbacRoles: userService.getUserRbacRoles(),
    updateRbacPermissions: permissions => {
      userService.setUserPermissions(permissions);
    },
    isInternalUser: () => userService.getUser()?.isHidden,
    isCurrentUserOwnerWithRbac: isRBACEnabled ? true : userService.getUser()?.isOwner
  };
}
