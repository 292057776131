/**
 * Check if all required fields are present in object
 *
 * @param obj - object to verify
 * @param requiredFields - list of fields to validate
 * @returns
 */
export function checkAllRequiredFieldsPresent(obj: any, requiredFields: string[]) {
  return requiredFields.every((field) => {
    return Object.keys(obj).includes(field);
  });
}
