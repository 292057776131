import { EditorConfiguration } from 'codemirror';
import {
  QueryParamType,
  PostBodyTypeEnum,
  RestApiAdvancedOptionTabs,
  OffsetUnit,
  IRestApiAdvanceOption
} from './models';

export const POST_METHOD_JSON_EDITOR_OPTIONS: EditorConfiguration = {
  lineNumbers: true,
  mode: {
    name: 'javascript',
    json: true
  },
  theme: 'hevo-default',
  foldGutter: true,
  gutters: [ 'CodeMirror-linenumbers', 'CodeMirror-foldgutter' ],
};

export const REST_API_REQUEST_METHODS: any[] = [
  {
    name: 'GET',
    value: 'GET'
  },
  {
    name: 'POST',
    value: 'POST'
  }
];

export const QUERY_PARAM_TYPES: QueryParamType[] = [
  {
    name: 'Text',
    value: 'text'
  },
  {
    name: 'Date',
    value: 'date'
  }
];

export const REST_API_PAGE_NO_METHODS: any[] = [
  {
    name: 'Increment on each call',
    value: 'increment'
  },
  {
    name: 'Extract from response',
    value: 'from_response'
  }
];

export const REST_API_POST_BODY_TYPES: any[] = [
  {
    name: 'JSON',
    value: PostBodyTypeEnum.JSON
  },
  {
    name: 'Form Data',
    value: PostBodyTypeEnum.FORM_DATA
  }
];

export const REST_API_ADVANCED_OPTIONS: IRestApiAdvanceOption[] = [
  {
    type: 'Authentication',
    value: RestApiAdvancedOptionTabs.AUTH
  },
  {
    type: 'Headers',
    value: RestApiAdvancedOptionTabs.HEADERS
  },
  {
    type: 'Query Params',
    value: RestApiAdvancedOptionTabs.QUERY_PARAMS
  }
];

export const OFFSET_UNITS: OffsetUnit[] = [
  {
    name: 'Years',
    value: 1
  },
  {
    name: 'Months',
    value: 2
  },
  {
    name: 'Days',
    value: 5
  },
  {
    name: 'Hours',
    value: 10
  },
  {
    name: 'Minutes',
    value: 12
  }
];

export const DEFAULT_REST_API_STARTING_PAGE_NO = 1;
export const REDIRECT_URL_CODE_QUERY_PARAM = 'code';
export const REDIRECT_URL_STATE_QUERY_PARAM = 'state';
export const REDIRECT_URL_ERROR_QUERY_PARAM = 'error';
export const REDIRECT_URL_PATH = 'rest-api/oauth';
export const REST_API_TOKEN_FORM_DATA_KEY = 'rest_api_token_data';

export const TRACKER_REST_API_SHOW_TABLE_FORMAT_DATA = '[Rest Api] show table format data';
export const TRACKER_REST_API_SUGGESTED_DATA_ROOT = '[Rest Api] suggested data root click';
export const TRACKER_REST_API_SET_UP_SOURCE_STEP_CONTINUE_CLICK = '[Rest Api] set up source step continue click';
export const TRACKER_REST_API_SET_UP_SOURCE_STEP_CONTINUE_SUCCESS = '[Rest Api] set up source step continue success';
export const TRACKER_REST_API_SET_UP_SOURCE_STEP_CONTINUE_FAIL = '[Rest Api] set up source step continue fail';
export const TRACKER_REST_API_DATA_ROOT_STEP_CONTINUE_CLICK = '[Rest Api] data root step continue click';
export const TRACKER_REST_API_DATA_ROOT_STEP_CONTINUE_FAIL = '[Rest Api] data root step continue fail';
export const TRACKER_REST_API_DATA_ROOT_STEP_CONTINUE_SUCCESS = '[Rest Api] data root step continue success';
export const TRACKER_REST_API_DATA_ROOT_STEP_PARSED_TAB_CLICK = '[Rest Api] data root step parsed tab click';
export const TRACKER_REST_API_CHECK_PARSED_RESPONSE_BUTTON_CLICK = '[Rest Api] check parse response button click';
export const TRACKER_REST_API_PAGINATION_STEP_TEST_PAGINATION_CLICK = '[Rest Api] pagination step test pagination click';
export const TRACKER_REST_API_PAGINATION_STEP_TEST_PAGINATION_SUCCESS = '[Rest Api] pagination step test pagination success';
export const TRACKER_REST_API_PAGINATION_STEP_TEST_PAGINATION_FAIL = '[Rest Api] pagination step test pagination fail';
export const TRACKER_REST_API_OAUTH2_CLICK = '[Rest Api] oauth2 click';
export const TRACKER_REST_API_OAUTH2_CREATE_TOKEN_CLICK = '[Rest Api] oauth2 create token click';
export const TRACKER_REST_API_OAUTH2_CREATE_TOKEN_SUCCESS = '[Rest Api] oauth2 create token success';
export const TRACKER_REST_API_OAUTH2_CREATE_TOKEN_FAIL = '[Rest Api] oauth2 create token fail';



export const AUTH_REDIRECT_RESPONSE_MESSAGE = {
  'invalid_request': 'Your HTTP request is missing some required parameters.',
  'access_denied': 'Authorization request was denied.',
  'invalid_scope': 'Requested scope is not valid or is unsupported.',
  'server_error': 'Requested scope is not valid or is unsupported.',
  'temporarily_unavailable': 'Internal server error. Please retry in a while.',
  'unauthorized_client': 'Client not authorized to access the requested resource.',
  'unsupported_response_type': '',
  'invalid_url_error': 'Invalid Auth URL.'
};

export const DEFAULT_KEY_VALUE_DATA = [ undefined, undefined ];

export const DEFAULT_QUERY_PARAMS_DATA = {
  type: QUERY_PARAM_TYPES[0],
  key: undefined,
  value: undefined,
  format: undefined
};
