import { fromEvent, Observable } from 'rxjs';
import { takeUntil, throwIfEmpty } from 'rxjs/operators';

export class PromiseCancelError extends Error {
  readonly isPromiseCancel = true;
}

export function isPromiseCancelError(error: Error) {
  return error instanceof PromiseCancelError && error.isPromiseCancel;
}

export function convertToPromise(observable: Observable<any>, abortSignal?: AbortSignal) {
  let observableModified = observable;

  if (abortSignal) {
    observableModified = observableModified.pipe(
      takeUntil(fromEvent(abortSignal, 'abort')),
      throwIfEmpty(() => new PromiseCancelError('Promise Cancelled'))
    );
  }

  return observableModified.toPromise();
}
