import { Injectable, Injector } from '@angular/core';
import { SSHService } from '../../core/service/ssh.service';
import { DESTINATION_TYPES } from '../destination-type/model';
import { NodeTypeConfigHelperConstructor } from '../node-config-helper';
import { NodeConfigHelperService } from '../node-config-helper.service';
import { SnowflakeAutoFillExperimentService } from '../nodes-settings/experiments';
import { DestinationTypeConfigHelper } from './destination-type-config-helper';


@Injectable({ providedIn: 'root' })
export class DestinationConfigHelperService extends NodeConfigHelperService {
  constructor(
    protected _injector: Injector,
    private _sshService: SSHService,
    private _snowflakeAutoFillExperimentService: SnowflakeAutoFillExperimentService
  ) {
    super(_injector);
  }

  getRenderingLibrary(nodeTypeIdentifier: string): string {
    return DESTINATION_TYPES[nodeTypeIdentifier].formRenderingLibrary;
  }

  getConfigHelperClass(nodeTypeIdentifier: string): NodeTypeConfigHelperConstructor {
    return DESTINATION_TYPES[nodeTypeIdentifier].configHelper;
  }

  getConfigHelperClassInstance(nodeTypeIdentifier: string): DestinationTypeConfigHelper {
    const configHelperClass = this.getConfigHelperClass(nodeTypeIdentifier);

    if (nodeTypeIdentifier === 'SNOWFLAKE') {
      return new configHelperClass(this._snowflakeAutoFillExperimentService);
    }

    if ([ 'REDSHIFT', 'MYSQL', 'AURORA', 'POSTGRES', 'MS_SQL' ].includes(nodeTypeIdentifier)) {
      return new configHelperClass(nodeTypeIdentifier, this._sshService);
    }

    return new configHelperClass(nodeTypeIdentifier);
  }
}
