import { GenericFieldHTMLAttributes } from 'formik';
import React, { useEffect, useState } from 'react';
import { IntercomService } from '../../../app/core/service/intercom.service';
import { PlanChatSupportService } from '../../../app/core/service/plan-chat-support.service';
import useService from '../../hooks/useService';
import useUserService from '../../hooks/services/useUserService';
import { UpgradeToLiveChatDialog } from '../UpgradeToLiveChatDialog';

export function HdSupportButton({
  Component,
  children,
  message,
  nodeCategory = '',
  ...props
}: {
  Component: React.ComponentType<any>;
  children?: React.ReactNode;
  message?: string;
  nodeCategory?: string;
} & GenericFieldHTMLAttributes & {
    [key: string]: any;
  }) {
  const [upgradeToLiveChatConfig, setUpgradeToLiveChatConfig] = useState<{
    open: boolean;
    isCurrentUserOwner?: boolean;
    currentPlanTier?: any;
  }>({
    open: false
  });

  const planChatSupportService = useService(PlanChatSupportService);
  const intercomService = useService(IntercomService);
  const { isCurrentUserOwner } = useUserService();

  const initChatSupport = () => {
    const urlMatchingActivate = nodeCategory === 'ACTIVATION';
    const urlMatchingTarget = nodeCategory === 'TARGET';

    if (!planChatSupportService.isLiveChatDisabled() || urlMatchingActivate || urlMatchingTarget) {
      intercomService.show(message);
    } else {
      setUpgradeToLiveChatConfig({
        open: true,
        currentPlanTier: planChatSupportService.currentPlanTier,
        isCurrentUserOwner
      });
    }
  };

  useEffect(() => {
    const sub = planChatSupportService.liveChatDisabled$.subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <>
      <Component
        {...props}
        onClick={e => {
          initChatSupport();

          if (props.onClick) {
            props.onClick(e);
          }
        }}
      >
        {children}
      </Component>

      <UpgradeToLiveChatDialog
        {...upgradeToLiveChatConfig}
        onClose={() => setUpgradeToLiveChatConfig({ open: false })}
      />
    </>
  );
}
