import { GENERIC_VARIATION } from '../../../../../../app/nodes/nodes-settings/constants';
import { EntityVariationData } from '../../../../../../app/nodes/nodes-settings/models';
import { MongodbVariations } from './models';


export const MONGODB_VARIATIONS: EntityVariationData[] = [
  {
    name: 'MongoDB Atlas',
    value: MongodbVariations.ATLAS,
    description: 'MongoDB Atlas is a fully-managed cloud database service that provides an easy way to deploy, operate, and scale ' +
      'MongoDB on multiple cloud providers (AWS, Azure, and GCP).',
    displayName: 'MongoDB Atlas',
    docLink: '/sources/databases/mongodb/mongodb-atlas/'
  },
  {
    name: 'Generic MongoDB',
    value: GENERIC_VARIATION,
    description: 'Select this option if you use a different cloud provider or if you manage MongoDB databases on your own.',
    displayName: 'MongoDB',
    docLink: '/sources/databases/mongodb/generic-mongodb/'
  }
];
