import { Pipe, PipeTransform } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { ColorMode } from '../core/models/color-mode';
import { ColorModeService } from '../core/service/color-mode.service';
import { LottieAnimationFileOptions } from './interface';


@Pipe({
  name: 'lottieAnimationFilePipe',
  pure: false
})
export class LottieAnimationFilePipe implements PipeTransform {
  private _inputOptions: AnimationOptions;
  private _outputOptions: AnimationOptions;
  private _colorMode: ColorMode;

  constructor(private _colorModeService: ColorModeService) {
  }

  transform(options: AnimationOptions, files: LottieAnimationFileOptions): AnimationOptions {
    const colorMode = this._colorModeService.getColorModeValue();

    if (this._inputOptions === options && colorMode === this._colorMode) {
      return this._outputOptions;
    }

    this._inputOptions = options;
    this._colorMode = colorMode;

    const path = colorMode === ColorMode.DARK && files.dark ? files.dark : files.light;

    this._outputOptions = { ...options, path };

    return this._outputOptions;
  }
}
