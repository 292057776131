import { GlobalSearchAPI } from './globalSearchAPI';
import { GlobalSearchReducerActions } from './GlobalSearchReducer';

export function fetchEntitiesData(
  actions: GlobalSearchReducerActions,
  fetchActivations = true,
  isWorkFlowsEnabled = false
) {
  GlobalSearchAPI.fetchPipelines(actions);
  GlobalSearchAPI.fetchDestinations(actions);

  GlobalSearchAPI.fetchModels(actions);

  if (isWorkFlowsEnabled) GlobalSearchAPI.fetchWorkflows(actions);

  if (fetchActivations) {
    GlobalSearchAPI.fetchTargets(actions);
    GlobalSearchAPI.fetchActivations(actions);
  }

  GlobalSearchAPI.fetchSourceTypes(actions);
  GlobalSearchAPI.fetchDestinationTypes(actions);
}

export function fetchAlgoliaResults(
  search: string,
  actions: GlobalSearchReducerActions,
  isWorkFlowsEnabled: boolean,
  isActivationEnabled: boolean
) {
  GlobalSearchAPI.fetchAlgoliaResults(search, actions, isWorkFlowsEnabled, isActivationEnabled);
}

export function fetchEntitiesStaticDocs() {
  return GlobalSearchAPI.fetchEntitiesStaticDocs();
}
