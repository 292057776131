import React from 'react';
import { AlertDialogProvider } from './components/Dialog/AlertDialog/AlertDialogProvider';
import { ConfirmDialogProvider } from './components/Dialog/ConfirmDialog/ConfirmDialogV1/ConfirmDialogV1Provider';
import { ConfirmDialogV2Provider } from './components/Dialog/ConfirmDialog/ConfirmDialogV2/ConfirmDialogV2Provider';

// Re-usable dialogs go here;
export function RootWrapper({ children }) {
  return (
    <ConfirmDialogProvider>
      <ConfirmDialogV2Provider>
        <AlertDialogProvider>{children}</AlertDialogProvider>
      </ConfirmDialogV2Provider>
    </ConfirmDialogProvider>
  );
}
