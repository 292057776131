// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.jobsListHeader_QCDDY {\n  border-top: 1px solid var(--default-border-color);\n  border-bottom: 1px solid var(--default-border-color);\n  justify-content: space-between;\n  display: flex;\n  padding: 8px 20px;\n}\n\n.fieldCell_Mode_uDwqY {\n  display: flex;\n  align-items: center;\n  width: 120px;\n  padding: 4px 0px;\n}\n\n.fieldCell_Pipeline_PVudX {\n  display: flex;\n  align-items: center;\n  width: 230px;\n  padding: 4px 6px;\n}\n\n.fieldCell_Started_at_Bri0e {\n  display: flex;\n  align-items: center;\n  width: 135px;\n  padding: 4px 6px;\n}\n\n.fieldCell_Events_qrndz {\n  display: flex;\n  align-items: center;\n  width: 112px;\n  padding: 4px 6px;\n}\n\n.fieldCell_Status_NsBM6 {\n  display: flex;\n  align-items: center;\n  padding: 4px 4px;\n  width: 126px;\n}\n\n.fieldCell_Duration_lgbHj {\n  display: flex;\n  align-items: center;\n  padding: 4px 6px;\n  width: 108px;\n}\n\n.jobsListContainer_DqY21 {\n  overflow: auto;\n  height: 74vh;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobsListHeader": "jobsListHeader_QCDDY",
	"fieldCell_Mode": "fieldCell_Mode_uDwqY",
	"fieldCell_Pipeline": "fieldCell_Pipeline_PVudX",
	"fieldCell_Started_at": "fieldCell_Started_at_Bri0e",
	"fieldCell_Events": "fieldCell_Events_qrndz",
	"fieldCell_Status": "fieldCell_Status_NsBM6",
	"fieldCell_Duration": "fieldCell_Duration_lgbHj",
	"jobsListContainer": "jobsListContainer_DqY21"
};
export default ___CSS_LOADER_EXPORT___;
