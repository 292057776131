import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { JobMode } from '../source-type/job-mode';

export interface SourceTypeConfigHelper {
  /**
   * Get default config to preload the form.
   * @param metaData: The source type metadata resolved before the form loads.
   *                  This is combination of data fetched from metaDataResolver & staticDependenciesResolver
   */
  getDefaultConfig: (metaData?: any) => any;
  /**
   * Get the config to preload the form from the provided rawData
   * @param rawData: values cached in local storage,
   *                 or existing data in case of edit source
   *                 or value from sample settings
   *                 or value from existing sources dropdown
   * @param metaData: The source type metadata resolved before the form loads.
   *                  This is combination of data fetched from metaDataResolver & staticDependenciesResolver
   */
  getConfigFromRawData: (rawData: any, metaData?: any) => any;
  /**
   * Returns the list of items to be rendered in a dropdown in test connection error handling dialog. This is only
   * applicable for Bigquery right now, and is not scalable. A more scalable approach would be a way to pass a component
   * that handles the test connection error.
   */
  getDropdownList?: () => any;
  /**
   * Convert the form data model into a model that can be sent to backend for test connection/create and similar APIs.
   * @param data: Data Model that was returned from the form
   */
  buildConnectionParams?: (data: any) => Observable<any> | Promise<any> | any;
  /**
   * Hook to fetch any form dependencies before the form is shown in the UI. This is specifically used to fetch data
   * based on the current state of form
   * @param rawData: values cached in local storage,
   *                 or existing data in case of edit source
   *                 or value from sample settings
   *                 or value from existing sources dropdown
   */
  metaDataResolver?: (sourceType: string, authData: any, rawData: any, draftData?: any) => Observable<any> | Promise<any> | any;
  /**
   * Hook to fetch any form dependencies before the form is shown in the UI. This is specifically used to fetch
   * data that does'nt depend on the form state.
   */
  staticDependenciesResolver?: (sourceType: string) => Observable<any> | Promise<any> | any;
}

export interface SourceTypeConfigHelperConstructor {
  new(...args: any[]): SourceTypeConfigHelper;
}

export const SOURCE_TYPE_IDENTIFIER = new InjectionToken<string>('');
export const JOB_MODE = new InjectionToken<JobMode>('');
