import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EmailVerificationService } from '../service/email-verification.service';
import { UserService } from '../service/user.service';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationGuard implements CanActivate {
  constructor(
    private _emailVerificationService: EmailVerificationService,
    private _userService: UserService,
    private _route: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this._emailVerificationService.emailVerified$.pipe(
      map((status) => {
        let allowNavigation = true;

        if (status.blocked) {
          allowNavigation = false;
        }

        if (!allowNavigation) {
          this._route.navigate(['verify-email-block']);
        }
        return allowNavigation;
      }),
      catchError((error) => {
        return of(true);
      })
    );
  }
}
