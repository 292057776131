<ng-container *ngIf="keys && keys.length">
  <span
    *ngFor="let key of keys; index as index"
    class="badge badge-key {{ key.class }}"
    tooltip="{{ key.name }}"
    [tooltipDisabled]="tooltipDisabled || key.tooltipDisabled"
    [class.ml-1]="index > 0">
    {{ key.abbr }}
    <ng-container *ngIf="key.index > 0"></ng-container>
  </span>
</ng-container>
