import { FireboltTableTypeEnum, SchemaTableTypeData } from './interface';


const FACT_TABLE_TYPE_DESCRIPTION =
  'FACT tables should be used for larger and most granular tables. It is recommended to define very large tables as FACT.';
const DIMENSION_TABLE_TYPE_DESCRIPTION =
  'DIMENSION tables should be used for the smaller tables that are more descriptive in nature and are joined with the FACT tables. '
  + 'It is recommended to define smaller tables as DIMENSION.';

export const FIREBOLT_MAP_EVENTS_DOC_LINK = '/destinations/data-warehouses/firebolt/mapping-events-to-firebolt-tables/';
export const FIREBOLT_DESTINATION_CONSIDERATIONS_DOC_LINK = '/destinations/data-warehouses/firebolt/#destination-considerations';
export const FIREBOLT_INDEXING_DOC_LINK =
  'https://docs.firebolt.io/concepts/get-instant-query-response-time#create-and-generate-an-aggregating-index';
export const FIREBOLT_TABLE_TYPE_INFO_DOC_LINK =
  '/destinations/data-warehouses/firebolt/mapping-events-to-firebolt-tables/configuring-fact-and-dimension-tables/';

export const TABLE_TYPES_INFO_DATA: SchemaTableTypeData[] = [
  {
    imgLightModeSrc: 'https://res.cloudinary.com/hevo/image/upload/v1629955589/hevo-website/integrations/fact_light_abl6cj.svg',
    imgDarkModeSrc: 'https://res.cloudinary.com/hevo/image/upload/v1629955589/hevo-website/integrations/fact_dark_dbwj3h.svg',
    title: 'Fact Tables',
    description: FACT_TABLE_TYPE_DESCRIPTION,
    docLink: FIREBOLT_TABLE_TYPE_INFO_DOC_LINK,
    tableType: {
      tableType: FireboltTableTypeEnum.FACT_TABLE,
      displayText: 'Fact Table',
      description: FACT_TABLE_TYPE_DESCRIPTION
    }
  }, {
    imgLightModeSrc: 'https://res.cloudinary.com/hevo/image/upload/v1629955589/hevo-website/integrations/dimension_light_nms5uv.svg',
    imgDarkModeSrc: 'https://res.cloudinary.com/hevo/image/upload/v1629955589/hevo-website/integrations/dimension_dark_wkoc4x.svg',
    title: 'Dimension Tables',
    description: DIMENSION_TABLE_TYPE_DESCRIPTION,
    docLink: FIREBOLT_TABLE_TYPE_INFO_DOC_LINK,
    tableType: {
      tableType: FireboltTableTypeEnum.DIMENSION_TABLE,
      displayText: 'Dimension Table',
      description: DIMENSION_TABLE_TYPE_DESCRIPTION
    }
  }
];

export const TRACKER_CREATE_TABLE_CLICK = '[Mapper] schema builder create table button clicked';
export const TRACKER_CANCEL_CLICK = '[Mapper] schema builder cancel button clicked';

export const TRACKER_TABLE_TYPE_SELECT_BUTTON_CLICK = '[Firebolt] schema builder selelct table type button clicked';
export const TRACKER_TABLE_TYPE_DROPDOWN_CLICK = '[Firebolt] schema builder table type dropdown clicked';
export const TRACKER_FIELDS_INFO_TOOL_CLICK = '[Firebolt] schema builder field info tool toggled';
export const TRACKER_WARNING_DIALOG_CREATE_TABLE_CLICK = '[Firebolt] warning dialog create table button clicked';
export const TRACKER_FIREBOLT_INDEXING_DOC_LINK_CLICK = '[Firebolt] firebolt indexing docs link click';

export const DATA_TYPE_JSON = 'json';

export const DATA_TYPE_JSON_STORED_VALUE = 'io.hevo.core.data.Type.NativeJson';

export const SCHEMA_BUILDER_TABLE_LENGTH_CHECK_LIMIT = 1024;

export const BIG_QUERY_CLUSTER_KEY_DATA_TYPES = [
  'org.apache.kafka.connect.data.date',
  'org.apache.kafka.connect.data.timestamp',
  'string',
  'boolean',
  'int8',
  'int16',
  'int32',
  'int64',
  'org.apache.kafka.connect.data.decimal',
  'float32',
  'float64'
];

export const DISABLED_PRIMARY_KEY_DATA_TYPES = [
  'geometry'
];

// DateTime & Geography types are not handled
export enum BigQueryPartitionStyleEnum {
  NO_PARTITION = 'NO_PARTITION',
  INGESTION_TIME_BASED_PARTITION = 'INGESTION_TIME_BASED_PARTITION',
  FIELD_BASED_PARTITION = 'FIELD_BASED_PARTITION'
}

export enum BigQueryPartitionTypeEnum {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export const BIGQUERY_PARTITION_STYLES = [
  { name: 'No Partition', value: BigQueryPartitionStyleEnum.NO_PARTITION },
  { name: 'Ingestion Time Based ', value: BigQueryPartitionStyleEnum.INGESTION_TIME_BASED_PARTITION },
  { name: 'Field Based', value: BigQueryPartitionStyleEnum.FIELD_BASED_PARTITION }
];
