import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { HdIcon } from '../../../../components/UIElements';

interface JobModeBadgeProps {
  mode: string;
}

const MODE_ICON_MAP = {
  incremental: 'incremental',
  historical: 'history',
  refresher: 'refresh',
  streaming: 'real-time'
};

export function JobModeBadge({ mode }: JobModeBadgeProps) {
  return (
    <div className={clsx('text-body-2', styles.jobModeBadgeContainer)}>
      <HdIcon name={MODE_ICON_MAP[mode]} />
      <span className={styles.jobModeBadgeText}>{mode}</span>
    </div>
  );
}
