import React from 'react';
import { FieldProps, getIn } from 'formik';
import { FormikHelperText } from '../../../utils/FormikHelperText';
import TimeSelector from '../../UIElements/TimeSelector';
import { formatTimeToString } from '../../UIElements/TimeSelector/utils';

interface HdFormikTimeSelectorProps extends FieldProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  iconSize?: number;
  helperText?: React.ReactNode;
  showDropdownOptions: boolean;
  onChangeEventHandler: Function;
}

export default function HdFormikTimeSelector({
   field,
   form: { touched, errors, setFieldValue, setFieldTouched },
   label,
   placeholder,
   id,
   iconSize,
   required = false,
   helperText = ' ',
   showDropdownOptions = true,
   onChangeEventHandler
 }: HdFormikTimeSelectorProps) {
  const showError =
    (getIn(errors, field.name) && getIn(touched, field.name)) ||
    (getIn(touched, field.name) && formatTimeToString(field.value).length !== 7);

  return (
    <TimeSelector
      dataId={field.name}
      id={id || field.name}
      value={field.value}
      label={label}
      required={required}
      iconSize={iconSize}
      onChange={date => {
        setFieldValue(field.name, date);
        if (typeof onChangeEventHandler === 'function') {
          onChangeEventHandler(date);
        }
      }}
      placeholder={placeholder || label}
      helperText={FormikHelperText({
        helperText,
        showError,
        fieldName: field.name,
        errorMessage: errors[field.name]
      })}
      onBlur={() => {
        setFieldTouched(field.name);
      }}
      showDropdownOptions={showDropdownOptions}
      error={!!showError}
    />
  );
}
