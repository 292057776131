import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { HevoEntity } from '../../core/models/hevo-entity';
import { User } from '../../core/models/user';
import { BeaconService } from '../../core/service/beacon.service';
import { InactivityService } from '../../core/service/inactivity.service';
import { RecentEntitiesService } from '../../core/service/recent-entities.service';
import { TrialDetailsService } from '../../core/service/trial-details.service';
import { UserService } from '../../core/service/user.service';

declare const __PCNA_DROP_SUPPORT_ZAP_URL__: string;

enum DropReason {
  'IDLE' = 'IDLE',
  'TAB_CLOSE' = 'TAB_CLOSE'
}

@Injectable({ providedIn: 'root' })
export class PCNASupportService implements OnDestroy {
  private _dropSupportTriggered = false;
  private _inactiveTimeout = 900000;
  private isUserInTrial: boolean;
  private _user: User;
  private _createPipelineTriggered = false;

  private _resetListeners$ = new Subject();

  constructor(
    private _inactivityService: InactivityService,
    private _userService: UserService,
    private _trialDetailsService: TrialDetailsService,
    private _beaconService: BeaconService,
    private _recentEntitiesService: RecentEntitiesService,
    private _ngZone: NgZone,
    private _router: Router
  ) {
  }

  /**
   * On Init is called each time user logs into the system,
   * or if a user is already logged into the system on page load
   */
  init() {
    this._resetListeners$.next();

    /**
     * Setting user information on init, in order to retain
     * these values for tracking, even after logout.
     */
    this._user = this._userService.getUser();

    this._trialDetailsService.trialDetails$.pipe(
      take(1),
      takeUntil(this._resetListeners$)
    ).subscribe((data) => {
      this.isUserInTrial = data.isInTrial();
    });

    this._ngZone.runOutsideAngular(() => {
      this._inactivityService.onInactivity$(this._inactiveTimeout).pipe(
        takeUntil(this._resetListeners$)
      ).subscribe(() => {
        this._triggerDropSupportIfApplicable(DropReason.IDLE);
      });
    });

    fromEvent(window, 'pagehide').pipe(
      takeUntil(this._resetListeners$)
    ).subscribe(() => {
      this._triggerDropSupportIfApplicable(DropReason.TAB_CLOSE);
    });
  }

  onCreatePipelineTrigger() {
    this._createPipelineTriggered = true;
  }

  private _triggerDropSupportIfApplicable(reason: DropReason) {
    if (
      this._createPipelineTriggered
      || this._recentEntitiesService.getRecent(HevoEntity.PIPELINE.value)
      || this._dropSupportTriggered
      || !this.isUserInTrial
    ) {
      return;
    }

    this._dropSupportTriggered = this._beaconService.sendBeacon(
      __PCNA_DROP_SUPPORT_ZAP_URL__,
      this._buildDataForSupport(reason)
    );
  }

  private _buildDataForSupport(reason) {
    const data = new FormData();

    data.set('page', this._router.url);
    data.set('email', this._user.email);
    data.set('teamId', this._user.teamId.toFixed(0));
    data.set('teamName', this._user.teamName);
    data.set('env', this._user.clusterId);
    data.set('triggerReason', reason);

    return data;
  }

  ngOnDestroy() {
    this._resetListeners$.next();
    this._resetListeners$.complete();
  }
}
