import { getHistoricalOptionFromConfigData } from '../../../../../../app/nodes/nodes-settings/historical-load-option';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
// eslint-disable-next-line import/no-cycle
import PipelinesAPI from '../../../PipelinesAPI';
import {
  GoogleAdsApiVersion,
  GoogleAdsConfigPayload,
  GoogleAdsConfigResponse,
  GoogleAdsCustomReport,
  GoogleAdsFormFieldNames,
  GoogleAdsFormMetaData,
  GoogleAdsFormState,
  GoogleAdsReportCategory,
  GoogleAdsResourceFieldData
} from './models';
import { getInclusionListStateFromRawData, getSelectedCustomersFromRawData } from './util';

const googleAdsOlderAPIDefaultVersion: GoogleAdsApiVersion = {
  id: 'V_13',
  name: 'V_13',
  helperText: null
};

export class GoogleAdsReactConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig(metaData: GoogleAdsFormMetaData) {
    const conversionWindow = metaData.conversionWindowOptions.find(window => window.id === 90);

    return {
      [GoogleAdsFormFieldNames.REPORT_CATEGORY]: GoogleAdsReportCategory.STANDARD_REPORTS,
      [GoogleAdsFormFieldNames.CONVERSION_WINDOW]: conversionWindow || 90,
      [GoogleAdsFormFieldNames.SELECTED_PREDEFINED_REPORTS]: metaData.reportOptions,
      [GoogleAdsFormFieldNames.AGGREGATION_WINDOW]: 'Daily',
      [GoogleAdsFormFieldNames.GOOGLE_ADS_API_VERSION]:
        metaData.apiVersions.length > 0 ? metaData.apiVersions[0] : null
    };
  }

  getConfigFromRawData(rawData: GoogleAdsConfigResponse, metaData: GoogleAdsFormMetaData) {
    const conversionWindow = metaData.conversionWindowOptions.find(
      window => rawData.conversion_window === window.id
    );

    const selectedApiVersion = metaData.apiVersions.find(
      apiVersion => apiVersion.id === rawData.api_version
    );
    const googleApiOldVersion = metaData.apiVersions.find(v => v.id === 'V_13');

    const config = {
      [GoogleAdsFormFieldNames.CUSTOMER_ID]: rawData.customer_id,
      [GoogleAdsFormFieldNames.SELECTED_CUSTOMERS]: getInclusionListStateFromRawData(
        rawData.selected_customers
      ),
      [GoogleAdsFormFieldNames.REPORT_CATEGORY]: rawData.is_custom_report
        ? GoogleAdsReportCategory.CUSTOM_REPORTS
        : GoogleAdsReportCategory.STANDARD_REPORTS,
      [GoogleAdsFormFieldNames.AGGREGATION_WINDOW]: rawData.aggregation_window,
      [GoogleAdsFormFieldNames.CONVERSION_WINDOW]: conversionWindow || rawData.conversion_window,
      [GoogleAdsFormFieldNames.HISTORICAL_SYNC_DURATION]: getHistoricalOptionFromConfigData(
        rawData,
        metaData.historicalLoadOptions
      ),
      [GoogleAdsFormFieldNames.GOOGLE_ADS_API_VERSION]: selectedApiVersion || googleApiOldVersion || {
        id: rawData.api_version,
        name: rawData.api_version,
        helperText: null
      } || googleAdsOlderAPIDefaultVersion
    };

    if (rawData.is_custom_report) {
      const customReport: GoogleAdsCustomReport = rawData.custom_reports[0];

      config[GoogleAdsFormFieldNames.SELECTED_CUSTOM_REPORT] = customReport.report;

      config[GoogleAdsFormFieldNames.SELECTED_SEGMENTS] = customReport.segments.map(field =>
        this._RawDataToResourceFactory(field, metaData.customFieldsData)
      );
      config[GoogleAdsFormFieldNames.SELECTED_DIMENSIONS] = customReport.dimensions.map(field =>
        this._RawDataToResourceFactory(field, metaData.customFieldsData)
      );
      config[GoogleAdsFormFieldNames.SELECTED_METRICS] = customReport.metrics.map(field =>
        this._RawDataToResourceFactory(field, metaData.customFieldsData)
      );

      config[GoogleAdsFormFieldNames.SEGMENTED_RESOURCE_FIELDS] =
        customReport.segmented_resource_fields || [];
      config[GoogleAdsFormFieldNames.ATTRIBUTED_RESOURCE_FIELDS] =
        customReport.attributed_resource_fields?.map(value => ({ name: value })) || [];
      config[GoogleAdsFormFieldNames.ATTRIBUTED_AND_SEGMENTED_RESOURCE_FIELDS] = [
        ...config[GoogleAdsFormFieldNames.SEGMENTED_RESOURCE_FIELDS],
        ...config[GoogleAdsFormFieldNames.ATTRIBUTED_RESOURCE_FIELDS]
      ];
    } else {
      config[GoogleAdsFormFieldNames.SELECTED_PREDEFINED_REPORTS] = rawData.predefined_reports;
    }

    if (typeof rawData.is_custom_report !== 'boolean') {
      config[GoogleAdsFormFieldNames.REPORT_CATEGORY] = GoogleAdsReportCategory.STANDARD_REPORTS;
      config[GoogleAdsFormFieldNames.AGGREGATION_WINDOW] = 'Daily';
    }

    return config;
  }

  buildConnectionParams(data: GoogleAdsFormState) {
    const { id } = data[GoogleAdsFormFieldNames.CONVERSION_WINDOW] as { id: number };
    const conversionWindow = id || (data[GoogleAdsFormFieldNames.CONVERSION_WINDOW] as number);

    const params: GoogleAdsConfigPayload = {
      customer_id: data.customerId,
      selected_customers: getSelectedCustomersFromRawData(
        data[GoogleAdsFormFieldNames.SELECTED_CUSTOMERS],
        data[GoogleAdsFormFieldNames.MANAGERS]
      ),
      is_custom_report:
        data[GoogleAdsFormFieldNames.REPORT_CATEGORY] === GoogleAdsReportCategory.CUSTOM_REPORTS,
      aggregation_window: data[GoogleAdsFormFieldNames.AGGREGATION_WINDOW],
      conversion_window: conversionWindow,
      api_version: data[GoogleAdsFormFieldNames.GOOGLE_ADS_API_VERSION].id
    };

    if (data[GoogleAdsFormFieldNames.REPORT_CATEGORY] === GoogleAdsReportCategory.CUSTOM_REPORTS) {
      // logic to split the segment vs attributes
      const selectedAttributedResource = [];
      const selectedSegmentedResource = [];

      if (data[GoogleAdsFormFieldNames.ATTRIBUTED_AND_SEGMENTED_RESOURCE_FIELDS]) {
        data[GoogleAdsFormFieldNames.ATTRIBUTED_AND_SEGMENTED_RESOURCE_FIELDS].forEach(selected => {
          const [category] = selected.name.split('.');

          if (data[GoogleAdsFormFieldNames.SEGMENTED_RESOURCES].includes(category)) {
            selectedSegmentedResource.push(selected.name);
          } else {
            selectedAttributedResource.push(selected.name);
          }
        });
      }

      const customReport: GoogleAdsCustomReport = {
        report: data[GoogleAdsFormFieldNames.SELECTED_CUSTOM_REPORT],
        segments: [
          ...this._ResourceFieldDataFactory(data[GoogleAdsFormFieldNames.SELECTED_SEGMENTS] || [])
        ],
        dimensions: [
          ...this._ResourceFieldDataFactory(data[GoogleAdsFormFieldNames.SELECTED_DIMENSIONS] || [])
        ],
        metrics: [
          ...this._ResourceFieldDataFactory(data[GoogleAdsFormFieldNames.SELECTED_METRICS] || [])
        ],
        attributed_resource_fields: selectedAttributedResource,
        segmented_resource_fields: selectedSegmentedResource
      };

      params.custom_reports = [customReport];
    } else {
      params.predefined_reports = data[GoogleAdsFormFieldNames.SELECTED_PREDEFINED_REPORTS];
    }

    return params;
  }

  async staticDependenciesResolver() {
    const promises = [
      PipelinesAPI.getGoogleAdsCustomFieldsData(),
      PipelinesAPI.getGoogleAdsConversionWindow(),
      PipelinesAPI.getGoogleAdsAPIVersions()
    ];

    // eslint-disable-next-line no-return-await
    return await Promise.all(promises)
      .then(([customFieldsData, conversionWindows, apiVersions]) => ({
        customFieldsData,
        conversionWindowOptions: (conversionWindows.data || []).map(window => {
          const [index] = Object.keys(window);
          return { id: parseInt(index, 10), name: window[index] };
        }),
        apiVersions: apiVersions.data.map(apiVersion => ({
          id: apiVersion.name,
          name: apiVersion.display_name,
          helperText: apiVersion.helper_text || '',
          ...(apiVersion.banner
            ? {
                banner: {
                  message: apiVersion.banner.message,
                  level: apiVersion.banner.level,
                  ...(apiVersion.banner.doc
                    ? {
                        doc: {
                          text: apiVersion.banner.doc.text,
                          url: apiVersion.banner.doc.url
                        }
                      }
                    : {})
                }
              }
            : {})
        }))
      }))
      .catch(() => ({
        reportOptions: [],
        customFieldsData: {},
        conversionWindowOptions: [],
        apiVersions: []
      }));
  }

  private _ResourceFieldDataFactory(resource: GoogleAdsResourceFieldData[]) {
    return resource.map(item => item.value);
  }

  private _RawDataToResourceFactory(
    field: string,
    customFieldsData: any
  ): GoogleAdsResourceFieldData {
    return {
      id: field,
      name: field,
      value: field,
      ...customFieldsData[field]
    };
  }
}
