// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.authHeading_dhaSq {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-bottom: 60px;\n}\n\n.hevoLogo_Xg_xX {\n  height: 50px;\n  margin-bottom: 16px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authHeading": "authHeading_dhaSq",
	"hevoLogo": "hevoLogo_Xg_xX"
};
export default ___CSS_LOADER_EXPORT___;
