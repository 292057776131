export enum DestinationConfigWarningFieldEnum {
  BUCKET = 'BUCKET',
  PROJECT_ID = 'PROJECT_ID',
  DATASET_NAME = 'DATASET_NAME'
}

export interface DestinationConfigWarningField {
  title: string;
  description?: string;
  value: DestinationConfigWarningFieldEnum
}
