import React from 'react';

interface BadgeLabelProps {
  color?: string;
  className?: string;
  variant?: 'content' | 'dot';
  children?: React.ReactNode;
}

export function BadgeLabel({
  variant = 'content',
  color = 'default',
  className,
  children
}: BadgeLabelProps) {
  return (
    <span
      className={`hd-label hd-badge-label hd-badge-label-${variant} hd-badge-label-${color} ${className}`}
      data-id='Badge-Label-Test'
    >
      {variant === 'content' ? children : null}
    </span>
  );
}

// supported colors
// export const BADGE_LABEL_COLORS = ['default', 'success', 'warning', 'error', 'deferred'];
