import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { HdButton, HdIcon, HdIconButton } from '../../components/UIElements';
import { JobTypes } from './model';
import { getDataIdsFromContract } from '../../utils/generateDataId';
import PaginationControl from '../../components/PaginationControl';
import {
  ListNextPage,
  ListPrevPage,
  RefreshList
} from '../../../app/pagination/list-conductor/list-actions';
import { JobsList } from './components/JobsList';
import { DEFAULT_LIMIT, PAGINATION_STRATEGY } from './constants';
import { EntityUIState } from '../../../app/core/models/entitiy-ui-state';
import { useQuery } from '../../hooks/useQuery';
import { getPaginationStrategy } from '../../components/MultiLevelSelector/util';
import JobsAPI from './JobsAPI';
import HdIndeterminateProgressBar from '../../components/UIElements/HdProgressBar/IntedeterminateProgressBar';
import { NoItemBox } from '../drawer/Shared/NoItemBox';
import RetryApiAlert from '../../components/RetryApiAlert';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import {
  TRACKER_ALL_JOBS_JOB_TYPE_TAB_CLICK,
  TRACKER_ALL_JOBS_NEXT_PAGE_CLICK,
  TRACKER_ALL_JOBS_PREV_PAGE_CLICK,
  TRACKER_ALL_JOBS_REFRESH_LIST_CLICK
} from './tracking';

const DATA_ID_CONTRACTS = {
  base: 'job-monitoring',
  batchTab: 'batch-tab',
  streamingTab: 'streaming-tab',
  filters: 'filters',
  searchArea: '',
  paginationControl: '',
  refreshList: 'refreshList',
  loader: 'loader',
  noItemBox: 'noItemBox',
  retryBtn: '',
  jobList: 'jobList'
};

export const JobMonitoringDashboardDataIds = getDataIdsFromContract(DATA_ID_CONTRACTS);

export function JobMonitoringDashboard() {
  const { eventTrack } = useAnalyticsTracker();
  const [selectedJobType, setSelectedJobType] = useState(JobTypes.BATCH);
  const pagination = getPaginationStrategy(PAGINATION_STRATEGY);

  const {
    fetch: getAllJobs,
    listConductor,
    uiState,
    error,
    data: jobsList
  } = useQuery({
    dataSource$: () =>
      JobsAPI.getAllJobs(selectedJobType, listConductor.getListMetaNetworkParams()).then(res => ({
        responseMetaData: {
          count: res.total_count
        },
        items: res.data
      })),
    paginated: true,
    listConductorOptions: {
      handleQueryParams: false,
      paginationStrategy: pagination.getStrategy(DEFAULT_LIMIT)
    }
  });

  const listConductorMeta = listConductor.getListMeta();
  const listConductorRefreshing = uiState === EntityUIState.REFRESHING;
  const fetchingList = uiState === EntityUIState.LOADING || uiState === EntityUIState.REFRESHING;

  useEffect(() => {
    listConductor.dispatch(new RefreshList(true));
  }, [selectedJobType]);

  const handleJobTypeChange = type => {
    eventTrack({
      action: TRACKER_ALL_JOBS_JOB_TYPE_TAB_CLICK,
      properties: {
        selectedJobType: type
      }
    });
    setSelectedJobType(type);
  };

  const streamingTypeActive = selectedJobType === JobTypes.STREAMING;
  const batchTypeActive = selectedJobType === JobTypes.BATCH;

  const onPrevClick = () => {
    eventTrack({
      action: TRACKER_ALL_JOBS_PREV_PAGE_CLICK,
      properties: {
        selectedJobType
      }
    });

    listConductor.dispatch(new ListPrevPage());
  };

  const onNextClick = () => {
    eventTrack({
      action: TRACKER_ALL_JOBS_NEXT_PAGE_CLICK,
      properties: {
        selectedJobType
      }
    });

    listConductor.dispatch(new ListNextPage());
  };

  const onRefreshClick = () => {
    eventTrack({
      action: TRACKER_ALL_JOBS_REFRESH_LIST_CLICK,
      properties: {
        selectedJobType
      }
    });

    listConductor.dispatch(new RefreshList(true));
  };

  return (
    <div className={styles.jobsContainer}>
      <div className='box'>
        <div className='center-flex-row justify-between p-5'>
          <div className={clsx('btn-group', 'btn-group-toggle', styles['dbt-btn-group'])}>
            <HdButton
              dataId={JobMonitoringDashboardDataIds.batchTab}
              variation='outline'
              className={batchTypeActive && 'active'}
              onClick={() => {
                handleJobTypeChange(JobTypes.BATCH);
              }}
            >
              BATCH
            </HdButton>

            <HdButton
              dataId={JobMonitoringDashboardDataIds.streamingTab}
              variation='outline'
              className={streamingTypeActive && 'active'}
              onClick={() => {
                handleJobTypeChange(JobTypes.STREAMING);
              }}
            >
              STREAMING
            </HdButton>
          </div>

          <div className={styles.filtersContainer}>
            <PaginationControl
              paginationData={listConductorMeta.pagination}
              listState={1}
              nextPage={onNextClick}
              prevPage={onPrevClick}
              dataId={JobMonitoringDashboardDataIds.paginationControl}
            />

            {!error ? (
              <HdIconButton
                onClick={onRefreshClick}
                className='ml-3'
                dataId={JobMonitoringDashboardDataIds.refreshList}
              >
                <HdIcon
                  name='refresh'
                  size={3}
                  className={`refresh-icon ${listConductorRefreshing ? 'refreshing' : ''}`}
                />
              </HdIconButton>
            ) : null}
          </div>
        </div>

        {fetchingList ? (
          <div className='py-9' data-id={JobMonitoringDashboardDataIds.loader}>
            <HdIndeterminateProgressBar>Hevo is loading your Jobs...</HdIndeterminateProgressBar>
          </div>
        ) : (
          <>
            {jobsList?.length ? (
              <JobsList jobs={jobsList} dataId={JobMonitoringDashboardDataIds.jobList} />
            ) : null}

            {!jobsList?.length && !error ? (
              <div data-id={JobMonitoringDashboardDataIds.noItemBox}>
                <NoItemBox className='py-9' iconName='jobs' title='No Jobs Found'>
                  <div className='no-item-box-desc' />
                </NoItemBox>
              </div>
            ) : null}

            {error ? (
              <RetryApiAlert
                dataId={JobMonitoringDashboardDataIds.retryBtn}
                error={error}
                actionHandler={getAllJobs}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
