import React from 'react';
import styles from './styles.module.scss';

export default function CloudwatchShimmer({ dataId }) {
  return (
    <div className='box box--table' data-id={dataId}>
      <div className='box__header'>
        <span className='box__title'>Activity Logs</span>
      </div>

      <div className={`box__body ${styles.shimmerContainer}`}>
        <div className='settings-row'>
          <div className='left'>
            <div
              className='shimmer shimmer-line'
              style={{ width: '131px', height: '12px', marginBottom: '12px' }}
            />

            <div
              className='shimmer shimmer-line'
              style={{ width: '301px', height: '8px', marginBottom: '12px' }}
            />

            <div
              className='shimmer shimmer-line'
              style={{ width: '211px', height: '8px', marginBottom: '12px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
