/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import useDebounce from '../../../../../hooks/useDebounce';
import useDontMountAtFirst from '../../../../../hooks/useDontMountAtFirst';
import HdIcon from '../../../HdIcon';
import { getDoubleDigitTime } from '../../utils';

const className = 'react-calendar__time-view-timer-input';

export function NumberInput({ minValue, maxValue, label = 'hour', onChange, activeValue }) {
  const [value, setValue] = useState(getDoubleDigitTime(activeValue));
  const debouncedValue = useDebounce(value, 500);

  useDontMountAtFirst(() => {
    const number = +debouncedValue;

    let newValue = number;
    if (!debouncedValue && +number === 0) {
      newValue = 0;
    } else if (number > maxValue) {
      newValue = maxValue;
    } else if (number <= minValue) {
      newValue = minValue;
    }

    setValue(getDoubleDigitTime(newValue));
    return onChange(newValue);
  }, [debouncedValue]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const upArrowDisabled = +value + 1 > maxValue;
  const downArrowDisabled = +value - 1 < minValue;

  const onUpClick = () => {
    setValue(getDoubleDigitTime(+value + 1));
  };

  const onDownClick = () => {
    setValue(getDoubleDigitTime(+value - 1));
  };

  return (
    <div className={`${className}`}>
      <label className={`${className}-content`}>
        <input
          maxLength={2}
          type='number'
          className={`${className}-native-element`}
          value={value}
          onBlur={() => setValue(getDoubleDigitTime(+value))}
          onChange={onInputChange}
        />
        <span className='d-none'>{label}</span>
      </label>

      <div className={`${className}-buttons`}>
        <button
          type='button'
          disabled={upArrowDisabled}
          onClick={() => onUpClick()}
          className={`${className}-button`}
        >
          <span className={`${className}-button-content`}>
            <HdIcon size={1} name='solid-dropdown' />
          </span>
        </button>

        <button
          type='button'
          disabled={downArrowDisabled}
          onClick={() => onDownClick()}
          className={`${className}-button`}
        >
          <span className={`${className}-button-content`}>
            <HdIcon size={1} name='solid-dropdown' />
          </span>
        </button>
      </div>
    </div>
  );
}
