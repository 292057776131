// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.card_lhkJv {\n  height: 100px;\n  width: 80px;\n  border-radius: 6px;\n  box-shadow: var(--shadow-default);\n  background-color: var(--surface-bg-color);\n  margin: 0 2px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.date__fF3U {\n  display: flex !important;\n  margin-bottom: 32px;\n}\n\n.zero_NXiVe {\n  font-size: 84px;\n  margin-top: -4px;\n}\n\n.line_UGfTQ {\n  height: 2px;\n  width: inherit;\n  background-color: var(--divider-color);\n  position: absolute;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card_lhkJv",
	"date": "date__fF3U",
	"zero": "zero_NXiVe",
	"line": "line_UGfTQ"
};
export default ___CSS_LOADER_EXPORT___;
