import React, { PropsWithChildren } from 'react';

interface DefaultLabelProps {
  color?: DEFAULT_LABEL_COLORS_UNION;
  className?: string;
}

export const DEFAULT_LABEL_COLORS = [
  'default',
  'primary',
  'success',
  'success-1',
  'error',
  'error-2',
  'purple',
  'purple-2',
  'warning',
  'primary-1'
] as const; // to make it read only

// will be union of all the elements there in the array
export type DEFAULT_LABEL_COLORS_UNION = typeof DEFAULT_LABEL_COLORS[number];

// eslint-disable-next-line react/function-component-definition
const DefaultLabel = ({
  color = 'default',
  className,
  children
}: PropsWithChildren<DefaultLabelProps>) => (
  <span className={`hd-label hd-default-label hd-default-label-${color} ${className}`}>
    {children}
  </span>
);

export default DefaultLabel;
