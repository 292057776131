import { saveAs } from 'file-saver';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { SidelineMessage } from '../../../../app/sideline/models/sideline-message';
import JsonEditor from '../../../components/CodeEditor/JsonEditor';
import { HdButton, HdIcon, HdIconButton, HdModal } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import SidelineAPI from '../SidelineAPI';

export interface ReplaySamplesDialogProps {
  open: boolean;
  onClose: Function;
  pipelineId?: number;
  events?: any[];
  sidelineMessage?: SidelineMessage;
}

export function ReplaySamplesDialog(props: ReplaySamplesDialogProps) {
  const { open, onClose } = props;
  const editorRef = useRef(null);

  return (
    <HdModal
      open={open}
      onClose={() => onClose()}
      placement={'top'}
      styling={{ width: '550px' }}
      onTransitionEnd={() => {
        editorRef.current.refresh();
      }}
    >
      <ReplaySamplesDialogContent {...props} editorRef={editorRef} />
    </HdModal>
  );
}

function ReplaySamplesDialogContent({
  onClose,
  pipelineId,
  sidelineMessage,
  events,
  editorRef
}: ReplaySamplesDialogProps & { editorRef: MutableRefObject<any> }) {
  const [cursor, setCursor] = useState<number>(0);
  const [sampleEvent, setSampleEvent] = useState(null);

  useEffect(() => {
    if (events instanceof Array) {
      setSampleEvent(JSON.stringify(events[cursor], null, '\t'));
    }
  }, [events, cursor]);

  const downloadClick = () => {
    SidelineAPI.downloadSamples(
      pipelineId,
      sidelineMessage.schemaName,
      sidelineMessage.stage,
      sidelineMessage.errorCode
    ).then(res => saveAs(res, 'replay-sample-' + sidelineMessage.schemaName + '.json'));
  };

  const getPrevious = () => {
    setCursor(prev => {
      if (prev <= 0) {
        return prev;
      }
      return prev - 1;
    });
  };

  const getNext = () => {
    setCursor(next => {
      if (next >= events.length - 1) {
        return next;
      }
      return next + 1;
    });
  };

  const dataIdGenerator = getDataIdGenerator('replay-samples-dialog');

  return (
    <>
      <div className='dialog-header text-capitalize border-bottom-0'>
        <div className='dialog-title'>Sample Events</div>

        <div className='center-flex-row'>
          {events && events.length ? (
            <HdButton
              variation='outline'
              className='mr-5'
              onClick={downloadClick}
              dataId={dataIdGenerator('download')}
            >
              Download
            </HdButton>
          ) : null}

          <HdIconButton
            dataId={dataIdGenerator('close')}
            onClick={() => onClose()}
            className='pure-ripple'
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>
        </div>
      </div>

      {sampleEvent ? (
        <>
          <div className='dialog-body p-0'>
            <JsonEditor
              dataId={dataIdGenerator('')}
              code={sampleEvent}
              allowCopy
              showLineNumbers
              getCodeMirror={ed => {
                editorRef.current = ed;
              }}
            />
          </div>

          <div className='dialog-footer justify-around border-top-0'>
            <HdButton
              variation='flat'
              color='secondary'
              icon='left-arrow'
              disabled={cursor <= 0}
              onClick={getPrevious}
              dataId={dataIdGenerator('prev')}
            >
              Previous
            </HdButton>

            <HdButton
              variation='flat'
              color='secondary'
              icon='right-arrow'
              direction='right'
              disabled={cursor >= events.length - 1}
              onClick={getNext}
              dataId={dataIdGenerator('next')}
            >
              Next
            </HdButton>
          </div>
        </>
      ) : (
        <div className='error-box'>Cannot fetch sample data. Please try later.</div>
      )}
    </>
  );
}
