import { QUANTIVE_REGIONS } from '../constants';
import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const QUANTIVE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'accountId',
      fieldId: 'account-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'accountId', label: 'Account ID', required: true },
      hint: 'The account ID obtained from Settings → API Tokens → Account ID in Quantive',
      buildParamsKey: 'account_id',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'region',
      fieldId: 'region',
      widget: FieldTypes.DROPDOWN_FIELD,
      fieldType: 'object',
      widgetOptions: {
        name: 'region',
        label: 'Region',
        options: QUANTIVE_REGIONS,
        displayAccessor: 'name',
        valueAccessor: 'value',
        required: true
      },
      hint: 'The region can be known from Settings → Account name and domain',
      buildParamsKey: 'region',
      getConfigValue: rawValue => QUANTIVE_REGIONS.find(option => option.value === rawValue),
      getRawValue: configValue => configValue.value,
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      ...getAPITokenField({
        hint: 'The API key obtained from Settings → API Tokens → Issue token → Generate API token in Quantive'
      })
    }
  ]
];
