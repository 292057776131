/*
  wrap the component with formik and pass updateValidationSchema to
  get the validation schema of cron forms in case isCustom is true and in submit get data from 
  formik values, use getPolicyRawData to construct the payload object.
*/

import React, { useEffect, useMemo } from 'react';
import { HdButton, HdTooltip } from '../../../components/UIElements';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import { SchedulerType } from '../models';
import {
  ExecutionPolicyContext,
  getDefaultCronTimeList,
  getExecutionPolicyFromRawData,
  getPolicyMessage
} from '../utils';
import useScheduler from '../useScheduler';
import { SchedulerInner } from '..';
import {
  FrequencyChip,
  FrequencyType,
  PolicyTypeEnum
} from '../../../../app/dialog/execution-policy-dialog/models';
import { getDataIdsFromContract } from '../../../utils/generateDataId';
import { validationSchema } from '../form-helper';
import { ContactSalesForStreaming } from '../../pipeline/create/PipelineSettings/PipelineSchedule/ContactSalesForStreaming';
import { STREAMING_FREQUENCY } from '../../pipeline/create/PipelineSettings/PipelineSchedule/constant';
import { STREAMING_OPTION } from '../constants';

interface InlineSchedulerProps {
  title: string;
  subTitle: string;
  entityDetails: any;
  frequencyChips: FrequencyChip[];
  positiveBtnText?: string;
  onNegativeClick?: Function;
  optionsClassName: string;
  defaultFrequency: { frequencyValue: number; frequencyType: FrequencyType };
  className: string;
  showPolicyMessage?: boolean;
  showProgress?: boolean;
  disablePositiveCTA?: boolean;
  disableNegativeCTA?: boolean;
  policyMessagePrefix: string;
  entity: string;
  hideCTA?: boolean;
  children?: React.ReactElement<any, any>;
  positiveBtnTooltipProps?: {
    title: any;
    disabled: boolean;
    placement: any;
  };
  showSchedulerOptions?: boolean;
  formikRef?: any;
  updateValidationSchema?: Function;
  isEdit?: boolean;
  disablePositiveCTACallback?: Function;
}

const DATA_IDS = {
  base: 'inlineScheduler',
  positiveBtn: 'positive',
  negativeBtn: 'negative'
};

export const dataIds = getDataIdsFromContract(DATA_IDS);

export function InlineScheduler({
  title,
  subTitle,
  entityDetails,
  frequencyChips,
  positiveBtnText,
  onNegativeClick,
  optionsClassName,
  defaultFrequency,
  className,
  hideCTA = false,
  showPolicyMessage = true,
  showProgress = false,
  disablePositiveCTA = false,
  disableNegativeCTA = false,
  policyMessagePrefix,
  positiveBtnTooltipProps = {
    title: '',
    disabled: true,
    placement: 'bottom'
  },
  entity,
  formikRef,
  updateValidationSchema,
  children,
  isEdit = true,
  disablePositiveCTACallback
}: InlineSchedulerProps) {
  const {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    isStreaming,
    handleOptionSelection,
    tooltipData,
    setTooltipData
  } = useScheduler({
    policy: getExecutionPolicyFromRawData(
      entityDetails?.execution_policy,
      entityDetails?.frequency_config,
      frequencyChips,
      frequencyChips
    ),
    entity,
    schedulerType: entityDetails?.frequency_config.is_daily_cron_configurable
      ? SchedulerType.MultiCronType
      : SchedulerType.FrequencyType,
    formikRef
  });

  useEffect(() => {
    if (policyObj.type === PolicyTypeEnum.NO_SCHEDULE) {
      setPolicyObj(prev => ({
        ...prev,
        minFrequency: entityDetails?.frequency_config.min_frequency,
        ...defaultFrequency,
        type: PolicyTypeEnum.FREQUENCY,
        suggestedCronTimeList: getDefaultCronTimeList()
      }));
    }
  }, []);

  const policyMessage = useMemo(
    () => getPolicyMessage(policyObj, isStreaming),
    [policyObj, isStreaming]
  );

  useEffect(() => {
    updateValidationSchema(
      validationSchema(
        policyObj.isDailyCronConfigurable
          ? SchedulerType.MultiCronType
          : SchedulerType.FrequencyType,
        isCustom,
        policyObj
      )
    );
  }, [policyObj, isCustom]);

  useEffect(() => {
    if (selectedOption?.value === STREAMING_OPTION.value) {
      setPolicyObjectForStreaming();

      if (disablePositiveCTACallback && !isPipelineInStreamingMode) {
        disablePositiveCTACallback(true);
      }
    } else if (disablePositiveCTACallback && selectedOption?.value !== STREAMING_OPTION.value) {
      disablePositiveCTACallback(false);
    }
  }, [policyObj.frequencyValue, selectedOption?.value]);

  const isPipelineInStreamingMode = !!entityDetails?.config?.streaming_schedule_enabled;

  useEffect(() => {
    if (formikRef?.current && isCustom) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        ...policyObj,
        selectedCustomOption: policyObj.cronType?.value,
        cronTime: policyObj.cronTimeList?.length ? policyObj.cronTimeList[0] : []
      });
    }
  }, [policyObj.cronType?.value, policyObj.cronTimeList?.length, isCustom]);

  useEffect(() => {
    if (formikRef?.current) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        ...policyObj
      });
    }
  }, [policyObj.frequencyValue]);

  const canShowPolicyMessage = () => {
    if (isStreaming && !isPipelineInStreamingMode) {
      return false;
    }
    return policyMessage && showPolicyMessage;
  };

  const onStreamingRequestSuccess = () => {
    if (!isEdit && disablePositiveCTACallback) {
      disablePositiveCTACallback(false);
    }
  };

  const setPolicyObjectForStreaming = () => {
    if (!isEdit) {
      setPolicyObj(prev => ({
        ...prev,
        minFrequency: entityDetails?.frequency_config.min_frequency,
        ...defaultFrequency,
        type: PolicyTypeEnum.FREQUENCY,
        suggestedCronTimeList: getDefaultCronTimeList()
      }));
    } else if (isPipelineInStreamingMode) {
      setPolicyObj(prev => ({
        ...prev,
        minFrequency: entityDetails?.frequency_config.min_frequency,
        ...STREAMING_FREQUENCY,
        type: PolicyTypeEnum.FREQUENCY,
        suggestedCronTimeList: getDefaultCronTimeList()
      }));
    }
  };

  return (
    <div className={className}>
      <div className='flex-col'>
        <div className='text-body-2 text-default'>{title}</div>

        <div className='text-body-1 text-secondary mt-1'>{subTitle}</div>
      </div>

      <SchedulerOptions
        className={optionsClassName}
        entity={entity}
        options={policyObj.displayedFrequencies}
        onScheduleOptionSelected={handleOptionSelection}
        selectedOption={selectedOption}
      />

      <ExecutionPolicyContext.Provider
        value={{
          tooltipData,
          setTooltipData
        }}
      >
        {isCustom ? (
          <SchedulerInner
            schedulerType={
              policyObj.isDailyCronConfigurable
                ? SchedulerType.MultiCronType
                : SchedulerType.FrequencyType
            }
            entity={entity}
            policyObj={policyObj}
            setPolicyObj={setPolicyObj}
            formikProps={formikRef.current}
          />
        ) : null}
      </ExecutionPolicyContext.Provider>

      {canShowPolicyMessage() ? (
        <ExecutionPolicyMessage
          className='mb-3 mt-7'
          policyMessage={policyMessage}
          messagePrefix={policyMessagePrefix}
        />
      ) : null}

      {isStreaming && !isPipelineInStreamingMode ? (
        <ContactSalesForStreaming
          entityDetails={entityDetails}
          policyObj={policyObj}
          isEdit={isEdit}
          onSuccessCallback={onStreamingRequestSuccess}
        />
      ) : null}

      {children}

      {!hideCTA ? (
        <div className='dialog-footer'>
          <HdButton
            dataId={dataIds.negativeBtn}
            variation='outline'
            disabled={disableNegativeCTA}
            onClick={() => onNegativeClick()}
          >
            Cancel
          </HdButton>

          <HdTooltip {...positiveBtnTooltipProps}>
            <HdButton
              dataId={dataIds.positiveBtn}
              disabled={disablePositiveCTA}
              showProgress={showProgress}
              type='submit'
              onClick={() => formikRef.current.submitForm()}
            >
              {positiveBtnText}
            </HdButton>
          </HdTooltip>
        </div>
      ) : null}
    </div>
  );
}
