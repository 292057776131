import { FieldType } from '../schema-mapper/models';

export function getSourceFieldDataTypeDisplay(field) {
  return field.type.type_display + (field.meta_type === FieldType.ARRAY && field.value_type ? ` (${field.value_type.type_display})` : '');
}

export function generateMappingForStruct(nestedFields: any) {
  return nestedFields.map((field) => {
    const fieldMapping = {
      sf: field.source.name,
      sft: field.source.type.type_display,
      ig: field.ignored,
      ty: field.source.meta_type,
      df: field.destination.name,
      dft: field.destination.type.type_display
    };

    if (field.nested) {
      fieldMapping['ns'] = generateMappingForStruct(field.nested);
    }

    return fieldMapping;
  });
}

export function generateSchemaRequestFields(nestedFields: any[], key: string) {
  return nestedFields.map((field) => {
    const fieldNode = field[key];

    const schemaRequestField: any = {
      name: fieldNode.name,
      type: {
        type: fieldNode.type.type_display
          || fieldNode.type.type
      }
    };

    if (schemaRequestField.type.type.toLowerCase() === 'array') {
      schemaRequestField['value_type'] = {
        type: fieldNode.value_type.type_display
      };
    }

    if (field.nested) {
      schemaRequestField['fields'] = generateSchemaRequestFields(field.nested, key);
    }

    return schemaRequestField;
  });
}

export function constructDisplayField(field: any, childAccessor: string) {
  const displayField = {};
  if (childAccessor === 'fields') {
    displayField['name'] = field.name;
    displayField['type'] = field.type_display;
    if (field.value_type_display) {
      displayField['value_type'] = field.value_type_display;
    }
  } else if (childAccessor === 'nested') {
    displayField['name'] = field.source.name;
    displayField['type'] = field.source.type.type_display;
    if (field.source.value_type) {
      displayField['value_type'] = field.source.value_type.type_display;
    }
  }

  if (field[childAccessor]) {
    displayField[childAccessor] = constructNestedDisplayField(field[childAccessor], childAccessor);
  }

  return displayField;
}

export function constructNestedDisplayField(fields: any, childAccessor: string) {
  const displayFields = [];

  for (let index = 0; index < fields.length; index++) {
    const displayField = constructDisplayField(fields[index], childAccessor);
    displayFields.push(displayField);
  }

  return displayFields;
}
