import { ElementRef, Input, Output, EventEmitter, TemplateRef, ViewChild, Directive } from '@angular/core';

export enum PopupThemeEnum {
  LIGHT = 'light',
  DARK = 'dark'
}

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class Popup {
  trigger: any;
  popupThemeEnum = PopupThemeEnum;

  @Input() popupClasses: string;
  @Input() popupTheme: PopupThemeEnum = PopupThemeEnum.LIGHT;

  @Output() close = new EventEmitter<string>();

  @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;
  @ViewChild('container') containerRef: ElementRef;

  constructor() {}

  hide() {
    this.close.emit();
  }
}
