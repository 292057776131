import React, { useCallback } from 'react';
import { Box, InputAdornment, Typography } from '@mui/material';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';
import HdTextField from '../HdTextField';
import HdLink from '../HdLink';
import { HdTooltip } from '../HdTooltip';
import { getMaskedString } from '../../../utils/stringFormatter';

interface HdTextFieldMaskedProps {
  dataId: string;
  label: string;
  value: string;
  helperText?: React.ReactNode;
  required: boolean;
  startAdornment?: React.ReactNode;
  onChangeClick: Function;
}

function HdTextFieldMasked({
  dataId,
  label,
  value,
  helperText = '',
  required,
  startAdornment,
  onChangeClick
}: HdTextFieldMaskedProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'masked'), [dataId]);

  return (
    <Box className={styles.maskWrapper}>
      <HdTooltip placement='bottom-start' title={value} enableOnTextOverflow>
        <Typography
          variant='body2'
          component='span'
          className={styles.maskLabel}
          data-id={dataIdGenerator('label')}
        >
          {getMaskedString(value)}
        </Typography>
      </HdTooltip>

      <HdTextField
        defaultValue='&nbsp;'
        label={label}
        placeholder={label}
        helperText={helperText}
        required={required}
        dataId={dataIdGenerator('')}
        InputProps={{
          inputProps: { readOnly: true },
          startAdornment,
          endAdornment: (
            <InputAdornment position='end' className={styles.maskAction}>
              <HdLink tag='button' onClick={onChangeClick} dataId={dataIdGenerator('clear')}>
                Change
              </HdLink>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default HdTextFieldMasked;
