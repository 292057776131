import React from 'react';
import { Filter } from '../../../../../app/filter/models/filter';
import { COMMON_REFRESH_KEY } from '../../../../../app/shortcuts/constants';
import styles from '../../ActivityLogInline/ActivityLogInlineFilters/styles.module.scss';
import HdIcon from '../../../../components/UIElements/HdIcon';
import { HdIconButton } from '../../../../components/UIElements';
import HdShortcut from '../../../../components/UIElements/HdShortcut';
import { HdTooltip } from '../../../../components/UIElements';
import { FilterMenu } from '../../ActivityLogInline/ActivityLogInlineFilters/FiltersMenu';
import { AppliedFilters } from '../../ActivityLogInline/ActivityLogInlineFilters/AppliedFilters';
import useKeyboardShortcutListener from '../../../../hooks/useKeyboardShortcutListener';

interface ActivityLogFiltersProps {
  filters: Filter<any>[];
  loading: boolean;
  appliedFilters: Array<{ name: string; value: { name: string } }>;
  resetFilter: Function;
  updateFilter: Function;
  onRefreshCallback?: Function;
  onCloseDialog?: Function;
}

export default function ActivityLogFilters({
  filters,
  loading,
  appliedFilters,
  resetFilter,
  updateFilter,
  onRefreshCallback,
  onCloseDialog = () => {}
}: ActivityLogFiltersProps) {
  const showFilters = filters.length;

  useKeyboardShortcutListener(onRefreshCallback, COMMON_REFRESH_KEY, {
    priority: 101
  });

  return (
    <>
      <div className='dialog-header'>
        <div className='dialog-title'>Activity log</div>

        <div className='center-flex-row'>
          <HdTooltip title={<HdShortcut command={COMMON_REFRESH_KEY} />}>
            <HdIconButton
              onClick={() => onRefreshCallback()}
              className='pure-ripple'
              dataId='activity-log-dialog-refresh'
            >
              <HdIcon
                name='refresh'
                size={3}
                className={`refresh-icon ${loading ? 'refreshing' : ''}`}
              />
            </HdIconButton>
          </HdTooltip>

          <HdIconButton
            onClick={() => onCloseDialog()}
            className='ml-4 pure-ripple'
            dataId='activity-log-dialog-close'
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>
        </div>
      </div>

      <div className={`${styles.filtersContainer} ${styles.filtersDialog}`}>
        {showFilters ? (
          <div className={styles.appliedFiltersContainer}>
            <div className={styles.filterWrapper}>
              <div className={styles.appliedFilter}>
                <AppliedFilters appliedFilters={appliedFilters} resetFilter={resetFilter} />
              </div>

              <FilterMenu filters={filters} updateFilter={updateFilter} />
            </div>
          </div>
        ) : (
          <>
            <div className={`shimmer shimmer-line my-2 ${styles.shimmerBig}`} />

            <div className={`shimmer shimmer-line my-2 ${styles.shimmerSmall}`} />
          </>
        )}
      </div>
    </>
  );
}
