export enum ActivationIdentifierType {
  UNIQUE = 'UNIQUE',
  MATCHING = 'MATCHING',
  COMPOSITE = 'COMPOSITE'
}

export interface IdentifierTypeData {
  identifierType: ActivationIdentifierType;
  identifiers: string[];
}

export interface IdentifierData {
  identifierName: string;
  identifierType: ActivationIdentifierType;
}
