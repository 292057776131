import { InclusionItem, InclusionListState } from '../../../app/core/models/inclusion-list-v2';
import { SELECTOR_SHIMMER_DIMENSIONS } from '../../../app/shared/single-level-selector/constants';

export const PAGE_SIZE = 20;
export const DEBOUNCE_INTERVAL = 500;
export const SEARCH_KEY = 'search_folder';
export const SHIMMER_DIMENSIONS = SELECTOR_SHIMMER_DIMENSIONS;
export const ROOT_ENTITY = JSON.stringify({ name: '', group: '' });
export const ROOT_SELECTED_ENTITY = { key: ROOT_ENTITY, selected: true };
export const CACHE_ID = (Math.random() + 1).toString(36).substring(7);

export interface FolderSelectorProps {
  dataId: string;
  typeIdentifier: string;
  integrationId?: number;
  selectedItems?: InclusionListState | null;
  showSearchBar?: boolean;
  requestParam?: { cacheId: string };
  listSelectionCallback?: Function;
  listInteractionCallback?: Function;
  onErrorCallback?: Function;
}

export interface FolderSelectionRow {
  inclusionItem?: InclusionItem;
}

export interface FolderSelectorEntity {
  all_select: boolean;
  entities: { name: string; group: string }[];
}

export interface SelectorItem {
  id: number | string;
  name?: string;
  data?: { [key: string]: any };
}

export interface FolderItem {
  id: string;
  label: string;
  path: string;
  parent: string;
  children: any[];
}

export interface FoldersTreeProps {
  folders: Array<FolderItem>;
  paginationStrategy: {};
  forceChecked: boolean;
  onCheckStateChange: Function;
  getChildren: Function;
  resetNode?: boolean;
  depth?: number;
  dataId?: string;
}
