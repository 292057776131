// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.reviewListContainer__ZNLM {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  margin-top: 32px;\n  overflow: hidden;\n  border-radius: 6px;\n  border: 1px solid var(--divider-color);\n  width: 1200px;\n}\n\n.listItemContainer_oxYyi {\n  overflow-y: auto;\n}\n\n.listItem_YlMLE {\n  padding: 16px 20px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid var(--divider-color);\n}\n\n.pipelineDetailsCell__pVR7 {\n  display: flex;\n  align-items: center;\n  width: 570px;\n}\n\n.syncFrequencyCell_Xj7Pg {\n  display: flex;\n  align-items: center;\n  width: 426px;\n}\n\n.nodeLogoText_RI_EQ {\n  width: 200px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviewListContainer": "reviewListContainer__ZNLM",
	"listItemContainer": "listItemContainer_oxYyi",
	"listItem": "listItem_YlMLE",
	"pipelineDetailsCell": "pipelineDetailsCell__pVR7",
	"syncFrequencyCell": "syncFrequencyCell_Xj7Pg",
	"nodeLogoText": "nodeLogoText_RI_EQ"
};
export default ___CSS_LOADER_EXPORT___;
