import { Formik, Field } from 'formik';
import React, { useState } from 'react';
import { VALID_EMAIL_REGEX } from '../../../../app/core/constants';
import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import { getShareDocLinkMessage } from './utils';
import TeamAPI from '../../../containers/team/TeamAPI';
import useClipboardService from '../../../hooks/services/useClipboardService';
import useToaster from '../../../hooks/useToaster';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { getDataIdsFromContract } from '../../../utils/generateDataId';
import FormError from '../../FormError';
import HdFormikTextField from '../../FormikElements/HdFormikTextField';
import RoundedIcon from '../../RoundedIcon';
import { HdButton, HdIcon, HdIconButton, HdModal, HdTooltip } from '../../UIElements';
import HdDocLink from '../../UIElements/HdDocLink';
import HdFormControl from '../../UIElements/HdFormControl';
import HdLink from '../../UIElements/HdLink';
import * as Yup from 'yup';
import styles from './styles.module.scss';

export interface PipelinePermissionFailureDialogProps {
  open: boolean;
  docLink: string;
  entityDisplayName: string;
  entity: HevoEntity;
  entityName: string;
  onClose: Function;
}

export function PipelinePermissionFailureDialog(props: PipelinePermissionFailureDialogProps) {
  const { open, onClose } = props;

  return (
    <HdModal open={open} onClose={() => onClose()} placement='center' styling={{ width: '550px' }}>
      <PipelinePermissionFailureDialogInner {...props} />
    </HdModal>
  );
}

const validationSchema = Yup.object({
  email: Yup.string().required('Specify atleast one email id to share').test({
    name: 'emailIdsValidation',
    exclusive: true,
    message: 'Please enter valid email addresses',
    test: (value: string) => value && value.split(',').every((e) => VALID_EMAIL_REGEX.test(e.trim()))
  })
});

export function PipelinePermissionFailureDialogInner({
  docLink,
  entityDisplayName,
  entity,
  entityName,
  onClose
}: PipelinePermissionFailureDialogProps) {
  const [sharing, setSharing] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  const clipboardService = useClipboardService();
  const toaster = useToaster();

  const dataIds = PIPELINE_PERMISSION_FAILURE_DIALOG_DATA_IDS;

  const share = (email) => {
    setSubmissionError(null);
    setSharing(true);

    TeamAPI.shareEmailForBulkActionPermissionError({
      toEmails: email?.split(',').map((e) => e.trim()),
      docLink: docLink,
      pipelineUrl: window.location.href,
      sourceType: entityName,
    }).then(() => {
      setSharing(false);

      toaster.pop(
        'success',
        undefined,
        'Documentation shared over email successfully.'
      );

      onClose();
    }, (err) => {
      setSubmissionError(getErrorMessageFromObj(err));
      setSharing(false);
    })
  };

  const copyClick = () => {
    clipboardService.copy(getShareDocLinkMessage(entityDisplayName, docLink, document), 'Text copied to clipboard');
  };

  return (
    <>
      <div className='dialog-header dialog-header--absolute'>
        <HdIconButton dataId={dataIds.close} onClick={() => onClose()}>
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className='dialog-body flex-col p-6'>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={() => {}}
        >
          {formikProps => (
            <form>
              <div className='center-flex-row justify-center mb-5'>
                <RoundedIcon containerBg='warning' iconName='warning' />
              </div>

              <div className='text-subheading-3 text-center'>
                It looks like you don’t have permission
              </div>

              <div className='mt-2 text-center text-secondary'>
                Permissions for ingesting data have not been granted on some objects. Invite a team
                member to Hevo to help, or share the details with them via email or chat.
              </div>

              <HdFormControl className='mt-7'>
                <Field
                  label='Email Address'
                  name='email'
                  type='email'
                  dataId={dataIds.email}
                  component={HdFormikTextField}
                  helperText={
                    "Enter your team member's email IDs. Use a comma to separate multiple IDs."
                  }
                  required
                />
              </HdFormControl>

              <div className='border border-radius-md flex-col text-body-1 position-relative p-4'>
                <p className='mb-3'>Hey there,</p>

                <p className='mb-2'>
                  I am trying to configure a Pipeline in Hevo with <b>{entityDisplayName}</b> as a
                  Source, and need your help to grant the requisite permissions for a few objects.
                  <br />
                  The related documentation for this is available at:{' '}
                  <HdDocLink
                    label={docLink}
                    docLink={docLink}
                    icon={null}
                    dataId={dataIds.docLink}
                  />
                </p>

                <p>
                  Please click the link below to configure the Pipeline:
                  <HdLink
                    dataId={dataIds.configurePipelineLink}
                    target='_blank'
                    href={document.location.href}
                  >
                    {document.location.href}
                  </HdLink>
                </p>

                <div className={styles.copyBtn}>
                  <HdTooltip title='Share via chat'>
                    <HdIconButton
                      onClick={() => copyClick()}
                      dataId={dataIds.copy}
                    >
                      <HdIcon name='copy' />
                    </HdIconButton>
                  </HdTooltip>
                </div>
              </div>

              <div className='text-caption-1 text-secondary mx-2'>
                Copy the message to share it over chat without adding the team member(s) to your
                Hevo workspace.
              </div>

              {submissionError ? (
                <FormError
                  dataId={dataIds.formError}
                  className='mt-5'
                >
                  {submissionError}
                </FormError>
              ) : null}

              <div className='center-flex-row justify-center'>
                <HdButton
                  className='mt-7'
                  icon='share'
                  dataId={dataIds.share}
                  disabled={sharing || !formikProps.isValid}
                  showProgress={sharing}
                  onClick={() => {
                    share(formikProps.values['email'])
                  }}
                >
                  Share
                </HdButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export const PIPELINE_PERMISSION_FAILURE_DIALOG_DATA_IDS = getDataIdsFromContract({
  base: 'pipeline-permission-failure-dialog',
  email: 'email',
  close: 'close',
  docLink: 'doc-link',
  configurePipelineLink: 'configure-pipeline-link',
  share: 'share',
  copy: 'copy',
  formError: 'form-error'
});
