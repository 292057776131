import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { UNSUBSCRIPTION_TYPE } from '../../../../app/core/constants';
import { EntityUIState } from '../../../../app/core/models/entitiy-ui-state';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { HdButton } from '../../../components/UIElements';
import HdIcon from '../../../components/UIElements/HdIcon';
import useEntityLogo from '../../../hooks/useEntityLogo';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import NotificationPreferencesAPI from '../../notification-settings/NotificationPreferences/NotificationPreferencesAPI';
import styles from './styles.module.scss';
import { UnsubscribeNotificationShimmer } from './UnsubscribeNotificationShimmer';

export function UnsubscribeNotificationsPage() {
  return (
    <Routes>
      <Route
        key='unsubscribe-notification-page'
        path='/notification-preference/unsubscribe/:unsubscriptionType/:identifier'
        element={<UnsubscribeNotificationsPageInner />}
      />
    </Routes>
  );
}

export function UnsubscribeNotificationsPageInner() {
  const [unsubscribeState, setUnsubscribeState] = useState<{
    state: EntityUIState;
    promiseError?: any;
    errorMessage?: string;
    successMessage?: string;
  }>({
    state: EntityUIState.LOADING
  });

  const { identifier, unsubscriptionType } = useParams<{
    identifier: string;
    unsubscriptionType: UNSUBSCRIPTION_TYPE;
  }>();

  const brandLogo = useEntityLogo(
    'https://res.cloudinary.com/hevo/image/upload/v1684393936/hevo-logos-v2/logo-horizontal/black/logo_cqvfsz.svg',
    'https://res.cloudinary.com/hevo/image/upload/v1684394045/hevo-logos-v2/logo-horizontal/white/logo_zrn99q.svg'
  );

  const toDashboard = () => {
    window.location.assign(window.location.origin + '/pipeline');
  };

  const toAlertPreferences = () => {
    window.location.assign(window.location.origin + '/alerts/preferences');
  };

  const unsubscribe = () => {
    setUnsubscribeState({
      state: EntityUIState.LOADING
    });

    NotificationPreferencesAPI.unsubscribeEmailNotifications(identifier, unsubscriptionType).then(
      res => {
        setUnsubscribeState({
          state: EntityUIState.IDLE,
          successMessage: res.success_message
        });
      },
      err => {
        if (err.status < 500) {
          setUnsubscribeState({
            state: EntityUIState.IDLE,
            errorMessage: getErrorMessageFromObj(err)
          });
        } else {
          setUnsubscribeState({
            state: EntityUIState.ERRORED,
            promiseError: err
          });
        }
      }
    );
  };

  useEffect(() => {
    unsubscribe();
  }, []);

  const dataIdGenerator = getDataIdGenerator('unsubscribe-notification');

  return (
    <div className='w-100 h-100 center-flex-col justify-center bg-default'>
      <img className={styles.brandLogo} src={brandLogo} />

      {unsubscribeState.state === EntityUIState.IDLE ? (
        <div
          className={clsx(
            'box no-item-box m-0',
            styles.noItemBox,
            unsubscribeState.errorMessage ? 'errored' : 'success'
          )}
        >
          <div className='no-item-box-icon-container'>
            <HdIcon
              className='no-item-box-icon'
              name={unsubscribeState.errorMessage ? 'email-warning' : 'email'}
            />
          </div>

          <div className='no-item-box-title'>
            {unsubscribeState.errorMessage ? 'Already Unsubscribed!' : 'Unsubscribed Successfully!'}{' '}
            😞
          </div>

          <div className='no-item-box-desc'>
            {unsubscribeState.successMessage || unsubscribeState.errorMessage}
          </div>

          <div>
            <HdButton
              dataId={dataIdGenerator('goto-dashboard')}
              variation='outline'
              className='no-item-box-action'
              onClick={toDashboard}
            >
              Go To Dashboard
            </HdButton>

            <HdButton
              dataId={dataIdGenerator('goto-alert-preferences')}
              className='no-item-box-action ml-4'
              onClick={toAlertPreferences}
            >
              Go To Alert Preferences
            </HdButton>
          </div>
        </div>
      ) : null}

      {unsubscribeState.state === EntityUIState.LOADING ? <UnsubscribeNotificationShimmer /> : null}

      {unsubscribeState.state === EntityUIState.ERRORED ? (
        <RetryApiAlert
          classes='p-0'
          dataId={dataIdGenerator('')}
          error={unsubscribeState.promiseError}
          actionHandler={unsubscribe}
        />
      ) : null}
    </div>
  );
}
