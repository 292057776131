import { useState } from 'react';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';

export function useConfirmDialog({
  onPositiveClick,
  onPositiveClickCallback,
  onPositiveClickSuccess,
  onClose
}) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const onErrorMessage = getErrorMessageFromObj(error);

  const onPositiveButtonClick = async () => {
    if (onPositiveClick) {
      onPositiveClick();
    }

    setProcessing(true);

    try {
      let response = true;

      if (onPositiveClickCallback) {
        response = await onPositiveClickCallback();
      }

      setProcessing(false);

      if (typeof onPositiveClickSuccess === 'function') {
        onPositiveClickSuccess(response);
      } else {
        onClose(response);
      }
    } catch (error) {
      setProcessing(false);
      setError(error);
    }
  };

  return {
    processing,
    error,
    onErrorMessage,
    onPositiveButtonClick,
    setError
  };
}
