import { AnimationEvent } from '@angular/animations';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DialogCommunicationService {
  private _onEscapeClickFunction$ = new BehaviorSubject<Function>(null);
  private _onOutsideClickFunction$ = new BehaviorSubject<Function>(null);

  private _onPositiveClick$ = new Subject<any>();
  private _onNegativeClick$ = new Subject<any>();

  private _hideDialog$ = new Subject<any>();
  private _popupAnimationDone$ = new Subject<AnimationEvent>();

  constructor() {
  }

  onEscapeClickFunction$ = this._onEscapeClickFunction$.asObservable();
  onOutsideClickFunction$ = this._onOutsideClickFunction$.asObservable();

  onPositiveClick$ = this._onPositiveClick$.asObservable();
  onNegativeClick$ = this._onNegativeClick$.asObservable();

  hideDialog$ = this._hideDialog$.asObservable();
  popupAnimationDone$ = this._popupAnimationDone$.asObservable();

  updateEscapeClickFunction(fn) {
    this._onEscapeClickFunction$.next(fn);
  }

  updateOutsideClickFunction(fn) {
    this._onOutsideClickFunction$.next(fn);
  }

  negativeClick(data?: any) {
    this._onNegativeClick$.next(data);
  }

  positiveClick(data?: any) {
    this._onPositiveClick$.next(data);
  }

  hideDialog(data?: any) {
    this._hideDialog$.next(data);
  }

  popupAnimationDone(e?: AnimationEvent) {
    this._popupAnimationDone$.next(e);
  }
}
