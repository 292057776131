/* eslint-disable no-shadow */
export enum KafkaTopicType {
  ALL_TOPICS = 'select_all',
  SPECIFIC_TOPICS = 'select_specific',
  REGEX_PATTERN = 'regex_pattern'
}

export enum KafkaSBSScrollFormField {
  APACHE_SERVER = 'APACHE_SERVER',
  CONFLUENT_SERVER = 'CONFLUENT_SERVER',
  CONFLUENT_API_KEY = 'API_KEY',
  CONFLUENT_API_SECRET = 'API_SECRET',
}

export type KafkaSBSScrollParams = {
  [key in KafkaSBSScrollFormField]: { url: string; label: string; };
};

export enum KafkaVariations {
  CONFLUENT_CLOUD = 'CONFLUENT_CLOUD'
}
