// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.strengthBar_aBTI4 {\n  width: 42px;\n  height: 3px;\n  border-radius: 2px;\n  background-color: var(--graph-break-divider);\n}\n\n.unmatchRuleIcon_cRmsZ {\n  border: 1px solid rgba(255, 255, 255, 0.2);\n  border-radius: 50%;\n  border-style: dashed;\n  margin-right: 8px;\n  color: rgba(255, 255, 255, 0.2);\n  padding: 1px;\n  margin-top: 4px;\n}\n\n.matchRuleIcon__mvHV {\n  border: 1px solid var(--success-color);\n  border-radius: 50%;\n  margin-right: 8px;\n  color: var(--success-color);\n  padding: 1px;\n  margin-top: 4px;\n}\n\n.strongPasswordTooltip_XDmHr {\n  margin-top: -10px !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strengthBar": "strengthBar_aBTI4",
	"unmatchRuleIcon": "unmatchRuleIcon_cRmsZ",
	"matchRuleIcon": "matchRuleIcon__mvHV",
	"strongPasswordTooltip": "strongPasswordTooltip_XDmHr"
};
export default ___CSS_LOADER_EXPORT___;
