import { Component, ElementRef, OnInit } from '@angular/core';
import { DownloadPage } from '../../../react/containers/dashboard/DownloadPage';
import { BridgeService } from '../../react-bridge/services/bridge.service';

@Component({
  selector: 'download-page-react',
  template: '',
  providers: [ BridgeService ]
})
export class DownloadPageReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      DownloadPage,
      this._el.nativeElement,
      {}
    );
  }
}
