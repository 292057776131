import {
  lowercaseValidator,
  numericValidator,
  passwordLengthValidator,
  specialCharValidator,
  uppercaseValidator
} from './ruleValidators';

export const PASSWORD_RULES_SET = [
  {
    rule_name: 'password_length',
    rule_title: 'At least 8 characters',
    ruleValidator: passwordLengthValidator
  },
  {
    rule_name: 'uppercase',
    rule_title: 'A uppercase letter',
    ruleValidator: uppercaseValidator
  },
  {
    rule_name: 'lowercase',
    rule_title: 'A lowercase letter',
    ruleValidator: lowercaseValidator
  },
  {
    rule_name: 'number',
    rule_title: 'A number',
    ruleValidator: numericValidator
  },
  {
    rule_name: 'special_char',
    rule_title: 'A special character (@#$%^)',
    ruleValidator: specialCharValidator
  }
];

export const STRENGTH_TITLE_MAP = {
  0: {
    title: 'Password Strength',
    titleClass: 'text-secondary',
    passwordStrengthColor: null
  },
  1: {
    title: 'Weak Password',
    titleClass: 'text-warning',
    passwordStrengthColor: 'bg-warning'
  },
  2: {
    title: 'Weak Password',
    titleClass: 'text-warning',
    passwordStrengthColor: 'bg-warning'
  },
  3: {
    title: 'Average Password',
    titleClass: 'text-warning',
    passwordStrengthColor: 'bg-warning'
  },
  4: {
    title: 'Strong Password',
    titleClass: 'text-success',
    passwordStrengthColor: 'bg-success'
  }
};
