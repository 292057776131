import { Component } from '@angular/core';

@Component({
  selector: 'playground',

  templateUrl: './playground.component.html',
  styleUrls: [ './playground.component.scss' ]
})
export class PlaygroundComponent {
  constructor(
  ) {
  }
}
