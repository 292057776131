import { GenericTargetObjectSettings } from '../models/target-object-settings';


export class KlaviyoTargetObjectSettings extends GenericTargetObjectSettings {
  eventName?: string;
  listId?: string;

  initialiseConfig() {
    super.initialiseConfig();
    this.eventName = null;
    this.listId = null;
  }

  setConfigFromRawData(rawData: any) {
    super.setConfigFromRawData(rawData);

    this.eventName = rawData.event_name || null;
    this.listId = rawData.klaviyo_list_id || null;
  }

  convertConfigToRawData(): any {
    const targetSyncConfig = super.convertConfigToRawData();

    if (this.eventName) {
      targetSyncConfig['event_name'] = this.eventName;
    }

    if (this.listId) {
      targetSyncConfig['klaviyo_list_id'] = this.listId;
    }

    return targetSyncConfig;
  }
}
