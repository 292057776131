// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.sqlViewer_KGfUM {\n  background-color: var(--surface-bg-color);\n  border-radius: 6px;\n  border: solid 1px var(--divider-color);\n  height: 200px;\n}\n\n.sqlViewerCodeEditorWithoutLineNumber_KBwiG {\n  padding: 12px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sqlViewer": "sqlViewer_KGfUM",
	"sqlViewerCodeEditorWithoutLineNumber": "sqlViewerCodeEditorWithoutLineNumber_KBwiG"
};
export default ___CSS_LOADER_EXPORT___;
