import { NgZone } from '@angular/core';
import React, { useEffect } from 'react';
import useService from '../../hooks/useService';
import { getDataIdGenerator } from '../../utils/generateDataId';
import RetryApiAlert from '../RetryApiAlert';
import { GenericPageShimmer } from '../Shimmer/GenericPageShimmer';
import { useResolvedComponent } from './useResolvedComponent';

export function HdResolvedComponent({
  Component,
  canActivate,
  resolve
}: {
  Component: React.FC<any>;
  canActivate?: Array<() => Promise<boolean> | boolean>;
  resolve?: { [key: string]: Promise<any> | any };
}) {
  const ngZone = useService(NgZone);

  const { resolveComponent, resolvedData, resolvingRoute, canActivateRoute, routeActivationError } =
    useResolvedComponent({
      canActivate,
      resolve
    });

  useEffect(() => {
    ngZone.run(() => {
      resolveComponent();
    });
  }, []);

  const dataIdGenerator = getDataIdGenerator('resolved-component');

  return (
    <>
      {resolvingRoute ? <GenericPageShimmer /> : null}

      {canActivateRoute ? <Component {...resolvedData} /> : null}

      {routeActivationError ? (
        <RetryApiAlert
          error={routeActivationError}
          actionHandler={() => resolveComponent()}
          dataId={dataIdGenerator('')}
        />
      ) : null}
    </>
  );
}
