import { Injectable } from '@angular/core';
import { DestinationTypeConfigHelper } from '../destination-settings-form/destination-type-config-helper';


@Injectable()
export class ManagedBigqueryConfigHelper implements DestinationTypeConfigHelper {
  getDefaultConfig() {
  }

  getConfigFromRawData() {
  }

  buildConnectionParams(data: any) {
    let params: any = {
      project_id: data.project_id,
      dataset_name: data.dataset_name,
      sanitize_name: true,
      service_account_id: data.service_account_id,
      service_account_key: data.service_account_key,
      bucket: data.bucket,
      preferred_location: ''
    };

    if ('preferredLocation' in data) {
      params = {
        project_id: data.project_id,
        dataset_name: data.dataset_name,
        bucket: data.bucket,
        preferred_location: data.preferredLocation
      };
    }

    return params;
  }
}
