import { Injectable } from '@angular/core';
import { RbacPermissions } from '../models/user';
import { TeamSettingsService } from './team-settings.service';
import { UserService } from './user.service';

@Injectable()
export class RbacService {
  constructor(
    private _teamSettingsService: TeamSettingsService,
    private _userService: UserService,
  ) {
  }

  canTakeAction(action?: RbacPermissions) {
    if (!action || !this._teamSettingsService.isRBACEnabled()) {
      return true;
    }

    const permissionsMap = this._userService.getPermissions();
    return !!permissionsMap.get(action);
  }


  isOwnerOrHasPermission(permission: RbacPermissions) {
    return this.isRbacEnabled()
      ? this.canTakeAction(permission)
      : this._userService.getUser().isOwner
  }


  isRbacEnabled() {
    return this._teamSettingsService.isRBACEnabled();
  }
}
