// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.jobModeBadgeContainer_Bbdp8 {\n  display: flex;\n  background-color: var(--ripple-bg-color);\n  padding: 4px 6px;\n  border-radius: 20px;\n}\n.jobModeBadgeContainer_Bbdp8 .jobModeBadgeText__L9BN {\n  padding-left: 6px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobModeBadgeContainer": "jobModeBadgeContainer_Bbdp8",
	"jobModeBadgeText": "jobModeBadgeText__L9BN"
};
export default ___CSS_LOADER_EXPORT___;
