import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class StripeConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      apiKey: rawData.api_key,
      webhookIncrementalLoad: !!rawData.use_webhook_for_incremental_sync,
      useERDV2: rawData.use_erd_v2 || false
    };
  }

  getDefaultConfig() {}

  buildConnectionParams(data: any) {
    return {
      api_key: data.apiKey,
      use_webhook_for_incremental_sync: data.webhookIncrementalLoad,
      source_version: data?.sourceVersion ?? null,
      use_erd_v2: data.useERDV2
    };
  }
}
