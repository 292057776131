import { BRAZE_REGIONS, DEFAULT_BRAZE_REGION } from './constants';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class BrazeConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      apiKey: rawData.api_key,
      region: BRAZE_REGIONS.find(region => region.id === rawData.region) || DEFAULT_BRAZE_REGION
    };
  }

  getDefaultConfig() {
    return {
      region: DEFAULT_BRAZE_REGION
    };
  }

  buildConnectionParams(data: any) {
    return {
      api_key: data.apiKey,
      region: data.region?.value,
    };
  }
}
