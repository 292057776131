import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnvConfig } from '../../../react/containers/core/env-config';
import { AppConfig } from '../app.config';
import { AnsibleBoolean } from '../models/ansible-config';
import { NetworkRequestOptions } from '../models/request';
import { AuthService } from './auth.service';
import { RxRequestService } from './rx-request.service';


export let _teamSettingsService;

declare const __RBAC_ENABLED__: string;
declare const __DEPLOYMENT_ENV__: string;
declare const __H2O_ENABLED__: AnsibleBoolean;

@Injectable({ providedIn: 'root' })
export class TeamSettingsService {
  private _teamSettingsUrl = this._appConfig.getTeamSettingsURL();
  private _teamSettingsSubject$ = new BehaviorSubject<any>(null);

  constructor(
    private _appConfig: AppConfig,
    private _authService: AuthService,
    private _rxRequestService: RxRequestService
  ) {
    _teamSettingsService = this;

    this._authService.logoutSubject.pipe(
      tap(() => {
        this._teamSettingsSubject$.next(null);
      })
    ).subscribe();
  }

  getTeamSettingsValue() {
    return this._teamSettingsSubject$.getValue();
  }

  getTeamSettings(showLoading = true, showErrorMsg = true, handleUnauthorisedResponse = true) {
    const options = {
      uiOptions: {
        handleUnauthorisedResponse: handleUnauthorisedResponse ,
        showLoading,
        showErrorMsg
      }
    };

    return this._rxRequestService.get(this._teamSettingsUrl, options).pipe(
      tap((res) => {
        this._teamSettingsSubject$.next(res.data);
      })
    );
  }

  applyTeamSettings(params: any, successMsg?: string): Observable<void> {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false,
        successMsg
      }
    };

    return this._rxRequestService.put(this._teamSettingsUrl, options, params);
  }

  isDbtEnabled() {
    return this.getTeamSettingsValue()?.['dbt_enabled_config'];
  }

  isRBACEnabled() {
    return __RBAC_ENABLED__ === AnsibleBoolean.TRUE && this.getTeamSettingsValue()?.['rbac_enabled'];
  }

  isTransformValidationEnabled() {
    return this.getTeamSettingsValue()?.['py_transform_validation_enabled'];
  }

  isActivationEnabled() {
    return this.getTeamSettingsValue()?.['activate_enabled'];
  }

  isFBAdsSearchEnabled() {
    return this.getTeamSettingsValue()?.['fb_search_mode'];
  }

  isMongoDbDisplayParseAsStringOptionEnabled() {
    return this.getTeamSettingsValue()?.['mongo_display_parse_as_string_option'];
  }

  isWebhookEventsEnabled() {
    return this.getTeamSettingsValue()?.['enable_hevo_webhook_events'];
  }

  isWorkflowsEnabled() {
    return this.getTeamSettingsValue()?.['is_workflow_enabled'];
  }

  isDraftPipelineEnabled() {
    return this.getTeamSettingsValue()?.['draft_pipeline_enabled'];
  }

  getVPCPeeringConfig() {
    const hostData = this.getTeamSettingsValue()?.['vpc_peering_host'];

    let host = null;

    if (hostData) {
      try {
        const hostRegionMapping = JSON.parse(hostData);
        host = hostRegionMapping[__DEPLOYMENT_ENV__];
      } catch (e) {
        host = hostData;
      }
    }

    return {
      enabled: !!host,
      host
    };
  }

  isHistoricalTaskSeparationForTableModeEnabled() {
    const teamSettings = this.getTeamSettingsValue();

    return teamSettings?.["team_version"] >= 10
      ? true
      : teamSettings?.["historical_task_separation_for_table_mode"] || false;
  }

  isServerlessAllowedForTeam() {
    return !!this.getTeamSettingsValue()?.['is_redshift_serverless_enabled'];
  }

  isH2OEnabled() {
    const teamSettings = this.getTeamSettingsValue();
    return typeof __H2O_ENABLED__ !== 'undefined' && __H2O_ENABLED__ === 'True' && teamSettings?.['enable_hevo_2'] === true;
  }

  isH20BillingEnabled() {
    const teamSettings = this.getTeamSettingsValue();
    return this.isH2OEnabled() && EnvConfig.isH20FeatureEnabled('BILLING') && teamSettings?.['enable_hevo_2_billing'];
  }
}
