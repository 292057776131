import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AuthorizationAccount } from '../../../app/nodes/models/models';
import { REQUIRED_SERVICE_ACC_KEY_FIELDS } from '../../../app/nodes/select-auth-type/constants';
import { checkAllRequiredFieldsPresent } from '../../../app/nodes/select-auth-type/utils';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { HdButton, HdDocLink, HdFileInput, HdLink } from '../UIElements';
import HdFormControl from '../UIElements/HdFormControl';
import HdModal from '../UIElements/HdModal';
import styles from './styles.module.scss';

interface EditServiceAccountDialogProps {
  open: boolean;
  closeModelHandler: Function;
  updateFile: Function;
  oldServiceAccount: AuthorizationAccount;
}

export const dataIdGenerator = getDataIdGenerator('edit-service-account');

export default function EditServiceAccountDialog({
  open,
  closeModelHandler,
  updateFile = () => {},
  oldServiceAccount
}: EditServiceAccountDialogProps) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUpdateClick = () => {
    updateFile(selectedFile);
  };

  const onFileChange = file => {
    setErrorMessage('');
    setSelectedFile(file);

    if (!file) {
      return;
    }

    let parsedServiceAccount;
    try {
      parsedServiceAccount = JSON.parse(file.content);
    } catch (e) {
      setErrorMessage('Invalid File Selected');
      return;
    }

    if (
      checkAllRequiredFieldsPresent(parsedServiceAccount, REQUIRED_SERVICE_ACC_KEY_FIELDS) &&
      parsedServiceAccount?.client_email === oldServiceAccount.getDisplayName()
    ) {
      return;
    }

    setErrorMessage('Invalid File Selected');
  };

  useEffect(() => {
    setSelectedFile(null);
    setErrorMessage(null);
  }, [open]);

  const getDisplayMessage = files => {
    let displayText = '';
    if (files.length === 0) {
      return displayText;
    }

    displayText += files[0];
    if (errorMessage) {
      displayText = `${displayText} ( ${errorMessage} )`;
    }

    return displayText;
  };

  return (
    <HdModal disableRestoreFocus open={open} onClose={() => closeModelHandler()}>
      <div
        className={`dialog-content ${styles.dialogContainer}`}
        data-id={dataIdGenerator('dialog-content')}
      >
        <div className={`${styles.dialogHeader}`}>
          <div className='text-bold text-subheading-3'>Edit Service Account Configuration</div>

          <HdLink
            onClick={() => closeModelHandler()}
            className={styles.closeBtn}
            iconClasses={styles.closeBtnSpan}
            icon='close'
            size='md'
            dataId={dataIdGenerator('close')}
          />
        </div>

        <HdFormControl className='px-6'>
          <HdFileInput
            dataId={dataIdGenerator('json')}
            showError={!!errorMessage}
            useErrorColorOnError
            label='Service Account'
            accept='application/json'
            id='auth-edit-file'
            helperText={
              !!errorMessage && (
                <Typography variant='caption' className={styles.deleteIconColor}>
                  The Service Account Configuration file you have uploaded is not valid.{' '}
                  <HdDocLink
                    label='Learn how'
                    size='sm'
                    docLink='/getting-started/connection-options/google-account-authentication/#creating-a-google-service-account'
                    dataId={dataIdGenerator('')}
                  />{' '}
                  to create and download a Service Account file.
                </Typography>
              )
            }
            displayRenderFunction={getDisplayMessage}
            onChangeEventHandler={onFileChange}
          />
        </HdFormControl>

        <div className={`text-medium ${styles.bottomContainer} p-5`}>
          <HdButton
            variation='outline'
            className='mr-3'
            onClick={() => closeModelHandler()}
            dataId={dataIdGenerator('cancel')}
          >
            {' '}
            Cancel{' '}
          </HdButton>

          <HdButton
            disabled={!!errorMessage}
            onClick={() => handleUpdateClick()}
            dataId={dataIdGenerator('confirm')}
          >
            {' '}
            Update{' '}
          </HdButton>
        </div>
      </div>
    </HdModal>
  );
}
