// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.migratePipelinesFiltersPopper__57vo {\n  max-width: 1000px !important;\n}\n.migratePipelinesFiltersPopper__57vo .filtersHeader_Adt9b {\n  border-bottom: 1px solid var(--default-border-color);\n  padding: 20px;\n  display: flex;\n  justify-content: space-between;\n  justify-content: space-between;\n}\n.migratePipelinesFiltersPopper__57vo .filtersContainer_J90tX {\n  display: flex;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"migratePipelinesFiltersPopper": "migratePipelinesFiltersPopper__57vo",
	"filtersHeader": "filtersHeader_Adt9b",
	"filtersContainer": "filtersContainer_J90tX"
};
export default ___CSS_LOADER_EXPORT___;
