import React from 'react';
import clsx from 'clsx';
import { BadgeLabel } from './BadgeLabel';

interface OverlayBadgeProps {
  color?: string;
  content?: string | number;
  variant?: 'content' | 'dot';
  className?: string;
  direction?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  children: React.ReactNode;
  badgeLabelClassName?: string;
}

export default function OverlayBadgeLabel({
  direction,
  className,
  variant,
  color,
  content,
  badgeLabelClassName,
  children
}: OverlayBadgeProps) {
  let classNames = 'd-inline-block hd-label-overlay-container hd-label-overlay-label-overlap';
  classNames += ` hd-label-overlay-container-${direction.vertical}-${direction.horizontal}`;
  classNames += ` ${className}`;

  return (
    <div className={classNames} data-id='hd-label-overlay-container'>
      {children}
      {content ? (
        <BadgeLabel
          className={clsx('hd-label-overlay-label', badgeLabelClassName)}
          variant={variant}
          color={color}
        >
          {content}
        </BadgeLabel>
      ) : null}
    </div>
  );
}

OverlayBadgeLabel.defaultProps = {
  color: 'default',
  variant: 'content',
  direction: {
    vertical: 'top',
    horizontal: 'right'
  },
  className: ''
};
