import { TargetObjectMeta } from '../../../react/containers/activate-target/models/target-object-meta';
import { ActivationSyncBehaviour, ActivationSyncBehaviourType } from './sync-behaviour';


export abstract class TargetObjectSettings {
  syncBehaviour: ActivationSyncBehaviourType;

  abstract initialiseConfig(): void;

  abstract setConfigFromRawData(rawData: any): void;

  abstract convertConfigToRawData(): any;
}

export type TargetObjectSettingsConstructor = new (...args: any[]) => TargetObjectSettings;

export class GenericTargetObjectSettings extends TargetObjectSettings {
  targetObject: TargetObjectMeta;
  uniqueIdentifierKeys?: string[];

  private static _extractUniqueIdentifierKeys(syncBehaviour: ActivationSyncBehaviourType, rawData: any): string[] {
    if (ActivationSyncBehaviour.DELETE === syncBehaviour) {
      return rawData.delete_keys?.length ? rawData.delete_keys : [];
    }

    if (ActivationSyncBehaviour.ARCHIVE === syncBehaviour) {
      return rawData.archive_keys?.length ? rawData.archive_keys : [];
    }

    return rawData.primary_keys?.length ? rawData.primary_keys : [];
  }

  initialiseConfig() {
    this.targetObject = null;
    this.syncBehaviour = ActivationSyncBehaviour.UPSERT;
    this.uniqueIdentifierKeys = [];
  }

  setConfigFromRawData(rawData: any) {
    if (!rawData) {
      return;
    }

    const syncBehaviour = ActivationSyncBehaviour.toArray().find(
      (syncBehaviourType) => syncBehaviourType.value === rawData.sync_mode
    );

    this.targetObject = {
      apiName: rawData.dest_object,
      displayName: rawData.display_object_name || rawData.dest_object
    };
    this.syncBehaviour = syncBehaviour;
    this.uniqueIdentifierKeys = GenericTargetObjectSettings._extractUniqueIdentifierKeys(syncBehaviour, rawData);
  }

  convertConfigToRawData(): any {
    const targetSyncConfig = {
      dest_object: this.targetObject.apiName,
      display_object_name: this.targetObject.displayName,
      sync_mode: this.syncBehaviour?.value
    };
    const uniqueIdentifierKeys = this.uniqueIdentifierKeys;

    if (this.syncBehaviour === ActivationSyncBehaviour.DELETE) {
      targetSyncConfig['delete_keys'] = uniqueIdentifierKeys;
    } else if (this.syncBehaviour === ActivationSyncBehaviour.ARCHIVE) {
      targetSyncConfig['archive_keys'] = uniqueIdentifierKeys;
    } else {
      targetSyncConfig['primary_keys'] = uniqueIdentifierKeys;
    }

    return targetSyncConfig;
  }
}
