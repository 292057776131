import { TemplateRef } from '@angular/core';


export enum DialogContainerPosition {
  START,
  END
}

export interface GenericDialogStylingConfig {
  containerPosition?: DialogContainerPosition;
  contentWidth?: number;
  marginTop?: number;
  marginBottom?: number;
}

export interface GenericDialogConfig<T = any> {
  componentRef?: any;
  templateRef?: TemplateRef<any>;
  bodyData?: T;
  stylingConfig?: GenericDialogStylingConfig;
  panelClass?: string | string[];
  reactComponent?: React.ReactNode;
  [key: string]: any;
}
