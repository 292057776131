import { Component, ElementRef, OnInit } from '@angular/core';
import { DestinationEditDialog } from '../../../react/containers/destination/create/DestinationEditDailog';
import { fadeAnim, popupAnim } from '../../dialog/animations';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { DestinationSettingsService } from '../destination-settings-form/destination-settings.service';

@Component({
  selector: 'destination-edit-dialog-react',
  template: '',
  providers: [ BridgeService, DestinationSettingsService ],
  animations: [ popupAnim(), fadeAnim() ]
})

export class DestinationEditDialogReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      DestinationEditDialog,
      this._el.nativeElement,
      {}
    );
  }
}
