import { useState } from 'react';

function useInput(initialInput = ''): [string, Function] {
  const [input, setInput] = useState(initialInput);
  /**
   *
   * @param {Object} e  input event
   * @param {boolean} reset if passed true, it will reset the input value to empty string.
   */
  const changeInput = (e: any, reset?: boolean) => {
    if (reset) {
      setInput('');
      return;
    }
    setInput(e.target.value);
  };

  return [input, changeInput];
}

export default useInput;
