import { Component, ElementRef, OnInit } from '@angular/core';
import { BridgeService } from '../react-bridge/services/bridge.service';
import { BannerOutlet } from '../../react/components/Banner/BannerOutlet';

@Component({
  selector: 'banner-outlet-react',
  template: '',
  providers: [ BridgeService ]
})

export class BannerOutletReactComponent implements OnInit {

  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit(): void {
    this._bridgeService.mount(
      BannerOutlet,
      this._el.nativeElement,
      {}
    );
  }

}
