import useClipboardService from './services/useClipboardService';
import { useReactClipboard } from './useReactClipboard';

export default function useCopyToClipboard(react = false) {
  if (react) {
    const clipboardServiceReact = useReactClipboard();
    return clipboardServiceReact;
  }

  const clipboardService = useClipboardService();
  return clipboardService;
}
