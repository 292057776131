import { Injectable } from '@angular/core';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DialogOverlayService } from '../dialog-overlay.service';
import { ConfirmV2DialogComponent } from './confirm-v2-dialog.component';
import { ConfirmV2DialogInterface } from './model';

@Injectable()
export class ConfirmV2DialogService  {

  constructor(private _dialogOverlayService: DialogOverlayService) { }

  confirm(data: ConfirmV2DialogInterface) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      ConfirmV2DialogComponent,
      data
    );

    return dialogRef.onClose();
  }
}
