import { Injectable } from '@angular/core';
import { AnsibleBoolean } from '../models/ansible-config';
import { TeamSettingsService } from './team-settings.service';


declare const __ACTIVATION_DEPRICATION_CONFIG__;

@Injectable({ providedIn: 'root' })
export class ActivationStatusService {

  constructor(
    private _teamSettingsService: TeamSettingsService,
  ) {
  }

  shouldShowActivation() {
    return __ACTIVATION_DEPRICATION_CONFIG__.disabled === AnsibleBoolean.FALSE ||
    (__ACTIVATION_DEPRICATION_CONFIG__.disabled === AnsibleBoolean.TRUE && this._teamSettingsService.isActivationEnabled());
  }

  // SHOULD BE CLEANED UP AFTER SUNSET
  shouldShowActivationBanner() {
    return __ACTIVATION_DEPRICATION_CONFIG__.disabled === AnsibleBoolean.TRUE &&
    __ACTIVATION_DEPRICATION_CONFIG__.showBanner === AnsibleBoolean.TRUE;
  }

  getActivationSunsetEpoch() {
    return __ACTIVATION_DEPRICATION_CONFIG__.activateSunsetEpoch;
  }

}
