<div
  class="priority-indicators {{ pipeline.priority | lowercase }}"
  [class.disabled]="isDisabled()"
  [tooltip]="infoTooltip"
  tooltipPlacement="right">
  <hd-icon name="top" size="0"></hd-icon>

  <tooltip-content #infoTooltip>
    {{ getTooltipMessage() }}
  </tooltip-content>
</div>
