import { FieldTypes, SourceFormFieldDefinition } from '../models';

export const SALESLOFT_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'apiKey',
      fieldId: 'api-key',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'apiKey', label: 'API Key', required: true },
      hint: 'Enter the API token obtained from the App Portal → API Keys → Create New → Add Description → Copy & save the API key.',
      buildParamsKey: 'api_key',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
]
