import React, { ReactNode } from 'react';
import {
  HEVO_WEBSITE_REDIRECT_LINKS,
  HevoWebsiteRedirectEnum
} from '../../../../app/core/constants';
import { HdAuthRequestType } from './models';
import { AUTH_STRINGS } from './strings';
import styles from './styles.module.scss';
import { FeaturedCustomers, TestimonialSection } from '../TestimonialSection';

declare const __DOCS_CONFIG__: any;

export interface HdAuthProps {
  selectedAuthType?: HdAuthRequestType;
  reducedMarginBottom?: boolean;
  featuredCustomers: FeaturedCustomers;
  loginContainerStyles?: React.CSSProperties;
  children: ReactNode;
  heading?: string;
  subheading?: string;
}

export function HdAuth({
  selectedAuthType,
  reducedMarginBottom = false,
  featuredCustomers,
  loginContainerStyles,
  children,
  heading = '',
  subheading = ''
}: HdAuthProps) {
  const selectedAuthString = AUTH_STRINGS[selectedAuthType];

  return (
    <div className='auth-container'>
      <TestimonialSection className='left' featuredCustomers={featuredCustomers} />

      <div className='right'>
        <div className='right-container'>
          <div className={`${styles.authHeading} ${reducedMarginBottom ? 'mb-8' : ''}`}>
            <img
              alt='Hevo'
              className={styles.hevoLogo}
              src='https://res.cloudinary.com/hevo/image/upload/v1684393404/hevo-logos-v2/logo-mark/logo_ph2du0.png'
            />

            <div className='auth-heading-container center-flex-col'>
              <div className='text-headline-3 text-center'>
                {heading || selectedAuthString?.heading}
              </div>

              {(!!selectedAuthString?.subheading || !!subheading) && (
                <div className='text-subheading-1 text-secondary text-center mt-3'>
                  {subheading || selectedAuthString?.subheading}
                </div>
              )}
            </div>
          </div>

          <div className='auth-login-container' style={loginContainerStyles}>
            {children}
          </div>
        </div>

        <footer className='d-flex justify-between'>
          <div className='text-caption-1 text-secondary'>
            © Hevo Data Inc. {new Date().getFullYear()}. All Rights Reserved.
          </div>

          <div className='footer-links-container'>
            <a
              href={HEVO_WEBSITE_REDIRECT_LINKS[HevoWebsiteRedirectEnum.TERMS_OF_SERVICE]}
              className='footer-link'
              target='_blank'
              rel='noreferrer'
            >
              Terms of Use
            </a>

            <a
              href={HEVO_WEBSITE_REDIRECT_LINKS[HevoWebsiteRedirectEnum.PRIVACY_POLICY]}
              className='footer-link'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>

            <a
              href={__DOCS_CONFIG__.baseURL}
              className='footer-link'
              target='_blank'
              rel='noreferrer'
            >
              Documentation
            </a>

            <a
              href={HEVO_WEBSITE_REDIRECT_LINKS[HevoWebsiteRedirectEnum.BLOG]}
              className='footer-link'
              target='_blank'
              rel='noreferrer'
            >
              Blog
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}
