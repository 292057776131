import React from 'react';
import { HdTimeFormat } from '../../../components/UIElements/HdCalendar';

export default function PaidUserFeedback({ subscriptionEndDate, dataId }) {
  return (
    <div className='box' data-id={`${dataId}-paid-user-feedback`}>
      <div className='box__header'>
        <span className='box__title'>Your account deletion request has been taken</span>
      </div>
      <div className='box__body'>
        <p>
          Your current subscription will end on{' '}
          <HdTimeFormat value={subscriptionEndDate} options={{ formats: 'MMM DD, YYYY' }} />. You
          will be able to access your Hevo account till then. To avoid being charged for any
          additional Events beyond your Events quota, you can pause or delete Pipelines you no
          longer need.
        </p>
      </div>
    </div>
  );
}
