import {
  GlobalSearchEntity,
  GlobalSearchReducerAction,
  GlobalSearchReducerActionType,
  GlobalSearchState
} from './models';
import { filterEntityData, filterStateData } from './util';

export function GlobalSearchReducer(
  state: GlobalSearchState,
  action: GlobalSearchReducerAction
): GlobalSearchState {
  switch (action.type) {
    case GlobalSearchReducerActionType.GET_ENTITY_DATA:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          loadingData: true
        }
      };

    case GlobalSearchReducerActionType.UPDATE_ENTITY_DATA: {
      let newState = {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          ...action.data
        }
      };

      newState = {
        ...newState,
        [action.entity]: {
          ...newState[action.entity],
          ...filterEntityData(action.entity, state.filters, newState),
          loadingData: false
        }
      };

      // re-filter once pipelines are fetched
      if (action.entity === GlobalSearchEntity.PIPELINES) {
        newState = {
          ...newState,
          [GlobalSearchEntity.SOURCE_TYPES]: {
            ...newState[GlobalSearchEntity.SOURCE_TYPES],
            ...filterEntityData(GlobalSearchEntity.SOURCE_TYPES, state.filters, newState),
            loadingData: false
          }
        };
      }

      // re-filter once destination are fetched
      if (action.entity === GlobalSearchEntity.DESTINATIONS) {
        newState = {
          ...newState,
          [GlobalSearchEntity.DESTINATION_TYPES]: {
            ...newState[GlobalSearchEntity.DESTINATION_TYPES],
            ...filterEntityData(GlobalSearchEntity.DESTINATION_TYPES, state.filters, newState),
            loadingData: false
          }
        };
      }

      return { ...newState };
    }

    case GlobalSearchReducerActionType.BULK_UPDATE:
      return {
        ...state,
        ...action.data
      };

    case GlobalSearchReducerActionType.FILTER_DATA: {
      const newFilters = {
        ...state.filters,
        ...action.data
      };

      return {
        ...state,
        ...filterStateData(newFilters, state),
        filters: {
          ...state.filters,
          ...action.data
        }
      };
    }

    case GlobalSearchReducerActionType.API_ERROR:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          loadingData: false,
          apiError: true
        }
      };

    default:
      return state;
  }
}
