import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { HdFormikTextField } from '../../../components/FormikElements';
import ErrorFocus from '../../../utils/ErrorFocus';
import { HdButton, HdIcon, HdRadioGroup, HdRadio } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import TeamAPI from '../TeamAPI';
import styles from './styles.module.scss';
import useForceUpdate from '../../../hooks/useForceUpdate';

const validationSchema = Yup.object({
  feedback: Yup.string().trim().required(),
  option: Yup.string().required()
});
const initialValues = {
  feedback: '',
  option: null
};
const RADIO_OPTIONS_SHIMMER_DIMENSIONS = ['194px', '254px', '194px', '254px', '194px', '254px'];

function FeedbackForm({ props, loading, feedbackOptions, isSubmitting, dataId, forceUpdate }) {
  useEffect(() => {
    forceUpdate();
  }, [props.values]);

  const dataIdGenerator = getDataIdGenerator(dataId, 'feedback');

  return (
    <Box
      component='form'
      onSubmit={props.handleSubmit}
      noValidate
      data-id={dataIdGenerator('form')}
    >
      <ErrorFocus formik={props} />

      {loading && (
        <div data-id={dataIdGenerator('options-shimmer')}>
          {RADIO_OPTIONS_SHIMMER_DIMENSIONS.map((width, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx} className={styles.shimmerOption}>
              <div
                className='shimmer shimmer-circle mr-2'
                style={{ width: '16px', height: '16px' }}
              />

              <div className='shimmer shimmer-line' style={{ width, height: '8px' }} />
            </div>
          ))}
        </div>
      )}

      {!loading && (
        <HdRadioGroup
          row={false}
          name='delete-option'
          onChange={value => {
            props.setFieldValue('option', value);
          }}
        >
          {feedbackOptions.map(ele => (
            <HdRadio
              className='mb-5'
              key={ele.code}
              value={ele.code}
              dataId={dataIdGenerator(`option-${ele.code}`)}
            >
              <span className={`ml-2 ${styles.optionBody}`}>{ele.display_reason}</span>
            </HdRadio>
          ))}
        </HdRadioGroup>
      )}

      <div className={`${styles.textArea} mt-3`}>
        <Field
          name='feedback'
          maxLength={1999}
          required
          minRows={4}
          disabled={isSubmitting}
          placeholder='How can we improve? Your feedback is important to us'
          multiline
          component={HdFormikTextField}
        />
      </div>
    </Box>
  );
}

export default function Feedback({ proceedClick, userEmail, backClick, dataId }) {
  const formikRef = useRef<FormikProps<any>>();

  const [loading, setLoading] = useState(false);
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const forceUpdate = useForceUpdate();

  const fetchFeedbackOptions = () => {
    setError(null);
    setLoading(true);

    TeamAPI.getAccountDeletionReasonOptions()
      .then(res => {
        setFeedbackOptions(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFeedbackOptions();
  }, []);

  const hasError = !!error;

  const handleSubmit = values => {
    setSubmitting(true);

    TeamAPI.teamAccountDeletionFeedback(userEmail, values.option, values.feedback)
      .then(() => {
        proceedClick();
      })
      .finally(() => setSubmitting(false));
  };

  const formError = Object.keys(formikRef.current?.errors || {}).length > 0;

  const dataIdGenerator = getDataIdGenerator(dataId, 'feedback');

  return (
    <>
      {!hasError && (
        <div className='box' data-id={dataIdGenerator('')}>
          <div className='box__header'>
            <div className='box__back'>
              <HdIcon
                className='actionable-icon actionable-icon-sm'
                onClick={() => backClick()}
                name='back'
                dataId={dataIdGenerator('back-icon')}
              />
            </div>

            <span className='box__title'>Give Us Feedback</span>
          </div>

          <div className='box__body'>
            <div>
              <span className='text-subheading-2'> We Will Miss You 😭</span>
            </div>

            <div className='mt-2 mb-2 text-body-1 mb-7'>
              Before you leave, would you like to give us feedback? It will mean a lot to us.
            </div>

            <Formik
              innerRef={formikRef}
              validateOnMount
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              data-id={dataIdGenerator('form')}
            >
              {props => (
                <FeedbackForm
                  dataId={dataId}
                  forceUpdate={forceUpdate}
                  feedbackOptions={feedbackOptions}
                  isSubmitting={submitting}
                  loading={loading}
                  props={props}
                />
              )}
            </Formik>
          </div>

          <div className='box__footer center-flex-row justify-end'>
            <HdButton
              onClick={() => formikRef.current?.submitForm()}
              type='button'
              showProgress={submitting}
              className='submit-btn'
              disabled={formError || submitting}
              dataId={dataIdGenerator('submit')}
            >
              <span>{submitting ? 'Submitting' : 'Proceed'}</span>
            </HdButton>
          </div>
        </div>
      )}

      {hasError && (
        <RetryApiAlert
          error={error}
          actionHandler={fetchFeedbackOptions}
          dataId={dataIdGenerator('')}
        />
      )}
    </>
  );
}
