// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.boxTable_Q_XRL > thead > tr {\n  height: 60px !important;\n}\n.boxTable_Q_XRL tr {\n  display: grid;\n  grid-template-columns: 20% 20% 27% 33%;\n}\n.boxTable_Q_XRL td,\n.boxTable_Q_XRL th {\n  display: flex;\n  align-items: center;\n}\n\n.noItemBoxIconContainer_RJTOU {\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60px;\n  width: 60px;\n  margin-bottom: 16px;\n  background-color: var(--primary-color);\n}\n\n.noItemBoxIcon_oNvj1 {\n  color: var(--light-text);\n  font-size: 30px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxTable": "boxTable_Q_XRL",
	"noItemBoxIconContainer": "noItemBoxIconContainer_RJTOU",
	"noItemBoxIcon": "noItemBoxIcon_oNvj1"
};
export default ___CSS_LOADER_EXPORT___;
