import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { HdIcon, HdTooltip } from '../../UIElements';
import HdFormikCheckBox from '../HdFormikCheckBox';
import HdFormikSwitchWrapper from '../HdFormikSwitchWrapper';
import HdFormControl from '../../UIElements/HdFormControl';
import { HdFormikFileField } from '../index';

interface SSLConfigProps {
  sslCARequired?: boolean;
}

export default function SSLConfig({ sslCARequired }: SSLConfigProps) {
  const formikProps = useFormikContext<{
    useSSL: boolean;
    sslClientCert?: object;
    sslClientKey?: object;
  }>();

  return (
    <>
      <HdFormikSwitchWrapper fieldName='useSSL' label='Use SSL' className='mb-7'>
        <Typography component='label' htmlFor='useSSL' className='mt-1'>
          Connect over an SSL-encrypted connection
        </Typography>
      </HdFormikSwitchWrapper>

      {formikProps.values.useSSL ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HdFormControl className='mb-2'>
                <Field
                  name='sslCA'
                  label='CA File'
                  required={
                    sslCARequired ||
                    !!formikProps.values.sslClientKey ||
                    !!formikProps.values.sslClientCert
                  }
                  component={HdFormikFileField}
                  className='clear-helper-text'
                />
              </HdFormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HdFormControl className='mb-6'>
                <Field
                  name='sslLoadAllCA'
                  component={HdFormikCheckBox}
                  label={
                    <div className='ml-1 center-flex-row'>
                      <span className='text-medium text-default'>Load all CA Certificates</span>
                      <HdTooltip
                        title='If enabled, Hevo loads all the CA certificates from the CA file.'
                        placement='right'
                      >
                        <HdIcon name='info' className='ml-1' />
                      </HdTooltip>
                    </div>
                  }
                />
              </HdFormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HdFormControl>
                <Field
                  name='sslClientCert'
                  label='Client Certificate'
                  component={HdFormikFileField}
                  required={!!formikProps.values.sslClientKey}
                />
              </HdFormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <HdFormControl>
                <Field
                  name='sslClientKey'
                  label='Client Key'
                  required={!!formikProps.values.sslClientCert}
                  component={HdFormikFileField}
                />
              </HdFormControl>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}
