import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class DialogOverlayRef {
  private _onClose = new Subject<any>();
  private _onOpen = new Subject<any>();
  private _positiveClick = new Subject<any>();
  customEventsSubject = new Subject<any>();
  dataUpdateSubject = new Subject<any>();
  closeRequestSubject = new Subject<void>();
  opened = false;

  constructor(public overlayRef: OverlayRef) { }

  close(data?: any): void {
    this.opened = false;
    this.overlayRef.dispose();
    this._onClose.next(data);
    this._onClose.complete();
  }

  markOpened() {
    this.opened = true;
    this._onOpen.next();
  }

  onClose() {
    return this._onClose.asObservable();
  }

  onOpen() {
    return this._onOpen.asObservable();
  }

  positiveClick(data?: any): void {
    return this._positiveClick.next(data);
  }

  onPositiveClick() {
    return this._positiveClick.asObservable();
  }

  closeDialog() {
    this.closeRequestSubject.next();
  }

  updateData(data: any) {
    this.dataUpdateSubject.next(data);
  }

  emitEvent(eventName: string, data?: any) {
    this.customEventsSubject.next({
      name: eventName,
      data: data
    });
  }
}
