import { saveAs } from 'file-saver';
import { RecoveryCode } from './interfaces';

export function downloadCodes(codes) {
  const blob: Blob = new Blob([getDownloadCodeContent(codes)], { type: 'text' });
  saveAs(blob, 'hevo-tfa-recovery-codes.txt');
}

export function getDownloadCodeContent(codes) {
  let contentToDownload = '';

  if (codes.length) {
    contentToDownload = codes.map((code: RecoveryCode) => code.value).join('\n');
  }

  return contentToDownload;
}
