export const TRACKER_DPA_OPEN = '[DPA Alert] banner open';
export const TRACKER_DPA_CLOSE = '[DPA Alert] banner close';
export const TRACKER_DPA_SET_CONSENT = '[DPA Alert] set consent';
export const TRACKER_DPA_CLOSE_CLICK = '[DPA Alert] banner close click';

export const TRACKER_RATE_LIMIT_BANNER_OPEN = '[Rate Limit Alert] banner open';

export const TRACKER_OVERAGE_OPEN = '[Overage Alert] banner open';
export const TRACKER_OVERAGE_CLOSE = '[Overage Alert] banner close';
export const TRACKER_OVERAGE_CLOSE_CLICK = '[Overage Alert] banner close click';

export const TRACKER_VERSION_UPDATE_OPEN = '[Version Update Alert] banner open';
export const TRACKER_VERSION_UPDATE_REFRESH = '[Version Update Alert] refresh page';

export const TRACKER_FEATURE_REQUEST_BANNER_SHOWN = '[Feature Request Banner] banner open';
export const TRACKER_FEATURE_REQUEST_BANNER_CLOSE = '[Feature Request Banner] banner close';
export const TRACKER_FEATURE_REQUEST_BANNER_REQUEST_FEATURE = '[Feature Request Banner] request feature click';
