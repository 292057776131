import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { HdAccordion, HdAccordionSummary, HdAccordionDetails, HdIcon } from '../UIElements';
import useDontMountAtFirst from '../../hooks/useDontMountAtFirst';
import { getDataIdGenerator } from '../../utils/generateDataId';

interface AdvancedConfigProps {
  children: React.ReactNode;
  className?: string;
  showBorderBottom?: boolean;
  forceExpand?: boolean;
  label?: string;
  onExpand?: Function;
  expandIcon?: React.ReactNode;
  classes?: {
    head?: string;
    body?: string;
    title?: string;
    root?: string;
  };
  keepExpanded?: boolean;
}

export const dataIdGenerator = getDataIdGenerator('accordion');

export default function AdvancedConfig({
  label = 'Advanced Settings',
  children,
  className,
  onExpand,
  forceExpand,
  classes,
  expandIcon = <HdIcon data-id={dataIdGenerator('expand-icon')} name='solid-dropdown' size={1} />,
  showBorderBottom,
  keepExpanded = false
}: AdvancedConfigProps) {
  const [expanded, setExpanded] = useState(false);
  const accordionChildRef = useRef(null);

  useDontMountAtFirst(() => {
    if (!expanded && forceExpand) {
      const erroredElement = accordionChildRef.current.querySelectorAll('.Mui-error');
      if (erroredElement.length) {
        setExpanded(true);
      }
    }
  }, [forceExpand]);

  useEffect(() => {
    if (keepExpanded) {
      setExpanded(true);
    }
  }, [keepExpanded]);

  useEffect(() => {
    if (expanded && onExpand) {
      onExpand();
    }
  }, [expanded]);

  return (
    <HdAccordion
      className={clsx(classes?.root, className)}
      showBorderBottom={showBorderBottom}
      expanded={expanded}
      data-id={dataIdGenerator('root')}
    >
      <HdAccordionSummary
        aria-controls='panel'
        data-id='panel-header'
        className={clsx(classes?.head)}
        expandIcon={expandIcon}
        onClick={() => !keepExpanded && setExpanded(prev => !prev)}
      >
        <Typography
          data-id={dataIdGenerator('title')}
          className={clsx(classes?.title)}
          variant='body4'
          component='h4'
        >
          {label}
        </Typography>
      </HdAccordionSummary>

      <HdAccordionDetails
        data-id={dataIdGenerator('body')}
        className={clsx(classes?.body)}
        ref={accordionChildRef}
      >
        {children}
      </HdAccordionDetails>
    </HdAccordion>
  );
}
