import React, { useState, useLayoutEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

const DEFAULT_WIDTH = 120;

export default function useRenderTagsLimit(
  tagsRef: React.RefObject<HTMLDivElement>,
  tags: [],
  defaultTagWidth = DEFAULT_WIDTH
) {
  const [tagsLimit, setTagsLimit] = useState<number>(tags.length);
  const [tagsWidth, setTagsWidth] = useState<number>(defaultTagWidth);

  /**
   * to listen the change in selected tags items post render to the DOM
   * and calculates only when tags are in collapsed state
   */
  useLayoutEffect(() => {
    if (tagsRef.current?.offsetWidth) {
      calculateVisibleTagsCountAndWidth(tagsRef.current?.offsetWidth, defaultTagWidth);
    } else {
      calculateVisibleTagsCount();
    }
  }, [tags]);

  // to listen the change in the width/size of tag wrapper div
  useResizeObserver<HTMLDivElement>({
    ref: tagsRef,
    onResize: ({ width }) => {
      calculateVisibleTagsCountAndWidth(width, defaultTagWidth);
    }
  });

  const calculateVisibleTagsCount = () => {
    let visibleItems = 0;
    /**
     * hook to find the count of the items which can appear on the first line
     * and the left out tags which are hidden dua to overflow
     */
    if (tagsRef.current) {
      const childElements = Array.from(tagsRef.current.children);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < childElements.length; i++) {
        if (visibleItems > 0 && isNewRowStarted(tagsRef.current, childElements[i])) {
          break;
        }
        visibleItems += 1;
      }

      // making sure to have at least min 2 tags
      if (tags.length > visibleItems && visibleItems === 1) {
        visibleItems += 1;
      }
      setTagsLimit(visibleItems);
    }
    return visibleItems;
  };

  const calculateVisibleTagsCountAndWidth = (width, tagWidth) => {
    /**
     * calculating the dynamic max width for each tag based on the width of the parent container & tags visible
     * 4.5px is reduced in respect to the margin given (which is 4px) to each tags
     * if none of the tag is hidden then use the default width
     */
    const visibleTags = calculateVisibleTagsCount();
    let calculatedWidth = width / visibleTags - 4.5;
    if (visibleTags === 1 || tags.length === visibleTags) {
      calculatedWidth = tagWidth;
    }
    setTagsWidth(calculatedWidth);
  };

  return { tagsLimit, leftOutTags: tags.length - tagsLimit, tagWidth: `${tagsWidth}px` };
}

function isNewRowStarted(parent, child) {
  return child.offsetLeft === parent.offsetLeft;
}
