import React from 'react';
import { getDurationString } from '../../../legacy-utils/date';
import { HdDurationPipeOptions } from '../../../../app/pipe/interface';

interface HdDurationProps {
  duration: number;
  options?: HdDurationPipeOptions;
}

export default function HdDuration({ duration, options = {} }: HdDurationProps) {
  return (
    <>{getDurationString(duration, options.useAbbr, options.separateUnits, options.precision)}</>
  );
}
