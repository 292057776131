export const CLEVERTAP_REGIONS = [
  {
    name: 'EU - Default',
    value: null,
    id: 'default'
  },
  {
    name: 'India',
    value: 'in1',
    id: 'in1'
  },
  {
    name: 'Singapore',
    value: 'sg1',
    id: 'sg1'
  },
  {
    name: 'South Korea',
    value: 'sk1',
    id: 'sk1'
  },
  {
    name: 'US',
    value: 'us1',
    id: 'us1'
  }
];

export const DEFAULT_CLEVERTAP_REGION = CLEVERTAP_REGIONS[0];
