import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import * as Yup from 'yup';
import {
  getUserAuthConfigFromRawData,
  UserAuthConfiguration,
  UserAuthenticationMethodsEnum
} from '../../team/Authentication/models';
import {
  GOOGLE_LOGIN_ENFORCED_ERROR,
  TRACKER_FORGOT_PASSWORD_CLICK,
  TRACKER_GOOGLE_LOGIN_CLICK,
  TRACKER_LOGIN_CLICK, TRACKER_LOGIN_FORM_ERROR, TRACKER_LOGIN_SUBMISSION_ERROR, TRACKER_LOGIN_SUBMISSION_SUCCESS
} from '../constants';
import { HdAuthRequestType } from '../HdAuth/models';
import { GoogleAuthRequestType } from '../models';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikPasswordField, HdFormikTextField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdIcon, HdIconButton, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import ErrorFocus from '../../../utils/ErrorFocus';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AuthAPI from '../AuthAPI';
import { GoogleLoginButton } from '../GoogleLoginButton';
import { HdAuth } from '../HdAuth';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useGoogleLoginError } from '../useGoogleLoginError';
import { useLogin } from '../useLogin';
import { useLoginGuard } from '../useLoginGuard';
import { useRecaptchaService } from '../useRecaptchaService';

declare const __WEBSITE_URL__: string;

export interface LoginProps {
  featuredCustomers: FeaturedCustomers;
}

const SIGNUP_URL = typeof __WEBSITE_URL__ !== 'undefined' ? (__WEBSITE_URL__ + '/signup/') : null;

const validationSchema = Yup.object({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required')
});

export function Login() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeLoginGuard } = useLoginGuard();

  return (
    <HdResolvedComponent
      Component={LoginInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[ executeLoginGuard ]}
    />
  );
}

export function LoginInner({
  featuredCustomers
}: LoginProps) {
  const [ emailVerified, setEmailVerified ] = useState(false);
  const [ verifyingEmail, setVerifyingEmail ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ formError, setFormError ] = useState(null);
  const [ emailValidationError, setEmailValidationError ] = useState(null);
  const [ googleLoginError, setGoogleLoginError ] = useState(useGoogleLoginError());

  const { login } = useLogin();
  const history = useHistory();
  const formikRef = useRef<FormikProps<{}>>();
  const { eventTrack } = useAnalyticsTracker();
  const { search } = useLocation();
  const { isRecaptchaReady, validateWithRecaptchaCommon } = useRecaptchaService('recaptcha_container');

  const initialEmail = new URLSearchParams(search).get('email');

  const initialValues = {
    email: initialEmail,
    password: ''
  };

  const validateEmail = () => {
    const email = formikRef.current.values['email'];

    if (!email) {
      return;
    }

    setVerifyingEmail(true);
    setEmailValidationError(null);

    validateWithRecaptchaCommon(
      (payload) => {
        return AuthAPI.getAuthConfigForLogin(payload);
      },
      {
        email
      }
    ).then((res) => {
      setVerifyingEmail(false);

      const authConfig: UserAuthConfiguration = getUserAuthConfigFromRawData(res.data);
      const queryParams = new URLSearchParams(search);
      queryParams.set('email', email);

      if (authConfig.method === UserAuthenticationMethodsEnum.GOOGLE_LOGIN) {
        setEmailValidationError(GOOGLE_LOGIN_ENFORCED_ERROR);
      } else if (authConfig.method === UserAuthenticationMethodsEnum.SAML) {
        history.push({
          pathname: `/saml-login`,
          search: `?${queryParams.toString()}`
        });
      } else {
        setEmailVerified(true);
      }
    }, (error) => {
      setVerifyingEmail(false);
      setEmailValidationError(getErrorMessageFromObj(error));
    });
  };

  const handleSubmit = () => {
    const trackingParams = {
      email: formikRef.current.values['email']
    };

    eventTrack({
      action: TRACKER_LOGIN_CLICK,
      properties: trackingParams
    });

    if (!formikRef.current.isValid) {
      eventTrack({
        action: TRACKER_LOGIN_FORM_ERROR,
        properties: trackingParams
      });

      return;
    }

    setIsSubmitting(true);
    setFormError(null);
    setGoogleLoginError(null);

    validateWithRecaptchaCommon(
      (payload) => {
        return login(
          payload,
          {
            uiOptions: {
              handleUnauthorisedResponse: false,
              showErrorMsg: false,
              showLoading: false
            }
          }
        );
      },
      {
        email: formikRef.current.values['email'],
        password: formikRef.current.values['password']
      }
    ).then(() => {
      eventTrack({
        action: TRACKER_LOGIN_SUBMISSION_SUCCESS,
        properties: trackingParams
      });

    }, (err) => {
      const errorMessage = getErrorMessageFromObj(err);
      setIsSubmitting(false);
      setFormError(errorMessage);

      eventTrack({
        action: TRACKER_LOGIN_SUBMISSION_ERROR,
        properties: {
          ...trackingParams,
          reason: errorMessage
        }
      });
    });
  };

  const onGoogleLoginClick = () => {
    eventTrack({
      action: TRACKER_GOOGLE_LOGIN_CLICK
    });
  };

  const onForgotPasswordClick = () => {
    eventTrack({
      action: TRACKER_FORGOT_PASSWORD_CLICK
    });
  };

  const onEditEmailClick = () => {
    setEmailVerified(false);
    formikRef.current.resetForm();
    formikRef.current.validateForm();
    setFormError(null);
  };

  const getForgotPasswordLink = (email) => {
    const queryParams = new URLSearchParams(search);
    queryParams.set('email', email);

    return `/forgot-password?${queryParams.toString()}`;
  };

  useEffect(() => {
    if (!initialEmail) {
      return;
    }

    setVerifyingEmail(true);

    if (isRecaptchaReady) {
      validateEmail();
    }
  }, [isRecaptchaReady]);

  const dataIdGenerator = getDataIdGenerator('login');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.LOGIN}
      featuredCustomers={featuredCustomers}
    >
      <GoogleLoginButton
        requestType={GoogleAuthRequestType.LOGIN}
        onClick={onGoogleLoginClick}
      />

      {!!googleLoginError && (
        <HdPane
          dataId={dataIdGenerator('google-login-error')}
          className='mt-4 w-100'
          variant='error-faded'
          icon='error-filled'
          iconClasses='text-error'
          disableHide
        >
          <div className='text-default'>
            {googleLoginError}
          </div>
        </HdPane>
      )}

      <div className='separator-with-content social-login-separator'>
        <span className='separator-content'>OR</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
        innerRef={formikRef}
      >
        {props => {
          return (
            <form
              noValidate
              onSubmit={props.handleSubmit}
            >
              <ErrorFocus formik={props} onFormError={() => {}} />

              {!props.values['email'] || !emailVerified ? (<>
                <HdFormControl className='mb-0'>
                  <Field
                    name='email'
                    label='Email Address'
                    component={HdFormikTextField}
                    startAdornment={<HdIcon name='email' />}
                    autoFocus
                    required
                  />
                </HdFormControl>

                {!!emailValidationError && (
                  <HdPane
                    dataId={dataIdGenerator('login-error')}
                    className='mb-1 w-100'
                    variant='error-faded'
                    icon='error-filled'
                    iconClasses='text-error'
                    disableHide
                  >
                    <div className='text-default'>
                      {emailValidationError}
                    </div>
                  </HdPane>
                )}

                <HdButton
                  dataId={dataIdGenerator('submit-email')}
                  type='submit'
                  size='lg'
                  className='w-100 mt-4'
                  showProgress={verifyingEmail}
                  disabled={verifyingEmail || !isRecaptchaReady}
                  onClick={validateEmail}
                >
                  Continue
                </HdButton>

                <div className='text-center w-100 mt-4'>
                  Don’t have an account?
                  {' '}
                  <a
                    className='text-link'
                    href={SIGNUP_URL}
                  >
                    Sign up now!
                  </a>
                </div>
              </>) : (<>
                <div className='email-display text-center'>
                  Enter password for
                  {' '}
                  <span
                    className='d-inline-flex align-items-center text-default text-bold'>
                  {props.values['email']}
                    <HdIconButton
                      dataId={dataIdGenerator('edit-email')}
                      verticalEdge='both'
                      onClick={onEditEmailClick}
                    >
                    <HdIcon name='edit' />
                  </HdIconButton>
                </span>
                </div>

                <HdFormControl className='mb-0'>
                  <Field
                    name='password'
                    label='Password'
                    component={HdFormikPasswordField}
                    startAdornment={<HdIcon name='password' />}
                    autoFocus
                    required
                  />
                </HdFormControl>

                {!!formError && (
                  <HdPane
                    dataId={dataIdGenerator('login-error')}
                    className='mb-1 w-100'
                    variant='error-faded'
                    icon='error-filled'
                    iconClasses='text-error'
                    disableHide
                  >
                    <div className='text-default'>
                      {formError}
                    </div>
                  </HdPane>
                )}

                <HdButton
                  dataId={dataIdGenerator('submit-password')}
                  type='submit'
                  size='lg'
                  className='w-100 mt-4'
                  showProgress={isSubmitting}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Log In
                </HdButton>

                <NavLink
                  className='text-link text-center btn-thumbnail-left w-100 mt-4'
                  to={getForgotPasswordLink(props.values['email'])}
                  onClick={onForgotPasswordClick}
                >
                  <HdIcon
                    name='help'
                    className='thumb'
                    size={3}
                  />
                  {' '}
                  Forgot Password?
                </NavLink>
              </>)}
            </form>
          );
        }}
      </Formik>

      <div id='recaptcha_container' />
    </HdAuth>
  );
}
