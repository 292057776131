import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { OverlayPosition } from './types';
import { ARROW_WIDTH, ARROW_HEIGHT, ARROW_OFFSET } from './constants';

export function getDirFromPositionPair(connectionPair: ConnectionPositionPair) {
  let dir: any = {
    position: 'end',
    align: 'top'
  };

  if (connectionPair.originX === 'start' || connectionPair.originX === 'end') {
    dir.position = connectionPair.originX;
    dir.align = connectionPair.overlayY;

    return dir;
  }

  if (connectionPair.originY === 'top' || connectionPair.originY === 'bottom') {
    dir.position = connectionPair.originY;
    dir.align = connectionPair.overlayX;

    return dir;
  }
}

export function getOffsetXFromPosition(position: OverlayPosition, defaultOffset: number = 0) {
  if (position.originX === 'start') {
    return -1 * ARROW_WIDTH - defaultOffset;
  }

  if (position.originX === 'end') {
    return ARROW_WIDTH + defaultOffset;
  }

  if (position.overlayX === 'start') {
    return -1 * (ARROW_OFFSET + ARROW_HEIGHT / 2) - defaultOffset;
  }

  if (position.overlayX === 'end') {
    return ARROW_OFFSET + ARROW_HEIGHT / 2 + defaultOffset;
  }

  return 0;
}

export function getOffsetYFromPosition(position: OverlayPosition, defaultOffset: number = 0) {
  if (position.originY === 'top') {
    return -1 * ARROW_WIDTH - defaultOffset;
  }

  if (position.originY === 'bottom') {
    return ARROW_WIDTH + defaultOffset;
  }

  if (position.overlayY === 'top') {
    return -1 * (ARROW_OFFSET + ARROW_HEIGHT / 2) - defaultOffset;
  }

  if (position.overlayY === 'bottom') {
    return ARROW_OFFSET + ARROW_HEIGHT / 2 + defaultOffset;
  }

  return 0;
}
