/* global CodeMirror */
/* global define */

((mod) => {
  'use strict';
  
  if (typeof exports === 'object' && typeof module === 'object') // CommonJS
      return mod(require('codemirror/lib/codemirror'));
  
  if (typeof define === 'function' && define.amd) // AMD
      return define(['codemirror/lib/codemirror'], mod);
  
  mod(CodeMirror);
})((CodeMirror) => {
  'use strict';
  
  CodeMirror.defineOption('showInvisibles', false, (cm, val, prev) => {
      let Count = 0;
      const Maximum = cm.getOption('maxInvisibles') || 16;
      
      if (prev === CodeMirror.Init)
          prev = false;
      
      if (prev && !val) {
          cm.removeOverlay('invisibles');
          return rm();
      }
      
      if (!prev && val) {
          add(Maximum);
       
          cm.addOverlay({
              name: 'invisibles',
              token: function nextToken(stream) {
                  if(stream.peek() === '	') {
                      stream.next();
                      return 'show-tab';
                  }

                  let spaces = 0;
                  let peek = stream.peek() === ' ';
                  
                  if (peek) {
                      while (peek && spaces < Maximum) {
                          ++spaces;
                          
                          stream.next();
                          peek = stream.peek() === ' ';
                      }
                      
                      let ret = 'whitespace whitespace-' + spaces;
                      
                      /*
                       * styles should be different
                       * could not be two same styles
                       * beside because of this check in runmode
                       * function in `codemirror.js`:
                       *
                       * 6624: if (!flattenSpans || curStyle != style) {}
                       */
                      if (spaces === Maximum)
                          ret += ' whitespace-rand-' + Count++;
                      
                      return ret;
                  }
                  
                  while (!stream.eol() && !peek) {
                      stream.next();
                      
                      peek = stream.peek() === ' ';
                  }
                  
                  return 'cm-eol';
              }
          });
      }
  });
  
  function add(max) {
      const classBase = '.CodeMirror .cm-whitespace-';
      const spaceChar = '·';
      const style = document.createElement('style');
      
      style.setAttribute('data-name', 'js-show-invisibles');
      
      let rules = '';
      let spaceChars = '';
      
      for (let i = 1; i <= max; ++i) {
          spaceChars += spaceChar;
          
          const rule = classBase + i + '::before { content: "' + spaceChars + '";}\n';
          rules += rule;
      }
      
    //   style.textContent = getStyle() + '\n' + getEOL() + '\n' + rules;
      style.textContent = rules;

      
      document.head.appendChild(style);
  }
  
  function rm() {
      const style = document.querySelector('[data-name="js-show-invisibles"]');
      
      document.head.removeChild(style);
  }
  
  function getStyle() {
      const style = [
          '.cm-whitespace::before {',
          '    position: absolute;',
          '    pointer-events: none;',
          '    color: #404F7D;',
          '}'
      ].join('');
      
      return style;
  }
  
  function getEOL() {
      const style = [
          '.CodeMirror-code > div > pre > span::after, .CodeMirror-line > span::after {',
          '    pointer-events: none;',
          '    color: #404F7D;',
          '    content: "¬"',
          '}',
      ].join('');
      
      return style;
  }
});
