import { Component, Input } from '@angular/core';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { getKeyIconClass } from '../../shortcuts/utils';

@Component({
  selector: 'tab-nav-label-tooltip',
  templateUrl: './tab-nav-label-tooltip.component.html',
  styleUrls: ['./tab-nav-label-tooltip.component.scss']
})
export class TabNavLabelTooltipComponent {
  keyCombination: string;
  @Input() label: string;
  @Input() showLabel = true;

  @Input()
  set command(value: string) {
    if (!value) {
      this.keyCombination = null;
      return;
    }
    this.keyCombination = this._shortcutService.getKeyFromCommand(value);
  }

  getKeyIconClass(key: string) {
    return getKeyIconClass(key);
  }

  constructor(private _shortcutService: KeyboardShortcuts) {}
}
