import { Observable } from 'rxjs';
import { User } from './user';

export abstract class SupportService {
  show: (message?: string) => void;
  hide: () => void;
  unreadCount$: Observable<number>;
  isOnCallSupportAvailable$: Observable<boolean>;
  setCurrentUser: (data: User) => void;
  reset: () => void;
  refresh: () => void;
  supportLink: string;
}

interface IOnCallSchedule {
  startTime: string;
  endTime: string;
}

export enum WeekDays {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

declare type OnCallSupportRoster = Record<WeekDays, Array<IOnCallSchedule>>;

export const ON_CALL_SUPPORT_ROSTER: OnCallSupportRoster = {
  [WeekDays.MONDAY]: [{
    startTime: '05:00:00',
    endTime: '23:59:59',
  }],
  [WeekDays.TUESDAY]: [{
    startTime: '00:00:00',
    endTime: '23:59:59',
  }],
  [WeekDays.WEDNESDAY]: [{
    startTime: '00:00:00',
    endTime: '23:59:59',
  }],
  [WeekDays.THURSDAY]: [{
    startTime: '00:00:00',
    endTime: '23:59:59',
  }],
  [WeekDays.FRIDAY]: [{
    startTime: '00:00:00',
    endTime: '23:59:59'
  }],
  [WeekDays.SATURDAY]: [{
    startTime: '00:00:00',
    endTime: '05:30:00',
  },
  {
    startTime: '10:00:00',
    endTime: '23:59:59',
  }],
  [WeekDays.SUNDAY]: [{
    startTime: '14:00:00',
    endTime: '23:00:00',
  }],
};

export const ON_CALL_SUPPORT_STATUS_CHECK_INTERVAL = 300000; // 5 min

export const SUPPORT_PERSON_IMAGES = [
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080956/dashboard/support-person-images/Sarthak_defamj.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080975/dashboard/support-person-images/Sathyam_vujutb.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080962/dashboard/support-person-images/Kamlesh_pyyyxr.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080956/dashboard/support-person-images/Nishant_ovzxkz.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080962/dashboard/support-person-images/Veeresh_yupu30.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080939/dashboard/support-person-images/Geetha_xfu56o.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080917/dashboard/support-person-images/Dipak_Patil_lrllmt.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080904/dashboard/support-person-images/Dimple_pnchpi.png',
  'https://res.cloudinary.com/hevo/image/upload/f_auto,q_auto,c_scale,w_90/v1668080960/dashboard/support-person-images/Vinita_tyjujc.png'
];
export const SUPPORT_PERSON_DEFAULT_IMAGE_URL = SUPPORT_PERSON_IMAGES[0];
export const ON_CALL_SUPPORT_PERSON_IMAGE_SHUFFLE_INTERVAL = 60 * 60 * 1000;
