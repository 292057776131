import React, { ReactElement, useMemo } from 'react';
import { RBAC_DISABLED_MESSAGE } from '../../../../app/core/models/rbac';
import useHasPermission from '../../../hooks/useHasPermission';
import { RbacPermissions } from '../../../../app/core/models/user';
import { HdIconButton, HdIconButtonProps } from '../HdIconButton';
import { HdTooltip } from '../HdTooltip';

export interface HdRbacIconButtonProps extends HdIconButtonProps {
  rbacPermission: RbacPermissions;
  tooltipContent?: ReactElement | string;
  disabledToolTipContent?: ReactElement | string;
}
export const HdRbacIconButton: React.FC<HdRbacIconButtonProps> = React.forwardRef(
  (
    {
      disabled,
      rbacPermission,
      disabledToolTipContent,
      tooltipContent,
      ...props
    }: HdRbacIconButtonProps,
    ref
  ) => {
    const { hasPermission } = useHasPermission();
    const isRbacActionEnabled = hasPermission(rbacPermission);

    const tooltip = useMemo(() => {
      if (!isRbacActionEnabled) {
        return RBAC_DISABLED_MESSAGE;
      }

      if (disabled) {
        return disabledToolTipContent;
      }

      return tooltipContent;
    }, [disabled, disabledToolTipContent, isRbacActionEnabled, tooltipContent]);

    const isDisabled = !isRbacActionEnabled || disabled;

    return (
      <HdTooltip title={tooltip} disabled={!tooltip}>
        <span>
          <HdIconButton
            style={isDisabled ? { pointerEvents: 'none' } : null}
            disabled={isDisabled}
            {...props}
            ref={ref}
          />
        </span>
      </HdTooltip>
    );
  }
);
