// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.destinationViewContainer_cLp3O {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n}\n\n.nodeImage_DtsWY {\n  width: 16px;\n  height: 16px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  flex-shrink: 0;\n  margin-right: 4px;\n}\n.nodeImage_DtsWY img {\n  max-width: 14px;\n  max-height: 14px;\n}\n.nodeImage_DtsWY hd-icon, .nodeImage_DtsWY .hevo-icon_BtE3C {\n  font-size: 14px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"destinationViewContainer": "destinationViewContainer_cLp3O",
	"nodeImage": "nodeImage_DtsWY",
	"hevo-icon": "hevo-icon_BtE3C"
};
export default ___CSS_LOADER_EXPORT___;
