import { forkJoin, from, of } from 'rxjs';
import { concatMap, delay, first, map, switchMap } from 'rxjs/operators';
import { EntityUIState } from '../../../app/core/models/entitiy-ui-state';
import { wrapIntoObservable } from '../../../app/hd-wizard/utils';
import { useQuery } from '../../hooks/useQuery';

export function useResolvedComponent({
  canActivate,
  resolve
}: {
  canActivate?: Array<() => Promise<boolean> | boolean>;
  resolve?: { [key: string]: any };
}) {
  const {
    data,
    uiState,
    error,
    fetch: resolveComponent
  } = useQuery({
    dataSource$: () =>
      from(canActivate || [])
        .pipe(
          delay(0),
          concatMap(guard => {
            const guardResp = guard();

            return wrapIntoObservable(guardResp).pipe(first());
          }),
          first(result => result !== true, true),
          switchMap(guardsResult => {
            if (!guardsResult || !resolve || !Object.keys(resolve || {}).length) {
              return of({
                guardsResult
              });
            }

            return forkJoin(
              Object.entries(resolve).reduce(
                (dict, [key, fn]) => ({
                  ...dict,
                  [key]: wrapIntoObservable(fn())
                }),
                {}
              )
            ).pipe(
              map(d => ({
                guardsResult,
                resolvedData: d
              }))
            );
          })
        )
        .toPromise()
  });

  const resolvingRoute = uiState === EntityUIState.LOADING || uiState === EntityUIState.NEW;
  const canActivateRoute = uiState === EntityUIState.IDLE && data?.guardsResult;
  const routeActivationError = uiState === EntityUIState.ERRORED ? error : null;
  const resolvedData = data?.resolvedData;

  return {
    resolveComponent,
    resolvedData,
    resolvingRoute,
    canActivateRoute,
    routeActivationError
  };
}
