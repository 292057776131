/**
 * @param module
 * Class method decorator that executes the decorated method only if provided
 * argument is defined
 *
 * e.g usage
 * `@ExecIfDefined(Calendly)
 * openWidget() {
 *   Calendly.openWidget({ url: 'calendly.com/product-discussion' })
 * }
 * `
 */
export function ExecIfDefined(module) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function() {
      if (typeof window[module] === 'undefined') {
        return;
      }
      originalMethod.apply(this, arguments);
    };
  };
}
