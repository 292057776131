export interface Time {
  hour: number;
  minute: number;
}

export function formatTime({ eventData, targetValue }) {
  const currValue = targetValue.split(' : ').join(':');

  if (!currValue) {
    return '';
  }

  let formattedVal = currValue;
  if (/^[3-9]$/.test(formattedVal)) {
    formattedVal = `0${formattedVal}`;
  }

  if (currValue.length === 2 && +currValue > 23) {
    const [head, ...tail] = currValue;
    formattedVal = `0${head}:${tail.join('')}`;
  }

  if (/^1[/-]$/.test(formattedVal)) {
    return `01 : `;
  }

  formattedVal = formattedVal.match(/(\d{1,2})/g) || [];
  if (formattedVal.length === 1) {
    if (!eventData && currValue.includes(':')) {
      return formattedVal[0];
    }

    if (/\d{2}/.test(formattedVal)) {
      return `${formattedVal[0]} : `;
    }
  }

  if (formattedVal.length === 2 && /^[6-9]$/.test(formattedVal[1])) {
    formattedVal[1] = `0${formattedVal[1]}`;
  }

  if (formattedVal.length > 2) {
    const [, month, year] = formattedVal.join('').match(/^(\d{2})(\d{2})/) || [];
    return [month, year].join(' : ');
  }

  return formattedVal.slice(0, 2).join(' : ');
}

export function getValidTime(h: string, m: string) {
  const hrs = Number.isInteger(parseInt(h, 10)) ? h : '00';
  const mins = Number.isInteger(parseInt(m, 10)) ? m : '00';
  return [hrs, mins];
}

export function getTimeUnit(val: number) {
  if (!Number.isInteger(val)) {
    return null;
  }
  return val;
}

export function formatTimeToString(val: Time) {
  if (!val || typeof val.hour !== 'number' || typeof val.minute !== 'number') {
    return '';
  }

  const [hrs, mins] = getValidTime(
    `${getDoubleDigitTime(val.hour)}`,
    `${getDoubleDigitTime(val.minute)}`
  );
  return `${hrs} : ${mins}`;
}

export function getDoubleDigitTime(val: Number) {
  return val.toString().padStart(2, '0');
}
