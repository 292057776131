export const TRACKER_ALL_JOBS_JOB_TYPE_TAB_CLICK = '[All Jobs] job type tab clicked';
export const TRACKER_ALL_JOBS_NEXT_PAGE_CLICK = '[All Jobs] next page CTA clicked';
export const TRACKER_ALL_JOBS_PREV_PAGE_CLICK = '[All Jobs] prev page CTA clicked';
export const TRACKER_ALL_JOBS_REFRESH_LIST_CLICK = '[All Jobs] refresh list CTA clicked';

export const TRACKER_JOB_CARD_NAVIGATE_TO_JOB_DETAILS_CLICK =
  '[Job Card] navigate to job details page CTA clicked';

export const TRACKER_JOB_STATS_NAVIGATE_TO_JOB_LIST_CLICK =
  '[Job Stats] navigate to jobs list CTA clicked';

export const TRACKER_JOBS_OBJECTS_LIST_NEXT_PAGE_CLICK =
  '[Jobs Objects List] next page CTA clicked';
export const TRACKER_JOBS_OBJECTS_LIST_PREV_PAGE_CLICK =
  '[Jobs Objects List] prev page CTA clicked';
export const TRACKER_JOBS_OBJECTS_LIST_REFRESH_LIST_CLICK =
  '[Jobs Object list] refresh list CTA clicked';

export const TRACKER_JOBS_OBJECTS_DETAILS_EXPAND_DETAILS_VIEW_CLICK =
  '[Jobs Object Details] expand details view clicked';

export const TRACKER_PIPELINE_JOBS_NEXT_PAGE_CLICK = '[Pipeline Jobs] next page CTA clicked';
export const TRACKER_PIPELINE_JOBS_PREV_PAGE_CLICK = '[Pipeline Jobs] prev page CTA clicked';
export const TRACKER_PIPELINE_JOBS_REFRESH_LIST_CLICK = '[Pipeline Jobs] refresh list CTA clicked';
