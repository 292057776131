// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.wrapper__EKNf {\n  display: flex;\n  align-items: center;\n}\n\n.wrapper__EKNf.shrink_V__he {\n  overflow: hidden;\n}\n.wrapper__EKNf.shrink_V__he .badgeKey__Noqd {\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.wrapper__EKNf.shrink_V__he .badge-key--partition-key__Dil0 {\n  flex-shrink: 3;\n}\n.wrapper__EKNf.shrink_V__he .badge-key--not-null_dtS3E {\n  flex-shrink: 2;\n}\n\n.badgeKey__Noqd {\n  border: solid 1px var(--divider-color);\n  border-radius: 4px;\n  padding: 2px 4px;\n  background: var(--surface-bg-overlay-color);\n  flex-shrink: 0;\n}\n\n.badge-key--pk__5kxu {\n  color: var(--key-badge-pk);\n}\n\n.badge-Key--sk_ko0dT {\n  color: var(--key-badge-sk);\n}\n\n.badge-key--dk_htFrs {\n  color: var(--key-badge-dk);\n}\n\n.badge-key--ck_cBOCB {\n  color: var(--key-badge-ck);\n}\n\n.badge-key--partition-key__Dil0 {\n  color: var(--key-badge-partition-key);\n}\n\n.badge-key--not-null_dtS3E {\n  color: var(--key-badge-not-null);\n  text-transform: capitalize;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper__EKNf",
	"shrink": "shrink_V__he",
	"badgeKey": "badgeKey__Noqd",
	"badge-key--partition-key": "badge-key--partition-key__Dil0",
	"badge-key--not-null": "badge-key--not-null_dtS3E",
	"badge-key--pk": "badge-key--pk__5kxu",
	"badge-Key--sk": "badge-Key--sk_ko0dT",
	"badge-key--dk": "badge-key--dk_htFrs",
	"badge-key--ck": "badge-key--ck_cBOCB"
};
export default ___CSS_LOADER_EXPORT___;
