import { useLocation } from 'react-router-dom';
import { GOOGLE_LOGIN_ERROR_QUERY_PARAM } from './constants';

export function useGoogleLoginError() {
  const { search } = useLocation();

  const errorEncoded = new URLSearchParams(search).get(GOOGLE_LOGIN_ERROR_QUERY_PARAM);

  if (typeof errorEncoded === 'string' && errorEncoded.length) {
    return atob(errorEncoded);
  }

  return null;
}
