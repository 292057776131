/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { safe, getWindow, getPadding } from './utils';
import { stylesMatcher } from './styles';
import { MaskProps } from './model';

// eslint-disable-next-line react/function-component-definition
const SVGMask: React.FC<MaskProps> = ({
  padding = 10,
  wrapperPadding = 0,
  onClick,
  onClickHighlighted,
  styles = {},
  sizes,
  className,
  anchorShape = 'rectangle',
  highlightedAreaClickable = true,
  highlightedAreaClassName,
  maskId,
  clipId
}) => {
  const maskID = maskId || uniqueId('mask__');
  const clipID = clipId || uniqueId('clip__');
  const getStyles = stylesMatcher(styles);
  const [px, py] = getPadding(padding);
  const [wpx, wpy] = getPadding(wrapperPadding);
  const { w, h } = getWindow();
  const width = safe(sizes?.width + px * 2);
  const height = safe(sizes?.height + py * 2);
  const top = safe(sizes?.top - py - wpy / 2);
  const left = safe(sizes?.left - px - wpx / 2);
  const windowWidth = w - wpx;
  const windowHeight = h - wpy;

  const maskAreaStyles = getStyles('maskArea', {
    x: left,
    y: top,
    width,
    height
  });

  const maskAreaCircleStyles = getStyles('maskAreaCircle', {
    cx: left + width / 2,
    cy: top + width / 2,
    r: width / 2
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role='button'
      tabIndex={0}
      aria-describedby='mask'
      style={getStyles('maskWrapper', {})}
      onClick={onClick}
      className={className}
    >
      <svg
        width={windowWidth}
        height={windowHeight}
        xmlns='http://www.w3.org/2000/svg'
        style={getStyles('svgWrapper', {
          windowWidth,
          windowHeight,
          wpx,
          wpy
        })}
      >
        <defs>
          <mask id={maskID}>
            <rect x={0} y={0} width={windowWidth} height={windowHeight} fill='white' />

            {anchorShape === 'circle' ? (
              <circle
                style={maskAreaCircleStyles}
                // Needs for Safari, as we pass any value, css rx will apply.
                rx={maskAreaStyles.rx ? 1 : undefined}
              />
            ) : (
              <rect
                style={maskAreaStyles}
                // Needs for Safari, as we pass any value, css rx will apply.
                rx={maskAreaStyles.rx ? 1 : undefined}
              />
            )}
          </mask>
          <clipPath id={clipID}>
            <polygon
              points={`0 0, 0 ${windowHeight}, ${left} ${windowHeight}, ${left} ${top}, ${
                left + width
              } ${top}, ${left + width} ${top + height}, ${left} ${
                top + height
              }, ${left} ${windowHeight}, ${windowWidth} ${windowHeight}, ${windowWidth} 0`}
            />
          </clipPath>
        </defs>

        {/* The actual Mask */}
        <rect
          style={getStyles('maskRect', {
            windowWidth,
            windowHeight,
            maskID
          })}
        />
        {/* The clickable area */}
        <rect
          style={getStyles('clickArea', {
            windowWidth,
            windowHeight,
            top,
            left,
            width,
            height,
            clipID
          })}
        />
        {anchorShape === 'circle' ? (
          <circle
            style={getStyles('highlightedCircleArea', {
              cx: left + width / 2,
              cy: top + width / 2,
              r: width / 2,
              highlightedAreaClickable
            })}
            className={highlightedAreaClassName}
          />
        ) : (
          <rect
            style={getStyles('highlightedArea', {
              x: left,
              y: top,
              width,
              height,
              highlightedAreaClickable
            })}
            className={highlightedAreaClassName}
            onClick={onClickHighlighted}
          />
        )}
      </svg>
    </div>
  );
};

export default SVGMask;

function uniqueId(prefix: string) {
  return prefix + Math.random().toString(36).substring(2, 16);
}
