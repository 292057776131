import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import { NodeLogoWithText } from '../../../../../containers/drawer/Shared/NodeLogoWithText';
import { HdButton } from '../../../../UIElements';
import { HdInlineDropdown } from '../../../../UIElements/HdInlineDropdown';
import { FilterOption } from '../../../../../../app/filter/models/filter-option';
import { DestinationFilterCustomOption } from '../../../../DestinationFilterCustomOption';

interface FilterProps {
  type: string;
  options: FilterOption<string>[];
  onChange: Function;
  selectedOptions: FilterOption<string>[];
  showSelectedOptionsCount?: boolean;
}

export function Filter({
  type,
  options,
  onChange,
  selectedOptions,
  showSelectedOptionsCount = false
}: FilterProps) {
  const filterTitle = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'source':
        return 'Source Type';
      case 'destination':
        return 'Destination';
      case 'status':
        return 'Pipeline Status';
    }
  }, []);

  const resetFilters = () => {
    onChange([]);
  };

  const handleOptionsChange = optionsSelected => {
    onChange(optionsSelected);
  };

  const getSelectedOptionsCount = () => selectedOptions?.length && `(${selectedOptions.length})`;

  const customFilterOption = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'source':
        return SourceFilterCustomOption;
      case 'destination':
        return DestinationFilterCustomOption;
      case 'status':
        return StatusFilterCustomOption;
    }
  }, []);
  return (
    <div className={clsx(styles.filterContainer, styles.borderRight)}>
      <div className={styles.filterTitle}>
        <span className='text-secondary'>
          {filterTitle} {showSelectedOptionsCount ? getSelectedOptionsCount() : null}
        </span>
        <HdButton
          className={clsx(styles.clearFilterBtn, 'text-body-3', 'text-capitalize')}
          icon='close'
          variation='flat'
          onClick={() => resetFilters()}
        >
          clear
        </HdButton>
      </div>

      <HdInlineDropdown
        dataId={`${filterTitle}-filters`}
        id={`${filterTitle}-filters`}
        onChangeEventHandler={handleOptionsChange}
        multiple
        options={options}
        valueAccessor='name'
        customOptionWrapperClass={() => styles.customFilterOption}
        CustomOption={customFilterOption}
        selectMode
        selected={selectedOptions}
      />
    </div>
  );
}

export function SourceFilterCustomOption({ option }) {
  const logoInfo = {
    logoURL: option.img,
    darkModeLogoURL: option.darkModeImg,
    primaryColor: SOURCE_TYPES[option.type]?.primaryColor,
    darkModePrimaryColor: SOURCE_TYPES[option.type]?.darkModePrimaryColor
  };
  return (
    <NodeLogoWithText
      option={logoInfo}
      name={option.name}
      size={2}
      logoClassName='mr-3'
      roundedBorders
      zIndex={1305}
    />
  );
}

export function StatusFilterCustomOption({ option }) {
  return <div className='text-body-2'>{option.name}</div>;
}
