// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.selectModeDropdown__P5Da {\n  opacity: 0;\n  height: 0;\n}\n\n.inlineDropdownPaper_JWYGH {\n  margin-bottom: 0;\n  border: 0 !important;\n  border-radius: 0 !important;\n  box-shadow: none !important;\n  width: 100%;\n}\n\n.inlineDropdownInput_QcA0z {\n  border-bottom: 1px solid var(var(--divider-color)) !important;\n  width: 100%;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectModeDropdown": "selectModeDropdown__P5Da",
	"inlineDropdownPaper": "inlineDropdownPaper_JWYGH",
	"inlineDropdownInput": "inlineDropdownInput_QcA0z"
};
export default ___CSS_LOADER_EXPORT___;
