import { useEffect, useRef, useState } from 'react';
import useDontMountAtFirst from '../../hooks/useDontMountAtFirst';

export function useDocsLoader({
  docLink,
  sendMessageToIframe,
  onIframeLoadError,
  onIframeLoadSuccess,
  loadMessage,
  loadedMessage
}) {
  const pollTimeoutRef = useRef(null);
  const isIframeLoadingRef = useRef(true);
  const [ error, setError ] = useState(false);
  const [ isIframeLoading, setIsIframeLoading ] = useState(true);

  useDontMountAtFirst(() => {
    setIsIframeLoading(true);
  }, [docLink]);

  useEffect(() => {
    isIframeLoadingRef.current = isIframeLoading;
  }, [ isIframeLoading ]);

  const onIframeLoadEvent = () => {
    sendMessageToIframe(loadMessage, false);

    if (pollTimeoutRef.current) {
      clearTimeout(pollTimeoutRef.current);
    }

    pollIframeLoadStatus();
  };

  const pollIframeLoadStatus = () => {
    let itr = 0;

    pollTimeoutRef.current = setInterval(() => {
      if (isIframeLoadingRef.current === false) {
        clearTimeout(pollTimeoutRef.current);
        return;
      }

      if (itr === 6) {
        setIsIframeLoading(false);
        setError(true);
        onIframeLoadError();

        clearTimeout(pollTimeoutRef.current);
        return;
      }

      itr++;
    }, 5000);
  };

  const onMessageFromIframe = (e) => {
    if (e.event === loadedMessage) {
      setIsIframeLoading(false);
      onIframeLoadSuccess();
    }
  };

  const onIframeLoadErrorEvent = () => {
    setError(true);
  };

  return {
    error,
    isIframeLoading,
    isIframeLoadingRef,
    onIframeLoadEvent,
    onIframeLoadErrorEvent,
    onMessageFromIframe
  };
}
