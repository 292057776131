import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Field, Formik, FormikProps } from 'formik';
import {
  DestinationCompactData,
  DestinationRecommendationsData
} from '../../../app/destination/models';
import { DESTINATION_TYPES } from '../../../app/nodes/destination-type/model';
import {
  TRACKER_DESTINATION_RECOMMENDATIONS_SELECTED_RECOMMENDATIONS,
  TRACKER_DESTINATION_RECOMMENDATIONS_SELECTED_RECOMMENDATIONS_CONFIRM
} from '../../../app/pipeline/tracking';
import DestinationsAPI from '../../containers/destination/DestinationsAPI';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import useToaster from '../../hooks/useToaster';
import { getDataIdGenerator } from '../../utils/generateDataId';
import useConfirmV2 from '../Dialog/ConfirmDialog/ConfirmDialogV2/useConfirmV2';
import { HdFormikSingleLevelSelector } from '../FormikElements';
import { NodeLogo } from '../NodeLogo';
import RoundedIcon from '../RoundedIcon';
import { DefaultSingleLevelSelectorRef } from '../SingleLevelSelector/model';
import { HdButton, HdDocLink, HdIcon, HdPane, HdTooltip } from '../UIElements';
import FullScreenModal from '../UIElements/FullScreenModal';
import HdFormControl from '../UIElements/HdFormControl';
import {
  DESTINATION_OPTIMISATION_DIALOG_TABLES_SEARCH_KEY,
  DESTINATION_OPTIMIZATION_CONFIRM_DIALOG_OPTIONS
} from './constants';
import { getRecommendedTables, initialValues, validationSchema } from './helper';
import styles from './styles.module.scss';

export const dataIdGenerator = getDataIdGenerator('destination-optimisation-dialog');

interface DestinationOptimisationDialogInnerProps {
  onClose: Function;
  destination: DestinationCompactData;
  recommendationsConfig: DestinationRecommendationsData;
}

export interface DestinationOptimisationDialogProps
  extends DestinationOptimisationDialogInnerProps {
  showModal: boolean;
}

export function DestinationOptimisationDialog({
  showModal,
  ...props
}: DestinationOptimisationDialogProps) {
  return (
    <FullScreenModal open={showModal} className={styles.dialog}>
      <DestinationOptimisationDialogInner {...props} />
    </FullScreenModal>
  );
}

export function DestinationOptimisationDialogInner({
  onClose,
  destination,
  recommendationsConfig
}: DestinationOptimisationDialogInnerProps) {
  const [isUpdated, setIsUpdated] = useState(false);
  const [allUpdated, setAllUpdated] = useState(!recommendationsConfig?.hasPendingRecommendations);
  const [searchTerm, setSearchTerm] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef<FormikProps<any>>();
  const selectorRef = useRef<DefaultSingleLevelSelectorRef>();

  const { eventTrack } = useAnalyticsTracker();
  const { confirmV2 } = useConfirmV2();
  const toasterService = useToaster();

  const closeDialog = () => {
    onClose({
      isUpdated,
      allUpdated
    });
  };

  const _prepareRawDataForSubmission = selectedIDs => {
    const tables = selectedIDs.entities.map(item => item.key);

    const data: any = {
      table_selector: {
        all_select: selectedIDs.selected,
        entities: tables
      },
      table_recommendation: 'ENABLE_APPEND_ONLY'
    };

    if (searchTerm && searchTerm !== '') {
      data[DESTINATION_OPTIMISATION_DIALOG_TABLES_SEARCH_KEY] = searchTerm;
    }

    return data;
  };

  const handleSubmit = values => {
    const selectedIDs = values.selectedTables;

    setSubmitting(true);

    eventTrack({
      action: TRACKER_DESTINATION_RECOMMENDATIONS_SELECTED_RECOMMENDATIONS,
      properties: {
        mode: 'enable-only',
        totalTables: selectedIDs.size || null,
        selectedTables: selectedIDs.entities.length
      }
    });

    const data = _prepareRawDataForSubmission(selectedIDs);

    const dialogOptions = DESTINATION_OPTIMIZATION_CONFIRM_DIALOG_OPTIONS;

    confirmV2({
      title: dialogOptions.title,
      body: dialogOptions.body,
      positiveButtonText: dialogOptions.positiveBtnText,
      negativeButtonText: dialogOptions.negativeBtnText,
      iconName: dialogOptions.iconName,
      iconContainerClass: dialogOptions.iconContainerClass
    }).then(proceed => {
      if (!proceed) {
        setSubmitting(false);
        return;
      }

      eventTrack({
        action: TRACKER_DESTINATION_RECOMMENDATIONS_SELECTED_RECOMMENDATIONS_CONFIRM,
        properties: {
          mode: 'append-only',
          totalTables: selectedIDs.size || null,
          selectedTables: selectedIDs.entities.length
        }
      });

      // eslint-disable-next-line consistent-return
      return DestinationsAPI.putAppendOnlyRecommendations(destination.destinationId, data)
        .then(() => DestinationsAPI.hasDestinationRecommendations(destination.destinationId))
        .then(res => {
          toasterService.pop('success', undefined, 'Successfully updated!');

          setIsUpdated(true);
          formikRef.current.setTouched({});

          if (!res.data.ENABLE_APPEND_ONLY.any_tables_with_pending_recommendations) {
            setAllUpdated(true);
          } else {
            selectorRef.current.refresh();
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  return (
    <div className='scrollable-content' data-id={dataIdGenerator('')}>
      <HdButton
        className={styles.hide}
        dataId={dataIdGenerator('go-back')}
        type='button'
        variation='flat'
        icon='back'
        palette='secondary'
        onClick={closeDialog}
      >
        Go Back
      </HdButton>
      <div className='body-container surface-on-surface mt-8'>
        <div className='header-container'>
          <div className='center-flex-col'>
            <div
              className='position-relative center-flex-col'
              data-id={dataIdGenerator('node-logo')}
            >
              <NodeLogo
                size={9}
                logoURL={destination.destTypeLogoUrl}
                darkModeLogoURL={destination.destTypeDarkModeLogoUrl}
                primaryColor={DESTINATION_TYPES[destination.destType].primaryColor}
                darkModePrimaryColor={DESTINATION_TYPES[destination.destType].darkModePrimaryColor}
              />
              <HdIcon name='settings' className={clsx('text-info', styles.destGearIcon)} />
            </div>
            <div className='text-primary text-bold mt-3'>{destination.destName}</div>
          </div>
          <div className='header mt-4'>Recommended Destination Settings</div>
          <div className='subheader'>
            Recommendations are generated using your data loading pattern to save on Destination
            costs
          </div>
        </div>
        <div className={clsx('box', styles.objectsSelectionContainer)}>
          <div className='center-flex-row'>
            <RoundedIcon
              data-id={dataIdGenerator('rounded-icon')}
              iconName={allUpdated ? 'checked-tick' : 'warning'}
              containerBg={allUpdated ? 'success' : 'warning'}
              containerSize={5}
              className='mr-3'
            />
            <h4 className='text-medium'>Enable Append-only mode on select tables</h4>
          </div>
          {!allUpdated ? (
            <>
              <HdPane
                className='mt-5'
                dataId={dataIdGenerator('')}
                variant='info-faded'
                icon='info'
                disableHide
              >
                <ul className={clsx(styles.warningList)}>
                  <li>
                    Removing duplicates requires a full table scan, and running it frequently
                    increases the Destination cost. Enable <b>Append-only</b> mode instead for
                    tables with no updates, to reduce costs.
                  </li>
                  <li>
                    As Hevo will not run deduplication on these tables, there is a slight chance of
                    having duplicates.
                  </li>
                  <li>
                    You can modify this setting anytime from the Load status Page.{' '}
                    <HdDocLink
                      dataId={dataIdGenerator('')}
                      docLink='/data-loading/optimizing-data-load-warehouse/'
                    />
                  </li>
                </ul>
              </HdPane>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                innerRef={formikRef}
              >
                {formikProps => (
                  <form
                    noValidate
                    onSubmit={formikProps.handleSubmit}
                    data-id={dataIdGenerator('form')}
                  >
                    <HdFormControl>
                      <Field
                        className='mt-5'
                        name='selectedTables'
                        selectableItemName='Tables'
                        getList={getRecommendedTables}
                        showSearchBar
                        requestParams={destination.destinationId}
                        component={HdFormikSingleLevelSelector}
                        onSearchChange={setSearchTerm}
                        selectorRef={selectorRef}
                        renderListItem={props => <span className='mr-2'>{props?.item.id}</span>}
                      />
                    </HdFormControl>
                    <div className='mt-4 d-flex justify-end'>
                      <HdTooltip
                        title='Select at-least one table to enable Append-only mode'
                        disabled={formikProps.isValid}
                      >
                        <span>
                          <HdButton
                            dataId={dataIdGenerator('submit')}
                            type='submit'
                            showProgress={submitting}
                            disabled={!formikProps.isValid || submitting}
                            className={!formikProps.isValid ? 'disabled-button' : ''}
                          >
                            Enable Append-Only Mode
                          </HdButton>
                        </span>
                      </HdTooltip>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          ) : null}
        </div>
        <div className='mt-7 text-center'>
          <HdButton
            dataId={dataIdGenerator('close')}
            onClick={closeDialog}
            palette='info-secondary-faded'
          >
            Go To Dashboard
          </HdButton>
        </div>
      </div>
    </div>
  );
}
