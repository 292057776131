import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MultiRegionService } from './multi-region.service';


@Injectable()
export class MultiRegionResolver implements Resolve<any> {
  constructor(
    private _multiRegionService: MultiRegionService
  ) {
  }

  resolve(): Observable<any> {
    return this._multiRegionService.getMultiRegions(false).pipe(
      map((res: any) => res.data),
      catchError((err: any) => {
        return of(null);
      })
    );
  }
}
