export enum ReportTypeEnum {
  preBuiltReport = 'PRE_BUILT_REPORT',
  customReport = 'CUSTOM_REPORT'
}

export enum ReportModalEnum {
  EDIT = 'EDIT',
  ADD = 'ADD'
}

export const LOGICAL_OPERATORS: any[] = [
  {
    name: 'Or',
    value: 'OR'
  },
  {
    name: 'And',
    value: 'AND'
  }
];

export const INCLUDE_EXCLUDE_OPTIONS: any[] = [
  {
    name: 'Include',
    value: false
  },
  {
    name: 'Exclude',
    value: true
  }
];

export const COMPARISON_OPERATORS: any[] = [
  {
    name: 'Equal',
    value: 'EQUAL'
  },
  {
    name: 'Less Than',
    value: 'LESS_THAN'
  },
  {
    name: 'Greater Than',
    value: 'GREATER_THAN'
  },
  {
    name: 'Is Missing',
    value: 'IS_MISSING'
  }
];

export const MATCH_OPERATORS: any[] = [
  {
    name: 'Regexp',
    value: 'REGEXP'
  },
  {
    name: 'Begins With',
    value: 'BEGINS_WITH'
  },
  {
    name: 'Ends With',
    value: 'ENDS_WITH'
  },
  {
    name: 'Partial',
    value: 'PARTIAL'
  },
  {
    name: 'Exact',
    value: 'EXACT'
  },
  {
    name: 'Numeric Equal',
    value: 'NUMERIC_EQUAL'
  },
  {
    name: 'Numeric Greater Than',
    value: 'NUMERIC_GREATER_THAN'
  },
  {
    name: 'Numeric Less Than',
    value: 'NUMERIC_LESS_THAN'
  },
  {
    name: 'In List',
    value: 'IN_LIST'
  }
];

export const getFilterObj = unit => ({
  caseSensitive: false,
  expression: '',
  unit: null,
  includeExclude: INCLUDE_EXCLUDE_OPTIONS[0],
  operator: unit === 'metric' ? COMPARISON_OPERATORS[0] : MATCH_OPERATORS[0]
});

export const getDefaultClauseObj = (unit: any) => ({
  operator: LOGICAL_OPERATORS[0],
  filters: [getFilterObj(unit)]
});

export const DEFAULT_HISTORICAL_IMPORT_DAYS = 90;

export interface IAccount {
  id: string;
  kind: string;
  name: string;
  webProperties: IProperty[];
}

export interface IProfile {
  id: string;
  kind: string;
  name: string;
  type: string;
}

export interface IClauseState {
  operator: any;
  filters: {
    unit?: any;
    includeExclude: any;
    operator: any;
    caseSensitive?: boolean;
    expression?: string;
  }[];
}

export interface IProperty {
  id: string;
  kind: string;
  name: string;
  level: string;
  profiles: IProfile[];
  internalWebPropertyId: string;
}

export interface ReportState {
  reportName?: string;
  accountId?: IAccount;
  propertyId?: IProperty;
  viewId?: IProfile;
  preBuiltReport?: string;
  reportType?: string;
  metrics?: any[];
  dimensions?: any[];
  dimensionFilterClauses?: IClauseState[];
  metricFilterClauses?: IClauseState[];
}

export interface IFormikState {
  oauthTokenId: number;
  reports: ReportState[];
}
