import React, { useEffect } from 'react';
import styles from './styles.module.scss';

export default function HdFormSubmissionOverlay({ anchorRef }) {
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    anchorRef.current.style.position = 'relative';
  }, []);

  return <div className={styles.formOverlay} data-id='form-submission-overlay' />;
}
