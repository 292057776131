import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import { HdFormikDropDown } from '../../../../../components/FormikElements';
import {
  HdButton,
  HdDocLink,
  HdLink,
  HdPane,
  HdSwitch,
  HdTooltip
} from '../../../../../components/UIElements';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';
import {
  AUTH_FORM_FIELD_NAME,
  BigqueryResourceType,
  HEVO_GCS_BUCKET_OPTION,
  ResourcePermissionState
} from './model';
import styles from './styles.module.scss';
import { BigqueryDestinationTrackingActions } from './tracking';
import { groupResourceListOnLocationInReactFormat } from './utils';

interface ResourceTabProps {
  dataId: string;
  label: string;
  defaultResourceList: any[];
  checkIfUserHasCreatePermission: (stateData: any, resource: string) => Promise<boolean>;
  fetchResourceList: (stateData: any, resource: string) => Promise<any[]>;
  resource: string;
  defaultLocation: string;
  autoCreateResourceName?: (projectId: string) => string;
}

/**
 *
 * Bucket list is modifies according to the selected dataset location
 * Logic -> only buckets which belong to the same region as the Dataset are shown ||
 * the auto created buckets like the hevo's bucket
 */
export function ResourceTab({
  dataId,
  label,
  checkIfUserHasCreatePermission,
  autoCreateResourceName,
  resource,
  defaultLocation,
  defaultResourceList,
  fetchResourceList
}: ResourceTabProps) {
  const { values, setFieldValue, setValues } = useFormikContext<{
    projectId: { id: string };
    authorisedAccount: string;
    dataset: { id: string; apiLocation: string };
    bucket: { id: string; internalBucket?: boolean; apiLocation: string; isAutoCreated: boolean };
  }>();

  const [toggleState, setToggleState] = useState(true);
  const [permissionState, setPermissionState] = useState(
    ResourcePermissionState.CHECKING_PERMISSION
  );
  const [showLoading, setLoading] = useState(false);
  const [resourceList, setResourceList] = useState(defaultResourceList);
  const [resourceLocationFilter, setResourceLocationFilter] = useState('');
  const [autoCreatedResourceName, setAutoCreatedResourceName] = useState('');
  const selectedResourceValue = values[resource]?.name;
  const userName = values.authorisedAccount || values?.[AUTH_FORM_FIELD_NAME]?.email;

  const { projectId } = values;

  const { eventTrack } = useAnalyticsTracker();
  useEffect(() => {
    if (resource === BigqueryResourceType.BUCKET) {
      setResourceLocationFilter(values?.dataset?.apiLocation?.toUpperCase());

      if (
        values?.dataset?.apiLocation?.toUpperCase() !==
          values?.bucket?.apiLocation?.toUpperCase() &&
        !values?.bucket?.isAutoCreated
      ) {
        setFieldValue(resource, null);
      }
    }
  }, [values?.dataset]);

  useEffect(() => {
    let cancelSubscription = false;

    if (projectId) {
      setPermissionState(ResourcePermissionState.CHECKING_PERMISSION);
      if (values[resource]) {
        setToggleState(false);
      } else {
        setToggleState(true);
      }

      Promise.allSettled([
        fetchResourceList(values, resource),
        checkIfUserHasCreatePermission(values, resource)
      ]).then(([resourceListResponse, hasPermissionResponse]) => {
        if (cancelSubscription) {
          return;
        }

        let loadedResourceList = [];

        if (resourceListResponse.status === 'fulfilled') {
          loadedResourceList = groupResourceListOnLocationInReactFormat(resourceListResponse.value);
        }

        let hasPermission = ResourcePermissionState.CANT_CREATE_RESOURCE;

        if (hasPermissionResponse.status === 'fulfilled') {
          const resourceLookup = {};
          resourceList.forEach(ele => {
            resourceLookup[ele.id] = true;
          });

          let resourceName = autoCreateResourceName(projectId.id);
          while (resourceLookup[resourceName]) {
            resourceName = autoCreateResourceName(projectId.id);
          }

          setAutoCreatedResourceName(resourceName);
          if (!values[resource]) {
            setFieldValue(resource, {
              name: resourceName,
              id: resourceName,
              existing: false,
              location: defaultLocation,
              apiLocation: defaultLocation,
              isAutoCreated: true
            });
          }
          hasPermission = ResourcePermissionState.CAN_CREATE_RESOURCE;
        }

        eventTrack({
          action:
            resource === BigqueryResourceType.DATASET
              ? BigqueryDestinationTrackingActions.DATASET_PERMISSION_FETCHED
              : BigqueryDestinationTrackingActions.BUCKET_PERMISSION_FETCHED,
          properties: {
            projectId: projectId?.id,
            hasPermission: true
          }
        });

        if (
          resource === BigqueryResourceType.BUCKET &&
          hasPermission === ResourcePermissionState.CANT_CREATE_RESOURCE
        ) {
          setValues(currentState => ({
            ...currentState,
            [resource]: HEVO_GCS_BUCKET_OPTION
          }));
          loadedResourceList.push(HEVO_GCS_BUCKET_OPTION);
        }

        setPermissionState(hasPermission);
        setResourceList(loadedResourceList);
      });
    }

    return () => {
      cancelSubscription = true;
    };
  }, [projectId?.id]);

  const dataIdGenerator = getDataIdGenerator(dataId);

  const filteredResourceList = useMemo(() => {
    if (resource === BigqueryResourceType.DATASET) {
      return resourceList;
    }

    const filteredList = resourceList.filter(
      ele => !resourceLocationFilter || ele.apiLocation?.toUpperCase() === resourceLocationFilter
    );

    if (filteredList.length === 0) {
      filteredList.push(HEVO_GCS_BUCKET_OPTION);
    }

    return filteredList;
  }, [resourceLocationFilter, resourceList]);

  const handleRefreshResourceList = () => {
    if (projectId) {
      setLoading(true);
      setResourceList([]);

      eventTrack({
        action:
          resource === BigqueryResourceType.BUCKET
            ? BigqueryDestinationTrackingActions.BUCKET_REFRESH_LIST_CLICK
            : BigqueryDestinationTrackingActions.DATASET_REFRESH_LIST_CLICK,
        properties: {
          projectId
        }
      });

      fetchResourceList(values, resource)
        .then(res => {
          const fetchedResourceList = groupResourceListOnLocationInReactFormat(res);
          setResourceList(fetchedResourceList);

          if (!fetchedResourceList.length) {
            eventTrack({
              action:
                resource === BigqueryResourceType.DATASET
                  ? BigqueryDestinationTrackingActions.DATASET_LIST_EMPTY
                  : BigqueryDestinationTrackingActions.BUCKET_LIST_EMPTY,
              properties: {
                projectId: projectId?.id
              }
            });
          }
          setLoading(false);
        })
        .catch(_ => {
          setResourceList([]);
          setLoading(false);
        });
    }
  };

  const DropdownRefreshAdornment = useCallback(
    () => (
      <HdLink
        tag='a'
        className={`${styles.refreshAdornment} text-primary `}
        onMouseDown={() => {
          handleRefreshResourceList();
        }}
        direction='right'
        icon='refresh'
        dataId={dataIdGenerator('dropdown-refresh')}
      >
        Refresh
      </HdLink>
    ),
    [projectId?.id]
  );

  const checkPermissionClickHandler = () => {
    eventTrack({
      action:
        resource === BigqueryResourceType.BUCKET
          ? BigqueryDestinationTrackingActions.BUCKET_CHECK_PERMISSION_CLICK
          : BigqueryDestinationTrackingActions.DATASET_CHECK_PERMISSION_CLICK,
      properties: {
        projectId: projectId?.id
      }
    });

    setPermissionState(ResourcePermissionState.CHECKING_PERMISSION);
    if (projectId) {
      checkIfUserHasCreatePermission(values, resource)
        .then(() => setPermissionState(ResourcePermissionState.CAN_CREATE_RESOURCE))
        .catch(() => setPermissionState(ResourcePermissionState.CANT_CREATE_RESOURCE));
    }
  };

  const useHevoGCSBucket =
    resource === BigqueryResourceType.BUCKET && values[resource]?.internalBucket;

  const paneMessage: () => any = useCallback(() => {
    if (!projectId) {
      return null;
    }

    if (permissionState === ResourcePermissionState.CHECKING_PERMISSION && !selectedResourceValue) {
      return (
        <HdPane iconClasses={styles.animatedLoadingIcon} icon='testing' variant='warning-faded'>
          <span className='text-medium'>
            We are checking if user <i>{userName}</i> has permission to create a {label}. Please
            wait for a few seconds.
          </span>
        </HdPane>
      );
    }

    if (useHevoGCSBucket) {
      return null;
    }

    if (values[resource]) {
      if (!values[resource]?.existing) {
        return (
          <HdPane icon='checked-tick' variant='success-faded'>
            <div className='text-medium'>
              Hevo will create a new {label} with name <i>{selectedResourceValue}</i>{' '}
            </div>
          </HdPane>
        );
      }

      return (
        <HdPane icon='checked-tick' variant='success-faded'>
          <div className='text-medium'>
            Hevo will use {label} with name <i>{selectedResourceValue}</i> for your Bigquery.
          </div>
        </HdPane>
      );
    }

    if (
      permissionState === ResourcePermissionState.CANT_CREATE_RESOURCE &&
      !selectedResourceValue
    ) {
      return (
        <HdPane icon='warning' variant='warning-faded'>
          <div className='text-medium'>
            Insufficient permissions: <i>{userName}</i> does not have permission to create a {label}
            .{' '}
            <HdDocLink
              label='Learn'
              docLink='destination_settings'
              section='/destinations/data-warehouses/google-bigquery/#using-google-account-authentication'
              dataId={dataIdGenerator('insufficient-permissions')}
            />{' '}
            about granting permissions.
          </div>

          <HdButton
            className={styles.checkAgainButton}
            icon='restart'
            onClick={checkPermissionClickHandler}
            variation='faded'
            palette='secondary'
            dataId={dataIdGenerator('check-permission')}
          >
            Check Again
          </HdButton>
        </HdPane>
      );
    }

    return null;
  }, [
    projectId,
    permissionState,
    values.authorisedAccount,
    label,
    userName,
    values,
    toggleState,
    selectedResourceValue,
    useHevoGCSBucket,
    autoCreatedResourceName
  ]);

  const paneOut = paneMessage();
  const isToggleEnabled =
    !!projectId && permissionState === ResourcePermissionState.CAN_CREATE_RESOURCE;
  let isToggleChecked = toggleState;

  if (!projectId) {
    isToggleChecked = true;
  } else {
    if (permissionState === ResourcePermissionState.CHECKING_PERMISSION && !selectedResourceValue) {
      isToggleChecked = true;
    }

    if (permissionState === ResourcePermissionState.CANT_CREATE_RESOURCE) {
      isToggleChecked = false;
    }
  }

  const handleToggleChange = e => {
    const { checked } = e.target;
    setToggleState(checked);

    eventTrack({
      action:
        resource === BigqueryResourceType.BUCKET
          ? BigqueryDestinationTrackingActions.BUCKET_AUTO_CREATE_TOGGLE
          : BigqueryDestinationTrackingActions.DATASET_AUTO_CREATE_TOGGLE,
      properties: {
        projectId: projectId?.id,
        autoCreate: checked
      }
    });
    if (checked) {
      setFieldValue(resource, {
        name: autoCreatedResourceName,
        id: autoCreatedResourceName,
        existing: false,
        location: defaultLocation,
        apiLocation: defaultLocation,
        isAutoCreated: true
      });
    } else {
      setFieldValue(resource, null);
    }
  };

  const handleCreateResource = async (resourceName: string) => {
    eventTrack({
      action:
        resource === BigqueryResourceType.BUCKET
          ? BigqueryDestinationTrackingActions.BUCKET_CREATE_NEW_CLICK
          : BigqueryDestinationTrackingActions.DATASET_CREATE_NEW_CLICK,
      properties: {
        projectId: projectId?.id
      }
    });
    setFieldValue(resource, { name: resourceName, id: resourceName, existing: false });
  };

  return (
    <div className='p-5 w-100'>
      <Grid container>
        <Grid item sm={6} md={6}>
          <div className='w-100'>
            <HdFormControl className={isToggleChecked && !paneOut ? 'mb-0' : ''}>
              <HdTooltip disabled={!!projectId} title='Select project to enable'>
                <div className={`center-flex-row text-medium ${!isToggleChecked && 'mt-5'}`}>
                  <HdSwitch
                    id={resource}
                    name='toggle dataset'
                    disabled={!isToggleEnabled}
                    checked={!!isToggleChecked}
                    onChange={handleToggleChange}
                    dataId={dataIdGenerator('auto-create')}
                  />
                  <div className='ml-4'> Automatically create a {label} </div>
                </div>
              </HdTooltip>
            </HdFormControl>
          </div>
        </Grid>

        <Grid item sm={6} md={6}>
          {!isToggleChecked && (
            <HdFormControl>
              <Field
                name={resource}
                options={filteredResourceList}
                group
                createOnClickAway
                helperText={`The ${label} you want to use with Destination`}
                creatable={permissionState === ResourcePermissionState.CAN_CREATE_RESOURCE}
                showLoading={showLoading}
                creatableLabel={label}
                creatableCallback={handleCreateResource}
                TopAdornment={DropdownRefreshAdornment}
                component={HdFormikDropDown}
                label={label}
              />
            </HdFormControl>
          )}
        </Grid>
      </Grid>
      {paneOut}
    </div>
  );
}
