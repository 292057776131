// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.nodeRelationContainer_WXpUi {\n  display: flex;\n  align-items: center;\n  color: var(--text-default-color);\n}\n\n.nodeLogoText_beEWB {\n  width: 100px;\n}\n\n.pipelineConnectionIndicator_eK6CD {\n  font-size: 18px;\n  margin: 0 16px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeRelationContainer": "nodeRelationContainer_WXpUi",
	"nodeLogoText": "nodeLogoText_beEWB",
	"pipelineConnectionIndicator": "pipelineConnectionIndicator_eK6CD"
};
export default ___CSS_LOADER_EXPORT___;
