export enum RestAPIStep {
  SOURCE_SETUP,
  DATA_ROOT,
  PAGINATION
}

export enum StepBoxStatus {
  INCOMPLETE,
  PROGRESS,
  COMPLETE
}

export interface TokenFormData {
  tokenName: string;
  clientId: string;
  clientSecret: string;
  callbackUrl: string;
  authUrl: string;
  tokenUrl: string;
  scopes: string;
}

export interface QueryParamType {
  name: string;
  value: string;
}

export interface QueryParam {
  type: QueryParamType;
  key: string;
  value: string;
  format?: string;
  offsetUnit?: OffsetUnit;
  offsetValue?: string;
}

export enum PaginationTypesEnum {
  NO_PAGINATION = 'no_pagination',
  SESSION = 'session',
  PAGE_NO = 'page_no',
  LIMIT_OFFSET = 'limit_offset'
}

export enum ResponseType {
  RAW,
  PARSED
}

export enum PostBodyTypeEnum {
  'JSON' = 'json',
  'FORM_DATA' = 'form_data'
}

export enum RestApiAdvancedOptionTabs {
  AUTH,
  HEADERS,
  QUERY_PARAMS,
  PAGINATION
}

export enum AuthType {
  NO_AUTH = 'NO_AUTH',
  BASIC_AUTH = 'BASIC_AUTH',
  OAUTH2 = 'OAUTH2'
}

export interface OffsetUnit {
  name: string;
  value: number;
}

export interface IRestApiAdvanceOption {
  type: string;
  value: RestApiAdvancedOptionTabs;
}
