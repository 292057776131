import { REGION_NAMES } from '../../../../app/nodes/constants';

export const CLOUDWATCH_REGIONS: any[] = [
  {
    name: REGION_NAMES['us-east-2'],
    value: 'US_EAST_2'
  },
  {
    name: REGION_NAMES['us-east-1'],
    value: 'US_EAST_1'
  },
  {
    name: REGION_NAMES['us-west-1'],
    value: 'US_WEST_1'
  },
  {
    name: REGION_NAMES['us-west-2'],
    value: 'US_WEST_2'
  },
  {
    name: REGION_NAMES['ca-central-1'],
    value: 'CA_CENTRAL_1'
  },
  {
    name: REGION_NAMES['ap-south-1'],
    value: 'AP_SOUTH_1'
  },
  {
    name: REGION_NAMES['ap-northeast-2'],
    value: 'AP_NORTHEAST_2'
  },
  {
    name: REGION_NAMES['ap-northeast-3'],
    value: 'AP_NORTHEAST_3'
  },
  {
    name: REGION_NAMES['ap-southeast-1'],
    value: 'AP_SOUTHEAST_1'
  },
  {
    name: REGION_NAMES['ap-southeast-2'],
    value: 'AP_SOUTHEAST_2'
  },
  {
    name: REGION_NAMES['ap-northeast-1'],
    value: 'AP_NORTHEAST_1'
  },
  {
    name: REGION_NAMES['cn-north-1'],
    value: 'CN_NORTH_1'
  },
  {
    name: REGION_NAMES['cn-northwest-1'],
    value: 'CN_NORTHWEST_1'
  },
  {
    name: REGION_NAMES['eu-central-1'],
    value: 'EU_CENTRAL_1'
  },
  {
    name: REGION_NAMES['eu-west-1'],
    value: 'EU_WEST_1'
  },
  {
    name: REGION_NAMES['eu-west-2'],
    value: 'EU_WEST_2'
  },
  {
    name: REGION_NAMES['eu-west-3'],
    value: 'EU_WEST_3'
  },
  {
    name: REGION_NAMES['sa-east-1'],
    value: 'SA_EAST_1'
  }
];
