import { ListActionType } from './list-actions';
import { Params } from '@angular/router';
import { PaginationStrategy } from './pagination-strategy';
import { EntityUIState } from '../../core/models/entitiy-ui-state';

export interface OffsetPaginationData {
  totalCount: number;
  page: number;
  limit: number;
}

export class OffsetPaginationStrategy extends PaginationStrategy<OffsetPaginationData> {
  isLastPage = false;

  constructor(private _pageSize: number) {
    super();
  }

  applyAction(action: ListActionType, paginationData: OffsetPaginationData, data?: any): OffsetPaginationData {
    let newData: OffsetPaginationData = {
      page: paginationData.page,
      totalCount: paginationData.totalCount,
      limit: this._pageSize
    };

    switch (action) {
      case ListActionType.NEXT:
        newData.page = newData.page + 1;
        break;
      case ListActionType.PREVIOUS:
        newData.page = newData.page -= 1;
        break;
      case ListActionType.RESPONSE:
        newData.totalCount = data.count;
        this.isLastPage = (newData.page + 1) * this._pageSize >= newData.totalCount;
        break;
      case ListActionType.REFRESH:
        if (data) {
          newData = this.getDefaultPaginationData();
        }
        break;
      case ListActionType.UPDATE_PAGE_SIZE:
        if (data) {
          this._pageSize = data.pageSize;

          newData = {
            ...newData,
            limit: data.pageSize
          };
        }
    }

    return newData;
  }

  getDataFromQueryParams(queryParamsPrefix: string, queryParams: Params, data: OffsetPaginationData): OffsetPaginationData {
    const pageNumber = queryParams[`${ queryParamsPrefix }_page`];
    return {
      page: pageNumber && parseInt(pageNumber) >= 0 ? parseInt(pageNumber, 10) : 0,
      limit: this._pageSize,
      totalCount: data.totalCount
    };
  }

  getQueryParamsFromData(queryParamsPrefix: string, data: OffsetPaginationData) {
    return {
      [`${ queryParamsPrefix }_page`]: data.page
    };
  }

  getDefaultPaginationData(paginationData?: OffsetPaginationData): OffsetPaginationData {
    return {
      page: 0,
      limit: this._pageSize,
      totalCount: paginationData ? paginationData.totalCount : undefined
    };
  }

  getNetworkParams(paginationData: OffsetPaginationData) {
    return {
      page: paginationData.page,
      limit: paginationData.limit
    };
  }

  isFirstPage(paginationData: OffsetPaginationData) {
    return !paginationData.page;
  }

  getListState(filtersLength: number, total: number) {
    if (filtersLength === 0 && total === 0) {
      return EntityUIState.NEW;
    }

    if (total === 0) {
      return EntityUIState.EMPTY;
    }

    return EntityUIState.IDLE;
  }
}
