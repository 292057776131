import React from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleAuthRequestType } from '../models';
import { createHttpParams } from '../../../legacy-utils/request';
import { HdButton } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AuthAPI from '../AuthAPI';

interface GoogleLoginButtonProps {
  code?: string;
  requestType: GoogleAuthRequestType;
  onClick?: Function;
}

export function GoogleLoginButton({
  code,
  requestType,
  onClick
}: GoogleLoginButtonProps) {
  const { search } = useLocation();

  const queryParams = createHttpParams({
    next: new URLSearchParams(search).get('next'),
    error_path: window.location.href.replace(/^.*\/\/[^\/]+/, ''),
    type: requestType,
    code
  });

  const oAuthURL = AuthAPI.getGoogleOAuthURL(queryParams);

  const dataIdGenerator = getDataIdGenerator('google-login-btn');

  return (
    <HdButton
      dataId={dataIdGenerator('')}
      tag='a'
      variation='outline'
      className='w-100'
      size='lg'
      iconSrc='https://res.cloudinary.com/hevo/image/upload/v1661186331/dashboard/google-icon_uyeuow.png'
      onClick={onClick}
      href={oAuthURL}
    >
      Continue with Google
    </HdButton>
  );
}
