import { Directive, ElementRef, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';

@Directive(
  {
    // tslint:disable-next-line:directive-selector
    selector: '[faNotFound]'
  }
)
export class FaNotFoundDirective {
  constructor(private el: ElementRef) {
  }
}
