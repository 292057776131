import React, { useState, useCallback } from 'react';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { Role } from '../models';
import {
  TRACKER_MEMBERS_PAGE_INVITE_MEMBER_CLICK,
  TRACKER_MEMBERS_PAGE_INVITE_MEMBER_ERROR,
  TRACKER_MEMBERS_PAGE_INVITE_MEMBER_SUCCESS
} from './constants';
import { MembersTrackingActions } from './tracking';
import { HdButton, HdIcon } from '../../../components/UIElements';
import { HdTextField } from '../../../components/UIElements/HdTextField';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import InviteUserPopup from './InviteUsersPopup';
import TeamAPI from '../TeamAPI';
import useTeamSettingsService from '../../../hooks/services/useTeamSettingsService';
import useHasPermission from '../../../hooks/useHasPermission';
import { RbacPermissions } from '../../../../app/core/models/user';
import styles from './styles.module.scss';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export interface InviteSectionProps {
  onInviteSuccess: () => void;
  roles: Role[];
}

const dataIdGenerator = getDataIdGenerator('team-member');

export default function InviteSection({
  onInviteSuccess,
  roles
}: InviteSectionProps) {
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviting, setInviting] = useState(false);
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);

  const { isRBACEnabled } = useTeamSettingsService();
  const { hasPermission } = useHasPermission();
  const analyticsTracker = useAnalyticsTracker();

  const inviteClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_MEMBERS_PAGE_INVITE_MEMBER_CLICK,
      properties: { email: inviteEmail }
    });

    sendInvite();
  };

  const sendInvite = () => {
    setInviting(true);

    TeamAPI.invite(inviteEmail)
      .then(() => {
        setInviting(false);

        analyticsTracker.eventTrack({
          action: TRACKER_MEMBERS_PAGE_INVITE_MEMBER_SUCCESS,
          properties: { email: inviteEmail }
        });

        setInviteEmail('');
        onInviteSuccess();
      })
      .catch((error: any) => {
        analyticsTracker.eventTrack({
          action: TRACKER_MEMBERS_PAGE_INVITE_MEMBER_ERROR,
          properties: { email: inviteEmail, error: getErrorMessageFromObj(error) }
        });

        setInviting(false);
      });
  };

  const inviteUserClickHandler = useCallback(() => {
    setIsInvitePopupOpen(true);

    analyticsTracker.eventTrack({
      action: MembersTrackingActions.INVITE_USER_CTA_CLICK
    });
  }, []);

  const inviteUserPopupCloseHandler = useCallback(() => {
    setIsInvitePopupOpen(false);
  }, []);

  return (
    <div className={styles.inviteInput}>
      {isRBACEnabled && hasPermission(RbacPermissions.TEAM_EDIT) && (
        <HdButton onClick={inviteUserClickHandler} dataId={dataIdGenerator('invite-user')} icon='plus'>
          Invite User
        </HdButton>
      )}

      {!isRBACEnabled && (
        <HdTextField
          label='Email Address'
          placeholder='Email Address'
          variant='outlined'
          dataId='team-member-email'
          size='small'
          InputProps={{
            inputProps: { 'data-id': `member-email` },
            startAdornment: <HdIcon name='email' />,
            endAdornment: (
              <HdButton
                variation='flat'
                disabled={!inviteEmail || inviting}
                onClick={inviteClick}
                showProgress={inviting}
                dataId={dataIdGenerator('invite')}
              >
                Invite
              </HdButton>
            )
          }}
          value={inviteEmail}
          onChange={e => {
            setInviteEmail(e.target.value);
          }}
          autoComplete='off'
        />
      )}

      <InviteUserPopup
        roles={roles}
        open={isInvitePopupOpen}
        onInviteSuccess={onInviteSuccess}
        onCloseHandler={inviteUserPopupCloseHandler}
      />
    </div>
  );
}
