import { _sshService } from '../../../../../../app/core/service/ssh.service';
import { DEFAULT_SCHEMA_NAME } from '../../../../../../app/nodes/constants';
import { DEFAULT_MS_SQL_SCHEMA_NAME } from '../../../../../../app/nodes/nodes-settings/ms-sql-base/constants';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { JobMode, JobModeEnum } from '../../../../../../app/nodes/source-type/job-mode';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import { SQL_CONFIG } from './config';
import { SqlConfigPayload, SqlFormState } from './interfaces';
import { SQL_SOURCE, SqlFormFields } from './models';

export class SqlConfigHelperReact implements SourceTypeConfigHelper {
  constructor(public sourceTypeIdentifier: string, public jobMode: JobMode) {}

  metaDataResolver?: (sourceType: string, authData: any, rawData: any, draftData?: any) => any;

  staticDependenciesResolver?: (sourceType: string) => any;

  getDefaultConfig() {
    const config: any = {
      [SqlFormFields.PORT]: 22,
      [SqlFormFields.SCHEMA_NAME]: DEFAULT_SCHEMA_NAME,
      [SqlFormFields.LOAD_ALL_DB]: this.jobMode?.canProvideMultipleDbNames || undefined,
      [SqlFormFields.LOAD_ALL_SCHEMAS]: this.jobMode?.canShowRedoLogAdvancedSettings || undefined,
      [SqlFormFields.MERGE_TABLES]: this.jobMode?.canMergeTables || undefined
    };

    switch (this.sourceTypeIdentifier) {
      case SQL_SOURCE.MYSQL:
      case SQL_SOURCE.MARIA_DB:
      case SQL_SOURCE.AURORA:
      case SQL_SOURCE.AWS_RDS_MYSQL:
      case SQL_SOURCE.GCP_MYSQL:
      case SQL_SOURCE.AZURE_MYSQL:
      case SQL_SOURCE.ORACLE_MYSQL:
      case SQL_SOURCE.TOKU_DB_MYSQL:
      case SQL_SOURCE.AWS_RDS_MARIA_DB:
      case SQL_SOURCE.AZURE_MARIA_DB:
      case SQL_SOURCE.PRESTASHOP_VIA_MYSQL:
      case SQL_SOURCE.MAGENTO_VIA_MYSQL:
      case SQL_SOURCE.WOOCOMMERCE_VIA_MYSQL:
      case SQL_SOURCE.WORDPRESS_VIA_MYSQL: {
        config.port = 3306;
        break;
      }

      case SQL_SOURCE.REDSHIFT: {
        config.port = 5439;
        break;
      }

      case SQL_SOURCE.ORACLE:
      case SQL_SOURCE.AWS_RDS_ORACLE: {
        config.port = 1521;
        config.schemaName = undefined;
        break;
      }

      case SQL_SOURCE.POSTGRES:
      case SQL_SOURCE.AWS_RDS_POSTGRES:
      case SQL_SOURCE.AWS_AURORA_POSTGRES:
      case SQL_SOURCE.GCP_POSTGRES:
      case SQL_SOURCE.AZURE_POSTGRES:
      case SQL_SOURCE.SF_HEROKU_POSTGRES: {
        config.port = 5432;
        break;
      }

      case SQL_SOURCE.MS_SQL:
      case SQL_SOURCE.AZURE_SQL:
      case SQL_SOURCE.GCP_MS_SQL:
      case SQL_SOURCE.AWS_RDS_MSSQL: {
        config.port = 1433;
        config.schemaName = DEFAULT_MS_SQL_SCHEMA_NAME;
        break;
      }
      default:
        break;
    }

    return config;
  }

  getConfigFromRawData(rawData: SqlConfigPayload) {
    const config: any = {
      [SqlFormFields.HOST]: rawData.db_host,
      [SqlFormFields.USER]: rawData.db_user,
      [SqlFormFields.PORT]: rawData.db_port,
      [SqlFormFields.DATABASE_NAME]: rawData.db_name,
      [SqlFormFields.DATABASE_NAMES]: rawData.db_names || rawData.db_name,
      [SqlFormFields.SCHEMA_NAMES]: rawData.schema_names,
      [SqlFormFields.LOAD_ALL_DB]: !!rawData.load_all_db,
      [SqlFormFields.LOAD_ALL_SCHEMAS]: !!rawData.load_all_schemas,
      [SqlFormFields.MERGE_TABLES]: rawData.merge_tables,
      [SqlFormFields.PASSWORD]: rawData.db_password,
      [SqlFormFields.SSH_IP]: rawData.ssh_host,
      [SqlFormFields.SSH_PORT]: rawData.ssh_port,
      [SqlFormFields.SSH_USER]: rawData.ssh_user,
      [SqlFormFields.SCHEMA_NAME]: rawData.schema_name,
      [SqlFormFields.USE_SSL]: !!rawData.use_ssl,
      [SqlFormFields.USE_SSH]: !!(rawData.ssh_host || rawData.ssh_port || rawData.ssh_user),
      [SqlFormFields.POLL_INTERVAL]:
        rawData?.oracle_long_polling_sleep_millis ||
        SQL_CONFIG.initialValues[SqlFormFields.POLL_INTERVAL],
      [SqlFormFields.QUERY_FETCH_SIZE]:
        rawData.oracle_result_set_fetch_size ||
        SQL_CONFIG.initialValues[SqlFormFields.QUERY_FETCH_SIZE],
      [SqlFormFields.LONG_TRANSACTION_WINDOW]:
        typeof rawData.oracle_log_mining_transaction_window_secs === 'number'
          ? rawData.oracle_log_mining_transaction_window_secs / 60
          : SQL_CONFIG.initialValues[SqlFormFields.LONG_TRANSACTION_WINDOW],
      [SqlFormFields.ONLINE_CATALOG]:
        typeof rawData.oracle_use_dictionary_from_online_catalog !== 'undefined'
          ? !!rawData.oracle_use_dictionary_from_online_catalog
          : SQL_CONFIG.initialValues[SqlFormFields.ONLINE_CATALOG],
      [SqlFormFields.ARCHIVE_LOG_ONLY]:
        typeof rawData.oracle_use_dictionary_from_online_catalog !== 'undefined'
          ? !rawData.oracle_mine_online_redo_logs
          : SQL_CONFIG.initialValues[SqlFormFields.ARCHIVE_LOG_ONLY],
      [SqlFormFields.ORACLE_POLL_TASK_V2_FLAG]: !!rawData?.oracle_poll_task_v2,
      [SqlFormFields.DATABASE_NAME_ERROR]: null,
      [SqlFormFields.SCHEMA_NAME_ERROR]: null
    };

    if (rawData.use_ssl) {
      if ('ssl_ca' in rawData) {
        config[SqlFormFields.SSL_CA] = { content: rawData.ssl_ca };
      }
      if ('ssl_client_key' in rawData) {
        config[SqlFormFields.SSL_CLIENT_KEY] = { content: rawData.ssl_client_key };
      }
      if ('ssl_client_cert' in rawData) {
        config[SqlFormFields.SSL_CLIENT_CERT] = { content: rawData.ssl_client_cert };
      }

      config.sslLoadAllCA = rawData.ssl_load_all_ca || false;
    }

    // we can revert this change when we enable the historical option for table mode for all.
    // this is short term change for safe release behind team settings flag.
    if ('enable_historical_task_table_mode' in rawData) {
      config.enableHistoricalTaskTableMode = rawData.enable_historical_task_table_mode;
    }

    config[SqlFormFields.RENDER_OLD_DB_NAME_FIELD] = !(
      this.jobMode?.canProvideMultipleDbNames &&
      (rawData.load_all_db || rawData.db_names)
    );

    if (rawData.db_name) {
      config[SqlFormFields.DATABASE_NAMES] = rawData.db_name;
    }

    if (this.sourceTypeIdentifier === 'AZURE_POSTGRES') {
      config[SqlFormFields.AZURE_SERVER_FLEXI] = rawData.use_flexible_server_format_in_azure_postgres ? 'true' : 'false';
    }

    if ('use_read_only_intent' in rawData) {
      config[SqlFormFields.APPLICATION_INTENT_READ_ONLY] = rawData.use_read_only_intent;
    }

    return config;
  }

  buildConnectionParams(data: SqlFormState): SqlConfigPayload {
    const params: SqlConfigPayload = {
      db_host: data[SqlFormFields.HOST],
      db_port: data[SqlFormFields.PORT],
      db_user: data[SqlFormFields.USER],
      db_password: data[SqlFormFields.PASSWORD],
      db_name: data[SqlFormFields.DATABASE_NAME],
      use_ssl: false
    };

    const { jobMode } = data;

    const isNewRedoLogAdvancedSettings =
      jobMode.canShowRedoLogAdvancedSettings &&
      (!data.isEditing || data[SqlFormFields.ORACLE_POLL_TASK_V2_FLAG] || data.schemaVersion >= 42);
    const isOldRedoLogAdvancedSettings =
      data.isEditing &&
      jobMode.canShowRedoLogAdvancedSettings &&
      data.schemaVersion < 42 &&
      !data[SqlFormFields.ORACLE_POLL_TASK_V2_FLAG];

    if (jobMode) {
      if (jobMode.canMergeTables) {
        params.merge_tables = !!data[SqlFormFields.MERGE_TABLES];
      }

      if (jobMode.canProvideMultipleDbNames) {
        params.load_all_db = !!data[SqlFormFields.LOAD_ALL_DB];
        params.db_names = params.load_all_db ? undefined : data[SqlFormFields.DATABASE_NAMES];
        delete params.db_name;
      }

      if (isOldRedoLogAdvancedSettings || isNewRedoLogAdvancedSettings) {
        params.load_all_schemas = !!data[SqlFormFields.LOAD_ALL_SCHEMAS];
        params.schema_names = params.load_all_schemas
          ? undefined
          : data[SqlFormFields.SCHEMA_NAMES];
      }

      if (isNewRedoLogAdvancedSettings) {
        params.oracle_long_polling_sleep_millis = Number(data[SqlFormFields.POLL_INTERVAL]);
        params.oracle_result_set_fetch_size = Number(data[SqlFormFields.QUERY_FETCH_SIZE]);
        params.oracle_log_mining_transaction_window_secs = Number(
          data[SqlFormFields.LONG_TRANSACTION_WINDOW] * 60
        );
        params.oracle_use_dictionary_from_online_catalog = data[SqlFormFields.ONLINE_CATALOG];
        params.oracle_mine_online_redo_logs = !data[SqlFormFields.ARCHIVE_LOG_ONLY];
      }

      // we can revert this change when we enable the historical option for table mode for all.
      // this is short term change for safe release behind team settings flag.
      if (jobMode.value === JobModeEnum.TABLE.value) {
        if (data.showImportHistoricalLoadForTableMode && !data.id) {
          // creation flow with flag enabled
          params.enable_historical_task_table_mode = true;
          params.import_historical_data = !!data[SqlFormFields.IMPORT_HISTORICAL_DATA];
        } else if (data.id && data.enableHistoricalTaskTableMode) {
          // edit mode with enable_historical_task_table_mode coming from backend
          params.enable_historical_task_table_mode = data.enableHistoricalTaskTableMode;
          params.import_historical_data = !!data[SqlFormFields.IMPORT_HISTORICAL_DATA];
        } else {
          delete params.enable_historical_task_table_mode;
          params.import_historical_data = false;
        }
      }

      if (
        jobMode.value === JobModeEnum.CHANGE_TRACKING.value &&
        jobMode.virtualValue === JobModeEnum.CHANGE_TRACKING.virtualValue &&
        data.changeTrackingV2Enabled
      ) {
        params.allow_only_change_tracking_tables = true;
      }

      if (jobMode.historicalMode) {
        params.import_historical_data = !!data[SqlFormFields.IMPORT_HISTORICAL_DATA];
      }
    }

    _sshService.buildSSHParams(params, data);

    if (
      SOURCE_TYPES[this.sourceTypeIdentifier].provideSchemaName &&
      (!jobMode || !jobMode.hideSchemaName)
    ) {
      params.schema_name = data[SqlFormFields.SCHEMA_NAME];
    }

    if (data[SqlFormFields.USE_SSL]) {
      params.use_ssl = true;
      params.ssl_ca = data[SqlFormFields.SSL_CA]?.content || null;
      params.ssl_client_key = data[SqlFormFields.SSL_CLIENT_KEY]?.content || null;
      params.ssl_client_cert = data[SqlFormFields.SSL_CLIENT_CERT]?.content || null;
      params.ssl_load_all_ca = data.sslLoadAllCA;
    }

    if (this.sourceTypeIdentifier === 'AZURE_POSTGRES') {
      params.use_flexible_server_format_in_azure_postgres = data[SqlFormFields.AZURE_SERVER_FLEXI] === 'true';
    }

    if (data[SqlFormFields.APPLICATION_INTENT_READ_ONLY]) {
      params.use_read_only_intent = true;
    }

    if (data.isPostgresPartitionTableToParentTableEnabled) {
      params.push_partition_table_to_parent_table = true;
    }

    return params;
  }
}
