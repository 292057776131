import { useEffect } from 'react';
import { toArray } from '../utils/helper';

declare const tns: any;

export function useSlider(options: { [key: string]: any }) {
  const setActiveClass = sliderInfo => {
    const indexPrev = sliderInfo.indexCached;
    const indexCurrent = sliderInfo.index;

    toArray(sliderInfo.slideItems).map(item => item.classList.remove('current-item'));

    sliderInfo.slideItems?.[indexPrev].classList.remove('current-item');
    sliderInfo.slideItems?.[indexCurrent].classList.add('current-item');
  };

  useEffect(() => {
    let slider;

    if (typeof tns !== 'undefined') {
      slider = tns(options);

      setActiveClass(slider.getInfo());

      slider.events.on('indexChanged', setActiveClass);
    }

    return () => {
      if (slider) {
        slider.destroy();
      }
    };
  }, []);
}
