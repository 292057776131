// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputFieldMasked__TdMF {\n  opacity: 0;\n  height: 0;\n  z-index: -1;\n}\n.inputFieldMasked__TdMF label,\n.inputFieldMasked__TdMF div,\n.inputFieldMasked__TdMF p {\n  height: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFieldMasked": "inputFieldMasked__TdMF"
};
export default ___CSS_LOADER_EXPORT___;
