import { useEffect } from 'react';
import { CommunicationMessageEvents } from './message-events';

export function useIframeMessage({
  iframeOrigin,
  iframeRef,
  onMessageFromIframe
}) {
  useEffect(() => {
    window.addEventListener('message', handleMessagesFromIframe);
    return () => window.removeEventListener('message', handleMessagesFromIframe);
  }, []);

  const handleMessagesFromIframe = e => {
    if (e.origin !== iframeOrigin) {
      return;
    }

    onMessageFromIframe(e.data);
  };

  const sendMessageToIframe = (event: CommunicationMessageEvents, data: any) => {
    const message: any = {
      event,
      data: {
        ...data
      }
    };

    try {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(message, iframeOrigin);
      }
    } catch ( error ) {
    }
  };

  return {
    sendMessageToIframe
  };
}
