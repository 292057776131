import React from 'react';
import BaseModal, { BaseModalProps } from '../BaseModal';
import styles from './styles.module.scss';

interface FullScreenModalProps extends BaseModalProps {
  innerContentClass?: string;
}

export default function FullScreenModal(props: FullScreenModalProps) {
  const { children, variant = 'custom', contentClassName, innerContentClass, ...rest } = props;

  return (
    <BaseModal
      variant={variant}
      contentClassName={`${styles.fullScreenContent} ${contentClassName}`}
      {...rest}
    >
      <div className={`${styles.fullScreenContentInner} ${innerContentClass}`}>{children}</div>
    </BaseModal>
  );
}
