import React, { useState } from 'react';
import clsx from 'clsx';
import { HdButton, HdDocLink, HdLink, HdPane } from '../../../../../../components/UIElements';
import { useQuery } from '../../../../../../hooks/useQuery';
import PipelinesAPI from '../../../../PipelinesAPI';
import { EntityUIState } from '../../../../../../../app/core/models/entitiy-ui-state';
import useConfirmV2 from '../../../../../../components/Dialog/ConfirmDialog/ConfirmDialogV2/useConfirmV2';

interface ZendeskVersionUpgradeBannerProps {
  className: string;
  pipelineId: number;
  versionUpgrade: () => void;
}

export function ZendeskVersionUpgradeBanner({ className, pipelineId, versionUpgrade }: ZendeskVersionUpgradeBannerProps) {
  const [upgradeSuccessful, setUpgradeSuccessful] = useState(false);
  const { confirmV2: confirm } = useConfirmV2();

  const {
    uiState,
    fetch: upgradeZendeskPipeline
  } = useQuery({
    dataSource$: () => PipelinesAPI.upgradeZendeskPipeline(pipelineId),
    fetchSuccess: () => {
      setUpgradeSuccessful(true);
      versionUpgrade();
    }
  });

  const onClickUpgrade = () => {
    confirm({
      title: 'Upgrade Zendesk Pipeline',
      body: 'Are you sure you want to upgrade the Pipeline?',
      negativeButtonText: 'Cancel',
      positiveButtonText: 'Yes, Upgrade Pipeline',
      iconName: 'deprecate',
      iconContainerClass: 'warning'
    }).then(confirmed => {
      if (confirmed) {
        upgradeZendeskPipeline();
      }
    });
  };

  if (upgradeSuccessful) {
    return null;
  }

  return (
    <HdPane
      variant='warning-faded'
      icon='warning'
      className={clsx( className)}
    >
      <div className='flex-col'>
        <div className='text-medium'>
          Hevo now supports a new version of Zendesk Source that uses a&nbsp;

          <HdLink
            className='text-primary'
            tag='button'
            onClick={() => {
              window.open(
                'https://support.zendesk.com/hc/en-us/articles/5591904358938-New-limits-for-offset-based-pagination',
                '_blank'
              );
            }}
          >
            cursor based pagination strategy.
          </HdLink>

          &nbsp;Upgrade your Pipelines to the new version before August 31, 2024, for uninterrupted data replication. &nbsp;

          <HdDocLink dataId='' docLink='/sources/sns-analytics/zendesk'/>
        </div>

        <div className='mt-3'>
          <HdButton
            dataId=''
            className='mr-2 bg-surface'
            variation='outline'
            size='sm'
            disabled={uiState === EntityUIState.LOADING}
            showProgress={uiState === EntityUIState.LOADING}
            onClick={onClickUpgrade}
          >
            Upgrade Pipeline
          </HdButton>
        </div>
      </div>
    </HdPane>
  );
}
