import { Injectable, Injector } from '@angular/core';
import { ViewLibrary } from '../react-bridge/models/view-library';
import { NODE_TYPE_IDENTIFIER, NodeTypeConfigHelper, NodeTypeConfigHelperConstructor } from './node-config-helper';


@Injectable()
export abstract class NodeConfigHelperService {
  protected _configHelpers: { [key: string]: NodeTypeConfigHelper } = {};

  protected constructor(protected _injector: Injector) {
  }

  abstract getConfigHelperClass(nodeTypeIdentifier: string): NodeTypeConfigHelperConstructor;

  abstract getRenderingLibrary(nodeTypeIdentifier: string): string;

  abstract getConfigHelperClassInstance(nodeTypeIdentifier: string): NodeTypeConfigHelper;

  getConfigHelper(nodeTypeIdentifier: string): NodeTypeConfigHelper {
    const existingRef = this._configHelpers[nodeTypeIdentifier];

    if (existingRef) {
      return existingRef;
    }

    const configHelperClass = this.getConfigHelperClass(nodeTypeIdentifier);
    const formRenderingLibrary = this.getRenderingLibrary(nodeTypeIdentifier);

    let newRef: NodeTypeConfigHelper;

    if (formRenderingLibrary === ViewLibrary.REACT) {
      newRef = this.getConfigHelperClassInstance(nodeTypeIdentifier);
    } else {
      const newInjector = Injector.create(
        [
          {
            provide: configHelperClass
          },
          {
            provide: NODE_TYPE_IDENTIFIER,
            useValue: nodeTypeIdentifier
          }
        ],
        this._injector
      );

      newRef = newInjector.get(configHelperClass);
    }

    this._configHelpers[nodeTypeIdentifier] = newRef;

    return newRef;
  }
}
