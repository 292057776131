<span
  *ngIf="thumbnail"
  class="hevo-icon hevo-{{ thumbnail }} dropdown-trigger-thumbnail">
</span>

<ng-content>
</ng-content>

<span
  class="hevo-icon hevo-dropdown-arrow dropdown-trigger-icon">
</span>
