import React from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export const dataIdGenerator = getDataIdGenerator('account-deletion-in-progress');

export default function AccountDeletionInProgress() {
  return (
    <div className='box' data-id={dataIdGenerator('')}>
      <div className='box__header'>
        <span className='box__title' data-id={dataIdGenerator('title')}>
          Deleting account...
        </span>
      </div>
      <div className='box__body'>
        <div className='text-subheading-1' data-id={dataIdGenerator('body')}>
          Your Hevo account is being deleted. You will be logged out soon.
        </div>
      </div>
    </div>
  );
}
