<div
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)"
  #container>

  <div
    class="dialog-content"
    #content
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>
    <div class="dialog-header" tabindex="0">
      <div class="dialog-title" *ngIf="!primaryKeyAdded">Select fields for Primary Key</div>
      <div class="dialog-title" *ngIf="primaryKeyAdded">Primary Key Added</div>
      <button
        hd-icon-btn
        (click)="onNegativeClick()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <ng-container *ngIf="!primaryKeyAdded">
      <div class="dialog-body" formOverlayLoader
           [showOverlay]="submitting">
        <primary-keys-selector
          [(ngModel)]="columns"
          [suggestions]="suggestions">
        </primary-keys-selector>

        <form-error class="mt-7 mb-3" *ngIf="formError">{{ formError }}</form-error>
      </div>

      <div class="dialog-footer">
        <div>
          <button
            type="button"
            hdButton
            variation="outline"
            (click)="onNegativeClick()">
            Cancel
          </button>
          <button
            type="button"
            hdButton
            (click)="onSubmit()"
            [showProgress]="submitting"
            [disabled]="!columns.length || submitting">
            Add Primary Key
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="primaryKeyAdded">
      <div class="dialog-body">
        Primary key has been added to the table <b>{{ this._data.schemaName }}</b>. You will have to manually remove any existing duplicate records.
      </div>

      <div class="dialog-footer">
        <div>
          <button
            type="button"
            hdButton
            (click)="onPositiveClick()">
            Ok, Got It
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
