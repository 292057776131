import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { fadeAnim, popupAnim } from '../animations';

import { DialogBase } from '../dialog-base';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';

import { Dialog } from '../models';

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: [ './alert-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class AlertDialogComponent extends DialogBase implements OnInit, Dialog {
  title = 'Alert';
  body = 'Alert Body';
  positiveButton = 'Ok';
  supportButton = 'Contact Support';
  isCustomerSupportBtn = false;
  supportMessage = '';

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: any,
    protected _keyboardShortcuts: KeyboardShortcuts
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  show() {
    super.show();
    this.title = this._data.title || this.title;
    this.body = this._data.body || this.body;
    this.positiveButton = this._data.positiveButton || this.positiveButton;
    this.isCustomerSupportBtn = this._data.isCustomerSupportBtn || this.isCustomerSupportBtn;
    this.supportButton = this._data.supportButton || this.supportButton;
    this.supportMessage = this._data.customerSupportMessage || this.supportMessage;
  }

  onPositiveClick() {
    super.onPositiveClick();
    this.hide();
  }
}
