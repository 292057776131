// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.activationViewContainer_OCbps {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.nodeInfo_wMG2Q {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n}\n\n.nodeImage_TJTxM {\n  width: 16px;\n  height: 16px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  flex-shrink: 0;\n  margin-right: 4px;\n}\n.nodeImage_TJTxM img {\n  max-width: 14px;\n  max-height: 14px;\n}\n.nodeImage_TJTxM hd-icon, .nodeImage_TJTxM .hevo-icon_sKqwo {\n  font-size: 14px;\n}\n\n.nodeName_lwiuU {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-size: 10px;\n  font-weight: 400;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  margin-bottom: 1px;\n}\n\n.connectionIndicator__Lyq3 {\n  transform: rotate(180deg);\n  margin: 0 12px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activationViewContainer": "activationViewContainer_OCbps",
	"nodeInfo": "nodeInfo_wMG2Q",
	"nodeImage": "nodeImage_TJTxM",
	"hevo-icon": "hevo-icon_sKqwo",
	"nodeName": "nodeName_lwiuU",
	"connectionIndicator": "connectionIndicator__Lyq3"
};
export default ___CSS_LOADER_EXPORT___;
