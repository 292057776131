import { CommunicationMessageEvents } from './message-events';
import { useDocsLoader } from './useDocsLoader';
import { useDocsUrl } from './useDocsUrl';
import { useIframeMessage } from './useIframeMessage';

declare let __DOCS_CONFIG__: any;

const DOCS_BASE_URL = __DOCS_CONFIG__.miniDocsBaseURL;


export function useDocs({
  iframeRef,
  docLink,
  areTabsRendered = false,
  queryParams,
  onMessage = null,
  onIframeLoadError = null,
  onIframeLoadSuccess = null,
  loadMessage,
  loadedMessage
}: {
  iframeRef: any;
  docLink: string;
  areTabsRendered?: boolean;
  queryParams?: Record<string, string>;
  onMessage?: Function;
  onIframeLoadError?: Function;
  onIframeLoadSuccess?: Function;
  loadMessage: CommunicationMessageEvents;
  loadedMessage: CommunicationMessageEvents;
}) {
  const [ iframeSourceURL, rebuildIframeSourceURL ] = useDocsUrl({
    docLink,
    areTabsRendered,
    queryParams
  });

  const onMessageFromIframe = (e) => {
    onLoadingMessageFromIframe(e);
    onMessage && onMessage(e);
  };

  const { sendMessageToIframe } = useIframeMessage({
    iframeOrigin: DOCS_BASE_URL,
    iframeRef,
    onMessageFromIframe
  });

  const {
    error,
    isIframeLoading,
    onIframeLoadEvent,
    onIframeLoadErrorEvent,
    onMessageFromIframe: onLoadingMessageFromIframe
  } = useDocsLoader({
    docLink: iframeSourceURL,
    sendMessageToIframe,
    onIframeLoadError,
    onIframeLoadSuccess,
    loadMessage,
    loadedMessage
  });

  return {
    error,
    iframeSourceURL,
    rebuildIframeSourceURL,
    isIframeLoading,
    onIframeLoadEvent,
    onIframeLoadErrorEvent,
    sendMessageToIframe
  };
}
