// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.wrapper_U7zRL {\n  display: inline-flex;\n}\n\n.actionSpinner_s4iMg {\n  position: relative;\n  width: 16px;\n  height: 16px;\n  border-radius: 8px;\n}\n.actionSpinner_s4iMg.primary_ko1fq {\n  border: solid 1px var(--primary-color);\n  background-color: var(--primary-color);\n}\n.actionSpinner_s4iMg.deferred_dvetd {\n  border: solid 1px var(--error-color);\n  background-color: var(--error-color);\n}\n.actionSpinner_s4iMg.pending_M4D9Y {\n  border: solid 1px var(--default-secondary-color);\n  background-color: var(--default-secondary-color);\n}\n\n.loadingCircle_CHzBX {\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  width: 10px;\n  height: 10px;\n  border-radius: 8px;\n  border: solid 1px;\n  border-color: #ffffff #ffffff #ffffff transparent;\n  animation: animateIndicator_Iq_zN 1000ms linear infinite;\n}\n\n@keyframes animateIndicator_Iq_zN {\n  100% {\n    transform: rotateZ(0deg);\n  }\n  100% {\n    transform: rotateZ(360deg);\n  }\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_U7zRL",
	"actionSpinner": "actionSpinner_s4iMg",
	"primary": "primary_ko1fq",
	"deferred": "deferred_dvetd",
	"pending": "pending_M4D9Y",
	"loadingCircle": "loadingCircle_CHzBX",
	"animateIndicator": "animateIndicator_Iq_zN"
};
export default ___CSS_LOADER_EXPORT___;
