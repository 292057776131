import React from 'react';
import styles from './styles.module.scss';

const shimmerFields = [
  {
    fieldWidth: '600px',
    labelWidth: '100px'
  },
  {
    fieldWidth: '300px',
    labelWidth: '130px'
  },
  {
    fieldWidth: '300px',
    labelWidth: '110px'
  },
  {
    fieldWidth: '300px',
    labelWidth: '130px'
  },
  {
    fieldWidth: '300px',
    labelWidth: '110px'
  }
];

export function NodeSettingsShimmer() {
  return (
    <div className='p-7' data-id='node-settings-shimmer'>
      <div className={`row justify-center mt-7 mb-9 ${styles.content}`}>
        {shimmerFields.map((field, index) => (
          <div key={index} className={`mb-9 ${index === 0 ? 'col-xs-12' : 'col-md-6'}`}>
            <div
              className='shimmer shimmer-line'
              style={{ maxWidth: field.labelWidth, height: '8px', marginBottom: '12px' }}
            />

            <div
              className='shimmer shimmer-line'
              style={{ maxWidth: field.fieldWidth, height: '12px' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
