import React from 'react';
import { getArrayFromSize } from '../../../../legacy-utils/array';

const shimmerArray = getArrayFromSize(2);
const splitSectionDimensions = ['100%', 260, '100%', 260];

export function GlobalSearchExcerptShimmer() {
  return (
    <div>
      <div className='center-flex-row pb-4 border-bottom'>
        <div
          className='shimmer shimmer-square mr-2 border-radius-md'
          style={{ width: 32, height: 32 }}
        />

        <div>
          <div className='shimmer shimmer-line mb-2' style={{ width: 190, height: 12 }} />
          <div className='shimmer shimmer-line' style={{ width: 130, height: 8 }} />
        </div>
      </div>

      <div className='mt-5'>
        {splitSectionDimensions.map((dimension, index) => (
          <div key={index} className='shimmer shimmer-line mt-3' style={{ width: dimension }} />
        ))}
      </div>
    </div>
  );
}

export function GlobalSearchEntitiesShimmer({ dataId }) {
  return (
    <div data-id={dataId}>
      {shimmerArray.map((key, index) => (
        <div key={index} className='center-flex-row justify-between pl-5' style={{ height: 56 }}>
          <div className='center-flex-row'>
            <div className='shimmer shimmer-circle mr-3' />
            <div className='shimmer shimmer-line' style={{ width: 130 }} />
          </div>

          <div className='center-flex-row mr-9'>
            <div className='shimmer shimmer-circle mr-3' />
            <div className='shimmer shimmer-line' style={{ width: 130 }} />
          </div>
        </div>
      ))}
    </div>
  );
}
