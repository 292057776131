import { REGION_NAMES } from '../../../../../../app/nodes/constants';

export const DYNAMODB_REGIONS: any[] = [
  {
    name: REGION_NAMES['us-east-2'],
    value: 'us-east-2',
    id: 'us-east-2',
  },
  {
    name: REGION_NAMES['us-east-1'],
    value: 'us-east-1',
    id: 'us-east-1'
  },
  {
    name: REGION_NAMES['us-west-1'],
    value: 'us-west-1',
    id: 'us-west-1'
  },
  {
    name: REGION_NAMES['us-west-2'],
    value: 'us-west-2',
    id: 'us-west-2'
  },
  {
    name: REGION_NAMES['ca-central-1'],
    value: 'ca-central-1',
    id: 'ca-central-1'
  },
  {
    name: REGION_NAMES['ap-south-1'],
    value: 'ap-south-1',
    id: 'ap-south-1'
  },
  {
    name: REGION_NAMES['ap-northeast-2'],
    value: 'ap-northeast-2',
    id: 'ap-northeast-2'
  },
  {
    name: REGION_NAMES['ap-northeast-3'],
    value: 'ap-northeast-3',
    id: 'ap-northeast-3'
  },
  {
    name: REGION_NAMES['ap-southeast-1'],
    value: 'ap-southeast-1',
    id: 'ap-southeast-1'
  },
  {
    name: REGION_NAMES['ap-southeast-2'],
    value: 'ap-southeast-2',
    id: 'ap-southeast-2'
  },
  {
    name: REGION_NAMES['ap-northeast-1'],
    value: 'ap-northeast-1',
    id: 'ap-northeast-1'
  },
  {
    name: REGION_NAMES['cn-north-1'],
    value: 'cn-north-1',
    id: 'cn-north-1'
  },
  {
    name: REGION_NAMES['cn-northwest-1'],
    value: 'cn-northwest-1',
    id: 'cn-northwest-1'
  },
  {
    name: REGION_NAMES['eu-central-1'],
    value: 'eu-central-1',
    id: 'eu-central-1'
  },
  {
    name: REGION_NAMES['eu-west-1'],
    value: 'eu-west-1',
    id: 'eu-west-1'
  },
  {
    name: REGION_NAMES['eu-west-2'],
    value: 'eu-west-2',
    id: 'eu-west-2'
  },
  {
    name: REGION_NAMES['eu-west-3'],
    value: 'eu-west-3',
    id: 'eu-west-3'
  },
  {
    name: REGION_NAMES['sa-east-1'],
    value: 'sa-east-1',
    id: 'sa-east-1'
  },
  {
    name: REGION_NAMES['us-gov-west-1'],
    value: 'us-gov-west-1',
    id: 'us-gov-west-1'
  }
];
