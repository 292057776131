export const PINTEREST_ATTRIBUTION_WINDOWS = [
  {
    name: '1 Day',
    value: 1,
    id: 1
  },
  {
    name: '7 Days',
    value: 7,
    id: 7
  },
  {
    name: '30 Days',
    value: 30,
    id: 30
  },
  {
    name: '60 Days',
    value: 60,
    id: 60
  }
];

export const PINTEREST_REPORT_TIMES = [
  {
    name: 'Time of Ad Action',
    value: 'TIME_OF_AD_ACTION',
    id: 'TIME_OF_AD_ACTION'
  },
  {
    name: 'Time of Conversion',
    value: 'TIME_OF_CONVERSION',
    id: 'TIME_OF_CONVERSION'
  }
];

export const PINTEREST_BREAKDOWNS = [
  {
    name: 'Hour',
    value: 'HOUR',
    id: 'HOUR'
  },
  {
    name: 'Day',
    value: 'DAY',
    id: 'DAY'
  },
  {
    name: 'Week',
    value: 'WEEK',
    id: 'WEEK'
  },
  {
    name: 'Month',
    value: 'MONTH',
    id: 'MONTH'
  },
  {
    name: 'Total',
    value: 'TOTAL',
    id: 'TOTAL'
  }
];
