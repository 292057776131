import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import { ModelStatusEnum } from '../../transform-model/model-status';
import { WorkflowStatusEnum } from '../../workflow/models/workflow-status';
import { PRODUCT_ENTITIES_OVERVIEW_ROUTES } from './constants';
import { GlobalSearchState, GlobalSearchEntity } from './GlobalSearchReducer';
import { GlobalSearchMenuItemType } from './models';

export function getEntityTrackingData(
  entity,
  isOverviewPageRoute = true,
  route: { cta?: string; path?: string; section?: 'list' | 'excerpt' } = {}
) {
  const { entityType } = entity;

  let url = '';
  let type: HevoEntity;
  const downstreamRoute = isOverviewPageRoute
    ? PRODUCT_ENTITIES_OVERVIEW_ROUTES[entityType]
    : route.path;

  switch (entityType) {
    case GlobalSearchEntity.PIPELINES:
      type = HevoEntity.PIPELINE.value;
      url = `pipeline/${entity.seq_id}${downstreamRoute}`;
      break;

    case GlobalSearchEntity.TARGETS:
      type = HevoEntity.ACTIVATION_TARGET.value;
      url = `target/${entity.seqId}${downstreamRoute}`;
      break;

    case GlobalSearchEntity.ACTIVATIONS:
      type = HevoEntity.ACTIVATION.value;
      url = `activation/${entity.seqId}${downstreamRoute}`;
      break;

    case GlobalSearchEntity.DESTINATIONS:
      type = HevoEntity.DESTINATION.value;
      url = `destination/d/${entity.seq_id}${downstreamRoute}`;
      break;

    case GlobalSearchEntity.MODELS:
      type = HevoEntity.MODEL.value;

      if (entity.status === ModelStatusEnum.DRAFT.value) {
        url = `model/draft/${entity.seq_id}`;
      } else {
        url = `model/${entity.seq_id}${downstreamRoute}`;
      }
      break;

    case GlobalSearchEntity.WORKFLOWS:
      type = HevoEntity.WORKFLOW.value;

      if (entity.status.value === WorkflowStatusEnum.DRAFT.value) {
        url = `workflow/draft/${entity.seqId}`;
      } else {
        url = `workflow/${entity.seqId}${downstreamRoute}`;
      }
      break;

    case GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS:
      url = entity.url;
      type = GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS;
      break;

    case GlobalSearchEntity.DESTINATION_TYPES:
      url = 'create-entity-shortcut/destination';
      type = GlobalSearchEntity.DESTINATION_TYPES;

      break;
    case GlobalSearchEntity.SOURCE_TYPES:
      type = GlobalSearchEntity.SOURCE_TYPES;
      url = 'create-entity-shortcut/pipeline';
      break;

    default:
      break;
  }

  return {
    url,
    trackingData: {
      type,
      exit: url,
      section: route?.section || 'list',
      cta: route?.cta || ''
    }
  };
}

export function getUIRenderingData(state: GlobalSearchState) {
  const filteredExistingEntitiesCount =
    state.workflows.filteredEntities.length +
    state.models.filteredEntities.length +
    state.pipelines.filteredEntities.length +
    state.destinations.filteredEntities.length +
    state.activations.filteredEntities.length +
    state.targets.filteredEntities.length;

  const renderedItemsCount =
    filteredExistingEntitiesCount +
    state.sourceTypes.filteredEntities.length +
    state.destinationTypes.filteredEntities.length +
    state.algoliaSearchResults.filteredEntities.length;

  const hasSearchResults =
    state.workflows.filteredEntities.length > 0 ||
    state.models.filteredEntities.length > 0 ||
    state.pipelines.filteredEntities.length > 0 ||
    state.destinations.filteredEntities.length > 0 ||
    state.activations.filteredEntities.length > 0 ||
    state.targets.filteredEntities.length > 0 ||
    state.sourceTypes.filteredEntities.length > 0 ||
    state.destinationTypes.filteredEntities.length > 0 ||
    state.algoliaSearchResults.filteredEntities.length > 0;

  const loadingExistingData =
    state.workflows.loadingData ||
    state.models.loadingData ||
    state.pipelines.loadingData ||
    state.destinations.loadingData ||
    state.activations.loadingData ||
    state.targets.loadingData;

  const loading = loadingExistingData || state.algoliaSearchResults.loadingData;

  const showBody = loading || !!state.filters.search || hasSearchResults;

  return {
    hasSearchResults,
    loadingData: loading,
    showExcerptShimmer: loadingExistingData,
    renderedItemsCount,
    filteredExistingEntitiesCount,
    showBody
  };
}

export function getScrollPosition(
  menuItems: Set<{ current: GlobalSearchMenuItemType }>,
  container: HTMLDivElement,
  currentPosition: number
): number | undefined {
  if (!menuItems || !menuItems.size) {
    return undefined;
  }

  if (currentPosition === 0) {
    return 0;
  }

  const itemRef = Array.from(menuItems)[currentPosition].current;

  const itemTop = itemRef?.offsetTop || 0;
  const itemOffsetHeight = itemRef?.offsetHeight || 0;
  const itemBottom = itemTop + itemOffsetHeight;

  if (itemBottom > container.scrollTop + container.offsetHeight) {
    return itemBottom - container.offsetHeight;
  }

  if (itemTop < container.scrollTop) {
    return itemTop;
  }

  return undefined;
}

export function getValidItemIndex(
  menuItems: Set<{ current: GlobalSearchMenuItemType }>,
  currentIndex: number,
  direction: 'next' | 'prev'
): number | undefined {
  const items = Array.from(menuItems);
  const disabledItems = items.filter(item => !!item.current.disabled);

  // there are no valid items in the list
  if (items.length === disabledItems.length) {
    return undefined;
  }

  let nextIndex = currentIndex;

  while (true) {
    if (direction === 'prev') {
      nextIndex = (nextIndex - 1 + items.length) % items.length;
    } else {
      nextIndex = (nextIndex + 1) % items.length;
    }

    const isNextItemDisabled = items[nextIndex].current.disabled;
    if (!isNextItemDisabled) {
      break;
    }
  }

  return nextIndex;
}
