import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { exhaustMap, filter, map, startWith } from 'rxjs/operators';
import { BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';

import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class VersionService {

  commitId: string;

  constructor(
    private _rxRequestService: RxRequestService
  ) {
  }

  getVersionUpdateObservable() {
    const period = 300000;

    return interval(period).pipe(
      startWith(0),
      exhaustMap(() => this._commitIdObservable()),
      map((res: any) => res.version),
      filter((commitId: string) => {
        const hasChanged: boolean = typeof this.commitId !== 'undefined'
          && this.commitId !== commitId;
        this.commitId = commitId;
        return hasChanged;
      })
    );
  }

  private _commitIdObservable(): Observable<any> {
    const reqUrl = '/app_version.json';

    const reqOptions: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          timestamp: (new Date()).getTime()
        })
      }
    };

    return this._rxRequestService.get(reqUrl, reqOptions);
  }
}
