import clsx from 'clsx';
import React from 'react';
import { AppConfig } from '../../../../app/core/app.config';
import { HdCopyIcon } from '../../UIElements';

const appConfig = new AppConfig();
const authKeyConfigList = appConfig.config.bastionSSHKeyList;

export function SSHAuthKey({ className }: { className?: string }) {
  return (
    <div className={clsx('authorise-key', className)}>
      <p>
        To authenticate via a public key, you'll need to add our public key to your
        .ssh/authorized_keys file on your SSH Server:
      </p>
      {authKeyConfigList.map(authKeyConfig => (
        <div className='key-wrapper'>
          <span>
            <b>
              {authKeyConfig.label}
              {authKeyConfig.isRecommended ? ' (Recommended)' : ''}:
            </b>
          </span>
          <div className='key'>
            <div className='copy-icon'>
              <HdCopyIcon
                copyText={authKeyConfig.key}
                dataId={authKeyConfig.label}
                toastMessage='Copied the key to clipboard'
              />
            </div>
            {authKeyConfig.key}
          </div>
        </div>
      ))}
    </div>
  );
}
