import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { AddPrimaryKeyDialogComponent } from './add-primary-key-dialog.component';

@Injectable()
export class AddPrimaryKeyDialogService {
  constructor(private _dialogOverlayService: DialogOverlayService) { }

  show(data) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      AddPrimaryKeyDialogComponent,
      data
    );

    return dialogRef.onClose();
  }
}
