// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.notificationContainer_Ah6Rj {\n  display: block;\n  padding: 16px;\n  border-bottom: solid 1px var(--divider-color);\n  min-height: 100px;\n  background: var(--surface-bg-color);\n}\n\n.noBorder_Sr4_t {\n  border-bottom: 0;\n}\n\n.header_pH_bP {\n  display: flex;\n  align-items: center;\n}\n\n.entityName_MvYFV {\n  flex: 1;\n}\n\n.createdTsTooltip_CgflI {\n  z-index: 1040 !important;\n}\n\n.body_PsFvg {\n  padding-top: 8px;\n  padding-bottom: 8px;\n  padding-left: 12px;\n  position: relative;\n}\n\n.body_PsFvg::before {\n  content: \"\";\n  width: 4px;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  border-radius: 2px;\n  background: var(--default-color);\n}\n\n.info_zfN_M::before {\n  background: var(--info-color);\n}\n\n.error__IWE3::before {\n  background: var(--error-color);\n}\n\n.warning_ANxTX::before {\n  background: var(--warning-color);\n}\n\n.success_SdwCJ::before {\n  background: var(--success-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationContainer": "notificationContainer_Ah6Rj",
	"noBorder": "noBorder_Sr4_t",
	"header": "header_pH_bP",
	"entityName": "entityName_MvYFV",
	"createdTsTooltip": "createdTsTooltip_CgflI",
	"body": "body_PsFvg",
	"info": "info_zfN_M",
	"error": "error__IWE3",
	"warning": "warning_ANxTX",
	"success": "success_SdwCJ"
};
export default ___CSS_LOADER_EXPORT___;
