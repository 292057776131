import { User } from '../../app/core/models/user';

// eslint-disable-next-line no-shadow
export enum ActionType {
  CHANGE_THEME = 'CHANGE_THEME',
  LOGIN_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER'
}

export interface ReduxState {
  theme: string;
  isLoggedIn: boolean;
  userData: User;
}

interface ActionProps {
  type: ActionType.CHANGE_THEME | ActionType.LOGIN_USER | ActionType.LOGOUT_USER;
  payload: ReduxState;
}

export type Action = ActionProps;
