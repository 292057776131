export const DATA_ID_CONTRACTS = {
  base: 'pipelineSchedule',
  baseContainer: '',
  infoIcon: 'info',
  schedule: 'schedule',
  streaming: 'streaming',
  infoPane: 'info',
  contactSalesCTA: 'contact-sales',
  errorPane: 'error'
};
