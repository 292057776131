import React from 'react';
import { ModelData } from '../../models';
import { NotificationView } from '../notification-view';
import useEntityLogo from '../../../../hooks/useEntityLogo';
import { HdIcon } from '../../../UIElements';
import styles from './styles.module.scss';

export function ModelView({ data }: NotificationView<ModelData>) {
  const destinationThumbnail = useEntityLogo(data.destTypeLogoUrl, data.destTypeDarkModeLogoUrl);

  return (
    <div className={styles.modelViewContainer}>
      <HdIcon name='models' size={1} className={`${styles.icon} mr-1`} />

      <div className='text-ellipsis text-caption-1'>{data.name}</div>

      <HdIcon name='right-arrow' size={1} className={`${styles.icon} mx-1`} />

      {data.destTypeLogoUrl ? (
        <div className={styles.nodeImage}>
          <img src={destinationThumbnail} alt='' />
        </div>
      ) : null}

      <div className='text-ellipsis text-caption-1'>{data.destName}</div>

      <span className={`mx-1 ${styles.icon} icon-size-1`}>&bull;</span>

      <HdIcon name='table' size={1} className={`${styles.icon} mr-1`} />

      <div className='text-ellipsis text-caption-1'>{data.destSchemaName}</div>
    </div>
  );
}
