<div
  class="tour-container"
  #container
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="dialogAnimationDone($event)">
  <div class="backdrop-blur"></div>

  <div class="side" #left></div>
  <div class="side" #top></div>
  <div class="side" #bottom></div>
  <div class="side" #right></div>

  <img
    #arrow
    [src]="arrowIcons[positionY + '-' + positionX + '-' + arrowDirection]"
    class="arrow arrow-x-{{ positionX }} arrow-y-{{ positionY }}">

  <div class="focused-area" #focusedArea></div>

  <div class="tour-content tour-content-x-{{ positionX }} tour-content-y-{{ positionY }}" #content>
    <ng-container *ngIf="currentStep && currentStep.tourComponent">
      <ng-container *ngComponentOutlet="currentStep.tourComponent; injector: dataInjector"></ng-container>
    </ng-container>

    <ng-container *ngIf="currentStep && currentStep.reactTourComponent">
     <product-tour-react-wrapper
       [tourData]="data"
       [currentStepIndex]="currentStepIdx"
       (nextStep)="next()"
       (previousStep)="previous()"
       (closeTour)="stepDone()"
       >
      </product-tour-react-wrapper>
    </ng-container>
  </div>
</div>
