import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AlertDialog, AlertDialogProps } from './AlertDialog';
import { AlertDialogContext } from './context';

export function AlertDialogProvider({ children }) {
  const [state, setState] = useState<any>({ open: false });
  const onCloseFunc = useRef<Function>();

  const alert = useCallback(
    (data: AlertDialogProps) =>
      new Promise<boolean>(resolve => {
        setState({ ...data, open: true });
        onCloseFunc.current = (choice: boolean) => {
          resolve(choice);
          setState({ open: false });
        };
      }),
    [setState]
  );

  const { open, ...others } = state;

  const memoizedAlert = useMemo(() => ({ alert }), [alert]);

  return (
    <AlertDialogContext.Provider value={memoizedAlert}>
      {children}

      <AlertDialog {...others} open={open} onClose={val => onCloseFunc.current(val)} />
    </AlertDialogContext.Provider>
  );
}
