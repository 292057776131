import React from 'react';

export type StyleFn = (
  props: { [key: string]: any },
  state?: { [key: string]: any }
) => React.CSSProperties & { rx?: number };

export type Styles = {
  maskWrapper: StyleFn;
  svgWrapper: StyleFn;
  maskArea: StyleFn;
  maskAreaCircle: StyleFn;
  maskRect: StyleFn;
  clickArea: StyleFn;
  highlightedArea: StyleFn;
  highlightedCircleArea: StyleFn;
};

export type StylesObj = {
  [key in StyleKey]?: StyleFn;
};

export type StyleKey = keyof Styles;

/**
 * @deprecated Use `StyleKey` alias instead.
 */
export type StylesKeys = StyleKey;

export const defaultStyles: Styles = {
  maskWrapper: () => ({
    opacity: 0.7,
    left: 0,
    top: 0,
    position: 'fixed',
    zIndex: 'var(--zindex-modal-backdrop)',
    pointerEvents: 'none',
    color: 'var(--backdrop-color)'
  }),
  svgWrapper: ({ windowWidth, windowHeight, wpx, wpy }) => ({
    width: windowWidth,
    height: windowHeight,
    left: Number(wpx) / 2,
    top: Number(wpy) / 2,
    position: 'fixed'
  }),
  maskArea: ({ x, y, width, height }) => ({
    x,
    y,
    width,
    height,
    fill: 'black',
    rx: 0
  }),
  maskAreaCircle: ({ cx, cy, r }) => ({
    cx,
    cy,
    r,
    fill: 'black',
    rx: 0
  }),
  maskRect: ({ windowWidth, windowHeight, maskID }) => ({
    x: 0,
    y: 0,
    width: windowWidth,
    height: windowHeight,
    fill: 'currentColor',
    mask: `url(#${maskID})`
  }),
  clickArea: ({ windowWidth, windowHeight, clipID }) => ({
    x: 0,
    y: 0,
    width: windowWidth,
    height: windowHeight,
    fill: 'currentcolor',
    pointerEvents: 'auto',
    clipPath: `url(#${clipID})`
  }),
  highlightedArea: ({ x, y, width, height, highlightedAreaClickable }) => ({
    x,
    y,
    width,
    height,
    pointerEvents: 'auto',
    fill: 'transparent',
    display: highlightedAreaClickable ? 'none' : 'block'
  }),
  highlightedCircleArea: ({ cx, cy, r, highlightedAreaClickable }) => ({
    cx,
    cy,
    r,
    pointerEvents: 'auto',
    fill: 'transparent',
    display: highlightedAreaClickable ? 'none' : 'block'
  })
};

export type getStylesType = (key: StyleKey, extra?: { [key: string]: any }) => {};

export function stylesMatcher(styles: StylesObj) {
  return (key: StyleKey, state: {}): React.CSSProperties & { rx?: number } => {
    const base = defaultStyles[key](state);
    const custom = styles[key];
    return custom ? custom(base, state) : base;
  };
}
