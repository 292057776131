import React from 'react';
import styles from './styles.module.scss';

const SHIMMER_FIELDS = [
  ['sm', 'lg', 'sm'],
  ['sm', 'lg', 'sm']
];

export default function DocsLoadingShimmer() {
  return (
    <div className='row justify-center mx-5'>
      {SHIMMER_FIELDS.map((field, fieldIndex) => (
        <div className='col-xs-12 mt-8' key={fieldIndex}>
          {field.map((item, itemIndex) => (
            <div
              className={`shimmer shimmer-line mb-2 ${styles.shimmerItem} ${
                item === 'sm' ? styles.shimmerItemSmall : styles.shimmerItemLarge
              }`}
              key={itemIndex}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
