export const DESTINATION_OPTIMISATION_DIALOG_TABLES_SEARCH_KEY = 'search_table_name';

export const DESTINATION_OPTIMIZATION_CONFIRM_DIALOG_OPTIONS = {
  title: 'Enable Append Only Mode for Selected Tables',
  body: 'Hevo will not deduplicate the data for the selected tables. You can change the settings anytime from the Load Status page.',
  positiveBtnText: 'Confirm',
  negativeBtnText: 'Cancel',
  iconName: 'table',
  iconContainerClass: 'success'
};
