export function getMapFromObj(obj: any): Map<string, any> {
  if (!obj) {
    return undefined;
  }

  const map: Map<string, any> = new Map();
  Object.keys(obj).forEach((key) => {
    map.set(key, obj[key]);
  });

  return map;
}

export function getArrayFromObj(obj: any): any[][] {
  if (!obj) {
    return undefined;
  }

  const arr: any[][] = [];
  Object.keys(obj).forEach((key) => {
    arr.push([ key, obj[key] ]);
  });

  return arr;
}

export function cloneObject(obj) {
  // WARNING It converts array into plain Object
    const clone = {};
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        if (obj[i] != null &&  typeof(obj[i]) === 'object') {
          clone[i] = cloneObject(obj[i]);
        } else {
            clone[i] = obj[i];
        }
      }
    }
    return clone;
}

export function fromMapToObjectLiteral(map: Map<any, any>) {
  return Array.from(map).reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
}

export  function deepCloneObjectUsingJSON<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
