import { HttpClientModule } from '@angular/common/http';
import {  ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';
import { LottieModule } from 'ngx-lottie';

import { AboutModule } from './about/about.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoaderModule } from './loader/loader.module';
import { NotificationSubscriptionModule } from './notification-subscription/notification-subscription.module';
import { PlaygroundModule } from './playground/playground.module';
import { SharedModule } from './shared/shared.module';
import { ToasterModule } from './toaster/toaster.module';
import { ToasterService } from './toaster/toaster.service';
import { ChunkErrorHandler } from './core/service/chunk-error-handler.service';

declare var __DASHBOARD_VERSION__: string;
declare var __CORALOGIX_RUM_CONFIG__: Record<string, string>
declare global {
  interface Window {
    CoralogixRum:any;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToasterModule,
    CoreModule,
    SharedModule,
    AuthModule,
    PlaygroundModule,
    AboutModule,
    NotificationSubscriptionModule,
    DashboardModule,
    AppRoutingModule,
    LoaderModule,
    Angulartics2Module.forRoot(),
    LottieModule.forRoot({ player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web') })
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    ToasterService,
    CookieService,
    {
      provide: ErrorHandler,
      useClass: ChunkErrorHandler
    }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule {
  constructor() {
    if (typeof __CORALOGIX_RUM_CONFIG__ !== 'undefined' && window.CoralogixRum) {
      window.CoralogixRum.init({
        ...__CORALOGIX_RUM_CONFIG__,
        version: __DASHBOARD_VERSION__.replace(/ /g, '_'),
        ignoreErrors:[/.*Promise Cancelled*./],
        sessionRecordingConfig: {
          enable: true,
          autoStartSessionRecording: true,
          recordConsoleEvents: true,
          sessionRecordingSampleRate: 100,
          maskInputOptions: { password: true }
        }
      });
    }
  }
}
