<div
  #container
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)">
  <div
    #content
    class="dialog-content"
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>
    <div class="dialog-header" tabindex="0">
      <div class="dialog-title">
        {{ title }}
      </div>

      <button
        hd-icon-btn
        class="dialog-close"
        (click)="onNegativeClick()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <div
      class="dialog-body alert-dialog-body"
      [innerHTML]="body">
    </div>

    <div class="dialog-footer">

      <button
        *ngIf="isCustomerSupportBtn"
        type="button"
        hdButton
        variation="outline"
        color="error"
        icon="support"
        [message]="supportMessage"
        supportBtn
        (click)="onPositiveClick()">
        {{ supportButton }}
      </button>

      <button
        *ngIf="!isCustomerSupportBtn"
        type="button"
        hdButton
        (click)="onPositiveClick()">
        {{ positiveButton }}
      </button>
    </div>
  </div>
</div>
