import { Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { fromEvent, timer } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

const MIN_HOVER_DURATION = 1000;

@Directive({
  selector: '[hoverTracker]'
})
export class HoverTrackerDirective implements OnChanges, OnDestroy {
  @Input() hoverTracker: string;
  @Input() trackingProps: any;

  private _destroyed$ = new Subject();

  private _trackingSubscription$ = fromEvent(this._el.nativeElement, 'mouseenter').pipe(
    switchMap(() => {
      return timer(MIN_HOVER_DURATION).pipe(
        tap(() => {
          this._angulartics.eventTrack.next({
            action: this.hoverTracker,
            properties: this.trackingProps
          });
        }),
        takeUntil(fromEvent(this._el.nativeElement, 'mouseleave'))
      );
    })
  );

  constructor(
    private _el: ElementRef,
    private _ngZone: NgZone,
    private _angulartics: Angulartics2
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hoverTracker) {
      this._destroyed$.next();

      if (this.hoverTracker) {
        this._ngZone.runOutsideAngular(() => {
          this._trackingSubscription$.pipe(
            takeUntil(this._destroyed$)
          ).subscribe();
        });
      }
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
