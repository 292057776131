import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styles from './styles.module.scss';
import { ReviewListItem } from './ReviewListItem';
import { useLoadItems } from '../../../containers/drawer/useLoadItems';

export function ReviewPipelines({ pipelines }) {
  const { hasNextPage, appendList, listItems, resetList } = useLoadItems(20, pipelines);

  useEffect(() => {
    resetList();
  }, []);
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: false,
    hasNextPage,
    onLoadMore: appendList,
    disabled: false,
    rootMargin: '0px 0px 40px 0px'
  });
  return (
    <div className={styles.reviewListContainer}>
      <div className={styles.listItem}>
        <ListHeader pipelineCount={pipelines.length} />
      </div>
      {listItems.length ? (
        <div className={styles.listItemContainer} ref={rootRef}>
          {listItems.map((pipeline, index) => (
            <>
              <ReviewListItem pipeline={pipeline} />
              {listItems.length === index + 1 ? <div ref={sentryRef} /> : null}
            </>
          ))}
        </div>
      ) : null}
    </div>
  );
}

function ListHeader({ pipelineCount }) {
  return (
    <>
      <div className={clsx('text-body-3', styles.pipelineDetailsCell)}>
        <p className='text-body-3'>Pipelines</p>
        <p className='text-body-2'>
          {` `}({pipelineCount})
        </p>
      </div>
      <div className={clsx('text-body-3 ml-5', styles.syncFrequencyCell)}>Sync Schedule</div>
    </>
  );
}
