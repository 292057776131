<div class="center-flex-row justify-start text-caption-3">
  <div>
    <div class="events-representation-block billable-events mr-1"></div>

    <span class="events-caption">
      Billable Events
    </span>
    <div class="text-body-2">
      {{ billableEvents | numFormatter }}
    </div>
  </div>

  <div class="ml-6">
    <div class="events-representation-block historical-events mr-1"></div>

    <span class="events-caption">
      Non Billable Events
    </span>

    <div class="text-body-2">
      {{ historicalEvents | numFormatter }}
    </div>
  </div>
</div>

<div class="text-caption-3 center-flex-row justify-start mt-2">
  <hd-icon name="time" [size]="0" class="mr-1"></hd-icon>

  <span>{{ displayString }}</span>
</div>
