/* eslint-disable import/no-cycle */
import { _sshService } from '../../../../../../app/core/service/ssh.service';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { JobMode } from '../../../../../../app/nodes/source-type/job-mode';
import { stringToBool } from '../../../../../utils/helper';

export class MongodbConfigHelper implements SourceTypeConfigHelper {
  constructor(public sourceType: string, public jobMode: JobMode) {}

  getDefaultConfig() {
    return {
      port: 27017,
      authDatabaseName: 'admin',
      sshPort: 22,
      loadAllDb: !!(this.jobMode && this.jobMode.canProvideMultipleDbNames),
      mergeCollections: !!(this.jobMode && this.jobMode.canMergeTables),
      isConnectionUri: String(this.sourceType === 'ATLAS_MONGODB'),
      isServerMongoDBAtlas: this.sourceType === 'ATLAS_MONGODB'
    };
  }

  getConfigFromRawData(rawData: any) {
    const config: any = {
      isConnectionUri: 'false',
      isSrvConn: rawData.is_srv_conn,
      user: rawData.db_user,
      host: rawData.db_host,
      port: rawData.db_port,
      databaseName: rawData.db_name,
      databaseNames:
        (rawData.db_names && rawData.db_names.map(item => ({ dbName: item }))) ||
        (rawData.db_name && [{ dbName: rawData.db_name }]),
      loadAllDb: !!rawData.load_all_db,
      authDatabaseName: rawData.auth_db,
      password: rawData.db_password,
      sshIp: rawData.ssh_host,
      sshPort: rawData.ssh_port,
      sshUser: rawData.ssh_user,
      isServerMongoDBAtlas: !!rawData.is_server_mongodb_atlas,
      useSSL: !!rawData.ssl_enabled,
      useSSH: !!(rawData.ssh_host || rawData.ssh_port || rawData.ssh_user),
      mergeCollections: rawData.merge_collections,
      ingestAllTypesAsString: !!rawData.should_ingest_all_types_as_string,
      renderOldDbNameField: !(
        this.jobMode &&
        this.jobMode.canProvideMultipleDbNames &&
        (rawData.load_all_db || rawData.db_names)
      )
    };

    const updatedConfig = this._setHostPort(config, rawData);

    return { ...config, ...updatedConfig };
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_user: data.user,
      db_password: data.password,
      db_name: data.databaseName || undefined,
      auth_db: data.authDatabaseName || undefined,
      is_connection_uri_string: stringToBool(data.isConnectionUri)
    };

    if (stringToBool(data.isConnectionUri)) {
      params.db_host = (data.hosts || []).map(val => val.host).join(',');
      params.db_port = data.hosts?.length && data.hosts[0].port ? +data.hosts[0].port : 27017;
      params.is_srv_conn = data.isSrvConn;
    } else {
      params.db_host = data.host;
      params.db_port = data.port;
      params.is_srv_conn = !!data.isServerMongoDBAtlas;
    }

    const { jobMode } = data;

    if (jobMode) {
      if (jobMode.canMergeTables) {
        params.merge_collections = !!data.mergeCollections;
      }

      if (jobMode.canProvideMultipleDbNames) {
        params.load_all_db = !!data.loadAllDb;
        if (!params.load_all_db && data.databaseNames && data.databaseNames.length) {
          params.db_names = data.databaseNames.map(item => item.dbName);
        }
      }

      if (jobMode.historicalMode) {
        params.import_historical_data = !!data.importHistoricalData;
      }
    }

    const sshParams = _sshService.getSSHParams(data);

    params.ssl_enabled = !!(data.useSSL || data.isServerMongoDBAtlas);
    params.is_server_mongodb_atlas = !!data.isServerMongoDBAtlas;
    params.should_ingest_all_types_as_string = !!data.ingestAllTypesAsString;

    return {
      ...params,
      ...sshParams
    };
  }

  private _setHostPort(config: any, rawData: any) {
    const updatedConfig = config;
    /**
     * setting the uri from the config data
     */
    if (
      rawData.is_connection_uri_string &&
      rawData.db_host &&
      rawData.db_user &&
      rawData.db_password
    ) {
      let URIType = rawData.is_srv_conn ? 'mongodb+srv://' : 'mongodb://';
      URIType += `${rawData.db_user}:${rawData.db_password}@`;

      if (rawData.is_srv_conn) {
        updatedConfig.URI = `${URIType}${rawData.db_host}`;
        updatedConfig.hosts = [{ host: rawData.db_host, port: rawData.db_port }];
      } else {
        const hostArr = rawData.db_host.split(',');
        let hostString = '';

        updatedConfig.hosts = hostArr.map((val, index) => {
          if (index !== hostArr.length - 1) {
            hostString += `${val}${rawData.db_port ? `:${rawData.db_port}` : ''},`;
          } else {
            hostString += `${val}${rawData.db_port ? `:${rawData.db_port}` : ''}`;
          }
          return { host: val, port: rawData.db_port };
        });

        updatedConfig.URI = `${URIType}${hostString}`;
      }
    } else {
      updatedConfig.host = rawData.db_host;
      updatedConfig.port = rawData.db_port;
      updatedConfig.URI = '';
      updatedConfig.hosts = [];
    }
    return updatedConfig;
  }
}
