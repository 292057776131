import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class BraintreePaymentsConfigHelper implements SourceTypeConfigHelper {

  getConfigFromRawData(rawData: any) {
    return {
      environment: rawData.environment,
      publicKey: rawData.public_key,
      privateKey: rawData.private_key,
      merchantId: rawData.merchant_id,
    };
  }

  getDefaultConfig() {
    return {
      environment: 'PRODUCTION'
    };
  }

  buildConnectionParams(data: any) {
    return {
      environment: data.environment,
      public_key: data.publicKey,
      private_key: data.privateKey,
      merchant_id: data.merchantId,
    };
  }
}
