import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../core/service/auth.service';
import { DialogOverlayRef } from './dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from './dialog-overlay.tokens';


interface DialogOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  viewContainerRef?: ViewContainerRef;
  handleLogout?: boolean;
}

const DEFAULT_CONFIG: DialogOverlayConfig = {
  hasBackdrop: true,
  handleLogout: true
};

@Injectable()
export class DialogOverlayService {
  private _closeAll = new Subject();

  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private _authService: AuthService
  ) {
    this._handleLogout();
  }

  open(component: any, data: any = {}, config: DialogOverlayConfig = {}): DialogOverlayRef {
    // Override default configuration
    const dialogConfig: DialogOverlayConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef: OverlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef: DialogOverlayRef = new DialogOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(
      overlayRef,
      dialogRef,
      component,
      data,
      config
    );

    if (dialogConfig.handleLogout) {
      this._closeAll.pipe(
        takeUntil(dialogRef.onClose())
      ).subscribe(() => {
        dialogRef.close();
      });
    }

    return dialogRef;
  }

  closeAllDialogs() {
    this._closeAll.next();
  }

  private createOverlay(config: DialogOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    dialogRef: DialogOverlayRef,
    component: any,
    data: any,
    config: DialogOverlayConfig
  ) {
    const injector = this.createInjector(dialogRef, data, config);

    const containerPortal = new ComponentPortal(component, config ? config.viewContainerRef : null, injector);
    const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(dialogRef: DialogOverlayRef, data: any, config: DialogOverlayConfig): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(DialogOverlayRef, dialogRef);
    injectionTokens.set(DIALOG_OVERLAY_DATA, data);

    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }

  private getOverlayConfig(config: DialogOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }

  /**
   * Logout should ideally be handled outside dialog module,
   * but in the current structure people might inject this service multiple times
   * and you cant get a global reference to this service,
   * unless you create another service provided at the same scope as this service.
   * That introduces a new dependency while providing this service. Want to avoid doing that,
   * as dev's might not remember providing the same.
   * @private
   */
  private _handleLogout() {
    this._authService.beforeLogoutSubject.subscribe(() => {
      this.closeAllDialogs();
    });
  }
}
