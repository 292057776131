import { TemplateRef } from '@angular/core';

export interface BannerNotifier {
  id: string;
  component?: any;
  templateRef?: TemplateRef<any>;
  priority?: number;
}

export interface BannerNotification<T> {
  id: string;
  data: T;
  replace?: boolean;
}

export enum BannerColorPalette {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
  DEFERRED = 'deferred',
  ERROR_FADED = 'error-faded',
  WARN_FADED = 'warning-faded'
}

export enum BannerContentColorPalette {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
  DEFERRED = 'deferred'
}
