// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.formOverlay_PlvG_::after {\n  content: \" \";\n  background: var(--surface-bg-60-color);\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  padding: 10.4px 0px;\n  top: 0;\n  left: 0;\n  z-index: 4;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formOverlay": "formOverlay_PlvG_"
};
export default ___CSS_LOADER_EXPORT___;
