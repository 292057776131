import PipelinesAPI from '../../../PipelinesAPI';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import {
  getSelectorRawDataFromInclusionState,
  getSelectorInclusionStateFromRawData
} from '../../../../../../app/shared/single-level-selector/model';

export class AdrollConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      reports: (rawData.selected_reports || [])?.map(report => ({ id: report, name: report })),
      advertisables: getSelectorInclusionStateFromRawData(rawData)
    };
  }

  getDefaultConfig(metaData: any) {
    return {
      advertisables: {
        selected: true,
        entities: []
      },
      reports: metaData.reports
    };
  }

  buildConnectionParams(data: any) {
    return {
      selected_reports: (data.reports || []).map(ele => ele.id),
      ...getSelectorRawDataFromInclusionState(data.advertisables)
    };
  }

  staticDependenciesResolver(sourceType: string) {
    return PipelinesAPI.getAdrollReportsList().then(res => ({
      reports: res.data.map(ele => ({ id: ele, name: ele }))
    }));
  }
}
