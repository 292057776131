import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { styled, Tab, Tabs } from '@mui/material';
import styles from './styles.module.scss';

const StyledTabHead = styled(Tabs)(() => ({
  borderBottom: '1px solid var(--divider-color)',
  padding: '0 calc(var(--spacer) * 1.25)!important',
  '.MuiTabs-indicator': {
    backgroundColor: 'var(--primary-color) !important'
  }
}));

export const TabsContext = createContext({
  idPrefix: '',
  value: '',
  onSelected: (e: any, value: string) => {}
});

export function useTabContext() {
  return useContext(TabsContext);
}

export function HdTabHead({ children, ...props }) {
  const { value, onSelected } = useTabContext();

  // Sets the first tab as active in case the selected tab is removed.
  useEffect(() => {
    const childTabs: any = React.Children.toArray(children).filter(tab =>
      React.isValidElement(tab)
    );
    if (!childTabs.find((x: React.ReactElement) => x.props.value === value)) {
      onSelected(null, childTabs?.[0]?.props?.value);
    }
  }, [React.Children.toArray(children)?.length]);

  return (
    <StyledTabHead value={value} onChange={onSelected} {...props}>
      {children}
    </StyledTabHead>
  );
}

export function HdTabLabel({ children, value, ...otherProps }) {
  return (
    <Tab
      classes={{ root: styles.tabLabel, selected: styles.tabLabelSelected }}
      value={value}
      label={children}
      {...otherProps}
    />
  );
}
/**
 *
 * @forceOpen => force sets the tab active if there are errors in the tab.
 * @value => used to identify the tab.
 * @keepMounted => doesn't remove the tab element from the dom.
 *
 */
export function HdTabPanel({ forceOpen, value, children, keepMounted = false }) {
  const tabContext = useTabContext();
  const childRef = useRef(null);

  const showTab = tabContext.value === value;

  useEffect(() => {
    if (forceOpen && !showTab) {
      if (childRef.current.querySelectorAll('.Mui-error').length) {
        tabContext.onSelected(null, value);
      }
    }
  });

  if (keepMounted) {
    return (
      <div ref={childRef} className={`w-100 ${!showTab && 'd-none'}`}>
        {children}
      </div>
    );
  }
  return (
    <div ref={childRef} className='w-100'>
      {showTab && children}
    </div>
  );
}

/**
 * sets the tab context
 * @param props
 * @returns
 */
export function HdTab({
  children,
  currentActiveTab,
  onChange
}: {
  children: React.ReactNode;
  currentActiveTab: any;
  onChange?: (number) => void;
}) {
  const values = useMemo(
    () => ({
      idPrefix: '',
      value: currentActiveTab,
      onSelected: (_, selectedValue) => {
        if (onChange) {
          onChange(selectedValue);
        }
      }
    }),
    [currentActiveTab]
  );

  return <TabsContext.Provider value={values}>{children}</TabsContext.Provider>;
}
