import { useRef } from 'react';

export default function useAbortController() {
  const abortController = useRef(new AbortController());

  const abort = (createNew = true) => {
    abortController.current.abort();

    if (createNew) {
      abortController.current = new AbortController();
    }
  };

  return {
    abortControllerRef: abortController,
    abortController: abortController.current,
    abort
  };
}
