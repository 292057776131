import { NodeTypeConfigHelper } from '../../../../../../app/nodes/node-config-helper';
import { DEFAULT_TARGET_CLEVERTAP_REGION, TARGET_CLEVERTAP_REGIONS } from './constants';

export class CleverTapConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData) {
    const selectedRegion = TARGET_CLEVERTAP_REGIONS.find(region => region.value === rawData.region);

    return {
      projectId: rawData.account_id,
      passcode: rawData.passcode,
      region: selectedRegion,
      regionValue: selectedRegion?.value || ''
    };
  }

  getDefaultConfig() {
    return {
      region: DEFAULT_TARGET_CLEVERTAP_REGION,
      regionValue: DEFAULT_TARGET_CLEVERTAP_REGION.value
    };
  }

  buildConnectionParams(data) {
    return {
      account_id: data.projectId,
      passcode: data.passcode,
      region: data.regionValue
    };
  }
}
