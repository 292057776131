import React, { useState } from 'react';
import { HdButton } from '../../UIElements';
import { SyncFrequencyDropdown, defaultSyncFrequencyOption } from './SyncFrequencyDropdown';
import styles from './styles.module.scss';
import { dataIds } from '.';

interface BulkManualUpdateProps {
  selectedCount: number;
  updateSyncFrequency: Function;
}
export function BulkManualUpdate({ selectedCount, updateSyncFrequency }: BulkManualUpdateProps) {
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const onDropdownChange = frequency => {
    setSelectedFrequency(frequency);
  };
  return (
    <div className={styles.bulkUpdateContainer}>
      <p className='text-secondary mr-2'>
        Set the Sync Schedule for the <b>selected {selectedCount} pipelines</b> to
      </p>
      <SyncFrequencyDropdown
        id='manual-update'
        syncFrequencyPolicy={{}}
        onChange={onDropdownChange}
      />
      <HdButton
        dataId={dataIds.updateFrequencyBulkBtn}
        variation='faded'
        className='ml-3'
        onClick={() => updateSyncFrequency(selectedFrequency || defaultSyncFrequencyOption)}
      >
        set SYNC SCHEDULE
      </HdButton>
    </div>
  );
}
