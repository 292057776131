import { ENTITY_ICON_MAP } from '../../../app/core/constants';
import { getCalendarDateWithTimezone } from '../../legacy-utils/date';
import { ACTIVITY_SEVERITIES } from './constants';
import { ActionOriginSegment, Activity, ActivityAuthorType, ActivityFilterOptions, ActivitySeverity } from './model';

export function ActivityListFactory(rawData: any[]): Activity[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((rawActivity: any) => ActivityFactory(rawActivity));
}

export function ActivityFactory(rawData: any) {
  const activity = new Activity();

  activity.message = rawData.message;
  activity.activityTime = rawData.activity_time;
  activity.displayTime = getCalendarDateWithTimezone(rawData.activity_time);

  if (rawData.goto_link && rawData.goto_link.display_name) {
    activity.action = {
      name: rawData.goto_link.display_name,
      url: rawData.goto_link.goto_link
    };
  }

  activity.author = {
    displayName: rawData.action_by.displayName,
    type: ActivityAuthorType.USER,
    id: rawData.action_by.id,
    iconClass: 'hevo-user'
  };

  if (rawData.action_by.id === -1) {
    activity.author.type = ActivityAuthorType.BOT;
    activity.author.iconClass = 'hevo-bot';
  }

  activity.severity = ACTIVITY_SEVERITIES.find((severity: ActivitySeverity) => {
    return severity.level === rawData.severity_level;
  });

  activity.origin = getActivityOriginFromRawData(rawData);

  try {
    if (typeof rawData.public_payload === 'object' && !Object.keys(rawData.public_payload).length) {
      return;
    }
    activity.payload = JSON.stringify(rawData.public_payload, null, '\t');
  } catch (e) {
  }

  return activity;
}

function getActivityOriginFromRawData(rawData: any): ActionOriginSegment[] {
  let i = 0;
  let ref: any = rawData['entities_l' + i];

  const origin: ActionOriginSegment[] = [];

  while (ref) {
    const dataToPush: ActionOriginSegment = {
      displayName: ref.display_name,
      id: ref.id,
      type: ref.type
    };

    if (i === 0) {
      dataToPush.iconClass = `hevo-${ ENTITY_ICON_MAP[ref.type] }`;
    }

    origin.push(dataToPush);

    i++;
    ref = ref['entities_l' + i];
  }

  return origin;
}

export function ActivityFilterOptionsFactory(rawData: any) {
  if (!rawData) {
    return;
  }

  const options = new ActivityFilterOptions();

  if (rawData.entities_l0.Account) {
    options.accounts = rawData.entities_l0.Account.map((acc: any) => {
      return {
        name: acc.name,
        value: acc.id
      };
    });
  }

  if (rawData.severity_level) {
    options.severities = rawData.severity_level.map((level: string) => {
      const ref = ACTIVITY_SEVERITIES.find((severity: ActivitySeverity) => {
        return severity.level === level;
      });

      if (ref) {
        return {
          name: ref.displayName,
          value: ref.filterLevels
        };
      }
    });
  }

  return options;
}
