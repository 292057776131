import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import styles from '../styles.module.scss';
import { HdButton } from '../../../components/UIElements';
import { SchedulerHeader } from '../components/SchedulerHeader';
import { getExecutionPolicyFormInitialValues } from '../form-helper';
import { SchedulerType } from '../models';
import useTeamSettingsService from '../../../hooks/services/useTeamSettingsService';
import {
  DEFAULT_FREQUENCY,
  DEFAULT_FREQUENCY_FOR_LOG_BASED,
  ELIGIBLE_JOB_MODES_STREAMING
} from '../../pipeline/create/PipelineSettings/PipelineSchedule/constant';
import PipelinesAPI from '../../pipeline/PipelinesAPI';
import { getPolicyRawData } from '../utils';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { PolicyTypeEnum } from '../../../../app/dialog/execution-policy-dialog/models';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import {
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_CLICK,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_FAILURE,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_SUCCESS
} from '../../../../app/dialog/execution-policy-dialog/constants';
import { InlineSchedulerWrapper } from '../../pipeline/create/PipelineSettings/PipelineSchedule/InlineSchedulerWrapper';

export function PipelineExecutionPolicyDialogWithStreaming({ onClose, entityDetails }) {
  const [disablePositiveCTA, setDisablePositiveCTA] = useState(false);
  const [savingSchedule, setSavingSchedule] = useState(false);
  const analyticsTracker = useAnalyticsTracker();

  const [validationSchema, setValidationSchema] = useState(null);

  const formikRef = useRef(null);

  const onFrequencyChange = () => {
    trackScheduleClick(TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_CLICK);
    setSavingSchedule(true);
    PipelinesAPI.schedulePipeline(entityDetails.id, getPolicyRawData(formikRef.current.values))
      .then(() => {
        onClose(true);
        trackScheduleClick(
          TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_SUCCESS
        );
      })
      .catch(() => {
        trackScheduleClick(
          TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_FAILURE
        );
      })
      .finally(() => {
        setSavingSchedule(false);
      });
  };

  const { getTeamSettings } = useTeamSettingsService();

  const oldSchedulerFlow = getTeamSettings()?.team_version < 12;

  const showOptionsForLogType =
    ELIGIBLE_JOB_MODES_STREAMING.includes(entityDetails.source_config?.type) ||
    entityDetails.source_config?.has_oplog ||
    entityDetails.source_config?.has_change_streams;

  const trackScheduleClick = (action: string) => {
    let selectedData = getPolicyRawData(formikRef.current.values);
    if (selectedData.type === PolicyTypeEnum.CRON && Array.isArray(selectedData.time_hour_list)) {
      selectedData = {
        ...selectedData,
        time_hour_list: selectedData.time_hour_list.join(','),
        time_hour_list_length: selectedData.time_hour_list.length
      };
    }

    analyticsTracker.eventTrack({
      action,
      properties: {
        ...selectedData,
        id: entityDetails.id
      }
    });
  };

  const dataIdGenerator = getDataIdGenerator('streaming-dialog');

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title='Change the Pipeline Ingestion Schedule'
        className='border-0'
        subTitle='Define the frequency or time(s) at which the Pipeline must ingest the data from the Source.'
        dataIdGenerator={dataIdGenerator}
        onClose={onClose}
      />
      <div className={clsx('mx-6')}>
        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          initialValues={getExecutionPolicyFormInitialValues(SchedulerType.MultiCronType)}
          onSubmit={onFrequencyChange}
        >
          <InlineSchedulerWrapper
            updateValidationSchema={setValidationSchema}
            oldSchedulerFlow={oldSchedulerFlow}
            defaultFrequency={
              showOptionsForLogType ? DEFAULT_FREQUENCY_FOR_LOG_BASED : DEFAULT_FREQUENCY
            }
            showStreamingOption={showOptionsForLogType}
            formikRef={formikRef}
            isEdit
            disablePositiveCTACallback={value => setDisablePositiveCTA(value)}
            entityDetails={entityDetails}
          />
        </Formik>
      </div>

      <div className='dialog-footer border-0'>
        <HdButton
          dataId={dataIdGenerator('close')}
          variation='outline'
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('positive')}
          disabled={disablePositiveCTA}
          showProgress={savingSchedule}
          type='submit'
          onClick={() => {
            formikRef.current.submitForm();
          }}
        >
          update
        </HdButton>
      </div>
    </div>
  );
}
