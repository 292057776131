import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ToasterService } from '../../toaster/toaster.service';

import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class PaymentService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _toasterService: ToasterService
  ) {
  }

  paymentUrl = this._appConfig.getIronBankURL();

  plansUrl = `${ this.paymentUrl }/plans`;
  chatSupportUrl = `${ this.paymentUrl }/chat-support`;
  pendingInvoicesUrl = `${ this.paymentUrl }/pending-invoices`;
  overageUrl = `${ this.paymentUrl }/upcoming-invoice-details`;
  paidSourceUrl = `${ this.paymentUrl }/paid-source`;
  onDemandEventsUrl = `${ this.paymentUrl }/on-demand-events`;
  onDemandCreditUrl = `${ this.paymentUrl }/on-demand-credit`;

  getCurrentPlan() {
    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          all_plans: false
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(this.plansUrl, options);
  }

  getChatSupportDetails() {
    return this._rxRequestService.get(this.chatSupportUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  paidSourceAttemptNotification(sourceType: string) {
    const params = {
      source_type: sourceType
    };

    return this._rxRequestService.post(this.paidSourceUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  getPendingInvoicesDetails() {
    return this._rxRequestService.get(this.pendingInvoicesUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getOverageDetails() {
    return this._rxRequestService.get(this.overageUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getUsageAlert() {
    const requestUrl = this.paymentUrl + '/usage-alert';

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getOnDemandEvents() {
    return this._rxRequestService.get(this.onDemandEventsUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getOnDemandCredit() {
    return this._rxRequestService.get(this.onDemandCreditUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  payUsedOnDemandCreditsBill() {
    return this._rxRequestService.post(
      `${ this.onDemandCreditUrl }/payment`,
      BACKGROUND_NETWORK_REQ_OPTIONS
    ).pipe(tap(() => {
      this._toasterService.pop(
        'success',
        undefined,
        'Credit Paid Successfully'
      );
    }));
  }
}
