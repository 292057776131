import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HoverTrackerDirective } from './hover-tracker/hover-tracker.directive';

const DIRECTIVES = [ HoverTrackerDirective ];

@NgModule({
  declarations: [ ...DIRECTIVES ],
  exports: [ ...DIRECTIVES ],
  imports: [
    CommonModule
  ]
})
export class TrackingModule {
}
