import React from 'react';
import useEntityLogo from '../../hooks/useEntityLogo';
import useEntityLogoBg from '../../hooks/useEntityLogoBg';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { nodeLogoDataIdGenerator } from './utils';

export interface NodeLogoProps {
  logoURL: string;
  darkModeLogoURL: string;
  primaryColor: string;
  hideBackground?: boolean;
  darkModePrimaryColor: string;
  roundedBorders?: boolean;
  disabled?: boolean;
  size?: number | string;
  title?: string;
  className?: string;
  dataId?: string;
}

export function NodeLogo({
  logoURL,
  darkModeLogoURL,
  primaryColor,
  darkModePrimaryColor,
  roundedBorders,
  disabled,
  size,
  hideBackground = false,
  title,
  className,
  dataId
}: NodeLogoProps) {
  const backgroundColor = useEntityLogoBg(primaryColor, darkModePrimaryColor);
  const thumbnail = useEntityLogo(logoURL, darkModeLogoURL);

  const dataIdGenerator = getDataIdGenerator(dataId)
  const classes = clsx({
    [styles.nodeLogo]: true,
    [styles[`nodeLogoSize${size || 5}`]]: true,
    [styles.disabled]: disabled,
    [styles.nodeLogoRoundedBorders]: roundedBorders,
    [styles.hideBackground]: hideBackground,
  }, className);

  return (
    <div className={classes} style={{ backgroundColor }} data-id={nodeLogoDataIdGenerator(dataId, '')}>
      <img src={thumbnail} alt={title} data-id={dataIdGenerator('nodeLogoImg')} />
    </div>
  );
}
