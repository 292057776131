import React, { useState } from 'react';
import { HdTooltip } from '../UIElements';
import styles from './styles.module.scss';

export function StrongPasswordFieldWrapper({ rules, title, value, children }) {
  const [openStrongPasswordTooltip, setOpenStrongPasswordTooltip] = useState(false);
  return (
    <HdTooltip
      extraOffsetX={8}
      className={styles.strongPasswordTooltip}
      open={openStrongPasswordTooltip}
      dataId='strong-password'
      placement='right'
      title={title}
      titleProps={{
        rules,
        value
      }}
    >
      <div
        onFocus={() => {
          setOpenStrongPasswordTooltip(true);
        }}
        onBlur={() => {
          setOpenStrongPasswordTooltip(false);
        }}
      >
        {children}
      </div>
    </HdTooltip>
  );
}
