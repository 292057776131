import { ActivationIdentifierType } from '../activate/models/identifier-type';
import {
  DEFAULT_CUSTOM_FIELD_TYPE,
  TARGET_OBJECT_CUSTOM_FIELD_TYPES
} from '../activate/models/target-object-field-type';
import { getIdentifierName } from '../activate/utils';
import { SchemaFieldFactory } from '../destination/utils';
import { DATA_TYPE_JSON, DATA_TYPE_JSON_STORED_VALUE } from '../schema-builder/constants';
import { generateMappingForStruct } from '../schema-builder/utils';
import { MappingRow, MappingStatus } from './model';


/**
 * @param object - contains source and destination field
 */
export function MappingRowFactory(object: any): MappingRow {
  if (!object) {
    return;
  }

  const mappingRow: MappingRow = {
    source: SchemaFieldFactory(object.source_field),
    destination: object.target_field ? SchemaFieldFactory(object.target_field) : null,
    status: MappingStatus[object.status]
  };

  if (object?.mandatory) {
    mappingRow.mandatoryField = true;
  }

  if (object?.meta_properties?.field_type) {
    if (object.meta_properties.field_type === DEFAULT_CUSTOM_FIELD_TYPE) {
      mappingRow.destinationCustomType = DEFAULT_CUSTOM_FIELD_TYPE;
    } else {
      mappingRow.destinationCustomType = TARGET_OBJECT_CUSTOM_FIELD_TYPES.find(
        (customFieldType) => customFieldType.value === object.meta_properties?.field_type);
    }
  }

  if (object?.meta_properties?.json_array) {
    mappingRow.parseJsonArray = true;
  }

  return mappingRow;
}

export function getFieldDataTypeDisplay(field) {
  let vtd = '';
  if (field.type_display === 'array' && field?.value_type_display) {
    vtd = ` (${ field.value_type_display })`;
  }

  return field.type_display + vtd;
}

export function sortFieldsPrimaryKeyFirst(fieldsList: any[], primaryKeysList: string[]) {
  return fieldsList.sort((fieldOne, fieldTwo) => {
    const fieldOneExists = primaryKeysList.includes(fieldOne.name),
      fieldTwoExists = primaryKeysList.includes(fieldTwo.name);

    return fieldOneExists && fieldTwoExists
      ? 0
      : (fieldOneExists && !fieldTwoExists ? -1 : 1
      );
  });
}

export function getFieldsMappingDTO(fields: any[]) {
  if (!fields.length) {
    return [];
  }

  return fields.map((field) => getFieldMappingDTO(field));
}

export function getFieldMappingDTO(field: any) {
  let fieldMapping: { [key: string]: any } = {
    sf: field.source.name,
    sft: field.source.type_display,
    ig: !field.inclusionItem?.included || false,
    ty: field.source.meta_type
  };

  if (field.destination) {
    let dataType = field.destination.type_display;

    if (dataType === DATA_TYPE_JSON) {
      dataType = DATA_TYPE_JSON_STORED_VALUE;
    }

    fieldMapping = {
      ...fieldMapping,
      df: field.destination.name,
      dft: dataType
    };
  }

  if (field.nested) {
    fieldMapping['ns'] = generateMappingForStruct(field.nested);
  }

  return fieldMapping;
}

export function getIdentifierTypeTooltipMessage(identifierType: ActivationIdentifierType) {
  const keyword = identifierType ? getIdentifierName(identifierType) : '';

  return `Hevo uses the ${ keyword } Identifier to look for matches in the Warehouse and Target, ` +
    `based on which, your selected Sync Behavior decides what to do. ` +
    `To know more, you can read the`;
}
