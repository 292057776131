export const MYSQL_SETUP_WARNING = 'We do not recommend using a MySQL Destination for building your production Pipelines. ' +
  'It can perform poorly even for low volumes of data. If you run into performance issues, these may be unresolvable, ' +
  'and you will have to migrate to a different Destination.';

export const DESTINATION_TYPE_ORDER_BY_USAGE = [
  'BIGQUERY',
  'SNOWFLAKE',
  'REDSHIFT',
  'MYSQL',
  'POSTGRES',
  'MS_SQL',
  'AURORA',
  'DATABRICKS',
  'FIREBOLT'
];
