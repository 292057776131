import { NavigationEnd, Router } from '@angular/router';
import { NavigationType, createPath, To, parsePath, Location } from 'react-router-dom';
import { filter } from 'rxjs/operators';
import { Location as AngularLocation } from '@angular/common';

function createLocation(
  current: string | Location,
  to: To,
  state: any = null,
  key?: string
): Readonly<Location> {
  return {
    pathname: typeof current === 'string' ? current : current.pathname,
    search: '',
    hash: '',
    ...(typeof to === 'string' ? parsePath(to) : to),
    state,
    // TODO: This could be cleaned up.  push/replace should probably just take
    // full Locations now and avoid the need to run through this flow at all
    // But that's a pretty big refactor to the current test suite so going to
    // keep as is for the time being and just let any incoming keys take precedence
    key: (to && (to as Location).key) || key || createKey(),
  };
}

function createKey() {
  return Math.random().toString(36).substr(2, 8);
}

function getHistoryState() {
  try {
    return window.history.state || {};
  } catch (e) {
    // IE 11 sometimes throws when accessing window.history.state
    // See https://github.com/ReactTraining/history/pull/289
    return {};
  }
}

export function createAngularHistory({
  angularRouter,
  angularLocation,
  forceRefresh = false
}: {
  angularRouter: Router;
  angularLocation: AngularLocation;
  forceRefresh?: boolean;
}) {
  let historyInternal;

  const globalHistory = window.history;
  const initialLocation = getDOMLocation(getHistoryState());

  const angularRouterChangesSub = angularRouter.events.pipe(
    filter((event) => {
      return event instanceof NavigationEnd;
    })
  ).subscribe((event: NavigationEnd) => {
    const location = getDOMLocation(getHistoryState());
    historyInternal.location = location;
    historyInternal.length = globalHistory.length;

    notifyListeners({ action: historyInternal.action, location });
  });

  function getDOMLocation(historyState) {
    const { state } = historyState || {};
    const { pathname, search, hash } = window.location;

    const path = pathname + search + hash;

    return createLocation(historyInternal?.location || path, path, state);
  }

  function go(n) {
    if (n === -1) {
      angularLocation.back();
    }

    /**
     * We couldn't find any interface in Angular router to jump to a specific index.
     */
    return;
  }

  function goBack() {
    angularLocation.back();
  }

  function goForward() {
    angularLocation.back();
  }

  function push(path, state) {
    const location = createLocation(historyInternal.location, path, state);
    const href = createHref(location);

    if (forceRefresh) {
      window.location.href = href;
    } else {
      angularRouter.navigateByUrl(angularLocation.normalize(href), { state });
    }
  }

  function replace(path, state) {
    const location = createLocation(historyInternal.location, path, state);
    const href = createHref(location);

    if (forceRefresh) {
      window.location.href = href;
    } else {
      angularRouter.navigateByUrl(angularLocation.normalize(href), { state, replaceUrl: true });
    }
  }

  function block() {
    /**
     * We will let angular Guard handle the blocking.
     */
    return () => {
    };
  }

  let listeners = [];

  function appendListener(fn) {
    let isActive = true;

    function listener(...args) {
      if (isActive) {
        fn(...args);
      }
    }

    listeners.push(listener);

    return () => {
      isActive = false;
      listeners = listeners.filter(item => item !== listener);
    };
  }

  function notifyListeners(...args) {
    listeners.forEach(listener => listener(...args));
  }

  function listen(callback) {
    return appendListener(callback);
  }

  function destroy() {
    angularRouterChangesSub.unsubscribe();
  }

  function createHref(location) {
    return createPath(location);
  }

  historyInternal = {
    length: globalHistory.length,
    action: NavigationType.Pop,
    location: initialLocation,
    createHref,
    push,
    replace,
    go,
    goBack,
    goForward,
    block,
    listen,
    destroy
  };

  return historyInternal;
}
