import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  HdFormikDropDown,
  HdFormikTextField
} from '../../../../../components/FormikElements';
import { GoogleAuth } from '../../../../../components/GoogleAuthFormik';
import { LoadedAt, SanitizeName } from '../../../../../components/NodeConfigOptions';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import { HdButton, HdLink } from '../../../../../components/UIElements';
import useDontMountAtFirst from '../../../../../hooks/useDontMountAtFirst';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';
import { AUTH_FORM_FIELD_NAME, BigqueryResourceType, HEVO_GCS_BUCKET_OPTION } from './model';
import { StreamingWritesConfig } from './StreamingWritesConfig';
import styles from './styles.module.scss';
import useTeamSettingsService from '../../../../../hooks/services/useTeamSettingsService';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import { ConfigDestinationTypeBaseProps } from '../interface';
import { BigqueryDestinationTrackingActions } from './tracking';
import { fetchResourceListFn, groupResourceListOnLocationInReactFormat } from './utils';

const fetchResource = (
  state,
  resource: BigqueryResourceType,
  setResourceList,
  setLoading,
  abortControllerRef: MutableRefObject<AbortController>
) => {
  setLoading(true);
  setResourceList([]);

  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
    // eslint-disable-next-line no-param-reassign
    abortControllerRef.current = new AbortController();
  }

  fetchResourceListFn(state, resource, {
    signal: abortControllerRef.current.signal
  })
    .then(resourceList => {
      if (resource === BigqueryResourceType.PROJECT) {
        setResourceList(resourceList);
      }

      if (resource === BigqueryResourceType.DATASET) {
        setResourceList(groupResourceListOnLocationInReactFormat(resourceList));
      }

      if (resource === BigqueryResourceType.BUCKET) {
        if (resourceList.length > 0) {
          setResourceList(groupResourceListOnLocationInReactFormat([...resourceList]));
        } else {
          setResourceList(groupResourceListOnLocationInReactFormat([HEVO_GCS_BUCKET_OPTION]));
        }
      }

      setLoading(false);
    })
    .catch(err => {
      if (err && err.isPromiseCancel) {
        return;
      }

      setLoading(false);

      if (resource === 'bucket') {
        setResourceList([HEVO_GCS_BUCKET_OPTION]);
      }
    });
};

function RefreshDropdownDataAdornment({
  values,
  name,
  setResourceList,
  setLoading,
  abortControllerRef
}) {
  const { eventTrack } = useAnalyticsTracker();

  let action;

  if (name === BigqueryResourceType.BUCKET) {
    action = BigqueryDestinationTrackingActions.BUCKET_REFRESH_LIST_CLICK;
  } else if (name === BigqueryResourceType.DATASET) {
    action = BigqueryDestinationTrackingActions.DATASET_REFRESH_LIST_CLICK;
  } else {
    action = BigqueryDestinationTrackingActions.PROJECT_REFRESH_LIST_CLICK;
  }

  const handleMouseClick = () => {
    eventTrack({
      action,
      properties: {
        projectId: values?.projectId?.id
      }
    });

    fetchResource(values, name, setResourceList, setLoading, abortControllerRef);
  };

  return (
    <HdLink
      tag='a'
      className={`${styles.refreshAdornment} text-primary `}
      onMouseDown={() => handleMouseClick()}
      direction='right'
      icon='refresh'
      dataId={`bigquery-edit-${name}-refresh`}
    >
      Refresh
    </HdLink>
  );
}

export default function BigQueryEdit({
  isEditing,
  formMetaData,
  canReauthoriseAccount,
  reauthoriseRequest,
  hevoEntityFor,
  isDestinationModeWarehouse
}: ConfigDestinationTypeBaseProps) {
  const { values, setValues, setFieldValue } = useFormikContext<{
    auth: { id: number; email: string };
    projectId: { id: string };
    dataset: { id: string; apiLocation: string };
    bucket: { id: string; apiLocation: string; internalBucket?: boolean };
    authorisedAccount: string;
    streamingWrites: boolean;
  }>();

  const { projects = [], buckets = [], datasets = [] } = formMetaData;

  const [projectList, setProjectList] = useState(projects);
  const [datasetList, setDatasetList] = useState(datasets);
  const [bucketsList, setBucketsList] = useState(buckets);
  const [projectLoading, setProjectLoading] = useState(false);
  const [datasetLoading, setDatasetLoading] = useState(false);
  const [bucketLoading, setBucketLoading] = useState(false);
  const [bucketLocationFilter, setBucketLocationFilter] = useState<string>();

  const projectAbortControllerRef = useRef(new AbortController());
  const datasetAbortControllerRef = useRef(new AbortController());
  const bucketAbortControllerRef = useRef(new AbortController());

  const { getTeamSettings } = useTeamSettingsService();

  const isStreamModeAllowed = getTeamSettings()?.wh_streaming_writes === true;
  const useStreamMode = isStreamModeAllowed && values.streamingWrites;

  useDontMountAtFirst(() => {
    setFieldValue('projectId', null);

    fetchResource(
      values,
      BigqueryResourceType.PROJECT,
      setProjectList,
      setProjectLoading,
      projectAbortControllerRef
    );
  }, [values.auth?.id]);

  useEffect(() => {
    if (!values.dataset?.id) {
      return;
    }

    if (
      values.dataset?.apiLocation?.toLocaleUpperCase() !==
        values.bucket?.apiLocation?.toUpperCase() &&
      !values.bucket?.internalBucket
    ) {
      setFieldValue('bucket', null);
    }

    setBucketLocationFilter(values.dataset.apiLocation?.toUpperCase());
  }, [values.dataset?.id]);

  const filteredBucketList = useMemo(() => {
    const otherRegions = [];
    const filteredRegion = [];

    (bucketsList || []).forEach(bucket => {
      if (!bucketLocationFilter || bucket.apiLocation?.toUpperCase() === bucketLocationFilter) {
        filteredRegion.push(bucket);
      } else {
        otherRegions.push(bucket);
      }
    });

    const filteredList = [...filteredRegion, ...otherRegions];

    if (filteredList.length === 0) {
      filteredList.push(HEVO_GCS_BUCKET_OPTION);
    }

    return filteredList;
  }, [bucketLocationFilter, bucketsList]);

  useDontMountAtFirst(() => {
    setValues(currentValues => ({
      ...currentValues,
      bucket: null,
      dataset: null
    }));

    if (values.projectId?.id) {
      fetchResource(
        values,
        BigqueryResourceType.DATASET,
        setDatasetList,
        setDatasetLoading,
        datasetAbortControllerRef
      );

      fetchResource(
        values,
        BigqueryResourceType.BUCKET,
        setBucketsList,
        setBucketLoading,
        bucketAbortControllerRef
      );
    }
  }, [values.projectId?.id]);

  const dataIdGenerator = useCallback(getDataIdGenerator('bigquery-edit'), []);

  return (
    <>
      {isEditing && (
        <Grid container spacing={2}>
          <Grid item md={6} sm={6}>
            <HdFormControl>
              <Field
                required
                label='Authorized User Account'
                name='auth-account'
                disabled
                suffixText={
                  canReauthoriseAccount && (
                    <HdButton
                      variation='flat'
                      onClick={reauthoriseRequest}
                      dataId={dataIdGenerator('reauth-account')}
                    >
                      Reauthorize
                    </HdButton>
                  )
                }
                helperText='The email address used to authorize the user in BigQuery'
                value={values.authorisedAccount}
                component={HdFormikTextField}
              />
            </HdFormControl>
          </Grid>

          <Grid item md={6} sm={6}>
            <HdFormControl>
              <Field
                name='projectId'
                label='Project'
                showLoading={projectLoading}
                TopAdornment={RefreshDropdownDataAdornment}
                topAdornmentProps={{
                  values,
                  name: BigqueryResourceType.PROJECT,
                  setLoading: setProjectLoading,
                  setResourceList: setProjectList
                }}
                component={HdFormikDropDown}
                required
                options={projectList}
              />
            </HdFormControl>
          </Grid>
        </Grid>
      )}

      {!isEditing && (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <HdFormControl>
                <Field
                  name={AUTH_FORM_FIELD_NAME}
                  provider='BIGQUERY'
                  displayName='Big Query'
                  hevoEntity={hevoEntityFor}
                  component={GoogleAuth}
                />
              </HdFormControl>
            </Grid>

            <Grid item md={12} sm={12}>
              <HdFormControl>
                <Field
                  name='projectId'
                  label='Project'
                  showLoading={projectLoading}
                  TopAdornment={RefreshDropdownDataAdornment}
                  topAdornmentProps={{
                    values,
                    name: BigqueryResourceType.PROJECT,
                    setLoading: setProjectLoading,
                    abortControllerRef: projectAbortControllerRef,
                    setResourceList: setProjectList
                  }}
                  component={HdFormikDropDown}
                  required
                  options={projectList}
                />
              </HdFormControl>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <HdFormControl>
            <Field
              label='Dataset'
              name='dataset'
              group
              showLoading={datasetLoading}
              TopAdornment={RefreshDropdownDataAdornment}
              topAdornmentProps={{
                values,
                abortControllerRef: datasetAbortControllerRef,
                name: BigqueryResourceType.DATASET,
                setLoading: setDatasetLoading,
                setResourceList: setDatasetList
              }}
              options={datasetList}
              component={HdFormikDropDown}
              required
            />
          </HdFormControl>
        </Grid>

        {!useStreamMode && (
          <Grid item md={6} sm={12}>
            <HdFormControl>
              <Field
                options={filteredBucketList}
                required
                group
                showLoading={bucketLoading}
                component={HdFormikDropDown}
                TopAdornment={RefreshDropdownDataAdornment}
                topAdornmentProps={{
                  values,
                  abortControllerRef: bucketAbortControllerRef,
                  name: BigqueryResourceType.BUCKET,
                  setLoading: setBucketLoading,
                  setResourceList: setBucketsList
                }}
                label='GCS bucket'
                name='bucket'
              />
            </HdFormControl>
          </Grid>
        )}
      </Grid>

      {!isDestinationModeWarehouse ? (
        <AdvancedConfig showBorderBottom>
          <div className='w-100'>
            <LoadedAt />

            <SanitizeName
              disabled={!!isEditing}
              dataId={dataIdGenerator('sanitize-name')}
            />

            {isStreamModeAllowed ? (
              <StreamingWritesConfig dataId={dataIdGenerator('')} />
            ) : null}
          </div>
        </AdvancedConfig>
      ) : null}
    </>
  );
}
