<button
  class="owl-dt-control-button owl-dt-control-arrow-button"
  [disabled]="!previousEnabled()"
  [attr.aria-label]="prevButtonLabel"
  type="button"
  tabindex="0"
  (click)="prevYearList($event)">
  <span
    class="owl-dt-control-button-content"
    tabindex="-1">
    <span class="hevo-icon hevo-left-arrow icon-size-1"></span>
  </span>
</button>

<table class="owl-dt-calendar-table owl-dt-calendar-multi-year-table">
  <thead class="owl-dt-calendar-header">
    <tr>
      <th colspan="3">{{tableHeader}}</th>
    </tr>
  </thead>

  <tbody
    owl-date-time-calendar-body
    role="grid"
    [rows]="years"
    [numCols]="3"
    [cellRatio]="3 / 7"
    [activeCell]="activeCell"
    [todayValue]="todayYear"
    [selectedValues]="selectedYears"
    [selectMode]="selectMode"
    (keydown)="handleCalendarKeydown($event)"
    (select)="selectCalendarCell($event)">
  </tbody>
</table>

<button
  class="owl-dt-control-button owl-dt-control-arrow-button"
  [disabled]="!nextEnabled()"
  [attr.aria-label]="nextButtonLabel"
  type="button"
  tabindex="0"
  (click)="nextYearList($event)">
  <span
    class="owl-dt-control-button-content"
    tabindex="-1">
    <span class="hevo-icon hevo-right-arrow icon-size-1"></span>
  </span>
</button>
