import { animate, state, style, transition, trigger } from '@angular/animations';

export function productTourAnim() {
  return trigger('fadeAnim', [
    state('*', style({ opacity: 0 })),
    state('in', style({ opacity: 1 })),
    transition('* => in', [
      style({ opacity: 0 }),
      animate('.3s ease-in-out', style({ opacity: 1 }))
    ]),
    transition('in => *', [
      animate('.3s ease-in-out', style({ opacity: 0 }))
    ])
  ]);
}
