import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';


@Injectable()
export class BodyInteractionService {

  isMouseIntent = false;

  constructor(
    private zone: NgZone,
    @Inject(DOCUMENT) private _document: any
  ) {
    this.zone.runOutsideAngular((): void => {
        this._document.addEventListener(
          'mousedown',
          (e: MouseEvent) => this.onDocumentClick(e)
        );

        this._document.addEventListener(
          'keydown',
          (e: KeyboardEvent) => this.onDocumentKeydown(e)
        );
      }
    );
  }

  onDocumentClick(e: MouseEvent) {
    this.isMouseIntent = true;
    this._document.querySelector('body').classList.add('intent-mouse');
  }

  onDocumentKeydown(e: KeyboardEvent) {
    this.isMouseIntent = false;
    this._document.querySelector('body').classList.remove('intent-mouse');
  }
}
