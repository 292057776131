import { SendgridAccountTimezone } from './model';

export const SENDGRID_ACCOUNT_TIMEZONES: SendgridAccountTimezone[] = [
  {
      'timezone': 'Pacific/Tongatapu',
      'offset': -46800,
      'display': 'GMT+13:00',
      'name': 'Nuku\'alofa',
      'id': 'Pacific/Tongatapu'
  },
  {
      'timezone': 'Pacific/Auckland',
      'offset': -46800,
      'display': 'GMT+12:00',
      'name': 'Auckland, Wellington',
      'id': 'Pacific/Auckland'
  },
  {
      'timezone': 'Etc/GMT-12',
      'offset': -43200,
      'display': 'GMT+12:00',
      'name': 'Fiji, Kamchatka, Marshall Is.',
      'id': 'Etc/GMT-12'
  },
  {
      'timezone': 'Asia/Magadan',
      'offset': -43200,
      'display': 'GMT+12:00',
      'name': 'Magadan',
      'id': 'Asia/Magadan'
  },
  {
      'timezone': 'Etc/GMT-11',
      'offset': -39600,
      'display': 'GMT+11:00',
      'name': 'Solomon Is., New Caledonia',
      'id': 'Etc/GMT-11'
  },
  {
      'timezone': 'Asia/Vladivostok',
      'offset': -39600,
      'display': 'GMT+11:00',
      'name': 'Vladivostok',
      'id': 'Asia/Vladivostok'
  },
  {
      'timezone': 'Asia/Yakutsk',
      'offset': -36000,
      'display': 'GMT+10:00',
      'name': 'Yakutsk',
      'id': 'Asia/Yakutsk'
  },
  {
      'timezone': 'Australia/Brisbane',
      'offset': -36000,
      'display': 'GMT+10:00',
      'name': 'Brisbane',
      'id': 'Australia/Brisbane'
  },
  {
      'timezone': 'Australia/Sydney',
      'offset': -39600,
      'display': 'GMT+10:00',
      'name': 'Canberra, Melbourne, Sydney',
      'id': 'Australia/Sydney'
  },
  {
      'timezone': 'Australia/Hobart',
      'offset': -39600,
      'display': 'GMT+10:00',
      'name': 'Hobart',
      'id': 'Australia/Hobart'
  },
  {
      'timezone': 'Australia/Adelaide',
      'offset': -37800,
      'display': 'GMT+09:30',
      'name': 'Adelaide',
      'id': 'Australia/Adelaide'
  },
  {
      'timezone': 'Australia/Darwin',
      'offset': -34200,
      'display': 'GMT+09:30',
      'name': 'Darwin',
      'id': 'Australia/Darwin'
  },
  {
      'timezone': 'Asia/Irkutsk',
      'offset': -32400,
      'display': 'GMT+09:00',
      'name': 'Irkutsk, Ulaan Bataar',
      'id': 'Asia/Irkutsk'
  },
  {
      'timezone': 'Asia/Tokyo',
      'offset': -32400,
      'display': 'GMT+09:00',
      'name': 'Osaka, Sapporo, Tokyo',
      'id': 'Asia/Tokyo'
  },
  {
      'timezone': 'Asia/Seoul',
      'offset': -32400,
      'display': 'GMT+09:00',
      'name': 'Seoul',
      'id': 'Asia/Seoul'
  },
  {
      'timezone': 'Asia/Hong_Kong',
      'offset': -28800,
      'display': 'GMT+08:00',
      'name': 'Beijing, Chongqing, Hong Kong, Urumqi',
      'id': 'Asia/Hong_Kong'
  },
  {
      'timezone': 'Asia/Krasnoyarsk',
      'offset': -28800,
      'display': 'GMT+08:00',
      'name': 'Krasnoyarsk',
      'id': 'Asia/Krasnoyarsk'
  },
  {
      'timezone': 'Australia/Perth',
      'offset': -28800,
      'display': 'GMT+08:00',
      'name': 'Perth',
      'id': 'Australia/Perth'
  },
  {
      'timezone': 'Asia/Taipei',
      'offset': -28800,
      'display': 'GMT+08:00',
      'name': 'Taipei',
      'id': 'Asia/Taipei'
  },
  {
      'timezone': 'Asia/Bangkok',
      'offset': -25200,
      'display': 'GMT+07:00',
      'name': 'Bangkok, Hanoi, Jakarta',
      'id': 'Asia/Bangkok'
  },
  {
      'timezone': 'Asia/Novosibirsk',
      'offset': -25200,
      'display': 'GMT+07:00',
      'name': 'Novosibirsk',
      'id': 'Asia/Novosibirsk'
  },
  {
      'timezone': 'Asia/Rangoon',
      'offset': -23400,
      'display': 'GMT+06:30',
      'name': 'Yangon Rangoon',
      'id': 'Asia/Rangoon'
  },
  {
      'timezone': 'Asia/Dhaka',
      'offset': -21600,
      'display': 'GMT+06:00',
      'name': 'Astana, Dhaka',
      'id': 'Asia/Dhaka'
  },
  {
      'timezone': 'Asia/Yekaterinburg',
      'offset': -21600,
      'display': 'GMT+06:00',
      'name': 'Ekaterinburg',
      'id': 'Asia/Yekaterinburg'
  },
  {
      'timezone': 'Asia/Katmandu',
      'offset': -20700,
      'display': 'GMT+05:45',
      'name': 'Kathmandu',
      'id': 'Asia/Katmandu'
  },
  {
      'timezone': 'Asia/Kolkata',
      'offset': -19800,
      'display': 'GMT+05:30',
      'name': 'Chennai, Kolkata, Mumbai, New Delhi',
      'id': 'Asia/Kolkata'
  },
  {
      'timezone': 'Asia/Tashkent',
      'offset': -18000,
      'display': 'GMT+05:00',
      'name': 'Asia/Tashkent',
      'id': 'Asia/Tashkent'
  },
  {
      'timezone': 'Asia/Kabul',
      'offset': -16200,
      'display': 'GMT+04:30',
      'name': 'Kabul',
      'id': 'Asia/Kabul'
  },
  {
      'timezone': 'Asia/Dubai',
      'offset': -14400,
      'display': 'GMT+04:00',
      'name': 'Abu Dhabi, Muscat',
      'id': 'Asia/Dubai'
  },
  {
      'timezone': 'Asia/Baku',
      'offset': -14400,
      'display': 'GMT+04:00',
      'name': 'Baku',
      'id': 'Asia/Baku'
  },
  {
      'timezone': 'Europe/Moscow',
      'offset': -14400,
      'display': 'GMT+04:00',
      'name': 'Moscow, St. Petersburg, Volgograd',
      'id': 'Europe/Moscow'
  },
  {
      'timezone': 'Asia/Tbilisi',
      'offset': -14400,
      'display': 'GMT+04:00',
      'name': 'Tbilisi',
      'id': 'Asia/Tbilisi'
  },
  {
      'timezone': 'Asia/Yerevan',
      'offset': -14400,
      'display': 'GMT+04:00',
      'name': 'Yerevan',
      'id': 'Asia/Yerevan'
  },
  {
      'timezone': 'Asia/Tehran',
      'offset': -12600,
      'display': 'GMT+03:30',
      'name': 'Tehran',
      'id': 'Asia/Tehran'
  },
  {
      'timezone': 'Asia/Baghdad',
      'offset': -10800,
      'display': 'GMT+03:00',
      'name': 'Baghdad',
      'id': 'Asia/Baghdad'
  },
  {
      'timezone': 'Europe/Minsk',
      'offset': -10800,
      'display': 'GMT+03:00',
      'name': 'Kaliningrad, Minsk',
      'id': 'Europe/Minsk'
  },
  {
      'timezone': 'Asia/Kuwait',
      'offset': -10800,
      'display': 'GMT+03:00',
      'name': 'Kuwait, Riyadh',
      'id': 'Asia/Kuwait'
  },
  {
      'timezone': 'Africa/Addis_Ababa',
      'offset': -10800,
      'display': 'GMT+03:00',
      'name': 'Nairobi',
      'id': 'Africa/Addis_Ababa'
  },
  {
      'timezone': 'Asia/Amman',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Amman',
      'id': 'Asia/Amman'
  },
  {
      'timezone': 'Europe/Bucharest',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Athens, Bucharest',
      'id': 'Europe/Bucharest'
  },
  {
      'timezone': 'Asia/Beirut',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Beirut',
      'id': 'Asia/Beirut'
  },
  {
      'timezone': 'Africa/Cairo',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Cairo',
      'id': 'Africa/Cairo'
  },
  {
      'timezone': 'Asia/Damascus',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Damascus',
      'id': 'Asia/Damascus'
  },
  {
      'timezone': 'Africa/Blantyre',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Harare, Pretoria',
      'id': 'Africa/Blantyre'
  },
  {
      'timezone': 'Europe/Helsinki',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      'id': 'Europe/Helsinki'
  },
  {
      'timezone': 'Europe/Istanbul',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Istanbul',
      'id': 'Europe/Istanbul'
  },
  {
      'timezone': 'Asia/Jerusalem',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Jerusalem',
      'id': 'Asia/Jerusalem'
  },
  {
      'timezone': 'Europe/Nicosia',
      'offset': -7200,
      'display': 'GMT+02:00',
      'name': 'Nicosia',
      'id': 'Europe/Nicosia'
  },
  {
      'timezone': 'Europe/Amsterdam',
      'offset': -3600,
      'display': 'GMT+01:00',
      'name': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      'id': 'Europe/Amsterdam'
  },
  {
      'timezone': 'Europe/Belgrade',
      'offset': -3600,
      'display': 'GMT+01:00',
      'name': 'Belgrade, Bratislava, Budapest, Prague, Ljubljana',
      'id': 'Europe/Belgrade'
  },
  {
      'timezone': 'Europe/Brussels',
      'offset': -3600,
      'display': 'GMT+01:00',
      'name': 'Brussels, Copenhagen, Madrid, Paris',
      'id': 'Europe/Brussels'
  },
  {
      'timezone': 'Europe/Warsaw',
      'offset': -3600,
      'display': 'GMT+01:00',
      'name': 'Sarajevo, Skopje, Warsaw, Zagreb',
      'id': 'Europe/Warsaw'
  },
  {
      'timezone': 'Africa/Algiers',
      'offset': -3600,
      'display': 'GMT+01:00',
      'name': 'West Central Africa',
      'id': 'Africa/Algiers'
  },
  {
      'timezone': 'Africa/Windhoek',
      'offset': -7200,
      'display': 'GMT+01:00',
      'name': 'Windhoek',
      'id': 'Africa/Windhoek'
  },
  {
      'timezone': 'Africa/Casablanca',
      'offset': 0,
      'display': 'GMT-00:00',
      'name': 'Casablanca',
      'id': 'Africa/Casablanca'
  },
  {
      'timezone': 'Etc/UTC',
      'offset': 0,
      'display': 'GMT-00:00',
      'name': 'Coordinated Universal Time',
      'id': 'Etc/UTC'
  },
  {
      'timezone': 'Africa/Abidjan',
      'offset': 0,
      'display': 'GMT-00:00',
      'name': 'Monrovia, Reykjavik',
      'id': 'Africa/Abidjan'
  },
  {
      'timezone': 'Europe/London',
      'offset': 0,
      'display': 'GMT-00:00',
      'name': 'Dublin, Lisabon, London, Edinburgh',
      'id': 'Europe/London'
  },
  {
      'timezone': 'Atlantic/Cape_Verde',
      'offset': 3600,
      'display': 'GMT-01:00',
      'name': 'Cape Verde Is.',
      'id': 'Atlantic/Cape_Verde'
  },
  {
      'timezone': 'Atlantic/Azores',
      'offset': 3600,
      'display': 'GMT-01:00',
      'name': 'Azores',
      'id': 'Atlantic/Azores'
  },
  {
      'timezone': 'America/Noronha',
      'offset': 7200,
      'display': 'GMT-02:00',
      'name': 'Mid-Atlantic',
      'id': 'America/Noronha'
  },
  {
      'timezone': 'Etc/GMT+2',
      'offset': 7200,
      'display': 'GMT-02:00',
      'name': 'Coordinated Universal Time - 02',
      'id': 'Etc/GMT+2'
  },
  {
      'timezone': 'America/Sao_Paulo',
      'offset': 7200,
      'display': 'GMT-03:00',
      'name': 'Brasilia',
      'id': 'America/Sao_Paulo'
  },
  {
      'timezone': 'America/Argentina/Buenos_Aires',
      'offset': 10800,
      'display': 'GMT-03:00',
      'name': 'Buenos Aires',
      'id': 'America/Argentina/Buenos_Aires'
  },
  {
      'timezone': 'America/Cayenne',
      'offset': 10800,
      'display': 'GMT-03:00',
      'name': 'Cayenne, Fortalenza',
      'id': 'America/Cayenne'
  },
  {
      'timezone': 'America/Godthab',
      'offset': 10800,
      'display': 'GMT-03:00',
      'name': 'Greenland',
      'id': 'America/Godthab'
  },
  {
      'timezone': 'America/Montevideo',
      'offset': 7200,
      'display': 'GMT-03:00',
      'name': 'Montevideo',
      'id': 'America/Montevideo'
  },
  {
      'timezone': 'America/St_Johns',
      'offset': 12600,
      'display': 'GMT-03:30',
      'name': 'Newfoundland',
      'id': 'America/St_Johns'
  },
  {
      'timezone': 'America/Asuncion',
      'offset': 10800,
      'display': 'GMT-04:00',
      'name': 'Asuncion',
      'id': 'America/Asuncion'
  },
  {
      'timezone': 'America/Goose_Bay',
      'offset': 14400,
      'display': 'GMT-04:00',
      'name': 'Atlantic Time, Goose Bay',
      'id': 'America/Goose_Bay'
  },
  {
      'timezone': 'America/Glace_Bay',
      'offset': 14400,
      'display': 'GMT-04:00',
      'name': 'Atlantic Time, Canada',
      'id': 'America/Glace_Bay'
  },
  {
      'timezone': 'America/Cuiaba',
      'offset': 10800,
      'display': 'GMT-04:00',
      'name': 'Cuiaba',
      'id': 'America/Cuiaba'
  },
  {
      'timezone': 'America/La_Paz',
      'offset': 14400,
      'display': 'GMT-04:00',
      'name': 'Georgetown, La Paz, Manaus, San Juan',
      'id': 'America/La_Paz'
  },
  {
      'timezone': 'America/Santiago',
      'offset': 10800,
      'display': 'GMT-04:00',
      'name': 'Santiago',
      'id': 'America/Santiago'
  },
  {
      'timezone': 'America/Caracas',
      'offset': 16200,
      'display': 'GMT-04:30',
      'name': 'Caracas',
      'id': 'America/Caracas'
  },
  {
      'timezone': 'America/Bogota',
      'offset': 18000,
      'display': 'GMT-05:00',
      'name': 'Bogota, Lima, Quito, Rio Branco',
      'id': 'America/Bogota'
  },
  {
      'timezone': 'America/New_York',
      'offset': 18000,
      'display': 'GMT-05:00',
      'name': 'Eastern Time, US & Canada',
      'id': 'America/New_York'
  },
  {
      'timezone': 'America/Havana',
      'offset': 18000,
      'display': 'GMT-05:00',
      'name': 'Cuba',
      'id': 'America/Havana'
  },
  {
      'timezone': 'America/Indiana/Indianapolis',
      'offset': 18000,
      'display': 'GMT-05:00',
      'name': 'Indiana (East)',
      'id': 'America/Indiana/Indianapolis'
  },
  {
      'timezone': 'America/Belize',
      'offset': 21600,
      'display': 'GMT-06:00',
      'name': 'Central America',
      'id': 'America/Belize'
  },
  {
      'timezone': 'America/Chicago',
      'offset': 21600,
      'display': 'GMT-06:00',
      'name': 'Central Time, US & Canada',
      'id': 'America/Chicago'
  },
  {
      'timezone': 'America/Cancun',
      'offset': 21600,
      'display': 'GMT-06:00',
      'name': 'Guadalajara, Mexico City, Monterrey',
      'id': 'America/Cancun'
  },
  {
      'timezone': 'Canada/Saskatchewan',
      'offset': 21600,
      'display': 'GMT-06:00',
      'name': 'Saskatchewan',
      'id': 'Canada/Saskatchewan'
  },
  {
      'timezone': 'America/Dawson_Creek',
      'offset': 25200,
      'display': 'GMT-07:00',
      'name': 'Arizona',
      'id': 'America/Dawson_Creek'
  },
  {
      'timezone': 'America/Chihuahua',
      'offset': 25200,
      'display': 'GMT-07:00',
      'name': 'Chihuahua, La Paz, Mazatlan',
      'id': 'America/Chihuahua'
  },
  {
      'timezone': 'America/Denver',
      'offset': 25200,
      'display': 'GMT-07:00',
      'name': 'Mountain Time, US & Canada',
      'id': 'America/Denver'
  },
  {
      'timezone': 'America/Ensenada',
      'offset': 28800,
      'display': 'GMT-08:00',
      'name': 'Tijuana, Baja California',
      'id': 'America/Ensenada'
  },
  {
      'timezone': 'America/Los_Angeles',
      'offset': 28800,
      'display': 'GMT-08:00',
      'name': 'Pacific Time, US & Canada',
      'id': 'America/Los_Angeles'
  },
  {
      'timezone': 'America/Anchorage',
      'offset': 32400,
      'display': 'GMT-09:00',
      'name': 'Alaska',
      'id': 'America/Anchorage'
  },
  {
      'timezone': 'Etc/GMT+10',
      'offset': 36000,
      'display': 'GMT-10:00',
      'name': 'Hawaii',
      'id': 'Etc/GMT+10'
  },
  {
      'timezone': 'Pacific/Midway',
      'offset': 39600,
      'display': 'GMT-11:00',
      'name': 'Midway Island, Samoa',
      'id': 'Pacific/Midway'
  },
  {
      'timezone': 'Etc/GMT+12',
      'offset': 43200,
      'display': 'GMT-12:00',
      'name': 'International Date Line West',
      'id': 'Etc/GMT+12'
  }
];
