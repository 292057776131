import { Directive, ElementRef } from '@angular/core';

@Directive(
  {
    // tslint:disable-next-line:directive-selector
    selector: '[faCustomLoadingText]'
  }
)
export class FaCustomLoadingTextDirective {
  constructor(private el: ElementRef) {
  }
}
