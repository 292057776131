import { useDrawerRouting } from './useDrawerRouting';

export function useHideDrawer() {
  const { navigateByPath } = useDrawerRouting();

  const hideDrawer = () => {
    navigateByPath(undefined);
  };

  return {
    hideDrawer
  };
}
