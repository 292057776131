import React, { ReactNode, useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
  GlobalSearchEntityAlgoliaSearchResultData,
  GlobalSearchEntityData,
  GlobalSearchMenuItemType
} from '../models';
import styles from '../styles.module.scss';
import useHasPermission from '../../../../hooks/useHasPermission';
import { RBAC_PROTECTED_ROUTES } from '../constants';
import { GlobalSearchEntity } from '../GlobalSearchReducer';

interface GlobalSearchMenuItemProps {
  select: Function;
  children: ReactNode;
  addMenuItemRef: Function;
  entity: GlobalSearchEntityData;
  onEntityClick: Function;
  menuItems: Set<{ current: GlobalSearchMenuItemType }>;
  dataId: string;
  index: number;
}

export function GlobalSearchMenuItem({
  select,
  children,
  addMenuItemRef,
  entity,
  menuItems,
  onEntityClick,
  dataId,
  index
}: GlobalSearchMenuItemProps) {
  const { hasPermission } = useHasPermission();

  const menuItemRef = useRef<GlobalSearchMenuItemType>();

  useEffect(() => {
    if (menuItemRef.current) {
      menuItemRef.current.select = select;
      menuItemRef.current.entity = entity;
      menuItemRef.current.disabled = false;

      if (
        entity.entityType === GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS &&
        (entity as GlobalSearchEntityAlgoliaSearchResultData).category === 'product'
      ) {
        const rbacPermission = RBAC_PROTECTED_ROUTES.find(item =>
          item.route.includes((entity as GlobalSearchEntityAlgoliaSearchResultData).url)
        );

        if (rbacPermission) {
          menuItemRef.current.disabled = !hasPermission(rbacPermission.permission);
        }
      }

      addMenuItemRef(menuItemRef);
    }
  }, [menuItems]);

  return (
    <div
      className={clsx({
        [styles.searchMenuItem]: true,
        [styles.entityDisabled]: !!menuItemRef.current?.disabled
      })}
      ref={menuItemRef}
      onClick={() => onEntityClick(menuItemRef.current.entity)}
      onKeyDown={() => select()}
      role='button'
      tabIndex={0}
      data-id={`${dataId}-menu-item-${index}`}
    >
      {children}
    </div>
  );
}
