import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { NetworkRequestOptions } from '../models/request';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class DataPrivacyService {
  consentUrl = `${this._appConfig.getSecurityServiceURL()}/consent`;

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  getCurrentAccountAccessConsentStatus() {
    const consentStatusUrl = this.consentUrl + '/status';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(consentStatusUrl, options);
  }
}
