import { Component, Input } from '@angular/core';


@Component({
  selector: 'events-stats-tooltip',
  templateUrl: './events-stats-tooltip.component.html',
  styleUrls: [ './events-stats-tooltip.component.scss' ]
})
export class EventsStatsTooltipComponent {
  @Input() historicalEvents: number;
  @Input() billableEvents: number;
  @Input() displayString: string;
}
