import { TargetObjectSettings } from '../models/target-object-settings';
import {
  GoogleSheetsActivationDatatype,
  GoogleSheetsActivationSyncBehaviour,
  GoogleSheetsMappingConfig
} from './models';


export class GoogleSheetsTargetObjectSettings extends TargetObjectSettings {
  googleSheetsMappingConfig: GoogleSheetsMappingConfig;

  initialiseConfig() {
    this.syncBehaviour = GoogleSheetsActivationSyncBehaviour.FULL_SYNC;
    this.googleSheetsMappingConfig = {
      worksheetId: null,
      worksheetName: null,
      startRange: null,
      endRange: null,
      dataType: GoogleSheetsActivationDatatype.RAW
    };
  }

  setConfigFromRawData(rawData: any) {
    if (!rawData) {
      return;
    }

    this.syncBehaviour = GoogleSheetsActivationSyncBehaviour.toArray().find(
      (syncBehaviourType) => syncBehaviourType.value === rawData.sync_mode
    );

    if (rawData.google_sheet_config) {
      this.googleSheetsMappingConfig = {
        worksheetId: rawData.google_sheet_config.work_sheet_id || null,
        worksheetName: rawData.google_sheet_config.work_sheet_name || null,
        startRange: rawData.google_sheet_config.start_range,
        endRange: rawData.google_sheet_config.end_range,
        dataType: GoogleSheetsActivationDatatype[rawData.google_sheet_config.value_input_option]
      };
    }
  }

  convertConfigToRawData(): any {
    return {
      sync_mode: this.syncBehaviour?.value,
      google_sheet_config: {
        work_sheet_id: this.googleSheetsMappingConfig.worksheetId,
        work_sheet_name: this.googleSheetsMappingConfig.worksheetName,
        value_input_option: this.googleSheetsMappingConfig.dataType,
        start_range: this.googleSheetsMappingConfig.startRange,
        end_range: this.googleSheetsMappingConfig.endRange
      }
    };
  }
}
