import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SourceService } from './source.service';


@Injectable()
export class AutoMappingService {
  statusUpdateSubject = new Subject<any>();

  constructor(
    private _sourceService: SourceService
  ) {
  }

  updateStatus(integrationId: number, status: string): Observable<void> {
    return this._sourceService.updatePipelineAutoMappingStatus(integrationId, status).pipe(
      tap(() => {
        this.statusUpdateSubject.next({
          integrationId,
          status
        });
      })
    );
  }
}
