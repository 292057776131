import React, { useCallback, useMemo } from 'react';
import HdIcon from '../../../HdIcon';
import { NumberInput } from './NumberInput';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';

const baseClassName = 'react-calendar';

const HOUR = 'hour';
const MINUTE = 'minute';
const SECOND = 'second';

export function TimeView<T>({
  value,
  maxDate,
  minDate,
  handleChange
}: {
  value: T;
  minDate: T;
  maxDate: T;
  handleChange: (date: T) => void;
}) {
  const { dateTimeAdapter, splitDate, checkIfDatesAreMatchingTill, getFieldValue } =
    useDateTimeAdapter<T>();
  const { hour, minutes, seconds } = splitDate(value);
  const handleTimeChange = (changedValue: string, field: string) => {
    const formattedValue = +changedValue;
    let updatedDate = dateTimeAdapter.clone(value);
    switch (field) {
      case HOUR:
        updatedDate = dateTimeAdapter.setHours(updatedDate, formattedValue);
        break;
      case MINUTE:
        updatedDate = dateTimeAdapter.setMinutes(updatedDate, formattedValue);
        break;
      case SECOND:
        updatedDate = dateTimeAdapter.setSeconds(updatedDate, formattedValue);
        break;
      default:
    }

    handleChange(updatedDate);
  };

  /**
   * Compares two date objects either returns the value of `field2` from first date object if `field` matches or the default value
   *
   * if max date is 16-01-01 02:23:34 and the current selected date is 16-01-01 02:X:X
   *  returns 23
   *  if max date is 16-01-01 02:23:34 and the current selected date is 16-01-01 01:X:X
   *  returns default value(59)
   */
  const compareDateObjects = useCallback(
    (date1, date2, field, field2, defaultValue) => {
      if (checkIfDatesAreMatchingTill(date1, date2, field)) {
        return getFieldValue(date1, field2);
      }

      return defaultValue;
    },
    [dateTimeAdapter]
  );

  const hourMin = useMemo(
    () => compareDateObjects(minDate, value, 'day', 'hour', 0),
    [minDate, value, compareDateObjects]
  );
  const minuteMin = useMemo(
    () => compareDateObjects(minDate, value, 'hour', 'minute', 0),
    [minDate, value, compareDateObjects]
  );
  const secondMin = useMemo(
    () => compareDateObjects(minDate, value, 'minute', 'second', 0),
    [minDate, value, compareDateObjects]
  );

  const hourMax = useMemo(
    () => compareDateObjects(maxDate, value, 'day', 'hour', 23),
    [maxDate, value, compareDateObjects]
  );
  const minuteMax = useMemo(
    () => compareDateObjects(maxDate, value, 'hour', 'minute', 59),
    [maxDate, value, compareDateObjects]
  );
  const secondMax = useMemo(
    () => compareDateObjects(maxDate, value, 'minute', 'second', 59),
    [maxDate, value, compareDateObjects]
  );

  return (
    <div className={`${baseClassName}__timeContainer owl-dt-container-row owl-dt-timer`}>
      <span className='center-flex-row text-secondary'>
        <HdIcon name='time mr-1' />
        <span>Time</span>
      </span>

      <div>
        <NumberInput
          activeValue={hour}
          minValue={hourMin}
          maxValue={hourMax}
          label='Hours'
          onChange={changedValue => {
            handleTimeChange(changedValue, HOUR);
          }}
        />

        <div className='owl-dt-timer-divider' />

        <NumberInput
          minValue={minuteMin}
          maxValue={minuteMax}
          label='Minutes'
          activeValue={minutes}
          onChange={changedValue => {
            handleTimeChange(changedValue, MINUTE);
          }}
        />

        <div className='owl-dt-timer-divider' />

        <NumberInput
          minValue={secondMin}
          maxValue={secondMax}
          label='Seconds'
          activeValue={seconds}
          onChange={changedValue => {
            handleTimeChange(changedValue, SECOND);
          }}
        />
      </div>
    </div>
  );
}
