import { useMemo } from 'react';
import { addDelimiter } from '../legacy-utils/string';
import { formatDataIdAttr } from './dataIdFormatter';

export function getDataIdGenerator(prefix = '', constant = '') {
  return function (suffix) {
    const str = addDelimiter('-', `${prefix}`, `${constant}`, `${suffix}`);
    return formatDataIdAttr(str);
  };
}

export const getDataId =
  (suffix: string) =>
  (prefix = '') =>
  (constant = '') => {
    const str = addDelimiter('-', `${prefix}`, `${constant}`, `${suffix}`);
    return formatDataIdAttr(str);
  };

interface DataIDsContract {
  base: string;
  [key: string]: string | Function;
}

export const getDataIdsFromContract = <T extends DataIDsContract>(
  dataContract: T,
  parentDataId = ''
): Omit<T, 'base'> => {
  const { base, ...dataIdContract } = dataContract;
  const dataIdGenerator = getDataIdGenerator(base, parentDataId);

  return Object.entries(dataIdContract).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]:
        typeof value === 'string'
          ? dataIdGenerator(value)
          : (...args) => dataIdGenerator(value(...args))
    }),
    {}
  ) as Omit<T, 'base'>;
};

export const useMemoizedDataIds = <T extends DataIDsContract>(contract: T, parentDataId = '') =>
  useMemo(() => getDataIdsFromContract<T>(contract, parentDataId), [parentDataId]);
