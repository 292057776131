import { Component, HostBinding, Input } from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'form-error',
  templateUrl: './form-error.component.html',
  styleUrls: [ '../hd-form-alert.scss', './form-error.component.scss' ]
})
export class FormErrorComponent {
  @HostBinding('class.faded-error') @Input() configFormError = false;

  constructor() {
  }
}
