export enum RelatedTaskCategoryType {
  LOG_TYPE = 'LOG_TYPE',
  SINGLE_LOG_TYPE = 'SINGLE_LOG_TYPE',
  HISTORICAL_TABLE_TYPE = 'HISTORICAL_TABLE_TYPE',
  TABLE_OR_REPORT_TYPE = 'TABLE_OR_REPORT_TYPE',
  REFRESHER_TYPE = 'REFRESHER_TYPE',
  GENERIC_SINGLE_LOG_TYPE = 'GENERIC_SINGLE_LOG_TYPE',
  MULTI_OBJECT_TYPE_HISTORICAL = 'MULTI_OBJECT_TYPE_HISTORICAL'
}

export const RelatedTaskCategoryTypeActionSuffix = {
  [RelatedTaskCategoryType.LOG_TYPE]: 'Incremental Load',
  [RelatedTaskCategoryType.SINGLE_LOG_TYPE]: 'Incremental Load',
  [RelatedTaskCategoryType.HISTORICAL_TABLE_TYPE]: 'Historical Load',
  [RelatedTaskCategoryType.TABLE_OR_REPORT_TYPE]: 'Incremental Load',
  [RelatedTaskCategoryType.REFRESHER_TYPE]: 'Data Refresher',
  [RelatedTaskCategoryType.GENERIC_SINGLE_LOG_TYPE]: 'Incremental Load',
  [RelatedTaskCategoryType.MULTI_OBJECT_TYPE_HISTORICAL]: 'Historical Load'
};
