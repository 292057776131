import { Component, ElementRef, OnInit } from '@angular/core';
import TopBar from '../../../react/containers/dashboard/TopBar';
import { BridgeService } from '../../react-bridge/services/bridge.service';

@Component({
  selector: 'top-bar-react',
  template: ``,
  providers: [ BridgeService ]
})
export class TopBarReactComponent implements OnInit {
  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef
  ) {}

  ngOnInit() {
    this._renderComponent();
  }

  private _renderComponent() {
    this._bridgeService.mount(
      TopBar,
      this._el.nativeElement
    );
  }
}
