import { Injectable } from '@angular/core';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';
import { ExecIfDefined } from '../../../react/legacy-utils/decorators';

declare var _hsq: any;

@Injectable({ providedIn: 'root' })
export class HubspotService implements UITracker {
  @ExecIfDefined('_hsq')
  init(trackingConfig) {
    _hsq.push(['doNotTrack', { track: true }]);
  }

  @ExecIfDefined('_hsq')
  identifyUser(user: User) {
    _hsq.push(['identify', {
      email: user.email,
      'userId': user.id,
      'env': user.clusterId,
      'company': user.teamName,
      'companyId': user.teamId,
      'name': user.name,
      'createdTs': user.createdTs
    }]);
  }

  updateIdentity(user: User) {
    this.identifyUser(user);
  }

  @ExecIfDefined('_hsq')
  updateUserProperties(user: User, data: any) {
    _hsq.push(['identify', {
      email: user.email,
      ...data
    }]);
  }

  reset() {
    document.cookie = 'hubspotutk=;Path=/;Domain=.your_domain.com;expires=Thu, 01 Jan 1970 0';
  }

  @ExecIfDefined('_hsq')
  stop() {
    _hsq.push(['doNotTrack']);
  }
}
