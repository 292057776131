// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.wrapper_VpeOA {\n  width: 100%;\n  margin: auto;\n}\n.wrapper_VpeOA .recoveryCodes__schf {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  margin: auto;\n  min-height: 288px;\n}\n.wrapper_VpeOA .recoveryCodeWrapper_J5QS2 {\n  display: flex;\n  justify-content: center;\n  width: 33.33%;\n  margin-bottom: 32px;\n}\n.wrapper_VpeOA .recoveryCode_cYl8K {\n  padding: 12px 16px;\n  width: 172px;\n  border-radius: 25px;\n  background: var(--default-bg-color);\n  font-weight: 700;\n  text-align: center;\n  position: relative;\n}\n.wrapper_VpeOA .recoveryCode_cYl8K:after {\n  content: \"\";\n  position: absolute;\n  top: -4px;\n  right: -4px;\n  bottom: -4px;\n  left: -4px;\n  border: dashed 1px var(--divider-color);\n  border-radius: 25px;\n}\n.wrapper_VpeOA .recoveryCode_cYl8K.disabled__XwY2 {\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  text-transform: uppercase;\n  color: var(--text-secondary-color);\n  background: repeating-linear-gradient(45deg, var(--divider-color), var(--divider-color) 1px, var(--secondary-bg-color) 1px, var(--secondary-bg-color) 8px);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_VpeOA",
	"recoveryCodes": "recoveryCodes__schf",
	"recoveryCodeWrapper": "recoveryCodeWrapper_J5QS2",
	"recoveryCode": "recoveryCode_cYl8K",
	"disabled": "disabled__XwY2"
};
export default ___CSS_LOADER_EXPORT___;
