import { NgModule } from '@angular/core';
import { UnsubscribeNotificationsPageReactComponent } from './unsubscribe-notifications-page-react.component';
import { NotificationSubscriptionRoutingModule } from './notification-subscription-routing.module';

@NgModule({
  imports: [
    NotificationSubscriptionRoutingModule
  ],
  declarations: [
    UnsubscribeNotificationsPageReactComponent
  ]
})
export class NotificationSubscriptionModule {
}
