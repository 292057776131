import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DOCUMENT } from '@angular/common';
import { DialogBase } from '../dialog-base';
import { fadeAnim, popupAnim } from '../animations';
import { Angulartics2 } from 'angulartics2';
import { TRACKER_UPGRADE_PLAN_CLICK } from './constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'upgrade-to-live-chat-dialog',
  templateUrl: './upgrade-to-live-chat-dialog.component.html',
  styleUrls: ['./upgrade-to-live-chat-dialog.component.scss'],
  animations: [ popupAnim(), fadeAnim() ]
})
export class UpgradeToLiveChatDialogComponent extends DialogBase implements OnInit, DialogBase {

  currentPlan: string;
  isOwner: boolean;
  upgradeToChatTooltipText = 'Contact your workspace owner to upgrade your plan';

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: any,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _angulartics: Angulartics2,
    private _router: Router
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  show() {
    super.show();
    this.currentPlan = this._data.currentPlan;
    this.isOwner = this._data.isOwner;
  }

  navigateToChangePlan() {
    this.hide();

    this._router.navigate(
      ['payment', 'plan-details'],
      {
        queryParams: {
          editing: true,
          next: this._router.url
        }
      }
    );

    this._angulartics.eventTrack.next({
      action: TRACKER_UPGRADE_PLAN_CLICK,
      properties: {
        'Current Plan': this.currentPlan
      }
    });
  }
}
