import { GENERIC_VARIATION } from '../nodes-settings/constants';
import { EntityVariationData } from '../nodes-settings/models';
import { JobMode, JobModeEnum } from './job-mode';
import { SOURCE_TYPES, SourceType } from './source-type';


export function compareJobModes(a: JobMode, b: JobMode) {
  return (a.virtualValue ? a.virtualValue : a.value) === (b.virtualValue ? b.virtualValue : b.value);
}

export function canSelectJobMode(sourceTypeMetaData: SourceType): boolean {
  return Array.isArray(sourceTypeMetaData.jobModes) && sourceTypeMetaData.jobModes.length > 1;
}

export function getDefaultJobMode(sourceTypeMetaData: SourceType): JobMode {
  if (Array.isArray(sourceTypeMetaData.jobModes) && sourceTypeMetaData.jobModes.length === 1) {
    return sourceTypeMetaData.jobModes[0];
  }

  return undefined;
}

export function getJobModeFromSourceConfig(sourceTypeIdentifier: string, config: { [key: string]: any }): JobMode {
  if (config.type) {
    return (SOURCE_TYPES[sourceTypeIdentifier].jobModes || [])
      .find((mode: JobMode) => mode.value === config.type);
  }

  if (config.has_oplog) {
    return JobModeEnum.OPLOG;
  }

  if (config.has_change_streams) {
    return JobModeEnum.CHANGE_STREAMS;
  }

  if (sourceTypeIdentifier === 'MONGODB') {
    return JobModeEnum.COLLECTION;
  }

  if (SOURCE_TYPES[sourceTypeIdentifier].isJDBCType) {
    return JobModeEnum.TABLE;
  }
}

export function isSourceTypeBase(sourceTypeIdentifier: string) {
  return !SOURCE_TYPES[sourceTypeIdentifier].parent;
}

export function getBaseSourceType(sourceTypeIdentifier: string) {
  const sourceTypeRef = SOURCE_TYPES[sourceTypeIdentifier];

  if (!sourceTypeRef.parent) {
    return sourceTypeRef;
  }

  return SOURCE_TYPES[sourceTypeRef.parent];
}

export function sourceTypeHasVariations(sourceTypeIdentifier: string) {
  return SOURCE_TYPES[sourceTypeIdentifier].variations?.length;
}

export function fetchVariationFromSourceConfig(sourceTypeIdentifier: string, sourceConfig: any) {
  if (sourceConfig) {
    if (sourceTypeIdentifier === 'MONGODB') {
      if (sourceConfig.isServerMongoDBAtlas || sourceConfig.is_server_mongodb_atlas) {
        return _getEntityVariationData(sourceTypeIdentifier, 'ATLAS');
      }

      return _getEntityVariationData(sourceTypeIdentifier, GENERIC_VARIATION);
    }

    let variationIdentifier = sourceConfig.variation || sourceConfig.provider;

    if (variationIdentifier === 'KAFKA') {
      variationIdentifier = GENERIC_VARIATION;
    }

    return _getEntityVariationData(sourceTypeIdentifier, variationIdentifier);
  }
}

export function getSourceTypeIdentifierUsingVariation(sourceTypeIdentifier: string, variation: string) {
  if (!isSourceTypeBase(sourceTypeIdentifier) || !sourceTypeHasVariations(sourceTypeIdentifier)) {
    return sourceTypeIdentifier;
  }

  if (!variation || variation === GENERIC_VARIATION) {
    return sourceTypeIdentifier;
  }

  return Object.values(SOURCE_TYPES).find((sourceTypeMetaData) => {
    if (sourceTypeMetaData.variation) {
      return sourceTypeMetaData.variation === variation && sourceTypeMetaData.parent === sourceTypeIdentifier;
    }
  })?.name;
}

export function getDerivedSourceTypeFromSourceConfig(sourceTypeIdentifier: string, sourceConfig: any) {
  const sourceTypeMetaData = SOURCE_TYPES[sourceTypeIdentifier];

  if (sourceTypeMetaData.parent || !sourceTypeMetaData.variations?.length) {
    return sourceTypeIdentifier;
  }

  const variation = fetchVariationFromSourceConfig(sourceTypeIdentifier, sourceConfig);

  return getSourceTypeIdentifierUsingVariation(sourceTypeIdentifier, variation.value);
}

function _getEntityVariationData(sourceTypeIdentifier: string, variant: string): EntityVariationData {
  const nodeVariations = SOURCE_TYPES[sourceTypeIdentifier].variations;

  if (variant && nodeVariations) {
    return nodeVariations.find((variation) => variation.value === variant);
  }

  // When no variant exists
  if (nodeVariations) {
    return nodeVariations.find((variation) => variation.value === GENERIC_VARIATION);
  }

  return null;
}

export function fetchParentAndVariations(sourceTypeIdentifier: string): SourceType[] {
  return Object.values(SOURCE_TYPES)
  .filter(sourceType => sourceType.name === sourceTypeIdentifier || sourceType.parent === sourceTypeIdentifier);
}

export function fetchParentAndVariationsNames(sourceTypeIdentifier: string): string[] {
  return fetchParentAndVariations(sourceTypeIdentifier).map(sourceType => sourceType.name);
}
