export enum UserAuthenticationMethodsEnum {
  PASSWORD = 'PASSWORD',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  SAML = 'SAML'
}

export interface UserAuthenticationMethod {
  title: string;
  subtext?: string;
  value: UserAuthenticationMethodsEnum;
  docLink?: string;
}

export const AUTHENTICATION_METHODS: UserAuthenticationMethod[] = [
  {
    title: 'Require Password or Google Authentication',
    subtext: 'Your workspace members can set up a password or use Google authentication.',
    value: UserAuthenticationMethodsEnum.PASSWORD
  },
  {
    title: 'Enforce Google Authentication',
    subtext:
      'Everyone in your workspace will be required to sign in through their Google work account.',
    value: UserAuthenticationMethodsEnum.GOOGLE_LOGIN
  },
  {
    title: 'Require SAML Single Sign-On (SSO)',
    subtext: 'Integrate with an identity provider such as Okta.',
    value: UserAuthenticationMethodsEnum.SAML,
    docLink: '/account-management/team-settings/enforcing-saml-sso-across-your-team/'
  }
];

export interface SAMLConfiguration {
  relayState: string;
  idpEntityId: string;
  autoProvisioning: boolean;
  sslCertificate: any;
  loginURL: string;
  logoutURL?: string;
  customErrorPageURL?: string;
  pickUserId: boolean;
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserAuthConfiguration {
  method: UserAuthenticationMethodsEnum;
  isTFAEnforced: boolean;
  samlConfig: SAMLConfiguration;
}

/**
 *
 * @param rawData to be consumed by FE
 * @returns SAML config
 */
export function samlConfigurationFactory(rawData: any): SAMLConfiguration {
  let config: SAMLConfiguration = {
    relayState: 'relay_state' in rawData ? rawData.relay_state : null,
    idpEntityId: 'entity_id' in rawData ? rawData.entity_id : null,
    autoProvisioning: 'automatic_provisioning' in rawData ? rawData.automatic_provisioning : false,
    sslCertificate: null,
    loginURL: 'login_url' in rawData ? rawData.login_url : null,
    pickUserId: 'use_name_id' in rawData ? rawData.use_name_id : false,
    email: null,
    firstName: null,
    lastName: null
  };

  if ('cert' in rawData) {
    config.sslCertificate = {
      name: 'cert_file_name' in rawData ? rawData.cert_file_name : 'cert.pem',
      content: rawData.cert
    };
  }

  if ('attribute_mapping' in rawData) {
    config = {
      ...config,
      email: 'email' in rawData.attribute_mapping ? rawData.attribute_mapping.email : null,
      firstName:
        'first_name' in rawData.attribute_mapping ? rawData.attribute_mapping.first_name : null,
      lastName:
        'last_name' in rawData.attribute_mapping ? rawData.attribute_mapping.last_name : null
    };
  }

  return config;
}

/**
 * converts BE config to FE usable config
 *
 * @param rawData
 * @returns authentication config to be used by frontend
 */
export function getUserAuthConfigFromRawData(rawData: any): UserAuthConfiguration {
  if (!rawData) {
    return;
  }

  let method: UserAuthenticationMethodsEnum;

  if (rawData.is_google_login_enforced) {
    method = UserAuthenticationMethodsEnum.GOOGLE_LOGIN;
  } else if (rawData.is_saml_enforced) {
    method = UserAuthenticationMethodsEnum.SAML;
  } else {
    method = UserAuthenticationMethodsEnum.PASSWORD;
  }

  const configuration: UserAuthConfiguration = {
    method,
    isTFAEnforced: rawData.is_tfa_enforced,
    samlConfig:
      'saml_config' in rawData
        ? samlConfigurationFactory(rawData.saml_config)
        : samlConfigurationFactory({})
  };

  return configuration;
}

/**
 * converts FE config to BE payload
 *
 * @param config authentication config
 * @returns rawdata to be consumed by the backend
 */
export function getPayloadFromUserAuthConfig(
  method: UserAuthenticationMethodsEnum,
  samlConfig: SAMLConfiguration,
  isTFAEnforced: boolean
): any {
  if (!samlConfig || !method) {
    return;
  }

  const requestBody = {
    is_google_login_enforced: false,
    is_saml_enforced: false,
    is_password_mode_enabled: false,
    is_tfa_enforced: isTFAEnforced,
    saml_config: {
      relay_state: samlConfig.relayState,
      entity_id: samlConfig.idpEntityId,
      automatic_provisioning: samlConfig.autoProvisioning,
      cert_file_name: samlConfig.sslCertificate?.name,
      cert: samlConfig.sslCertificate?.content,
      login_url: samlConfig.loginURL,
      use_name_id: samlConfig.pickUserId,
      attribute_mapping: {
        email: samlConfig.email,
        first_name: samlConfig.firstName,
        last_name: samlConfig.lastName
      }
    }
  };

  if (method === UserAuthenticationMethodsEnum.GOOGLE_LOGIN) {
    requestBody.is_google_login_enforced = true;
  } else if (method === UserAuthenticationMethodsEnum.SAML) {
    requestBody.is_saml_enforced = true;
  } else {
    requestBody.is_password_mode_enabled = true;
  }

  return requestBody;
}
