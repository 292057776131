import {
  RBAC_DISABLED_MESSAGE,
  RBAC_OBSERVER_DISABLED_MESSAGE,
  RBAC_RESOLVED_ROLE_MESSAGE
} from '../../../../app/core/models/rbac';

export enum MemberPageURLBasedAction {
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  DECLINE_INVITE = 'DECLINE_INVITE'
}

export interface InviteActionResponse {
  action?: MemberPageURLBasedAction;
  error?: any;
  userName?: string;
  userEmail?: string;
  code?: string;
}

// eslint-disable-next-line no-shadow
export enum MemberActionables {
  IS_RESOLVED_ROLE,
  IS_ASSIGNED_ROLE,
  CAN_NOT_ACT_ON_ROLE,
  CAN_ACT_ON_ROLE
}

// All the roles are not declared here, the roles will be fetched dynamically from backend.
// only the roles which have specific use-cases are defined here.
export enum RolesEnum {
  TEAM_ADMIN = 'TEAM_ADMIN',
  TEAM_COLLABORATOR = 'TEAM_COLLABORATOR',
  BILLING_ADMIN = 'BILLING_ADMIN',
  PIPELINE_ADMIN = 'PIPELINE_ADMIN',
  PIPELINE_COLLABORATOR = 'PIPELINE_COLLABORATOR',
  MODELS_AND_WORKFLOWS_ADMIN = 'MODELS_AND_WORKFLOWS_ADMIN',
  MODELS_AND_WORKFLOWS_COLLABORATOR = 'MODELS_AND_WORKFLOWS_COLLABORATOR',
  ACTIVATION_ADMIN = 'ACTIVATION_ADMIN',
  ACTIVATION_COLLABORATOR = 'ACTIVATION_COLLABORATOR',
  OBSERVER = 'OBSERVER'
}

export const optionsTooltipResolver = (option, rolesActionsMap) => {
  const { roleName } = option || {};

  if (roleName === RolesEnum.OBSERVER) {
    return RBAC_OBSERVER_DISABLED_MESSAGE;
  }

  if (rolesActionsMap[roleName] === MemberActionables.CAN_NOT_ACT_ON_ROLE) {
    return RBAC_DISABLED_MESSAGE;
  }

  if (rolesActionsMap[roleName] === MemberActionables.IS_RESOLVED_ROLE) {
    return RBAC_RESOLVED_ROLE_MESSAGE;
  }

  return null;
};
