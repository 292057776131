import React from 'react';
import { Typography } from '@mui/material';
import { HevoEntityItem } from '../../../../../../app/core/models/hevo-entity';
import HdSetupGuideLink from '../../../../../components/UIElements/HdSetupGuideLink';
import { s3NewConfigureDocLink } from './constants';
import { hostUrlLabel, hostUrlSection } from '../Sql/constants';

export const getHostNameHelpText = (destinationTypeTrackingProps: { destinationType: string, context: HevoEntityItem }) => {
  return (
    <Typography variant='caption'>
      <span className='mr-1'>The Amazon Resource Name for the IAM role.</span>
      <HdSetupGuideLink
        setupGuideLink={s3NewConfigureDocLink}
        section={hostUrlSection}
        label={hostUrlLabel}
        properties={destinationTypeTrackingProps}
        scrollOffset={-50}
        highlightTitle
        size='sm'
        icon='right'
        dataId='s3-new-host-url'
      >
        Need help?
      </HdSetupGuideLink>
    </Typography>
  );
};
