import React from 'react';
import { DestinationData } from '../../models';
import { NotificationView } from '../notification-view';
import useEntityLogo from '../../../../hooks/useEntityLogo';
import styles from './styles.module.scss';

export function DestinationView({ data }: NotificationView<DestinationData>) {
  const destinationThumbnail = useEntityLogo(data.logoUrl, data.darkModeLogoUrl);

  return (
    <div className={styles.destinationViewContainer}>
      {data?.logoUrl ? (
        <div className={styles.nodeImage}>
          <img src={destinationThumbnail} alt='' />
        </div>
      ) : null}

      <div className='text-ellipsis text-caption-1'>{data.name}</div>
    </div>
  );
}
