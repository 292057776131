import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { ColorMode } from '../../core/models/color-mode';
import { ColorModeService } from '../../core/service/color-mode.service';
import { convertToRgba } from '../../../react/legacy-utils/colors';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'node-logo',
  templateUrl: './node-logo.component.html',
  styleUrls: [ './node-logo.component.scss' ]
})
export class NodeLogoComponent implements OnChanges {
  @Input() logoURL: string;
  @Input() logoURLDarkMode: string;
  @Input() primaryColor: string;
  @Input() darkModePrimaryColor: string;
  @Input() roundedBorders: boolean;
  @Input() disabled = false;

  /*
   * You can refer to this for size index
   1: (16px, 4px),
   2: (16px, 6px),
   3: (20px, 6px),
   4: (24px, 6px),
   5: (24px, 8px),
   6: (36px, 8px),
   7: (36px, 12px),
   8: (16px, 2px),
   9: (36px, 4px)
   * */
  @Input() size = 5;

  colorMode$: Observable<ColorMode>;
  colorModes = ColorMode;

  bgColor: string;
  darkModeBgColor: string;

  constructor(
    private _colorModeService: ColorModeService
  ) {
    this.colorMode$ = this._colorModeService.colorMode$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.primaryColor) {
      this.bgColor = convertToRgba(this.primaryColor, 0.10);
    }

    if (changes.darkModePrimaryColor) {
      this.darkModeBgColor = convertToRgba(this.darkModePrimaryColor, 0.15);
    }
  }
}
