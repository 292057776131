// eslint-disable-next-line import/no-cycle
import PipelinesAPI from '../../../PipelinesAPI';

export const GENERIC_SOURCE_META_DATA_RESOLVER: {
  [key: string]: (authData?: any, rawData?: any) => {};
} = {
  RECURLY_V2: () =>
    PipelinesAPI.getRecurlyEuEnabledStatus().then((res: any) => ({
      showEuToggle: res?.data
    }))
};
