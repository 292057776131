import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { CONNECTION_ERROR_MSG, INVALID_INPUT_MSG } from '../../app/core/constants';

export function getErrorMessageFromObj(err: any) {
  if (!err) {
    return;
  }

  if (err.status <= 0) {
    return CONNECTION_ERROR_MSG;
  }

  return err.status === 422
    ? INVALID_INPUT_MSG
    : (
      err.error
        ? (err.error.error_message && err.error.error_message.length > 0 ?
        err.error.error_message
        : err.error.message ? err.error.message : err.message )
        : err.message
    );
}

export function getSuccessMessageFromObj(response: any) {
  if (!response) {
    return;
  }

  const res: any = Object.assign({}, response);
  return res.body ? res.body.success_message : res.success_message;
}

export class NativeHttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(key: string): string { return encodeURIComponent(key); }

  encodeValue(value: string): string { return encodeURIComponent(value); }

  decodeKey(key: string): string { return decodeURIComponent(key); }

  decodeValue(value: string) { return decodeURIComponent(value); }
}

export function createHttpParams(params: {}): HttpParams {
  let httpParams: HttpParams = new HttpParams({
    encoder: new NativeHttpUrlEncodingCodec()
  });

  Object.keys(params).forEach(param => {
    if (Array.isArray(params[param])) {
      params[param].forEach((item) => {
        httpParams = httpParams.append(param, item);
      });
    } else if (typeof params[param] !== 'undefined' && params[param] !== null) {
        httpParams = httpParams.set(param, params[param]);
    }
  });

  return httpParams;
}

export function getBackendErrorCodeFromObj(error: any) {
  return error && error.error ? error.error.error_code : null;
}
