import React from 'react';
import clsx from 'clsx';

import { isWeekend } from '../shared/dates';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';

const className = 'react-calendar__month-view__weekdays';
const weekdayClassName = `${className}__weekday`;

export default function Weekdays<T>() {
  const { dateTimeAdapter, splitDate } = useDateTimeAdapter<T>();

  const { year, month } = splitDate(dateTimeAdapter.now());

  const beginOfMonth = dateTimeAdapter.createDate(year, month, 1);

  const dayOfWeek = dateTimeAdapter.getDay(beginOfMonth);

  return <div className={`${className} react-calendar__row`}>
    {dateTimeAdapter.getDayOfWeekNames('short').map((weekday, index) => {
      return (
        <div
          style={{
            height: 0,
            width: `${100 / 7}%`,
            paddingTop: `${50 / 7}%`,
            paddingBottom: `${50 / 7}%`
          }}
          key={weekday}
          className={
            clsx(
              weekdayClassName,
              isWeekend(new Date(year, month, index + 1 - dayOfWeek)) && `${weekdayClassName}--weekend`
            )
          }
        >
          <abbr className='react-calendar__weekdays-weekday-content' aria-label={weekday} title={weekday}>
            {weekday}
          </abbr>
        </div>
      );
    })}
  </div>;
}
