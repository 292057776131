import { useHistory } from '@useHistory/useHistoryCompat';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { RecentEntitiesService } from '../../../app/core/service/recent-entities.service';
import useService from '../../hooks/useService';

export function useDrawerModelGuard() {
  const history = useHistory();
  const recentEntitiesService = useService(RecentEntitiesService);

  return () => {
    const recentSqlModel = recentEntitiesService.getRecent(HevoEntity.MODEL.value);
    const recentDbtProject = recentEntitiesService.getRecent(HevoEntity.DBT_PROJECT.value);

    if (!recentSqlModel && !recentDbtProject) {
      history.push('/model');
      return false;
    }
    return true;
  };
}
