import {
  FREQUENCY_TYPES,
  STRICT_CRON_TYPES,
  CRON_DAYS,
  DEFAULT_FREQUENCY_TYPE,
  DEFAULT_FREQUENCY_VALUE,
  DEFAULT_CRON_TYPE,
  DEFAULT_MIN_FREQUENCY,
  DEFAULT_MAX_FREQUENCY,
  DEFAULT_FREQUENCY_CHIPS,
  FREQUENCY_TYPE_HOURS,
  FREQUENCY_TYPE_MINUTES,
  CRON_TYPE_DAILY,
  CRON_TYPE_WEEKLY
} from './constants';

export enum PolicyTypeEnum {
  FREQUENCY = 'FREQUENCY',
  CRON = 'CRON',
  LOAD = 'LOAD',
  NO_SCHEDULE = 'NONE'
}

export interface FrequencyType {
  name: string;
  value: string;
  minSeconds: number;
}

export interface CronType {
  name: string;
  value: string;
}

export interface CronDay {
  name: string;
  value: number;
}

export interface CronTime {
  hour: number;
  minute: number;
}

export class FrequencyChip {
  value: number;
  frequencyType: FrequencyType;
  displayText: string;
  disabled?: boolean;
  badgeContent?: string
  badgeColor?:string

  constructor(value: number, frequencyType: FrequencyType, displayText: string) {
    this.value = value;
    this.frequencyType = frequencyType;
    this.displayText = displayText;
    this.disabled = false;
    this.badgeContent = null
  }
}

export class Policy {
  id: number;
  message: string;
  status: string;
  type: PolicyTypeEnum;
  frequencyType: FrequencyType = DEFAULT_FREQUENCY_TYPE;
  frequencyValue: number = DEFAULT_FREQUENCY_VALUE;
  minFrequency = DEFAULT_MIN_FREQUENCY;
  maxFrequency = DEFAULT_MAX_FREQUENCY;
  cronType: CronType = DEFAULT_CRON_TYPE;
  cronDays: CronDay[];
  cronTime: CronTime;
  cronTimeList: CronTime[];
  destinationId: number;
  destTables: String[] = [];
  suggestedFrequencies: any[] = [];
  displayedFrequencies: any[] = [];
  suggestedCronTimeList: CronTime[] = [];
  isDailyCronConfigurable: boolean; // available for pipelines only in frequency config
  asHtmlText?: string;
  triggerBasedLoad?: boolean;
  triggerJobStatus?: string;

  constructor(
    rawData: any, frequencyConfig?: any,
    defaultFrequencyChips = DEFAULT_FREQUENCY_CHIPS,
    displayedFrequencies = DEFAULT_FREQUENCY_CHIPS
  ) {
    this.constructFromRawData(rawData, defaultFrequencyChips, displayedFrequencies, frequencyConfig);
  }

  constructFromRawData(
    rawData: any,
    defaultFrequencyChips: FrequencyChip[],
    displayedFrequencies: FrequencyChip[],
    frequencyConfig?: any
  ) {
    this.displayedFrequencies = displayedFrequencies;

    if (!rawData || !Object.keys(rawData).length || rawData.type === PolicyTypeEnum.NO_SCHEDULE) {
      this.type = PolicyTypeEnum.NO_SCHEDULE;
      this.suggestedFrequencies = defaultFrequencyChips;
      // Defaults for when model and workflow are currently not scheduled
      this.frequencyType = FREQUENCY_TYPE_HOURS;
      this.frequencyValue = 1;
      return;
    }

    this.id = rawData.id;
    this.status = rawData.status;
    this.message = rawData.message;
    this.suggestedCronTimeList = this.getDefaultCronTimeList();

    if (typeof frequencyConfig === 'object' && frequencyConfig !== null) {
      if (frequencyConfig.min_frequency) {
        this.minFrequency = frequencyConfig.min_frequency;
      }

      if (frequencyConfig.max_frequency) {
        this.maxFrequency = frequencyConfig.max_frequency;
      }

      if (!rawData.frequency_value && frequencyConfig.default_frequency) {
        this.frequencyValue = frequencyConfig.default_frequency;
      }

      if (frequencyConfig.is_daily_cron_configurable) {
        this.isDailyCronConfigurable = true;
      }

      if (frequencyConfig.suggested_frequencies) {
        for (let index = 0; index < frequencyConfig.suggested_frequencies.length; index++) {
          this.suggestedFrequencies.push(this.getFrequencyChip(frequencyConfig.suggested_frequencies[index]));
          this.displayedFrequencies = [ ...this.suggestedFrequencies ];
        }
      }
    } else {
      this.suggestedFrequencies = defaultFrequencyChips;
      this.isDailyCronConfigurable = false;
    }

    if (rawData.type === PolicyTypeEnum.FREQUENCY) {

      this.type = PolicyTypeEnum.FREQUENCY;

      if (!rawData.frequency_type) {
        return;
      }

      this.frequencyValue = rawData.frequency_value;
      this.frequencyType = FREQUENCY_TYPES.find((type: FrequencyType) => {
        return type.value === rawData.frequency_type;
      });
    } else if (rawData.type === PolicyTypeEnum.CRON) {

      this.cronType = STRICT_CRON_TYPES.find((type: CronType) => {
        return type.value === rawData.cron_type;
      });

      if (!this.cronType) {
        return;
      }

      this.type = PolicyTypeEnum.CRON;

      if (!rawData.cron_type) {
        return;
      }

      this.cronTimeList = (rawData.time_hour_list || []).map((timeHour: number) => {
        return {
          hour: timeHour,
          minute: rawData.time_minute
        };
      });

      if (this.cronType.value === 'WEEKLY') {
        this.cronDays = rawData.days.map((day: number) => {
          return CRON_DAYS[day];
        });
      }

    } else {
      this.type = PolicyTypeEnum.LOAD;
      this.destinationId = rawData.destination_id;
      this.destTables = rawData.dest_tables;
    }
  }

  getFrequencyChip(value: number): FrequencyChip {
    const frequencyType = this.getFrequencyType(value);
    const displayText = this.getFrequencyDisplayText(value, true);

    if (frequencyType === FREQUENCY_TYPE_MINUTES) {
      value = value / FREQUENCY_TYPE_MINUTES.minSeconds;
    } else {
      value = value / FREQUENCY_TYPE_HOURS.minSeconds;
    }

    return new FrequencyChip(value, frequencyType, displayText);
  }

  getFrequencyType(value: number): FrequencyType {
    if (value >= FREQUENCY_TYPE_HOURS.minSeconds) {
      return FREQUENCY_TYPE_HOURS;
    }

    return FREQUENCY_TYPE_MINUTES;
  }

  getFrequencyDisplayText(value: number, showAbbreviation: boolean): string {
    if (value >= FREQUENCY_TYPE_HOURS.minSeconds) {
      value = value / FREQUENCY_TYPE_HOURS.minSeconds;

      if (value > 1) {
        return value + (!showAbbreviation ? ' Hours' : ' Hrs');
      } else {
        return value + (!showAbbreviation ? ' Hour' : ' Hr');
      }
    }

    value = value / FREQUENCY_TYPE_MINUTES.minSeconds;
    if (value > 1) {
      return value + (!showAbbreviation ? ' Minutes' : ' Mins');
    } else {
      return value + (!showAbbreviation ? ' Minute' : ' Min');
    }
  }

  getRawData(): any {
    const data: any = {
      type: this.type
    };

    if (this.type === PolicyTypeEnum.FREQUENCY) {
      data.frequency_value = this.frequencyValue;
      data.frequency_type = this.frequencyType.value;

      return data;
    }

    if (this.type === PolicyTypeEnum.CRON) {
      data.cron_type = this.cronType.value;

      data.time_hour_list = this.getValidCronTimeList().map((cronTime) => cronTime.hour);
      data.time_minute = this.cronTimeList[0].minute || 0;

      if (data.cron_type === 'WEEKLY') {
        data.days = this.cronDays.map((day: CronDay) => {
          return day.value;
        });
      }

      return data;
    }

    if (this.type === PolicyTypeEnum.LOAD) {
      data.destination_id = this.destinationId;
      data.dest_tables = this.destTables;

      return data;
    }

    if (this.type === PolicyTypeEnum.NO_SCHEDULE) {
      return {};
    }

    return data;
  }

  getClone() {
    const policy = Object.create(Policy.prototype);
    const clone  = JSON.parse(JSON.stringify(this));
    for (const i in clone) {
      if (clone.hasOwnProperty(i)) {
        policy[i] = clone[i];
      }
    }
    // Reassign maxFrequency as Infinity is converted to null by JSON.stringify
    policy.maxFrequency = policy.maxFrequency || DEFAULT_MAX_FREQUENCY;
    return policy;
  }

  getTimeString(number: number) {
    if (typeof number !== 'number') {
      return;
    }

    if (number < 10) {
      return '0' + number;
    } else {
      return number.toString();
    }
  }

  getPolicyMessage(): string {
    if (this.type === PolicyTypeEnum.FREQUENCY) {
      if (!this.isValidFrequencyValue(this.frequencyValue, this.frequencyType)) {
        return undefined;
      }

      return ['every', this.getFrequencyDisplayText(
        this.frequencyValue * this.frequencyType.minSeconds, false)].join(' ');
    }

    if (this.type === PolicyTypeEnum.CRON) {
      if (!this.cronTime && !this.cronTimeList) {
        return undefined;
      }

      if (this.cronType && this.cronType.name === CRON_TYPE_DAILY.name) {
        const validDataArr = this.getValidCronTimeList();
        if (validDataArr && validDataArr.length) {
          const message = validDataArr.reduce((acc: string, cronTime, index) => {
            if (index === validDataArr.length - 1) {
              return acc += `${ this.getTimeString(cronTime.hour) }:${ this.getTimeString(cronTime.minute) } UTC.`;
            } else {
              return acc += `${ this.getTimeString(cronTime.hour) }:${ this.getTimeString(cronTime.minute) } UTC, `;
            }
          }, '');

          return `Daily at ${message}`;
        }
      }

      if (this.cronType && this.cronType.name === CRON_TYPE_WEEKLY.name) {
        if (!this.cronDays) {
          return undefined;
        }

        let daysString = 'on every ';

        this.cronDays.sort((day1: CronDay, day2: CronDay) => {
          return day1.value < day2.value ? -1 : 1;
        });

        for (let index = 0; index < this.cronDays.length; index++) {
          daysString = daysString + this.cronDays[index].name;
          if (index !== this.cronDays.length - 1) {
            daysString = daysString + ', ';
          }
        }
        const validCronTimeArr = this.getValidCronTimeList();
        if (validCronTimeArr && validCronTimeArr.length) {
          return [daysString, 'at', this.getTimeString(validCronTimeArr[0].hour)].join(' ')
            + ':' + [this.getTimeString(validCronTimeArr[0].minute), 'UTC'].join(' ');
        }
      }
    }

    if (this.type === PolicyTypeEnum.LOAD) {
      if (!this.destTables || this.destTables.length === 0) {
        return undefined;
      }

      return ['on load in', this.destTables[0]].join(' ');
    }

    return undefined;
  }

  isValidFrequencyValue(frequencyValue: number, frequencyType: FrequencyType) {
    if (!frequencyValue || !frequencyType || frequencyValue <= 0 || !Number.isInteger(frequencyValue)) {
      return false;
    }

    if (this.maxFrequency && frequencyValue * frequencyType.minSeconds > this.maxFrequency) {
      return false;
    }

    if (this.minFrequency && frequencyValue * frequencyType.minSeconds < this.minFrequency) {
      return false;
    }

    return true;
  }

  getValidCronTimeList() {
    return (this.cronTimeList || []).filter((cronTime) => {
      return cronTime && typeof cronTime.hour === 'number' && typeof cronTime.minute === 'number';
    });
  }

  getDefaultCronTimeList() {
    const arr: CronTime[] = [];

    for (let hour = 0; hour < 24; hour++) {
      arr.push({
        hour: hour,
        minute: 0
      });
    }

    return arr;
  }
}

export interface PolicyDialogMetaData {
  editModeTitle?: string;
  setModeTitle?: string;
  defaultPolicyDescription?: string;
  action?: string;
  helpText?: string;
  hideNoSchedule?: boolean;
}
