import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { capitalise } from '../../../legacy-utils/string';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export interface HdIconButtonProps extends IconButtonProps {
  dataId: string;
  verticalEdge?: 'top' | 'bottom' | 'both';
  horizontalEdge?: 'both';
}

// styling mui base icon button to match up HD styling
export const HdIconButton = styled(

  React.forwardRef<any, HdIconButtonProps>(({ dataId, verticalEdge, horizontalEdge, ...props }: HdIconButtonProps, ref) => {
    let verticalEdgeClass = '';
    let horizontalEdgeClass = '';

    if (verticalEdge) {
      verticalEdgeClass = `MuiIconButton-verticalEdge${capitalise(verticalEdge)}`;
    }

    if (horizontalEdge === 'both') {
      horizontalEdgeClass = `MuiIconButton-horizontalEdge${capitalise(horizontalEdge)}`;
    }

    const iconButtonRef = useRef(null);

    useEffect(() => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(iconButtonRef.current);
      } else {
        ref.current = iconButtonRef.current;
      }
    });

    useEffect(() => {}, [ref, iconButtonRef]);

    const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

    return (
      <IconButton
        {...props}
        data-id={dataIdGenerator('iconButton')}
        ref={iconButtonRef}
        className={`${props.className} ${verticalEdgeClass} ${horizontalEdgeClass}`}
      />
    );
  })
)({
  '&.MuiIconButton-root': {
    transition: 'none',
    opacity: '.8',
    padding: 'calc(var(--spacer) * 0.4)',
    color: 'var(--text-default-color)'
  },
  '&.MuiIconButton-root:hover:not(:disabled), &.MuiIconButton-root:focus:not(:disabled), &.focused-button':
    {
      opacity: '1',
      backgroundColor: 'var(--ripple-bg-color)'
    },
  '&.MuiIconButton-root.pure-ripple': {
    padding: '2px'
  },
  '&.MuiIconButton-root.pure-ripple:hover:not(:disabled), &.MuiIconButton-root.pure-ripple:focus:not(:disabled)':
    {
      outline: '6px var(--ripple-bg-color) solid',
      boxSizing: 'border-box'
    },
  '&.MuiIconButton-root:disabled': {
    opacity: '.5',
    cursor: 'not-allowed',
    pointerEvents: 'auto'
  },
  '&.MuiIconButton-edgeEnd': {
    marginRight: 'calc(var(--spacer) * 0.4 * -1)'
  },
  '&.MuiIconButton-edgeStart': {
    marginLeft: 'calc(var(--spacer) * 0.4 * -1)'
  },
  '&.MuiIconButton-verticalEdgeTop': {
    marginTop: 'calc(var(--spacer) * 0.4 * -1)'
  },
  '&.MuiIconButton-verticalEdgeBottom': {
    marginBottom: 'calc(var(--spacer) * 0.4 * -1)'
  },
  '&.MuiIconButton-verticalEdgeBoth': {
    marginTop: 'calc(var(--spacer) * 0.4 * -1)',
    marginBottom: 'calc(var(--spacer) * 0.4 * -1)'
  },
  '&.MuiIconButton-horizontalEdgeBoth': {
    marginLeft: 'calc(var(--spacer) * 0.4 * -1)',
    marginRight: 'calc(var(--spacer) * 0.4 * -1)'
  }
});
