import { InclusionItem, InclusionListState } from '../../core/models/inclusion-list-v2';
import { SelectorItem } from './interface';


export function SelectorItemFactory(rawData: any): SelectorItem {
  if (!rawData) {
    return;
  }
  if (typeof rawData === 'object') {
    const { id, name, ...data } = rawData;

    const selectorItem = {
      id: id,
      name: name
    };

    if (data && Object.keys(data).length > 0) {
      selectorItem['data'] = data;
    }

    return selectorItem;
  } else {
    const selectorItem = {
      id: rawData
    };

    return selectorItem;
  }
}

export function SelectorItemsFactory(rawData: any[]): SelectorItem[] {
  if (!rawData) {
    return;
  }

  return rawData.map((item) => SelectorItemFactory(item));
}

export function getSelectorInclusionStateFromRawData(rawData: any): InclusionListState {
  return {
    selected: !!rawData.all_select,
    entities: (rawData.entities || []).map((key) => {
      return {
        key: key,
        selected: !rawData.all_select
      };
    })
  };
}

export function getSelectorRawDataFromInclusionState(state: InclusionListState) {
  if (!state) {
    return;
  }

  return {
    all_select: state.selected,
    entities: (state.entities || []).map((account) => {
      return account.key;
    })
  };
}

export type SelectorItemWithListData = SelectorItem & { inclusionItem: InclusionItem };
