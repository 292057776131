import * as Yup from 'yup';
import { DestinationConfigMetaData } from '../interface';
import BigQuery, { bucketErrorMessage, datasetErrorMessage } from './index';

export const BIG_QUERY_CONFIG: DestinationConfigMetaData = {
  formComponent: BigQuery,
  initialValues: {
    auth: null,
    loadedAt: true,
    sanitizeName: true,
    streamingWrites: false,
    projectId: null,
    dataset: null,
    bucket: null
  },
  validationSchema: Yup.object({
    auth: Yup.object().when('authorisedAccount', {
      is: account => !!account,
      then: () => Yup.object().nullable(),
      otherwise: () => Yup.object().required()
    }),
    projectId: Yup.object().required(),
    dataset: Yup.object().required(),
    'dataset-creatableFieldValue': Yup.string()
      .nullable()
      .test({
        name: 'dataset-name-error',
        exclusive: true,
        message: () => datasetErrorMessage(),
        test(value) {
          if (!this.parent['dataset-dropdownInCreatableMode']) {
            return true;
          }

          if (!value) {
            return false;
          }

          return value.length < 1024 && /^[a-zA-Z0-9_]+$/.test(value);
        }
      }),
    bucket: Yup.object()
      .nullable()
      .test('bucket-test', 'bucket is required', function (value: any) {
        return !!(this.parent.streamingWrites || value?.internalBucket || !!value?.id);
      }),
    'bucket-creatableFieldValue': Yup.string()
      .nullable()
      .test({
        name: 'bucket-name-error',
        exclusive: true,
        message: () => bucketErrorMessage(),
        test(value) {
          if (!this.parent['bucket-dropdownInCreatableMode']) {
            return true;
          }

          if (!value) {
            return false;
          }

          return (
            value.length > 3 && value.length < 63 && /^[a-z0-9][a-z0-9-_.]*[a-z0-9]$/.test(value)
          );
        }
      })
  })
};
