import React from 'react';
import { HdIcon, HdLink } from '../../../../components/UIElements';
import RoundedIcon from '../../../../components/RoundedIcon';
import { FEATURE_REQUEST_HANDLER } from '../../../../constants';
import { isMac } from '../../../../legacy-utils/file';
import styles from '../styles.module.scss';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import { TRACKER_GLOBAL_SEARCH_SUGGEST_FEATURE_CLICK } from '../tracking';

const UPVOTY_NEW_CONNECTOR_LINK = 'https://hevo.upvoty.com/b/other-feature-requests/';

export function GlobalSearchEntitiesHeader({
  totalEntities,
  filterEntities,
  entityHeader,
  showFilteredCount = true,
  cta,
  showCTA = true,
  navigate,
  dataId,
  path
}) {
  const navigateToEntity = () => {
    navigate(path);
  };

  return (
    <div className={`justify-between ${styles.groupLabel}`}>
      <div>
        <span className='text-bold'>{entityHeader}</span>

        {showFilteredCount ? (
          <span className='text-caption-1 text-secondary ml-1'>
            {filterEntities} of {totalEntities}
          </span>
        ) : null}
      </div>

      {showCTA ? (
        <HdLink direction='right' icon='overview' dataId={dataId} onClick={navigateToEntity}>
          {cta}
        </HdLink>
      ) : null}
    </div>
  );
}

export function GlobalSearchFooter() {
  return (
    <div className='dialog-footer justify-center'>
      <div className={styles.keyCombination}>
        <div className={styles.iconContainer}>
          <HdIcon name='navigation-indicator' className={styles.key} size={1} />
        </div>

        <span className={styles.keyAction}>to Navigate</span>
      </div>

      <div className={styles.keyCombination}>
        <div className={styles.iconContainer}>
          <HdIcon name='enter' className={styles.key} size={1} />
        </div>

        <span className={styles.keyAction}>to Select</span>
      </div>

      <div className={styles.keyCombination}>
        <div className={styles.iconContainer}>
          <HdIcon name='esc' className={styles.key} size={2} />
        </div>

        <span className={styles.keyAction}>to Dismiss</span>
      </div>

      <div className={styles.keyCombination}>
        <div className={styles.iconContainer}>
          <HdIcon name={isMac() ? 'cmd' : 'ctrl'} size={1} className={styles.key} />
        </div>

        <div className={`${styles.iconContainer} ml-1`}>
          <span className={`${styles.key} icon-size-0`}>/</span>
        </div>

        <span className={styles.keyAction}>Shortcuts</span>
      </div>
    </div>
  );
}

export function GlobalSearchEmptyResult({ searchTerm, uuid }) {
  const analyticsTracker = useAnalyticsTracker();

  const onSuggestFeatureClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_GLOBAL_SEARCH_SUGGEST_FEATURE_CLICK,
      properties: {
        handler: FEATURE_REQUEST_HANDLER,
        searchTerm,
        uuid
      }
    });
  };

  return (
    <div className='no-item-box no-item-box-dialog w-100'>
      <RoundedIcon iconName='search' containerBg='primary' className='mb-4' />

      <span className='no-item-box-title'>No Results Found 😞</span>

      <span className='no-item-box-desc'>
        Cannot find what you&apos;re looking for?&nbsp;
        <HdLink
          href={UPVOTY_NEW_CONNECTOR_LINK}
          target='_blank'
          onClick={onSuggestFeatureClick}
          dataId='global-search-invite-team-member'
        >
          Submit a Feature Request
        </HdLink>
      </span>
    </div>
  );
}
