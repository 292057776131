import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { AccordionItem } from './accordion-item/accordion-item.component';

@Component({
  selector: 'hd-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: [ './accordion.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @ContentChildren(AccordionItem, { descendants: true }) items: QueryList<AccordionItem> = new QueryList<AccordionItem>();

  constructor(
    private _el: ElementRef
  ) {
  }

  expandAllItems() {
    this.items.forEach((item) => {
      if (!item.isExpanded()) {
        item.expand();
      }
    });
  }
}
