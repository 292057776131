import { CircularProgress, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import { readFileAsText, validateFileList } from '../../../utils/fileReader';
import { getDataId, getDataIdGenerator } from '../../../utils/generateDataId';
import HdIcon from '../HdIcon';
import { HdIconButton } from '../HdIconButton';
import { HdTextField, HdTextFieldProps } from '../HdTextField';
import { HdTooltip } from '../HdTooltip';
import styles from './styles.module.scss';

export interface HdFileInputProps {
  className?: string;
  label?: string;
  placeholder?: string;
  onChangeEventHandler: Function;
  showError?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  id?: string;
  value?: any;
  displayRenderValue?: Function;
  onBlur?: Function;
  displayRenderFunction?: Function;
  helperText?: React.ReactElement;
  multiple?: boolean;
  accept?;
}

const getDefaultFileData = (fileData, type = null) => {
  if (fileData) {
    if (Array.isArray(fileData)) {
      if (type === 'names') {
        return fileData.map(file => file.name);
      }
      return fileData;
    }

    if (type === 'names') {
      return [fileData?.name || 'Click To Change'];
    }
    return [fileData];
  }
  return [];
};

// eslint-disable-next-line react/function-component-definition
const HdFileField: React.FC<HdFileInputProps & HdTextFieldProps> = ({
  dataId,
  value,
  label,
  placeholder,
  id,
  onBlur,
  showError,
  displayRenderFunction = getFileName,
  onChangeEventHandler,
  displayRenderValue,
  variant = 'outlined',
  helperText = ' ',
  accept,
  multiple = false,
  ...props
}) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [fileNames, setFileNames] = useState(() => getDefaultFileData(value, 'names'));
  const [fileData, setFileData] = useState(() => getDefaultFileData(value));

  const inputRef = useRef(null);
  const inputFileRef = useRef(null);

  const showCloseButton = !fileLoading && !!fileData.length;
  const tooltipTitle = fileNames.length > 1 ? fileNames.join(', ') : '';

  useEffect(() => {
    const fileSelector = inputFileRef.current;
    fileSelector.addEventListener('change', handleFileSelector);
    return () => fileSelector.removeEventListener('change', handleFileSelector);
  }, []);

  useDontMountAtFirst(() => {
    if (!multiple) {
      if (fileData.length === 0) {
        onChangeEventHandler(null);
      } else {
        onChangeEventHandler(fileData[0]);
      }
    } else {
      onChangeEventHandler(fileData);
    }
  }, [fileData, id]);

  const handleFileSelector = async event => {
    const fileList = event.target.files;

    if (validateFileList(fileList)) {
      setFileLoading(true);

      const promiseList = [];
      const names = [];
      Array.from(fileList).forEach((file: File) => {
        promiseList.push(readFileAsText(file));
        names.push(file.name);
      });

      setFileNames(names);
      Promise.all(promiseList).then(files => {
        if (files.length) {
          const fileContent = [];
          files.forEach((file, index) => {
            const data = {
              name: fileList[index].name,
              content: file,
              type: fileList[index].type
            };
            fileContent.push(data);
          });
          setFileData(fileContent);
        }
        setFileLoading(false);
      });
    }
  };

  const triggerFileSelector = () => {
    inputRef.current.blur();
    inputFileRef.current.click();
    if (onBlur) {
      onBlur();
    }
  };

  const resetFileSelector = () => {
    setFileNames([]);
    setFileData([]);
    inputFileRef.current.value = '';
    onChangeEventHandler(null);
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'fileField'), [dataId]);

  return (
    <>
      {tooltipTitle ? (
        <HdTooltip title={tooltipTitle} placement='bottom-start'>
          <span className={styles.shallowTooltip}>&nbsp;</span>
        </HdTooltip>
      ) : null}

      <HdTextField
        id={id}
        variant={variant}
        label={!fileNames.length && placeholder ? placeholder : label}
        helperText={helperText}
        type='text'
        {...props}
        onBlur={() => {}}
        error={showError}
        inputRef={inputRef}
        autoComplete='off'
        className={`${props.className}`}
        dataId={dataIdGenerator('')}
        InputProps={{
          inputProps: {
            value: displayRenderFunction(fileNames),
            tabIndex: -1,
            onClick: triggerFileSelector,
            readOnly: true,
            ...props
          },
          endAdornment: (
            <InputAdornment position='start'>
              {fileLoading ? InProgressButton(id) : null}
              {showCloseButton ? ClearFileButton(resetFileSelector, id) : null}
              {!fileLoading ? AttachFileButton(triggerFileSelector, id) : null}
            </InputAdornment>
          )
        }}
      />

      <input ref={inputFileRef} name={id} type='file' hidden accept={accept} multiple={multiple} />
    </>
  );
};

export default HdFileField;

/**
 * child components for the InputAdornment
 */

function AttachFileButton(triggerFileSelector, name) {
  return (
    <HdIconButton
      onClick={triggerFileSelector}
      aria-label='select'
      edge='end'
      type='button'
      dataId={getDataId('attach')(name)('fileField')}
    >
      <HdIcon name='attach' />
    </HdIconButton>
  );
}

function ClearFileButton(resetFileSelector, name) {
  return (
    <HdIconButton
      aria-label='clear'
      edge='start'
      type='button'
      onClick={resetFileSelector}
      dataId={getDataId('clear')(name)('fileField')}
    >
      <HdIcon name='close' />
    </HdIconButton>
  );
}

function InProgressButton(name) {
  return (
    <HdIconButton
      aria-label='progress'
      edge='start'
      type='button'
      dataId={getDataId('progress')(name)('fileField')}
    >
      <CircularProgress size={10} className={styles.progress} />
    </HdIconButton>
  );
}

function getFileName(names) {
  let displayName = '';
  if (names.length) {
    [displayName] = names;
    if (names.length > 1) {
      displayName = displayName.concat(` + ${names.length - 1}`);
    }
  }
  return displayName;
}
