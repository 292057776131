import {
  Directive,
  ElementRef,
  Input,
  Output,
  OnInit,
  OnDestroy,
  HostBinding,
  HostListener,
  EventEmitter
} from '@angular/core';

import { ENTER } from '@angular/cdk/keycodes';
import { FlexibleAutoCompleteComponent } from './flexible-auto-complete.component';
import { Subscription } from 'rxjs';
import { FlexibleAutoCompleteOrigin } from './flexible-auto-complete-origin.directive';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[flexibleAutoCompleteTrigger]',
  exportAs: 'flexibleAutoCompleteTrigger'
})
export class FlexibleAutoCompleteTriggerDirective implements OnInit, OnDestroy {
  @Input('flexibleAutoCompleteTrigger') autoComplete: FlexibleAutoCompleteComponent;
  @Input() flexibleAutoCompleteConnectedTo: FlexibleAutoCompleteOrigin;

  private _disabled = false;
  _autoCompleteVisible = false;

  autoCompleteHideSub: Subscription = Subscription.EMPTY;

  openedBy: 'mouse' | 'touch' | null = null;

  @HostBinding('class.disabled') @Input('disabled')
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Output() onOpen = new EventEmitter<void>();

  constructor(
    protected _el: ElementRef) {
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent): void {
    if (this._disabled) {
      return;
    }
    e['targetDirective'] = this;
    this.toggleAutoComplete();
  }

  /** Handles mouse presses on the trigger. */
  @HostListener('mousedown', ['$event']) _handleMousedown(event: MouseEvent): void {
    this.openedBy = event.button === 0 ? 'mouse' : null;
  }

  toggleAutoComplete(): void {
    return this._autoCompleteVisible ? this.closeAutoComplete() : this.openAutoComplete();
  }

  openAutoComplete(): void {
    if (this._autoCompleteVisible) {
      return;
    }

    this.autoComplete.show(this);
    this._autoCompleteVisible = true;
    this.onOpen.emit();
  }

  closeAutoComplete(): void {
    this.autoComplete.hide();
    this._autoCompleteVisible = false;
  }

  ngOnInit() {
    this.autoComplete.hasExternalTrigger = true;
    this.autoComplete.setMode();

    this.autoCompleteHideSub = this.autoComplete.onHide.subscribe(() => {
      this._autoCompleteVisible = false;
    });
  }

  ngOnDestroy() {
    if (this._autoCompleteVisible) {
      this.closeAutoComplete();
    }
    this.autoCompleteHideSub.unsubscribe();
  }

  focus() {
    if (this.flexibleAutoCompleteConnectedTo) {
      this.flexibleAutoCompleteConnectedTo.elementRef.nativeElement.focus();
    }
    this._el.nativeElement.focus();
  }

  containsEventTarget(e: MouseEvent): boolean {
    return this._el.nativeElement.contains(e.target);
  }

  getConnectedElement() {
    if (this.flexibleAutoCompleteConnectedTo) {
      return this.flexibleAutoCompleteConnectedTo.elementRef;
    }

    return this.getElement();
  }

  getElement() {
    return this._el;
  }
}
