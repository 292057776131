import React from 'react';
import styles from './styles.module.scss';

export default function HelpBannerTitle({ children }) {
  return (
    <div className={`${styles.helpNotesMainLeft} center-flex-row`}>
      <span className={`hevo-icon hevo-info ${styles.helpNotesInfoIcon} `} />

      {children}
    </div>
  );
}
