import React from 'react';

export function AccountAccessShimmer({ dataId }) {
  return (
    <div className='box__body' data-id={dataId}>
      <div className='shimmer shimmer-line mb-3' style={{ width: '170px', height: '12px' }} />

      <div className='shimmer shimmer-line mb-3' style={{ width: '486px', height: '8px' }} />

      <div className='shimmer shimmer-line mb-2' style={{ width: '416px', height: '8px' }} />
    </div>
  );
}

export function AccountAccessListShimmer({ dataId }) {
  return (
    <tbody data-id={dataId}>
      <tr>
        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>

        <td>
          <div className='shimmer shimmer-line' style={{ width: '132px', height: '8px' }} />
        </td>

        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>
      </tr>

      <tr>
        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>

        <td>
          <div className='shimmer shimmer-line' style={{ width: '100px', height: '8px' }} />
        </td>

        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>
      </tr>
      <tr>
        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>

        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>

        <td>
          <div className='shimmer shimmer-line' style={{ width: '116px', height: '8px' }} />
        </td>
      </tr>
    </tbody>
  );
}
