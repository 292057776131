// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.transformContainers_y2rVE {\n  opacity: 1;\n  transform: scale(1);\n}\n\n.setupGuideDocs_Vf2RX {\n  width: 40%;\n  flex-shrink: 0;\n}\n\n.editModal_Y7Uyi {\n  z-index: 1065 !important;\n}\n\n.hide_QVGRH {\n  position: absolute !important;\n  top: 20px;\n  left: 20px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transformContainers": "transformContainers_y2rVE",
	"setupGuideDocs": "setupGuideDocs_Vf2RX",
	"editModal": "editModal_Y7Uyi",
	"hide": "hide_QVGRH"
};
export default ___CSS_LOADER_EXPORT___;
