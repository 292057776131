import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { HdA11yModule } from '../a11y/a11y.module';
import { LoaderModule } from '../loader/loader.module';
import { IpModule } from '../ip/ip.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { AdvancedGlobalSearchReactComponent } from './advanced-global-search-react.component';

import { KeyboardShortcutsDialogReactComponent } from './keyboard-shortcuts-dialog-react.component';

import { KeyboardShortcuts } from './service/keyboard-shortcuts.service';

const DIRECTIVES: any = [
  KeyboardShortcutsDialogReactComponent,
  AdvancedGlobalSearchReactComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HdA11yModule,
    SharedModule,
    LoaderModule,
    IpModule,
    TooltipModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
  entryComponents: [
    KeyboardShortcutsDialogReactComponent,
    AdvancedGlobalSearchReactComponent
  ]
})
export class ShortcutsModule {
  static forRoot(): ModuleWithProviders<ShortcutsModule> {
    return {
      ngModule: ShortcutsModule,
      providers: [
        KeyboardShortcuts
      ]
    };
  }
}
