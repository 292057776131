// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.timeSelectorDropdown__Mftb {\n  display: flex;\n  height: 200px;\n  width: 100%;\n  overflow-y: auto;\n  border: solid 1px var(--divider-color);\n  border-radius: 4px;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.dropdownScrollbar_wF4Jd::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.dropdownScrollbar_wF4Jd {\n  -ms-overflow-style: none;\n  /* IE and Edge */\n  scrollbar-width: none;\n  /* Firefox */\n}\n\n.dropdown_H9p1A {\n  overflow-y: auto;\n  height: 100%;\n  width: 50%;\n}\n.dropdown_H9p1A .dropdownItem_FAGke {\n  cursor: pointer;\n  padding: 12px 16px;\n  margin: 4px;\n  color: var(--text-default-color);\n  font-weight: 600;\n}\n.dropdown_H9p1A .active__IB78 {\n  background-color: var(--primary-color);\n  color: #ffffff;\n  border-radius: 4px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeSelectorDropdown": "timeSelectorDropdown__Mftb",
	"dropdownScrollbar": "dropdownScrollbar_wF4Jd",
	"dropdown": "dropdown_H9p1A",
	"dropdownItem": "dropdownItem_FAGke",
	"active": "active__IB78"
};
export default ___CSS_LOADER_EXPORT___;
