import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { WebhookDialog } from '../../../react/containers/notification-settings/webhooks/webhooksDialog';
import { Destination } from '../../destination/models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'destination-webhook',
  template: '',
  providers: [ BridgeService ]
})
export class DestinationWebhookComponent implements OnChanges {

  @Input() destination: Destination;

  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef<any>,
  ) {
  }

  ngOnChanges(): void {
    this._bridgeService.mount(
      WebhookDialog,
      this._el.nativeElement,
      {
        destination: this.destination
      });
  }
}
