import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamSettingsService } from '../../core/service/team-settings.service';

@Component({
  selector: 'hd-create-entity-redirection',
  template: ''
})
export class CreateEntityRedirection {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _teamSettingsService: TeamSettingsService
  ) {
    const isEnterpriseEnabled = this._teamSettingsService.isH2OEnabled();

    const entity = this._route.snapshot.paramMap.get('entity');
    let commands = ['pipeline', 'create'];

    if (entity === 'destination') {
      commands = ['destination', 'add'];
    }

    this._router.navigate(
      commands,
      {
        queryParams: {
          wizard_step: null,
          ...(isEnterpriseEnabled && entity === 'pipeline' ? { common_source_type: true } : {})
        },
        replaceUrl: true
      });
  }
}
