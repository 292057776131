// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.optionsContainer_Q1R_U {\n  display: flex;\n}\n.optionsContainer_Q1R_U :not(:first-child) {\n  margin-left: 20px;\n}\n\n.optionButton_DivHW {\n  line-height: 16px;\n  border-radius: 4px;\n  border: transparent;\n  background-color: var(--surface-bg-overlay-color);\n  padding: 8px 8px;\n  color: var(--text-default-color);\n}\n.optionButton_DivHW:hover, .optionButton_DivHW:focus {\n  cursor: pointer;\n  background-color: var(--ripple-bg-color);\n  color: var(--text-primary-color);\n}\n.optionButton_DivHW.active_WRCfg {\n  background-color: var(--primary-color) !important;\n  color: var(--light-text) !important;\n}\n.optionButton_DivHW:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n  pointer-events: none;\n}\n\n.popupBox_YJUcp {\n  max-width: 180px !important;\n}\n\n.optionBadgeLabel_a73tN {\n  margin-top: -7px !important;\n  margin-left: -16px !important;\n  border: 1px solid var(--surface-bg-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsContainer": "optionsContainer_Q1R_U",
	"optionButton": "optionButton_DivHW",
	"active": "active_WRCfg",
	"popupBox": "popupBox_YJUcp",
	"optionBadgeLabel": "optionBadgeLabel_a73tN"
};
export default ___CSS_LOADER_EXPORT___;
