import { ComponentType } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { PopoverOrigin } from '@mui/material';
import { Observable } from 'rxjs';

export type ProductTourPositionX = 'before' | 'start' | 'center' | 'end' | 'after';
export type ProductTourPositionY = 'above' | 'start' | 'center' | 'end' | 'below';
export type ProductTourArrowDirection = 'in' | 'out';
export type AnchorShape = 'circle' | 'rectangle';

export interface ProductTourDialogData {
  renderReact?: boolean;
  steps: ProductTourDialogStep[];
}

export interface ArrowOrigin {
  vertical: 'top' | 'bottom' | 'center';
  horizontal: 'left' | 'right' | 'center';
}

export interface ProductTourDialogStep {
  tourItem: string;
  tourComponent?: ComponentType<any>;
  reactTourComponent?: any;
  positionX?: ProductTourPositionX;
  positionY?: ProductTourPositionY;
  arrowDirection?: ProductTourArrowDirection;
  offsetMainAxis?: number;
  offsetCrossAxis?: number;
  anchorShape?: AnchorShape;
  anchorInteractive?: boolean;
  anchorPadding?: number;
  anchorAbsolute?: boolean;
  anchorPosition?: { top: number; left: number; };
  anchorOrigin?: PopoverOrigin; 
  transformOrigin?: PopoverOrigin;
  arrowOrigin?: ArrowOrigin;
  popoverOrigin?: { vertical: number; horizontal: number; }
  data?: any;
  hideOverlay?: boolean;
  arrowImageUrl?: string;
  close$?: Observable<any>;
}

export const PRODUCT_TOUR_ITEM_DATA = new InjectionToken<any>('productTourItemData');
