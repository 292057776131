import { ChatSupportCurrentPlan } from '../../../../app/core/service/plan-chat-support.service';
import { BillingProvider, MarketplaceProviders, PaymentPlanTier } from '../models/payment';
import { UsageAlertData, UsageStatus, UsageAlertPrimaryAction } from '../models/usage-status';

export function UsageAlertDataFactory(rawData: any): UsageAlertData {
  if (!rawData) {
    return null;
  }

  const {
    usage_status: status,
    alert_title: alertTitle,
    alert_message: alertMessage,
    credit_exhausted: hasCreditExhausted,
    has_assigned_max_credit: hasAssignedMaxCredit,
    has_reached_early_credit_consumption_threshold: hasReachedEarlyCreditConsumptionThreshold,
    has_assigned_credit: hasAssignedCredit
  } = rawData;

  return {
    status,
    alertTitle,
    alertMessage,
    hasCreditExhausted,
    hasAssignedMaxCredit,
    hasReachedEarlyCreditConsumptionThreshold,
    hasAssignedCredit
  };
}

export function getUsageAlertActions(
  currentPlan: ChatSupportCurrentPlan,
  usageData: UsageAlertData
) {
  const {
    status: usageStatus,
    hasAssignedCredit,
    hasAssignedMaxCredit,
    hasCreditExhausted,
    hasReachedEarlyCreditConsumptionThreshold
  } = usageData;

  if (MarketplaceProviders.includes(currentPlan.paymentSource as BillingProvider)) {
    return [];
  }

  if (
    usageStatus === UsageStatus.REGULAR_CONSUMPTION ||
    usageStatus === UsageStatus.REGULAR_CREDIT_CONSUMPTION
  ) {
    return [];
  }

  if (currentPlan.currentPlanTier === PaymentPlanTier.FREE) {
    return [UsageAlertPrimaryAction.UPGRADE_PLAN];
  }

  if (
    usageStatus === UsageStatus.EARLY_CONSUMPTION ||
    usageStatus === UsageStatus.BASE_CONSUMPTION
  ) {
    return hasAssignedCredit
      ? [UsageAlertPrimaryAction.UPGRADE_PLAN]
      : [
        UsageAlertPrimaryAction.UPGRADE_PLAN,
        UsageAlertPrimaryAction.SET_ON_DEMAND_CREDIT
      ];
  }

  if (usageStatus === UsageStatus.BUFFER_CONSUMPTION) {
    if (hasAssignedCredit) {
      if (hasAssignedMaxCredit) {
        return [
          UsageAlertPrimaryAction.UPGRADE_PLAN,
          UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT
        ];
      }
      return [UsageAlertPrimaryAction.UPGRADE_PLAN, UsageAlertPrimaryAction.HIKE_ON_DEMAND_CREDIT];
    }
    return [UsageAlertPrimaryAction.UPGRADE_PLAN, UsageAlertPrimaryAction.SET_ON_DEMAND_CREDIT];
  }

  if (
    usageStatus === UsageStatus.EARLY_ON_DEMAND_CREDIT_CONSUMPTION ||
    usageStatus === UsageStatus.NEAR_ON_DEMAND_CREDIT_CONSUMPTION ||
    usageStatus === UsageStatus.ON_DEMAND_CREDIT_CONSUMPTION
  ) {
    return hasAssignedMaxCredit
      ? [UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT]
      : [
          UsageAlertPrimaryAction.HIKE_ON_DEMAND_CREDIT,
          UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT
        ];
  }

  if (
    usageStatus === UsageStatus.NEAR_ON_DEMAND_CONSUMPTION ||
    usageStatus === UsageStatus.ON_DEMAND_CONSUMPTION
  ) {
    if (!hasAssignedCredit) {
      return [UsageAlertPrimaryAction.UPGRADE_PLAN, UsageAlertPrimaryAction.SET_ON_DEMAND_CREDIT];
    }

    if (hasAssignedMaxCredit) {
      if (hasCreditExhausted || hasReachedEarlyCreditConsumptionThreshold) {
        return [UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT];
      }
      return [];
    }
    if (hasCreditExhausted || hasReachedEarlyCreditConsumptionThreshold) {
      return [
        UsageAlertPrimaryAction.HIKE_ON_DEMAND_CREDIT,
        UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT
      ];
    }
    return [];
  }

  return [];
}

export function canCloseAlert(usageStatus: UsageStatus) {
  return ![
    UsageStatus.BUFFER_CONSUMPTION,
    UsageStatus.ON_DEMAND_CONSUMPTION,
    UsageStatus.ON_DEMAND_CREDIT_CONSUMPTION
  ].includes(usageStatus);
}

export function canShowUsageAlert(usageData: UsageAlertData) {
  if (!usageData) {
    return false;
  }

  if (
    usageData.status === UsageStatus.REGULAR_CONSUMPTION ||
    usageData.status === UsageStatus.REGULAR_CREDIT_CONSUMPTION
  ) {
    return false;
  }

  if (
    usageData.status === UsageStatus.NEAR_ON_DEMAND_CONSUMPTION ||
    usageData.status === UsageStatus.ON_DEMAND_CONSUMPTION
  ) {
    if (
      usageData.hasAssignedCredit &&
      !usageData.hasCreditExhausted &&
      !usageData.hasReachedEarlyCreditConsumptionThreshold
    ) {
      return false;
    }
  }

  return true;
}

export function hasUsageAlertStatusChanged(a: UsageAlertData, b: UsageAlertData) {
  return (
    a.status !== b.status ||
    a.alertTitle !== b.alertTitle ||
    a.alertMessage !== b.alertMessage ||
    a.hasCreditExhausted !== b.hasCreditExhausted ||
    a.hasAssignedMaxCredit !== b.hasAssignedMaxCredit ||
    a.hasReachedEarlyCreditConsumptionThreshold !== b.hasReachedEarlyCreditConsumptionThreshold ||
    a.hasAssignedCredit !== b.hasAssignedCredit
  );
}
