import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import HdIcon from '../HdIcon';

export const HdAccordion = styled(
  ({ showBorderBottom, ...props }: AccordionProps & { showBorderBottom?: boolean }) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  )
)(props => ({
  border: '1px solid var(--divider-color)',
  borderRadius: '6px',
  marginBottom: 'calc(var(--spacer) * 1.25)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 'calc(var(--spacer) * 0.32) 0px',
  alignItems: 'start',
  '& .MuiCollapse-root': {
    width: '100%'
  },
  '&:not(:last-child)': {
    borderBottom: !props.showBorderBottom && 0
  },
  '&:before': {
    display: 'none'
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent'
  }
}));

export const HdAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={props.expandIcon || <HdIcon name='solid-dropdown' size={1} />}
    {...props}
  />
))(() => ({
  width: '100%',
  flexDirection: 'row-reverse',
  padding: '0px calc(var(--spacer) * 1.25)',
  '&.margin-0 .MuiAccordionSummary-content': {
    margin: '0'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 'calc(var(--spacer) * 0.75)'
  },
  '&.no-margin .MuiAccordionSummary-content': {
    marginLeft: '0'
  },
  '&:hover .MuiAccordionSummary-content h4': {
    color: 'var(--text-primary-color)'
  },
  '&:focus .MuiAccordionSummary-content h4': {
    color: 'var(--text-primary-color)'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--text-default-color)',
    transform: 'rotate(-90deg)',
    padding: 'calc(var(--spacer) * 0.12)'
  },
  '&:hover .MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--text-primary-color)'
  },
  '&:focus .MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--text-primary-color)'
  },
  '&.Mui-focusVisible': {
    background: 'transparent'
  },
  '&.Mui-disabled': {
    opacity: 1
  }
}));

export const HdAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 'calc(var(--spacer) * 0.53) calc(var(--spacer) * 1.25)',
  paddingBottom: 'var(--spacer)'
}));

export const HdTabAccordion = styled((props: AccordionProps) => (
  <MuiAccordion
    TransitionProps={{ timeout: { exit: 100 } }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(() => ({
  margin: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  '& .MuiCollapse-root': {
    width: '100%'
  },
  '&.flexible-height .MuiCollapse-root': {
    height: 'auto !important'
  },
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export const HdTabAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<HdIcon name='dropdown-arrow' size={3} />} {...props} />
))(() => ({
  width: '100%',
  padding: 0,
  minHeight: 'auto',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: 'var(--text-default-color)',
    opacity: 1,
    transform: 'rotate(180deg)',
    paddingLeft: 'calc(var(--spacer) * 1.25)',
    paddingRight: '0px'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    paddingLeft: 0
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: 0,
    opacity: 0.3,
    color: 'var(--text-default-color)',
    padding: 'calc(var(--spacer) * 0.12) 0px',
    paddingRight: 'calc(var(--spacer) * 1.25)'
  },
  '&:hover .MuiAccordionSummary-expandIconWrapper': {
    transition: 'opacity 200ms ease',
    opacity: 1,
    color: 'var(--text-default-color)'
  },
  '&.noPadding .MuiAccordionSummary-expandIconWrapper': {
    padding: 0
  }
}));

export const HdTabAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0 calc(var(--spacer) * 1.25) calc(var(--spacer) * 1.25)'
}));
