import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ResetPasswordRequestType } from './models';
import { AuthService } from '../../../app/core/service/auth.service';
import { AngularDIContext } from '../../../app/react-bridge/di-context';
import AuthAPI from './AuthAPI';

export function useResetPassword() {
  const angularDIContext = useContext(AngularDIContext);
  const authService = angularDIContext.get(AuthService);

  const { search } = useLocation();

  const next = new URLSearchParams(search).get('next');

  const resetPassword = (
    code: string,
    password: string,
    resetPasswordRequestType: ResetPasswordRequestType
  ) =>
    AuthAPI.resetPassword(code, password, resetPasswordRequestType).then(res => {
      authService.handleAuthResponseReact(res, next);
      return res;
    });

  return {
    resetPassword
  };
}
