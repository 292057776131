import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ColorModeService } from '../../core/service/color-mode.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColorMode } from '../../core/models/color-mode';

@Directive({
  selector: '[hdColorModeImage]'
})
export class ColorModeImageDirective implements OnInit, OnChanges, OnDestroy {
  @Input() lightSrc: string;
  @Input() darkSrc: string;

  private _colorMode: ColorMode = ColorMode.LIGHT;

  private _destroyed$ = new Subject<void>();

  constructor(
    private _colorModeService: ColorModeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._updateImageSrc();
  }

  ngOnInit() {
    this._handleMode();
  }

  private _handleMode() {
    this._colorModeService.colorMode$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((mode) => {
      this._colorMode = mode;
      this._updateImageSrc();
    });
  }

  private _updateImageSrc() {
    let newSrc = this.lightSrc;
    if (this._colorMode === ColorMode.DARK && this.darkSrc) {
      newSrc = this.darkSrc;
    }

    this._el.nativeElement.src = newSrc;
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
