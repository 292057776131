import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogOverlayService } from '../dialog/dialog-overlay.service';
import { ProductTourItemComponent } from './product-tour-item/product-tour-item.component';
import { ProductTourDialogService } from './product-tour-dialog/product-tour-dialog.service';
import { ProductTourDialogComponent } from './product-tour-dialog/product-tour-dialog.component';
import { ProductTourDialogReactComponent } from './product-tour-dialog/product-tour-dialog-react.component';


@NgModule({
  declarations: [ ProductTourItemComponent, ProductTourDialogComponent, ProductTourDialogReactComponent ],
  imports: [
    CommonModule
  ],
  exports: [ ProductTourItemComponent ]
})
export class ProductTourModule {
  static forRoot(): ModuleWithProviders<ProductTourModule> {
    return {
      ngModule: ProductTourModule,
      providers: [
        ProductTourDialogService,
        DialogOverlayService
      ]
    };
  }
}
