import { AppConfig } from '../../../app/core/app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../../../app/core/constants';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';
import { createHttpParams } from '../../legacy-utils/request';
import {
  getAllJobsFactory,
  getAllJobsForPipelineFactory,
  getJobDetailsFactory,
  getObjectsForJobFactory
} from './factory';

const appConfig = new AppConfig();
const jobsURL = appConfig.getJobsURL();
const JobsAPI = {
  integrationsURL: appConfig.getIntegrationsURL(),

  getJobsForPipeline(integrationId: number, paginationData) {
    const requestURL = `${jobsURL}/${integrationId}`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          page: paginationData.page,
          limit: paginationData.limit
        })
      }
    };

    return courier.get(requestURL, options).then(res => getAllJobsForPipelineFactory(res));
  },

  getObjectsForJob(jobId: string, paginationData, integrationId) {
    const requestURL = `${jobsURL}/${integrationId}/job/${jobId}/objects`;
    const paramsOption = {
      page: paginationData.page,
      limit: paginationData.limit
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paramsOption)
      }
    };

    return courier.get(requestURL, options).then(res => getObjectsForJobFactory(res));
  },

  getAllJobs(jobMode, paginationData) {
    const requestURL = `${jobsURL}/all`;

    const paramsOption = {
      page: paginationData.page,
      limit: paginationData.limit,
      job_type: jobMode
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paramsOption)
      }
    };

    return courier.get(requestURL, options).then(res => getAllJobsFactory(res));
  },

  getJobDetails: (integrationId, jobId) => {
    const requestURL = `${jobsURL}/${integrationId}/job/${jobId}/stats`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestURL, options).then(res => getJobDetailsFactory(res));
  },

  getSourceObjectSidelineSummaryForJobId(pipelineId: number, jobId: string, sourceObjectId: number) {
    const requestUrl = `${jobsURL}/${pipelineId}/job/${jobId}/failure/${sourceObjectId}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
};

export default JobsAPI;
