export const BIGQUERY_REGIONS: any[] = [
  {
    name: 'US (Multi-region)',
    value: 'US'
  },
  {
    name: 'EU (Multi-region)',
    value: 'EU'
  },
  {
    name: 'northamerica-northeast1 (Montréal)',
    value: 'northamerica-northeast1'
  },
  {
    name: 'southamerica-east1 (São Paulo)',
    value: 'southamerica-east1'
  },
  {
    name: 'us-east1 (South Carolina)',
    value: 'us-east1'
  },
  {
    name: 'us-east4 (Northern Virginia)',
    value: 'us-east4'
  },
  {
    name: 'us-west1 (Oregon)',
    value: 'us-west1'
  },
  {
    name: 'us-west2 (Los Angeles)',
    value: 'us-west2'
  },
  {
    name: 'europe-north1 (Finland)',
    value: 'europe-north1'
  },
  {
    name: 'europe-west1 (Belgium)',
    value: 'europe-west1'
  },
  {
    name: 'europe-west2 (London)',
    value: 'europe-west2'
  },
  {
    name: 'europe-west3 (Frankfurt)',
    value: 'europe-west3'
  },
  {
    name: 'europe-west4 (Netherlands)',
    value: 'europe-west4'
  },
  {
    name: 'europe-west6 (Zürich)',
    value: 'europe-west6'
  },
  {
    name: 'asia-east1 (Taiwan)',
    value: 'asia-east1'
  },
  {
    name: 'asia-east2 (Hong Kong)',
    value: 'asia-east2'
  },
  {
    name: 'asia-northeast1 (Tokyo)',
    value: 'asia-northeast1'
  },
  {
    name: 'asia-northeast2 (Osaka)',
    value: 'asia-northeast2'
  },
  {
    name: 'asia-south1 (Mumbai)',
    value: 'asia-south1'
  },
  {
    name: 'asia-southeast1 (Singapore)',
    value: 'asia-southeast1'
  },
  {
    name: 'australia-southeast1 (Sydney)',
    value: 'australia-southeast1'
  }
];

export const BIGQUERY_CLUSTER_REGION_MAPPING: { [key: string]: string } = {
  'asia': 'asia-southeast1',
  'in': 'asia-south1',
  'eu': 'EU',
  'us': 'US',
  'us2': 'US',
  'au': 'australia-southeast1',
  'classplus': 'asia-south1'
};
