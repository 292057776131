import { AfterViewChecked, Component, ElementRef, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PortalInjector } from '@angular/cdk/portal';
import { AnimationEvent } from '@angular/animations';
import { filter, takeUntil } from 'rxjs/operators';

import { Dialog } from '../../dialog/models';
import { DIALOG_OVERLAY_DATA } from '../../dialog/dialog-overlay.tokens';
import { DialogOverlayRef } from '../../dialog/dialog-overlay-ref';
import { DialogBase } from '../../dialog/dialog-base';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import {
  PRODUCT_TOUR_ITEM_DATA, ProductTourArrowDirection,
  ProductTourDialogData,
  ProductTourDialogStep,
  ProductTourPositionX,
  ProductTourPositionY
} from './interface';
import { ProductTourService } from '../product-tour.service';
import { productTourAnim } from './animations';

@Component({
  selector: 'product-tour-dialog',
  templateUrl: './product-tour-dialog.component.html',
  styleUrls: [ './product-tour-dialog.component.scss' ],
  animations: [ productTourAnim() ]
})
export class ProductTourDialogComponent extends DialogBase implements OnInit, Dialog, OnDestroy, AfterViewChecked {
  currentStep: ProductTourDialogStep;
  currentStepIdx: number;
  backdropCloseDisabled = true;
  dataInjector: Injector;

  offsetMainAxis;
  offsetCrossAxis;

  positionX: ProductTourPositionX;
  positionY: ProductTourPositionY;
  arrowDirection: ProductTourArrowDirection;

  stepPositioned = false;

  arrowIcons = {
    'above-start-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-above-start-in_aublhi.svg',
    'above-center-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-above-start-in_aublhi.svg',
    'above-end-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-end-in_b9v9xn.svg',
    'below-end-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-end-in_q4znzc.svg',
    'below-center-in': 'https://res.cloudinary.com/hevo/image/upload/v1613035791/glyphs/product-tour/arrow-below-start_cfaozo.svg',
    'below-start-in': 'https://res.cloudinary.com/hevo/image/upload/v1613035791/glyphs/product-tour/arrow-below-start_cfaozo.svg',
    'above-start-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-start-out_lg0j16.svg',
    'above-center-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-start-out_lg0j16.svg',
    'above-end-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-above-end-out_k5tof0.svg',
    'below-end-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-end-out_mzvg2l.svg',
    'below-start-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-start-out_vcipjs.svg',
    'below-center-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-below-start-out_vcipjs.svg',
    'start-before-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-start-before-in_n6rqv8.svg',
    'start-after-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-start-after-in_mjowiy.svg',
    'end-before-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-end-before-in_v7bkul.svg',
    'end-after-in': 'https://res.cloudinary.com/hevo/image/upload/v1624454031/glyphs/product-tour/arrow-end-after-in_b4xyr5.svg',
    'start-before-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-start-before-out_zygcz6.svg',
    'start-after-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454032/glyphs/product-tour/arrow-start-after-out_ssbety.svg',
    'end-before-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454028/glyphs/product-tour/arrow-end-before-out_tkm3qx.svg',
    'end-after-out': 'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-end-after-out_pm7etz.svg',
  };

  @ViewChild('left', { static: true }) leftBackdrop: ElementRef;
  @ViewChild('top', { static: true }) topBackdrop: ElementRef;
  @ViewChild('bottom', { static: true }) bottomBackdrop: ElementRef;
  @ViewChild('right', { static: true }) rightBackdrop: ElementRef;
  @ViewChild('arrow', { static: true }) arrow: ElementRef;
  @ViewChild('focusedArea', { static: true }) focusedArea: ElementRef;
  @ViewChild('content') content: ElementRef;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public data: ProductTourDialogData,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _productTourService: ProductTourService,
    private _injector: Injector
  ) {
    super(document, _dialogRef, data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.show();
    }, 0);
  }

  show() {
    super.show();

    this.showFirstStep();

    this._productTourService.onDeregister$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((key) => {
      if (this.currentStep && this.currentStep.tourItem === key) {
        this.currentStep = null;
        this.hide();
      }
    });
  }

  showFirstStep() {
    this.currentStep = this.data.steps[0];

    this.currentStepIdx = 0;
    this.execute();
  }

  execute() {
    const currentStep = this.currentStep;

    this.positionX = currentStep.positionX || 'start';
    this.positionY = currentStep.positionY || 'below';
    this.arrowDirection = currentStep.arrowDirection || 'in';
    this.offsetMainAxis = currentStep.offsetMainAxis || 32;
    this.offsetCrossAxis = currentStep.offsetCrossAxis || 20;

    const injectionTokens = new WeakMap();

    const item = this._productTourService.getItem(currentStep.tourItem);

    injectionTokens.set(PRODUCT_TOUR_ITEM_DATA, currentStep.data);
    injectionTokens.set(ProductTourDialogComponent, this);

    this.dataInjector = new PortalInjector(item.injector, injectionTokens);

    item.setActive(true);

    if (currentStep?.close$) {
      currentStep.close$.pipe(
        filter(val => !!val),
        takeUntil(this._destroyed$)
      ).subscribe(() => {
        this.stepDone();
      });
    }

    setTimeout(() => {
      this.positionDialog();
      this.stepPositioned = true;
    }, 0);
  }

  next() {
    if (this.currentStepIdx === this.data.steps.length - 1) {
      return;
    }
    const item = this._productTourService.getItem(this.currentStep.tourItem);
    item.setActive(false)
    this.currentStep = this.data.steps[this.currentStepIdx + 1];
    this.currentStepIdx = this.currentStepIdx + 1;
    this.execute();
  }

  previous() {
    if (this.currentStepIdx === 0) {
      return;
    }
    const item = this._productTourService.getItem(this.currentStep.tourItem);
    item.setActive(false)
    this.currentStep = this.data.steps[this.currentStepIdx - 1];
    this.currentStepIdx = this.currentStepIdx - 1;
    this.execute();
  }

  ngAfterViewChecked() {
    if (this.stepPositioned) {
      this.positionDialog();
    }
  }

  positionDialog() {
    if (this.visibleState === 'out') {
      return;
    }

    const tourItem = this._productTourService.getItem(this.currentStep.tourItem);
    const tourItemRect = tourItem.el.nativeElement.getBoundingClientRect();
    const contentRect = this.content.nativeElement.getBoundingClientRect();
    const viewportRect = document.documentElement.getBoundingClientRect();

    this.focusedArea.nativeElement.style.top = tourItemRect.top + 'px';
    this.focusedArea.nativeElement.style.left = tourItemRect.left + 'px';
    this.focusedArea.nativeElement.style.width = tourItemRect.width + 'px';
    this.focusedArea.nativeElement.style.height = tourItemRect.height + 'px';

    if (window.getComputedStyle) {
      const computedStyles = window.getComputedStyle(tourItem.el.nativeElement);

      this.focusedArea.nativeElement.style.borderTopLeftRadius = computedStyles['border-top-left-radius'].toString();
      this.focusedArea.nativeElement.style.borderTopRightRadius = computedStyles['border-top-right-radius'].toString();
      this.focusedArea.nativeElement.style.borderBottomRightRadius = computedStyles['border-bottom-right-radius'].toString();
      this.focusedArea.nativeElement.style.borderBottomLeftRadius = computedStyles['border-bottom-left-radius'].toString();
    }

    this.leftBackdrop.nativeElement.style.left = 0;
    this.leftBackdrop.nativeElement.style.top = 0;
    this.leftBackdrop.nativeElement.style.height = '100%';
    this.leftBackdrop.nativeElement.style.width = tourItemRect.left + 'px';

    this.rightBackdrop.nativeElement.style.right = 0;
    this.rightBackdrop.nativeElement.style.top = 0;
    this.rightBackdrop.nativeElement.style.height = '100%';
    this.rightBackdrop.nativeElement.style.left = tourItemRect.right + 'px';

    this.topBackdrop.nativeElement.style.left = tourItemRect.left + 'px';
    this.topBackdrop.nativeElement.style.width = tourItemRect.width + 'px';
    this.topBackdrop.nativeElement.style.top = 0;
    this.topBackdrop.nativeElement.style.height = tourItemRect.top + 'px';

    this.bottomBackdrop.nativeElement.style.left = tourItemRect.left + 'px';
    this.bottomBackdrop.nativeElement.style.width = tourItemRect.width + 'px';
    this.bottomBackdrop.nativeElement.style.top = tourItemRect.bottom + 'px';
    this.bottomBackdrop.nativeElement.style.bottom = 0;

    let offsetX = 0;
    let offsetY = 0;

    if (this.positionX === 'start' || this.positionX === 'center' || this.positionX === 'end') {
      offsetX = Math.min(tourItemRect.width / 2, this.offsetMainAxis);
    }

    if (this.positionX === 'before' || this.positionX === 'after') {
      offsetX = this.offsetCrossAxis;
    }

    if (this.positionY === 'start' || this.positionY === 'end') {
      offsetY = Math.min(tourItemRect.height / 2, this.offsetMainAxis);
    }

    if (this.positionY === 'above' || this.positionY === 'below') {
      offsetY = this.offsetCrossAxis;
    }

    if (this.positionX === 'start') {
      const left = tourItemRect.left + offsetX + 'px';

      this.arrow.nativeElement.style.left = left;
      this.contentRef.nativeElement.style.left = left;
    }

    if (this.positionX === 'center') {
      const left = tourItemRect.left + (tourItemRect.width - contentRect.width) / 2 + offsetX + 'px';

      this.arrow.nativeElement.style.left = left;
      this.contentRef.nativeElement.style.left = left;
    }

    if (this.positionX === 'before') {
      const right = this.rightBackdrop.nativeElement.getBoundingClientRect().width
        + tourItemRect.width
        + offsetX + 'px';

      this.arrow.nativeElement.style.right = right;
      this.contentRef.nativeElement.style.right = right;
    }

    if (this.positionX === 'after') {
      const left = tourItemRect.width + tourItemRect.left + offsetX + 'px';

      this.arrow.nativeElement.style.left = left;
      this.contentRef.nativeElement.style.left = left;
    }

    if (this.positionX === 'end') {
      const right = this.rightBackdrop.nativeElement.getBoundingClientRect().width + offsetX + 'px';

      this.arrow.nativeElement.style.right = right;
      this.contentRef.nativeElement.style.right = right;
    }

    if (this.positionY === 'above') {
      const bottom = viewportRect.height - this.topBackdrop.nativeElement.getBoundingClientRect().height + offsetY + 'px';

      this.arrow.nativeElement.style.bottom = bottom;
      this.contentRef.nativeElement.style.bottom = bottom;
    }

    if (this.positionY === 'start') {
      const top = tourItemRect.top + offsetY + 'px';

      this.arrow.nativeElement.style.top = top;
      this.contentRef.nativeElement.style.top = top;
    }

    if (this.positionY === 'end') {
      const bottom = this.bottomBackdrop.nativeElement.getBoundingClientRect().height + offsetY + 'px';

      this.arrow.nativeElement.style.bottom = bottom;
      this.contentRef.nativeElement.style.bottom = bottom;
    }

    if (this.positionY === 'below') {
      const top = tourItemRect.bottom + offsetY + 'px';

      this.arrow.nativeElement.style.top = top;
      this.contentRef.nativeElement.style.top = top;
    }
  }

  dialogAnimationDone(e: AnimationEvent) {
    this.onDialogAnimationDone(e);
  }

  stepDone() {
    this.hide();
    this._productTourService.getItem(this.currentStep.tourItem).setActive(false);
  }

  ngOnDestroy() {
    if (this.currentStep) {
      this._productTourService.getItem(this.currentStep.tourItem).setActive(false);
    }

    this._destroyed$.next();
    this._destroyed$.complete();

    super.ngOnDestroy();
  }
}
