export enum CreatePipelineStepPaths {
  SELECT_SOURCE_TYPE = 'select-source/select-source-type',
  SELECT_SOURCE_AUTH_TYPE = 'select-source/select-auth-type',
  SELECT_SOURCE_AUTH_ACCOUNT = 'select-source/select-auth-account',
  SOURCE_CONNECTION_SETTINGS = 'select-source/connection-settings',
  SELECT_OBJECTS = 'configure-jobs/objects-selection',
  CONFIGURE_OBJECTS = 'configure-jobs/objects-settings',
  CUSTOM_SQL_SETTINGS = 'configure-jobs/custom-sql',
  SELECT_SOURCE_VERSION = 'select-source/select-source-version',
  SELECT_DESTINATION_TYPE = 'connect-destination/select-dest-type',
  SELECT_EXISTING_DESTINATION = 'connect-destination/select-destination',
  SETUP_WAREHOUSE = 'connect-destination/setup-warehouse',
  SELECT_BI_TOOLS = 'final-settings/select-bi-tools',
  BI_TOOL_CREDENTIALS = 'final-settings/bi-credentials',
  DESTINATION_CONNECTION_SETTINGS = 'connect-destination/connection-settings',
  PIPELINE_SETTINGS = 'final-settings/pipeline-settings'
}
