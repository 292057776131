import { useState, useEffect } from 'react';

export function useVisibleOnScreen(refElement) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    const currentRef = refElement.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.unobserve(currentRef);
    };
  }, []);

  return isIntersecting;
}
