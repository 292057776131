// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.maskWrapper_wNubh {\n  position: relative;\n}\n.maskWrapper_wNubh .maskLabel__J30_ {\n  z-index: 1;\n  left: 0;\n  width: 75%;\n  position: absolute;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  margin-left: 16px;\n  top: 16px;\n}\n.maskWrapper_wNubh .maskAction__1PZE {\n  z-index: 2;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maskWrapper": "maskWrapper_wNubh",
	"maskLabel": "maskLabel__J30_",
	"maskAction": "maskAction__1PZE"
};
export default ___CSS_LOADER_EXPORT___;
