// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dropdownTrigger_Qb3w4 {\n  margin: 0;\n  cursor: pointer;\n  padding: 0;\n  background: none;\n  border-width: 0;\n  color: inherit;\n  font: inherit;\n  display: inline-flex;\n  padding: 4px 8px;\n  cursor: pointer;\n  border-radius: 4px;\n  background-color: inherit;\n  align-items: center;\n  font-weight: 600;\n}\n.dropdownTrigger_Qb3w4:focus {\n  outline: none;\n  text-decoration: none;\n}\n.dropdownTrigger_Qb3w4[disabled] {\n  cursor: not-allowed;\n}\n.dropdownTrigger_Qb3w4:focus:not(.disabled_o8ve6):not(.clearSurroundingGutter__DLI7), .dropdownTrigger_Qb3w4:hover:not(.disabled_o8ve6):not(.clearSurroundingGutter__DLI7) {\n  background-color: var(--ripple-bg-color);\n}\n.dropdownTrigger_Qb3w4 .icon_sp10T {\n  margin-left: 4px;\n  font-size: 14px;\n}\n.dropdownTrigger_Qb3w4 .thumbnail_sPQW_ {\n  margin-right: 16px;\n  font-size: 16px;\n}\n.dropdownTrigger_Qb3w4.disabled_o8ve6 {\n  cursor: default;\n}\n.dropdownTrigger_Qb3w4.disabled_o8ve6 .icon_sp10T {\n  opacity: 0;\n  transform: scale(0);\n}\n.dropdownTrigger_Qb3w4.clearHorizontalGutter_ca4KO {\n  margin-left: -8px;\n  margin-right: -8px;\n}\n.dropdownTrigger_Qb3w4.clearSurroundingGutter__DLI7 {\n  margin: -4px -8px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownTrigger": "dropdownTrigger_Qb3w4",
	"disabled": "disabled_o8ve6",
	"clearSurroundingGutter": "clearSurroundingGutter__DLI7",
	"icon": "icon_sp10T",
	"thumbnail": "thumbnail_sPQW_",
	"clearHorizontalGutter": "clearHorizontalGutter_ca4KO"
};
export default ___CSS_LOADER_EXPORT___;
