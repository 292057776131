export const passwordLengthValidator = value => passwordLengthRegex.test(value);

export const uppercaseValidator = value => uppercaseRegex.test(value);

export const lowercaseValidator = value => lowercaseRegex.test(value);

export const numericValidator = value => numberRegex.test(value);

export const specialCharValidator = value => specialCharRegex.test(value);

export const strongPasswordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

const passwordLengthRegex = /^.{8,}$/;
const numberRegex = /^(?=.*[0-9]).+$/;
const lowercaseRegex = /^(?=.*[a-z]).+$/;
const uppercaseRegex = /^(?=.*[A-Z]).+$/;
const specialCharRegex = /^(?=.*[\W_]).+$/;
