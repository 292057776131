
export const getNearestScrollableParent = (node) => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getNearestScrollableParent(node.parentNode);
  }
};
