import { HevoEntity } from '../../core/models/hevo-entity';
import { SourceService } from '../../core/service/source.service';
import { AccountEntityTypes } from './interface';

export const convertHevoEntityToAccountEntityTypes = (hevoEntity : HevoEntity) => {
  switch (hevoEntity) {
    case HevoEntity.DESTINATION:
      return AccountEntityTypes.DESTINATION;
    case HevoEntity.SOURCE:
      return AccountEntityTypes.SOURCE;
    case HevoEntity.DESTINATION:
      return AccountEntityTypes.TARGET
    default:
      return null;
  }
};

export const validateAdsAccountRequest = (sourceTypeIdentifier, oAuthToken, sourceService: SourceService) => {
  if (sourceTypeIdentifier === 'GOOGLE_ADS') {
    return sourceService.getAdwordsManagerProfiles(oAuthToken, undefined);
  }

  if (sourceTypeIdentifier === 'GOOGLE_ANALYTICS') {
    return sourceService.getGAViews(oAuthToken);
  }

  if (sourceTypeIdentifier === 'GOOGLE_CM') {
    return sourceService.getGCMProfilesAndReportTypes(oAuthToken);
  }

  if (sourceTypeIdentifier === 'FACEBOOK_ADS') {
    return sourceService.getFBAAccounts(oAuthToken);
  }

  if (sourceTypeIdentifier === "AMAZON_ADS") {
    return sourceService.getAmazonAdsProfiles(oAuthToken);
  }

  return;
};
