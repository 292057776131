import React, { useState } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { HdButton, HdDocLink, HdIcon, HdPane } from '../UIElements';
import FullScreenModal from '../UIElements/FullScreenModal';
import { PipelinesList } from './SyncFrequencySelectionStep';
import { usePipelinesForMigration } from './usePipelineForMigration';
import HdIndeterminateProgressBar from '../UIElements/HdProgressBar/IntedeterminateProgressBar';
import { ReviewPipelines } from './SyncFrequencyReviewStep';
import { MigrationAPI } from './MigrationAPI';
import { PolicyTypeEnum } from '../../../app/dialog/execution-policy-dialog/models';

enum MigrationStep {
  SELECT_FREQUENCY,
  REVIEW
}
interface DialogProps {
  open: boolean;
  onClose: Function;
}

export function MigratePipelinesToSyncFrequencyDialog({ open, onClose }: DialogProps) {
  const {
    loading,
    filteredPipelines,
    pipelines,
    updateSyncFrequency,
    listFilters,
    updateFilter,
    clearFilters
  } = usePipelinesForMigration();
  const [currentMigrationStep, setCurrentMigrationStep] = useState(MigrationStep.SELECT_FREQUENCY);

  const backHandler = () => {
    if (currentMigrationStep === MigrationStep.REVIEW) {
      setCurrentMigrationStep(MigrationStep.SELECT_FREQUENCY);
    } else {
      onClose();
    }
  };

  const migrationChangeHandler = () => {
    if (currentMigrationStep === MigrationStep.SELECT_FREQUENCY) {
      setCurrentMigrationStep(MigrationStep.REVIEW);
      clearFilters();
    } else {
      migrationAPIHandler();
    }
  };

  const migrationAPIHandler = () => {
    const payload = {};
    pipelines.forEach(pipeline => {
      const { type, frequency_value, frequency_type } = pipeline.sync_execution_policy;
      if (type === PolicyTypeEnum.FREQUENCY) {
        payload[pipeline.id] = { pipeline_schedule: { type, frequency_type, frequency_value } };
      } else {
        payload[pipeline.id] = { pipeline_schedule: pipeline.sync_execution_policy };
      }
    });

    MigrationAPI.migratePipelinesToSyncFrequency(payload).then(() => {
      MigrationAPI.enableSyncFrequency().then(() => {
        onClose(true);
      });
    });
  };

  return (
    <FullScreenModal
      open={open}
      className={styles.migrationModal}
      innerContentClass={styles.innerContentContainer}
    >
      <div className={styles.modalContentContainer}>
        <HdButton
          className={styles.hide}
          type='button'
          variation='flat'
          icon='back'
          palette='secondary'
          onClick={backHandler}
        >
          Go Back
        </HdButton>

        <div className={styles.header}>
          <div className='center-flex-row'>
            <div className={styles.iconBg}>
              <HdIcon name='date-time' size='4' className={styles.success} />
            </div>
            <p className='text-title-3-1 ml-4'>Migrate Pipelines To Sync Schedule</p>
          </div>

          <div className='text-secondary mt-4'>
            Configure the Sync Schedule to define the new schedules for the Pipelines.{' '}
            <span>
              <HdDocLink
                section='sync-frequency'
                label='Read More'
                docLink=''
                dataId='sync-frequency'
              />
            </span>
          </div>

          <HdPane variant='warning-faded' icon='warning' className={styles.migrationWarningPane}>
            <Typography variant='body2'>
              <b>This migration is irreversible.</b> You need to migrate all your existing Pipelines
              to use the new Sync Schedule. Once the Pipelines are migrated, they will run based on
              the sync frequency instead of the ingestion and load frequencies
            </Typography>
          </HdPane>
        </div>

        {loading ? (
          <div className={clsx('mt-8', styles.dialogLoader)}>
            <HdIndeterminateProgressBar>
              Hevo is loading your Pipelines...
            </HdIndeterminateProgressBar>
          </div>
        ) : (
          <>
            {currentMigrationStep === MigrationStep.SELECT_FREQUENCY ? (
              <PipelinesList
                pipelinesCount={pipelines.length}
                filteredPipelines={filteredPipelines}
                updateSyncFrequency={updateSyncFrequency}
                listFilters={listFilters}
                updateFilter={updateFilter}
              />
            ) : (
              <ReviewPipelines pipelines={pipelines} />
            )}

            <div className={styles.actionContainer}>
              <HdButton dataId='cancel' variation='outline' onClick={() => backHandler()}>
                cancel
              </HdButton>

              <HdButton
                dataId='next-step'
                className='ml-3'
                disabled={!pipelines.length}
                onClick={() => {
                  migrationChangeHandler();
                }}
              >
                {currentMigrationStep === MigrationStep.SELECT_FREQUENCY
                  ? 'review updates'
                  : 'migrate pipelines'}
              </HdButton>
            </div>
          </>
        )}
      </div>
    </FullScreenModal>
  );
}
