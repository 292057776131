import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { SidelineEventService } from '../../core/service/sideline-event.service';
import { Pipeline } from '../../pipeline/models/pipeline';
import { SourceObject } from '../../pipeline/source-objects/models/source-object';
import { SidelineMessage, SidelineMessageDisplayAction } from '../models/sideline-message';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'failed-event-types-trigger',
  templateUrl: './failed-event-types-trigger.component.html',
  styleUrls: [ './failed-event-types-trigger.component.scss' ],
  animations: [
    trigger('hoverButtonAnim', [
      state('open', style({ opacity: 1 })),
      state('close', style({ opacity: 0 })),
      transition('close <=> open', [
        animate('200ms')
      ])
    ])
  ]
})

export class FailedEventTypesTriggerComponent {
  @Input() count: number;
  @Input() sidelineMessageDisplayActions: Array<SidelineMessageDisplayAction>;
  @Input() object: SourceObject;
  @Input() pipeline: Pipeline;
  @Input() sidelineMessage: SidelineMessage;
  @Input() hasUncertainCount: boolean;
  @Input() isWidgetOpen: boolean;
  @Input() sidelineMessagesFound: boolean;

  ctaMenuOpenFlag = false;
  arrowAnim = 'close';
  arrowButtonClickFlag = false;

  constructor(
    private _sidelineEventService: SidelineEventService
  ) {
  }

  onCTAMenuOpen() {
    this.ctaMenuOpenFlag = true;
  }

  onCTAMenuCloseWithButton() {
    this.ctaMenuOpenFlag = false;
    if (this.arrowButtonClickFlag) {
      this.arrowAnim = 'close';
      this.arrowButtonClickFlag = false;
    }
  }

  checkDropdownMenuFlag(e) {
    if (this.ctaMenuOpenFlag) {
      return;
    }

    this.arrowAnim = 'close';
  }

  onCTAMenuCloseWithoutButton() {
    this.arrowButtonClickFlag = true;
  }

  actionClick(action, message) {
    this._sidelineEventService.performSidelineAction(this.pipeline, this.object, action, message);
  }
}
