import { Directive, ElementRef, Optional, SkipSelf } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, map, switchMap, take, tap, throttleTime } from 'rxjs/operators';

import smoothscroll from 'smoothscroll-polyfill';
import { CoordinatedScrollAnchorDirective } from './coordinated-scroll-anchor.directive';
import { HdScrollViewportComponent } from './scroll-viewport/scroll-viewport.component';

smoothscroll.polyfill();

@Directive({
  selector: '[hdCoordinatedScroll]'
})
export class CoordinatedScrollDirective {
  constructor(
    @SkipSelf() @Optional() private _parent: CoordinatedScrollDirective,
    @SkipSelf() @Optional() private _scrollAnchor: CoordinatedScrollAnchorDirective,
    private _scrollViewport: HdScrollViewportComponent,
    public el: ElementRef
  ) {
    if (!this._parent || !this._scrollAnchor) {
      return;
    }

    fromEvent(this._scrollViewport.elementRef.nativeElement, 'wheel').pipe(
      throttleTime(1000),
      switchMap((e) => {
        return this._scrollViewport.elementScrolled().pipe(
          take(1),
          map(() => {
            return this._scrollViewport.measureScrollOffset('top');
          }),
          filter(offset => offset > 0 && this._parent._scrollViewport.measureScrollOffset('top') < this._scrollAnchor.getTopOffset()),
          tap((dir) => {
            e.preventDefault();
            this._parent.scrollToAnchor(this._scrollAnchor.getTopOffset());
          })
        );
      }),
    ).subscribe();
  }

  public scrollToAnchor(scrollTop) {
    const offsetFromBottom = this._scrollViewport.measureScrollOffset('bottom');

    if (offsetFromBottom) {
      this._scrollViewport.elementRef.nativeElement.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  }
}
