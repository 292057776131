import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { map } from 'rxjs/operators';
import { AutoMappingStatus } from '../../core/models/auto-mapping-status';
import { ConfirmV2DialogService } from '../../dialog/confirm-v2-dialog/confirm-v2-dialog.service';


@Injectable()
export class DisableAutoMappingConfirmationService {
  constructor(
    private _confirmV2Service: ConfirmV2DialogService,
    private _angulartics: Angulartics2
  ) {
  }

  confirm() {
    return this._confirmV2Service.confirm({
      title: 'Do you really want to disable Auto Mapping?',
      body: '95% of the Pipelines with Auto Mapping do not face mapping errors. We strongly recommend keeping Auto Mapping enabled.',
      positiveBtnText: 'Keep Auto Mapping Enabled',
      negativeBtnText: 'Disable Auto Mapping',
      iconName: 'warning',
      iconContainerClass: 'deferred',
      positiveBtnClass: 'primary',
      backdropCloseDisabled: true
    }).pipe(
      map((res) => {
        let mappingStatus = AutoMappingStatus.NO_MAP;

        if (res || typeof res === 'undefined') {
          mappingStatus = AutoMappingStatus.MAP_ALL;
        } else {
          this._angulartics.eventTrack.next({
            action: '[Auto Mapping] disable auto mapping'
          });
        }

        return {
          autoMappingStatus: mappingStatus
        };
      })
    );
  }
}
