// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shimmerItem_tk3ms {\n  height: 8px;\n}\n\n.shimmerItemSmall_ck7Ps {\n  max-width: 38%;\n}\n\n.shimmerItemLarge_E0R0L {\n  max-width: 53%;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shimmerItem": "shimmerItem_tk3ms",
	"shimmerItemSmall": "shimmerItemSmall_ck7Ps",
	"shimmerItemLarge": "shimmerItemLarge_E0R0L"
};
export default ___CSS_LOADER_EXPORT___;
