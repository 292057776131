import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const FRESH_SALES_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPIKeyField({
        hint: 'Enter the API key obtained from the Profile Picture → Settings → API Settings → Copy the API Key.',
      })
    }
  ],
  [
    {
      key: 'domainName',
      fieldId: 'domain-name',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'domainName', label: 'Bundle Alias', required: true },
      hint: 'Enter the Bundle alias obtained from the Profile Picture → Settings → API Settings → Copy the Bundle Alias.',
      buildParamsKey: 'domain_name',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
