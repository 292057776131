import { Component, ElementRef, OnInit } from '@angular/core';
import { VersionPage } from '../../react/containers/about/VersionPage';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'version-react',
  template: '',
  providers: [ BridgeService ]
})
export class VersionReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      VersionPage,
      this._el.nativeElement,
      {}
    );
  }
}
