import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthPagesReactComponent } from './auth-pages-react.component';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule
  ],
  declarations: [
    AuthPagesReactComponent
  ],
  providers: []
})
export class AuthModule {
}
