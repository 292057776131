import { FieldTypes, SourceFormFieldDefinition } from '../models';


export const STAMPED_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'apiKeyPublic',
      fieldId: 'api-key-public',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'apiKeyPublic', label: 'API Key Public', required: true },
      hint: 'Click "Settings → API keys" from the menu located at the top to get the API Key Public.',
      buildParamsKey: 'public_key',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'apiKeyPrivate',
      fieldId: 'api-key-private',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'apiKeyPrivate', label: 'API Key Private', required: true },
      hint: 'Click "Settings → API keys" from the menu located at the top to get the API Key Private.',
      buildParamsKey: 'secret_key',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'storeHash',
      fieldId: 'store-hash',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'storeHash', label: 'Store Hash', required: true },
      hint: 'Click "Settings → API keys" from the menu located at the top and you will find the Store Hash value at the bottom.',
      buildParamsKey: 'store_hash',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
