import { GlobalSearchState, GlobalSearchEntity } from './models';

export const GLOBAL_SEARCH_DEFAULT_ENTITY_STATE = {
  entities: [],
  filteredEntities: [],
  loadingData: false
};

export const GLOBAL_SEARCH_DEFAULT_STATE: GlobalSearchState = {
  [GlobalSearchEntity.PIPELINES]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.DESTINATIONS]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.WORKFLOWS]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.MODELS]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.TARGETS]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.ACTIVATIONS]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.SOURCE_TYPES]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.DESTINATION_TYPES]: GLOBAL_SEARCH_DEFAULT_ENTITY_STATE,
  [GlobalSearchEntity.FILTERS]: {
    search: ''
  }
};

export const GLOBAL_SEARCH_ENTITY_FILTER_FIELDS = {
  [GlobalSearchEntity.PIPELINES]: [
    'source_name',
    'dest_name',
    'dest_type',
    'source_type',
    'source_category_type',
    'source_type_search_keywords'
  ],
  [GlobalSearchEntity.DESTINATIONS]: ['name', 'type'],
  [GlobalSearchEntity.MODELS]: ['name', 'destination.name', 'destination.dest_type'],
  [GlobalSearchEntity.WORKFLOWS]: ['name'],
  [GlobalSearchEntity.ACTIVATIONS]: [
    'name',
    'warehouse.name',
    'warehouse.warehouseType',
    'target.name',
    'target.targetType'
  ],
  [GlobalSearchEntity.TARGETS]: ['name', 'targetType'],
  [GlobalSearchEntity.SOURCE_TYPES]: ['source_type_display', 'source_type_search_keywords'],
  [GlobalSearchEntity.DESTINATION_TYPES]: ['dest_type_display', 'search_keywords', 'dest_type']
};
