import React, { useState } from 'react';
import moment from 'moment-mini-ts';
import useInterval from '../../../hooks/useInterval';
import { getSecondsUntilUpdate } from '../../../utils/momentUtils';

export interface HdTimeAgoProps {
  timestamp: number | Date;
}

const momentConstructor = moment;

export default function HdTimeAgo({ timestamp }: HdTimeAgoProps) {
  const [displayTime, setDisplayTime] = useState(moment(timestamp).fromNow());

  const momentInstance = momentConstructor(timestamp);
  const timeToUpdate = getSecondsUntilUpdate(momentInstance) * 1000;

  const getTimeAgo = () => {
    setDisplayTime(moment(timestamp).fromNow());
  };

  useInterval(getTimeAgo, timeToUpdate);

  return <>{displayTime}</>;
}
