import clsx from 'clsx';
import React, { useRef } from 'react';
import { getDataId } from '../../../utils/generateDataId';
import { HdTooltip } from '../HdTooltip';
import styles from './styles.module.scss';
import HdIcon from '../HdIcon';
import { useVisibleOnScreen } from '../../../hooks/useVisibleOnScreen';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';

export interface HdMenuTriggerProps {
  className?: string;
  children: React.ReactNode;
  id: string;
  dataId?: string;
  onClick: Function;
  disabled?: boolean;
  thumbnail?: string;
  clearHorizontalGutter?: boolean;
  onForceClose?: Function;
  showDropdownIcon?: boolean;
  clearAllGutter?: boolean;
  overflowTooltipContent?: string;
}

export default function HdMenuTrigger({
  className = '',
  onClick,
  id,
  dataId = '',
  children,
  disabled,
  thumbnail,
  clearHorizontalGutter,
  onForceClose,
  showDropdownIcon = true,
  clearAllGutter,
  overflowTooltipContent = ''
}: HdMenuTriggerProps) {
  const menuRef = useRef<HTMLButtonElement>(null);
  const isVisible = useVisibleOnScreen(menuRef);

  useDontMountAtFirst(() => {
    if (!isVisible) {
      const activeElementRef = document.activeElement;
      if (
        activeElementRef instanceof HTMLElement &&
        activeElementRef.getAttribute('role') === 'menuitem'
      ) {
        activeElementRef.blur();
      }
      if (onForceClose) {
        onForceClose();
      }
    }
  }, [isVisible]);

  dataId = getDataId('button')(dataId)('menuTrigger');

  return (
    <button
      ref={menuRef}
      type='button'
      id={id}
      data-id={dataId}
      onClick={event => onClick(event.currentTarget)}
      className={clsx(styles.dropdownTrigger, className, {
        [styles.disabled]: disabled,
        [styles.clearSurroundingGutter]: clearAllGutter,
        [styles.clearHorizontalGutter]: !clearAllGutter && clearHorizontalGutter
      })}
      aria-describedby='hd-menu'
      aria-controls='hd-menu'
      aria-haspopup='true'
    >
      {thumbnail ? <HdIcon name={thumbnail} className={styles.thumbnail} /> : null}

      <HdTooltip title={overflowTooltipContent} enableOnTextOverflow={!!overflowTooltipContent}>
        <span className='text-ellipsis flex-1'>{children}</span>
      </HdTooltip>

      {showDropdownIcon ? <HdIcon name='dropdown-arrow' className={styles.icon} /> : null}
    </button>
  );
}
