import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { HevoEntity } from '../models/hevo-entity';
import { NetworkRequestOptions } from '../models/request';
import { RxRequestService } from './rx-request.service';

export let _recentEntitiesService;

@Injectable({ providedIn: 'root' })
export class RecentEntitiesService {
  recentMap: { [key: string]: BaseEntity } = {};

  utilityUrl = this._appConfig.getUtilityURL();
  integrationsUrl = this._appConfig.getIntegrationsURL();
  modelsUrl = this._appConfig.getModelsURL();
  workflowUrl = this._appConfig.getWorkflowsURL();
  destinationUrl = this._appConfig.getDestinationsURL();
  activationUrl = this._appConfig.getActivationsURL();
  dbtModelsUrl = this._appConfig.getDbtURL();
  activationTargetUrl = this.activationUrl + '/targets';
  recentEntityUpdate = new Subject<{ [key: string]: BaseEntity }>();

  constructor(
    private _appConfig: AppConfig,
    private _requestService: RxRequestService
  ) {
    _recentEntitiesService = this;
  }

  getRecent(entity: string) {
    return this.recentMap[entity];
  }

  fetchRecent() {
    const requestUrl = `${ this.utilityUrl }/recent-entities`;

    return this._requestService.get(requestUrl).pipe(
      tap((res: any) => {
        this.recentMap = res.data;
      })
    );
  }

  getRecentIntegration() {
    return this._getRecentEntity(this.integrationsUrl, HevoEntity.PIPELINE.value);
  }

  getRecentModel() {
    return this._getRecentEntity(this.modelsUrl, HevoEntity.MODEL.value);
  }

  getRecentDbtModel() {
    return this._getRecentEntity(this.dbtModelsUrl, HevoEntity.DBT_PROJECT.value);
  }

  getRecentWorkflow(showLoading = true) {
    return this._getRecentEntity(this.workflowUrl, HevoEntity.WORKFLOW.value, showLoading);
  }

  getRecentDestination() {
    return this._getRecentEntity(this.destinationUrl, HevoEntity.DESTINATION.value);
  }

  getRecentActivation() {
    return this._getRecentEntity(this.activationUrl, HevoEntity.ACTIVATION.value);
  }

  getRecentActivationTarget() {
    return this._getRecentEntity(this.activationTargetUrl, HevoEntity.ACTIVATION_TARGET.value);
  }

  setRecent(entity: string, data: BaseEntity) {
    this.recentMap[entity] = data;
    this.recentEntityUpdate.next(this.recentMap);
  }

  private _getRecentEntity(baseURL, entity: string, showLoading = true) {
    const requestUrl = baseURL + '/recent';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showErrorMsg: false,
        showLoading
      }
    };

    return this._requestService.get(requestUrl, options).pipe(
      tap((res: any) => {
        this.setRecent(entity, res.data && res.data.id ? res.data : null);
      })
    );
  }
}

interface BaseEntity {
  id: number;
  seq_id: number;
  team_id: number;
  type: string;
  status: string;
}
