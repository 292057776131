import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { MixPanelRegion } from './models';

export class MixpanelConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      apiSecret: rawData.api_secret,
      events: rawData.events,
      region: rawData.account_project_region || MixPanelRegion.US
    };
  }

  getDefaultConfig() {
    return {
      region: MixPanelRegion.US
    };
  }

  buildConnectionParams(data: any) {
    return {
      api_secret: data.apiSecret,
      events: data.events || undefined,
      account_project_region: data.region === MixPanelRegion.EU ? data.region : null
    };
  }
}
