import { InViewArgs, PositionsObjectType } from './model';

export function safe(sum: number): number {
  return sum < 0 ? 0 : sum;
}

export function getInViewThreshold(threshold: InViewArgs['threshold']) {
  if (typeof threshold === 'object' && threshold !== null) {
    return {
      thresholdX: (threshold.x || 0) as number,
      thresholdY: (threshold.y || 0) as number
    };
  }
  return {
    thresholdX: (threshold || 0) as number,
    thresholdY: (threshold || 0) as number
  };
}

export function getWindow(): { w: number; h: number } {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return { w, h };
}

export function inView({ top, right, bottom, left, threshold }: InViewArgs): boolean {
  const { w: windowWidth, h: windowHeight } = getWindow();
  const { thresholdX, thresholdY } = getInViewThreshold(threshold);

  return top < 0 && bottom - top > windowHeight
    ? true
    : top >= 0 + thresholdY &&
        left >= 0 + thresholdX &&
        bottom <= windowHeight - thresholdY &&
        right <= windowWidth - thresholdX;
}

export const isHoriz = (pos: string) => /(left|right)/.test(pos);
export const isOutsideX = (val: number, windowWidth: number): boolean => val > windowWidth;
export const isOutsideY = (val: number, windowHeight: number): boolean => val > windowHeight;

export function bestPositionOf(positions: PositionsObjectType): string[] {
  return Object.keys(positions)
    .map(p => ({
      position: p,
      value: positions[p]
    }))
    .sort((a, b) => b.value - a.value)
    .map(p => p.position);
}

const defaultPadding = 10;

export function getPadding(padding: number | [number, number] = defaultPadding) {
  if (Array.isArray(padding)) {
    return padding[0]
      ? [padding[0], padding[1] ? padding[1] : padding[0]]
      : [defaultPadding, defaultPadding];
  }
  return [padding, padding];
}
