import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { InviteMemberOptions } from '../models/user';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { AuthService } from './auth.service';
import { RxRequestService } from './rx-request.service';

export let _teamService: TeamService;
@Injectable()
export class TeamService {
  constructor(
    private _appConfig: AppConfig,
    private _authService: AuthService,
    private _rxRequestService: RxRequestService
  ) {
    _teamService = this;

    this._authService.logoutSubject.subscribe(() => {
      this.teamDetails = null;
    });
  }

  private _securityServiceURL = this._appConfig.getSecurityServiceURL();

  teamURL = `${ this._securityServiceURL }/teams/my`;
  usersURL = `${ this._securityServiceURL }/users`;
  feedbackURL = `${ this._securityServiceURL }/feedbacks`;
  npsURL = `${ this.feedbackURL }/nps`;

  teamDetails: any;

  inviteMember(inviteOptions: InviteMemberOptions) {
    const requestUrl = this.usersURL + '/invite-member';

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, inviteOptions);
  }

  getTeamDetails() {
    return this._rxRequestService.get(this.teamURL, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      tap((res) => {
        this.teamDetails = res.data;
      })
    );
  }

  updateTeamConfig(params) {
    const requestUrl = `${ this.teamURL }/config`;

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  getNpsStatus() {
    return this._rxRequestService.get(this.npsURL, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  updateNPSRating(feedbackId: string, rating: number, comment: string) {
    const params = {
      feedback_request_id: feedbackId,
      score: rating,
      comment: comment
    };

    return this._rxRequestService.post(this.npsURL, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  refuseRating(feedbackId: string) {
    const reqOptions: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          feedback_request_id: feedbackId
        })
      }
    };

    return this._rxRequestService.delete(this.npsURL, reqOptions);
  }
}
