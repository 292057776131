import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { HdScrollViewportComponent } from '../scroll-viewport/scroll-viewport.component';
import { ScrollDispatcher } from '@angular/cdk/overlay';

@Component({
  selector: 'hd-scroll-top',
  templateUrl: './hd-scroll-top.component.html',
  styleUrls: [ './hd-scroll-top.component.scss' ]
})
export class HdScrollTopComponent implements OnInit {
  private _container: HdScrollViewportComponent;

  attached = false;

  anchor: HTMLElement;

  offset = 20;

  compact = false;

  withPadding = true;

  private _anchorDefaultPaddingBottom: string;
  private _containerDefaultPosition: string;

  constructor(
    private _el: ElementRef,
    private _scrollDispatcher: ScrollDispatcher,
    private _cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  registerScrollableContainer(container: HdScrollViewportComponent) {
    this._container = container;
  }

  onAttach() {
    this.attached = true;
    this._anchorDefaultPaddingBottom = this.anchor.style.paddingBottom;
    this._containerDefaultPosition = this._container.elementRef.nativeElement.parentElement.style.position;
    this._container.elementRef.nativeElement.parentElement.style.position = 'relative';

    if (this.withPadding) {
      this.anchor.style.paddingBottom = this._el.nativeElement.offsetHeight + this.offset + 'px';
    }
    this._cdRef.detectChanges();
  }

  onDetach() {
    this.attached = false;
    this._container.elementRef.nativeElement.parentElement.style.position = this._containerDefaultPosition;
    if (this.withPadding) {
      this.anchor.style.paddingBottom = this._anchorDefaultPaddingBottom;
    }
  }

  @HostListener('click') onClick() {
    this._container.scrollTop(0);
  }

  @HostListener('window:resize') onWindowResize() {
    this.position();
  }

  position() {
    const el = (this._el.nativeElement as HTMLElement);

    const anchorTop = this.anchor.offsetTop;
    const anchorLeft = this.anchor.offsetLeft;
    const anchorHeight = this.anchor.offsetHeight;
    const anchorWidth = this.anchor.offsetWidth;


    el.style.top = anchorTop + anchorHeight - this.offset - el.offsetHeight + 'px';
    el.style.left = anchorLeft + anchorWidth - this.offset - el.offsetWidth + 'px';
  }

  clear() {
    this._container = null;
    this.anchor = null;
  }
}
