// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdownInline__L_WO {\n  min-width: 256px;\n  max-width: 500px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownInline": "dropdownInline__L_WO"
};
export default ___CSS_LOADER_EXPORT___;
