// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.tag__o_zc {\n  display: flex;\n  align-items: center;\n  padding-left: 8px;\n  padding-right: 2px;\n  border-radius: 4px;\n  font-size: 10px;\n  font-weight: 600;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  border: solid 1px var(--divider-color);\n  color: var(--text-default-color);\n  background: var(--surface-bg-color);\n  overflow: hidden;\n  margin-left: 12px;\n  cursor: pointer;\n  pointer-events: auto;\n}\n.tag__o_zc.withoutCloseButton_xLI96 {\n  min-width: fit-content;\n  padding: 5.12px 8px;\n}\n.tag__o_zc .closeButton_unKya {\n  margin-left: 4px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "tag__o_zc",
	"withoutCloseButton": "withoutCloseButton_xLI96",
	"closeButton": "closeButton_unKya"
};
export default ___CSS_LOADER_EXPORT___;
