import { Injectable } from '@angular/core';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';
import { ExecIfDefined } from '../../../react/legacy-utils/decorators';

declare var mixpanel: any;

@Injectable({ providedIn: 'root' })
export class MixpanelService implements UITracker {
  @ExecIfDefined('mixpanel')
  init(trackingConfig) {
    if (mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_in_tracking();
    }
  }

  @ExecIfDefined('mixpanel')
  identifyUser(user: User) {
    mixpanel.people.set({
      'User ID': user.id,
      'Company': user.teamName,
      'Company ID': user.teamId,
      'Env': user.clusterId,
      '$email': user.email,
      '$name': user.name,
      '$created': user.createdTs
    });

    mixpanel.identify(user.email);
  }

  updateIdentity(data: User) {
    this.identifyUser(data);
  }

  @ExecIfDefined('mixpanel')
  updateUserProperties(user: User, data: any) {
    mixpanel.people.set(data);
    mixpanel.identify(user.email);
  }

  @ExecIfDefined('mixpanel')
  reset() {
    mixpanel.reset();
  }

  @ExecIfDefined('mixpanel')
  stop() {
    mixpanel.opt_out_tracking();
  }
}
