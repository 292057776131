// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dialogBody_H7zgA {\n  height: 500px;\n  overflow: auto;\n  background: var(--default-bg-color);\n  padding: 0;\n}\n.dialogBody_H7zgA .dialogActivityBody__Bhrt {\n  padding: 12px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogBody": "dialogBody_H7zgA",
	"dialogActivityBody": "dialogActivityBody__Bhrt"
};
export default ___CSS_LOADER_EXPORT___;
