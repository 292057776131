import { Injectable, OnDestroy } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { StorageKey } from '../../pipeline/storage-key';

import { PIPELINE_SESSION_ID_KEY } from '../constants';
import { SessionTrackingAngularticsService } from './session-tracking-angulartics.service';
import { SettingsStorageService } from './settings-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CreatePipelineAngularticsService extends SessionTrackingAngularticsService implements OnDestroy {
  constructor(
    protected _angulartics2: Angulartics2,
    protected _settingsStorageService: SettingsStorageService
  ) {
    super(
      _angulartics2,
      _settingsStorageService,
      PIPELINE_SESSION_ID_KEY
    );
  }

  getEventTrackingProperties(data: any) {
    return {
      ...super.getEventTrackingProperties(data),
      sourceId: this.getDraftSourceId(),
      destinationId: this.getDraftDestinationId()
    };
  }

  getDraftSourceId() {
    return this._settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_SOURCE)?.id;
  }

  getDraftDestinationId() {
    return this._settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_DESTINATION)?.id;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
