import React, { useRef } from 'react';
import { attachListener } from '../utils/keyboardShortcuts';

function useKeyboardShortcutListener(callback: Function, command: string, options = {}) {
  const propsRef = useRef({ callback, command, options });
  propsRef.current = { callback, command, options };

  React.useEffect(() => {
    function commandCallback(event) {
      propsRef.current.callback(event);
    }

    const keyboardUnlisten = attachListener(
      {
        [propsRef.current.command]: (event) => {
          commandCallback(event);
        }
      },
      propsRef.current.options
    );

    return () => {
      if (typeof keyboardUnlisten === 'function') {
        keyboardUnlisten();
      }
    };
  }, []);
}

export default useKeyboardShortcutListener;
