import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SecondaryNavResizeService {
  private _resize$ = new Subject();

  resize$ = this._resize$.asObservable();

  onResize() {
    this._resize$.next();
  }
}
