import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import DownloadAPI from './DownloadAPI';

export function DownloadPage() {
  return (
    <Routes>
      <Route
        key='download-page'
        path='/download/:feature/:filename'
        element={<DownloadPageInner />}
      />
    </Routes>
  );
}

export function DownloadPageInner() {
  const { eventTrack } = useAnalyticsTracker();
  const { feature, filename } = useParams<{ feature: string; filename: string }>();
  const history = useHistory();

  useEffect(() => {
    eventTrack({
      action: '[Download Page] Download event',
      properties: {
        feature,
        filename
      }
    });

    DownloadAPI.downloadFile(feature, filename).then(
      res => {
        const blob = new Blob([res.data], {
          type: 'text/csv;charset=utf-8;'
        });
        window.open(window.URL.createObjectURL(blob));

        history.replace('/pipeline');
      },
      () => {
        history.replace('/pipeline');
      }
    );
  }, [feature, filename]);

  return <></>;
}
