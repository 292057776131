import { useHistory } from '@useHistory/useHistoryCompat';
import useUserService from '../../hooks/services/useUserService';
import AuthAPI from './AuthAPI';
import { useLogout } from './useLogout';

export function useLoginGuard() {
  const { user } = useUserService();
  const history = useHistory();
  const { logout } = useLogout();

  const executeLoginGuard = () => {
    try {
      const searchParams = new URLSearchParams(history.location?.search);

      const requestedEmail = searchParams.get('email');
      const nextUrl = searchParams.get('next');

      if (user) {
        return Promise.resolve(requestedEmail && user.email !== requestedEmail)
          .then(isRequestedEmailDifferent => {
            if (isRequestedEmailDifferent) {
              return true;
            }

            return AuthAPI.isUserSessionStillValid().then(isValidSession => !isValidSession);
          })
          .then(shouldLogout => {
            if (shouldLogout) {
              return logout(false, false).then(() => {
                return true;
              });
            }

            if (nextUrl) {
              history.replace(nextUrl);
            } else {
              history.replace('/');
            }

            return false;
          });
      }
    } catch (e) {}

    return true;
  };

  return {
    executeLoginGuard
  };
}
