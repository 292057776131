// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.paneContainer_YdD8t {\n  border: solid 1px var(--divider-color);\n  border-radius: 6px;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 32px;\n}\n\n.pipelineScheduleHeader_vya34 {\n  border-bottom: solid 1px var(--divider-color);\n  padding: 12px 20px;\n  display: flex;\n  align-items: center;\n}\n\n.scheduleContainer_JCcSr {\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.pipelineSchedulerOptions_OmGhY {\n  margin: 20px 0 0 0;\n}\n.pipelineSchedulerOptions_OmGhY button {\n  background: var(--calendly-surface-bg);\n  border: 1px solid var(--divider-color);\n}\n\n.contactSalesCTA__dXqJ {\n  max-width: 120px;\n  margin-top: 16px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paneContainer": "paneContainer_YdD8t",
	"pipelineScheduleHeader": "pipelineScheduleHeader_vya34",
	"scheduleContainer": "scheduleContainer_JCcSr",
	"pipelineSchedulerOptions": "pipelineSchedulerOptions_OmGhY",
	"contactSalesCTA": "contactSalesCTA__dXqJ"
};
export default ___CSS_LOADER_EXPORT___;
