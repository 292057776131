import { RadioGroup, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { getDataIdGenerator } from '../../../utils/generateDataId';

interface HdRadioGroupProps {
  children: React.ReactNode;
  name: string;
  defaultValue?: string;
  currentValue?: string;
  id?: string;
  row?: boolean;
  helperText?: string;
  label?: string;
  labelVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  className?: string;
  labelStyleProps?: SxProps<Theme>;
  onChange?: Function;
  [key: string]: any;
}

export function HdRadioGroup({
  children,
  name,
  id,
  label,
  row = true,
  className,
  labelVariant = 'body4',
  helperText = '',
  defaultValue = null,
  currentValue = null,
  labelStyleProps = {},
  onChange = () => {},
  ...props
}: HdRadioGroupProps) {
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = (event.target as HTMLInputElement).value;
    setValue(selected);
    onChange(selected);
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(name, 'radioGroup'), [name]);

  return (
    <>
      {label ? (
        <Typography
          data-id={dataIdGenerator('label')}
          variant={labelVariant}
          id={id || name}
          className='mb-5'
          sx={labelStyleProps}
        >
          {label}
        </Typography>
      ) : null}

      <RadioGroup
        row={row}
        aria-labelledby={id || name}
        name={name}
        value={value}
        data-id={dataIdGenerator('input')}
        onChange={handleChange}
        className={className}
        {...props}
      >
        {children}
      </RadioGroup>
      {helperText}
    </>
  );
}
