import clsx from 'clsx';
import React, { forwardRef } from 'react';

export interface HevoIconProps {
  name: string;
  size?: number;
  className?: string;
  dataId?: string;
  [key: string]: any;
}

const HdIcon = forwardRef<any, HevoIconProps>(
  ({ name, size, className = '', dataId = '', ...props }: HevoIconProps, ref) => (
    <span
      ref={ref}
      data-id={dataId}
      {...props}
      className={clsx(`hevo-icon hevo-${name}`, size && `icon-size-${size}`, className)}
    />
  )
);

export default HdIcon;
