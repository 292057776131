import React from 'react';
import clsx from 'clsx';
import { PasswordStrengthBar } from './PasswordStrengthBar';
import { usePasswordStrengthValidator } from '../../hooks/usePasswordStrengthValidator';
import { STRENGTH_TITLE_MAP } from './contants';
import { RuleContainer } from './RuleContainer';

interface StrongPasswordRulesWrapperProps {
  rules: Array<{ [key: string]: any }>;
  value: string;
}

export function StrongPasswordRulesWrapper({ rules, value }: StrongPasswordRulesWrapperProps) {
  const { strength, ruleMatchStatus } = usePasswordStrengthValidator(rules, 4, value);

  const { titleClass, title, passwordStrengthColor } = STRENGTH_TITLE_MAP[strength];

  return (
    <div className='p-2'>
      <div className={clsx('text-body-3 mb-1', titleClass)}>{title}</div>

      <PasswordStrengthBar
        barCount={4}
        strength={strength}
        strengthBarClass={passwordStrengthColor}
      />

      <div className='mt-2'>
        <div className='text-caption-1'>Password must have:</div>
        {rules.map(rule => (
          <RuleContainer name={rule.rule_title} status={ruleMatchStatus[rule.rule_name]} />
        ))}
      </div>
    </div>
  );
}
