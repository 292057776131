import { InputAdornment } from '@mui/material';
import { FieldProps, getIn } from 'formik';
import React, { ChangeEvent, FocusEvent, useCallback, useRef, useState } from 'react';
import { FormikHelperText } from '../../../utils/FormikHelperText';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdIcon from '../../UIElements/HdIcon';
import { HdIconButton } from '../../UIElements/HdIconButton';
import { HdTextField } from '../../UIElements/HdTextField';
import HdTextFieldMasked from '../../UIElements/HdTextFieldMasked';
import styles from './styles.module.scss';

interface PasswordFieldInterface {
  id: string;
  label: string;
  variant: 'outlined' | 'standard' | 'filled';
  helperText?: React.ReactNode;
  className?: string;
  type?: string;
  masked?: boolean;
  required?: boolean;
  prefixIcon?: string;
  prefixText?: string;
  suffixText?: string;
  visibilityToggle?: boolean;
  startAdornment?: React.ReactNode;
  onMaskResetCallback?: Function;
  onChange?: Function;
  onBlur?: Function;
  dataId?: string;
}

// eslint-disable-next-line react/function-component-definition
const HdFormikPasswordField: React.FC<PasswordFieldInterface & FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  type,
  label,
  id,
  masked,
  visibilityToggle = true,
  variant = 'outlined',
  helperText = ' ',
  startAdornment,
  suffixText,
  prefixIcon,
  prefixText,
  onBlur,
  onChange,
  onMaskResetCallback,
  dataId,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showMask, setShowMask] = useState(field.value?.length ? masked : false);
  const inputRef = useRef(null);
  const showError =
    (touched[field.name] && !!errors[field.name]) ||
    (getIn(errors, field.name) && getIn(touched, field.name));

  const resetMaskHandler = () => {
    setFieldValue(field.name, '');
    inputRef.current?.focus();
    setShowMask(false);

    if (onMaskResetCallback) {
      onMaskResetCallback();
    }
  };

  const onTogglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const onChangeEvent = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(`${dataId ? `${dataId}-` : ''}${field.name}`, 'passwordField'), [
    field.name
  ]);

  return (
    <>
      {showMask ? (
        <HdTextFieldMasked
          label={label}
          helperText={helperText}
          value={field.value}
          required={props.required}
          startAdornment={startAdornment}
          onChangeClick={resetMaskHandler}
          dataId={dataIdGenerator('')}
        />
      ) : null}
      <HdTextField
        id={id || field.name}
        variant={variant}
        label={label}
        placeholder={label}
        helperText={FormikHelperText({
          helperText,
          showError,
          fieldName: field.name,
          errorMessage: errors[field.name]
        })}
        suffixText={suffixText}
        prefixIcon={prefixIcon}
        prefixText={prefixText}
        type={showPassword ? 'text' : 'password'}
        {...field}
        {...props}
        error={showError}
        inputRef={inputRef}
        autoComplete='off'
        className={`${props.className} ${showMask ? styles.inputFieldMasked : ''}`}
        onBlur={onBlurEvent}
        onChange={onChangeEvent}
        dataId={dataIdGenerator('')}
        InputProps={{
          startAdornment,
          inputProps: {
            ...props
          },
          endAdornment: (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {visibilityToggle ? (
                <InputAdornment position='end'>
                  <HdIconButton
                    aria-label='toggle password visibility'
                    onClick={onTogglePasswordVisibility}
                    edge='start'
                    type='button'
                    dataId={dataIdGenerator('toggle')}
                  >
                    {!showPassword ? <HdIcon name='view-password' /> : <HdIcon name='no-preview' />}
                  </HdIconButton>
                </InputAdornment>
              ) : null}
            </>
          )
        }}
      />
    </>
  );
};

export default HdFormikPasswordField;
