import React, { useCallback, useMemo, useState } from 'react';

interface IAnalytics {
  sessionId: string;
  updateSessionId: (sessionId: string) => void;
}

const analyticsDefaultContext = {
  sessionId: null,
  updateSessionId: () => {}
};

export const AnalyticsContext = React.createContext<IAnalytics>(analyticsDefaultContext);

export function AnalyticsSessionProvider({ children }) {
  const [sessionId, setSessionId] = useState();

  const updateSessionId = useCallback(newSessionId => {
    setSessionId(newSessionId);
  }, []);

  const value = useMemo(
    () => ({
      sessionId,
      updateSessionId
    }),
    [updateSessionId, sessionId]
  );
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}
