// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.nodeRelationContainer_ZOFZR {\n  display: flex;\n  align-items: flex-start !important;\n  flex-direction: column;\n  color: var(--text-default-color);\n  max-width: 223px;\n}\n.nodeRelationContainer_ZOFZR .nodeLogoText_qCJyQ {\n  max-width: 50px;\n}\n\n.pipelineConnectionIndicator_DE77h {\n  font-size: 18px;\n  margin: 0 12px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeRelationContainer": "nodeRelationContainer_ZOFZR",
	"nodeLogoText": "nodeLogoText_qCJyQ",
	"pipelineConnectionIndicator": "pipelineConnectionIndicator_DE77h"
};
export default ___CSS_LOADER_EXPORT___;
