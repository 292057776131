import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { SESSION_KEY } from './constants';
import { LocalStorageService } from './service/local-storage.service';


@Injectable()
export class RestInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService: CookieService,
    private _localStorageService: LocalStorageService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('Anonymous') === 'True') {
      const newHeaders = req.headers.delete('Anonymous');
      const newRequest = req.clone({ headers: newHeaders });
      return this.handleResponse(next.handle(newRequest));
    }

    const token = this._cookieService.get('ht');
    const sessionId = this._localStorageService.get(SESSION_KEY);

    if (token || sessionId) {
      const authReq = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ sessionId || token }`
        }
      });

      return this.handleResponse(next.handle(authReq));
    } else {
      const authReq = req.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });

      return this.handleResponse(next.handle(authReq));
    }
  }

  handleResponse($next) {
    return $next.pipe(
      filter((event) => event instanceof HttpResponse),
      tap((res: HttpResponse<any>) => {
        const newSessionId = res.body?.data?.session_token;

        if (newSessionId) {
          this._localStorageService.set(SESSION_KEY, newSessionId);
        }
      })
    );
  }
}
