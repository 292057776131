import { combineLatest, interval, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';

export function intervalWithDelay(
  period: number,
  delayMs: number = 0
): Observable<number> {
  return of([])
  .pipe(
    delay(delayMs),
    switchMap(() => {
      return interval(period).pipe(startWith(0));
    })
  );
}

export const muteFirst = <T, R>(first$: Observable<T>, second$: Observable<R>) => combineLatest(
  first$,
  second$,
  (a, b) => b
).pipe(
  distinctUntilChanged()
);
