/* eslint-disable consistent-return */
import {
  DEFAULT_INCLUSION_LIST_STATE,
  InclusionItem,
  InclusionItemKey,
  InclusionItemState,
  InclusionListState
} from '../../../app/core/models/inclusion-list-v2';
import { FolderItem, FolderSelectionRow, ROOT_ENTITY, SelectorItem } from './model';

export const getFolderData = (data, prefix, cleanupPrefix = true): FolderItem[] =>
  (data || []).map(folder => {
    // clean up the api response for edit mode
    if (cleanupPrefix && typeof folder === 'string') {
      const buildPrefix = folder.replace(/\/+$/, '').split('/');
      if (buildPrefix.length > 1) {
        buildPrefix.pop();
        // eslint-disable-next-line no-param-reassign
        prefix = buildPrefix.join('/').concat('/');
      }
    }
    // clean up the api response
    const folderName =
      typeof folder === 'string' ? folder.replace(prefix, '').replace(/\/+$/, '') : '';

    return {
      id: `dir_${folderName}`,
      label: folderName,
      path: folder,
      children: []
    };
  });

export const findDeepFolder = (data, prefix, updatedRenderedFolders) =>
  data.map(folder => {
    if (folder.path === prefix) {
      return { ...folder, children: updatedRenderedFolders };
    }
    if (folder.children?.length > 0) {
      const response = findDeepFolder(folder.children, prefix, updatedRenderedFolders);
      return { ...folder, children: response };
    }
    return folder;
  });

export const getDeduplicatedRows = (existingRows, newRows) =>
  newRows.filter(
    newRow =>
      existingRows.findIndex(row => row.name === newRow.name && row.group === newRow.group) === -1
  );

export const _getFolderSelectionRowsFromRawData = (folders: FolderItem[]): FolderSelectionRow[] =>
  folders.map((folder: any) => ({
    ...folder,
    name: folder.label,
    group: folder.path,
    inclusionItem: new InclusionItem(JSON.stringify({ name: folder.label, group: folder.path }))
  }));

export const _getRootFolderRow = () => new InclusionItem(ROOT_ENTITY);

export function _getFoldersSelectedFromInclusionList(
  inclusionListState: InclusionListState
): Array<string> {
  return inclusionListState.entities.map((state: InclusionItemState) => {
    let entityObject;

    if (typeof state.key === 'string') {
      try {
        entityObject = JSON.parse(state.key);
      } catch (error) {
        entityObject = undefined; // or some other default value
      }
    }

    return entityObject;
  });
}

export function _getSelectedItemFromEntityKey(item: InclusionItemKey): {
  name: string;
  group: string;
} | null {
  if (typeof item === 'string') {
    try {
      return JSON.parse(item);
    } catch (error) {
      return null;
    }
  }
  return null;
}

export const SelectorItemFactory = (rawData: any): SelectorItem => {
  if (!rawData) {
    return;
  }

  let selectorItem: any = {
    id: rawData
  };

  if (typeof rawData === 'object') {
    const { id, name, ...data } = rawData;

    selectorItem = {
      id,
      name,
      ...data
    };

    if (data && Object.keys(data).length > 0) {
      selectorItem.data = data;
    }
  }

  return selectorItem;
};

export const SelectorItemsFactory = (rawData: any[]): SelectorItem[] => {
  if (!rawData) {
    return;
  }

  return rawData.map(item => SelectorItemFactory(item));
};

export function _getInclusionListStateFromFoldersSelector(selector): InclusionListState {
  if (!selector) {
    return DEFAULT_INCLUSION_LIST_STATE;
  }

  return {
    selected: selector.all_select,
    entities: selector.entities
  };
}

export function _getSelectorInclusionStateFromRawData(rawData: any): InclusionListState {
  const folders = getFolderData(rawData, '');
  return {
    selected: false,
    entities: folders.map(folder => ({
      key: JSON.stringify({ name: folder.label, group: folder.path }),
      selected: true
    }))
  };
}

export function _getFolderSelectorRawDataFromInclusionState(state: InclusionListState) {
  if (!state) {
    return;
  }

  return {
    all_select: state.selected,
    entities: state.entities.map(folder => _getSelectedItemFromEntityKey(folder.key).group)
  };
}

export const _getRootSelected = selectedItems => {
  let isRootSelected = false;
  if (selectedItems?.entities && selectedItems.entities.length > 0) {
    selectedItems.entities.map(selectedFolder => {
      const selected = _getSelectedItemFromEntityKey(selectedFolder.key);
      if (selected.group === '') {
        isRootSelected = true;
      }
      return selectedFolder;
    });
  }
  return isRootSelected;
};
