import { NotificationsListType } from './models';
import { NotificationAPI } from './notificationsAPI';
import { NotificationsList } from './NotificationsList';

export function StandardNotificationsList() {
  return (
    <NotificationsList
      dataSource={(payload, options) => NotificationAPI.getNotifications(payload, options)}
      entity={NotificationsListType.REGULAR}
    />
  );
}
