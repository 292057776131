import { useEffect, useState } from 'react';
import PipelinesAPI from '../../containers/pipeline/PipelinesAPI';
import {
  getPipelinesWithSuggestedSyncFrequency,
  getSourceTypeFilterOptionsFromPipelines
} from './utils';
import { buildOptionsFromDestinations } from '../../legacy-utils/integration-node';
import { Filter } from '../../../app/filter/models/filter';
import DestinationsAPI from '../../containers/destination/DestinationsAPI';
import {
  DESTINATION_FILTER_KEY,
  LIST_FILTERS_INDEX,
  PIPELINE_FILTER_KEY,
  STATUS_FILTER_KEY
} from './constant';
import { PIPELINE_STATUSES } from '../../../app/pipeline/constants';
import { matchPattern } from '../../legacy-utils/string';

export const usePipelinesForMigration = () => {
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listFetchError, setListFetchError] = useState(null);
  const [listFilters, setListFilters] = useState<Filter<any>[]>([]);
  const [filteredPipelines, setFilteredPipelines] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getPipelineAndDestination();
  }, []);

  useEffect(() => {
    filterPipelines();
  }, [pipelines, listFilters, search]);

  const initialiseFilters = () => {
    const _listFilters = listFilters;
    const sourceFilter = new Filter<Array<any>>(PIPELINE_FILTER_KEY, [], undefined, false);
    sourceFilter.matchCriteria = pipeline =>
      (sourceFilter.value as any).some(filter => filter.type === pipeline.source_type);

    _listFilters.push(sourceFilter);

    const destinationsFilter = new Filter<Array<any>>(DESTINATION_FILTER_KEY, [], undefined, false);
    destinationsFilter.matchCriteria = pipeline =>
      (destinationsFilter.value as any).some(filter => filter.value === pipeline.destination_id);

    _listFilters.push(destinationsFilter);

    const statusFilter = new Filter<Array<any>>(
      STATUS_FILTER_KEY,
      PIPELINE_STATUSES,
      undefined,
      false
    );
    statusFilter.matchCriteria = (pipeline: any) =>
      (statusFilter.value as any).some(status => {
        if (Array.isArray(status.value)) {
          return status.value.includes(pipeline.pipeline_status.status);
        }
        return status.value === pipeline.pipeline_status.status;
      });
    _listFilters.push(statusFilter);

    setListFilters([..._listFilters]);
  };

  const getPipelineAndDestination = () => {
    setLoading(true);
    setListFetchError(false);

    const requests = [
      PipelinesAPI.getIntegrationsV2(),
      DestinationsAPI.getDestinations()
    ];

    Promise.all(requests)
      .then(async (res: any) => {
        initialiseFilters();
        const _listFilters = listFilters;
        const pipelinesList = getPipelinesWithSuggestedSyncFrequency(res[0].data);
        _listFilters[LIST_FILTERS_INDEX[DESTINATION_FILTER_KEY]].invalidate(
          buildOptionsFromDestinations(res[1].data),
          false
        );
        _listFilters[LIST_FILTERS_INDEX[PIPELINE_FILTER_KEY]].invalidate(
          getSourceTypeFilterOptionsFromPipelines(res[0].data),
          false
        );

        setPipelines([...pipelinesList]);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setListFetchError(error);
      });
  };

  const updateSyncFrequency = (pipelineSeqIds, syncFrequency) => {
    const seqIdsSet = new Set(pipelineSeqIds);
    setPipelines(prev =>
      prev.map(pipeline => {
        if (seqIdsSet.has(pipeline.seq_id)) {
          return {
            ...pipeline,
            sync_execution_policy: syncFrequency,
            updated_manually: true
          };
        }
        return pipeline;
      })
    );
  };

  const updateFilter = (index, option, isSearch = false) => {
    if (isSearch) {
      setSearch(option);
    } else {
      const _listFilters = listFilters;
      _listFilters[index].activate(option.length ? option : null);
      setListFilters([...listFilters]);
    }
  };

  const clearFilters = () => {
    setSearch('');
    const _listFilters = listFilters.map(listFilter => {
      listFilter.activate(null);
      return listFilter;
    });
    setListFilters([..._listFilters]);
  };

  const filterPipelines = () => {
    const _filteredPipelines = pipelines.filter(
      pipeline =>
        matchPattern(
          [
            pipeline.source_name,
            pipeline.dest_name,
            pipeline.source_type,
            pipeline.dest_type,
            `#${pipeline.seq_id}`
          ],
          search
        ) && Filter.compareWithFilters(pipeline, listFilters)
    );
    setFilteredPipelines([..._filteredPipelines]);
  };

  return {
    loading,
    listFetchError,
    filteredPipelines,
    pipelines,
    updateSyncFrequency,
    listFilters,
    updateFilter,
    clearFilters
  };
};
