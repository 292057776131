import * as Yup from 'yup';
import { DestinationConfigMetaData } from '../interface';
import BlackHole from './index';

export const BLACK_HOLE_CONFIG: DestinationConfigMetaData = {
  formComponent: BlackHole,
  initialValues: {
    queryLatency: ''
  },
  validationSchema: Yup.object({
    queryLatency: Yup.string().trim().required()
  })
};
