// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.toasterContainer_eNcq6 {\n  position: fixed;\n  z-index: 999999;\n  /*overrides*/\n  pointer-events: auto;\n  top: 12px;\n  right: 12px;\n}\n\n.toast_SKFqY {\n  position: relative;\n  background-color: var(--primary-color);\n  padding: 12px;\n  width: 300px;\n  border-radius: 6px;\n  background-position: 15px center;\n  background-repeat: no-repeat;\n  color: var(--light-text);\n  display: flex;\n  align-items: flex-start;\n}\n\n.toast-success_lmBRw {\n  background-color: var(--success-color);\n}\n\n.toast-error_elhNR {\n  background-color: var(--error-color);\n}\n\n.toast-info_qTRXN {\n  background-color: var(--info-color);\n}\n\n.toast-wait__6Qcs {\n  background-color: var(--primary-color);\n}\n\n.toast-warning_NN_pe {\n  background-color: var(--deferred-color);\n}\n\n.toastContent_KL187 {\n  flex: 1;\n  padding: 0 12px;\n  display: inline-block;\n  max-height: 500px;\n  overflow-y: auto;\n  align-self: center;\n}\n\n.toasterIcon_U7l5B {\n  flex-shrink: 0;\n  font-size: 20px;\n  margin-top: -1px;\n  margin-bottom: -1px;\n  background-color: rgba(255, 255, 255, 0.1);\n  border-radius: 6px;\n  width: 40px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.toastTitle_o5nFF {\n  font-weight: 700;\n}\n\n.toastMessage_dMH5w {\n  -ms-word-wrap: break-word;\n  word-wrap: break-word;\n  font-weight: 700;\n  line-height: 18px;\n}\n.toastMessage_dMH5w a, .toastMessage_dMH5w label {\n  color: var(--light-text);\n}\n.toastMessage_dMH5w a:hover {\n  color: #cccccc;\n  text-decoration: none;\n}\n\n.toastCloseButton_TKmRm {\n  flex-shrink: 0;\n  font-size: 16px;\n  cursor: pointer;\n  margin-top: 1px;\n  margin-bottom: 1px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toasterContainer": "toasterContainer_eNcq6",
	"toast": "toast_SKFqY",
	"toast-success": "toast-success_lmBRw",
	"toast-error": "toast-error_elhNR",
	"toast-info": "toast-info_qTRXN",
	"toast-wait": "toast-wait__6Qcs",
	"toast-warning": "toast-warning_NN_pe",
	"toastContent": "toastContent_KL187",
	"toasterIcon": "toasterIcon_U7l5B",
	"toastTitle": "toastTitle_o5nFF",
	"toastMessage": "toastMessage_dMH5w",
	"toastCloseButton": "toastCloseButton_TKmRm"
};
export default ___CSS_LOADER_EXPORT___;
