// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.versionTable__iPjW {\n  margin: auto;\n  margin-top: 20px;\n  background: var(--surface-bg-color);\n  border-collapse: collapse;\n}\n.versionTable__iPjW thead {\n  background: var(--ripple-bg-color);\n}\n.versionTable__iPjW td, .versionTable__iPjW th {\n  padding: 16px 32px;\n  border: solid 1px var(--divider-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionTable": "versionTable__iPjW"
};
export default ___CSS_LOADER_EXPORT___;
