import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FireboltTableTypeEnum } from '../../schema-builder/interface';

import { EventTypeActionEnum, SchemaConstraint } from '../../schema-mapper/models';
import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../constants';
import { AutoMappingStatus } from '../models/auto-mapping-status';
import { NetworkRequestOptions, RequestPaginationData } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class MapperService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) { }

  mapperUrl = this._appConfig.getMapperURL();

  getSourceSchemaDetails(integrationId: any, paginationData: RequestPaginationData): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/source-schemas-details`;

    if (paginationData.with_warnings) {
      paginationData['status_group'] = 'WITH_WARNINGS';
      delete paginationData.with_warnings;
    }

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams(paginationData)
      },
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getMappingSummary(integrationId: number, evenTypeName: string): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/mapping-summary/${encodeURIComponent(evenTypeName)}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  removeMapping(integrationId: number, mappingId: number): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schema_mapping/${mappingId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.delete(requestUrl, options);
  }

  getDataTypes(destType: string, showLoading = true): Observable<any> {
    const requestUrl = `${this.mapperUrl}/data-types`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          destination_type: destType
        })
      },
      uiOptions: {
        showLoading: showLoading
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getFileStoragePartitionFormats(): Observable<any> {
    const requestUrl = `${this.mapperUrl}/destination-partition-formats`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getCreateTableQuery(
    integrationId: number,
    destinationId: any,
    schemaName: string,
    fields: any[],
    partitionStyle?: any,
    partitionType?: any,
    tableType?: FireboltTableTypeEnum
  ): Observable<any> {
    const params = this.buildCreateTableParams(
      integrationId,
      schemaName,
      fields,
      partitionStyle,
      partitionType,
      tableType
    );

    const requestUrl = `${ this.mapperUrl }/${ destinationId }/get-create-schema-query`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false
      }
    };

    return this._rxRequestService.post(requestUrl, options, params);
  }

  createTable(
    integrationId: number,
    destinationId: any,
    schemaName: string,
    fields: any[],
    partitionStyle?: any,
    partitionType?: any,
    showErrorMessage = true,
    tableType?: FireboltTableTypeEnum
  ): Observable<any> {
    const params = this.buildCreateTableParams(integrationId,
      schemaName,
      fields,
      partitionStyle,
      partitionType,
      tableType);

    const requestUrl = `${ this.mapperUrl }/${ destinationId }/create-schema`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: showErrorMessage
      }
    };

    return this._rxRequestService.post(requestUrl, options, params);
  }

  getMappingQuery(integrationId: number, mappingId: number, params: any): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schema_mapping/${mappingId}/get-update-schema-query`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.post(requestUrl, options, params);
  }

  updateMapping(integrationId: any, mappingId: any, params: any, validateMappingEnabled = true, forceUpdateMapping = false): Observable<any> {
    const requestUrl = `${ this.mapperUrl }/${ integrationId }/schema_mapping/${ mappingId }`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.put(requestUrl, options, {
      ...params,
      'validate_mapping_enabled': validateMappingEnabled,
      force_update_mapping: forceUpdateMapping
    });
  }

  getEventTypes(integrationId: any, paginationData: RequestPaginationData): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/source-schemas-details`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams(paginationData)
      },
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getEventType(integrationId: any, eventTypeName: string): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/${encodeURIComponent(eventTypeName)}/source-schemas-details`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  resetEventType(integrationId: any, eventTypeName: string): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schema/${encodeURIComponent(eventTypeName)}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.delete(requestUrl, options);
  }

  skipEventType(integrationId: any, eventTypeName: string): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schema/${encodeURIComponent(eventTypeName)}`;

    const params = {
      skip: true
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.put(requestUrl, options, params);
  }

  unskipEventType(integrationId: any, eventTypeName: string): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schema/${encodeURIComponent(eventTypeName)}`;

    const params = {
      skip: false
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.put(requestUrl, options, params);
  }

  resizeStringFields(integrationId: any, eventTypeName: string): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/fix-varchar-columns/${encodeURIComponent(eventTypeName)}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.post(requestUrl, options);
  }

  updateEventTypeAutoMappingStatus(
    integrationId: any,
    eventTypeName: string,
    status: AutoMappingStatus,
    disableAutoMappingComment?: string
  ) {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schema/${encodeURIComponent(eventTypeName)}/auto-mapping-status`;

    const params: any = {
      status: status,
      disable_auto_mapping_comment: disableAutoMappingComment
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.put(requestUrl, options, params);
  }

  getIncompleteCount(integrationId: number): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/incomplete-schemas/count`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getWarningCount(integrationId: number): Observable<any> {
    const requestUrl = `${this.mapperUrl}/${integrationId}/schemas-with-warnings/count`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  buildCreateTableParams(
    integrationId: number,
    schemaName: string,
    fields: any[],
    partitionStyle?: any,
    partitionType?: any,
    tableType?: FireboltTableTypeEnum
  ) {
    return {
      integration_id: integrationId,
      schema_name: schemaName,
      fields: fields,
      metadata: {
        partition: {
          'partition_style': partitionStyle.value,
          'partition_type': partitionType
        },
        table_type: tableType
      }
    };
  }

  addConstraints(integrationId: number, schemaName: string, constraints: SchemaConstraint[]) {
    const requestUrl = `${this.mapperUrl}/${integrationId}/${encodeURIComponent(schemaName)}/constraints`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      }
    };

    const payload = {
      constraints: constraints.map((constraint) => {
        return {
          constraint_type: constraint.constraintType,
          columns: constraint.columns
        };
      })
    };

    return this._rxRequestService.post(requestUrl, options, payload);
  }

  dropTable(destinationId, tableName) {
    const requestUrl = `${this.mapperUrl}/destination/${destinationId}/schema/${tableName}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false,
        showErrorMsg: false
      }
    };

    return this._rxRequestService.delete(requestUrl, options);
  }

  applyBulkAction(integrationId: number, action: EventTypeActionEnum, filter: any, selector: any, force = false) {
    const requestUrl = `${ this.mapperUrl }/${ integrationId }/bulk-event-types-actions`;

    if (filter && filter.status_group) {
      filter['event_type_status_filter'] = filter.status_group.value;
      delete filter.status_group;
    }

    if (filter && filter.search_event) {
      filter.name = {};
      filter['name'] = filter.search_event;
      delete filter.search_event;
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    const params = {
      selector,
      filter,
      action_type: action,
      force
    };

    return this._rxRequestService.post(requestUrl, options, params);
  }

  validateFieldMappingCompatibility(
    integrationId: number,
    mappingId: number,
    destinationId: number,
    destinationSchemaName: string,
    fieldMapping: any
  ) {
    const requestUrl = `${ this.mapperUrl }/${ integrationId }/schema_mapping/${ mappingId }/validate-field-mapping-request`;

    const params = {
      destination_schema_name: destinationSchemaName,
      destination_id: destinationId,
      mappings: [ fieldMapping ]
    };

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  updateObjectTypeAutoMappingStatus(
    integrationId: number,
    sourceObjectId: number
  ) {
    const requestUrl = `${this.mapperUrl}/${integrationId}/source-object/${sourceObjectId}/enable-auto-mapping`;

    const params: any = {
      status
    };

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }
}
