import { _docLinkHandlerService } from '../../app/core/service/doc-link-handler.service';

// utilizing angular tracking service to track the clicks for documentation oriented links
export const trackDocLink = (docLink, section, properties = {}) => {
  _docLinkHandlerService?.trackClick(docLink, section, properties);
};

// utilizing angular tracking service to get the complete path of the documentation links
export const getDocLink = (path, section, properties = {}) =>
  _docLinkHandlerService?.getLinkURL(path, section, properties);
