import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import styles from '../styles.module.scss';
import { HdButton, HdPane } from '../../../components/UIElements';
import Scheduler from '../index';
import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import useScheduler from '../useScheduler';
import { getPolicyMessage, getPolicyRawData } from '../utils';
import {
  FREQUENCY_TYPE_MINUTES,
  WORKFLOW_POLICY_MESSAGE_PREFIX
} from '../../../../app/dialog/execution-policy-dialog/constants';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import WorkflowAPI from '../../workflow/WorkflowAPI';
import { SchedulerType } from '../models';
import { PolicyTypeEnum } from '../../../../app/dialog/execution-policy-dialog/models';
import { SchedulerHeader } from '../components/SchedulerHeader';
import { Workflow } from '../../workflow/models/workflow';

export default function WorkflowExecutionPolicyDialog({
  onClose,
  entityDetails
}: {
  onClose: Function;
  entityDetails: Workflow;
}) {
  const formikRef = useRef<FormikProps<{}>>();
  const [savingSchedule, setSavingSchedule] = useState(false);

  const { selectedOption, policyObj, setPolicyObj, isCustom, handleOptionSelection } = useScheduler(
    {
      policy: entityDetails.executionPolicy,
      entity: HevoEntity.WORKFLOW.value,
      schedulerType: SchedulerType.CronType,
      formikRef
    }
  );

  useEffect(() => {
    if (policyObj.type === PolicyTypeEnum.NO_SCHEDULE) {
      setPolicyObj(prev => ({
        ...prev,
        type: PolicyTypeEnum.FREQUENCY
      }));
    }
  }, []);

  const handleSubmit = () => {
    setSavingSchedule(true);
    WorkflowAPI.schedule(entityDetails.id, getPolicyRawData(policyObj))
      .then(() => {
        setSavingSchedule(false);
        onClose(true);
      })
      .catch(() => {
        setSavingSchedule(false);
      });
  };

  const dataIdGenerator = getDataIdGenerator('WorkflowExecutionPolicyDialog');

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title='Change Workflow Schedule'
        subTitle='Schedule the Workflow to run at a fixed frequency. You can also run the
          Workflow daily or on certain days of the week.'
        dataIdGenerator={dataIdGenerator}
        onClose={onClose}
      />

      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div>
          <div className='text-body-2 mb-6'>Runs Every</div>

          <SchedulerOptions
            entity={HevoEntity.WORKFLOW.value}
            options={policyObj.suggestedFrequencies}
            onScheduleOptionSelected={handleOptionSelection}
            selectedOption={selectedOption}
          />

          <Scheduler
            schedulerType={SchedulerType.CronType}
            entity={HevoEntity.WORKFLOW.value}
            policyObj={policyObj}
            setPolicyObj={setPolicyObj}
            ref={formikRef}
            handleSubmit={handleSubmit}
            isCustom={isCustom}
          />

          {policyObj.frequencyType.value === FREQUENCY_TYPE_MINUTES.value ? (
            <HdPane
              icon='warning'
              variant='warning-faded'
              dataId={dataIdGenerator('')}
              className='my-5 text-body-2'
              disableHide
            >
              We dont support {policyObj.frequencyValue} minutes as frequency anymore. While your
              existing workflow will continue to work at the frequency of {policyObj.frequencyValue}{' '}
              minutes, you need to select atleast 1 hour as the new frequency to update this
              schedule.
            </HdPane>
          ) : null}

          {getPolicyMessage(policyObj) ? (
            <ExecutionPolicyMessage
              className='mb-5 mt-7'
              policyMessage={getPolicyMessage(policyObj)}
              messagePrefix={WORKFLOW_POLICY_MESSAGE_PREFIX}
            />
          ) : null}
        </div>
      </div>

      <div className='dialog-footer'>
        <HdButton
          dataId={dataIdGenerator('cancel')}
          variation='outline'
          disabled={savingSchedule}
          onClick={() => onClose()}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('submit')}
          disabled={savingSchedule}
          showProgress={savingSchedule}
          onClick={() => formikRef.current.submitForm()}
        >
          Schedule
        </HdButton>
      </div>
    </div>
  );
}
