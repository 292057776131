// Vendor Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuModule } from '../menu/menu.module';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';

import { TimeFilterComponent } from './time-filter/time-filter.component';
import { ListFilterComponent } from './list-filter/list-filter.component';

const DIRECTIVES = [
  ListFilterComponent,
  TimeFilterComponent
];

@NgModule({
  imports: [
    CommonModule,
    MenuModule,
    IconModule,
    TooltipModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class FilterModule { }
