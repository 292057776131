<div *ngFor="let column of columns; index as index" class="primary-key-wrapper">
  {{ column }}
  <button
    *ngIf="editable"
    hd-icon-btn
    size="2"
    (click)="removeKey(index)">
    <hd-icon name="close"></hd-icon>
  </button>
</div>

<ng-container *ngIf="editable">
  <flexible-auto-complete
    *ngIf="showSuggestions && selectionMode"
    class="hd-form-control select-key-control mb-3"
    [ngModel]="dropdownSelectedKey"
    (ngModelChange)="onSelect($event)"
    [placeholder]="placeholder"
    [options]="availableSuggestions"
    [accessor]="'name'"
    [loading]="loadingSuggestions"
    [useAsText]="canUseCustom"
    (onOpen)="onDropdownOpen()"
    name="select-key">
    <ng-template
      faItemTemplate
      let-option>
      <div class="center-flex-row" *ngIf="option.name">
        {{ option.name }}

        <div class="text-secondary">
          <span class="mx-2">&bull;</span>
          {{ option.type_display + (option.type_display === 'array' && option.value_type_display ? 'option.value_type_display' : '') }}
        </div>

        <hd-key-badge
          class="ml-3"
          *ngIf="option.keys && option.keys.length"
          [keys]="option.keys">
        </hd-key-badge>
      </div>

      <div *ngIf="!option.name">
        {{ option }}
      </div>
    </ng-template>
    <span *ngIf="canUseCustom" faCustomLoadingText>Loading suggestions.</span>
  </flexible-auto-complete>

  <form
    (ngSubmit)="onEnter(dropdownSelectedKey)"
    novalidate
    autocomplete="off"
    *ngIf="!showSuggestions && selectionMode">
    <div class="hd-form-control-group mb-3">
      <input
        name="primary-key"
        type="text"
        class="hd-form-control"
        [(ngModel)]="dropdownSelectedKey"
        [placeholder]="placeholder">
      <button class="d-none" type="submit" [disabled]="!dropdownSelectedKey" hd-icon-btn>
        <hd-icon name="plus"></hd-icon>
      </button>
    </div>
  </form>

  <button
    type="button"
    (click)="selectNewKey()"
    [disabled]="selectionMode && !dropdownSelectedKey"
    class="add-more-btn">
    Add More
    <hd-icon name="plus" class="thumb"></hd-icon>
  </button>
</ng-container>

