import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from '../HdDropDown/styles.module.scss';
import HdDropDown, { HdDropDownProps } from '../HdDropDown';
import HdIcon from '../HdIcon';
import HdLink from '../HdLink';
import { HdTextField } from '../HdTextField';
import { HdIconButton } from '../HdIconButton';
import { HdTooltip } from '../HdTooltip';

function CreatableAdornment({ creatableLabel, onClickCallback, dataIdGenerator }) {
  return (
    <div
      role='button'
      tabIndex={0}
      className={styles.adornmentWrapper}
      onMouseDown={onClickCallback}
      data-id={dataIdGenerator('new-button')}
    >
      <HdLink
        tag='button'
        className={`dropdown-fa-bottom-link ${styles.link}`}
        dataId={dataIdGenerator('new')}
      >
        <HdIcon name='plus' className='mr-2' />
        New {creatableLabel}
      </HdLink>
    </div>
  );
}

export default function HdCreatableDropDown({
  dataId,
  id,
  label,
  group,
  error,
  multiple,
  renderSelectAll,
  disabled,
  options,
  showLoading,
  asyncMethod,
  onChangeEventHandler,
  CustomOption,
  TopAdornment,
  BottomAdornment,
  createOnClickAway = false,
  prefixIcon,
  prefixText,
  topAdornmentProps = {},
  bottomAdornmentProps = {},
  suffixText,
  valueAccessor,
  HelperDocumentAdornment,
  displayAccessor,
  hideClearable,
  onFocus,
  helperText = ' ',
  required = false,
  onBlur,
  selected,
  noOptionsText,
  creatable,
  creatableLabel,
  creatableFieldShowError,
  creatableInputValue,
  creatableBlurHandler,
  onCreatableInputChangeHandler,
  onCreatableInputSelection,
  creatableHelperText = '',
  creatableCallback
}: HdDropDownProps) {
  const [creating, setCreating] = useState(false);
  const [creatableInputField, setCreatableInputField] = useState(false);

  const inputRef = useRef(null);

  // retain the auto focus of the text field with settimeout to prevent any default blur events
  useEffect(() => {
    if (creatableInputField) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  }, [creatableInputField]);

  const onKeyDownHandler = event => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setCreatableInputField(false);
    }
  };

  const onKeyPressHandler = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCreatableCallback();
    }
  };

  const onCreatableCallback = async () => {
    if (!creatableInputValue || creatableFieldShowError) {
      return false;
    }
    try {
      setCreating(true);
      await creatableCallback(creatableInputValue);
      setCreating(false);
      onCreatableInputChangeHandler(null);
      setCreatableInputField(false);
    } catch (err) {
      setCreating(false);
    }
    return true;
  };

  // Sanity
  useEffect(() => {
    onCreatableInputSelection(creatableInputField);
    if (!creatableInputField && creatableInputValue) {
      onCreatableInputChangeHandler(null);
    }
  }, [creatableInputField]);

  const handleClickAway = () => {
    if (createOnClickAway) {
      onCreatableCallback();
    }
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'creatable-dropdown'), [dataId]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {creatableInputField ? (
        <ClickAwayListener onClickAway={() => handleClickAway()}>
          <div>
            <HdTextField
              autoFocus
              inputRef={inputRef}
              required={required}
              label={label}
              placeholder={`Enter the ${creatableLabel.toLowerCase()} name`}
              helperText={creatableHelperText}
              suffixText={suffixText}
              prefixIcon={prefixIcon}
              prefixText={prefixText}
              forceActiveLegend
              error={!!creatableFieldShowError}
              value={creatableInputValue}
              onBlur={() => creatableBlurHandler()}
              onChange={event => onCreatableInputChangeHandler(event.target.value)}
              onKeyDown={onKeyDownHandler}
              onKeyPress={onKeyPressHandler}
              dataId={dataIdGenerator('')}
              InputProps={{
                endAdornment: creating ? (
                  <div className={styles.loader}>
                    {`Creating ${creatableLabel}`}
                    <HdIcon name='testing' className={`ml-2 icon ${styles.icon}`} />
                  </div>
                ) : (
                  <>
                    <HdTooltip title={`Create ${creatableLabel}`}>
                      <HdIconButton
                        onClick={() => onCreatableCallback()}
                        dataId={dataIdGenerator('confirm')}
                      >
                        <HdIcon name='checked-tick' className={styles.save} />
                      </HdIconButton>
                    </HdTooltip>
                    <HdIconButton
                      onKeyPress={event => event.stopPropagation()}
                      onClick={() => setCreatableInputField(false)}
                      dataId={dataIdGenerator('cancel')}
                    >
                      <HdIcon name='close' />
                    </HdIconButton>
                  </>
                )
              }}
            />
          </div>
        </ClickAwayListener>
      ) : (
        <HdDropDown
          dataId={dataId}
          options={options}
          required={required}
          id={id}
          error={error}
          label={label}
          helperText={helperText}
          group={group}
          multiple={multiple}
          renderSelectAll={renderSelectAll}
          disabled={disabled}
          noOptionsText={noOptionsText}
          selected={selected}
          HelperDocumentAdornment={HelperDocumentAdornment}
          valueAccessor={valueAccessor}
          displayAccessor={displayAccessor}
          hideClearable={hideClearable}
          topAdornmentProps={topAdornmentProps}
          bottomAdornmentProps={bottomAdornmentProps}
          showLoading={showLoading}
          asyncMethod={asyncMethod}
          onFocus={onFocus}
          CustomOption={CustomOption}
          TopAdornment={TopAdornment}
          onBlur={onBlur}
          BottomAdornment={
            creatable
              ? () =>
                  CreatableAdornment({
                    creatableLabel,
                    onClickCallback: () => setCreatableInputField(true),
                    dataIdGenerator
                  })
              : BottomAdornment
          }
          suffixText={suffixText}
          prefixIcon={prefixIcon}
          prefixText={prefixText}
          onChangeEventHandler={onChangeEventHandler}
        />
      )}
    </>
  );
}
