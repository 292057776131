// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.content_y1GYC {\n  display: flex;\n  flex-direction: column;\n}\n.content_y1GYC .linkHolder_UZvNF {\n  color: var(--light-text);\n  word-break: break-word;\n}\n.content_y1GYC .scrollToTopButton_cIHWs {\n  display: flex;\n  align-items: center;\n  white-space: pre;\n}\n.content_y1GYC.warning_PZdKW .linkHolder_UZvNF {\n  color: var(--error-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content_y1GYC",
	"linkHolder": "linkHolder_UZvNF",
	"scrollToTopButton": "scrollToTopButton_cIHWs",
	"warning": "warning_PZdKW"
};
export default ___CSS_LOADER_EXPORT___;
