import { Component, Output, Input, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: [ 'tag.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent {
  @Input() canRemove = true;
  @Input() disabled = false;
  @Output() remove = new EventEmitter<any>();

  removeClick(e) {
    this.remove.emit(e);
  }
}
