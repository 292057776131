import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationControlComponent } from './pagination-control/pagination-control.component';
import { PipeModule } from '../pipe/pipe.module';
import { IconModule } from '../icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    IconModule
  ],
  declarations: [PaginationControlComponent],
  exports: [PaginationControlComponent]
})
export class PaginationModule { }
