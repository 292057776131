import React from 'react';
import { ProductTour } from './ProductTour';

export enum TourStepType {
  TOOLTIP = 'TOOLTIP',
  TOUR_CARD = 'TOUR_CARD'
}

export interface TourStep {
  id: string;
  type: TourStepType;
  component: React.FC<any>;
  componentProps?: any;
  tourCardProps?: any;
}

export interface ITour {
  steps: TourStep[];
  tourId: ProductTour;
  tourShownIdentifier?: string;
  tourScheduleDelay?: number;
  rules?: TOUR_RULES[];
}

export enum TOUR_RULES {
  CLOSE_DRAWER_IF_OPEN,
  ONLY_AFTER_PIPELINE_CREATION
}

export enum TourPriority {
  LOW = 1,
  MEDIUM = 2,
  HIGHEST = 3
}

export const MAX_TOURS_ALLOWED_PER_SESSION = 2;
