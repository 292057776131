import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[hdDropdownTriggerButton]',
  templateUrl: './hd-dropdown-trigger-button.component.html',
  styleUrls: [ './hd-dropdown-trigger-button.component.scss' ]
})
export class HdDropdownTriggerButtonComponent {
  @Input() thumbnail: string;
  @HostBinding('class.clear-horizontal-gutter') @Input() clearHorizontalGutter = false;

  @HostBinding('class.dropdown-trigger') readonly hasDropdownTriggerClass = true;
}
