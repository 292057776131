import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TeamSettingsService } from '../service/team-settings.service';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseEnabledGuard implements CanActivate {
  constructor(
    private _teamSettingsService: TeamSettingsService,
  ) { }

  canActivate(): boolean {
    return this._teamSettingsService.isH2OEnabled();
  }
}
