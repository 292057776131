import { useHistory } from '@useHistory/useHistoryCompat';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { RecentEntitiesService } from '../../../app/core/service/recent-entities.service';
import useService from '../../hooks/useService';
import { TeamSettingsService } from '../../../app/core/service/team-settings.service';

export function useDrawerWorkflowGuard() {
  const history = useHistory();
  const recentEntitiesService = useService(RecentEntitiesService);
  const teamSettingsService = useService(TeamSettingsService);

  return () => {
    const isWorkFlowsEnabled = teamSettingsService.isWorkflowsEnabled();

    if (!isWorkFlowsEnabled) {
      history.push('/');
      return false;
    }

    const recent = recentEntitiesService.getRecent(HevoEntity.WORKFLOW.value);

    if (!recent) {
      history.push('/workflow');
      return false;
    }

    return true;
  };
}
