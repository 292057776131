import { EntityStatus } from '../../../../app/core/models/entity-status';
import { Enum } from '../../../../app/core/models/object-enum';

export class WorkflowHistoryStatusEnum extends Enum {
  static SUCCESS = new EntityStatus('Success', 'SUCCESS', 'success');
  static QUEUED = new EntityStatus('Queued', 'QUEUED', 'info');
  static RUNNING = new EntityStatus('Running', 'RUNNING', 'success');
  static CANCELLED = new EntityStatus('Cancelled', 'CANCELLED', 'warning');
  static FAILED = new EntityStatus('Failed', 'FAILED', 'error');
}

export class WorkflowRunHistoryItem {
  startedTs: number;
  runTime: number;
  failureMessage: string;
  status: EntityStatus;
}
