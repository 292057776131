import { getSortedListFromReferenceList } from '../../../../legacy-utils/array';
import { matchPattern } from '../../../../legacy-utils/string';
import { SOURCE_TYPES_DISPLAY_ORDER } from '../../../../../app/nodes/source-type/source-type-display-order';
import { GLOBAL_SEARCH_ENTITY_FILTER_FIELDS } from './constants';
import {
  GlobalSearchEntity,
  GlobalSearchFilters,
  GlobalSearchState,
  GlobalSearchStateEntity
} from './models';

function getFilteredEntities(entity, entityData, search, filterFields?: string[]) {
  return entityData.entities.filter(data => {
    const _filterFields = filterFields || GLOBAL_SEARCH_ENTITY_FILTER_FIELDS[entity];
    const filters = _filterFields.map(filter => data[filter] || '');

    return matchPattern(filters, search);
  });
}

export function filterEntityData(
  entity: GlobalSearchEntity,
  filters: GlobalSearchFilters,
  state: GlobalSearchState
): GlobalSearchStateEntity {
  if (entity === GlobalSearchEntity.FILTERS) {
    return;
  }

  const { search } = filters;
  const entityData = state[entity];

  if (!search) {
    if (
      entity === GlobalSearchEntity.SOURCE_TYPES ||
      entity === GlobalSearchEntity.DESTINATION_TYPES
    ) {
      // eslint-disable-next-line consistent-return
      return {
        ...entityData,
        filteredEntities: []
      };
    }

    if (entity === GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS) {
      // eslint-disable-next-line consistent-return
      return {
        loadingData: false,
        entities: [],
        filteredEntities: []
      };
    }

    // eslint-disable-next-line consistent-return
    return {
      ...entityData,
      filteredEntities: entityData.entities.slice(0, 2)
    };
  }

  let filteredEntities = [];

  switch (entity) {
    case GlobalSearchEntity.ACTIVATIONS:
    case GlobalSearchEntity.DESTINATIONS:
    case GlobalSearchEntity.MODELS:
    case GlobalSearchEntity.PIPELINES:
    case GlobalSearchEntity.TARGETS:
    case GlobalSearchEntity.WORKFLOWS:
      filteredEntities = getFilteredEntities(entity, entityData, search);
      break;

    case GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS:
      filteredEntities = [...entityData.entities];
      break;

    case GlobalSearchEntity.SOURCE_TYPES: {
      /**
       * Filters all the source types with 0 pipelines
       * */
      const filteredSourceTypes = getFilteredEntities(entity, entityData, search);

      if (filteredSourceTypes.length > 0) {
        const filteredPipelines = getFilteredEntities(
          GlobalSearchEntity.PIPELINES,
          state.pipelines,
          search
        );

        filteredSourceTypes.forEach(sourceType => {
          const sourcePipelineExists =
            filteredPipelines.findIndex(pipeline =>
              matchPattern([pipeline.source_type], sourceType.source_type)
            ) > -1;

          if (!sourcePipelineExists) {
            filteredEntities.push(sourceType);
          }
        });

        filteredEntities = getSortedListFromReferenceList(
          filteredEntities,
          SOURCE_TYPES_DISPLAY_ORDER,
          sourceType => sourceType.source_type
        ).slice(0, 3);
      }

      break;
    }

    case GlobalSearchEntity.DESTINATION_TYPES: {
      const filteredDestinations = getFilteredEntities(
        GlobalSearchEntity.DESTINATIONS,
        state.destinations,
        search
      );

      if (filteredDestinations.length === 0) {
        filteredEntities = getFilteredEntities(entity, entityData, search).slice(0, 3);
      }

      break;
    }

    default:
      break;
  }

  // eslint-disable-next-line consistent-return
  return {
    ...entityData,
    filteredEntities
  };
}

export function filterStateData(filters: GlobalSearchFilters, state: GlobalSearchState) {
  return {
    ...state,
    pipelines: filterEntityData(GlobalSearchEntity.PIPELINES, filters, state),
    destinations: filterEntityData(GlobalSearchEntity.DESTINATIONS, filters, state),
    workflows: filterEntityData(GlobalSearchEntity.WORKFLOWS, filters, state),
    models: filterEntityData(GlobalSearchEntity.MODELS, filters, state),
    targets: filterEntityData(GlobalSearchEntity.TARGETS, filters, state),
    activations: filterEntityData(GlobalSearchEntity.ACTIVATIONS, filters, state),
    sourceTypes: filterEntityData(GlobalSearchEntity.SOURCE_TYPES, filters, state),
    destinationTypes: filterEntityData(GlobalSearchEntity.DESTINATION_TYPES, filters, state),
    algoliaSearchResults: filterEntityData(
      GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS,
      filters,
      state
    )
  };
}
