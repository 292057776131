import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import React from 'react';
import useTeamSettingsService from '../../../hooks/services/useTeamSettingsService';
import HdFormikSwitchWrapper from '../HdFormikSwitchWrapper';
import RequestSSHAccess from '../../RequestSSHAccess';
import { HdLink } from '../../UIElements';

interface SSHConfigProps {
  entityTypeIdentifier: string;
  hideRequestSSHAccessComponent?: boolean;
  showNeedHelp?: boolean;
  onNeedHelpClick?: Function;
  children: React.ReactNode;
  className?: string;
}

export default function SSHConfig({
  entityTypeIdentifier,
  hideRequestSSHAccessComponent = false,
  showNeedHelp = false,
  onNeedHelpClick = () => {},
  children,
  className
}: SSHConfigProps) {
  const formikProps = useFormikContext<{
    useSSH: boolean;
  }>();

  const { getVPCPeeringConfig } = useTeamSettingsService();

  if (getVPCPeeringConfig().enabled) {
    return null;
  }

  return (
    <div className={clsx(className)}>
      {!formikProps.values.useSSH && !hideRequestSSHAccessComponent ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <RequestSSHAccess entityTypeIdentifier={entityTypeIdentifier} />
          </Grid>
        </Grid>
      ) : null}

      <Grid container>
        <Grid item xs={12} md={12}>
          <HdFormikSwitchWrapper fieldName='useSSH' label='Connect Through SSH'>
            <Typography component='label' htmlFor='useSSH' className='mt-1'>
              <span>
                Connect securely through an SSH tunnel if your database is not publicly
                accessible.&nbsp;
                {showNeedHelp && (
                  <HdLink tag='button' onClick={onNeedHelpClick} dataId='ssh'>
                    Need Help?
                  </HdLink>
                )}
              </span>
            </Typography>
          </HdFormikSwitchWrapper>
        </Grid>
      </Grid>

      {children}
    </div>
  );
}
