<ng-template>
  <div
    class="hd-menu {{ theme }} {{ classes }}"
    (keydown)="_handleKeydown($event)"
    [hdFocusable]="0"
    role="menu">

    <div class="menu-content" [style.max-height]="maxHeight" >
      <ng-content></ng-content>
    </div>

    <img
      *ngIf="displayType === 'tooltip' && theme !== 'dark'"
      src="https://res.cloudinary.com/hevo/image/upload/v1661186315/dashboard/arrow-tooltip_q3sxyt.svg"
      class="arrow-img">
    <img
      *ngIf="displayType === 'tooltip' && theme === 'dark'"
      src="https://res.cloudinary.com/hevo/image/upload/v1661186315/dashboard/arrow-tooltip-dark_vjb1ib.svg"
      class="arrow-img">
  </div>
</ng-template>
