import * as Yup from 'yup';
import { ListConductor } from '../../../app/pagination/list-conductor/list-conductor';
import { SELECTOR_SEARCH_KEY } from '../../../app/shared/single-level-selector/constants';
import DestinationsAPI from '../../containers/destination/DestinationsAPI';
import { singleLevelSelectorValidatorSchema } from '../../utils/validatorSchema';
import { DESTINATION_OPTIMISATION_DIALOG_TABLES_SEARCH_KEY } from './constants';

export const getRecommendedTables = (destinationId: number, listConductorArg: ListConductor) => {
  const queries = listConductorArg.getListMetaNetworkParams();

  if (queries[SELECTOR_SEARCH_KEY]) {
    queries[DESTINATION_OPTIMISATION_DIALOG_TABLES_SEARCH_KEY] = queries[SELECTOR_SEARCH_KEY];
    delete queries[SELECTOR_SEARCH_KEY];
  }

  const request = DestinationsAPI.getRecommendedTableList(destinationId, queries);

  return request.then((res: any) => {
    if (res.data.length === 0) {
      return {
        items: [],
        totalCount: 0
      };
    }

    return {
      items: res.data[0].table_names,
      totalCount: res.data[0].total_count
    };
  });
};

export const validationSchema = Yup.object({
  selectedTables: Yup.object().test(
    singleLevelSelectorValidatorSchema({ message: 'You need to select at least one table' })
  )
});

export const initialValues = {
  selectedTables: null
};
