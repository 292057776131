// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.star_jAwUo {\n  margin: 0;\n  cursor: pointer;\n  padding: 0;\n  background: none;\n  border-width: 0;\n  color: inherit;\n  font: inherit;\n  cursor: pointer;\n  margin-right: 4px;\n}\n.star_jAwUo:focus {\n  outline: none;\n  text-decoration: none;\n}\n.star_jAwUo[disabled] {\n  cursor: not-allowed;\n}\n\n.rangeLabel_uDGiH {\n  font-weight: 700;\n  text-transform: uppercase;\n}\n\n.unselected_jMqXj {\n  color: var(--ripple-bg-color);\n}\n\n.notLikely_ZnWHW {\n  color: var(--error-color);\n}\n\n.likely_o7bcL {\n  color: var(--success-color);\n}\n\n.average__NhxZ {\n  color: var(--deferred-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star": "star_jAwUo",
	"rangeLabel": "rangeLabel_uDGiH",
	"unselected": "unselected_jMqXj",
	"notLikely": "notLikely_ZnWHW",
	"likely": "likely_o7bcL",
	"average": "average__NhxZ"
};
export default ___CSS_LOADER_EXPORT___;
