import { Pipeline } from '../../../app/pipeline/models/pipeline';

export enum JobTypes {
  BATCH = 'BATCH',
  STREAMING = 'STREAMING'
}

enum JOB_STATUS {
  IN_PROGRESS = 'in progress',
  SUCCESS = 'success',
  FAILED = 'FAILED'
}

export enum StagesStatuses {
  IN_PROGRESS = 'in progress',
  SUCCESS = 'success',
  FAILED = 'failed',
  SKIPPED = 'skipped'
}

export interface JobData {
  jobType?: string;
  mode: string;
  jobId: string;
  pipeline?: PipelineInterface;
  startedAt: number;
  duration: number;
  events: {
    failed: number;
    ingested: number;
    loaded: number;
    billable: number;
  };
  status: {
    value: string;
    colorType: string;
    warningMessage?: string;
    errorMessage?: string;
  };
}

export interface PipelineInterface {
  source: {
    sourceType: string;
    seqId: number;
    logoURL: string;
    darkModeLogoURL: string;
    name: string;
  };
  destination: {
    destinationType: string;
    logoURL: string;
    darkModeLogoURL: string;
    name: string;
  };
}

export interface ObjectStage {
  stage: string;
  startedAt: number;
  duration: number;
  inputEvents: number;
  failedEvents: number;
  outputEvents: number;
  status: {
    value: string;
    colorType: 'info' | 'success' | 'error';
  };
  error?: {
    message: string;
  };
}

export interface ObjectStageCardProps {
  stageDetails: ObjectStage;
  borderBottom: boolean;
  objectName: string;
}

export interface JobStatsProps {
  jobStats: {
    jobId: string | number;
    mode: string;
    status: {
      icon: string;
      value: string;
      colorType: string;
    };
    stats: StatsType;
  };
  pipeline: Pipeline;
}

export interface JobStatsContentProps {
  stats: StatsType;
}

export interface StatsType {
  startedAt: number;
  duration: number;
  objectsSuccess: number;
  objectsQueued: number;
  objectsFailed: number;
  eventsIngested: number;
  eventsLoaded: number;
  eventsFailed: number;
  billableEvents: number;
  nonBillableEvents: number;
}

export interface JobStatCardProps {
  dataId: string;
  children: any;
  statIcon: string;
  statName: string;
  className?: string;
  statTitleClassName?: string;
}

export interface PipelineJobsViewProps {
  integrationId: number;
}

export interface ObjectListItemProps {
  className?: string;
  jobId: string;
  object: {
    objectName: string;
    objectId: number;
    eventsLoaded: number;
    eventsFailed: number;
    status: {
      value: string;
      colorType: string;
    };
    objectDetails: Array<ObjectStage>;
  };
  pipeline: Pipeline;
}
