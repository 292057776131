import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';

export function popupAnim() {
  return trigger('popupAnim', [
    state('*', style({ transform: 'scale(0)', opacity: 0 })),
    state('in', style({ transform: 'scale(1)', opacity: 1 })),
    transition('* <=> in', animate('0.3s cubic-bezier(.25, .8, .25, 1)'))
  ]);
}

export function fadeAnim() {
  return trigger('fadeAnim', [
    state('*', style({ transform: 'scale(0)', opacity: 0 })),
    state('in', style({ transform: 'scale(1)', opacity: 1 })),
    transition('* => in', [
      style({ transform: 'scale(1)', opacity: 0 }),
      animate('.2s ease-in-out', style({ transform: 'scale(1)', opacity: 1 })),
      query('@popupAnim', animateChild())
    ]),
    transition('in => *', [
      query('@popupAnim', animateChild()),
      animate('.2s ease-in-out', style({ transform: 'scale(1)', opacity: 0 }))
    ])
  ]);
}
