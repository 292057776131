<ng-container *ngIf="visible">
  <div class="l-section">
    <ng-content></ng-content>
  </div>

  <div class="r-section">
    <ng-content select="[actions]"></ng-content>

    <button
      *ngIf="canHide"
      hd-icon-btn
      (click)="hide()"
      class="close"
      size="3">
      <hd-icon name="close"></hd-icon>
    </button>
  </div>
</ng-container>
