import { Injectable } from '@angular/core';


@Injectable()
export class SessionStorageService {
  get(key: string) {
    return sessionStorage.getItem(key);
  }

  set(key: string, value: any) {
    const formattedValue = typeof value === 'string' ? value : JSON.stringify(value);
    return sessionStorage.setItem(key, formattedValue);
  }

  remove(key: string) {
    return sessionStorage.removeItem(key);
  }
}
