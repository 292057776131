import { CronDay, CronType, FrequencyChip, FrequencyType } from './models';

export const FREQUENCY_TYPE_MINUTES: FrequencyType = {
  name: 'Minutes',
  value: 'MINUTES',
  minSeconds: 60
};

export const FREQUENCY_TYPE_HOURS: FrequencyType = {
  name: 'Hours',
  value: 'HOURS',
  minSeconds: 3600
};

export const CRON_TYPE_DAILY: CronType = {
  name: 'Daily',
  value: 'DAILY'
};

export const CRON_TYPE_WEEKLY: CronType = {
  name: 'Weekly',
  value: 'WEEKLY'
};

export const CRON_TYPE_FIXED_INTERVAL: CronType = {
  name: 'Fixed Interval of',
  value: 'FIXED_INTERVAL'
};

export const DEFAULT_FREQUENCY_VALUE = 15;
export const DEFAULT_MAX_FREQUENCY = Infinity;
export const DEFAULT_MIN_FREQUENCY = FREQUENCY_TYPE_MINUTES.minSeconds;
export const DEFAULT_FREQUENCY_TYPE: FrequencyType = FREQUENCY_TYPE_MINUTES;
export const DEFAULT_CRON_TYPE: CronType = CRON_TYPE_DAILY;

export const FREQUENCY_TYPES: FrequencyType[] = [
  FREQUENCY_TYPE_MINUTES,
  FREQUENCY_TYPE_HOURS
];

export const CRON_OPTIONS: CronType[] = [
  CRON_TYPE_FIXED_INTERVAL,
  CRON_TYPE_DAILY,
  CRON_TYPE_WEEKLY
];

export const STRICT_CRON_TYPES: CronType[] = [
  CRON_TYPE_DAILY,
  CRON_TYPE_WEEKLY
];

export const CRON_DAYS: CronDay[] = [
  {
    name: 'Sunday',
    value: 0
  },
  {
    name: 'Monday',
    value: 1
  },
  {
    name: 'Tuesday',
    value: 2
  },
  {
    name: 'Wednesday',
    value: 3
  },
  {
    name: 'Thursday',
    value: 4
  },
  {
    name: 'Friday',
    value: 5
  },
  {
    name: 'Saturday',
    value: 6
  }
];

export const DEFAULT_FREQUENCY_CHIPS: FrequencyChip[] = [
  {
    value: 5,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '5 Mins',
    disabled: false
  },
  {
    value: 15,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '15 Mins',
    disabled: false
  },
  {
    value: 30,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '30 Mins',
    disabled: false
  },
  {
    value: 1,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '1 Hr',
    disabled: false
  },
  {
    value: 12,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '12 Hrs',
    disabled: false
  },
  {
    value: 24,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '24 Hrs',
    disabled: false
  }
];

export const DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2: FrequencyChip[] = [
  {
    value: 1,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '1 Hr',
    disabled: false
  },
  {
    value: 2,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '2 Hrs',
    disabled: false
  },
  {
    value: 6,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '6 Hrs',
    disabled: false
  },
  {
    value: 12,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '12 Hrs',
    disabled: false
  },
  {
    value: 24,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '24 Hrs',
    disabled: false
  }
];


export const SYNC_FREQUENCY_CHIPS: FrequencyChip[] = [
  {
    value: 5,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '5 Mins',
    disabled: false
  },
  {
    value: 15,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '15 Mins',
    disabled: false
  },
  {
    value: 30,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '30 Mins',
    disabled: false
  },
  {
    value: 3,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '3 Hrs',
    disabled: false
  },
  {
    value: 6,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '6 Hrs',
    disabled: false
  },
  {
    value: 12,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '12 Hrs',
    disabled: false
  }
  
];


export const AUDIT_TABLE_FREQUENCY_CHIPS: FrequencyChip[] = [
  
  {
    value: 3,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '3 Hrs',
    disabled: false
  },
  {
    value: 6,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '6 Hrs',
    disabled: false
  },
  {
    value: 12,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '12 Hrs',
    disabled: false
  },
  {
    value: 24,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '24 Hrs',
    disabled: false
  }
  
];

export const PIPELINE_SCHEDULE_FREQUENCY_CHIPS: FrequencyChip[] = [
  {
    value: 30,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '30 Mins',
    disabled: false
  },
  {
    value: 1,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '1 Hr',
    disabled: false
  },
  {
    value: 6,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '6 Hrs',
    disabled: false
  },
  {
    value: 8,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '8 Hrs',
    disabled: false
  },
  {
    value: 12,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '12 Hrs',
    disabled: false
  },
  {
    value: 24,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '24 Hrs',
    disabled: false
  }
  
];


export const PIPELINE_SCHEDULE_FREQUENCY_CHIPS_FOR_LOG_BASED: FrequencyChip[] = [
  {
    value: 30,
    frequencyType: FREQUENCY_TYPE_MINUTES,
    displayText: '30 Mins',
    disabled: false
  },
  {
    value: 1,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '1 Hr',
    disabled: false
  },
  {
    value: 6,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '6 Hrs',
    disabled: false
  },
  {
    value: 8,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '8 Hrs',
    disabled: false
  },
  {
    value: 12,
    frequencyType: FREQUENCY_TYPE_HOURS,
    displayText: '12 Hrs',
    disabled: false
  }
  
];




export const PIPELINE_POLICY_MESSAGE_PREFIX = 'Hevo will ingest your data';
export const MODEL_POLICY_MESSAGE_PREFIX = 'Hevo will Run your Model';
export const WORKFLOW_POLICY_MESSAGE_PREFIX = 'Hevo will Run your Workflow';
export const DESTINATION_POLICY_MESSAGE_PREFIX = 'Hevo will refresh your Schema';
export const ACTIVATION_POLICY_MESSAGE_PREFIX = 'Hevo will Run your Activation';
export const SYNC_FREQUENCY_POLICY_MESSAGE_PREFIX = 'Hevo will sync your data';
export const AUDIT_TABLE_POLICY_MESSAGE_PREFIX = 'Hevo will upload the logs';

export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_SUCCESS = '[Pipeline Execution Policy Dialog] schedule success';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_FAILURE = '[Pipeline Execution Policy Dialog] schedule failure';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_CLICK = '[Pipeline Execution Policy Dialog] schedule click';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_ENABLED = '[Pipeline Execution Policy Dialog] full load object frequency enabled';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_DISABLED = '[Pipeline Execution Policy Dialog] full load object frequency disabled';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_SELECTION = '[Pipeline Execution Policy Dialog] full load object frequency selection';


export const TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_SUCCESS = '[Destination Execution Policy Dialog] schedule success';
export const TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_FAILURE = '[Destination Execution Policy Dialog] schedule failure';
export const TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_CLICK = '[Destination Execution Policy Dialog] schedule click';

export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_SUCCESS = '[Pipeline Execution Policy With Streaming Dialog] schedule success';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_FAILURE = '[Pipeline Execution Policy With Streaming Dialog] schedule failure';
export const TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_WITH_STREAMING_SCHEDULE_CLICK = '[Pipeline Execution Policy With Streaming Dialog] schedule click';