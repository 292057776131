import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import {
  getSelectorInclusionStateFromRawData,
  getSelectorRawDataFromInclusionState
} from '../../../../../../app/shared/single-level-selector/model';

export class TypeformConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      selectedWorkspaces: getSelectorInclusionStateFromRawData(rawData)
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      ...getSelectorRawDataFromInclusionState(data.selectedWorkspaces)
    };
  }
}
