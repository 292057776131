import React, { useCallback, useRef, useState } from 'react';
import SourceObjectsAPI from '../../../containers/pipeline/SourceObjectsAPI';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdButton, HdDocLink, HdIcon, HdIconButton, HdModal, HdPane } from '../../UIElements';
import { EntityUIState } from '../../../../app/core/models/entitiy-ui-state';
import useService from '../../../hooks/useService';
import { SourceObjectService } from '../../../../app/core/service/source-object.service';
import {
  LOG_EXPIRY_DETAILS,
  SOURCE_OBJECT_LIST_PAGE_LIMIT
} from '../../../../app/pipeline/task/log-expiry-handling/constants';
import SingleLevelSelector from '../../SingleLevelSelector';
import { ListConductor } from '../../../../app/pagination/list-conductor/list-conductor';
import { InclusionListState } from '../../../../app/core/models/inclusion-list-v2';
import HdSwitchWrapper from '../../UIElements/HdSwitchWrapper';
import FormError from '../../FormError';
import { getDataIdsFromContract } from '../../../utils/generateDataId';
import { TRACKER_LOG_EXPIRY_HANDLER_DIALOG_SUBMIT_CLICK } from '../../../../app/pipeline/tracking';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';

export interface LogExpiryHandlerDialogProps {
  open: boolean;
  onClose: () => {};
  taskName: string;
  failedTasks: string[];
  integrationId: number;
  sourceType: string;
}

export const LOG_EXPIRY_HANDLER_DIALOG_DATA_IDS = getDataIdsFromContract({
  base: 'log-expiry-handler-dialog',
  close: 'close',
  banner: 'banner',
  bannerDocLink: 'banner-doc-link',
  selector: '',
  cancel: 'cancel',
  submit: 'submit',
  afterSubmitBtn: 'after-submit',
  historicalLoad: 'historical-load',
  title: 'title',
  message: 'message',
  submitError: 'submitError'
});

export function LogExpiryHandlerDialog({ open, onClose, ...props }: LogExpiryHandlerDialogProps) {
  return (
    <HdModal
      open={open}
      onClose={onClose}
      styling={{
        marginTop: '64px',
        width: '732px',
        maxWidth: '100%'
      }}
    >
      <LogExpiryHandlerDialogContent onClose={onClose} {...props} />
    </HdModal>
  );
}

function ListItem({ item }) {
  return <span className='text-medium text-ellipsis'>{item.data.full_name}</span>;
}

function LogExpiryHandlerDialogContent({
  onClose,
  taskName,
  failedTasks,
  integrationId,
  sourceType
}: Omit<LogExpiryHandlerDialogProps, 'open'>) {
  const sourceObjectService = useService(SourceObjectService);
  const { eventTrack } = useAnalyticsTracker();
  const taskTypeDetails = LOG_EXPIRY_DETAILS[taskName];

  const sourceObjectNameList = useRef([]);
  const filteredObjectNameList = useRef([]);
  const [runHistoricalLoad, setRunHistoricalLoad] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [fixedJob, setFixedJob] = useState(false);
  const [objectListUIState, setObjectListUIState] = useState<EntityUIState>(EntityUIState.IDLE);
  const [inclusionListState, setInclusionListState] = useState<InclusionListState>({
    selected: true,
    entities: []
  });
  const [submitting, setSubmitting] = useState(false);
  const [search, setSearch] = useState('');

  const getSourceObjectNames = (requestParams: any, listConductorArg: ListConductor) => {
    const paginationData = listConductorArg.getListMetaNetworkParams();
    const searchString = paginationData?.search_item;

    if (sourceObjectNameList.current.length > 0) {
      filteredObjectNameList.current = [...sourceObjectNameList.current];

      if (searchString) {
        setSearch(searchString);
        filteredObjectNameList.current = sourceObjectNameList.current.filter(task =>
          task.full_name.includes(searchString)
        );
      }

      return Promise.resolve({
        items: getCurrentPageTasks(paginationData),
        totalCount: filteredObjectNameList.current.length,
        metaData: null
      });
    }

    setObjectListUIState(EntityUIState.LOADING);

    return SourceObjectsAPI
      .getAllObjectNames(requestParams.integrationId, requestParams.failedTasks)
      .then((res: any) => {
        sourceObjectNameList.current = res.data.namespaces;

        if (searchString) {
          setSearch(searchString);
          filteredObjectNameList.current = sourceObjectNameList.current.filter(task =>
            task.full_name.includes(searchString)
          );
        } else {
          filteredObjectNameList.current = res.data.namespaces;
        }

        setObjectListUIState(EntityUIState.IDLE);

        return {
          items: getCurrentPageTasks(paginationData),
          totalCount: res.data.namespaces.length,
          metaData: null
        };
      })
      .catch(e => {
        setObjectListUIState(EntityUIState.IDLE);
        throw e;
      });
  };

  const listUpdateCallback = useCallback((state: InclusionListState) => {
    setInclusionListState(state);
  }, []);

  const listInteractionCallback = useCallback(() => {}, []);

  const getCurrentPageTasks = (paginationData: any) => {
    const { page, limit } = paginationData;
    const startIndex = page * limit;

    return filteredObjectNameList.current.slice(startIndex, startIndex + limit).map(namespace => ({
      ...namespace,
      id: namespace.full_name
    }));
  };

  const getRawData = () => {
    if (!search) {
      return {
        re_ingest_all_objects: inclusionListState.selected,
        namespaces: sourceObjectNameList.current.filter(sourceObject => {
          return inclusionListState?.entities.some(entity => sourceObject.full_name === entity.key);
        })
      };
    }

    const sourceObjectNames = filteredObjectNameList.current.filter(sourceObject => {
      if (
        inclusionListState.selected &&
        (!inclusionListState.entities || !inclusionListState.entities.length)
      ) {
        return true;
      }

      if (inclusionListState.selected) {
        return inclusionListState?.entities.every(entity => sourceObject.full_name !== entity.key);
      }

      return inclusionListState?.entities.some(entity => sourceObject.full_name === entity.key);
    });

    return {
      re_ingest_all_objects: false,
      namespaces: sourceObjectNames
    };
  };

  const handleLogExpiry = () => {
    let params = {
      re_ingest_all_objects: false,
      namespaces: []
    }

    if (runHistoricalLoad) {
      params = getRawData();
    }

    setSubmitting(true);
    setErrorMessage(null);

    eventTrack({
      action: TRACKER_LOG_EXPIRY_HANDLER_DIALOG_SUBMIT_CLICK,
      properties: {
        sourceType,
        pipelineMode: taskName,
        historicalLoadOption: runHistoricalLoad
      }
    });

    SourceObjectsAPI
      .handleLogExpiry(integrationId, params)
      .then(
        () => {
          sourceObjectService.refreshOverviewList();
          setSubmitting(false);
          setFixedJob(true);
        },
        (err: any) => {
          setSubmitting(false);
          setErrorMessage(getErrorMessageFromObj(err));
        }
      );
  };

  const dataIds = LOG_EXPIRY_HANDLER_DIALOG_DATA_IDS;

  return (
    <>
      <div className='dialog-header'>
        <div className='dialog-title text-transform-none' data-id={dataIds.title}>
          {fixedJob ? taskTypeDetails.afterFixingJob.dialogTitle : taskTypeDetails.dialogTitle}
        </div>

        <HdIconButton onClick={onClose} dataId={dataIds.close}>
          <HdIcon name='close' size={3} />
        </HdIconButton>
      </div>

      {!fixedJob ? (
        <div className='dialog-body'>
          <span className='text-secondary' data-id={dataIds.message}>{taskTypeDetails.dialogMessage}</span>

          {taskTypeDetails.bannerText ? (
            <HdPane
              variant='warning-faded'
              className='mt-4'
              dataId={dataIds.banner}
              disableHide
              iconClasses='icon-with-text mr-2'
              icon='warning'
              iconSize={2}
            >
              <span>
                {taskTypeDetails.bannerText}
                {taskTypeDetails.bannerDocLink ? (
                  <HdDocLink
                    dataId={dataIds.bannerDocLink}
                    label='Read More'
                    docLink={taskTypeDetails.bannerDocLink}
                    section='log-expiry-handler-dialog-banner'
                    className='ml-1'
                  />
                ) : null}
              </span>
            </HdPane>
          ) : null}

          <div className='center-flex-row box box--no-shadow py-2 px-4 my-4'>
            <HdSwitchWrapper
              id='runHistoricalLoad'
              dataId={dataIds.historicalLoad}
              label='Run Historical Load'
              onChange={event => {
                if (!event.target.checked) {
                  setInclusionListState({ selected: true, entities: [] });
                }
                setRunHistoricalLoad(prev => !prev);
                sourceObjectNameList.current = [];
              }}
              switchProps={{
                checked: runHistoricalLoad
              }}
            />
          </div>

          {runHistoricalLoad ? (
            <SingleLevelSelector
              dataId={dataIds.selector}
              name='historical objects'
              showRefresh={false}
              showSearchBar
              selectableItemName='Objects'
              requestParams={{
                integrationId,
                failedTasks,
                pageSize: SOURCE_OBJECT_LIST_PAGE_LIMIT
              }}
              getList={getSourceObjectNames}
              listUpdateCallback={listUpdateCallback}
              listInteractionCallback={listInteractionCallback}
              selectedItems={inclusionListState}
              retryApiVariant='default'
              renderListItem={ListItem}
            />
          ) : null}

          {errorMessage ? <FormError dataId={dataIds.submitError} className='mt-5 mb-0'>{errorMessage}</FormError> : null}
        </div>
      ) : (
        <div className='dialog-body d-flex'>
          <div className='hevo-checked-tick mr-3' />
          <span className='text-body-1'>{taskTypeDetails.afterFixingJob.dialogMessage}</span>
        </div>
      )}

      {!fixedJob ? (
        <div className='dialog-footer'>
          <HdButton
            variation='outline'
            type='button'
            dataId={dataIds.cancel}
            onClick={onClose}
            disabled={objectListUIState === EntityUIState.LOADING}
          >
            Don't Do It
          </HdButton>

          <HdButton
            dataId={dataIds.submit}
            type='button'
            onClick={handleLogExpiry}
            disabled={objectListUIState === EntityUIState.LOADING || submitting}
            showProgress={submitting}
          >
            OK, Fix It
          </HdButton>
        </div>
      ) : (
        <div className='dialog-footer'>
          <HdButton dataId={dataIds.afterSubmitBtn} onClick={onClose}>
            OK, Got it!
          </HdButton>
        </div>
      )}
    </>
  );
}
