export function getShareDocLinkMessage(sourceType: string, docLink: string, document: Document) {
  return `Hey there,

 I am trying to configure a Pipeline in Hevo with ${ sourceType } as a Source, and need your help to
 grant the requisite permissions for a few objects.
 The related documentation for this is available at:
 ${docLink}

 Please click the link below to configure the Pipeline:
 ${document.location.href}`;
}
