import React, { ReactNode, useCallback } from 'react';
import { getDocLink, trackDocLink } from '../../../utils/docLinkHandler';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdLink from '../HdLink';
import clsx from 'clsx';

export interface HdDocLinkProps {
  dataId: string;
  label?: string | ReactNode;
  icon?: string;
  iconOnly?: boolean;
  size?: 'sm' | 'md';
  direction?: 'left' | 'right';
  docLink: string;
  section?: string;
  properties?: { [key: string]: any };
  className?: string;
  iconClassName?: string;
  onClick?: (e: any) => any;
  [key: string]: any;
}

export default function HdDocLink({
  dataId,
  label = 'Learn More',
  icon = 'new-window',
  iconOnly,
  direction = 'right',
  docLink,
  section,
  properties,
  iconClassName = '',
  onClick,
  ...props
}: HdDocLinkProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  const iconClasses = clsx([
    {
      'ml-1': direction === 'right',
      'mr-1': direction !== 'right'
    },
    iconClassName
  ]);

  return (
    <HdLink
      href={getDocLink(docLink, section, properties)}
      onClick={e => {
        trackDocLink(docLink, section, properties);
        if (typeof onClick === 'function') {
          onClick(e);
        }
      }}
      target='_blank'
      icon={icon}
      iconOnly={iconOnly}
      direction={direction}
      dataId={dataIdGenerator('doc')}
      iconClasses={iconClasses}
      {...props}
    >
      {iconOnly ? '' : label}
    </HdLink>
  );
}
