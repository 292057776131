import { useFormikContext } from 'formik';
import React from 'react';
import { HdFormikSwitch } from '../../../../../components/FormikElements';
import { HdDocLink, HdLink, HdPane } from '../../../../../components/UIElements';
import { useMemoizedDataIds } from '../../../../../utils/generateDataId';

export function StreamingWritesConfig({
  dataId
}: {
  dataId: string
}) {
  const { values } = useFormikContext<{
    streamingWrites: boolean;
  }>();

  const useStreamMode = values.streamingWrites;

  const dataIds = useMemoizedDataIds(STREAMING_WRITES_CONFIG_DATA_ID_CONTRACT, dataId);

  return (
    <>
      <HdFormikSwitch fieldName='streamingWrites' label='Enable Streaming Inserts'>
        Load data to your BigQuery Destination in near real-time by streaming the writes.{' '}
        <HdDocLink
          label='Know More'
          section='destination_settings'
          docLink='/destinations/data-warehouses/google-bigquery/data-loading-by-streaming/'
          dataId={dataIds.toggleSwitch}
        />
      </HdFormikSwitch>

      {useStreamMode ? (
        <HdPane className='mt-5' variant='warning-faded' icon='warning'>
          Enabling Streaming Inserts may lead to duplicate rows in your Destination. Hevo will
          now insert each update as a new row instead of updating the existing rows. Streaming
          inserts may also incur additional costs on your BigQuery account. For details, check{' '}
          <HdLink
            icon='new-window'
            href='https://cloud.google.com/bigquery/pricing'
            tag='a'
            target='_blank'
            dataId={dataIds.pricingDoc}
          >
            BigQuery Pricing
          </HdLink>
        </HdPane>
      ) : null}
    </>
  );
}

export const STREAMING_WRITES_CONFIG_DATA_ID_CONTRACT = {
  base: '',
  toggleSwitch: 'streaming-writes',
  pricingDoc: 'pricing-external'
};
