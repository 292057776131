import { Enum, EnumVal } from '../../../../../app/core/models/object-enum';
import { ActivationRunStageEnum } from './run-stages';


export class SyncHistoryEntityStatus extends EnumVal {
  constructor(
    public name: string,
    public value: string,
    public activationRunStages: ActivationRunStageEnum[],
    public isTerminalStatus: boolean,
    public isFailedStatus: boolean,
    public colorType?: string,
    public description?: string
  ) {
    super(name);
  }
}

export class SyncHistoryStatusEnum extends Enum {
  static COMPLETED = new SyncHistoryEntityStatus(
    'Completed',
    'COMPLETED',
    [ ActivationRunStageEnum.LOADING ],
    true,
    false,
    'success'
  );
  static QUEUED = new SyncHistoryEntityStatus(
    'Queued',
    'QUEUED',
    [ ActivationRunStageEnum.TRANSFORMATION ],
    false,
    false,
    'info-1'
  );
  static SKIPPED = new SyncHistoryEntityStatus(
    'Shelved',
    'SKIPPED',
    [ ActivationRunStageEnum.TRANSFORMATION ],
    true,
    false,
    'info-1'
  );
  static INGESTION_RUNNING = new SyncHistoryEntityStatus(
    'Ingestion Running',
    'INGESTION_RUNNING',
    [ ActivationRunStageEnum.INGESTION ],
    false,
    false,
    'info-1'
  );
  static TRANSFORMATION_RUNNING = new SyncHistoryEntityStatus(
    'Transformation Running',
    'TRANSFORMATION_RUNNING',
    [ ActivationRunStageEnum.TRANSFORMATION ],
    false,
    false,
    'info-1'
  );
  static TRANSFORMATION_COMPLETED = new SyncHistoryEntityStatus(
    'Transformation Completed',
    'TRANSFORMATION_COMPLETED',
    [ ActivationRunStageEnum.TRANSFORMATION ],
    false,
    false,
    'info-1'
  );
  static LOADING = new SyncHistoryEntityStatus(
    'Loading',
    'LOADING',
    [ ActivationRunStageEnum.LOADING ],
    false,
    false,
    'info-1'
  );
  static INGESTION_FAILED = new SyncHistoryEntityStatus(
    'Ingestion Failed',
    'INGESTION_FAILED',
    [ ActivationRunStageEnum.INGESTION ],
    true,
    true,
    'error'
  );
  static TRANSFORMATION_FAILED = new SyncHistoryEntityStatus(
    'Transformation Failed',
    'TRANSFORMATION_FAILED',
    [ ActivationRunStageEnum.TRANSFORMATION ],
    true,
    true,
    'error'
  );
  static TRIGGER_LOAD_FAILED = new SyncHistoryEntityStatus(
    'Trigger Load Failed',
    'TRIGGER_LOAD_FAILED',
    [ ActivationRunStageEnum.TRANSFORMATION ],
    true,
    true,
    'error'
  );
  static LOADING_FAILED = new SyncHistoryEntityStatus(
    'Loading Failed',
    'LOADING_FAILED',
    [ ActivationRunStageEnum.LOADING ],
    true,
    true,
    'error'
  );
  static TARGET_FAILED = new SyncHistoryEntityStatus(
    'Target Failed',
    'TARGET_FAILED',
    [ ActivationRunStageEnum.LOADING ],
    true,
    true,
    'error'
  );
  static CANCELLED = new SyncHistoryEntityStatus(
    'Cancelled',
    'CANCELLED',
    [ ActivationRunStageEnum.TRANSFORMATION, ActivationRunStageEnum.LOADING ],
    true,
    false,
    'warning'
  );
  static DEFERRED = new SyncHistoryEntityStatus(
    'Deferred',
    'DEFERRED',
    [ ActivationRunStageEnum.LOADING ],
    false,
    false,
    'deferred'
  );
}
