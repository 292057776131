// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.buttonWrapper_MsF8p {\n  width: fit-content;\n  display: inline-block;\n  margin: 10.4px 5.12px;\n  margin-bottom: 0;\n  padding-bottom: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "buttonWrapper_MsF8p"
};
export default ___CSS_LOADER_EXPORT___;
