import { EntityStatus } from '../../../../app/core/models/entity-status';
import { Enum } from '../../../../app/core/models/object-enum';

export class WorkflowStatusEnum extends Enum {
  static DRAFT = new EntityStatus('Draft', 'DRAFT', 'default');
  static QUEUED = new EntityStatus('Queued', 'QUEUED', 'info');
  static SCHEDULED = new EntityStatus('Scheduled', 'SCHEDULED', 'success');
  static COMPLETED = new EntityStatus('Not Scheduled', 'COMPLETED', 'success');
  static RUNNING = new EntityStatus('Running', 'RUNNING', 'success');
  static CANCELLED = new EntityStatus('Cancelled', 'CANCELLED', 'warning');
  static PAUSED = new EntityStatus('Paused', 'PAUSED', 'warning');
  static FAILED = new EntityStatus('Failed', 'FAILED', 'error');
}
