import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivationStatusService } from '../service/activation-status.service';

@Injectable({
  providedIn: 'root'
})
export class ActivationGuard implements CanActivate {

  protected constructor(
    private _activationStatusService: ActivationStatusService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> | boolean {
    if (this._activationStatusService.shouldShowActivation()){
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
