import React, { useEffect, useRef, useState } from 'react';
import { DestinationMode } from '../../../../../app/core/models/destination-mode';
import { HevoEntity } from '../../../../../app/core/models/hevo-entity';
import { _authService } from '../../../../../app/core/service/auth.service';
import { DestinationService } from '../../../../../app/core/service/destination.service';
import { DestinationConfigData } from '../../../../../app/nodes/destination-settings-form/destination-form-data';
import { DestinationSettingsService } from '../../../../../app/nodes/destination-settings-form/destination-settings.service';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { AngularDiProviders } from '../../../../components/AngularDI/AngularDiProviders';
import { NodeSettingsShimmer } from '../../../../components/NodeSettingsShimmer';
import RetryApiAlert from '../../../../components/RetryApiAlert';
import { SetupGuideDocs } from '../../../../components/Docs/SetupGuideDocs';
import { HdButton, HdDocLink } from '../../../../components/UIElements';
import FullScreenModal from '../../../../components/UIElements/FullScreenModal';
import useService from '../../../../hooks/useService';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import DestinationsAPI from '../../DestinationsAPI';
import DestinationSettingsForm from '../DestinationSettingsForm';
import styles from './styles.module.scss';
import { useDestinationEditDialog } from './useDestinationEditDialog';

const PLACEHOLDER_FUNCTION = () => {};

const angularDiProviders = [
  {
    provide: DestinationSettingsService
  }
];

export function DestinationEditDialog() {
  const {
    destinationData,
    setDestinationData,
    editDestinationModalConfig,
    navigate
  } = useDestinationEditDialog({ handleDialog: true });

  const [pageLoadError, setPageLoadError] = useState(null);

  const canShowSideBySideDocs = () => {
    if (destinationData?.dest_type) {
      return DESTINATION_TYPES[destinationData?.dest_type].canShowSideBySideDocs;
    }

    return false;
  };

  return (
    <FullScreenModal
      open={editDestinationModalConfig.visible}
      className={styles.editModal}
      innerContentClass={canShowSideBySideDocs() && !pageLoadError ? 'd-flex' : ''}
    >
      <AngularDiProviders providers={angularDiProviders}>
        <DestinationEditDialogInner
          destinationSeqId={editDestinationModalConfig.destinationSeqId}
          canShowSideBySideDocs={canShowSideBySideDocs()}
          pageLoadError={pageLoadError}
          onPageError={(error) => setPageLoadError(error)}
          destinationData={destinationData}
          onDestinationData={(data) => setDestinationData(data)}
          onClose={() => navigate(undefined)}
        />
      </AngularDiProviders>
    </FullScreenModal>
  );
}

export function DestinationEditDialogInner({
  destinationSeqId,
  canShowSideBySideDocs,
  pageLoadError,
  destinationData,
  onDestinationData,
  onPageError,
  onClose
}) {
  const [loadingPage, setLoadingPage] = useState(false);
  const destinationConfig = useRef<DestinationConfigData>();
  const _destinationSettingsService = useService(DestinationSettingsService);
  const destinationService = useService(DestinationService);
  const dataIdGenerator = getDataIdGenerator('edit-destination-dialog');

  useEffect(() => {
    resolveDependencies();
  }, [destinationSeqId]);

  const resolveDependencies = async () => {
    try {
      setLoadingPage(true);
      onPageError(null);
      const { data: destination } = await DestinationsAPI.getDestinationBySeqId(
        destinationSeqId,
        {},
        false
      );

      if (destination?.config?.oauth_token_id) {
        const authUser = await _authService
          .getTokenDetails(destination.dest_type, destination.config.oauth_token_id)
          .toPromise();
        destination.config.authenticated_user = authUser.user;
      }

      const res = await _destinationSettingsService
        .getNodeFromExistingData$(DESTINATION_TYPES[destination.dest_type], destination, true)
        .toPromise();
      destinationConfig.current = { ...res };

      setLoadingPage(false);
      onDestinationData(destination);
    } catch (error) {
      setLoadingPage(false);
      onPageError(error);
    }
  };

  const closeDialog = () => {
    onClose();
  };

  const onSubmit = () => {
    destinationService.editDestinationSubject.next();
    onClose();
  };

  const onFormChanges = formData => {
    destinationConfig.current.formData = formData;
  };

  const getDestDocumentation = (destType: string): string => DESTINATION_TYPES[destType].docLink;

  return (
    <>
      <div
        className={`scrollable-content ${
          canShowSideBySideDocs && !pageLoadError ? 'flex-1' : ''
        }`}
      >
        <HdButton
          className={styles.hide}
          dataId='destination-edit-dialog-go-back'
          type='button'
          variation='flat'
          icon='back'
          palette='secondary'
          onClick={closeDialog}
        >
          Go Back
        </HdButton>

        <div className='body-container mt-4'>
          {loadingPage && <NodeSettingsShimmer />}

          {pageLoadError && (
            <RetryApiAlert
              actionHandler={resolveDependencies}
              error={pageLoadError}
              dataId={dataIdGenerator('')}
            />
          )}

          {!loadingPage && !pageLoadError && destinationData ? (
            <>
              <div className='header-container'>
                <div className='header'>
                  Edit {destinationData?.dest_type_display} Destination connection settings
                </div>

                {destinationData?.dest_type &&
                getDestDocumentation(destinationData?.dest_type) ? (
                  <div className='subheader'>
                    To know more about configuring Destination, you can read our{' '}
                    <HdDocLink
                      label='Documentation'
                      icon='new-window'
                      section='settings_header'
                      dataId={dataIdGenerator('')}
                      docLink={getDestDocumentation(destinationData.dest_type)}
                    />
                  </div>
                ) : null}
              </div>

              <div
                className='settings-content'
                style={{ maxWidth: `${canShowSideBySideDocs ? '80%' : '800px'}` }}
              >
                <DestinationSettingsForm
                  destination={destinationConfig.current}
                  destinationType={destinationData}
                  destinationMode={DestinationMode.DESTINATION}
                  updateConnection
                  hevoEntityFor={HevoEntity.DESTINATION}
                  draftDestination={undefined}
                  selectedExistingDestination={undefined}
                  frozenFields={undefined}
                  onSubmit={onSubmit}
                  onFormDataChanges={onFormChanges}
                  onConnectionError={PLACEHOLDER_FUNCTION}
                  onConnectionSuccess={PLACEHOLDER_FUNCTION}
                  onFormDirty={PLACEHOLDER_FUNCTION}
                  onFormFieldChange={PLACEHOLDER_FUNCTION}
                  onFormFieldFocusIn={PLACEHOLDER_FUNCTION}
                  onInviteMember={PLACEHOLDER_FUNCTION}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>

      {!pageLoadError && destinationData && canShowSideBySideDocs ? (
        <div className={styles.setupGuideDocs}>
          <SetupGuideDocs
            sourceTypeIdentifier={destinationData.dest_type}
            docLink={getDestDocumentation(destinationData.dest_type)}
            loadingParent={loadingPage}
            areTabsRendered={DESTINATION_TYPES[destinationData.dest_type].showSBSTabs}
          />
        </div>
      ) : null}
    </>
  );
}
