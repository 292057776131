/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */

import { Pipe, ChangeDetectorRef, PipeTransform, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import moment from 'moment-mini-ts';
import { Subscription } from 'rxjs';
import { timezoneAbbr } from './tz-abbr';

const momentConstructor = moment;

@Pipe({ name: 'hdmCalendar', pure: false })
export class HdmCalendarPipe implements PipeTransform, OnDestroy {

  /**
   * Internal reference counter, so we can clean up when no instances are in use
   */
  private static refs = 0;

  private static timer: number | null = null;
  private static midnight: EventEmitter<Date> | null = null;

  private midnightSub: Subscription;

  private defaultFormats: any = DEFAULT_CALENDAR_FORMATS;

  constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {
    // using a single static timer for all instances of this pipe for performance reasons
    HdmCalendarPipe.initTimer(ngZone);

    HdmCalendarPipe.refs++;

    // values such as Today will need to be replaced with Yesterday after midnight,
    // so make sure we subscribe to an EventEmitter that we set up to emit at midnight
    this.midnightSub = HdmCalendarPipe.midnight.subscribe(() => {
      this.ngZone.run(() => this.cdRef.markForCheck());
    });
  }

  private static initTimer(ngZone: NgZone) {
    // initialize the timer
    if (!HdmCalendarPipe.midnight) {
      HdmCalendarPipe.midnight = new EventEmitter<Date>();
      if (typeof window !== 'undefined') {
        const timeToUpdate = HdmCalendarPipe._getMillisecondsUntilUpdate();
        HdmCalendarPipe.timer = ngZone.runOutsideAngular(() => {
          return window.setTimeout(() => {
            // emit the current date
            HdmCalendarPipe.midnight.emit(new Date());

            // refresh the timer
            HdmCalendarPipe.removeTimer();
            HdmCalendarPipe.initTimer(ngZone);
          }, timeToUpdate);
        });
      }
    }
  }

  private static removeTimer() {
    if (HdmCalendarPipe.timer) {
      window.clearTimeout(HdmCalendarPipe.timer);
      HdmCalendarPipe.timer = null;
      HdmCalendarPipe.midnight = null;
    }
  }

  private static _getMillisecondsUntilUpdate() {
    const now = momentConstructor();
    const tomorrow = momentConstructor().startOf('day').add(1, 'days');
    const timeToMidnight = tomorrow.valueOf() - now.valueOf();
    return timeToMidnight + 1000; // 1 second after midnight
  }

  transform(value: number | Date | moment.Moment, ...args: any[]): any {
    let formats: any = this.defaultFormats;
    let referenceTime: any = null;
    let showTimeZone = true;

    for (let i = 0, len = args.length; i < len; i++) {
      if (args[i] !== null) {
        if (typeof args[i] === 'object' && !moment.isMoment(args[i])) {

          if (args[i].formats) {
            formats = args[i].formats;
          }

          if (args[i].showTimezone === false) {
            showTimeZone = false;
          }

        } else {
          referenceTime = momentConstructor(args[i]);
        }
      }
    }

    let op = momentConstructor(value).calendar(referenceTime, formats);

    if (showTimeZone) {
      let date;
      if (value instanceof Date) {
        date = value;
      } else if (moment.isMoment(value)) {
        date = value.toDate();
      } else if (typeof(value) === 'string' || typeof(value) === 'number') {
        date = new Date(value);
      } else {
        return op;
      }

      op += ' (' + timezoneAbbr(date) + ')';
    }

    return op;
  }

  ngOnDestroy(): void {
    if (HdmCalendarPipe.refs > 0) {
      HdmCalendarPipe.refs--;
    }

    if (HdmCalendarPipe.refs === 0) {
      HdmCalendarPipe.removeTimer();
    }

    this.midnightSub.unsubscribe();
  }
}

export const DEFAULT_CALENDAR_FORMATS = {
  lastDay : '[Yesterday at] LT',
  sameDay : '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek : 'Do MMM, YYYY [at] LT',
  nextWeek: 'Do MMM, YYYY [at] LT',
  sameElse: 'Do MMM, YYYY [at] LT'
};
