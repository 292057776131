import { SidelineMessage } from '../models/sideline-message';

export function SidelineMessagesFactory(rawData: any[]): SidelineMessage[] {
  return rawData.map((item: any) => {
    const message = new SidelineMessage();

    message.schemaName = item.schema_name;
    message.errorCode = item.code;
    message.stage = item.stage;
    message.count = item.count;
    message.reason = item.reason;
    message.detail = item.detail;
    message.actions = item.actions.actions || [];
    message.hasEagerEvents = item.has_eager_events;
    message.hasUncertainCount = item.has_uncertain_count;
    message.disabledActions = item.temporarily_disabled_actions.actions || [];

    return message;
  });
}
