import { autocompleteClasses, InputBase, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { getDataId } from '../../../utils/generateDataId';
import { HdTextFieldWithAdornment } from '../HdTextFieldAdornment';

// styling mui base input to match up HD styling
export const HdInputBase = styled(InputBase)({
  '& .MuiInputBase-input': {
    padding: 'calc(var(--spacer) * 0.13) 0px calc(var(--spacer) * 0.17)',
    '&::placeholder': {
      opacity: 1
    }
  }
});

const slideInTransition = {
  from: {
    marginTop: '-50px',
    width: '300%'
  },
  to: {
    marginTop: 0,
    width: '100%'
  }
};

const transitionText = {
  display: 'flex',
  whiteSpace: 'pre-line',
  alignItems: 'center',
  animationDuration: '300ms'
};

export interface HdTextFieldProps {
  prefixElement?: React.ReactElement;
  dataId: string;
  prefixIcon?: string;
  prefixText?: string;
  hideFieldAppliedBorder?: boolean;
  useErrorColorOnError?: boolean;
  suffixElement?: React.ReactElement;
  suffixText?: string;
  forceActiveLegend?: boolean;
  resize?: string;
  classStyles?: {
    'MuiOutlinedInput-root'?: any;
    'MuiOutlinedInput-input'?: any;
  };
}

export const HdTextField = styled(
  ({
    prefixElement,
    dataId,
    prefixIcon,
    prefixText,
    suffixText,
    suffixElement,
    hideFieldAppliedBorder,
    useErrorColorOnError,
    forceActiveLegend,
    classStyles,
    InputProps,
    inputProps,
    ...props
  }: HdTextFieldProps & TextFieldProps) => {
    const inputDataId = getDataId('input')(dataId)();

    const inputPropsFinal = {
      ...inputProps,
      'data-id': inputDataId
    };

    let InputPropsFinal;
    if (typeof InputProps !== 'undefined' && InputProps !== null) {
      InputPropsFinal = {
        ...InputProps
      };

      if (typeof InputProps.inputProps !== 'undefined' && InputProps.inputProps !== null) {
        InputPropsFinal.inputProps = {
          ...InputProps.inputProps,
          'data-id': inputDataId
        };
      }
    }

    let conditionalProps = {};

    if (props?.type === 'number') {
      conditionalProps = {
        onWheel: e => e.target.blur(),
        onKeyDown: e => {
          if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
          }

          if (props.onKeyDown) {
            props.onKeyDown(e);
          }
        }
      };
    }

    return (
      <>
        {forceActiveLegend || prefixIcon || prefixText || suffixElement || suffixText ? (
          <HdTextFieldWithAdornment
            {...props}
            prefixElement={prefixElement}
            prefixIcon={prefixIcon}
            prefixText={prefixText}
            suffixText={suffixText}
            suffixElement={suffixElement}
            forceActiveLegend={forceActiveLegend}
            InputProps={InputPropsFinal}
            inputProps={inputPropsFinal}
            {...conditionalProps}
          />
        ) : (
          <TextField
            {...props}
            InputProps={InputPropsFinal}
            inputProps={inputPropsFinal}
            {...conditionalProps}
          />
        )}
      </>
    );
  }
)(props => ({
  width: '100%',
  borderRadius: 'var(--border-radius-md)',
  '& label.MuiInputLabel-root.custom-shrinkable': {
    transform: 'translate(calc(var(--spacer) * 4.4), var(--spacer)) scale(1)'
  },
  '& label.MuiInputLabel-root.custom-icon-shrinkable': {
    transform: 'translate(calc(var(--spacer) * 3), var(--spacer)) scale(1)'
  },
  '& .MuiInputBase-adornedStart': {
    paddingLeft: 'var(--spacer)'
  },
  '& .MuiInputBase-adornedEnd': {
    paddingRight: 'var(--spacer)'
  },
  '& .MuiInputBase-adornedStart .hd-adornment:first-of-type': {
    maxWidth: '60px',
    display: 'flex',
    alignItems: 'center'
  },
  '& label.MuiInputLabel-root': {
    color: 'var(--text-secondary-color)',
    fontWeight: 'var(--font-weight-medium)',
    fontSize: 'var(--font-size-body)',
    transform: 'translate(var(--spacer), var(--spacer)) scale(1)',
    paddingRight: 'calc(var(--spacer) * 2)'
  },
  '& label.MuiInputLabel-shrink': {
    transform: 'translate(var(--spacer), -6px) scale(0.83) !important'
  },
  '& label .MuiInputLabel-asterisk': {
    paddingLeft: '1px',
    color: 'var(--error-color-asterisk)'
  },
  '& label.Mui-focused': {
    color: 'var(--text-default-color)'
  },
  '& label.Mui-error': {
    color: 'var(--error-color)'
  },
  '& label.Mui-disabled, & label.Mui-disabled .MuiInputLabel-asterisk': {
    color: 'var(--text-secondary-color)'
  },
  '& .MuiFormHelperText-root:empty': {
    display: 'none'
  },
  '& .MuiFormHelperText-root': {
    marginTop: 'calc(var(--spacer) * 0.25)',
    marginLeft: 'calc(var(--spacer) * 0.55)',
    fontSize: '83%',
    fontWeight: 'var(--font-weight-medium)',
    color: 'var(--text-secondary-color) !important',
    minHeight: '20px',
    width: 'auto',
    position: 'relative',
    overflow: 'hidden'
  },
  '&.overflow-visible-helper-text .MuiFormHelperText-root': {
    overflow: 'visible'
  },
  '&.clear-helper-text .MuiFormHelperText-root': {
    minHeight: 0,
    margin: 0
  },
  '& .MuiFormHelperText-root .transition': {
    '@keyframes slidein': slideInTransition,
    ...transitionText,
    animationName: 'slidein'
  },
  '& .MuiFormHelperText-root .error-transition': {
    '@keyframes slideinerror': slideInTransition,
    ...transitionText,
    animationName: 'slideinerror',
    '& a': {
      fontSize: 'var(--font-size-caption)'
    }
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: props.hideFieldAppliedBorder ? 'none': 'var(--surface-bg-color)',
    ...(props.classStyles?.['MuiOutlinedInput-root'] || {}),
    ' textarea': {
      resize: props.resize || 'vertical'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: props.hideFieldAppliedBorder ? '0 !important' : '1px',
      borderColor: 'var(--divider-color)',
      borderRadius: 'var(--border-radius-md)',
      padding: '0px 10px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderRadius: 'var(--border-radius-md)',
      borderColor: 'var(--text-default-color)'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderRadius: 'var(--border-radius-md)',
      borderColor: 'var(--text-default-color)'
    },
    '&.MuiInputBase-multiline': {
      padding: 0
    },
    '&.Mui-error': {
      color: 'var(--error-color)'
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--error-color)'
    },
    '&.Mui-error .MuiOutlinedInput-input': props.useErrorColorOnError
      ? {
          color: 'var(--error-color)',
          WebkitTextFillColor: 'var(--error-color)'
        }
      : {},
    '& .MuiOutlinedInput-notchedOutline span': {
      padding: props.label ? 'calc(var(--spacer) * 0.63)' : 0
    },
    '& .MuiOutlinedInput-input': {
      color: 'var(--text-default-color)',
      fontSize: 'var(--font-size-body)',
      fontFamily: 'inherit',
      fontWeight: 'var(--font-weight-medium)',
      WebkitBoxShadow: '0 0 0 1000px var(--surface-bg-color) inset',
      WebkitTextFillColor: 'var(--text-default-color)',
      paddingLeft: 'var(--spacer)',
      ...(props.classStyles?.['MuiOutlinedInput-input'] || {})
    },
    '& .MuiOutlinedInput-input.MuiInputBase-inputMultiline': {
      paddingTop: '16.5px',
      paddingBottom: '16.5px'
    },
    '& .MuiInputBase-inputSizeSmall': {
      lineHeight: '16px',
      height: '16px',
      paddingTop: 'calc(var(--spacer) * .75)',
      paddingBottom: 'calc(var(--spacer) * .75)'
    },
    '& .MuiInputBase-inputAdornedStart': {
      paddingLeft: 'calc(var(--spacer) * .75)'
    },
    '& .MuiInputBase-inputAdornedEnd': {
      paddingRight: 'calc(var(--spacer) * .75)'
    },
    '&.Mui-disabled .MuiOutlinedInput-input.Mui-disabled': {
      WebkitBoxShadow: 'none',
      cursor: 'not-allowed'
    },
    '&.Mui-disabled': {
      backgroundColor: 'var(--default-bg-color)'
    },
    [`&.Mui-disabled fieldset`]: {
      border: 0
    },
    '& .MuiOutlinedInput-input:read-only': {
      cursor: 'pointer'
    }
  },
  '&.hd-flat-input .MuiOutlinedInput-input': {
    padding: 'calc(var(--spacer) * 0.72)'
  },
  '&.hd-flat-input label.MuiInputLabel-root': {
    transform: 'translate(var(--spacer), calc(var(--spacer) * 0.72)) scale(1)'
  },
  '&.hd-flat-input .MuiFormHelperText-root': {
    display: 'none'
  },
  '&.HdTextField-visuallyHidden .MuiInputBase-input': {
    opacity: 0,
    transform: 'scale(0)'
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    right: 'calc(var(--spacer) * 0.75)!important',
    color: 'var(--text-secondary-color)',
    width: '28px'
  },
  [`& .${autocompleteClasses.endAdornment} .${autocompleteClasses.clearIndicator},
    & .${autocompleteClasses.endAdornment} .${autocompleteClasses.popupIndicator}`]: {
    transition: 'none',
    opacity: '.8',
    color: 'var(--text-default-color)'
  },
  [`& .${autocompleteClasses.endAdornment} .${autocompleteClasses.clearIndicator}:hover:not(:disabled),
    & .${autocompleteClasses.endAdornment} .${autocompleteClasses.clearIndicator}:focus,
    & .${autocompleteClasses.endAdornment} .${autocompleteClasses.popupIndicator}:hover:not(:disabled),
    & .${autocompleteClasses.endAdornment} .${autocompleteClasses.popupIndicator}:focus`]: {
    opacity: '1',
    backgroundColor: 'var(--ripple-bg-color)'
  },
  [`& .${autocompleteClasses.endAdornment} .${autocompleteClasses.clearIndicator}`]: {
    visibility: 'visible'
  },
  [`& .${autocompleteClasses.endAdornment} .${autocompleteClasses.popupIndicator},
  & .${autocompleteClasses.endAdornment} .${autocompleteClasses.clearIndicator}`]: {
    padding: 0
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    paddingLeft: 'calc(var(--spacer) * .5) !important'
  },
  [`& .${autocompleteClasses.input}`]: {
    paddingLeft: 'calc(var(--spacer) * .5) !important'
  },
  [`& .${autocompleteClasses.inputRoot} .hd-adornment:first-of-type`]: {
    padding: '0px calc(var(--spacer) * .3) 0px calc(var(--spacer) * .5) !important'
  },
  [`& .${autocompleteClasses.input}.MuiInputBase-inputSizeSmall`]: {
    paddingTop: 'calc(var(--spacer) * .375) !important',
    paddingBottom: 'calc(var(--spacer) * .375) !important'
  },
  [`& .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
    cursor: 'pointer'
  },
  [`& .${autocompleteClasses.inputRoot}.Mui-disabled fieldset`]: {
    borderWidth: '1px',
    border: '1px solid var(--divider-color)',
    borderRadius: 'var(--border-radius-md)'
  },
  [`& .${autocompleteClasses.inputRoot}.Mui-disabled,
  & .${autocompleteClasses.inputRoot}.Mui-disabled .${autocompleteClasses.input}`]: {
    backgroundColor: 'var(--default-bg-color) !important',
    cursor: 'not-allowed',
    WebkitBoxShadow: 'none',
    pointerEvents: 'none'
  }
}));

export default HdTextField;
