// Converts any rgb or hex value to rgba using a specified alpha
export function convertToRgba(color: string, alpha: number) {
  if (!color) { return; }

  let rgbaColor = color;

  if (color.includes('#')) {                    // If hex provided
    color = color.replace('#', '');
    const r = parseInt(color.length === 3 ? color.slice(0, 1).repeat(2) : color.slice(0, 2), 16);
    const g = parseInt(color.length === 3 ? color.slice(1, 2).repeat(2) : color.slice(2, 4), 16);
    const b = parseInt(color.length === 3 ? color.slice(2, 3).repeat(2) : color.slice(4, 6), 16);
    if (alpha) {
      rgbaColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      rgbaColor = 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  } else if (color.indexOf('a') === -1) {       // If rgb provided
    rgbaColor = color.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }

  return rgbaColor;
}
