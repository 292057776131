export enum AccountAccessStatus {
  GRANTED = 'GRANTED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED'
}

export class AccountAccessHistory {
  id: number;
  teamId: number;
  createdAt: Date;
  expiresAt: Date;
  updatedAt: Date;
  consentStatus: AccountAccessStatus;
  approvedByUser: string;
  approvedByUserId: number;
  revokedByUser: string;
  revokedByUserId: number;
}
