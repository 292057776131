export enum EditorModes {
  SQL = 'text/x-sql',
  MS_SQL = 'text/x-mssql',
  MY_SQL = 'text/x-mysql',
  POSTGRE_SQL = 'text/x-pgsql'
}

export enum FormatterModes {
  SQL = 'sql',
  MY_SQL = 'mysql',
  POSTGRE_SQL = 'postgresql',
  REDSHIFT = 'redshift',
  TSQL = 'tsql'
}
