import { Injectable, OnDestroy } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

import { PRODUCT_TOUR_SESSION_ID_KEY } from '../constants';
import { SessionTrackingAngularticsService } from './session-tracking-angulartics.service';
import { SettingsStorageService } from './settings-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ProductTourAngularticsService extends SessionTrackingAngularticsService implements OnDestroy {
  constructor(
    protected _angulartics2: Angulartics2,
    protected _settingsStorageService: SettingsStorageService
  ) {
    super(
      _angulartics2,
      _settingsStorageService,
      PRODUCT_TOUR_SESSION_ID_KEY
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
