import { DestinationMode } from '../../../../../app/core/models/destination-mode';
import { HevoEntityItem } from '../../../../../app/core/models/hevo-entity';
import { DestinationConfigData } from '../../../../../app/nodes/destination-settings-form/destination-form-data';
import { RawDestinationType } from '../../../../../app/nodes/destination-type/model';
import { SettingsFormSubmissionMode } from '../../../../../app/nodes/submission-mode';

export interface SettingFormProps {
  destinationType: RawDestinationType;
  destinationMode: DestinationMode;
  updateConnection: boolean;
  hevoEntityFor: HevoEntityItem;
  destination: DestinationConfigData;
  draftDestination: RawDestinationType;
  selectedExistingDestination: any;
  frozenFields: any;
  onConnectionError: Function;
  onConnectionSuccess: Function;
  onSubmit: Function;
  onFormDirty: Function;
  onFormFieldChange: Function;
  onFormFieldFocusIn: Function;
  onFormDataChanges: Function;
  onInviteMember: Function;
}

export interface State {
  formError?: any;
  formErrorCode?: number;
  formErrorFor?: SettingsFormSubmissionMode;
  formSuccess?: any;
  connecting?: boolean;
  submitting?: boolean;
  isSnowflakeAutoFillExperimentVariant: boolean;
  isRedshiftConnectionURIExperimentVariant: boolean;
}

export enum ActionKind {
  SET_FORM_SUCCESS = 'setFormSuccess',
  SET_FORM_ERROR = 'setFormError',
  CLEAR_FORM_ERROR_METADATA = 'clearFormErrorMetaData',
  UPDATE_FORM_ERROR_METADATA = 'updateFormErrorMetaData',
  SET_CONNECTING = 'setConnecting',
  SET_SUBMITTING = 'setSubmitting',
  CONNECTION_SUCCESS = 'connectionSuccess'
}

export interface Action {
  type: ActionKind;
  data?: any;
}
