import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, timer } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import {
  Notification,
  NotificationsListType
} from '../../../react/components/NotificationDrawer/models';
import { EnvConfig } from '../../../react/containers/core/env-config';

import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';
import { TeamSettingsService } from './team-settings.service';

interface LatestFetchNotificationOffset {
  id: string;
  createdTs: string;
}

@Injectable()
export class NotificationService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _teamSettingsService: TeamSettingsService
  ) {}

  notificationUrl: string = this._appConfig.getNotificationURL();
  alertsV2Url: string = this._appConfig.getAlertsV2URL();
  private _unreadCount$ = new BehaviorSubject<{ [key: string]: number }>({
    regular: 0,
    enterprise: 0
  });
  private _latestFetchStandardNotificationOffset$ = new BehaviorSubject<{
    [key: string]: LatestFetchNotificationOffset;
  }>({ regular: null, enterprise: null });
  private _notifications$ = new BehaviorSubject<{ [key: string]: Notification[] }>({
    regular: [],
    enterprise: []
  });

  unreadCount$ = this._unreadCount$.asObservable();
  notifications$ = this._notifications$.asObservable();

  updateUnreadCount = (value: { [key: string]: number }) => {
    this._unreadCount$.next({ ...this._unreadCount$.getValue(), ...value });
  };

  setNotifications(notifications: { [key: string]: Notification[] }) {
    this._notifications$.next({ ...this._notifications$.getValue(), ...notifications });
  }

  updateLatestFetchNotificationOffset(offset: { [key: string]: LatestFetchNotificationOffset }) {
    this._latestFetchStandardNotificationOffset$.next({
      ...this._latestFetchStandardNotificationOffset$.getValue(),
      ...offset
    });
  }

  getLatestFetchNotificationOffsetForEntity(entity: NotificationsListType) {
    return this._latestFetchStandardNotificationOffset$.getValue()[entity];
  }

  markRead(entity: NotificationsListType, ids?: any[]): Observable<any> {
    const params: any = ids;

    if (entity === NotificationsListType.ENTERPRISE) {
      const enterpriseAlertsUrl = this.alertsV2Url + '/mark-read';

      const until_ms =
        this._notifications$.getValue()[NotificationsListType.ENTERPRISE][0]?.createdTs;

      return this._rxRequestService.post(enterpriseAlertsUrl, BACKGROUND_NETWORK_REQ_OPTIONS, {
        until_ms
      });
    }

    const requestUrl = this.notificationUrl + '/mark-read';

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params).pipe(
      map(() => {
        return ids;
      })
    );
  }

  getNotificationCountObservable(): Observable<any> {
    const period = 30000;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: false
      },
      networkOptions: {}
    };

    return timer(0, period).pipe(
      exhaustMap(() => {
        let standardOptions = { ...options };
        standardOptions.networkOptions.params = {};
        const latestFetchStandardNotificationOffset =
          this._latestFetchStandardNotificationOffset$.getValue();

        if (latestFetchStandardNotificationOffset?.id) {
          standardOptions.networkOptions.params = createHttpParams({
            excludeId: latestFetchStandardNotificationOffset.regular.id,
            from: new Date(latestFetchStandardNotificationOffset.regular.createdTs).toJSON()
          });
        }

        const standardAlertsUrl = this.notificationUrl + '/counts';
        const enterpriseAlertsUrl = this.alertsV2Url + '/count';
        const countAPIPromises = [this._rxRequestService.get(standardAlertsUrl, standardOptions)];

        if (
          this._teamSettingsService.isH2OEnabled() && EnvConfig.isH20FeatureEnabled('ALERTS')
        ) {
          countAPIPromises.push(this._rxRequestService.get(enterpriseAlertsUrl, options));
        }

        return forkJoin(countAPIPromises);
      }),
      map(res => {
        this._unreadCount$.next({
          regular: res[0].data.unread_count,
          enterprise: res[1]?.data.unread_alerts ?? 0
        });
        return res;
      })
    );
  }
}
