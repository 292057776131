import { ActivitySeverity, ActivitySeverityLevel } from './model';

export const ACTIVITY_SEVERITIES: ActivitySeverity[] = [
  {
    displayName: 'Low',
    level: ActivitySeverityLevel.TRACE,
    colorType: 'default',
    filterLevels: [
      ActivitySeverityLevel.TRACE,
      ActivitySeverityLevel.INFO,
      ActivitySeverityLevel.WARN,
      ActivitySeverityLevel.ERROR
    ]
  },
  {
    displayName: 'Medium',
    level: ActivitySeverityLevel.INFO,
    colorType: 'info',
    filterLevels: [
      ActivitySeverityLevel.INFO,
      ActivitySeverityLevel.WARN,
      ActivitySeverityLevel.ERROR
    ]
  },
  {
    displayName: 'High',
    level: ActivitySeverityLevel.WARN,
    colorType: 'warning',
    filterLevels: [
      ActivitySeverityLevel.WARN,
      ActivitySeverityLevel.ERROR
    ]
  },
  {
    displayName: 'Critical',
    level: ActivitySeverityLevel.ERROR,
    colorType: 'error',
    filterLevels: [
      ActivitySeverityLevel.ERROR
    ]
  }
];
export const DEFAULT_SEVERITY_NAME = 'Medium';
export const ACTIVITY_PAGE_LIMIT = 50;
