<div
  [tooltip]="rbacToolTip"
  class="d-inline-block"
  [tooltipDisabled]="!isBlocked()">
  <button
    type="button"
    hdButton
    size="sm"
    variation="outline"
    [iconOnly]="iconOnly"
    iconClass="icon-size-2"
    [icon]="paused ? 'play' : 'pause'"
    [disabled]="disabled || isBlocked()"
    [tooltipDisabled]="isBlocked()"
    [tooltip]="paused ? 'Resume' : 'Pause'"
    (click)="onClick($event)">
    {{ !iconOnly ? (paused ? 'Resume' : 'Pause') : '' }}
  </button>
</div>
