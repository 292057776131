export const AGGREGATION_FUNCTION: any[] = [
  {
    name: 'SUM',
    value: 'TOTAL'
  },
  {
    name: 'MINIMUM',
    value: 'MINIMUM'
  },
  {
    name: 'MAXIMUM',
    value: 'MAXIMUM'
  },
  {
    name: 'COUNT',
    value: 'COUNT'
  }
];

export const COMPARISON_OPERATORS: IOption[] = [
  {
    name: 'Numeric Equal',
    id: 'NUMERIC_EQUAL'
  },
  {
    name: 'Numeric Greater Than',
    id: 'NUMERIC_GREATER_THAN'
  },
  {
    name: 'Numeric Less Than',
    id: 'NUMERIC_LESS_THAN'
  },
  {
    name: 'Numeric Less than or equal',
    id: 'NUMERIC_LESS_THAN_OR_EQUAL'
  },
  {
    name: 'Numeric Greater than or equal',
    id: 'NUMERIC_GREATER_THAN_OR_EQUAL'
  },
  {
    name: 'Is Missing',
    id: 'IS_MISSING'
  }
];

export const MATCH_OPERATORS: IOption[] = [
  {
    name: 'Regexp',
    id: 'REGEXP'
  },
  {
    name: 'Begins With',
    id: 'BEGINS_WITH'
  },
  {
    name: 'Ends With',
    id: 'ENDS_WITH'
  },
  {
    name: 'Partial',
    id: 'PARTIAL'
  },
  {
    name: 'Exact',
    id: 'EXACT'
  },
  {
    name: 'Numeric Equal',
    id: 'NUMERIC_EQUAL'
  },
  {
    name: 'Numeric Greater Than',
    id: 'NUMERIC_GREATER_THAN'
  },
  {
    name: 'Numeric Less Than',
    id: 'NUMERIC_LESS_THAN'
  },
  {
    name: 'Numeric Less than or equal',
    id: 'NUMERIC_LESS_THAN_OR_EQUAL'
  },
  {
    name: 'Numeric Greater than or equal',
    id: 'NUMERIC_GREATER_THAN_OR_EQUAL'
  },
  {
    name: 'In List',
    id: 'IN_LIST'
  }
];

export const LOGICAL_OPERATORS: any[] = [
  {
    name: 'Or',
    id: 'OR'
  },
  {
    name: 'And',
    id: 'AND'
  }
];

export const INCLUDE_EXCLUDE_OPTIONS: any[] = [
  {
    name: 'Include',
    id: false
  },
  {
    name: 'Exclude',
    id: true
  }
];

interface IOption {
  [key: string]: string;
}

export interface IClauseState {
  operator: IOption;
  filters: {
    unit?: IOption;
    includeExclude: IOption;
    operator: IOption;
    caseSensitive?: boolean;
    expression?: string;
  }[];
}

export const getFilterObj = unit => ({
  caseSensitive: false,
  expression: '',
  unit: null,
  includeExclude: INCLUDE_EXCLUDE_OPTIONS[0],
  operator: unit === 'metric' ? COMPARISON_OPERATORS[0] : MATCH_OPERATORS[0]
});

export const getDefaultClauseObj = (unit: any) => ({
  operator: LOGICAL_OPERATORS[0],
  filters: [getFilterObj(unit)]
});

export interface IReportState {
  dimensions: IOption[];
  metrics: IOption[];
  reportName: string;
  pivotReports: boolean;
  pivotDimensions: IOption[];
  pivotMetrics: IOption[];
  pivotAggregationFunctions: IOption[];
  metricFilterClauses: IClauseState[];
  dimensionFilterClauses: IClauseState[];
}
export interface IGoogleAnalyticsSourceFormikState {
  accountId: IOption;
  propertyId: IOption;
  oauthTokenId: number;
  reports: IReportState[];
}

export const getDefaultReportStateObj: () => IReportState = () => ({
  reportName: '',
  dimensions: [
    {
      apiName: 'date',
      description: 'The date of the event, formatted as YYYYMMDD.',
      uiName: 'Date'
    }
  ],
  metrics: [],
  pivotMetrics: [],
  pivotDimensions: [],
  pivotAggregationFunctions: [],
  pivotReports: false,
  metricFilterClauses: [],
  dimensionFilterClauses: []
});
