import * as Yup from 'yup';
import { getSSLConfigValidationSchema } from '../../../../../components/FormikElements/SSLConfig/validationSchema';
import { GenericKeyValueType } from '../../../../../generics';
import { Sql } from './index';
import { SourceConfigMetaData } from '../models';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import {
  hostNameValidatorSchema,
  localHostValidatorSchema,
  schemaNameValidatorSchema
} from '../../../../../utils/validatorSchema';
import { SetupGuideLinkParams } from '../../../../../utils/setupGuideLinkHandler';
import { SqlFormFields } from './models';

export interface SqlConfigMetaData {
  getLocalhostSBSLinkParams: Function;
}

export const SQL_CONFIG: SourceConfigMetaData & SqlConfigMetaData = {
  formComponent: Sql,

  initialValues: {
    [SqlFormFields.HOST]: '',
    [SqlFormFields.PORT]: '',
    [SqlFormFields.USER]: '',
    [SqlFormFields.DATABASE_NAME]: '',
    [SqlFormFields.SQL_SCHEMA]: '',
    [SqlFormFields.USE_SSL]: false,
    [SqlFormFields.USE_SSH]: false,
    [SqlFormFields.SSH_IP]: '',
    [SqlFormFields.SSH_PORT]: '',
    [SqlFormFields.SSH_USER]: '',
    [SqlFormFields.SSL_CA]: null,
    [SqlFormFields.SSL_CLIENT_CERT]: null,
    [SqlFormFields.SSL_CLIENT_KEY]: null,
    [SqlFormFields.LOAD_ALL_DB]: true,
    [SqlFormFields.MERGE_TABLES]: true,
    [SqlFormFields.IMPORT_HISTORICAL_DATA]: true,
    [SqlFormFields.INCLUDE_NEW_TABLES]: true,
    [SqlFormFields.LOAD_ALL_SCHEMAS]: false,
    [SqlFormFields.SCHEMA_NAMES]: undefined,
    [SqlFormFields.SCHEMA_NAME]: '',
    [SqlFormFields.DATABASE_NAMES]: '',
    [SqlFormFields.POLL_INTERVAL]: 500,
    [SqlFormFields.QUERY_FETCH_SIZE]: 10000,
    [SqlFormFields.LONG_TRANSACTION_WINDOW]: 5,
    [SqlFormFields.ONLINE_CATALOG]: true,
    [SqlFormFields.ARCHIVE_LOG_ONLY]: true,
    [SqlFormFields.ORACLE_POLL_TASK_V2_FLAG]: false,
    [SqlFormFields.AZURE_SERVER_FLEXI]: 'true',
    sslLoadAllCA: false,
    [SqlFormFields.APPLICATION_INTENT_READ_ONLY]: false
  },

  fieldNameMapping: {
    host: 'db-host',
    user: 'db-admin',
    port: 'db-port',
    databaseName: 'db-name',
    databaseNames: 'db-names',
    password: 'db-password',
    sshIp: 'ssh-ip',
    sshPort: 'ssh-port',
    sshUser: 'ssh-user',
    schemaName: 'schema-name',
    useSSH: 'use-ssh',
    useSSL: 'use-ssl',
    loadAllSchemas: 'load-all-dbs',
    schemaNames: 'schema-names',
    loadAllDb: 'load-all-dbs',
    importHistoricalData: 'historical-mode',
    mergeTables: 'merge-tables',
    sslCA: 'ssl-ca',
    sslClientCert: 'ssl-client-cert',
    sslClientKey: 'ssl-client-key',
    pollInterval: 'oracle_long_polling_sleep_millis',
    queryFetchSize: 'oracle_result_set_fetch_size',
    longTransactionWindow: 'oracle_log_mining_transaction_window_secs',
    onlineCatalog: 'oracle_use_dictionary_from_online_catalog',
    archiveLogOnly: 'oracle_mine_online_redo_logs',
    oraclePollTaskV2Flag: 'oracle_poll_task_v2'
  },

  validationSchema(props) {
    const sourceTypeIdentifier = props.sourceTypeMetaData.name;

    let sqlSchema: GenericKeyValueType<string, any> = {
      host: Yup.string()
        .trim()
        .test(
          localHostValidatorSchema({
            type: 'SQL',
            typeIdentifier: sourceTypeIdentifier,
            localhostDocLinkParams: this.getLocalhostSBSLinkParams(sourceTypeIdentifier)
          })
        )
        .test(hostNameValidatorSchema({ type: 'SQL' }))
        .required(),
      port: Yup.number().positive().required(),
      user: Yup.string().trim().required(),
      password: Yup.string().trim().required(),
      useSSH: Yup.boolean(),
      useSSL: Yup.boolean(),
      sshIp: Yup.string().when('useSSH', {
        is: true,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
      sshPort: Yup.string().when('useSSH', {
        is: true,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
      sshUser: Yup.string().when('useSSH', {
        is: true,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
      databaseNames: Yup.string().when('loadAllDb', {
        is: false,
        then: () => Yup.string().trim().required(),
        otherwise: () => Yup.string().trim().nullable()
      }),
      loadAllDb: Yup.boolean(),
      mergeTables: Yup.boolean(),
      importHistoricalData: Yup.boolean(),
      includeNewTables: Yup.boolean(),
      loadAllSchemas: Yup.boolean(),
      pollInterval: Yup.number()
        .required('Specify a value between 1 and 10,000.')
        .min(1, 'Specify a value between 1 and 10,000.')
        .max(10000, 'Specify a value between 1 and 10,000.'),
      queryFetchSize: Yup.number()
        .required('Specify a value between 1,000 and 50,000.')
        .min(1000, 'Specify a value between 1,000 and 50,000.')
        .max(50000, 'Specify a value between 1,000 and 50,000.'),
      longTransactionWindow: Yup.number()
        .required('Specify a value between 0 and 240.')
        .min(0, 'Specify a value between 0 and 240.')
        .max(240, 'Specify a value between 0 and 240.'),
      onlineCatalog: Yup.boolean(),
      archiveLogOnly: Yup.boolean(),
      oraclePollTaskV2Flag: Yup.boolean(),
      oracleEnableLongPolling: Yup.boolean()
    };

    if (props.sourceTypeMetaData.sslAllowed) {
      sqlSchema = {
        ...sqlSchema,
        ...getSSLConfigValidationSchema(props.sourceTypeMetaData.sslCARequired)
      };
    }

    if (!props.jobMode.canProvideMultipleDbNames) {
      sqlSchema.databaseName = Yup.string()
        .trim()
        .test({
          name: 'invalid-database-name',
          message: 'Database is not valid',
          test(value) {
            const { databaseNameError } = this.parent;
            return databaseNameError !== 1014 && !!value;
          }
        });
    }

    if (props.sourceTypeMetaData.provideSchemaName) {
      sqlSchema.schemaName = Yup.string()
        .trim()
        .test(schemaNameValidatorSchema())
        .test({
          name: 'invalid-schema-name',
          message: 'Schema name is not valid',
          test(value) {
            const { schemaNameError } = this.parent;

            if (schemaNameError === 1020) {
              return false;
            }

            if (props.sourceTypeMetaData.isSchemaNameRequired && !value) {
              return false;
            }

            return true;
          }
        });
    }

    if (
      props.sourceTypeMetaData.provideSchemaName &&
      props.jobMode?.canShowRedoLogAdvancedSettings
    ) {
      sqlSchema.schemaNames = Yup.string()
        .trim()
        .when(['loadAllSchemas'], {
          is: false,
          then: () => Yup.string().trim().required(),
          otherwise: () => Yup.string().trim()
        });
    }

    return Yup.object().shape(
      { ...sqlSchema },

      [['sslClientCert', 'sslClientKey']]
    );
  },

  getLocalhostSBSLinkParams: sourceTypeIdentifier => {
    const params: SetupGuideLinkParams = {
      setupGuideLink: `${SOURCE_TYPES[sourceTypeIdentifier].docLink}#connect-to-a-local-database-optional`,
      section: 'source-settings',
      label: 'db-host-field-local-host-steps',
      properties: {
        sourceType: sourceTypeIdentifier,
        context: 'Source'
      }
    };
    return params;
  }
};
