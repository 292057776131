import React, { useState } from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { HdIcon, HdIconButton, HdMenu, HdRbacMenuItem } from '../../../../components/UIElements';
import { DestinationLoadWebhook } from '../interface';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import { ENTITY_DELETE_DIALOG_COMMON_CONFIG } from '../../../../../app/core/constants';
import WebhooksAPI from '../WebhooksApi';
import useConfirmV2 from '../../../../components/Dialog/ConfirmDialog/ConfirmDialogV2/useConfirmV2';

import { EDIT_BY_SEQ_ID_QUERY_PARAM, WebhookStatus, webhookStatusLookUp } from '../model';
import { RbacPermissions } from '../../../../../app/core/models/user';

interface WebhookActionItemsProps {
  webhook: DestinationLoadWebhook;
  onDelete?: (webhookId: number) => void;
  updateWebhookStatus: (webhookId: number, status: any) => void;
}

export function WebhookActionItems({
  webhook,
  onDelete,
  updateWebhookStatus
}: WebhookActionItemsProps) {
  const [open, setOpen] = useState(false);

  const { confirmV2 } = useConfirmV2();

  const dataIdGenerator = getDataIdGenerator(`webhook-${webhook.id}-actions`);

  const history = useHistory();

  const onDeleteClick = () => {
    setOpen(false);
    confirmV2({
      ...ENTITY_DELETE_DIALOG_COMMON_CONFIG,
      title: `Delete ${webhook?.name} Webhook`,
      body: `Are you sure you want to delete the ${webhook?.name} webhook for ${webhook?.destination?.name}`,
      positiveButtonText: 'Yes, Delete This Webhook',
      negativeButtonText: 'No',
      modalProps: { placement: 'top' }
    }).then(res => {
      if (!res) {
        return;
      }

      WebhooksAPI.deleteWebhook(webhook.id).then(() => {
        onDelete(webhook.id);
      });
    });
  };

  const onPauseClick = () => {
    setOpen(false);
    WebhooksAPI.pauseWebhook(webhook.id).then(() => {
      updateWebhookStatus(webhook.id, webhookStatusLookUp[WebhookStatus.PAUSED]);
    });
  };

  const onResumeClick = () => {
    setOpen(false);
    WebhooksAPI.resumeWebhook(webhook.id).then(() => {
      updateWebhookStatus(webhook.id, webhookStatusLookUp[WebhookStatus.ACTIVE]);
    });
  };

  const onEdit = () => {
    setOpen(false);
    history.push(`/alerts/webhooks/config?${EDIT_BY_SEQ_ID_QUERY_PARAM}=${webhook.seqId}`);
  };
  return (
    <>
      <HdIconButton
        id={`webhook-${webhook.id}-action-items`}
        onClick={() => setOpen(state => !state)}
        dataId={dataIdGenerator('more-actions')}
      >
        <HdIcon name='more-vertical' />
      </HdIconButton>

      <HdMenu
        target={`#webhook-${webhook.id}-action-items`}
        open={open}
        onClose={() => setOpen(false)}
      >
        {webhook.status.status === WebhookStatus.ACTIVE && (
          <HdRbacMenuItem
            dataId={dataIdGenerator('rbac-pause')}
            rbacPermission={RbacPermissions.PIPELINE_EDIT}
            onClick={onPauseClick}
          >
            <HdIcon name='pause' dataId={dataIdGenerator('pause')} />
            Pause
          </HdRbacMenuItem>
        )}

        {webhook.status.status === WebhookStatus.PAUSED && (
          <HdRbacMenuItem
            dataId={dataIdGenerator('rbac-play')}
            rbacPermission={RbacPermissions.PIPELINE_EDIT}
            onClick={onResumeClick}
          >
            <HdIcon name='play' dataId={dataIdGenerator('play')} />
            Resume
          </HdRbacMenuItem>
        )}

        <HdRbacMenuItem
          dataId={dataIdGenerator('rbac-edit')}
          rbacPermission={RbacPermissions.PIPELINE_EDIT}
          onClick={onEdit}
        >
          <HdIcon name='edit' dataId={dataIdGenerator('edit')} />
          Edit
        </HdRbacMenuItem>

        <HdRbacMenuItem
          dataId={dataIdGenerator('rbac-delete')}
          rbacPermission={RbacPermissions.PIPELINE_EDIT}
          className='border-top error'
          onClick={onDeleteClick}
        >
          <HdIcon name='delete' dataId={dataIdGenerator('delete')} />
          Delete
        </HdRbacMenuItem>
      </HdMenu>
    </>
  );
}
