// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nodeLogo__zTXX {\n  border-radius: 4px !important;\n}\n\n.btnClearStyles_gsgjS {\n  margin: 0;\n  cursor: pointer;\n  padding: 0;\n  background: none;\n  border-width: 0;\n  color: inherit;\n  font: inherit;\n}\n.btnClearStyles_gsgjS:focus {\n  outline: none;\n  text-decoration: none;\n}\n.btnClearStyles_gsgjS[disabled] {\n  cursor: not-allowed;\n}\n\n.description_TaBYM {\n  width: 60%;\n}\n\n.cell_QF9JP {\n  padding: 0 20px 0 20px;\n  display: flex;\n  align-items: center;\n}\n.cell_QF9JP.name_iU1t4 {\n  width: 20%;\n}\n.cell_QF9JP.url_J7SrA {\n  width: 60%;\n}\n.cell_QF9JP.status__7mtb {\n  width: 20%;\n}\n\n.dataRow_Qsv98 {\n  height: 60px;\n}\n\n.headerRow_iKRvm {\n  height: 32px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeLogo": "nodeLogo__zTXX",
	"btnClearStyles": "btnClearStyles_gsgjS",
	"description": "description_TaBYM",
	"cell": "cell_QF9JP",
	"name": "name_iU1t4",
	"url": "url_J7SrA",
	"status": "status__7mtb",
	"dataRow": "dataRow_Qsv98",
	"headerRow": "headerRow_iKRvm"
};
export default ___CSS_LOADER_EXPORT___;
