import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const TRELLO_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPITokenField({
        label: 'API Key',
        hint: 'Enter the API key by logging into Trello and visiting <a class="text-link text-link-sm" href="https://trello.com/app-key" target="_blank">here</a>.',
        externalDocLink:
          'https://developer.atlassian.com/cloud/trello/guides/rest-api/api-introduction/'
      })
    }
  ],
  [
    {
      key: 'apiSecret',
      fieldId: 'api-secret',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'apiSecret', label: 'Secret Token', required: true },
      hint: 'Enter the Token by clicking on hyperlinked <i>Token</i> under the API key.',
      externalDocLink:
        'https://developer.atlassian.com/cloud/trello/guides/rest-api/api-introduction/',
      buildParamsKey: 'api_secret',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
