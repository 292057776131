import { FieldTypes, SourceFormFieldDefinition } from '../../models';
import modifyFieldDefinition from './modify-field-definition';
import { TemplateConfig } from './template-config';

export default function APITokenTemplate(templateConfig: TemplateConfig): Array<Array<SourceFormFieldDefinition>> {
  return [
    [
      getAPITokenField(templateConfig)
    ]
  ];
}

export function getAPITokenField(templateConfig: TemplateConfig): SourceFormFieldDefinition {
  const { label = 'API Token', hint } = templateConfig;

  const template: SourceFormFieldDefinition = {
    key: 'apiToken',
    fieldId: 'api-token',
    widget: FieldTypes.PASSWORD_FIELD,
    fieldType: 'string',
    widgetOptions: { name: 'apiToken', label: label, required: true },
    hint: hint,
    buildParamsKey: 'api_token',
    validations: [
      {
        type: 'required'
      }
    ]
  };

  return modifyFieldDefinition(template, templateConfig);
}
