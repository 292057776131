/* eslint-disable class-methods-use-this */
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';
import { getFormattedDataForDropdown, moveWorkspaceDataToSelectedProjects } from './util';

export class AsanaConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any): {} {
    return {
      workspace: this.transformPropertyListToArray(metaData, rawData.selected_projects || [])
    };
  }

  getDefaultConfig(metaData: any) {
    const workspace = [];
    metaData.workspaceList.forEach(() => {
      workspace.push([]);
    });
    return {
      workspace
    };
  }

  buildConnectionParams(data: any) {
    const selectedProjects = Object.entries(moveWorkspaceDataToSelectedProjects(data.workspace || []))
      .filter(
        ([_, workspaceDetails]: [string, any]) => workspaceDetails && workspaceDetails.length > 0
      )
      .map(([workspaceId, workspaceDetails]) => ({
        workspace_id: workspaceId,
        projects: workspaceDetails
      }));

    return {
      selected_projects: selectedProjects
    };
  }

  transformPropertyListToArray(metaData: any, selectedWorkspaceList: any[]) {
    const selectedWorkspaceArray = [];

    /* looping over the metadata too bcz we need to give empty default values to the respective dropdown
        as user selected the projects from only one workspace out of many.
        and FieldArray component render the initial values based on index
    */

    metaData.workspaceList.forEach(data => {
      selectedWorkspaceList.forEach((workspaceDetail: any) => {
        let selectedProjectArray = [];
        if (data.workspace_id === workspaceDetail.workspace_id) {
          selectedProjectArray = this.filterSelectedProjectList(
            metaData,
            workspaceDetail.workspace_id,
            workspaceDetail.projects
          );
          selectedWorkspaceArray.push(selectedProjectArray);
        }
      });
    });
    return selectedWorkspaceArray;
  }

  filterSelectedProjectList(metaData: any, workspaceId: string, selectedProjects = []) {
    if (workspaceId in metaData.workspaceObj) {
      return metaData.workspaceObj[workspaceId].filter((project: any) =>
        selectedProjects.some((selectedProject: any) => selectedProject.key === project.key)
      );
    }
    return [];
  }

  async metaDataResolver(sourceType: string, authData: any) {
    const { data } = await PipelinesAPI.getAsanaProperties(authData.oauthTokenId);
    const workspaceList = data.asana_response_list;
    const workspaceObj = workspaceList.reduce(
      (workspaceMap: any, workspaceDetail: any) => ({
        ...workspaceMap,
        [workspaceDetail.workspace_id]: getFormattedDataForDropdown(workspaceDetail) || []
      }),
      {}
    );
    return {
      workspaceList,
      workspaceObj
    };
  }
}
