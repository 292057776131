import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { HdButton, HdIcon, HdIconButton, HdModal, HdPane } from '../../UIElements';
import { MigratePipelinesToSyncFrequencyDialog } from '..';
import { getDataIdsFromContract } from '../../../utils/generateDataId';

interface SyncFrequencyGTMProps {
  open: boolean;
  onClose: Function;
}

export const dataIds = getDataIdsFromContract({
  base: 'syncFrequencyGTM',
  closeIconBtn: 'close',
  cancelBtn: 'cancel',
  setFrequencyBtn: 'setFrequency'
});

export function SyncFrequencyGTM({ open, onClose }: SyncFrequencyGTMProps) {
  const [isGTMModalOpen, setIsGTMModalOpen] = useState(false);
  const [isMigrationDialogOpen, setIsMigrationDialogOpen] = useState(false);
  const [isMigrationComplete, setIsMigrationComplete] = useState(false);
  useEffect(() => {
    setIsGTMModalOpen(open);
  }, [open]);

  const openMigrationDialogHandler = () => {
    setIsGTMModalOpen(false);
    onCloseHandler();
    setIsMigrationDialogOpen(true);
  };

  const onCloseHandler = () => {
    if (!isMigrationComplete) {
      onClose();
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <HdModal
        open={isGTMModalOpen || isMigrationComplete}
        variant='custom'
        onClose={onCloseHandler}
      >
        <div className={styles.gtmContentContainer}>
          {isMigrationComplete ? (
            <HdIconButton
              dataId={dataIds.closeIconBtn}
              className='drawer-close'
              onClick={onCloseHandler}
            >
              <HdIcon name='close' size='2' />
            </HdIconButton>
          ) : null}

          <div className={clsx(styles.bannerImg, 'bg-info-2')}>
            <img
              alt='gtm-banner'
              src='https://res.cloudinary.com/hevo/image/upload/v1694157422/hevo-website/Hevo%202.0/illustrations/syncScheduleGTM_altx96.svg'
            />
          </div>
          <div className={styles.modalContent}>
            <div className='text-subheading-3'>
              {!isMigrationComplete
                ? `Introducing Sync Schedule`
                : `Pipelines Successfully Migrated to Sync Schedule!`}
            </div>

            <div className='mt-3 text-center text-secondary'>
              {!isMigrationComplete
                ? `We are switching from separate ingestion and load frequencies to a common Sync
              Schedule for a Pipeline. You can set the sync frequencies for your Pipelines in the
              next screen.`
                : 'All your Pipelines are updated to use the Sync Schedule to replicate data from your Source to the Destination.'}
            </div>
            {!isMigrationComplete ? (
              <>
                <HdPane
                  variant='warning-faded'
                  icon='warning'
                  className={clsx(styles.migrationWarningPane, 'mt-3')}
                >
                  <Typography variant='body2'>
                    <b>This migration is irreversible.</b>You need to migrate all your existing
                    Pipelines to use the new Sync Schedule. Once the Pipelines are migrated, they
                    will run based on the sync frequency instead of the ingestion and load
                    frequencies.
                  </Typography>
                </HdPane>

                <div className='d-flex mt-4'>
                  <HdButton
                    dataId={dataIds.cancelBtn}
                    variation='outline'
                    onClick={() => {
                      setIsGTMModalOpen(false);
                      onClose();
                    }}
                  >
                    WILL DO THIS LATER
                  </HdButton>

                  <HdButton
                    dataId={dataIds.setFrequencyBtn}
                    className='ml-3'
                    onClick={() => {
                      openMigrationDialogHandler();
                    }}
                  >
                    SET SYNC SCHEDULE
                  </HdButton>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </HdModal>
      {isMigrationDialogOpen ? (
        <MigratePipelinesToSyncFrequencyDialog
          open
          onClose={(complete = false) => {
            setIsMigrationDialogOpen(false);
            setIsMigrationComplete(complete);
          }}
        />
      ) : null}
    </>
  );
}
