import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'no-item-box-icon-container',
  templateUrl: './no-item-box-icon-container.component.html',
  styleUrls: [ './no-item-box-icon-container.component.scss' ]
})
export class NoItemBoxIconContainerComponent {
  @Input() iconName: string;
  @HostBinding('class.no-item-box-icon-container') addContainerClass = true;
}
