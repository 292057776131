import React from 'react';
import { TitleGroupProps } from './shared/interfaces';
import { calendarViews } from './shared/const';
import { useDateTimeAdapter } from '../useDateTimeAdapter';

interface ITileGroup<T> extends TitleGroupProps<T> {
  rowClassName?: string;
  className: string;
  activeStartDate: T;
  count?: number;
  cellRatio?: number;
  start: number;
  end: number;
  currentMonthIndex?: number;
  dateTransform: (num: number) => T;
  dateType: calendarViews;
  step?: number;
  tile: Function;
}

export default function TileGroup<T>({
  className,
  count = 3,
  cellRatio = 1,
  dateTransform,
  dateType,
  end,
  hover,
  rowClassName = '',
  start,
  tile: Tile,
  value,
  ...tileProps
}: ITileGroup<T>) {
  const titleGrid: React.ReactElement[] = [];

  const { dateTimeAdapter, getTileClasses } = useDateTimeAdapter<T>();

  let row: React.ReactElement[] = [];

  for (let point = 0; point <= end - start; point += 1) {
    if (point % count === 0) {
      if (row.length > 0) {
        titleGrid.push(
          <div
            key={point}
            className={`react-calendar__row ${rowClassName}`}
            style={{ display: 'flex' }}
          >
            {row}
          </div>
        );
      }
      row = [];
    }

    const date = dateTransform(point + start);
    row.push(
      <div
        key={point}
        style={{
          position: 'relative',
          width: `${100 / count}%`,
          height: 0,
          paddingTop: `${(50 * cellRatio) / count}%`,
          paddingBottom: `${(50 * cellRatio) / count}%`
        }}
      >
        <Tile
          key={dateTimeAdapter.getTime(date)}
          classes={getTileClasses({
            value,
            date,
            dateType
          })}
          date={date}
          point={point}
          {...tileProps}
        />
      </div>
    );
  }

  if (row.length !== 0) {
    titleGrid.push(
      <div
        key='last-row'
        className={`react-calendar__row ${rowClassName}`}
        style={{ display: 'flex' }}
      >
        {row}
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{titleGrid}</>
  );
}
