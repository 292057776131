export enum ResourcePermissionState {
  CHECKING_PERMISSION,
  CAN_CREATE_RESOURCE,
  CANT_CREATE_RESOURCE
}

export enum BigqueryResourceType {
  BUCKET = 'bucket',
  DATASET = 'dataset',
  PROJECT = 'projectId'
}

export const AUTH_FORM_FIELD_NAME = 'auth';

export const HEVO_GCS_BUCKET_OPTION = {
  id: 'hevos gcs bucket option',
  name: `Hevo's GCS bucket `,
  existing: true,
  apiLocation: null,
  internalBucket: true,
  isAutoCreated: true
};
