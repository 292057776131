// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.workflowViewContainer_n6YVi {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n}\n\n.workflowIcon__0bdN {\n  flex-shrink: 0;\n  margin-right: 4px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflowViewContainer": "workflowViewContainer_n6YVi",
	"workflowIcon": "workflowIcon__0bdN"
};
export default ___CSS_LOADER_EXPORT___;
