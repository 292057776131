export enum WorkflowStepExecutionStatus {
  EDIT = 'EDIT',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum WorkflowStepType {
  MODEL = 'MODEL',
  ACTIVATION = 'ACTIVATION',
  SWITCH_CONDITION = 'SWITCH_CONDITION'
}

export enum WorkflowConditionStepOperandType {
  CONDITION = 'CONDITION',
  BOOL_OPERATION = 'BOOL_OPERATION'
}

export interface WorkflowConditionStepOperand {
  type: WorkflowConditionStepOperandType;
}

export enum WorkflowConditionStepConditionOperator {
  GEQ = 'GEQ',
  LEQ = 'LEQ',
  GREATER_THAN_ZERO = 'GREATER_THAN_ZERO'
}

export interface WorkflowConditionStepCondition extends WorkflowConditionStepOperand {
  tableName: string;
  destinationId: number;
  rowCount: number;
  operator: WorkflowConditionStepConditionOperator;
}

export enum WorkflowConditionStepBooleanOperationOperator {
  AND = 'AND',
  OR = 'OR'
}

export interface WorkflowConditionStepBooleanOperation extends WorkflowConditionStepOperand {
  left: WorkflowConditionStepOperand;
  right: WorkflowConditionStepOperand;
  operator: WorkflowConditionStepBooleanOperationOperator;
}

export interface WorkflowEdge {
  vertexId: string;
  type: 'DEFAULT' | 'TRUE_OUTPUT' | 'FALSE_OUTPUT';
}

export interface WorkflowStepJob {
  status: WorkflowStepExecutionStatus;
  failureMessage?: string;
}

export class WorkflowStep {
  stopOnFailure = true;
  dependsOnVertices: WorkflowEdge[];
  id: number;
  vertexId: string;
  lastJob: WorkflowStepJob;
  stepType: WorkflowStepType;
  status: WorkflowStepExecutionStatus;
  hasIncompleteConfig = false;
}

export class WorkflowConditionStep extends WorkflowStep {
  name: string;
  condition?: WorkflowConditionStepOperand;
}

export class WorkflowEntityStep extends WorkflowStep {
  entityId: number;
  vertexNodeData: any;
  isDbtProject?: boolean;
  allowIndependentRuns?: boolean;
}
