import React from 'react';
import HdSetupGuideLink from '../../../../../components/UIElements/HdSetupGuideLink';
import { SetupGuideLinkRequiredParams } from '../../../../../utils/setupGuideLinkHandler';

export function getSnowflakeValidationErrorMessage() {
  const snowflakeHostUrlSetupGuide: SetupGuideLinkRequiredParams = {
    setupGuideLink: '/destinations/data-warehouses/snowflake/#obtain-your-snowflake-account-url',
    section: 'destination-settings',
    label: 'account-url-field-help-text'
  };

  return (
    <span>
      Invalid Snowflake Account URL. Enter URL of format{' '}
      <i>https://account_name.region.snowflakecomputing.com</i>.
      <HdSetupGuideLink
        {...snowflakeHostUrlSetupGuide}
        size='sm'
        icon='right'
        dataId='snowflake-account-url'
      >
        Need Help?
      </HdSetupGuideLink>
    </span>
  );
}
