import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, ESCAPE, TAB } from '@angular/cdk/keycodes';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollDispatcher,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccordionComponent } from '../../accordion/accordion.component';

import { matchPattern } from '../../../react/legacy-utils/string';
import { executeOnStable } from '../../../react/legacy-utils/zone';
import { MatFormField, MatFormFieldControl } from '../../form-field';
import { NestedKeyboardNavigationComponent } from '../../a11y/nested-keyboard-navigation/nested-keyboard-navigation.component';
import { EdgeDir, HdScrollViewportComponent } from '../../scrolling/scroll-viewport/scroll-viewport.component';
import { FaCustomLoadingTextDirective } from './fa-custom-loading-text.directive';
import { FaCustomValueIconDirective } from './fa-custom-value-icon.directive';
import { FaCustomValueTextDirective } from './fa-custom-value-text.directive';
import { FaGroupTemplateDirective } from './fa-group-template.directive';
import { FaHelpTemplateDirective } from './fa-help-template.directive';
import { FaItemTemplateDirective } from './fa-item-template.directive';
import { FaNotFoundDirective } from './fa-not-found.directive';
import { FlexibleAutoCompleteMode } from './flexible-auto-complete-mode';
import { FlexibleAutoCompleteTriggerDirective } from './flexible-auto-complete-trigger.directive';
import { AutoCompleteOption, AutoCompleteOptionGroup, HelpTextState, ListRotateDir } from './models';


const DEFAULT_ALL_SELECTED_STRING = 'All';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'flexible-auto-complete',
  templateUrl: './flexible-auto-complete.component.html',
  styleUrls: [ './flexible-auto-complete.component.scss' ],
  providers: [
    {
      provide: MatFormFieldControl, useExisting: FlexibleAutoCompleteComponent
    }
  ]
})
export class FlexibleAutoCompleteComponent
  implements OnInit, OnChanges, DoCheck, ControlValueAccessor, MatFormFieldControl<any>, AfterViewChecked, OnDestroy {
  static nextId = 0;

  showingOptionsList = false;
  searchString: string;
  allSelected = false;
  reflectedIpValue: any = '';
  helpTextStates = HelpTextState;
  mode: FlexibleAutoCompleteMode;
  modes = FlexibleAutoCompleteMode;

  // optionMinHeight = 40, maxOverlayHeight = 224px , pageSize = 2 + (int)(maxOverlayHeight / optionMinHeight)
  pageSize = 8;
  selectionContainerDefaultWidth = 256;

  private currentValue: any;
  /** Whether or not the label state is being overridden. */
  private _manuallyFloatingLabel = false;

  private _disabled = false;
  @HostBinding('class.disabled') @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input() useAsText = false;
  @Input() renderSelectAll = false;
  @Input() sort = true;
  @Input() sortOptionGroup = true;
  @Input() accessor: any;
  @Input() grouped: boolean;
  @Input() groupAccessor: any = 'name';
  @Input() @HostBinding('class.has-external-trigger') hasExternalTrigger = false;
  @Input() inline = false;
  @Input() multi = false;
  @Input('loading') renderLoading = false;
  @Input() searchMethod: (pattern: string, option: any) => boolean;
  @Input() maxWidth = '500px';
  @Input() showCustomField = true;
  @Input() maxTextLength: number | null;
  @Input() focusOnInit = false;
  @Input() disabledOptions = new Map<string | number, boolean>();
  @Input() valueAccessor: any;
  @Input() helpAccessor = 'help';
  @Input() useVirtualScroll = false;
  @Input() withOptionHelp = false;
  @Input() showClearOption = true;
  @Input() renderGroupsAsAccordion = false;
  @Input() isErrorIconVisible = false;
  @Input() errorTooltipContent: string;

  /**
   * TODO: adding option to a group when multiple groups present
   * */
  @Input() showAddNewOptionAtBottom = false;
  @Input() addNewOptionAtBottomKeyword = 'Option';
  @Input() useSearchTextToCreateNewOption = false;
  @Input() createNewOptionFn: (optionName: string) => Observable<any>;
  @Output() emitCreatedOption = new EventEmitter<any>();
  creatingNewOption = false;

  @Input() showRefreshOptionsList = false;
  @Output() emitRefreshOptionsRequest = new EventEmitter<void>();
  refreshingOptionList = false;

  /**
   * deprecated use onOpen
   */
  @Output() ipFocus = new EventEmitter<any>();
  @Output() onOpen = new EventEmitter<any>();
  @Output() onHide = new EventEmitter<any>();

  private _placeholder = 'Search';
  multiPlaceholder;

  @Input('placeholder')
  set placeholder(value: string) {
    this._placeholder = value;
    this.updateMultiPlaceholderText();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input('options') _optionsData: any[] = [];
  filteredOptionGroups: AutoCompleteOptionGroup[] = [];
  optionGroups: AutoCompleteOptionGroup[] = [];
  displayedOptionGroups: AutoCompleteOptionGroup[] = [];
  filteredOptionsCount = 0;

  helpTextCurrentOption: AutoCompleteOption;

  reverseRendering = false;
  lastItem: any;
  firstItem: any;
  /**
   * Document click handler
   */
  private _documentClickListener: () => void;

  @ContentChild(FaItemTemplateDirective) faItemTemplate: FaItemTemplateDirective;
  @ContentChild(FaHelpTemplateDirective) faHelpTemplate: FaHelpTemplateDirective;
  @ContentChild(FaGroupTemplateDirective) faGroupTemplate: FaGroupTemplateDirective;
  @ContentChild(FaNotFoundDirective) faNotFound: FaNotFoundDirective;
  @ContentChild(FaCustomValueTextDirective) faCustomValueText: FaCustomValueTextDirective;
  @ContentChild(FaCustomValueIconDirective) faCustomValueIcon: FaCustomValueIconDirective;
  @ContentChild(FaCustomLoadingTextDirective) faCustomLoadingText: FaCustomLoadingTextDirective;

  @ViewChild('ip') inputRef: any;
  @ViewChild('externalTriggerSearch') externalTriggerSearch: any;
  @ViewChild('triggerWrapper') triggerWrapper: any;
  @ViewChild(NestedKeyboardNavigationComponent) nestedOptionsElementRef: NestedKeyboardNavigationComponent;
  @ViewChild('selectionContainer') selectionContainer: ElementRef;
  @ViewChild('selectionContainerTemplateRef', { static: true }) selectionContainerTemplateRef: TemplateRef<any>;
  @ViewChild('defaultOptionTemplate') defaultOptionTemplateRef: TemplateRef<any>;
  @ViewChild('clearButton', { read: ElementRef }) clearButtonRef: ElementRef;
  @ViewChild('accordionRef') accordionRef: AccordionComponent;

  externalTrigger: FlexibleAutoCompleteTriggerDirective;

  private _overlayRef: OverlayRef;
  private _dropdownPortal: TemplatePortal;
  private _edgeBufferTop = 35;
  private _minimumOptionSize = 42;

  @ViewChild('scrollViewport') scrollViewport: HdScrollViewportComponent;

  /**
   * Implement form field control interface
   */
  focused = false;

  canShowClearIcon = false;
  useSearchTextToAddNewOption = false;

  get value() {
    return this.currentValue;
  }

  set value(value) {
    this.updateCurrentValue(value);
    this.stateChanges.next();
  }

  get isShowingLoader(): boolean {
    return this.creatingNewOption || this.refreshingOptionList;
  }

  stateChanges = new Subject<void>();

  @HostBinding() id = `flexible-autocomplete-${ FlexibleAutoCompleteComponent.nextId++ }`;

  get empty() {
    if (!this.currentValue) {
      return true;
    }
    if (Array.isArray(this.currentValue)) {
      return !this.currentValue.length;
    }

    return false;
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  private _required = false;

  errorState = false;

  controlType = 'flexible-auto-complete';

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    const clickElement = event.target as Element;
    if (clickElement.tagName.toLowerCase() !== 'input') {
      this.focusIp();
    }
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get addNewOptionBottomLabel(): string {
    if (!this.showAddNewOptionAtBottom) {
      return '';
    }

    if (this.useSearchTextToCreateNewOption && this.searchString?.length) {
      return `Create "${ this.searchString }" as a new ${ this.addNewOptionAtBottomKeyword }`;
    }

    return `Add ${ this.addNewOptionAtBottomKeyword }`;
  }

  constructor(
    private _el: ElementRef,
    private _zone: NgZone,
    private _scrollDispatcher: ScrollDispatcher,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private _fm: FocusMonitor,
    private _cdRef: ChangeDetectorRef,
    @Optional() private _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    this.monitorFocus();
  }

  private propagateChange = (_: any) => {
  }

  private onTouched = () => {
  }

  ngOnInit() {
    /**
     * We need to call this here because, reactive form control directive
     * tries to call writeValue before ngOnInit of this component, while
     * all input properties are not resolved
     */
    this.onCurrentValueChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.valueAccessor) {
      this.valueAccessor = this.accessor;
    }

    if (this.renderSelectAll) {
      this._edgeBufferTop = this._edgeBufferTop + this._minimumOptionSize;
    }

    if (changes._optionsData) {
      this.refreshingOptionList = false;
      this.buildOptionGroups();
    }

    if (changes.renderLoading && !this.renderLoading && this.refreshingOptionList) {
      this.refreshingOptionList = false;
    }

    this.setMode();

    if (this.focusOnInit) {
      executeOnStable(this._zone, () => {
        this._zone.run(() => {
          if (this.mode === FlexibleAutoCompleteMode.INLINE) {
            this.focusExternalTriggerSearch();
          }
        });
      });
    }

    if (changes.disabledOptions) {
      this.updateDisabledOptions();
    }
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = new ErrorStateMatcher();
    const control = this.ngControl ? this.ngControl.control as FormControl : null;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  /**
   * Control value accessor methods
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.updateCurrentValue(value);
    this.handleShowClearIcon();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }


  /**
   * Handle implicit trigger events
   */
  onFocus(e: any) {
    e.target.selectionStart = e.target.selectionEnd;
    this.show();
    this._floatLabel(true);
    this.ipFocus.emit(e);
  }


  onArrowClick(e: any) {
    this.inputRef.nativeElement.focus();
    e.stopPropagation();
  }

  onClick(e) {
    this.onContainerClick(e);
  }


  /**
   * Update current value and set placeholder and options state
   */
  updateCurrentValue(value: any) {
    if (value) {
      if (this.multi && !value.length) {
        this.currentValue = undefined;
      } else {
        this.currentValue = value;
      }

    } else {
      this.currentValue = undefined;
    }

    this.onCurrentValueChange();
  }

  onCurrentValueChange() {
    this.updateIpValue();
    this.updateMultiPlaceholderText();
    this.updateOptionsActiveState();
    this.updateAllSelectedFlag();
    this.stateChanges.next();
  }


  /**
   * Update placeholder text only in the case of multi
   */
  updateMultiPlaceholderText() {
    let text: string;

    if (this.multi && Array.isArray(this.currentValue)) {
      text = this.getPlaceholderStringFromArray(this.currentValue);
    }

    this.multiPlaceholder = text;
  }

  getPlaceholderStringFromArray(array: any[]) {
    if (!Array.isArray(array)) {
      return undefined;
    }

    if (array.length === this.getOptionsLength(this.optionGroups)) {
      return DEFAULT_ALL_SELECTED_STRING;
    }

    let text: string = this.getDisplayTextFromAccessor(array[0]);

    const maxTextLength = 10;

    if (text.length > maxTextLength) {
      text = text.slice(0, maxTextLength - 1) + '...';
    }

    return array.length > 1 ?
      text + ' +' + (array.length - 1)
      : text;
  }

  /**
   * Update input value when not multi
   */
  updateIpValue() {
    if (this.multi) {
      return;
    }

    const text = this.getDisplayTextFromAccessor(this.currentValue);

    this.reflectedIpValue = text || '';

    /* Handles the case of editing and selecting the already selected value.
        Selecting an already selected value fails to trigger Change Detection
        as the reflectedIpValue remains the same. Hence, inputRef.nativeElement.value
        has to be explicitly set. */
    if (this.inputRef) {
      this.inputRef.nativeElement.value = this.reflectedIpValue;
    }
  }

  /**
   * Handle item select
   */
  onSelect(option: any) {
    const value = this.getValueOnOptionSelect(option);
    this.updateCurrentValue(value);
    this.propagateChange(this.currentValue);

    if (this.multi) {
      this.focusIp();
    } else {
      this.clearSearch();
      this.hide();
    }
  }

  onAllOptionsSelect() {
    const filteredOptionsValues = this.filteredOptionGroups.reduce((options, optionGroup) => {
      return options.concat(optionGroup.children.filter((option) => !option.isDisabled).map((option) => option.value));
    }, []);

    const newList = [ ...(this.currentValue || []) ];

    const selectItems = !this.allSelected;

    filteredOptionsValues.forEach((option) => {
      const optionIndex = this.getOptionIndexFromList(newList, option);

      if (selectItems && optionIndex === -1) {
        newList.push(option);
      } else if (!selectItems && optionIndex !== -1) {
        newList.splice(optionIndex, 1);
      }
    });

    this.updateCurrentValue(newList);

    this.propagateChange(this.currentValue);
    this.focusIp();
  }

  updateAllSelectedFlag() {
    if (!this.filteredOptionGroups) {
      this.allSelected = false;
      return;
    }

    this.allSelected = this.filteredOptionGroups.every((group) => {
      return group.children.every((option) => option.isActive);
    });
  }

  getValueOnOptionSelect(option: any) {
    if (!this.multi) {
      return option;
    }

    const newValue = Array.isArray(this.currentValue)
      ? this.currentValue.slice(0, this.currentValue.length)
      : [];

    const optionIndex = this.getOptionIndex(option);

    if (optionIndex !== -1) {
      newValue.splice(optionIndex, 1);
    } else {
      newValue.push(option);
    }

    return newValue;
  }

  getOptionIndex(option) {
    return this.getOptionIndexFromList(this.currentValue, option);
  }

  getOptionIndexFromList(list: any[], option: any) {
    if (!Array.isArray(list)) {
      return -1;
    }

    return list.findIndex((item: any) => {
      return this.getValueFromAccessor(item) === this.getValueFromAccessor(option);
    });
  }

  updateOptionsActiveState() {
    if (!Array.isArray(this.optionGroups)) {
      return;
    }

    this.optionGroups.forEach((optionGroup: AutoCompleteOptionGroup) => {
      optionGroup.children.forEach((option) => {
        if (this.multi) {
          option.isActive = this.getOptionIndex(option.value) >= 0;
        } else {
          option.isActive = this.getValueFromAccessor(this.currentValue) === this.getValueFromAccessor(option.value);
        }
      });
    });
  }

  /**
   * Builds option groups from options data
   */
  buildOptionGroups() {
    if (!Array.isArray(this._optionsData)) {
      this.optionGroups = [];
    } else {
      if (!this.grouped) {
        this.optionGroups = [{
          value: 'Default Group',
          isActive: true,
          children: this.buildOptions(this._optionsData)
        }];
      } else {
        this.optionGroups = this._optionsData.map((option: any) => {
          return {
            value: option,
            isActive: false,
            children: this.buildOptions(option.children)
          };
        });
      }
    }

    this.updateOptionsActiveState();
    this.filterOptions();
    if (this.sort) {
      this.sortOptions();
    }
  }

  buildOptions(options: any[]) {
    return options.map((option: any) => this.buildOption(option));
  }

  buildOption(option) {
    return {
      value: option,
      isActive: false,
      isDisabled: false
    };
  }

  updateDisabledOptions() {

    for ( const optionGroup of this.optionGroups ) {
      for ( const option of optionGroup.children ) {
        if (this.disabledOptions.has(this.getValueFromAccessor(option.value))) {
          option.isDisabled = this.disabledOptions.get(this.getValueFromAccessor(option.value));
        } else {
          option.isDisabled = false;
        }
      }
    }
  }

  /**
   * Expand all items for better visibility
   * during search
   * */
  expandAccordionItemsOnSearch() {
    if (this.accordionRef) {
      this.accordionRef.expandAllItems();
    }
  }

  /**
   * Filter options based on search text
   */
  filterOptions() {
    if (!Array.isArray(this.optionGroups)) {
      return;
    }

    const comparator = this.searchMethod || ((pattern: string, option: any) => {
      return matchPattern(
        this.getDisplayTextFromAccessor(option),
        pattern
      );
    });

    this.filteredOptionGroups = this.optionGroups.map((optionGroup) => {
      return {
        ...optionGroup,
        children: optionGroup.children.filter(
          (option: AutoCompleteOption) => {
            return comparator(this.searchString, option.value);
          }
        )
      };
    }).filter((optionGroup) => optionGroup.children.length > 0);

    this.filteredOptionsCount = this.filteredOptionGroups.reduce((total, group) => {
      return total + group.children.length;
    }, 0);

    this.updateAllSelectedFlag();

    if (this.useVirtualScroll) {
      this.reverseRendering = false;

      this.calculateFirstItem();
      this.calculateLastItem();

      this.updateRenderedOptions(false, true);
    } else {
      this.displayedOptionGroups = this.filteredOptionGroups;
    }
  }

  /**
   * Sort Options by moving their position inside ViewContainerRef
   */
  sortOptions() {
    if (!Array.isArray(this.filteredOptionGroups)) {
      return;
    }

    if (this.sortOptionGroup) {
      this.filteredOptionGroups.sort((a: AutoCompleteOptionGroup, b: AutoCompleteOptionGroup) => {
        return this.getActiveOptions(b).length - this.getActiveOptions(a).length;
      });
    }

    this.filteredOptionGroups.forEach((group) => {
      group.children.sort((a: AutoCompleteOption, b: AutoCompleteOption) => {
        return a.isActive === b.isActive ? 0 : (a.isActive ? -1 : 1);
      });
    });

    this.filteredOptionGroups.forEach((group) => {
      group.children.sort((a, b) => {
        return a.isDisabled === b.isDisabled ? 0 : (a.isDisabled ? 1 : -1);
      });
    });
  }

  handleDocEvents() {
    this._documentClickListener = this.renderer.listen(
      'document',
      'click',
      (e: any) => this.onDocumentClick(e));
  }

  unhandleDocEvents() {
    if (this._documentClickListener) {
      this._documentClickListener();
    }
  }

  onDocumentClick(e: any) {
    const isEventTargetInput = this.isEventTargetInput(e);
    const isEventTargetOptions = this.isEventTargetOptions(e);
    const isEventTargetTrigger = this.isEventTargetTrigger(e);
    const isEventTargetFormField = this.isEventTargetFormField(e);
    const isEventTargetClearButton = this.isEventTargetClearButton(e);

    if (isEventTargetClearButton) {
      this.canShowClearIcon = false;
      return;
    }

    if ((this.multi && isEventTargetOptions) || (this.canShowClearIcon && this.useSearchTextToAddNewOption)) {
      return;
    }

    if (!isEventTargetFormField && !isEventTargetInput && !isEventTargetTrigger) {
      this._zone.run(() => {
        if (this.externalTrigger) {
          this.externalTrigger.openedBy = 'mouse';
        }
        this.hide();
      });
    }
  }

  isEventTargetFormField(e: MouseEvent): boolean {
    return this._formField && this._formField._elementRef && this._formField._elementRef.nativeElement.contains(e.target);
  }

  isEventTargetInput(e: MouseEvent): boolean {
    return this.inputRef && this.inputRef.nativeElement.contains(e.target)
      || this.externalTriggerSearch && this.externalTriggerSearch.nativeElement.contains(e.target);
  }

  isEventTargetOptions(e: MouseEvent): boolean {
    return this.nestedOptionsElementRef !== undefined && this.nestedOptionsElementRef.el.nativeElement.contains(e.target);
  }

  isEventTargetTrigger(e: MouseEvent): boolean {
    return this.externalTrigger && this.externalTrigger.containsEventTarget(e);
  }

  isEventTargetClearButton(e: MouseEvent): boolean {
    return this.clearButtonRef && this.clearButtonRef.nativeElement.contains(e.target);
  }

  /** Set dropdown min width */
  ngAfterViewChecked() {
    this.setSelectionContainerDimension();
  }

  setSelectionContainerDimension() {
    const connectedTo = this._getConnectedElement();
    if (this.selectionContainer) {
      this.selectionContainer.nativeElement.style.minWidth =
        Math.max(connectedTo.nativeElement.offsetWidth, this.selectionContainerDefaultWidth) + 'px';
    }
  }


  /**
   * Search input keyboard handler
   */
  onInput(e: any) {
    if (e.target.value === this.searchString) {
      return;
    }

    this.searchString = e.target.value;

    if (!this.hasExternalTrigger && !this.multi
      && this.searchString === '' || this.searchString === undefined) {
      this.clearCurrentValue();
    }

    this.filterOptions();

    if (this.renderGroupsAsAccordion) {
      this.expandAccordionItemsOnSearch();
    }

    this.handleShowClearIcon();
  }

  onKeydown(e: KeyboardEvent) {
    if (e.keyCode === TAB && !this.useSearchTextToAddNewOption) {
      this.keyboardHide();
      return;
    }

    if (e.keyCode === ESCAPE) {

      if (this.showingOptionsList) {
        this.keyboardHide();
        if (this.triggerWrapper) {
          this.triggerWrapper.nativeElement.focus();
        }

        e.stopPropagation();
      }

      return;
    }

    if (e.keyCode === ENTER && this.isNewField() && this.filteredOptionGroups.length === 0) {
      this.keyboardHide();
      return;
    }

    if (this.nestedOptionsElementRef) {
      this.nestedOptionsElementRef.listKeydown(e);
    }
  }

  keyboardHide() {
    if (this.externalTrigger) {
      this.externalTrigger.openedBy = null;
    }

    this.hide();
  }

  focusIp() {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus();
    }
  }

  clearSelectedValues() {
    if (!this.multi || !this.currentValue || !this.currentValue.length) {
      this.updateCurrentValue(undefined);
    } else {
      const newValue = this.currentValue.filter((value) => {
        return this.disabledOptions.get(this.getValueFromAccessor(value)) === true;
      });

      this.updateCurrentValue(newValue.length ? newValue : undefined);
    }

    this.propagateChange(this.currentValue);
  }

  clearCurrentValue() {
    this.updateCurrentValue(undefined);
    this.propagateChange(this.currentValue);
  }

  clearSearch() {
    this.searchString = undefined;
  }

  clearIp() {
    if (this.inputRef) {
      this.inputRef.nativeElement.value = null;
    }
  }

  blurIp() {
    if (this.inputRef) {
      this.inputRef.nativeElement.blur();
    }
  }

  focusExternalTriggerSearch() {
    if (this.externalTriggerSearch) {
      this.externalTriggerSearch.nativeElement.focus();
    }

    if (this.nestedOptionsElementRef) {
      this.nestedOptionsElementRef.scrollViewport.scrollTop(0);
    }
  }

  clearExternalSearchIp() {
    if (this.externalTriggerSearch) {
      this.externalTriggerSearch.nativeElement.value = null;
    }
  }

  ngOnDestroy() {
    this.unhandleDocEvents();
    if (this.showingOptionsList) {
      this.hide();
    }
    this.stateChanges.complete();
    this._fm.stopMonitoring(this._el.nativeElement);
  }


  getValueFromAccessor(data: any) {
    if (typeof data === 'object' && data !== null && this.accessor) {
      return data[this.accessor];
    }

    return data;
  }

  getDisplayTextFromAccessor(data: any) {
    if (typeof data === 'object' && data !== null && this.valueAccessor) {
      return data[this.valueAccessor];
    }

    return data;
  }

  getOptionsLength(optionGroups) {
    return optionGroups.reduce((total, group) => total + group.children.length, 0);
  }

  getActiveOptions(optionGroup) {
    return optionGroup.children.filter((option) => option.isActive);
  }

  /**
   * Handle show hide logic of dropdown
   */
  show(externalTrigger?: FlexibleAutoCompleteTriggerDirective) {
    if (this.showingOptionsList || this.useSearchTextToAddNewOption) {
      return;
    }

    this.reverseRendering = false;

    this.filterOptions();
    if (this.sort) {
      this.sortOptions();
    }

    if (externalTrigger !== this.externalTrigger) {
      this._overlayRef = null;
    }

    this.externalTrigger = externalTrigger;

    this._createOverlayAndAttach();

    this.onOpen.emit();

    executeOnStable(this._zone, () => {
      this._zone.run(() => {
        this.focusExternalTriggerSearch();
        this.showingOptionsList = true;
        this.handleDocEvents();
        this.handleShowClearIcon();
      });
    });
  }

  hide() {
    this._destroyDropdown();
    this._resetLabel();

    this.showingOptionsList = false;
    this.handleShowClearIcon();

    if (!this.multi && (this.useAsText || this.useSearchTextToAddNewOption) && this.searchString) {
      const option = this.getOptionFromSearchString();

      if (option != null) {
        this.updateCurrentValue(option.value);
        this.propagateChange(this.currentValue);
      } else {
        this.updateCurrentValue(this.searchString);

        if (this.useSearchTextToAddNewOption) {
          this.callCreateNewOption();
          return;
        }

        this.propagateChange(this.currentValue);
      }
    }

    if (this.multi) {
      this.clearIp();
    } else {
      this.updateIpValue();
    }
    this.clearSearch();
    this.clearExternalSearchIp();
    this.blurIp();

    this.unhandleDocEvents();
    this.onTouched();
    this.onHide.emit(this.currentValue);

    if (this.externalTrigger && !this.externalTrigger.openedBy) {
      this.externalTrigger.focus();
    }

    this.externalTrigger = null;
    this.helpTextCurrentOption = null;
  }

  isNewField() {
    if (this.searchString === undefined || this.searchString === '') {
      return false;
    }

    return this.getOptionFromSearchString() == null;
  }

  getOptionFromSearchString(): AutoCompleteOption {
    const trimmedSearchString = this.searchString.replace(/ /g, '');
    let ref = null;

    this.filteredOptionGroups.some((group) => {
      return group.children.some((option) => {
        const optionName = this.getValueFromAccessor(option.value);
        if (String(optionName) === this.searchString || String(optionName) === trimmedSearchString) {
          ref = option;
          return true;
        }
        return false;
      });
    });

    return ref;
  }

  getHelpTextState() {
    if (this.useAsText && !this.showAddNewOptionAtBottom && this.searchString && !this.filteredOptionGroups.length) {
      return HelpTextState.CUSTOM_VALUE;
    }

    if (this.renderLoading) {
      return HelpTextState.LOADING;
    }

    if (this.searchString && !this.filteredOptionGroups.length) {
      return HelpTextState.NO_MATCH;
    }

    if (!this.searchString && !this.filteredOptionGroups.length) {
      return HelpTextState.NO_OPTIONS;
    }

    return HelpTextState.FOUND;
  }

  private _destroyDropdown(): void {
    if (this._overlayRef && this.showingOptionsList) {
      this._overlayRef.detach();
    }
  }

  private _createOverlayAndAttach() {
    if (!this._overlayRef) {
      const overlayConfig = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(overlayConfig);

      this._dropdownPortal = new TemplatePortal(
        this.selectionContainerTemplateRef,
        this._viewContainerRef
      );
    }

    this._overlayRef.attach(this._dropdownPortal);
  }


  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
  }

  private _getPositionStrategy(): FlexibleConnectedPositionStrategy {
    const originY: VerticalConnectionPos = 'bottom';
    const overlayY: VerticalConnectionPos = 'top';
    const originX: HorizontalConnectionPos = 'start';
    const originFallbackX: HorizontalConnectionPos = 'end';

    const positions: ConnectedPosition[] = [
      { originX: originX, originY: originY, overlayX: originX, overlayY: overlayY },
      { originX: originFallbackX, originY: originY, overlayX: originFallbackX, overlayY: overlayY },
      { originX: originX, originY: overlayY, overlayX: originX, overlayY: originY },
      { originX: originFallbackX, originY: overlayY, overlayX: originFallbackX, overlayY: originY }
    ];


    const strategy = this._overlay.position()
      .flexibleConnectedTo(this._getConnectedElement())
      .withPositions(positions);

    const scrollableAncestors = this._scrollDispatcher
      .getAncestorScrollContainers(this._el);

    strategy.withScrollableContainers(scrollableAncestors);

    strategy.positionChanges.subscribe(change => {
      if (change.scrollableViewProperties.isOverlayClipped
        && change.scrollableViewProperties.isOriginClipped
        && this.showingOptionsList) {
        this._zone.run(() => {
          this.hide();
        });
      }
    });

    return strategy;
  }

  private _getConnectedElement(): ElementRef {
    if (this.externalTrigger) {
      return this.externalTrigger.getConnectedElement();
    }

    return this._formField ? this._formField.getConnectedOverlayOrigin() : this._el;
  }

  monitorFocus() {
    this._fm.monitor(this._el.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  /**
   * In "auto" mode, the label will animate down as soon as focus is lost.
   * This causes the value to jump when selecting an option with the mouse.
   * This method manually floats the label until the panel can be closed.
   * @param shouldAnimate Whether the label should be animated when it is floated.
   */
  private _floatLabel(shouldAnimate = false): void {
    if (this._formField && this._formField.floatLabel === 'auto') {
      if (shouldAnimate) {
        this._formField._animateAndLockLabel();
      } else {
        this._formField.floatLabel = 'always';
      }

      this._manuallyFloatingLabel = true;
    }
  }

  /** If the label has been manually elevated, return it to its normal state. */
  private _resetLabel(): void {
    if (this._manuallyFloatingLabel) {
      this._formField.floatLabel = 'auto';
      this._manuallyFloatingLabel = false;
    }
  }

  /**
   * Updates Rendered Option Groups for Virtual Scrolling.
   */
  onListRotation(dir: ListRotateDir) {
    if (!this.useVirtualScroll) {
      return;
    }

    if (dir === ListRotateDir.TOP_TO_BOTTOM) {
      this.reverseRendering = true;
      this.updateRenderedOptions(true, true);
    } else {
      this.reverseRendering = false;
      this.updateRenderedOptions(false, true);
    }
  }

  onEdgeScroll(dir: EdgeDir) {
    if (!this.useVirtualScroll) {
      return;
    }

    if (dir === 'bottom' && !this.reverseRendering) {
      this.updateRenderedOptions(false);
    }

    if (dir === 'top' && this.reverseRendering) {
      this.updateRenderedOptions(true);
    }
  }

  updateRenderedOptions(reverse = false, reset = false) {
    if (!this.filteredOptionGroups.length) {
      this.displayedOptionGroups = [];
      return;
    }

    if (reset) {
      this.displayedOptionGroups = [];
    }

    const iterableGroups = reverse ? [ ...this.filteredOptionGroups ].reverse() : [ ...this.filteredOptionGroups ];

    let remainingItems = this.pageSize;
    let currentIterableGroupIndex = Math.max(this.displayedOptionGroups.length - 1, 0);
    let currentIterationUsedItemsCount = 0;

    if (this.displayedOptionGroups.length > 0) {
      currentIterationUsedItemsCount = this.displayedOptionGroups[reverse ? 0 : this.displayedOptionGroups.length - 1].children.length;
    }

    iterableGroups.slice(currentIterableGroupIndex, this.filteredOptionGroups.length).some((group) => {
      const availableGroupItems = group.children.length - currentIterationUsedItemsCount;

      if (availableGroupItems >= remainingItems) {
        currentIterationUsedItemsCount = currentIterationUsedItemsCount + remainingItems;
        return true;
      }

      remainingItems = remainingItems - availableGroupItems;
      currentIterationUsedItemsCount = 0;
      currentIterableGroupIndex++;
    });

    if (currentIterableGroupIndex >= iterableGroups.length) {
      currentIterationUsedItemsCount = iterableGroups[iterableGroups.length - 1].children.length;
      currentIterableGroupIndex = iterableGroups.length - 1;
    }

    const groupRangeStart = reverse ? this.filteredOptionGroups.length - currentIterableGroupIndex - 1 : 0;
    const groupRangeEnd = reverse ? this.filteredOptionGroups.length : currentIterableGroupIndex + 1;

    this.displayedOptionGroups = this.filteredOptionGroups.slice(groupRangeStart, groupRangeEnd);

    if (reverse) {
      const firstGroup = { ...this.displayedOptionGroups[0] };
      firstGroup.children = firstGroup.children.slice(
        firstGroup.children.length - currentIterationUsedItemsCount,
        firstGroup.children.length
      );
      this.displayedOptionGroups[0] = firstGroup;
    } else {
      const lastGroup = { ...this.displayedOptionGroups[this.displayedOptionGroups.length - 1] };
      lastGroup.children = lastGroup.children.slice(0, currentIterationUsedItemsCount);
      this.displayedOptionGroups[this.displayedOptionGroups.length - 1] = lastGroup;
    }
  }

  calculateLastItem() {
    if (!this.filteredOptionGroups.length || !this.filteredOptionGroups[0].children.length) {
      return ;
    }
    this.lastItem = this.getValueFromAccessor(this.filteredOptionGroups[this.filteredOptionGroups.length - 1]
      .children[this.filteredOptionGroups[this.filteredOptionGroups.length - 1].children.length - 1].value);
  }

  calculateFirstItem() {
    if (!this.filteredOptionGroups.length || !this.filteredOptionGroups[0].children.length) {
      return;
    }
    this.firstItem = this.getValueFromAccessor(this.filteredOptionGroups[0].children[0].value);
  }

  setMode() {
    if (this.inline) {
      this.mode = FlexibleAutoCompleteMode.INLINE;
      return;
    }

    if (this.hasExternalTrigger) {
      this.mode = FlexibleAutoCompleteMode.DROPDOWN_SEARCH;
      return;
    }

    this.mode = FlexibleAutoCompleteMode.INLINE_SEARCH;
  }

  groupIdTracker(index: number, group: AutoCompleteOptionGroup) {
    return typeof group.value === 'object' ? group.value[this.groupAccessor] : group.value;
  }

  optionIdTracker(index: number, option: AutoCompleteOption) {
    return this.getValueFromAccessor(option.value);
  }

  getPlaceholderDisplayText() {
    if (this.multi && this.multiPlaceholder) {
      return '';
    }

    if (this.useSearchTextToAddNewOption) {
      return `Enter the ${ this.addNewOptionAtBottomKeyword.toLowerCase() } name`;
    }

    return this.placeholder;
  }

  handleShowClearIcon() {
    if (this.multi || this.disabled) {
      this.canShowClearIcon = false;
      return;
    }

    if (
      ((this.showingOptionsList && coerceBooleanProperty(this.searchString || this.value))
        || (!this.showingOptionsList && coerceBooleanProperty(this.value))
        || this.useSearchTextToAddNewOption
      ) && this.showClearOption
    ) {
      this.canShowClearIcon = true;
      return;
    }

    this.canShowClearIcon = false;
  }

  onClearIconClick() {
    executeOnStable(this._zone, () => {
      if (this.value || this.searchString) {
        this.clearSearch();
        this.clearCurrentValue();
        this.filterOptions();
      }

      if (this.useSearchTextToAddNewOption) {
        this.useSearchTextToAddNewOption = false;
      }
    });
  }

  closeDropdownAndAddNewOption() {
    if (this.useSearchTextToCreateNewOption && this.searchString?.length) {
      this.callCreateNewOption();
      return;
    }

    this._destroyDropdown();
    this.unhandleDocEvents();
    this.focusIp();

    if (this.value) {
      this.clearCurrentValue();
    }

    this.showingOptionsList = false;
    this.useSearchTextToAddNewOption = true;
    this.handleShowClearIcon();
  }

  callCreateNewOption() {
    this.useSearchTextToAddNewOption = false;
    this.creatingNewOption = true;

    this.createNewOptionFn(this.searchString).pipe(
      take(1)
    ).subscribe((option) => {
      this.creatingNewOption = false;

      if (!option) {
        return;
      }

      const flexibleAutoCompleteOption = this.buildOption(option);
      this.optionGroups[0].children.push(flexibleAutoCompleteOption);

      this.onSelect(option);
      this.emitCreatedOption.next(option);

    }, () => {
      this.creatingNewOption = false;
      this.useSearchTextToAddNewOption = true;
    });
  }

  refreshOptionList() {
    this.emitRefreshOptionsRequest.next();
    this.refreshingOptionList = true;
    this._optionsData = [];
  }
}
