import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import HdFormControl from '../../UIElements/HdFormControl';
import HdFormikTextField from '../HdFormikTextField';
import { SSHAuthKey } from './SSHAuthKey';

export default function SSHConfigFields({
  sshIPHelperText
}: {
  sshIPHelperText?: React.ReactNode;
}) {
  const formikProps = useFormikContext<{
    useSSH: boolean;
  }>();

  return formikProps.values.useSSH ? (
    <div className='mt-7'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='sshIp'
              label='SSH IP'
              required
              component={HdFormikTextField}
              helperText={sshIPHelperText}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='sshPort'
              label='SSH Port'
              required
              component={HdFormikTextField}
              type='number'
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field name='sshUser' label='SSH User' required component={HdFormikTextField} />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <SSHAuthKey />
      </Grid>
    </div>
  ) : null;
}
