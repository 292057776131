import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { wrapIntoObservable } from '../hd-wizard/utils';
import { NodeConfigHelperService } from './node-config-helper.service';
import { NodeConfigData, NodeFormData } from './node-form-data';


@Injectable()
export abstract class NodeSettingsFormService<T extends NodeConfigHelperService, U extends { name: string }> {
  protected _staticFormMetaData: any;
  protected _staticDependenciesLoaded: boolean;

  constructor(
    protected _configHelperService: T
  ) {
  }

  getNodeFromExistingData$(nodeType: U, existingData: any, isUpdateMode = false, draftDestination?: any): Observable<NodeConfigData> {
    if (draftDestination?.id && draftDestination.dest_type === nodeType.name) {
      existingData = {
        ...existingData,
        ...draftDestination
      };
    }

    return this._resolveDependencies(nodeType, existingData).pipe(
      map((metaData) => {
        if (!existingData) {
          return {
            type: nodeType.name,
            metaData,
            formData: {
              ...this._getDefaultConfig(nodeType, metaData)
            }
          };
        }

        if (existingData.isSample) {
          return {
            type: nodeType.name,
            metaData,
            formData: {
              name: existingData.name,
              ...this._getConfigFromRawData(nodeType, existingData, metaData, isUpdateMode)
            }
          };
        }

        if (existingData.isInitialConfig) {
          return {
            type: nodeType.name,
            metaData,
            formData: {
              name: existingData.name,
              ...this._getDefaultConfig(nodeType, metaData),
              ...this._getConfigFromInitialData(nodeType, existingData, metaData, isUpdateMode)
            }
          };
        }

        if (existingData.id) {
          return {
            id: draftDestination && draftDestination.id ? null : existingData.id,
            type: nodeType.name,
            metaData,
            formData: {
              name: existingData.name,
              ...this._getConfigFromRawData(nodeType, existingData.config, metaData, isUpdateMode)
            }
          };
        }

        return {
          type: nodeType.name,
          metaData,
          formData: {
            ...this._getDefaultConfig(nodeType, metaData),
            ...existingData
          }
        };
      })
    );
  }

  protected abstract _getConfigFromRawData(nodeType: U, rawData: any, metaData: any, isUpdateMode: boolean): NodeFormData;

  protected abstract _getDefaultConfig(nodeType: U, metaData: any): NodeFormData;

  protected abstract _getConfigFromInitialData(nodeType: U, rawData: any, metaData: any, isUpdateMode: boolean): NodeFormData;

  protected _resolveDependencies(nodeType: U, existingData: any) {
    const configHelperInstance = this._getConfigHelperInstance(nodeType.name);

    const requests$ = [];

    const metaDataResolver = configHelperInstance.metaDataResolver;

    if (typeof metaDataResolver !== 'function') {
      requests$.push(of({}));
    } else {
      requests$.push(wrapIntoObservable(metaDataResolver.call(configHelperInstance, nodeType.name, existingData)));
    }

    const staticDependenciesResolver = configHelperInstance.staticDependenciesResolver;

    if (typeof staticDependenciesResolver !== 'function') {
      requests$.push(of({}));
    } else if (this._staticDependenciesLoaded) {
      requests$.push(of({ ...this._staticFormMetaData }));
    } else {
      requests$.push(wrapIntoObservable(staticDependenciesResolver.call(configHelperInstance, nodeType.name)));
    }

    return forkJoin(requests$).pipe(
      map(([ metaData, staticMetaData ]: any[]) => {
        this._staticFormMetaData = staticMetaData;
        this._staticDependenciesLoaded = true;

        return {
          ...metaData,
          ...staticMetaData
        };
      })
    );
  }

  protected abstract _getExistingNodes$?(nodeType: U): Observable<any[]>;

  protected _getConfigHelperInstance(nodeTypeIdentifier: string) {
    return this._configHelperService.getConfigHelper(nodeTypeIdentifier);
  }
}
