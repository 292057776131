import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/hd-button.module';
import { TrackingModule } from '../tracking/tracking.module';
import { IconModule } from '../icon/icon.module';
import { BannerOutletReactComponent } from './banner-outlet-react.component';

const DIRECTIVES = [
  BannerOutletReactComponent
];

@NgModule({
  declarations: [ DIRECTIVES ],
  exports: DIRECTIVES,
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    TrackingModule
  ]
})
export class BannerModule { }
