import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import {
  getSelectorInclusionStateFromRawData,
  getSelectorRawDataFromInclusionState
} from '../../../../../../app/shared/single-level-selector/model';

export class LinkedinAdsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    const config: any = {
      selectedAccounts: getSelectorInclusionStateFromRawData(rawData)
    };

    return config;
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      ...getSelectorRawDataFromInclusionState(data.selectedAccounts)
    };
  }
}
