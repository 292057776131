export interface AggregationTimeRawData {
  value: number;
  unit: string;
}

export interface AggregationTime extends AggregationTimeRawData {
  displayName: string;
  value: number;
  unit: string;
  valueWithUnit: string;
}

export interface AggregationLevel {
  displayName: string;
  value: string;
}

export interface FBBreakdown {
  name: string;
  group: string;
  value: string;
  tooltip: string;
  unsupported: string[];
}

export interface FBField {
  name: string;
  group: string;
  value: string;
  tooltip: string;
}

export interface FBBreakdownOption extends Omit<FBBreakdown, 'unsupported'> {
  disabled?: boolean;
}

export declare interface FbAdsCustomReportListField {
  entities: Array<{ key: string }>;
}

export enum FBABreakdownGroups {
  DYNAMIC_CREATIVE_ASSET = 'Dynamic creative asset',
  DELIVERY = 'Delivery',
  ACTION = 'Action'
}
