import {
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../../app/core/constants';
import { NetworkRequestOptions } from '../../app/core/models/request';
import { AppConfig } from '../../app/core/app.config';
import { courier } from '../../app/core/service/courier.service';

const appConfig = new AppConfig();

const AuthAPI = {
  multiRegionURL: `${appConfig.getSecurityServiceURL()}/multi-region`,

  getRegionSwitchingToken(targetClusterId: string) {
    const requestUrl = `${this.multiRegionURL}/session`;

    const body = {
      target_cluster_id: targetClusterId
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showLoading: true
      }
    };

    return courier.post(requestUrl, options, body);
  }
};

export default AuthAPI;
