import React from 'react';
import { useFormikContext } from 'formik';
import { Typography } from '@mui/material';
import useAnalyticsTracker from '../../../../../../hooks/useAnalyticsTracker';
import { HdRadio, HdRadioGroup } from '../../../../../../components/UIElements';
import { SnowflakeAuthenticationType } from '../constants';
import { SnowflakeDestinationFormikProps } from '..';
import { SnowflakeDestinationTrackingActions } from '../tracking';

export function ConnectionSettingsRadioGroup({
  field,
  form,
  isEditing,
  destinationTypeIdentifier
}) {
  const analyticsTracker = useAnalyticsTracker();
  const formikProps = useFormikContext<SnowflakeDestinationFormikProps>();

  const connectionSettingsModeChange = authType => {
    analyticsTracker.eventTrack({
      action: SnowflakeDestinationTrackingActions.CONNECTION_SETTINGS_MODE_CHANGE,
      properties: {
        selectedOption:
          authType === SnowflakeAuthenticationType.KEY_PAIR
            ? 'connected_using_key_pair'
            : 'connected_using_access_credentials',
        destinationType: destinationTypeIdentifier
      }
    });

    const values = {
      ...form.values,
      authType
    };

    form.setValues(values);
  };

  return (
    <HdRadioGroup
      {...field}
      name='isConnectionUri'
      onChange={connectionSettingsModeChange}
      className='mb-5'
    >
      <HdRadio
        key='key-pair'
        value={SnowflakeAuthenticationType.KEY_PAIR}
        disabled={isEditing}
        dataId='connection-settings-key-pair'
        checked={formikProps.values.authType === SnowflakeAuthenticationType.KEY_PAIR}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Connect using Key Pair Authentication</Typography>
        </div>
      </HdRadio>

      <HdRadio
        key='access-credentials'
        value={SnowflakeAuthenticationType.ACCESS_CREDENTIALS}
        className='ml-5'
        disabled={isEditing}
        dataId='connection-settings-access-credentials'
        checked={formikProps.values.authType === SnowflakeAuthenticationType.ACCESS_CREDENTIALS}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Connect using Access Credentials</Typography>
        </div>
      </HdRadio>
    </HdRadioGroup>
  );
}
