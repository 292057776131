import clsx from 'clsx';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { DestinationMode } from '../../../../../../app/core/models/destination-mode';
import { TRACKER_DESTINATION_AZURE_SYNAPSE_HOST_NAME_BLUR } from '../../../../../../app/nodes/tracking';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import {
  HdFormikPasswordField,
  HdFormikTextField
} from '../../../../../components/FormikElements';
import SSHConfig from '../../../../../components/FormikElements/SSHConfig';
import SSHConfigFields from '../../../../../components/FormikElements/SSHConfig/SSHConfigFields';
import { SSHConfigHelpBanner } from '../../../../../components/FormikElements/SSHConfig/SSHConfigHelpBanner';
import { HdLink } from '../../../../../components/UIElements';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import useLocalHostTracker from '../../../../../hooks/useLocalHostTracker';
import { ConfigDestinationTypeBaseProps } from '../interface';
import styles from './styles.module.scss';

// eslint-disable-next-line import/no-cycle
import { ConnectionSettingsRadioGroup, getConnectionParamsFromURI } from './utils';
import { LoadedAt, SanitizeName } from '../../../../../components/NodeConfigOptions';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';

const SYNAPSE_JSBC_CONNECTION_STRING =
  'https://learn.microsoft.com/en-us/azure/synapse-analytics/sql/connection-strings?source=recommendations#sample-jdbc-connection-string';

const dataIdGenerator = getDataIdGenerator('azure-synapse');

export default function AzureSynapse({
  hdLocalHostValidatorDisabled,
  maskSecretFields,
  destinationTypeIdentifier,
  destinationMode,
  hevoEntityFor,
  onInviteMember,
  rawDestinationType
}: ConfigDestinationTypeBaseProps) {
  const analyticsTracker = useAnalyticsTracker();

  const formikProps = useFormikContext<{
    host: string;
    isConnectionUri: string;
    URI: string;
    port: string;
    user: string;
    databaseName: string;
    useSSH: boolean;
  }>();

  useLocalHostTracker({
    value: formikProps.values.host,
    hdLocalHostValidatorDisabled,
    localHostTrackerLabel: 'db-host-field-local-host-steps',
    localHostTrackerSection: 'destination_settings',
    localHostTrackingParams: {
      destinationType: destinationTypeIdentifier,
      context: hevoEntityFor.toString()
    }
  });

  const onHostNameBlur = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_DESTINATION_AZURE_SYNAPSE_HOST_NAME_BLUR,
      properties: {
        hostName: formikProps.values.host,
        destinationType: destinationTypeIdentifier
      }
    });
  };

  const isAdvancedConfigVisible = !(destinationMode === DestinationMode.WAREHOUSE);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div
            className={clsx('collapsible-group-box collapsible-group pb-0', isAdvancedConfigVisible ? 'mb-7' : 'mb-5')}
          >
            <HdFormControl>
              <Field
                name='isConnectionUri'
                label='Connection Settings'
                component={ConnectionSettingsRadioGroup}
                destinationTypeIdentifier={destinationTypeIdentifier}
              />
            </HdFormControl>
            {formikProps.values.isConnectionUri === 'true' ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <HdFormControl>
                    <Field
                      name='URI'
                      label='Connection String'
                      placeholder='jdbc:sqlserver://yourserver.sql.azuresynapse.net:1433;...'
                      required
                      component={HdFormikTextField}
                      helperText={
                        <Typography variant='caption'>
                          The connection string retrieved from Connection strings → JDBC → JDBC (
                          <i>authentication</i>){' '}
                          <HdLink
                            href={SYNAPSE_JSBC_CONNECTION_STRING}
                            target='_blank'
                            size='sm'
                            icon='new-window'
                            dataId={dataIdGenerator('help-documentation-conection-string')}
                          >
                            Learn More
                          </HdLink>
                        </Typography>
                      }
                      onChange={e => {
                        const connectionString = e.target.value;
                        const formData: {
                          host: string;
                          databaseName: string;
                          user: string;
                          port?: string;
                        } = {
                          host: '',
                          databaseName: '',
                          user: ''
                        };
                        const credentialObj = getConnectionParamsFromURI(connectionString);
                        if (!credentialObj.error) {
                          const { host, port, databaseName, user } = credentialObj;
                          formData.host = host || '';
                          formData.port = port || '';
                          formData.databaseName = databaseName || '';
                          formData.user = user || '';
                        }
                        formikProps.setValues({
                          ...formikProps.values,
                          URI: connectionString,
                          ...formData
                        });
                      }}
                    />
                  </HdFormControl>
                </Grid>
              </Grid>
            ) : null}

            {formikProps.values.URI?.length &&
            !formikProps.errors.URI &&
            formikProps.values.isConnectionUri === 'true' ? (
              <div className='info-box__secondary mb-5'>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container className='mb-4'>
                      <Grid item xs={2} md={2} className={styles.heading}>
                        Server Name
                      </Grid>

                      <Grid item xs={8} md={5} className={`text-ellipsis pr-1 ${styles.content}`}>
                        {formikProps.values.host}
                      </Grid>
                      <Grid item xs={4} md={2} className={styles.heading}>
                        Port
                      </Grid>

                      <Grid item xs={8} md={3} className={`text-ellipsis pr-1 ${styles.content}`}>
                        {formikProps.values.port}
                      </Grid>
                    </Grid>

                    <Grid container className='mb-4'>
                      <Grid item xs={4} md={2} className={styles.heading}>
                        Username
                      </Grid>

                      <Grid item xs={8} md={5} className={`text-ellipsis pr-1 ${styles.content}`}>
                        {formikProps.values.user}
                      </Grid>

                      <Grid item xs={4} md={2} className={styles.heading}>
                        Database Name
                      </Grid>

                      <Grid item xs={8} md={3} className={`text-ellipsis pr-1 ${styles.content}`}>
                        {formikProps.values.databaseName}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : null}

            {formikProps.values.isConnectionUri === 'false' ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='host'
                      label='Server Name'
                      placeholder='yourserver.sql.azuresynapse.net'
                      helperText={
                        <Typography variant='caption'>
                          The name of your Azure Synapse Analytics instance.{' '}
                          <HdLink
                            href={SYNAPSE_JSBC_CONNECTION_STRING}
                            target='_blank'
                            size='sm'
                            icon='new-window'
                            dataId={dataIdGenerator('help-documentation-servername')}
                          >
                            Learn More
                          </HdLink>
                        </Typography>
                      }
                      required
                      component={HdFormikTextField}
                      onBlur={onHostNameBlur}
                    />
                  </HdFormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='port'
                      label='Database Port'
                      helperText='The port on which the server is accepting connections'
                      required
                      component={HdFormikTextField}
                      type='number'
                    />
                  </HdFormControl>
                </Grid>
              </Grid>
            ) : null}

            <Grid container spacing={2}>
              {formikProps.values.isConnectionUri === 'false' ? (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field name='user' label='Username' required component={HdFormikTextField} />
                  </HdFormControl>
                </Grid>
              ) : null}

              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='password'
                    label='Password'
                    required
                    masked={maskSecretFields}
                    component={HdFormikPasswordField}
                  />
                </HdFormControl>
              </Grid>

              {formikProps.values.isConnectionUri !== 'false' ? (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field name='schemaName' label='Schema Name' component={HdFormikTextField} />
                  </HdFormControl>
                </Grid>
              ) : null}
            </Grid>

            <Grid container spacing={2}>
              {formikProps.values.isConnectionUri === 'false' ? (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='databaseName'
                      label='Database Name'
                      required
                      component={HdFormikTextField}
                      helperText='The name of the dedicated SQL pool retrieved from Azure Synapse Analytics → yourworkspace → SQL pools'
                    />
                  </HdFormControl>
                </Grid>
              ) : null}

              {formikProps.values.isConnectionUri === 'false' ? (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field name='schemaName' label='Schema Name' component={HdFormikTextField} />
                  </HdFormControl>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </Grid>
      </Grid>

      {isAdvancedConfigVisible ? (
        <AdvancedConfig forceExpand={formikProps.isSubmitting} showBorderBottom>
          <SSHConfig className='mb-7' entityTypeIdentifier='Azure Synapse'>
            {formikProps.values.useSSH ? (
              <SSHConfigHelpBanner
                destinationTypeDisplayName={rawDestinationType.dest_type_display}
                defaultExpanded
                onInviteMember={onInviteMember}
              />
            ) : null}

            <SSHConfigFields />
          </SSHConfig>

          <LoadedAt />

          <SanitizeName
            dataId={dataIdGenerator('help-documentation-name-sanitization')}
          />
        </AdvancedConfig>
      ) : null}
    </>
  );
}
