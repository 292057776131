import { ListKeyManager } from '@angular/cdk/a11y';
import { DOWN_ARROW, ENTER, UP_ARROW } from '@angular/cdk/keycodes';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ListRotateDir } from '../../ip/flexible-auto-complete/models';
import { EdgeDir, HdScrollViewportComponent } from '../../scrolling/scroll-viewport/scroll-viewport.component';
import { NestedKeyboardNavigationItem } from './nested-keyboard-navigation-item';

@Component({
  selector: '[nestedKeyboardNavigation]',
  template: `<ng-content></ng-content>`
})
export class NestedKeyboardNavigationComponent implements AfterContentInit, OnDestroy, OnChanges {
  @Output() listRotated = new EventEmitter<ListRotateDir>();
  @Input() withVirtualScroll = false;
  @Input() firstItem: any;
  @Input() lastItem: any;
  @Input() accessor: any;
  @Input() hasSelectAll = false;

  private _keyManager: ListKeyManager<NestedKeyboardNavigationItem>;
  private _itemChangesSub: Subscription = Subscription.EMPTY;
  private _refreshList = false;
  private _lastFocusedItem;
  private _topListItem;
  private _bottomListItem;

  rotationFlag: ListRotateDir = ListRotateDir.BOTTOM_TO_TOP;

  @ContentChild('scrollViewport') scrollViewport: HdScrollViewportComponent;
  @ContentChildren(NestedKeyboardNavigationItem, { descendants: true }) items: QueryList<NestedKeyboardNavigationItem> = new QueryList<NestedKeyboardNavigationItem>();

  constructor(
    public el: ElementRef,
    private _zone: NgZone
  ) {
  }

  ngOnChanges(changes) {
    if (changes.withVirtualScroll && this._keyManager) {
      this._keyManager.withWrap(!this.withVirtualScroll);
    }
  }

  ngAfterContentInit() {
    this._keyManager = new ListKeyManager<NestedKeyboardNavigationItem>(this.items).withWrap(!this.withVirtualScroll);

    this._topListItem = this.getTopListItem();
    this._bottomListItem = this.getBottomListItem();

    this._itemChangesSub = this.items.changes.pipe(
      switchMap(() => {
        return timer(0).pipe(
          tap(() => {
            if (this._lastFocusedItem) {
              const lastFocusedItemIndex = this.getItemIndex(this._lastFocusedItem);

              if (lastFocusedItemIndex >= 0) {
                this._keyManager.setActiveItem(lastFocusedItemIndex);
                this.refreshFocusCursor();
              }
            }

            if (this._refreshList) {
              if (this.rotationFlag === ListRotateDir.BOTTOM_TO_TOP) {
                this.focusFirstItem();
              } else {
                this.focusLastItem();
              }
            } else {
              if (this._topListItem !== this.getTopListItem()) {
                this.updateBodyScrollPosition(true, this._topListItem, 'top');
              } else if (this._bottomListItem !== this.getBottomListItem()) {
                this.updateBodyScrollPosition(true, this._bottomListItem, 'bottom');
              }
            }

            this._topListItem = this.getTopListItem();
            this._bottomListItem = this.getBottomListItem();
          })
        );
      })
    ).subscribe();
  }

  listKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case ENTER: {
        this.onEnterKeyPress(event);
        event.preventDefault();
        break;
      }
      case UP_ARROW: {
        this.focusPreviousItem();
        event.preventDefault();
        break;
      }
      case DOWN_ARROW: {
        this.focusNextItem();
        event.preventDefault();
        break;
      }
    }
  }

  unFocusAllOptions() {
    this.items.forEach((item: NestedKeyboardNavigationItem) => {
      item.onUnFocus();
    });
  }

  focusNextItem() {
    if (!this._keyManager) {
      return;
    }

    if (this.firstItem && this.lastItem) {
      if (this._lastFocusedItem === this.lastItem) {
        this._refreshList = true;
        this.rotationFlag = ListRotateDir.BOTTOM_TO_TOP;
        this.listRotated.emit(this.rotationFlag);
      }
    }


    if (this.canSkipToRenderSelectAll('down')) {
      this._keyManager.setActiveItem(0);
    } else if (this.canSkipToNextParentItem()) {
      this.skipToNextParentItem();
    } else {
      this._keyManager.setNextItemActive();
    }

    this.refreshFocusCursor();
    this.updateBodyScrollPosition();

    this._lastFocusedItem = this.getActiveItem();
  }

  focusPreviousItem() {
    if (!this._keyManager) {
      return;
    }

    if (this.firstItem && this.lastItem) {
      if ((!this.getActiveItem() && (this.getActiveItemIndex() === -1 || this.hasSelectAll))
        || (!this.hasSelectAll && this._lastFocusedItem === this.firstItem)) {
        this._refreshList = true;
        this.rotationFlag = ListRotateDir.TOP_TO_BOTTOM;
        this.listRotated.emit(this.rotationFlag);
      }
    }


    if (this.canSkipToRenderSelectAll('up')) {
      this._keyManager.setActiveItem(0);
    } else if (this.canSkipToPreviousParentItem()) {
      this.skipToPreviousParentItem();
    } else {
      this._keyManager.setPreviousItemActive();
    }

    this.refreshFocusCursor();
    this.updateBodyScrollPosition();

    this._lastFocusedItem = this.getActiveItem();
  }

  focusFirstItem(): void {
    this._keyManager.setFirstItemActive();
    this.refreshFocusCursor();
    this.updateBodyScrollPosition();
    this._refreshList = false;

    this._lastFocusedItem = this.getActiveItem();
  }

  focusLastItem(): void {
    this._keyManager.setLastItemActive();
    this.refreshFocusCursor();
    this.updateBodyScrollPosition();
    this._refreshList = false;

    this._lastFocusedItem = this.getActiveItem();
  }

  refreshFocusCursor() {
    this.unFocusAllOptions();
    if (this._keyManager.activeItem) {
      this._keyManager.activeItem.onFocus();
    }
  }

  onEnterKeyPress(e: KeyboardEvent) {
    if (this._keyManager.activeItem) {
      this._keyManager.activeItem.onEnter(e);
    }
  }

  canSkipToNextParentItem() {
    if (!this._keyManager || !this._keyManager.activeItem) {
      return false;
    }

    return this._keyManager.activeItem.isParent() && !this._keyManager.activeItem.isExpanded();
  }

  canSkipToPreviousParentItem() {
    if (!this._keyManager) {
      return false;
    }

    // no focused item and first keystroke is up, focus last parent item if not expanded
    if (
      !this._keyManager.activeItem &&
      this._getLastParentItemIndex() !== -1 &&
      this.items.get(this._getLastParentItemIndex()).isParent() &&
      !this.items.get(this._getLastParentItemIndex()).isExpanded()
    ) {
      return true;
    }

    // select all is focused and keystroke is up, focus last parent item if not expanded
    if (
      this.hasSelectAll &&
      this._keyManager.activeItemIndex === 0 &&
      this._getLastParentItemIndex() !== -1 &&
      this.items.get(this._getLastParentItemIndex()).isParent() &&
      !this.items.get(this._getLastParentItemIndex()).isExpanded()
    ) {
      return true;
    }

    const parentItemIndices = this._getParentItemIndices();

    const itemIndex = parentItemIndices.findIndex(index => index === this._keyManager.activeItemIndex);

    if (itemIndex >= 0) {
      const previousParentItemIndex = parentItemIndices[(itemIndex - 1 + parentItemIndices.length) % parentItemIndices.length];
      const previousParentItem = this.items.get(previousParentItemIndex);

      return !previousParentItem.isExpanded();
    }

    return false;
  }

  canSkipToRenderSelectAll(direction: 'up' | 'down') {
    if (!this._keyManager || !this._keyManager.activeItem) {
      return false;
    }

    // find if current active element is last element in list
    if (!this.hasSelectAll) {
      return false;
    }

    // last parent item is collapsed, move to select all at top
    if (
      direction === 'down' &&
      this._keyManager.activeItem.isParent() &&
      !this._keyManager.activeItem.isExpanded() &&
      this._getLastParentItemIndex() === this._keyManager.activeItemIndex
    ) {
      return true;
    }

    // first parent item is focused and up key is hit
    if(
      direction === 'up' &&
      this._keyManager.activeItem.isParent() &&
      this._keyManager.activeItemIndex === 1
    ) {
      return true;
    }

    return false;
  }

  private _getLastParentItemIndex() {
    const parentItemIndices = this._getParentItemIndices();
    if (parentItemIndices.length) {
      return parentItemIndices[parentItemIndices.length - 1];
    }

    return -1;
  }

  skipToNextParentItem() {
    if (!this._keyManager || !this._keyManager.activeItem || !this.items || !this.items.length) {
      return;
    }

    const parentItemIndices = this._getParentItemIndices();

    const activeParentIndex = parentItemIndices.findIndex((index) => index === this._keyManager.activeItemIndex);

    if (activeParentIndex >= 0) {
      const nextParentIndex = parentItemIndices[(activeParentIndex + 1) % parentItemIndices.length];
      this._keyManager.setActiveItem(nextParentIndex);
    }
  }

  skipToPreviousParentItem() {
    if (!this._keyManager || !this.items || !this.items.length) {
      return;
    }

    // user hits key up and currently no focused item. Focus last parent item
    if (!this._keyManager.activeItem && this._getLastParentItemIndex() != -1) {
      this._keyManager.setActiveItem(this._getLastParentItemIndex());
      return;
    }

    // has select all and it is focused, user hits keyup, focus last parent item
    if (
      this.hasSelectAll &&
      this._keyManager.activeItemIndex === 0 &&
      !this._keyManager.activeItem.isParent() &&
      this._getLastParentItemIndex() != -1
    ) {
      this._keyManager.setActiveItem(this._getLastParentItemIndex());
      return;
    }

    const parentItemIndices = this._getParentItemIndices();

    const itemIndex = parentItemIndices.findIndex(index => index === this._keyManager.activeItemIndex);

    if (itemIndex >= 0) {
      const previousParentItemIndex = parentItemIndices[(itemIndex - 1 + parentItemIndices.length) % parentItemIndices.length];
      this._keyManager.setActiveItem(previousParentItemIndex);
    }
  }

  private _getParentItemIndices() {
    const parentIndices = [];

    this.items.toArray().filter((item, index) => {
      if (item.isParent()) {
        parentIndices.push(index);
      }
    });

    return parentIndices.sort((a, b) => a - b);
  }

  getItemIndex(item: any) {
    return this.items.toArray().findIndex((navItem) => {
      if (this.getValueFromAccessor(navItem.value) === item) {
        return true;
      }
    });
  }

  getTopListItem() {
    if (!this.items || !this.items.length) {
      return;
    }

    return this.hasSelectAll
      ? this.getValueFromAccessor(this.items.toArray()[1].value)
      : this.getValueFromAccessor(this.items.first.value);
  }

  getBottomListItem() {
    if (!this.items || !this.items.length) {
      return;
    }

    return this.getValueFromAccessor(this.items.last.value);
  }

  getActiveItem() {
    if (!this._keyManager || !this._keyManager.activeItem) {
      return;
    }

    return this.getValueFromAccessor(this._keyManager.activeItem.value);
  }

  updateBodyScrollPosition(refresh = false, item?: any, dir?: EdgeDir) {
    if (!refresh && this.getActiveItemIndex() === 0) {
      this.scrollViewport.scrollTop(0);
      return;
    }

    const top = refresh ? this.getItemTop(item) : this.getActiveElementTop();
    const bottom = top + (refresh ? this.getItemHeight(item) : this.getActiveElementHeight());

    const {
      scrollTop: viewportScrollTop,
      offsetHeight: viewportHeight
    } = this.scrollViewport.elementRef.nativeElement;

    if ((refresh && dir === 'bottom') ||
      (!refresh && bottom > viewportScrollTop + viewportHeight)) {
      this.scrollViewport.scrollTop(bottom - viewportHeight);
      return;
    }

    if ((refresh && dir === 'top') || (!refresh && top < viewportScrollTop)) {
      this.scrollViewport.scrollTop(top);
      return;
    }
  }

  getActiveItemIndex() {
    if (!this._keyManager) {
      return -1;
    }

    return this._keyManager.activeItemIndex;
  }

  getActiveElementTop() {
    if (!this._keyManager || !this._keyManager.activeItem) {
      return 0;
    }

    return this._keyManager.activeItem.getElementTop();
  }

  getActiveElementHeight() {
    if (!this._keyManager || !this._keyManager.activeItem) {
      return 0;
    }

    return this._keyManager.activeItem.getHeight();
  }

  getItemTop(item: any) {
    if (!this.items || !this.items.length) {
      return 0;
    }

    const itemIndex = this.getItemIndex(item);
    if (itemIndex >= 0) {
      return this.items.toArray()[itemIndex].getElementTop();
    } else {
      return 0;
    }
  }

  getItemHeight(item: any) {
    if (!this.items || !this.items.length) {
      return 0;
    }

    const itemIndex = this.getItemIndex(item);
    if (itemIndex >= 0) {
      return this.items.toArray()[itemIndex].getHeight();
    } else {
      return 0;
    }
  }

  getValueFromAccessor(data: any) {
    if (typeof data === 'object' && data !== null && this.accessor) {
      return data[this.accessor];
    }

    return data;
  }

  ngOnDestroy() {
    this._itemChangesSub.unsubscribe();
  }
}
