export enum TaskActionEnum {
  CONFIGURE = 5,  // Task specific configurations
  PAUSE = 10,
  RESUME = 11,
  SKIP = 20,
  INCLUDE = 21,
  RESTART = 30, // RESTART and RESET are the same thing. They have been created differently to be able to have different names
  RESET = 31,
  RUN_NOW = 40,
  EDIT_CONFIG = 50, // Job Settings/Mode
  EDIT_OFFSET = 53
}
