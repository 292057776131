import { Destination } from '../../../../app/destination/models';
import { DestinationLoadWebhook } from './interface';
import { webhookStatusLookUp } from './model';

export const PostLoadWebhookFactory = (
  data,
  destinations: Destination[]
): DestinationLoadWebhook[] => {
  const destinationMap = destinations.reduce(
    (dict, destination) => ({
      ...dict,
      [destination.id]: destination
    }),
    {}
  );

  return data
    .filter(webhook => !!destinationMap[webhook.destination_id])
    .map(webhook => ({
      seqId: webhook.seq_id,
      id: webhook.id,
      name: webhook.name,
      destination: destinationMap[webhook.destination_id],
      status: webhookStatusLookUp[webhook.status],
      lineage: webhook.integrations_for_webhook,
      webhookUrl: webhook.webhook_url
    }));
};
