import { SchemaFieldFactory } from '../../destination/utils';
import { MappingStatus } from '../../mapping-box/model';
import { MappingRowWithExtraInfo } from '../models/activation';


export function extractColumnFromGoogleSheetsNotation(range: string) {
  return range.replace(/[^a-z]/gi, '');
}

export function extractRowFromGoogleSheetsNotation(range: string) {
  return Number(range.replace(/[^0-9]/gi, ''));
}

export function convertGoogleSheetsColumnTitleToNumber(column: string) {
  const charCodeOfA = 'A'.charCodeAt(0);
  let columnNumber = 0;

  column.split('').forEach((character) => {
    columnNumber *= 26;
    columnNumber += (character.charCodeAt(0) - charCodeOfA + 1);
  });

  return columnNumber;
}

export function convertGoogleSheetsColumnNumberToTitle(column: number) {
  const charCodeOfA = 'A'.charCodeAt(0);
  const columnTitle = [];

  while ( column > 0 ) {
    const rem = column % 26;

    if (rem === 0) {
      columnTitle.push('Z');
      column = Math.floor(column / 26) - 1;
    } else {
      columnTitle.push(String.fromCharCode((rem - 1) + charCodeOfA));
      column = Math.floor(column / 26);
    }
  }

  return columnTitle.reverse().join('');
}

export function checkIfSheetsRangeIsSmaller(start, end, lengthToCheck) {
  const startColumnTitle = extractColumnFromGoogleSheetsNotation(start),
    endColumnTitle = extractColumnFromGoogleSheetsNotation(end);

  const startColumnNumber = convertGoogleSheetsColumnTitleToNumber(startColumnTitle),
    endColumnNumber = convertGoogleSheetsColumnTitleToNumber(endColumnTitle);

  const rangeLength = endColumnNumber - startColumnNumber + 1;
  return rangeLength < lengthToCheck;
}

export function checkIfStartRangeIsGreaterThanEndRange(start, end) {
  const startColumnTitle = extractColumnFromGoogleSheetsNotation(start),
    endColumnTitle = extractColumnFromGoogleSheetsNotation(end);

  const startColumnNumber = convertGoogleSheetsColumnTitleToNumber(startColumnTitle),
    endColumnNumber = convertGoogleSheetsColumnTitleToNumber(endColumnTitle);

  if (startColumnNumber > endColumnNumber) {
    return true;
  }

  const startRowNumber = extractRowFromGoogleSheetsNotation(start),
    endRowNumber = extractRowFromGoogleSheetsNotation(end);

  return startRowNumber > endRowNumber;
}

/*
 * Transforming mapping data to row
 * */
export function transformMappingRowsToSheetRawData(rows: MappingRowWithExtraInfo[]) {
  if (!rows || 0 === rows.length) {
    return [];
  }

  return rows.map(row => transformMappingRowToSheetRawData(row));
}

function transformMappingRowToSheetRawData(row: MappingRowWithExtraInfo) {
  if (!row) {
    return;
  }

  return {
    source_field: row.source.name,
    target_field: row.destinationNameControl?.control?.value ? row.destinationNameControl.control.value : '',
    target_field_position: row.destination ? row.destination.position : '',
    ignored: row.destination ? row.status === MappingStatus.SKIPPED : true
  };
}

/*
 * Transforming raw data to mapping data
 * */
export function SheetsMappingRowFactory(object: any): MappingRowWithExtraInfo {
  if (!object) {
    return;
  }

  return {
    source: SchemaFieldFactory(object.source_field),
    destination: object.target_field && object.target_field_position
      ? {
        name: object.target_field.nm,
        position: object.target_field_position
      }
      : null,
    status: MappingStatus[object.status]
  };
}
