<div
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)"
  #container>
  <div
    class="dialog-content mapping-box"
    #content
    [@popupAnim]="visibleState">

    <ng-container *ngIf="!error">
      <button
        hd-icon-btn
        class="close-dialog-btn"
        (click)="onNegativeClick()">
        <hd-icon name="close"></hd-icon>
      </button>

      <div class="center-flex-row justify-center mb-5">
        <hd-rounded-icon iconName="run-now">
        </hd-rounded-icon>
      </div>

      <ng-container *ngIf="!successMsg">
        <ng-container *ngIf="!isFireboltDest">
          <div class="text-subheading-3 text-center">
            Mapping is missing for these Events
          </div>

          <div class="mt-2 text-center body-text">
            <div>
              In the absence of mapping, Hevo is unable to load these Events in your Destination. Hevo's most successful
              customers save their time by enabling Auto Mapping. It automatically creates the mapping for you.
              <a
                hdLink
                icon="new-window"
                (click)="clickTracking()"
                [hdDocLink]="autoMappingDocLink">
                Learn more
              </a>
            </div>
          </div>

          <div class="footer">
            <button
              [disabled]="runningReq"
              type="button"
              hdButton
              variation="outline"
              class="mr-3"
              (click)="onSecondaryClick()">
              Manually map Events
            </button>

            <button
              [disabled]="runningReq"
              type="button"
              hdButton
              (click)="autoMapObject()">
              Enable Auto Mapping {{ sourceObject ? '' : 'For Pipeline' }}
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="isFireboltDest">
          <div class="text-subheading-3 text-center">
            Unable to load Events to Firebolt
          </div>

          <div class="mt-2 text-center body-text">
            <div>
              Map the Event Types in the Source to Events Types at the Destination.
              <a
                hdLink
                icon="new-window"
                section="firebolt_map_events"
                (click)="clickTracking()"
                [hdDocLink]="fireboltMapEventsDocLink">
                Learn more
              </a>
            </div>
          </div>

          <div class="footer">
            <button
              [disabled]="runningReq"
              type="button"
              hdButton
              (click)="onSecondaryClick()">
              Map Events
            </button>
          </div>

        </ng-container>
      </ng-container>

      <ng-container *ngIf="successMsg && !isFireboltDest">
        <div class="text-subheading-3 text-center">Magic unlocked!</div>

        <div
          class="mt-2 text-center body-text">
          Sit back and relax. We will load the Events after creating the appropriate mappings.
          {{ sourceObject ? 'Meanwhile, you can turn on Auto Mapping for the entire Pipeline to avoid such errors in future.' : 'This may take a while.' }}
        </div>

        <div class="center-flex-row justify-center text-medium mt-5">
          <button
            *ngIf="sourceObject"
            type="button"
            hdButton
            [disabled]="runningReq"
            (click)="autoMapPipeline()">
            Auto Map All Events
          </button>

          <button
            *ngIf="!sourceObject"
            type="button"
            hdButton
            variation="outline"
            (click)="onNegativeClick()">
            Got It
          </button>
        </div>
      </ng-container>
    </ng-container>

    <page-error
      class="m-0"
      *ngIf="error"
      [error]="error"
      (tryAgain)="resetDialog()">
    </page-error>
  </div>
</div>
