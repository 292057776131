// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n/*\nTheme variables\n */\n/*\nTheme variables\n */\n.appbar_IN94G {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 84px;\n  z-index: 1050;\n  color: var(--light-text);\n  background: var(--appbar-bg-color);\n  padding-bottom: 16px;\n}\n\n.appbarBrandImage_POjug {\n  height: 32px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.appbarLinks_LtB2_ {\n  width: 100%;\n  display: grid;\n  row-gap: 8px;\n  place-items: center;\n}\n\n.appbarLinksBorderTop__InUe {\n  padding-top: 8px;\n  border-top: solid 1px var(--divider-10-color);\n}\n\n.appbarLinksBottom_k1MEf {\n  margin-top: auto;\n}\n\n.appbarVersionTagWrapper_L0gYC {\n  overflow: hidden;\n  width: 100%;\n  padding: 0 16px;\n}\n\n.appbarVersionTag__TVq3 {\n  padding: 4px 8px;\n  border-radius: 12px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 100%;\n  display: inline-block;\n  text-align: center;\n  transition: color 200ms ease;\n  background: var(--appbar-bg-open-color);\n  color: var(--light-text-secondary);\n  font-size: 10px;\n  font-weight: 700;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n}\n.appbarVersionTag__TVq3:hover, .appbarVersionTag__TVq3:focus {\n  color: var(--light-text);\n}\n\n.tourActive__h6cj::before {\n  content: none !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appbar": "appbar_IN94G",
	"appbarBrandImage": "appbarBrandImage_POjug",
	"appbarLinks": "appbarLinks_LtB2_",
	"appbarLinksBorderTop": "appbarLinksBorderTop__InUe",
	"appbarLinksBottom": "appbarLinksBottom_k1MEf",
	"appbarVersionTagWrapper": "appbarVersionTagWrapper_L0gYC",
	"appbarVersionTag": "appbarVersionTag__TVq3",
	"tourActive": "tourActive__h6cj"
};
export default ___CSS_LOADER_EXPORT___;
