// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.filterContainer_sC2Nq {\n  width: 220px;\n}\n\n.borderRight_KLFu8 {\n  border-right: 1px solid var(--default-border-color);\n}\n\n.filterTitle_Ehpv5 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 0px 4px 12px;\n}\n\n.clearFilterBtn_BP78a:hover,\n.clearFilterBtn_BP78a:focus {\n  box-shadow: none !important;\n  background: none !important;\n}\n\n.customFilterOption__3MyK {\n  padding-left: 12px !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "filterContainer_sC2Nq",
	"borderRight": "borderRight_KLFu8",
	"filterTitle": "filterTitle_Ehpv5",
	"clearFilterBtn": "clearFilterBtn_BP78a",
	"customFilterOption": "customFilterOption__3MyK"
};
export default ___CSS_LOADER_EXPORT___;
