import React from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import {
  REDIRECT_URL_CODE_QUERY_PARAM,
  REDIRECT_URL_ERROR_QUERY_PARAM,
  REDIRECT_URL_STATE_QUERY_PARAM
} from '../../../../app/nodes/nodes-settings/rest-api/constants';

export function RestAPIOAuth() {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location?.search);

  const state = atob(searchParams.get(REDIRECT_URL_STATE_QUERY_PARAM));
  const code = searchParams.get(REDIRECT_URL_CODE_QUERY_PARAM);
  const error = searchParams.get(REDIRECT_URL_ERROR_QUERY_PARAM);
  let redirectUrl: string;

  redirectUrl = code ? `${state}&code=${code}` : `${state}&error=${error}`;
  window.location.assign(redirectUrl);

  return <></>;
}
