import { Injectable } from '@angular/core';
import { DialogOverlayRef } from '../../dialog/dialog-overlay-ref';
import { DialogOverlayService } from '../../dialog/dialog-overlay.service';
import { ActivityLogDialogReactComponent } from './activity-log-dialog-react.component';
import { ActivityLogDialogData } from './interface';

@Injectable()
export class ActivityLogDialogService {
  constructor(
    private _dialogOverlayService: DialogOverlayService
  ) { }

  show(data: ActivityLogDialogData): DialogOverlayRef {
    return this._dialogOverlayService.open(
      ActivityLogDialogReactComponent,
      data
    );
  }
}
