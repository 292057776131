export const OWNER_OPTIONS = [
  {
    name: 'Owned by anyone',
    id: 'OWNED_BY_ANYONE'
  },
  {
    name: 'Owned by me',
    id: 'OWNED_BY_ME'
  },
  {
    name: 'Not owned by me',
    id: 'NOT_OWNED_BY_ME'
  }
];

export interface DriveFolder {
  id: string;
  name: string;
  modifiedTime: number;
  owner: string;
  link: string;
}

export interface DriveFolderMeta {
  page: string; // Next page token
  folders: DriveFolder[];
}

export function DriveFolderFactory(rawData: any): DriveFolder {
  if (!rawData) {
    return;
  }

  return {
    id: rawData.id,
    name: rawData.name,
    modifiedTime: rawData.modifiedTime,
    owner: rawData.owner,
    link: rawData.link
  };
}

export function DriveFoldersFactory(rawData: any): DriveFolderMeta {
  if (!rawData) {
    return;
  }

  return {
    page: rawData.page,
    folders: rawData.folders.map((item) => {
      return DriveFolderFactory(item);
    })
  };
}
