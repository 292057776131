export enum FieldType {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION'
}

export enum MappingStatus {
  MAPPED = 'MAPPED',
  SKIPPED = 'SKIPPED',
  UNMAPPED = 'UNMAPPED',
  INCOMPLETE = 'INCOMPLETE'
}

export enum MappingMode {
  CREATE,
  UPDATE
}

export enum RequiredFieldType {
  IDENTIFIER,
  MANDATORY
}

export interface MappingRow {
  source: any;
  destination: any;
  status?: MappingStatus;
  primaryKey?: PrimaryKeyProperty;
  identifierField?: boolean;
  mandatoryField?: boolean;
  [key: string]: any;
}

export interface PrimaryKeyProperty {
  show: boolean;
  value: boolean;
}

export class MappingSummary {
  sourceId?: number;
  sourceSchemaName?: string;
  destinationId?: number;
  destinationSchemaName?: string;
  fields: MappingRow[];
  mappingStatus?: MappingStatus;
}

export const DEFAULT_PRIMARY_KEY_PROPERTY: PrimaryKeyProperty = {
  show: false,
  value: false
};

export enum MappingBoxMenuActions {
  SEARCH_OPEN = 'SEARCH_OPEN',
  SEARCH_CLOSE = 'SEARCH_CLOSE',
  FILTER_OPTION = 'FILTER_OPTION',
  REFRESH = 'REFRESH'
}

export interface MappingBoxMenuActionData {
  menuAction: MappingBoxMenuActions;
  data?: any;
}
