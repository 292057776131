import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import { RestrictedTokenType } from './models';
import { LoginCredentials } from '../../../app/core/models/login-credentials';
import { RecaptchaTokenPayload } from '../../../app/core/models/recaptcha';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { AuthService } from '../../../app/core/service/auth.service';
import useService from '../../hooks/useService';
import AuthAPI from './AuthAPI';

export function useLogin() {
  const authService = useService(AuthService);
  const { search } = useLocation();
  const history = useHistory();

  const login = (
    credentials: LoginCredentials & RecaptchaTokenPayload,
    options?: NetworkRequestOptions,
    tfaCode?: string
  ) => {
    const next = new URLSearchParams(search).get('next');

    return AuthAPI.login(
      credentials,
      options,
      tfaCode
    ).then((res) => {
      if (res?.data?.require_challenge || res?.data?.challenge_failed) {
        return res;
      }

      authService.handleAuthResponseReact(res, next);
      return res;
    });
  };

  const loginWithCode = (code: any, next: string) => {
    return AuthAPI.loginWithCode(code, next).then((res) => {
      handleAuthResponse(res, next);
    });
  };

  const googleLogin = (err: any, code: any, state: any, tfaCode?: string) => {
    return AuthAPI.googleLogin(err, code, state, tfaCode).then((res) => {
      handleAuthResponse(res, res.data.next);
    });
  };

  const handleAuthResponse = (res: any, next: string) => {
    const restrictedTokenType: RestrictedTokenType = res.data.restricted_token_type;
    const redirectQueryParams = new URLSearchParams({ next });

    if (restrictedTokenType === RestrictedTokenType.SETUP_TFA) {
      history.replace({
        pathname: '/tfa/qr',
        search: redirectQueryParams.toString()
      });
      return;
    }

    if (restrictedTokenType === RestrictedTokenType.LOGIN_WITH_OTP) {
      history.replace({
        pathname: '/tfa/otp',
        search: redirectQueryParams.toString()
      });
      return;
    }


    if (restrictedTokenType === RestrictedTokenType.HEVO_LOGIN) {
      history.replace({
        pathname: '/emp-login-ac-select',
        search: redirectQueryParams.toString()
      });
      return;
    }

    if (restrictedTokenType === RestrictedTokenType.SAML_LOGIN) {
      const email = res.data.email;

      redirectQueryParams.set('email', email);

      history.replace({
        pathname: '/saml-login',
        search: redirectQueryParams.toString()
      });

      return;
    }

    navigateToDashboard(next);
  };

  const navigateToDashboard = (nextUrl: string) => {
    if (nextUrl) {
      history.replace(nextUrl);
    } else {
      history.replace('/');
    }
  };

  return {
    login,
    googleLogin,
    loginWithCode
  };
}
