// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.notificationShimmerContainer_qK92r {\n  display: block;\n  padding: 16px;\n  background: var(--surface-bg-color);\n  border-bottom: solid 1px var(--divider-color);\n}\n\n.l1Container_vYuPN {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationShimmerContainer": "notificationShimmerContainer_qK92r",
	"l1Container": "l1Container_vYuPN"
};
export default ___CSS_LOADER_EXPORT___;
