import { AppConfig } from '../../../app/core/app.config';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';

const appConfig = new AppConfig();
export const MigrationAPI = {
  teamSettingsURL: appConfig.getTeamSettingsURL(),
  integrationURL: appConfig.getIntegrationsURL(),

  enableSyncFrequency() {
    const requestURL = `${this.teamSettingsURL}/enable-sync-execution-policy`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: true
      }
    };

    return courier.post(requestURL, options);
  },

  migratePipelinesToSyncFrequency(payload) {
    const requestURL = `${this.integrationURL}/bulk-schedule`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: true,
        showErrorMsg: true
      }
    };

    return courier.put(requestURL, options, payload);
  }
};
