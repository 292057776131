import AlgoliaSearch from 'algoliasearch';
import { AlgoliaSearchResult } from '../GlobalSearchReducer';

declare const __ALGOLIA_CONFIG__;

const AlgoliaConfig =
  typeof __ALGOLIA_CONFIG__ !== 'undefined'
    ? __ALGOLIA_CONFIG__
    : {
        appId: 'HBAU4MYQP6',
        docsAPIKey: '218bf5f61fc36924d0b806ec45ae09f8',
        dashboardAPIKey: '14eefb096c57e133d1193e7afb538359'
      };

const DocsAlgoliaSearchClient = AlgoliaSearch(AlgoliaConfig.appId, AlgoliaConfig.docsAPIKey);
const DocIndex = DocsAlgoliaSearchClient.initIndex('hevo-docs');

const ProductAlgoliaSearchClient = AlgoliaSearch(
  AlgoliaConfig.appId,
  AlgoliaConfig.dashboardAPIKey
);
const ProductRoutesSearchIndex = ProductAlgoliaSearchClient.initIndex('hevo-product-routes-test');

export async function AlgoliaSearchFactory(
  query: string,
  isWorkFlowsEnabled: boolean,
  isActivationEnabled: boolean,
  pageLimit = 10
): Promise<AlgoliaSearchResult[]> {
  if (!query) {
    return Promise.resolve([]);
  }

  const docResultPromise = DocIndex.search(query, { hitsPerPage: pageLimit });
  const productRoutesPromise = ProductRoutesSearchIndex.search(query);

  return Promise.all([docResultPromise, productRoutesPromise]).then(
    ([docResult, productRoutesResult]) => {
      const docs = docResult.hits.map((hit: any) => AlgoliaSearchResultItemFactory(hit, 'docs'));
      let routes = productRoutesResult.hits.map((hit: any) =>
        AlgoliaSearchResultItemFactory(hit, 'product')
      );

      if (!isWorkFlowsEnabled) {
        routes = routes.filter(item => item.icon !== 'workflow');
      }

      if (!isActivationEnabled) {
        routes = routes.filter(item => item.icon !== 'activate');
      }
      return [...routes, ...docs];
    }
  );
}

export function AlgoliaSearchResultItemFactory(hit, category): AlgoliaSearchResult {
  const id = hit.objectID;
  const title = getParsedHTMLContent(hit.title);
  const { url } = hit;
  const icon = hit?.icon || 'documentation';

  const displayContent =
    category === 'product'
      ? ''
      : getParsedHTMLContent(
          hit?._highlightResult?.content?.value ||
            hit?.content ||
            hit?._highlightResult?.title?.value ||
            hit.title
        );

  return {
    id,
    title,
    url,
    icon,
    category,
    displayContent
  };
}

function getParsedHTMLContent(text: string) {
  const parser = new DOMParser();
  return parser.parseFromString(text, 'text/html').body.textContent;
}
