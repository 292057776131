// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.NotificationTableContainer_UFdx8 {\n  display: block;\n}\n\n.notificationTable_lm0uR {\n  width: 100%;\n  table-layout: fixed;\n  border-spacing: 0;\n}\n\n.header_iyn_V {\n  font-weight: 700;\n}\n\n.notificationTableCell_yMhMt {\n  vertical-align: top;\n  padding-bottom: 2px;\n}\n\n.notificationTableCell_yMhMt:not(.header_iyn_V) {\n  word-wrap: break-word;\n}\n\n.notificationTableCell_yMhMt.header_iyn_V {\n  width: 25%;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NotificationTableContainer": "NotificationTableContainer_UFdx8",
	"notificationTable": "notificationTable_lm0uR",
	"header": "header_iyn_V",
	"notificationTableCell": "notificationTableCell_yMhMt"
};
export default ___CSS_LOADER_EXPORT___;
