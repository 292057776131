import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import {
  TRACKER_DATABASE_DROPDOWN_VISIBLE,
  TRACKER_SCHEMA_DROPDOWN_VISIBLE,
  TRACKER_SQL_HOST_NAME_BLUR
} from '../../../../../../app/nodes/tracking';
import {
  HdFormikDropDown,
  HdFormikPasswordField,
  HdFormikTextField
} from '../../../../../components/FormikElements';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import useLocalHostTracker from '../../../../../hooks/useLocalHostTracker';
import { isChangeTrackingV2EnabledForTeam } from '../../../utils';
import { SourceConfigProps } from '../models';
import { _sshService } from '../../../../../../app/core/service/ssh.service';
import SSHConfig from '../../../../../components/FormikElements/SSHConfig';
import SSLConfig from '../../../../../components/FormikElements/SSLConfig';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import {
  IncludeNewTable,
  LoadAllDatabases,
  LoadHistoricalData,
  MergeTables
} from '../../../../../components/NodeConfigOptions';
import PipelinesAPI from '../../../PipelinesAPI';
import HdSetupGuideLink from '../../../../../components/UIElements/HdSetupGuideLink';
import {
  fetchParentAndVariationsNames,
  getBaseSourceType
} from '../../../../../../app/nodes/source-type/utils';
import JobModeSelectionField from '../../../../../components/JobModeSelectionField';
import { SqlFormFields } from './models';
import { SqlConfigPayload, SqlFormState } from './interfaces';
import SSHConfigFields from '../../../../../components/FormikElements/SSHConfig/SSHConfigFields';
import { JOB_MODES_SEE_MORE_OPTIONS_DEFAULT_EXPAND_SOURCES } from './constants';
import useTeamSettings from '../../../../../hooks/services/useTeamSettingsService';
import RedologAdvancedConfig from './RedologAdvancedConfig';
import LoadAllSchema from './LoadAllSchema';
import { HdRadio, HdRadioGroup } from '../../../../../components/UIElements';

export function Sql({
  sourceTypeIdentifier,
  sourceTypeDisplay,
  sourceTypeMetaData,
  formMetaData,
  isEditing,
  draftSource,
  jobMode,
  canShowAdvancedSettings,
  canSkipNewTables,
  setJobMode,
  formErrorCode,
  showJobModeSelector,
  canShowDiagnosePane,
  formData
}: SourceConfigProps) {
  const analyticsTracker = useAnalyticsTracker();
  const formikProps = useFormikContext<SqlFormState>();
  const { jobModes } = SOURCE_TYPES[sourceTypeIdentifier];

  // we will remove this team based flag in future.
  const { getTeamSettings, isHistoricalTaskSeparationForTableModeEnabled } = useTeamSettings();
  const teamSettings = getTeamSettings();

  const showImportHistoricalLoadForTableMode = isHistoricalTaskSeparationForTableModeEnabled;
  const changeTrackingV2Enabled = isChangeTrackingV2EnabledForTeam(teamSettings);
  const isSQLServerReadReplicaSupportEnabled =
    !!teamSettings?.['sql_server_read_replica_support_enabled'];
  const isPostgresPartitionTableToParentTableEnabled =
    !!teamSettings?.['pg_push_partition_table_to_parent_table'];

  const [databaseList, setDatabaseList] = useState([]);
  const [loadingDatabaseList, setLoadingDatabaseList] = useState(false);
  const [schemasList, setSchemasList] = useState([]);
  const [loadingSchemasList, setLoadingSchemasList] = useState(false);

  const { host, port, user, password } = formikProps.values;

  const isDropdownFieldAllowedForSource = () =>
    ['MYSQL', 'MS_SQL'].includes(getBaseSourceType(sourceTypeIdentifier).name);

  const sourceTypeDisplayFragment = sourceTypeDisplay
    .replace(/\s+/g, '-')
    .replace(/_/g, '')
    .toLowerCase();

  let connectionSettingsDocLink = sourceTypeMetaData.docLink;

  if (sourceTypeIdentifier === 'ORACLE') {
    connectionSettingsDocLink = `${connectionSettingsDocLink}#specify-oracle-connection-settings`;
  } else if (sourceTypeIdentifier === 'AWS_RDS_ORACLE') {
    connectionSettingsDocLink = `${connectionSettingsDocLink}#specify-amazon-rds-oracle-connection-settings`;
  } else if (sourceTypeIdentifier === 'MS_SQL') {
    connectionSettingsDocLink = `${connectionSettingsDocLink}#specify-generic-sql-server-connection-settings`;
  } else if (sourceTypeIdentifier === 'REDSHIFT') {
    connectionSettingsDocLink = `${connectionSettingsDocLink}#configure-${sourceTypeDisplayFragment}-connection-settings`;
  } else {
    connectionSettingsDocLink = `${connectionSettingsDocLink}#specify-${sourceTypeDisplayFragment}-connection-settings`;
  }

  useEffect(() => {
    formikProps.setValues({
      ...formikProps.values,
      showImportHistoricalLoadForTableMode,
      changeTrackingV2Enabled,
      isPostgresPartitionTableToParentTableEnabled
    });
  }, []);

  useEffect(() => {
    if (databaseList.length) {
      setDatabaseList([]);
    }
    if (schemasList.length) {
      setSchemasList([]);
    }
  }, [host, port, user, password]);

  useEffect(() => {
    if (isDropdownFieldAllowedForSource) {
      setTimeout(() => {
        onFormError();
      }, 100);

      if (formErrorCode === 1014) {
        fetchAllDatabases();
      }

      if (formErrorCode === 1020) {
        fetchAllSchemas();
      }
    }
  }, [formErrorCode]);

  const onFormError = () => {
    if (formErrorCode === 1014) {
      formikProps.setFieldValue(SqlFormFields.DATABASE_NAME_ERROR, formErrorCode, true);
      formikProps.setFieldTouched(SqlFormFields.DATABASE_NAME, true, true);
    } else {
      formikProps.setFieldValue(SqlFormFields.DATABASE_NAME_ERROR, null, true);
    }

    if (formErrorCode === 1020) {
      formikProps.setFieldValue(SqlFormFields.SCHEMA_NAME_ERROR, formErrorCode, true);
      formikProps.setFieldTouched(SqlFormFields.SCHEMA_NAME, true, true);
    } else {
      formikProps.setFieldValue(SqlFormFields.SCHEMA_NAME_ERROR, null, true);
    }
  };

  const onHostNameBlur = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_SQL_HOST_NAME_BLUR,
      properties: {
        hostName: formikProps.values.host,
        sourceType: formMetaData.parent ? formMetaData.parent : sourceTypeIdentifier,
        derivedSourceType: sourceTypeIdentifier
      }
    });
  };

  const shouldCheckLocalHost =
    ['MYSQL', 'MS_SQL', 'ORACLE'].includes(sourceTypeIdentifier) &&
    !_sshService.sshEnabled(formikProps.values);

  useLocalHostTracker({
    value: formikProps.values.host,
    hdLocalHostValidatorDisabled: !shouldCheckLocalHost,
    localHostTrackerLabel: 'db-host-field-local-host-steps',
    localHostTrackerSection: 'source-settings',
    localHostTrackingParams: {
      sourceType: sourceTypeMetaData.parent ? sourceTypeMetaData.parent : sourceTypeIdentifier,
      derivedSourceType: sourceTypeIdentifier
    }
  });

  const isSourceTypeRedshift = SOURCE_TYPES.REDSHIFT.name === sourceTypeIdentifier;
  const isSourceTypeSQLServer =
    SOURCE_TYPES.MS_SQL.name === getBaseSourceType(sourceTypeIdentifier).name;

  const canUseSSL = sourceTypeMetaData.sslAllowed;

  const getConfigParams = (): Partial<SqlConfigPayload> => ({
    db_host: host,
    db_port: port,
    db_user: user,
    db_password: password,
    integration_id: formMetaData.id || null,
    include_system_databases: false
  });

  const fetchAllDatabases = async () => {
    try {
      setLoadingSchemasList(true);

      const config = getConfigParams();
      const { data } = await PipelinesAPI.getSqlDatabases(sourceTypeIdentifier, config);

      setDatabaseList(data);
      setLoadingDatabaseList(false);

      analyticsTracker.eventTrack({
        action: TRACKER_DATABASE_DROPDOWN_VISIBLE,
        properties: {
          type: sourceTypeIdentifier
        }
      });
    } catch (error) {
      setLoadingDatabaseList(false);
    }
  };

  const fetchAllSchemas = async () => {
    setLoadingSchemasList(true);

    const config = {
      ...getConfigParams(),
      db_name: formikProps.values[SqlFormFields.DATABASE_NAME]
    };

    const { data } = await PipelinesAPI.getSqlSchemas(sourceTypeIdentifier, config);

    setSchemasList(data);
    setLoadingSchemasList(false);

    analyticsTracker.eventTrack({
      action: TRACKER_SCHEMA_DROPDOWN_VISIBLE,
      properties: {
        type: sourceTypeIdentifier
      }
    });
  };

  const canProvideMultipleDbNames = () => {
    /**
     * Return false for sources that were created before
     * multi db name feature was introduced
     */
    if (isEditing && formikProps.values[SqlFormFields.RENDER_OLD_DB_NAME_FIELD]) {
      return false;
    }
    /**
     * Return true if selected job mode is binlog
     */
    return !!jobMode?.canProvideMultipleDbNames;
  };

  const canShowRedoLogAdvancedSettings =
    sourceTypeMetaData.provideSchemaName && jobMode?.canShowRedoLogAdvancedSettings;
  const defaultExpandJobModesField = JOB_MODES_SEE_MORE_OPTIONS_DEFAULT_EXPAND_SOURCES.includes(
    sourceTypeMetaData.name
  );

  const canProvideSchemaName = () =>
    sourceTypeMetaData.provideSchemaName && (!jobMode || !jobMode.hideSchemaName);

  const isNewRedoLogAdvancedSettings =
    canShowRedoLogAdvancedSettings &&
    (!isEditing || formData.oraclePollTaskV2Flag || formData.schemaVersion >= 42);
  const isOldRedoLogAdvancedSettings =
    isEditing &&
    canShowRedoLogAdvancedSettings &&
    formData.schemaVersion < 42 &&
    !formData.oraclePollTaskV2Flag;

  return (
    <>
      <Grid container spacing={2}>
        {sourceTypeIdentifier === 'AZURE_POSTGRES' ? (
          <Grid item xs={12} md={12}>
            <HdRadioGroup
              name={SqlFormFields.AZURE_SERVER_FLEXI}
              currentValue={formikProps.values[SqlFormFields.AZURE_SERVER_FLEXI]}
              onChange={(value: string) => {
                formikProps.setFieldValue(SqlFormFields.AZURE_SERVER_FLEXI, value);
              }}
              className='w-100'
              label='Select Server Type'
              disabled={isEditing}
            >
              <HdRadio
                key='flexi'
                dataId='flexi'
                value='true'
                className='mat-radio-wrap-label w-25 mb-5 mt-3'
              >
                <Typography variant='body2' className='ml-2'>
                  Flexible Server
                </Typography>
              </HdRadio>

              <HdRadio
                key='single'
                dataId='single'
                value='false'
                className='mat-radio-wrap-label mb-5 mt-3'
              >
                <Typography variant='body2' className='ml-2'>
                  Single Server
                </Typography>
              </HdRadio>
            </HdRadioGroup>
          </Grid>
        ) : null}

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.HOST}
              label={isSourceTypeRedshift ? 'Database Cluster Identifier' : 'Database Host'}
              placeholder={
                isSourceTypeRedshift
                  ? 'Database Cluster Identifier'
                  : 'Enter your IP Address/DNS name for your database source'
              }
              component={HdFormikTextField}
              onBlur={onHostNameBlur}
              required
              helperText={
                <Typography variant='caption'>
                  10.123.1.001 or {sourceTypeDisplayFragment}-replica.westeros.inc.{' '}
                  <HdSetupGuideLink
                    setupGuideLink={connectionSettingsDocLink}
                    section='source-settings'
                    label='db-host-name'
                    size='sm'
                    icon='right'
                    dataId='sql-db-host-name'
                  >
                    Learn more
                  </HdSetupGuideLink>
                </Typography>
              }
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.PORT}
              label='Database Port'
              placeholder='Database Port'
              required
              component={HdFormikTextField}
              type='number'
              helperText={
                <Typography variant='caption'>
                  The port on which the database is accepting connections.{'  '}
                  <HdSetupGuideLink
                    setupGuideLink={connectionSettingsDocLink}
                    section='source-settings'
                    label='db-port'
                    tab='Setup'
                    size='sm'
                    icon='right'
                    dataId='sql-db-port'
                  >
                    Need help?
                  </HdSetupGuideLink>
                </Typography>
              }
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.USER}
              label='Database User'
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.PASSWORD}
              label='Database Password'
              required
              masked={isEditing || (draftSource && draftSource.isDraft)}
              component={HdFormikPasswordField}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      {showJobModeSelector ? (
        <JobModeSelectionField
          selectedJobMode={jobMode}
          jobModes={jobModes}
          isSeeMoreOptionsVariant
          jobModeChange={setJobMode}
          seeMoreOptionsExpandDefault={defaultExpandJobModesField}
        />
      ) : null}

      <Grid container spacing={2}>
        {!canProvideMultipleDbNames() ? (
          <DatabaseFields
            sourceTypeIdentifier={sourceTypeIdentifier}
            databaseList={databaseList}
            loadingDatabaseList={loadingDatabaseList}
            isDropdownFieldAllowed={isDropdownFieldAllowedForSource}
            focusDropdownOnRender={!canShowDiagnosePane}
            isEditing={isEditing}
          />
        ) : null}

        {canProvideSchemaName() ? (
          <SchemaFields
            sourceTypeIdentifier={sourceTypeIdentifier}
            schemasList={schemasList}
            loadingSchemasList={loadingSchemasList}
            sourceTypeMetaData={sourceTypeMetaData}
            isDropdownFieldAllowed={isDropdownFieldAllowedForSource}
            focusDropdownOnRender
          />
        ) : null}
      </Grid>

      {isOldRedoLogAdvancedSettings ? <LoadAllSchema isEditing={isEditing} /> : null}

      <SSHConfig className='mb-7' entityTypeIdentifier={sourceTypeDisplay}>
        <SSHConfigFields />
      </SSHConfig>

      {canUseSSL ? <SSLConfig sslCARequired={!!sourceTypeMetaData.sslCARequired} /> : null}

      {isSourceTypeSQLServer && isSQLServerReadReplicaSupportEnabled ? (
        <div className='mb-7'>
          <Grid container>
            <Grid item xs={12}>
              <HdFormikSwitchWrapper
                fieldName={SqlFormFields.APPLICATION_INTENT_READ_ONLY}
                label='Set Application Intent Read Only'
                disabled={isEditing}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}

      {isNewRedoLogAdvancedSettings ? (
        <RedologAdvancedConfig
          isEditing={isEditing}
          isOnlineCatalog={formData[SqlFormFields.ONLINE_CATALOG]}
        />
      ) : null}

      {canShowAdvancedSettings ? (
        <AdvancedConfig forceExpand={formikProps.isSubmitting} showBorderBottom>
          {canProvideMultipleDbNames() ? <LoadAllDatabases disabled={isEditing} /> : null}

          {jobMode.historicalMode || showImportHistoricalLoadForTableMode ? (
            <LoadHistoricalData disabled={isEditing} />
          ) : null}

          {jobMode.canMergeTables ? (
            <MergeTables
              disabled={isEditing}
              databasesDisplay={sourceTypeMetaData.provideSchemaName ? 'schemas' : 'databases'}
            />
          ) : null}

          {canSkipNewTables ? <IncludeNewTable /> : null}
        </AdvancedConfig>
      ) : null}
    </>
  );
}

function DatabaseFields({
  sourceTypeIdentifier,
  databaseList,
  loadingDatabaseList,
  isDropdownFieldAllowed,
  focusDropdownOnRender,
  isEditing = false
}) {
  const formikProps = useFormikContext<SqlFormState>();

  const baseSourceType = getBaseSourceType(sourceTypeIdentifier).name;

  const dbDropdownRef = useRef(null);

  const isDropdownFieldRendered =
    isDropdownFieldAllowed && databaseList.length && baseSourceType !== 'ORACLE';

  const isSourceTypePostgres = useMemo(
    () => (fetchParentAndVariationsNames('POSTGRES') || []).includes(sourceTypeIdentifier),
    [sourceTypeIdentifier]
  );

  useEffect(() => {
    if (focusDropdownOnRender && isDropdownFieldRendered) {
      dbDropdownRef.current?.focus();
    }
  }, [focusDropdownOnRender, isDropdownFieldRendered]);

  return (
    <>
      {baseSourceType === 'ORACLE' ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.DATABASE_NAME}
              label='Service Name'
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>
      ) : null}

      {!databaseList?.length && baseSourceType !== 'ORACLE' ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.DATABASE_NAME}
              label='Database Name'
              required
              component={HdFormikTextField}
              onChange={() => {
                setTimeout(() => {
                  formikProps.setFieldValue(SqlFormFields.DATABASE_NAME_ERROR, null, true);
                }, 0);
              }}
              disabled={isEditing && isSourceTypePostgres}
              helperText={
                loadingDatabaseList
                  ? 'Loading databases...'
                  : 'The name of the database you want to ingest data from'
              }
            />
          </HdFormControl>
        </Grid>
      ) : null}

      {isDropdownFieldRendered ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              ref={dbDropdownRef}
              name={SqlFormFields.DATABASE_NAME}
              label='Database Name'
              placeholder='DB Name'
              required
              options={databaseList}
              Loading={loadingDatabaseList}
              component={HdFormikDropDown}
              onChangeEventHandler={d => {
                formikProps.setFieldValue(SqlFormFields.DATABASE_NAME, d);
                setTimeout(() => {
                  formikProps.setFieldValue(SqlFormFields.DATABASE_NAME_ERROR, null, true);
                }, 0);
              }}
              helperText='Choose a database'
              creatableMode
              creatablePrimitive
            />
          </HdFormControl>
        </Grid>
      ) : null}
    </>
  );
}

function SchemaFields({
  sourceTypeIdentifier,
  schemasList,
  loadingSchemasList,
  sourceTypeMetaData,
  isDropdownFieldAllowed,
  focusDropdownOnRender
}) {
  const formikProps = useFormikContext<SqlFormState>();

  const baseSourceType = getBaseSourceType(sourceTypeIdentifier).name;

  const schemaDropdownRef = useRef(null);

  const isDropdownFieldRendered =
    isDropdownFieldAllowed && schemasList.length && baseSourceType !== 'ORACLE';

  useEffect(() => {
    if (focusDropdownOnRender && isDropdownFieldRendered) {
      schemaDropdownRef.current?.focus();
    }
  }, [focusDropdownOnRender, isDropdownFieldRendered]);

  const isSchemaRequired = sourceTypeMetaData.isSchemaNameRequired;

  return (
    <>
      {baseSourceType === 'ORACLE' ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.SCHEMA_NAME}
              label='Owner'
              component={HdFormikTextField}
              helperText='Name of the schema owner in your database.'
            />
          </HdFormControl>
        </Grid>
      ) : null}

      {!schemasList?.length && baseSourceType !== 'ORACLE' ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.SCHEMA_NAME}
              label='Schema Name'
              required={isSchemaRequired}
              onChange={() => {
                setTimeout(() => {
                  formikProps.setFieldValue(SqlFormFields.SCHEMA_NAME_ERROR, null, true);
                }, 0);
              }}
              component={HdFormikTextField}
              helperText={
                loadingSchemasList
                  ? 'Loading schemas...'
                  : 'The name of the schema you want to ingest data from'
              }
            />
          </HdFormControl>
        </Grid>
      ) : null}

      {isDropdownFieldRendered ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              ref={schemaDropdownRef}
              name={SqlFormFields.SCHEMA_NAME}
              label='Schema Name'
              placeholder='Schema Name'
              required={isSchemaRequired}
              options={schemasList}
              Loading={loadingSchemasList}
              component={HdFormikDropDown}
              onChangeEventHandler={s => {
                formikProps.setFieldValue(SqlFormFields.SCHEMA_NAME, s);
                setTimeout(() => {
                  formikProps.setFieldValue(SqlFormFields.SCHEMA_NAME_ERROR, null, true);
                }, 0);
              }}
              helperText='Choose a schema'
              creatableMode
              creatablePrimitive
            />
          </HdFormControl>
        </Grid>
      ) : null}
    </>
  );
}
