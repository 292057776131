import { createTheme, useTheme } from '@mui/material/styles';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

export const activeTheme = currentTheme =>
  createTheme({
    palette: {
      mode: currentTheme
    },
    typography: typographyTheme,
    components: {
      MuiInputBase: MuiInputBaseTheme,
      MuiPaper: MuiPaperTheme,
      MuiIconButton: {
        defaultProps: {
          disableFocusRipple: true,
          disableTouchRipple: true
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'body3' },
            style: customTypographyBody3
          },
          {
            props: { variant: 'body4' },
            style: customTypographyBody4
          }
        ]
      },
      MuiModal: {
        styleOverrides: {
          root: {
            background: 'rgba(0, 0, 0, 0.32)'
          },
          backdrop: {
            background: 'var(--backdrop-color)'
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            zIndex: 'var(--zindex-drawer)'
          },
          paper: {
            borderRadius: 0
          }
        }
      }
    }
  });

export const useActiveTheme = () => {
  const theme = useTheme();
  return theme.palette.mode;
};

const typographyTheme = {
  fontFamily: 'inherit',
  h3: {
    color: 'var(--text-default-color)',
    fontSize: 'var(--font-size-title)',
    fontWeight: 'var(--font-weight-bold)',
    lineHeight: '40px',
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal'
  },
  body1: {
    color: 'var(--text-secondary-color)',
    fontSize: 'var(--font-size-body)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: '16px',
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal'
  },
  body2: {
    color: 'var(--text-default-color);',
    fontSize: 'var(--font-size-body)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: '16px',
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal'
  },
  subtitle1: {
    color: 'var(--text-default-color);',
    fontSize: 'var(--font-size-subheader)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: '20px',
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal'
  },
  subtitle2: {
    color: 'var(--text-default-color);',
    fontSize: 'var(--font-size-subheader)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: '20px',
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal'
  },
  caption: {
    color: 'var(--text-secondary-color);',
    fontSize: 'var(--font-size-caption)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: '16px',
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal'
  }
};

const customTypographyBody3 = {
  color: 'var(--text-default-color);',
  fontSize: 'var(--font-size-body)',
  fontWeight: 'var(--font-weight-normal)',
  lineHeight: '16px',
  fontFamily: 'var(--font-family)',
  fontStyle: 'normal'
};

const customTypographyBody4 = {
  color: 'var(--text-default-color)',
  fontSize: 'var(--font-size-body)',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: '16px',
  fontFamily: 'var(--font-family)',
  fontStyle: 'normal'
};

const MuiInputBaseTheme = {
  styleOverrides: {
    root: {
      color: 'var(--text-default-color)',
      fontSize: 'var(--font-size-body)',
      fontFamily: 'var(--font-family)',
      fontWeight: 'var(--font-weight-medium)',
      background: 'none',
      width: '100%'
    }
  }
};

const MuiPaperTheme = {
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      transition: 'all .2s ease',
      boxShadow: 'none'
    }
  }
};
