import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class AppleSearchAdsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const config: any = {
      organizations: rawData.selected_organizations
        .filter(organization => !!organization)
        .map(organization => ({
          id: organization,
          name: organization
        })),
      clientId: rawData.client_id,
      teamId: rawData.team_id,
      clientKey: rawData.key_id,
      tokenId: rawData.token_id
    };

    return config;
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data: any) {
    return {
      selected_organizations: (data.organizations || []).map(organization => organization?.id),
      client_id: data.clientId,
      team_id: data.teamId,
      key_id: data.clientKey,
      token_id: data.tokenId
    };
  }

  async metaDataResolver(sourceType: string, authData: any, rawData: any) {
    let tokenId;

    if (rawData.isSample || rawData.id) {
      const config: any = rawData.id ? rawData.source_config : rawData;
      tokenId = config.token_id;
    } else {
      tokenId = rawData.tokenId;
    }

    const { data } = await PipelinesAPI.getAppleSearchAdsPublicKey(tokenId);
    return {
      publicKey: data.public_key,
      privateKey: data.private_key
    };
  }
}
