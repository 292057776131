import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';


interface FormValueChangesObserverProps {
  onChange: Function;
}

// eslint-disable-next-line react/function-component-definition
export const FormValueChangesObserver: React.FC<FormValueChangesObserverProps> = ({
  onChange
}) => {
  const { values }: any = useFormikContext();

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange();
    }
  }, [ values ]);

  return null;
};
