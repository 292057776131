import React from 'react';
import { HdIcon, HdTooltip } from '../../../UIElements';
import { DbtProjectData } from '../../models';
import { NotificationView } from '../notification-view';
import styles from './styles.module.scss';

export default function DbtView({ data }: NotificationView<DbtProjectData>) {
  return (
    <div className={styles.dbtViewContainer}>
      <HdIcon name='dbt' size={1} className={`${styles.icon} mr-1`} />

      <HdTooltip enableOnTextOverflow title={data.name}>
        <div className='text-ellipsis text-caption-1'>{data.name}</div>
      </HdTooltip>
    </div>
  );
}
