import * as Yup from 'yup';
import {
  DESTINATION_TYPES,
  DestinationType
} from '../../../../../../app/nodes/destination-type/model';
import {
  getSSHConfigValidationSchema
} from '../../../../../components/FormikElements/SSHConfig/validationSchema';
import { getSSLConfigValidationSchema } from '../../../../../components/FormikElements/SSLConfig/validationSchema';
import { SetupGuideLinkParams } from '../../../../../utils/setupGuideLinkHandler';
import {
  hostNameValidatorSchema,
  localHostValidatorSchema,
  schemaNameValidatorSchema
} from '../../../../../utils/validatorSchema';
import { DestinationConfigMetaData } from '../interface';
import Sql from './index';

export const fieldNameMapping = {
  host: 'db_host',
  user: 'db_user',
  port: 'db_port',
  databaseName: 'db_name',
  password: 'db_password',
  sshIp: 'ssh_host',
  sshPort: 'ssh_port',
  sshUser: 'ssh_user',
  schemaName: 'schema_name',
  optimizeTables: 'optimisation',
  useSSH: 'use_ssh',
  useSSL: 'use_ssl',
  sanitizeName: 'sanitize_name'
};

export const SQL_CONFIG: DestinationConfigMetaData = {
  formComponent: Sql,
  initialValues: {
    host: '',
    port: '',
    user: '',
    password: '',
    databaseName: '',
    sqlSchema: '',
    useSSL: false,
    useSSH: false,
    sshIp: '',
    sshPort: '',
    sshUser: '',
    sslCA: null,
    sslClientCert: null,
    sslClientKey: null,
    sanitizeName: true,
    sslLoadAllCA: false
  },
  validationSchema(props: { destinationTypeMetaData: DestinationType }) {
    const destinationTypeIdentifier = props.destinationTypeMetaData.name;

    let sqlSchema: { [key: string]: any } = {
      host: Yup.string()
        .trim()
        .test(
          localHostValidatorSchema({
            type: 'SQL',
            typeIdentifier: destinationTypeIdentifier,
            localhostDocLinkParams: getLocalhostSBSLinkParams(destinationTypeIdentifier)
          })
        )
        .test(hostNameValidatorSchema({ type: 'SQL' }))
        .required(),
      port: Yup.number().positive().required(),
      user: Yup.string().trim().required(),
      password: Yup.string().trim().required(),
      databaseName: Yup.string().trim().required(),
      useSSL: Yup.boolean(),
      ...getSSHConfigValidationSchema()
    };
    if (props.destinationTypeMetaData.canUseSSL) {
      sqlSchema = {
        ...sqlSchema,
        ...getSSLConfigValidationSchema(props.destinationTypeMetaData.sslCARequired)
      };
    }

    if (props.destinationTypeMetaData.provideSchemaName) {
      sqlSchema.schemaName = Yup.string().trim().test(schemaNameValidatorSchema());
    }

    return Yup.object().shape(
      { ...sqlSchema },

      [['sslClientCert', 'sslClientKey']]
    );
  },
  fieldNameMapping
};
export function getLocalhostSBSLinkParams(destinationTypeIdentifier) {
  const params: SetupGuideLinkParams = {
    setupGuideLink: `${DESTINATION_TYPES[destinationTypeIdentifier].docLink}#connect-to-a-local-database`,
    section: 'destination_settings',
    label: 'db-host-field-local-host-steps',
    properties: {
      destinationType: destinationTypeIdentifier,
      context: 'Destination'
    }
  };
  return params;
}
