import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ZendeskVersionUpgradeBanner } from '../../../react/containers/pipeline/create/ConfigSourceType/GenericSource/Zendesk/ZendeskVersionUpgradeBanner';
import { BridgeService } from '../../react-bridge/services/bridge.service';

@Component({
  selector: 'hd-zendesk-source-version-upgrade-banner',
  template: '',
  providers: [ BridgeService ]
})

export class ZendeskSourceVersionUpgradeBannerComponent implements OnChanges {
  @Input() pipelineId: number;
  @Input() className: string;
  @Output() versionUpgrade = new EventEmitter();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount(
      ZendeskVersionUpgradeBanner,
      this._el.nativeElement,
      {
        className: this.className,
        pipelineId: this.pipelineId,
        versionUpgrade: this.versionUpgrade.emit.bind(this.versionUpgrade)
      }
    );
  }
}
