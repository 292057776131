import { NgZone } from '@angular/core';
import { AppConfig } from '../../../../app/core/app.config';

declare const grecaptcha;
declare const GoogleRecaptcha;
declare const clarity;

const appConfig = new AppConfig();

export function executeRecaptchaV3(callback) {
  grecaptcha.execute(appConfig.config.recaptchaV3Key, { action: 'submit' }).then(function (token) {
    callback(token);
  });
}

export function executeRecaptchaV2(invisibleWidgetId: number) {
  grecaptcha.reset(invisibleWidgetId);
  grecaptcha.execute(invisibleWidgetId);
}

export function renderInvisibleRecaptcha(container, callback, expiredCallback, erroredCallback) {
  return grecaptcha.render(container, {
    sitekey: appConfig.config.recaptchaV2InvisibleKey,
    callback: callback,
    size: 'invisible',
    'expired-callback': expiredCallback,
    'errored-callback': erroredCallback
  });
}

export class RecaptchaService {
  recaptcha: any = GoogleRecaptcha;
  invisibleWidgetId: number;

  private _successCallback: (challengeToken) => any;
  private _errorCallback: () => any;

  constructor(public ngZone: NgZone, container?: string) {
    if (!container) {
      return;
    }

    this.renderInvisibleRecaptcha(container);
  }

  private _callback(challengeToken) {
    this._successCallback(challengeToken);
  }

  private _expiryCallback() {
    this._errorCallback();
  }

  private _erroredCallback() {
    this._errorCallback();
  }

  renderInvisibleRecaptcha(container) {
    this.recaptcha.onInit(() => {
      this.invisibleWidgetId = renderInvisibleRecaptcha(
        container,
        this._callback.bind(this),
        this._expiryCallback.bind(this),
        this._erroredCallback.bind(this)
      );
    });
  }

  onReady(callback) {
    this.ngZone.run(() => {
      this.recaptcha.onInit(() => {
        this.ngZone.run(() => {
          callback();
        });
      });
    });
  }

  validateWithRecaptcha(getPromise) {
    return new Promise((resolve, reject) => {
      executeRecaptchaV3((scoreToken) => {
        this.ngZone.run(() => {
          getPromise({ scoreToken }).then((data) => {
            if (data.showChallenge) {
              if (typeof clarity !== 'undefined') {
                clarity(
                  'event',
                  'recaptcha_init_challenge_request'
                );
              }

              executeRecaptchaV2(this.invisibleWidgetId);

              this._successCallback = (challengeToken) => {
                this.ngZone.run(() => {
                  getPromise({ challengeToken }).then((data) => {
                    if (data.challengeFailed) {
                      reject(new Error('Recaptcha validation failed'));
                    } else {
                      resolve(data);
                    }
                  }, function (error) {
                    reject(error);
                  });
                });
              };

              this._errorCallback = () => {
                reject(new Error('Recaptcha validation failed'));
              };
            } else {
              resolve(data);
            }
          }, function (error) {
            reject(error);
          });
        });
      });
    });
  }

  validateWithRecaptchaCommon(getPromise, payload, onScore = null) {
    return this.validateWithRecaptcha(
      (captchaParams) => {
        if (captchaParams.scoreToken) {
          payload.score_token = captchaParams.scoreToken;
          payload.challenge_token = undefined;
        }

        if (captchaParams.challengeToken) {
          payload.challenge_token = captchaParams.challengeToken;
          payload.score_token = undefined;
        }

        return getPromise(payload).then((data) => {
          if (data?.data?.captcha_response?.score && typeof onScore === 'function') {
            onScore(data.data.captcha_response);
          }

          if (data?.data?.require_challenge) {
            return {
              showChallenge: true
            };
          }

          if (data?.data?.challenge_failed) {
            return {
              challengeFailed: true
            };
          }

          return data;
        });
      }
    );
  }
}
