import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class TiktokAdsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    return {
      accounts: this.fetchListFromRawData(rawData.ad_accounts || [], metaData.accountList)
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      ad_accounts: (data.accounts || []).map(account => account.id)
    };
  }

  metaDataResolver(sourceType: string, authData: any) {
    return PipelinesAPI.getTiktokAdsAccounts(authData.oauthTokenId).then((res: any) => ({
        accountList: res?.data?.adAccounts,
      })
    );
  }

  fetchListFromRawData(selectedList: string[], rawList: any[]) {
    return selectedList.map((id: string) => rawList.find((obj: any) => obj.id === id));
  }
}
