import { Typography } from '@mui/material';
import React from 'react';
import { HdCheckbox } from '../../UIElements/HdCheckbox';
import styles from './styles.module.scss';

export default function HdFormikCheckBox({ children, field, form, disabled, label, onChange }) {
  return (
    <>
      <HdCheckbox
        id={field.name}
        dataId={field.name}
        name={field.name}
        className={styles.checkbox}
        checked={!!field.value}
        disabled={disabled}
        label={label}
        onChange={(event: boolean) => {
          if (onChange) {
            onChange(event, field.name);
          } else {
            form.setFieldValue(field.name, event);
          }
        }}
      />

      <div className={`${styles.text}`}>
        <Typography variant='body2' component='label' htmlFor={field.name}>
          {children}
        </Typography>
      </div>
    </>
  );
}
