import { Address4, Address6 } from 'ip-address';

export function isLocal(host: string) {
  if (isLocalhost(host)) {
    return true;
  }

  if (isIPAddress(host)) {
    const ipAddress = getIPAddress(host);

    return ipAddress.isAnyLocalAddress()
      || ipAddress.isLoopbackAddress()
      || ipAddress.isLinkLocalAddress()
      || ipAddress.isSiteLocalAddress();
  }

  return false;
}

export function isLocalhost(host: string) {
  return host === 'localhost';
}

export function isIPAddress(host: string) {
  return Address4.isValid(host) || Address6.isValid(host);
}

export function getIPAddress(host: string) {
  if (Address4.isValid(host)) {
    return new IpAddressV4(host);
  }

  return new IpAddressV6(host);
}

export class IpAddress {
  constructor(public host: string) {
  }

  isAnyLocalAddress() {
    return false;
  }

  isLoopbackAddress() {
    return false;
  }

  isLinkLocalAddress() {
    return false;
  }

  isSiteLocalAddress() {
    return false;
  }
}

// tslint:disable:no-bitwise
export class IpAddressV4 extends IpAddress {
  ipAddress: Address4;

  constructor(public host: string) {
    super(host);

    this.ipAddress = new Address4(host);
  }

  isAnyLocalAddress() {
    let test = 0;
    for (let i = 0; i < 4; i++) {
      test |= parseInt(this.ipAddress.parsedAddress[i], 10);
    }
    return (test === 0);
  }

  isLoopbackAddress() {
    return this.ipAddress.isInSubnet(new Address4('127.0.0.0/8'));
  }

  isLinkLocalAddress() {
    return this.ipAddress.isInSubnet(new Address4('169.254.0.0/16'));
  }

  isSiteLocalAddress() {
    return this.ipAddress.isInSubnet(new Address4('10.0.0.0/8'))
      || this.ipAddress.isInSubnet(new Address4('172.16.0.0/12'))
      || this.ipAddress.isInSubnet(new Address4('192.168.0.0/16'));
  }
}

// tslint:disable:no-bitwise
export class IpAddressV6 extends IpAddress {
  ipAddress: Address6;

  constructor(public host: string) {
    super(host);

    this.ipAddress = new Address6(host);
  }

  isAnyLocalAddress() {
    let test = 0;
    for (let i = 0; i < this.ipAddress.parsedAddress.length; i++) {
      test |= parseInt(this.ipAddress.parsedAddress[i], 16);
    }
    return (test === 0);
  }

  isLoopbackAddress() {
    return this.ipAddress.isLoopback();
  }

  isLinkLocalAddress() {
    return this.ipAddress.isLinkLocal();
  }

  isSiteLocalAddress() {
    return this.ipAddress.isInSubnet(new Address6('fec0::/10'));
  }
}
