import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AllowedActionsService } from '../service/entity-actions.service';

@Injectable({
  providedIn: 'root'
})
export class FetchGlobalActionsGuard implements CanActivate {

  constructor(
    private allowedActionsService: AllowedActionsService
  ) {}

  canActivate() {
    return this.allowedActionsService.getAllowedActions(true, false).pipe(
      map(() => true),
      catchError(() => {
        return of(true);
      }) 
    )
}
}