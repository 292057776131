import { NO_SPACE_REGEX } from '../constants';
import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getRootDomain } from './utils/helpers';

export const NETSUITE_ANALYTICS_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'servicehost',
      fieldId: 'service-host',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'servicehost',
        label: 'Service host',
        required: true,
        prefixText: 'https://',
        suffixText: '.connect.api.netsuite.com'
      },
      hint: 'The host URL for the SuiteAnalytics Connect application, obtained from Settings → Set Up SuiteAnalytics Connect → Your Configuration',
      buildParamsKey: 'service_host',
      validations: [
        {
          type: 'required'
        }
      ],
      getRawValue: value => getRootDomain(value)
    },
    {
      key: 'serverDataSource',
      fieldId: 'server-data-source',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'serverDataSource',
        label: 'Server Data Source',
        disabled: true
      },
      hint: 'Netsuite2.com is Netsuite’s default, and recommended source',
      initialValue: 'NetSuite2.com',
      buildParamsKey: 'server_data_source',
      validations: []
    }
  ],
  [
    {
      key: 'servicePort',
      fieldId: 'service-port',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'number',
      widgetOptions: {
        name: 'servicePort',
        label: 'Service Port',
        required: true,
        type: 'number'
      },
      hint: 'The port number of your SuiteAnalytics service, obtained from Settings → Set Up SuiteAnalytics Connect → Your Configuration',
      buildParamsKey: 'service_port',
      validations: [
        {
          type: 'positive'
        },
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'accountId',
      fieldId: 'account-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'accountId',
        label: 'Account ID',
        required: true
      },
      hint: 'The NetSuite account ID, obtained from Settings → Set Up SuiteAnalytics Connect → Your Configuration',
      buildParamsKey: 'account_id',
      validations: [
        {
          type: 'matches',
          params: NO_SPACE_REGEX
        },
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'consumerKey',
      fieldId: 'consumer-key',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'consumerKey',
        label: 'Consumer Key',
        required: true
      },
      hint: 'The consumer key generated in SuiteAnalytics during integration record setup. ',
      docLink:
        '/sources/fna-analytics/suiteanalytics/#step-3-create-an-integration-record-for-hevo',
      buildParamsKey: 'consumer_key',
      validations: [
        {
          type: 'required'
        },
        {
          type: 'matches',
          params: NO_SPACE_REGEX
        }
      ]
    },

    {
      key: 'consumerSecret',
      fieldId: 'consumer-secret',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'consumerSecret',
        label: 'Consumer Secret',
        required: true
      },
      hint: 'The consumer secret associated with the consumer key. ',
      docLink:
        '/sources/fna-analytics/suiteanalytics/#step-3-create-an-integration-record-for-hevo',
      buildParamsKey: 'consumer_secret',
      validations: [
        {
          type: 'matches',
          params: NO_SPACE_REGEX
        },
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'tokenId',
      fieldId: 'token-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'tokenId', label: 'Token ID', required: true },
      hint: 'The token ID for the integration record, user, and role combination. ',
      docLink: '/sources/fna-analytics/suiteanalytics/#step-6-create-an-access-token',
      buildParamsKey: 'token_id',
      validations: [
        {
          type: 'matches',
          params: NO_SPACE_REGEX
        },
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'tokenSecret',
      fieldId: 'token-secret',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'tokenSecret',
        label: 'Token Secret',
        required: true
      },
      hint: 'The token secret in SuiteAnalytics associated with the Token ID. ',
      docLink: '/sources/fna-analytics/suiteanalytics/#step-6-create-an-access-token',
      buildParamsKey: 'token_secret',
      validations: [
        {
          type: 'matches',
          params: NO_SPACE_REGEX
        },
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'roleId',
      fieldId: 'role-id',
      fieldSize: 6,
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'number',
      widgetOptions: {
        name: 'roleId',
        label: 'Role ID',
        required: true,
        type: 'number'
      },
      hint: 'The ID of the data warehouse integrator role, obtained from Setup → Users/Roles → Manage Roles → INTERNAL ID.',
      docLink:
        '/sources/fna-analytics/suiteanalytics/#step-7-obtain-the-data-warehouse-integrator-role-id',
      buildParamsKey: 'role_id',
      validations: [
        {
          type: 'positive'
        },
        {
          type: 'required'
        }
      ]
    }
  ]
];
