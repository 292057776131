import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AboutRoutingModule } from './about-routing.module';
import { VersionReactComponent } from './version-react.component';

@NgModule({
  imports: [
    AboutRoutingModule,
    HttpClientModule
  ],
  declarations: [ VersionReactComponent ]
})
export class AboutModule { }
