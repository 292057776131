import { ModelsFactory } from './model-factory';
import { HevoEntity } from './hevo-entity';
import { timer } from 'rxjs';
import { SESSION_UPDATES_POLL_INTERVAL } from '../constants';
import { map } from 'rxjs/operators';
import { PercentageProgress } from './percentage-progress';

export interface SessionUpdate {
  updatedAt: number;
  entity: HevoEntity;
  entityId: number;
  affectedEntities: SessionUpdateEntity[];
  progress?: PercentageProgress; // We should create subclasses for specific fields.
}

export enum SessionUpdateEntity {
  TASKS = 'TASKS',
  SOURCE_OBJECTS = 'SOURCE_OBJECTS',
  EVENT_TYPES = 'EVENT_TYPES'
}

export function MockSessionUpdatesObservable() {
  let updatedAt = -1;
  return timer(0, SESSION_UPDATES_POLL_INTERVAL).pipe(
    map((count) => {
      updatedAt = count < 5 ? new Date().getTime() + 10000 : updatedAt;
      return MockSessionsUpdatesFactory(updatedAt);
    })
  );
}

export function MockSessionsUpdatesFactory(updatedAt): SessionUpdate[] {
  return [{
    updatedAt,
    entity: HevoEntity.PIPELINE,
    entityId: 1,
    affectedEntities: [SessionUpdateEntity.EVENT_TYPES],
    progress: {
      total: 10,
      current: 1
    }
  }];
}

export function SessionUpdatesFactory(rawData: any[]) {
  return ModelsFactory(rawData, SessionUpdateFactory);
}

export function SessionUpdateFactory(rawData: any): SessionUpdate {
  if (!rawData) {
    return;
  }

  return {
    updatedAt: rawData.updated_at,
    entity: HevoEntity[rawData.entity_type],
    entityId: rawData.entity_id,
    affectedEntities: rawData.affected_entity_types,
    progress: rawData.data
  };
}

export function getAffectedSessions(
  sessionUpdates: SessionUpdate[],
  entity?: HevoEntity,
  entityId?: number,
  secondaryEntity?: SessionUpdateEntity
) {
  return sessionUpdates.filter((sessionUpdate) => {
    if (entity && sessionUpdate.entity !== entity) {
      return false;
    }

    if (entityId && sessionUpdate.entityId !== entityId) {
      return false;
    }

    if (secondaryEntity && !(sessionUpdate.affectedEntities || []).includes(secondaryEntity)) {
      return false;
    }

    return true;
  });
}
