import { getExecutionPolicyFromRawData } from '../scheduler/utils';
import { EntityIndependentRunStatusFactory } from '../../legacy-utils/entity';
import { ModelStatusEnum } from './model-status';
import { Model, QueryHistoryItem } from './models';
import { QueryHistoryStatusEnum } from './query-history-status';

export function ModelFactory(rawData: any) {
  const model = new Model();

  model.id = rawData.id;
  model.seqId = rawData.seq_id;
  model.name = rawData.name;
  model.executionPolicy = getExecutionPolicyFromRawData(rawData.execution_policy);
  model.destination = rawData.destination;
  model.source = rawData.source;
  model.createdBy = rawData.created_by;
  model.updatedBy = rawData.updated_by;
  model.destinationSchemaName = rawData.dest_schema_name;
  model.destinationSchemaId = rawData.dest_schema_id;
  model.query = rawData.query;
  model.truncateAndLoad = rawData.truncate_and_Load;
  model.status = ModelStatusEnum[rawData.display_status.name];
  model.version = rawData.version;
  model.failureReason = rawData.failure_reason;
  model.independentRunStatus = EntityIndependentRunStatusFactory(rawData.independent_run_status);

  if (rawData.history) {
    model.history = QueryHistoryFactory([rawData.history]);
  }

  model.setLastRunTs();
  model.setFailureMsg();
  model.setActions();

  return model;
}

export function QueryHistoryFactory(rawData: any[]) {
  if (!rawData || !rawData.length) {
    return rawData;
  }

  return rawData.map((data) => {
    const history = new QueryHistoryItem();
    history.id = data.id;
    history.seqId = data.seq_id;
    history.startedTs = data.started_at;
    history.createdTs = data.created_ts;
    history.runTime = data.run_time;
    history.finishedTs = data.finished_ts;
    history.query = data.query;
    history.columns = (data.columns || '').split(', ');
    history.outputTable = data.table_name;
    history.destinationId = data.target_destination_id;
    history.failureMsg = data.failure_message;
    history.status = QueryHistoryStatusEnum[data.status.name];

    return history;
  });
}
