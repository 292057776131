import * as Yup from 'yup';
import {
  DESTINATION_TYPES,
  DestinationType
} from '../../../../../../app/nodes/destination-type/model';
import { SetupGuideLinkParams } from '../../../../../utils/setupGuideLinkHandler';
import {
  getJDBCErrorMessageForSynapse,
  hostNameValidatorSchema,
  localHostValidatorSchema
} from '../../../../../utils/validatorSchema';
import { DestinationConfigMetaData } from '../interface';
import AzureSynapse from './index';
import { getConnectionParamsFromURI } from './utils';

export const AZURE_SYNAPSE_CONFIG: DestinationConfigMetaData = {
  formComponent: AzureSynapse,
  initialValues: {
    isConnectionUri: 'true',
    URI: '',
    host: '',
    port: '',
    user: '',
    password: '',
    databaseName: '',
    schemaName: '',
    sqlSchema: '',
    useSSH: false,
    sshIp: '',
    sshPort: '',
    sshUser: '',
    sanitizeName: true,
    loadedAt: DESTINATION_TYPES.AZURE_SYNAPSE.populateLoadedAt
  },
  validationSchema(props: { destinationTypeMetaData: DestinationType }) {
    const destinationTypeIdentifier = props.destinationTypeMetaData.name;
    const sqlSchema: { [key: string]: any } = {
      host: Yup.string()
        .trim()
        .test(
          localHostValidatorSchema({
            type: 'SQL',
            typeIdentifier: destinationTypeIdentifier,
            localhostDocLinkParams: this.getLocalhostSBSLinkParams(destinationTypeIdentifier)
          })
        )
        .test(hostNameValidatorSchema({ type: 'SQL' }))
        .required(),
      port: Yup.number().positive().required(),
      user: Yup.string().trim().required(),
      password: Yup.string().trim().required(),
      databaseName: Yup.string().trim().required(),
      useSSH: Yup.boolean(),
      sshIp: Yup.string().when('useSSH', {
        is: true,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
      sshPort: Yup.string().when('useSSH', {
        is: true,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
      sshUser: Yup.string().when('useSSH', {
        is: true,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
      URI: Yup.string().when('isConnectionUri', {
        is: isConnectionUri => isConnectionUri === 'true',
        then: () =>
          Yup.string()
            .test({
              name: 'connectionStringValidation',
              message: () => getJDBCErrorMessageForSynapse(),
              test(value) {
                if (!value) {
                  return true;
                }
                const credentialObj = getConnectionParamsFromURI(value);
                if ('error' in credentialObj) {
                  return false;
                }
                return true;
              }
            })
            .required(),
        otherwise: () => Yup.string()
      })
    };

    return Yup.object().shape({ ...sqlSchema });
  },

  getLocalhostSBSLinkParams: destinationTypeIdentifier => {
    const params: SetupGuideLinkParams = {
      setupGuideLink: `${DESTINATION_TYPES[destinationTypeIdentifier].docLink}#connect-to-a-local-database`,
      section: 'destination_settings',
      label: 'db-host-field-local-host-steps',
      properties: {
        destinationType: destinationTypeIdentifier,
        context: 'Destination'
      }
    };
    return params;
  }
};
