import { SidelineMessageAction } from '../../sideline/models/sideline-message';

export const SIDELINE_ACTION_CLICK_TRACKING = {
  [SidelineMessageAction.DONE]: '[Source Object Sideline] done event click',
  [SidelineMessageAction.CREATE_MAPPING]: '[Source Object Sideline] create mapping click',
  [SidelineMessageAction.FIX_MAPPING]: '[Source Object Sideline] fix mapping click',
  [SidelineMessageAction.SKIP_OBJECT]: '[Source Object Sideline] skip object click',
  [SidelineMessageAction.VIEW_DETAILS]: '[Source Object Sideline] view details click',
  [SidelineMessageAction.MAP_SCHEMA]: '[Source Object Sideline] schema map click',
  [SidelineMessageAction.REPLAY]: '[Source Object Sideline] replay schema click',
  [SidelineMessageAction.SKIP]: '[Source Object Sideline] skip schema click',
  [SidelineMessageAction.VIEW_SAMPLE]: '[Source Object Sideline] show sample event click',
  [SidelineMessageAction.INCLUDE_OBJECT]: '[Source Object Sideline] include object click',
  [SidelineMessageAction.UPGRADE_PLAN]: '[Source Object Sideline] upgrade plan click',
  [SidelineMessageAction.SET_ON_DEMAND_CREDIT]: '[Source Object Sideline] set on demand credit click',
  [SidelineMessageAction.HIKE_ON_DEMAND_CREDIT]: '[Source Object Sideline] raise on demand credit click',
  [SidelineMessageAction.REDEEM_ON_DEMAND_CREDIT]: '[Source Object Sideline] redeem on demand credit click',
};

export const TRACKER_SOURCE_OBJECT_TASK_FAILURE_TROUBLESHOOT_LINK_CLICK = '[Source Object Task Failure] troubleshoot link click';

export const TRACKER_SOURCE_OBJECT_ACTION_CLICK = '[Source Object] action click';
export const TRACKER_SOURCE_OBJECT_REPLAY_SIDELINE_CLICK = '[Source Object] replay sideline click';
export const TRACKER_SOURCE_OBJECT_SKIP_SIDELINE_CLICK = '[Source Object] skip sideline click';
