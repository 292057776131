export const TRACKER_CHANGE_SCHEDULE_DIALOG_CUSTOM_CHIP_CLICK =
  '[Change Schedule Dialog] custom button click';
export const TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_DAILY_CLICK =
  '[Change Schedule Dialog] run daily button click';
export const TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_AT_FIXED_INTERVAL_CLICK =
  '[Change Schedule Dialog] run at fixed interval button click';
export const TRACKER_CHANGE_SCHEDULE_DIALOG_ADD_MORE_CRON_TIME_CLICK =
  '[Change Schedule Dialog] add more cron time button click';
export const TRACKER_CHANGE_SCHEDULE_DIALOG_DELETE_CRON_TIME_CLICK =
  '[Change Schedule Dialog] delete cron time button click';
export const SOURCE_RUN_DAILY_POP_UP_SHOWN = 'source_change_schedule_run_daily_tooltip_shown';
export const DESTINATION_RUN_DAILY_POP_UP_SHOWN =
  'destination_change_schedule_run_daily_tooltip_shown';
export const SOURCE_TOOLTIP_MESSAGE =
  'Select Run Daily to schedule Source ingestion jobs at custom time(s)';
export const DESTINATION_TOOLTIP_MESSAGE =
  'Select Run Daily to schedule Destination load jobs at custom time(s)';

export const CUSTOM_OPTION: any = {
  value: 'Custom',
  displayText: 'Custom',
  disabled: false
};

export const STREAMING_OPTION: any = {
  value: 'Streaming',
  displayText: 'Streaming',
  disabled: false,
  badgeContent: 'PRO',
  badgeColor: 'pro'
};
