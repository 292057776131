import React from 'react';
import { getShareDocLinkMessage } from '../../../../app/dialog/share-doc-dialog/utils';
import { HdDocLink, HdLink } from '../../UIElements';
import InviteMembersDialog from './index';
import useTeamSettingsService from '../../../hooks/services/useTeamSettingsService';

export interface SourceInviteMembersProps {
  entity: string;
  type: string;
  open: boolean;
  onClose: Function;
  docLink: string;
  renderAsDialog: boolean;
  sourceDisplayName: string;
  sourceId: string;
}

declare const __DOCS_CONFIG__: any;

export default function SourceInviteMembersDialog({
  entity,
  type,
  open,
  onClose,
  docLink,
  renderAsDialog,
  sourceDisplayName,
  sourceId
}: SourceInviteMembersProps) {
  const teamSettings = useTeamSettingsService();
  const draftSourceLinkAvailable =
    teamSettings.getTeamSettings()?.draft_pipeline_enabled && sourceId;
  const draftSourceLink = `${window.location.origin}/pipeline/draft-source/${sourceId}`;

  return (
    <InviteMembersDialog
      entity={entity}
      entityType={type}
      renderAsDialog={renderAsDialog}
      onClose={onClose}
      open={open}
      sourceId={sourceId}
      copyMessage={getShareDocLinkMessage(type, docLink, draftSourceLink)}
    >
      <div className='email-content flex-col text-body-1 position-relative'>
        <p className='mb-3'>Hi there,</p>
        <p>
          {!draftSourceLinkAvailable && (
            <>
              Can you help me configure a Pipeline in Hevo with <b /> as a Source. The related
              documentation for this is available at:
              <HdDocLink
                dataId='invite-source-doclink'
                section='dialog_doclink'
                docLink={docLink}
                label={__DOCS_CONFIG__.baseURL + docLink}
              />
            </>
          )}

          {draftSourceLinkAvailable && (
            <>
              Can you help me configure a Pipeline in Hevo with <b>{sourceDisplayName}</b> as a
              Source. I need assistance with <b>{sourceDisplayName}</b> Connection Settings. Here is
              the link to my Pipeline:
              <br />
              <HdLink dataId='draft-link' href={draftSourceLink}>
                {draftSourceLink}
              </HdLink>
              <br />
              <br />
              The related documentation for this is available at:
              <HdDocLink
                dataId='invite-source-doclink'
                section='dialog_doclink'
                docLink={docLink}
                label={__DOCS_CONFIG__.baseURL + docLink}
              />
            </>
          )}
        </p>
      </div>
    </InviteMembersDialog>
  );
}
