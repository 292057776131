import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import { EntityUIState, EntityUIStateWrapper } from '../../../../app/core/models/entitiy-ui-state';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { RecoveryCodesFactory } from '../../../components/TFAElements/factory';
import { TFARecoveryCode } from '../../../components/TFAElements/models';
import TFACodeList from '../../../components/TFAElements/TFACodeList';
import { downloadCodes, getDownloadCodeContent } from '../../../components/TFAElements/utils';
import { HdLink } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import useClipboardService from '../../../hooks/services/useClipboardService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AccountsAPI from '../../account/AccountsAPI';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useLoginGuard } from '../useLoginGuard';

export interface RecoveryCodesPageProps {
  featuredCustomers: FeaturedCustomers;
}

export function RecoveryCodesPage() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeLoginGuard } = useLoginGuard();

  return (
    <HdResolvedComponent
      Component={RecoveryCodesPageInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[executeLoginGuard]}
    />
  );
}

export function RecoveryCodesPageInner({ featuredCustomers }: RecoveryCodesPageProps) {
  const [codeList, setCodeList] = useState<TFARecoveryCode[]>([]);
  const [uiState, setUiState] = useState<EntityUIStateWrapper>({ state: EntityUIState.LOADING });

  const clipboardService = useClipboardService();
  const history = useHistory();
  const { search } = useLocation();

  const errored = uiState.state === EntityUIState.ERRORED;
  const loading = uiState.state === EntityUIState.LOADING;

  const nextURL = new URLSearchParams(search).get('next');

  useEffect(() => {
    getRecoveryCodes();
  }, []);

  const getRecoveryCodes = () => {
    setUiState({ state: EntityUIState.LOADING });
    AccountsAPI.getTfaRecoveryCodes()
      .then(({ data }) => {
        setUiState({ state: EntityUIState.IDLE });
        setCodeList(RecoveryCodesFactory(data.recovery_codes));
      })
      .catch(error => {
        setUiState({ state: EntityUIState.ERRORED, error });
      });
  };

  const copyToClipboard = () => {
    clipboardService.copy(getDownloadCodeContent(codeList), 'Recovery codes copied to clipboard');
  };

  const navigateToDashboard = () => {
    history.push(nextURL || '/');
  };

  const dataIdGenerator = getDataIdGenerator('recovery-codes-page');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.TFA_RECOVERY_CODES}
      featuredCustomers={featuredCustomers}
      loginContainerStyles={{ width: '600px' }}
    >
      {!errored ? (
        <>
          <TFACodeList uiState={uiState} codes={codeList} />

          <div className='mt-8 text-center'>
            <HdButton
              dataId={dataIdGenerator('copy')}
              icon='copy'
              disabled={loading}
              onClick={copyToClipboard}
            >
              Copy
            </HdButton>

            <HdButton
              dataId={dataIdGenerator('download')}
              icon='download'
              disabled={loading}
              className='ml-3'
              onClick={() => downloadCodes(codeList)}
            >
              Download
            </HdButton>
          </div>
        </>
      ) : (
        <RetryApiAlert
          dataId={dataIdGenerator('')}
          actionHandler={getRecoveryCodes}
          error={uiState.error}
        />
      )}

      <HdLink
        dataId={dataIdGenerator('goto-dashboard')}
        tag='button'
        icon='right'
        className='mt-4'
        onClick={navigateToDashboard}
      >
        Go to Dashboard
      </HdLink>
    </HdAuth>
  );
}
