import React from 'react';

import clsx from 'clsx';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';
import Tile from '../Tile';
import { TitleProps } from '../shared/interfaces';
import { calendarViews } from '../shared/const';

const className = 'react-calendar__decade-view__years__year';

export default function Year<T>({ classes, ...otherProps }: TitleProps<T>) {
  const { date } = otherProps;

  const { dateTimeAdapter } = useDateTimeAdapter<T>();

  return (
    <Tile {...otherProps} classes={clsx(classes, className)} view={calendarViews.MULTI_YEAR}>
      <>{dateTimeAdapter.getYearName(date)}</>
    </Tile>
  );
}
