export const S3_NEW_DEST_COMPRESSION_SUPPORTED: any[] = [
  {
    id: 'SNAPPY',
    name: 'SNAPPY',
    value: 'SNAPPY'
  },
  {
    id: 'UNCOMPRESSED',
    name: 'UNCOMPRESSED',
    value: 'UNCOMPRESSED'
  },
  {
    id: 'GZIP',
    name: 'GZIP',
    value: 'GZIP'
  }
];

export const s3NewConfigureDocLink =
  '/destinations/databases/s3/new/#s3-new-database-host';


export enum S3DestFileFormat {
  PARQUET = 'PARQUET',
  JSON = 'JSON'
}
