import { Collapse } from '@mui/material';
import React from 'react';
import { useHelpBanner } from '.';

interface HelpBannerProps {
  children: React.ReactNode;
  [key: string]: any;
}

export default function HelpBannerBody({ children, className, ...props }: HelpBannerProps) {
  const { expanded } = useHelpBanner();
  return (
    <div className={className || ''} {...props}>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  );
}
