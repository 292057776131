export const stringToBool = (str: 'true' | 'false') => str === 'true';

export const toArray = array => (Array.isArray(array) ? array : []);

export const removeDuplicatesFromArrayOfPrimitives = <T>(collection: T[]) => [
  ...new Set(collection)
];

export const getMinimumDigitsFromNumber = (number: number, digits: number) =>
  number.toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false });
