import { Enum, EnumVal } from '../../../../../app/core/models/object-enum';
import { ActivationRunStageEnum } from './run-stages';


export class StatsRunStageInfo extends EnumVal {
  constructor(
    public name: string,
    public value: ActivationRunStageEnum,
    public stageIcon: string,
    public rawValue: string
  ) {
    super(name);
  }
}

export class StatsRunStages extends Enum {
  static INGESTION = new StatsRunStageInfo(
    'Ingestion',
    ActivationRunStageEnum.INGESTION,
    'database',
    'INGESTED'
  );
  static TRANSFORMATION = new StatsRunStageInfo(
    'Transformations',
    ActivationRunStageEnum.TRANSFORMATION,
    'transformation',
    'TRANSFORMED'
  );
  static LOADING = new StatsRunStageInfo(
    'Loading',
    ActivationRunStageEnum.LOADING,
    'destinations',
    'LOADED'
  );
}
