export class StorageKey {
  static CREATE_PIPELINE = 'pipeline.general.create.wizard';
  static CREATE_DRAFT_PIPELINE_SOURCE = 'pipeline.create.draft_source';
  static CREATE_DRAFT_PIPELINE_DESTINATION = 'pipeline.create.draft_destination';
  static FREE_TRIAL_BANNER_SHOWN = 'pipeline.show_free_trial_banner';
  static SOURCE_TYPE_TRIAL_BANNER_SHOWN = 'pipeline.show_trial_info_banner_for_source_type';
  static OBJECTS_PERMISSION_DENIED_BANNER_SHOWN = 'pipeline.objects.permission_denied_banner';
  static SNACKBAR_BANNER_SHOWN = 'pipeline.snackbar_banner_shown';
  static MANAGED_WAREHOUSE_ALERT_SHOWN = 'pipeline.queryManagedWarehouseAlert';

  static STATS_FILTER = 'statsFilter';
  static YOUTUBE_ANALYTICS_ALERT = 'youtubeAnalyticsAlert';
  static BIGQUERY_ALERT = 'bigquerySourceAlert';
  static GOOGLE_ANALYTICS_4_ALERT = 'googleAnalytics4Alert';
  static BINLOG_RETENTION_WARNING = 'show_binlog_retention_warning';
  static SIDELINE_SUMMARY = 'sidelineSummary';
  static REAUTHORIZED_WARNING = 're-authorized';
  static SPLASH_SCREEN_FEEDBACK_CTA = 'splashScreen.feedback.cta.click';
  static RESTART_OBJECT_BANNER_SHOWN = 'restart_object_banner_shown';

  static getStatsFilterKey(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.STATS_FILTER }`;
  }

  static getYoutubeAnalyticsAlertKey(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.YOUTUBE_ANALYTICS_ALERT }`;
  }

  static getBigqueryAlertKey(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.BIGQUERY_ALERT }`;
  }

  static getGoogleAnalytics4Key(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.GOOGLE_ANALYTICS_4_ALERT }`;
  }

  static getBinlogRetentionWarningKey(pipelineSeqId: number) {
    return `pipeline.${ pipelineSeqId }.${ this.BINLOG_RETENTION_WARNING }`;
  }

  static getSidelineSummaryKey(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.SIDELINE_SUMMARY }`;
  }

  static getReauthorizedWarningKey(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.REAUTHORIZED_WARNING }`;
  }

  static getSplashScreenFeedbackCTAKey(pipelineId: number) {
    return `pipeline.${ pipelineId }.${ this.SPLASH_SCREEN_FEEDBACK_CTA }`;
  }

  static getRestartObjectBannerKey(pipelineId: number, objectName: string) {
    return `pipeline.${ pipelineId }.${objectName}.${ this.RESTART_OBJECT_BANNER_SHOWN }`;
  }
}
