import React from 'react';

function isBuffer(obj) {
  return (
    obj &&
    obj.constructor &&
    typeof obj.constructor.isBuffer === 'function' &&
    obj.constructor.isBuffer(obj)
  );
}
// { a : [{b : { c : 'a'} } ] } => a[0].b.c
// created to match yup object style
export function flatten(target: object = {}) {
  const delimiter = '.';
  const output = {};

  function step(object, prev, isPreviousEleArray = false) {
    Object.keys(object).forEach(key => {
      // Ignoring Hidden Keys to avoid self loops in case of inclusive list items
      // As a practice please do not create fields begining with '_'
      if (key.startsWith('_')) {
        return;
      }
      const value = object[key];
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isReactElement = React.isValidElement(value);
      const isobject = (type === '[object Object]' || type === '[object Array]') && !isReactElement;
      let newKey;

      newKey = prev ? prev + delimiter + key : key;

      if (isPreviousEleArray) {
        newKey = `${prev}[${key}]`;
      }

      if (!isbuffer && isobject && Object.keys(value).length) {
        step(value, newKey, Array.isArray(value));
        return;
      }

      output[newKey] = value;
    });
  }

  step(target, '');

  return output;
}
