import { Injectable } from '@angular/core';
import { appendTrackingParams } from '../../../react/legacy-utils/docs';
import { UserService } from './user.service';
import { TrackerConfig } from '../models/tracking';
import { Angulartics2 } from 'angulartics2';
import { AnsibleBoolean } from '../models/ansible-config';

declare var __DOCS_CONFIG__: any;

export let _docLinkHandlerService: DocLinkHandlerService;

@Injectable({ providedIn: 'root' })
export class DocLinkHandlerService {
  constructor(
    private _userService: UserService,
    private _angulartics2: Angulartics2
  ) {
    _docLinkHandlerService = this;
  }

  openLink(path: string, section: string, properties?: { [key: string]: any }) {
    const url = this.getLinkURL(path, section, properties);

    window.open(
      url,
      '_blank'
    );

    this.trackClick(path, section, properties);
  }

  getLinkURL(path, section, properties) {
    if (typeof __DOCS_CONFIG__ === 'undefined') {
      return null;
    }

    const url = __DOCS_CONFIG__.baseURL + path;

    if (this._isTrackingEnabled(this._userService.getUser(), __DOCS_CONFIG__)) {
      return appendTrackingParams(url, section, this._userService.getUser(), properties);
    }

    return url;
  }

  trackClick(path, section, properties) {
    this._angulartics2.eventTrack.next({
      action: '[Docs] link click',
      properties: {
        path,
        section,
        ...properties
      }
    });
  }

  private _isTrackingEnabled(user, config: TrackerConfig) {
    if (typeof config === 'undefined' || config.enabled !== AnsibleBoolean.TRUE) {
      return false;
    }

    const enabledForHevo = config.enabledForHevo === AnsibleBoolean.TRUE;

    if (user && user.email.includes('@hevodata.com') && !enabledForHevo) {
      return false;
    }

    return true;
  }
}
