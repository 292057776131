import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AbExperiment } from '../models/ab-experiments';
import { TrialDetailsService } from './trial-details.service';
import { VWOService } from './vwo.service';


@Injectable()
export abstract class AbExperimentService {
  protected _isUserInTrial = false;
  private _isExperimentActive$ = new BehaviorSubject<boolean>(false);

  isExperimentActive$ = this._isExperimentActive$.asObservable();

  protected constructor(
    protected _vwoService: VWOService,
    protected _trialDetailsService: TrialDetailsService,
    protected _experimentName: AbExperiment
  ) {
    this._trialDetailsService.trialDetails$.pipe(
      take(1)
    ).subscribe((data) => {
      this._isUserInTrial = data.isInTrial();
    });
  }

  protected abstract _isExperimentApplicable(): boolean;

  abstract isExperimentVariant(variant: any): boolean;

  isExperimentNodeType?(nodeTypeIdentifier: string);

  startExperimentIfApplicable() {
    if (this._isExperimentApplicable()) {
      this._vwoService.activateExperiment(this._experimentName);
      this.updateExperimentStatus(true);
    }
  }

  triggerConversionIfApplicable() {
    if (this._isExperimentApplicable()) {
      this._vwoService.triggerExperimentConversion(this._experimentName);
    }
  }

  getExperimentName() {
    return this._experimentName;
  }

  isExperimentActive() {
    return this._isExperimentActive$.getValue();
  }

  updateExperimentStatus(isActive: boolean) {
    this._isExperimentActive$.next(isActive);
  }
}
