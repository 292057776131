// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.troubleshootGuideContainer_taruf {\n  height: 100%;\n  width: 100%;\n  background-color: var(--surface-bg-color);\n  position: relative;\n}\n\n.iframeContainer_UdCS3 {\n  height: 100%;\n  width: 100%;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"troubleshootGuideContainer": "troubleshootGuideContainer_taruf",
	"iframeContainer": "iframeContainer_UdCS3"
};
export default ___CSS_LOADER_EXPORT___;
