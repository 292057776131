export const TRACKER_EDIT_SOURCE_ICON_CLICK = '[Pipeline Overview] edit source config edit click';
export const TRACKER_MIGRATE_TO_SERVICE_ACCOUNT_CLICK = '[Pipeline Overview] migrate to service account click';
export const TRACKER_EDIT_DESTINATION_ICON_CLICK = '[Pipeline Overview] edit destination config edit click';

export const TRACKER_PIPELINE_OVERVIEW_MORE_ICON_BUTTON_CLICK = '[Pipeline Overview] more icon button click';

export const TRACKER_PIPELINE_OVERVIEW_SPLASH_SCREEN_FEEDBACK_SUBMITTED = '[Pipeline Overview] splash screen feedback submitted';
export const TRACKER_PIPELINE_OVERVIEW_SPLASH_SCREEN_FEEDBACK_CANCELLED = '[Pipeline Overview] splash screen feedback cancelled';

export const TRACKING_AUTO_MAPPING_TOUR_ENABLE_AUTOMAPPING = '[Auto Mapping Tour] enable automapping';
export const TRACKING_AUTO_MAPPING_WIDGET_ENABLE = '[Auto Mapping Widget] enable';
export const TRACKING_AUTO_MAPPING_WIDGET_DISABLE = '[Auto Mapping Widget] disable';
export const TRACKING_AUTO_MAPPING_TOUR_CLOSE_CLICK = '[Auto Mapping Tour] dialog close';
export const TRACKING_AUTO_MAPPING_TOUR_DOC_CLICK = '[Auto Mapping Tour] open doc';

export const TRACKER_EPM_MOUSE_OVER = '[Pipeline Graphs] EPM mouse over';

export const TRACKER_PIPELINE_SHOW_LOG_EXPIRY_ERROR_FIX_NOW_BUTTON = '[Pipeline Log Expiry Error] show fix now button';
export const TRACKER_PIPELINE_LOG_EXPIRY_ERROR_FIX_NOW_CLICK = '[Pipeline Log Expiry Error] fix now click';
export const TRACKER_LOG_EXPIRY_HANDLER_DIALOG_SUBMIT_CLICK = '[Pipeline Log Expiry Error] log expiry handler dialog submit click';

export const TRACKER_MAPPING_REQUIRED_ALERT_MAP_EVENTS_CLICK = '[Firebolt] mapping required alert map manually button click';

export const TRACKER_SHOW_PIPELINE_EXECUTION_POLICY_DIALOG = '[Pipeline Overview] show execution policy dialog';

export const TRACKER_FREE_TRIAL_EVENT_ON_HOVER = '[Pipeline Overview] tracking free trial badge event on hover';
export const TRACKER_FREE_TRIAL_ENDED_UPGRADE_PLAN_CLICK = '[Pipeline Overview] free trial ended upgrade plan click';

export const TRACKER_CREATE_PIPELINE_DROP_SUPPORT_PROMPT_OPEN = '[Create Pipeline Drop Support Prompt] open';
export const TRACKER_CREATE_PIPELINE_DROP_SUPPORT_PROMPT_CHAT_CLOSE_CLICK = '[Create Pipeline Drop Support Prompt] chat close click';
export const TRACKER_CREATE_PIPELINE_DROP_SUPPORT_PROMPT_CHAT_CANCEL_CLICK = '[Create Pipeline Drop Support Prompt] chat cancel click';
export const TRACKER_CREATE_PIPELINE_DROP_SUPPORT_PROMPT_CHAT_CLICK = '[Create Pipeline Drop Support Prompt] chat click';

export const TRACKER_DESTINATION_RECOMMENDATIONS_BANNER_CLICK_IN_SOURCE = '[Pipeline Overview] destination recommendations banner click';
export const TRACKER_DESTINATION_RECOMMENDATIONS_DISMISS_CLICK_IN_SOURCE = '[Pipeline Overview] destination recommendations dismiss click';
export const TRACKER_DESTINATION_RECOMMENDATIONS_BANNER_CLICK_IN_DESTINATION = '[Destination Overview] destination recommendations banner click';
export const TRACKER_DESTINATION_RECOMMENDATIONS_BULB_CLICK_IN_DESTINATION = '[Destination Overview] destination recommendations bulb click';
export const TRACKER_DESTINATION_RECOMMENDATIONS_DISMISS_CLICK_IN_DESTINATION = '[Destination Overview] destination recommendations dismiss click';
export const TRACKER_DESTINATION_RECOMMENDATIONS_SELECTED_RECOMMENDATIONS = '[Destination Recommendations] selected recommendations';
export const TRACKER_DESTINATION_RECOMMENDATIONS_SELECTED_RECOMMENDATIONS_CONFIRM = '[Destination Recommendations] confirm selected recommendations';

export const TRACKER_SLA_AUTO_MAPPING_CLICK = '[SLA Widget] enable auto mapping click';
export const TRACKER_SLA_MAP_MANUALLY_CLICK = '[SLA Widget] map manually click';
export const TRACKER_SLA_CLOSE_BUTTON_CLICK = '[SLA Widget] close button click';
export const TRACKER_SLA_DOCS_LINK_CLICK = '[SLA Widget] docs link click';
export const TRACKER_SLA_EXPLORE_DATA_CLICK = '[SLA Widget] explore data click';
export const TRACKER_SLA_EDIT_SOURCE_CONFIG_CLICK = '[SLA Widget] edit source configuration click';

export const TRACKER_DRAFT_PIPELINE_TOASTER_DELETE_CLICK = '[Draft Pipeline] toaster delete click';
export const TRACKER_DRAFT_PIPELINE_DRAWER_DELETE_CLICK = '[Draft Pipeline] drawer delete click';
export const TRACKER_DRAFT_PIPELINE_DRAWER_LIST_ITEM_CLICK = '[Draft Pipeline] drawer list item click';
export const TRACKER_DRAFT_PIPELINE_DIALOG_DELETE_CONFIRM = '[Draft Pipeline] dialog delete confirm click';
export const TRACKER_DRAFT_PIPELINE_DIALOG_CANCEL_CLICK = '[Draft Pipeline] dialog cancel click';
export const TRACKER_SELECT_EXISTING_DRAFT_DESTINATION_ITEM = '[Draft Destination] draft destination item click';

export const TRACKER_DRAFT_PIPELINE_DRAWER_FILTER_PANE_CLICK = '[Draft Pipeline] drawer filter pane click'

export const TRACKER_PIPELINE_SHOW_SPIKE_ALERT_DIALOG = '[Pipeline Overview] show data spike alert dialog';
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_LEARN_MORE_CLICK = '[Pipeline Spike Alert Dialog] learn more click'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_SET_ALERT_CLICK = '[Pipeline Spike Alert Dialog] set alert click'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_CANCEL_CLICK = '[Pipeline Spike Alert Dialog] cancel click'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_CLOSE_CLICK = '[Pipeline Spike Alert Dialog] close click'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_ALERT_DISABLED = '[Pipeline Spike Alert Dialog] alert disabled'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_DATE_RANGE_CHANGE = '[Pipeline Spike Alert Dialog] data range change'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_DAILY_EVENTS_LIMIT = '[Pipeline Spike Alert Dialog] daily events limit'
export const TRACKER_PIPELINE_SPIKE_ALERT_DIALOG_PAUSE_PIPELINE_CLICK = '[Pipeline Spike Alert Dialog] pause pipeline click'

export const TRACKER_SPIKE_ALERT_TOUR_CLOSE_CLICK = '[Spike Alert Tour] close click';
export const TRACKER_SPIKE_ALERT_TOUR_SKIP_CLICK = '[Spike Alert Tour] skip click'
export const TRACKER_SPIKE_ALERT_TOUR_DONE_CLICK = '[Spike Alert Tour] done click'
export const TRACKER_SPIKE_ALERT_TOUR_EXPLORE_CLICK = '[Spike Alert Tour] explore click'
