import { TabProps } from '../models';
import { AccountAccess } from './AccountAccess';
import Authentication from './Authentication';
import Cloudwatch from './Cloudwatch';
import DeleteAccount from './DeleteAccount';
import { Members } from './Members';
import { MemberRole } from './models';
import { Timezone } from './Timezone';

export const TEAM_TABS: Array<TabProps> = [
  {
    Component: Members,
    label: 'Members',
    route: '/team/members',
    icon: 'team'
  },
  {
    Component: Cloudwatch,
    label: 'Activity Logs',
    route: '/team/cloudwatch',
    icon: 'cloudwatch'
  },
  {
    Component: Authentication,
    label: 'Authentication',
    route: '/team/authentication',
    icon: 'authentication',
    visibilityResolver: ({ isOwner }) => isOwner
  },
  {
    Component: Timezone,
    label: 'Timezone',
    route: '/team/timezone',
    icon: 'timezone'
  },
  {
    Component: AccountAccess,
    label: 'Data & Privacy',
    route: '/team/account-access',
    icon: 'account-access'
  },
  {
    Component: DeleteAccount,
    label: 'Delete Account',
    route: '/team/delete-account',
    className: 'error',
    icon: 'delete'
  }
];

export const MEMBER_ROLES: MemberRole[] = [
  {
    name: 'Owner',
    value: 1,
    id: '1'
  },
  {
    name: 'Member',
    value: 2,
    id: '2'
  }
];
