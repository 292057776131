import useToaster from './useToaster';

declare type CopyFn = (text: string, successMessage?: string) => void;

export function useReactClipboard(): { copy: CopyFn } {
  const toaster = useToaster();

  const copy: CopyFn = async (text, successMessage) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    navigator.clipboard.writeText(text)
      .then(() => {
        handleClipboardResponse(successMessage);
      })
      .catch((e) => {
        console.warn('Copy failed', e);
      });
  };

  const handleClipboardResponse = (successMessage?: string) => {
    if (successMessage && successMessage.length) {
      toaster.pop('success', undefined, successMessage);
    }
  };

  return {
    copy
  };
}
