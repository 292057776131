import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const ACTIVE_CAMPAIGN_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPIKeyField({
        hint: 'Enter the API Key from “Home“ → “Settings“ → “Developer“ → “Copy API Key“',
      })
    }
  ],
  [
    {
      key: 'accountName',
      fieldId: 'account-name',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'accountName', label: 'Account Name', required: true },
      hint: 'Enter the Account Name from “Home“ → “Settings“ → “Developer“ → “Copy Account Name from API Url“',
      buildParamsKey: 'account_name',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
