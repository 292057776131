export const TRACKER_GLOBAL_SEARCH_OPEN = '[Search Anything] open';
export const TRACKER_GLOBAL_SEARCH_CLOSE = '[Search Anything] close';
export const TRACKER_GLOBAL_SEARCH_FILTER_ENTITIES = '[Search Anything] filter entities';
export const TRACKER_GLOBAL_SEARCH_SUGGEST_FEATURE_CLICK = '[Source Type] suggest feature click';
export const TRACKER_GLOBAL_SEARCH_SELECT_ITEM = '[Search Anything] select item';
export const TRACKER_GLOBAL_SEARCH_ENTITY_DOCS_INTERACTION =
  '[Search Anything] entity docs interaction';
export const TRACKER_GLOBAL_SEARCH_NO_RESULT = '[Search Anything] no results found';
export const TRACKER_GLOBAL_ATTEMPT_TO_CREATE_PIPELINE_WITH_NO_PERMISSION =
  '[Search Anything] attempt to create pipeline with no permission';
export const TRACKER_GLOBAL_SEARCH_ATTEMPT_TO_CREATE_DESTINATION_WITH_NO_PERMISSION =
  '[Search Anything] attempt to create destination with no permission';
export const TRACKER_GLOBAL_SEARCH_ATTEMPT_TO_ACCESS_ROUTE_WITH_NO_PERMISSION =
  '[Search Anything] attempt to access route with no permission';
export const GLOBAL_SEARCH_UTM_MEDIUM = 'GLOBAL_SEARCH';
