// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.upgradeToLiveChatBox_NRR3M {\n  display: flex;\n  flex-direction: column;\n  padding: 24px;\n}\n\n.title_awOGP {\n  font-size: 16px;\n  flex-shrink: 0;\n}\n\n.supportRequestText_p_PoH {\n  line-height: 18px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upgradeToLiveChatBox": "upgradeToLiveChatBox_NRR3M",
	"title": "title_awOGP",
	"supportRequestText": "supportRequestText_p_PoH"
};
export default ___CSS_LOADER_EXPORT___;
