export const CREATE_TARGET_CACHE_KEY = 'activation.general.create-target.wizard';

export const TABLE_LIST_PAGE_LIMIT = 20;

export const TARGET_AUTHORISATION_FAILED_ERROR_CODE = [ 11002, 11003 ];

export const TRACKER_CREATE_TARGET_CLICK = '[Activation Targets] create target click';
export const TRACKER_CREATE_TARGET_SHORTCUT_CLICK = '[Activations Targets] create target shortcut key';

export const TRACKER_DELETE_TARGET_CLICK = '[Activation Targets] delete target click';
export const TRACKER_DELETE_TARGET_CONFIRM = '[Activation Targets] delete target confirm';

export const TRACKER_SELECT_TARGET_TYPE = '[Activation Target Type] select type';
export const TRACKER_TARGET_TYPE_EMPTY_RESULT = '[Activation Target Type] empty result';
export const TRACKER_SUGGEST_TARGET_CLICK = '[Activation Target Type] suggest target click';
export const TRACKER_SELECT_EXISTING_TARGET = '[Activation Target List] select existing target';
export const TRACKER_TARGET_LIST_EMPTY_RESULT = '[Activation Target List] empty result list';
export const TRACKER_ADD_NEW_TARGET_CLICK = '[Activation Target List] add new target click';

export const TRACKER_TARGET_CONFIG_POPUP_REAUTHORISE_CLICK = '[Target Config Popup] reauthorise target click';

export const REAUTHORISE_TARGET_CONFIRMATION_BODY = 'Do you have the required permissions to synchronize data with this Target?' +
  ' Your Activations may fail if you do not have the required permissions.';

export const REAUTHORISE_TARGET_DOC_LINK =
  '/getting-started/connection-options/reauthorizing-oauth-account/#target-account-reauthorization';
