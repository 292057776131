import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import HdFormikPasswordField from '../../../../../components/FormikElements/HdFormikPasswordField';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import HdFormikTextField from '../../../../../components/FormikElements/HdFormikTextField';
import { LoadedAt, SanitizeName } from '../../../../../components/NodeConfigOptions';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import useLocalHostTracker from '../../../../../hooks/useLocalHostTracker';
import { ConfigDestinationTypeBaseProps } from '../interface';

export default function Databricks({
  isEditing,
  destinationTypeMetaData,
  isDestinationModeWarehouse,
  maskSecretFields,
  frozenFields
}: ConfigDestinationTypeBaseProps) {
  const formikProps = useFormikContext<{ serverHostname: string; schemaName: string }>();
  useLocalHostTracker({
    value: formikProps.values.serverHostname,
    hdLocalHostValidatorDisabled: false
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='serverHostname'
              label='Server Hostname'
              helperText='The DNS name of your database server'
              disabled={!!frozenFields.serverHostname}
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='port'
              label='Database Port'
              helperText='The port on which the database is accepting connections'
              disabled={!!frozenFields.port}
              required
              component={HdFormikTextField}
              type='number'
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='httpPath'
              label='HTTP Path'
              disabled={!!frozenFields.httpPath}
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='personalAccessToken'
              label='Personal Access Token (PAT)'
              helperText='PAT: The personal access token generated in Databricks'
              disabled={!!frozenFields.personalAccessToken}
              required
              masked={maskSecretFields}
              component={HdFormikPasswordField}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      {destinationTypeMetaData.provideSchemaName ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <HdFormControl>
              <Field name='catalogName' label='Catalog Name' disabled={!!frozenFields.catalogName} component={HdFormikTextField} />
            </HdFormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <HdFormControl>
              <Field name='schemaName' label='Schema Name' component={HdFormikTextField} required />
            </HdFormControl>
          </Grid>
        </Grid>
      ) : null}

      {destinationTypeMetaData.populateLoadedAt && !isDestinationModeWarehouse ? (
        <AdvancedConfig forceExpand={formikProps.isSubmitting} showBorderBottom>
          <LoadedAt />

          <SanitizeName
            disabled={!!isEditing}
          />

          <DeltaTable frozenFields={frozenFields} />

          <HdFormikSwitchWrapper fieldName='vacuumTables' label='Vacuum Delta Tables'>
            If enabled, Hevo runs the Vacuum operation every weekend to delete uncommitted files
            and optimize your Delta tables.
          </HdFormikSwitchWrapper>

          <HdFormikSwitchWrapper fieldName='optimizeTables' label='Optimize Delta Tables'>
            If enabled, Hevo runs the Optimize operation every weekend to optimize the layout of
            the data and improve the query speed.
          </HdFormikSwitchWrapper>
        </AdvancedConfig>
      ) : null}
    </>
  );
}

export function DeltaTable({ frozenFields }) {
  const formikProps = useFormikContext<{
    externalLocationToggle: boolean;
    externalLocation: string;
  }>();
  return (
    <>
      <HdFormikSwitchWrapper
        fieldName='externalLocationToggle'
        disabled={!!frozenFields.externalLocationToggle}
        label='Create Delta Tables in External Location'
        onChange={(checked: boolean) => {
          if (!checked) {
            formikProps.setValues({
              ...formikProps.values,
              externalLocation: '',
              externalLocationToggle: checked
            });
          } else {
            formikProps.setFieldValue('externalLocationToggle', checked);
          }
        }}
      >
        <span>
          If enabled, tables are created externally instead of in the Databricks file system
          location registered with the cluster.
        </span>
      </HdFormikSwitchWrapper>

      {formikProps.values.externalLocationToggle ? (
        <Grid container spacing={2} className='mt-2 ml-7'>
          <Grid item xs={12} md={6.5}>
            <HdFormControl>
              <Field
                disabled={!!frozenFields.externalLocation}
                required
                name='externalLocation'
                label='External table location'
                helperText='Enter the location in { externallocation } / { schema } / { table } format'
                component={HdFormikTextField}
              />
            </HdFormControl>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
