import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

import { Dialog } from '../models';
import { fadeAnim, popupAnim } from '../../dialog/animations';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DialogBase } from '../dialog-base';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { MapperService } from '../../core/service/mapper.service';
import { SchemaConstraint, SchemaConstraintType } from '../../schema-mapper/models';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';

@Component({
  selector: 'add-primary-key-dialog',
  templateUrl: './add-primary-key-dialog.component.html',
  styleUrls: [ './add-primary-key-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class AddPrimaryKeyDialogComponent extends DialogBase implements OnInit, Dialog {
  columns: any[] = [];
  suggestions: any[] = [];
  selectionMode = true;
  dropdownSelectedKey: string;
  submitting = false;
  primaryKeyAdded = false;
  formError: string;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: any,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _mapperService: MapperService
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
    this.suggestions = [ ...this._data.suggestions ];
  }

  onSubmit() {
    this.submitting = true;
    this.formError = null;

    const constraints: SchemaConstraint[] = [ {
      constraintType: SchemaConstraintType.PRIMARY_KEY,
      columns: this.columns.map(column => column)
    } ];

    this._mapperService.addConstraints(this._data.destinationId, this._data.schemaName, constraints).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((res) => {
      this._dialogResponse = true;
      this.submitting = false;
      this.primaryKeyAdded = true;
    }, (err: any) => {
      this.formError = getErrorMessageFromObj(err);
      this.submitting = false;
    });
  }

  onPositiveClick() {
    super.onPositiveClick();
    this.hide(true);
  }

  removeKey(index) {
    this.suggestions.push(this.columns[index]);
    this.suggestions = [ ...this.suggestions ];
    this.columns.splice(index, 1);
    if (!this.columns.length) {
      this.selectNewKey();
    }
  }

  selectNewKey() {
    this.selectionMode = true;
  }

  onSelect(key) {
    this.columns.push(key);
    this.suggestions.splice(
      this.suggestions.findIndex((suggestion) => suggestion.name === key.name), 1
    );
    this.suggestions = [ ...this.suggestions ];
    this.selectionMode = false;
    this.dropdownSelectedKey = null;
  }
}
