// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.migrationModal_pRimL {\n  z-index: 1065 !important;\n}\n.migrationModal_pRimL .innerContentContainer_cZoiP {\n  height: 100vh;\n}\n\n.hide_bGK2P {\n  position: absolute !important;\n  top: 20px;\n  left: 20px;\n}\n\n.modalContentContainer_RPxLE {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n}\n\n.header__5kH7 {\n  padding-top: 42px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.header__5kH7 .iconBg_ICHkD {\n  width: 44px;\n  height: 44px;\n  border-radius: 8px;\n  background: var(--success-faded-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.header__5kH7 .iconBg_ICHkD .success_bjSPT {\n  color: var(--success-color);\n}\n.header__5kH7 .migrationWarningPane_tgHmB {\n  max-width: 1015px;\n  padding: 12px;\n  margin-top: 32px;\n}\n\n.actionContainer_YaEGi {\n  display: flex;\n  padding-top: 32px;\n  padding-bottom: 32px;\n}\n\n.dialogLoader__UAt4 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"migrationModal": "migrationModal_pRimL",
	"innerContentContainer": "innerContentContainer_cZoiP",
	"hide": "hide_bGK2P",
	"modalContentContainer": "modalContentContainer_RPxLE",
	"header": "header__5kH7",
	"iconBg": "iconBg_ICHkD",
	"success": "success_bjSPT",
	"migrationWarningPane": "migrationWarningPane_tgHmB",
	"actionContainer": "actionContainer_YaEGi",
	"dialogLoader": "dialogLoader__UAt4"
};
export default ___CSS_LOADER_EXPORT___;
