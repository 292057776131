import React, { useCallback, useState, useRef, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styles from './styles.module.scss';
import SearchArea from '../../SearchArea';
import { HdIcon, HdIconButton } from '../../UIElements';
import { MigratePipelinesFilters } from './Filters';
import { NoItemBox } from '../../../containers/drawer/Shared/NoItemBox';
import { useLoadItems } from '../../../containers/drawer/useLoadItems';
import { getDataIdsFromContract } from '../../../utils/generateDataId';
import { PipelineListTable } from './PipelineListTable';
import { Filter } from '../../../../app/filter/models/filter';
import { Pipeline } from '../../../../app/pipeline/models/pipeline';

export const dataIds = getDataIdsFromContract({
  base: 'pipeline-list',
  search: 'search',
  filtersBtn: 'filters-btn',
  selectAll: 'select-all',
  updateFrequencyBulkBtn: 'update-btn'
});

interface PipelineListProps {
  pipelinesCount: number;
  filteredPipelines: Array<Pipeline & { sync_execution_policy: any; updated_manually?: boolean }>;
  updateSyncFrequency: Function;
  listFilters: Filter<any>[];
  updateFilter: (index: number, filter: any, isSearch?: boolean) => void;
}

export function PipelinesList({
  pipelinesCount,
  filteredPipelines,
  updateSyncFrequency,
  listFilters,
  updateFilter
}: PipelineListProps) {
  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const { hasNextPage, appendList, resetList, updateList, listItems } = useLoadItems(
    20,
    filteredPipelines
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: false,
    hasNextPage,
    onLoadMore: appendList,
    disabled: false,
    rootMargin: '0px 0px 40px 0px'
  });

  const resetPagination = useRef(false);
  const bodyRef = useRef(null);

  useEffect(() => {
    if (resetPagination.current) {
      resetList();
      resetScroll();
      resetPagination.current = false;
    } else {
      updateList();
    }
  }, [filteredPipelines]);

  useEffect(() => {
    setSelectedCount(
      filteredPipelines.filter(pipeline => selectedPipelines.indexOf(pipeline.seq_id) > -1).length
    );
  }, [selectedPipelines, filteredPipelines]);

  const onPipelineSelect = useCallback(pipelineSeqId => {
    // if present in intermittent state , remove it else  add to selectedPipelines
    setSelectedPipelines(prev => {
      const index = prev.indexOf(pipelineSeqId);
      if (index > -1) {
        return prev.filter(seqId => seqId !== pipelineSeqId);
      }

      return [...prev, pipelineSeqId];
    });
  }, []);

  const selectAll = checked => {
    if (checked) {
      setSelectedPipelines(prev => [
        ...prev,
        ...filteredPipelines.map(pipeline => pipeline.seq_id)
      ]);
    } else {
      setSelectedPipelines([]);
    }
  };

  const updateSyncFrequencyCallback = useCallback((frequency, seqIds) => {
    updateSyncFrequency(seqIds, frequency);
    setSelectedPipelines([]);
  }, []);

  const isListItemSelected = seqId => selectedPipelines.indexOf(seqId) > -1;

  const onFilterChange = useCallback((index, value, isSearch) => {
    updateFilter(index, value, isSearch);
    resetPagination.current = true;
  }, []);

  const resetScroll = () => {
    bodyRef.current?.parentElement.scrollTo(0, 0);
  };

  const onSearchHandler = useCallback(value => {
    onFilterChange(null, value, true);
  }, []);

  return (
    <div className={styles.pipelinesListContainer}>
      <div className={styles.pipelinesList}>
        <div className={styles.listPrimaryHeader}>
          <div className='d-flex'>
            <p className='text-body-3'>Pipelines</p>
            <p className='text-body-2 pl-1'>({pipelinesCount})</p>
          </div>
          <div className='d-flex'>
            <SearchArea
              dataId={dataIds.search}
              onSearch={onSearchHandler}
              defaultExpanded={false}
              debounceInterval={500}
              placeholder='Search Pipelines'
            />

            <HdIconButton
              id='filters'
              onClick={() => {
                setShowFilters(prev => !prev);
              }}
              dataId={dataIds.filtersBtn}
              className='ml-5'
            >
              <HdIcon name='filter' size={3} />
            </HdIconButton>

            <MigratePipelinesFilters
              target='filters'
              listFilters={listFilters}
              open={showFilters}
              onChange={onFilterChange}
              onClose={() => {
                setShowFilters(false);
              }}
            />
          </div>
        </div>
        {filteredPipelines.length ? (
          <PipelineListTable
            selectAll={selectAll}
            pipelines={filteredPipelines}
            selectedCount={selectedCount}
            listItems={listItems}
            isListItemSelected={isListItemSelected}
            onPipelineSelect={onPipelineSelect}
            selectedPipelines={selectedPipelines}
            updateSyncFrequency={updateSyncFrequencyCallback}
            scrollRefs={{ sentryRef, rootRef, bodyRef }}
          />
        ) : (
          <NoItemBox
            iconName='pipeline'
            className='p-8'
            title={`No
           Pipelines Found`}
          >
            <div className='no-item-box-desc'>
              No matching Pipelines found for the above search criteria.
            </div>
          </NoItemBox>
        )}
      </div>
    </div>
  );
}
