import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { ProductTourRoot } from '../../../react/components/ProductTourV2/index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'product-tour-v2-react',
  template: '',
  providers: [ BridgeService ]
})
export class ProductTourV2Component implements OnInit {

  @Input() open = false;
  @Output() onClose = new EventEmitter();

  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef<any>,
    private _ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this._bridgeService.mount(
      ProductTourRoot,
      this._el.nativeElement,
      {});
  }
}
