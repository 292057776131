import { AccountAccessHistory, AccountAccessStatus } from './models';

export function AccountAccessHistoryLogEntryFactory(rawData: any): AccountAccessHistory {
  const accountAccessHistory = new AccountAccessHistory();

  accountAccessHistory.id = rawData.id;
  accountAccessHistory.createdAt = rawData.created_ts;
  accountAccessHistory.expiresAt = rawData.expiry_ts;
  accountAccessHistory.updatedAt = rawData.updated_ts;
  accountAccessHistory.teamId = rawData.team_id;
  accountAccessHistory.consentStatus =
    rawData.consent_status === AccountAccessStatus.GRANTED.toString()
      ? AccountAccessStatus.GRANTED
      : rawData.consent_status === AccountAccessStatus.REVOKED.toString()
      ? AccountAccessStatus.REVOKED
      : AccountAccessStatus.EXPIRED;
  accountAccessHistory.approvedByUser = rawData.approved_by_user;
  accountAccessHistory.approvedByUserId = rawData.approved_by_user_id;
  accountAccessHistory.revokedByUser = rawData.revoked_by_user;
  accountAccessHistory.revokedByUserId = rawData.revoked_by_user_id;

  return accountAccessHistory;
}

export function AccountAccessHistoryLogsFactory(rawDataArray: any[]): AccountAccessHistory[] {
  if (!rawDataArray) {
    return [];
  }

  return rawDataArray.map((resp: any) => AccountAccessHistoryLogEntryFactory(resp));
}
