/* eslint-disable class-methods-use-this */
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';
import {
  INCLUDE_EXCLUDE_OPTIONS,
  MATCH_OPERATORS,
  LOGICAL_OPERATORS,
  COMPARISON_OPERATORS,
  IGoogleAnalyticsSourceFormikState,
  IReportState
} from '../GoogleAnalytics4/model';

export class FirebaseAnalyticsConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const reports = rawData.reports.map(report => {
      let reportParsed: any;
      try {
        reportParsed = JSON.parse(report);
      } catch {
        reportParsed = report;
      }

      reportParsed.isEditing = true;
      reportParsed.pivotMetrics = reportParsed.pivot?.pivotMetrics || [];
      reportParsed.pivotDimensions = reportParsed.pivot?.pivotDimensions || [];
      reportParsed.pivotAggregationFunctions = reportParsed.pivot?.aggregationFunctions || [];
      reportParsed.dimensionFilterClauses = this.createDimensionFilterClauses(reportParsed);
      reportParsed.metricFilterClauses = this.createMetricFilterClauses(reportParsed);

      return reportParsed;
    });

    return {
      propertyId: metaData?.propertyList?.find(property => property.id === rawData.property_id),
      accountId: metaData?.accountList?.find(account => account.name === rawData.account_id),
      reports
    };
  }

  getObjectFromList(list: any[], value: any, key: string) {
    return list.find((item: any) => item[key] === value);
  }

  createDimensionFilterClauses(report) {
    let dimensionFilterClauses: any[] = [];

    if (Array.isArray(report.dimensionFilterClauses)) {
      dimensionFilterClauses = report.dimensionFilterClauses
        .filter(
          (dimensionFilterClause: any) =>
            dimensionFilterClause.filters && dimensionFilterClause.filters.length
        )
        .map((dimensionFilterClause: any) => {
          const dimensionFilters: any[] = dimensionFilterClause.filters
            .filter((filter: any) => !!filter)
            .map((filter: any) => ({
              unit: filter.dimension,
              includeExclude: this.getObjectFromList(INCLUDE_EXCLUDE_OPTIONS, filter.not, 'id'),
              operator: this.getObjectFromList(MATCH_OPERATORS, filter.operator, 'id'),
              caseSensitive: filter.caseSensitive,
              expression: filter.expressions
            }));

          return {
            operator: this.getObjectFromList(
              LOGICAL_OPERATORS,
              dimensionFilterClause.operator,
              'id'
            ),
            filters: dimensionFilters
          };
        });
      return dimensionFilterClauses;
    }
    return null;
  }

  createMetricFilterClauses(report) {
    let metricFilterClauses: any[] = [];

    if (Array.isArray(report.metricFilterClauses)) {
      metricFilterClauses = report.metricFilterClauses
        .filter(
          (metricFilterClause: any) =>
            metricFilterClause.filters && metricFilterClause.filters.length
        )
        .map((metricFilterClause: any) => {
          const filters: any[] = metricFilterClause.filters
            .filter((filter: any) => !!filter)
            .map((filter: any) => ({
              unit: filter.metric,
              includeExclude: this.getObjectFromList(INCLUDE_EXCLUDE_OPTIONS, filter.not, 'id'),
              operator: this.getObjectFromList(COMPARISON_OPERATORS, filter.operator, 'id'),
              expression: filter.comparisonValue
            }));

          return {
            operator: this.getObjectFromList(LOGICAL_OPERATORS, metricFilterClause.operator, 'id'),
            filters
          };
        });

      return metricFilterClauses;
    }
    return null;
  }

  getDefaultConfig() {}

  metaDataResolver(sourceType: string, authData: any, rawData: any, draftSourceData: any) {
    const sourceVersion = rawData.config?.source_version ?? null;

    const observablesArr = [
      PipelinesAPI.getFirebaseAnalyticsAccounts(authData.oauthTokenId, sourceVersion)
    ];

    const config: any = rawData.id ? rawData.source_config : rawData;

    const accountId =
      config.accountId?.name || config.account_id || draftSourceData?.config?.account_id;

    if (accountId) {
      observablesArr.push(
        PipelinesAPI.getFirebaseAnalyticsProperties(authData.oauthTokenId, accountId, sourceVersion)
      );
    }

    const propertyId =
      config.property_id || config.propertyId?.id || draftSourceData?.config?.property_id;
    if (accountId && propertyId) {
      observablesArr.push(
        PipelinesAPI.getFirebaseAnalyticsMetadata(authData.oauthTokenId, propertyId, sourceVersion)
      );
    }

    return Promise.all(observablesArr).then(res => {
      const [accountList, propertyList, metadata] = res;
      const resolvedMetadata: { [key: string]: any } = {
        accountList
      };

      if (propertyList) {
        resolvedMetadata.propertyList = propertyList;
      }

      if (metadata) {
        resolvedMetadata.metadata = {
          dimensions: res[2].dimensions,
          metrics: res[2].metrics
        };
      }
      return resolvedMetadata;
    });
  }

  buildConnectionParams(data: IGoogleAnalyticsSourceFormikState) {
    const reports = data.reports.map((reportData: IReportState) => {
      const reportClone: any = {};
      reportClone.reportName = reportData.reportName;
      reportClone.pivotReports = reportData.pivotReports;

      reportClone.dimensions = reportData.dimensions;
      reportClone.metrics = reportData.metrics;

      reportClone.metricFilterClauses = reportData.metricFilterClauses.map(x => ({
        operator: x.operator.id,
        filters: x.filters.map(filter => ({
          metric: filter.unit,
          not: filter.includeExclude.id,
          operator: filter.operator.id,
          comparisonValue: filter.expression
        }))
      }));

      reportClone.dimensionFilterClauses = reportData.dimensionFilterClauses.map(x => ({
        operator: x.operator.id,
        filters: x.filters.map(filter => ({
          dimension: filter.unit,
          not: filter.includeExclude.id,
          operator: filter.operator.id,
          caseSensitive: filter.caseSensitive,
          expressions: filter.expression
        }))
      }));

      if (!reportClone.pivotReports) {
        reportClone.pivot = {};
      } else {
        reportClone.pivot = {
          pivotMetrics: reportData.pivotMetrics,
          pivotDimensions: reportData.pivotDimensions,
          aggregationFunctions: reportData.pivotAggregationFunctions
        };
      }

      return reportClone;
    });

    return {
      property_id: data.propertyId?.id,
      account_id: data.accountId?.name,
      reports
    };
  }
}
