import { Injectable } from '@angular/core';

import moment from 'moment-mini-ts';
import { of, Subject, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AnsibleBoolean } from '../../core/models/ansible-config';
import { IntercomService } from '../../core/service/intercom.service';
import { PlanChatSupportService } from '../../core/service/plan-chat-support.service';
import { SettingsStorageService } from '../../core/service/settings-storage.service';
import { VWOService } from '../../core/service/vwo.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DialogOverlayService } from '../dialog-overlay.service';
import { AskSupportDialogComponent } from './ask-support-dialog.component';
import { AskSupportTrigger } from './ask-support-trigger';
import { ASK_SUPPORT_ON_ERROR_DELAY, ASK_SUPPORT_SHOWN, OFFICE_HOURS } from './constants';
import { AskSupportData, SupportQuery } from './interface';


declare const __SHOW_ASK_SUPPORT_PROMPT__: AnsibleBoolean;

@Injectable()
export class AskSupportDialogService {
  private _stepErrors$ = new Subject<string>();
  private _defaultLauncher: any;

  constructor(
    private _dialogOverlayService: DialogOverlayService,
    private _userSettingsService: SettingsStorageService,
    private _vwoService: VWOService,
    private _supportService: IntercomService,
    private _planChatSupportService: PlanChatSupportService
  ) {
  }

  show(askSupportData: AskSupportData) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      AskSupportDialogComponent,
      {
        askSupportData,
        launcher: this._defaultLauncher
      }
    );

    return dialogRef.onClose().pipe(tap((openSupport) => {
      if (openSupport) {
        this._vwoService.triggerConversion(200);

        this._supportService.show(
          typeof askSupportData.supportQuery === 'string'
            ? askSupportData.supportQuery
            : askSupportData.supportQuery(askSupportData.payload)
        );
      }
    }));
  }

  onStepError(error: string) {
    this._stepErrors$.next(error);
  }

  askSupportOnFailure(concern: string, supportQuery: SupportQuery, maxErrors: number) {
    if (this.isAskSupportDisabled()) {
      return of();
    }

    let count = 0;

    return this._stepErrors$.pipe(
      switchMap((error) => {
        count++;

        if (count < maxErrors) {
          return of();
        }

        return timer(ASK_SUPPORT_ON_ERROR_DELAY).pipe(
          switchMap(() => {
            if (this._planChatSupportService.isLiveChatDisabled() || this._supportService.isLiveChatOpen()) {
              return of();
            }

            return this._askSupportOnce({
              concern,
              trigger: AskSupportTrigger.ERROR,
              payload: error,
              supportQuery
            });
          })
        );
      })
    );
  }

  askSupportAfterTimeout(concern: string, supportQuery: SupportQuery, period: number) {
    if (this.isAskSupportDisabled()) {
      return of();
    }

    return timer(period).pipe(switchMap(() => {
      if (this._planChatSupportService.isLiveChatDisabled()) {
        return of();
      }

      return this._askSupportOnce({
        concern,
        trigger: AskSupportTrigger.TIMEOUT,
        supportQuery
      });
    }));
  }

  askSupport(data: AskSupportData) {
    return this._askSupport(data);
  }

  private _askSupport(data: AskSupportData) {
    return this.show(data);
  }

  askSupportOnce(data: AskSupportData) {
    if (this.isAskSupportDisabled()) {
      return of();
    }

    return this._askSupport(data);
  }

  private _askSupportOnce(data: AskSupportData) {
    const askSupportShown = this._userSettingsService.getSettings(ASK_SUPPORT_SHOWN);

    if (askSupportShown) {
      return of();
    }

    this._userSettingsService.applySettings(ASK_SUPPORT_SHOWN, true);

    return this._askSupport(data);
  }

  isAskSupportDisabled() {
    return !this.isOfficeHour() || this.isAskSupportDisabledForTeam();
  }

  isOfficeHour() {
    const now = moment.utc();
    return OFFICE_HOURS.some((range) => {
      const startDate = moment.utc();
      startDate.weekday(range.startDay);
      startDate.hour(range.startHour);
      startDate.minute(range.startMinute);

      const endDate = moment.utc();
      endDate.weekday(range.endDay);
      endDate.hour(range.endHour);
      endDate.minute(range.endMinute);

      return now.toDate() >= startDate.toDate() && now.toDate() <= endDate.toDate();
    });
  }

  isAskSupportDisabledForTeam() {
    return typeof __SHOW_ASK_SUPPORT_PROMPT__ !== 'undefined' && __SHOW_ASK_SUPPORT_PROMPT__ === AnsibleBoolean.FALSE;
  }

  registerDefaultLauncher(defaultLauncher: any) {
    this._defaultLauncher = defaultLauncher;
  }
}
