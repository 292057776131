/**
 * Append query parameters to a url
 * @param url: A valid url string for URL object
 * @param params: query parameters string without leading ?
 */
export function appendQueryParams(url: string, params: string) {
  let urlObject: URL;

  try {
    urlObject = new URL(url);
  } catch (e) {
    return url;
  }

  if (params.length === 0) {
    // No parameters, the visible URL is just the URL given at creation time.
    return url;
  } else {
    // Does the URL already have query parameters? Look for '?'.
    const searchString = urlObject.search || '';

    const qIdx = searchString.indexOf('?');
    // There are 3 cases to handle:
    // 1) No existing parameters -> append '?' followed by params.
    // 2) '?' exists and is followed by existing query string ->
    //    append '&' followed by params.
    // 3) '?' exists at the end of the url -> append params directly.
    // This basically amounts to determining the character, if any, with
    // which to join the URL and parameters.
    const sep: string = qIdx === -1 ? '?' : (qIdx < searchString.length - 1 ? '&' : '');
    urlObject.search = searchString + sep + params;

    return urlObject.toString();
  }
}
