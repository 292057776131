import PipelinesAPI from '../../../PipelinesAPI';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class FbInsightsConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const pages = metaData.pages.filter(page => (rawData.pages || []).find(pageId => page.id === pageId));

    return {
      pages
    };
  }

  getDefaultConfig(metaData: any) {}

  buildConnectionParams(data: any) {
    return {
      oauth_token_id: data.oauthTokenId,
      pages: (data.pages || []).map(page => page.id)
    };
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    return PipelinesAPI.getFacebookPages(authData.oauthTokenId).then((res: any) => ({
      pages: res.data.pages.map((page: { id: { value: string }; name: { value: string } }) => ({
        id: page.id.value,
        name: page.name.value
      }))
    }));
  }
}
