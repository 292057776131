import { CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdIconButton } from '../HdIconButton';
import styles from './styles.module.scss';

interface HdSpinnerProps {
  variant: 'primary' | 'deferred' | 'pending';
  classes?: string;
  message?: string;
  dataId?: string;
}

export default function HdSpinner({ variant, classes, message, dataId = '' }: HdSpinnerProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'hd-spinner'), [dataId]);

  return (
    <div data-id={dataIdGenerator('')} className={styles.wrapper}>
      <span className={`${styles.actionSpinner} ${styles[variant]} ${classes}`}>
        <span className={styles.loadingCircle} />
      </span>
      {message ? <span className='ml-1'>{message}</span> : null}
    </div>
  );
}

// simple spinner
export function HdSmallSpinner() {
  return (
    <HdIconButton aria-label='progress' edge='start' type='button' dataId='spinner'>
      <CircularProgress size={10} />
    </HdIconButton>
  );
}
