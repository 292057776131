import { NodeTypeConfigHelper } from '../../../../../../app/nodes/node-config-helper';

export class FreshdeskConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData) {
    return {
      subdomain: rawData.subdomain,
      apiToken: rawData.api_token
    };
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data) {
    return {
      subdomain: data.subdomain,
      api_token: data.apiToken
    };
  }
}
