import { Component, Input, ElementRef, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tooltip-content',
  styleUrls: ['./tooltip.component.scss'],
  template: `
<ng-template>
  <div class="tooltip-wrapper">
    <div class="tooltip" role="tooltip">
      <ng-content></ng-content>
      {{ content }}
    </div>
  </div>
</ng-template>
`
})
export class TooltipContent {
  @Input()
  hostElement: HTMLElement;

  @Input()
  content: string;

  @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;

  constructor(
    private element: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
  }
}
