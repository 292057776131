import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[hdCoordinatedScrollAnchor]'
})
export class CoordinatedScrollAnchorDirective {
  constructor(
    public el: ElementRef
  ) { }

  public getTopOffset() {
    return this.el.nativeElement.offsetTop;
  }
}
