import React, { useCallback, useRef } from 'react';
import { DragEnd, DragStart, useDrag } from '../../hooks/useDrag';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { HdButton, HdIcon, HdTooltip } from '../UIElements';
import { CodeMirror } from './CodeMirror';
import styles from './styles.module.scss';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';

const codeMirrorInstance = require('codemirror');

export interface HdCodeEditorProps {
  dataId: string;
  className?: string;
  codeEditorClassName?: string;
  editorOptions?: Object;
  value?: string;
  autoHeight?: boolean;
  showLineNumbers?: boolean;
  allowCopy?: boolean;
  allowFormatting?: boolean;
  onChange?: (string: string) => void;
  readonly?: boolean;
  resizeCtrl?: boolean;
  autoIndent?: boolean;
  autocomplete?: boolean;
  getCodeMirror?: Function;
  copyTooltipContent?: string;
  copySuccessMessage?: string;
  formatTooltipContent?: string;
  showInvisibles?: boolean;
  floatingActionPalette?: string;
  autofocus?: boolean;
  formatter?: (value: string) => string;
  canUseReactClipboard?: boolean;
  showLintMarkers?: boolean;
}

export function HdCodeEditor({
  dataId,
  className = '',
  codeEditorClassName = '',
  editorOptions = {},
  value,
  autoHeight = false,
  showLineNumbers = false,
  allowCopy = true,
  allowFormatting = false,
  readonly = true,
  resizeCtrl = false,
  autoIndent = false,
  autocomplete = false,
  onChange = () => {},
  getCodeMirror,
  copyTooltipContent = 'Copy',
  copySuccessMessage = 'Copied to clipboard',
  formatTooltipContent = 'Format',
  showInvisibles,
  floatingActionPalette = 'secondary',
  autofocus,
  formatter,
  canUseReactClipboard = false,
  showLintMarkers = false
}: HdCodeEditorProps) {
  const clipboardService = useCopyToClipboard(canUseReactClipboard);

  const editorRef = useRef(null);
  const wrapperRef = useRef(null);
  const dragStartHeight = useRef(null);

  const onDragStart = () => {
    dragStartHeight.current = wrapperRef.current.offsetHeight;
  };

  const onDrag = (params: DragStart & DragEnd) => {
    wrapperRef.current.style.height =
      Math.max(0, dragStartHeight.current + params.endY - params.startY) + 'px';
  };

  const { draggableItemRef: resizeCtrlRef } = useDrag(onDragStart, onDrag);

  const copyTextToClipboard = () => {
    clipboardService.copy(value, copySuccessMessage);
  };

  const formatValue = () => {
    if (formatter) {
      const formattedValue = formatter(value);
      editorRef.current.setValue(formattedValue);
      onChange(formattedValue);
    }
  };

  const setEditorRef = editor => {
    editorRef.current = editor;

    if (typeof getCodeMirror === 'function') {
      getCodeMirror(editor);
    }
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'codeEditor'), [dataId]);

  let gutters = [];

  if (showLineNumbers) {
    gutters = ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'];
  }

  if (showLintMarkers) {
    gutters.push('CodeMirror-lint-markers');
  }

  return (
    <div
      className={`${styles.editorWrapper} ${autoHeight ? styles.autoHeight : ''} ${className}`}
      ref={wrapperRef}
    >
      <CodeMirror
        dataId={dataIdGenerator('')}
        onAttach={setEditorRef}
        value={value}
        className={`${styles.editor} ${codeEditorClassName}`}
        onChange={onChange}
        autoIndent={autoIndent}
        autocomplete={autocomplete}
        options={{
          lineWrapping: true,
          theme: 'hevo-default',
          viewportMargin: autoHeight ? Infinity : 10,
          readOnly: readonly,
          foldGutter: showLineNumbers,
          gutters,
          autofocus,
          lineNumbers: showLineNumbers,
          ...(readonly
            ? {
                cursorBlinkRate: -1
              }
            : {}),
          showInvisibles,
          ...editorOptions,
          lint: editorOptions['lint'] ? {
            ...editorOptions['lint'],
            getAnnotations: codeMirrorInstance.lintValidator
          } : false
        }}
      />

      <div className={styles.floatingActions}>
        {allowCopy ? (
          <HdTooltip title={copyTooltipContent}>
            <HdButton
              className={styles.floatingAction}
              variation='outline'
              palette={floatingActionPalette}
              icon='copy'
              iconOnly
              onKeyDown={() => copyTextToClipboard()}
              onClick={() => copyTextToClipboard()}
              dataId={dataIdGenerator('copy')}
            />
          </HdTooltip>
        ) : null}

        {allowFormatting && !!formatter ? (
          <HdTooltip title={formatTooltipContent}>
            <HdButton
              className={styles.floatingAction}
              variation='outline'
              palette={floatingActionPalette}
              icon='format'
              iconOnly
              onKeyDown={() => formatValue()}
              onClick={() => formatValue()}
              dataId={dataIdGenerator('format')}
            />
          </HdTooltip>
        ) : null}
      </div>

      {resizeCtrl ? (
        <div className={styles.resizeCtrlV} ref={resizeCtrlRef}>
          <HdIcon name='resize' size={0} />
        </div>
      ) : null}
    </div>
  );
}
