import React from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdSupportButton } from '../../SupportButton';
import { HdButton, HdIcon, HdIconButton, HdModal } from '../../UIElements';
import { DialogCommonProps } from '../ConfirmDialog/interface';

export interface AlertDialogProps {
  dataId: string;
  onClose?: Function;
  title: string;
  body: string;
  positiveButtonText?: string;
  showCustomerSupportButton?: boolean;
  supportButtonText?: string;
  supportMessage?: string;
}

export function AlertDialog(props: AlertDialogProps & DialogCommonProps) {
  const { open, onClose } = props;

  return (
    <HdModal
      open={open}
      onClose={() => onClose()}
      placement='top'
      styling={{
        width: '470px'
      }}
    >
      <AlertDialogContent {...props} />
    </HdModal>
  );
}

function AlertDialogContent({
  dataId,
  onClose,
  title,
  body,
  positiveButtonText = 'Ok',
  showCustomerSupportButton = false,
  supportButtonText = 'Contact Support',
  supportMessage = ''
}: AlertDialogProps) {
  const dataIdGenerator = getDataIdGenerator(dataId, 'alert-dialog');

  return (
    <>
      <div className='dialog-header' tabIndex={0}>
        <div className='dialog-title'>{title}</div>

        <HdIconButton onClick={() => onClose()} dataId={dataIdGenerator('close')}>
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className='dialog-body alert-dialog-body'>{body}</div>

      <div className='dialog-footer'>
        {showCustomerSupportButton ? (
          <HdSupportButton
            message={supportMessage}
            Component={HdButton}
            variation='outline'
            palette='error'
            icon='support'
            onClick={() => onClose(true)}
            dataId={dataIdGenerator('chat-support')}
          >
            {supportButtonText}
          </HdSupportButton>
        ) : (
          <HdButton dataId={dataIdGenerator('positive')} onClick={() => onClose(true)}>
            {positiveButtonText}
          </HdButton>
        )}
      </div>
    </>
  );
}
