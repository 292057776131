import { GenericKeyValueType } from '../../../../../generics';

export interface FTPProtocolType {
  name: string;
  desc: string;
  value: string;
  defaultPort: number;
}

export class FTPProtocolTypeEnum {
  static FTP: FTPProtocolType = {
    name: 'FTP',
    desc: 'File Transfer Protocol',
    value: 'FTP',
    defaultPort: 21
  };

  static SFTP: FTPProtocolType = {
    name: 'SFTP',
    value: 'SFTP',
    desc: 'SSH File Transfer Protocol',
    defaultPort: 22
  };

  static FTPS: FTPProtocolType = {
    name: 'FTPS',
    value: 'FTPS',
    desc: 'File Transfer Protocol Secure',
    defaultPort: 990
  };
}

export interface FTPFileFormat {
  name: string;
  value: string;
  id: string;
}

export interface FTPFormikState extends GenericKeyValueType<string, any> {
  useSSH: boolean;
  protocolType: { name: string; value: string };
  fileFormat: FTPFileFormat;
  parseStringsAsDatetime: boolean;
}
