import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { AutoMapFailedEventsDialogComponent } from './auto-map-failed-events-dialog.component';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { SourceObject } from '../../pipeline/source-objects/models/source-object';
import { SidelineMessage } from '../../sideline/models/sideline-message';


@Injectable()
export class AutoMapFailedEventsDialogService {
  constructor(private _dialogOverlayService: DialogOverlayService) { }

  confirm(
    pipeline: any,
    object?: SourceObject,
    event?: SidelineMessage
  ) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      AutoMapFailedEventsDialogComponent,
      {
        pipeline,
        object,
        event
      }
    );

    return dialogRef.onClose();
  }
}
