export enum StatusFilterValue {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
  FAILED_EVENTS = 'HAS_FAILED_EVENTS',
  AWAITING_MAPPING = 'AWAITING_MAPPING'
}

export enum QueryModeFilterValue {
  FULL_LOAD = 'bulk',
  DELTA_TIMESTAMP = 'timestamp',
  CHANGE_DATA_CAPTURE = 'timestamp+incrementing',
  UNIQUE_INCREMENTING = 'unique_incrementing'
}

export enum PollTypeFilerValue {
  FULL_LOAD = 'FULL_LOAD'
}

export const STATUS_WITH_FULL_LOAD_FILTER_VALUE_SELECT_ALL = 'SELECT_ALL';
