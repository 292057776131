export interface TestPayloadResponse {
  failureMessage: string;
  status: number;
  rawData: string;
  parsedData: any[];
  success: boolean;
  nextOffset: any;
  suggestedDataRoots: string[];
}

export enum GoogleAdsVersionEnum {
  v1 = 'v1',
  v2 = 'v2'
}
