/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, FormHelperText, InputAdornment, Collapse } from '@mui/material';
import useResizeObserver from 'use-resize-observer';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from '../HdDropDown/styles.module.scss';
import HdDropDown, { accessorFn, HdDropDownProps } from '../HdDropDown';
import HdIcon from '../HdIcon';
import HdTag from '../HdTag';
import { HdTextField } from '../HdTextField';
import { HdIconButton } from '../HdIconButton';
import useRenderTagsLimit from '../../../hooks/useRenderTagsLimit';
import { PopperComponentProps, StyledInput, StyledPopper } from './elements';

export default function HdMultiSelectDropDown(props: HdDropDownProps) {
  const {
    dataId,
    selected,
    displayAccessor,
    disabled,
    group,
    required,
    error,
    id: formikId,
    label,
    HelperDocumentAdornment,
    placeholder,
    helperText,
    onChangeEventHandler,
    displayValueFn,
    hideClearable = false
  } = props;

  /**
   * groupExpand: key to maintain state of expand/collapse for groups/category for the option list
   * leftOutTags: count of the tags which are overflowed because of limited available space
   * multiline: state to reflect if drowdown container can be multiline or singleline
   */
  const [groupExpand, setGroupExpand] = useState({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [leftOutTags, setLeftOutTags] = useState(0);
  const [multiline, setMultiline] = useState(false);
  const [popperWidth, setPopperWidth] = useState('100%');
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [hoveredOption, setHoveredOption] = useState(null);
  const showSupportDialog = !!hoveredOption && !!HelperDocumentAdornment;

  useEffect(() => {
    // on change in selected options, setting that particular group in expand state
    let groups = {};
    (selected || []).map(option => {
      if (option?.group) {
        groups = { ...groups, [option.group]: true };
      }
      return option;
    });
    setGroupExpand({ ...groupExpand, ...groups });
  }, [selected]);

  /**
   * added resize observer to listen the accurate width of dropdown container post rerender
   * to keep popper width in sync to container.
   */
  useResizeObserver<HTMLDivElement>({
    ref: dropdownRef,
    onResize: ({ width }) => {
      setPopperWidth(`${width}px`);
    }
  });

  useEffect(() => {
    if (dropdownRef.current) {
      setPopperWidth(`${dropdownRef.current.offsetWidth}px`);
    }
  }, [dropdownRef.current]);

  /**
   * added callback for popper to avoid rerendering of the dropdown
   * which will cause scroll to top event if callback is not used
   */
  const PopperComponentCallback = useCallback(
    popperProps => PopperComponent({ ...popperProps, popperWidth }),
    [popperWidth]
  );

  const handleOpen = () => {
    setAnchorEl(dropdownRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearAll = event => {
    event.stopPropagation();
    onChangeEventHandler([]);
  };

  const onHoverOption = option => {
    setHoveredOption(option);
  };

  // get the latest count of left out/hidden tags
  const onTagsLimitCallback = leftOutTagsCount => {
    setLeftOutTags(leftOutTagsCount);
  };

  const onSetMultiline = event => {
    event.stopPropagation();
    setMultiline(true);
  };

  let accessor = displayAccessor;
  if (!accessor) {
    accessor = ele => ele?.name;
  }

  const displayAccessorFn = accessorFn(accessor, 'name');
  const open = Boolean(anchorEl);
  const id = open ? 'hd-multi-select' : undefined;
  const selectionFound = selected?.length > 0;
  const showLeftOutTags = leftOutTags > 0 && !multiline;

  let conditionalProps = props;
  if (group) {
    conditionalProps = {
      ...props,
      GroupComponent: groupProps =>
        GroupComponent({ ...groupProps, expand: groupExpand, setExpand: setGroupExpand })
    };
  }

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'multi-dropdown'), [dataId]);

  const showClearIcon = !hideClearable && selectionFound && !disabled;

  return (
    <>
      <ClickAwayListener onClickAway={() => setMultiline(false)}>
        <div>
          <div
            role='button'
            tabIndex={0}
            ref={dropdownRef}
            aria-describedby={id}
            onKeyPress={handleOpen}
            onClick={handleOpen}
          >
            <HdTextField
              required={required}
              id={formikId}
              error={error ? true : undefined}
              label={label}
              placeholder={placeholder}
              disabled={disabled}
              inputProps={{ className: selectionFound ? styles.hideInput : '' }}
              maxRows={multiline ? 2 : 1}
              dataId={dataIdGenerator('')}
              InputProps={{
                startAdornment:
                  selectionFound && !displayValueFn ? (
                    <div className={styles.optionTagsWrapper}>
                      <TagsComponent
                        multiline={multiline}
                        disabled={disabled}
                        selectedOptions={selected}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        displayAccessorFn={displayAccessorFn}
                        setSelectedOptions={onChangeEventHandler}
                        tagsLimitCallback={onTagsLimitCallback}
                        dataIdGenerator={dataIdGenerator}
                      />

                      {showLeftOutTags ? (
                        <div className={styles.optionLeftOutTagsWrapper}>
                          <HdTag
                            dataId={dataIdGenerator('left-out')}
                            className={styles.optionLeftOutTags}
                            onClick={onSetMultiline}
                          >
                            +{leftOutTags}
                          </HdTag>
                        </div>
                      ) : null}
                    </div>
                  ) : displayValueFn && selectionFound ? (
                    <div className={styles.optionTagsWrapper}>
                      <div className={`${styles.tagsWrapper} py-2`}>{displayValueFn(selected)}</div>
                    </div>
                  ) : null,
                endAdornment: (
                  <InputAdornment position='end'>
                    {showClearIcon ? (
                      <HdIconButton
                        className='mr-1 p-0'
                        onClick={clearAll}
                        dataId={dataIdGenerator('clear-all')}
                      >
                        <HdIcon name='close' size={2} className={styles.clearIcon} />
                      </HdIconButton>
                    ) : null}

                    <HdIconButton
                      className='p-0'
                      onClick={handleOpen}
                      dataId={dataIdGenerator('open')}
                    >
                      <HdIcon name='dropdown-arrow' className={styles.caretIcon} />
                    </HdIconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>

          <StyledPopper
            onMouseLeave={() => setHoveredOption(null)}
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement='bottom-start'
          >
            <ClickAwayListener onClickAway={handleClose}>
              <div className='d-flex'>
                <div style={{ width: popperWidth }}>
                  <HdDropDown
                    {...conditionalProps}
                    selected={selected}
                    onHoverOption={onHoverOption}
                    hoveredOption={hoveredOption}
                    InputComponent={InputComponent}
                    PopperComponent={PopperComponentCallback}
                    onChangeEventHandler={onChangeEventHandler}
                    paperClasses={styles.inlineDropdownWrapper}
                    renderInline
                  />
                </div>
                {showSupportDialog && (
                  <div className={`${styles.multiSelectHelperWindow}`}>
                    <HelperDocumentAdornment option={hoveredOption} />
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </StyledPopper>
        </div>
      </ClickAwayListener>

      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
}

function GroupComponent(props) {
  const { group, expand, key, setExpand, children } = props;

  const toggleGroup = () => {
    setExpand(prev => ({ ...prev, [group]: !expand[group] }));
  };

  return (
    <React.Fragment key={group}>
      {group ? (
        <>
          <Box
            id={key}
            key={`group-${key}`}
            role='presentation'
            className={styles.itemWrapper}
            onClick={toggleGroup}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                toggleGroup();
              }
            }}
          >
            <div className='box__title text-ellipsis'>{group}</div>
            {expand[group] ? (
              <HdIcon name='up-arrow' className='mr-0' />
            ) : (
              <HdIcon name='dropdown-arrow' className='mr-0' />
            )}
          </Box>

          <Collapse in={expand[group]}>{children}</Collapse>
        </>
      ) : (
        children
      )}
    </React.Fragment>
  );
}

function InputComponent(props) {
  const { InputProps, inputProps, ...others } = props;

  return (
    <StyledInput
      {...others}
      autoFocus
      placeholder='Search'
      ref={InputProps.ref}
      startAdornment={
        <InputAdornment position='start'>
          <HdIcon name='search' />
        </InputAdornment>
      }
      // disabled is false so user can search the list even dropdown/options are disabled
      inputProps={{ ...inputProps, disabled: false }}
    />
  );
}

function TagsComponent({
  multiline,
  disabled,
  handleOpen,
  handleClose,
  selectedOptions,
  displayAccessorFn,
  setSelectedOptions,
  tagsLimitCallback,
  dataIdGenerator
}) {
  const tagsRef = useRef<HTMLDivElement>(null);
  /**
   * useRenderTagsLimit hook to get the limit of tags which are visible
   * leftOutTags which are hidden at given time
   * tagWidth an uniform width of each tag to maintain the same number of tags in each line
   */
  const { leftOutTags, tagWidth } = useRenderTagsLimit(tagsRef, selectedOptions);

  useEffect(() => {
    tagsRef.current.scrollTop = 0;
    tagsLimitCallback(leftOutTags);
  }, [multiline]);

  useEffect(() => {
    tagsLimitCallback(leftOutTags);
  }, [leftOutTags]);

  const removeTag = (option, event = null) => {
    event.stopPropagation();
    setSelectedOptions((selectedOptions || []).filter(entry => entry !== option));
    handleClose();
  };

  return (
    <div
      className={`${styles.tagsWrapper} ${multiline ? styles.scrollableTagsWrapper : ''}`}
      ref={tagsRef}
    >
      {selectedOptions.map(option => {
        const optionLabel = displayAccessorFn(option);
        let tagProps = {};

        if (!disabled && !option?.disabled) {
          tagProps = { onClose: event => removeTag(option, event) };
        }

        return (
          <React.Fragment key={optionLabel}>
            <div style={{ width: tagWidth }} className={styles.optionTags}>
              <HdTag
                dataId={dataIdGenerator(optionLabel)}
                className={styles.tag}
                onClick={event => handleOpen(event)}
                {...tagProps}
              >
                {optionLabel}
              </HdTag>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

function PopperComponent(props: PopperComponentProps) {
  const {
    disablePortal,
    anchorEl,
    open,
    popperWidth,
    hoverOptionRef,
    HelperDocumentAdornment,
    setHoverOption,
    children,
    autoWidthPaperMode,
    ...other
  } = props;
  return (
    <div {...other} style={{ width: popperWidth }}>
      <div>{children}</div>
    </div>
  );
}
