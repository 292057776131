import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';


@Injectable()
export class SidelineService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  sidelineUrl: string = this._appConfig.getSidelineURL();

  getSidelineSummary(pipelineId: number, sourceObjectId?: number[]): Observable<any> {
    const requestUrl = `${this.sidelineUrl}/${pipelineId}/summary?`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          source_object_id: sourceObjectId
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getSidelineSummaryForSourceObject(pipelineId: number, sourceObjectId?: number): Observable<any> {
    const requestUrl = `${this.sidelineUrl}/${pipelineId}/source-object-summary?`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          source_object_id: sourceObjectId,
          actionable_only: true,
          global: typeof sourceObjectId === 'undefined' || sourceObjectId === null
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  cancelReplayProgress(integrationId: number, sourceObjectId?: number)  {
    const requestUrl = `${this.sidelineUrl}/${integrationId}/replayed-batches`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      },
      networkOptions: {
        params: createHttpParams({
          source_object_id: sourceObjectId
        })
      }
    };

    return this._rxRequestService.delete(requestUrl, options);
  }

  replayGroup(integrationId: number, forced = false, sourceObjectId?: number, schemaName?: string, code?: number): Observable<any> {
    const requestUrl = `${ this.sidelineUrl }/${ integrationId }/replay-group`;
    const params: any = {
      source_object_id: sourceObjectId,
      schema_name: schemaName,
      code,
      forced
    };

    return this._rxRequestService.put(requestUrl, {}, params);
  }

  skipGroup(integrationId: number, sourceObjectId?: number, schemaName?: string, code?: number): Observable<any> {
    const requestUrl = `${ this.sidelineUrl }/${ integrationId }/skip-group`;
    const params: any = {
      source_object_id: sourceObjectId,
      schema_name: schemaName,
      code
    };

    return this._rxRequestService.put(requestUrl, {}, params);
  }

  getSamples(integrationId: number, schemaName: string, stage: any, code: any): Observable<any> {
    const requestUrl = this.sidelineUrl + `/${ integrationId }/summary-sample`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          code: code,
          stage: stage,
          schema_name: schemaName
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  downloadSamples(integrationId: number, schemaName: string, stage: any, code: any): Observable<any> {
    const requestUrl = this.sidelineUrl + `/${ integrationId }/summary-sample/download`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        responseType: 'blob',
        params: createHttpParams({
          code: code,
          stage: stage,
          schema_name: schemaName
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
