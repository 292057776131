// eslint-disable-next-line max-classes-per-file

export enum WebhookStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  FAILED = 'FAILED'
}

export const webhookStatusLookUp = {
  [WebhookStatus.ALL]: {
    status: WebhookStatus.ALL,
    color: 'success',
    displayName: 'All'
  },
  [WebhookStatus.ACTIVE]: {
    status: WebhookStatus.ACTIVE,
    color: 'success',
    displayName: 'Active'
  },
  [WebhookStatus.PAUSED]: {
    status: WebhookStatus.PAUSED,
    color: 'warning',
    displayName: 'Paused'
  },
  [WebhookStatus.FAILED]: {
    status: WebhookStatus.FAILED,
    color: 'error',
    displayName: 'Failed'
  }
};

export const webhookDarkModeURL = 'https://cdn.hevo.io/dark_mode_external_logos/webhooks.png';
export const webhookUrl = 'https://cdn.hevo.io/external_logos/webhooks.png';
export const webhookPrimaryColor = 'rgba(184, 69, 100, 0.1)';
export const webhookDarkColor = 'rgba(184, 69, 100, 0.15)';

export const EDIT_BY_SEQ_ID_QUERY_PARAM = 'edit_webhook_by_seq_id';
export const WEBHOOK_EVENTS_DOC_LINK = '/destinations/webhook-events/';
