import { AuthString, HdAuthRequestType } from './models';

export const AUTH_STRINGS: { [key: string]: AuthString } = {
  [HdAuthRequestType.LOGIN]: {
    heading: 'Log in to Hevo',
    subheading: 'Use your Google account or email address to log in.'
  },
  [HdAuthRequestType.SETUP_ACCOUNT]: {
    heading: 'Set up your Account',
    subheading: 'Set your password or log in with your Google account to start using Hevo.'
  },
  [HdAuthRequestType.FORGOT_PASSWORD]: {
    heading: 'Forgot Password?',
    subheading: 'Enter your email address and we’ll send you a link to reset your password.'
  },
  [HdAuthRequestType.RESET_PASSWORD]: {
    heading: 'Create New Password',
    subheading: 'Forgot your password? No problem. Create a new password here.'
  },
  [HdAuthRequestType.EMAIL_VERIFIED]: {
    heading: 'Your Email is Successfully Verified',
    subheading: 'Thanks for verifying your email address. You are now all set to log in.'
  },
  [HdAuthRequestType.EMPLOYEE_LOGIN]: {
    heading: 'Hevo Employee Login',
    subheading: 'Enter the email address of the account you wish to log in to.'
  },
  [HdAuthRequestType.ACCEPT_INVITE]: {
    heading: 'Set up your Account',
    subheading: 'Set your password or log in with your Google account to start using Hevo.'
  },
  [HdAuthRequestType.SETUP_PERSONA]: {
    heading: 'Welcome to Hevo'
  },
  [HdAuthRequestType.TFA_LOGIN]: {
    heading: 'Two Factor Authentication',
    subheading:
      'Enter the verification code displayed in the authentication app on your smartphone.'
  },
  [HdAuthRequestType.TFA_RECOVERY]: {
    heading: 'Enter Recovery Code',
    subheading:
      'Enter one of the recovery codes to access your Hevo account. ' +
      "Contact your workspace owner if you don't have the recovery codes."
  },
  [HdAuthRequestType.TFA_RECOVERY_CODES]: {
    heading: 'Two Factor Authentication Enabled',
    subheading:
      'Recovery Codes help you gain access to your Hevo account if you lose your smartphone. ' +
      'Copy or download the codes to a safe location. ' +
      'Treat them the way you treat your passwords.'
  },
  [HdAuthRequestType.SETUP_TFA]: {
    heading: 'Set up Two Factor Authentication',
    subheading:
      'Two Factor Authentication is enforced for your team. ' +
      'Please scan the QR code to obtain the verification code for activating it.'
  },
  [HdAuthRequestType.LOGIN_SAML]: {
    heading: 'Log in to Hevo',
    subheading: 'SAML Login'
  }
};
