import { NodeTypeConfigHelper } from '../../../../../../app/nodes/node-config-helper';

export class KlaviyoConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData) {
    return {
      publicKey: rawData.public_key,
      privateKey: rawData.private_key
    };
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data) {
    return {
      public_key: data.publicKey,
      private_key: data.privateKey
    };
  }
}
