import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'hd-menu-item-icon',
  templateUrl: './hd-menu-item-icon.component.html',
  styleUrls: [ './hd-menu-item-icon.component.scss' ]
})
export class HdMenuItemIconComponent {
  @HostBinding('class.hevo-icon') readonly hevoIcon = true;
  @HostBinding('class.menu-icon') readonly menuIcon = true;

  private _name: string;
  @Input()
  set name(value: string) {
    this._setName(value);
    this._name = value;
  }
  get name(): string {
    return this._name;
  }

  constructor(private _elRef: ElementRef) {
  }

  private _setName(name: string) {
    const classList: DOMTokenList = (<HTMLElement>this._elRef.nativeElement).classList;
    if (this._name) {
      classList.remove(`hevo-${this._name}`);
    }
    if (name) {
      classList.add(`hevo-${name}`);
    }
  }
}
