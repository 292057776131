// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.tabLabel_l_uwX {\n  padding: 8px 12px !important;\n  text-transform: none !important;\n  height: 50px !important;\n  display: flex !important;\n  flex-direction: row !important;\n  align-content: center !important;\n  min-width: unset !important;\n  margin-right: 16px !important;\n  font-size: 12px !important;\n  font-weight: 600 !important;\n}\n\n.tabLabelSelected_xXl2y {\n  color: var(--primary-color) !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabLabel": "tabLabel_l_uwX",
	"tabLabelSelected": "tabLabelSelected_xXl2y"
};
export default ___CSS_LOADER_EXPORT___;
