import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class GoogleAnalytics360ConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      datasets: (rawData.dataset_id || []).map(dataset => ({id: dataset, name: dataset})),
      projectId: rawData.project_id ? { id: rawData.project_id, name: rawData.project_display_value } : null,
    };
  }

  getDefaultConfig() {
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    const requests = [PipelinesAPI.getGA360ProductId(authData.oauthTokenId)];
    if (rawData.projectId?.id) {
      requests.push(PipelinesAPI.getGA360DatasetId(authData.oauthTokenId, rawData.projectId.id));
    }
    return Promise.all(requests)
      .then(([projects, datasets]) => ({
        projects: projects.map(project => ({id: project.id, name: project.display_name})),
        datasets: datasets?.map(dataset => ({id: dataset, name: dataset}))
      }));
  }

  buildConnectionParams(data: any) {
    return {
      project_id: data.projectId?.id,
      project_display_value: data.projectId?.name,
      dataset_id: (data.datasets || []).map(dataset => dataset.id)
    };
  }
}
