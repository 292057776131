import { styled } from '@mui/material';
import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdTag from '../HdTag';
import { HdTextField } from '../HdTextField';

export const StyledInput = styled(HdTextField)(() => ({
  '& .MuiInputBase-root': {
    display: 'inline-flex',
    flexWrap: 'wrap',
    padding: 'calc(var(--spacer)) calc(var(--spacer)) calc(var(--spacer) * 0.5) calc(var(--spacer))'
  },
  '& .MuiOutlinedInput-input': {
    flex: 1,
    minWidth: '150px',
    width: 'unset',
    padding: '0 !important',
    marginBottom: 'calc(var(--spacer) * 0.5)'
  }
}));

interface HdInputWithTagsProps {
  dataId: string;
  required?: boolean;
  label?: string;
  maxTagWidth?: number;
  onChange: (selectedValues: string[]) => void;
  validateFunction: (value: string) => boolean;
  onInputChange?: (input: string) => void;
  error?: boolean;
  placeholder?: string;
  helperText?: any;
}

export default function HdInputWithTags({
  dataId,
  required,
  label,
  placeholder,
  maxTagWidth = 120,
  onChange,
  error,
  onInputChange,
  helperText,
  validateFunction
}: HdInputWithTagsProps) {
  const [selected, setSelected] = useState([]);

  const [input, setInput] = useState('');
  const selectionFound = selected?.length > 0;

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (onInputChange) {
      onInputChange(input);
    }
  }, [input]);

  const onKeyDown: KeyboardEventHandler<any> = e => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const validationResponse = validateFunction(input);
      if (validationResponse) {
        setSelected([...selected, input.trim()]);
        setInput('');
      }
    }
  };

  const onClearTag = (tag, event) => {
    event.stopPropagation();
    setSelected((selected || []).filter(entry => entry !== tag));
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'multiTags'), [dataId]);

  return (
    <StyledInput
      required={required}
      label={label}
      value={input}
      error={!!error}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onChange={e => setInput(e.target.value)}
      helperText={helperText}
      dataId={dataIdGenerator('field')}
      InputProps={{
        startAdornment: selectionFound ? (
          <>
            {selected.map(tag => (
              <div style={{ maxWidth: `${maxTagWidth}px` }}>
                <HdTag
                  dataId={dataIdGenerator(tag)}
                  className='ml-0 mb-2 mr-3'
                  onClose={e => onClearTag(tag, e)}
                >
                  {tag}
                </HdTag>
              </div>
            ))}
          </>
        ) : null
      }}
    />
  );
}
