import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { PopupComponent } from './popup/popup.component';
import { PopupHeaderComponent } from './popup/popup-header.component';
import { PopupBodyComponent } from './popup/popup-body.component';

// Local directives
import { HdPopupDirective } from './popup-trigger.directive';
import { ClearPendingPopupService } from './clear-pending-popup.service';

const DIRECTIVES = [
  PopupComponent,
  PopupHeaderComponent,
  PopupBodyComponent,
  HdPopupDirective
];

@NgModule({
  imports: [
    CommonModule,
    OverlayModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
  providers: [
    ClearPendingPopupService
  ]
})
export class PopupModule { }
