import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HdAuthRequestType } from '../HdAuth/models';
import { TRACKER_LOGIN_WITH_SAML_SSO_CLICK } from './constants';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdButton, HdPane } from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AuthAPI from '../AuthAPI';
import { HdAuth } from '../HdAuth';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';

export interface SamlLoginProps {
  featuredCustomers: FeaturedCustomers;
}

export function SamlLogin() {
  const { getFeaturedCustomers } = useFeaturedCustomers();

  return (
    <HdResolvedComponent
      Component={SamlLoginInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
    />
  );
}

function SamlLoginInner({
  featuredCustomers
}: SamlLoginProps) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ formError, setFormError ] = useState(null);

  const { eventTrack } = useAnalyticsTracker();
  const { search } = useLocation();

  const email = new URLSearchParams(search).get('email');

  const samlLogin = () => {
    setIsSubmitting(true);
    setFormError(null);

    AuthAPI.getSAMLRequestURL(
      email
    ).then((res) => {
      setIsSubmitting(false);

      eventTrack({
        action: TRACKER_LOGIN_WITH_SAML_SSO_CLICK
      });

      if (res.data?.authn_url) {
        window.location.assign(res.data.authn_url);
      }
    }, (err) => {
      setIsSubmitting(false);

      setFormError(getErrorMessageFromObj(err));
    });
  };

  const dataIdGenerator = getDataIdGenerator('saml-login');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.LOGIN_SAML}
      featuredCustomers={featuredCustomers}
    >
      <HdButton
        dataId={dataIdGenerator('continue')}
        size='lg'
        variation='outline'
        icon='tfa'
        className='w-100'
        disabled={!email || isSubmitting}
        showProgress={isSubmitting}
        onClick={() => samlLogin()}
      >
        Continue With SAML SSO
      </HdButton>

      {!!formError && (
        <HdPane
          dataId={dataIdGenerator('form-error')}
          className='mt-4 w-100'
          variant='error-faded'
          icon='error-filled'
          iconClasses='text-error'
          disableHide
        >
          <div className='text-default'>
            {formError}
          </div>
        </HdPane>
      )}

      <HdPane
        dataId={dataIdGenerator('saml-enforcement-warning')}
        className='mt-4 w-100'
        variant='info-faded'
        icon='password'
        iconClasses='text-info'
        disableHide
      >
        <div className='text-default'>
          Your workspace owner has enforced SAML SSO.
        </div>
      </HdPane>
    </HdAuth>
  );
}
