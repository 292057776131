import { Component, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: '[hdLink]',
  templateUrl: './hd-link.component.html',
  styleUrls: [ './hd-link.component.scss' ]
})
export class HdLinkComponent implements OnChanges {
  @Input() icon?: string;
  @Input() iconOnly?: boolean;
  @Input() iconSrc?: string;
  @Input() size?: 'lg' | 'sm';
  @Input() disabled?: boolean;
  @Input() color?: 'light' | 'dark' | 'error';
  @Input() direction?: 'left' | 'right' = 'right';

  constructor(private _el: ElementRef) {
    this._addClasses();
  }

  ngOnChanges(changes) {
    const classList: DOMTokenList = (<HTMLElement>this._el.nativeElement).classList;

    classList.remove('disabled-text-link');
    classList.remove('text-link-icon-only');

    if (changes.size?.previousValue) {
      classList.remove(`text-link-${changes.size.previousValue}`);
    }

    if (changes.color?.previousValue) {
      classList.remove(`text-link-${changes.color.previousValue}`);
    }

    if (changes.direction?.previousValue) {
      classList.remove(`thumbnail-${changes.direction?.previousValue}`);
    }

    if (changes.icon?.previousValue || changes.iconSrc?.previousValue) {
      classList.remove(`thumbnail-${this.direction}`);
    }

    this._addClasses();
  }

  _addClasses() {
    const classList: DOMTokenList = (<HTMLElement>this._el.nativeElement).classList;

    classList.add('text-link');

    if (this.icon || this.iconSrc) {
      const iconDirection = this.direction;
      classList.add(`thumbnail-${iconDirection}`);
    }

    if (this.size) {
      classList.add(`text-link-${this.size}`);
    }

    if (this.color) {
      classList.add(`text-link-${this.color}`);
    }

    if (this.disabled) {
      classList.add('disabled-text-link');
    }

    if (this.iconOnly) {
      classList.add('text-link-icon-only');
    }
  }
}
