/* eslint-disable react/function-component-definition */
import React, { useMemo } from 'react';
import clsx from 'clsx';
import RoundedIcon from '../../../components/RoundedIcon';
import styles from './styles.module.scss';
import { getDataIdGenerator } from '../../../utils/generateDataId';

const PERMISSIONS_SORT_ORDER = [
  'TEAM_EDIT',
  'TEAM_DELETE',

  'BILLING_EDIT',

  'PIPELINE_CREATE',
  'PIPELINE_EDIT',
  'PIPELINE_DELETE',

  'DESTINATION_CREATE',
  'DESTINATION_EDIT',
  'DESTINATION_DELETE',

  'MODELS_AND_WORKFLOWS_CREATE',
  'MODELS_AND_WORKFLOWS_EDIT',
  'MODELS_AND_WORKFLOWS_DELETE',

  'ACTIVATION_CREATE',
  'ACTIVATION_EDIT',
  'ACTIVATION_DELETE'
];

export function AllowedIcon({dataId}) {
  return (
    <RoundedIcon
      data-id={dataId}
      className='mr-2'
      containerBg='success-faded'
      containerSize={2}
      iconClasses='text-success'
      iconName='checked-tick'
      isIconColorWhite={false}
    />
  );
}

export function NotAllowedIcon({dataId}) {
  return (
    <RoundedIcon
      data-id={dataId}
      className='mr-2'
      containerBg='error-faded'
      containerSize={2}
      iconName='close'
      iconClasses='text-error'
      isIconColorWhite={false}
    />
  );
}
const sortPermissions = (permissions: any[]) =>
  permissions.sort(
    (permission1, permission2) =>
      PERMISSIONS_SORT_ORDER.indexOf(permission1.permission_name) -
      PERMISSIONS_SORT_ORDER.indexOf(permission2.permission_name)
  );


export const dataIdGenerator = getDataIdGenerator('dropdown-helper-adornment');

export function DropdownHelperAdornment({ option, allPermissions }) {
  const { permissions = [] } = option;

  const [enabledPermissions, disabledPermissions] = useMemo(() => {
    const permissionsSet = new Set(permissions.map(permission => permission.permission_name));

    const enabledPermissionsArr = [];
    const disabledPermissionsArr = [];

    Object.keys(allPermissions).forEach(permission => {
      if (permissionsSet.has(permission)) {
        enabledPermissionsArr.push(allPermissions[permission]);
      } else {
        disabledPermissionsArr.push(allPermissions[permission]);
      }
    });

    return [sortPermissions(enabledPermissionsArr), sortPermissions(disabledPermissionsArr)];
  }, [allPermissions, permissions]);

  return (
    <div className={clsx('h-100  p-3', styles.dropdownHelperAdornment)}>
      {enabledPermissions.map((permission, index) => (
        <div key={permission.title} className='center-flex-row mb-3' data-id={dataIdGenerator(`allowed-${index}`)}>
          <AllowedIcon dataId={dataIdGenerator(`allowed-icon-${index}`)} /> {permission.title}
        </div>
      ))}
      {disabledPermissions.map((permission, index) => (
        <div key={permission.title} className='center-flex-row mb-3' data-id={dataIdGenerator(`not-allowed-${index}`)}>
          <NotAllowedIcon dataId={dataIdGenerator(`not-allowed-icon-${index}`)}/> {permission.title}
        </div>
      ))}
    </div>
  );
}
