import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, exhaustMap, map, switchMap } from 'rxjs/operators';
import { IntegrationLag } from '../../pipeline/models/models';
import { SourceService } from './source.service';


const DEFAULT_LAG_PERIOD = 300000;
const FAST_LAG_PERIOD = 60000;

@Injectable()
export class IntegrationLagService {
  lagDataMap: { [key: number]: BehaviorSubject<IntegrationLag> } = {};

  constructor(
    private _sourceService: SourceService
  ) {}

  pollLagData(id: number): Observable<void> {
    return this._getLagData(id, 0);
  }

  getLagDataObservable(id: number): Observable<IntegrationLag> {
    this._initBehaviourSubject(id);
    return this.lagDataMap[id].asObservable();
  }

  private _initBehaviourSubject(id: number) {
    if (!this.lagDataMap[id]) {
      this.lagDataMap[id] = new BehaviorSubject(undefined);
    }
  }

  private _getLagData(id: number, delayMs: number): Observable<void> {
    return of([]).pipe(
      delay(delayMs),
      exhaustMap(() => {
        return this._sourceService.getLagData(id);
      }),
      map((lagData: IntegrationLag) => {
        this._initBehaviourSubject(id);
        this.lagDataMap[id].next(lagData);
        return lagData;
      }),
      switchMap((lagData: IntegrationLag) => {
        if (lagData.effectiveLag > lagData.minimumLag) {
          return this._getLagData(id, FAST_LAG_PERIOD);
        } else {
          return this._getLagData(id, DEFAULT_LAG_PERIOD);
        }
      })
    );
  }
}
