// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.pipelinesListContainer_E_T4t {\n  display: flex;\n  justify-content: center;\n  margin-top: 32px;\n  overflow: hidden;\n}\n.pipelinesListContainer_E_T4t .pipelinesList__wsJo {\n  width: 1200px;\n  border-radius: 6px;\n  border: 1px solid var(--divider-color);\n  flex-direction: column;\n  display: flex;\n  flex: 1;\n}\n.pipelinesListContainer_E_T4t .listPrimaryHeader_nStCL {\n  padding: 8px 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid var(--divider-color);\n}\n\n.listItemContainer_jmhbC {\n  overflow-y: auto;\n}\n\n.listItem_ZIXP1 {\n  padding: 12px 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid var(--divider-color);\n}\n\n.pipelineDetailsCell_XFfuf {\n  display: flex;\n  width: 388px;\n  align-items: center;\n}\n\n.genericCell__ai7W {\n  display: flex;\n  width: 130px;\n  align-items: center;\n  margin-left: 32px;\n}\n\n.frequencyMenuTriggerWrapper_hsdYO {\n  display: flex;\n  border: 1px solid var(--divider-color);\n  border-radius: 6px;\n}\n.frequencyMenuTriggerWrapper_hsdYO button {\n  padding: 7px 12px;\n  width: 96px;\n  display: flex;\n  justify-content: space-between;\n  border-radius: 0 6px 6px 0;\n}\n\n.bulkUpdateContainer_qyt2p {\n  display: flex;\n  padding: 12px 20px;\n  border-bottom: 1px solid var(--divider-color);\n  justify-content: center;\n  align-items: center;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pipelinesListContainer": "pipelinesListContainer_E_T4t",
	"pipelinesList": "pipelinesList__wsJo",
	"listPrimaryHeader": "listPrimaryHeader_nStCL",
	"listItemContainer": "listItemContainer_jmhbC",
	"listItem": "listItem_ZIXP1",
	"pipelineDetailsCell": "pipelineDetailsCell_XFfuf",
	"genericCell": "genericCell__ai7W",
	"frequencyMenuTriggerWrapper": "frequencyMenuTriggerWrapper_hsdYO",
	"bulkUpdateContainer": "bulkUpdateContainer_qyt2p"
};
export default ___CSS_LOADER_EXPORT___;
