import React, { useCallback, useMemo } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { currencyFormatter } from '../../../legacy-utils/currency';

interface IHdCurrencyFormatProps {
  value: number;
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  dataId?: string;
}

export function HdCurrencyFormat({
  value,
  locale,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
  dataId = ''
}: IHdCurrencyFormatProps) {
  const containerDataId = useMemo(() => getCurrencyFormatDataId(dataId), [dataId]);

  return (
    <span data-id={containerDataId}>
      {currencyFormatter(value, locale, currency, minimumFractionDigits, maximumFractionDigits)}
    </span>
  );
}

export const getCurrencyFormatDataId = (dataId = '') =>
  getDataIdGenerator('currency-format', dataId)('');
