import { Typography } from '@mui/material';
import React from 'react';
import { HdRadio, HdRadioGroup } from '../../../../../components/UIElements';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';
import { AWSAuthenticationType } from './model';

export default function AwsAuthTypeRadioGroup({ field, form }) {
  const dataIdGenerator = getDataIdGenerator('aws-auth-type');

  return (
    <HdRadioGroup
      {...field}
      onChange={val => form.setFieldValue(field.name, val)}
      className='mt-3 mb-5 flex-no-wrap'
    >
      <HdRadio
        dataId={dataIdGenerator(AWSAuthenticationType.IAM_ROLE.toLowerCase())}
        value={AWSAuthenticationType.IAM_ROLE}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Connect using IAM Role</Typography>
        </div>
      </HdRadio>

      <HdRadio
        dataId={dataIdGenerator(AWSAuthenticationType.ACCESS_CREDENTIALS.toLowerCase())}
        value={AWSAuthenticationType.ACCESS_CREDENTIALS}
        className='ml-5'
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Connect using Access Credentials</Typography>
        </div>
      </HdRadio>
    </HdRadioGroup>
  );
}
