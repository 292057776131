import React, { createContext, ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import { Router, NavigationType, Location } from 'react-router-dom';
import { createAngularHistory } from '../app/react-bridge/create-angular-history';

export const HistoryContext = createContext(null);

export function CompatRouter({
  basename,
  history,
  children
}: {
  basename?: string;
  history: ReturnType<typeof createAngularHistory>;
  children?: ReactNode;
}) {
  const [state, setStateImpl] = useState({
    action: history.action,
    location: history.location
  });

  const setState = useCallback(
    (newState: { action: NavigationType; location: Location }) => {
      setStateImpl(newState);
    },
    [setStateImpl]
  );

  useLayoutEffect(() => history.listen(setState), [history, setState]);

  return (
    <HistoryContext.Provider value={history}>
      <Router
        basename={basename}
        children={children}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    </HistoryContext.Provider>
  );
}
