import React from 'react';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';
import { calendarViews } from '../shared/const';

import { TitleGroupProps } from '../shared/interfaces';
import TileGroup from '../TileGroup';
import Day from './Day';

export default function Days<T>(props: TitleGroupProps<T>) {
  const { dateTimeAdapter } = useDateTimeAdapter<T>();
  const { activeStartDate } = props;
  const { ...otherProps } = props;

  const year = dateTimeAdapter.getYear(activeStartDate);
  const monthIndex = dateTimeAdapter.getMonth(activeStartDate);

  const _date = dateTimeAdapter.createDate(year, monthIndex, 1);

  const dayOfWeek = dateTimeAdapter.getDay(_date);

  const offset = 0;

  /**
   * Calendar Month view always renders 6 rows of days, the first row and last row will render some of the sibling
   * month's dates start date -> is the first day rendered in the first row end date -> is the last day rendered in the
   * last row the difference between the first and last is always 42( 7*6 )
   *
   */

  const start = 0 - ((dayOfWeek + 7) % 7);

  const end = start + 41;

  return (
    <TileGroup
      {...otherProps}
      className='react-calendar__month-view__days'
      count={7}
      currentMonthIndex={monthIndex}
      dateTransform={(day: number) => dateTimeAdapter.addCalendarDays(_date, day)}
      dateType={calendarViews.DAY}
      end={end}
      start={start}
      tile={Day}
    />
  );
}
