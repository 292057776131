import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, TemplateRef } from '@angular/core';
import { SecondaryNavComponent } from '../secondary-nav/secondary-nav.component';
import { RouterLinkActive } from '@angular/router';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[hdSecondaryNavItem]',
  templateUrl: './secondary-nav-item.component.html',
  styleUrls: ['./secondary-nav-item.component.scss']
})
export class SecondaryNavItemComponent implements OnInit, OnDestroy {
  get expanded() {
    return this._secondaryNav.expanded;
  }

  @Input() navActive: boolean;
  @Input() label: string;
  @Input() iconName: string;
  @Input() shortcut: string;
  @Input() badgeContent: string;
  @Input() badgeColor: string;
  @Input() tooltipExtraContent: TemplateRef<any>;

  focused = false;

  constructor(
    @Optional() private _secondaryNav: SecondaryNavComponent,
    @Optional() @Self() private _routerLinkActive: RouterLinkActive,
    private _fm: FocusMonitor,
    private _el: ElementRef
  ) { }

  ngOnInit(): void {
    this.monitorFocus();
  }

  get active() {
    if (typeof this._routerLinkActive !== 'undefined' && this._routerLinkActive) {
      return this._routerLinkActive.isActive;
    }

    return this.navActive;
  }

  monitorFocus() {
    this._fm.monitor(this._el.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
    });
  }

  ngOnDestroy() {
    this._fm.stopMonitoring(this._el.nativeElement);
  }
}

