import { useEffect, useState } from 'react';
import useService from '../../hooks/useService';
import { TourConductorService } from '../../../app/product-tour-v2/tour-conductor.service';
import { TourOrchestratorService } from '../../../app/product-tour-v2/tour-orchestrator.service';
import { ITour, TourPriority, TourStep } from '../../../app/product-tour-v2/model';
import { TourRegisterService } from '../../../app/product-tour-v2/tour-register.service';
import useForceUpdate from '../../hooks/useForceUpdate';

export const useProductTour = () => {
  const _tourConductorService = useService(TourConductorService);
  const _tourOrchestratorService = useService(TourOrchestratorService);
  const _tourRegisteryService = useService(TourRegisterService);
  const forceUpdate = useForceUpdate();

  const [activeTourStep, setActiveTourStep] = useState<TourStep>(null);

  useEffect(() => {
    const activeItemSub = _tourConductorService.activeTourStep$.subscribe(step => {
      setActiveTourStep(step);
    });

    const registerySub = _tourRegisteryService.registryRefresh$.subscribe(() => {
      forceUpdate();
    });

    return () => {
      activeItemSub.unsubscribe();
      registerySub.unsubscribe();
    };
  }, []);

  return {
    activeTour: _tourOrchestratorService._activeTourId.getValue(),
    activeStep: activeTourStep,
    tourConductorMetadata: {
      stepIndex: _tourConductorService.currentStep
    },
    showNextStep: props => {
      _tourConductorService.showNextStep(props);
    },
    showPrevStep: props => {
      _tourConductorService.showPrevStep(props);
    },
    endCurrentTour: () => {
      _tourOrchestratorService.endCurrentTour();
    },
    scheduleTour: (tour: ITour, priority: TourPriority) => {
      _tourOrchestratorService.scheduleTour(tour, priority);
    },
    runTour: (tour: ITour) => {
      _tourOrchestratorService.runTour(tour);
    }
  };
};
