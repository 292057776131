import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HdScrollingModule } from '../scrolling/scrolling.module';
import { AccordionComponent } from './accordion.component';
import { AccordionItem } from './accordion-item/accordion-item.component';
import { HdA11yModule } from '../a11y/a11y.module';
import { IconModule } from '../icon/icon.module';


@NgModule({
  declarations: [
    AccordionComponent,
    AccordionItem,
  ],
  imports: [ CommonModule, HdA11yModule, IconModule, HdScrollingModule ],
  exports: [
    AccordionComponent,
    AccordionItem,
  ]
})
export class AccordionModule {}
