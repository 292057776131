import React from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdButton } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useVerifyEmail } from '../useVerifyEmail';

export interface VerifyEmailProps {
  featuredCustomers: FeaturedCustomers;
  email: string;
}

export function VerifyEmail() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { verifyEmail } = useVerifyEmail();

  return (
    <HdResolvedComponent
      Component={VerifyEmailInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers,
        email: verifyEmail
      }}
    />
  );
}

function VerifyEmailInner({ email, featuredCustomers }: VerifyEmailProps) {
  const history = useHistory();

  const dataIdGenerator = getDataIdGenerator('verify-email');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.EMAIL_VERIFIED}
      featuredCustomers={featuredCustomers}
    >
      <HdButton
        dataId={dataIdGenerator('submit')}
        size='lg'
        className='w-100'
        onClick={() => history.push(`/login?email=${email}`)}
      >
        Log In
      </HdButton>
    </HdAuth>
  );
}
