// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.copyBtn_hxl4s {\n  position: absolute;\n  top: 12px;\n  right: 12px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyBtn": "copyBtn_hxl4s"
};
export default ___CSS_LOADER_EXPORT___;
