import { AppConfig } from '../../../app/core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS,
  DEFAULT_NETWORK_REQ_OPTIONS
} from '../../../app/core/constants';
import {
  NetworkRequestOptions,
  RequestPaginationData,
  HTTPRequestMethodEnum
} from '../../../app/core/models/request';
import { SessionUpdatesFactory } from '../../../app/core/models/session-update';
import { WebHookFailureRecordsFactory } from '../../../app/core/models/webhook-failure-record';
import { courier, CourierNetworkRequestOptions } from '../../../app/core/service/courier.service';
import { _settingsStorageService } from '../../../app/core/service/settings-storage.service';
import { _teamSettingsService } from '../../../app/core/service/team-settings.service';
import { createHttpParams } from '../../legacy-utils/request';
import { GoogleAdsVersionEnum } from '../../../app/nodes/models';
import { DraftPipelinePayload } from '../../../app/pipeline/draft-source/models';
import { StorageKey } from '../../../app/pipeline/storage-key';
import { OffsetViews } from '../../../app/core/models/offset-view';
import { _sshService } from '../../../app/core/service/ssh.service';
import { getOffsetViewUpdatePayload } from '../../legacy-utils/offset-view';
import { EventsBarGraphFactory } from '../../../app/core/models/events-bar-graph-data-point';
import { SELECTOR_SEARCH_KEY } from '../../../app/shared/single-level-selector/constants';
import { PipelinePriorityEnum } from '../../../app/pipeline/models/models';
import { DriveFolderMeta, DriveFoldersFactory } from './create/ConfigSourceType/GoogleDrive/model';

export const PIPELINE_STATS_TIME_INTERVAL = 1440;

const appConfig = new AppConfig();
const PipelinesAPI = {
  integrationsURL: appConfig.getIntegrationsURL(),
  sourcesURL: appConfig.getSourcesURL(),
  draftSourceURL: appConfig.getDraftSourcesURL(),
  statsURL: appConfig.getStatsURL(),

  getIntegrationURL(id: number) {
    return `${this.integrationsURL}/${id}`;
  },

  getIntegrationDisplayDetails(id: number, options = BACKGROUND_NETWORK_REQ_OPTIONS) {
    const requestUrl = `${this.getIntegrationURL(id)}/display-details`;

    return courier.get(requestUrl, options).then(res => {
      res.data.config = _sshService.filterSSHDisplayConfig(res.data.config);
      return res;
    });
  },

  getSourceTypes(showLoading = true) {
    const requestUrl = `${this.sourcesURL}/get-types`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return courier.get(requestUrl, options);
  },

  getIntegrationsURLWithPipelineId(pipelineId: number) {
    return `${this.integrationsURL}/${pipelineId}`;
  },

  validatePipelineName(sourceName: string, config = {}) {
    const requestUrl = `${this.integrationsURL}/pre-validation`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      ...config
    };

    const params: any = {
      source_name: sourceName
    };

    return courier.put(requestUrl, options, params);
  },

  getIntegrations(extras?: {
    streaming?: boolean;
    getSidelineCount?: boolean;
    getTaskHealthStats?: boolean;
    getDeleted?: boolean;
  }, showLoading?: boolean) {
    return PipelinesAPI.getIntegrationsV2(extras);
  },

  getIntegrationsV2(extras?: {
    streaming?: boolean;
    getSidelineCount?: boolean;
    getTaskHealthStats?: boolean;
    getDeleted?: boolean;
  }, signal?: AbortSignal) {
    let requestUrl = `${this.integrationsURL}?`;

    const options: CourierNetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      signal
    };

    if (typeof extras !== 'undefined') {
      if (extras.streaming) {
        requestUrl += '&at_least_status=STREAMING';
      }

      if (extras.getSidelineCount) {
        requestUrl += '&fetch_num_replay_queue_count=true';
      }

      if (extras.getTaskHealthStats) {
        requestUrl += '&task_health_stats=true';
      }

      if (extras.getDeleted) {
        requestUrl += '&include_deleted=true';
      }
    }

    /**
     * get_source_dest_config is set to true in backend by default, for backward compatibility.
     */
    requestUrl += '&get_source_dest_config=false';

    return courier.get(requestUrl, options);
  },

  getIntegrationSessionUpdates() {
    const requestUrl = `${this.integrationsURL}/session/users`;

    return courier
      .get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS)
      .then(res => SessionUpdatesFactory(res.data?.session_data || []));
  },

  changePosition(pipelineId: any, taskName: string, offsetView: OffsetViews) {
    const requestUrl = `${this.getIntegrationURL(pipelineId)}/tasks/${encodeURIComponent(
      taskName
    )}/offset`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const params = {
      offset: getOffsetViewUpdatePayload(offsetView),
      skip_tracking: true,
      only_offset: true
    };

    return courier.put(requestUrl, options, params);
  },

  getFacebookPages(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/fbpages/pages/${oAuthTokenId}`;

    return courier.get(requestUrl);
  },

  getTiktokAdsAccounts(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/tiktok-ads/ad-accounts/${oAuthTokenId}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getAdrollReportsList() {
    const requestUrl = `${this.integrationsURL}/adroll/report-types`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getAdrollAdvertisables(oAuthTokenId: number, paginationData: RequestPaginationData, config = {}) {
    const requestUrl = `${this.integrationsURL}/adroll/oauth/${oAuthTokenId}/advertisables`;

    const params: { [key: string]: any } = {
      page_no: paginationData.page,
      page_size: paginationData.limit
    };

    if (SELECTOR_SEARCH_KEY in paginationData && paginationData[SELECTOR_SEARCH_KEY] !== '') {
      params.search_name = paginationData[SELECTOR_SEARCH_KEY];
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getLinkedinAccounts(oAuthTokenId: number, paginationData: RequestPaginationData, config = {}) {
    const requestUrl = `${this.integrationsURL}/linkedin-ads/accounts/${oAuthTokenId}`;

    const params: { [key: string]: any } = {
      page: paginationData.page,
      limit: paginationData.limit
    };

    if (SELECTOR_SEARCH_KEY in paginationData && paginationData[SELECTOR_SEARCH_KEY] !== '') {
      params.search_account = paginationData[SELECTOR_SEARCH_KEY];
    }

    const options: NetworkRequestOptions = {
      uiOptions: { ...BACKGROUND_NETWORK_REQ_UI_OPTIONS, handleUnauthorisedResponse: false },
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getPinterestAccounts(oAuthTokenId: number, paginationData: RequestPaginationData, config = {}) {
    const requestUrl = `${this.integrationsURL}/pinterest-ads/accounts/${oAuthTokenId}`;

    const params: { [key: string]: any } = {
      page: paginationData.page,
      limit: paginationData.limit,
      bookmark: null
    };

    if (SELECTOR_SEARCH_KEY in paginationData && paginationData[SELECTOR_SEARCH_KEY] !== '') {
      params.search_account = paginationData[SELECTOR_SEARCH_KEY];
    }

    const options: NetworkRequestOptions = {
      uiOptions: { ...BACKGROUND_NETWORK_REQ_UI_OPTIONS, handleUnauthorisedResponse: false },
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getDriveFoldersForAccount(
    tokenId: number,
    authType: string,
    paginationData: any,
    config: any
  ): Promise<DriveFolderMeta> {
    const requestUrl = `${this.integrationsURL}/drive/folders/${authType}/${tokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams(paginationData)
      },
      ...config
    };

    return courier.get(requestUrl, options).then((res: any) => DriveFoldersFactory(res.data));
  },

  getGA360ProductId(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/bigquery/GOOGLE_ANALYTICS_360/projects/${oAuthTokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options).then((res: any) => res.data?.projects || []);
  },

  getGA360DatasetId(oAuthTokenId: number, projectId: string, config = {}) {
    const requestUrl = `${this.integrationsURL}/bigquery/GOOGLE_ANALYTICS_360/datasets/${oAuthTokenId}/${projectId}`;

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.get(requestUrl, options).then((res: any) => res.data?.datasets || []);
  },

  getBigQuerySourceProjectId(authType: string, accountId: number) {
    const requestUrl = `${this.integrationsURL}/bigquery/BIGQUERY/projects/${authType}/${accountId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };
    return courier.get(requestUrl, options);
  },

  getBigQuerySourceDatasetId(
    authType: string,
    accountId: number,
    paginationData: RequestPaginationData,
    projectId: string,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/bigquery/BIGQUERY/datasets/${authType}/${accountId}/${projectId}`;

    const params: { [key: string]: any } = {
      page: paginationData.page,
      limit: paginationData.limit
    };

    if (SELECTOR_SEARCH_KEY in paginationData && paginationData[SELECTOR_SEARCH_KEY] !== '') {
      params.search_name = paginationData[SELECTOR_SEARCH_KEY];
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };

    return courier.get(requestUrl, options).then((res: any) => res.data);
  },

  computeObjectsAndConfigForStaticSource(sourceTypeIdentifier: string): Promise<string> {
    const requestUrl = `${this.sourcesURL}/${sourceTypeIdentifier}/objects-and-config`;

    return courier
      .post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, {})
      .then(res => res.data.session_id);
  },

  getObjectsAndConfig(sessionId: string, listParams: any, config = {}) {
    const requestUrl = `${this.sourcesURL}/objects-and-config/${sessionId}`;
    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };
    return courier.post(requestUrl, options, listParams);
  },

  getAccessibleObjectsAndConfig(
    sourceTypeIdentifier,
    accessibleListParams: any,
    draftSourceID = null,
    config = {}
  ) {
    const requestUrl = `${this.sourcesURL}/${sourceTypeIdentifier}/accessible-source-objects`;
    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: draftSourceID
        })
      },
      ...config
    };
    return courier.post(requestUrl, options, accessibleListParams);
  },

  getInstagramAccounts(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/instagram_business/accounts/oauth/${oAuthTokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options).then((res: any) =>
      (res.data || []).map(account => ({
        ...account,
        name: account.display_field
      }))
    );
  },

  getGA4Accounts(oAuthTokenId: number, sourceVersion: number | null) {
    const requestUrl = `${this.integrationsURL}/ga-4/${oAuthTokenId}/accounts`;

    const queryParams: { source_version?: number } = {};
    if (sourceVersion !== null) {
      queryParams.source_version = sourceVersion;
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams(queryParams)
      }
    };

    return courier.get(requestUrl, options);
  },

  getGA4Properties(
    oAuthTokenId: number,
    accountId: string,
    sourceVersion: number | null,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/ga-4/${oAuthTokenId}/${accountId}/properties`;

    const queryParams: { source_version?: number } = {};
    if (sourceVersion !== null) {
      queryParams.source_version = sourceVersion;
    }

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config,
      networkOptions: {
        params: createHttpParams(queryParams)
      }
    };

    return courier.get(requestUrl, options);
  },

  getGA4Metadata(
    oAuthTokenId: number,
    propertyId: string,
    sourceVersion: number | null,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/ga-4/${oAuthTokenId}/${propertyId}/metadata`;

    const queryParams: { source_version?: number } = {};
    if (sourceVersion !== null) {
      queryParams.source_version = sourceVersion;
    }

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config,
      networkOptions: {
        params: createHttpParams(queryParams)
      }
    };

    return courier.get(requestUrl, options);
  },

  getFirebaseAnalyticsAccounts(oAuthTokenId: number, sourceVersion: number | null) {
    const requestUrl = `${this.integrationsURL}/firebase-analytics/${oAuthTokenId}/accounts`;

    const queryParams: { source_version?: number } = {};
    if (sourceVersion !== null) {
      queryParams.source_version = sourceVersion;
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams(queryParams)
      }
    };

    return courier.get(requestUrl, options);
  },

  getFirebaseAnalyticsProperties(
    oAuthTokenId: number,
    accountId: string,
    sourceVersion: number | null,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/firebase-analytics/${oAuthTokenId}/${accountId}/properties`;

    const queryParams: { source_version?: number } = {};
    if (sourceVersion !== null) {
      queryParams.source_version = sourceVersion;
    }

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config,
      networkOptions: {
        params: createHttpParams(queryParams)
      }
    };

    return courier.get(requestUrl, options);
  },

  getFirebaseAnalyticsMetadata(
    oAuthTokenId: number,
    propertyId: string,
    sourceVersion: number | null,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/firebase-analytics/${oAuthTokenId}/${propertyId}/metadata`;

    const queryParams: { source_version?: number } = {};
    if (sourceVersion !== null) {
      queryParams.source_version = sourceVersion;
    }

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config,
      networkOptions: {
        params: createHttpParams(queryParams)
      }
    };

    return courier.get(requestUrl, options);
  },

  getSnapchatAdsMetadata(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/snapchat-ads/orgs/${oAuthTokenId}`;

    return courier
      .get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS)
      .then((res: any) => res?.data?.orgs);
  },

  getSnapchatAdsSettings() {
    const requestUrl = `${this.integrationsURL}/snapchat-ads/settings`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).then((res: any) => res?.data);
  },

  getGscProperties(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/google-search-console/properties/${oAuthTokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options).then((res: any) => res.data);
  },

  getTypeformWorkspaces(oAuthTokenId: number, paginationData: RequestPaginationData, config = {}) {
    const requestUrl = `${this.integrationsURL}/typeform/workspaces/${oAuthTokenId}`;

    const params: { [key: string]: any } = {
      page: paginationData.page,
      limit: paginationData.limit
    };

    if (SELECTOR_SEARCH_KEY in paginationData && paginationData[SELECTOR_SEARCH_KEY] !== '') {
      params.search_workspace = paginationData[SELECTOR_SEARCH_KEY];
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getTaboolaAccounts(clientId: string, clientSecret: string, pipelineID = null) {
    const requestUrl = `${this.integrationsURL}/taboola/accounts`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          clientId,
          clientSecret,
          integrationId: pipelineID,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  getRecurlyEuEnabledStatus() {
    const requestUrl = `${this.integrationsURL}/recurly/show-env-toggle`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getXeroTenants(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/xero/tenants/${oAuthTokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getFlatStorageDirectories(
    typeIdentifier,
    integrationId,
    listParams,
    paginationData: RequestPaginationData,
    config = {}
  ) {
    const integration = integrationId ? { integration_id: integrationId } : {};
    const requestUrl = `${this.integrationsURL}/${typeIdentifier}/dirs`;

    const httpParams: { [key: string]: any } = {
      // as api page counter stats with 1
      ...integration,
      draft_source_id: this.getDraftDataIdFromStorage(),
      page_number: paginationData.page + 1,
      page_size: paginationData.limit,
      cache_id: paginationData.cache_id
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: httpParams
      },
      ...config
    };

    return courier.post(requestUrl, options, listParams);
  },

  getGooglePlayReportsList() {
    const requestUrl = `${this.integrationsURL}/google-play-console/reports`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestUrl, options);
  },

  getAsanaProperties(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/asana/properties/${oAuthTokenId}`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestUrl, options);
  },

  getYoutubeChannelReportList(oAuthTokenId: string) {
    const requestUrl = `${this.integrationsURL}/youtube/report-types/oauth/${oAuthTokenId}`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestUrl, options);
  },

  getTwitterAdsCountries(params: any) {
    const requestUrl = `${this.integrationsURL}/twitter-ads/targeting-criteria/locations`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({ ...params, draft_source_id: this.getDraftDataIdFromStorage() })
      }
    };
    return courier.get(requestUrl, options);
  },

  getTwitterAdsAccounts(params: any) {
    const requestUrl = `${this.integrationsURL}/twitter-ads/accounts`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({ ...params, draft_source_id: this.getDraftDataIdFromStorage() })
      }
    };
    return courier.get(requestUrl, options);
  },

  getGCMProfilesAndReportTypes(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/cm/oauth/${oAuthTokenId}/accounts/view`;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).then((res: any) => res.data);
  },

  getGCMReports(oAuthTokenId: number, profiles: any[], reportType: string[]) {
    const requestUrl = `${this.integrationsURL}/cm/oauth/${oAuthTokenId}/accounts/reports`;

    const params = {
      profile_id: profiles.map(profile => profile.id),
      report_type: reportType
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };
    return courier.get(requestUrl, options).then((res: any) => res.data);
  },

  getGCMMetrics(reportType: string) {
    const requestUrl = `${this.integrationsURL}/cm/${reportType}/metrics`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).then((res: any) => res.data);
  },

  getGCMDimensions(reportType: string) {
    const requestUrl = `${this.integrationsURL}/cm/${reportType}/dimensions`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).then((res: any) => res.data);
  },

  getDiagnoseJobStatus(typeIdentifier, runningJobId) {
    const requestUrl = `${this.sourcesURL}/${typeIdentifier}/test/job/${runningJobId}`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };
    return courier.get(requestUrl, options);
  },

  getSourceFiles(
    pipelineID: number,
    paginationData: RequestPaginationData,
    networkRequestOptions = {}
  ) {
    const requestUrl = `${this.integrationsURL}/${pipelineID}/file-details`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      ...networkRequestOptions
    };

    const requestParams = {
      ...paginationData
    };

    return courier.post(requestUrl, options, requestParams);
  },

  getAppleSearchAdsApiKeyOrganizationList(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/apple-search-ads/organizations/${oAuthTokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getAppleSearchAdsPublicKey(tokenId = null) {
    let requestUrl = `${this.integrationsURL}/apple-search-ads/public-key`;

    if (tokenId) {
      requestUrl = `${requestUrl}/${tokenId}`;
    }

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getFBAConnectionFields() {
    const requestUrl = `${this.integrationsURL}/fbads/accounts/settings`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getFBBreakdownsDisplayData() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1672821956/dashboard/json/fba/breakdowns-display-data_g5u0ua.json';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getFBFieldsData() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1672821956/dashboard/json/fba/fields-display-data_lucum3.json';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getFBFieldsDimensionsData() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1673244643/dashboard/json/fba/dimension-display-data_oit5ib.json';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getFBFieldsMetricsData() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1673244643/dashboard/json/fba/metrics-display-data_az0ji1.json';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getFBAAccounts(oauthTokenId: number, paginationData: RequestPaginationData, config) {
    const requestUrl = `${this.integrationsURL}/fbads/accounts/${oauthTokenId}`;

    const params = {
      page: paginationData.page || 0,
      size: paginationData.limit
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getJsonParsingStrategy(sourceType, destinationType) {
    const requestUrl = `${this.integrationsURL}/json-parsing-strategy`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          source_type: sourceType,
          destination_type: destinationType
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  getGAPreBuiltReports() {
    const requestUrl = `${this.integrationsURL}/google-analytics/pre-built-reports`;

    return courier
      .get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS)
      .then(res => (res.data ? res.data : []));
  },

  getGAMetrics(
    oauthTokenId: number,
    accountId: string,
    propertyId: string,
    viewId: string,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/google-analytics/${oauthTokenId}/metrics`;

    const parameters: any = {
      account_id: accountId,
      property_id: propertyId,
      view_id: viewId
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams(parameters)
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getGADimensions(oauthTokenId: number, accountId: string, propertyId: string, config = {}) {
    const requestUrl = `${this.integrationsURL}/google-analytics/dimensions/${oauthTokenId}/${accountId}/${propertyId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getGASegments(oauthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/google-analytics/${oauthTokenId}/segments`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(requestUrl, options).then((res: any) => res.data);
  },

  getGAMetaData(oauthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/google-analytics/${oauthTokenId}/metadata`;

    const options: NetworkRequestOptions = {
      uiOptions: {}
    };
    return courier.get(requestUrl, options).then((res: any) => res.data);
  },

  getWebHookFailures(
    pipelineID: number,
    paginationData: RequestPaginationData,
    networkRequestOptions = {}
  ) {
    const requestUrl = `${this.integrationsURL}/${pipelineID}/web-hook/failures`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: {
          page: paginationData.page,
          limit: paginationData.limit
        }
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      ...networkRequestOptions
    };

    return courier.get(requestUrl, options).then(res => {
      res.data.failure_records = WebHookFailureRecordsFactory(res.data.failure_records);
      return res.data;
    });
  },

  resolveWebHookFailureRecord(pipelineID: number, failedRecordHash: string) {
    const requestUrl = `${this.integrationsURL}/${pipelineID}/web-hook/${failedRecordHash}/resolve`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.put(requestUrl, options);
  },

  getGACubes() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1672821969/dashboard/json/ga/cubes_bh7r36.json';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getGoogleAdsPath(version: GoogleAdsVersionEnum) {
    return version === GoogleAdsVersionEnum.v1 ? 'google-adwords' : 'google-ads';
  },

  getAdwordsManagerProfiles(version: GoogleAdsVersionEnum, oAuthTokenId: number, config = {}) {
    const requestUrl = `${this.integrationsURL}/${this.getGoogleAdsPath(
      version
    )}/oauth/${oAuthTokenId}/customers`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getGoogleAdsCustomerProfiles(
    oAuthTokenId: number,
    customerId: number,
    version: GoogleAdsVersionEnum,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/${this.getGoogleAdsPath(
      version
    )}/oauth/${oAuthTokenId}/customers/${customerId}/profiles`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        ...config
      }
    };

    return courier.get(requestUrl, options);
  },

  getDraftDataIdFromStorage() {
    if (_teamSettingsService.getTeamSettingsValue()?.draft_pipeline_enabled) {
      const draftData = _settingsStorageService.getSettings(
        StorageKey.CREATE_DRAFT_PIPELINE_SOURCE
      );
      return draftData?.id || null;
    }
    return null;
  },

  getGAViews(oauthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/google-analytics/views/${oauthTokenId}`;

    return courier
      .get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS)
      .then(res => (res.data?.items ? res.data.items : []));
  },

  getSqlDatabases(sourceTypeIdentifier: string, requestParams: any) {
    const requestUrl = `${this.integrationsURL}/jdbc/${sourceTypeIdentifier}/databases`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return courier.post(requestUrl, options, requestParams);
  },

  getSqlSchemas(sourceTypeIdentifier: string, requestParams: any) {
    const requestUrl = `${this.integrationsURL}/jdbc/${sourceTypeIdentifier}/schemas`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return courier.post(requestUrl, options, requestParams);
  },

  getWebhookAuthCredentials() {
    const requestUrl = `${this.integrationsURL}/new-auth-keys`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getAmazonAdsProfiles(oAuthTokenId: number, config = {}) {
    const requestUrl = `${this.integrationsURL}/amazon-ads/profiles/${oAuthTokenId}`;
    const options = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getAmazonAdsReports() {
    const requestUrl = `${this.integrationsURL}/amazon-ads/report-types`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getAmazonAdsCoreObjects() {
    const requestUrl = `${this.integrationsURL}/amazon-ads/core-objects`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getAmazonAdsReportDetails(reportName: string) {
    const requestUrl = `${this.integrationsURL}/amazon-ads/report-details/${reportName}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getDraftSourceList() {
    const requestUrl = this.draftSourceURL;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getIntegrationCreationInfo(showLoading = true) {
    const requestUrl = `${this.integrationsURL}/creation_info`;
    return courier.get(requestUrl, {
      uiOptions: {
        showLoading,
        showSuccessMsg: false,
        showErrorMsg: false
      }
    });
  },

  resumePipeline(pipelineID: number) {
    return courier.put(`${this.getIntegrationURL(pipelineID)}/resume`, {
      uiOptions: {
        ...DEFAULT_NETWORK_REQ_OPTIONS.uiOptions,
        showErrorMsg: false
      }
    });
  },

  deletePipeline(pipelineID: number) {
    return courier.delete(this.getIntegrationURL(pipelineID));
  },

  getDateFormats() {
    const requestUrl = `${this.integrationsURL}/rest-api/date-format`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false
      }
    };
    return courier.get(requestUrl, options);
  },

  testRestApi(
    data: any,
    initialRequest: boolean,
    testPagination: boolean,
    payloadData: any,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/rest-api/test`;

    const body: { [key: string]: any } = {
      config: data,
      integration_id: payloadData.integrationId
    };
    if (initialRequest) {
      body.config.is_initial_test_connection = true;
    }
    if (testPagination) {
      body.offset = payloadData.offset;
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      },
      ...config
    };

    return courier.post(requestUrl, options, body);
  },

  getDraftSources() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: true
      }
    };

    return courier.get(this.draftSourceURL, options);
  },

  getDraftSourcesByType(type: string) {
    const requestUrl = `${this.draftSourceURL}/source/${type}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  updatePipelinePriority(pipelineID: number, priority: PipelinePriorityEnum) {
    return courier.put(`${this.getIntegrationURL(pipelineID)}/priorities/${priority}`);
  },

  pausePipeline(pipelineId: number, forcePause = false) {
    const requestURL = `${this.getIntegrationsURLWithPipelineId(pipelineId)}/pause`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          force: forcePause
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.put(requestURL, options);
  },

  getQuotaLimit(pipelineId: number) {
    const requestUrl = `${this.integrationsURL}/frequency_change/${pipelineId}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  schedulePipeline(pipelineId: number, policy: any, floPolicy = null) {
    const params: any = {
      pipeline_schedule: policy
    };

    if (floPolicy) {
      params.full_load_schedule = floPolicy;
    }

    const requestUrl = `${this.integrationsURL}/${pipelineId}/schedule`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.put(requestUrl, options, params);
  },

  getSheetsForAccount(tokenId: number, authType: string, paginationData: RequestPaginationData) {
    const requestUrl = `${this.integrationsURL}/google-sheets/sheets/${authType}/${tokenId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams(paginationData)
      }
    };

    return courier.get(requestUrl, options);
  },

  getSheetsForSpreadsheet(tokenId: number, authType: number, spreadsheetId: string) {
    const requestUrl = `${this.integrationsURL}/google-sheets/worksheets/${authType}/${tokenId}/${spreadsheetId}`;
    const options: NetworkRequestOptions = { uiOptions: { showLoading: false } };

    return courier.get(requestUrl, options);
  },

  getIntegrationStats(
    id: string | number,
    minutes = PIPELINE_STATS_TIME_INTERVAL,
    maxPoints = 1,
    config = {}
  ) {
    const requestUrl = `${appConfig.getStatsURL()}/integration-summary/${id}`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints
        })
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getMSAdsObjects() {
    const requestUrl = `${this.integrationsURL}/microsoft-ads/objects`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getMSAdsManagerAccounts(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/microsoft-ads/oauth/${oAuthTokenId}/manager-accounts`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getMsAdsCustomerAccounts(oAuthTokenId: number, managerId: string) {
    const requestUrl = `${this.integrationsURL}/microsoft-ads/oauth/${oAuthTokenId}/manager-accounts/${managerId}/ad-accounts`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getPipelineStats(pipelineId: number, minutes: number, maxPoints: number) {
    const requestUrl = `${this.statsURL}/integration-summary/${pipelineId}`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints
        })
      }
    };

    return courier.get(requestUrl, options).then(res => EventsBarGraphFactory(res.data.stats));
  },

  getGoogleAdsReports(apiVersion: string) {
    const requestUrl = `${this.integrationsURL}/google-ads/${apiVersion}/reports`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getGoogleAdsCustomFieldsData() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1723644029/dashboard/json/gads/resources_myjbha.json';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getGoogleAdsConversionWindow() {
    const requestUrl = `${this.integrationsURL}/google-ads/conversion-windows`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getGoogleAdsAggregationWindow(reportName: string, version = '') {
    const requestUrl = `${this.integrationsURL}/google-ads/aggregation-windows${version}`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({ report_name: reportName })
      }
    };

    return courier.get(requestUrl, options);
  },

  getGoogleAdsResourceSchema(
    oAuthTokenId: number,
    resourceName: string,
    apiVersion: string,
    config = {}
  ) {
    const requestUrl = `${this.integrationsURL}/google-ads/${apiVersion}/${oAuthTokenId}/${resourceName}/resource-schema`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  deleteDraftSource(id: number, showLoading = false) {
    const requestUrl = `${this.draftSourceURL}/${id}`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return courier.delete(requestUrl, options);
  },

  getJobConfigsDropdownData(url: string, method: string, jobConfig: any, sourceConfig: any) {
    const requestUrl = `${appConfig.getDynamicURL()}/${url}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: true
      }
    };

    const reqBody = {
      source_config: sourceConfig,
      job_config: jobConfig
    };

    return courier.sendRequest(HTTPRequestMethodEnum[method], requestUrl, reqBody, options);
  },

  validateJobConfig(request: any, sourceTypeIdentifier: string) {
    const requestUrl = `${this.integrationsURL}/${sourceTypeIdentifier}/validate/secondary-config`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return courier.post(requestUrl, options, request);
  },

  getJobConfigDropdownData(url: string, method: string, jobConfig: any, sourceConfig: any) {
    const requestUrl = `${this._appConfig.getDynamicURL()}/${url}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    const reqBody = {
      source_config: sourceConfig,
      job_config: jobConfig
    };

    return courier.sendRequest(HTTPRequestMethodEnum[method], requestUrl, reqBody, options);
  },

  getDraftSourceByID(id: string) {
    const requestUrl = `${this.draftSourceURL}/${id}`;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).then((res: any) => ({
      ...res.data,
      isDraft: true
    }));
  },

  createDraftSource(params: DraftPipelinePayload) {
    const requestUrl = this.draftSourceURL;

    return courier
      .post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params)
      .then((res: any) => res.data);
  },

  updateDraftSource(id: number, params: DraftPipelinePayload) {
    const requestUrl = `${this.draftSourceURL}/${id}`;

    return courier
      .put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params)
      .then((res: any) => res.data);
  },

  getGoogleAdsAPIVersions() {
    const requestUrl = `${this.integrationsURL}/google-ads/api-versions`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    return courier.get(requestUrl, options);
  },

  upgradeGoogleAdsPipeline(id: number, options?: NetworkRequestOptions) {
    const requestUrl = `${this.integrationsURL}/${id}/upgrade-pipeline`;
    return courier.put(requestUrl, options);
  },

  requestStreamingMode(params: { source_type: string }) {
    const requestUrl = `${this.integrationsURL}/request-streaming-mode`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.put(requestUrl, options);
  },

  upgradeZendeskPipeline(pipelineId: number) {
    const requestUrl = `${this.sourcesURL}/zendesk/${pipelineId}/migrate-to-v2/`;
    return courier.post(requestUrl, DEFAULT_NETWORK_REQ_OPTIONS, {});
  }
};

export default PipelinesAPI;
