import { getDataIdsFromContract } from '../../../utils/generateDataId';

export const QUERY_CONFIRM_DIALOG_DATA_IDS = getDataIdsFromContract({
  modal: 'modal',
  base: 'query-confirm-dialog',
  title: 'title',
  body: 'body',
  close: 'close',
  cancel: 'cancel',
  confirm: 'confirm',
  query: 'query'
});
