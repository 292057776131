import React from 'react';

export default function DeleteAccountShimmer({ dataId }) {
  return (
    <div className='box' data-id={`${dataId}-shimmer`}>
      <div className='box__header'>
        <div className='shimmer shimmer-line' style={{ width: '100px', height: '8px' }} />
      </div>
      <div className='box__body'>
        <div className='row'>
          <div className='col-md-5 col-xs-12'>
            <div
              className='shimmer shimmer-line'
              style={{ width: '100px', height: '8px', marginBottom: '12px' }}
            />
            <div className='shimmer shimmer-line' style={{ width: '300px', height: '12px' }} />
            <div
              className='shimmer shimmer-line'
              style={{ width: '200px', height: '8px', marginTop: '12px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
