/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, InputAdornment, Slide, Typography } from '@mui/material';
import { sanitize } from 'dompurify';
import { FieldProps, getIn } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { QUERY_DEBOUNCE_TIME } from '../../../../app/core/constants';
import { isPromiseCancelError } from '../../../legacy-utils/promise';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import useDebounce from '../../../hooks/useDebounce';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdTextField } from '../../UIElements/HdTextField';
import styles from './styles.module.scss';

interface IHdFormikTextFieldValidator {
  className?: string;
  label?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  id?: string;
  helperText?: string;
  asyncValidator: Function;
  asyncMonitor?: Function;
  onChangeHandler?: Function;
  errorSuffixElement?: React.ReactElement;
}

// eslint-disable-next-line react/function-component-definition
const HdFormikTextFieldValidator: React.FC<IHdFormikTextFieldValidator & FieldProps> = ({
  field,
  form,
  label,
  asyncValidator,
  id,
  helperText,
  variant = 'outlined',
  asyncMonitor,
  onChangeHandler,
  errorSuffixElement,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string>(field.value || '');
  const [error, setError] = useState<string>();
  const debouncedSearchTerm = useDebounce(inputValue, QUERY_DEBOUNCE_TIME);
  const [loading, setLoading] = useState(false);
  const abortController = useRef(new AbortController());
  const containerRef = React.useRef(null);

  useEffect(() => {
    if (debouncedSearchTerm !== '' && debouncedSearchTerm === inputValue) {
      if (asyncValidator) {
        validate();
      }
    }
  }, [debouncedSearchTerm, inputValue]);

  useDontMountAtFirst(() => {
    if (asyncMonitor) {
      asyncMonitor(loading);
    }
  }, [loading]);

  const validate = async () => {
    try {
      /**
       * Remove any previous errors
       */
      if (error) {
        setError(null);
      }
      setLoading(true);

      await asyncValidator(debouncedSearchTerm, { signal: abortController.current.signal });
      setLoading(false);
    } catch (e) {
      if (isPromiseCancelError(e)) {
        return;
      }
      form.setFieldValue(field.name, '');
      form.setFieldTouched(field.name, true);
      setLoading(false);
      if (e.customMessage) {
        setError(e.customMessage);
      } else {
        setError(getErrorMessageFromObj(e));
      }
    }
  };

  const onChange = e => {
    if (onChangeHandler) {
      onChangeHandler(e);
    }

    /**
     * On every key stroke
     * abort the previous controller (Cancels any previous on going api calls)
     * spin off a new controller
     */
    abortController.current.abort();
    abortController.current = new AbortController();
    if (e.target.value.length) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    /**
     * Remove any previous errors
     */
    if (error) {
      setError(null);
    }
    setInputValue(e.target.value);
    form.setFieldValue(field.name, e.target.value); // setting state in formik.
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(field.name, 'textFieldValidator'), [
    field.name
  ]);

  return (
    <>
      <HdTextField
        id={id || field.name}
        label={label}
        placeholder={label}
        variant={variant}
        {...field}
        {...props}
        value={inputValue}
        onChange={onChange}
        error={
          (form.touched[field.name] && !!form.errors[field.name]) ||
          (getIn(form.errors, field.name) && getIn(form.touched, field.name)) ||
          !!error
        }
        autoComplete='off'
        className={`${props.className} ${styles.field}`}
        dataId={dataIdGenerator('')}
        InputProps={{
          inputProps: {
            ...props
          },
          endAdornment: errorSuffixElement ? (
            <InputAdornment position='end'>{errorSuffixElement}</InputAdornment>
          ) : loading ? (
            <InputAdornment position='start'>
              <CircularProgress size={10} className={styles.progress} />
            </InputAdornment>
          ) : null
        }}
      />
      <div ref={containerRef}>
        {error && !errorSuffixElement ? (
          <Slide direction='down' in timeout={300} unmountOnExit container={containerRef.current} data-id={dataIdGenerator('fieldError')}>
            <Typography component='span' className={`mt-1 ${styles.fieldError}`}>
              <span dangerouslySetInnerHTML={{ __html: sanitize(error) }} />
            </Typography>
          </Slide>
        ) : null}

        {(!error || errorSuffixElement) && helperText ? (
          <Slide
            direction='down'
            in
            timeout={300}
            container={containerRef.current}
            appear={!!containerRef.current}
            data-id={dataIdGenerator('helperText')}
          >
            <Typography component='span' pl={1} className={styles.fieldHelp}>
              {helperText}
            </Typography>
          </Slide>
        ) : null}
      </div>
    </>
  );
};

export default HdFormikTextFieldValidator;
