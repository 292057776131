export const readFileAsText = inputFile => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(inputFile);
  });
};

export function validateFileList(files: FileList) {
  if (!files || !files.length) {
    return false;
  }

  /**
   * verifying instance of blob to process the request
   */
  const fileListAsArray = Array.from(files);
  for (let index = 0; index < fileListAsArray.length; index += 1) {
    if (!(fileListAsArray[index] instanceof Blob)) {
      return false;
    }
  }
  return true;
}
