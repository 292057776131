import React from 'react';
import * as Yup from 'yup';
import { AWSAuthenticationType, AWSFormField, IAM_ROLE_ARN_REGEX } from './model';

export class AwsConfig {
  static initialValues = {
    [AWSFormField.AUTH_TYPE]: AWSAuthenticationType.IAM_ROLE,
    [AWSFormField.ACCESS_KEY]: '',
    [AWSFormField.SECRET_KEY]: '',
    [AWSFormField.IAM_ROLE_ARN]: '',
    [AWSFormField.IAM_EXTERNAL_ID]: ''
  };

  static validationSchema = Yup.object({
    [AWSFormField.AUTH_TYPE]: Yup.string().trim().required(),
    [AWSFormField.ACCESS_KEY]: Yup.string()
      .trim()
      .when([AWSFormField.AUTH_TYPE], {
        is: authenticationType => authenticationType === AWSAuthenticationType.ACCESS_CREDENTIALS,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
    [AWSFormField.SECRET_KEY]: Yup.string()
      .trim()
      .when([AWSFormField.AUTH_TYPE], {
        is: authenticationType => authenticationType === AWSAuthenticationType.ACCESS_CREDENTIALS,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      }),
    [AWSFormField.IAM_ROLE_ARN]: Yup.string()
      .trim()
      .when([AWSFormField.AUTH_TYPE], {
        is: authenticationType => authenticationType === AWSAuthenticationType.IAM_ROLE,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      })
      .matches(IAM_ROLE_ARN_REGEX, {
        excludeEmptyString: true,
        message: () => getRoleARNError()
      }),
    [AWSFormField.IAM_EXTERNAL_ID]: Yup.string()
      .trim()
      .when([AWSFormField.AUTH_TYPE], {
        is: authenticationType => authenticationType === AWSAuthenticationType.IAM_ROLE,
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable()
      })
  });
}

export function getRoleARNError() {
  return (
    <span>
      The specified IAM Role ARN that you have entered is invalid. Please re-enter the correct IAM
      Role ARN.{' '}
    </span>
  );
}
