import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { onUserLogin } from '../../../react/actions/global';

import { SupportService } from '../models/support';
import { User } from '../models/user';
import { GrowthBookService } from './growth-book.service';
import { store } from './redux.service';
import { LocalStorageService } from './local-storage.service';
import { TrackingService } from './tracking.service';


export let _userServiceInstance: UserService;

@Injectable()
export class UserService {
  onUserUpdate$ = new Subject<void>();

  _userPermissions = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());
  _userRbacRoles = new BehaviorSubject<Array<string>>([]);
  constructor(
    private _localStorageService: LocalStorageService,
    private _trackingService: TrackingService,
    private _growthBookService: GrowthBookService,
    private _supportService: SupportService
  ) {
    _userServiceInstance = this;
  }

  clearUser() {
    this._localStorageService.remove('userData');
    this._supportService.reset();
    this._userPermissions.next(new Map());
    this._trackingService.reset();
  }

  setUser(data: any) {
    this._localStorageService.set('userData', JSON.stringify(data));
    store.dispatch(onUserLogin(data));
    this.setUserPermissions(data?.rbac?.permissions);
    this.setUserRbacRoles(data?.rbac?.roles);
    this.onUserUpdate$.next();
  }

  setUserPermissions(permissions: any) {
    const permissionsMap = new Map<string, boolean>();
    if (permissions) {
      permissions.forEach(permission => {
        permissionsMap.set(permission.name, true);
      });
    }
    this._userPermissions.next(permissionsMap);
  }

  setUserRbacRoles(roles) {
    let rbacRoles = [];
    if (roles) {
      rbacRoles = roles.map((role) => role.name);
    }
    this._userRbacRoles.next(rbacRoles);
  }

  getUser(): User {
    let userData: any;
    try {
      userData = JSON.parse(this._localStorageService.get('userData'));
    } catch (e) {
      return undefined;
    }

    if (!userData) {
      return undefined;
    }

    let profileImage = userData.image_url;
    profileImage = (!profileImage || profileImage === 'undefined') ? undefined : profileImage;

    return {
      name: userData.display_name,
      email: userData.email,
      profileImage: profileImage,
      isOwner: userData.is_owner,
      id: userData.id,
      isHidden: userData.is_hidden,
      clusterId: userData.cluster_id,
      tfaEnabled: userData.is_tfa_enabled,
      createdTs: userData.created_ts,
      teamId: userData.team_id,
      emailVerified: userData.email_verified,
      isEmailVerificationBlock: userData.email_verification_block,
      teamName: userData.team_name,
      role: userData.additional_details?.role
    };
  }

  updateThirdPartyData(data: any) {
    const user = this.getUser();
    this._supportService.setCurrentUser(user);
    this._trackingService.setUser(user);
    this._trackingService.refresh();
    this._trackingService.updateIdentity(user);
    this._growthBookService.setId(user.email);
    this.updateCoralogixRumContext(user);
  }

  updateCoralogixRumContext(user) {
    if (!!window.CoralogixRum) {
      window.CoralogixRum.setUserContext({
        user_id: user.id.toString(),
        user_email: user.email
      });
    }
  }

  public getPermissions() {
    return this._userPermissions.getValue();
  }

  public getUserRbacRoles() {
    return this._userRbacRoles.getValue();
  }

}
