import { CursorPaginationData, CursorPaginationStrategy } from '../../../app/pagination/list-conductor/cursor-pagination-strategy';
import { ListConductor } from '../../../app/pagination/list-conductor/list-conductor';
import { OffsetPaginationStrategy } from '../../../app/pagination/list-conductor/offset-pagination-strategy';
import { PaginationStrategy } from '../../../app/pagination/list-conductor/pagination-strategy';
import { MultiLevelSelectorChildrenPageData, MultiLevelSelectorParentPageData } from './model';

export interface MultiLevelSelectorPaginationHelper {
  getStrategy(): PaginationStrategy<any>;
  shouldAppendPage(listConductor: ListConductor): boolean;
  getListResponseData(pageData: MultiLevelSelectorParentPageData): any;
  getListSize(pageData: MultiLevelSelectorParentPageData, currentListSize: number): number;
}

type PageData = MultiLevelSelectorParentPageData | MultiLevelSelectorChildrenPageData;

export class MultiLevelSelectorOffsetPaginationHelper implements MultiLevelSelectorPaginationHelper {
  getStrategy(pageSize = 5) {
    return new OffsetPaginationStrategy(pageSize);
  }

  shouldAppendPage(listConductor: ListConductor) {
    return false;
  }

  getListResponseData(pageData: PageData) {
    return {};
  }

  getListSize(pageData: PageData, currentListSize: number) {
    return pageData.totalCount;
  }
}

export class MultiLevelSelectorCursorPaginationHelper implements MultiLevelSelectorPaginationHelper {
  getStrategy(pageSize = 5): PaginationStrategy<any> {
    return new CursorPaginationStrategy(pageSize);
  }

  shouldAppendPage(listConductor: ListConductor): boolean {
    const isFirstPage = !(listConductor.getListMeta().pagination as CursorPaginationData).total;

    return !isFirstPage;
  }

  getListResponseData(pageData: PageData): any {
    return { page: pageData.metaData?.cursor, hasMore: pageData.metaData?.hasMore };
  }

  getListSize(pageData: PageData, currentListSize: number): number {
    if (pageData.totalCount) {
      return pageData.totalCount;
    }

    return pageData.metaData?.hasMore ? null : currentListSize;
  }
}
