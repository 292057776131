import { ESCAPE } from '@angular/cdk/keycodes';

import {
  ConnectedPositionStrategy,
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollDispatcher
} from '@angular/cdk/overlay';

import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { combineLatest, concatMap, debounceTime, filter, map, merge as mergeOp, startWith, take } from 'rxjs/operators';
import { OverlayPosition } from '../overlay/types';
import { getDirFromPositionPair } from '../overlay/utils';
import { ClearPendingPopupService } from './clear-pending-popup.service';
import { TOOLTIP_FALLBACK_POSITIONS, TOOLTIP_OVERLAY_POSITION_MAPPING } from './constants';
import { PopupPosition } from './models';
import { Popup } from './popup/popup';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[popupTrigger]',
  exportAs: 'popupTrigger'
})
export class HdPopupDirective implements OnInit, OnChanges, OnDestroy {
  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal<void>;
  private _popupOpened = false;
  private _closeSubscription = Subscription.EMPTY;
  private _docClickHandler: any;
  private _docKeydownHandler: any;

  private _triggerOutsideSub: any;
  private _triggerInsideSub: any;

  private _triggerElementRefs: HTMLElement[] = [];

  @Input('popupTrigger') popup: Popup;
  @Input() hideOnClick = false;
  @Input() toggleOnClick = true;
  @Input() showOnHover = true;
  @Input() popupDisabled = false;
  @Input() position: PopupPosition = 'right';
  @Input() extraOffsetX = 0;
  @Input() extraOffsetY = 0;
  @Input() useFallbackPositions = true;

  // this will show popup persistently without hiding it on event listener.
  @Input() showPopup = false;
  @Output() open = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  fallbackPositions: OverlayPosition[] = TOOLTIP_FALLBACK_POSITIONS;

  constructor(
    private _el: ElementRef,
    private _overlay: Overlay,
    private _renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef,
    private _scrollDispatcher: ScrollDispatcher,
    private _clearPendingPopupService: ClearPendingPopupService,
    private _ngZone: NgZone
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showPopup && !this.showOnHover && this.showPopup) {
      this.openPopup();
    }
    if (changes.showPopup && !this.showPopup && !this.showOnHover && this._popupOpened) {
      this.closePopup();
    }
  }

  ngOnInit() {
    if (this.showOnHover) {
      this._handleTriggerIn();
      this._handleTriggerOut();
    }
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent): void {
    if (this.showOnHover || this.showPopup || !this.toggleOnClick) {
      return;
    }

    e['targetDirective'] = this;
    this.togglePopup();
  }

  togglePopup(): void {
    return this._popupOpened ? this.closePopup() : this.openPopup();
  }

  openPopup(): void {
    if (this._popupOpened || this.popupDisabled) {
      return;
    }

    this._createOverlay().attach(this._portal);

    this.handleDocEvents();

    this._initPopup();

    this._clearPendingPopupService.onPopupShown();

    this._closeSubscription = this._popupClosingActions().subscribe(() => this.closePopup());
  }

  togglePopupFromExternalTrigger(externalRef) {
    this.togglePopup();

    const externalRefInd = this._triggerElementRefs.find((elementRef) => elementRef === externalRef);

    if (!externalRefInd) {
      this._triggerElementRefs.push(externalRef);
    }
  }

  closePopup(): void {
    this._destroyPopup();
  }

  private _popupClosingActions() {
    let backdrop;
    let detachments;
    if (this._overlayRef) {
      backdrop = this._overlayRef.backdropClick();
      detachments = this._overlayRef.detachments();
    }
    const popupClose = this.popup.close;
    const nextPopupShown = this._clearPendingPopupService.popupShown$;
    return merge(backdrop, detachments, popupClose, nextPopupShown);
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const overlayConfig = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(overlayConfig);

      this._portal = new TemplatePortal(this.popup.templateRef, this._viewContainerRef);
    }

    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
  }

  private _getPositionStrategy(): ConnectedPositionStrategy {
    const popupPosition: OverlayPosition = TOOLTIP_OVERLAY_POSITION_MAPPING[this.position];
    const fallbackPositions: OverlayPosition[] = this.fallbackPositions;

    const strategy = this._overlay.position()
    .connectedTo(this._el,
      { originX: popupPosition.originX, originY: popupPosition.originY },
      { overlayX: popupPosition.overlayX, overlayY: popupPosition.overlayY }
    )
    .withOffsetX(this.getOffsetXFromPosition(popupPosition))
    .withOffsetY(this.getOffsetYFromPosition(popupPosition));

    if (this.useFallbackPositions) {
      strategy
      .withFallbackPosition(
        { originX: fallbackPositions[0].originX, originY: fallbackPositions[0].originY },
        { overlayX: fallbackPositions[0].overlayX, overlayY: fallbackPositions[0].overlayY },
        this.getOffsetXFromPosition(fallbackPositions[0]),
        this.getOffsetYFromPosition(fallbackPositions[0])
      )
      .withFallbackPosition(
        { originX: fallbackPositions[1].originX, originY: fallbackPositions[1].originY },
        { overlayX: fallbackPositions[1].overlayX, overlayY: fallbackPositions[1].overlayY },
        this.getOffsetXFromPosition(fallbackPositions[1]),
        this.getOffsetYFromPosition(fallbackPositions[1])
      )
      .withFallbackPosition(
        { originX: fallbackPositions[2].originX, originY: fallbackPositions[2].originY },
        { overlayX: fallbackPositions[2].overlayX, overlayY: fallbackPositions[2].overlayY },
        this.getOffsetXFromPosition(fallbackPositions[2]),
        this.getOffsetYFromPosition(fallbackPositions[2])
      )
      .withFallbackPosition(
        { originX: fallbackPositions[3].originX, originY: fallbackPositions[3].originY },
        { overlayX: fallbackPositions[3].overlayX, overlayY: fallbackPositions[3].overlayY },
        this.getOffsetXFromPosition(fallbackPositions[3]),
        this.getOffsetYFromPosition(fallbackPositions[3])
      );
    }

    const scrollableAncestors = this._scrollDispatcher
    .getAncestorScrollContainers(this._el);

    strategy.withScrollableContainers(scrollableAncestors);

    strategy.onPositionChange.subscribe(change => {
      this.updatePositionClasses(change.connectionPair);
      if (
        change.scrollableViewProperties.isOriginClipped
        && this._popupOpened
        && !this.showOnHover
      ) {
        this._ngZone.run(() => {
          this.closePopup();
        });
      }
    });

    return strategy;
  }

  private _destroyPopup(): void {
    if (this._overlayRef && this._popupOpened) {
      this._popupOpened = false;
      this._closeSubscription.unsubscribe();
      this._overlayRef.detach();
      this.unhandleDocEvents();
      this.close.emit();
    }
  }

  private _initPopup(): void {
    this._popupOpened = true;
    this.open.emit();
  }

  onDocumentClick(e: any) {
    if (
      this._popupOpened
      && !this._el.nativeElement.contains(e.target)
      && !this._triggerElementRefs.includes(e.target)
      && (this.hideOnClick || !this._overlayRef.overlayElement.contains(e.target))
    ) {
      this.popup.close.emit();
    }
  }

  onDocumentKeydown(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.popup.close.emit();
      event.stopPropagation();
    }
  }

  unhandleDocEvents() {
    if (this._docClickHandler) {
      this._docClickHandler();
      this._docKeydownHandler();
    }
  }

  handleDocEvents() {
    this._docClickHandler = this._renderer.listen('document', 'click', (e: any) => {
      if (this.showPopup) {
        this.onDocumentClick(e);
      }
    });

    this._docKeydownHandler = this._renderer.listen('document', 'keydown', (e: any) => {
      if (this.showPopup) {
        this.onDocumentKeydown(e);
      }
    });
  }

  getOffsetXFromPosition(position: OverlayPosition): number {
    return 0 + this.extraOffsetX;
  }

  getOffsetYFromPosition(position: OverlayPosition): number {
    return 0 + this.extraOffsetY;
  }

  updatePositionClasses(connectionPair: ConnectionPositionPair) {
    const direction: any = getDirFromPositionPair(connectionPair);
    const element = this._overlayRef.overlayElement.querySelector('.popup-wrapper');

    element.classList.remove(
      'p-start',
      'p-end',
      'p-top',
      'p-bottom',
      'p-center',
      'a-start',
      'a-end',
      'a-top',
      'a-bottom',
      'a-center');

    element.classList.add(`p-${direction.position}`, `a-${direction.align}`);
  }

  private _handleTriggerIn() {
    const enter = fromEvent(this._el.nativeElement, 'mouseenter');
    const leave = fromEvent(this._el.nativeElement, 'mouseleave');
    const move  = fromEvent(this._el.nativeElement, 'mousemove');
    const entered = enter.pipe(
      map(e => true),
      mergeOp(
        leave.pipe(
          map(e => false)
        )
      )
    );

    this._triggerInsideSub = enter
    .pipe(
      combineLatest(entered),
      filter(([e, b]) => {
        return b;
      }),
      map(([e, _]) => e)
    )
    .subscribe(e => {
      this.openPopup();
      this._handleTriggerOut();
    });
  }

  private _handleTriggerOut() {
    if (this._triggerOutsideSub) {
      this._triggerOutsideSub.unsubscribe();
    }

    this._triggerOutsideSub = fromEvent(this._el.nativeElement, 'mouseleave')
    .pipe(
      concatMap((e) => {
        return fromEvent(document, 'mousemove').pipe(startWith(null));
      }),
      debounceTime(16),
      filter((e: any) => {
        if (!e) {
          return true;
        }

        if (!this._popupOpened) {
          return false;
        }

        const element = this._overlayRef.overlayElement.querySelector('.popup-wrapper');
        if (!element || element.contains(e.target)) {
          return false;
        }

        return true;
      }),
      take(1)
    )
    .subscribe((e: any) => {
      this.closePopup();
      this._triggerOutsideSub.unsubscribe();
    });
  }

  private _removeTriggerInOutSubs() {
    if (this._triggerOutsideSub) {
      this._triggerOutsideSub.unsubscribe();
    }
    if (this._triggerInsideSub) {
      this._triggerInsideSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
      this.unhandleDocEvents();
    }

    this._removeTriggerInOutSubs();
  }
}
