import React, { useCallback } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { SetupGuideLinkParams, trackSetupGuideLink } from '../../../utils/setupGuideLinkHandler';
import HdLink from '../HdLink';

export interface HdSetupGuideLinkProps extends SetupGuideLinkParams {
  dataId: string;
  useMouseDownEvent?: boolean;
  [key: string]: any;
}

export default function HdSetupGuideLink({
  setupGuideLink,
  section,
  label,
  medium = 'click',
  properties = {},
  scrollOffset = 0,
  highlightTitle = false,
  dataId,
  useMouseDownEvent = false,
  ...props
}: HdSetupGuideLinkProps) {
  const setupGuideLinkParams = {
    setupGuideLink,
    section,
    label,
    medium,
    properties,
    scrollOffset,
    highlightTitle
  };

  const onMouseDownHandler = () => {
    if (useMouseDownEvent) {
      trackSetupGuideLink(setupGuideLinkParams);
    }
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  return (
    <HdLink
      onClick={(e) => {
        e.preventDefault();
        trackSetupGuideLink(setupGuideLinkParams);
      }}
      dataId={dataIdGenerator('setupGuide')}
      onMouseDown={onMouseDownHandler}
      {...props}
    >
      {props.children}
    </HdLink>
  );
}
