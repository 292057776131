// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.gtmContentContainer__vLZe {\n  width: 552px;\n}\n\n.bannerImg_rjiSd {\n  height: 190px;\n  display: flex;\n  justify-content: center;\n  border-radius: 4px 4px 0 0;\n}\n.bannerImg_rjiSd img {\n  max-width: 389px;\n}\n\n.modalContent_z2U3S {\n  padding: 24px 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gtmContentContainer": "gtmContentContainer__vLZe",
	"bannerImg": "bannerImg_rjiSd",
	"modalContent": "modalContent_z2U3S"
};
export default ___CSS_LOADER_EXPORT___;
