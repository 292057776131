import { RecoveryCode } from './interfaces';

export function RecoveryCodesFactory(rawData: any): RecoveryCode[] {
  return Object.entries(rawData).map(([key, value]) => {
    return {
      value: key,
      active: value as boolean
    };
  });
}
