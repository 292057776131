import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DrawerRoutingService } from '../../drawer/drawer-routing.service';


@Injectable({ providedIn: 'root' })
export class TroubleshootGuideService {
  private _troubleshootLink$ = new BehaviorSubject<string>(null);
  private _isTroubleshootVisible$ = new BehaviorSubject(false);

  constructor(
    private _drawerRoutingService: DrawerRoutingService
  ) {
  }

  troubleshootLink$ = this._troubleshootLink$.asObservable();
  isTroubleshootVisible$ = this._isTroubleshootVisible$.asObservable();

  setTroubleshootVisibleStatus(value: boolean) {
    this._isTroubleshootVisible$.next(value);
  }

  showTroubleshootGuide(link: string) {
    this._troubleshootLink$.next(link);
    this.setTroubleshootVisibleStatus(true);
    this._drawerRoutingService.navigateByPath('troubleshoot');
  }
}
