import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { DocLinkHandlerService } from '../core/service/doc-link-handler.service';

@Directive({
  selector: '[hdDocLink]'
})
export class DocLinkDirective implements OnChanges {
  @Input() hdDocLink: string;
  @Input() section: string;
  @Input() properties: { [key: string]: any };

  constructor(
    private _el: ElementRef,
    private _docLinkHandlerService: DocLinkHandlerService
  ) {
    this._el.nativeElement.setAttribute('target', '_blank');
  }

  ngOnChanges() {
    this._el.nativeElement.href = this._docLinkHandlerService.getLinkURL(this.hdDocLink, this.section, this.properties);
  }

  @HostListener('click') onClick() {
    this._docLinkHandlerService.trackClick(this.hdDocLink, this.section, this.properties);
  }
}
