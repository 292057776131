import React, { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import { useSelector } from 'react-redux';
import useService from '../../../hooks/useService';
import { ACCOUNT_EXEC_SCHEDULE_CALL_LINK, CHANGE_LOG_URL } from '../../../../app/core/constants';
import {
  MultiRegionService,
  ClusterMetadata
} from '../../../../app/core/service/multi-region.service';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { MultiRegionTracking } from './tracking';
import { ShortcutsDialogHandlerReactService } from '../../../../app/shortcuts/service/shortcuts-dialog-handler-react.service';
import { HeadwayService } from '../../../../app/core/service/headway.service';
import { NotificationService } from '../../../../app/core/service/notification.service';
import { TeamTrialStatus } from '../../../../app/core/models/trial-details';
import { TrialDetailsService } from '../../../../app/core/service/trial-details.service';
import { getDaysFromTimestamp, getHoursFromTimestamp } from '../../../legacy-utils/date';
import { AuthService } from '../../../../app/core/service/auth.service';
import HdShortcut from '../../../components/UIElements/HdShortcut';
import styles from './styles.module.scss';
import {
  HdButton,
  HdCalendar,
  HdIcon,
  HdIconButton,
  HdMenu,
  HdMenuItem,
  HdTooltip
} from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdSwitchWrapper from '../../../components/UIElements/HdSwitchWrapper';
import { ColorMode } from '../../../../app/core/models/color-mode';
import { ColorModeService } from '../../../../app/core/service/color-mode.service';
import { getDocLink } from '../../../utils/docLinkHandler';
import { getUnreadDisplayCount } from '../../../legacy-utils/string';
import MultiRegionAPI from '../../MultiRegionAPI';
import { TRIAL_NOT_STARTED_TOOLTIP } from './strings';
import { numFormatter } from '../../../legacy-utils/number';
import useHasPermission from '../../../hooks/useHasPermission';
import { RbacPermissions } from '../../../../app/core/models/user';
import useUserService from '../../../hooks/services/useUserService';
import useSettingsStorageService from '../../../hooks/services/useSettingsStorageService';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';
import { SyncFrequencyGTM } from '../../../components/MigratePipelinesToSyncFrequencyDialog/SyncFrequencyGTM';
import { SYNC_FREQUENCY_GTM_SHOWN_KEY } from '../../../components/MigratePipelinesToSyncFrequencyDialog/constant';
import { isUserEligibleToStartSyncFrequencyMigration } from '../../../components/MigratePipelinesToSyncFrequencyDialog/utils';

export const dataIdGenerator = getDataIdGenerator('Topbar');

export default function TopBar() {
  const { eventTrack } = useAnalyticsTracker();
  const history = useHistory();
  const location = useLocation();
  const { hasPermission, isInternalUserOrHasPermission } = useHasPermission();
  const { user } = useUserService();
  const { getSettings, applySettings } = useSettingsStorageService();
  const { getTeamSettings } = useTeamSettings();

  const authService = useService(AuthService);
  const userService = useUserService();
  const multiRegionService = useService(MultiRegionService);
  const headwayService = useService(HeadwayService);
  const notificationService = useService(NotificationService);
  const trialDetailsService = useService(TrialDetailsService);
  const shortcutsDialogHandlerReactService = useService(ShortcutsDialogHandlerReactService);
  const colorModeService = useService(ColorModeService);

  const [showUserAccountMenu, setShowUserAccountMenu] = useState(false);
  const [showScheduleCallDisplay, setShowScheduleCallDisplay] = useState(false);
  const [showRegionsMenu, setShowRegionsMenu] = useState(false);
  const [showMiscMenu, setShowMiscMenu] = useState(false);
  const [showRegionSwitcher, setShowRegionSwitcher] = useState(false);
  const [regionGroups, setRegionGroups] = useState<
    { provider: string; options: ClusterMetadata[] }[]
  >([]);
  const [selectedRegion, setSelectedRegion] = useState<ClusterMetadata>(null);
  const [newFeatsUnseenCount, setNewFeatsUnseenCount] = useState(0);
  const [newCount, setNewCount] = useState(0);
  const [trialInfo, setTrialInfo] = useState({
    trialStartedText: '',
    showTrialStartedBadge: false,
    showTrialNotStartedBadge: false,
    trialEndTimestamp: 0
  });
  const [showSyncFrequencyGTM, setShowSyncFrequencyGTM] = useState(false);

  const { theme } = useSelector(<T extends { global: { theme: string } }>(state: T) => ({
    theme: state.global.theme
  }));

  useEffect(() => {
    headwayService.init();
    const headwaySubscription = headwayService.unreadCountSubject.subscribe((count: number) => {
      setNewFeatsUnseenCount(count);
    });

    const storeSubscription = notificationService.unreadCount$.subscribe(unreadCount => {
      setNewCount(unreadCount.regular + unreadCount.enterprise);
    });

    const notificationSubscription = notificationService
      .getNotificationCountObservable()
      .subscribe();

    const trialDetailsSubscription = getTrialDetails();

    openSyncFrequencyGTMModalByDefault();

    return () => {
      headwaySubscription.unsubscribe();
      notificationSubscription.unsubscribe();
      storeSubscription.unsubscribe();
      trialDetailsSubscription.unsubscribe();
    };
  }, []);

  const getTrialDetails = () =>
    trialDetailsService.trialDetails$.subscribe(
      details => {
        setShowScheduleCallDisplay(details.isInTrial());
        setMultiRegionStatus();

        if (details.trialEndTs && details.status === TeamTrialStatus.CREATED) {
          const trialPeriodLeft = getTrialPeriodLeft(details.trialEndTs);

          setTrialInfo({
            showTrialStartedBadge: true,
            showTrialNotStartedBadge: false,
            trialEndTimestamp: details.trialEndTs,
            trialStartedText: getTrialStatusMessage(trialPeriodLeft)
          });
        } else if (details.status === TeamTrialStatus.SENT) {
          setTrialInfo(prev => ({
            ...prev,
            showTrialStartedBadge: false,
            showTrialNotStartedBadge: true
          }));
        } else {
          setTrialInfo(prev => ({
            ...prev,
            showTrialStartedBadge: false,
            showTrialNotStartedBadge: false
          }));
        }
      },
      () => {
        setMultiRegionStatus();
      }
    );

  const getTrialPeriodLeft = trialEndTimestamp => trialEndTimestamp - new Date().getTime();

  const getTrialStatusMessage = trialPeriodLeft => {
    const message = 'Trial Expires in';
    const daysLeft = getDaysFromTimestamp(trialPeriodLeft);
    if (daysLeft > 1) {
      return `${message} ${Math.ceil(daysLeft)} days`;
    }

    const hoursLeft = getHoursFromTimestamp(trialPeriodLeft);
    if (hoursLeft > 1) {
      return `${message} ${Math.ceil(hoursLeft)} hours`;
    }

    return `${message} less than 1 hour`;
  };

  const setMultiRegionStatus = () => {
    if (
      multiRegionService.isMultiRegionUser() &&
      multiRegionService.getMultiRegionsValue()?.length > 1 &&
      multiRegionService.getSelectedRegionValue()
    ) {
      setShowRegionSwitcher(true);
      setRegionGroups(multiRegionService.getRegionsGroupedByProvider());
      setSelectedRegion(multiRegionService.getSelectedRegionValue());
    }
  };

  const getDefaultMultiRegionTrackingPayload = () => ({
    currentCluster: multiRegionService.getSelectedRegionValue()?.id
  });

  const logout = () => {
    authService.logout(false).subscribe();
  };

  const markAllNewSeen = () => {
    eventTrack({
      action: '[Topbar] changelog page link click'
    });

    headwayService.markAllSeen();
  };

  const showSearchDialog = () => {
    shortcutsDialogHandlerReactService.openGlobalSearchDialog();
  };

  const selectRegion = option => {
    if (!option.current_cluster) {
      eventTrack({
        action: MultiRegionTracking.MULTI_REGION_REGION_CHANGED,
        properties: {
          ...getDefaultMultiRegionTrackingPayload(),
          targetCluster: option.id
        }
      });

      setSelectedRegion(option);

      MultiRegionAPI.getRegionSwitchingToken(option.id).then(res => {
        window.location.href = `${option.url}?session_token=${res.data?.temp_session_token}`;
      });
    }
  };

  const onScheduleCallClick = () => {
    window.open(ACCOUNT_EXEC_SCHEDULE_CALL_LINK, '_blank');

    eventTrack({
      action: '[Free Trial]: Talk to us button clicked',
      properties: {
        section: 'topbar'
      }
    });
  };

  const openAlertDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('drawer', 'alerts');

    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`
    });
  };

  const trackMultiRegionEvent = action => {
    eventTrack({
      action,
      properties: getDefaultMultiRegionTrackingPayload()
    });
  };

  const showShortcutDialog = () => shortcutsDialogHandlerReactService.openKeyboardShortcutDialog();

  const toggleColorMode = () => colorModeService.toggleColorMode();

  const alertCount = useMemo(() => getUnreadDisplayCount(newCount), [newCount]);
  const newUnseenFeaturesCount = useMemo(
    () => numFormatter(newFeatsUnseenCount),
    [newFeatsUnseenCount]
  );

  const isSyncFrequencyGTMEnabled =
    isUserEligibleToStartSyncFrequencyMigration(userService.rbacRoles) &&
    !!getTeamSettings()?.show_sync_frequency_migration_popup &&
    !getTeamSettings()?.use_sync_execution_policy;

  const openSyncFrequencyGTMModalByDefault = () => {
    if (!getSettings(SYNC_FREQUENCY_GTM_SHOWN_KEY) && isSyncFrequencyGTMEnabled) {
      setShowSyncFrequencyGTM(true);
      applySettings(SYNC_FREQUENCY_GTM_SHOWN_KEY, true);
    }
  };

  return (
    <div className={styles.topBar} data-id={dataIdGenerator('')}>
      <div className='center-flex-row flex-1'>
        <div className={styles.globalSearchContainerMaxWidth}>
          <button
            type='button'
            data-id='top-bar-global-search'
            className={styles.globalSearchContainer}
            onClick={showSearchDialog}
          >
            <HdIcon name='search' size={2} className='mr-3' />
            <span>Search Anything</span>
            <span className='ml-2'>(</span>
            <HdShortcut command='global.searchDialog' />
            <span>)</span>
          </button>
        </div>
      </div>

      {showRegionSwitcher ? (
        <>
          <button
            data-id='region-switcher'
            type='button'
            onClick={() => {
              trackMultiRegionEvent(MultiRegionTracking.MULTI_REGION_DROPDOWN_CLICK);
              setShowRegionsMenu(prev => !prev);
            }}
            id='regionsMenu'
            className={clsx('mr-2', styles.dropdownTrigger)}
          >
            <HdIcon name='timezone' size={2} className='mr-1' />
            {selectedRegion?.display_name}
            <HdIcon name='dropdown-arrow' size={2} className={styles.dropdownTriggerIcon} />
          </button>

          <HdMenu
            target='#regionsMenu'
            open={showRegionsMenu}
            onClose={() => setShowRegionsMenu(false)}
          >
            {regionGroups.map(regionGroup => (
              <React.Fragment key={regionGroup.provider}>
                <div className={clsx(styles.regionGroupContent, 'text-uppercase')}>
                  {regionGroup.provider}
                </div>

                {regionGroup.options.map(region => (
                  <HdMenuItem
                    selected={selectedRegion?.id === region.id}
                    onClick={e => {
                      e.stopPropagation();
                      selectRegion(region);
                      setShowRegionsMenu(false);
                    }}
                    dataId={dataIdGenerator(region.id)}
                    key={region.id}
                  >
                    {region.display_name}
                  </HdMenuItem>
                ))}
              </React.Fragment>
            ))}
          </HdMenu>
        </>
      ) : null}

      {trialInfo.showTrialNotStartedBadge ? (
        <HdTooltip title={TRIAL_NOT_STARTED_TOOLTIP}>
          <div
            className='hd-outline-label hd-outline-label-thumbnail-left mr-4'
            id='trial-not-started'
            data-id={dataIdGenerator('trial-not-started')}
          >
            <HdIcon name='time' size={2} className='hd-outline-label-thumbnail' />
            Trial has not started
          </div>
        </HdTooltip>
      ) : null}

      {trialInfo.showTrialStartedBadge && (
        <HdTooltip title={<HdCalendar value={trialInfo.trialEndTimestamp} />} dataId='trialStarted'>
          <div
            className='hd-faded-label hd-faded-label-deferred hd-faded-label-bordered hd-faded-label-thumbnail-left mr-4'
            id='trial-status'
            data-id={dataIdGenerator('trial-status')}
          >
            <HdIcon name='time' size={2} className='hd-faded-label-thumbnail' />
            {trialInfo.trialStartedText}
          </div>
        </HdTooltip>
      )}

      {showScheduleCallDisplay && (
        <HdButton
          dataId={dataIdGenerator('scheduleCall')}
          variation='outline'
          size='sm'
          icon='schedule-call'
          onClick={onScheduleCallClick}
        >
          Schedule a call
        </HdButton>
      )}

      {isSyncFrequencyGTMEnabled && (
        <HdIconButton
          dataId={dataIdGenerator('syncFrequency')}
          onClick={() => setShowSyncFrequencyGTM(prev => !prev)}
          className={clsx('ml-1', styles.syncFrequencyIcon)}
        >
          <HdIcon name='date-time' size={3} />
        </HdIconButton>
      )}

      <HdTooltip title='Quick Links'>
        <HdIconButton
          dataId={dataIdGenerator('tour-links')}
          onClick={() => setShowMiscMenu(prev => !prev)}
          id='misc'
          className='ml-1'
        >
          <HdIcon name='planet' size={3} />
        </HdIconButton>
      </HdTooltip>

      <HdMenu target='#misc' open={showMiscMenu} onClose={() => setShowMiscMenu(false)}>
        <HdMenuItem
          component='a'
          rel='noreferrer'
          dataId={dataIdGenerator('documentation')}
          href={getDocLink('/', 'top_bar')}
          target='_blank'
        >
          <HdIcon name='docs' />
          Documentation
        </HdMenuItem>

        <HdMenuItem
          component='a'
          rel='noreferrer'
          dataId={dataIdGenerator('new-documentation')}
          href={CHANGE_LOG_URL}
          target='_blank'
          onClick={markAllNewSeen}
        >
          <HdIcon name='what-new' />
          What's New
          {newFeatsUnseenCount ? (
            <span className='badge overlay-badge overlay-badge-primary ml-2'>
              {newUnseenFeaturesCount}
            </span>
          ) : null}
        </HdMenuItem>

        <HdMenuItem
          component='a'
          rel='noreferrer'
          dataId={dataIdGenerator('upcoming-documentation')}
          href={getDocLink('/upcoming-features', 'top_bar')}
          target='_blank'
        >
          <HdIcon name='gift' />
          Upcoming Features
        </HdMenuItem>

        <HdMenuItem onClick={showShortcutDialog} dataId='keyboard-shortcut'>
          <HdIcon name='keyboard' />
          Keyboard Shortcuts
          <span className={styles.menuItemShortcut}>
            <HdShortcut command='global.keyboardShortcuts' />
          </span>
        </HdMenuItem>
      </HdMenu>

      <HdTooltip title='Quick Alerts'>
        <HdIconButton
          dataId={dataIdGenerator('quick-alerts')}
          className='ml-1 mr-2'
          onClick={openAlertDrawer}
        >
          <HdIcon
            name='alerts'
            size={3}
            className={clsx(
              !newCount && 'overlay-badge-container-hidden',
              'overlay-badge-container overlay-badge-container-overlap'
            )}
            dataId='alerts-icon'
          >
            <span className={clsx('badge overlay-badge-error overlay-badge hd-badge-active')}>
              {alertCount}
            </span>
          </HdIcon>
        </HdIconButton>
      </HdTooltip>

      <div className='h-100'>
        <button
          data-id={dataIdGenerator('user-details-button')}
          type='button'
          className={styles.userDetails}
          id='userAccountMenu'
          onClick={() => setShowUserAccountMenu(prev => !prev)}
        >
          <div className={styles.userProfileImgContainer}>
            <img
              src={
                user?.profileImage ||
                'https://res.cloudinary.com/hevo/image/upload/v1661186304/dashboard/profile-image-default_oeutti.svg'
              }
              alt='account-avatar'
              data-id='account-avatar'
            />
          </div>

          <div className={styles.nameEmailWrapper}>
            <div className='center-flex-row'>
              <HdTooltip title={user?.name} enableOnTextOverflow dataId='user-name'>
                <span className={styles.userName}>{user?.name}</span>
              </HdTooltip>

              <HdIcon name='dropdown-arrow' className='ml-1' size={1} />
            </div>

            {user?.name !== user?.email ? (
              <HdTooltip title={user?.email} enableOnTextOverflow dataId='user-email'>
                <span className={styles.userEmail}>{user?.email}</span>
              </HdTooltip>
            ) : null}
          </div>
        </button>
      </div>

      <div className='d-none' id='headway_widget_trigger' />

      <HdMenu
        target='#userAccountMenu'
        open={showUserAccountMenu}
        onClick={e => {
          e.stopPropagation();
          setShowUserAccountMenu(false);
        }}
        menuListClassName={styles.navMenu}
        onClose={() => setShowUserAccountMenu(false)}
      >
        <HdMenuItem component={NavLink} to='/account' dataId={dataIdGenerator('account')}>
          <HdIcon name='user' size={2} className='mr-3' />

          <span className='tab-nav-link-text'>Account</span>
        </HdMenuItem>

        <HdMenuItem component={NavLink} to='/team' dataId={dataIdGenerator('team')}>
          <HdIcon name='team' size={2} className='mr-3' />

          <span className='tab-nav-link-text'>Team</span>

          <span className={styles.menuItemShortcut}>
            <HdShortcut command='global.team' />
          </span>
        </HdMenuItem>

        {!isInternalUserOrHasPermission(RbacPermissions.BILLING_EDIT) && (
          <HdMenuItem
            component={NavLink}
            to='/payment/usage-summary'
            dataId={dataIdGenerator('usage-summary')}
          >
            <HdIcon name='graph' size={2} className='mr-3' />

            <span className='tab-nav-link-text'>Usage Summary</span>
          </HdMenuItem>
        )}

        {isInternalUserOrHasPermission(RbacPermissions.BILLING_EDIT) && (
          <HdMenuItem component={NavLink} to='/payment' dataId={dataIdGenerator('payment')}>
            <HdIcon name='cards-payment' size={2} className='mr-3' />

            <span className='tab-nav-link-text'>Billing</span>

            <span className={styles.menuItemShortcut}>
              <HdShortcut command='global.payment' />
            </span>
          </HdMenuItem>
        )}

        <HdMenuItem component={NavLink} to='/alerts/preferences' dataId={dataIdGenerator('alerts')}>
          <HdIcon name='alerts' size={2} className='mr-3' />

          <span className='tab-nav-link-text'>Alerts</span>
        </HdMenuItem>

        <HdMenuItem
          sx={{ borderTop: '1px solid var(--divider-color)' }}
          onClick={e => {
            e.stopPropagation();
            toggleColorMode();
          }}
          dataId={dataIdGenerator('color-toggle')}
        >
          <span className='center-flex-row'>
            <HdIcon name='darkmode' size={2} className='mr-3' />
            Dark Mode
          </span>

          <HdSwitchWrapper
            className='ml-4 d-inline-block'
            id='color-mode'
            dataId={dataIdGenerator('color-mode')}
            label=''
            onChange={toggleColorMode}
            switchProps={{
              checked: theme === ColorMode.DARK
            }}
          />
        </HdMenuItem>

        <HdMenuItem
          sx={{ borderTop: '1px solid var(--divider-color)' }}
          onClick={logout}
          dataId={dataIdGenerator('logout')}
        >
          <span className='text-error center-flex-row'>
            <HdIcon name='logout' size={2} className='mr-3' />
            Log out
          </span>
        </HdMenuItem>
      </HdMenu>

      <SyncFrequencyGTM
        open={showSyncFrequencyGTM}
        onClose={() => {
          setShowSyncFrequencyGTM(false);
        }}
      />
    </div>
  );
}
