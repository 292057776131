import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getUrlWithoutParams } from '../../react/legacy-utils/routing';

@Injectable()
export class DrawerRoutingService {
  constructor(
    private route: ActivatedRoute,
    private _router: Router
  ) {
  }

  navigateByPath(path: string) {
    this._router.navigateByUrl(
      this.getURLTree(path)
    );
  }

  getURLTree(path: string) {
    return this._router.createUrlTree([ getUrlWithoutParams(this._router) ], {
      queryParams: { drawer: path },
      queryParamsHandling: 'merge'
    });
  }

  isDrawerOpen() {
    return this.route.snapshot.queryParamMap.keys.includes('drawer');
  }
}

