import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class ClearCookieService {
  invalidCookies = [
    '_hp2_'
  ];

  constructor(
    private _cookieService: CookieService
  ) {
    this.deleteInvalidCookies();
  }

  deleteInvalidCookies() {
    this.getInvalidCookies().forEach((cookie) => {
      this._cookieService.delete(cookie, '/', '.hevo.io');
    });
  }

  getInvalidCookies() {
    const cookies = this._cookieService.getAll();
    return Object.keys(cookies).filter((key) => {
      return this.invalidCookies.some((invalidCookie) => {
        return key.includes(invalidCookie);
      });
    });
  }
}
