// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.jsonEditor_S3b6_ {\n  height: 300px;\n  border: solid 1px var(--divider-color);\n  border-radius: 6px;\n  background: var(--surface-bg-color);\n}\n\n.jsonEditorCodeEditorWithoutLineNumber_GkRPV {\n  padding: 8px 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jsonEditor": "jsonEditor_S3b6_",
	"jsonEditorCodeEditorWithoutLineNumber": "jsonEditorCodeEditorWithoutLineNumber_GkRPV"
};
export default ___CSS_LOADER_EXPORT___;
