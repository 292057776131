import {
  GlobalSearchReducerActionType,
  GlobalSearchEntity,
  GlobalSearchReducerActions
} from './models';

const getEntityData = (dispatch: Function) => (entity: GlobalSearchEntity) => {
  const action = {
    type: GlobalSearchReducerActionType.GET_ENTITY_DATA,
    entity
  };

  dispatch(action);
};

const updateEntityData = (dispatch: Function) => (entity: GlobalSearchEntity, data: object) => {
  const action = {
    type: GlobalSearchReducerActionType.UPDATE_ENTITY_DATA,
    entity,
    data
  };

  dispatch(action);
};

const bulkUpdateState = (dispatch: Function) => (data: object) => {
  const action = {
    data,
    type: GlobalSearchReducerActionType.BULK_UPDATE
  };

  dispatch(action);
};

const filterData = (dispatch: Function) => (filters: object) => {
  const action = {
    data: filters,
    type: GlobalSearchReducerActionType.FILTER_DATA
  };

  dispatch(action);
};

const apiError = (dispatch: Function) => (entity: GlobalSearchEntity) => {
  const action = {
    data: {},
    entity,
    type: GlobalSearchReducerActionType.API_ERROR
  };

  dispatch(action);
};

export function GlobalSearchReducerActionsFactory(dispatch): GlobalSearchReducerActions {
  return {
    getEntityData: getEntityData(dispatch),
    updateEntityData: updateEntityData(dispatch),
    bulkUpdateState: bulkUpdateState(dispatch),
    filterData: filterData(dispatch),
    apiError: apiError(dispatch)
  };
}
