export const TARGET_CLEVERTAP_REGIONS = [
  {
    name: 'EU - Default',
    value: 'Global',
    id: 'default'
  },
  {
    name: 'India',
    value: 'India',
    id: 'in1'
  },
  {
    name: 'Singapore',
    value: 'Singapore',
    id: 'sg1'
  },
  {
    name: 'US',
    value: 'US',
    id: 'us1'
  }
];

export const DEFAULT_TARGET_CLEVERTAP_REGION = TARGET_CLEVERTAP_REGIONS[0];
