import React, { useRef } from 'react';
import HdDropDown, { accessorFn, HdDropDownProps, OptionsType } from '../HdDropDown';

export const CREATE_OPTION_ID = 'hd-create-option';
const createTag: OptionsType = { name: 'Add custom value', value: null, id: CREATE_OPTION_ID };

export default function HdCreatableOptionDropDown(props: HdDropDownProps) {
  const { valueAccessor = ele => ele?.id, onChangeEventHandler } = props;
  const valueAccessorFn = accessorFn(valueAccessor, 'id');
  const customOptionValue = useRef('');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <HdDropDown
        {...props}
        onChangeEventHandler={selected => {
          if (selected?.id === CREATE_OPTION_ID) {
            if (props.creatablePrimitive) {
              return onChangeEventHandler(customOptionValue.current);
            }

            if (typeof valueAccessor === 'string') {
              return onChangeEventHandler({
                ...selected,
                name: customOptionValue.current,
                [valueAccessor]: customOptionValue.current
              });
            }

            return onChangeEventHandler({
              ...selected,
              name: customOptionValue.current
            });
          }

          return onChangeEventHandler(selected);
        }}
        filterOptionsCallback={({ filteredItems, inputValue }) => {
          customOptionValue.current = inputValue;
          if (filteredItems.length === 0) {
            if (inputValue !== '') {
              return [{ ...createTag, name: `${createTag.name} ${inputValue}`, value: inputValue }];
            }
          }
          return filteredItems;
        }}
      />
    </>
  );
}
