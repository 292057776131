import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { Typography } from '@mui/material';
import styles from '../styles.module.scss';
import { HdButton, HdDocLink, HdIcon, HdPane } from '../../../components/UIElements';
import Scheduler from '../index';
import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import useScheduler from '../useScheduler';
import {
  ExecutionPolicyContext,
  getExecutionPolicyFromRawData,
  getPolicyMessage,
  getPolicyRawData
} from '../utils';
import {
  PIPELINE_POLICY_MESSAGE_PREFIX,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_CLICK,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_FAILURE,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_SUCCESS
} from '../../../../app/dialog/execution-policy-dialog/constants';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { PolicyTypeEnum } from '../../../../app/dialog/execution-policy-dialog/models';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { SchedulerType } from '../models';
import { SOURCE_TYPES } from '../../../../app/nodes/source-type/source-type';
import PipelinesAPI from '../../pipeline/PipelinesAPI';
import { RawPipeline } from '../../pipeline/model';
import { SchedulerHeader } from '../components/SchedulerHeader';
import { FullLoadObjectExecutionPolicy } from './FullLoadObjectExecutionPolicy';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';

export default function PipelineExecutionPolicyDialog({
  onClose,
  entityDetails,
  openSyncFrequencyMigrationDialog
}: {
  onClose: Function;
  entityDetails: RawPipeline;
  openSyncFrequencyMigrationDialog?: Function;
}) {
  const analyticsTracker = useAnalyticsTracker();
  const formikRef = useRef<FormikProps<{}>>();
  const fullLoadFormikRef = useRef<FormikProps<{}>>();
  const [savingSchedule, setSavingSchedule] = useState(false);
  const [showFullLoadFrequencyScheduler, setShowFullLoadFrequencyScheduler] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [quotaLimitWarningObj, setQuotaLimitWarningObj] = useState(null);
  const [fullLoadPolicyObj, setFullLoadPolicyObj] = useState(null);
  const isFullLoadScheduleAllowed = SOURCE_TYPES[entityDetails.source_type].hasFullLoadFrequency;
  const { getTeamSettings } = useTeamSettings();
  const {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    handleOptionSelection,
    tooltipData,
    setTooltipData
  } = useScheduler({
    policy: getExecutionPolicyFromRawData(
      entityDetails.execution_policy,
      entityDetails.frequency_config
    ),
    entity: HevoEntity.PIPELINE.value,
    schedulerType: SchedulerType.MultiCronType,
    formikRef
  });

  useEffect(() => {
    getQuotaLimit();
  }, []);

  useEffect(() => {
    const canShowFullLoadFrequencyScheduler =
      isFullLoadScheduleAllowed && policyObj.type !== PolicyTypeEnum.CRON;
    setShowFullLoadFrequencyScheduler(canShowFullLoadFrequencyScheduler);
  }, [policyObj.type]);

  const handleSubmit = () => {
    trackScheduleClick(TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_CLICK);
    setSavingSchedule(true);
    PipelinesAPI.schedulePipeline(
      entityDetails.id,
      getPolicyRawData(policyObj),
      showFullLoadFrequencyScheduler && fullLoadPolicyObj
        ? getPolicyRawData(fullLoadPolicyObj)
        : null
    )
      .then(() => {
        onClose(true);
        trackScheduleClick(TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_SUCCESS);
      })
      .catch(() => {
        trackScheduleClick(TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_SCHEDULE_FAILURE);
      })
      .finally(() => {
        setSavingSchedule(false);
      });
  };

  const getQuotaLimit = () => {
    PipelinesAPI.getQuotaLimit(entityDetails.id)
      .then((res: any) => {
        setQuotaLimitWarningObj(res.data);
      })
      .catch(() => {
        setQuotaLimitWarningObj(null);
      });
  };

  const trackScheduleClick = (action: string) => {
    let selectedData = getPolicyRawData(policyObj);
    if (selectedData.type === PolicyTypeEnum.CRON && Array.isArray(selectedData.time_hour_list)) {
      selectedData = {
        ...selectedData,
        time_hour_list: selectedData.time_hour_list.join(','),
        time_hour_list_length: selectedData.time_hour_list.length
      };
    }

    analyticsTracker.eventTrack({
      action,
      properties: {
        ...selectedData,
        id: entityDetails.id
      }
    });
  };

  const onFullLoadObjectChange = policy => {
    setFullLoadPolicyObj(policy);
  };

  const canShowQuotaAlert = useMemo(() => {
    const { frequencyValue, frequencyType } = policyObj;
    if (!frequencyValue) {
      return false;
    }

    const selectedFrequencyValue = entityDetails.execution_policy.frequency;
    const defaultFrequencyValue = entityDetails.frequency_config.default_frequency;
    const newFrequencyValue = frequencyValue * frequencyType.minSeconds;
    return (
      quotaLimitWarningObj?.message &&
      (newFrequencyValue < selectedFrequencyValue || newFrequencyValue < defaultFrequencyValue)
    );
  }, [quotaLimitWarningObj, policyObj.frequencyValue]);

  const policyMessage = useMemo(() => getPolicyMessage(policyObj), [policyObj]);

  const dataIdGenerator = getDataIdGenerator('PipelineExecutionPolicyDialog');

  const isMigrationGtmEnabled = !!getTeamSettings().show_sync_frequency_migration_popup;

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title='Change the Pipeline Ingestion Schedule'
        subTitle='Define the frequency or time(s) at which the Pipeline must ingest the data from the Source.'
        dataIdGenerator={dataIdGenerator}
        onClose={onClose}
      />

      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div className='mb-5'>
          <div className='text-body-2 mb-2'>Pipeline Ingestion Schedule</div>

          <div className='text-body-1 text-secondary mb-6'>
            This Ingestion schedule applies to all the Objects in the Pipeline
            {showFullLoadFrequencyScheduler ? ' except Full Load Objects.' : '.'}
          </div>

          <SchedulerOptions
            entity={HevoEntity.PIPELINE.value}
            options={policyObj.displayedFrequencies}
            onScheduleOptionSelected={handleOptionSelection}
            selectedOption={selectedOption}
          />

          <ExecutionPolicyContext.Provider
            value={{
              tooltipData,
              setTooltipData
            }}
          >
            <Scheduler
              schedulerType={
                policyObj.isDailyCronConfigurable
                  ? SchedulerType.MultiCronType
                  : SchedulerType.FrequencyType
              }
              entity={HevoEntity.PIPELINE.value}
              policyObj={policyObj}
              setPolicyObj={setPolicyObj}
              ref={formikRef}
              handleSubmit={
                showFullLoadFrequencyScheduler && fullLoadPolicyObj
                  ? fullLoadFormikRef.current?.handleSubmit
                  : handleSubmit
              }
              isCustom={isCustom}
            />
          </ExecutionPolicyContext.Provider>

          {policyMessage ? (
            <ExecutionPolicyMessage
              className='mb-5 mt-7'
              policyMessage={policyMessage}
              messagePrefix={PIPELINE_POLICY_MESSAGE_PREFIX}
            />
          ) : null}

          {canShowQuotaAlert && (
            <HdPane
              dataId={dataIdGenerator('pipeline_quota_limit_alert')}
              icon='warning'
              variant='warning-faded'
              className='mb-5'
            >
              <span className='text-body-1 text-secondary'>
                {quotaLimitWarningObj.message}

                {quotaLimitWarningObj.docs && quotaLimitWarningObj.docs.url && (
                  <HdDocLink
                    dataId={dataIdGenerator('pipeline_quota_limit_alert')}
                    label={quotaLimitWarningObj.docs.displayText}
                    docLink={quotaLimitWarningObj.docs.url}
                    section='pipeline_quota_limit_alert'
                    className='ml-1'
                  />
                )}
              </span>
            </HdPane>
          )}
        </div>

        {showFullLoadFrequencyScheduler && (
          <FullLoadObjectExecutionPolicy
            entityDetails={entityDetails}
            fullLoadFormikRef={fullLoadFormikRef}
            policyObj={policyObj}
            disableSubmit={disable => {
              setIsSubmitDisabled(disable);
            }}
            handleSubmit={handleSubmit}
            onChange={onFullLoadObjectChange}
            dataIdGenerator={dataIdGenerator}
          />
        )}
        {isMigrationGtmEnabled ? (
          <HdPane variant='warning-faded' icon='warning'>
            <Typography variant='body2'>
              <b>Migrate the Pipeline to Sync Schedule-based replication.</b> Once migrated, the
              Pipelines will run as per the sync frequency instead of ingestion and load
              frequencies.
              <span
                role='button'
                tabIndex={0}
                className='text-primary text-body-3 ml-1 cursor-hand'
                onClick={() => {
                  openSyncFrequencyMigrationDialog();
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    openSyncFrequencyMigrationDialog();
                  }
                }}
              >
                Update Sync Schedule
                <HdIcon className='ml-1' name='new-window' />
              </span>
            </Typography>
          </HdPane>
        ) : null}
      </div>

      <div className='dialog-footer'>
        <HdButton
          dataId={dataIdGenerator('cancel')}
          variation='outline'
          disabled={savingSchedule}
          onClick={() => onClose()}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('submit')}
          disabled={savingSchedule || isSubmitDisabled}
          showProgress={savingSchedule}
          type='submit'
          onClick={() => formikRef.current.submitForm()}
        >
          Schedule
        </HdButton>
      </div>
    </div>
  );
}
