import * as Yup from 'yup';

export function getSSHConfigValidationSchema() {
  return {
    useSSH: Yup.boolean(),
    vpcPeeringEnabled: Yup.boolean(),
    sshIp: Yup.string().when(['useSSH', 'vpcPeeringEnabled'], {
      is: (useSSH, vpcPeeringEnabled) => useSSH && !vpcPeeringEnabled,
      then: () => Yup.string().required(),
      otherwise: () => Yup.string().nullable()
    }),
    sshPort: Yup.string().when(['useSSH', 'vpcPeeringEnabled'], {
      is: (useSSH, vpcPeeringEnabled) => useSSH && !vpcPeeringEnabled,
      then: () => Yup.string().required(),
      otherwise: () => Yup.string().nullable()
    }),
    sshUser: Yup.string().when(['useSSH', 'vpcPeeringEnabled'], {
      is: (useSSH, vpcPeeringEnabled) => useSSH && !vpcPeeringEnabled,
      then: () => Yup.string().required(),
      otherwise: () => Yup.string().nullable()
    })
  };
}
