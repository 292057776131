import {
  SetupGuideLinkTrackingMedium,
  SetupGuideScrollStrategy
} from '../../app/nodes/nodes-settings/setup-guide-link';
import { _setupGuideCommService } from '../../app/nodes/services/setup-guide-comm.service';

export interface SetupGuideLinkRequiredParams {
  setupGuideLink: string;
  section: string;
  label: string;
}

export interface SetupGuideLinkParams extends SetupGuideLinkRequiredParams {
  medium?: SetupGuideLinkTrackingMedium;
  properties?: object;
  scrollOffset?: number;
  highlightTitle?: boolean;
  scrollStrategy?: SetupGuideScrollStrategy;
}

// utilizing angular tracking service to track the clicks for setup guide
export const trackSetupGuideLink = ({
  setupGuideLink,
  section,
  label,
  medium = 'click',
  properties = {},
  scrollOffset = 0,
  highlightTitle = false,
  scrollStrategy = 'auto'
}: SetupGuideLinkParams) =>
  _setupGuideCommService.goToSection(
    setupGuideLink,
    {
      medium,
      sourceSection: section,
      label,
      properties,
      scrollStrategy
    },
    scrollOffset,
    highlightTitle
  );
