import * as Yup from 'yup';

export function getSSLConfigValidationSchema(sslCARequired: boolean) {
  const schema: any = {};

  schema.sslCA = Yup.object().when(['useSSL', 'sslClientCert', 'sslClientKey', 'optionalSSL'], {
    is: (useSSL, sslClientCert, sslClientKey, optionalSSL) => {
      if (useSSL) {
        if (sslCARequired || !!sslClientCert || !!sslClientKey) {
          return true;
        }
      } else if (optionalSSL) {
        return true;
      }
      return false;
    },
    then: () => Yup.object().required(),
    otherwise: () => Yup.object().nullable()
  });

  schema.sslClientCert = Yup.object().when(['useSSL', 'sslClientKey'], {
    is: (useSSL, sslClientKey) => useSSL && !!sslClientKey,
    then: () => Yup.object().required(),
    otherwise: () => Yup.object().nullable()
  });

  schema.sslClientKey = Yup.object()
    .when(['useSSL', 'sslClientCert'], {
      is: (useSSL, sslClientCert) => useSSL && !!sslClientCert,
      then: () => Yup.object().required(),
      otherwise: () => Yup.object().nullable()
    })
    .nullable();

  schema.sslLoadAllCA = Yup.boolean().when(['useSSL'], {
    is: useSSL => useSSL,
    then: () => Yup.boolean().required(),
    otherwise: () => Yup.boolean().nullable()
  });

  return schema;
}
