import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const CLICKUP_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPITokenField({
        hint: 'The API key generated from your avatar lower-left corner → Apps → API Token → Click generate. ',
        externalDocLink:
          'https://clickup.com/api/developer-portal/authentication/#generate-your-personal-api-token',
        buildParamsKey: 'api_key'
      })
    }
  ],
  [
    {
      key: 'workspaceId',
      fieldId: 'workspace-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'workspaceId', label: 'Workspace ID', required: true },
      hint: 'The 8-digit Workspace ID generated from the URL of the Home page.',
      sbsDocLink: '/sources/sns-analytics/clickup/#obtaining-the-workspace-id',
      buildParamsKey: 'workspace_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
