/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { getDataIdGenerator } from '../../utils/generateDataId';
import styles from './styles.module.scss';
import { InclusionItem } from '../../../app/core/models/inclusion-list-v2';
import { isEqual } from '../../legacy-utils/equality';
import {
  GetList,
  ListNextPage,
  ListPrevPage,
  ListResponse,
  FilterList,
  RefreshList,
  ListError,
  MultipleFilterList
} from '../../../app/pagination/list-conductor/list-actions';
import { ListConductor } from '../../../app/pagination/list-conductor/list-conductor';
import {
  SELECTOR_SEARCH_KEY,
  SELECTOR_SHIMMER_DIMENSIONS
} from '../../../app/shared/single-level-selector/constants';
import { SingleLevelSelectorPageData } from '../../../app/shared/single-level-selector/interface';
import {
  SelectorItemsFactory,
  SelectorItemWithListData
} from '../../../app/shared/single-level-selector/model';
import useDontMountAtFirst from '../../hooks/useDontMountAtFirst';
import useInclusionList from '../../hooks/useInclusionList';
import useListConductor from '../../hooks/useListConductor';
import { DefaultSingleLevelSelectorProps, IHeaderElementProps } from './model';
import { capitalizeFirstLetter } from '../../utils/stringFormatter';
import { EntityUIState } from '../../../app/core/models/entitiy-ui-state';
import SearchArea from '../SearchArea';
import { HdIconButton, HdLink, HdIcon, HdCheckbox } from '../UIElements';
import PaginationControl from '../PaginationControl';
import ScrollToTopButton from '../ScrollToTopButton';
import useAbortController from '../../hooks/useAbortController';
import RetryApiAlert from '../RetryApiAlert';
import { getSingleLevelSelectorPaginationStrategy } from './utils';
import {
  SingleLevelSelectorItemShimmer,
  SingleLevelSelectorDefaultItem,
  SingleLevelSelectorEmptyListError,
  SingleLevelSelectorEmptyFilteredListError
} from './Components';

import useForceUpdate from '../../hooks/useForceUpdate';
import { isPromiseCancelError } from '../../legacy-utils/promise';

const DEBOUNCE_INTERVAL = 500;
const selectorShimmerDimensions = SELECTOR_SHIMMER_DIMENSIONS;

function HeaderElement({ id, HeaderComponent, handleFilterUpdate }: IHeaderElementProps) {
  return <HeaderComponent updateFilterValue={handleFilterUpdate} key={id} />;
}

function SingleLevelSelector({
  dataId,
  name,
  selectableItemName,
  showSearchBar = false,
  paginationStrategy = 'offset',
  getList,
  requestParams,
  canBulkSelect = true,
  canSelectAll = true,
  listUpdateCallback,
  NoItemsFoundError,
  NoItemsMatchingFilterError,
  listInteractionCallback,
  selectedItems,
  headerOptions,
  classes = {},
  renderListItem,
  onSearchChange,
  selectorRef,
  showRefresh = true,
  retryApiVariant = 'compact'
}: DefaultSingleLevelSelectorProps) {
  const pagination = useRef(getSingleLevelSelectorPaginationStrategy(paginationStrategy));
  const paginationHelper = useRef(pagination.current);
  const forceUpdate = useForceUpdate();
  const [currentRenderingItems, setCurrentRenderingItems] = useState<SelectorItemWithListData[]>(
    []
  );

  const { listConductor, changes } = useListConductor({
    paginationStrategy: pagination.current.getStrategy(requestParams?.pageSize || 10)
  });

  const { inclusionList, togglePageCheckedState, setCheckedState } = useInclusionList(
    'selector',
    null,
    listUpdateCallback,
    selectedItems
  );

  useImperativeHandle(selectorRef, () => ({
    refresh: () => {
      onRefresh();
    }
  }));

  const { abort, abortControllerRef } = useAbortController();
  const showPaginationControl = paginationStrategy === 'offset';
  const selectableItemNameLabel = capitalizeFirstLetter(selectableItemName);
  const listConductorMeta = listConductor.getListMeta();
  const listConductorCount = inclusionList.getIncludedItemsCount();
  const listConductorSize = inclusionList.state.size;
  const listConductorAllIncluded = inclusionList.isAllIncluded();
  const listConductorAllSelectable = canBulkSelect && listConductorCount > 0;
  const listConductorState = listConductor.getListState().state;
  const listConductorStateError = listConductor.getListState().error;
  const listConductorRefreshing = listConductorState === EntityUIState.REFRESHING;
  const listConductorIdle = listConductorState === EntityUIState.IDLE;
  const listConductorErrored = listConductorState === EntityUIState.ERRORED;
  const listConductorEmpty = listConductorState === EntityUIState.EMPTY;
  const listConductorNoItem = listConductorState === EntityUIState.NEW && listConductorSize === 0;
  const listConductorCheckboxIndeterminate = !!inclusionList.pageIndeterminate;
  const listConductorCheckboxChecked = !!(
    inclusionList.pageIncluded && !inclusionList.pageIndeterminate
  );
  const listConductorRefreshingOrLoading =
    listConductorState === EntityUIState.LOADING ||
    listConductorState === EntityUIState.LOADING_MORE ||
    listConductorState === EntityUIState.REFRESHING;
  const listConductorShowListItem =
    (paginationStrategy === 'offset' && listConductorState === EntityUIState.IDLE) ||
    (paginationStrategy === 'cursor' &&
      (listConductorState === EntityUIState.IDLE ||
        listConductorState === EntityUIState.LOADING_MORE));

  /**
   * x (of y)* selected
   * x is the count of total selected items
   * y is the count of filtered response
   * (of y)* if (we know the total count of filtered response and bulk selection is allowed) or (the list is not
   * paginated)
   */
  const showOfSelected = listConductorSize && canBulkSelect;

  useDontMountAtFirst(() => {
    getListData(requestParams, listConductor);
  }, [changes]);

  useEffect(() => {
    // setting the base filters passed through props
    if (headerOptions) {
      const filters = {};
      headerOptions
        ?.filter(option => !!option.defaultFilterValue)
        ?.forEach(option => {
          filters[option.id] = option.defaultFilterValue;
        });

      listConductor.dispatch(new MultipleFilterList(filters));
    }
  }, []);

  useEffect(() => {
    listConductor.dispatch(new GetList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  const getListData = async (selectorRequestParams: any, listConductorArg: ListConductor) => {
    try {
      abort(true);
      const data = await getList(selectorRequestParams, listConductorArg, {
        signal: abortControllerRef.current.signal
      });
      processItemsInclusionList(data);
    } catch (error) {
      if (isPromiseCancelError(error)) {
        return;
      }

      listConductor.dispatch(new ListError(error));
      forceUpdate();
    }
  };

  const processItemsInclusionList = (res: SingleLevelSelectorPageData) => {
    let rawItems = [];

    if (res.items) {
      rawItems = res.items;
    }

    const selectorItems = SelectorItemsFactory(rawItems) || [];

    const inclusionItems = [];

    let itemsWithListData = selectorItems.map(item => {
      const inclusionItem = new InclusionItem(item.id);
      inclusionItems.push(inclusionItem);

      return {
        ...item,
        inclusionItem
      };
    });

    if (paginationHelper.current.shouldAppendPage(listConductor)) {
      itemsWithListData = [...currentRenderingItems, ...itemsWithListData];
      inclusionList.addItems(inclusionItems);
    } else {
      inclusionList.setItems(inclusionItems);
    }
    const currentListSize = itemsWithListData.length;
    const totalCount = paginationHelper.current.getListSize(res, currentListSize);
    inclusionList.setSize(totalCount);

    listConductor.dispatch(
      new ListResponse({
        count: totalCount || currentListSize,
        ...paginationHelper.current.getListResponseData(res)
      })
    );
    setCurrentRenderingItems(itemsWithListData);
  };

  const onPrev = () => {
    listConductor.dispatch(new ListPrevPage());
  };

  const onNext = () => {
    listConductor.dispatch(new ListNextPage());
  };

  const toggleChecklist = () => {
    togglePageCheckedState();
    listInteractionCallback();
  };

  const handleClearAll = () => {
    setCheckedState(false);
    listInteractionCallback();
  };

  const handleSelectAll = () => {
    setCheckedState(true);
    listInteractionCallback();
  };

  const toggleCheckbox = (inclusionItem: InclusionItem) => {
    inclusionItem.toggleCheckedState();
    listInteractionCallback();
  };

  const filterList = (key: string, value: string) => {
    listConductor.dispatch(new FilterList({ key, value }));
  };

  const onSearch = useCallback(search => {
    filterList(SELECTOR_SEARCH_KEY, search);

    if (typeof onSearchChange === 'function') {
      onSearchChange(search);
    }
  }, []);

  const onRefresh = () => {
    if (paginationStrategy === 'cursor') {
      listConductor.dispatch(new RefreshList(true));
      setCurrentRenderingItems([]);
    } else {
      listConductor.dispatch(new RefreshList());
    }
  };

  const handleErrorRefreshClick = () => {
    filterList(SELECTOR_SEARCH_KEY, '');
    onRefresh();
  };

  const [sentryRef] = useInfiniteScroll({
    loading: listConductorRefreshingOrLoading,
    hasNextPage: !!listConductorMeta.pagination.page,
    onLoadMore: () => onNext(),
    disabled: listConductorErrored,
    rootMargin: '0px 0px 400px 0px'
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'single-level-selector'), [
    dataId
  ]);

  const handleFilterUpdate = (key: string, value: any) => {
    filterList(key, value);
  };

  const scrollViewportId = dataIdGenerator('items-container');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!listConductorErrored ? (
        <div data-id={dataIdGenerator('')} className={clsx(styles.wrapper, 'table-container')}>
          <table className='box-table'>
            <thead>
              <tr>
                <th>
                  <div className={clsx('center-flex-row', classes.selectorColumnHeader)}>
                    {listConductorRefreshingOrLoading ? (
                      <span className='shimmer shimmer-square mr-4' />
                    ) : null}

                    {listConductorIdle && canSelectAll ? (
                      <HdCheckbox
                        dataId={dataIdGenerator(`root-${name}`)}
                        name={name}
                        value={`all-${selectableItemNameLabel}-selector`}
                        className={`mr-4 ${
                          !listConductorCheckboxIndeterminate ? 'hd-indeterminate-checked' : ''
                        }`}
                        checked={listConductorCheckboxChecked}
                        indeterminate={listConductorCheckboxIndeterminate}
                        onChange={toggleChecklist}
                      />
                    ) : null}

                    <span className='mr-1 text-ellipsis'>Select {selectableItemNameLabel}</span>

                    {listConductorIdle &&
                      (showOfSelected ? (
                        <span
                          data-id={dataIdGenerator('partial-selection-count')}
                        >{`(${listConductorCount} of ${listConductorSize})`}</span>
                      ) : (
                        <span data-id={dataIdGenerator('selection-count')}>
                          {listConductorCount
                            ? `(${listConductorCount} Selected)`
                            : inclusionList.isAllIncluded()
                            ? '(All Selected)'
                            : ''}
                        </span>
                      ))}

                    {listConductorAllSelectable ? (
                      <div className='ml-2 px-2 border-left'>
                        {!listConductorAllIncluded ? (
                          <HdLink
                            tag='button'
                            className='text-link'
                            onClick={handleSelectAll}
                            dataId={dataIdGenerator('select-all')}
                          >
                            Select All
                            {` ${listConductorSize || ''} ${selectableItemNameLabel}`}
                          </HdLink>
                        ) : (
                          <HdLink
                            tag='a'
                            className='text-link'
                            onClick={handleClearAll}
                            dataId={dataIdGenerator('clear-all')}
                          >
                            Clear All
                          </HdLink>
                        )}
                      </div>
                    ) : null}
                  </div>
                  {headerOptions &&
                    headerOptions.map(ele => (
                      <HeaderElement
                        key={ele.id}
                        handleFilterUpdate={handleFilterUpdate}
                        {...ele}
                      />
                    ))}
                  <div className='center-flex-row justify-end flex-1'>
                    {showSearchBar ? (
                      <SearchArea
                        dataId={dataIdGenerator('')}
                        collapsible
                        autofocus
                        defaultExpanded={false}
                        onSearch={onSearch}
                        debounceInterval={DEBOUNCE_INTERVAL}
                        placeholder={`Search ${selectableItemNameLabel}`}
                        onChange={() => abort()}
                      />
                    ) : null}

                    {showPaginationControl ? (
                      <PaginationControl
                        className='ml-4'
                        paginationData={listConductorMeta.pagination}
                        listState={listConductorState}
                        nextPage={onNext}
                        prevPage={onPrev}
                        dataId={dataIdGenerator('')}
                      />
                    ) : null}

                    {showRefresh ? (
                      <HdIconButton
                        disabled={listConductorRefreshingOrLoading}
                        onClick={onRefresh}
                        className='ml-3'
                        dataId={dataIdGenerator('refresh')}
                      >
                        <HdIcon
                          name='refresh'
                          size={3}
                          className={clsx('refresh-icon', { refreshing: listConductorRefreshing })}
                        />
                      </HdIconButton>
                    ) : null}
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          <div className={styles.listWrapper} id={scrollViewportId} data-id={scrollViewportId}>
            <table className='box-table'>
              <tbody>
                {listConductorShowListItem
                  ? currentRenderingItems.map((item: SelectorItemWithListData) => (
                      <tr key={item.id}>
                        <td className='center-flex-row'>
                          <div className={clsx('center-flex-row', classes.selectorColumnBody)}>
                            <HdCheckbox
                              dataId={dataIdGenerator(`item-${item.id}`)}
                              value={`${selectableItemNameLabel}-selector`}
                              className='mr-4'
                              checked={item.inclusionItem.included}
                              onChange={() => toggleCheckbox(item.inclusionItem)}
                            />

                            {typeof renderListItem === 'function' ? (
                              renderListItem({ item })
                            ) : (
                              <SingleLevelSelectorDefaultItem item={item} />
                            )}
                          </div>
                          {headerOptions &&
                            headerOptions.map(option => <option.BodyComponent option={item} />)}
                        </td>
                      </tr>
                    ))
                  : null}

                {paginationStrategy === 'cursor' ? (
                  <tr ref={sentryRef} data-id={dataIdGenerator('cursor-sentry-ref')} />
                ) : null}

                {listConductorRefreshingOrLoading
                  ? selectorShimmerDimensions.map((dimension, index) => (
                      <SingleLevelSelectorItemShimmer
                        key={index}
                        dimension={dimension}
                        dataId={dataIdGenerator(`item-${index}-shimmer`)}
                      />
                    ))
                  : null}

                {listConductorEmpty ? (
                  <SingleLevelSelectorEmptyFilteredListError
                    dataId={dataIdGenerator('empty-filtered-list-error')}
                    selectableItemNameLabel={selectableItemNameLabel}
                    NoItemsMatchingFilterError={NoItemsMatchingFilterError}
                  />
                ) : null}

                {listConductorNoItem ? (
                  <SingleLevelSelectorEmptyListError
                    dataId={dataIdGenerator('empty-list-error')}
                    selectableItemNameLabel={selectableItemNameLabel}
                    NoItemsFoundError={NoItemsFoundError}
                  />
                ) : null}
              </tbody>
            </table>
          </div>

          {listConductorShowListItem ? (
            <ScrollToTopButton variant='compact' scrollViewportId={scrollViewportId} />
          ) : null}
        </div>
      ) : (
        <RetryApiAlert
          variant={retryApiVariant}
          classes='my-0'
          actionHandler={handleErrorRefreshClick}
          error={listConductorStateError}
          dataId={dataIdGenerator('')}
        />
      )}
    </>
  );
}

export const singleLevelSelectorCompare = (
  prevProps: DefaultSingleLevelSelectorProps,
  nextProps: DefaultSingleLevelSelectorProps
) => {
  // pluck out the selectedItems(InclusionListState) from the props to do a deep comparison of
  // props to minimize re-renders
  let remainingPreviousProps: Partial<DefaultSingleLevelSelectorProps>;
  let remainingNextProps: Partial<DefaultSingleLevelSelectorProps>;

  if (prevProps) {
    const { selectedItems, getList, renderListItem, ...restPrevProps } = prevProps;
    remainingPreviousProps = restPrevProps;
  }

  if (nextProps) {
    const { selectedItems, getList, renderListItem, ...restNextProps } = nextProps;
    remainingNextProps = restNextProps;
  }

  return isEqual(remainingPreviousProps, remainingNextProps);
};

export default React.memo(SingleLevelSelector, singleLevelSelectorCompare);
