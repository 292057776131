import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { QueryConfirmDialog } from '../../react/components/Dialog/QueryConfirmDialog';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'query-confirm-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class QueryConfirmDialogReactComponent implements OnChanges {
  @Input() isOpen = false;
  @Input() params: {
    query: string;
    queryEditorMode: string;
    mappingParams: any;
  };

  @Output() onClose = new EventEmitter<boolean>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount(
      QueryConfirmDialog,
      this._el.nativeElement,
      {
        isOpen: this.isOpen,
        params: this.params,
        onClose: this.onClose.emit.bind(this.onClose)
      }
    );
  }
}
