import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HdA11yModule } from '../a11y/a11y.module';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';

import { MessagePaneComponent } from './message-pane/message-pane.component';
import { ShowExtensionPaneReactComponent } from './show-extension-pane-react.component';
import { SharedModule } from '../shared/shared.module';



const DIRECTIVES = [
  MessagePaneComponent,
  ShowExtensionPaneReactComponent
];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
  imports: [
    CommonModule,
    HdA11yModule,
    IconModule,
    SharedModule,
    TooltipModule
  ]
})
export class HdAlertModule {
}
