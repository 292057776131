import { Component, ViewEncapsulation, Input, HostBinding, EventEmitter, Output, OnChanges } from '@angular/core';
import { SnackbarType } from '../models';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent implements OnChanges {
  visible = false;
  @Input() canClose = true;
  @Input() showOnLoad = false;
  @Input() type: SnackbarType;
  @Input() classes: string;
  @Input() inline = false;
  @HostBinding('class') snackbarClasses;

  @Output() close = new EventEmitter<void>();

  constructor(
  ) {}

  ngOnChanges() {
    this.updateSnackbarClasses();
  }

  updateSnackbarClasses() {
    this.snackbarClasses = `${this.visible ? 'visible' : ''} ${this.type || SnackbarType.WARN} ${this.classes || ''} ${this.inline ? 'inline' : ''}`;
  }

  show() {
    this.visible = true;
    this.updateSnackbarClasses();
  }

  hide() {
    if (!this.visible) {
      return;
    }
    this.visible = false;
    this.updateSnackbarClasses();
    this.close.emit();
  }
}
