import React, { useEffect } from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { Banner } from '../../../components/Banner';
import { BannerColorPalette } from '../../../../app/banner/interface';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import {
  TRACKER_PAYMENT_PENDING_BANNER_CLOSE,
  TRACKER_PAYMENT_PENDING_BANNER_CLOSE_CLICK,
  TRACKER_PAYMENT_PENDING_BANNER_OPEN,
  TRACKER_PAYMENT_PENDING_PAY_NOW_CLICK,
  TRACKER_PAYMENT_PENDING_TO_PAYMENT_METHODS_CLICK,
  TRACKER_PAYMENT_PENDING_VIEW_PENDING_INVOICES_CLICK
} from './constants';
import { HdButton } from '../../../components/UIElements';

export default function PaymentPendingBanner({ close, data }) {
  const analyticsTracker = useAnalyticsTracker();
  const history = useHistory();

  useEffect(() => {
    analyticsTracker.eventTrack({
      action: TRACKER_PAYMENT_PENDING_BANNER_OPEN
    });
  }, [analyticsTracker]);

  const onCloseClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_PAYMENT_PENDING_BANNER_CLOSE_CLICK
    });

    onClose();
  };

  const onClose = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_PAYMENT_PENDING_BANNER_CLOSE
    });

    close();

    if (typeof data?.closeCallback === 'function') {
      data.closeCallback('mouse');
    }
  };

  const payNowClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_PAYMENT_PENDING_PAY_NOW_CLICK
    });

    onClose();
  };

  const viewUnpaidInvoicesClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_PAYMENT_PENDING_VIEW_PENDING_INVOICES_CLICK
    });

    history.push('/payment/payment-history');

    onClose();
  };

  const toPaymentMethodsClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_PAYMENT_PENDING_TO_PAYMENT_METHODS_CLICK
    });

    history.push('/payment/cards');

    onClose();
  };

  return (
    <Banner
      color={BannerColorPalette.DEFERRED}
      dataId='payment-pending-banner'
      open
      onClose={onCloseClick}
      canClose
      icon='warning'
      Title={<span>Payment is Pending!</span>}
    >
      Please make the payment soon to avoid disruption in services.
      {!!data?.payment_url ? (
        <HdButton
          variation='outline'
          size='sm'
          tag='a'
          className='ml-2'
          palette='light'
          inlineText
          href={data.payment_url}
          target='_blank'
          onClick={payNowClick}
        >
          Pay Now
        </HdButton>
      ) : (
        <HdButton
          size='sm'
          variation='outline'
          palette='light'
          inlineText
          className='ml-2'
          onClick={viewUnpaidInvoicesClick}
        >
          View Unpaid Invoices
        </HdButton>
      )}
      <HdButton
        size='sm'
        variation='faded'
        inlineText
        className='ml-2'
        palette='light'
        onClick={toPaymentMethodsClick}
      >
        Add Payment Method
      </HdButton>
    </Banner>
  );
}
