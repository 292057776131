import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { PostPCSNotification } from '../models/post-pcs-notification';
import { BeaconService } from './beacon.service';
import { SettingsStorageService } from './settings-storage.service';
import { TrialDetailsService } from './trial-details.service';
import { UserService } from './user.service';

declare const __POST_PCS_ERROR_NOTIFICATION_URL__: string;

@Injectable({ providedIn: 'root' })
export class PostPCSNotifierService {
  private _postPCSErrorNotificationStoredDataKey = 'postPCSErrorNotification';
  private _notificationTriggerInterval = 3600000;
  private _isUserInTrial: boolean;

  constructor(
    private _userService: UserService,
    private _beaconService: BeaconService,
    protected _trialDetailsService: TrialDetailsService,
    private _settingStorageService: SettingsStorageService
  ) {
    this._trialDetailsService.trialDetails$.pipe(
      take(1)
    ).subscribe((data) => {
      this._isUserInTrial = data.isInTrial();
    });
  }

  notify({ type, pipeline, throttle, throttleTime, ...data}: PostPCSNotification) {
    const user = this._userService.getUser();

    if (!this._isUserInTrial || !user) {
      return;
    }

    if (throttle) {
      const storedNotificationData = this._settingStorageService.getSettings(this._postPCSErrorNotificationStoredDataKey);

      let lastNotificationTimestamp = 0;

      let timestampStorageKey: string = type;

      if (pipeline) {
        timestampStorageKey = `${pipeline.id}.${type}`;
      }

      if (storedNotificationData) {
        lastNotificationTimestamp = storedNotificationData[timestampStorageKey];
      }

      if ((new Date().getTime() - lastNotificationTimestamp) < (throttleTime || this._notificationTriggerInterval)) {
        return;
      }

      this._settingStorageService.applySettings(this._postPCSErrorNotificationStoredDataKey, {
        ...(storedNotificationData || {}),
        [timestampStorageKey]: new Date().getTime()
      });
    }

    const beaconData = {
      type,
      ...data,
      ...(pipeline ? {
        pipelineSeqId: pipeline.seq_id,
        pipelineId: pipeline.id,
        sourceType: pipeline.source_type,
        destinationType: pipeline.dest_type,
        autoMappingStatus: pipeline.auto_mapping_status
      } : {}),
      name: user.name,
      email: user.email,
      isUserOwner: user.isOwner,
      userId: user.id,
      teamId: user.teamId,
      teamName: user.teamName,
      region: user.clusterId
    };

    this._beaconService.sendBeacon(
      __POST_PCS_ERROR_NOTIFICATION_URL__,
      Object.keys(beaconData).reduce((formData, key) => {
        if (Array.isArray(beaconData[key])) {
          beaconData[key].forEach((val) => {
            formData.append(key, val);
          });
        } else {
          formData.append(key, beaconData[key]);
        }
        return formData;
      }, new FormData())
    );
  }
}
