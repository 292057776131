import { useCallback } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { FormControlLabel, SvgIcon } from '@mui/material';
import { getDataIdGenerator } from '../../../utils/generateDataId';

function HdCheckedIcon() {
  return (
    <SvgIcon className='hd-checked-icon' style={{ width: 16, height: 16, fill: 'none' }}>
      <path
        d='M4.1,12.7 9,17.6 20.3,6.3'
        style={{ stroke: 'currentColor' }}
        strokeDasharray={22.910259}
        strokeLinecap='round'
        strokeWidth='2px'
        className='checkmark-path'
      />
    </SvgIcon>
  );
}

function HdIndeterminateIcon() {
  return (
    <SvgIcon style={{ width: 14, height: 14, fill: 'none' }}>
      <path
        d='M4 12 L 20 12'
        style={{ stroke: 'currentColor' }}
        strokeLinecap='round'
        strokeWidth='3px'
      />
    </SvgIcon>
  );
}

interface HdCheckboxProps {
  dataId: string;
  value?: string;
  disabled?: boolean;
  label?: React.ReactElement;
  onChange?: Function;
  [key: string]: any;
}

export function HdCheckbox({
  dataId,
  value,
  disabled,
  label = <></>,
  onChange = () => {},
  className,
  ...props
}: HdCheckboxProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'checkbox'), [dataId]);
  return (
    <FormControlLabel
      value={value}
      label={label}
      className={className}
      control={
        <StyledCheckbox
          name={dataId}
          disabled={disabled}
          onChange={event => onChange(event.target.checked)}
          inputProps={{
            'aria-label': 'Checkbox',
            // @ts-ignore
            'data-id': dataIdGenerator('input')
          }}
          {...props}
        />
      }
      data-id={dataIdGenerator('label')}
      sx={{ margin: 0 }}
    />
  );
}

const StyledCheckbox = styled((props: CheckboxProps) => (
  <Checkbox
    color='default'
    checkedIcon={<HdCheckedIcon />}
    indeterminateIcon={<HdIndeterminateIcon />}
    icon={<span />}
    disableRipple
    {...props}
  />
))(() => ({
  border: 'solid 2px var(--divider-color)',
  borderRadius: 4,
  padding: 0,
  width: 16,
  height: 16,
  pointerEvents: 'none',
  '& .PrivateSwitchBase-input': {
    pointerEvents: 'auto'
  },
  '& .PrivateSwitchBase-input[disabled]': {
    pointerEvents: 'none !important'
  },
  '&.Mui-checked': {
    ...checkedStyle,
    '& .hd-checked-icon path': {
      '@keyframes checkmark-path': checkmarkPath,
      WebkitAnimation: '180ms linear 0s checkmark-path',
      animation: '180ms linear 0s checkmark-path'
    }
  },
  '&.MuiCheckbox-indeterminate': {
    ...checkedStyle,
    '& svg': {
      '@keyframes indeterminate-path': indeterminatePath,
      WebkitAnimation: '90ms linear 0s indeterminate-path',
      animation: '90ms linear 0s indeterminate-path'
    }
  },
  '&.hd-indeterminate-checked.Mui-checked': {
    '& .hd-checked-icon': {
      '@keyframes animate-indeterminate': animateIndeterminate,
      WebkitAnimation: '500ms linear 0s animate-indeterminate',
      animation: '500ms linear 0s animate-indeterminate'
    }
  },
  '&.Mui-disabled:not(.Mui-checked)': {
    backgroundColor: 'var(--divider-color)',
    borderColor: 'var(--divider-color)',
    color: 'var(--text-secondary-color)'
  },
  '&.Mui-disabled.Mui-checked': {
    opacity: 0.5
  },
  '&:hover': {
    '&:before': {
      content: '""',
      backgroundColor: 'var(--text-default-color)',
      opacity: 0.05,
      width: '40px',
      height: '40px',
      position: 'absolute',
      borderRadius: '50%'
    }
  },
  '&.Mui-checked:hover': {
    '&:before': {
      backgroundColor: 'var(--primary-color)'
    }
  }
}));

const fadeInBackground = {
  '0%': {
    opacity: 0
  },

  '50%': {
    opacity: 1
  }
};

const checkmarkPath = {
  '0%, 50%': {
    strokeDashoffset: '22.91026'
  },

  '50%': {
    animationTimingFunction: 'cubic-bezier(0, 0, .2, .1)'
  },

  '100%': {
    strokeDashoffset: 0
  }
};

const indeterminatePath = {
  '0%': {
    width: 0
  },
  '100%': {
    width: '100%'
  }
};

const animateIndeterminate = {
  from: {
    animationTimingFunction: 'cubic-bezier(0.14, 0, 0, 1)',
    opacity: 0,
    transform: 'rotate(45deg)'
  },

  to: {
    opacity: 1,
    transform: 'rotate(360deg)'
  }
};

const checkedStyle = {
  '@keyframes fadein-background': fadeInBackground,
  WebkitAnimation: '180ms linear 0s fadein-background',
  animation: '180ms linear 0s fadein-background',
  backgroundColor: 'var(--primary-color)',
  borderColor: 'var(--primary-color)',
  color: '#fff'
};
