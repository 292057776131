import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AcceptInvite } from './AcceptInvite';
import { AccountSetup } from './AccountSetup';
import { EmpLoginAccountSelect } from './EmpLoginAccountSelect';
import { ForgotPassword } from './ForgotPassword';
import { GoogleLogin } from './GoogleLogin';
import { Login } from './Login';
import { LoginWithCode } from './LoginWithCode';
import { RecoveryCodesPage } from './RecoveryCodesPage';
import { ResetPassword } from './ResetPassword';
import { RestAPIOAuth } from './RestAPIOAuth';
import { SamlLogin } from './SamlLogin';
import { SetupPersona } from './SetupPersona';
import { SubscribeMarketplace } from './SubscribeMarketplace';
import { TfaOtpPage } from './TfaOtpPage';
import { TfaSetupPage } from './TfaSetupPage';
import { VerifyEmail } from './VerifyEmail';

export function AuthPages() {
  return (
    <Routes>
      <Route key='login-with-code' path='/login/code/:code' element={<LoginWithCode />} />

      <Route key='login' path='/login' element={<Login />} />

      <Route key='forgot-password' path='/forgot-password' element={<ForgotPassword />} />

      <Route
        key='emp-login-ac-select'
        path='/emp-login-ac-select'
        element={<EmpLoginAccountSelect />}
      />

      <Route key='reset' path='/reset' element={<ResetPassword />} />

      <Route key='accept-invite' path='/accept-invite' element={<AcceptInvite />} />

      <Route key='setup-account' path='/setup-account' element={<AccountSetup />} />

      <Route key='verify-email' path='/verify-email' element={<VerifyEmail />} />

      <Route key='saml-login' path='/saml-login' element={<SamlLogin />} />

      <Route key='google-login' path='/google-login' element={<GoogleLogin />} />

      <Route key='tfa-otp' path='/tfa/otp' element={<TfaOtpPage />} />

      <Route key='tfa-qr' path='/tfa/qr' element={<TfaSetupPage />} />

      <Route key='tfa-recovery-codes' path='/tfa/recovery-codes' element={<RecoveryCodesPage />} />

      <Route key='rest-api-oauth' path='/rest-api/oauth' element={<RestAPIOAuth />} />

      <Route key='setup-persona' path='/setup-persona' element={<SetupPersona />} />

      <Route
        key='subscribe-marketplace'
        path='/subscribe-marketplace'
        element={<SubscribeMarketplace />}
      />
    </Routes>
  );
}
