export interface BillingFrequency {
  type: string;
  label: string;
  frequency: BillingFrequencyValues;
  value: number;
  monthlyDivisor: number;
}

export enum BillingFrequencyValues {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY'
}

export class BillingFrequencies {
  static [BillingFrequencyValues.MONTHLY]: BillingFrequency = {
    type: 'MONTH',
    label: 'MONTHLY',
    frequency: BillingFrequencyValues.MONTHLY,
    value: 1,
    monthlyDivisor: 1
  };
  static [BillingFrequencyValues.QUARTERLY]: BillingFrequency = {
    type: '3 MONTHS',
    label: 'QUARTERLY',
    frequency: BillingFrequencyValues.QUARTERLY,
    value: 2,
    monthlyDivisor: 3
  };
  static [BillingFrequencyValues.HALF_YEARLY]: BillingFrequency = {
    type: '6 MONTHS',
    label: 'HALF YEARLY',
    frequency: BillingFrequencyValues.HALF_YEARLY,
    value: 3,
    monthlyDivisor: 6
  };
  static [BillingFrequencyValues.YEARLY]: BillingFrequency = {
    type: 'YEAR',
    label: 'YEARLY',
    frequency: BillingFrequencyValues.YEARLY,
    value: 4,
    monthlyDivisor: 12
  };
}

export enum PaymentPlanTier {
  CUSTOM = 'CUSTOM',
  FREE = 'FREE',
  BASIC = 'BASIC',
  STARTER = 'STARTER',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  PROFESSIONAL = 'PROFESSIONAL'
}

export enum BasePaymentPlanType {
  BASE_PLAN = 'BASE_PLAN',
  BASE_OVERAGE_PLAN = 'BASE_OVERAGE_PLAN',
  BASE_FIX_PLAN = 'BASE_FIX_PLAN'
}

export enum BillingProvider {
  AWS = 'AWS',
  STRIPE = 'STRIPE',
  GCP = 'GCP'
}

export const MarketplaceProviders = [BillingProvider.AWS, BillingProvider.GCP];