<div
   class="dialog-container"
   [@fadeAnim]="visibleState"
   hdRbac
   #rbacUser="hdRbac"
   (@fadeAnim.done)="onDialogAnimationDone($event)"
   #container>

  <div
    class="dialog-body upgrade-to-live-chat-box"
    #content
    [@popupAnim]="visibleState">

    <button
      hd-icon-btn
      class="close-dialog-btn"
      (click)="hide()">
      <hd-icon name="close"></hd-icon>
    </button>


    <div class="center-flex-row justify-center mb-5">
      <hd-rounded-icon iconName="password">
      </hd-rounded-icon>
    </div>

    <div class="text-bold title text-center mt-1">Live Chat is not available in your plan</div>

    <div class="text-secondary mt-2 mb-4 text-center support-request-text">
      Your plan includes email support. You can send us your queries at
      <span class="text-primary text-medium">support@hevodata.com</span>
      and we will get back to you within 2 business days.
    </div>

    <div class="center-flex-row justify-center mt-2">
      <div
        [tooltip]="rbacUser.isActionBlocked(rbacUser.rbacPermissions.BILLING_EDIT) ?  rbacUser.rbacDisabledTooltip :  upgradeToChatTooltipText"
        [tooltipDisabled]="rbacUser.isOwnerOrHasPermission(rbacUser.rbacPermissions.BILLING_EDIT)">
        <button
          type="button"
          hdButton
          [disabled]="!rbacUser.isOwnerOrHasPermission(rbacUser.rbacPermissions.BILLING_EDIT)"
          (click)="navigateToChangePlan()">
          Upgrade Your Plan
        </button>
      </div>
    </div>
  </div>

</div>
