import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastConfig } from '../../react/components/Toaster/toast-config';

@Injectable({ providedIn: 'root' })
export class ToasterService {
  public addToastSubject = new BehaviorSubject<ToastConfig>(null);

  pop(type: string | ToastConfig, title?: string, body?: string) {
    const toast = typeof type === 'string' ? { type: type, title: title, body: body } : type;

    this.addToastSubject.next(toast);
  }
}
