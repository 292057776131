import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { AnimationEvent } from '@angular/animations';
import { ProductTourDialogData, ProductTourDialogStep } from './interface';
import { DIALOG_OVERLAY_DATA } from '../../dialog/dialog-overlay.tokens';
import { ProductTourService } from '../product-tour.service';
import ProductTourPopover from '../../../react/components/ProductTourPopover';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogOverlayRef } from '../../dialog/dialog-overlay-ref';
import { DialogBase } from '../../dialog/dialog-base';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { Dialog } from '../../dialog/models';
import { productTourAnim } from './animations';
import { ProductTourAngularticsService } from '../../core/service/product-tour-angulartics.service';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'product-tour-dialog-react',
  templateUrl: './product-tour-dialog-react.component.html',
  providers: [BridgeService,{ provide: Angulartics2, useExisting: ProductTourAngularticsService }],
  animations: [productTourAnim()]
})
export class ProductTourDialogReactComponent extends DialogBase implements OnInit, Dialog, OnDestroy {
  currentStep: ProductTourDialogStep;
  currentStepIdx: number;
  backdropCloseDisabled = true;
  protected _destroyed$ = new Subject<void>();

  constructor(
    private _reactBridgeService: BridgeService,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public data: ProductTourDialogData,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _productTourService: ProductTourService,
    private _creationTrackingAngularticsService: ProductTourAngularticsService
  ) {
    super(document, _dialogRef, data, _keyboardShortcuts);
  }

  @ViewChild('reactContainer') _el: ElementRef;

  ngOnInit() {
    super.ngOnInit();
    this._creationTrackingAngularticsService.startSession();
    setTimeout(() => {
      this.show();
    }, 0);
  }

  show() {
    super.show();
    this.showFirstStep();

    this._productTourService.onDeregister$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((key) => {
      if (this.currentStep && this.currentStep.tourItem === key) {
        this.currentStep = null;
      }
    });
  }

  showFirstStep() {
    this.currentStep = this.data.steps[0];
    this.currentStepIdx = 0;
    this.execute();
  }

  execute() {
    const currentStep = this.currentStep;
    const item = this._productTourService.getItem(currentStep.tourItem);
    item.setActive(true);

    if (currentStep?.close$) {
      currentStep.close$.pipe(
        filter(val => !!val),
        takeUntil(this._destroyed$)
      ).subscribe(() => {
        this.stepDone();
      });
    }

    setTimeout(() => {
      this._reactBridgeService.unmount();
      this._reactBridgeService.mount(
        ProductTourPopover,
        this._el.nativeElement,
        {
          currentStep: this.currentStep,
          anchorElement: item.el.nativeElement,
          tourData: this.data,
          currentStepIndex: this.currentStepIdx,
          next: this.next.bind(this),
          previous: this.previous.bind(this),
          closeTour: this.stepDone.bind(this),
        });
    }, 0);
  }

  dialogAnimationDone(e: AnimationEvent) {
    this.onDialogAnimationDone(e);
  }

  next() {
    if (this.currentStepIdx === this.data.steps.length - 1) {
      return;
    }
    const item = this._productTourService.getItem(this.currentStep.tourItem);
    item.setActive(false);
    this.currentStep = this.data.steps[this.currentStepIdx + 1];
    this.currentStepIdx = this.currentStepIdx + 1;
    this.execute();
  }

  previous() {
    if (this.currentStepIdx === 0) {
      return;
    }
    const item = this._productTourService.getItem(this.currentStep.tourItem);
    item.setActive(false)
    this.currentStep = this.data.steps[this.currentStepIdx - 1];
    this.currentStepIdx = this.currentStepIdx - 1;
    this.execute();
  }

  stepDone() {
    this._reactBridgeService.unmount();
    this._productTourService.getItem(this.currentStep.tourItem).setActive(false);
    this.hide();
  }

  ngOnDestroy() {
    if (this.currentStep) {
      this._productTourService.getItem(this.currentStep.tourItem).setActive(false);
    }

    this._destroyed$.next();
    this._destroyed$.complete();
    super.ngOnDestroy();
    this._creationTrackingAngularticsService.endSession();
  }
}
