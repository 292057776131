import { ActionKind, ReducerAction, ReducerState } from './model';

export const reducer = (state: ReducerState, action: ReducerAction) => {
  const { type, data } = action;
  switch (type) {
    case ActionKind.FETCH_FILTERS_OPTIONS:
      return {
        ...state,
        loadingFilterOptions: true
      };

    case ActionKind.FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        listFilters: data.listFilters,
        loadingFilterOptions: false
      };

    case ActionKind.FETCH_ACTIVITY_LIST:
      return {
        ...state,
        listFetchError: null,
        fetchingList: true
      };

    case ActionKind.FETCH_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activities: data.activities,
        fetchingList: false
      };
    case ActionKind.FETCH_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        listFetchError: data.listFetchError,
        fetchingList: false
      };

    case ActionKind.SET_ACTIVITIES:
      return {
        ...state,
        activities: data.activities
      };

    case ActionKind.SET_FILTERS:
      return {
        ...state,
        listFilters: data.listFilters
      };

    default:
      return { ...state };
  }
};
