import { UsageStatus } from './models/usage-status';
import { ResourceState } from './interface';

export const DEFAULT_RESOURCE_STATE: ResourceState = {
  loading: false,
  data: null,
  error: null
};
export const PAYMENT_HISTORY_PAGE_LIMIT = 10;
export const BASE_EVENT_UNIT = 1000000;
export const USAGE_STATUS_ALERT_TYPES = {
  [UsageStatus.EARLY_CONSUMPTION]: 'warn',
  [UsageStatus.BASE_CONSUMPTION]: 'error',
  [UsageStatus.BUFFER_CONSUMPTION]: 'error',
  [UsageStatus.EARLY_ON_DEMAND_CREDIT_CONSUMPTION]: 'warn',
  [UsageStatus.NEAR_ON_DEMAND_CREDIT_CONSUMPTION]: 'error',
  [UsageStatus.ON_DEMAND_CREDIT_CONSUMPTION]: 'error',
  [UsageStatus.NEAR_ON_DEMAND_CONSUMPTION]: 'error',
  [UsageStatus.ON_DEMAND_CONSUMPTION]: 'error'
};
export const INITIAL_NAVIGATION_ROUTES = [ 'usage-summary' ];
export const DEFAULT_ON_DEMAND_EVENTS_UNIT_QUANTITY = 1;
export const PLAN_OWNER_GUARD_MSG = 'You need to have owner privileges to take this action';
export const FREE_SOURCES_DOC_LINK = '/sources/free-sources/';
export const TRACKER_FREE_SOURCES_LINK_CLICK = '[Plan Selection] free sources click';
export const TRACKER_SELECT_PLAN_BUTTON_CLICK = '[Plan Selection] select plan click';
export const TRACKER_CHOOSE_PLAN_CLICK = '[Setup Billing] Choose Plan Click';
export const TRACKER_GO_TO_PIPELINES_CLICK = '[Setup Billing] Go to Pipelines Click';
export const TRACKER_PRODUCT_USAGE_SUMMARY_DOWNLOAD_CLICK = '[Product Usage Summary] Download Click';
export const USER_ENTERED_CARD_DETAILS_KEY = 'CARD_DETAILS_ENTERED';
export const EVENTS_POPUP_TEXT = 'Each record that is either updated or inserted in the Destination' +
  ' (data warehouse, database, etc.) is counted as one Event.';
export const SOURCES_POPUP_TEXT = 'A Source is any database or application from where you want to ingest your data. '
  + 'You can create unlimited Pipelines using unlimited Sources.';
export const MODELS_POPUP_TEXT = 'A Model is an SQL transformation that runs on the data in your Destination at a specified schedule.';
export const AWS_MARKETPLACE__POPUP_TEXT = 'Manage Hevo subscription billing using AWS marketplace';
