import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const SHIPSTATION_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPIKeyField({
        hint: 'The API Key generated from My Profile → API Settings → API Keys.',
        externalDocLink: 'https://www.shipstation.com/docs/api/requirements/#authentication',
        widget: FieldTypes.TEXT_FIELD
      })
    }
  ],
  [
    {
      key: 'apiSecret',
      fieldId: 'api-secret',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'apiSecret', label: 'Private API key', required: true },
      hint: 'The API Secret Generated from My Profile → API Settings → API Keys.',
      externalDocLink: 'https://www.shipstation.com/docs/api/requirements/#authentication',
      buildParamsKey: 'api_secret',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
