import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SETUP_PERSONA_REQUIRED } from '../../../react/containers/auth/SetupPersona/constants';
import { LocalStorageService } from '../service/local-storage.service';
import { UserService } from '../service/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserPersonaGuard implements CanActivate {
  constructor(
    private _localStorageService: LocalStorageService,
    private _userService: UserService,
    private _router: Router,
  ) { }

  canActivate(): boolean {
    let canActivate = true;

    const user = this._userService.getUser();
    const forceUserPersona = this._localStorageService.get(SETUP_PERSONA_REQUIRED);

    if (user.role) {
      this._localStorageService.remove(SETUP_PERSONA_REQUIRED);
    } else if (forceUserPersona) {
      canActivate = false;
      this._router.navigate(['setup-persona']);
    }

    return canActivate;
  }
}
