import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RawPipeline } from '../../../react/containers/pipeline/model';
import { ExecutionPolicyDialog } from '../../../react/containers/scheduler/ExecutionPolicyDialog';
import { ExecutionPolicyDialogProps } from '../../../react/containers/scheduler/models';
import { Activation } from '../../activate/models/activation';
import { HevoEntity } from '../../core/models/hevo-entity';
import { Destination } from '../../destination/models';
import { Model } from '../../../react/containers/transform-model/models';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { Workflow } from '../../../react/containers/workflow/models/workflow';

@Component({
  selector: 'execution-policy-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class ExecutionPolicyDialogReactComponent implements OnChanges {
  @Input() entity: HevoEntity;
  @Input() open: boolean;
  @Input() entityDetails: Destination | Activation | Workflow | Model | RawPipeline;

  @Output() onClose = new EventEmitter<boolean>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this.renderComponent();
  }

  private renderComponent() {
    this._reactBridgeService.mount<ExecutionPolicyDialogProps>(
      ExecutionPolicyDialog,
      this._el.nativeElement,
      {
        entityDetails: this.entityDetails,
        open: this.open,
        onClose: this.onClose.emit.bind(this.onClose),
        entity: this.entity
      }
    );
  }
}
