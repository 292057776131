import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'progress-spinner-determinate',
  templateUrl: './progress-spinner-determinate.component.html',
  styleUrls: ['./progress-spinner-determinate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerDeterminateComponent {
  @Input() progress = 0;
  @Input() strokeWidth = 2;

  @Input() size = 20;

  constructor() {}

  getProgressDashArray(radius: number): string {
    const arcLength = this.calcArcLength(radius, 1);
    return `${arcLength} ${arcLength}`;
  }

  getProgressOffset(radius: number): number {
    return this.calcArcLength(radius, 1) - this.calcArcLength(radius, this.progress);
  }

  calcArcLength(radius: number, ratio: number) {
    return 2 * Math.PI * radius * ratio;
  }

  getViewBox() {
    const viewBoxSize: number = this.size + this.strokeWidth;
    return `0 0 ${viewBoxSize} ${viewBoxSize}`;
  }

  getCircleCenter() {
    return (this.size + this.strokeWidth) / 2;
  }

  getCircleRadius() {
    return this.size / 2;
  }

  getCheckPolyline() {
    const viewBoxLength = this.size + this.strokeWidth;
    return `M${.305 * viewBoxLength}, ${.49 * viewBoxLength} L${.445 * viewBoxLength},${.64 * viewBoxLength} L${.688643 * viewBoxLength}, ${.385188 * viewBoxLength}`;
  }
}
