import { Pipe, PipeTransform } from '@angular/core';
import { numFormatter } from '../../react/legacy-utils/number';
import { NumFormatterPipeOptions } from './interface';


@Pipe({
  name: 'numFormatter'
})
export class NumFormatterPipe implements PipeTransform {
  transform(num: any, args: NumFormatterPipeOptions = {}): any {
    return numFormatter(num, args.type, args.allowDecimalValues);
  }
}
