import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import {
  GoogleSheetFieldNames,
  GoogleSheetFormState,
  GoogleSheetsConfigPayload,
  GoogleSheetsConfigResponse
} from './models';
import { getSelectedSheetsConfigRawData, SelectedSheetsConfigFactory } from './util';

export class GoogleSheetsReactConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig() {
    return {
      [GoogleSheetFieldNames.REFRESH_SHEETS_EVERYDAY]: false,
      [GoogleSheetFieldNames.AUTO_SKIP_BLANK_HEADERS_ROWS]: false,
      [GoogleSheetFieldNames.CREATE_EVENT_TYPES_FROM_DIFFERENT_SHEETS]: false,
      [GoogleSheetFieldNames.CREATE_EVENT_TYPES_FROM_SPREADSHEETS]: false,
      [GoogleSheetFieldNames.HEADER_ROW_INDEX]: undefined,
      [GoogleSheetFieldNames.DATA_ROW_INDEX]: undefined,
      [GoogleSheetFieldNames.SHEET_WORKSHEETS_MAP]: {}
    };
  }

  buildConnectionParams(data: GoogleSheetFormState): GoogleSheetsConfigPayload {
    return {
      account_type: data[GoogleSheetFieldNames.AUTH_TYPE],
      refresh_sheets_everyday: data[GoogleSheetFieldNames.REFRESH_SHEETS_EVERYDAY],
      ...(data[GoogleSheetFieldNames.HEADER_ROW_INDEX] >= 1
        ? {
            header_row_index: data[GoogleSheetFieldNames.HEADER_ROW_INDEX] - 1,
            data_row_index: data[GoogleSheetFieldNames.HEADER_ROW_INDEX]
          }
        : {}),
      spreadsheets: getSelectedSheetsConfigRawData(
        data[GoogleSheetFieldNames.SPREAD_SHEETS],
        data[GoogleSheetFieldNames.SHEET_WORKSHEETS_MAP]
      )
    };
  }

  getConfigFromRawData(rawData: GoogleSheetsConfigResponse): GoogleSheetFormState {
    return {
      [GoogleSheetFieldNames.REFRESH_SHEETS_EVERYDAY]: rawData.refresh_sheets_everyday || false,
      ...(rawData?.header_row_index >= 0
        ? {
            [GoogleSheetFieldNames.HEADER_ROW_INDEX]: rawData.header_row_index + 1,
            [GoogleSheetFieldNames.DATA_ROW_INDEX]: rawData?.data_row_index
          }
        : {}),
      [GoogleSheetFieldNames.SPREAD_SHEETS]: SelectedSheetsConfigFactory(rawData.spreadsheets)
    };
  }
}
