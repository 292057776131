// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.item_U8rup {\n  display: flex;\n  align-items: center;\n}\n.item_U8rup .text_xaL_c {\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n}\n.item_U8rup .text_xaL_c label {\n  cursor: pointer;\n}\n.item_U8rup .text_xaL_c.disabled_eYttv, .item_U8rup .text_xaL_c.disabled_eYttv label {\n  cursor: not-allowed;\n}\n.item_U8rup.item_U8rup + .item_U8rup {\n  margin-top: 24px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "item_U8rup",
	"text": "text_xaL_c",
	"disabled": "disabled_eYttv"
};
export default ___CSS_LOADER_EXPORT___;
