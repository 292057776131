import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { WebStorageType } from '../models/web-storage';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { UserService } from './user.service';


export interface StorageSettingsInfo {
  currentUser?: boolean;
  userId?: number;
  webStorageType?: WebStorageType;
}

const LOCAL_STORAGE_USER_SETTINGS_KEY = 'settings';
const SESSION_STORAGE_USER_SETTINGS_KEY = 'user_settings';

export let _settingsStorageService;

@Injectable()
export class SettingsStorageService {
  localStorageSettings: { [key: string]: any } = {};
  sessionStorageSettings: { [key: string]: any } = {};
  user: User;

  constructor(
    private _localStorageService: LocalStorageService,
    private _sessionStorageService: SessionStorageService,
    private _userService: UserService
  ) {
    _settingsStorageService = this;
    this._fetchSettingsFromCache();
  }

  applySettings(key: string, value: any, storageSettingsInfo: StorageSettingsInfo = {
    currentUser: true,
    webStorageType: WebStorageType.LOCAL_STORAGE
  }) {
    const userId = this._getUserId(storageSettingsInfo);
    const userSettings = this._getUserSettings(userId, storageSettingsInfo.webStorageType);

    userSettings[key] = value;
    this._updateSettings(userId, userSettings, storageSettingsInfo.webStorageType);
    this._updateSettingsInCache(storageSettingsInfo.webStorageType);
  }

  getSettings(key: string, storageSettingsInfo: StorageSettingsInfo = {
    currentUser: true,
    webStorageType: WebStorageType.LOCAL_STORAGE
  }) {
    const userId = this._getUserId(storageSettingsInfo);
    const userSettings = this._getUserSettings(userId, storageSettingsInfo.webStorageType);
    return userSettings[key];
  }

  private _fetchSettingsFromCache() {
    try {
      this.localStorageSettings = JSON.parse(this._localStorageService.get(LOCAL_STORAGE_USER_SETTINGS_KEY)) || {};
    } catch (e) {
      this.localStorageSettings = {};
    }

    try {
      this.sessionStorageSettings = JSON.parse(this._sessionStorageService.get(SESSION_STORAGE_USER_SETTINGS_KEY)) || {};
    } catch (e) {
      this.sessionStorageSettings = {};
    }
  }

  private _getUserId(storageSettingsInfo: StorageSettingsInfo) {
    if (storageSettingsInfo.userId) {
      return storageSettingsInfo.userId;
    }

    if (storageSettingsInfo.currentUser) {
      const currentUser = this._userService.getUser();
      return currentUser?.id;
    }
  }

  private _getUserSettings(userId: number, webStorageType = WebStorageType.LOCAL_STORAGE) {
    return (webStorageType === WebStorageType.LOCAL_STORAGE
      ? this.localStorageSettings[userId]
      : this.sessionStorageSettings[userId])
      || {};
  }

  private _updateSettings(userId: number, userSettings, webStorageType = WebStorageType.LOCAL_STORAGE) {
    if (webStorageType === WebStorageType.LOCAL_STORAGE) {
      this.localStorageSettings[userId] = userSettings;
    }

    if (webStorageType === WebStorageType.SESSION_STORAGE) {
      this.sessionStorageSettings[userId] = userSettings;
    }
  }

  private _updateSettingsInCache(webStorageType = WebStorageType.LOCAL_STORAGE) {
    if (webStorageType === WebStorageType.LOCAL_STORAGE) {
      this._localStorageService.set(LOCAL_STORAGE_USER_SETTINGS_KEY, this.localStorageSettings);
    }

    if (webStorageType === WebStorageType.SESSION_STORAGE) {
      this._sessionStorageService.set(SESSION_STORAGE_USER_SETTINGS_KEY, this.sessionStorageSettings);
    }
  }
}
