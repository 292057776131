import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';

export function useDrawerRouting() {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const isDrawerOpen = !!searchParams.get('drawer');

  const navigateByPath = (path: string) => {
    const params = new URLSearchParams(location.search);

    if (!path) {
      params.delete('drawer');
    } else {
      params.set('drawer', path);
    }

    history.push(`${location.pathname}?${params.toString()}`);
  };

  return {
    isDrawerOpen,
    navigateByPath
  };
}
