import React from 'react';
import { marked } from 'marked';
import { sanitize } from 'dompurify';
import styles from './styles.module.scss';
import {
  HdTabAccordion,
  HdTabAccordionDetails,
  HdTabAccordionSummary
} from '../../../components/UIElements';
import HdTimeAgo from '../../../components/UIElements/HdTimeAgo';
import JsonEditor from '../../../components/CodeEditor/JsonEditor';

interface ActivityProps {
  message: string;
  author: { displayName: string };
  severity: { colorType: string };
  payload: string;
  activityTime: number;
}

interface ActivityLogListProps {
  dataIdGenerator: Function;
  activities: Array<ActivityProps>;
  loadingMore?: boolean;
}

export default function ActivityLogList({
  dataIdGenerator,
  activities,
  loadingMore
}: ActivityLogListProps) {
  const onAccordionChange = (event: React.ChangeEvent<HTMLInputElement>, expanded: boolean) => {
    const rootElement = event.target.closest('.activityList');
    if (rootElement) {
      if (expanded) {
        rootElement.classList.add(styles.expanded);
      } else {
        rootElement.classList.remove(styles.expanded);
      }
    }
  };

  return (
    <div
      className={`${styles.activityContainer} ${loadingMore ? styles.loadMoreBottomContainer : ''}`}
    >
      {activities.map((activity, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`activityList ${styles.activity} ${
            styles[activity.severity.colorType || 'default']
          }`}
        >
          <HdTabAccordion onChange={onAccordionChange}>
            <HdTabAccordionSummary
              className={styles.activityCard}
              aria-controls='activities'
              data-id={dataIdGenerator('panel-activities')}
            >
              <div
                className={styles.activityCardLeft}
                dangerouslySetInnerHTML={{ __html: sanitize(marked.parse(activity.message)) }}
              />

              <div className={styles.activityCardRight}>
                <div className={styles.activityCardWrapper}>
                  <div className={styles.activityCardWrapperTime}>
                    <HdTimeAgo timestamp={activity.activityTime} />
                  </div>

                  <div className={`${styles.activityCardWrapperAuthor} ml-1`}>
                    {activity.author.displayName ? `By ${activity.author.displayName}` : null}
                  </div>
                </div>
              </div>
            </HdTabAccordionSummary>

            <HdTabAccordionDetails className={`${styles.activity} ${styles.activityDetails}`}>
              <JsonEditor
                allowCopy
                code={JSON.stringify(JSON.parse(activity.payload), null, '\t')}
                dataId={dataIdGenerator('')}
                className={styles.activityJsonViewer}
              />
            </HdTabAccordionDetails>
          </HdTabAccordion>
        </div>
      ))}
    </div>
  );
}
