import { MEMBER_ROLES } from './constants';
import { Member, Role } from './models';
import { rolesToRolesMap } from './utils';

export const ROLES_PRIORITY_LIST = [
  'TEAM_ADMIN',
  'TEAM_COLLABORATOR',
  'BILLING_ADMIN',
  'PIPELINE_ADMIN',
  'PIPELINE_COLLABORATOR',
  'MODELS_AND_WORKFLOWS_ADMIN',
  'MODELS_AND_WORKFLOWS_COLLABORATOR',
  'ACTIVATION_ADMIN',
  'ACTIVATION_COLLABORATOR',
  'OBSERVER',
];

export function MembersFactory(rawData: any[], roles: Role[] = []) {
  if (!rawData?.length) {
    return [];
  }

  const rolesLookUp = rolesToRolesMap(roles);

  return rawData.map((item) => {
    return MemberFactory(item, rolesLookUp);
  }).sort((member1, member2) => {
    if (member1.isCurrentUser) {
      return -1;
    } else {
      return 0;
    }
  });
}

export function MemberFactory(rawData: any, rolesLookUp): Member {
  if (!rawData) {
    return;
  }

  return {
    ...rawData,
    memberRole: rawData.is_owner ? MEMBER_ROLES[0] : MEMBER_ROLES[1],
    displayName: rawData.display_name,
    imageURL: rawData.image_url,
    isCurrentUser: rawData.is_current_user,
    isTFAEnabled: rawData.is_tfa_enabled,
    rbacRoles: sortRoles((rawData.rbac?.roles || []).map(role => rolesLookUp[role.name]).filter(role => role)),
  };
}

export function RolesFactory(rawData: any[]) {
  if (!rawData?.length) {
    return [];
  }

  return rawData.map(item => {
    return RoleFactory(item);
  }).sort((r1, r2) => {
    const categoryComparission = r1.category.localeCompare(r2.category);
    if (categoryComparission !== 0) {
      return categoryComparission;
    }
    return ROLES_PRIORITY_LIST.indexOf(r1.roleName) - ROLES_PRIORITY_LIST.indexOf(r2.roleName);

  });
}

export function sortRoles(roles: Role[]): Role[] {
  return roles.sort((r1, r2) => {
    const categoryComparission = r1.category.localeCompare(r2.category);
    if (categoryComparission !== 0) {
      return categoryComparission;
    }
    return ROLES_PRIORITY_LIST.indexOf(r1.roleName) - ROLES_PRIORITY_LIST.indexOf(r2.roleName);

  });
}

export function RoleFactory(rawData: any): Role {
  if (!rawData) {
    return;
  }

  return {
    ...rawData,
    roleName: rawData.role_name,
  };
}
