import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import HdFormikTextField from '../../../../../components/FormikElements/HdFormikTextField';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';

export default function BlackHole() {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <HdFormControl>
            <Field
              name='queryLatency'
              label='Query latency'
              helperText='Mock latency to be added to the queries'
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>
      </Grid>
    </>
  );
}
