import { TemplatePortal } from '@angular/cdk/portal';

export enum ListRotateDir {
  TOP_TO_BOTTOM,
  BOTTOM_TO_TOP
}

export enum HelpTextState {
  LOADING,
  NO_MATCH,
  NO_OPTIONS,
  FOUND,
  CUSTOM_VALUE
}

export interface AutoCompleteOption {
  value: any;
  isActive: boolean;
  isDisabled: boolean;
  portal?: TemplatePortal;
}

export interface AutoCompleteOptionGroup {
  value: any;
  isActive: boolean;
  children: AutoCompleteOption[];
}
