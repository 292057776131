// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.headerSection__hLaJ {\n  line-height: 18px;\n  width: 100%;\n}\n\n.productLinks__ybBc {\n  display: flex;\n  flex-wrap: wrap;\n  padding-bottom: 12px;\n  padding-top: 8px;\n  margin-top: 20px;\n}\n.productLinks__ybBc .productLinkBtn__4M9T {\n  margin-top: 4px;\n  padding: 8px 12px;\n}\n.productLinks__ybBc .productLinkBtn__4M9T:not(:last-child) {\n  margin-right: 4px;\n}\n\n.entityStats__GhrM {\n  padding: 12px;\n  background: var(--surface-bg-alternate-color);\n  border-radius: 6px;\n  height: 42px;\n}\n.entityStats__GhrM:not(:last-child) {\n  margin-right: 12px;\n}\n.entityStats__GhrM .statsCounter_HhlBt {\n  text-align: end;\n}\n.entityStats__GhrM .statsLoadingShimmer_aHK_P {\n  height: 12px;\n  width: 30px;\n  display: inline-flex;\n  border-radius: 6px;\n  margin-top: 4px;\n}\n\n.docLinkUlStyle_Yba_t {\n  list-style-type: none;\n  padding-inline-start: 0;\n}\n\n.docLinkContainer_aph9z {\n  margin-left: 0 !important;\n  margin-right: 0 !important;\n}\n.docLinkContainer_aph9z .docLink__seEc {\n  margin-right: 8px;\n  margin-top: 16px;\n  color: var(--text-default-color);\n  font-weight: 400;\n  align-items: flex-start;\n}\n.docLinkContainer_aph9z .docLink__seEc:hover {\n  color: var(--text-primary-color);\n}\n.docLinkContainer_aph9z .docLink__seEc .icon_gt2FM {\n  color: var(--text-primary-color);\n  margin-top: 2px;\n}\n@media screen and (max-width: 862px) {\n  .docLinkContainer_aph9z .docLink__seEc {\n    width: 100%;\n  }\n}\n\n.selectSourceExcerptWrapper_Qc43h {\n  padding: 16px;\n}\n\n.setupGuideContainer__qhH3 {\n  height: inherit;\n}\n.setupGuideContainer__qhH3 .sbsContainer_NOvWg {\n  background: transparent !important;\n  border-left: none !important;\n}\n\n.rbacWarningPane_JL81q {\n  border-radius: 0 !important;\n  margin: -20px -20px 20px;\n  height: 36px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerSection": "headerSection__hLaJ",
	"productLinks": "productLinks__ybBc",
	"productLinkBtn": "productLinkBtn__4M9T",
	"entityStats": "entityStats__GhrM",
	"statsCounter": "statsCounter_HhlBt",
	"statsLoadingShimmer": "statsLoadingShimmer_aHK_P",
	"docLinkUlStyle": "docLinkUlStyle_Yba_t",
	"docLinkContainer": "docLinkContainer_aph9z",
	"docLink": "docLink__seEc",
	"icon": "icon_gt2FM",
	"selectSourceExcerptWrapper": "selectSourceExcerptWrapper_Qc43h",
	"setupGuideContainer": "setupGuideContainer__qhH3",
	"sbsContainer": "sbsContainer_NOvWg",
	"rbacWarningPane": "rbacWarningPane_JL81q"
};
export default ___CSS_LOADER_EXPORT___;
