export class WebhookFailureRecord {
  eventNamePath: string;
  eventPropertiesPath: string;
  failureMessage: string;
  failureMessageHash: string;
  payload: string;
  occurredTs: Date;
  isResolved: boolean;
}

export function WebHookFailureRecordsFactory(rawData: any[]) {
  let i = 0;

  for (i = 0; i < rawData.length; i++) {
    rawData[i] = WebhookFailureRecordFactory(rawData[i]);
  }

  return rawData;
}

function WebhookFailureRecordFactory(rawDataRecord: any) {
  const failureRecord = new WebhookFailureRecord();

  failureRecord.eventNamePath = rawDataRecord.event_name_path;
  failureRecord.eventPropertiesPath = rawDataRecord.event_properties_path;
  failureRecord.occurredTs = rawDataRecord.occurred_ts;
  failureRecord.payload = rawDataRecord.payload;
  failureRecord.failureMessage = rawDataRecord.message;
  failureRecord.failureMessageHash = rawDataRecord.failure_message_hash;
  failureRecord.isResolved = false;

  return failureRecord;
}
