import { NotificationsListType } from './models';
import { NotificationAPI } from './notificationsAPI';
import { NotificationsList } from './NotificationsList';

export function EnterpriseNotificationsList() {
  return (
    <NotificationsList
      dataSource={(payload, options) =>
        NotificationAPI.getEnterpriseNotifications(payload, options)
      }
      entity={NotificationsListType.ENTERPRISE}
    />
  );
}
