import React from 'react';
import { BulkManualUpdate } from './BulkManualUpdate';
import { ListHeader } from './ListHeader';
import { PipelineListItem } from './PipelineListItem';
import styles from './styles.module.scss';

interface PipelineListTableProps {
  pipelines: Array<any>;
  listItems: Array<any>;
  onPipelineSelect: (seqId: number) => void;
  selectedPipelines: Array<any>;
  selectAll: (checked: boolean) => void;
  updateSyncFrequency: Function;
  isListItemSelected: (seqId: number) => boolean;
  selectedCount: number;
  scrollRefs: { rootRef: any; sentryRef: any; bodyRef: any };
}

export function PipelineListTable({
  pipelines,
  listItems,
  onPipelineSelect,
  selectedPipelines,
  selectAll,
  updateSyncFrequency,
  isListItemSelected,
  selectedCount,
  scrollRefs
}: PipelineListTableProps) {
  const { rootRef, sentryRef, bodyRef } = scrollRefs;

  return (
    <>
      <div className={styles.listItem}>
        <ListHeader
          selectAll={selectAll}
          selectedCount={selectedCount}
          totalCount={pipelines.length}
        />
      </div>

      {selectedPipelines.length ? (
        <BulkManualUpdate
          selectedCount={selectedPipelines.length}
          updateSyncFrequency={frequency => updateSyncFrequency(frequency, selectedPipelines)}
        />
      ) : null}

      <div ref={rootRef} className={styles.listItemContainer}>
        <div ref={bodyRef}>
          {listItems.map((pipeline, index) => (
            <>
              <PipelineListItem
                key={pipeline.seq_id}
                pipeline={pipeline}
                onSelect={onPipelineSelect}
                isSelected={isListItemSelected(pipeline.seq_id)}
                updateSyncFrequency={updateSyncFrequency}
              />

              {listItems.length === index + 1 ? <div ref={sentryRef} /> : null}
            </>
          ))}
        </div>
      </div>
    </>
  );
}
