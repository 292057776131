import { useHistory } from '@useHistory/useHistoryCompat';
import AuthAPI from './AuthAPI';

export function useVerifyEmail() {
  const history = useHistory();

  const verifyEmail = () => {
    const searchParams = new URLSearchParams(history.location?.search);

    const code = searchParams.get('code');
    if (!code) {
      history.replace('/login');
      return;
    }

    return AuthAPI.verifyEmail(code).then(
      res => {
        return res?.data?.email;
      },
      () => {
        history.replace('/login');
        return null;
      }
    );
  };

  return {
    verifyEmail
  };
}
