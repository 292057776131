import React, { forwardRef, HTMLAttributes } from 'react';
import HdIcon from '../UIElements/HdIcon';

export interface RoundedIconProps {
  dataId?: string;
  iconName: string;
  containerBg?: string;
  containerSize?: number;
  isIconColorWhite?: boolean;
  hasBg?: boolean;
  isRounded?: boolean;
  hasBorder?: boolean;
  showSpinningAnimation?: boolean;
  iconClasses?: string;
  className?: string;
  isSquareRounded?: boolean;
}

const RoundedIcon = forwardRef<any, RoundedIconProps & HTMLAttributes<HTMLSpanElement>>(
  (
    {
      dataId = '',
      iconName,
      containerBg = 'success',
      containerSize = 1,
      isIconColorWhite = true,
      hasBg = true,
      isRounded = true,
      isSquareRounded = false,
      hasBorder = false,
      showSpinningAnimation = false,
      iconClasses = '',
      className,
      ...htmlAttributes
    },
    ref
  ) => {
    let classes = `hd-rounded-icon-container container-size-${containerSize}`;

    if (hasBg) {
      classes += ` bg-${containerBg}`;
    }

    if (isIconColorWhite) {
      classes += ' with-white-icon';
    }

    if (isRounded) {
      classes += ' with-container-rounded';
    }

    if (isSquareRounded) {
      classes += ' with-container-square-rounded';
    }

    if (hasBorder) {
      classes += ' with-container-border';
    }

    if (showSpinningAnimation) {
      classes += ' with-icon-spinning';
    }

    if (className) {
      classes += ` ${className}`;
    }

    return (
      <span className={classes} ref={ref} {...htmlAttributes}>
        <HdIcon name={iconName} className={iconClasses} />
      </span>
    );
  }
);

export default RoundedIcon;
