// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.wrapper_vhu5h {\n  display: block;\n  overflow: hidden;\n  border: solid 1px var(--divider-color);\n  border-radius: 6px;\n}\n.wrapper_vhu5h.noBorder_NWoGj {\n  border: 0;\n}\n.wrapper_vhu5h.error_XSBYw {\n  border: solid 1px var(--error-color);\n}\n.wrapper_vhu5h th {\n  display: flex;\n  align-items: center;\n  height: 48px;\n}\n.wrapper_vhu5h .listWrapper_eqU31 {\n  max-height: 300px;\n  display: block;\n  overflow: auto;\n  height: 100%;\n}\n\n.noBorder_NWoGj {\n  border: 0 !important;\n}\n\n.groupListWrapper_uZF4q {\n  border: solid 1px var(--divider-color);\n  border-radius: 6px;\n}\n\n.childWrapper_BW_oN {\n  display: block;\n  overflow: scroll;\n  max-height: 180px;\n}\n\n.noBackground_w9wjQ {\n  background: transparent !important;\n}\n\n.groupHeader__zlZF {\n  border-top: solid 1px var(--divider-color);\n}\n.groupHeader__zlZF:first-child {\n  border-top: 0;\n}\n.groupHeader__zlZF td {\n  height: 40px !important;\n}\n\n.groupItems_VUTxA td {\n  padding-left: 42px !important;\n  height: 34px !important;\n}\n\n.loader_VjE3E {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: fit-content;\n  min-width: fit-content;\n}\n.loader_VjE3E .icon_KV6VC {\n  animation: spin_ju5LO 2s linear infinite;\n}\n@keyframes spin_ju5LO {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.virtualizedContainer_exrHl {\n  max-height: 250px;\n}\n.virtualizedContainer_exrHl .virtualizedWrapper_sjBM0 .virtualizedRow_biMy1.odd__fiEE {\n  background-color: var(--surface-bg-alternate-color);\n}\n.virtualizedContainer_exrHl .virtualizedWrapper_sjBM0 .virtualizedRow_biMy1 {\n  padding: 0 20px;\n  height: 56px;\n  border: none;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_vhu5h",
	"noBorder": "noBorder_NWoGj",
	"error": "error_XSBYw",
	"listWrapper": "listWrapper_eqU31",
	"groupListWrapper": "groupListWrapper_uZF4q",
	"childWrapper": "childWrapper_BW_oN",
	"noBackground": "noBackground_w9wjQ",
	"groupHeader": "groupHeader__zlZF",
	"groupItems": "groupItems_VUTxA",
	"loader": "loader_VjE3E",
	"icon": "icon_KV6VC",
	"spin": "spin_ju5LO",
	"virtualizedContainer": "virtualizedContainer_exrHl",
	"virtualizedWrapper": "virtualizedWrapper_sjBM0",
	"virtualizedRow": "virtualizedRow_biMy1",
	"odd": "odd__fiEE"
};
export default ___CSS_LOADER_EXPORT___;
