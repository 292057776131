import React, { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import useToaster from '../../../hooks/useToaster';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdIcon from '../HdIcon';
import { HdIconButton } from '../HdIconButton';

export interface HdCopyIconProps {
  copyText: string;
  toastMessage: string;
  dataId: string;
  className?: string;
  iconSize?: number;
}

export const getHdCopyIconDataIdGenerator = (dataId: string) => {
  return getDataIdGenerator(dataId, 'copy');
};

export default function HdCopyIcon({
  copyText,
  toastMessage,
  dataId,
  className = '',
  iconSize = 2
}: HdCopyIconProps) {
  const toasterService = useToaster();

  const dataIdGenerator = useCallback(getHdCopyIconDataIdGenerator(dataId), [dataId]);

  return (
    <HdIconButton
      dataId={dataIdGenerator('')}
      className={className}
      onClick={() => {
        copy(copyText);

        if (toastMessage) {
          toasterService.pop('success', undefined, toastMessage);
        }
      }}
    >
      <HdIcon name='copy' dataId={dataIdGenerator('copyIcon')} size={iconSize} />
    </HdIconButton>
  );
}
