import { useEffect, useReducer, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { FILTER_ALL } from '../../../app/filter/constants';
import { Filter } from '../../../app/filter/models/filter';
import { FilterOption } from '../../../app/filter/models/filter-option';
import ActivityLogAPI from './ActivityLogAPI';
import { reducer } from './ActivityLogReducer';
import { ACTIVITY_PAGE_LIMIT, DEFAULT_SEVERITY_NAME } from './constants';
import { ActivityFilterOptionsFactory, ActivityListFactory } from './factory';
import { ActionKind, ActivityFilterOptions } from './model';

export function useActivityLog({ loadFilters, entityFilters }) {
  const [ pageEndReached, setPageEndReached ] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    activities: [],
    listFilters: [],
    fetchingList: false,
    loadingFilterOptions: false,
    listFetchError: null
  });

  const [sentryRef] = useInfiniteScroll({
    loading: state.fetchingList,
    hasNextPage: !pageEndReached,
    onLoadMore: () => {
      if (!loadFilters || state.listFilters.length) {
        getActivities();
      }
    },
    disabled: !!state.listFetchError,
    rootMargin: '0px 0px 400px 0px'
  });

  useEffect(() => {
    if (loadFilters) {
      getFilterOptions();
    } else {
      getActivities(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.listFilters.length) {
      getActivities(true);
    }
  }, [state.listFilters]);

  const getFilterOptions = async () => {
    dispatch({ type: ActionKind.FETCH_FILTERS_OPTIONS });
    try {
      const { data } = await ActivityLogAPI.getFilterOptions();
      createFilterOptions(ActivityFilterOptionsFactory(data));
    } catch (error) {
      // do nothing
    }
  };

  const createFilterOptions = (filterOptions: ActivityFilterOptions) => {
    const listFilters: Filter<any>[] = [];

    const authorsFilter = new Filter<string>(
      'action_by',
      filterOptions.accounts,
      FILTER_ALL,
      true
    );
    listFilters.push(authorsFilter);

    const severityFilter = new Filter<string>(
      'severity_level',
      filterOptions.severities,
      filterOptions.severities.find(
        (severity: FilterOption<any>) => severity.name === DEFAULT_SEVERITY_NAME
      ),
      false
    );
    listFilters.push(severityFilter);

    dispatch({
      type: ActionKind.FETCH_FILTERS_SUCCESS,
      data: { listFilters }
    });
  };

  const getRawFilterData = () => {
    const options: any = {};
    const { listFilters } = state;

    listFilters.forEach(filter => {
      if (filter.value && filter.value.value !== FILTER_ALL.value) {
        if (filter.name === 'action_by') {
          options[filter.name] = [filter.value.value];
        } else {
          options[filter.name] = filter.value.value;
        }
      }
    });

    if (entityFilters) {
      return Object.assign(options, entityFilters);
    }

    return options;
  };

  const getActivities = async (clear = false) => {
    if (state.fetchingList === true) {
      return;
    }

    if (clear) {
      dispatch({ type: ActionKind.SET_ACTIVITIES, data: { activities: [] } });
    } else if (pageEndReached) {
      return;
    }

    dispatch({ type: ActionKind.FETCH_ACTIVITY_LIST });

    try {
      const activitiesLength = state.activities.length;

      const { data } = await ActivityLogAPI.getActivities(getRawFilterData(), {
        pageSize: ACTIVITY_PAGE_LIMIT,
        start: clear ? 0 : activitiesLength
      });

      const res = ActivityListFactory(data);

      updatePageEndReachedStatus(res.length);

      dispatch({
        type: ActionKind.FETCH_ACTIVITY_LIST_SUCCESS,
        data: {
          activities: clear ? res : [...state.activities, ...res]
        }
      });
    } catch (error) {
      dispatch({
        type: ActionKind.FETCH_ACTIVITY_LIST_FAILURE,
        data: {
          listFetchError: error
        }
      });
    }
  };

  const getAppliedFilters = (): Filter<any>[] => state.listFilters.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag);

  const resetFilter = (filterToBeRemovedIndex: number) => {
    const updatedFilter = [...state.listFilters];
    updatedFilter[filterToBeRemovedIndex].reset();

    dispatch({
      type: ActionKind.SET_FILTERS,
      data: {
        listFilters: updatedFilter
      }
    });
  };

  const updateFilter = (selectedFilterIndex, selectedValue) => {
    const updatedFilter = [...state.listFilters];
    updatedFilter[selectedFilterIndex].activate(selectedValue);

    dispatch({
      type: ActionKind.SET_FILTERS,
      data: {
        listFilters: updatedFilter
      }
    });
  };

  const updatePageEndReachedStatus = count => {
    const existingCount = state.activities.length;
    if ((count && count < ACTIVITY_PAGE_LIMIT) || (existingCount && !count)) {
      setPageEndReached(true);
      return;
    }
    setPageEndReached(false);
  };

  const refresh = () => {
    getActivities(true);
  };

  const loadingMore = !!(state.fetchingList || !pageEndReached);
  const showActivities = !state.listFetchError && state.activities.length > 0;
  const showSkeleton =
    state.loadingFilterOptions ||
    (!state.listFetchError && state.activities.length === 0 && state.fetchingList);
  const noActivitiesFound =
    !state.listFetchError &&
    !state.fetchingList &&
    state.listFilters.length &&
    state.activities.length === 0;

  return {
    state,
    loadingMore,
    showActivities,
    showSkeleton,
    noActivitiesFound,
    pageEndReached,
    sentryRef,
    getActivities,
    getAppliedFilters,
    resetFilter,
    updateFilter,
    refresh
  };
}
