import { animate, state, style, transition, trigger } from '@angular/animations';
import { ListKeyManagerOption } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NestedKeyboardNavigationItem } from '../../a11y/nested-keyboard-navigation/nested-keyboard-navigation-item';

@Component({
  selector: 'hd-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed',
        animate('200ms ease-in')),
    ])
  ],
  providers: [{ provide: NestedKeyboardNavigationItem, useExisting: AccordionItem }]
})
export class AccordionItem implements OnInit, ListKeyManagerOption {
  @Input() title = "";
  @Input() disabled = false;

  @Output() keyboardFocus = new EventEmitter<void>();

  @ViewChild('accordionHeader') accordionHeader: ElementRef;

  value: any;

  private _isFocused = false;
  private _isExpanded = false;

  constructor(
    private _el: ElementRef<HTMLElement>
  ) {
  }

  ngOnInit() {
    this.value = this.title;
  }

  isParent() {
    return true;
  }

  onEnter(e: KeyboardEvent) {
    e.stopPropagation();
    this.toggle();
  }

  onClickHeaderElement(event: any) {
    event.stopPropagation();
    this.toggle();
  }

  isExpanded() {
    return this._isExpanded;
  }

  isFocused() {
    return this._isFocused;
  }

  toggle() {
    this._isExpanded = !this._isExpanded;
  }

  expand() {
    this._isExpanded = true;
  }

  collapse() {
    this._isExpanded = false;
  }

  onFocus() {
    this._isFocused = true;
    this.keyboardFocus.emit();
  }

  onUnFocus() {
    this._isFocused = false;
  }

  getElementTop() {
    return this._el.nativeElement.offsetTop;
  }

  getHeight() {
    return this.accordionHeader.nativeElement.offsetHeight;
  }
}
