import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface HdIndeterminateProgressBarProps {
  color?: string;
  reverseBg?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export default function HdIndeterminateProgressBar({
  color,
  reverseBg,
  children,
  className = ''
}: HdIndeterminateProgressBarProps) {
  return (
    <div className={clsx(styles[color], reverseBg ? styles.reverseBg : '', className)}>
      <div className='text-center text-medium'>
        <div className={styles.progressBar} />
        <br />
        <div className={styles.extra}>{children}</div>
      </div>
    </div>
  );
}
