import React, { useEffect, useState } from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import {
  EDIT_DESTINATION_SEQ_ID_QUERY_PARAM_TRIGGER,
  EDIT_DESTINATION_QUERY_PARAM_TRIGGER
} from '../../../../../app/nodes/constants';
import DestinationsAPI from '../../DestinationsAPI';

export function useDestinationEditDialog({ handleDialog }: { handleDialog?: boolean }) {
  const [destinationData, setDestinationData] = useState(null);
  const [editDestinationModalConfig, setEditDestinationModalConfig] = useState<{
    visible: boolean;
    destinationSeqId?: number;
  }>({
    visible: false
  });

  const history = useHistory();

  const updateConfig = () => {
    const search = new URLSearchParams(history.location.search);
    const seqID = search.get(EDIT_DESTINATION_SEQ_ID_QUERY_PARAM_TRIGGER);
    const id = search.get(EDIT_DESTINATION_QUERY_PARAM_TRIGGER);

    if (seqID) {
      setEditDestinationModalConfig({
        visible: true,
        destinationSeqId: parseInt(seqID, 10)
      });
    } else if (id) {
      DestinationsAPI.getDestinationByIdWithoutExtras(parseInt(id, 10)).then(
        res => {
          const newSearch = new URLSearchParams(history.location.search);
          newSearch.set(EDIT_DESTINATION_SEQ_ID_QUERY_PARAM_TRIGGER, res.data.seq_id);
          newSearch.delete(EDIT_DESTINATION_QUERY_PARAM_TRIGGER);

          history.replace({
            search: newSearch.toString()
          });
        },
        () => {}
      );
    } else {
      setEditDestinationModalConfig(s => {
        return {
          ...s,
          visible: false
        };
      });
    }
  };

  useEffect(() => {
    if (!handleDialog) {
      return;
    }

    const unlisten = history.listen(() => {
      updateConfig();
    });

    updateConfig();

    return () => {
      unlisten();
    };
  }, []);

  const navigate = (seqId: number) => {
    const search = new URLSearchParams(history.location.search);
    if (seqId) {
      search.set(EDIT_DESTINATION_SEQ_ID_QUERY_PARAM_TRIGGER, `${seqId}`);
    } else {
      search.delete(EDIT_DESTINATION_SEQ_ID_QUERY_PARAM_TRIGGER);
      setDestinationData(null);
    }

    history.push({ search: search.toString() });
  };

  return {
    destinationData,
    setDestinationData,
    editDestinationModalConfig,
    navigate
  };
}
