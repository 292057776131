import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { ConfirmDialogV1, ConfirmDialogV1Config } from './ConfirmDialogV1';

const ConfirmDialogContext = createContext<{
  confirm?: (data: ConfirmDialogV1Config) => Promise<boolean>;
}>({});

export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState<any>({ open: false });
  const fn = useRef<Function>();

  const confirm = useCallback(
    (data: ConfirmDialogV1Config) =>
      new Promise<boolean>(resolve => {
        setState({ ...data, open: true });
        fn.current = (choice: boolean) => {
          resolve(choice);
          setState({ open: false });
        };
      }),
    [setState]
  );

  const { open, ...others } = state;

  const memoizedConfirm = useMemo(() => ({ confirm }), [confirm]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ConfirmDialogContext.Provider value={memoizedConfirm}>
      {children}

      <ConfirmDialogV1
        {...others}
        open={open}
        onClose={() => fn.current(false)}
        onPositiveClick={() => fn.current(true)}
      />
    </ConfirmDialogContext.Provider>
  );
}

export function useConfirm() {
  return useContext(ConfirmDialogContext);
}
