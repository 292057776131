import React from 'react';
import { TroubleshootGuideService } from '../../../../../app/core/service/troubleshoot-guide.service';
import useHasPermission from '../../../../hooks/useHasPermission';
import styles from './styles.module.scss';
import useService from '../../../../hooks/useService';
import HelpBanner from '../../../HelpBanner';
import HelpBannerBody from '../../../HelpBanner/HelpBannerBody';
import HelpBannerTitle from '../../../HelpBanner/HelpBannerTitle';
import { HdLink } from '../../../UIElements';

export function SSHConfigHelpBanner({
  destinationTypeDisplayName,
  defaultExpanded,
  onInviteMember
}) {
  const troubleshootLink = '/getting-started/connection-options/connecting-through-ssh/';
  const troubleshootService = useService(TroubleshootGuideService);
  const { isRbacEnabled } = useHasPermission();

  const onTriggerTroubleshoot = () => {
    troubleshootService.showTroubleshootGuide(troubleshootLink);
  };

  return (
    <HelpBanner defaultExpanded={defaultExpanded}>
      <HelpBannerTitle>
        Please verify if you’ve completed the Prerequisites for {destinationTypeDisplayName} setup.
      </HelpBannerTitle>

      <HelpBannerBody>
        <div className='banner pt-4 pr-4'>
          <ul className={`mb-0 ${styles.helpItemList}`}>
            <li className='mb-4'>
              <p>
                Verify that <strong>SSH IP, Port, User </strong>entered in the
                Configure your Destination page is correct
              </p>
            </li>

            {!isRbacEnabled ? (
              <li className='mb-4'>
                <p>
                  <HdLink
                    onClick={onInviteMember}
                    dataId='experiment-invite-member'>
                    Invite a Team Member
                  </HdLink>{' '}
                  with the required privileges to setup the pipeline
                </p>
              </li>
            ) : null}

            <li>
              <p>
                Please read{' '}
                <HdLink
                  onClick={ onTriggerTroubleshoot }
                  dataId='experiment-ssh-troubleshoot'>
                  <strong>Connecting Through SSH </strong>
                </HdLink>{' '}
                which allows an additional level of security to your database by not exposing your{' '}
                {destinationTypeDisplayName} setup to the public
              </p>
            </li>
          </ul>
        </div>
      </HelpBannerBody>
    </HelpBanner>
  );
}
