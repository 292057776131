<button
  type="button"
  hd-icon-btn
  (onMenuOpen)="onMenuOpen.emit()"
  [hdMenuTrigger]="(grouped && filterType === filterTypesEnum.MULTI_FILTER) ? multiOptionsMenu : optionsMenu"
  #menuTrigger="hdMenuTrigger">
  <hd-icon
    name="filter"
    class="filter-icon"
    [class.active]="hasActiveOptions()">
  </hd-icon>
</button>

<hd-menu
  #optionsMenu>
  <ng-container *ngFor="let optionGroup of allOptions; index as groupIndex">
    <div *ngIf="!separator" class="px-3 py-1 text-secondary text-caption-2">{{ optionGroup.name }}</div>
    <hd-menu-item
      [class.warning]="option.warning"
      [withSeparator]="groupIndex > 0 && optionIndex === 0 && separator"
      *ngFor="let option of optionGroup.options; index as optionIndex"
      [active]="isOptionActive(optionGroup, option)"
      (select)="onFilterClick(optionGroup, option)">
      {{ option.name }}
    </hd-menu-item>
  </ng-container>
</hd-menu>

<hd-menu
  #multiOptionsMenu>
  <div [class.multi-dropdown-filter]="allOptions.length > 1">
    <ng-container *ngFor="let optionGroup of allOptions; index as groupIndex">
      <div class="dropdown">
        <div class="px-3 py-1 text-secondary text-caption-2">{{ optionGroup.name }}</div>
        <hd-menu-item
          [withSeparator]="groupIndex > 0 && optionIndex === 0"
          *ngFor="let option of optionGroup.options; index as optionIndex"
          [active]="isOptionActive(optionGroup, option)"
          (select)="onFilterClick(optionGroup, option)">
          {{ option.name }}
        </hd-menu-item>
      </div>
    </ng-container>
  </div>
</hd-menu>
