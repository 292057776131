import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { HdMenuTriggerDirective } from '../../menu/hd-menu/hd-menu-trigger.directive';
import { FILTER_ALL } from '../constants';
import { FilterOption } from '../models/filter-option';
import { FilterOptionGroup } from '../models/filter-option-group';
import { FilterTypeView } from '../models/filter-type-view';
import { DEFAULT_GROUP_KEY, DEFAULT_GROUP_NAME, FILTER_ALL_KEY } from './constants';


@Component({
  selector: 'list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: [ './list-filter.component.scss' ]
})
export class ListFilterComponent implements OnChanges {
  allOptions: FilterOptionGroup[];
  selectedOptions: { [key: string]: any };

  filterAll = FILTER_ALL;
  filterAllKey = FILTER_ALL_KEY;
  defaultGroupName = DEFAULT_GROUP_NAME;
  defaultGroupKey = DEFAULT_GROUP_KEY;
  filterTypesEnum = FilterTypeView;

  @Input() options: FilterOption<any>[];
  @Input() optionGroups: FilterOptionGroup[];
  @Input() grouped = false;
  @Input() filterType = FilterTypeView.GROUP;
  @Input() isMultiFilterOption = false;
  @Input() separator = true;
  @Input() activeOption: any;
  @Input() activeOptions: { [key: string]: any };
  @Input() renderAll = true;

  @Output() select = new EventEmitter<any>();
  @Output() onMenuOpen = new EventEmitter<any>();

  @ViewChild('menuTrigger', { static: true }) menuTrigger: HdMenuTriggerDirective;

  constructor() { }

  ngOnChanges(changes) {
    if (changes.grouped || changes.options || changes.renderAll || changes.optionGroups) {
      this.buildOptionGroups();
    }

    if (changes.activeOption || changes.activeOptions) {
      this.buildSelectedOptions();
    }
  }

  onFilterClick(optionGroup: FilterOptionGroup, option: FilterOption<any>) {
    if (this.grouped && this.filterType === FilterTypeView.GROUP) {
      this.select.emit(
        this.optionGroups.reduce((dict, group) => {
          return {
            ...dict,
            [group.key]: optionGroup.key === this.filterAllKey || optionGroup.key !== group.key
              ? undefined
              : option.value
          };
        }, {})
      );
    } else if (this.grouped && this.filterType === FilterTypeView.MULTI_FILTER) {
      const filterOptions = this.optionGroups.reduce((dict, group) => {
        let newVal = dict[group.key];
        if (group.key === optionGroup.key && option.value === this.filterAll.value) {
          newVal = undefined;
        } else if (group.key === optionGroup.key && option.value !== this.filterAll.value) {
          newVal = option.value;
        }

        return {
          ...dict,
          [group.key]: newVal
        };
      }, this.activeOptions);

      this.select.emit(filterOptions);
    } else {
      this.select.emit(option.value === this.filterAll.value ? undefined : option.value);
    }
  }

  buildOptionGroups() {
    if (this.grouped) {
      if (!this.optionGroups || !this.optionGroups.length) {
        this.allOptions = [];
        return;
      }

      this.allOptions = this.optionGroups.map((group) => {
        return {
          ...group,
          options: this.buildOptions(group.options, group.renderAll)
        };
      });

      if (this.renderAll) {
        this.allOptions = [
          {
            name: 'Select All',
            key: this.filterAllKey,
            options: [ this.filterAll ]
          },
          ...this.allOptions
        ];
      }
    } else {
      if (!this.options || !this.options.length) {
        this.allOptions = [];
        return;
      }

      this.allOptions = [{
        name: this.defaultGroupName,
        renderAll: this.renderAll,
        key: this.defaultGroupKey,
        options: this.buildOptions(this.options, this.renderAll)
      }];
    }
  }

  buildOptions(options, renderAll) {
    return renderAll ?  [ this.filterAll, ...options ] : [ ...options ];
  }

  buildSelectedOptions() {
    if (this.grouped) {
      this.selectedOptions = { ...this.activeOptions };
    } else {
      this.selectedOptions = { [this.defaultGroupKey]: this.activeOption };
    }
  }

  hasActiveOptions() {
    if (!this.selectedOptions) {
      return;
    }

    return Object.keys(this.selectedOptions).some((key) => this.selectedOptions[key] && this.selectedOptions[key] !== this.filterAll.value);
  }

  isOptionActive(optionGroup, option) {
    if (this.grouped && optionGroup.key === this.filterAllKey && option.value === this.filterAll.value) {
      return !this.selectedOptions || Object.values(this.selectedOptions).every((value) => !value);
    } else {
      if (!this.selectedOptions || !this.selectedOptions[optionGroup.key]) {
        return option.value === this.filterAll.value;
      }

      return option.value === this.selectedOptions[optionGroup.key];
    }
  }
}
