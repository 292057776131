import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import HdFormikTextField from '../../../../../components/FormikElements/HdFormikTextField';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import HdFormikPasswordField from '../../../../../components/FormikElements/HdFormikPasswordField';
import HdFormikDropDown from '../../../../../components/FormikElements/HdFormikDropDown';
import { ConfigByType } from '../configByType';
import CustomOptionWithSubtext from '../../../../../components/DropDownCustomOptions/CustomOptionWithSubtext';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import { ConfigDestinationTypeBaseProps } from '../interface';

export default function S3({ maskSecretFields }: ConfigDestinationTypeBaseProps) {
  const memoizedGetRegions = useMemo(ConfigByType.S3.regionList, []);
  const memoizedGetFileFormats = useMemo(ConfigByType.S3.fileFormatList, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='awsAccessKey'
              label='Access Key ID'
              required
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='awsSecretAccessKey'
              label='Secret Access Key'
              required
              masked={maskSecretFields}
              component={HdFormikPasswordField}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field name='bucket' label='Bucket Name' required component={HdFormikTextField} />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field name='prefix' label='Prefix' component={HdFormikTextField} />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='bucketRegion'
              label='Bucket Region'
              required
              idAccessor='value'
              options={memoizedGetRegions}
              component={HdFormikDropDown}
              CustomOption={CustomOptionWithSubtext}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='fileFormat'
              label='File Format'
              required
              idAccessor='value'
              options={memoizedGetFileFormats}
              component={HdFormikDropDown}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} className='mb-7'>
        <Grid item xs={12} md={12}>
          <HdFormControl>
            <HdFormikSwitchWrapper fieldName='gzip' label='Should files be GZipped?' />
          </HdFormControl>
        </Grid>
      </Grid>
    </>
  );
}
