import React from 'react';
import clsx from 'clsx';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import styles from './styles.module.scss';
import { PipelineInterface } from '../../model';
import { SOURCE_TYPES } from '../../../../../app/nodes/source-type/source-type';
import { NodeLogoWithText } from '../../../drawer/Shared/NodeLogoWithText';

interface JobNodeRelationProps {
  pipeline: PipelineInterface;
  className?: string;
}

export function JobNodeRelation({ pipeline, className = '' }: JobNodeRelationProps) {
  const {
    destinationType,
    logoURL: destionationLogoURL,
    darkModeLogoURL: destionationDarkLogoURL,
    name: destinationName
  } = pipeline.destination;
  const {
    seqId,
    sourceType,
    logoURL: sourceLogoURL,
    darkModeLogoURL: sourceDarkModeLogoURL,
    name: sourceName
  } = pipeline.source;

  const sourceLogoInfo = {
    logoURL: sourceLogoURL,
    darkModeLogoURL: sourceDarkModeLogoURL,
    primaryColor: SOURCE_TYPES[sourceType].primaryColor,
    darkModePrimaryColor: SOURCE_TYPES[sourceType].darkModePrimaryColor
  };

  const destinationLogoInfo = {
    logoURL: destionationLogoURL,
    darkModeLogoURL: destionationDarkLogoURL,
    primaryColor: DESTINATION_TYPES[destinationType].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[destinationType].darkModePrimaryColor
  };

  return (
    <div className={`${styles.nodeRelationContainer} ${className}`}>
      <div className='text-body-2'>
        (#{seqId}) {pipeline.source.name}
      </div>
      <div className='center-flex-row mt-1'>
        <NodeLogoWithText
          option={sourceLogoInfo}
          name={` ${sourceName}`}
          size={1}
          className='mr-2'
          roundedBorders
          textClassName={clsx('text-body-2 text-secondary', styles.nodeLogoText)}
        />

        <span
          className={`hevo-icon hevo-pipeline-connection text-secondary ${styles.pipelineConnectionIndicator}`}
        />

        <NodeLogoWithText
          option={destinationLogoInfo}
          name={destinationName}
          size={1}
          className='mr-2'
          roundedBorders
          textClassName={clsx('text-body-2 text-secondary', styles.nodeLogoText)}
        />
      </div>
    </div>
  );
}
