import { ActivationSyncBehaviour, ActivationSyncBehaviourType } from '../models/sync-behaviour';


export class GoogleSheetsActivationSyncBehaviour extends ActivationSyncBehaviour {
  static FULL_SYNC = new ActivationSyncBehaviourType(
    'Full Sync',
    'FULLREFRESH'
  );
  static APPEND_ONLY = new ActivationSyncBehaviourType(
    'Append Only',
    'INSERT'
  );
}

export enum GoogleSheetsActivationDatatype {
  RAW = 'RAW',
  USER_ENTERED = 'USER_ENTERED'
}

export interface GoogleSheetsActivationConfig {
  worksheet: {
    id: number,
    name: string
  };
  syncBehaviour: GoogleSheetsActivationSyncBehaviour;
  datatype: GoogleSheetsActivationDatatype;
  isCustomRangeAllowed: boolean;
  spreadsheetRange?: {
    start: string,
    end: string
  };
}

export interface GoogleSheetsMappingConfig {
  worksheetId: number;
  worksheetName: string;
  startRange: string;
  endRange: string;
  dataType: GoogleSheetsActivationDatatype;
}
