/**
 * AuthString interface used to fetch
 * heading & subheading to show
 */
export interface AuthString {
  heading: String;
  subheading?: String;
}

export enum HdAuthRequestType {
  LOGIN = 'LOGIN',
  SETUP_ACCOUNT = 'SETUP_ACCOUNT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  EMPLOYEE_LOGIN = 'EMPLOYEE_LOGIN',
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  SETUP_PERSONA = 'SETUP_PERSONA',
  TFA_LOGIN = 'TFA_LOGIN',
  TFA_RECOVERY = 'TFA_RECOVERY',
  TFA_RECOVERY_CODES = 'TFA_RECOVERY_CODES',
  SETUP_TFA = 'SETUP_TFA',
  LOGIN_SAML = 'LOGIN_SAML'
}
