import { Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { getErrorMessageFromObj } from '../../../../legacy-utils/request';
import HdFormSubmissionOverlay from '../../../../components/HdFormSubmissionOverlay';
import { HdIconButton } from '../../../../components/UIElements';
import HdButton from '../../../../components/UIElements/HdButton';
import HdIcon from '../../../../components/UIElements/HdIcon';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import ActivityLogAPI from '../../../activity-log/ActivityLogAPI';
import { CLOUDWATCH_REGIONS } from '../constants';
import CloudWatchPreferenceDialogBody from './CloudWatchPreferenceDialogBody';
import { getConfigRawData, getInitialValues, VALIDATION_SCHEMA } from './config';

const regions = CLOUDWATCH_REGIONS.map(region => ({ ...region, id: region.value }));
export const dataIdGenerator = getDataIdGenerator('cloudwatch-setup-dialog');

export default function CloudwatchPreferenceDialog({
  clientLogger,
  editMode,
  onNegativeClickCallback,
  onPreferenceUpdateCallback
}: {
  clientLogger: any;
  editMode: boolean;
  onNegativeClickCallback: () => void;
  onPreferenceUpdateCallback: () => void;
}) {
  const [saving, setSaving] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [formError, setFormError] = useState<string>(null);
  const [formSuccess, setFormSuccess] = useState<string>(null);

  const initialValues = getInitialValues(clientLogger?.config, regions);

  const formRef = useRef();

  const saveConfig = (formData: any) => {
    setSaving(true);
    setFormError(null);
    setFormSuccess(null);

    const config = getConfigRawData(formData);

    ActivityLogAPI.testCloudwatchConnection(config)
      .then(() => {
        if (!editMode) {
          return ActivityLogAPI.enableCloudwatch(config);
        }

        return ActivityLogAPI.updateCloudwatchConfig(config, clientLogger.id);
      })
      .then(() => {
        onPreferenceUpdateCallback();
      })
      .catch((error: any) => {
        setFormError(getErrorMessageFromObj(error));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const testConnection = (formData: any) => {
    setConnecting(true);
    setFormError(null);
    setFormSuccess(null);

    ActivityLogAPI.testCloudwatchConnection(getConfigRawData(formData))
      .then(() => {
        setConnecting(false);
        setFormSuccess('Connection successful');
      })
      .catch((error: any) => {
        setConnecting(false);
        setFormError(getErrorMessageFromObj(error));
      });
  };

  return (
    <>
      <div data-id={dataIdGenerator('header')} className='dialog-header text-capitalize'>
        <div className='dialog-title text-capitalize'>CloudWatch Sync Settings</div>

        <HdIconButton
          dataId={dataIdGenerator('close')}
          onClick={() => onNegativeClickCallback()}
          className='ml-2'
        >
          <HdIcon name='close' size={3} />
        </HdIconButton>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        validateOnMount
        onSubmit={() => {}}
      >
        {(formikProps: FormikProps<any>) => (
          <form noValidate ref={formRef}>
            <CloudWatchPreferenceDialogBody
              formSuccess={formSuccess}
              setFormSuccess={setFormSuccess}
              formError={formError}
              editMode={editMode}
              regions={regions}
            />

            <div className='dialog-footer'>
              <HdButton
                dataId={dataIdGenerator('test')}
                variation='outline'
                showProgress={connecting}
                onClick={() => {
                  formikProps
                    .submitForm()
                    .then(() => {
                      if (!formikProps.isValid) {
                        return;
                      }

                      testConnection(formikProps.values);
                    })
                    .catch(() => {});
                }}
                disabled={saving || connecting}
              >
                {connecting ? 'Connecting' : 'Test Connection'}
              </HdButton>

              <HdButton
                dataId={dataIdGenerator('edit')}
                showProgress={saving}
                onClick={() => {
                  formikProps
                    .submitForm()
                    .then(() => {
                      if (!formikProps.isValid) {
                        return;
                      }

                      saveConfig(formikProps.values);
                    })
                    .catch(() => {});
                }}
                disabled={saving || connecting}
              >
                {editMode ? 'Update' : 'Enable'}
              </HdButton>
            </div>

            {(saving || connecting) && <HdFormSubmissionOverlay anchorRef={formRef} />}
          </form>
        )}
      </Formik>
    </>
  );
}
