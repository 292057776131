import { DestinationLoadWebhook } from '../interface';
import WebhooksAPI from '../WebhooksApi';
import { useQuery } from '../../../../hooks/useQuery';
import { Destination } from '../../../../../app/destination/models';
import DestinationsAPI from '../../../destination/DestinationsAPI';
import { DestinationsFactory } from '../../../../../app/destination/utils';
import { PostLoadWebhookFactory } from '../factory';

interface QueryState {
  destinations: Destination[];
  webhooks: DestinationLoadWebhook[];
}

const fetchData = destinationId =>
  Promise.all([DestinationsAPI.getDestinations(), WebhooksAPI.fetchWebhooks(destinationId)]).then(
    ([destinationsRes, webhooksRes]) => {
      const destinations = DestinationsFactory(destinationsRes.data);

      const webhooks = PostLoadWebhookFactory(webhooksRes.data, destinations);

      return {
        destinations,
        webhooks
      };
    }
  );

export const useWebhooksLoad = (destinationId: number = null) => {
  const { data, error, fetch, uiState, refetch, writeData } = useQuery<{
    destinations: Destination[];
    webhooks: DestinationLoadWebhook[];
  }>({
    dataSource$: () => fetchData(destinationId)
  });

  const handleWebhookDelete = (webhookId: number) => {
    writeData(oldData => ({
      ...oldData,
      webhooks: (oldData?.webhooks || []).filter(_webhook => webhookId !== _webhook.id)
    }));
  };

  const updateWebhookStatus = (webhookId: number, status: any) =>
    writeData((updateData: QueryState) => ({
      webhooks: updateData.webhooks.map(webhook => ({
        ...webhook,
        status: webhook.id === webhookId ? status : webhook.status
      }))
    }));

  return {
    data,
    error,
    fetch,
    uiState,
    refetch,
    handleWebhookDelete,
    updateWebhookStatus
  };
};
