import React, { MutableRefObject, useRef } from 'react';
import QueryViewer from '../../CodeEditor/QueryViewer';
import { HdButton, HdIcon, HdIconButton, HdModal } from '../../UIElements';
import { QUERY_CONFIRM_DIALOG_DATA_IDS } from './data-ids';

export interface QueryConfirmDialogProps {
  isOpen: boolean;
  params: {
    title: string;
    body: string;
    query: string;
    queryEditorMode: string;
    mappingParams: any;
  };
  onClose: (confirm?: boolean) => void;
}

export function QueryConfirmDialog({
  isOpen,
  ...others
}: QueryConfirmDialogProps) {
  const editorRef = useRef(null);

  return (
    <HdModal
      dataId={QUERY_CONFIRM_DIALOG_DATA_IDS.modal}
      open={isOpen}
      onClose={() => others.onClose()}
      placement='top'
      styling={{width: '550px'}}
      onTransitionEnd={() => {
        editorRef.current.refresh();
      }}
    >
      <QueryConfirmDialogInner {...others} editorRef={editorRef} />
    </HdModal>
  );
}

function QueryConfirmDialogInner({
  params,
  onClose,
  editorRef
}: Omit<QueryConfirmDialogProps, 'isOpen'> & { editorRef: MutableRefObject<any> }) {
  return (
    <>
      <div
        className='dialog-header'
        tabIndex={0}>
        <div className='dialog-title' data-id={QUERY_CONFIRM_DIALOG_DATA_IDS.title}>
          {params.title}
        </div>

        <HdIconButton
          dataId={QUERY_CONFIRM_DIALOG_DATA_IDS.close}
          className='pure-ripple'
          onClick={() => onClose()}>
          <HdIcon name='close' size={3} />
        </HdIconButton>
      </div>

      <div className='dialog-body'>
        <div
          data-id={QUERY_CONFIRM_DIALOG_DATA_IDS.body}
          className='mb-4'
        >
          {params.body}
        </div>

        <QueryViewer
          dataId={QUERY_CONFIRM_DIALOG_DATA_IDS.query}
          value={params.query}
          mode={params.queryEditorMode}
          getCodeMirror={ed => {
            editorRef.current = ed;
          }}
        />
      </div>

      <div className='dialog-footer'>
        <div>
          <HdButton
            onClick={() => onClose()}
            variation='outline'
            dataId={QUERY_CONFIRM_DIALOG_DATA_IDS.cancel}
          >
            Cancel
          </HdButton>

          <HdButton
            onClick={() => onClose(true)}
            dataId={QUERY_CONFIRM_DIALOG_DATA_IDS.confirm}
          >
            Confirm
          </HdButton>
        </div>
      </div>
    </>
  );
}
