import moment from 'moment-mini-ts';


export function getBarGraphDisplayTime(start: string, end: string): string {
  const startTime = moment.utc(start).local(),
    endTime = moment.utc(end).local();

  if (startTime.isSame(endTime, 'day')) {
    return startTime.format('MMM Do YYYY') + ' ' + startTime.format('LT') + ' - ' + endTime.format('LT');
  }

  return startTime.format('MMM Do YYYY LT') + ' ' + ' - ' + endTime.format('MMM Do YYYY LT');
}
