import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TrialDetailsService } from '../service/trial-details.service';
import { map, catchError } from 'rxjs/operators';
import { UserBlockDetails } from '../models/trial-details';

@Injectable({
  providedIn: 'root'
})
export class TrialExpiryCheckGuard implements CanActivate {
  constructor(
    private _trialDetailsService: TrialDetailsService,
    private _router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this._trialDetailsService.shouldBlockUser$.pipe(
      map((userBlockDetails: UserBlockDetails) => {
        if (userBlockDetails.blockUser) {
          this._router.navigate([userBlockDetails.redirectRoute]);
        }

        return !userBlockDetails.blockUser;
      }),
      catchError((error) => {
        return of(true);
      })
    );
  }
}
