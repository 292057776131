import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { InclusionListState } from '../../../../../../app/core/models/inclusion-list-v2';
import { GenericKeyValueType } from '../../../../../generics';

export enum MSAdsFormFields {
  CUSTOMER_ID = 'customerId',
  CORE_OBJECTS = 'coreObjects',
  REPORTS = 'reports',
  SELECTED_CUSTOMERS = 'selectedCustomers'
}

export interface MSAdsFormState extends GenericKeyValueType<string, any> {
  [MSAdsFormFields.CUSTOMER_ID]: string | number;
  [MSAdsFormFields.CORE_OBJECTS]: string[];
  [MSAdsFormFields.REPORTS]: string[];
  [MSAdsFormFields.SELECTED_CUSTOMERS]: InclusionListState;
}

export interface MSAdsFormMetaData extends GenericKeyValueType<string, any> {
  coreObjects: string[];
  reports: string[];
}

export interface MSAdsSelectedCustomer {
  id: string | number;
  all_select: boolean;
  entities: Array<string | number>;
}

export interface MSAdsConfigPayload {
  customer_id: string | number;
  selected_core_objects: string[];
  selected_reports: string[];
  all_select: boolean;
  selected_customers: MSAdsSelectedCustomer[];
}

export type MSAdsConfigResponse = MSAdsConfigPayload;

export interface MSAdsManagerAccount {
  id: number;
  name: string;
  totalNumOfAccounts: number;
}

export function MSAdsManagerAccountsFactory(rawData: any[]): MSAdsManagerAccount[] {
  if (!rawData) {
    return;
  }

  return rawData.map((item) => {
    return MSAdsManagerAccountFactory(item);
  });
}

export function MSAdsManagerAccountFactory(rawData: any): MSAdsManagerAccount {
  if (!rawData) {
    return;
  }

  return {
    id: rawData.id,
    name: rawData.name,
    totalNumOfAccounts: rawData.total_num_of_accounts
  };
}

export function MsAdsInclusionStateFactory(rawData: any): InclusionListState {
  return {
    selected: coerceBooleanProperty(rawData.all_select),
    entities: (rawData.selected_customers || []).map((manager) => {
      return {
        key: manager.id,
        selected: manager.all_select,
        entities: manager.entities.map((adName) => {
          return {
            key: adName,
            selected: !manager.all_select
          };
        })
      };
    })
  };
}

export function getMSAdsInclusionStateRawData(state: InclusionListState) {
  if (!state) {
    return;
  }

  return {
    all_select: state.selected,
    selected_customers: state.entities.map((managerEntity) => {
      return {
        id: managerEntity.key,
        all_select: managerEntity.selected,
        entities: (<InclusionListState>managerEntity).entities.map((adEntity) => {
          return adEntity.key;
        })
      };
    })
  };
}
