// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.testimonialSection_tYwQK {\n  position: relative;\n  justify-content: center;\n  overflow: hidden;\n  color: var(--light-text);\n  background-color: var(--auth-left-bg);\n  background-image: var(--auth-left-bg-image);\n  background-attachment: fixed;\n  background-size: cover;\n}\n.testimonialSection_tYwQK::after {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: block;\n  content: \"\";\n}\n\n.testimonialContainer_nqgLk {\n  z-index: 1;\n  padding: 0 32px;\n}\n\n.testimonialSliderContainer_qTuZi {\n  margin-top: 80px;\n}\n\n.clientsSliderContainer_kNQIP {\n  z-index: 1;\n  margin-top: 96px;\n}\n\n.clientsSliderItem_Z6xik {\n  height: 32px;\n  opacity: 0.3;\n}\n.clientsSliderItem_Z6xik.tns-slide-active.current-item {\n  opacity: 1;\n}\n\n.sliderDot_M3wt5 {\n  width: 8px;\n  height: 8px;\n  border-radius: 4px;\n  border: solid 1px white;\n  display: inline-block;\n  margin-right: 4px;\n  cursor: pointer;\n}\n.sliderDot_M3wt5.tns-nav-active {\n  background-color: white;\n}\n.sliderDot_M3wt5:last-child {\n  margin-right: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"testimonialSection": "testimonialSection_tYwQK",
	"testimonialContainer": "testimonialContainer_nqgLk",
	"testimonialSliderContainer": "testimonialSliderContainer_qTuZi",
	"clientsSliderContainer": "clientsSliderContainer_kNQIP",
	"clientsSliderItem": "clientsSliderItem_Z6xik",
	"sliderDot": "sliderDot_M3wt5"
};
export default ___CSS_LOADER_EXPORT___;
