export interface RequestUIOptions {
  showLoading?: boolean;
  showSuccessMsg?: boolean;
  showErrorMsg?: boolean;
  handleUnauthorisedResponse?: boolean;
  unauthorisedHandleNext?: boolean;
  successMsg?: string;
  handleTimeoutError?: boolean;
}

export interface NetworkRequestOptions {
  networkOptions?: any;
  uiOptions?: RequestUIOptions;
  requireAuth?: boolean;
}

export interface RequestPaginationData {
  page?: number;
  limit?: number;
  [key: string]: any;
}

export enum HTTPRequestMethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export enum HTTPResponseCode {
  BAD_REQUEST = 400
}
