import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive(
  {
    selector: '[hdAutoFocus]'
  }
)
export class AutoFocusDirective implements OnInit {
  constructor(private el: ElementRef) {
  }

  @Input() hdAutoFocus = true;

  ngOnInit() {
    if (this.hdAutoFocus !== false) {
      this.el.nativeElement.focus();
    }
  }
}
