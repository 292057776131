import { FieldTypes, SourceFormFieldDefinition } from '../../models';
import modifyFieldDefinition from './modify-field-definition';
import { TemplateConfig } from './template-config';

export default function APIKeyTemplate(templateConfig: TemplateConfig): Array<Array<SourceFormFieldDefinition>> {
  return [
    [
      getAPIKeyField(templateConfig)
    ]
  ];
}

export function getAPIKeyField(templateConfig: TemplateConfig): SourceFormFieldDefinition {
  const { label = 'API Key', hint } = templateConfig;

  const template: SourceFormFieldDefinition = {
    key: 'apiKey',
    fieldId: 'api-key',
    widget: FieldTypes.PASSWORD_FIELD,
    fieldType: 'string',
    widgetOptions: { name: 'apiKey', label: label, required: true },
    hint: hint,
    buildParamsKey: 'api_key',
    validations: [
      {
        type: 'required'
      }
    ]
  };

  return modifyFieldDefinition(template, templateConfig);
}
