<ng-content></ng-content>

<button
  hd-icon-btn
  class="ml-2"
  size="1"
  (click)="removeClick($event)"
  *ngIf="canRemove && !disabled">
  <hd-icon name="close"></hd-icon>
</button>
