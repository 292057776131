/* eslint-disable camelcase */
import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { DESTINATION_TYPES } from '../../../../app/nodes/destination-type/model';
import { SOURCE_TYPES } from '../../../../app/nodes/source-type/source-type';
import { NodeLogoWithText } from '../../../containers/drawer/Shared/NodeLogoWithText';

interface PipelineNodeRelationProps {
  pipeline: any;
  className?: string;
  textClassName?: string;
}

export function PipelineNodeRelation({
  pipeline,
  className = '',
  textClassName = ''
}: PipelineNodeRelationProps) {
  const {
    dest_type,
    dest_type_logo_url,
    dest_type_dark_mode_logo_url,
    dest_name,
    seq_id,
    source_type,
    source_type_logo_url,
    source_type_dark_mode_logo_url,
    source_name,
    pipeline_status
  } = pipeline;

  const sourceLogoInfo = {
    logoURL: source_type_logo_url,
    darkModeLogoURL: source_type_dark_mode_logo_url,
    primaryColor: SOURCE_TYPES[source_type].primaryColor,
    darkModePrimaryColor: SOURCE_TYPES[source_type].darkModePrimaryColor
  };

  const destinationLogoInfo = {
    logoURL: dest_type_logo_url,
    darkModeLogoURL: dest_type_dark_mode_logo_url,
    primaryColor: DESTINATION_TYPES[dest_type].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[dest_type].darkModePrimaryColor
  };

  return (
    <div className={`${styles.nodeRelationContainer} ${className}`}>
      <div className='center-flex-row overflow-hidden'>
        <NodeLogoWithText
          option={sourceLogoInfo}
          name={`(#${seq_id}) ${source_name}`}
          size={1}
          logoClassName='mr-2'
          roundedBorders
          textClassName={clsx('text-body-2', styles.nodeLogoText, textClassName)}
        />

        <span
          className={`hevo-icon hevo-pipeline-connection ${styles.pipelineConnectionIndicator} text-${pipeline_status.color_type}`}
        />

        <NodeLogoWithText
          option={destinationLogoInfo}
          name={dest_name}
          size={1}
          logoClassName='mr-2'
          roundedBorders
          textClassName={clsx('text-body-2', styles.nodeLogoText, textClassName)}
        />
      </div>
    </div>
  );
}
