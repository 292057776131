import React, { useMemo } from 'react';
import { HdKeyBadge as HdKeyBadgeInterface } from '../../../../app/badge/key-badge/key-badge';
import { HdTooltip } from '../HdTooltip';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';

interface HdKeyBadgeProps {
  keys: HdKeyBadgeInterface[];
  className?: string;
  shrink?: boolean;
  tooltipDisabled?: boolean;
  dataId?: string;
}

export function HdKeyBadge({
  keys,
  className,
  shrink,
  tooltipDisabled,
  dataId = ''
}: HdKeyBadgeProps) {
  const keyBadgeDataId = useMemo(() => getKeyBadgeDataId(dataId), [dataId]);
  return (
    <div className={`${styles.wrapper} ${className} ${shrink ? styles.shrink : ''} `}>
      {keys.map((key, index) => (
        <HdTooltip key={key.name} title={tooltipDisabled || key.tooltipDisabled ? '' : key.name}>
          <span
            data-id={`${keyBadgeDataId}-${key.abbr}`}
            key={key.name}
            className={`badge ${styles.badgeKey} ${styles[key.class]} ${index > 0 ? 'ml-1' : ''}`}
          >
            {key.abbr}
            {index > 0 ? <span /> : null}
          </span>
        </HdTooltip>
      ))}
    </div>
  );
}

export const getKeyBadgeDataId = (dataId = '') => getDataIdGenerator(dataId, 'hd-key-badge')('');
