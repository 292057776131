export enum OffsetViewType {
  DEFAULT = 'DEFAULT',
  NUMBER = 'NUMBER',
  DATE_TIME = 'DATE_TIME',
  DATE = 'DATE',
  OBJECT = 'OBJECT',
  FILE_BASED = 'FILE_BASED',
  MULTI_OBJECT = 'MULTI_OBJECT',
  MONTH_YEAR = 'MONTH_YEAR'
}

export interface OffsetTimezone {
  offset: string;
  id: string;
}

export interface OffsetViewFormFields {
  label: string;
  placeholder: string;
  description: string;
}

export class OffsetView {
  type: OffsetViewType;
  offset: any;
  offset_form_fields: OffsetViewFormFields;
}

export class DefaultOffsetView extends OffsetView {
  readonly type = OffsetViewType.DEFAULT;
}

export class DateTimeOffsetView extends OffsetView {
  readonly type = OffsetViewType.DATE_TIME;
  timezone: OffsetTimezone;
}

export class DateOffsetView extends OffsetView {
  readonly type = OffsetViewType.DATE;
  timezone: OffsetTimezone;
}

export class NumberOffsetView extends OffsetView {
  readonly type = OffsetViewType.NUMBER;
  field_name: string;
}

export class FileBasedOffsetView extends OffsetView {
  readonly type = OffsetViewType.FILE_BASED;
  file_name: string;
  offset: number;
}

export class ObjectOffsetView extends OffsetView {
  readonly type = OffsetViewType.OBJECT;
}

export class MultiObjectOffsetView extends OffsetView {
  readonly type = OffsetViewType.MULTI_OBJECT;
  offset: { [key: string]: any };
  keys: string;
}

export class MonthYearOffsetView extends OffsetView {
  readonly type = OffsetViewType.MONTH_YEAR;
  month: number;
  year: number;
  month_fields: {
    label: string;
    placeholder: string;
  };
  year_fields: {
    label: string;
    placeholder: string;
  };
}

export type OffsetViews =
  | DefaultOffsetView
  | DateOffsetView
  | DateTimeOffsetView
  | NumberOffsetView
  | FileBasedOffsetView
  | ObjectOffsetView
  | MultiObjectOffsetView
  | MonthYearOffsetView;

export const MONTHS = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
