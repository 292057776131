import { NounPluralFormatterOptions } from '../../app/pipe/interface';


export function matchPattern(params: string | string[], searchPattern: string): boolean;
export function matchPattern(
  params: (string | string[]),
  searchPattern: string
) {
  let compareWith: string[] = [];

  if (typeof searchPattern === 'undefined' || searchPattern === null) {
    return true;
  }

  const patternEscaped = (searchPattern + '')
  .trim()
  .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

  if (!Array.isArray(params)) {
    compareWith = [ params ];
  } else {
    compareWith = [ ...params ];
  }

  return compareWith.some((param) => {
    return (param + '').search(new RegExp(patternEscaped, 'i')) >= 0;
  });
}

export function getUnreadDisplayCount(count: number): string | number {
  return count >= 100 ? '99+' : count;
}

export function capitalise(word: string) {
  if (!word) {
    return;
  }

  const lower = word.toLowerCase();

  return lower.slice(0, 1).toUpperCase() + lower.slice(1, lower.length);
}

export function nounPluralFormatter(noun: string, options: NounPluralFormatterOptions) {
  if (options.overrideNoun) {
    return options.count === 1 ? noun : options.overrideNoun;
  }

  if (options.suffix) {
    return addSuffixWhenNounPlural(noun, options.count, options.suffix);
  }

  return addSuffixWhenNounPlural(noun, options.count);
}

export function addSuffixWhenNounPlural(noun: string, numberToCheck: number, suffix = 's') {
  return numberToCheck === 1 ? noun : noun + suffix;
}

export function getRandomString(length: number, useLowerChars = true, useUpperChars = true, useDigits = true) {
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
  const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const characters = (useLowerChars ? lowerChars : '') + (useUpperChars ? upperChars : '') + (useDigits ? digits : '');
  const charsLength = characters.length;

  if (charsLength === 0) {
    return '';
  }

  let s = '';
  for ( let i = 0; i < length; ++i ) {
    s += characters.charAt(Math.floor(Math.random() * charsLength));
  }
  return s;
}

export function addDelimiter(delimiter: string, ...words: string[]) {
  return words.filter(word => word.trim()).join(delimiter);
}
