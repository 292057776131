import { Filter } from '../../../app/filter/models/filter';

export class Activity {
  message: string;
  action: ActivityAction;
  activityTime: number;
  author: ActivityAuthor;
  severity: ActivitySeverity;
  origin: ActionOriginSegment[];
  displayTime: string;
  payload: string;
}

/* eslint-disable no-shadow */
export interface FilterOption<T> {
  name: string;
  value: T;
  warning?: boolean;
  img?: string;
}

export interface ActivityInterface {
  message: string;
  action: ActivityAction;
  activityTime: number;
  author: ActivityAuthor;
  severity: ActivitySeverity;
  origin: ActionOriginSegment[];
  displayTime: string;
  payload: string;
}

export interface ActivityAction {
  name: string;
  url: string;
}

export enum ActivityAuthorType {
  USER = 'USER',
  BOT = 'BOT'
}

export enum ActivitySeverityLevel {
  INFO = 'INFO',
  WARN = 'WARN',
  TRACE = 'TRACE',
  ERROR = 'ERROR'
}

export interface ActivityAuthor {
  displayName: string;
  type: ActivityAuthorType;
  id: number;
  iconClass: 'hevo-bot' | 'hevo-user';
}

export interface ActivitySeverity {
  displayName: string;
  level: ActivitySeverityLevel;
  colorType: 'info' | 'warning' | 'default' | 'error';
  filterLevels: string[];
}

export interface ActivityFilterOptions {
  accounts: FilterOption<number>[];
  severities: FilterOption<string>[];
}

export interface ActionOriginSegment {
  displayName: string;
  id: number;
  type: string;
  iconClass?: string;
}

export interface ActivityLogEntityFilters {
  entities_l0: {
    type: string;
    id: string | number;
    entities_l1: { type: string; id: string | number }[];
  }[];
}

export enum ActionKind {
  FETCH_FILTERS_OPTIONS = 'FETCH_FILTERS_OPTIONS',
  FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS',
  FETCH_ACTIVITY_LIST = 'FETCH_ACTIVITY_LIST',
  FETCH_ACTIVITY_LIST_SUCCESS = 'FETCH_ACTIVITY_LIST_SUCCESS',
  FETCH_ACTIVITY_LIST_FAILURE = 'FETCH_ACTIVITY_LIST_FAILURE',
  SET_ACTIVITIES = 'SET_ACTIVITIES',
  SET_FILTERS = 'SET_FILTERS'
}

export interface ReducerState {
  activities: ActivityInterface;
  listFilters: Filter<any>[];
  fetchingList: boolean;
  loadingFilterOptions: boolean;
  listFetchError: null;
}

export interface ReducerAction {
  type: ActionKind;
  data?: any;
}

export class ActivityFilterOptions {
  accounts: FilterOption<number>[] = [];
  severities: FilterOption<string>[] = [];
}
