export function currencyFormatter(
  value,
  locale?: string,
  currency?: string,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
) {
  const formatter = new Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: minimumFractionDigits || 0,
    maximumFractionDigits: maximumFractionDigits || 2
  });

  return formatter.format(value);
}
