import { AlertSnackbarStyle, SnackbarType } from './models';

export const ALERT_SNACKBAR_STYLES: { [ key: string ]: AlertSnackbarStyle } = {
  'info': {
    iconClass: 'hevo-info',
    type: SnackbarType.INFO
  },
  'warning': {
    iconClass: 'hevo-warning',
    type: SnackbarType.WARN
  },
  'error': {
    iconClass: 'hevo-error-filled',
    type: SnackbarType.ERROR
  }
};
