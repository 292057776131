import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { getSelectorRawDataFromInclusionState } from '../../../../../../app/shared/single-level-selector/model';
import PipelinesAPI from '../../../PipelinesAPI';

export class GoogleSearchConsoleConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    return {
      oauthTokenId: rawData.oauth_token_id,
      selectedProperties: {
        selected: rawData.all_selected,
        entities: rawData.included_properties
          .filter(property => metaData.gcsProperties.includes(property))
          .map(key => ({ key, selected: true })),
        excludedProperties: []
      }
    };
  }

  getDefaultConfig() {}

  buildConnectionParams(data: any) {
    const rawConfig = getSelectorRawDataFromInclusionState(data.selectedProperties);
    return {
      included_properties: rawConfig.entities,
      excluded_properties: [],
      all_selected: rawConfig.all_select
    };
  }

  metaDataResolver(sourceType: string, authData: any) {
    return PipelinesAPI.getGscProperties(authData.oauthTokenId).then((res: any) => ({
        gcsProperties: res
      })
    );
  }
}
