export enum UsageStatus {
  REGULAR_CONSUMPTION = 'REGULAR_CONSUMPTION',
  EARLY_CONSUMPTION = 'EARLY_CONSUMPTION',
  BASE_CONSUMPTION = 'BASE_CONSUMPTION',
  BUFFER_CONSUMPTION = 'BUFFER_CONSUMPTION',
  REGULAR_CREDIT_CONSUMPTION = 'REGULAR_CREDIT_CONSUMPTION',
  EARLY_ON_DEMAND_CREDIT_CONSUMPTION = 'EARLY_ON_DEMAND_CREDIT_CONSUMPTION',
  NEAR_ON_DEMAND_CREDIT_CONSUMPTION = 'NEAR_ON_DEMAND_CREDIT_CONSUMPTION',
  ON_DEMAND_CREDIT_CONSUMPTION = 'ON_DEMAND_CREDIT_CONSUMPTION',
  NEAR_ON_DEMAND_CONSUMPTION = 'NEAR_ON_DEMAND_CONSUMPTION',
  ON_DEMAND_CONSUMPTION = 'ON_DEMAND_CONSUMPTION'
}

export enum UsageAlertPrimaryAction {
  UPGRADE_PLAN,
  SET_ON_DEMAND_CREDIT,
  HIKE_ON_DEMAND_CREDIT,
  REDEEM_ON_DEMAND_CREDIT
}

export interface UsageAlertData {
  status: UsageStatus;
  alertTitle: string;
  alertMessage: string;
  hasCreditExhausted: boolean;
  hasAssignedMaxCredit: boolean;
  hasReachedEarlyCreditConsumptionThreshold: boolean;
  hasAssignedCredit: boolean;
}
