export interface User {
  name: string;
  email: string;
  profileImage: string;
  isOwner: boolean;
  isHidden: boolean;
  id: number;
  tfaEnabled: boolean;
  createdTs: number;
  teamId: number;
  clusterId: string;
  teamName: string;
  emailVerified: boolean;
  isEmailVerificationBlock: boolean;
  role?: string;
}

export enum RbacPermissions {
  PIPELINE_EDIT = 'PIPELINE_EDIT',
  PIPELINE_DELETE = 'PIPELINE_DELETE',
  PIPELINE_CREATE = 'PIPELINE_CREATE',

  DESTINATION_EDIT = 'DESTINATION_EDIT',
  DESTINATION_CREATE = 'DESTINATION_CREATE',
  DESTINATION_DELETE = 'DESTINATION_DELETE',

  MODELS_AND_WORKFLOWS_CREATE = 'MODELS_AND_WORKFLOWS_CREATE',
  MODELS_AND_WORKFLOWS_EDIT = 'MODELS_AND_WORKFLOWS_EDIT',
  MODELS_AND_WORKFLOWS_DELETE = 'MODELS_AND_WORKFLOWS_DELETE',

  ACTIVATION_CREATE = 'ACTIVATION_CREATE',
  ACTIVATION_EDIT = 'ACTIVATION_EDIT',
  ACTIVATION_DELETE = 'ACTIVATION_DELETE',

  TEAM_EDIT = 'TEAM_EDIT',
  TEAM_DELETE = 'TEAM_DELETE',

  BILLING_EDIT = 'BILLING_EDIT'
}

export const CENTRAL_CLUSTER_ID = 'CENTRAL';

export interface InviteMemberOptions {
  email: string;
  entity: string;
  entity_type: string;
  wizard: string;
  redirect_url: string;
}
