import { FILTER_ALL } from '../constants';
import { FilterOption } from './filter-option';


export class Filter<T> {
  name: string;
  defaultValue: FilterOption<T> ;
  options: FilterOption<T>[];
  value: FilterOption<T>;
  renderTag = true;
  appendAll = false;

  static compareWithFilters(item: any, filters: Filter<any>[]): boolean {
    return filters.every((filter: Filter<any>) => {
      return filter.match(item);
    });
  }

  constructor(name: string, options: any, defaultVal: FilterOption<T>, appendAll = false) {
    this.name = name;

    this.appendAll = appendAll;

    this.invalidate(options, appendAll);

    this.defaultValue = defaultVal;

    this.value = defaultVal;
  }

  invalidate(options: any, appendAll: boolean) {
    this.options = appendAll ? [ FILTER_ALL ].concat(options) : options;
  }

  match(item: any): boolean {
    if (!this.value) { return true; }
    return <any> this.value.value === FILTER_ALL.value || this.matchCriteria(item);
  }

  matchCriteria(item: any): boolean {
    if (!this.value) { return false; }
    return item === this.value.value;
  }

  /**
   * [isActive description]
   * @deprecated        Don't use this directly
   */
  isActive(option: FilterOption<T>): boolean {
    if (!this.value) { return false; }
    return this.value.value === option.value;
  }

  isOptionActive(option: FilterOption<T>): boolean {
    if (!this.value) { return false; }
    return this.value.value === option.value;
  }

  /**
   * [isFilterActive Used for applied-filters]
   * @return [description]
   */
  isFilterActive(): boolean {
    if (!this.value) { return false; }

    return !(this.appendAll && <any> this.value.value === FILTER_ALL.value);
  }

  activate(option: FilterOption<T>) {
    this.value = option;
  }

  reset() {
    if (this.appendAll) {
      this.value = this.defaultValue;
    } else {
      this.value = undefined;
    }
  }
}
