import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CompatRouter } from '../../../react/CompatRouter';
import ThemeWrapper from '../../../react/ThemeWrapper';
import { store } from '../../core/service/redux.service';
import { createAngularHistory } from '../create-angular-history';
import { AngularDIContext } from '../di-context';
import { RootWrapper } from '../../../react/RootWrapper';
import { ReactElementTree } from '../react-element-tree';
import { Location } from '@angular/common';


@Injectable()
export class BridgeService implements OnDestroy {
  private _keepMounted = false;

  private _history = createAngularHistory({
    angularRouter: this._router,
    angularLocation: this._location
  });
  private _reactHostElement: any;

  constructor(
    private _router: Router,
    private _injector: Injector,
    private _location: Location
  ) {
  }

  mount<T = object>(Component: any, container: HTMLElement | null, props?: T, keepMounted = false, basename = '') {
    this._reactHostElement = container;
    this._reactHostElement.setAttribute('data-id', 'hd-react-container');

    const finalProps = {
      ...props,
      history: this._history
    };

    const reactElementTree = this._getReactElementTree(Component, finalProps, basename);
    const componentToMount = this._createReactElement(reactElementTree);

    ReactDOM.render(componentToMount, container);

    this._keepMounted = !!keepMounted;
  }

  unmount() {
    if (this._reactHostElement) {
      ReactDOM.unmountComponentAtNode(this._reactHostElement);
      this._reactHostElement = null;
    }
  }

  private _getReactElementTree<T = any>(Component: any, props?: T, basename?: string): ReactElementTree {
    return {
      elementType: AngularDIContext.Provider,
      props: {
        value: this._injector
      },
      children: [
        {
          elementType: Provider,
          props: {
            store
          },
          children: [
            {
              elementType: ThemeWrapper,
              props: null,
              children: [
                {
                  elementType: CompatRouter,
                  props: {
                    history: this._history,
                    basename
                  },
                  children: [
                    {
                      elementType: RootWrapper,
                      props: null,
                      children: [
                        {
                          elementType: Component,
                          props
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
  }

  private _createReactElement(elementTree: ReactElementTree): React.ReactElement {
    if (!elementTree) {
      return null;
    }

    const { elementType, props, children } = elementTree;
    const reactNodes = [];
    if (children && children.length) {
      for ( const childTree of children ) {
        reactNodes.push(this._createReactElement(childTree));
      }
    }

    return React.createElement(elementType, props, ...reactNodes);
  }

  ngOnDestroy(): void {
    this._history.destroy();

    if (!this._keepMounted) {
      this.unmount();
    }
  }
}
