export enum ListActionType {
  GET,
  RESET,
  NEXT,
  PREVIOUS,
  REFRESH,
  FILTER,
  FILTER_MULTIPLE,
  SORT,
  RESPONSE,
  ERROR,
  UPDATE_PAGE_META,
  UPDATE_PAGE_SIZE
}

export interface ListAction {
  type: ListActionType;
}

export class GetList implements ListAction {
  readonly type = ListActionType.GET;
}

export class ResetList implements ListAction {
  readonly type = ListActionType.RESET;
}

export class ListNextPage implements ListAction {
  readonly type = ListActionType.NEXT;
}

export class ListPrevPage implements ListAction {
  readonly type = ListActionType.PREVIOUS;
}

export class RefreshList implements ListAction {
  readonly type = ListActionType.REFRESH;
  constructor(public resetPagination?: boolean) {}
}

export class FilterList implements ListAction {
  readonly type = ListActionType.FILTER;
  constructor(public data: { key: string, value: any }) {}
}

export class MultipleFilterList implements ListAction {
  readonly type = ListActionType.FILTER_MULTIPLE;
  constructor(public data: any) {}
}

export class SortList implements ListAction {
  readonly type = ListActionType.SORT;
  constructor(public data: { key: string, order: any }) {}
}

export class ListResponse implements ListAction {
  readonly type = ListActionType.RESPONSE;
  constructor(public data: { count: number, [key: string]: any }) {}
}

export class ListError implements ListAction {
  readonly type = ListActionType.ERROR;
  constructor(public error: any) {}
}

export class ListUpdatePageMeta implements ListAction {
  readonly type = ListActionType.UPDATE_PAGE_META;
  constructor(public data: any) {}
}

export class ListUpdatePageSize implements ListAction {
  readonly type = ListActionType.UPDATE_PAGE_SIZE;
  constructor(public data: { pageSize: number }) {}
}

export type ListActions = GetList
  | ResetList
  | ListNextPage
  | ListPrevPage
  | RefreshList
  | FilterList
  | MultipleFilterList
  | SortList
  | ListResponse
  | ListError
  | ListUpdatePageMeta
  | ListUpdatePageSize;
