/* eslint-disable react/function-component-definition */
import { FieldProps, getIn } from 'formik';
import React from 'react';
import { FormikHelperText } from '../../../utils/FormikHelperText';
import { HdFileInput } from '../../UIElements';

interface HdFormikFileFieldProps {
  className?: string;
  label?: string;
  onChangeEventHandler?: Function;
  placeholder?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  id?: string;
  helperText?: string;
  multiple?: boolean;
  accept?;
}

const HdFormikFileField: React.FC<HdFormikFileFieldProps & FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  onChangeEventHandler,
  placeholder,
  id,
  variant = 'outlined',
  helperText = ' ',
  accept,
  multiple = false,
  ...props
}) => {
  const showError =
    (touched[field.name] && !!errors[field.name]) ||
    (getIn(errors, field.name) && getIn(touched, field.name));

  return (
    <HdFileInput
      dataId={field.name}
      multiple={multiple}
      accept={accept}
      label={label}
      placeholder={placeholder}
      id={field.name}
      variant={variant}
      value={field.value}
      helperText={FormikHelperText({
        helperText,
        showError,
        fieldName: field.name,
        errorMessage: errors[field.name]
      })}
      {...field}
      showError={showError}
      onBlur={() => setFieldTouched(field.name, true)}
      {...props}
      onChangeEventHandler={files => {
        if (onChangeEventHandler) {
          onChangeEventHandler(files);
        } else {
          setFieldValue(field.name, files);
        }
      }}
    />
  );
};

export default HdFormikFileField;
