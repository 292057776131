import { Field, useFormikContext } from 'formik';
import React from 'react';
import FormApiSuccess from '../../../../components/FormApiSuccess';
import HdFormikDropDown from '../../../../components/FormikElements/HdFormikDropDown';
import HdFormikPasswordField from '../../../../components/FormikElements/HdFormikPasswordField';
import HdFormikTextField from '../../../../components/FormikElements/HdFormikTextField';
import HdFormControl from '../../../../components/UIElements/HdFormControl';
import HdPane from '../../../../components/UIElements/HdPane';
import AwsAuthTypeRadioGroup from '../../../pipeline/create/ConfigSourceType/AWS/AwsAuthTypeRadioGroup';
import {
  AWSAuthenticationType,
  AWSFormField
} from '../../../pipeline/create/ConfigSourceType/AWS/model';
import { getDataIdGenerator } from '../../../../utils/generateDataId';

export const dataIdGenerator = getDataIdGenerator('cloud-watch-preference-dialog-body');

export interface CloudWatchPreferenceDialogBodyProps {
  formSuccess: string;
  setFormSuccess: Function;
  formError: string;
  editMode: boolean;
  regions: any[];
}

export default function CloudWatchPreferenceDialogBody({
  formSuccess,
  setFormSuccess,
  formError,
  editMode,
  regions
}: CloudWatchPreferenceDialogBodyProps) {
  const { values, setValues } = useFormikContext<{
    [AWSFormField.AUTH_TYPE]: string;
    [AWSFormField.ACCESS_KEY]: string;
    [AWSFormField.SECRET_KEY]: string;
    [AWSFormField.IAM_ROLE_ARN]: string;
    [AWSFormField.IAM_EXTERNAL_ID]: string;
  }>();

  const clearCredentialFieldsOnFocus = () => {
    if (values[AWSFormField.AUTH_TYPE] === AWSAuthenticationType.ACCESS_CREDENTIALS) {
      setValues({
        ...values,
        [AWSFormField.IAM_ROLE_ARN]: '',
        [AWSFormField.IAM_EXTERNAL_ID]: ''
      });
    } else if (values[AWSFormField.AUTH_TYPE] === AWSAuthenticationType.IAM_ROLE) {
      setValues({
        ...values,
        [AWSFormField.ACCESS_KEY]: '',
        [AWSFormField.SECRET_KEY]: ''
      });
    }
  };

  return (
    <div className='dialog-body' data-id={dataIdGenerator('')}>
      <HdFormControl>
        <Field name={AWSFormField.AUTH_TYPE} component={AwsAuthTypeRadioGroup} required />
      </HdFormControl>

      {values[AWSFormField.AUTH_TYPE] === AWSAuthenticationType.ACCESS_CREDENTIALS && (
        <>
          <HdFormControl className='mb-2'>
            <Field
              name={AWSFormField.ACCESS_KEY}
              label='AWS Key'
              placeholder='Enter the AWS Key'
              component={HdFormikPasswordField}
              masked={editMode && !!values[AWSFormField.SECRET_KEY]}
              onFocus={clearCredentialFieldsOnFocus}
              required
            />
          </HdFormControl>

          <HdFormControl className='mb-2'>
            <Field
              name={AWSFormField.SECRET_KEY}
              label='AWS Secret'
              placeholder='Enter the AWS Secret'
              component={HdFormikPasswordField}
              masked={editMode && !!values[AWSFormField.SECRET_KEY]}
              onFocus={clearCredentialFieldsOnFocus}
              required
            />
          </HdFormControl>
        </>
      )}

      {values[AWSFormField.AUTH_TYPE] === AWSAuthenticationType.IAM_ROLE && (
        <>
          <HdFormControl className='mb-2'>
            <Field
              name={AWSFormField.IAM_ROLE_ARN}
              label='IAM Role ARN'
              placeholder='arn:aws:iam::<aws_account_no>:role/<role_name>'
              component={HdFormikTextField}
              onFocus={clearCredentialFieldsOnFocus}
              required
            />
          </HdFormControl>

          <HdFormControl className='mb-2'>
            <Field
              name={AWSFormField.IAM_EXTERNAL_ID}
              label='External Id'
              placeholder='Enter the External Id'
              component={HdFormikPasswordField}
              masked={editMode && !!values[AWSFormField.IAM_EXTERNAL_ID]}
              onFocus={clearCredentialFieldsOnFocus}
              required
            />
          </HdFormControl>
        </>
      )}

      <HdFormControl className='mb-2'>
        <Field
          name='region'
          label='Region'
          placeholder='Please Choose the Region'
          options={regions}
          component={HdFormikDropDown}
          required
        />
      </HdFormControl>

      <HdFormControl className='mb-0'>
        <Field
          name='logGroup'
          label='Log Group'
          placeholder='Type the Log Group'
          component={HdFormikTextField}
          required
        />
      </HdFormControl>

      {formSuccess && (
        <div className='mt-3' data-id={dataIdGenerator('form-success')}>
          <FormApiSuccess
            message={formSuccess}
            onClose={() => {
              setFormSuccess(null);
            }}
          />
        </div>
      )}

      {formError && (
        <div className='mt-3' data-id={dataIdGenerator('form-error')}>
          <HdPane variant='error' icon='error-filled'>
            {formError}
          </HdPane>
        </div>
      )}
    </div>
  );
}
