import { useEffect } from 'react';

export default function useBeforeUnload(callback) {
  useEffect(() => {
    const handleTabClose = () => {
      callback();
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return null;
}
