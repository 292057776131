import { EntityStatus } from '../core/models/entity-status';
import { Enum } from '../core/models/object-enum';
import { BulkActionUIItem } from '../shared/bulk-actions-button-group/interface';

export class EventTypeDisplayStatusEnum extends Enum {
  static MAPPED = new EntityStatus(
    'Mapped',
    'MAPPED',
    'success',
    'Mapped Events are loaded to the Destination.'
  );
  static UNMAPPED = new EntityStatus(
    'Unmapped',
    'UNMAPPED',
    'warning',
    'Unmapped Events are marked as failed and not loaded to the Destination until mapped.'
  );
  static AUTO_MAPPED = new EntityStatus(
    'Auto Mapped',
    'AUTO_MAPPED',
  'success',
    'Hevo will replicate the Source schemas as is. You need not worry about the schema changes in the Source.'
  );
  static AUTO_MAPPING_FAILED = new EntityStatus(
    'Auto Mapping Failed',
    'AUTO_MAPPING_FAILED',
    'error',
    null
  );
  static AUTO_MAPPING_PROGRESS = new EntityStatus(
    'Mapping In Progress',
    'AUTO_MAPPING_PROGRESS',
    'warning',
    'Auto Mapping is in progress. Hevo will replicate the Source schemas as is. You need not worry about the schema changes in the Source.'
  );
  static MANUAL_MAPPING_IN_PROGRESS = new EntityStatus(
    'Mapping In Progress',
    'MANUAL_MAPPING_PROGRESS',
    'warning',
    'Hevo is updating the destination schema.'
  );
  static IGNORED = new EntityStatus(
    'Skipped',
    'IGNORED',
    'default',
    'Skipped Events are dropped and not loaded to the Destination.'
  );
}

export enum EventTypeStatus {
  MAPPED = 'MAPPED',
  UNMAPPED = 'UNMAPPED',
  IGNORED = 'IGNORED',
  MAPPING_IN_PROGRESS = 'MAPPING_IN_PROGRESS',
  INCOMPLETE = 'INCOMPLETE'
}

export enum EventTypeActionEnum {
  SKIP = 10,
  INCLUDE = 20,
  UNMAP = 30,
  RESET_SCHEMA = 40,
  RESIZE_STRING_FIELDS = 50
}

export const EVENT_TYPE_BULK_ACTIONS: BulkActionUIItem[] = [
  {
    id: 10,
    actionDisplayName: 'Skip'
  },
  {
    id: 20,
    actionDisplayName: 'Include'
  },
  {
    id: 30,
    actionDisplayName: 'Unmap'
  },
  {
    id: 40,
    actionDisplayName: 'Reset Schema'
  },
];

export interface EventTypeFieldsCount {
  ignored: number;
  mapped: number;
  total: number;
}

export enum FieldType {
  OBJECT = 'O',
  STRUCT = 'S',
  ARRAY = 'A'
}

export enum SchemaConstraintType {
  PRIMARY_KEY = 'PRIMARY_KEY'
}

export interface SchemaConstraint {
  constraintType: SchemaConstraintType;
  columns: string[];
}

export class SchemaMappingSummary {
  id: number;
  status: EventTypeStatus;
  autoMappingStatus: EntityStatus;
  destinationId: number;
  destinationSchemaName?: string;
  fields: MappingSummaryFields[];
  partitionRules?: PartitionMappingRule[];
  suggestion?: MappingSuggestion;
  validations?: any;
}

export class MappingSuggestion {
  fields: MappingSummaryFields[];
  destSchemaName: string;
}

export type MappingSummaryFields = any;
export type PartitionMappingRule = any;


export interface EventTypeWarningCount {
  incomplete: number;
  incompatible: number;
}

export enum EventTypeMappingBoxWarningType {
  WARNING,
  PRIMARY_KEY
}
