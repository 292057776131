import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

export function UnsubscribeNotificationShimmer() {
  return (
    <div className={clsx('box no-item-box', styles.noItemBox)}>
      <div
        className='shimmer shimmer-circle mb-3'
        style={{ width: '60px', height: '60px' }}
      />

      <div
        className='shimmer shimmer-line mb-3'
        style={{ width: '203px', height: '18px' }}
      />

      <div
        className='shimmer shimmer-line'
        style={{ width: '351px', height: '14px' }}
      />

      <div className='center-flex-row justify-center mt-6'>
        <div
          className='shimmer shimmer-line'
          style={{ width: '125px', height: '30px' }}
        />

        <div
          className='shimmer shimmer-line ml-4'
          style={{ width: '172px', height: '30px' }}
        />
      </div>
    </div>
  );
}
