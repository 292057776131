import { SourceFormFieldDefinition } from '../models';
import { ACTIVE_CAMPAIGN_SOURCE_TEMPLATE } from './active-campaign';
import { BIGCOMMERCE_SOURCE_TEMPLATE } from './bigcommerce';
import { BRAZE_SOURCE_TEMPLATE } from './braze';
import { BUILD_KITE_SOURCE_TEMPLATE } from './build-kite';
import { CLICKUP_SOURCE_TEMPLATE } from './clickup';
import { CONTENTFUL_SOURCE_TEMPLATE } from './contentful';
import { DEFAULT_TEMPLATE } from './default';
import { DRIP_SOURCE_TEMPLATE } from './drip';
import { FRESH_SALES_SOURCE_TEMPLATE } from './fresh-sales';
import { FRESH_SERVICE_SOURCE_TEMPLATE } from './fresh-service';
import { FRESHDESK_SOURCE_TEMPLATE } from './freshdesk';
import { HARVEST_SOURCE_TEMPLATE } from './harvest';
import { HIVE_SOURCE_TEMPLATE } from './hive';
import { HUBSPOT_SOURCE_TEMPLATE } from './hubspot';
import { NETSUITE_ERP_SOURCE_TEMPLATE } from './netsuite-erp';
import { QUANTIVE_SOURCE_TEMPLATE } from './quantive';
import { QUICKBASE_SOURCE_TEMPLATE } from './quickbase';
import { SALESLOFT_TEMPLATE } from './salesloft';
import { SENTRY_SOURCE_TEMPLATE } from './sentry';
import { SHIPSTATION_SOURCE_TEMPLATE } from './shipstation';
import { STAMPED_SOURCE_TEMPLATE } from './stamped';
import { SWELL_SOURCE_TEMPLATE } from './swell';
import { TOGGL_TRACK_SOURCE_TEMPLATE } from './toggle-track';
import { TRELLO_SOURCE_TEMPLATE } from './trello';
import { USERVOICE_SOURCE_TEMPLATE } from './uservoice';
import AccessTokenTemplate from './utils/access-token';
import APIKeyTemplate from './utils/api-key';
import APITokenTemplate from './utils/api-token';
import { NETSUITE_ANALYTICS_SOURCE_TEMPLATE } from './netsuite-analytics';
import { WORKABLE_SOURCE_TEMPLATE } from './workable';
import { ZENDESK_SOURCE_TEMPLATE } from './zendesk';
import { RECURLY_V2_TEMPLATE } from './recurly';

// sample for validation array
// validations: [
//   {
//     type: "required",
//     params: ["this field is required"]
//   },
//   {
//     type: "min",
//     params: [5, "name cannot be less than 5 characters"]
//   },
//   {
//     type: "max",
//     params: [10, "name cannot be more than 10 characters"]
//   }
// ]

export const GENERIC_SOURCE_TEMPLATES: {
  [key: string]: Array<Array<SourceFormFieldDefinition>>;
} = {
  INTERCOM: DEFAULT_TEMPLATE,
  SALESFORCE: DEFAULT_TEMPLATE,
  SALESFORCE_V2: DEFAULT_TEMPLATE,
  PIPEDRIVE: DEFAULT_TEMPLATE,
  QUICKBOOKS: DEFAULT_TEMPLATE,
  MAILCHIMP: DEFAULT_TEMPLATE,
  HELPSCOUT: DEFAULT_TEMPLATE,
  SURVEY_MONKEY: DEFAULT_TEMPLATE,
  TYPEFORM_II: DEFAULT_TEMPLATE,
  OUTREACH: DEFAULT_TEMPLATE,
  ZOHO_CRM: DEFAULT_TEMPLATE,
  VINCERE: DEFAULT_TEMPLATE,
  YOUTUBE_DATA: DEFAULT_TEMPLATE,

  HUBSPOT: HUBSPOT_SOURCE_TEMPLATE,
  DRIP: DRIP_SOURCE_TEMPLATE,
  SHIPSTATION: SHIPSTATION_SOURCE_TEMPLATE,
  STAMPED: STAMPED_SOURCE_TEMPLATE,
  SWELL: SWELL_SOURCE_TEMPLATE,
  ZENDESK: ZENDESK_SOURCE_TEMPLATE,
  TOGGL_TRACK: TOGGL_TRACK_SOURCE_TEMPLATE,
  WORKABLE: WORKABLE_SOURCE_TEMPLATE,
  QUICKBASE: QUICKBASE_SOURCE_TEMPLATE,
  TRELLO: TRELLO_SOURCE_TEMPLATE,
  HARVEST: HARVEST_SOURCE_TEMPLATE,
  FRESHDESK: FRESHDESK_SOURCE_TEMPLATE,
  BIGCOMMERCE: BIGCOMMERCE_SOURCE_TEMPLATE,
  CONTENTFUL: CONTENTFUL_SOURCE_TEMPLATE,
  USERVOICE: USERVOICE_SOURCE_TEMPLATE,
  ACTIVE_CAMPAIGN: ACTIVE_CAMPAIGN_SOURCE_TEMPLATE,
  NETSUITE_ERP: NETSUITE_ERP_SOURCE_TEMPLATE,
  BRAZE: BRAZE_SOURCE_TEMPLATE,
  CLICKUP: CLICKUP_SOURCE_TEMPLATE,
  HIVE: HIVE_SOURCE_TEMPLATE,
  QUANTIVE: QUANTIVE_SOURCE_TEMPLATE,
  BUILDKITE: BUILD_KITE_SOURCE_TEMPLATE,
  FRESH_SALES: FRESH_SALES_SOURCE_TEMPLATE,
  FRESH_SERVICE: FRESH_SERVICE_SOURCE_TEMPLATE,
  SENTRY: SENTRY_SOURCE_TEMPLATE,
  NETSUITE_ANALYTICS: NETSUITE_ANALYTICS_SOURCE_TEMPLATE,
  SALESLOFT: SALESLOFT_TEMPLATE,
  RECURLY_V2: RECURLY_V2_TEMPLATE,

  INVOICED: APIKeyTemplate({
    label: 'Invoiced API Key',
    hint: 'Enter the API key. ',
    sbsDocLink: '/sources/finance-&-accounting/invoiced/#obtaining-the-api-key'
  }),
  AFTERSHIP: APIKeyTemplate({
    label: 'AfterShip API Key',
    hint: 'Enter the API key. ',
    sbsDocLink: '/sources/e-commerce/aftership/#obtaining-the-api-key'
  }),
  KLAVIYO: APIKeyTemplate({
    label: 'Private API Key',
    hint: 'Private API Key for your Klaviyo account.',
    sbsDocLink: '/sources/marketing/klaviyo/#generating-the-api-key-in-klaviyo'
  }),
  KLAVIYO_V2: APIKeyTemplate({
    label: 'Private API Key',
    hint: 'Private API Key for your Klaviyo account.',
    sbsDocLink: '/sources/marketing/klaviyo/#generating-the-api-key-in-klaviyo'
  }),
  DELIGHTED: APIKeyTemplate({
    hint: 'Enter the private API key that is linked to your account. ',
    sbsDocLink: '/sources/marketing/delighted/#obtaining-the-api-key'
  }),
  ZENDESK_SELL: APITokenTemplate({
    hint: 'Enter the API token generated from Settings → OAuth → Access Tokens → Add Access Token → Describe & Save. Copy the token which will be generated.'
  }),
  DIXA: APITokenTemplate({
    hint: 'The API token retrieved from your Dixa account, to allow Hevo to access your data.',
    sbsDocLink: '/sources/sns-analytics/dixa/#obtaining-the-api-token'
  }),
  RECHARGE: APITokenTemplate({
    hint: 'The API token retrieved from your Recharge account, to allow Hevo to access your data.',
    sbsDocLink: '/sources/mkt-analytics/recharge/#creating-the-api-token'
  }),
  PAGER_DUTY: APITokenTemplate({
    label: 'API Key',
    hint: 'The API Key generated from Integrations → API Access Key → Create New API Key option in PagerDuty.',
    externalDocLink: 'https://support.pagerduty.com/docs/api-access-keys#rest-api-keys'
  }),
  ONFLEET: APITokenTemplate({
    label: 'API Key',
    hint: 'The API Key generated from Settings → API and Webhooks → Create Key.'
  }),
  MISSIVE: APITokenTemplate({
    hint: "Select the Profile button on the Home Page's bottom left corner. Click on API → Create New Token. Enter the Token Description & Click Ok. Now copy and save it."
  }),
  LEMLIST: APITokenTemplate({
    label: 'API Key',
    hint: 'The API key generated from Settings → Integrations → Generate the API key.',
    sbsDocLink: '/sources/mkt-analytics/lemlist/#obtaining-the-api-key'
  }),
  OPSGENIE: APITokenTemplate({
    hint: 'The API key generated from Profile Settings → API Key Management → <b>Add New API key</b>. Name the API key and select all the access rights.'
  }),
  PINGDOM: APITokenTemplate({
    hint: 'The API key generated from Settings → Pingdom API → Add API token.',
    sbsDocLink: '/sources/engg-analytics/pingdom/#obtaining-the-api-token'
  }),
  CLOSE: APITokenTemplate({
    hint: 'Enter the API Token from “Home“ → “Settings“ → “API Keys“ → “Click New API Key & Copy“.'
  }),
  QUICKBOOKS_TIME: APITokenTemplate({
    label: 'Access Token',
    hint: 'Enter the access token that is linked to your account.',
    docLink: '/sources/'
  }),
  MAILSHAKE: APIKeyTemplate({
    hint: 'Enter the API token generated from Integrations → API → Create your Key. Copy the Key which will be generated.'
  }),
  PANDA_DOC: APITokenTemplate({
    label: 'API Key',
    hint: 'Enter the API token generated from Developer dashboard → Configuration → API Keys. Generate the Production key. Save it and copy the key which will be generated.'
  }),
  SPARK_POST: APITokenTemplate({
    hint: 'Enter the API key generated from Configuration → API Keys. Click on Create API key and provide the API Key name and permissions. Save it and copy the key which will be generated.'
  }),
  STREAK: APIKeyTemplate({
    hint: 'The API key obtained from Gmail → Streak → Integrations → Streak API → Get API Key.'
  }),
  SLACK: APITokenTemplate({
    label: 'OAuth Token',
    hint: 'The OAuth Token generated from Apps → Oauth and Permissions → Copy Bot User OAuth Access Token.'
  }),
  GITLAB: AccessTokenTemplate({
    hint: 'The Personal Access Token obtained from the Edit profile → Access Tokens → Name the Token → Remove Expiration date → Select scopes → Create personal access token → Copy.'
  }),
  PRODUCTBOARD: AccessTokenTemplate({
    hint: 'The access token obtained from Integrations → Public API → Access token → <strong>+</strong> → Copy'
  }),
  WRIKE: AccessTokenTemplate({
    hint: 'The Permanent Access Token obtained from your profile → Apps & Integrations → API → Name the App → Permanent Access Token → Create Token → Copy & Save.'
  }),
  HUB_PLANNER: APIKeyTemplate({
    hint: 'The API key obtained from Settings → Hub Planner API → Enable API → Generate Key.'
  }),
  APIFY: APITokenTemplate({
    hint: 'The API token obtained from Settings → Integrations → Add Token → Add description → Save.'
  })
};
