import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import HdFormikTextField from '../../../../../components/FormikElements/HdFormikTextField';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import HdFormikPasswordField from '../../../../../components/FormikElements/HdFormikPasswordField';
import HdFormikDropDown from '../../../../../components/FormikElements/HdFormikDropDown';
import { ConfigByType } from '../configByType';
import CustomOptionWithSubtext from '../../../../../components/DropDownCustomOptions/CustomOptionWithSubtext';
import { ConfigDestinationTypeBaseProps } from '../interface';
import { HdRadio, HdRadioGroup } from '../../../../../components/UIElements';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import {
  TRACKER_SOURCE_S3_NEW_CONFIG_FILE_FORMAT,
  TRACKER_SOURCE_S3_NEW_CONFIG_ON_USING_IAM_ROLE
} from '../../../../../../app/nodes/tracking';
import { S3DestFileFormat } from './constants';
import { getHostNameHelpText } from './utils';
import { AWSAuthenticationType } from '../../../../pipeline/create/ConfigSourceType/AWS/model';
import { AnsibleBoolean } from '../../../../../../app/core/models/ansible-config';

declare const __ENABLE_PARQUET_IN_S3__;

interface S3DestinationFormikProps {
  amazonResourceName: string;
  authType: AWSAuthenticationType;
  externalId: string;
  awsAccessKey: string;
  secretAccessKey: string;
  bucket: string;
  bucketRegion: string;
  compressionSupported: object;
  fileFormat: S3DestFileFormat;
}

export default function S3New({
  maskSecretFields,
  destinationTypeIdentifier,
  hevoEntityFor,
  isEditing
}: ConfigDestinationTypeBaseProps) {
  const memoizedGetRegions: any[] = useMemo(ConfigByType.S3_NEW.regionList, []);
  const memoizedGetSupportedCompressions: any[] = useMemo(
    ConfigByType.S3_NEW.getS3NewSupportedCompressionList,
    []
  );

  const formikProps = useFormikContext<S3DestinationFormikProps>();

  const destinationTypeTrackingProps = {
    destinationType: destinationTypeIdentifier,
    context: hevoEntityFor
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className='collapsible-group-box collapsible-group mb-5 pb-0'>
            <HdFormControl>
              <Field
                name='authType'
                component={ConnectionSettingsRadioGroup}
                destinationTypeIdentifier={destinationTypeIdentifier}
                isEditing={isEditing}
              />
            </HdFormControl>

            <Grid container spacing={2}>
              {formikProps.values.authType === AWSAuthenticationType.IAM_ROLE ? (
                <>
                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='amazonResourceName'
                        label='IAM Role ARN'
                        required
                        masked={maskSecretFields}
                        component={HdFormikPasswordField}
                        helperText={getHostNameHelpText(destinationTypeTrackingProps)}
                      />
                    </HdFormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='externalId'
                        label='External Id'
                        required
                        masked={maskSecretFields}
                        component={HdFormikPasswordField}
                        helperText='The external ID that you specified while creating the IAM role'
                      />
                    </HdFormControl>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='awsAccessKey'
                        label='Access Key ID'
                        required
                        component={HdFormikTextField}
                        helperText='The ID of your Amazon S3 bucket’s access key'
                      />
                    </HdFormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='awsSecretAccessKey'
                        label='Secret Access Key '
                        required
                        masked={maskSecretFields}
                        component={HdFormikPasswordField}
                        helperText='The secret key of your Amazon S3 bucket'
                      />
                    </HdFormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='bucket'
                    label='Bucket Name'
                    required
                    component={HdFormikTextField}
                    helperText='The name of the Amazon S3 bucket that holds your data'
                  />
                </HdFormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='bucketRegion'
                    label='Bucket Region'
                    required
                    idAccessor='value'
                    valueAccessor='value'
                    options={memoizedGetRegions}
                    component={HdFormikDropDown}
                    helperText='The AWS region of your S3 bucket'
                    CustomOption={CustomOptionWithSubtext}
                  />
                </HdFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='prefix'
                    disabled={isEditing}
                    label='Prefix'
                    helperText='Subdirectory under the bucket where the data will be synced'
                    component={HdFormikTextField}
                  />
                </HdFormControl>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* Advanced Config */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className='collapsible-group-box collapsible-group mb-5 pb-0'>
            <HdFormControl>
              <Field
                name='fileFormat'
                component={FileFormatRadioGroup}
                isEditing={isEditing}
                destinationTypeIdentifier={destinationTypeIdentifier}
              />
            </HdFormControl>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='compressionSupported'
                    label='Compression Support'
                    required
                    disabled={isEditing}
                    idAccessor='value'
                    valueAccessor='value'
                    options={
                      formikProps.values.fileFormat === S3DestFileFormat.PARQUET
                        ? memoizedGetSupportedCompressions.slice(0, 2)
                        : memoizedGetSupportedCompressions.slice(1, 3)
                    }
                    component={HdFormikDropDown}
                  />
                </HdFormControl>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

function ConnectionSettingsRadioGroup({ field, form, isEditing, destinationTypeIdentifier }) {
  const analyticsTracker = useAnalyticsTracker();
  const formikProps = useFormikContext<S3DestinationFormikProps>();

  const connectionURIModeChange = authType => {
    analyticsTracker.eventTrack({
      action: TRACKER_SOURCE_S3_NEW_CONFIG_ON_USING_IAM_ROLE,
      properties: {
        selectedOption:
          authType === AWSAuthenticationType.IAM_ROLE
            ? 'connected_using_iam_role'
            : 'connected_using_access_credentials',
        destinationType: destinationTypeIdentifier
      }
    });

    const values = {
      ...form.values,
      authType
    };

    form.setValues(values);
  };

  return (
    <HdRadioGroup
      {...field}
      name='isConnectionUri'
      onChange={connectionURIModeChange}
      className='mb-5'
    >
      <HdRadio
        key='iam-role'
        value={AWSAuthenticationType.IAM_ROLE}
        disabled={isEditing}
        dataId='connection-settings-role'
        checked={formikProps.values.authType === AWSAuthenticationType.IAM_ROLE}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Connect using IAM Role</Typography>
        </div>
      </HdRadio>

      <HdRadio
        key='access-credentials'
        value={AWSAuthenticationType.ACCESS_CREDENTIALS}
        className='ml-5'
        disabled={isEditing}
        dataId='connection-settings-credentials'
        checked={formikProps.values.authType === AWSAuthenticationType.ACCESS_CREDENTIALS}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Connect using Access Credentials</Typography>
        </div>
      </HdRadio>
    </HdRadioGroup>
  );
}

function FileFormatRadioGroup({ field, form, isEditing, destinationTypeIdentifier }) {
  const analyticsTracker = useAnalyticsTracker();
  const formikProps = useFormikContext<S3DestinationFormikProps>();

  const isParquetFileDisabled = useMemo(
    () =>
      typeof __ENABLE_PARQUET_IN_S3__ === 'undefined' ||
      __ENABLE_PARQUET_IN_S3__ !== AnsibleBoolean.TRUE,
    []
  );
  const fileFormatTypeChange = e => {
    const fileFormat = e;

    analyticsTracker.eventTrack({
      action: TRACKER_SOURCE_S3_NEW_CONFIG_FILE_FORMAT,
      properties: {
        selectedOption: fileFormat === S3DestFileFormat.PARQUET ? 'parquet-type' : 'jsonl-type',
        destinationType: destinationTypeIdentifier
      }
    });

    const values = {
      ...form.values,
      fileFormat: e,
      compressionSupported: ''
    };

    form.setValues(values);
  };

  return (
    <HdRadioGroup
      {...field}
      name='fileFormat'
      label='Select the File Format'
      onChange={fileFormatTypeChange}
      className='mb-5'
      isEditing={isEditing}
    >
      <HdRadio
        key='parquet'
        value={S3DestFileFormat.PARQUET}
        disabled={isEditing || isParquetFileDisabled}
        dataId='parquet-type'
        checked={formikProps.values.fileFormat === S3DestFileFormat.PARQUET}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Parquet</Typography>
        </div>
      </HdRadio>

      <HdRadio
        key='jsonl'
        value={S3DestFileFormat.JSON}
        className='ml-5'
        disabled={isEditing}
        dataId='jsonl-type'
        checked={formikProps.values.fileFormat === S3DestFileFormat.JSON}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>JSONL</Typography>
        </div>
      </HdRadio>
    </HdRadioGroup>
  );
}
