import { AuthAccountEnvironmentMetaData } from '../source-type/source-type';


export interface AuthAccountProvider {
  type: string;
  typeDisplay: string;
  entityType?: AccountEntityTypes;
  linkedAccount?: string;
  connectorType?: string;
  authAccountEnvironmentsMetaData?: { [key: string]: AuthAccountEnvironmentMetaData };
  providerClass?: string;
  icon?: string;
  isAccountTypeGoogle?: boolean;
}

export enum AccountEntityTypes {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION',
  TARGET = 'TARGET'
}
