export const ON_DEMAND_CREDIT_COLOR_STEPS = [
  {
    progress: 0,
    consumed: 'success',
    available: 'success-faded-1'
  },
  {
    progress: 0.7,
    consumed: 'warning',
    available: 'warning-faded'
  },
  {
    progress: 0.9,
    consumed: 'error',
    available: 'error-faded-1'
  }
];

export const GOTO_ON_DEMAND_CREDIT_SECTION_QUERY_PARAM = 'goto_on_demand_credit_section';

export const ON_DEMAND_CREDIT_DOC_LINK =
  '/account-management/billing/on-demand-purchases/on-demand-credit/';

export const ON_DEMAND_CREDIT_INTRODUCTION_SHOWN = 'payment.onDemandCredit.introductionShown';

export const MOCK_ON_DEMAND_CREDIT_RAW_DATA = {
  data: {
    assigned_credit: {
      events: 1000000,
      cost: 6.0
    },
    available_credit: {
      events: 5,
      cost: 50.0
    },
    unpaid_used_credit: {
      events: 50,
      cost: 50.0
    },
    total_used_credit: {
      events: 0,
      cost: 0.0
    },
    allowed_credit: {
      events: 60000004,
      cost: 360.0
    },
    cost_per_million_events: 6.0,
    period_end: 1648909538000,
    period_start: 1646231138000,
    updated_ts: 1646645616000,
    credit_in_use: false,
    credit_limit_in_percent: 60,
    credit_usage_history: [
      {
        cycle_start: 1646933566000,
        cycle_end: 1649611966000,
        usage: 7200004,
        cost: 100
      },
      {
        cycle_start: 1644514366000,
        cycle_end: 1646933566000,
        usage: 8000000,
        cost: 120
      },
      {
        cycle_start: 1642095166000,
        cycle_end: 1644514366000,
        usage: 0,
        cost: 0
      }
    ]
  }
};

export const ON_DEMAND_CREDIT_INTRO_ILLUSTRATION =
  'https://res.cloudinary.com/hevo/image/upload/v1646665530/thumbnails/on-demand-credit-introduction-illustration_iimnkh.svg';
