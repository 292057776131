import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { HevoEntity } from '../../../../app/core/models/hevo-entity';
import {
  DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2,
  PIPELINE_POLICY_MESSAGE_PREFIX,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_DISABLED,
  TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_ENABLED
} from '../../../../app/dialog/execution-policy-dialog/constants';
import { SchedulerType } from '../models';
import useScheduler from '../useScheduler';
import {
  ExecutionPolicyContext,
  getExecutionPolicyFromRawData,
  getPolicyMessage,
  getPolicyRawData
} from '../utils';
import {
  FrequencyChip,
  PolicyTypeEnum
} from '../../../../app/dialog/execution-policy-dialog/models';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { QUERY_MODE_DOC_URL } from '../../../../app/core/constants';
import { HdTooltip, HdDocLink, HdIcon, HdPane } from '../../../components/UIElements';
import HdSwitchWrapper from '../../../components/UIElements/HdSwitchWrapper';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import Scheduler from '../index';

const DEFAULT_TIME = 6;

export function FullLoadObjectExecutionPolicy({
  entityDetails,
  fullLoadFormikRef,
  policyObj,
  disableSubmit,
  dataIdGenerator,
  onChange,
  handleSubmit
}) {
  const [fullLoadToggle, setFullLoadToggle] = useState(false);

  const [frequencyMatchErrorMessage, setFrequencyMatchErrorMessage] = useState('');

  const { eventTrack } = useAnalyticsTracker();

  const {
    selectedOption: fullLoadSelectedOption,
    policyObj: fullLoadPolicyObj,
    setPolicyObj: setFullLoadPolicyObj,
    isCustom: isFullLoadCustom,
    tooltipData,
    setTooltipData,
    setIsCustom,
    handleOptionSelection: handleFullLoadOptionSelection
  } = useScheduler({
    policy: getExecutionPolicyFromRawData(
      entityDetails.full_load_frequency,
      { ...entityDetails.frequency_config, max_frequency: 86400 }, // to remove max_frequency restrictions of ingestion policy on FLO policy
      DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2,
      DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2
    ),
    entity: HevoEntity.PIPELINE.value,
    schedulerType: SchedulerType.FrequencyType,
    formikRef: fullLoadFormikRef
  });

  useEffect(() => {
    onChange(fullLoadToggle ? fullLoadPolicyObj : null);
  }, [fullLoadToggle, fullLoadPolicyObj]);

  useEffect(() => {
    setFullLoadToggle(true);
    if (fullLoadPolicyObj.type === PolicyTypeEnum.NO_SCHEDULE) {
      setFullLoadPolicyObj(prev => ({
        ...prev,
        frequencyValue: DEFAULT_TIME,
        type: PolicyTypeEnum.FREQUENCY
      }));
      const selectedFrequencyOption = policyObj.suggestedFrequencies.find(
        (frequency: any) =>
          frequency.value === policyObj.frequencyValue &&
          frequency.frequencyType.value === policyObj.frequencyType.value
      );
      if (!selectedFrequencyOption) {
        setIsCustom(true);
      }
      setFullLoadToggle(false);
    }
  }, []);

  useEffect(() => {
    showFrequencyMatchError();
  }, [policyObj, fullLoadPolicyObj, fullLoadToggle]);

  useLayoutEffect(() => {
    const disableFrequencies = () => {
      const { frequencyValue, frequencyType } = policyObj;

      const fullLoadDisplayedFrequencies = fullLoadPolicyObj.displayedFrequencies.map(
        (frequency: FrequencyChip) => {
          const disabled =
            typeof frequency.value !== 'string' &&
            frequency.frequencyType.minSeconds * frequency.value <
              frequencyValue * frequencyType.minSeconds;
          return {
            ...frequency,
            disabled
          };
        }
      );

      setFullLoadPolicyObj(prev => ({
        ...prev,
        displayedFrequencies: fullLoadDisplayedFrequencies
      }));
    };

    disableFrequencies();
  }, [policyObj.frequencyValue, fullLoadPolicyObj.displayedFrequencies.length]);

  const showFrequencyMatchError = () => {
    const { frequencyValue, frequencyType } = policyObj;
    const { frequencyValue: fullLoadFrequencyValue, frequencyType: fullLoadFrequencyType } =
      fullLoadPolicyObj;
    const frequency = frequencyValue * frequencyType.minSeconds;
    const floFrequency = fullLoadFrequencyValue * fullLoadFrequencyType.minSeconds;

    if (fullLoadToggle && floFrequency < frequency) {
      setFrequencyMatchErrorMessage(
        'The Full Load objects ingestion frequency cannot be less than the Pipeline frequency.'
      );
      disableSubmit(true);
      setFullLoadToggle(true);
    } else {
      setFrequencyMatchErrorMessage(null);
      disableSubmit(false);
    }
  };

  const trackScheduleClick = (action: string) => {
    let selectedData = getPolicyRawData(fullLoadPolicyObj);
    if (selectedData.type === PolicyTypeEnum.CRON && Array.isArray(selectedData.time_hour_list)) {
      selectedData = {
        ...selectedData,
        time_hour_list: selectedData.time_hour_list.join(','),
        time_hour_list_length: selectedData.time_hour_list.length
      };
    }

    eventTrack({
      action,
      properties: {
        ...selectedData,
        id: entityDetails.id
      }
    });
  };

  const toggleFullLoadObject = e => {
    setFullLoadToggle(e.target.checked);
    if (e.target.checked) {
      trackScheduleClick(
        TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_ENABLED
      );
    } else {
      trackScheduleClick(
        TRACKER_PIPELINE_EXECUTION_POLICY_DIALOG_FULL_LOAD_OBJECT_FREQUENCY_DISABLED
      );
    }
  };

  const fullLoadPolicyMessage = useMemo(
    () => getPolicyMessage(fullLoadPolicyObj),
    [fullLoadPolicyObj]
  );

  return (
    <div className='collapsible-group-box collapsible-group pb-5 mb-3'>
      <HdSwitchWrapper
        id='full-load'
        dataId='full-load'
        onChange={toggleFullLoadObject}
        label=''
        switchProps={{
          checked: fullLoadToggle
        }}
      >
        <span className='flex-col'>
          <span className='center-flex-row text-medium text-default'>
            <span>
              {fullLoadToggle
                ? 'Change Full Load Object Ingestion Schedule'
                : 'Enable Full Load Object Ingestion Schedule'}
            </span>

            <HdTooltip
              title={
                <div>
                  <span className='text-body-2'>
                    For Full Load objects, Hevo ingests the entire data in each Pipeline run. Proper
                    scheduling can reduce your billable Events for these Objects.{' '}
                  </span>

                  <HdDocLink
                    dataId={dataIdGenerator('full-load-info')}
                    section='full-load'
                    docLink={`${QUERY_MODE_DOC_URL}/#full-load`}
                  />
                </div>
              }
              disableInteractive={false}
              mode='surface'
              placement='right'
            >
              <HdIcon name='info' onClick={e => e.preventDefault()} className='text-info ml-1' />
            </HdTooltip>
          </span>

          <span className='text-secondary mt-1'>
            {fullLoadToggle
              ? 'This Ingestion schedule is activated for all Full Load objects.'
              : 'This Ingestion schedule applies to all Full Load objects.'}
          </span>
        </span>
      </HdSwitchWrapper>

      {fullLoadToggle && (
        <>
          <div className='mt-5'>
            <SchedulerOptions
              entity={HevoEntity.PIPELINE.value}
              options={fullLoadPolicyObj.displayedFrequencies}
              onScheduleOptionSelected={handleFullLoadOptionSelection}
              selectedOption={fullLoadSelectedOption}
              showButtonToolTip
              tooltipLearnMoreLink={`${QUERY_MODE_DOC_URL}/#full-load`}
              tooltipMessage='Full Load object ingestion schedule cannot be less than the Pipeline schedule.'
            />
          </div>

          <ExecutionPolicyContext.Provider
            value={{
              tooltipData,
              setTooltipData
            }}
          >
            <Scheduler
              schedulerType={SchedulerType.FrequencyType}
              entity={HevoEntity.PIPELINE.value}
              policyObj={fullLoadPolicyObj}
              setPolicyObj={setFullLoadPolicyObj}
              ref={fullLoadFormikRef}
              handleSubmit={handleSubmit}
              isCustom={isFullLoadCustom}
            />
          </ExecutionPolicyContext.Provider>

          {fullLoadPolicyMessage && !frequencyMatchErrorMessage ? (
            <ExecutionPolicyMessage
              className='mt-7'
              policyMessage={fullLoadPolicyMessage}
              messagePrefix={PIPELINE_POLICY_MESSAGE_PREFIX}
            />
          ) : null}

          {frequencyMatchErrorMessage && (
            <HdPane
              dataId={dataIdGenerator('frequency_match_error')}
              icon='error-filled'
              variant='error-faded'
              className='mb-0 mt-5'
            >
              {frequencyMatchErrorMessage}
            </HdPane>
          )}
        </>
      )}
    </div>
  );
}
