import { Field, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { HdAuthRequestType } from '../HdAuth/models';
import { ResetPasswordRequestType } from '../models';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import HdFormikPasswordField from '../../../components/FormikElements/HdFormikPasswordField';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdIcon, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import ErrorFocus from '../../../utils/ErrorFocus';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdAuth } from '../HdAuth';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useResetPassword } from '../useResetPassword';
import { StrongPasswordRulesWrapper } from '../../../components/StrongPasswordRulesWrapper';
import { PASSWORD_RULES_SET } from '../../../components/StrongPasswordRulesWrapper/contants';
import { strongPasswordRegex } from '../../../components/StrongPasswordRulesWrapper/ruleValidators';
import { StrongPasswordFieldWrapper } from '../../../components/StrongPasswordRulesWrapper/StrongPasswordFieldWrapper';

export interface ResetPasswordProps {
  featuredCustomers: FeaturedCustomers;
}

const initialValues = {
  newPassword: '',
  confirmNewPassword: ''
};

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .strict(true)
    .trim('Password cannot start or end with whitespaces')
    .matches(strongPasswordRegex)
    .required('Please enter a new password.'),
  confirmNewPassword: Yup.string()
    .strict(true)
    .trim('Password cannot start or end with whitespaces')
    .required('Please re-enter the new password.')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'New Password and Confirm Password do not match. Please re-enter the values'
    )
});

export function ResetPassword() {
  const { getFeaturedCustomers } = useFeaturedCustomers();

  return (
    <HdResolvedComponent
      Component={ResetPasswordInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
    />
  );
}

export function ResetPasswordInner({ featuredCustomers }: ResetPasswordProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);
  const formikRef = useRef<FormikProps<{ newPassword: string }>>();
  const { resetPassword } = useResetPassword();
  const { search } = useLocation();

  const code = new URLSearchParams(search).get('code');

  const handleSubmit = values => {
    if (!formikRef.current.isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setFormError(null);
    resetPassword(code, values.newPassword, ResetPasswordRequestType.FORGOT_PASSWORD).then(
      () => {
        setIsSubmitting(false);
      },
      error => {
        setIsSubmitting(false);

        const errorMessage = getErrorMessageFromObj(error);

        setFormError(errorMessage);
      }
    );
  };

  const dataIdGenerator = getDataIdGenerator('reset-password-page');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.RESET_PASSWORD}
      featuredCustomers={featuredCustomers}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleSubmit(values)}
        validateOnMount
        innerRef={formikRef}
      >
        {props => (
          <form noValidate onSubmit={props.handleSubmit}>
            <ErrorFocus formik={props} onFormError={() => {}} />

            <HdFormControl>
              <StrongPasswordFieldWrapper
                title={StrongPasswordRulesWrapper}
                rules={PASSWORD_RULES_SET}
                value={formikRef.current?.values?.newPassword}
              >
                <Field
                  name='newPassword'
                  label='New Password'
                  component={HdFormikPasswordField}
                  startAdornment={<HdIcon name='password' />}
                  helperText='Enter a new password.'
                  required
                />
              </StrongPasswordFieldWrapper>
            </HdFormControl>

            <HdFormControl className='mb-0'>
              <Field
                name='confirmNewPassword'
                label='Confirm Password'
                component={HdFormikPasswordField}
                startAdornment={<HdIcon name='password' />}
                helperText='Re-enter the new password.'
                required
              />
            </HdFormControl>

            {!!formError && (
              <HdPane
                dataId={dataIdGenerator('form-error')}
                className='mb-1 w-100'
                variant='error-faded'
                icon='error-filled'
                iconClasses='text-error'
                disableHide
              >
                <div className='text-default'>{formError}</div>
              </HdPane>
            )}

            <HdButton
              dataId={dataIdGenerator('submit')}
              type='submit'
              size='lg'
              className='w-100 mt-4'
              showProgress={isSubmitting}
              disabled={!props.isValid || isSubmitting}
            >
              {isSubmitting ? 'Updating Password' : 'Reset Password'}
            </HdButton>
          </form>
        )}
      </Formik>
    </HdAuth>
  );
}
