// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.qrWrapper_nuD2c {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n}\n.qrWrapper_nuD2c .qrContainer_B5QdU {\n  display: inline-flex;\n}\n.qrWrapper_nuD2c .qrContainer_B5QdU img {\n  width: 200px;\n}\n.qrWrapper_nuD2c .loader__yQcT {\n  animation: spin__nCKk 1s linear infinite;\n}\n@keyframes spin__nCKk {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.qrWrapper_nuD2c .qrLoading__xR77 {\n  width: 200px;\n  height: 200px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: var(--surface-bg-overlay-color);\n  border-radius: 6px;\n  border: solid 1px var(--divider-color);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qrWrapper": "qrWrapper_nuD2c",
	"qrContainer": "qrContainer_B5QdU",
	"loader": "loader__yQcT",
	"spin": "spin__nCKk",
	"qrLoading": "qrLoading__xR77"
};
export default ___CSS_LOADER_EXPORT___;
