export enum GoogleAuthRequestType {
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  SETUP_ACCOUNT = 'SETUP_ACCOUNT',
  LOGIN = 'LOGIN'
}

export enum ResetPasswordRequestType {
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  SETUP_ACCOUNT = 'SETUP_ACCOUNT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD'
}

export enum RestrictedTokenType {
  SETUP_TFA = 'SETUP_TFA',
  LOGIN_WITH_OTP = 'LOGIN_WITH_OTP',
  HEVO_LOGIN = 'HEVO_LOGIN',
  SAML_LOGIN = 'SAML_LOGIN'
}
