import { Timezone } from './models/timezone';

export const TIMEZONES: Timezone[] = [
  { id: 'Africa/Abidjan', offset: '+00:00' },
  { id: 'Africa/Accra', offset: '+00:00' },
  { id: 'Africa/Algiers', offset: '+01:00' },
  { id: 'Africa/Bissau', offset: '+00:00' },
  { id: 'Africa/Cairo', offset: '+02:00' },
  { id: 'Africa/Casablanca', offset: '+01:00' },
  { id: 'Africa/Ceuta', offset: '+01:00' },
  { id: 'Africa/El_Aaiun', offset: '+00:00' },
  { id: 'Africa/Johannesburg', offset: '+02:00' },
  { id: 'Africa/Khartoum', offset: '+02:00' },
  { id: 'Africa/Lagos', offset: '+01:00' },
  { id: 'Africa/Maputo', offset: '+02:00' },
  { id: 'Africa/Monrovia', offset: '+00:00' },
  { id: 'Africa/Nairobi', offset: '+03:00' },
  { id: 'Africa/Ndjamena', offset: '+01:00' },
  { id: 'Africa/Tripoli', offset: '+02:00' },
  { id: 'Africa/Tunis', offset: '+01:00' },
  { id: 'Africa/Windhoek', offset: '+02:00' },
  { id: 'America/Adak', offset: '−10:00' },
  { id: 'America/Anchorage', offset: '−09:00' },
  { id: 'America/Araguaina', offset: '−03:00' },
  { id: 'America/Asuncion', offset: '−04:00' },
  { id: 'America/Bahia', offset: '−03:00' },
  { id: 'America/Barbados', offset: '−04:00' },
  { id: 'America/Belem', offset: '−03:00' },
  { id: 'America/Belize', offset: '−06:00' },
  { id: 'America/Boa_Vista', offset: '−04:00' },
  { id: 'America/Bogota', offset: '−05:00' },
  { id: 'America/Boise', offset: '−07:00' },
  { id: 'America/Buenos_Aires', offset: '−03:00' },
  { id: 'America/Cambridge_Bay', offset: '−07:00' },
  { id: 'America/Campo_Grande', offset: '−04:00' },
  { id: 'America/Cancun', offset: '−05:00' },
  { id: 'America/Caracas', offset: '−04:00' },
  { id: 'America/Cayenne', offset: '−03:00' },
  { id: 'America/Chicago', offset: '−06:00' },
  { id: 'America/Chihuahua', offset: '−07:00' },
  { id: 'America/Cordoba', offset: '−03:00' },
  { id: 'America/Costa_Rica', offset: '−06:00' },
  { id: 'America/Cuiaba', offset: '−04:00' },
  { id: 'America/Curacao', offset: '−04:00' },
  { id: 'America/Danmarkshavn', offset: '+00:00' },
  { id: 'America/Dawson', offset: '−08:00' },
  { id: 'America/Dawson_Creek', offset: '−07:00' },
  { id: 'America/Denver', offset: '−07:00' },
  { id: 'America/Detroit', offset: '−05:00' },
  { id: 'America/Edmonton', offset: '−07:00' },
  { id: 'America/Eirunepe', offset: '−05:00' },
  { id: 'America/El_Salvador', offset: '−06:00' },
  { id: 'America/Fortaleza', offset: '−03:00' },
  { id: 'America/Glace_Bay', offset: '−04:00' },
  { id: 'America/Godthab', offset: '−03:00' },
  { id: 'America/Goose_Bay', offset: '−04:00' },
  { id: 'America/Grand_Turk', offset: '−05:00' },
  { id: 'America/Guatemala', offset: '−06:00' },
  { id: 'America/Guayaquil', offset: '−05:00' },
  { id: 'America/Guyana', offset: '−04:00' },
  { id: 'America/Halifax', offset: '−04:00' },
  { id: 'America/Havana', offset: '−05:00' },
  { id: 'America/Hermosillo', offset: '−07:00' },
  { id: 'America/Indiana/Indianapolis', offset: '−05:00' },
  { id: 'America/Indiana/Knox', offset: '−06:00' },
  { id: 'America/Indiana/Marengo', offset: '−05:00' },
  { id: 'America/Indiana/Vevay', offset: '−05:00' },
  { id: 'America/Inuvik', offset: '−07:00' },
  { id: 'America/Iqaluit', offset: '−05:00' },
  { id: 'America/Jamaica', offset: '−05:00' },
  { id: 'America/Jujuy', offset: '−03:00' },
  { id: 'America/Juneau', offset: '−09:00' },
  { id: 'America/Kentucky/Louisville', offset: '−05:00' },
  { id: 'America/Kentucky/Monticello', offset: '−05:00' },
  { id: 'America/La_Paz', offset: '−04:00' },
  { id: 'America/Lima', offset: '−05:00' },
  { id: 'America/Los_Angeles', offset: '−08:00' },
  { id: 'America/Maceio', offset: '−03:00' },
  { id: 'America/Managua', offset: '−06:00' },
  { id: 'America/Manaus', offset: '−04:00' },
  { id: 'America/Martinique', offset: '−04:00' },
  { id: 'America/Mazatlan', offset: '−07:00' },
  { id: 'America/Mendoza', offset: '−03:00' },
  { id: 'America/Menominee', offset: '−06:00' },
  { id: 'America/Merida', offset: '−06:00' },
  { id: 'America/Mexico_City', offset: '−06:00' },
  { id: 'America/Miquelon', offset: '−03:00' },
  { id: 'America/Monterrey', offset: '−06:00' },
  { id: 'America/Montevideo', offset: '−03:00' },
  { id: 'America/Nassau', offset: '−05:00' },
  { id: 'America/New_York', offset: '−05:00' },
  { id: 'America/Nipigon', offset: '−05:00' },
  { id: 'America/Nome', offset: '−09:00' },
  { id: 'America/Noronha', offset: '−02:00' },
  { id: 'America/North_Dakota/Center', offset: '−06:00' },
  { id: 'America/Panama', offset: '−05:00' },
  { id: 'America/Pangnirtung', offset: '−05:00' },
  { id: 'America/Paramaribo', offset: '−03:00' },
  { id: 'America/Phoenix', offset: '−07:00' },
  { id: 'America/Port-au-Prince', offset: '−05:00' },
  { id: 'America/Port_of_Spain', offset: '−04:00' },
  { id: 'America/Porto_Velho', offset: '−04:00' },
  { id: 'America/Puerto_Rico', offset: '−04:00' },
  { id: 'America/Rainy_River', offset: '−06:00' },
  { id: 'America/Rankin_Inlet', offset: '−06:00' },
  { id: 'America/Recife', offset: '−03:00' },
  { id: 'America/Regina', offset: '−06:00' },
  { id: 'America/Rio_Branco', offset: '−05:00' },
  { id: 'America/Santiago', offset: '−04:00' },
  { id: 'America/Santo_Domingo', offset: '−04:00' },
  { id: 'America/Sao_Paulo', offset: '−03:00' },
  { id: 'America/Scoresbysund', offset: '−01:00' },
  { id: 'America/St_Johns', offset: '−03:30' },
  { id: 'America/Swift_Current', offset: '−06:00' },
  { id: 'America/Tegucigalpa', offset: '−06:00' },
  { id: 'America/Thule', offset: '−04:00' },
  { id: 'America/Thunder_Bay', offset: '−05:00' },
  { id: 'America/Tijuana', offset: '−08:00' },
  { id: 'America/Toronto', offset: '−05:00' },
  { id: 'America/Vancouver', offset: '−08:00' },
  { id: 'America/Whitehorse', offset: '−08:00' },
  { id: 'America/Winnipeg', offset: '−06:00' },
  { id: 'America/Yakutat', offset: '−09:00' },
  { id: 'America/Yellowknife', offset: '−07:00' },
  { id: 'Antarctica/Casey', offset: '+11:00' },
  { id: 'Antarctica/Davis', offset: '+07:00' },
  { id: 'Antarctica/DumontDUrville', offset: '+10:00' },
  { id: 'Antarctica/Mawson', offset: '+05:00' },
  { id: 'Antarctica/Palmer', offset: '−03:00' },
  { id: 'Antarctica/Rothera', offset: '−03:00' },
  { id: 'Antarctica/Syowa', offset: '+03:00' },
  { id: 'Antarctica/Vostok', offset: '+06:00' },
  { id: 'Asia/Almaty', offset: '+06:00' },
  { id: 'Asia/Amman', offset: '+02:00' },
  { id: 'Asia/Anadyr', offset: '+12:00' },
  { id: 'Asia/Aqtau', offset: '+05:00' },
  { id: 'Asia/Aqtobe', offset: '+05:00' },
  { id: 'Asia/Ashgabat', offset: '+05:00' },
  { id: 'Asia/Baghdad', offset: '+03:00' },
  { id: 'Asia/Baku', offset: '+04:00' },
  { id: 'Asia/Bangkok', offset: '+07:00' },
  { id: 'Asia/Beirut', offset: '+02:00' },
  { id: 'Asia/Bishkek', offset: '+06:00' },
  { id: 'Asia/Brunei', offset: '+08:00' },
  { id: 'Asia/Calcutta', offset: '+05:30' },
  { id: 'Asia/Choibalsan', offset: '+08:00' },
  { id: 'Asia/Colombo', offset: '+05:30' },
  { id: 'Asia/Damascus', offset: '+02:00' },
  { id: 'Asia/Dhaka', offset: '+06:00' },
  { id: 'Asia/Dili', offset: '+09:00' },
  { id: 'Asia/Dubai', offset: '+04:00' },
  { id: 'Asia/Dushanbe', offset: '+05:00' },
  { id: 'Asia/Gaza', offset: '+02:00' },
  { id: 'Asia/Hong_Kong', offset: '+08:00' },
  { id: 'Asia/Hovd', offset: '+07:00' },
  { id: 'Asia/Irkutsk', offset: '+08:00' },
  { id: 'Asia/Jakarta', offset: '+07:00' },
  { id: 'Asia/Jayapura', offset: '+09:00' },
  { id: 'Asia/Jerusalem', offset: '+02:00' },
  { id: 'Asia/Kabul', offset: '+04:30' },
  { id: 'Asia/Kamchatka', offset: '+12:00' },
  { id: 'Asia/Karachi', offset: '+05:00' },
  { id: 'Asia/Katmandu', offset: '+05:45' },
  { id: 'Asia/Krasnoyarsk', offset: '+07:00' },
  { id: 'Asia/Kuala_Lumpur', offset: '+08:00' },
  { id: 'Asia/Kuching', offset: '+08:00' },
  { id: 'Asia/Macau', offset: '+08:00' },
  { id: 'Asia/Magadan', offset: '+11:00' },
  { id: 'Asia/Makassar', offset: '+08:00' },
  { id: 'Asia/Manila', offset: '+08:00' },
  { id: 'Asia/Nicosia', offset: '+02:00' },
  { id: 'Asia/Novosibirsk', offset: '+07:00' },
  { id: 'Asia/Omsk', offset: '+06:00' },
  { id: 'Asia/Oral', offset: '+05:00' },
  { id: 'Asia/Pontianak', offset: '+07:00' },
  { id: 'Asia/Pyongyang', offset: '+09:00' },
  { id: 'Asia/Qatar', offset: '+03:00' },
  { id: 'Asia/Qyzylorda', offset: '+05:00' },
  { id: 'Asia/Rangoon', offset: '+06:30' },
  { id: 'Asia/Riyadh', offset: '+03:00' },
  { id: 'Asia/Saigon', offset: '+07:00' },
  { id: 'Asia/Sakhalin', offset: '+11:00' },
  { id: 'Asia/Samarkand', offset: '+05:00' },
  { id: 'Asia/Seoul', offset: '+09:00' },
  { id: 'Asia/Shanghai', offset: '+08:00' },
  { id: 'Asia/Singapore', offset: '+08:00' },
  { id: 'Asia/Taipei', offset: '+08:00' },
  { id: 'Asia/Tashkent', offset: '+05:00' },
  { id: 'Asia/Tbilisi', offset: '+04:00' },
  { id: 'Asia/Tehran', offset: '+03:30' },
  { id: 'Asia/Thimphu', offset: '+06:00' },
  { id: 'Asia/Tokyo', offset: '+09:00' },
  { id: 'Asia/Ulaanbaatar', offset: '+08:00' },
  { id: 'Asia/Urumqi', offset: '+06:00' },
  { id: 'Asia/Vladivostok', offset: '+10:00' },
  { id: 'Asia/Yakutsk', offset: '+09:00' },
  { id: 'Asia/Yekaterinburg', offset: '+05:00' },
  { id: 'Asia/Yerevan', offset: '+04:00' },
  { id: 'Atlantic/Azores', offset: '−01:00' },
  { id: 'Atlantic/Bermuda', offset: '−04:00' },
  { id: 'Atlantic/Canary', offset: '+00:00' },
  { id: 'Atlantic/Cape_Verde', offset: '−01:00' },
  { id: 'Atlantic/Faeroe', offset: '+00:00' },
  { id: 'Atlantic/Madeira', offset: '+00:00' },
  { id: 'Atlantic/Reykjavik', offset: '+00:00' },
  { id: 'Atlantic/South_Georgia', offset: '−02:00' },
  { id: 'Atlantic/Stanley', offset: '−03:00' },
  { id: 'Australia/Adelaide', offset: '+09:30' },
  { id: 'Australia/Brisbane', offset: '+10:00' },
  { id: 'Australia/Broken_Hill', offset: '+09:30' },
  { id: 'Australia/Darwin', offset: '+09:30' },
  { id: 'Australia/Hobart', offset: '+10:00' },
  { id: 'Australia/Lindeman', offset: '+10:00' },
  { id: 'Australia/Lord_Howe', offset: '+10:30' },
  { id: 'Australia/Melbourne', offset: '+10:00' },
  { id: 'Australia/Perth', offset: '+08:00' },
  { id: 'Australia/Sydney', offset: '+10:00' },
  { id: 'Etc/GMT', offset: '+00:00' },
  { id: 'Etc/GMT+1', offset: '−01:00' },
  { id: 'Etc/GMT+2', offset: '−02:00' },
  { id: 'Etc/GMT+3', offset: '−03:00' },
  { id: 'Etc/GMT+4', offset: '−04:00' },
  { id: 'Etc/GMT+5', offset: '−05:00' },
  { id: 'Etc/GMT+6', offset: '−06:00' },
  { id: 'Etc/GMT+7', offset: '−07:00' },
  { id: 'Etc/GMT+8', offset: '−08:00' },
  { id: 'Etc/GMT+9', offset: '−09:00' },
  { id: 'Etc/GMT+10', offset: '−10:00' },
  { id: 'Etc/GMT+11', offset: '−11:00' },
  { id: 'Etc/GMT+12', offset: '−12:00' },
  { id: 'Etc/GMT-1', offset: '+01:00' },
  { id: 'Etc/GMT-2', offset: '+02:00' },
  { id: 'Etc/GMT-3', offset: '+03:00' },
  { id: 'Etc/GMT-4', offset: '+04:00' },
  { id: 'Etc/GMT-5', offset: '+05:00' },
  { id: 'Etc/GMT-6', offset: '+06:00' },
  { id: 'Etc/GMT-7', offset: '+07:00' },
  { id: 'Etc/GMT-8', offset: '+08:00' },
  { id: 'Etc/GMT-9', offset: '+09:00' },
  { id: 'Etc/GMT-10', offset: '+10:00' },
  { id: 'Etc/GMT-11', offset: '+11:00' },
  { id: 'Etc/GMT-12', offset: '+12:00' },
  { id: 'Etc/GMT-13', offset: '+13:00' },
  { id: 'Etc/GMT-14', offset: '+14:00' },
  { id: 'Etc/UTC', offset: '+00:00' },
  { id: 'Europe/Amsterdam', offset: '+01:00' },
  { id: 'Europe/Andorra', offset: '+01:00' },
  { id: 'Europe/Athens', offset: '+02:00' },
  { id: 'Europe/Belgrade', offset: '+01:00' },
  { id: 'Europe/Berlin', offset: '+01:00' },
  { id: 'Europe/Brussels', offset: '+01:00' },
  { id: 'Europe/Bucharest', offset: '+02:00' },
  { id: 'Europe/Budapest', offset: '+01:00' },
  { id: 'Europe/Chisinau', offset: '+02:00' },
  { id: 'Europe/Copenhagen', offset: '+01:00' },
  { id: 'Europe/Dublin', offset: '+00:00' },
  { id: 'Europe/Gibraltar', offset: '+01:00' },
  { id: 'Europe/Helsinki', offset: '+02:00' },
  { id: 'Europe/Istanbul', offset: '+03:00' },
  { id: 'Europe/Kaliningrad', offset: '+02:00' },
  { id: 'Europe/Kiev', offset: '+02:00' },
  { id: 'Europe/Lisbon', offset: '+00:00' },
  { id: 'Europe/London', offset: '+00:00' },
  { id: 'Europe/Luxembourg', offset: '+01:00' },
  { id: 'Europe/Madrid', offset: '+01:00' },
  { id: 'Europe/Malta', offset: '+01:00' },
  { id: 'Europe/Minsk', offset: '+03:00' },
  { id: 'Europe/Monaco', offset: '+01:00' },
  { id: 'Europe/Moscow', offset: '+03:00' },
  { id: 'Europe/Oslo', offset: '+01:00' },
  { id: 'Europe/Paris', offset: '+01:00' },
  { id: 'Europe/Prague', offset: '+01:00' },
  { id: 'Europe/Riga', offset: '+02:00' },
  { id: 'Europe/Rome', offset: '+01:00' },
  { id: 'Europe/Samara', offset: '+04:00' },
  { id: 'Europe/Simferopol', offset: '+03:00' },
  { id: 'Europe/Sofia', offset: '+02:00' },
  { id: 'Europe/Stockholm', offset: '+01:00' },
  { id: 'Europe/Tallinn', offset: '+02:00' },
  { id: 'Europe/Tirane', offset: '+01:00' },
  { id: 'Europe/Uzhgorod', offset: '+02:00' },
  { id: 'Europe/Vienna', offset: '+01:00' },
  { id: 'Europe/Vilnius', offset: '+02:00' },
  { id: 'Europe/Warsaw', offset: '+01:00' },
  { id: 'Europe/Zaporozhye', offset: '+02:00' },
  { id: 'Europe/Zurich', offset: '+01:00' },
  { id: 'Indian/Chagos', offset: '+06:00' },
  { id: 'Indian/Christmas', offset: '+07:00' },
  { id: 'Indian/Cocos', offset: '+06:30' },
  { id: 'Indian/Kerguelen', offset: '+05:00' },
  { id: 'Indian/Mahe', offset: '+04:00' },
  { id: 'Indian/Maldives', offset: '+05:00' },
  { id: 'Indian/Mauritius', offset: '+04:00' },
  { id: 'Indian/Reunion', offset: '+04:00' },
  { id: 'Pacific/Apia', offset: '+13:00' },
  { id: 'Pacific/Auckland', offset: '+12:00' },
  { id: 'Pacific/Chatham', offset: '+12:45' },
  { id: 'Pacific/Easter', offset: '−06:00' },
  { id: 'Pacific/Efate', offset: '+11:00' },
  { id: 'Pacific/Enderbury', offset: '+13:00' },
  { id: 'Pacific/Fakaofo', offset: '+13:00' },
  { id: 'Pacific/Fiji', offset: '+12:00' },
  { id: 'Pacific/Funafuti', offset: '+12:00' },
  { id: 'Pacific/Galapagos', offset: '−06:00' },
  { id: 'Pacific/Gambier', offset: '−09:00' },
  { id: 'Pacific/Guadalcanal', offset: '+11:00' },
  { id: 'Pacific/Guam', offset: '+10:00' },
  { id: 'Pacific/Honolulu', offset: '−10:00' },
  { id: 'Pacific/Kiritimati', offset: '+14:00' },
  { id: 'Pacific/Kosrae', offset: '+11:00' },
  { id: 'Pacific/Kwajalein', offset: '+12:00' },
  { id: 'Pacific/Majuro', offset: '+12:00' },
  { id: 'Pacific/Marquesas', offset: '−09:30' },
  { id: 'Pacific/Nauru', offset: '+12:00' },
  { id: 'Pacific/Niue', offset: '−11:00' },
  { id: 'Pacific/Norfolk', offset: '+11:00' },
  { id: 'Pacific/Noumea', offset: '+11:00' },
  { id: 'Pacific/Pago_Pago', offset: '−11:00' },
  { id: 'Pacific/Palau', offset: '+09:00' },
  { id: 'Pacific/Pitcairn', offset: '−08:00' },
  { id: 'Pacific/Ponape', offset: '+11:00' },
  { id: 'Pacific/Port_Moresby', offset: '+10:00' },
  { id: 'Pacific/Rarotonga', offset: '−10:00' },
  { id: 'Pacific/Tahiti', offset: '−10:00' },
  { id: 'Pacific/Tarawa', offset: '+12:00' },
  { id: 'Pacific/Tongatapu', offset: '+13:00' },
  { id: 'Pacific/Truk', offset: '+10:00' },
  { id: 'Pacific/Wake', offset: '+12:00' },
  { id: 'Pacific/Wallis', offset: '+12:00' }
];
