import { useEffect, useState } from 'react';

export function usePasswordStrengthValidator(rules, maxStrength, value) {
  const [ruleMatchStatus, setRuleMatchStatus] = useState({});

  const [strength, setStrength] = useState(0);

  useEffect(() => {
    let rulesMatchObj = {};
    rules.forEach(rule => {
      rulesMatchObj = { ...rulesMatchObj, [rule.rule_name]: false };
    });

    setRuleMatchStatus(rulesMatchObj);
  }, []);

  useEffect(() => {
    const _rulesMatchObj = { ...ruleMatchStatus };
    let totalValidRulesValueMatched = 0;

    rules.forEach(rule => {
      const isValueValid = rule.ruleValidator(value);
      _rulesMatchObj[rule.rule_name] = isValueValid;
      totalValidRulesValueMatched += isValueValid ? 1 : 0;
    });

    setRuleMatchStatus({ ..._rulesMatchObj });

    if (totalValidRulesValueMatched === 0) {
      setStrength(0);
    } else {
      setStrength(
        Math.max(Math.floor(totalValidRulesValueMatched * (maxStrength / rules.length)), 1)
      );
    }
  }, [value]);

  return {
    strength,
    ruleMatchStatus
  };
}
