import { AppConfig } from '../../../app/core/app.config';
import { BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../../../app/core/constants';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';
import { createHttpParams } from '../../legacy-utils/request';

const appConfig = new AppConfig();
const SidelineAPI = {
  sidelineURL: appConfig.getSidelineURL(),

  getSidelineSummary(pipelineId: number, sourceObjectIds?: number[]) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/summary`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          source_object_id: sourceObjectIds
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  getSourceObjectSidelineSummary(pipelineId: number, sourceObjectId?: number) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/source-object-summary`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          source_object_id: sourceObjectId,
          actionable_only: true,
          global: typeof sourceObjectId === 'undefined' || sourceObjectId === null
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  replayGroup(
    pipelineId: number,
    forced = false,
    sourceObjectId?: number,
    schemaName?: string,
    errorCode?: number
  ) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/replay-group`;
    const params: any = {
      source_object_id: sourceObjectId,
      schema_name: schemaName,
      code: errorCode,
      forced
    };

    return courier.put(requestUrl, {}, params);
  },

  skipGroup(pipelineId: number, sourceObjectId?: number, schemaName?: string, errorCode?: number) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/skip-group`;
    const params: any = {
      source_object_id: sourceObjectId,
      schema_name: schemaName,
      code: errorCode
    };

    return courier.put(requestUrl, {}, params);
  },

  // can be removed
  cancelReplayProgress(pipelineId: number, sourceObjectId?: number) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/replayed-batches`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      },
      networkOptions: {
        params: createHttpParams({
          source_object_id: sourceObjectId
        })
      }
    };

    return courier.delete(requestUrl, options);
  },

  getSamples(pipelineId: number, schemaName: string, stage: any, code: any) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/summary-sample`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          code,
          stage,
          schema_name: schemaName
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  downloadSamples(pipelineId: number, schemaName: string, stage: string, code: number) {
    const requestUrl = `${this.sidelineURL}/${pipelineId}/summary-sample/download`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        responseType: 'blob',
        params: createHttpParams({
          code,
          stage,
          schema_name: schemaName
        })
      }
    };

    return courier.get(requestUrl, options);
  }
};

export default SidelineAPI;
