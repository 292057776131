// Vendor Modules
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { HdA11yModule } from '../a11y/a11y.module';
import { BadgeModule } from '../badge/badge.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '../form-field';
import { ButtonModule } from '../button/hd-button.module';
import { IconModule } from '../icon/icon.module';
import { MatInputModule } from '../input';

import { LoaderModule } from '../loader/loader.module';
import { PipeModule } from '../pipe/pipe.module';
import { PopupModule } from '../popup/popup.module';
import { HdScrollingModule } from '../scrolling/scrolling.module';
import { TagModule } from '../tag/tag.module';
import { TooltipModule } from '../tooltip/tooltip.module';

import { FaCustomLoadingTextDirective } from './flexible-auto-complete/fa-custom-loading-text.directive';
import { FaCustomValueIconDirective } from './flexible-auto-complete/fa-custom-value-icon.directive';
import { FaCustomValueTextDirective } from './flexible-auto-complete/fa-custom-value-text.directive';
import { FaGroupContentDirective } from './flexible-auto-complete/fa-group-content.directive';
import { FaGroupTemplateDirective } from './flexible-auto-complete/fa-group-template.directive';
import { FaHelpContentDirective } from './flexible-auto-complete/fa-help-content.directive';
import { FaHelpTemplateDirective } from './flexible-auto-complete/fa-help-template.directive';
import { FaItemContentDirective } from './flexible-auto-complete/fa-item-content.directive';
import { FaItemTemplateDirective } from './flexible-auto-complete/fa-item-template.directive';
import { FaNotFoundDirective } from './flexible-auto-complete/fa-not-found.directive';
import { FaOptionComponent } from './flexible-auto-complete/fa-option.component';
import { FlexibleAutoCompleteOrigin } from './flexible-auto-complete/flexible-auto-complete-origin.directive';
import { FlexibleAutoCompleteTriggerDirective } from './flexible-auto-complete/flexible-auto-complete-trigger.directive';
import { FlexibleAutoCompleteComponent } from './flexible-auto-complete/flexible-auto-complete.component';
import { FlexibleSelectComponent } from './flexible-select/flexible-select.component';
import { FsOptionComponent } from './flexible-select/fs-option.component';
import { SearchareaComponent } from './searcharea/searcharea.component';
import { IntegerValidator } from './validators/integer.directive';
import { MaxValidator } from './validators/max.directive';
import { MinValidator } from './validators/min.directive';
import { AccordionModule } from '../accordion/accordion.module';


const DIRECTIVES = [
  FlexibleSelectComponent,
  FsOptionComponent,
  FlexibleAutoCompleteComponent,
  FaOptionComponent,
  FaNotFoundDirective,
  FaCustomValueTextDirective,
  FaCustomValueIconDirective,
  FaCustomLoadingTextDirective,
  FlexibleAutoCompleteTriggerDirective,
  FlexibleAutoCompleteOrigin,
  MinValidator,
  MaxValidator,
  IntegerValidator,
  FaItemTemplateDirective,
  FaGroupTemplateDirective,
  FaHelpContentDirective,
  FaHelpTemplateDirective,
  FaItemContentDirective,
  FaGroupContentDirective,
  SearchareaComponent
];

const EXPORTED_MODULES = [
  MatCheckboxModule,
  MatRadioModule,
  MatFormFieldModule,
  MatInputModule
];

@NgModule({
  imports: [
    CommonModule,
    TagModule,
    PopupModule,
    HdA11yModule,
    TooltipModule,
    PortalModule,
    BadgeModule,
    IconModule,
    ButtonModule,
    HdScrollingModule,
    LoaderModule,
    ...EXPORTED_MODULES,
    PipeModule,
    FormsModule,
    AccordionModule
  ],
  declarations: DIRECTIVES,
  exports: [ ...DIRECTIVES, ...EXPORTED_MODULES ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        appearance: 'outline'
      }
    }
  ]
})
export class IpModule {
}
