import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KeyBadgeComponent } from './key-badge/key-badge.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { OverlayBadgeDirective } from './overlay-badge.directive';


@NgModule({
  declarations: [
    KeyBadgeComponent,
    OverlayBadgeDirective
  ],
  exports: [
    KeyBadgeComponent,
    OverlayBadgeDirective
  ],
  imports: [
    CommonModule,
    TooltipModule
  ]
})
export class BadgeModule { }
