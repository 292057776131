import { Doc, Pos, Position } from 'codemirror';
import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from '../../legacy-utils/equality';

import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/hint/show-hint.css';
import '../../../app/code-editor/addons/show-invisible';
import '../../../app/code-editor/addons/json-data-type-marker/index';
import '../../../app/code-editor/addons/show-hint';
import '../../../app/code-editor/addons/sql-hint';

import { isMac } from '../../legacy-utils/user-agent';
import { getNearestScrollableParent } from '../../../app/scrolling/scrolling';

export interface CodeMirrorProps {
  dataId: string;
  className?: string;
  name?: string;
  path?: string;
  codeMirrorInstance?: Function;
  options: any;
  autoIndent?: boolean;
  autocomplete?: boolean;
  defaultValue?: string;
  value?: string;
  onAttach?: Function;
  onChange?: Function;
  onCursorActivity?: Function;
  onFocusChange?: Function;
  onScroll?: Function;
}

export function CodeMirror({
  dataId,
  className = '',
  name,
  path,
  codeMirrorInstance,
  options,
  autoIndent,
  autocomplete,
  defaultValue,
  value,
  onChange,
  onCursorActivity,
  onFocusChange,
  onScroll,
  onAttach
}: CodeMirrorProps) {
  const [isFocused, setIsFocused] = useState(false);
  const codeMirror = useRef(null);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const keydownEventHandled = useRef(false);
  const containerKeydownListenerRef = useRef(null);

  const getCodeMirrorInstance = () => codeMirrorInstance || require('codemirror');

  useEffect(() => {
    const instance = getCodeMirrorInstance();

    codeMirror.current = instance.fromTextArea(textareaRef.current, {
      extraKeys: getExtraKeys(),
      hintOptions: {
        completeSingle: false,
        container: getNearestScrollableParent(containerRef.current)
      },
      ...options
    });
    codeMirror.current.on('change', codemirrorValueChanged);
    codeMirror.current.on('cursorActivity', cursorActivity);
    codeMirror.current.on('focus', focusChanged.bind(this, true));
    codeMirror.current.on('blur', focusChanged.bind(this, false));
    codeMirror.current.on('scroll', scrollChanged);
    codeMirror.current.on('inputRead', onInputRead);
    codeMirror.current.on('keyHandled', () => {
      keydownEventHandled.current = true;
    });

    containerKeydownListenerRef.current = e => {
      if (keydownEventHandled.current) {
        e.stopPropagation();
      }

      keydownEventHandled.current = false;
    };

    containerRef.current.addEventListener('keydown', containerKeydownListenerRef.current);

    codeMirror.current.setValue(defaultValue || value || '');

    onAttach(codeMirror.current);

    if (autoIndent) {
      indentDoc();
    }

    return () => {
      if (containerRef.current) {
        codeMirror.current.toTextArea();
        containerRef.current.removeEventListener('keydown', containerKeydownListenerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (codeMirror.current.getValue() !== value) {
      codeMirror.current.setValue(value);

      if (autoIndent) {
        indentDoc();
      }
    }

    if (typeof options === 'object') {
      for (const optionName in options) {
        if (options.hasOwnProperty(optionName)) {
          setOptionIfChanged(optionName, options[optionName]);
        }
      }
    }
  }, [value, options]);

  const focusChanged = focused => {
    setIsFocused(focused);
    onFocusChange && onFocusChange(focused);
  };

  const codemirrorValueChanged = (doc, change) => {
    if (onChange && change.origin !== 'setValue') {
      onChange(doc.getValue(), change);
    }

    if (change.origin === 'paste' && autoIndent) {
      indentDoc();
    }
  };

  const onInputRead = (cm, changeObj: any) => {
    if (!autocomplete) {
      return;
    }

    if ([' ', '*'].includes(changeObj.text?.[0])) {
      return;
    }

    if (cm.state.completionActive) {
      return;
    }

    codeMirror.current.execCommand('autocomplete');
  };

  const cursorActivity = cm => {
    onCursorActivity && onCursorActivity(cm);
  };

  const scrollChanged = cm => {
    onScroll && onScroll(cm.getScrollInfo());
  };

  const setOptionIfChanged = (optionName, newValue) => {
    const oldValue = codeMirror.current.getOption(optionName);

    if (!isEqual(oldValue, newValue)) {
      codeMirror.current.setOption(optionName, newValue);
    }
  };

  const indentDoc = () => {
    const doc: Doc = codeMirror.current.getDoc();
    const cursorPos: Position = doc.getCursor();
    doc.setSelection(Pos(doc.firstLine(), 0), Pos(doc.lastLine()), { scroll: false });

    codeMirror.current.indentSelection('smart');
    doc.setCursor(cursorPos);
  };

  const getExtraKeys = () => {
    const common = {
      Tab: 'insertSoftTab',
      'Ctrl-Space': () =>
        function (cm: any) {
          cm.execCommand('autocomplete');
        }
    };

    if (isMac()) {
      return {
        ...common,
        'Cmd-F': 'findPersistent',
        'Cmd-/': toggleComment()
      };
    }
    return {
      ...common,
      'Ctrl-F': 'findPersistent',
      'Ctrl-/': toggleComment()
    };
  };

  const toggleComment = () =>
    function (cm: any) {
      cm.toggleComment();
    };

  return (
    <div
      className={`ReactCodeMirror ${isFocused ? 'ReactCodeMirror--focused' : ''} ${className}`}
      ref={containerRef}
      data-id={`${dataId}-container`}
    >
      <textarea
        ref={textareaRef}
        name={name || path}
        defaultValue={value}
        autoComplete='off'
        data-id={`${dataId}-codemirror-textarea`}
        style={{ opacity: 0 }}
      />
    </div>
  );
}
