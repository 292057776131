import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { SANITIZE_NAMES_DOC_LINK } from '../../../app/nodes/constants';
import HdFormikSwitchWrapper from '../FormikElements/HdFormikSwitchWrapper';
import HdFormikTextField from '../FormikElements/HdFormikTextField';
import HdDocLink from '../UIElements/HdDocLink';
import HdFormControl from '../UIElements/HdFormControl';

export interface DisabledFieldProps {
  disabled?: boolean;
}

export function LoadedAt({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='loadedAt'
      disabled={disabled}
      label='Populate Loaded Timestamp'
    >
      Populate the <i>__hevo__loaded_at</i> field to indicate when data was inserted or updated in
      the table.
    </HdFormikSwitchWrapper>
  );
}

export function SanitizeName({
  disabled,
  section = 'destination_settings',
  link = SANITIZE_NAMES_DOC_LINK,
  dataId = 'sanitize-name',
  className = ''
}: {
  disabled?: boolean;
  link?: string;
  section?: string;
  dataId?: string;
  className?: string;
}) {
  return (
    <HdFormikSwitchWrapper
      fieldName='sanitizeName'
      disabled={disabled}
      label='Sanitize Table/Column Names'
      className={className}
    >
      Replace non-alphanumeric characters and whitespaces in table/column names with underscores.
      {' '}
      <HdDocLink docLink={link} section={section} dataId={dataId} />
    </HdFormikSwitchWrapper>
  );
}

export function IncludeNewTable({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='includeNewTables'
      disabled={disabled}
      label='Include New Tables in the Pipeline'
    >
      If enabled, Hevo automatically ingests from any tables created or restored after the Pipeline
      is created.
    </HdFormikSwitchWrapper>
  );
}

export function IncludeNewCollections({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='includeNewTables'
      disabled={disabled}
      label='Include New Collections in the Pipeline'
    >
      If enabled, Hevo automatically ingests from any collections created or restored after the Pipeline
      is created.
    </HdFormikSwitchWrapper>
  );
}

export function IncludeNewObjects({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='includeNewTables'
      disabled={disabled}
      label='Include New Objects in the Pipeline'
    >
      If enabled, Hevo automatically ingests from any objects created or restored after the Pipeline
      is created.
    </HdFormikSwitchWrapper>
  );
}

export function LoadAllDatabases({ disabled }: DisabledFieldProps) {
  const formikProps = useFormikContext<{
    loadAllDb: boolean;
  }>();
  return (
    <>
      <HdFormikSwitchWrapper fieldName='loadAllDb' disabled={disabled} label='Load All Databases'>
        If enabled, Hevo loads data from all the databases in the selected host.
      </HdFormikSwitchWrapper>
      {!formikProps.values.loadAllDb ? (
        <Grid item xs={12} md={6} className='mt-5'>
          <HdFormControl>
            <Field
              name='databaseNames'
              label='Specify Database Names'
              required
              helperText='Comma-separated list of database names you want to load the data from'
              component={HdFormikTextField}
            />
          </HdFormControl>
        </Grid>
      ) : null}
    </>
  );
}

export function LoadHistoricalData({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='importHistoricalData'
      label='Load Historical Data'
      disabled={disabled}
    >
      If disabled, Hevo loads only the data that is written to your database after the time of
      creation of the Pipeline.
    </HdFormikSwitchWrapper>
  );
}

export function MergeCollections({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='mergeCollections'
      label='Merge Collections'
      disabled={disabled}
    >
      Merge Collections with the same name from different databases.
    </HdFormikSwitchWrapper>
  );
}

export function LoadIncrementalDataUsingWebhooks({ disabled }: DisabledFieldProps) {
  return (
    <HdFormikSwitchWrapper
      fieldName='webhookIncrementalLoad'
      label='Load incremental data using webhooks'
      disabled={disabled}
    >
      If enabled, Hevo loads incremental data using webhooks framework.{' '}
      <HdDocLink docLink='' dataId='incremental-load-webhooks' />
    </HdFormikSwitchWrapper>
  );
}

export function MergeTables({ disabled, databasesDisplay = 'databases' }: DisabledFieldProps & { databasesDisplay?: string }) {
  return (
    <HdFormikSwitchWrapper fieldName='mergeTables' label='Merge Tables' disabled={disabled}>
      If enabled, Hevo merges tables having the same name from different {databasesDisplay} while loading
      data to the Destination.
    </HdFormikSwitchWrapper>
  );
}
