import { HttpClient } from '@angular/common/http';
import React, { useState } from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import useUserService from '../../../hooks/services/useUserService';
import useService from '../../../hooks/useService';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdAuth } from '../HdAuth';
import { FeaturedCustomers } from '../TestimonialSection';
import { useAuthGuard } from '../useAuthGuard';
import { useFeaturedCustomers } from '../useFeaturedCustomer';

declare const __WEBSITE_URL__: string;

export interface SubscribeMarketplaceProps {
  featuredCustomers: FeaturedCustomers;
}

const dataIdGenerator = getDataIdGenerator('subscribe-aws-marketplace');

export function SubscribeMarketplace() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeAuthGuard } = useAuthGuard();

  return (
    <HdResolvedComponent
      Component={SubscribeMarketplaceInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[executeAuthGuard]}
    />
  );
}

export function SubscribeMarketplaceInner({ featuredCustomers }: SubscribeMarketplaceProps) {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>(null);
  const [cancelling, setCancelling] = useState(false);
  const [cancelError, setCancelError] = useState<string>(null);

  const http = useService(HttpClient);
  const history = useHistory();

  const { user } = useUserService();

  const accountId = new URLSearchParams(history.location.search).get('account_id');
  const provider = new URLSearchParams(history.location.search).get('marketplace_provider');

  const subscriptionMetaURL = `${__WEBSITE_URL__}/auth/subscription-meta`;

  const cancelClick = () => {
    setCancelling(true);
    setCancelError(null);

    http
      .delete(subscriptionMetaURL, {
        headers: { Anonymous: 'True' },
        withCredentials: true
      })
      .toPromise()
      .then(
        () => {
          setCancelling(false);
          history.replace('/');
        },
        error => {
          setCancelError(getErrorMessageFromObj(error));
          setCancelling(false);
        }
      );
  };

  const subscribeClick = () => {
    setSubmitting(true);
    setSubmitError(null);

    http
      .post(
        subscriptionMetaURL,
        {
          team_id: user.teamId
        },
        {
          headers: { Anonymous: 'True' },
          withCredentials: true
        }
      )
      .toPromise()
      .then(
        () => {
          setSubmitting(false);
          history.replace('/');
        },
        error => {
          setSubmitError(getErrorMessageFromObj(error));
          setSubmitting(false);
        }
      );
  };

  return (
    <HdAuth
      heading={`Subscribe using ${provider} Marketplace?`}
      featuredCustomers={featuredCustomers}
    >
      <div className='text-subheading-1 text-secondary text-center'>
        {`By subscribing, your Hevo account will be linked to ${provider} Marketplace with account ID`}
        <b className='text-default'>{accountId}</b>
      </div>

      {submitError || cancelError ? (
        <HdPane
          dataId={dataIdGenerator('form-error')}
          className='mt-5 w-100'
          variant='error-faded'
          icon='error-filled'
          iconClasses='text-error'
          disableHide
        >
          <div className='text-default'>{submitError || cancelError}</div>
        </HdPane>
      ) : null}

      <div className='center-flex-row mt-5'>
        <HdButton
          dataId={dataIdGenerator('cancel')}
          variation='outline'
          showProgress={cancelling}
          disabled={cancelling}
          onClick={cancelClick}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('subscribe')}
          className='ml-4'
          showProgress={submitting}
          disabled={submitting}
          onClick={subscribeClick}
        >
          {submitting ? 'Subscribing' : 'Subscribe'}
        </HdButton>
      </div>
    </HdAuth>
  );
}
