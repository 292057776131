// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.defaultWarehouseTag__u8sV {\n  border: 0;\n  font-weight: 700;\n  background: var(--hover-bg-color);\n  color: var(--text-secondary-color);\n  padding: 0 4px !important;\n}\n\n.errorCaption_NhM5S {\n  color: var(--error-color) !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultWarehouseTag": "defaultWarehouseTag__u8sV",
	"errorCaption": "errorCaption_NhM5S"
};
export default ___CSS_LOADER_EXPORT___;
