import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RbacService } from '../../core/service/rbac.service';
import { RBAC_DISABLED_MESSAGE } from '../../core/models/rbac';

@Component({
  selector: 'play-btn',
  templateUrl: './play-btn.component.html',
  styleUrls: ['./play-btn.component.scss']
})
export class PlayBtnComponent {
  @Input() paused: boolean;
  @Input() disabled = false;
  @Input() iconOnly = false;
  @Output() pause = new EventEmitter<MouseEvent | KeyboardEvent>();
  @Output() resume = new EventEmitter<MouseEvent | KeyboardEvent>();
  @Input() rbacPermission;
  rbacToolTip = RBAC_DISABLED_MESSAGE;

  constructor(
    private _rbacService: RbacService
  ) { }

  isBlocked() {
    return !this._rbacService.canTakeAction(this.rbacPermission);
  }

  onClick(e: any) {
    if (this.paused) {
      this.resume.emit(e);
    } else {
      this.pause.emit(e);
    }
  }
}
