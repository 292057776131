import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class ShopifyConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      shopName: rawData.shop_name,
      appPassword: rawData.app_password
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      shop_name: data.shopName,
      app_password: data.appPassword
    };
  }
}
