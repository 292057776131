// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.alertWrapper_BZ8Yx {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.alertWrapper_BZ8Yx.compact_IQA0h {\n  flex-direction: row;\n  justify-content: flex-start;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n.alertWrapper_BZ8Yx.compact_IQA0h .iconBox_PVS4b {\n  margin-bottom: 0;\n  margin-right: 16px;\n  height: 48px;\n  width: 48px;\n  flex-shrink: 0;\n}\n.alertWrapper_BZ8Yx.compact_IQA0h .iconBox_PVS4b .icon_gfDX6 {\n  font-size: 24px;\n}\n.alertWrapper_BZ8Yx.compact_IQA0h .text_Dkg8s {\n  align-items: flex-start !important;\n}\n\n.text_Dkg8s {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.text_Dkg8s .subtext_r2D4v {\n  margin-top: 9.6px !important;\n  word-break: break-word;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertWrapper": "alertWrapper_BZ8Yx",
	"compact": "compact_IQA0h",
	"iconBox": "iconBox_PVS4b",
	"icon": "icon_gfDX6",
	"text": "text_Dkg8s",
	"subtext": "subtext_r2D4v"
};
export default ___CSS_LOADER_EXPORT___;
