import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieModule } from 'ngx-lottie';

import { HdA11yModule } from '../a11y/a11y.module';
import { AccordionModule } from '../accordion/accordion.module';
import { BadgeModule } from '../badge/badge.module';
import { BannerModule } from '../banner/banner.module';
import { ButtonModule } from '../button/hd-button.module';
import { FilterModule } from '../filter/filter.module';
import { IconModule } from '../icon/icon.module';
import { IpModule } from '../ip/ip.module';
import { MenuModule } from '../menu/menu.module';
import { HdMomentModule } from '../moment/moment.module';
import { PaginationModule } from '../pagination/pagination.module';
import { PipeModule } from '../pipe/pipe.module';
import { PopupModule } from '../popup/popup.module';
import { HdScrollingModule } from '../scrolling/scrolling.module';
import { MatSlideToggleModule } from '../slide-toggle/slide-toggle-module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { TrackingModule } from '../tracking/tracking.module';
import { AlertToggleButtonComponent } from './alert-toggle-button/alert-toggle-button.component';
import { AutoMappingControlPaneComponent } from './automapping-control-pane/automapping-control-pane.component';
import { BulkActionsButtonGroupComponent } from './bulk-actions-button-group/bulk-actions-button-group.component';
import { CollapseDirective } from './collapse/collapse.directive';
import { ColorModeImageDirective } from './color-mode-image/color-mode-image.directive';
import { DataIdDirective } from './data-id.directive';
import { DocLinkDirective } from './doc-link.directive';
import { ErrorSnackbarMessageComponent } from './error-snackbar-message/error-snackbar-message.component';
import { EventsStatsTooltipComponent } from './events-stats-tooltip/events-stats-tooltip/events-stats-tooltip.component';
import { FixedFooterComponent } from './fixed-footer/fixed-footer.component';
import { FormLoadingOverlayComponent } from './form-loading-overlay/form-loading-overlay.component';
import { FormOverlayLoaderDirective } from './form-overlay-loader/form-overlay-loader.directive';
import { HdDropdownTriggerButtonComponent } from './hd-dropdown-trigger-button/hd-dropdown-trigger-button.component';
import { HdLinkComponent } from './hd-link/hd-link.component';
import { NoItemBoxIconContainerComponent } from './no-item-box-icon-container/no-item-box-icon-container.component';
import { NodeLogoComponent } from './node-logo/node-logo.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { PlayBtnComponent } from './play-btn/play-btn.component';
import { PrimaryKeysSelectorComponent } from './primary-keys-selector/primary-keys-selector.component';
import { PipelinePriorityComponent } from './priority/pipeline-priority.component';
import { RoundedIconComponent } from './rounded-icon/rounded-icon.component';
import { SecondaryNavItemComponent } from './secondary-nav-item/secondary-nav-item.component';
import { SecondaryNavComponent } from './secondary-nav/secondary-nav.component';
import { SupportBtnDirective } from './support-btn.directive';
import { TabNavLabelTooltipComponent } from './tab-nav-label-tooltip/tab-nav-label-tooltip.component';
import { UserRbacAccessError } from './user-rbac-access-error/user-rbac-access-error.component';
import { RbacDirective } from './rbac.directive';
import { DestinationEditDialogReactComponent } from '../nodes/destination-edit-dialog/destination-edit-dialog-react.component';
import { DestinationWebhookComponent } from './destination-webhook/destination-webhook.component';
import { ZendeskSourceVersionUpgradeBannerComponent } from './zendesk-source-version-upgrade-banner/zendesk-source-version-upgrade-banner.component';


const COMPONENTS = [
  PageErrorComponent,
  AutoMappingControlPaneComponent,
  PlayBtnComponent,
  FormLoadingOverlayComponent,
  PipelinePriorityComponent,
  FixedFooterComponent,
  PrimaryKeysSelectorComponent,
  NodeLogoComponent,
  TabNavLabelTooltipComponent,
  SecondaryNavItemComponent,
  SecondaryNavComponent,
  BulkActionsButtonGroupComponent,
  AlertToggleButtonComponent,
  ErrorSnackbarMessageComponent,
  RoundedIconComponent,
  DestinationWebhookComponent,
  HdDropdownTriggerButtonComponent,
  UserRbacAccessError,
  NoItemBoxIconContainerComponent,
  EventsStatsTooltipComponent,
  HdLinkComponent,
  DestinationEditDialogReactComponent,
  ZendeskSourceVersionUpgradeBannerComponent
];

const DIRECTIVES = [
  CollapseDirective,
  FormOverlayLoaderDirective,
  SupportBtnDirective,
  ColorModeImageDirective,
  DocLinkDirective,
  RbacDirective,
  DataIdDirective
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClipboardModule,
    LottieModule,
    HdMomentModule,
    FilterModule,
    MenuModule,
    IpModule,
    PopupModule,
    HdA11yModule,
    TooltipModule,
    MatSlideToggleModule,
    IconModule,
    PaginationModule,
    PipeModule,
    BannerModule,
    BadgeModule,
    RouterModule,
    HdScrollingModule,
    ButtonModule,
    AccordionModule
  ],
  declarations: [ ...COMPONENTS, ...DIRECTIVES ],
  exports: [ ...COMPONENTS, ...DIRECTIVES, IconModule, ButtonModule, TrackingModule ],
  entryComponents: [
    FormLoadingOverlayComponent
  ],
  providers: []
})
export class SharedModule {
}
