import { Grid } from '@mui/material';
import React from 'react';
import { Field, useFormikContext } from 'formik';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import { SqlFormState } from './interfaces';
import { SqlFormFields } from './models';
import { HdFormControl } from '../../../../../components/UIElements';
import { HdFormikTextField } from '../../../../../components/FormikElements';

interface LoadAllSchemaProps {
  isEditing: boolean;
}

function LoadAllSchema({ isEditing }: LoadAllSchemaProps) {
  const { values } = useFormikContext<SqlFormState>();
  return (
    <Grid container >
      <Grid
        item
        xs={12}
        md={12}
        data-id='load-all-schema-container'
        className={`${!values[SqlFormFields.LOAD_ALL_SCHEMAS] ? 'mb-5' : 'mb-7'} mt-1`}
      >
        <HdFormikSwitchWrapper
          fieldName={SqlFormFields.LOAD_ALL_SCHEMAS}
          label='Load All Schemas'
          disabled={isEditing}
        >
          <span className='text-secondary mt-1'>
            If enabled, Hevo loads data from all the schemas defined on the selected host.
          </span>
        </HdFormikSwitchWrapper>
      </Grid>

      {!values.loadAllSchemas ? (
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.SCHEMA_NAMES}
              label='Specify Schema Names'
              required
              component={HdFormikTextField}
              helperText='Comma-separated list of schema names you want to load the data from.'
            />
          </HdFormControl>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default LoadAllSchema;
