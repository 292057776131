export function getShareDocLinkMessage(sourceType: string, docLink: string, draftSourceLink: string) {
  if (draftSourceLink) {
    return `Hi there,

Can you help me configure a Pipeline in Hevo with ${ sourceType } as a Source. I need assistance with
${ sourceType } Connection Settings. Here is the link to my Pipeline:
${ draftSourceLink }

The related documentation for this is available at:
${docLink}`;
  } else {
    return `Hi there,

Can you help me configure a Pipeline in Hevo with ${ sourceType } as a Source.
The related documentation for this is available at:
${docLink}`;
  }
}
