import { AppConfig } from '../../../app/core/app.config';
import { courier } from '../../../app/core/service/courier.service';

const appConfig = new AppConfig();

const DownloadAPI = {
  downloadUrl: appConfig.getDownloadConfigURL(),

  downloadFile(feature: string, fileName: string) {
    const url = `${this.downloadUrl}/feature/${feature}/object/${fileName}`;

    return courier.get(url, {
      uiOptions: {
        showLoading: true,
        showSuccessMsg: false,
        showErrorMsg: true,
      }
    });
  }
};

export default DownloadAPI;
