import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VersionReactComponent } from './version-react.component';

const routes: Routes = [
  {
    path: 'about/version',
    component: VersionReactComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AboutRoutingModule { }
