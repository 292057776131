export const SOURCE_TERMS_AND_SERVICE_MESSAGE_MAP: { [key: string]: string } = {
  'YOUTUBE_ANALYTICS': `You are sharing sensitive
    <a
      href="https://myaccount.google.com/permissions"
      class="text-link thumbnail-right"
      target="_blank">
      account data
    </a> with Hevo. Read about
    <a
      href="https://policies.google.com/privacy"
      class="text-link thumbnail-right"
      target="_blank">
      Google’s Privacy Policy
    </a>. You can delete any unused accounts, if needed.
    Hevo uses YouTube’s API Services to fetch your data in compliance with
    <a
      href="https://www.youtube.com/t/terms"
      class="text-link thumbnail-right"
      target="_blank">
      YouTube's Terms of Service
    </a>.`
};

export const SOURCE_INFO_MESSAGE_PANE_MAP: { [key: string]: any } = {
  'INSTAGRAM_BUSINESS': {
    message: `Your Instagram Business account must be linked to a Facebook page. Hevo uses your Facebook login for authentication. `,
    docLink: '/sources/marketing/instagram-business/#linking-a-facebook-page-to-your-instagram-business-account'
  },
  'GOOGLE_PLAY_CONSOLE': {
    message: 'Hevo requires \'read\' access to Google Cloud Storage (GCS) associated with your Google Play ' +
      'Developer account, where your Android app\'s performance data is published.'
  }
};

export const AUTH_FAIL_DOC_LINK_MAP: { [key: string]: string } = {
  'INSTAGRAM_BUSINESS': `/sources/marketing/instagram-business/#step-1-add-an-instagram-business-account`,
  'FACEBOOK_ADS': '/sources/marketing/facebook-ads/#configuring-facebook-ads-as-a-source',
  'FACEBOOK_PAGES': '/sources/marketing/facebook-pages/#configuring-facebook-pages-as-a-source'
};

export const ADS_ACCOUNT_SETUP_DOC_LINK_MAP: { [key: string]: string } = {
  'GOOGLE_ADS': '/sources/mkt-analytics/google-ads/#granting-access-to-your-google-ads-account-optional',
  'GOOGLE_ANALYTICS': '/sources/mkt-analytics/google-analytics/#adding-users-to-your-google-analytics-account-optional',
  'GOOGLE_CM': '/sources/mkt-analytics/gcm/#managing-access-to-your-google-campaign-manager-account-optional'
};

export const AUTH_ERROR_MESSAGE_PARAM_NAME = 'redirectMessage';
