import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { RBAC_DISABLED_MESSAGE } from '../../core/models/rbac';
import { RbacPermissions } from '../../core/models/user';
import { RbacService } from '../../core/service/rbac.service';
import { BulkActionUIItem } from './interface';

@Component({
  selector: 'bulk-actions-button-group',
  templateUrl: './bulk-actions-button-group.component.html',
  styleUrls: ['./bulk-actions-button-group.component.scss']
})
export class BulkActionsButtonGroupComponent implements OnChanges {
  renderedBulkActions: BulkActionUIItem[];
  inlineSize = 4;

  rbacActionBlockedTooltip = RBAC_DISABLED_MESSAGE;

  @Input() rbacPermission: RbacPermissions;
  @Input() permittedBulkActions: { [key: number]: boolean };
  @Input() bulkActionsArray: BulkActionUIItem[];

  @Output() bulkActionClick = new EventEmitter<number>();

  constructor(
    private _rbacService: RbacService
  ) { }

  ngOnChanges() {
    this.renderedBulkActions = this.bulkActionsArray.filter((action) => {
      return typeof this.permittedBulkActions[action.id] !== 'undefined';
    });
  }

  isActionBlockedByRbac() {
    return !this._rbacService.canTakeAction(this.rbacPermission);
  }

  isBulkActionDisabled(action: number) {
    const isActionBlocked = this.permittedBulkActions[action] === false;
    if (this.rbacPermission) {
      return this.isActionBlockedByRbac() || isActionBlocked;
    }

    return isActionBlocked;
  }

  onBulkActionClick(actionId: number) {
    this.bulkActionClick.emit(actionId);
  }

}
