<div
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)"
  [style.align-items]="containerPosition"
  #container>
  <div
    class="dialog-content"
    [@popupAnim]="visibleState"
    (@popupAnim.done)="onPopupAnimationDone($event)"
    [style.width]="contentWidth"
    [style.margin-top]="marginTop"
    #content
    cdkTrapFocus
    cdkTrapFocusAutoCapture>
    <ng-container #dialogBodyContainer></ng-container>
  </div>
</div>
