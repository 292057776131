import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dot-pulse',
  templateUrl: './dot-pulse.component.html',
  styleUrls: ['./dot-pulse.component.scss']
})
export class DotPulseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
