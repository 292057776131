<div
  class="flexible-auto-complete"
  [ngClass]="{ 'disabled': disabled, 'focus': showingOptionsList || useSearchTextToAddNewOption }"
  tabindex="-1"
  *ngIf="mode === modes.INLINE_SEARCH"
  #triggerWrapper
  (click)="onClick($event)">

  <ng-content select="[auto-complete-thumbnail]"></ng-content>

  <tag
    *ngIf="multi && multiPlaceholder"
    (click)="disabled && show()"
    [class.cursor-hand]="disabled"
    (remove)="clearSelectedValues(); $event.stopPropagation()"
    [disabled]="disabled"
    class="internal-tag">
    <div>
      {{ multiPlaceholder }}
    </div>
  </tag>

  <input
    class="internal-search mr-4"
    autocomplete="off"
    [disabled]="disabled || creatingNewOption"
    [class.has-value]="!!currentValue"
    [class.mr-6]="refreshingOptionList"
    [class.with-error-icon]="isErrorIconVisible"
    type="text"
    #ip
    [value]="reflectedIpValue"
    [attr.maxlength]="maxTextLength"
    [placeholder]="getPlaceholderDisplayText()"
    (focus)="onFocus($event)"
    (input)="onInput($event)"
    (keydown)="onKeydown($event)"/>

  <div class="actions-container">
    <button
      hd-icon-btn
      *ngIf="useSearchTextToAddNewOption"
      size="2"
      class="add-new-button mr-3"
      (click)="hide()">
      <hd-icon name="checked-tick"></hd-icon>
    </button>

    <hd-icon
      *ngIf="isErrorIconVisible"
      [tooltip]="errorTooltipContent"
      class="text-error"
      name="error-filled">
    </hd-icon>

    <button
      hd-icon-btn
      *ngIf="showClearOption && canShowClearIcon && !isShowingLoader && !disabled"
      size="2"
      #clearButton
      (click)="onClearIconClick()">
      <hd-icon name="close"></hd-icon>
    </button>

    <span
      *ngIf="!showingOptionsList && !useSearchTextToAddNewOption && !isShowingLoader && !canShowClearIcon"
      id="chevron"
      class="hevo-icon hevo-dropdown-arrow"
      (click)="onArrowClick($event)">
    </span>

    <div
      *ngIf="isShowingLoader"
      class="center-flex-row text-primary loading">
      <ng-container *ngIf="creatingNewOption">
        Creating {{ addNewOptionAtBottomKeyword }}...
      </ng-container>

      <hd-icon
        name="testing"
        tooltip="Refreshing..."
        [tooltipDisabled]="creatingNewOption">
      </hd-icon>
    </div>
  </div>
</div>

<ng-template #selectionContainerTemplateRef>
  <div
    class="selection-wrapper"
    [class.selection-dropdown]="mode !== modes.INLINE"
    [class.dropdown-border-shadow]="mode !== modes.INLINE
    && (showCustomField || (!showCustomField && this.filteredOptionGroups.length !== 0))"
    (mouseleave)="helpTextCurrentOption = null">
    <div
      #selectionContainer
      class="selection-container"
      [style.max-width]="maxWidth">

      <ng-container [ngTemplateOutlet]="faCompleteOptionsTemplate">
      </ng-container>

      <div class="fa-bottom">
        <ng-content select="[fa-bottom]"></ng-content>

        <span
          *ngIf="showAddNewOptionAtBottom"
          class="dropdown-fa-bottom-link"
          (click)="closeDropdownAndAddNewOption()">
          <span class="mr-3 hevo-icon hevo-plus"></span>
          {{ addNewOptionBottomLabel }}
        </span>
      </div>
    </div>

    <div class="selection-help" *ngIf="withOptionHelp && this.filteredOptionGroups.length && helpTextCurrentOption && helpTextCurrentOption.value[helpAccessor]">
      <div
        [faHelpContent]="(faHelpTemplate && faHelpTemplate.elRef) ? faHelpTemplate.elRef : faHelpDefaultTemplate"
        [option]="helpTextCurrentOption.value">
      </div>
    </div>
  </div>
</ng-template>

<ng-container
  *ngIf="mode === modes.INLINE"
  [ngTemplateOutlet]="selectionContainerTemplateRef">
</ng-container>

<ng-template
  #faItemDefaultTemplate
  let-option
  let-index="index"
  let-even="even">
  {{ valueAccessor ? option[valueAccessor] : option }}
</ng-template>

<ng-template
  #faGroupDefaultTemplate
  let-group
  let-index="index"
  let-even="even">
  <div class="fa-option-group-content">{{ group[groupAccessor] }}</div>
</ng-template>

<ng-template
  #faHelpDefaultTemplate
  let-option>
  {{ option[helpAccessor] }}
</ng-template>

<ng-template #faCompleteOptionsTemplate>
  <div
    class="fa-top"
    nestedKeyboardNavigation
    [withVirtualScroll]="useVirtualScroll && filteredOptionsCount > pageSize"
    [firstItem]="firstItem"
    [lastItem]="lastItem"
    [hasSelectAll]="renderSelectAll"
    [accessor]="accessor"
    (listRotated)="onListRotation($event)">
    <hd-scroll-viewport
      #scrollViewport
      class="list-wrapper"
      edgeBufferBottom="35"
      [edgeBufferTop]="_edgeBufferTop"
      [renderScrollTopBtn]="false"
      (scrolledToEdge)="onEdgeScroll($event)">
      <div class="external-trigger-search-container" *ngIf="mode !== modes.INLINE_SEARCH">
        <span class="hevo-icon hevo-search"></span>

        <tag
          *ngIf="multi && multiPlaceholder"
          [disabled]="disabled"
          [class.cursor-hand]="disabled"
          (remove)="clearSelectedValues(); $event.stopPropagation()"
          class="external-tag">
          <div>
            {{ multiPlaceholder }}
          </div>
        </tag>

        <input
          type="text"
          class="external-trigger-search"
          autocomplete="off"
          [placeholder]="placeholder"
          (input)="onInput($event)"
          (keydown)="onKeydown($event)"
          #externalTriggerSearch>
      </div>

      <div class="fa-head">
        <ng-content select="[fa-head]"></ng-content>
      </div>

      <ng-container [ngSwitch]="getHelpTextState()">
        <div class="no-match" *ngSwitchCase="helpTextStates.LOADING">
          <ng-content select="[faCustomLoadingText]"></ng-content>
          <span *ngIf="!faCustomLoadingText">Loading...</span>
        </div>

        <div class="no-match" *ngSwitchCase="helpTextStates.NO_MATCH">
          <ng-content select="[faNotFound]"></ng-content>
          <span *ngIf="!faNotFound">No match found.</span>
        </div>

        <div class="no-match" *ngSwitchCase="helpTextStates.NO_OPTIONS">
          No options found.
        </div>

        <div
          *ngSwitchCase="helpTextStates.CUSTOM_VALUE"
          class="custom-value no-match">
          <ng-content select="[faCustomValueIcon]"></ng-content>
          <ng-content select="[faCustomValueText]"></ng-content>
          <span *ngIf="!faCustomValueText">Add custom value </span>
          <span class="text-medium ml-1">{{ searchString }}</span>
        </div>
      </ng-container>

      <fa-option
        *ngIf="!renderLoading && renderSelectAll && multi && this.filteredOptionGroups.length !== 0"
        [isActive]="allSelected"
        [multi]="multi"
        [isSelectAll]="true"
        [disabled]="disabled"
        (keyboardFocus)="helpTextCurrentOption = null"
        (select)="onAllOptionsSelect()">
        <div class="option-text">
          <mat-checkbox
            [checked]="allSelected"
            [disabled]="disabled"
            color="primary"
            onclick="return false">
            Select All
          </mat-checkbox>
        </div>
      </fa-option>

      <fa-option
        *ngIf="!renderLoading && showRefreshOptionsList"
        (select)="refreshOptionList()">
        <div class="center-flex-row text-primary">
          Refresh

          <hd-icon name="refresh" size="1" class="ml-1"></hd-icon>
        </div>
      </fa-option>

      <hd-accordion *ngIf="!renderLoading && renderGroupsAsAccordion" #accordionRef>
        <hd-accordion-item
          *ngFor="let optionGroup of displayedOptionGroups; trackBy: groupIdTracker"
          (keyboardFocus)="helpTextCurrentOption = null"
          [title]="optionGroup.value[groupAccessor]">
          <fa-option
            accordionContent
            *ngFor="let option of optionGroup.children; index as index; even as even; trackBy: optionIdTracker.bind(this)"
            [isActive]="option.isActive"
            [disabled]="disabled || option.isDisabled"
            [multi]="multi"
            [option]="option.value"
            [style.padding-left]="'26px'"
            (mouseleave)="helpTextCurrentOption = null"
            (mouseenter)="helpTextCurrentOption = option"
            (keyboardFocus)="helpTextCurrentOption = option"
            (select)="onSelect($event)">
            <div
              [faItemContent]="(faItemTemplate && faItemTemplate.elRef) ? faItemTemplate.elRef : faItemDefaultTemplate"
              [option]="option.value"
              [index]="index"
              [even]="even"
              [active]="option.isActive">
            </div>
          </fa-option>
        </hd-accordion-item>
      </hd-accordion>

      <ng-container *ngIf="!renderLoading && !renderGroupsAsAccordion">
        <div
          class="fa-option-group"
          *ngFor="let optionGroup of displayedOptionGroups; index as groupIndex; even as groupEven; trackBy: groupIdTracker">
          <ng-container
            *ngIf="optionGroups.length > 1"
            [faGroupContent]="(faGroupTemplate && faGroupTemplate.elRef) ? faGroupTemplate.elRef : faGroupDefaultTemplate"
            [group]="optionGroup.value"
            [index]="groupIndex"
            [even]="groupEven"
            [active]="optionGroup.isActive">
          </ng-container>

          <fa-option
            *ngFor="let option of optionGroup.children; index as index; even as even; trackBy: optionIdTracker.bind(this)"
            [isActive]="option.isActive"
            [disabled]="disabled || option.isDisabled"
            [multi]="multi"
            [option]="option.value"
            (mouseleave)="helpTextCurrentOption = null"
            (mouseenter)="helpTextCurrentOption = option"
            (keyboardFocus)="helpTextCurrentOption = option"
            (select)="onSelect($event)">
            <div
              [faItemContent]="(faItemTemplate && faItemTemplate.elRef) ? faItemTemplate.elRef : faItemDefaultTemplate"
              [option]="option.value"
              [index]="index"
              [even]="even"
              [active]="option.isActive">
            </div>
          </fa-option>
        </div>
      </ng-container>
    </hd-scroll-viewport>
  </div>
</ng-template>

<ng-content></ng-content>
