export const getAllJobsFactory = jobs => {
  const response = { total_count: jobs.total_count, data: getCleanAllJobsResponse(jobs.data) };
  return response;
};

const getCleanAllJobsResponse = data =>
  data.map(job => ({
    mode: job.type.toLowerCase(),
    jobId: job.job_id,
    pipeline: {
      source: {
        seqId: job.integration_details.seq_id,
        logoURL: job.integration_details.source_type_logo_url,
        darkModeLogoURL: job.integration_details.source_type_logo_url,
        name: job.integration_details.source_name,
        sourceType: job.integration_details.source_type
      },
      destination: {
        logoURL: job.integration_details.dest_type_logo_url,
        darkModeLogoURL: job.integration_details.dest_type_logo_url,
        name: job.integration_details.dest_name,
        destinationType: job.integration_details.destination_type
      }
    },
    startedAt: job.created_at,
    duration: job.duration,

    events: {
      ingested: job.events_ingested,
      failed: job.events_failed,
      loaded: job.events_loaded,
      billable: job.billable_events
    },
    status: {
      value: job.status.value,
      colorType: job.status.color
    }
  }));

export const getAllJobsForPipelineFactory = jobs => {
  const response = {
    total_count: jobs.total_count,
    data: getCleanAllJobsForPipelineResponse(jobs.data)
  };
  return response;
};

const getCleanAllJobsForPipelineResponse = jobs =>
  jobs.map(job => ({
    mode: job.type.toLowerCase(),
    jobId: job.job_id,
    startedAt: job.created_at,
    duration: job.duration,
    events: {
      ingested: job.events_ingested,
      failed: job.events_failed,
      loaded: job.events_loaded,
      billable: job.billable_events || 0
    },
    status: {
      value: job.status.value,
      colorType: job.status.color
    }
  }));

const statusIconMap = {
  'in progress': 'testing',
  success: 'checked-tick',
  completed: 'checked-tick',
  failed: 'error-filled'
};

export const getJobDetailsFactory = job => ({
  data: {
    jobId: job.job_id,
    mode: job.type.toLowerCase(),
    status: {
      icon: statusIconMap[job.status.value.toLowerCase()],
      value: job.status.value.toLowerCase(),
      colorType: job.status.color
    },
    stats: {
      startedAt: job.created_at,
      duration: job.duration,
      objectsSuccess: job.objects_success,
      objectsQueued: job.objects_queued,
      objectsFailed: job.objects_failed,
      eventsIngested: job.events_ingested,
      eventsLoaded: job.events_loaded,
      eventsFailed: job.events_failed,
      billableEvents: job.billable_events,
      nonBillableEvents: job.non_billable_events
    }
  }
});

export const getObjectsForJobFactory = res => {
  const response = { total_count: res.total_count, data: getCleanObjectsForJob(res.data) };
  return response;
};

const getCleanObjectsForJob = data =>
  data.map(object => ({
    objectId: object.object_id,
    objectName: object.object_name,
    eventsLoaded: object.stages.load.output_events,
    eventsFailed: object.stages.ingestion.failed_events + object.stages.load.failed_events,
    status: {
      value: object.status.value,
      colorType: object.status.color
    },
    objectDetails: [
      getStageDetails(object.stages.ingestion, 'Ingest'),
      getStageDetails(object.stages.load, 'Load')
    ]
  }));

const getStageDetails = (data, stage) => ({
  stage,
  startedAt: data?.started_ts,
  inputEvents: data.input_events,
  outputEvents: data.output_events,
  failedEvents: data.failed_events,
  duration: data?.duration,
  status: {
    value: data.status?.value.toLowerCase(),
    colorType: data.status?.color
  }
});
