// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.modelViewContainer_roGyp {\n  display: flex;\n  overflow: hidden;\n  align-items: center;\n}\n\n.nodeImage__saTU {\n  width: 16px;\n  height: 16px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  flex-shrink: 0;\n  margin-right: 4px;\n}\n.nodeImage__saTU img {\n  max-width: 14px;\n  max-height: 14px;\n}\n.nodeImage__saTU hd-icon, .nodeImage__saTU .hevo-icon_cz7OK {\n  font-size: 14px;\n}\n\n.icon_qXL8k {\n  flex-shrink: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelViewContainer": "modelViewContainer_roGyp",
	"nodeImage": "nodeImage__saTU",
	"hevo-icon": "hevo-icon_cz7OK",
	"icon": "icon_qXL8k"
};
export default ___CSS_LOADER_EXPORT___;
