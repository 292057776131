import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import { getDataIdGenerator } from '../../utils/generateDataId';
import HdIcon from '../UIElements/HdIcon';
import styles from './styles.module.scss';
import useDebounce from '../../hooks/useDebounce';
import useDontMountAtFirst from '../../hooks/useDontMountAtFirst';
import useInput from '../../hooks/useInput';
import { HdInputBase } from '../UIElements/HdTextField';
import { HdIconButton } from '../UIElements';

export interface SearchProps {
  dataId: string;
  placeholder?: string;
  autofocus?: boolean;
  fullWidth?: boolean;
  hasBorder?: boolean;
  iconSize?: number;
  className?: string;
  filled?: boolean;
  wrapperClassName?: string;
  disabled?: boolean;
  defaultExpanded?: boolean;
  debounceInterval?: number;
  collapsible?: boolean;
  defaultSearch?: string;
  onChange?: (string) => void;
  onSearch: (val: string) => void;
  onCollapsedStateChange?: (state: boolean) => void;
  keyDown?: Function;
  currentValue?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onRefUpdate?: (ref: React.Ref<any>) => void;
  searchWrapperClassName?: string;
}

export default function SearchArea({
  dataId,
  placeholder,
  autofocus,
  fullWidth = false,
  hasBorder = true,
  iconSize,
  className,
  filled,
  wrapperClassName = '',
  disabled,
  defaultExpanded = true,
  onSearch,
  debounceInterval = 0,
  collapsible = true,
  defaultSearch = '',
  onChange,
  onCollapsedStateChange,
  keyDown,
  currentValue = '',
  onBlur,
  onFocus,
  onRefUpdate,
  searchWrapperClassName = ''
}: SearchProps) {
  const [expanded, setExpanded] = useState(collapsible ? defaultExpanded : true);
  const [val, setVal] = useInput(defaultSearch);
  const debouncedSearchTerm = useDebounce(val, debounceInterval);
  const iconSizeFinal = iconSize || (collapsible ? 2 : 3);

  const inputRef = useRef(null);

  useEffect(() => {
    if (onRefUpdate) {
      onRefUpdate(inputRef.current);
    }
  }, []);

  useEffect(() => {
    if (currentValue !== val && currentValue.length) {
      setVal({ target: { value: currentValue } }, false);
      setExpanded(true);
    }
  }, [currentValue]);

  useEffect(() => {
    if (onChange) {
      onChange(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  useDontMountAtFirst(() => {
    if (onSearch) {
      onSearch(val);
    }
  }, [debouncedSearchTerm, onSearch]);

  useDontMountAtFirst(() => {
    if (expanded && inputRef.current) {
      inputRef.current.focus();
    }

    if (onCollapsedStateChange) {
      onCollapsedStateChange(expanded);
    }
  }, [expanded]);

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const clearSearch = (e: any) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setVal(e, true);
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 27 && !val && expanded && collapsible) {
      setExpanded(false);
      return;
    }

    if (keyDown) {
      keyDown(e);
    }
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'search'), [dataId]);

  return (
    <div
      className={clsx(
        styles.searchWrapper,
        collapsible && expanded && styles.collapsible,
        expanded && fullWidth && styles.fullWidth,
        !hasBorder && styles.noBorder,
        filled && styles.filled,
        searchWrapperClassName
      )}
      data-id={dataIdGenerator('wrapper')}
    >
      {expanded ? (
        <Paper
          className={`${styles.searchBox} ${
            collapsible && expanded ? styles.expanded : ''
          } ${wrapperClassName}`}
          sx={{ color: 'inherit' }}
          data-id='search-box'
        >
          <HdIcon name='search' size={iconSizeFinal} className={styles.icon} />

          <HdInputBase
            inputProps={{
              'aria-label': placeholder,
              'data-id': dataIdGenerator('input')
            }}
            inputRef={inputRef}
            className={className}
            autoFocus={autofocus}
            placeholder={placeholder}
            value={val}
            onChange={e => setVal(e)}
            onKeyDown={e => onKeyDown(e)}
            disabled={disabled}
            onBlur={e => onBlur && onBlur(e)}
            onFocus={e => onFocus && onFocus(e)}
          />

          {(typeof val === 'string' && val.length > 0) || collapsible === true ? (
            <HdIconButton
              aria-label='search'
              type='button'
              onClick={clearSearch}
              className={`${styles.actionButton} pure-ripple`}
              dataId={dataIdGenerator('clear')}
            >
              <HdIcon name='close' />
            </HdIconButton>
          ) : null}
        </Paper>
      ) : (
        <HdIconButton
          aria-label='search'
          type='button'
          onClick={toggleExpand}
          className={styles.actionButton}
          dataId={dataIdGenerator('toggle')}
        >
          <HdIcon name='search' size={3} />
        </HdIconButton>
      )}
    </div>
  );
}
