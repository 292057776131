import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ReplaySamplesDialog } from '../../react/containers/pipeline/Sideline/ReplaySamplesDialog';
import { BridgeService } from '../react-bridge/services/bridge.service';
import { SidelineMessage } from '../sideline/models/sideline-message';

@Component({
  selector: 'replay-samples-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class ReplaySamplesDialogReactComponent implements OnChanges {
  @Input() open = false;
  @Input() pipelineId?: number;
  @Input() events?: object[];
  @Input() sidelineMessage?: SidelineMessage;


  @Output() onClose = new EventEmitter<void>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount(
      ReplaySamplesDialog,
      this._el.nativeElement,
      {
        open: this.open,
        pipelineId: this.pipelineId,
        events: this.events,
        sidelineMessage: this.sidelineMessage,
        onClose: this.onClose.emit.bind(this.onClose)
      }
    );
  }
}
