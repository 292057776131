import { Component, ElementRef, Input } from '@angular/core';


@Component({
  selector: '[hdButton]',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ]
})
export class ButtonComponent {
  private _icon: string;

  @Input() get icon() {
    return this._icon;
  }
  set icon(value) {
    this._removeClasses();
    this._icon = value;
    this._addClasses();
  }

  private _iconOnly: boolean;
  @Input() get iconOnly() {
    return this._iconOnly;
  }
  set iconOnly(value) {
    this._removeClasses();
    this._iconOnly = value;
    this._addClasses();
  }

  private _iconSrc: string;
  @Input() get iconSrc() {
    return this._iconSrc;
  }

  set iconSrc(value) {
    this._removeClasses();
    this._iconSrc = value;
    this._addClasses();
  }

  @Input() iconClass = '';

  private _variation: string;
  @Input() get variation() {
    return this._variation;
  }

  set variation(value) {
    this._removeClasses();
    this._variation = value;
    this._addClasses();
  }

  private _size: 'lg' | 'sm';
  @Input() get size() {
    return this._size;
  }
  set size(value) {
    this._removeClasses();
    this._size = value;
    this._addClasses();
  }

  private _color: string;
  @Input() get color() {
    return this._color;
  }
  set color(value) {
    this._removeClasses();
    this._color = value;
    this._addClasses();
  }

  private _showProgress: boolean;
  @Input() get showProgress() {
    return this._showProgress;
  }
  set showProgress(value) {
    this._removeClasses();
    this._showProgress = value;
    this._addClasses();
  }

  private _active: boolean;
  @Input() get active() {
    return this._active;
  }
  set active(value) {
    this._removeClasses();
    this._active = value;
    this._addClasses();
  }

  private _filledBg: boolean;
  @Input() get filledBg() {
    return this._filledBg;
  }
  set filledBg(value) {
    this._removeClasses();
    this._filledBg = value;
    this._addClasses();
  }

  private _inlineText: boolean;
  @Input() get inlineText() {
    return this._inlineText;
  }
  set inlineText(value) {
    this._removeClasses();
    this._inlineText = value;
    this._addClasses();
  }

  private _edge: 'start' | 'end';
  @Input() get edge() {
    return this._edge;
  }
  set edge(value) {
    this._removeClasses();
    this._edge = value;
    this._addClasses();
  }

  private _class: string;
  @Input('class') get clazz() {
    return this._class;
  }
  set clazz(value) {
    this._removeClasses();
    this._class = value;
    this._addClasses();
  }

  private _direction?: 'left' | 'right' = 'left';
  @Input() get direction() {
    return this._direction;
  }
  set direction(value) {
    this._removeClasses();
    this._direction = value;
    this._addClasses();
  }

  constructor(private _el: ElementRef) {
    this._addClasses();
  }

  private _removeClasses() {
    const classList: DOMTokenList = (<HTMLElement>this._el.nativeElement).classList;

    classList.remove('btn');
    classList.remove('btn-thumbnail');
    classList.remove('active');
    classList.remove('filled-bg');
    classList.remove('in-progress');
    classList.remove('with-inline-text');

    if (this._variation) {
      classList.remove(`btn-${ this._variation }`);
    }

    if (this._size) {
      classList.remove(`btn-${ this._size }`);
    }

    const prevColor = this._getResolvedColor(this._variation, this._color);
    if (prevColor) {
      classList.remove(`btn-${ prevColor }`);
    }

    if (this._direction) {
      classList.remove(`btn-thumbnail-${ this._direction }`);
    }

    if (this._edge) {
      classList.remove(`btn-edge-${ this._edge }`);
    }

    if (this._icon || this._iconSrc) {
      classList.remove(`btn-thumbnail-${ this._direction }`);
    }
  }

  _addClasses() {
    const classList: DOMTokenList = (<HTMLElement>this._el.nativeElement).classList;

    if (this.variation) {
      classList.add(`btn-${this.variation}`);
    } else {
      classList.add(`btn`);
    }

    if (this.icon || this.iconSrc) {
      if (this.iconOnly) {
        classList.add('btn-thumbnail');
      } else {
        classList.add(`btn-thumbnail-${ this.direction }`);
      }
    }

    if (this.size) {
      classList.add(`btn-${ this.size }`);
    }

    const color = this._getResolvedColor(this.variation, this.color);
    if (color) {
      classList.add(`btn-${ color }`);
    }

    if (this.showProgress) {
      classList.add('in-progress');
    }

    if (this.active) {
      classList.add('active');
    }

    if (this.filledBg) {
      classList.add('filled-bg');
    }

    if (this.inlineText) {
      classList.add('with-inline-text');
    }

    if (this.edge) {
      classList.add(`btn-edge-${ this.edge }`);
    }
  }

  private _getResolvedColor(variation: string, color: string) {
    if (color) {
      return color;
    }

    if (!variation || variation === 'flat' || variation === 'faded') {
      return 'primary';
    }

    if (variation === 'outline') {
      return 'secondary';
    }

    if (variation === 'push') {
      return 'info';
    }
  }
}
