import { useHistory } from '@useHistory/useHistoryCompat';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { AuthService } from '../../../app/core/service/auth.service';
import useService from '../../hooks/useService';

export function useAuthGuard() {
  const history = useHistory();
  const authService = useService(AuthService);

  const executeAuthGuard = () => {
    const requestOptions: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse: false,
        showLoading: false
      }
    };

    const searchParams = new URLSearchParams(history.location?.search);

    const sessionToken = searchParams.get('session_token');

    if (sessionToken) {
      return authService
        .loginWithSessionToken(sessionToken)
        .pipe(
          map(() => {
            history.replace(history.location.pathname);
            return true;
          }),
          catchError(error => {
            if (error.status === 401) {
              return authService.onUnAuthorisedEvent(false).pipe(map(res => false));
            }

            return of(false);
          })
        )
        .toPromise();
    }
    return authService
      .getLoggedInUser(requestOptions, true)
      .pipe(
        map(res => true),
        catchError((error: any) => {
          if (error.status === 401) {
            return authService.onUnAuthorisedEvent(true).pipe(map(() => false));
          }

          return of(false);
        })
      )
      .toPromise();
  };

  return {
    executeAuthGuard
  };
}
