import React from 'react';
import { LinearProgress, styled } from '@mui/material';

const HdLinearProgress = styled(LinearProgress)(({ theme }) => ({
 height: 3,
 '&.MuiLinearProgress-colorPrimary': {
    'backgroundColor': 'var(--primary-faded-color)'
 },
 '.MuiLinearProgress-barColorPrimary': {
   'backgroundColor': 'var(--primary-color)'
 }
}));

export default HdLinearProgress;
