import { AzureSynapseConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/AzureSynapse/configHelper';
import { BigQueryConfigHelperReact } from '../../../react/containers/destination/create/ConfigDestinationType/BigQuery/configHelper';
import { BlackHoleConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/BlackHole/configHelper';
import { DatabricksConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/Databricks/configHelper';
import { FireboltConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/Firebolt/configHelper';
import { DestinationS3ConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/S3/configHelper';
import { DestinationS3NewConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/S3New/configHelper';
import { SnowflakeConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/Snowflake/configHelper';
import { DestinationSqlConfigHelper } from '../../../react/containers/destination/create/ConfigDestinationType/Sql/configHelper';
import { EditorModes, FormatterModes } from '../../code-editor/constants';
import { ViewLibrary } from '../../react-bridge/models/view-library';
import { ManagedBigqueryConfigHelper } from '../managed-warehouse/managed-bigquery-config-helper';
import { NodeTypeConfigHelperConstructor } from '../node-config-helper';
import { MYSQL_SETUP_WARNING } from './constants';


export interface RawDestinationType {
  dest_type: string;
  dest_type_display: string;
  [key: string]: any;
}

export interface DestinationType {
  name: string;
  displayName?: string; // Extrinsic value, populated via API
  isJDBCType?: boolean;
  requireAuth?: boolean;
  isAuthTypeGoogle?: boolean;
  isPartitionBased?: boolean;
  canUseExisting?: boolean;
  queryEditorMode: 'SQL' | 'JSON';
  provideSchemaName?: boolean;
  testEnabled?: boolean;
  allowJSONParsingStrategy?: boolean;
  canUseSSL?: boolean;
  sslCARequired?: boolean;
  populateLoadedAt?: boolean;
  isFileBased?: boolean;
  primaryColor?: string;
  darkModePrimaryColor?: string;
  docLink?: string;
  loadPendingEventsActionAvailable?: boolean;
  canUpdateDedup?: boolean;
  setupWarning?: string;
  canShowSideBySideDocs?: boolean;
  showSBSTabs?: boolean;
  requireServiceAuth?: boolean;
  isActivateDestination?: boolean;
  editorMode?: EditorModes;
  formatterMode?: FormatterModes;
  queryFormattingDisabled?: boolean;
  showNewBadge?: boolean;
  showBetaBadge?: boolean;
  warehouseDocLink?: string;
  warehousePermissionsDocLink?: string;
  isBlackHole?: boolean;
  formRenderingLibrary?: ViewLibrary;
  configHelper?: NodeTypeConfigHelperConstructor;
  submitCTAEnabledOnInvalidForm?: boolean;
}

export const DESTINATION_TYPES: { [key: string]: DestinationType } = {
  'REDSHIFT': {
    name: 'REDSHIFT',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgb(59, 149, 212)',
    darkModePrimaryColor: '#66A2DF',
    docLink: '/destinations/data-warehouses/amazon-redshift/',
    warehouseDocLink: '/activate/activate-warehouses/amazon-redshift/',
    warehousePermissionsDocLink: '/activate/activate-warehouses/amazon-redshift/#create-a-user-and-grant-privileges',
    loadPendingEventsActionAvailable: true,
    canUpdateDedup: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    isActivateDestination: true,
    formatterMode: FormatterModes.REDSHIFT,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SNOWFLAKE': {
    name: 'SNOWFLAKE',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgb(0, 194, 241)',
    darkModePrimaryColor: '#00C2F7',
    docLink: '/destinations/data-warehouses/snowflake/',
    warehouseDocLink: '/activate/activate-warehouses/snowflake/',
    warehousePermissionsDocLink: '/activate/activate-warehouses/snowflake/#create-a-role-and-grant-permissions',
    loadPendingEventsActionAvailable: true,
    canUpdateDedup: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    isActivateDestination: true,
    queryFormattingDisabled: true,
    configHelper: SnowflakeConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'FIREBOLT': {
    name: 'FIREBOLT',
    requireAuth: false,
    isPartitionBased: false,
    queryEditorMode: 'SQL',
    canUseExisting: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: false,
    populateLoadedAt: true,
    isFileBased: true,
    primaryColor: 'rgb(248, 43, 48)',
    darkModePrimaryColor: '#F82B30',
    docLink: '/destinations/data-warehouses/firebolt/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    requireServiceAuth: false,
    isActivateDestination: false,
    showNewBadge: true,
    queryFormattingDisabled: true,
    configHelper: FireboltConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BIGQUERY': {
    name: 'BIGQUERY',
    // requireAuth: true,
    // isAuthTypeGoogle: true,
    queryEditorMode: 'JSON',
    testEnabled: true,
    allowJSONParsingStrategy: false,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgb(69, 134, 247)',
    darkModePrimaryColor: '#6FAAFF',
    docLink: '/destinations/data-warehouses/google-bigquery/',
    warehouseDocLink: '/activate/activate-warehouses/google-bigquery/',
    warehousePermissionsDocLink: '/activate/activate-warehouses/google-bigquery/#permissions',
    loadPendingEventsActionAvailable: true,
    canUpdateDedup: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    // requireServiceAuth: true,
    isActivateDestination: true,
    queryFormattingDisabled: true,
    formRenderingLibrary: ViewLibrary.REACT,
    configHelper: BigQueryConfigHelperReact,
    submitCTAEnabledOnInvalidForm: true
  },
  'DATABRICKS': {
    name: 'DATABRICKS',
    requireAuth: false,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: false,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgb(255, 54, 32)',
    darkModePrimaryColor: '#FF3620',
    docLink: '/destinations/data-warehouses/databricks/',
    loadPendingEventsActionAvailable: true,
    canUpdateDedup: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    provideSchemaName: true,
    requireServiceAuth: false,
    isActivateDestination: false,
    queryFormattingDisabled: true,
    showNewBadge: true,
    showBetaBadge: true,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    canUseExisting: true,
    configHelper: DatabricksConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'MANAGED_BIGQUERY': {
    name: 'MANAGED_BIGQUERY',
    queryEditorMode: 'JSON',
    testEnabled: true,
    allowJSONParsingStrategy: false,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgb(69, 134, 247)',
    darkModePrimaryColor: '#6FAAFF',
    docLink: '/destinations/data-warehouses/managed-google-bigquery-dw/',
    queryFormattingDisabled: true,
    configHelper: ManagedBigqueryConfigHelper
  },
  'MYSQL': {
    name: 'MYSQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#E87912',
    docLink: '/destinations/databases/mysql/',
    setupWarning: MYSQL_SETUP_WARNING,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AURORA': {
    name: 'AURORA',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#E87912',
    docLink: '/destinations/databases/amazon-aurora/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    setupWarning: MYSQL_SETUP_WARNING,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_RDS_MYSQL': {
    name: 'AWS_RDS_MYSQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#E87912',
    docLink: '/destinations/databases/mysql/',
    setupWarning: MYSQL_SETUP_WARNING,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GCP_MYSQL': {
    name: 'GCP_MYSQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#E87912',
    docLink: '/destinations/databases/mysql/',
    setupWarning: MYSQL_SETUP_WARNING,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AZURE_MYSQL': {
    name: 'AZURE_MYSQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#E87912',
    docLink: '/destinations/databases/mysql/',
    setupWarning: MYSQL_SETUP_WARNING,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'ORACLE_MYSQL': {
    name: 'ORACLE_MYSQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#E87912',
    docLink: '/destinations/databases/mysql/',
    setupWarning: MYSQL_SETUP_WARNING,
    editorMode: EditorModes.MY_SQL,
    formatterMode: FormatterModes.MY_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'POSTGRES': {
    name: 'POSTGRES',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: false,
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    docLink: '/destinations/databases/postgresql/',
    warehouseDocLink: '/activate/activate-warehouses/postgresql/',
    warehousePermissionsDocLink: '/activate/activate-warehouses/postgresql/#create-a-user-and-grant-privileges',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    isActivateDestination: true,
    editorMode: EditorModes.POSTGRE_SQL,
    formatterMode: FormatterModes.POSTGRE_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_RDS_POSTGRES': {
    name: 'AWS_RDS_POSTGRES',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: false,
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    docLink: '/destinations/databases/postgresql/',
    editorMode: EditorModes.POSTGRE_SQL,
    formatterMode: FormatterModes.POSTGRE_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_AURORA_POSTGRES': {
    name: 'AWS_AURORA_POSTGRES',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: false,
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    docLink: '/destinations/databases/postgresql/',
    editorMode: EditorModes.POSTGRE_SQL,
    formatterMode: FormatterModes.POSTGRE_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GCP_POSTGRES': {
    name: 'GCP_POSTGRES',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: false,
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    docLink: '/destinations/databases/postgres/',
    editorMode: EditorModes.POSTGRE_SQL,
    formatterMode: FormatterModes.POSTGRE_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AZURE_POSTGRES': {
    name: 'AZURE_POSTGRES',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: false,
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    docLink: '/destinations/databases/postgresql/',
    editorMode: EditorModes.POSTGRE_SQL,
    formatterMode: FormatterModes.POSTGRE_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'SF_HEROKU_POSTGRES': {
    name: 'SF_HEROKU_POSTGRES',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    canUseSSL: true,
    sslCARequired: false,
    primaryColor: 'rgb(31, 104, 149)',
    darkModePrimaryColor: '#3B95D4',
    docLink: '/destinations/databases/postgresql/',
    editorMode: EditorModes.POSTGRE_SQL,
    formatterMode: FormatterModes.POSTGRE_SQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'MS_SQL': {
    name: 'MS_SQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    primaryColor: 'rgb(255, 140, 155)',
    docLink: '/destinations/databases/microsoft-sql-server/',
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    editorMode: EditorModes.MS_SQL,
    formatterMode: FormatterModes.TSQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AWS_RDS_MSSQL': {
    name: 'AWS_RDS_MSSQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    primaryColor: 'rgb(255, 140, 155)',
    docLink: '/destinations/databases/microsoft-sql-server/',
    editorMode: EditorModes.MS_SQL,
    formatterMode: FormatterModes.TSQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'GCP_MS_SQL': {
    name: 'GCP_MS_SQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    primaryColor: 'rgb(255, 140, 155)',
    docLink: '/destinations/databases/microsoft-sql-server/',
    editorMode: EditorModes.MS_SQL,
    formatterMode: FormatterModes.TSQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AZURE_SQL': {
    name: 'AZURE_SQL',
    isJDBCType: true,
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    primaryColor: 'rgb(255, 140, 155)',
    docLink: '/destinations/databases/microsoft-sql-server/',
    editorMode: EditorModes.MS_SQL,
    formatterMode: FormatterModes.TSQL,
    configHelper: DestinationSqlConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'S3': {
    name: 'S3',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isPartitionBased: true,
    primaryColor: 'rgb(224, 82, 67)',
    darkModePrimaryColor: '#E05243',
    docLink: '',
    configHelper: DestinationS3ConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'S3_NEW': {
    name: 'S3_NEW',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isPartitionBased: true,
    primaryColor: 'rgb(224, 82, 67)',
    darkModePrimaryColor: '#E05243',
    docLink: '/destinations/cloud-storage-based/amazon-s3-destination',
    showNewBadge: true,
    showSBSTabs: true,
    canShowSideBySideDocs: true,
    configHelper: DestinationS3NewConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BLACK_HOLE': {
    name: 'BLACK_HOLE',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#00C2F7',
    isBlackHole: true,
    docLink: '/destinations/data-warehouses/black-hole/',
    configHelper: BlackHoleConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BLACK_HOLE_BQ': {
    name: 'BLACK_HOLE_BQ',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#00C2F7',
    isBlackHole: true,
    docLink: '/destinations/data-warehouses/black-hole/',
    queryFormattingDisabled: true,
    configHelper: BlackHoleConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'BLACK_HOLE_SF': {
    name: 'BLACK_HOLE_SF',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    provideSchemaName: true,
    testEnabled: true,
    allowJSONParsingStrategy: true,
    isFileBased: true,
    populateLoadedAt: true,
    primaryColor: 'rgba(255, 162, 48, .15)',
    darkModePrimaryColor: '#00C2F7',
    isBlackHole: true,
    docLink: '/destinations/data-warehouses/black-hole/',
    queryFormattingDisabled: true,
    configHelper: BlackHoleConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
  'AZURE_SYNAPSE': {
    name: 'AZURE_SYNAPSE',
    canUseExisting: true,
    queryEditorMode: 'SQL',
    testEnabled: true,
    populateLoadedAt: true,
    primaryColor: 'rgba(24, 116, 186, 0.1)',
    darkModePrimaryColor: 'rgba(24, 116, 186, 0.15)',
    docLink: '/destinations/data-warehouses/azure-synapse/',
    canUpdateDedup: true,
    canShowSideBySideDocs: true,
    showSBSTabs: true,
    showNewBadge: true,
    editorMode: EditorModes.MS_SQL,
    formatterMode: FormatterModes.TSQL,
    configHelper: AzureSynapseConfigHelper,
    formRenderingLibrary: ViewLibrary.REACT
  },
};
