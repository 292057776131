import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogService } from '../../dialog/confirm-dialog/confirm-dialog.service';
import { ConfirmV2DialogService } from '../../dialog/confirm-v2-dialog/confirm-v2-dialog.service';
import { ConfirmationDialogVersions } from '../../dialog/models';


@Injectable({ providedIn: 'root' })
export class MutationService {
  constructor(
    private _dialogService: ConfirmDialogService,
    private _confirmV2Service: ConfirmV2DialogService
  ) {
  }

  alertAndMutate(
    action: Observable<any>,
    dialogConfig: any,
    beforeAction?: () => void,
    confirmationDialogVersion = ConfirmationDialogVersions.VERSION_1
  ) {
    let dialogConfirmationObs: Observable<any>;

    if (confirmationDialogVersion === ConfirmationDialogVersions.VERSION_1) {
      dialogConfirmationObs = this._dialogService.confirm(
        dialogConfig.title,
        dialogConfig.body,
        dialogConfig.positiveButton,
        dialogConfig.negativeButton,
        dialogConfig.showCheckBox,
        undefined,
        undefined,
        dialogConfig.dialogBody,
        dialogConfig.bodyData,
        dialogConfig.bodyComponent,
        dialogConfig.requiresExtraConfirmation
      );
    } else {
      dialogConfirmationObs = this._confirmV2Service.confirm(dialogConfig);
    }

    return dialogConfirmationObs.pipe(
      switchMap((confirm) => {
        if (confirm) {
          if (typeof beforeAction === 'function') {
            beforeAction();
          }

          return action;
        }

        return of(confirm);
      })
    );
  }

  mutateWithConfirmation(
    action: (force: boolean) => Observable<any>,
    dialogConfig: (mutationRes: any) => any,
    needConfirmation: (mutationRes: any) => boolean,
    confirmationDialogVersion = ConfirmationDialogVersions.VERSION_1
  ): Observable<{ actionTaken: boolean, res?: any }> {
    return action(false).pipe(
      switchMap((res) => {
        if (!needConfirmation(res)) {
          return of({
            actionTaken: true,
            res: res
          });
        }

        const config: any = dialogConfig(res);

        let dialogConfirmationObs: Observable<any>;
        if (confirmationDialogVersion === ConfirmationDialogVersions.VERSION_1) {
          dialogConfirmationObs = this._dialogService.confirm(
            config.title,
            config.body,
            config.positiveButton,
            config.negativeButton,
            config.showCheckBox,
            undefined,
            undefined,
            config.dialogBody,
            config.bodyData
          );
        } else {
          dialogConfirmationObs = this._confirmV2Service.confirm(config);
        }

        return dialogConfirmationObs.pipe(
          switchMap((confirm) => {
            if (!confirm) {
              return of({ actionTaken: false, res });
            }

            return action(true).pipe(
              switchMap((forceRes) => {
                return of({
                  actionTaken: true,
                  res: forceRes
                });
              })
            );
          })
        );
      })
    );
  }
}
