import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxMdService } from 'ngx-md';
import { BehaviorSubject, combineLatest, from, merge, of, Subject, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  groupBy,
  map,
  mergeMap,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

import { AlertDialogService } from '../../dialog/alert-dialog/alert-dialog.service';
import { ModelFactory } from '../../../react/containers/transform-model/model-factory';
import { Model } from '../../../react/containers/transform-model/models';
import { ModelStrings } from '../../../react/containers/transform-model/strings';
import { ToasterService } from '../../toaster/toaster.service';
import { MODEL_DETAIL_FAST_POLL_INTERVAL, MODEL_DETAIL_POLL_INTERVAL } from '../constants';
import { EntityUIState, EntityUIStateWrapper } from '../models/entitiy-ui-state';
import { NetworkRequestOptions } from '../models/request';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { muteFirst } from '../../../react/legacy-utils/rxjs-observables';
import { AuthService } from './auth.service';
import { ModelService } from './model.service';
import { ConfirmV2DialogService } from '../../dialog/confirm-v2-dialog/confirm-v2-dialog.service';
import { ENTITY_DELETE_DIALOG_COMMON_CONFIG } from '../constants';


@Injectable()
export class ModelListService {
  constructor(
    private _modelService: ModelService,
    private _authService: AuthService,
    private _alertDialogService: AlertDialogService,
    private _toasterService: ToasterService,
    private _router: Router,
    private _markdownService: NgxMdService,
    private _confirmV2Service: ConfirmV2DialogService
  ) {}

  private _modelsSubject = new BehaviorSubject<any>({});
  private _modelDrawerSubject = new BehaviorSubject<number[]>([]);
  private _modelListStateSubject = new BehaviorSubject<EntityUIStateWrapper>({
    state: EntityUIState.NEW
  });
  private _modelsStateSubject = new BehaviorSubject<any>({});
  private _modelsConsumersSubject = new BehaviorSubject<(BehaviorSubject<number[]>)[]>([]);

  private _clearDataSubject = new Subject<void>();

  private _needModels$ = new Subject<void>();
  private _needModel$ = new Subject<number>();

  public draftModelActiveId = null;

  private _models$ = this._needModels$.pipe(
    switchMap(() => {
      this._modelListStateSubject.next({
        state: EntityUIState.LOADING
      });

      return from(this._modelService.getModels()).pipe(
        tap((res) => {
          const models = res ? res.data : [];
          const modelsDict = models.reduce((dict, model) => {
            return {
              ...dict,
              [model.id]: model
            };
          }, {});

          this._modelsSubject.next(modelsDict);

          this._modelDrawerSubject.next(models.map((model: any) => {
            return model.id;
          }));

          const uiState = models.length ? EntityUIState.IDLE : EntityUIState.EMPTY;
          this._modelListStateSubject.next({
            state: uiState
          });
        }),
        catchError((err: any) => {
          this._onModelsFetchError(err);
          return of();
        }),
        takeUntil(this._clearDataSubject)
      );
    }),
    share()
  );

  private _model$ = this._needModel$.pipe(
    groupBy(id => id),
    mergeMap((group$) => {
      return group$.pipe(
        exhaustMap((id) => {
          this._onModelFetchStart(id);
          return this._modelService.getModel(id, false).pipe(
            tap((res) => {
              this._onModelFetchSuccess(id);
              this._modelsSubject.next({
                ...this._modelsSubject.getValue(),
                [id]: res.data
              });
            }),
            catchError((err: any) => {
              this._onModelFetchError(id, err);
              return of(null);
            }),
            takeUntil(this._clearDataSubject)
          );
        })
      );
    }),
    share()
  );

  private _pollModels$ = combineLatest([
    this._modelsConsumersSubject,
    this._modelsSubject
  ])
  .pipe(
    switchMap(([consumers, models]) => {
      return combineLatest(consumers).pipe(
        map((consumersData: number[][]) => {
          return consumersData.filter((consumerData: number[]) => Array.isArray(consumers))
          .reduce((acc, val) => acc.concat(val), [])
          .filter((v, i, a) => a.indexOf(v) === i);
        }),
        switchMap((ids: number[]) => {
          const normalIntervalIds = [];
          const fastIntervalIds = [];

          ids.forEach((id) => {
            const model = models[id];
            if (!model || !this._fastPollingFilter(models)) {
              normalIntervalIds.push(id);
            } else {
              fastIntervalIds.push(id);
            }
          });

          const pollTasks = [];
          if (normalIntervalIds.length) {
            pollTasks.push(
              timer(MODEL_DETAIL_POLL_INTERVAL, MODEL_DETAIL_POLL_INTERVAL)
              .pipe(
                switchMap(() => {
                  return this._modelService.getModels(normalIntervalIds);
                }),
                catchError((err) => {
                  return of(null);
                })
              )
            );
          }

          if (fastIntervalIds.length) {
            pollTasks.push(
              timer(MODEL_DETAIL_FAST_POLL_INTERVAL, MODEL_DETAIL_FAST_POLL_INTERVAL)
            .pipe(
              switchMap(() => {
                return this._modelService.getModels(fastIntervalIds);
              }),
              catchError((err) => {
                return of(null);
              })
            )
            );
          }

          return merge(...pollTasks).pipe(
            tap((res: any) => {
              const data = res ? res.data : [];
              const modelsDict = data.reduce((dict: any, model: any) => {
                return {
                  ...dict,
                  [model.id]: model
                };
              }, {});

              this._modelsSubject.next(modelsDict);
            })
          );
        })
      );
    }),
    share()
  );

  private _clearData$ = this._authService.logoutSubject.pipe(
    tap(() => {
      this._modelsSubject.next({});
      this._modelDrawerSubject.next([]);
      this._clearDataSubject.next();
    }),
    share()
  );

  private _modelEffects$ = combineLatest([
    this._models$.pipe(startWith(null)),
    this._model$.pipe(startWith(null)),
    this._clearData$.pipe(startWith(null)),
    this._pollModels$.pipe(startWith(null))
  ]);

  public modelsState$ = this._modelListStateSubject.asObservable();

  private _fastPollingFilter(model: any) {
    return model.status === 'CANCEL_REQUESTED';
  }

  private _onModelsFetchError(err: any) {
    this._modelListStateSubject.next({
      state: EntityUIState.ERRORED,
      error: err
    });
  }

  private _onModelFetchError(id: number, err: any) {
    this._modelsStateSubject.next({
      ...this._modelsStateSubject,
      [id]: {
        uiState: EntityUIState.ERRORED,
        error: err
      }
    });
  }

  private _onModelFetchStart(id: number) {
    this._modelsStateSubject.next({
      ...this._modelsStateSubject,
      [id]: {
        uiState: EntityUIState.LOADING
      }
    });
  }

  private _onModelFetchSuccess(id: number) {
    this._modelsStateSubject.next({
      ...this._modelsStateSubject,
      [id]: {
        uiState: EntityUIState.IDLE
      }
    });
  }

  private _addConsumer() {
    const consumer = new BehaviorSubject<number[]>(null);
    this._modelsConsumersSubject.next(
      [
        ...this._modelsConsumersSubject.getValue(),
        consumer
      ]
    );
    return consumer;
  }

  private _removeConsumer(consumer) {
    consumer.complete();
    const consumers = [ ...this._modelsConsumersSubject.getValue() ];
    const consumerIndex = consumers.indexOf(consumer);
    consumers.splice(consumerIndex, 1);
    this._modelsConsumersSubject.next(consumers);
  }

  modelListSelector() {
    const consumer = this._addConsumer();
    return muteFirst(
      this._modelEffects$,
      combineLatest([this._modelDrawerSubject, this._modelsSubject])
    ).pipe(
      map(([modelIds, models]) => {
        const filteredIds = modelIds.filter((id) => {
          return !!models[id];
        });

        consumer.next(filteredIds);

        return filteredIds.map((key) => {
          return ModelFactory(models[key]);
        });
      }),
      finalize(() => {
        this._removeConsumer(consumer);
      })
    );
  }

  modelSelector(id: number) {
    const consumer = this._addConsumer();

    return muteFirst(
      this._modelEffects$,
      this._modelsSubject
    ).pipe(
      map((models: any) => models[id]),
      map((model: any) => {
        consumer.next([model.id]);
        return ModelFactory(model);
      }),
      distinctUntilChanged(),
      finalize(() => {
        this._removeConsumer(consumer);
      })
    );
  }

  modelBySeqIdSelector(seqId: number) {
    const consumer = this._addConsumer();

    return muteFirst(
      this._modelEffects$,
      this._modelsSubject
    ).pipe(
      map((models: any) => {
        return Object
        .keys(models)
        .map(key => models[key])
        .find(model => model.seq_id === seqId);
      }),
      filter((model) => !!model),
      map((model: any) => {
        consumer.next([model.id]);
        return ModelFactory(model);
      }),
      distinctUntilChanged(),
      finalize(() => {
        this._removeConsumer(consumer);
      })
    );
  }

  getModels() {
    this._needModels$.next();
  }

  getModel(id: number) {
    this._needModel$.next(id);
  }

  deleteModel(model: Model, onDrawerDeleteClick = false) {
    this._confirmV2Service.confirm({
      title: ModelStrings.deleteModelTitle,
      body: ModelStrings.deleteModelMessage,
      positiveBtnText: ModelStrings.deleteModelPositiveBtn,
      negativeBtnText: ModelStrings.deleteModelNegativeBtn,
      ...ENTITY_DELETE_DIALOG_COMMON_CONFIG
      }
    ).pipe(
      filter(confirm => confirm),
      switchMap(() => {
        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false
          }
        };
        return this._modelService.deleteModel(model.id, options);
      }),
      map(() => {
        this.onModelDelete(model);

        if (onDrawerDeleteClick && parseInt(this.draftModelActiveId, 10) !== model.id) {
          return;
        }

        this._router.navigate(
          ['/model'],
          { replaceUrl: true }
        );
      }),
      catchError((err) => {
        const errMsg: string = getErrorMessageFromObj(err);

        if (!errMsg) {
          return;
        }

        if (model.status.value === 'DRAFT') {
          this._toasterService.pop('error', undefined, errMsg);
          return;
        }

        this._alertDialogService.alert(
          ModelStrings.deleteModelErrorTitle,
          this._markdownService.compile(errMsg),
          ModelStrings.deleteModelErrorPositiveBtn
        );

        return of();
      }),
    ).subscribe();
  }

  onModelDelete(model) {
    const modelsClone = { ...this._modelsSubject.getValue() };
    delete modelsClone[model.id];
    this._modelsSubject.next(modelsClone);
  }

  pauseModel(id: number) {
    this._modelService.pauseModel(id).pipe(
      tap((res) => {
        this.upsertModel(id, res.data);
      })
    ).subscribe();
  }

  resumeModel(id: number) {
    this._modelService.resumeModel(id).pipe(
      tap((res) => {
        this.upsertModel(id, res.data);
      })
    ).subscribe();
  }

  runNow(id: number) {
    this._modelService.runNow(id)
    .pipe(
      switchMap(() => {
        return this.getModelObservable(id);
      })
    ).subscribe();
  }

  stopRunningInstance(model: Model) {
    this._modelService.stopRunningQuery(model.id, model.getLastExecutionId())
    .pipe(
      switchMap(() => {
        return this.getModelObservable(model.id);
      })
    ).subscribe();
  }

  getModelObservable(id: number) {
    return this._modelService.getModel(id)
    .pipe(tap((res) => {
      this.upsertModel(id, res.data);
    }));
  }

  upsertModel(id: number, data: any) {
    const modelRef = this._modelsSubject.getValue()[id];
    this._modelsSubject.next({
      ...this._modelsSubject.getValue(),
      [id]: {
        ...modelRef,
        ...data
      }
    });
  }
}
