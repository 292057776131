import { easings, useChain, useSpring, useSpringRef, useTransition } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { DrawerDefinition } from './DrawersContainer';

export function useDrawerAnimation(activeDrawerDefinition: DrawerDefinition) {
  const [ activeDrawerDefinitionApplied, setActiveDrawerDefinitionApplied ] = useState(null);

  const baseTransitionTime = 300;

  useEffect(() => {
    if (!activeDrawerDefinitionApplied) {
      setActiveDrawerDefinitionApplied(activeDrawerDefinition);
    } else {
      setActiveDrawerDefinitionApplied(null);
    }
  }, [activeDrawerDefinition]);

  const containerSpringScaleRef = useSpringRef();

  const containerSpringScaleValues = useSpring({
    ref: containerSpringScaleRef,
    from: {
      scale: 0
    },
    to: {
      scale: !!activeDrawerDefinition ? 1 : 0
    },
    immediate: true
  });

  const containerSpringOpacityRef = useSpringRef();
  const containerSpringOpacityValues = useSpring({
    ref: containerSpringOpacityRef,
    from: {
      opacity: 0
    },
    to: {
      opacity: !!activeDrawerDefinition ? 1 : 0
    },
    config: {
      duration: baseTransitionTime
    }
  });

  const drawerSpringRef = useSpringRef();

  const drawerTransition = useTransition(activeDrawerDefinitionApplied || [], {
    ref: drawerSpringRef,
    from: (definition) => ({
      x: definition.direction === 'left' ? '-100%' : '100%'
    }),
    enter: {
      x: '0%'
    },
    leave: (definition) => ({
      x: definition.direction === 'left' ? '-100%' : '100%'
    }),
    onDestroyed: () => {
      if (activeDrawerDefinition) {
        setTimeout(() => {
          setActiveDrawerDefinitionApplied(activeDrawerDefinition);
        }, 0);
      }
    },
    config: {
      duration: baseTransitionTime,
      easing: easings.easeInOutCubic
    }
  });

  useChain(
    !!activeDrawerDefinition
      ? [containerSpringScaleRef, containerSpringOpacityRef, drawerSpringRef]
      : [drawerSpringRef, containerSpringOpacityRef, containerSpringScaleRef],
    !!activeDrawerDefinition ? [0, 0, .5] : [0, .5, 1],
    baseTransitionTime * 2
  );

  return {
    containerSpringScaleValues,
    containerSpringOpacityValues,
    drawerTransition
  };
}
