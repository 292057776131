import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';

const slideInTransition = {
  from: {
    marginTop: '-20px',
    width: '300%'
  },
  to: {
    marginTop: 0,
    width: '100%'
  }
};

export const HdFormHelperText = styled(FormHelperText)({
  marginTop: 'calc(var(--spacer) * 0.25)',
  marginLeft: 'calc(var(--spacer) * 0.55)',
  fontSize: '83%',
  fontWeight: 'var(--font-weight-medium)',
  color: 'var(--text-secondary-color) !important',
  minHeight: '20px',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  '& .error-transition': {
    '@keyframes slideinerror': slideInTransition,
    animationName: 'slideinerror',
    animationDuration: '300ms',
    color: 'var(--error-color)',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
    '& a': {
      fontSize: 'var(--font-size-caption)'
    }
  }
});
