import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'button[hd-icon-btn], a[hd-icon-btn], label[hd-icon-btn]',
  templateUrl: './icon-btn.component.html',
  styleUrls: ['./icon-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconBtnComponent implements OnInit, OnChanges {
  @Input() size: number | string;

  constructor(
    private _elementRef: ElementRef
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const prevSize = changes['size'].previousValue;
    const currSize = changes['size'].currentValue;
    if (prevSize) {
      this._elementRef.nativeElement.classList.remove(`size-${prevSize}`);
    }

    if (currSize) {
      this._elementRef.nativeElement.classList.add(`size-${currSize}`);
    }
  }

  _getHostElement() {
    return this._elementRef.nativeElement;
  }
}
