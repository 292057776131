export function windowHasSelection() {
  if (document.getSelection().toString().length > 0) {
    return true;
  }

  const activeElement = document.activeElement;

  if (!(activeElement instanceof HTMLInputElement)
  && !(activeElement instanceof HTMLTextAreaElement)) {
    return false;
  }

  return activeElement.selectionEnd > activeElement.selectionStart;
}

export function elementHasSelection(elem: HTMLElement) {
  const selection = document.getSelection();
  return selection.toString().length > 0;
}
