import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Radio, RadioProps } from '@mui/material';
import { getDataIdGenerator } from '../../../utils/generateDataId';

interface HdRadioProps {
  dataId: string;
  value: string;
  disabled?: boolean;
  children?: React.ReactElement;
  onChange?: Function;
  sx?: any;
  [key: string]: any;
}

export function HdRadio({
  dataId,
  value,
  disabled,
  children = null,
  onChange = () => {},
  className,
  sx = {},
  ...props
}: HdRadioProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  return (
    <FormControlLabel
      value={value}
      label={children || ''}
      className={className}
      control={
        <StyledRadio
          dataId={dataIdGenerator('radioInput')}
          disabled={disabled}
          onChange={event => onChange((event.target as HTMLInputElement).value)}
          {...props}
        />
      }
      data-id={dataIdGenerator('radio')}
      sx={{ margin: 0, ...sx }}
    />
  );
}

const StyledRadio = styled((props: RadioProps & { dataId: string }) => {
  const { dataId } = props;
  return (
    <Radio
      checkedIcon={<HdCheckedIcon />}
      icon={<HdIcon />}
      disableRipple
      {...props}
      inputProps={{
        // @ts-ignore
        'data-id': dataId,
        ...props.inputProps
      }}
    />
  );
})(() => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    '&:before': {
      content: '""',
      backgroundColor: 'var(--text-default-color)',
      opacity: 0.05,
      width: '35px',
      height: '35px',
      position: 'absolute',
      borderRadius: '50%'
    }
  },
  '&.Mui-checked:hover': {
    '&:before': {
      backgroundColor: 'var(--primary-color)'
    }
  },
  '&.Mui-disabled > span': {
    borderColor: 'var(--surface-bg-overlay-color)',
    borderWidth: '7px'
  },
  '&.Mui-disabled.Mui-checked > span': {
    borderWidth: '3.5px'
  },
  '&.Mui-disabled.Mui-checked > span:before': {
    backgroundColor: 'var(--text-secondary-color)'
  }
}));

const HdIcon = styled('span')(() => ({
  border: 'solid 2px var(--divider-color)',
  borderRadius: '50%',
  padding: 0,
  width: 14,
  height: 14,
  background: 'transparent'
}));

const HdCheckedIcon = styled(HdIcon)({
  borderColor: 'var(--primary-color)',
  borderWidth: '5px',
  position: 'relative',
  '&:before': {
    content: '""',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'absolute'
  }
});
