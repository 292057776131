import { FTPProtocolTypeEnum } from './models';

export const FTP_PROTOCOLS = [
  FTPProtocolTypeEnum.FTP,
  FTPProtocolTypeEnum.SFTP,
  FTPProtocolTypeEnum.FTPS
];

export const FTP_PROTOCOLS_OPTIONS = FTP_PROTOCOLS.map(elem => ({
  ...elem,
  id: elem.value
}));

export const DEFAULT_FTP_PROTOCOL = FTPProtocolTypeEnum.FTP;

export const FTP_FILE_FORMATS: any[] = [
  {
    name: 'JSON',
    value: 'JSON'
  },
  {
    name: 'XML',
    value: 'XML'
  },
  {
    name: 'CSV',
    value: 'CSV'
  },
  {
    name: 'TSV',
    value: 'TSV'
  }
];

export const FTP_FILE_FORMATS_OPTIONS = FTP_FILE_FORMATS.map(elem => ({
  ...elem,
  id: elem.value
}));
