import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const DRIP_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'accountId',
      fieldId: 'account-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'accountId', label: 'Drip Account ID ', required: true },
      hint: 'The ID of the Drip account from which data is to be ingested.',
      sbsDocLink: '/sources/e-commerce/drip/#obtaining-the-account-id',
      buildParamsKey: 'account_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      ...getAPIKeyField({
        label: 'Drip Access Token',
        hint: 'The API token retrieved from your Drip account, to allow Hevo to access your data.',
        externalDocLink: 'https://www.getdrip.com/user/edit',
      })
    }
  ]
];
