import { BulkActionUIItem } from '../../../shared/bulk-actions-button-group/interface';

export enum SourceObjectActionEnum {
  CONFIGURE = 5,  // Task specific configurations
  PAUSE = 10,
  RESUME = 11,
  SKIP = 20,
  INCLUDE = 21,
  RESTART = 30,
  RUN_NOW = 40,
  EDIT_CONFIG = 50, // Job Settings/Mode
  EDIT_OFFSET = 53,
  BULK_EDIT_CONFIG = 80
}

export enum SidelineEventsBulkActionEnum {
  REPLAY_FAILED = 60,
  SKIP_FAILED = 61
}

export const BULK_SOURCE_OBJECT_ACTIONS: BulkActionUIItem[] = [
  {
    id: SourceObjectActionEnum.PAUSE,
    actionDisplayName: 'Pause',
    hasIcon: true,
    iconName: 'pause',
  },
  {
    id: SourceObjectActionEnum.RESUME,
    actionDisplayName: 'Resume',
    hasIcon: true,
    iconName: 'play',
  },
  {
    id: SourceObjectActionEnum.SKIP,
    actionDisplayName: 'Skip',
    hasIcon: true,
    iconName: 'skip',
  },
  {
    id: SourceObjectActionEnum.INCLUDE,
    actionDisplayName: 'Include',
    hasIcon: true,
    iconName: 'skip',
  },
  {
    id: SourceObjectActionEnum.RUN_NOW,
    actionDisplayName: 'Run Now',
    hasIcon: true,
    iconName: 'run-now',
  },
  {
    id: SourceObjectActionEnum.RESTART,
    actionDisplayName: 'Restart',
    hasIcon: true,
    iconName: 'restart',
  },
  {
    id: SidelineEventsBulkActionEnum.REPLAY_FAILED,
    actionDisplayName: 'Replay Failed Events',
    hasIcon: true,
    iconName: 'replay-failed'
  },
  {
    id: SidelineEventsBulkActionEnum.SKIP_FAILED,
    actionDisplayName: 'Skip Failed Events',
    hasIcon: true,
    iconName: 'replay-skipped'
  },
  {
    id: SourceObjectActionEnum.BULK_EDIT_CONFIG,
    actionDisplayName: 'Update Query Mode',
    hasIcon: true,
    iconName: 'popup'
  }
];
