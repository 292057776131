import { styled } from '@mui/material/styles';
import { Popper, Paper } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const HdPopper = styled(Popper)(({ theme }) => ({
  boxShadow: 'var(--shadow-dropdown)',
  borderRadius: 'var(--border-radius-md)',
  width: 250,
  zIndex: theme.zIndex.modal,
  border: 'solid 1px var(--divider-color)',
  background: 'var(--surface-bg-secondary-color)'
}));

export const HdAutocompletePopper = styled('div')(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    width: '100%',
    maxHeight: '100%',
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8
    }
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    fontSize: 'var(--font-size-body)',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'inherit'
  },

  [`& .${autocompleteClasses.listbox} .Mui-focused`]: {
    background: 'var(--hover-bg-color)'
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative'
  }
}));

export const HdAutocompletePaper = styled(Paper)(({ theme }) => ({
  borderRadius: 'var(--border-radius-md)',
  zIndex: theme.zIndex.modal,
  display: 'flex',
  flexDirection: 'column',
  border: 'solid 1px var(--divider-color)',
  background: 'var(--surface-bg-secondary-color)',
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    width: '100%',
    maxHeight: '224px',
    overflow: 'auto'
  },
  [`& .${autocompleteClasses.listbox} .Mui-focused`]: {
    background: 'var(--hover-bg-color)'
  },
  [`& .${autocompleteClasses.groupLabel}`]: {
    color: 'var(--text-secondary-color)',
    fontSize: 'var(--font-size-caption)',
    padding: 'calc(var(--spacer) * 0.25) calc(var(--spacer))',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    position: 'initial',
    height: '22px'
  },
  [`& .${autocompleteClasses.groupLabel}:after`]: {
    content: '""',
    height: '1px',
    borderBottom: 'solid 1px var(--divider-color)',
    marginLeft: 'calc(var(--spacer) * 0.75)',
    flex: 1
  },
  [`& .${autocompleteClasses.noOptions}, & .${autocompleteClasses.loading}`]: {
    color: 'var(--text-default-color)',
    fontSize: 'var(--font-size-body)',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'inherit',
    padding: 'calc(var(--spacer) * 0.5) var(--spacer)'
  },
  '& .MuiCheckbox-root.Mui-checked .hd-checked-icon path': {
    WebkitAnimation: 'none',
    animation: 'none'
  }
}));
