import { PortalInjector } from '@angular/cdk/portal';
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ALERT_SNACKBAR_STYLES } from '../constants';
import { AlertSnackbarData, SnackbarType } from '../models';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { ALERT_SNACKBAR_COMPONENT_DATA } from './interface';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'alert-snackbar',
  templateUrl: './alert-snackbar.component.html',
  styleUrls: ['./alert-snackbar.component.scss']
})
export class AlertSnackbarComponent {
  @ViewChild('snackbar', { static: true }) snackbarRef: SnackbarComponent;
  @Input() iconClass: string;

  messageInjector: Injector;

  DEFAULT_SNACKBAR_DATA: AlertSnackbarData = {
    canClose: true,
    type: SnackbarType.WARN,
    inline: false
  };

  snackBarData: AlertSnackbarData = this.DEFAULT_SNACKBAR_DATA;
  visible = false;
  showActions = true;

  onCloseSubject = new Subject<void>();

  constructor(private _injector: Injector) {
  }

  show(data: AlertSnackbarData) {
    this.snackBarData = { ...this.DEFAULT_SNACKBAR_DATA, ...data };
    this.setIconClass();
    this.visible = true;
    this.showActions = true;

    if (this.snackBarData.messageComponent) {
      const injectionTokens = new WeakMap();
      injectionTokens.set(ALERT_SNACKBAR_COMPONENT_DATA, this.snackBarData.messageComponentData);
      this.messageInjector = new PortalInjector(this._injector, injectionTokens);
    }

    if (this.snackBarData.messageComponent || this.snackBarData.messageTemplate) {
      this.showActions = false;
    }

    this.snackbarRef.show();
  }

  hide() {
    if (!this.visible) {
      return;
    }
    this.snackbarRef.hide();
    this.visible = false;
  }

  setIconClass() {
    this.iconClass = ALERT_SNACKBAR_STYLES[this.snackBarData.type].iconClass;
  }

  onSnackbarClose() {
    this.onCloseSubject.next();
  }

  onClose() {
    return this.onCloseSubject;
  }
}
