import { useState } from 'react';

export default function useQueue(): [object, Function, Function, number] {
  const [asyncProcessQueue, setAsyncProcessQueue] = useState({});

  const asyncMonitorAddProcess = (asyncProcessType: string) => {
    if (asyncProcessQueue[asyncProcessType]) {
      return;
    }

    setAsyncProcessQueue(prev => {
      const copy = { ...prev };
      copy[asyncProcessType] = true;
      return copy;
    });
  };

  const asyncMonitorRemoveProcess = (asyncProcessType: string) => {
    if (!asyncProcessQueue[asyncProcessType]) {
      return;
    }

    setAsyncProcessQueue(prev => {
      const copy = { ...prev };
      if (copy[asyncProcessType]) {
        delete copy[asyncProcessType];
      }

      return copy;
    });
  };

  return [
    asyncProcessQueue,
    asyncMonitorAddProcess,
    asyncMonitorRemoveProcess,
    Object.keys(asyncProcessQueue).length
  ];
}
