import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const HARVEST_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPITokenField({
        label: 'Access Token',
        hint: 'The Access Token retrieved from your Harvest account, to allow Hevo to access your data.',
        sbsDocLink: '/sources/fna-analytics/harvest/#obtaining-the-access-token'
      })
    },
    {
      key: 'accountId',
      fieldId: 'account-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'accountId', label: 'Account ID ', required: true },
      hint: 'The ID of the Harvest account from which data is to be ingested.',
      sbsDocLink: '/sources/fna-analytics/harvest/#account-id',
      buildParamsKey: 'account_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
