/* eslint-disable jsx-a11y/label-has-associated-control */
import { Typography } from '@mui/material';
import { FieldProps, getIn } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { getErrorMessageFromObj } from '../../legacy-utils/request';
import { AuthorizationAccount, ServiceAccount } from '../../../app/nodes/models/models';
import { authorizedAccountsFactory } from '../../../app/nodes/models/utils';
import { convertHevoEntityToAccountEntityTypes } from '../../../app/nodes/select-auth-account/utils';
import { REQUIRED_SERVICE_ACC_KEY_FIELDS } from '../../../app/nodes/select-auth-type/constants';
import { checkAllRequiredFieldsPresent } from '../../../app/nodes/select-auth-type/utils';
import {
  ConnectorAuthTypeEnum,
  getAuthTypeDetails
} from '../../../app/nodes/source-type/auth-type';
import {
  TRACKER_ADD_AUTH_ACCOUNT_CLICK,
  TRACKER_AUTH_ACCOUNT_CLICK,
  TRACKER_NEW_SERVICE_ACCOUNT_FAILED,
  TRACKER_NEW_SERVICE_ACCOUNT_SUCCESS,
  TRACKER_SELECT_AUTH_TYPE
} from '../../../app/nodes/tracking';
import AuthAPI from '../../containers/auth/AuthAPI';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import useToaster from '../../hooks/useToaster';
import { readFileAsText } from '../../utils/fileReader';
import { ConfirmDialogV1 } from '../Dialog/ConfirmDialog/ConfirmDialogV1/ConfirmDialogV1';
import { HdDocLink, HdDropDown, HdIcon, HdLink } from '../UIElements';
import { HdSmallSpinner } from '../UIElements/HdSpinner';
import EditAccountDialog from './EditAccountDialog';
import styles from './styles.module.scss';
import { fetchAuthorisedAccounts } from './apiUtil';
import { getDataIdGenerator } from '../../utils/generateDataId';

interface HdFormikGoogleAuthProps {
  id?: string;
  provider: string;
  displayName: string;
  hevoEntity?: HevoEntity;
  disabled?: boolean;
  onAuthTypeChange?: Function;
  isUserAccountDisabled?: boolean;
}

export const dataIdGenerator = getDataIdGenerator('google-auth');

const findSelectedAuthType = (authTypes, selectedType) =>
  authTypes.find(auth => auth.value === selectedType) || authTypes[0];

function GoogleAuthBottomAdornment({ showAdornment, onMouseDownHandler, authType = '' }) {
  if (!showAdornment) {
    return null;
  }

  return (
    <HdLink
      tag='button'
      className='w-100 p-3'
      direction='left'
      onMouseDown={() => onMouseDownHandler()}
      icon='plus '
      dataId={dataIdGenerator(`add-new-${authType.toLowerCase()}`)}
    >
      Add Another {authType}
    </HdLink>
  );
}

// eslint-disable-next-line react/function-component-definition
export const GoogleAuth: React.FC<HdFormikGoogleAuthProps & FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  provider,
  displayName,
  onAuthTypeChange,
  disabled = false,
  hevoEntity,
  isUserAccountDisabled
}) => {
  const [loading, setLoading] = useState(true);
  const [authorisedAccounts, setAuthorisedAccounts] = useState<AuthorizationAccount[]>([]);
  const [serviceAccountKeyFileError, setServiceAccountKeyFileError] = useState('');
  const [selectedAuthAccount, setSelectedAuthAccount] = useState<AuthorizationAccount>(null);
  const [isEditDialogOpen, setEditDialogState] = useState(false);
  const [focused, setFocused] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogState] = useState(false);
  const [itemSelectedForDeleteFlow, setItemForDeleteFlow] = useState(null);

  const consentUrlsRef = useRef([]);
  const inputFileRef = useRef(null);
  const isFirstRender = useRef(true);
  const authTypeOptions = useRef(
    Object.values(
      getAuthTypeDetails({
        entityType: convertHevoEntityToAccountEntityTypes(hevoEntity),
        isAccountTypeGoogle: true,
        type: provider,
        typeDisplay: displayName
      })
    )
  );

  const [selectedAuthTypeOption, setAuthTypeOption] = useState(
    findSelectedAuthType(authTypeOptions.current, isUserAccountDisabled ? ConnectorAuthTypeEnum.SERVICE : field.value?.type)
  );

  const { eventTrack } = useAnalyticsTracker();
  const toasterService = useToaster();

  const dropDownDisplayValue = serviceAccountKeyFileError
    ? { id: 'INTERNAL_ERROR', getDisplayName: () => serviceAccountKeyFileError }
    : selectedAuthAccount;

  const selectedAuthType = selectedAuthTypeOption?.value;

  const handleSetAuthAccount = (authAccount, updateFormikState = true) => {
    setSelectedAuthAccount(authAccount);
    if (updateFormikState) {
      eventTrack({
        action: TRACKER_AUTH_ACCOUNT_CLICK,
        properties: {
          account: authAccount,
          provider: hevoEntity
        }
      });

      if (!authAccount) {
        setFieldValue(field.name, null);
        return;
      }

      setFieldValue(field.name, {
        id: authAccount.id,
        type: authAccount.getAccountType(),
        email: authAccount.getDisplayName()
      });
    }
  };

  useEffect(() => {
    let cancelRequest = false;
    setLoading(true);
    setAuthorisedAccounts([]);

    if (!isFirstRender.current) {
      handleSetAuthAccount(null);
    }

    if (!selectedAuthType) {
      setLoading(false);
      return () => {};
    }

    fetchAuthorisedAccounts(selectedAuthType, provider)
      .then(res => {
        if (cancelRequest) {
          return;
        }

        const authAccountOptions = authorizedAccountsFactory(res.tokens, selectedAuthType);
        setAuthorisedAccounts(authAccountOptions);

        if (isFirstRender.current) {
          isFirstRender.current = false;
          const selectedAccount = authAccountOptions.find(acc => acc.id === field.value?.id);

          if (selectedAccount) {
            handleSetAuthAccount(selectedAccount);
          } else {
            handleSetAuthAccount(authAccountOptions[0]);
          }
        } else {
          handleSetAuthAccount(authAccountOptions[0]);
        }

        if (selectedAuthType === ConnectorAuthTypeEnum.OAUTH) {
          consentUrlsRef.current = res?.urls || [];
        } else {
          consentUrlsRef.current = [];
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      cancelRequest = true;
    };
  }, [selectedAuthType]);

  useEffect(() => {
    const fileSelector = inputFileRef.current;
    fileSelector.addEventListener('change', handleFileSelector);
    return () => fileSelector.removeEventListener('change', handleFileSelector);
  }, []);

  // Utils
  const handleFileSelector = event => {
    setLoading(true);
    const file = event.target.files?.[0];
    handleSetAuthAccount(null);
    setServiceAccountKeyFileError(null);

    readFileAsText(file)
      .then((content: string) => {
        const serviceAccount = JSON.parse(content);
        // Check for required fields in the selected JSON file
        const requiredFieldsPresent = checkAllRequiredFieldsPresent(
          serviceAccount,
          REQUIRED_SERVICE_ACC_KEY_FIELDS
        );

        if (!requiredFieldsPresent) {
          setServiceAccountKeyFileError(`${file.name} (Invalid file selected) `);
          setLoading(false);
          return;
        }

        const serviceAccountEmail = serviceAccount.client_email;
        AuthAPI.addServiceAccount(provider, serviceAccountEmail, content)
          .then(res => {
            const newList = [new ServiceAccount(res.data), ...authorisedAccounts];
            setAuthorisedAccounts(newList);
            handleSetAuthAccount(newList[0]);

            eventTrack({
              action: TRACKER_NEW_SERVICE_ACCOUNT_SUCCESS,
              properties: {
                account: newList[0],
                entityType: provider
              }
            });

            setLoading(false);
          })
          .catch(e => {
            setServiceAccountKeyFileError(`${file.name} (Invalid file selected)`);
            setLoading(false);

            eventTrack({
              action: TRACKER_NEW_SERVICE_ACCOUNT_FAILED,
              properties: {
                message: getErrorMessageFromObj(e),
                entityType: provider
              }
            });
          });
      })
      .catch(() => {
        setServiceAccountKeyFileError(`${file.name} (Invalid file selected) `);
        setLoading(false);

        eventTrack({
          action: TRACKER_NEW_SERVICE_ACCOUNT_FAILED,
          properties: {
            entityType: provider
          }
        });
      })
      .finally(() => {
        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }
      });
  };

  const handleDeleteConfirmationEvent = async () => {
    let promise = null;
    if (!itemSelectedForDeleteFlow) {
      return;
    }

    const accounts = authorisedAccounts;
    const filteredAccounts = authorisedAccounts.filter(
      acc => acc.id !== itemSelectedForDeleteFlow.id
    );

    setAuthorisedAccounts(filteredAccounts);
    if (itemSelectedForDeleteFlow.id === selectedAuthAccount?.id) {
      if (filteredAccounts.length > 0) {
        setSelectedAuthAccount(filteredAccounts[0]);
      } else {
        setSelectedAuthAccount(null);
      }
    }

    if (itemSelectedForDeleteFlow.getAccountType() === ConnectorAuthTypeEnum.SERVICE) {
      promise = AuthAPI.deleteServiceAccount(itemSelectedForDeleteFlow.id);
    } else {
      promise = AuthAPI.deleteAccount(provider, itemSelectedForDeleteFlow.id);
    }

    promise
      .catch(() => {
        setAuthorisedAccounts(accounts);
      })
      .finally(() => {
        setItemForDeleteFlow(null);
      });
  };

  const handleDeleteAction = (account: AuthorizationAccount) => {
    setItemForDeleteFlow(account);
    setDeleteDialogState(true);
  };

  // EDIT ACCOUNTS
  const triggerEditFlow = () => {
    setEditDialogState(true);
  };

  const handleEditServiceAccount = file => {
    AuthAPI.editServiceAccount(provider, selectedAuthAccount.id, file.content)
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false))
      .finally(() => setEditDialogState(false));
  };

  const reAuthoriseAccount = (accountId: number) => {
    AuthAPI.reAuthoriseAccount(
      provider,
      accountId,
      window.location.href,
      window.location.href
    ).then(res => {
      if (res && res.data && res.data.consent_url) {
        window.location.assign(res.data.consent_url);
        return;
      }

      toasterService.pop('error', undefined, "Couldn't reauthorize this account");
    });
  };

  // ADD ACCOUNTS
  const addOAuthAccount = (e = null) => {
    if (e) {
      e.stopPropagation();
    }

    eventTrack({
      action: TRACKER_ADD_AUTH_ACCOUNT_CLICK,
      properties: {
        entityType: provider
      }
    });

    if (consentUrlsRef?.current && consentUrlsRef.current.length === 1) {
      window.location.assign(consentUrlsRef.current[0].url);
    }
  };

  const addServiceAccount = (e = null) => {
    if (e) {
      e.stopPropagation();
    }

    inputFileRef.current.click();
  };

  // Do not show error while loading something
  const showError =
    ((touched[field.name] && !!errors[field.name]) ||
      (getIn(errors, field.name) && getIn(touched, field.name)) ||
      !!serviceAccountKeyFileError) &&
    !loading;

  let endAdornment;
  const isSelected = !!selectedAuthAccount;

  if (loading) {
    endAdornment = HdSmallSpinner();
  } else if (isSelected) {
    if (selectedAuthType === ConnectorAuthTypeEnum.OAUTH) {
      endAdornment = (
        <HdLink
          className={styles.innerIcon}
          tag='button'
          disabled={disabled}
          onClick={e => {
            e.stopPropagation();
            reAuthoriseAccount(selectedAuthAccount.id);
          }}
          dataId={dataIdGenerator('reauthorise')}
        >
          <HdIcon name='edit' className='mr-2' /> REAUTHORISE
        </HdLink>
      );
    } else {
      endAdornment = (
        <HdLink
          className={styles.innerIcon}
          tag='button'
          disabled={disabled}
          onClick={e => {
            e.stopPropagation();
            triggerEditFlow();
          }}
          dataId={dataIdGenerator('edit')}
        >
          <HdIcon name='attach' className='mr-2' /> EDIT
        </HdLink>
      );
    }
  } else {
    const onClickHandler =
      selectedAuthType === ConnectorAuthTypeEnum.OAUTH ? addOAuthAccount : addServiceAccount;
    const icon = selectedAuthType === ConnectorAuthTypeEnum.OAUTH ? 'plus' : 'attach';
    endAdornment = (
      <HdLink
        disabled={disabled}
        className={styles.innerIcon}
        tag='button'
        onClick={onClickHandler}
        dataId={dataIdGenerator('add-account')}
      >
        <HdIcon name={icon} className='mr-2' /> ADD ACCOUNT
      </HdLink>
    );
  }

  let placeholder;
  if (selectedAuthType === ConnectorAuthTypeEnum.OAUTH) {
    if (authorisedAccounts?.length > 0) {
      placeholder = 'Select Google Account';
    } else {
      placeholder = 'Add Google Account';
    }
  } else if (authorisedAccounts?.length > 0) {
    placeholder = 'Select Service Account';
  } else {
    placeholder = 'Upload Service Account File';
  }

  const AuthAccountDropdownOption = useCallback(
    ({ option, onClose }) => (
      <div className={styles.dropdownRow}>
        <div className={styles.dropdownText}>{googleAuthAccountDisplayAccessor(option)}</div>
        <HdLink
          tag='a'
          iconClasses={styles.deleteIconColor}
          icon='delete'
          onClick={e => {
            e.stopPropagation();
            onClose();
            handleDeleteAction(option);
          }}
          dataId={dataIdGenerator('delete-account')}
        />
      </div>
    ),
    []
  );

  const setBlur = () => {
    setFieldTouched(field.name);
    setFocused(false);
  };

  const handleAuthTypeChange = authType => {
    eventTrack({
      action: TRACKER_SELECT_AUTH_TYPE,
      properties: {
        authType,
        entityType: provider
      }
    });

    if (onAuthTypeChange) {
      onAuthTypeChange(authType);
    }

    setAuthTypeOption(authType);
  };

  return (
    <>
      <div
        className={`w-100 d-flex ${styles.root} ${focused ? styles.focused : ''} ${
          showError ? styles.error : ''
        }`}
        data-id={dataIdGenerator('')}
      >
        <label
          className={`${styles.accountsLabel} ${focused ? styles.labelFocused : ''} text-caption-2`}
          data-id={dataIdGenerator('label')}
        >
          {' '}
          Authorised {selectedAuthTypeOption.name}<span className={styles.asterisk}>*</span>
        </label>

        {!isUserAccountDisabled ? (
          <HdDropDown
            dataId={dataIdGenerator('type')}
            id={`${field.name}-auth-selector`}
            classes={{
              root: styles.accountTypeDropdownRoot
            }}
            inputFieldProps={{
              hideFieldAppliedBorder: true
            }}
            selected={selectedAuthTypeOption}
            onFocus={() => {
              setFocused(true);
            }}
            disabled={disabled}
            error={showError}
            onBlur={setBlur}
            selectMode
            valueAccessor='value'
            displayAccessor='name'
            options={authTypeOptions.current}
            onChangeEventHandler={handleAuthTypeChange}
          />
        ) : null}
        <HdDropDown
          dataId={dataIdGenerator('account')}
          id={field.name}
          classes={{
            root: styles.authAccountsDropDownRoot
          }}
          inputFieldProps={{
            hideFieldAppliedBorder: true,
            showEndIcon: true,
            useErrorColorOnError: true
          }}
          disabled={disabled}
          options={authorisedAccounts}
          placeholder={placeholder}
          error={showError}
          onBlur={setBlur}
          selected={dropDownDisplayValue}
          onFocus={e => {
            setFocused(true);
          }}
          valueAccessor={googleAuthAccountValueAccessor}
          displayAccessor={googleAuthAccountDisplayAccessor}
          CustomOption={AuthAccountDropdownOption}
          BottomAdornment={GoogleAuthBottomAdornment}
          bottomAdornmentProps={{
            showAdornment: authorisedAccounts.length > 0,
            onMouseDownHandler:
              selectedAuthTypeOption.value === ConnectorAuthTypeEnum.SERVICE
                ? addServiceAccount
                : addOAuthAccount,
            authType: selectedAuthTypeOption.name
          }}
          onChangeEventHandler={option => {
            setServiceAccountKeyFileError('');
            handleSetAuthAccount(option);
          }}
          suffixElement={endAdornment}
        />

        <input
          data-id={dataIdGenerator('service-account-file-input')}
          ref={inputFileRef}
          name={field.name}
          type='file'
          hidden
          accept='application/json'
        />

        <EditAccountDialog
          open={isEditDialogOpen}
          updateFile={handleEditServiceAccount}
          oldServiceAccount={selectedAuthAccount}
          closeModelHandler={() => setEditDialogState(false)}
        />

        <ConfirmDialogV1
          open={isDeleteDialogOpen}
          title='DELETE'
          modalProps={{ disableRestoreFocus: true }}
          body='Are you sure you want to delete this account?'
          positiveButtonText='Confirm'
          negativeButtonText='No'
          onClose={() => setDeleteDialogState(false)}
          onPositiveClickCallback={handleDeleteConfirmationEvent}
        />
      </div>

      {!!serviceAccountKeyFileError && (
        <Typography className={`${styles.deleteIconColor} mt-2 px-2`} variant='caption'>
          The Service Account Configuration file you have uploaded is not valid.{' '}
          <HdDocLink
            label='Learn how'
            docLink='/getting-started/connection-options/google-account-authentication/#creating-a-google-service-account'
            dataId={dataIdGenerator('service-account')}
          />
          {'  '}
          to create and download a Service Account file.
        </Typography>
      )}
    </>
  );
};

const googleAuthAccountValueAccessor = account => account.id;
const googleAuthAccountDisplayAccessor = account => account.getDisplayName();
