import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Tooltip} from './tooltip.directive';
import {TooltipContent} from './tooltip-content';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule
  ],
  declarations: [
    Tooltip,
    TooltipContent,
  ],
  exports: [
    Tooltip,
    TooltipContent,
  ],
  entryComponents: [
    TooltipContent
  ]
})
export class TooltipModule {

}
