// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.container__Yhri {\n  display: flex;\n  flex: 1;\n  background-color: var(--surface-bg-raised-color);\n  width: 100%;\n  height: 40px;\n  box-shadow: var(--shadow-header);\n  align-items: center;\n  padding-left: 28px;\n  padding-right: 28px;\n  position: relative;\n  z-index: 1055;\n}\n\n.closeIconWrapper_YSq7y {\n  position: absolute;\n  right: 22px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container__Yhri",
	"closeIconWrapper": "closeIconWrapper_YSq7y"
};
export default ___CSS_LOADER_EXPORT___;
