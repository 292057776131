import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ClearPendingPopupService {
  private _popupShownSubject = new Subject();
  public popupShown$ = this._popupShownSubject.asObservable();

  onPopupShown() {
    this._popupShownSubject.next(null);
  }
}
