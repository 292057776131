import { OffsetView, OffsetViews, OffsetViewType } from '../../app/core/models/offset-view';

export function getOffsetViewUpdatePayload(offsetView: OffsetViews) {
  const offset: any = {};

  if (offsetView.type === OffsetViewType.DATE || offsetView.type === OffsetViewType.DATE_TIME) {
    offset.timestamp = offsetView.offset;
  }

  if (offsetView.type === OffsetViewType.NUMBER) {
    offset.id = offsetView.offset;
  }

  if (offsetView.type === OffsetViewType.FILE_BASED) {
    offset.id = offsetView.offset;
    offset.file_name = offsetView.file_name;
  }

  if (offsetView.type === OffsetViewType.MULTI_OBJECT) {
    const isValidObject = typeof offsetView.offset === 'object' && offsetView.offset !== null;

    // Try parsing all fields to number if they are valid, with fallback as string
    if (!isValidObject) {
      offset.key_values = offsetView.offset;
    } else {
      offset.key_values = Object.keys(offsetView.offset).reduce((dict, key) => {
        const value = offsetView.offset[key];
        const valueNumeric = Number(value);

        return {
          ...dict,
          [key]: value === null || isNaN(valueNumeric) ? value : valueNumeric
        };
      }, {});
    }
  }

  if (offsetView.type === OffsetViewType.MONTH_YEAR) {
    offset.month = offsetView.month;
    offset.year = offsetView.year;
  }

  offset.offset_view_type = offsetView.type;

  return offset;
}

export function getModifiableOffsetViewFromList(offsetViews: OffsetView[]) {
  const timestampOffsetViewIndex = offsetViews.findIndex((offsetView) => [
    OffsetViewType.DATE, OffsetViewType.DATE_TIME
  ].includes(offsetView.type));

  if (timestampOffsetViewIndex >= 0) {
    return offsetViews[timestampOffsetViewIndex];
  }

  return offsetViews[0];
}
