import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { getArrayFromSize } from '../../legacy-utils/array';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { HdIcon, HdTooltip } from '../UIElements';
import { StarSize } from './models';
import styles from './styles.module.scss';

export interface StarWidgetProps {
  className?: string;
  dataId: string;
  type?: StarSize;
  selectedStars: number;
  onRatingClick: (rating: number) => any;
  onRatingHover?: (rating: number) => any;
  starCount?: number;
}

export function StarWidget({
  className = '',
  dataId,
  type = StarSize.MEDIUM,
  selectedStars,
  onRatingClick,
  onRatingHover,
  starCount = 10
}: StarWidgetProps) {
  const unselected = selectedStars <= 0;
  const rangeTextFontSize = type === StarSize.MEDIUM ? '10px' : '12px';
  const starFontSize = type === StarSize.MEDIUM ? '16px' : '20px';

  const stars = useMemo(() => {
    return getArrayFromSize(starCount);
  }, [ starCount ]);

  const selectedStarsColor = useMemo(() => {
    if (selectedStars <= 0) {
      return styles.unselected;
    }

    if (selectedStars >= 9) {
      return styles.likely;
    }

    if (selectedStars >= 7 && selectedStars < 9) {
      return styles.average;
    }

    return styles.notLikely;
  }, [ selectedStars ]);

  const getStarClass = (starRating: number) => {
    if (starRating > selectedStars) {
      return styles.unselected;
    }

    return selectedStarsColor;
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'star-widget'), [dataId]);

  return (
    <div className={clsx('center-flex-row', className)}>
      <span
        className={clsx(styles.rangeLabel, 'mr-4', !unselected && styles.notLikely)}
        style={{ fontSize: rangeTextFontSize }}
      >
        Not Likely
      </span>

      {stars.map((_, index) => (
        <HdTooltip key={index} title={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}>
          <button
            data-id={dataIdGenerator(`star-${index}`)}
            className={styles.star}
            onClick={() => onRatingClick(index + 1)}
            onMouseEnter={() => onRatingHover && onRatingHover(index + 1)}
          >
            <HdIcon
              name='star'
              className={getStarClass(index + 1)}
              style={{ fontSize: starFontSize }}
            />
          </button>
        </HdTooltip>
      ))}

      <span
        className={clsx(styles.rangeLabel, 'ml-4', !unselected && styles.likely)}
        style={{ fontSize: rangeTextFontSize }}
      >
        Very Likely
      </span>
    </div>
  );
}
