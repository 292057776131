import React from 'react';
import ScrollToTopButton from '../../../components/ScrollToTopButton';
import { ActivityLogListContainer } from '../ActivityLogListContainer';
import ActivityLogDialogFilters from './ActivityLogDialogFilters';
import { useActivityLog } from '../useActivityLog';
import styles from './styles.module.scss';

export interface ActivityLogDialogContentProps {
  entityFilters: any;
  onCloseDialog: Function;
}

export function ActivityLogDialogContent({
  entityFilters,
  onCloseDialog
}: ActivityLogDialogContentProps) {
  const {
    state,
    loadingMore,
    showActivities,
    showSkeleton,
    noActivitiesFound,
    sentryRef,
    getAppliedFilters,
    resetFilter,
    updateFilter,
    refresh
  } = useActivityLog({ loadFilters: true, entityFilters });

  return (
    <div className={styles.dialogBody} id='activity-panel'>
      <ActivityLogDialogFilters
        filters={state.listFilters}
        loading={state.activities.length === 0 && state.fetchingList}
        appliedFilters={getAppliedFilters()}
        resetFilter={resetFilter}
        updateFilter={updateFilter}
        onRefreshCallback={refresh}
        onCloseDialog={onCloseDialog}
      />

      <ActivityLogListContainer
        className={styles.dialogActivityBody}
        state={state}
        sentryRef={sentryRef}
        noActivitiesFound={noActivitiesFound}
        showSkeleton={showSkeleton}
        showActivities={showActivities}
        loadingMore={loadingMore}
        refresh={refresh}
      />

      <ScrollToTopButton scrollViewportId='activity-panel' />
    </div>
  );
}
