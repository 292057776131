import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';

import { AppModule } from './app/app.module';
import { AnsibleBoolean } from './app/core/models/ansible-config';
import { environment } from './environments/environment';

declare const __METICULOUS_RECORDER_CONFIG__: { enabled: AnsibleBoolean; projectId?: string };

if (environment.production) {
  enableProdMode();
}

async function startMeticulousRecorder() {
  if (
    typeof __METICULOUS_RECORDER_CONFIG__ === 'undefined'
    || !__METICULOUS_RECORDER_CONFIG__
    || __METICULOUS_RECORDER_CONFIG__.enabled !== AnsibleBoolean.TRUE
  ) {
    return;
  }

  await tryLoadAndStartRecorder({
    projectId: __METICULOUS_RECORDER_CONFIG__.projectId as string,
    isProduction: false
  });
}

async function bootstrapApp() {
  try {
    await startMeticulousRecorder();
  } catch (err) {
    console.log(err);
  }

  try {
    await platformBrowserDynamic().bootstrapModule(AppModule);
  } catch (err) {
    console.log(err);
  }
}

bootstrapApp();

