import { Component, Input } from '@angular/core';


@Component({
  selector: 'hd-rounded-icon',
  templateUrl: './rounded-icon.component.html'
})
export class RoundedIconComponent {
  @Input() iconName: string;
  @Input() containerBg = 'success';
  @Input() containerSize = 1;

  @Input() isIconColorWhite = true;
  @Input() hasBg = true;
  @Input() isRounded = true;
  @Input() hasBorder = false;
  @Input() showSpinningAnimation = false;
}
