import { FieldTypes, SourceFormFieldDefinition } from '../models';


export const TOGGL_TRACK_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'username',
      fieldId: 'username',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'username', label: 'Username', required: true },
      hint: 'The email ID of your Toggl Track account.',
      buildParamsKey: 'username',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'password',
      fieldId: 'password',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'password', label: 'Password', required: true },
      hint: 'The password of your Toggl Track account.',
      buildParamsKey: 'password',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'workspaceId',
      fieldId: 'workspace-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'workspaceId', label: 'Workspace ID', required: true },
      hint: 'The 7-digit unique ID for your workspace, obtained from the Projects page URL in your Toggl Track account.',
      buildParamsKey: 'workspace_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
