export enum SourceObjectInnerWidget {
  NONE,
  SIDELINE,
  TASK_FAILURES,
  TASK_WARNINGS
}

export interface SourceObjectOpenedWidgetState {
  openedWidget?: SourceObjectInnerWidget;
}
