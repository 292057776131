import { FieldTypes, SourceFormFieldDefinition } from '../models';

export const RECURLY_V2_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'apiKey',
      fieldId: 'api-key',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'apiKey', label: 'API Key', required: true },
      hint: 'Enter the Private API key obtained from the Integrations → API Credentials → Private API Keys. Save it and copy the key which will be generated.',
      buildParamsKey: 'api_key',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      key: 'recurlyEuEnv',
      fieldId: 'recurly-eu-env',
      widget: FieldTypes.SWITCH_FIELD,
      fieldType: 'boolean',
      widgetOptions: { name: 'recurlyEuEnv', label: 'EU Environment', required: true },
      hint: 'Enable this if your Recurly account is in the European Union (EU) region.',
      buildParamsKey: 'region',
      validations: [],
      getConfigValue: configValue => configValue === '.eu',
      getRawValue: configValue => (configValue === true ? '.eu' : ''),
      getRenderCondition: formMetaData => !!formMetaData?.showEuToggle
    }
  ],
  [
    {
      key: 'allAvailableData',
      fieldId: 'all-available-data',
      widget: FieldTypes.SWITCH_FIELD,
      fieldType: 'boolean',
      widgetOptions: { name: 'allAvailableData', label: 'All Available Data', required: true },
      buildParamsKey: 'all_available_data',
      validations: [],
      getRawValue: () => true,
      getRenderCondition: () => false
    }
  ]
];
