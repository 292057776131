import { Invoice } from '../models/invoice';
import { InvoiceStatusEnum } from '../models/invoice-status';
import { BillingFrequencies, MarketplaceProviders } from '../models/payment';
import { PaymentPlan } from '../models/payment-plan';

export function InvoiceListFactory(rawData: any): Invoice[] {
  if (!Array.isArray(rawData) || !rawData.length) {
    return undefined;
  }

  return rawData.map((table: any) => {
    return InvoiceFactory(table);
  });
}

export function InvoiceFactory(rawData: any): Invoice {
  const invoice: Invoice = new Invoice();

  invoice.id = rawData.id;
  invoice.number = rawData.number;
  invoice.amount = rawData.amount;
  invoice.createdTimestamp = rawData.created_timestamp;
  invoice.status = InvoiceStatusEnum[rawData.status];
  invoice.invoiceUrl = rawData.invoice_url;
  invoice.paymentUrl = rawData.payment_url;
  invoice.failureMessage = rawData.failure_message;

  return invoice;
}

export function getMonthlyAmount(amount: number, frequency: string) {
  if (amount === -1) {
    return null;
  }

  const divisor = BillingFrequencies[frequency]?.monthlyDivisor;

  if (!divisor || typeof amount !== 'number') {
    return null;
  }

  return amount / divisor;
}

export function getApplicableBillingFeatures(plan: PaymentPlan): {
  changePlan?: boolean;
  upcomingInvoices?: boolean;
  onDemandEvents?: boolean;
  onDemandCredit?: boolean;
  paymentMethods?: boolean;
  billingHistory?: boolean;
  billingDetails?: boolean;
  editBillingDetails?: boolean;
} {
  const applicableFeatures = {
    changePlan: true,
    upcomingInvoices: true,
    onDemandEvents: true,
    onDemandCredit: true,
    paymentMethods: true,
    billingHistory: true,
    billingDetails: true,
    editBillingDetails: true
  };

  if (MarketplaceProviders.includes(plan.billing_provider_type)) {
    return {};
  }

  return applicableFeatures;
}
