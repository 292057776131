import { PortalInjector } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { fadeAnim, popupAnim } from '../animations';
import { DialogBase } from '../dialog-base';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { CONFIRM_V2_DIALOG_COMPONENT_DATA } from './constants';
import { ConfirmV2DialogInterface } from './model';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confirm-v2-dialog',
  templateUrl: './confirm-v2-dialog.component.html',
  styleUrls: [ './confirm-v2-dialog.component.scss' ],
  animations: [ fadeAnim(), popupAnim() ]
})
export class ConfirmV2DialogComponent extends DialogBase implements OnInit {
  showTitle = true;
  title = 'Need Your Attention';
  body = '';

  iconName = 'warning';
  iconContainerClass = 'warning';

  showConfirmationSubtext = false;
  positiveBtnText = 'Confirm';
  positiveBtnClass = 'primary';
  positiveBtnVariation = '';
  negativeBtnText = 'Cancel';

  hoverTrackerAction: string;
  closeCTAHoverTrackerAction: string;
  negativeCTAHoverTrackerAction: string;
  positiveCTAHoverTrackerAction: string;
  bodyComponentInjector: Injector;
  focusPositiveBtnOnRender = true;

  confirmJob$: Observable<any>;
  runningConfirmJob = false;
  confirmJobError: string;

  constructor(
    @Inject(DOCUMENT) protected _document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public data: ConfirmV2DialogInterface,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _injector: Injector
  ) {
    super(_document, _dialogRef, data, _keyboardShortcuts);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.bodyComponent) {
      const injectionTokens = new WeakMap();

      injectionTokens.set(CONFIRM_V2_DIALOG_COMPONENT_DATA, this.data.bodyComponentData);

      this.bodyComponentInjector = new PortalInjector(this._injector, injectionTokens);
    }

    this.show();
  }

  show() {
    super.show();
    this.showTitle = this.data.hideTitle ? false : this.showTitle;
    this.title = this.data.title || this.title;
    this.body = this.data.body || this.body;
    this.iconName = this.data.iconName || this.iconName;
    this.iconContainerClass = this.data.iconContainerClass || this.iconContainerClass;
    this.showConfirmationSubtext = this.data.showConfirmationSubtext || this.showConfirmationSubtext;
    this.positiveBtnText = this.data.positiveBtnText || this.positiveBtnText;
    this.negativeBtnText = this.data.negativeBtnText || this.negativeBtnText;
    this.positiveBtnClass = this.data.positiveBtnClass || this.positiveBtnClass;
    this.positiveBtnVariation = this.data.positiveBtnVariation || this.positiveBtnVariation;

    this.focusPositiveBtnOnRender = !this.data.hideInitialFocus;

    this.hoverTrackerAction = this.data.hoverTrackerAction;
    this.closeCTAHoverTrackerAction = this.data.closeCTAHoverTrackerAction;
    this.negativeCTAHoverTrackerAction = this.data.negativeCTAHoverTrackerAction;
    this.positiveCTAHoverTrackerAction = this.data.positiveCTAHoverTrackerAction;

    this.confirmJob$ = this._data.confirmJob$ || of(null);
    this.backdropCloseDisabled = this._data.backdropCloseDisabled || this.backdropCloseDisabled;
  }

  onPositiveClick() {
    super.onPositiveClick(this._dialogResponse);
    this.runningConfirmJob = true;
    this.confirmJobError = null;

    this.confirmJob$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this.runningConfirmJob = false;

      this._dialogResponse = true;
      this.hide(this._dialogResponse);
    }, (err) => {
      this.runningConfirmJob = false;
      this.confirmJobError = getErrorMessageFromObj(err);
    });
  }

  onEscapeClick() {
    this.hide();
  }

  onOutsideClick() {
    this.hide();
  }
}
