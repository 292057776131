import { useEffect, useRef } from 'react';

export interface DragStart {
  startX: number;
  startY: number;
}

export interface DragEnd {
  endX: number;
  endY: number;
}

export function useDrag(
  onDragStart: (params: DragStart) => void,
  onDrag: (params: DragStart & DragEnd) => void
) {
  const draggableItemRef = useRef(null);
  const dragging = useRef(false);
  const mouseDownListener = useRef(null);
  const mouseMoveListener = useRef(null);
  const mouseUpListener = useRef(null);

  const clearDragEvents = () => {
    dragging.current = false;

    if (mouseMoveListener.current) {
      document.removeEventListener('mousemove', mouseMoveListener.current);
    }

    if (mouseUpListener.current) {
      window.removeEventListener('mouseup', mouseUpListener.current);
    }
  };

  useEffect(() => {
    if (!draggableItemRef.current) {
      return;
    }

    mouseDownListener.current = (e) => {
      if (dragging.current) {
        clearDragEvents();

        return;
      }

      const startX = e.x;
      const startY = e.y;

      dragging.current = true;

      onDragStart({
        startX,
        startY
      });

      mouseMoveListener.current = (moveEvent) => {
        onDrag({
          startX,
          startY,
          endX: moveEvent.x,
          endY: moveEvent.y
        });
      };

      document.addEventListener('mousemove', mouseMoveListener.current);

      mouseUpListener.current = () => {
        clearDragEvents();
      };

      window.addEventListener('mouseup', mouseUpListener.current);
    };

    draggableItemRef.current.addEventListener('mousedown', mouseDownListener.current);

    return () => {
      clearDragEvents();
    };
  }, []);

  return {
    draggableItemRef
  };
}
