import React, { useCallback } from 'react';
import { getIn } from 'formik';
import HdDropDown from '../../UIElements/HdDropDown';
import HdCreatableDropDown from '../../UIElements/HdCreatableDropDown';
import { FormikHelperText } from '../../../utils/FormikHelperText';
import HdMultiSelectDropDown from '../../UIElements/HdMultiSelectDropDown';
import HdCreatableOptionDropDown from '../../UIElements/HdCreatableOptionDropDown';
import HdCreateNewOptionsDropDownV2 from '../../UIElements/HdCreatableOptionDropDownV2';
import { getDataIdGenerator } from '../../../utils/generateDataId';

function HdFormikDropDown({
  field,
  form: { values, touched, errors, setFieldValue, setFieldTouched },
  label,
  placeholder,
  group,
  multiple,
  HelperDocumentAdornment,
  renderSelectAll,
  disabled,
  selectMode,
  SelectedOptionRenderInSelectMode,
  onFocus,
  options,
  displayAccessor,
  noOptionsText,
  valueAccessor,
  hideClearable = false,
  asyncMethod,
  onChangeEventHandler,
  CustomOption,
  showLoading,
  TopAdornment,
  BottomAdornment,
  hideHelperMessageIfEmpty = false,
  topAdornmentProps,
  bottomAdornmentProps,
  prefixIcon,
  prefixText,
  suffixText,
  classStyles,
  autoWidthPaperMode,
  disableVirtualization,
  createOnClickAway,
  creatable,
  disabledOptions,
  creatableLabel,
  creatableCallback,
  creatableHelperText = '',
  helperText = ' ',
  required = false,
  onBlur,
  creatableMode = false,
  createNewOptions = false,
  customOptionWrapperClass,
  displayValueFn,
  creatablePrimitive = false,
  dataId = '',
  sortSelectedOptions = false,
  sortDisabledOptions = false
}) {
  const showError =
    (getIn(touched, field.name) && !!getIn(errors, field.name)) ||
    (getIn(errors, field.name) && getIn(touched, field.name));

  let creatableOptionalProps = {};
  let Component = HdDropDown;

  if (creatableMode) {
    Component = HdCreatableOptionDropDown;
  }

  if (creatable) {
    Component = HdCreatableDropDown;
    const creatableFieldPath = `${field.name}-creatableFieldValue`;
    const creatableFieldState = `${field.name}-dropdownInCreatableMode`;
    const creatableFieldShowError =
      (touched[creatableFieldPath] && !!errors[creatableFieldPath]) ||
      (getIn(errors, creatableFieldPath) && getIn(touched, creatableFieldPath));
    creatableOptionalProps = {
      creatableFieldShowError,
      creatableInputValue: getIn(values, creatableFieldPath),
      creatableHelperText: (
        <FormikHelperText
          helperText={creatableHelperText || helperText}
          showError={creatableFieldShowError}
          fieldName={creatableFieldPath}
          errorMessage={getIn(errors, creatableFieldPath, '')}
        />
      ),
      createOnClickAway,
      creatableBlurHandler: () => setFieldTouched(creatableFieldPath),
      onCreatableInputSelection: value => setFieldValue(creatableFieldState, value),
      onCreatableInputChangeHandler: inputValue => setFieldValue(creatableFieldPath, inputValue)
    };
  }

  if (multiple) {
    Component = HdMultiSelectDropDown;
  }

  if (createNewOptions) {
    Component = HdCreateNewOptionsDropDownV2;
  }

  const dataIdGenerator = useCallback(
    getDataIdGenerator(`${dataId ? `${dataId}-` : ''}${field.name}`, ''),
    [field.name]
  );

  return (
    <Component
      customOptionWrapperClass={customOptionWrapperClass}
      dataId={dataIdGenerator('')}
      options={options}
      required={required}
      id={field.name}
      label={label}
      helperText={
        <FormikHelperText
          helperText={helperText}
          showError={showError}
          hideHelperMessageIfEmpty={hideHelperMessageIfEmpty}
          fieldName={field.name}
          errorMessage={getIn(errors, field.name)}
        />
      }
      displayAccessor={displayAccessor}
      valueAccessor={valueAccessor}
      placeholder={placeholder}
      group={group}
      disabledOptions={disabledOptions}
      multiple={multiple}
      renderSelectAll={renderSelectAll}
      disabled={disabled}
      autoWidthPaperMode={autoWidthPaperMode}
      disableVirtualization={disableVirtualization}
      selected={field.value}
      SelectedOptionRenderInSelectMode={SelectedOptionRenderInSelectMode}
      hideClearable={hideClearable}
      showLoading={showLoading}
      asyncMethod={asyncMethod}
      CustomOption={CustomOption}
      noOptionsText={noOptionsText}
      selectMode={selectMode}
      HelperDocumentAdornment={HelperDocumentAdornment}
      TopAdornment={TopAdornment}
      BottomAdornment={BottomAdornment}
      topAdornmentProps={topAdornmentProps}
      bottomAdornmentProps={bottomAdornmentProps}
      suffixText={suffixText}
      classStyles={classStyles}
      onFocus={onFocus}
      prefixIcon={prefixIcon}
      prefixText={prefixText}
      creatable={creatable}
      creatableLabel={creatableLabel}
      creatableCallback={creatableCallback}
      {...creatableOptionalProps}
      onChangeEventHandler={selected => {
        if (onChangeEventHandler) {
          onChangeEventHandler(selected, field.name);
        } else {
          setFieldValue(field.name, selected);
        }
      }}
      error={showError}
      onBlur={() => {
        setFieldTouched(field.name);
        if (onBlur) {
          onBlur();
        }
      }}
      displayValueFn={displayValueFn}
      sortSelectedOptions={sortSelectedOptions}
      creatablePrimitive={creatablePrimitive}
      sortDisabledOptions={sortDisabledOptions}
    />
  );
}

export default HdFormikDropDown;

export const shouldUpdateFormikDropdownFastField = (nextProps, currentProps) =>
  nextProps.options !== currentProps.options ||
  nextProps.showLoading !== currentProps.showLoading ||
  nextProps.name !== currentProps.name ||
  nextProps.required !== currentProps.required ||
  nextProps.disabled !== currentProps.disabled ||
  nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
  Object.keys(nextProps).length !== Object.keys(currentProps).length ||
  getIn(nextProps.formik.values, currentProps.name) !==
    getIn(currentProps.formik.values, currentProps.name) ||
  getIn(nextProps.formik.errors, currentProps.name) !==
    getIn(currentProps.formik.errors, currentProps.name) ||
  getIn(nextProps.formik.touched, currentProps.name) !==
    getIn(currentProps.formik.touched, currentProps.name);
