import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    // If previous chunk was cache busted after deployment, catch load chunk error and reload the page.
    const chunkErrorRegex = /Loading chunk(.)*failed./;

    if (chunkErrorRegex.test(err.message)) {
      window.location.reload();
      return;
    }
  }
}
