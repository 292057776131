import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import { HdIcon } from '../UIElements';
import { ToastConfig } from './toast-config';
import styles from './styles.module.scss';
import { animated } from '@react-spring/web';

export interface ToastProps extends ToastConfig {
  springValues: any;
  onMouseOver: MouseEventHandler;
  onMouseOut:  MouseEventHandler;
  onCloseClick: Function;
}

const TOAST_TYPE_ICON_MAP = {
  error: 'error-filled',
  info: 'info',
  wait: 'info',
  success: 'checked-tick',
  warning: 'warning'
};

export function Toast({
  springValues,
  type,
  bodyComponent,
  toasterComponentData,
  title,
  body,
  iconName,
  onMouseOver,
  onMouseOut,
  onCloseClick
}: ToastProps) {
  const BodyComponent = bodyComponent;

  return (
    <animated.div
      style={springValues}
      className={clsx(styles.toast, styles[`toast-${type}`])}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <HdIcon
        className={clsx(styles.toasterIcon)}
        name={iconName || TOAST_TYPE_ICON_MAP[type]}
      />

      <div className={styles.toastContent}>
        {!BodyComponent ? (
          <>
            <div className={styles.toastTitle}>{title}</div>

            <div className={styles.toastMessage}>
              <div style={{ whiteSpace: 'pre-line' }}>
                {body}
              </div>
            </div>
          </>
        ) : (
          <BodyComponent
            data={toasterComponentData}
            close={onCloseClick}
          />
        )}
      </div>

      <HdIcon
        className={clsx(styles.toastCloseButton)}
        name='close'
        onClick={onCloseClick}
      />
    </animated.div>
  );
}
