import { Injectable } from '@angular/core';
import { of, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsStorageService } from '../../core/service/settings-storage.service';
import { ASK_SUPPORT_ON_ERROR_DELAY } from '../ask-support-dialog/constants';
import { DialogOverlayRef } from '../dialog-overlay-ref';

import { SHARE_DOC_DIALOG_SHOWN } from './constants';
import { ShareDialogOptions } from './interface';
import { ShareDocDialogReactComponent } from '../../nodes/source-settings/share-source-settings-react/share-source-settings-react.component';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';


@Injectable()
export class ShareDocDialogService {
  private _stepErrors$ = new Subject<string>();

  constructor(
    private _genericDialogService: GenericDialogService,
    private _userSettingsService: SettingsStorageService
  ) {
  }

  show(data: ShareDialogOptions) {
    const dialogRef: DialogOverlayRef = this._genericDialogService.open(
      {
        bodyData: data,
        componentRef: ShareDocDialogReactComponent,
        stylingConfig: {
          contentWidth: 600
        }
      }
    );

    return dialogRef.onClose();
  }

  onStepError(error: string) {
    this._stepErrors$.next(error);
  }

  showShareDialogOnFailure(data: ShareDialogOptions, maxErrors: number) {
    let count = 0;

    return this._stepErrors$.pipe(
      switchMap(() => {
        count++;

        if (count < maxErrors) {
          return of();
        }

        return timer(ASK_SUPPORT_ON_ERROR_DELAY).pipe(
          switchMap(() => {

            return this._showShareDialogOnce(data);
          })
        );
      })
    );
  }

  private _showShareDialogOnce(data: ShareDialogOptions) {
    const shareDocDialogShown = this._userSettingsService.getSettings(SHARE_DOC_DIALOG_SHOWN);

    if (shareDocDialogShown) {
      return of();
    }

    this._userSettingsService.applySettings(SHARE_DOC_DIALOG_SHOWN, true);

    return this.show(data);
  }

}
