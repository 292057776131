import { Directive, Input, ComponentFactoryResolver, ApplicationRef, Injector, SimpleChanges, ElementRef, OnChanges } from '@angular/core';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { FormLoadingOverlayComponent } from '../form-loading-overlay/form-loading-overlay.component';

@Directive({
  selector: '[formOverlayLoader]'
})
export class FormOverlayLoaderDirective implements OnChanges {

  private portalHost: DomPortalHost;
  private portal: ComponentPortal<FormLoadingOverlayComponent> = new ComponentPortal(FormLoadingOverlayComponent);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private el: ElementRef
  ) { }

  @Input() overlayOutlet: any;
  @Input() showOverlay = false;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.portalHost) {
      this.overlayOutlet = this.el.nativeElement;
    }

    this.portalHost = new DomPortalHost(
      this.overlayOutlet,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    if (changes.showOverlay) {
      if (this.showOverlay) {
        this.overlayOutlet.classList.add('position-relative');
        this.portal.attach(this.portalHost);
      } else if (this.portal && this.portal.isAttached) {
        this.overlayOutlet.classList.remove('position-relative');
        this.portal.detach();
      }
    }
  }
}
