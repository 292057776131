import { SidelineSummary } from '../models/sideline-summary-group';
import { SidelineGroupReplayProgressFactory } from './replay-progress-factory';

export function serializeSidelineSummary(sidelineSummary: SidelineSummary) {
  return {
    ...sidelineSummary
  };
}

export function deSerializeSidelineSummary(rawData: any): SidelineSummary {
  Object.values(rawData.summaries).forEach((value: any) => {
    value.progress = SidelineGroupReplayProgressFactory(value.sourceObjectId, {
      total: value.progress.totalNoOfRecords,
      replayed: value.progress.noOfRecordsReplayed
    });
  });

  return rawData;
}
