export const getEditorMode = mode => {
  switch (mode) {
    case 'SQL': {
      return 'text/x-sql';
    }
    case 'JSON': {
      return { name: 'javascript', json: true };
    }
    default: {
      return mode;
    }
  }
};
