import { Directive, Input, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[faItemContent]'})
export class FaItemContentDirective implements OnChanges {
  @Input() faItemContent: TemplateRef<any>;
  @Input() option: any;
  @Input() even: boolean;
  @Input() index: number;
  @Input() active: boolean;

  constructor(
    private _viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(changes: any) {
    if (!changes.faItemContent) {
      return;
    }
    this._viewContainer.clear();
    this._viewContainer.createEmbeddedView(
      this.faItemContent,
      {
        $implicit: this.option,
        even: this.even,
        index: this.index,
        active: this.active
      }
    );
  }
}
