import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SupportService } from '../core/models/support';
import { PlanChatSupportService } from '../core/service/plan-chat-support.service';
import { AuthService } from '../core/service/auth.service';
import { UpgradeToLiveChatDialogService } from '../dialog/upgrade-to-live-chat-dialog/upgrade-to-live-chat-dialog.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[supportBtn]'
})
export class SupportBtnDirective {
  isOwner: boolean;

  @Input() message: string;

  constructor(
    private _supportService: SupportService,
    private _upgradeToLiveChatDialogService: UpgradeToLiveChatDialogService,
    private _authService: AuthService,
    private _planChatSupportService: PlanChatSupportService,
    private _router: Router
  ) {
    this.isOwner = this._authService.getUser().isOwner;
  }

  @HostListener('click') onClick() {
    const urlMatchingActivate = this._router.isActive('/activation', false),
      urlMatchingTarget = this._router.isActive('/target', false);

    if (!this._planChatSupportService.isLiveChatDisabled() || (urlMatchingActivate || urlMatchingTarget)) {
      this._supportService.show(this.message);
    } else {
      this._upgradeToLiveChatDialogService.show(
        this._planChatSupportService.currentPlanTier,
        this.isOwner
      ).onClose().subscribe();
    }
  }
}
