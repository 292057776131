import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export function PasswordStrengthBar({ barCount, strengthBarClass, strength }) {
  return (
    <div className='d-flex'>
      {[...Array(barCount).keys()].map((_, index) => (
        <div
          className={clsx(styles.strengthBar, 'mr-1', {
            [strengthBarClass]: index + 1 <= strength
          })}
        />
      ))}
    </div>
  );
}
