import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  constructor(private _dialogOverlayService: DialogOverlayService) { }

  confirm(
    title?: string,
    body?: string,
    positiveButton?: string,
    negativeButton?: string,
    showCheckBox?: boolean,
    dropdownElements?: any,
    confirmJob$?: Observable<any>,
    dialogBody?: TemplateRef<any>,
    bodyData?: any,
    bodyComponent?: ComponentType<any>,
    requiresExtraConfirmation?: boolean
  ) {
    const dialogRef: DialogOverlayRef = this._dialogOverlayService.open(
      ConfirmDialogComponent,
      {
        title,
        body,
        positiveButton,
        negativeButton,
        showCheckBox,
        dropdownElement: dropdownElements,
        confirmJob$,
        dialogBody,
        bodyData,
        bodyComponent,
        requiresExtraConfirmation
      }
    );

    return dialogRef.onClose();
  }
}
