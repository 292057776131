import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, of, Subject, timer } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { DEFAULT_ENTITY_UI_STATE_WRAPPER } from '../../core/constants';
import { EntityUIState, EntityUIStateWrapper } from '../../core/models/entitiy-ui-state';
import { MutationService } from '../../core/service/mutation.service';
import { SettingsStorageService } from '../../core/service/settings-storage.service';

import { SidelineService } from '../../core/service/sideline.service';
import { muteFirst } from '../../../react/legacy-utils/rxjs-observables';
import { ConfirmDialogService } from '../../dialog/confirm-dialog/confirm-dialog.service';
import { SIDELINE_SUMMARY_POLL_INTERVAL } from '../../pipeline/source-objects/constants';
import { StorageKey } from '../../pipeline/storage-key';
import { ToasterService } from '../../toaster/toaster.service';
import { DETACHED_SIDELINE_SUMMARY_ID } from '../constants';
import { NeedSourceObjectSidelineMsg, SidelineMessage } from '../models/sideline-message';
import { SidelineSummary } from '../models/sideline-summary-group';
import { SidelineStrings } from '../strings';
import { deSerializeSidelineSummary, serializeSidelineSummary } from '../utils/serialize-sideline-summary';
import { SidelineMessagesFactory } from '../utils/sideline-messages-factory';
import { SidelineSummaryFactory } from '../utils/sideline-summary-factory';


@Injectable()
export class SidelineCacheService {
  private _pipelineId: number;

  private _sidelineSummary$ = new BehaviorSubject<SidelineSummary>(null);
  private _sidelineSummaryUIState$ = new BehaviorSubject<EntityUIStateWrapper>(DEFAULT_ENTITY_UI_STATE_WRAPPER);
  private _sidelineMessages$ = new BehaviorSubject<{ [key: number]: SidelineMessage[] }>({});
  private _sidelineMessagesUIState$ = new BehaviorSubject<{ [key: number]: EntityUIStateWrapper }>({});
  private _refreshedAt$ = new BehaviorSubject<number>(0);
  private _needSidelineSummary$ = new BehaviorSubject(false);
  private _needSourceObjectSidelineMessages$ = new BehaviorSubject<NeedSourceObjectSidelineMsg>(null);
  private _needDetachedSidelineMessages$ = new BehaviorSubject<NeedSourceObjectSidelineMsg>(null);
  private _needDataReset$ = new Subject();

  private _sidelineSummaryEffect$ = this._needSidelineSummary$.pipe(
    filter(require => require),
    switchMap(() => {
      return timer(0, SIDELINE_SUMMARY_POLL_INTERVAL).pipe(
        exhaustMap(() => {
          this._sidelineSummaryUIState$.next({ state: EntityUIState.LOADING });

          return this._sidelineService.getSidelineSummary(this._pipelineId).pipe(
            tap((res: any) => {
              this._sidelineSummaryUIState$.next({ state: EntityUIState.IDLE });

              const sidelineSummary = SidelineSummaryFactory(this._pipelineId, res.data);

              this._setObjectSummaryData(this._sidelineSummary$.getValue(), sidelineSummary);

              this._storeSummaryData(null);

              this._sidelineSummary$.next(sidelineSummary);

              this._calcSidelineCountAndUpdate();
            }),
            catchError((error: any) => {
              this._sidelineSummaryUIState$.next({ state: EntityUIState.ERRORED, error });
              return of();
            })
          );
        }),
        takeUntil(this._needDataReset$)
      );
    }),
    share(),
    startWith(null)
  );

  private _sidelineMessagesEffect$ = merge(
    this._needSourceObjectSidelineMessages$.pipe(
      filter(request => !!request)
    ),
    this._needDetachedSidelineMessages$.pipe(
      filter(request => !!request)
    )
  ).pipe(
    mergeMap(({ sourceObjectId, handleUIState }) => {
      if (handleUIState) {
        this._updateSideMessagesUIState(sourceObjectId, EntityUIState.LOADING);
      }

      return this._sidelineService.getSidelineSummaryForSourceObject(
        this._pipelineId,
        sourceObjectId
      ).pipe(
        tap((res: any) => {
          if (handleUIState) {
            this._updateSideMessagesUIState(sourceObjectId, EntityUIState.IDLE);
          }

          const msgs = SidelineMessagesFactory(res.data);

          this._sidelineMessages$.next({
            ...this._sidelineMessages$.getValue(),
            [sourceObjectId || DETACHED_SIDELINE_SUMMARY_ID]: msgs
          });

          const sidelineCurrentVal = this._sidelineSummary$.getValue();
          const refreshedAt = new Date().getTime();

          const newSummaryData = {
            ...sidelineCurrentVal,
            summaries: {
              ...sidelineCurrentVal.summaries,
              [sourceObjectId || DETACHED_SIDELINE_SUMMARY_ID]: {
                ...sidelineCurrentVal.summaries[sourceObjectId || DETACHED_SIDELINE_SUMMARY_ID],
                actionable: msgs.reduce((total, msg) => total + msg.count, 0),
                hasEagerEvents: msgs.some((msg) => msg.hasEagerEvents === true),
                hasUncertainCount: msgs.some((msg) => msg.hasUncertainCount === true),
                refreshedAt
              }
            },
          };

          this._sidelineSummary$.next(newSummaryData);

          this._calcSidelineCountAndUpdate();

          this._storeSummaryData(this._sidelineSummary$.getValue());
        }),
        catchError((error) => {
          if (handleUIState) {
            this._updateSideMessagesUIState(sourceObjectId, EntityUIState.ERRORED, error);
          }
          return of(null);
        })
      );
    }),
    share(),
    startWith(null)
  );

  public sidelineSummary$ = muteFirst(
    this._sidelineSummaryEffect$,
    this._sidelineSummary$
  );

  public sidelineMessages$ = muteFirst(
    this._sidelineMessagesEffect$,
    this._sidelineMessages$
  );

  public sidelineSummaryUIState$ = this._sidelineSummaryUIState$.asObservable();
  public sidelineMessagesUIState$ = this._sidelineMessagesUIState$.asObservable();

  constructor(
    private _sidelineService: SidelineService,
    private _toasterService: ToasterService,
    private _mutationService: MutationService,
    private _confirmDialogService: ConfirmDialogService,
    private _storageService: SettingsStorageService,
  ) {
  }

  public init(pipelineId: number) {
    this._pipelineId = pipelineId;

    let storedSummary: SidelineSummary;
    try {
      storedSummary = deSerializeSidelineSummary(
        this._storageService.getSettings(this._getLocalStorageKey())
      );
    } catch (e) {
    }

    if (storedSummary) {
      this._sidelineSummary$.next(storedSummary);
      this._updateCacheRefreshedAt(storedSummary.refreshedAt);
    } else {
      this._clearSidelineData();
      this._updateCacheRefreshedAt(0);
    }
  }

  public getSidelineSummary() {
    this._needSidelineSummary$.next(true);
  }

  public getSidelineMessages(sourceObjectId?: number, handleUIState = true) {
    if (sourceObjectId) {
      this._needSourceObjectSidelineMessages$.next({ sourceObjectId, handleUIState });
    } else {
      this._needDetachedSidelineMessages$.next({ sourceObjectId: null, handleUIState });
    }
  }

  public stopReplayProgress(sourceObjectId?: number) {
    this._confirmDialogService.confirm(
      SidelineStrings.stopReplayTitle,
      SidelineStrings.stopReplayMsg,
      SidelineStrings.stopReplayPositiveBtn,
      SidelineStrings.stopReplayNegativeBtn
    ).pipe(
      filter(confirm => confirm),
      switchMap((confirm) => {
        return this._sidelineService.cancelReplayProgress(
          this._pipelineId,
          sourceObjectId
        ).pipe(
          map(() => {
            const sidelineClone = { ...this._sidelineSummary$.getValue() };
            sidelineClone[sourceObjectId || DETACHED_SIDELINE_SUMMARY_ID] = {
              ...sidelineClone[sourceObjectId || DETACHED_SIDELINE_SUMMARY_ID],
              progress: undefined
            };

            this._sidelineSummary$.next(sidelineClone);
            this._updateCacheRefreshedAt(new Date().getTime());
            this._storeSummaryData(this._sidelineSummary$.getValue());

            this.getSidelineMessages(sourceObjectId);
            this.getSidelineSummary();
          })
        );
      })
    ).subscribe();
  }

  public replay(sourceObjectId?: number, message?: SidelineMessage, forced = false) {
    let schemaName: string;
    let errorCode: number;

    if (message) {
      schemaName = message.schemaName;
      errorCode = message.errorCode;
    }

    this._sidelineService.replayGroup(
      this._pipelineId,
      forced,
      sourceObjectId,
      schemaName,
      errorCode
    ).subscribe(() => {
      this._toasterService.pop('success', undefined, SidelineStrings.replayResponse);
      this.getSidelineMessages(sourceObjectId);
      this.getSidelineSummary();
    });
  }

  public skip(sourceObjectId?: number, objectName?: string, message?: SidelineMessage) {
    let schemaName: string;
    let errorCode: number;

    if (message) {
      schemaName = message.schemaName;
      errorCode = message.errorCode;
    }

    this._mutationService.alertAndMutate(
      this._sidelineService.skipGroup(
        this._pipelineId,
        sourceObjectId,
        schemaName,
        errorCode
      ).pipe(map(() => true)),
      {
        title: SidelineStrings.skipTitle,
        body: SidelineStrings.skipMessage(objectName, schemaName),
        positiveButton: SidelineStrings.skipPositiveBtn,
        negativeButton: SidelineStrings.skipNegativeBtn
      }
    ).subscribe((actionTaken) => {
      if (actionTaken) {
        this._toasterService.pop('success', undefined, SidelineStrings.skipResponse);
        this.getSidelineMessages(sourceObjectId);
        this.getSidelineSummary();
      }
    });
  }

  private _updateSideMessagesUIState(sourceObjectId: number, state: EntityUIState, error?: any) {
    this._sidelineMessagesUIState$.next({
      ...this._sidelineMessagesUIState$.getValue(),
      [sourceObjectId || DETACHED_SIDELINE_SUMMARY_ID]: { state, error }
    });
  }

  private _clearSidelineData() {
    this._sidelineSummary$.next(null);
    this._sidelineMessages$.next({});
    this._sidelineMessagesUIState$.next({});
  }

  private _updateCacheRefreshedAt(timestamp: number) {
    this._refreshedAt$.next(timestamp);
  }

  private _calcSidelineCountAndUpdate() {
    const sidelineCurrentVal = this._sidelineSummary$.getValue();
    const sidelineMessageCurrentVal = this._sidelineMessages$.getValue();

    const eventsCount = Object.values(sidelineCurrentVal.summaries).reduce((total, group) => {
      return total + group.totalEvents;
    }, 0);

    const actionable = Object.values(sidelineCurrentVal.summaries).reduce((total, group) => {
      return total + group.actionable;
    }, 0);

    const objectsCount = Object.values(sidelineCurrentVal).filter((group) => {
      return group.count > 0;
    }).length;

    const hasSourceObjectsWithUncertainCount = Object.values(sidelineCurrentVal.summaries).some(group => {
      const msgFound = sidelineMessageCurrentVal?.[group.sourceObjectId];
      return msgFound ? msgFound.length > 0 && group.hasUncertainCount === true : group.hasUncertainCount === true;
    });

    const newCalculatedData = {
      ...sidelineCurrentVal,
      sourceObjects: objectsCount,
      totalEvents: eventsCount,
      actionable: actionable,
      hasSourceObjectsWithUncertainCount: hasSourceObjectsWithUncertainCount
    };

    this._sidelineSummary$.next(newCalculatedData);
  }

  private _getLocalStorageKey() {
    return StorageKey.getSidelineSummaryKey(this._pipelineId);
  }

  private _storeSummaryData(summaryData) {
    this._storageService.applySettings(this._getLocalStorageKey(), serializeSidelineSummary(summaryData));
  }

  private _setObjectSummaryData(prevSummaryData: SidelineSummary, newSummaryData: SidelineSummary) {
    if (!prevSummaryData) {
      return;
    }
    Object.keys(prevSummaryData.summaries).forEach((objectId) => {
      if (prevSummaryData.summaries[objectId]?.refreshedAt > newSummaryData.refreshedAt) {
        newSummaryData.summaries[objectId] = prevSummaryData.summaries[objectId];
      }
    });
  }
}
