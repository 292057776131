import React from 'react';
import clsx from 'clsx';
import { HdIcon } from '../UIElements';
import styles from './styles.module.scss';

export function RuleContainer({ name, status }) {
  return (
    <div className='center-flex-row'>
      <div
        className={clsx(
          { [styles.unmatchRuleIcon]: !status, [styles.matchRuleIcon]: status },
          'd-flex'
        )}
      >
        <HdIcon name='checked-tick' size='1' />
      </div>
      <div
        className={
          status ? 'text-caption-1 text-line-through text-success' : 'text-caption-1 text-secondary'
        }
      >
        {name}
      </div>
    </div>
  );
}
