import React, { useMemo, useState } from 'react';
import { isSchedulerInPipelineEnabled } from '../../../app/core/app.config';
import {
  EntityExecutionPolicyDialogProps,
  ExecutionPolicyDialogProps,
  SyncFrequencyDialogProps,
  SyncFrequencyPolicyProps
} from './models';
import { HdModal } from '../../components/UIElements';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import ActivationExecutionPolicyDialog from './ActivationExecutionPolicyDialog';
import DestinationExecutionPolicyDialog from './DestinationExecutionPolicyDialog';
import WorkflowExecutionPolicyDialog from './WorkflowExecutionPolicyDialog';
import ModelExecutionPolicyDialog from './ModelExecutionPolicyDialog';
import PipelineExecutionPolicyDialog from './PipelineExecutionPolicyDialog';
import { SyncFrequencyDialog } from './SyncFrequencyDialog';
import { SOURCE_TYPES } from '../../../app/nodes/source-type/source-type';
import { PipelineExecutionPolicyDialogWithStreaming } from './PipelineExecutionPolicyDialogWithStreaming';
import useTeamSettingsService from '../../hooks/services/useTeamSettingsService';
import { PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES } from '../pipeline/create/PipelineSettings/PipelineSchedule/constant';

export function ExecutionPolicyDialog({
  open,
  entity,
  ...restProps
}: ExecutionPolicyDialogProps | SyncFrequencyDialogProps) {
  const { getTeamSettings } = useTeamSettingsService();

  const hasFullLoadFrequency =
    !!SOURCE_TYPES[restProps?.entityDetails?.source_type]?.hasFullLoadFrequency;

  const isPipelineSchedulerEnabledForRegion = isSchedulerInPipelineEnabled();
  const isPipelineSchedulerEnabledForTeam = getTeamSettings()?.team_version >= 12;

  const isPipelineSchedulerEnabled =
    isPipelineSchedulerEnabledForRegion && isPipelineSchedulerEnabledForTeam;

  if (isPipelineSchedulerEnabled && hasFullLoadFrequency) {
    restProps.entityDetails.frequency_config.suggested_frequencies =
      PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES;
  }
  const Component: React.FC<EntityExecutionPolicyDialogProps | SyncFrequencyPolicyProps> =
    useMemo(() => {
      switch (entity) {
        case HevoEntity.ACTIVATION.value: {
          return ActivationExecutionPolicyDialog;
        }
        case HevoEntity.DESTINATION.value: {
          return DestinationExecutionPolicyDialog;
        }
        case HevoEntity.WORKFLOW.value: {
          return WorkflowExecutionPolicyDialog;
        }
        case HevoEntity.MODEL.value: {
          return ModelExecutionPolicyDialog;
        }
        case HevoEntity.PIPELINE.value: {
          return !isPipelineSchedulerEnabledForRegion || hasFullLoadFrequency
            ? PipelineExecutionPolicyDialog
            : PipelineExecutionPolicyDialogWithStreaming;
        }

        case HevoEntity.SYNC_FREQUENCY.value: {
          return SyncFrequencyDialog;
        }

        default:
          break;
      }
    }, [entity, !!hasFullLoadFrequency, isPipelineSchedulerEnabledForRegion]);

  return (
    <HdModal
      open={open}
      onClose={() => restProps.onClose()}
      styling={{ width: '580px', marginTop: '120px' }}
    >
      <Component {...restProps} />
    </HdModal>
  );
}
