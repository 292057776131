import { isArray } from 'rxjs/internal-compatibility';
import { AppConfig } from '../../../app/core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../../../app/core/constants';
import { EntitiesEventsBarGraphFactory } from '../../../app/core/models/events-bar-graph-data-point';
import { OffsetViews } from '../../../app/core/models/offset-view';
import { NetworkRequestOptions, RequestPaginationData } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';
import { groupStatsRawDataOnObjectKey } from '../../legacy-utils/events-bar-graph-data-point';
import { getOffsetViewUpdatePayload } from '../../legacy-utils/offset-view';
import { createHttpParams } from '../../legacy-utils/request';
import { GraphConstants } from '../../../app/graph/event-bar-graph/constants';
import { getNamespaceRequestPayload } from '../../../app/nodes/source-objects/utils';
import {
  SidelineEventsBulkActionEnum,
  SourceObjectActionEnum
} from '../../../app/pipeline/source-objects/models/source-object-action-enum';
import { RelatedTaskCategoryType } from '../../../app/pipeline/task/models/related-task-category-type';
import { TaskActionEnum } from '../../../app/pipeline/task/models/task-action-enum';

const appConfig = new AppConfig();
export const SourceObjectsAPI = {
  sourceObjectsURL: appConfig.getSourceObjectsURL(),
  sidelineURL: appConfig.getSidelineURL(),
  statsURL: appConfig.getStatsURL(),

  sourceObjectsUrl: appConfig.getSourceObjectsURL(),

  getIntegrationUrl(id: any): string {
    return `${appConfig.getIntegrationsURL()}/${ id }`;
  },

  getPipelineSourceObjectsURL(pipelineId: number) {
    return `${ this.sourceObjectsURL }/${ pipelineId }`;
  },

  getIntegrationsSourceObjectsURL(integrationId: number) {
    return `${ this.sourceObjectsUrl }/${ integrationId }`;
  },

  getSourceObjects(pipelineId: number, paginationData: RequestPaginationData) {
    const params = paginationData;
    params.offset = params.page;

    return courier.post(
      this.getIntegrationsSourceObjectsURL(pipelineId),
      BACKGROUND_NETWORK_REQ_OPTIONS,
      params
    );
  },

  getObjectConfig(pipelineId: any, objectId: number): Promise<any> {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/config`;
    const params = {
      ...getNamespaceRequestPayload(objectId)
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getObject(pipelineId: any, objectId: number, showLoading = true): Promise<any> {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/objects/${ objectId }`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };
    return courier.get(requestUrl, options);
  },

  updateBulkEditConfig(pipelineId: number, params: any) {
    const url = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/bulk-config`;

    return courier.put(url, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getBulkEditConfig(pipelineId: number, objectIds: any[]) {
    const url = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/bulk-config`;

    const params = {
      ids: objectIds
    };

    return courier.post(url, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  editObjectConfig(pipelineId: number, objectId: number, config: any): Promise<any> {
    const requestUrl = `${ this.getIntegrationsSourceObjectsURL(pipelineId) }/config`;
    const params = {
      config,
      ...getNamespaceRequestPayload(objectId)
    };

    return courier.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getPipelineURL(pipelineId: number) {
    return `${ appConfig.getIntegrationsURL() }/${ pipelineId }`;
  },

  getSourceObject(pipelineId: number, objectId: number, showLoading = false) {
    const requestURL = `${ this.getPipelineSourceObjectsURL(pipelineId) }/objects/${ objectId }`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return courier.get(requestURL, options);
  },

  getSourceObjectsTimeline(
    pipelineId: number,
    objectNames: string[],
    minutes: number,
    maxPoints: number
  ) {
    const requestUrl = `${ this.statsURL }/source-objects-timeline/${ pipelineId }`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints,
          objects: objectNames
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestUrl, options).then(res => {
      const objectStatsWithAdditionalData = (res?.data?.stats || []).reduce(
        groupStatsRawDataOnObjectKey,
        {}
      );

      return EntitiesEventsBarGraphFactory(
        objectNames,
        objectStatsWithAdditionalData,
        GraphConstants.eventsBarCountOld
      );
    });
  },

  applyBulkAction(
    pipelineId: number,
    action: TaskActionEnum | SidelineEventsBulkActionEnum,
    filter: any,
    selector: any,
    force = false
  ) {
    let requestURL = this.getPipelineURL(pipelineId);

    if (
      action === SidelineEventsBulkActionEnum.SKIP_FAILED ||
      action === SidelineEventsBulkActionEnum.REPLAY_FAILED
    ) {
      requestURL = `${ this.sidelineURL }/${ pipelineId }/failed-events/bulk-actions`;
    }

    const params = {
      selector,
      filter,
      action_type: action,
      force
    };

    return courier.post(requestURL, {}, params);
  },

  refreshPermissionDeniedObjects(pipelineId: number) {
    const requestURL = `${ this.getPipelineSourceObjectsURL(pipelineId) }/permission-denied/refresh`;

    return courier.put(requestURL, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  applyActionOnObject(
    pipelineId: number,
    objectId: number,
    categoryType: RelatedTaskCategoryType,
    action: SourceObjectActionEnum,
    force: boolean
  ) {
    const requestUrl = `${ this.getPipelineSourceObjectsURL(pipelineId) }/objects/${ objectId }/status`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const params = {
      force,
      task_category_type: categoryType,
      action
    };

    return courier.put(requestUrl, options, params);
  },

  autoUpdateAndIncludeObject(
    pipelineId: number,
    objectId: number | number[],
    categoryType?: RelatedTaskCategoryType
  ) {
    const requestUrl = `${ this.getPipelineSourceObjectsURL(pipelineId) }/auto-update-and-include`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    const params = {
      ids: isArray(objectId) ? objectId : [ objectId ],
      category_type: categoryType || null
    };

    return courier.post(requestUrl, options, params).then(response => response.data);
  },

  includeHistoricalLoad(
    pipelineId: number,
    objectId: number | number[]
  ) {
    const requestUrl = `${ this.getPipelineSourceObjectsURL(pipelineId) }/task-config`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const params = {
      ids: isArray(objectId) ? objectId : [ objectId ]
    };

    return courier.put(requestUrl, options, params);
  },

  restartObject(pipelineId: any, objectId: number, categoryType: RelatedTaskCategoryType) {
    const requestUrl = `${ this.getPipelineSourceObjectsURL(pipelineId) }/restart`;
    const params = {
      id: objectId,
      task_category_type: categoryType
    };

    return courier.put(requestUrl, {}, params);
  },

  runNow(pipelineId: number, objectId: number) {
    const requestUrl = `${ this.getPipelineSourceObjectsURL(pipelineId) }${
      objectId ? '/objects/instant-run/' : '/instant-run'
    }`;

    let params;
    if (objectId) {
      params = {
        id: objectId
      };
    }

    return courier.put(requestUrl, {}, params);
  },

  changePosition(pipelineId: number, objectId: number, offsetView: OffsetViews) {
    const requestUrl = `${ this.getPipelineSourceObjectsURL(pipelineId) }/offset`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    const body = {
      offset: getOffsetViewUpdatePayload(offsetView),
      skip_tracking: true,
      ...getNamespaceRequestPayload(objectId)
    };

    return courier.put(requestUrl, options, body);
  },

  getAllObjectNames(pipelineId: number, failedTasks: string[]) {
    const requestUrl = this.getIntegrationsSourceObjectsURL(pipelineId);

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          properties: 'namespace',
          failed_log_tasks: failedTasks
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  handleLogExpiry(pipelineId: number, params: any) {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/log-expiry`;

    return courier.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }
};

export default SourceObjectsAPI;
