import { Injectable } from '@angular/core';
import { ProductTourItemComponent } from './product-tour-item/product-tour-item.component';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductTourService {
  private _registeredItems: { [key: string]: ProductTourItemComponent } = {};

  private _onDeregister$ = new Subject<string>();

  onDeregister$ = this._onDeregister$.asObservable();

  registerItem(key: string, component: ProductTourItemComponent) {
    this._registeredItems[key] = component;
  }

  deregisterItem(key: string) {
    this._onDeregister$.next(key);
    delete this._registeredItems[key];
  }

  getItem(key: string) {
    return this._registeredItems[key];
  }
}
