import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class AppsflyerConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      projectID: rawData.project_id,
      apiToken: rawData.api_token,
      apiTokenV2: rawData.api_token_v2,
      pullAPITimezone: rawData.pull_api_timezone ? {
        canonicalID: rawData.pull_api_timezone,
        name: rawData.pull_api_timezone,
        id: rawData.pull_api_timezone
      } : undefined
    };
  }

  getDefaultConfig(metaData: any) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);

    return {
      dateOffset: date
    };
  }

  buildConnectionParams(data: any) {
    return {
      project_id: data.projectID,
      api_token_v2: data.apiTokenV2,
      pull_api_timezone: data.pullAPITimezone?.canonicalID
    };
  }
}
