import { Component, Input, Output, EventEmitter, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnChanges {

  @Input() error: any;
  @Input() mode: 'full' | 'compact' = 'full';
  @Output() tryAgain = new EventEmitter<void>();

  constructor(private _el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    const classList: DOMTokenList = (<HTMLElement>this._el.nativeElement).classList;
    if (changes.mode) {
      classList.remove(`mode-${changes.mode.previousValue}`);
      classList.add(`mode-${changes.mode.currentValue}`);
    }
  }

  getErrorMsg() {
    return getErrorMessageFromObj(this.error);
  }

  getErrorIcon() {
    if (this.error && this.error.status <= 0) {
      return 'no-internet';
    }

    return 'broken';
  }
}
