import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { BridgeService } from '../../../react-bridge/services/bridge.service';
import SourceInviteMembersDialog, {SourceInviteMembersProps} from '../../../../react/components/Dialog/InviteMembersDialog/SourceInviteMembersDialog'
import { DialogOverlayRef } from '../../../dialog/dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../../../dialog/dialog-overlay.tokens';
@Component({
  selector: 'share-docs-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class ShareDocDialogReactComponent implements OnInit {

  shareDocDialogOptions: any

  constructor(
    @Inject(DialogOverlayRef) protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: any,
    private _reactBridgeService: BridgeService,
    private _el: ElementRef,
  ) {
    this.shareDocDialogOptions = _data;
  }

  ngOnInit() {
    this.renderReact();
  }

  renderReact() {
    this._reactBridgeService.mount<SourceInviteMembersProps>(SourceInviteMembersDialog, this._el.nativeElement, {
      docLink: this.shareDocDialogOptions.bodyData.docLink,
      entity: this.shareDocDialogOptions.bodyData.entity,
      renderAsDialog: false,
      sourceId: this.shareDocDialogOptions.bodyData.sourceId,
      onClose: () => this._dialogRef.closeDialog(),
      open: true,
      sourceDisplayName: this.shareDocDialogOptions.bodyData.displayName,
      type: this.shareDocDialogOptions.bodyData.type
    });
  }
}
