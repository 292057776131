import React, { useEffect, useState } from 'react';
import { Popover, styled } from '@mui/material';
import styles from './styles.module.scss';
import SVGMask from '../SVGMask';
import useForceUpdate from '../../hooks/useForceUpdate';
import { AnchorShape } from '../../../app/product-tour/product-tour-dialog/interface';

// eslint-disable-next-line no-shadow
export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

const DEFAULT_ARROW_ICON =
  'https://res.cloudinary.com/hevo/image/upload/v1624454030/glyphs/product-tour/arrow-above-end-in_b9v9xn.svg';

function ProductTourPopover({ currentStep, anchorElement, ...rest }) {
  const [tourCurrentStep, setTourCurrentStep] = useState(currentStep);
  const forceUpdate = useForceUpdate();
  // extract all the props for current render step item
  const {
    anchorShape = 'rectangle' as AnchorShape,
    anchorInteractive = false,
    hideOverlay = false,
    arrowImageUrl,
    anchorPadding,
    anchorAbsolute,
    anchorPosition,
    anchorOrigin,
    transformOrigin,
    arrowOrigin = { vertical: '', horizontal: '' },
    popoverOrigin = { vertical: 0, horizontal: 0 }
  } = tourCurrentStep;

  const anchorDimensions: DOMRect = anchorElement.getBoundingClientRect();
  const anchorRelativePosition = { top: anchorDimensions.top, left: anchorDimensions.left };
  const arrowOriginMetaData = getArrowOriginMetaDeta();
  const TourComponent = tourCurrentStep.reactTourComponent;

  function getArrowOriginMetaDeta() {
    let className = '';
    const positionBottom =
      arrowOrigin.vertical === Position.BOTTOM ||
      (arrowOrigin.vertical === Position.CENTER && arrowOrigin.horizontal === Position.RIGHT);

    if (arrowOrigin.vertical === Position.TOP && arrowOrigin.horizontal === Position.RIGHT) {
      className = styles[arrowOrigin.horizontal];
    } else {
      className = styles.show;
    }

    if (arrowOrigin.vertical === Position.BOTTOM) {
      className = styles[arrowOrigin.horizontal];
    }

    if (arrowOrigin.vertical === Position.CENTER) {
      className = styles[`rotate${arrowOrigin.horizontal}`];
    }
    return {
      className: arrowImageUrl ? '' : className,
      // eslint-disable-next-line no-nested-ternary
      position: positionBottom ? Position.BOTTOM : arrowOrigin.vertical !== '' ? Position.TOP : ''
    };
  }

  useEffect(() => {
    setTourCurrentStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    forceUpdate();
  };

  const popoverFlexRowClass = arrowOrigin.vertical === Position.CENTER ? styles.sideBySide : '';
  const popoverOriginPadding = `${popoverOrigin.vertical}px ${popoverOrigin.horizontal}px`;

  return (
    <div>
      {!hideOverlay ? (
        <SVGMask
          sizes={anchorDimensions}
          padding={anchorPadding || 0}
          anchorShape={anchorShape}
          highlightedAreaClickable={anchorInteractive}
        />
      ) : null}

      <StyledPopover
        open
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorReference={anchorAbsolute || anchorPosition ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={anchorPosition || anchorRelativePosition}
        anchorEl={anchorElement}
      >
        <div style={{ padding: popoverOriginPadding }}>
          <div className={`${styles.tourWrapper} ${popoverFlexRowClass}`}>
            {arrowOriginMetaData.position === Position.TOP && (
              <div className={`${styles.tourDirection} ${arrowOriginMetaData.className}`}>
                <img alt='tour-direction' src={arrowImageUrl || DEFAULT_ARROW_ICON} />
              </div>
            )}

            <div className={styles.tourContainer}>
              {TourComponent ? <TourComponent {...rest} /> : null}
            </div>

            {arrowOriginMetaData.position === Position.BOTTOM && (
              <div className={`${styles.tourDirection} ${arrowOriginMetaData.className}`}>
                <img alt='tour-direction' src={arrowImageUrl || DEFAULT_ARROW_ICON} />
              </div>
            )}
          </div>
        </div>
      </StyledPopover>
    </div>
  );
}

export default ProductTourPopover;

const StyledPopover = styled(Popover)(() => ({
  position: 'relative',
  '& .MuiBackdrop-root': {
    display: 'none'
  },
  '& .MuiPaper-root': {
    position: 'fixed',
    zIndex: 'var(--zindex-modal-backdrop)'
  }
}));
