import { OverlayPosition } from '../overlay/types';
import { PopupPosition } from './models';


export function isFallbackAlignedToPopupPosition(popupPosition: PopupPosition, fallbackPosition: OverlayPosition) {
  switch (popupPosition) {
    case 'above':
      return fallbackPosition.originY === 'top' && fallbackPosition.overlayY === 'bottom';
    case 'below':
      return fallbackPosition.originY === 'bottom' && fallbackPosition.overlayY === 'top';
    case 'left':
      return fallbackPosition.originX === 'start' && fallbackPosition.overlayX === 'end';
    case 'right':
      return fallbackPosition.originX === 'end' && fallbackPosition.overlayX === 'start';
  }
}
