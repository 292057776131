import { AnimationEvent } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { KeyboardShortcuts, Unlisten } from '../shortcuts/service/keyboard-shortcuts.service';
import { DialogOverlayRef } from './dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from './dialog-overlay.tokens';


@Directive()
export class DialogBase implements OnInit, OnDestroy {
  title: string;
  message: string;

  bodyClassAdded = false;
  trapFocusAutoCapture = false;
  disableClose = false;
  backdropCloseDisabled = false;

  protected _dialogResponse: any = false;

  @ViewChild('content', { static: true }) contentRef: any;
  @ViewChild('container', { static: true }) containerRef: any;

  protected _keyboardUnlisten: Unlisten;

  visibleState: string;

  protected _destroyed$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) protected document: any,
    @Inject(DialogOverlayRef) protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public _data: any,
    @Inject(KeyboardShortcuts) protected _keyboardShortcuts: KeyboardShortcuts
  ) {
  }

  ngOnInit() {
    this._listenKeyboardEvents();

    this._dialogRef.closeRequestSubject.pipe(
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this.hide();
    });
  }

  show() {
    this.visibleState = 'in';

    if (!document.querySelector('body').classList.contains('modal-open')) {
      this.bodyClassAdded = true;
      document.querySelector('body').classList.add('modal-open');
    }

    this.title = this._data.title;
    this.message = this._data.message;
  }

  hide(dialogResponse?: any) {
    if (this.disableClose) {
      return;
    }

    this._dialogResponse = dialogResponse;

    this.visibleState = 'out';

    if (this.bodyClassAdded) {
      document.querySelector('body').classList.remove('modal-open');
    }
  }

  @HostListener('document:keydown', ['$event']) onDocumentKeydown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.onEscapeClick();

      if (!this.backdropCloseDisabled) {
        this.onNegativeClick();
      }
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    if (this.containerRef.nativeElement.contains(event.target)
      && !this.contentRef.nativeElement.contains(event.target)) {
      this.onOutsideClick();

      if (!this.backdropCloseDisabled) {
        this.onNegativeClick();
      }
    }
  }

  onPositiveClick(data?: any) {
    this._dialogRef.positiveClick(data);
  }

  onNegativeClick(dialogResponse?: any) {
    if (this._dialogResponse) {
      this.hide(this._dialogResponse);
    } else {
      this.hide(dialogResponse || false);
    }
  }

  onOutsideClick() {
  }

  onEscapeClick() {
  }

  onDialogAnimationDone(e: AnimationEvent) {
    if (e.phaseName === 'done' && e.toState === 'out') {
      this._dialogRef.close(this._dialogResponse);
    }

    if (e.phaseName === 'done' && e.toState === 'in') {
      this._dialogRef.markOpened();
    }
  }

  protected _listenKeyboardEvents() {
    this._keyboardUnlisten = this._keyboardShortcuts.listen(
      {}, {
        priority: 100
      }
    );
  }

  ngOnDestroy() {
    if (typeof this._keyboardUnlisten === 'function') {
      this._keyboardUnlisten();
    }

    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
