import React from 'react';
import { Typography } from '@mui/material';
import { HdRadio } from '../../../../../components/UIElements/HdRadio';
import { HdRadioGroup } from '../../../../../components/UIElements/HdRadioGroup';
import { stringToBool } from '../../../../../utils/helper';
import { hostNameValidator, localHostValidator } from '../../../../../utils/hostNameHandler';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import { TRACKER_DESTINATION_AZURE_SYNAPSE_CONNECTION_STRING } from '../../../../../../app/nodes/tracking';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';

const dataIdGenerator = getDataIdGenerator('azure-synapse');

export function ConnectionSettingsRadioGroup({
  field,
  form,
  isEditing,
  destinationTypeIdentifier
}) {
  const analyticsTracker = useAnalyticsTracker();

  const connectionURIModeChange = e => {
    const isConnectionURI = stringToBool(e);

    analyticsTracker.eventTrack({
      action: TRACKER_DESTINATION_AZURE_SYNAPSE_CONNECTION_STRING,
      properties: {
        selectedOption: isConnectionURI ? 'paste_connectiong_string' : 'enter_fields_manually',
        destinationType: destinationTypeIdentifier
      }
    });

    let values = {
      ...form.values,
      isConnectionUri: e,
      URI: ''
    };

    /**
     * If user selects manual field option then we will check if the connectionString is valid or not if its valid then we preserve host,
     * port, and database along with userName and password otherwise we will clear the fields
     */
    if (!isConnectionURI) {
      values = {
        ...values,
        databaseName: '',
        host: '',
        user: ''
      };

      const credentialObj = getConnectionParamsFromURI(form.values.URI);

      // if there is no error
      if (!credentialObj.error) {
        values = { ...values, ...credentialObj };
      }
    }

    /**
     * If user selection connection string mode then we check if all the fields which are required to form a connection string are valid
     * or not, if they are then we form a connection string other just set the connectString to empty string
     */
    if (
      isConnectionURI &&
      !form.errors.host &&
      !form.errors.port &&
      !form.errors.databaseName &&
      !form.errors.user
    ) {
      values = {
        ...values,
        URI: `jdbc:sqlserver://${form.values.host}:${form.values.port};database=${form.values.databaseName};user=${form.values.user};`
      };
    }

    form.setValues(values);
  };

  return (
    <HdRadioGroup
      {...field}
      name='isConnectionUri'
      label='Connection Settings'
      onChange={connectionURIModeChange}
      className='mb-5'
    >
      <HdRadio
        key='connectionString'
        value='true'
        disabled={isEditing}
        dataId={dataIdGenerator('mode-connectionString')}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Paste Connection String</Typography>
        </div>
      </HdRadio>

      <HdRadio
        key='manual'
        value='false'
        className='ml-5'
        disabled={isEditing}
        dataId={dataIdGenerator('mode-manual')}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Enter Connection Settings Manually</Typography>
        </div>
      </HdRadio>
    </HdRadioGroup>
  );
}

function isURIInvalid(uri: string) {
  if (!uri.startsWith('jdbc:sqlserver://')) {
    return true;
  }
  return false;
}

export function getConnectionParamsFromURI(uri: string) {
  let paramObj: {
    host: string;
    port: string;
    user: string;
    databaseName: string;
    error?: string;
  } = getInitialConnectionParams();

  if (isURIInvalid(uri)) {
    paramObj = getInitialConnectionParams();
    paramObj.error = 'invalidURI';
    return paramObj;
  }
  let hostAndPort = parseHostAndPort(uri);

  if (hostAndPort !== null) {
    if (!localHostValidator(hostAndPort.host) || !hostNameValidator(hostAndPort.host)) {
      hostAndPort = null;
    }
  }
  if (hostAndPort !== null) {
    paramObj.host = hostAndPort.host;
    paramObj.port = String(hostAndPort.port);
  } else {
    paramObj.error = 'invalidURI';
    return paramObj;
  }

  const credentialObj: any = parseConnectionString(uri);

  if (!credentialObj.database) {
    paramObj.error = 'databaseRequired';
    return paramObj;
  }
  paramObj.databaseName = credentialObj.database;

  if (!credentialObj.user) {
    paramObj.error = 'userRequired';
    return paramObj;
  }

  paramObj.user = credentialObj.user;

  return paramObj;
}

// This function takes a JDBC connection string as its input and returns an object
// containing the host and port extracted from the string.
function parseHostAndPort(str) {
  // First, we use a regular expression to extract the host and port from the string.
  // The regular expression looks for the pattern "host:port" where host is a sequence
  // of one or more non-colon characters, and port is a sequence of one or more digits.
  const hostAndPort = str.match(/[^:\/\s]+:[0-9]+/);

  // If the host and port were found, we extract them from the match and return them
  // as an object.
  if (hostAndPort) {
    const hostAndPortArray = hostAndPort[0].split(':');
    return {
      host: hostAndPortArray[0],
      port: parseInt(hostAndPortArray[1], 10)
    };
  }

  // If the host and port were not found, we return null.
  return null;
}

function parseConnectionString(connectionString: string) {
  const items = connectionString.split(';');
  const parsedItems = {};
  items.forEach(item => {
    const [key, value] = item.split('=');
    parsedItems[key] = value;
  });
  return parsedItems;
}

function getInitialConnectionParams() {
  return {
    host: '',
    port: '',
    user: '',
    databaseName: ''
  };
}
