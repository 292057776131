import React from 'react';
import { StaticProvider } from '@angular/core';
import { useRegisterProviders } from '../../hooks/useService';
import { AngularDIContext } from '../../../app/react-bridge/di-context';

export function AngularDiProviders({ providers, children }: { providers: StaticProvider[]; children: any }) {
  const injectorWithTargetSettingsService = useRegisterProviders(providers);

  return (
    <AngularDIContext.Provider value={injectorWithTargetSettingsService}>
      {children}
    </AngularDIContext.Provider>
  );
}
