import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { AutoMapFailedEventsDialogService } from '../../dialog/auto-map-failed-events-dialog/auto-map-failed-events-dialog.service';

import { GOTO_ON_DEMAND_CREDIT_SECTION_QUERY_PARAM } from '../../../react/containers/payment/OnDemandCredit/constants';
import { CARD_LIST_OPENED_WIDGET, CardListOpenedWidget } from '../../pipeline/models/card-list-opened-widget';
import { SourceObject } from '../../pipeline/source-objects/models/source-object';
import { SourceObjectActionEnum } from '../../pipeline/source-objects/models/source-object-action-enum';
import { SourceObjectInnerWidget } from '../../pipeline/source-objects/source-object-list/source-object-opened-widget-state';
import { SourceObjectStrings } from '../../pipeline/source-objects/strings';
import { SIDELINE_ACTION_CLICK_TRACKING } from '../../pipeline/source-objects/tracking';
import { SidelineMessage, SidelineMessageAction } from '../../sideline/models/sideline-message';
import { SidelineCacheService } from '../../sideline/service/sideline-cache.service';
import { PostPCSNotificationType } from '../models/post-pcs-notification';
import { MutationService } from './mutation.service';
import { PostPCSNotifierService } from './post-pcs-notifier.service';
import { SidelineService } from './sideline.service';
import { SourceObjectService } from './source-object.service';
import { UserService } from './user.service';


@Injectable()
export class SidelineEventService {
  isOwner = false;
  allActions = SourceObjectActionEnum;

  replaySamplesDialogConfig: {
    open: boolean;
    pipelineId?: number;
    events?: object[];
    sidelineMessage?: SidelineMessage;
  } = {
    open: false
  };

  constructor(
    private _sidelineService: SidelineService,
    private _userService: UserService,
    private _router: Router,
    private _angulartics: Angulartics2,
    private _autoMapFailedEventsDialogService: AutoMapFailedEventsDialogService,
    private _sidelineCacheService: SidelineCacheService,
    private _mutationService: MutationService,
    private _sourceObjectService: SourceObjectService,
    private _postPCSNotifierService: PostPCSNotifierService,
    @Inject(CARD_LIST_OPENED_WIDGET) private _cardListOpenedWidget: CardListOpenedWidget
  ) {
    this.isOwner = this._userService.getUser().isOwner;
  }

  performSidelineAction(pipeline: any, object: SourceObject, action: SidelineMessageAction, message: SidelineMessage) {
    this._angulartics.eventTrack.next({
      action: SIDELINE_ACTION_CLICK_TRACKING[action]
    });

    switch (action) {
      case SidelineMessageAction.DONE: {
        this.replay(object, false, message);
        break;
      }
      case SidelineMessageAction.REPLAY: {
        this._postPCSNotifierService.notify({
          type: PostPCSNotificationType.REPLAY_SIDELINE_ERRORS,
          pipeline: pipeline,
          object: object?.name,
          message: message?.reason,
          messageCode: message?.errorCode
        });

        this.replay(object, true, message);
        break;
      }
      case SidelineMessageAction.SKIP: {
        this.skip(object, message);
        break;
      }
      case SidelineMessageAction.UPGRADE_PLAN: {
        this.goToUpgradePlan();
        break;
      }
      case SidelineMessageAction.SET_ON_DEMAND_CREDIT: {
        this.goToOnDemandCredit();
        break;
      }
      case SidelineMessageAction.HIKE_ON_DEMAND_CREDIT: {
        this.goToOnDemandCredit();
        break;
      }
      case SidelineMessageAction.REDEEM_ON_DEMAND_CREDIT: {
        this.goToOnDemandCredit();
        break;
      }
      case SidelineMessageAction.VIEW_SAMPLE: {
        this.showSamples(pipeline, message);
        break;
      }
      case SidelineMessageAction.MAP_SCHEMA: {
        this.goToMapper(pipeline, message, action);
        break;
      }
      case SidelineMessageAction.FIX_MAPPING: {
        this.goToMapper(pipeline, message, action);
        break;
      }
      case SidelineMessageAction.CREATE_MAPPING: {
        this._autoMapFailedEventsDialogService.confirm(
          pipeline,
          object,
          message
        ).subscribe(() => {
          this._sidelineCacheService.getSidelineSummary();
        });
        break;
      }
      case SidelineMessageAction.SKIP_OBJECT: {
        if (!object) { // for webhook
          this._sidelineCacheService.skip(null, null, message);
          break;
        }
        this.applyAction(pipeline, object, this.allActions.SKIP);
        break;
      }
      case SidelineMessageAction.VIEW_DETAILS: {
        this.openSidelineEventWidget(object?.id);
        break;
      }
      case SidelineMessageAction.INCLUDE_OBJECT: {
        if (!object) { // for webhook
          break;
        }
        this.applyAction(pipeline, object, this.allActions.INCLUDE);
        break;
      }
    }
  }

  showSamples(pipeline, message: SidelineMessage) {
    this._sidelineService.getSamples(
      pipeline.id,
      message.schemaName,
      message.stage,
      message.errorCode
    ).subscribe((res: any) => {
      this.replaySamplesDialogConfig = {
        open: true,
        pipelineId: pipeline.id,
        sidelineMessage: message,
        events: res.data
      };
    }, () => {
      this.replaySamplesDialogConfig = {
        open: true,
        pipelineId: pipeline.id,
        sidelineMessage: message
      };
    });
  }

  onReplaySamplesDialogClose() {
    this.replaySamplesDialogConfig.open = false;
  }


  goToOnDemandCredit() {
    this._router.navigate(
      ['payment', 'plan-details'],
      {
        queryParams: {
          [GOTO_ON_DEMAND_CREDIT_SECTION_QUERY_PARAM]: true
        }
      }
    );
  }

  goToUpgradePlan() {
    this._router.navigate(
      ['payment', 'plan-details'],
      {
        queryParams: {
          editing: true,
        }
      }
    );
  }

  goToMapper(pipeline, message: SidelineMessage, action: SidelineMessageAction) {
    const extraParam = {};
    if (action === SidelineMessageAction.FIX_MAPPING) {
      extraParam['events_with_warnings'] = true;
    } else if (action === SidelineMessageAction.MAP_SCHEMA) {
      extraParam['status'] = 'ALL';
    }
    this._router.navigate(
      [ 'pipeline', pipeline.seq_id, 'schema-mapper', 'details' ],
      {
        queryParams: {
          event: message.schemaName,
          events_page: 0,
          ...extraParam
        }
      }
    );
  }

  replay(object = null, forced = false, message?: SidelineMessage) {
    this._sidelineCacheService.replay(object?.id, message, forced);
  }

  skip(object = null, message?: SidelineMessage) {
    this._sidelineCacheService.skip(object?.id, object?.name, message);
  }

  openSidelineEventWidget(objectId?: number) {
    this._cardListOpenedWidget.toggleObjectOpenedWidget(objectId, SourceObjectInnerWidget.SIDELINE);
    this._sidelineCacheService.getSidelineMessages(objectId);
  }

  closeSidelineEventWidget(objectId) {
    this._cardListOpenedWidget.toggleObjectOpenedWidget(objectId, SourceObjectInnerWidget.SIDELINE);
  }

  applyAction(pipeline, object, action: SourceObjectActionEnum) {
    this._mutationService.mutateWithConfirmation(
      (force: boolean) => {
        return this._sourceObjectService.applyActionOnObject(
          pipeline.id,
          object.id,
          null,
          action,
          force
        );
      },
      (mutationRes: any) => {
        return {
          title: SourceObjectStrings.applyActionTitle(action),
          body: mutationRes.data.message,
          positiveButton: SourceObjectStrings.actionPstBtn,
          negativeButton: SourceObjectStrings.actionNgtBtn
        };
      },
      (mutationRes: any) => {
        return mutationRes.data.need_confirmation;
      }
    ).subscribe(({ actionTaken }: any) => {
      if (!actionTaken) {
        return;
      }

      this._sourceObjectService.onSourceObjectEdit({
        pipelineId: pipeline.id,
        objectId: object.id
      });
    });
  }
}
