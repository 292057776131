import { useEffect, useState } from 'react';
import { TrackingService } from '../../../app/core/service/tracking.service';
import useService from '../../hooks/useService';
import useUserService from '../../hooks/services/useUserService';

declare let __CLARITY_CONFIG__: any;
declare let __DOCS_CONFIG__: any;

const DOCS_BASE_URL = __DOCS_CONFIG__.miniDocsBaseURL;

export function useDocsUrl({
  docLink,
  areTabsRendered,
  queryParams
}): [string, () => any] {
  const { user } = useUserService();
  const trackingService = useService(TrackingService);

  const getIframeSourceURL = () => {

    if (docLink === null) {
      return null;
    }

    let linkWithQueryParams = docLink;

    // set Fullstory to true if enabled in config
    const clarityTrackingEnabled = trackingService.isTrackingEnabled(__CLARITY_CONFIG__);
    linkWithQueryParams += `?ms_clarity=${ clarityTrackingEnabled ? 'true' : 'false'}`;

    // If body contains dark-mode then append as query params
    // to render the docs in dark mode without waiting for colorModeService
    if (document.body.classList.contains('color-mode-dark')) {
      linkWithQueryParams += '&colorMode=dark';
    }

    if (user) {
      linkWithQueryParams += `&app_user=${ user.email }`;
    }

    if (queryParams) {
      const urlEncodedQueryParams = getURLEncodedQueryParams(queryParams);

      if (urlEncodedQueryParams) {
        linkWithQueryParams += `&${urlEncodedQueryParams}`;
      }
    }

    if (areTabsRendered) {
      linkWithQueryParams += `&canShowTabs=true`;
    }

    return DOCS_BASE_URL + linkWithQueryParams;
  };

  const getURLEncodedQueryParams = (params: Record<string, string>) => {
    const keys = Object.keys(params);
    if (keys.length === 0) {
      return '';
    }

    return keys.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  };

  const [ iframeSourceURL, setIframeSourceURL ] = useState(getIframeSourceURL());

  useEffect(() => {
    setIframeSourceURL(getIframeSourceURL());
  }, [docLink, areTabsRendered, queryParams]);

  const rebuildIframeSourceURL = () => {
    setIframeSourceURL(getIframeSourceURL());
  };

  return [ iframeSourceURL, rebuildIframeSourceURL ];
}
