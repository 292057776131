import { EntityStatus } from '../../../../app/core/models/entity-status';
import { TargetTypesEnum } from './target-type';


export class Target {
  id: number;
  seqId?: number;
  name: string;
  targetType: TargetTypesEnum;
  targetTypeDisplay: string;
  targetTypeLogoUrl: string;
  targetTypeDarkModeLogoUrl: string;
  config: any;
  displayConfig?: { [key: string]: string };
  isEditable?: boolean;
  oauthUserId?: number;
  status: EntityStatus;
  failureMessage: string;
  lastSyncedTs: any;
}
