import { Component, ElementRef, OnInit } from '@angular/core';
import { UnsubscribeNotificationsPage } from '../../react/containers/notification-subscription/UnsubscribeNotificationsPage';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'unsubscribe-notifications-react',
  template: ``,
  providers: [ BridgeService ]
})
export class UnsubscribeNotificationsPageReactComponent implements OnInit {
  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._renderComponent();
  }

  private _renderComponent() {
    this._bridgeService.mount(
      UnsubscribeNotificationsPage,
      this._el.nativeElement,
      {}
    );
  }
}
