import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdCodeEditor } from '../index';
import styles from './styles.module.scss';
import { getEditorMode } from '../utils';

require('codemirror/mode/javascript/javascript');
require('codemirror/mode/sql/sql');

interface QueryViewerProps {
  dataId: string;
  className?: string;
  value: string;
  codeEditorClassName?: string;
  autoHeight?: boolean;
  allowCopy?: boolean;
  resizeCtrl?: boolean;
  showLineNumbers?: boolean;
  mode?: any;
  onChange?: (string: string) => void;
  getCodeMirror?: Function;
}

export default function QueryViewer({
  dataId,
  className = '',
  value,
  codeEditorClassName = '',
  autoHeight = false,
  allowCopy = true,
  showLineNumbers = false,
  resizeCtrl = false,
  mode = 'SQL',
  onChange = () => {},
  getCodeMirror
}: QueryViewerProps) {
  const editorMode = getEditorMode(mode);
  const queryValue = useMemo(() => {
    if (mode === 'JSON') {
      return JSON.stringify(JSON.parse(value), null, '\t');
    }
    return value;
  }, [value, mode]);

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  return (
    <HdCodeEditor
      dataId={dataIdGenerator('query-viewer')}
      className={`${styles.sqlViewer} ${className}`}
      codeEditorClassName={clsx(
        !showLineNumbers && styles.sqlViewerCodeEditorWithoutLineNumber,
        codeEditorClassName,
        styles.sqlViewerCodeEditor
      )}
      value={queryValue}
      autoHeight={autoHeight}
      allowCopy={allowCopy}
      showLineNumbers={showLineNumbers}
      readonly
      resizeCtrl={resizeCtrl}
      onChange={onChange}
      editorOptions={{
        mode: editorMode
      }}
      getCodeMirror={getCodeMirror}
      copyTooltipContent='Copy Query'
    />
  );
}
