import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { NodeStrings } from '../../../app/nodes/node-strings';
import {
  TRACKER_DEST_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK,
  TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK,
  TRACKER_SOURCE_CONFIG_TROUBLESHOOT_BUTTON_CLICK,
  TRACKER_TARGET_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK
} from '../../../app/nodes/tracking';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { trackSetupGuideLink } from '../../utils/setupGuideLinkHandler';
import { capitalizeFirstLetter } from '../../utils/stringFormatter';
import { HdSupportButton } from '../SupportButton';
import { HdLink } from '../UIElements';
import HdButton from '../UIElements/HdButton';
import HdDocLink from '../UIElements/HdDocLink';
import HdPane from '../UIElements/HdPane';
import styles from './styles.module.scss';

declare const __DOCS_CONFIG__: any;

interface FormApiErrorProps {
  dataId: string;
  message: string;
  errorCode?: number;
  nodeType: string;
  nodeCategory: string;
  docLink?: string;
  actionType?: string;
  troubleshootLink?: string;
  troubleshootUsingSBSDocs?: string;
  troubleshootSBSDocsLink?: string;
  onClick: Function;
  className?: string;
}

export default function FormApiError({
  dataId,
  message,
  nodeType,
  nodeCategory,
  docLink,
  errorCode,
  actionType,
  troubleshootLink,
  troubleshootUsingSBSDocs,
  troubleshootSBSDocsLink,
  onClick,
  className
}: FormApiErrorProps) {
  const errorWrapperRef = useRef<HTMLDivElement>(null);
  const analyticsTracker = useAnalyticsTracker();
  const actionTypeTroubleshoot = actionType === 'TROUBLESHOOT' ? 'Troubleshoot' : null;
  const appendDot =
    typeof message === 'string' ? message.trim().charAt(message.length - 1) !== '.' : false;
  const showReadDocumentation = !actionTypeTroubleshoot && docLink;
  const showTroubleShoot = !!actionTypeTroubleshoot;
  const nodeCategoryLowerCase = nodeCategory.toLowerCase();

  useLayoutEffect(() => {
    if (errorWrapperRef.current && 'scrollIntoView' in errorWrapperRef.current) {
      errorWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const getSupportDefaultMessage = () => {
    let supportMessage = NodeStrings.getConnectionErrorSupportMessage(
      capitalizeFirstLetter(nodeType),
      nodeCategoryLowerCase,
      message
    );

    if (actionType === 'TROUBLESHOOT' && troubleshootLink) {
      supportMessage = `${supportMessage} \n Error ${errorCode} (${__DOCS_CONFIG__.baseURL}${troubleshootLink})`;
    }

    return supportMessage;
  };

  const onSupportClick = () => {
    const action = getTrackingAction();

    if (action) {
      analyticsTracker.eventTrack({
        action,
        properties: { errorCode }
      });
    }
  };

  const onActionButtonClick = () => {
    if (actionType === 'TROUBLESHOOT') {
      onTroubleshootButtonClick();

      if (troubleshootUsingSBSDocs) {
        const trackingProps: any = {};
        if (nodeCategory === 'SOURCE') {
          trackingProps.sourceType = nodeType;
        } else if (nodeCategory === 'DESTINATION' || nodeCategory === 'WAREHOUSE') {
          trackingProps.destinationType = nodeType;
        } else {
          trackingProps.targetType = nodeType;
        }

        trackSetupGuideLink({
          setupGuideLink: troubleshootSBSDocsLink,
          section: 'node-form-error',
          label: actionType.toLowerCase(),
          properties: trackingProps
        });
      }
    }
  };

  const getTrackingAction = () => {
    switch (nodeCategory) {
      case 'SOURCE':
        return TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK;
      case 'DESTINATION':
      case 'WAREHOUSE':
        return TRACKER_DEST_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK;
      case 'TARGET':
        return TRACKER_TARGET_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK;
      default:
        return null;
    }
  };

  const onTroubleshootButtonClick = () => {
    trackSetupGuideLink({
      setupGuideLink: troubleshootLink,
      section: 'node-form-error',
      label: 'form-error-troubleshoot-click',
      medium: 'click',
      properties: {},
      scrollOffset: 0,
      highlightTitle: false
    });

    analyticsTracker.eventTrack({
      action: TRACKER_SOURCE_CONFIG_TROUBLESHOOT_BUTTON_CLICK,
      properties: { errorCode, category: nodeCategoryLowerCase, troubleshootLink }
    });
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'connectionError'), [dataId]);

  return (
    <HdPane
      variant={actionTypeTroubleshoot ? 'error-faded-2' : 'error'}
      icon='error-filled'
      onClick={onClick}
      className={className || ''}
    >
      <div
        ref={errorWrapperRef}
        data-id={dataIdGenerator('wrapper')}
        className={`${styles.content} ${actionTypeTroubleshoot ? styles.warning : ''}`}
      >
        <Typography
          data-id={dataIdGenerator('message')}
          variant='body2'
          component='span'
          className={styles.linkHolder}
        >
          {`${message}${appendDot ? '.' : ''}`}
        </Typography>

        <div className={`${styles.action} mt-2`}>
          {showTroubleShoot ? (
            <HdButton
              color='error'
              variation='outline'
              className='btn-error mr-2'
              size='sm'
              icon='troubleshoot'
              onClick={onActionButtonClick}
              dataId={dataIdGenerator('troubleshoot')}
            >
              {capitalizeFirstLetter(actionType)}
            </HdButton>
          ) : null}

          {showReadDocumentation ? (
            <>
              <HdDocLink
                docLink={docLink}
                section='connection_error'
                label='Read Documentation'
                dataId={dataIdGenerator('')}
                color='light'
              />

              <span className='text-medium'>{` or `}</span>
            </>
          ) : null}

          <HdSupportButton
            tag='button'
            color={actionTypeTroubleshoot ? 'error' : 'light'}
            icon='support'
            onClick={onSupportClick}
            dataId={dataIdGenerator('chat-support')}
            Component={HdLink}
            message={getSupportDefaultMessage()}
            nodeCategory={nodeCategory}
          >
            Chat with Support
          </HdSupportButton>
        </div>
      </div>
    </HdPane>
  );
}
