import { Directive, ElementRef, forwardRef, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, NgControl } from '@angular/forms';
import { BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, groupBy, map, mergeMap, tap } from 'rxjs/operators';
import { isElementAutoFilled } from '../../react/legacy-utils/form';


@Directive()
abstract class HDFormControlChangeNotifier implements OnInit, OnDestroy {
  private _children: HDFormControlChangeNotifier[] = [];

  private _isFormFieldFocusEvent$ = new BehaviorSubject<boolean>(false);
  private _formFieldFocus$ = new Subject<any>();

  get name() {
    return this._control.name;
  }

  get formControl() {
    return this._control.control;
  }

  constructor(
    public el: ElementRef,
    protected _control: { name: string | number | null, control: AbstractControl },
    protected _parent: HDFormControlChangeNotifier
  ) {
  }

  ngOnInit() {
    if (this._parent) {
      this._parent.registerChild(this);
    }
  }

  registerChild(child: HDFormControlChangeNotifier) {
    this._children.push(child);
  }

  deRegisterChild(child: HDFormControlChangeNotifier) {
    this._children.splice(this._children.indexOf(child), 1);
  }

  /**
   * Track changes done to the form field and its descendants by users,
   * when the form fields and its descendant form fields are focused or blurred.
   * This method is particularly useful, if we want to avoid tracking form value changes
   * on every key stroke, and filter out form value changes done due to chrome autofill.
   * This is particularly useful to track user's interaction with the form fields, in the form
   * of changes done.
   */
  onFormChangesByUser() {
    return merge(
      fromEvent(this.el.nativeElement, 'focusin'),
      fromEvent(this.el.nativeElement, 'focusout')
    ).pipe(
      delay(0),
      map((e: any) => {
        const eventTarget = e.target as HTMLElement;
        const controlNotifiers: HDFormControlChangeNotifier[] = this.getContainingControlNotifiers(eventTarget);

        this._isFormFieldFocusEvent$.next(e.type === 'focusin');

        return controlNotifiers;
      }),
      filter((controlNotifiers) => !!controlNotifiers.map(control => control.name).filter(name => !!name).length),
      map((controlNotifiers) => {
        const controlNotifier = controlNotifiers[controlNotifiers.length - 1];
        const formControl = controlNotifier.formControl;

        return {
          controlPath: controlNotifiers.map(control => control.name).filter(name => !!name).join('.'),
          control: formControl,
          controlValue: formControl.value,
          formControlElement: controlNotifier.el.nativeElement
        };
      }),
      groupBy(a => a.controlPath),
      mergeMap((group$) => {
        return group$.pipe(
          filter((a) => !isElementAutoFilled(a.formControlElement)),
          tap((a) => {
            this._formFieldFocus$.next(a);
          }),
          filter((a) => a.control.dirty),
          distinctUntilChanged((a, b) => {
            return a.controlValue === b.controlValue;
          })
        );
      }),
    );
  }

  onFormFieldFocus() {
    return this._formFieldFocus$.asObservable().pipe(
      filter(() => this._isFormFieldFocusEvent$.getValue())
    );
  }

  getContainingControlNotifiers(element: HTMLElement) {
    const containingChild = this._children.find((child) => {
      return (child.el.nativeElement as HTMLElement).contains(element);
    });

    if (containingChild) {
      return [ this, ...containingChild.getContainingControlNotifiers(element) ];
    }

    return [ this ];
  }

  ngOnDestroy() {
    if (this._parent) {
      this._parent.deRegisterChild(this);
    }
  }
}


@Directive({
  selector: '[ngModel], [formControl], [formControlName]',
  providers: [ {
    provide: HDFormControlChangeNotifier, useExisting: forwardRef(() => HDFormControlChangeNotifierDirective)
  } ]
})
export class HDFormControlChangeNotifierDirective extends HDFormControlChangeNotifier {
  constructor(
    public el: ElementRef,
    protected _control: NgControl,
    @Optional() @SkipSelf() protected _parent: HDFormControlChangeNotifier
  ) {
    super(el, _control, _parent);
  }
}

@Directive({
  selector: 'form:not([ngNoForm]):not([formGroup]),ng-form,[ngForm],[formGroup]',
  providers: [ {
    provide: HDFormControlChangeNotifier, useExisting: forwardRef(() => HDFormChangeNotifierDirective)
  } ]
})
export class HDFormChangeNotifierDirective extends HDFormControlChangeNotifier {
  constructor(
    public el: ElementRef,
    protected _control: ControlContainer,
    @Optional() @SkipSelf() protected _parent: HDFormControlChangeNotifier
  ) {
    super(el, _control, _parent);
  }
}
