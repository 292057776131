<ng-content
  select="[snackbarThumbnail]">
</ng-content>

<div class="snackbar-message">
  <ng-content></ng-content>

  <ng-content
    select="[snackbarLink]">
  </ng-content>

  <ng-content
   select="[hdDocLink]">
  </ng-content>
</div>

<ng-content
  select="[snackbarAction]">
</ng-content>

<button
  type="button"
  hd-icon-btn
  class="close-snackbar"
  (click)="hide()"
  *ngIf="canClose">
  <hd-icon name="close"></hd-icon>
</button>
