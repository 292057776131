export class ActivationStrings {
  static deleteActivationTitle = 'DELETE';
  static deleteActivationBody = 'Are you sure you want to delete this Activation?';
  static deleteActivationPositiveBtn = 'Yes, delete this Activation';
  static deleteActivationNegativeBtn = 'No';

  static reauthoriseWarningBody = 'Do you have the required permissions to read data from this Warehouse?' +
    ' Your Activations may fail to synchronize records if you do not have the required permissions.';

  static triggerInstantRunSuccessMessage = 'The request to run the Activation has been accepted. ' +
    'It might take a few seconds before it starts synchronizing data.';
  static triggerRetryRunSuccessMessage = 'The request to retry has been accepted. ' +
    'It might take a few seconds before it starts synchronizing the Records on Hold.';
}

export const ACTIVATION_DRAFT_QUERY_TOOLTIP_MESSAGE = 'The query is validated and saved but is not used in the current Activation.';
export const SYNC_BEHAVIOUR_DELETE_INFO_MESSAGE = 'This will delete records in the Target object.';
