import clsx from 'clsx';
import React, { forwardRef, useCallback } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdIcon from '../HdIcon';
import { BUTTON_TYPES, BUTTON_VARIATIONS } from './model';

export interface HdButtonProps {
  dataId: string;
  tag?: 'button' | 'a';
  type?: BUTTON_TYPES;
  icon?: string;
  iconClassName?: string;
  iconOnly?: boolean;
  iconSrc?: string;
  direction?: 'left' | 'right';
  edge?: 'start' | 'end';
  variation?: BUTTON_VARIATIONS;
  palette?: string;
  size?: 'sm' | 'md' | 'lg';
  active?: boolean;
  inlineText?: boolean;
  showProgress?: boolean;
  filledBg?: boolean;
  children?: any;
  actionRequiredIndicator?: boolean;
  [key: string]: any;
}

const HdButton = forwardRef<any, HdButtonProps>(
  (
    {
      dataId,
      tag,
      type,
      icon,
      iconClassName = '',
      iconOnly,
      iconSrc,
      direction,
      edge,
      variation,
      palette,
      size,
      inlineText,
      showProgress,
      actionRequiredIndicator,
      filledBg,
      active,
      children,
      ...props
    }: HdButtonProps,
    ref
  ) => {
    let color = palette;

    if (!palette) {
      if (!variation || variation === 'flat' || variation === 'faded') {
        color = 'primary';
      }

      if (variation === 'outline') {
        color = 'secondary';
      }

      if (variation === 'push') {
        color = 'info';
      }
    }

    let classes = ``;

    if (variation) {
      classes += `btn-${variation}`;
    } else {
      classes += 'btn';
    }

    classes += ` btn-${color}`;

    if (edge) {
      classes += ` btn-edge-${edge}`;
    }

    classes += ` btn-${size || 'md'}`;

    if (inlineText) {
      classes += ' with-inline-text';
    }

    if (showProgress) {
      classes += ' in-progress';
    }

    if (props.className) {
      classes += ` ${props.className}`;
    }

    if (actionRequiredIndicator) {
      classes += ' btn-action-required-indicator';
    }

    if (icon || iconSrc) {
      if (iconOnly) {
        classes += ` btn-thumbnail`;
      } else {
        const iconDirection = direction || 'left';
        classes += ` btn-thumbnail-${iconDirection}`;
      }
    }

    if (filledBg) {
      classes += ' filled-bg';
    }

    if (active) {
      classes += ' active';
    }

    const iconClassesComputed = clsx('thumb', iconClassName);

    const ButtonTag = tag || 'button';
    const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

    return (
      <ButtonTag
        type={type || 'button'}
        data-testid={`hd-${ButtonTag}`}
        {...props}
        data-id={dataIdGenerator(tag === 'a' ? 'link' : 'button')}
        className={classes}
        ref={ref}
      >
        {icon && direction !== 'right' ? (
          <HdIcon
            data-id={dataIdGenerator('icon')}
            dataId={dataIdGenerator('icon')}
            name={icon}
            className={iconClassesComputed}
          />
        ) : null}

        {iconSrc && direction !== 'right' ? (
          <img src={iconSrc} data-id={dataIdGenerator('img')} className='thumb' alt='icon' />
        ) : null}

        {children}

        {icon && direction === 'right' ? (
          <HdIcon
            data-id={dataIdGenerator('icon')}
            dataId={dataIdGenerator('icon')}
            name={icon}
            className={iconClassesComputed}
          />
        ) : null}

        {iconSrc && direction === 'right' ? (
          <img src={iconSrc} data-id={dataIdGenerator('img')} className='thumb' alt='icon' />
        ) : null}
      </ButtonTag>
    );
  }
);

export default HdButton;
