import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAccessTokenField } from './utils/access-token';


export const BIGCOMMERCE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'storeHash',
      fieldId: 'store-hash',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'storeHash', label: 'Store Hash', required: true },
      hint: 'The unique identifier for your store, retrieved from the API path of your store API account.',
      buildParamsKey: 'store_hash',
      sbsDocLink: '/sources/prod-analytics/bigcommerce/#obtaining-the-store-hash-and-access-token',
      validations: [
        {
          type: 'required'
        }
      ]
    },
    {
      ...getAccessTokenField({
        hint: 'The access token for Hevo to make API requests, retrieved from your store API account.',
        sbsDocLink: '/sources/prod-analytics/bigcommerce/#obtaining-the-store-hash-and-access-token',
      })
    }
  ]
];
