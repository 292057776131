import { useHistory } from '@useHistory/useHistoryCompat';
import { first } from 'rxjs/operators';
import { UserBlockDetails } from '../../app/core/models/trial-details';
import { TrialDetailsService } from '../../app/core/service/trial-details.service';
import useService from './useService';

export function useValidUserCheckGuard() {
  const trialDetailsService = useService(TrialDetailsService);
  const history = useHistory();

  const executeValidUserCheckGuard = () => {
    return trialDetailsService.shouldBlockUser$
      .pipe(first())
      .toPromise()
      .then(
        (userBlockDetails: UserBlockDetails) => {
          if (!userBlockDetails.blockUser) {
            history.replace('/');
          }
          return userBlockDetails.blockUser;
        },
        () => {
          return true;
        }
      );
  };

  return {
    executeValidUserCheckGuard
  };
}
