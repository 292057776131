import { ComponentType } from '@angular/cdk/overlay';
import { TemplateRef } from '@angular/core';

export enum SnackbarType {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error'
}

export interface AlertSnackbarStyle {
  iconClass: string;
  type: SnackbarType;
}

export interface AlertSnackbarAction {
  actionName: string;
  actionDisabled?: boolean;
  errorType?: string;
  actionDisabledToolTip?: string;
  actionCallback: () => void;
}

export interface DocLink {
  text: string;
  url: string;
}

export interface AlertSnackbarData {
  type?: SnackbarType;
  message?: string;
  messageTemplate?: TemplateRef<any>;
  messageComponent?: ComponentType<any>;
  messageTemplateContext?: any;
  messageComponentData?: any;
  canClose?: boolean;
  inline?: boolean;
  btnActions?: AlertSnackbarAction[];
  linkActions?: AlertSnackbarAction[];
  docLink?: DocLink;
}
