import { SidelineMessageAction } from './models/sideline-message';

export const DETACHED_SIDELINE_SUMMARY_ID = -1;

export const TRACKING_ON_HOVER_REPLAY_PROGRESS = '[Source Object Sideline] on hover replay progress';
export const SIDELINE_MESSAGES_POLL_INTERVAL = 10000;
export const TRACKING_ON_HOVER_EAGER_EVENTS_COUNT = '[Source Object Sideline] on hover eager events count';

export const TRACKING_DISABLED_SIDELINE_ACTIONS = {
  [SidelineMessageAction.DONE]: '[Source Object Sideline] disabled done event click',
  [SidelineMessageAction.CREATE_MAPPING]: '[Source Object Sideline] disabled create mapping click',
  [SidelineMessageAction.FIX_MAPPING]: '[Source Object Sideline] disabled fix mapping click',
  [SidelineMessageAction.SKIP_OBJECT]: '[Source Object Sideline] disabled skip object click',
  [SidelineMessageAction.VIEW_DETAILS]: '[Source Object Sideline] disabled view details click',
  [SidelineMessageAction.MAP_SCHEMA]: '[Source Object Sideline] disabled schema map click',
  [SidelineMessageAction.REPLAY]: '[Source Object Sideline] disabled replay schema click',
  [SidelineMessageAction.SKIP]: '[Source Object Sideline] disabled skip schema click',
  [SidelineMessageAction.VIEW_SAMPLE]: '[Source Object Sideline] disabled show sample event click',
  [SidelineMessageAction.INCLUDE_OBJECT]: '[Source Object Sideline] disabled include object click'
};
