import React from 'react';
import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import {
  CronDay,
  PolicyTypeEnum,
  CronTime,
  CronType
} from '../../../../app/dialog/execution-policy-dialog/models';
import {
  CRON_DAYS,
  CRON_OPTIONS,
  CRON_TYPE_FIXED_INTERVAL,
  CRON_TYPE_WEEKLY,
  FREQUENCY_TYPE_HOURS
} from '../../../../app/dialog/execution-policy-dialog/constants';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import styles from '../styles.module.scss';
import { getDaysPlaceholder } from '../utils';
import { HdFormikDropDown } from '../../../components/FormikElements';
import FrequencyForm from './FrequencyForm';
import HdFormikTimeSelector from '../../../components/FormikElements/HdFormikTimeSelector';


export default function CronForm({ policyObj, setPolicyObj }) {
  const { values, setFieldValue, resetForm } = useFormikContext<{
    selectedCustomOption: CronType;
    cronTime: CronTime;
    cronDays: CronDay[];
  }>();

  const onCronOptionClick = (selectedOption: CronType) => {
    resetForm();
    setFieldValue('selectedCustomOption', selectedOption);

    if (selectedOption.value === CRON_TYPE_FIXED_INTERVAL.value) {
      setPolicyObj((prev) => ({
        ...prev,
        type: PolicyTypeEnum.FREQUENCY,
        frequencyType: FREQUENCY_TYPE_HOURS,
        cronType: selectedOption,
        frequencyValue: null
      }));
    } else {
      setPolicyObj((prev) => ({
        ...prev,
        type: PolicyTypeEnum.CRON,
        cronType: selectedOption,
        cronTimeList: [{
          hour: null,
          minute: null
        }],
        cronDays: []
      }));
    }
  };

  const renderFrequencyDropdown = values.selectedCustomOption?.value === CRON_TYPE_FIXED_INTERVAL.value;

  const renderWeeklyFields = values.selectedCustomOption?.value === CRON_TYPE_WEEKLY.value;

  return (
    <Grid>
      <Grid item className='center-flex-row'>
        <HdFormControl className={`${styles.inputBox} mb-0`}>
          <Field
            name='selectedCustomOption'
            onChangeEventHandler={onCronOptionClick}
            options={CRON_OPTIONS}
            component={HdFormikDropDown}
            valueAccessor='value'
            displayAccessor='name'
            selectMode
            label='Run On'
            hideClearable
            required
          />
        </HdFormControl>

        {renderFrequencyDropdown ? (
          <FrequencyForm
            className={`${ styles.inputBox } ml-2`}
            setPolicyObj={setPolicyObj}
            policyObj={policyObj}
          />
        ) : (
          <>
            {renderWeeklyFields && (
              <HdFormControl
                className={`${ styles.inputBox } ml-2`}
              >
                <Field
                  name='cronDays'
                  onChangeEventHandler={(selected) => {
                    setFieldValue('cronDays', selected);
                    setPolicyObj((prev) => ({
                      ...prev,
                      cronDays: selected
                    }));
                  }}
                  options={CRON_DAYS}
                  component={HdFormikDropDown}
                  valueAccessor='name'
                  multiple
                  placeholder='On'
                  label='On'
                  displayValueFn={(selected) => getDaysPlaceholder(selected)}
                  hideClearable
                  required
                />
              </HdFormControl>
            )}

            <HdFormControl className={`${ styles.inputBox } mb-0 ml-2`}>
              <Field
                name='cronTime'
                onChangeEventHandler={(time: CronTime) => {
                  setPolicyObj((prev) => ({
                    ...prev,
                    cronTimeList: [ time ]
                  }));
                }}
                component={HdFormikTimeSelector}
                label='at'
                helperText='Timezone: UTC'
                required
              />
            </HdFormControl>
          </>
        )}
      </Grid>
    </Grid>
  );
}
