import React from 'react';
import { HdCheckbox } from '../../UIElements';
import styles from './styles.module.scss';
import { dataIds } from '.';

interface ListHeaderProps {
  selectAll: (checked: boolean) => void;
  selectedCount: number;
  totalCount: number;
}

export function ListHeader({ selectAll, selectedCount, totalCount }: ListHeaderProps) {
  return (
    <>
      <div className={styles.pipelineDetailsCell}>
        <HdCheckbox
          dataId={dataIds.selectAll}
          onChange={selectAll}
          indeterminate={selectedCount !== totalCount && !!selectedCount}
          checked={!!selectedCount}
        />
        <div className=' ml-2 text-secondary'>
          Select Pipelines ({selectedCount} of {totalCount})
        </div>
      </div>

      <div className={styles.genericCell}>
        <div className='text-secondary'>Pipeline Status</div>
      </div>

      <div className={styles.genericCell}>
        <div className='text-secondary'>Ingestion Frequency</div>
      </div>

      <div className={styles.genericCell}>
        <div className='text-secondary'>Load Frequency</div>
      </div>

      <div className={styles.genericCell}>
        <div className='text-secondary'>Sync Schedule</div>
      </div>

      <div className={styles.genericCell} />
    </>
  );
}
