import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const HIVE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPIKeyField({
        hint: 'Enter the API key obtained from the Edit profile → API info → Copy the API key.',
      })
    }
  ],
  [
    {
      key: 'userId',
      fieldId: 'user-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'userId', label: 'User ID', required: true },
      hint: 'Enter the User ID obtained from the Edit profile → API info → Copy the User ID.',
      buildParamsKey: 'user_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      key: 'workspaceId',
      fieldId: 'workspace-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'workspaceId', label: 'Workspace ID', required: true },
      hint: 'Enter the Workspace ID obtained from the Edit profile → API info → Copy the Workspace ID.',
      buildParamsKey: 'workspace_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
