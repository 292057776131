import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PlaygroundComponent } from './playground.component';
import { ReactPlaygroundComponent } from './react-playground.component';


const routes: Routes = [
  {
    path: 'playground',
    component: PlaygroundComponent
  },
  {
    path: 'react-playground',
    component: ReactPlaygroundComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class PlaygroundRoutingModule { }
