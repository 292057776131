import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TeamSettingsService } from '../service/team-settings.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowGuard implements CanActivate {

  protected constructor(
    private _teamSettingsService: TeamSettingsService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> | boolean {
    if (this._teamSettingsService.isWorkflowsEnabled()){
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
