import { useCallback, useState } from 'react';

const useForceUpdate = () => {
  const [, dispatch] = useState(Object.create(null));
  return useCallback(() => {
    dispatch(Object.create(null));
  }, []);
};

export default useForceUpdate;
