<div
  *ngIf="showDivider"
  class="owl-dt-timer-divider"
  aria-hidden="true"></div>

<label class="owl-dt-timer-content">
  <input
    class="owl-dt-timer-input"
    maxlength="2"
    [value]="displayValue | numberFixedLen : 2"
    (input)="handleInputChange(valueInput.value)"
    #valueInput>
  <span class="owl-hidden-accessible">{{inputLabel}}</span>
</label>

<div class="owl-dt-timer-box-control-buttons">
  <button
    class="owl-dt-control-button owl-dt-control-arrow-button"
    type="button"
    tabindex="-1"
    [disabled]="upBtnDisabled"
    [attr.aria-label]="upBtnAriaLabel"
    (click)="upBtnClicked()">
    <span
      class="owl-dt-control-button-content"
      tabindex="-1">
      <span class="hevo-icon hevo-solid-dropdown icon-size-1"></span>
    </span>
  </button>

  <button
    class="owl-dt-control-button owl-dt-control-arrow-button"
    type="button"
    tabindex="-1"
    [disabled]="downBtnDisabled"
    [attr.aria-label]="downBtnAriaLabel"
    (click)="downBtnClicked()">
    <span
      class="owl-dt-control-button-content"
      tabindex="-1">
      <span class="hevo-icon hevo-solid-dropdown icon-size-1"></span>
    </span>
  </button>
</div>
