import React from 'react';

import Years from './MultiYearsView/Years';
import { TitleGroupProps } from './shared/interfaces';

export default function DecadeView<T>(props: TitleGroupProps<T>) {
  function renderYears() {
    return <Years {...props} />;
  }

  return <div className='react-calendar__decade-view'>{renderYears()}</div>;
}
