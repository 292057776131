<div
  hdRbac
  #rbacUser="hdRbac"
  class=center-flex-row>
  <mat-slide-toggle
    class="mr-3"
    [disabled]="rbacUser.isActionBlocked(rbacUser.rbacPermissions.PIPELINE_EDIT)"
    [tooltip]="rbacUser.rbacDisabledTooltip"
    [tooltipDisabled]="!rbacUser.isActionBlocked(rbacUser.rbacPermissions.PIPELINE_EDIT)"
    [checked]="status === autoMappingStatuses.MAP_ALL"
    (change)="onAutoMappingStatusChange($event.checked)">
  </mat-slide-toggle>

  <span class="text-medium mr-2">
    Auto Mapping {{ status === autoMappingStatuses.MAP_ALL ? 'Enabled' : 'Disabled' }}:
  </span>

  <ng-container *ngIf="status === autoMappingStatuses.MAP_ALL">
    Hevo will automatically map all Event Types in this Pipeline.
  </ng-container>

  <ng-container  *ngIf="status !== autoMappingStatuses.MAP_ALL">
    Enable Auto Mapping to automatically map all Event Types in this Pipeline.
  </ng-container>

  <a
    hdLink
    icon="new-window"
    class="ml-1"
    [hdDocLink]="autoMappingDocLink"
    section="automapping_widget">
    What is Auto Mapping?
  </a>
</div>

<a
  *ngIf="getSchemaDocLink()"
  hdButton
  size="sm"
  variation="outline"
  [hdDocLink]="getSchemaDocLink()"
  section="[Auto Mapping Widget] view_data_schema_button_click">
  View {{ sourceDisplayName }} Schema
</a>
