import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthPagesReactComponent } from './auth-pages-react.component';

const routes: Routes = [
  {
    path: 'login',
    component: AuthPagesReactComponent
  },
  {
    path: 'login/code/:code',
    component: AuthPagesReactComponent
  },
  {
    path: 'forgot-password',
    component: AuthPagesReactComponent
  },
  {
    path: 'emp-login-ac-select',
    component: AuthPagesReactComponent
  },
  {
    path: 'reset',
    component: AuthPagesReactComponent
  },
  {
    path: 'accept-invite',
    component: AuthPagesReactComponent
  },
  {
    path: 'setup-account',
    component: AuthPagesReactComponent
  },
  {
    path: 'verify-email',
    component: AuthPagesReactComponent
  },
  {
    path: 'saml-login',
    component: AuthPagesReactComponent
  },
  {
    path: 'google-login',
    component: AuthPagesReactComponent
  },
  {
    path: 'tfa/otp',
    component: AuthPagesReactComponent
  },
  {
    path: 'tfa/qr',
    component: AuthPagesReactComponent
  },
  {
    path: 'tfa/recovery-codes',
    component: AuthPagesReactComponent
  },
  {
    path: 'rest-api/oauth',
    component: AuthPagesReactComponent
  },
  {
    path: 'setup-persona',
    component: AuthPagesReactComponent
  },
  {
    path: 'subscribe-marketplace',
    component: AuthPagesReactComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AuthRoutingModule { }
