// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.formWrapper_lnFOA {\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n  position: relative;\n  padding-top: 3px;\n}\n.formWrapper_lnFOA .formCard_a68oa {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}\n.formWrapper_lnFOA .formCard_a68oa .infoWrapper_cHncw {\n  margin-top: 10.4px;\n  margin-bottom: 20px;\n}\n.formWrapper_lnFOA .formCard_a68oa .fixedWrapper_zujUW {\n  z-index: 10;\n  position: sticky;\n  bottom: 0px;\n  padding-bottom: 20px;\n  padding-top: 32px;\n  background: linear-gradient(to top, var(--surface-bg-color), var(--surface-bg-color) 52%, var(--surface-bg-color-0));\n}\n.formWrapper_lnFOA.backdrop_lhpid::after {\n  content: \" \";\n  background: var(--surface-bg-60-color);\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  padding: 10.4px 0px;\n  top: 0;\n  left: 0;\n  z-index: 4;\n}\n.formWrapper_lnFOA .hideOverlay_hOXqW {\n  z-index: 5;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": "formWrapper_lnFOA",
	"formCard": "formCard_a68oa",
	"infoWrapper": "infoWrapper_cHncw",
	"fixedWrapper": "fixedWrapper_zujUW",
	"backdrop": "backdrop_lhpid",
	"hideOverlay": "hideOverlay_hOXqW"
};
export default ___CSS_LOADER_EXPORT___;
