/* eslint-disable default-param-last */
import { ActionType, Action, ReduxState } from '../actions/actionTypes';

const INITIAL_STATE = {
  theme: '',
  isLoggedIn: false,
  userData: null
};

export default (state: ReduxState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ActionType.CHANGE_THEME: {
      return {
        ...state,
        theme: action.payload
      };
    }

    case ActionType.LOGOUT_USER: {
      return {
        ...state,
        isLoggedIn: false,
        userData: null
      };
    }

    case ActionType.LOGIN_USER: {
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload
      };
    }

    default:
      return state;
  }
};
