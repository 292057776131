import React, { useEffect } from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import useService from '../../../hooks/useService';
import { RbacService } from '../../../../app/core/service/rbac.service';
import { RbacPermissions } from '../../../../app/core/models/user';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import {
  TRACKER_TRIAL_EXPIRY_BANNER_CLOSE,
  TRACKER_TRIAL_EXPIRY_BANNER_CLOSE_CLICK,
  TRACKER_TRIAL_EXPIRY_BANNER_OPEN,
  TRACKER_TRIAL_EXPIRY_BANNER_SUPPORT_CLICK,
  TRACKER_TRIAL_EXPIRY_SETUP_BILLING_CLICK
} from './constants';
import {
  TRIAL_PERIOD_REMINDER_MSG,
  TRIAL_PERIOD_REMINDER_MSG_IF_OWNER
} from '../../../../app/core/constants';
import { getDaysFromTimestamp, getHoursFromTimestamp } from '../../../legacy-utils/date';
import { Banner } from '../../../components/Banner';
import { BannerColorPalette } from '../../../../app/banner/interface';
import { HdButton, HdRbacButton } from '../../../components/UIElements';
import { HdSupportButton } from '../../../components/SupportButton';

export default function TrialExpiryReminderBanner(props) {
  const rbacService = useService(RbacService);
  const history = useHistory();
  const isOwner = rbacService.isOwnerOrHasPermission(RbacPermissions.BILLING_EDIT);
  const analyticsTracker = useAnalyticsTracker();

  useEffect(() => {
    analyticsTracker.eventTrack({
      action: TRACKER_TRIAL_EXPIRY_BANNER_OPEN
    });
  }, []);

  const onClose = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_TRIAL_EXPIRY_BANNER_CLOSE_CLICK
    });

    close();
  };

  const close = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_TRIAL_EXPIRY_BANNER_CLOSE
    });
    props.close();
  };

  const setupBilling = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_TRIAL_EXPIRY_SETUP_BILLING_CLICK
    });

    history.push('/payment/initial-setup');

    close();
  };

  const supportClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_TRIAL_EXPIRY_BANNER_SUPPORT_CLICK
    });
  };

  const getTrialReminderSnackbarMessage = () => {
    const message = isOwner ? TRIAL_PERIOD_REMINDER_MSG_IF_OWNER : TRIAL_PERIOD_REMINDER_MSG;

    const daysLeft = getDaysFromTimestamp(props.data.trialPeriodLeft);
    if (daysLeft > 1) {
      return `${Math.ceil(daysLeft)} days${message}`;
    }

    const hoursLeft = getHoursFromTimestamp(props.data.trialPeriodLeft);
    if (hoursLeft > 1) {
      return `${Math.ceil(hoursLeft)} hours${message}`;
    }
    return `Less than 1 hour${message}`;
  };

  return (
    <Banner
      canClose
      open
      dataId='trial-expiry-reminder-banner'
      color={BannerColorPalette.DEFERRED}
      onClose={onClose}
      icon='time'
      Title={<span> Trial Ending Soon!</span>}
    >
      <span>{getTrialReminderSnackbarMessage()}</span>

      <HdRbacButton
        dataId='trial-expiry-reminder-banner-setup-billing'
        disabled={!isOwner}
        disabledToolTipContent='You need to have owner privileges to take this action'
        rbacPermission={RbacPermissions.BILLING_EDIT}
        variation='outline'
        size='sm'
        palette='light'
        inlineText
        className='ml-2'
        onClick={setupBilling}
      >
        Set up Billing
      </HdRbacButton>

      <HdSupportButton
        dataId='trial-expiry-reminder-banner-chat-support'
        size='sm'
        variation='faded'
        palette='light'
        inlineText
        className='ml-2'
        onClick={supportClick}
        Component={HdButton}
        message='Hey can you help me with setting up billing details?'
      >
        Chat with Support
      </HdSupportButton>
    </Banner>
  );
}
