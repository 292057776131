import { Component, ElementRef, OnInit } from '@angular/core';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { JobMonitoringDashboard } from '../../../react/containers/job-monitoring';

@Component({
  selector: 'job-monitoring-react',
  template: '',
  providers: [ BridgeService ]
})
export class JobMonitoringReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      JobMonitoringDashboard,
      this._el.nativeElement,
      {}
    );
  }
}
