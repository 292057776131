import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HdA11yModule } from '../a11y/a11y.module';
import { HdMenuItemIconComponent } from './hd-menu-item-icon/hd-menu-item-icon.component';
import { HdMenuItemComponent } from './hd-menu-item/hd-menu-item.component';
import { HdMenuTriggerDirective } from './hd-menu/hd-menu-trigger.directive';
import { HdMenuComponent } from './hd-menu/hd-menu.component';

const DIRECTIVES = [
  HdMenuComponent,
  HdMenuItemComponent,
  HdMenuTriggerDirective,
  HdMenuItemIconComponent
];

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    HdA11yModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
  providers: []
})
export class MenuModule {
}
