import React from 'react';
import { HdPane } from '../../../components/UIElements';

export default function ExecutionPolicyMessage({
  className = '',
  messagePrefix,
  policyMessage
}) {
  return (
    <HdPane
      icon='time'
      variant='info-faded'
      className={className}
    >
      <span className='text-body-1 text-secondary'>{ messagePrefix }</span>&nbsp;
      <span className='text-body-2'>{ policyMessage }</span>
    </HdPane>
  );
}
