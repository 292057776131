import { Typography } from '@mui/material';
import React from 'react';
import HdSetupGuideLink from '../../../../../components/UIElements/HdSetupGuideLink';
import {
  hostUrlLabel,
  hostUrlSection,
  postgresConfigureDocLink,
  redshiftConfigureDocLink,
} from './constants';


export const getHostName = (destinationTypeIdentifier, destinationTypeTrackingProps) => {
  let hostHelperText;
  if (destinationTypeIdentifier === 'REDSHIFT') {
    hostHelperText = (
      <Typography variant='caption'>
        Eg: examplecluster.abc123xyz789.us-west-2.redshift.amazonaws.com.{'  '}
        <HdSetupGuideLink
          setupGuideLink={redshiftConfigureDocLink}
          section={hostUrlSection}
          label={hostUrlLabel}
          properties={destinationTypeTrackingProps}
          scrollOffset={-50}
          highlightTitle
          size='sm'
          icon='right'
          dataId='redshift-host-url'
        >
          Need help?
        </HdSetupGuideLink>
      </Typography>
    );
  }

  if (destinationTypeIdentifier === 'POSTGRES') {
    hostHelperText = (
      <Typography variant='caption'>
        Enter your IP address/DNS Server.{'  '}
        <HdSetupGuideLink
          setupGuideLink={postgresConfigureDocLink}
          section={hostUrlSection}
          label={hostUrlLabel}
          properties={destinationTypeTrackingProps}
          scrollOffset={-50}
          highlightTitle
          size='sm'
          icon='right'
          dataId='postgres-host-url'
        >
          Need help?
        </HdSetupGuideLink>
      </Typography>
    );
  }

  return hostHelperText;
};
