import React, { useState } from 'react';

import TileGroup from '../TileGroup';
import Year from './Year';

import { TitleGroupProps } from '../shared/interfaces';
import { calendarViews } from '../shared/const';
import { HdIconButton } from '../../../HdIconButton';
import { HdIcon } from '../../../index';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';

export default function Years<T>(props: TitleGroupProps<T>) {
  const { activeStartDate } = props;
  const { dateTimeAdapter } = useDateTimeAdapter<T>();

  const [year, setYear] = useState(dateTimeAdapter.getYear(activeStartDate));
  /**
   * We always show 21( 7 * 3 ) years in the view
   */
  const start = year - (year % (7 * 3));
  const end = start + 20;

  return (
    <div className='d-flex'>
      <div className='center-flex-row'>
        <HdIconButton onClick={() => setYear(year - 21)} dataId='date-picker-years-prev'>
          <HdIcon name='left-arrow' />
        </HdIconButton>
      </div>
      <div className='w-100'>
        <div className='react-calendar__control-content'>
          <span className='react-calendar__multi-years-label'>
            {start}
            {' ~ '}
            {end}
          </span>
        </div>
        <TileGroup
          {...props}
          rowClassName='react-calendar__multi-year-row'
          cellRatio={3 / 7}
          className='react-calendar__multi-year-view__years'
          dateTransform={(renderYear: number) =>
            dateTimeAdapter.createDate(
              renderYear,
              dateTimeAdapter.getMonth(activeStartDate),
              1,
              dateTimeAdapter.getHours(activeStartDate),
              dateTimeAdapter.getMinutes(activeStartDate),
              dateTimeAdapter.getSeconds(activeStartDate)
            )
          }
          dateType={calendarViews.YEAR}
          end={end}
          start={start}
          tile={Year}
        />
      </div>
      <div className='center-flex-row'>
        <HdIconButton onClick={() => setYear(year + 21)} dataId='date-picker-years-next'>
          <HdIcon name='right-arrow' />
        </HdIconButton>
      </div>
    </div>
  );
}
