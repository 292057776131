import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule
    ],
    declarations: [
      TagComponent
    ],
    exports: [
      TagComponent
    ]
})
export class TagModule {

}
