import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { HdKeyBadge } from './key-badge';

@Component({
  selector: 'hd-key-badge',
  templateUrl: './key-badge.component.html',
  styleUrls: ['./key-badge.component.scss']
})
export class KeyBadgeComponent implements OnInit {
  @Input() tooltipDisabled = false;
  @Input() keys: HdKeyBadge[] = [];
  @HostBinding('class.shrink') @Input() shrink = false;

  constructor() { }

  ngOnInit() {
  }

}
