import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'hd-message-pane',
  templateUrl: './message-pane.component.html',
  styleUrls: [ './message-pane.component.scss' ],
})
export class MessagePaneComponent {
  @HostBinding('class.visible') visible = true;
  @Input() canHide = true;
  @Output() onHide = new EventEmitter<void>();

  constructor() {}

  hide() {
    if (!this.canHide) {
      return;
    }
    this.visible = false;
    this.onHide.emit();
  }

  resetVisibility() {
    this.visible = true;
  }
}
