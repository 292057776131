// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n/*\nTheme variables\n */\n.dialog_i97bI {\n  z-index: 1065 !important;\n}\n\n.hide__g1FV {\n  position: absolute !important;\n  top: 20px;\n  left: 20px;\n}\n\n.objectsSelectionContainer_JrdoI {\n  max-width: 710px;\n  margin: auto;\n  padding: 20px;\n}\n\n.destGearIcon_BC7DU {\n  position: absolute;\n  bottom: -4px;\n  right: -4px;\n  border: solid 1px var(--primary-color);\n  padding: 2px;\n  border-radius: 4px;\n  background-color: transparent;\n  font-size: 12px;\n}\n\n.warningList_Bkyeb {\n  padding-left: 12px;\n  line-height: 1.5;\n}\n.warningList_Bkyeb li {\n  list-style-type: none;\n  position: relative;\n}\n.warningList_Bkyeb li::before {\n  content: \"•\";\n  position: absolute;\n  left: -10px;\n  font-size: 12px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "dialog_i97bI",
	"hide": "hide__g1FV",
	"objectsSelectionContainer": "objectsSelectionContainer_JrdoI",
	"destGearIcon": "destGearIcon_BC7DU",
	"warningList": "warningList_Bkyeb"
};
export default ___CSS_LOADER_EXPORT___;
