import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { COOKIE_VARIABLES } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { CENTRAL_CLUSTER_ID } from '../models/user';
import { AuthService } from './auth.service';
import { RxRequestService } from './rx-request.service';
import { UserService } from './user.service';

declare const __COOKIE_DOMAIN__: string;

export interface ClusterMetadata {
  display_name: string;
  url: string;
  current_cluster: boolean;
  id: string;
  provider: string;
  is_primary_cluster: false;
}

@Injectable({ providedIn: 'root' })
export class MultiRegionService {
  constructor(
    private _appConfig: AppConfig,
    private _authService: AuthService,
    private _userService: UserService,
    private _rxRequestService: RxRequestService,
    private _cookieService: CookieService
  ) {
    this.setupLastVisitedCookieSubscription();
  }

  multiRegionURL = this._appConfig.getSecurityServiceURL() + '/multi-region';

  private _multiRegionSubject$ = new BehaviorSubject<ClusterMetadata[]>(null);

  getMultiRegionsValue() {
    return this._multiRegionSubject$.getValue();
  }

  setupLastVisitedCookieSubscription() {
    this._multiRegionSubject$.pipe(
      skip(1)
    ).subscribe(() => {
      if (this.isMultiRegionUser()) {
        this._cookieService.set(
          COOKIE_VARIABLES.LAST_VISITED_REGION,
          this.getSelectedRegionValue()?.id, 100000, '/', __COOKIE_DOMAIN__
        );
      }
    });
  }

  getSelectedRegionValue() {
    return this._multiRegionSubject$.getValue()?.find(region => region.current_cluster);
  }

  isMultiRegionUser() {
    return this._userService.getUser().clusterId === CENTRAL_CLUSTER_ID;
  }

  getMultiRegions(showLoading = true) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading,
        showErrorMsg: false,
        showSuccessMsg: false
      }
    };

    return this._rxRequestService.get(this.multiRegionURL, options).pipe(
      tap((res) => {
        this._multiRegionSubject$.next(res.data?.multi_region);
      })
    );
  }

  getRegionsGroupedByProvider() {
    const regionsGroupedByProvider: {[provider: string]: ClusterMetadata[]} = {};

    this.getMultiRegionsValue().forEach((region) => {
      if (!regionsGroupedByProvider[region.provider]) {
        regionsGroupedByProvider[region.provider] = [];
      }

      regionsGroupedByProvider[region.provider].push(region);
    });

    return Object.values(regionsGroupedByProvider).map(regionGroup => ({
      provider: regionGroup[0].provider,
      options: regionGroup
    }));
  }
}
