import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { BehaviorSubject } from 'rxjs';
import { SetupGuideGoToSection, SetupGuideLinkTrackingParams } from '../nodes-settings/setup-guide-link';


export let _setupGuideCommService: SetupGuideCommService;

@Injectable({
  providedIn: 'root'
})
export class SetupGuideCommService {
  private _goToSection$ = new BehaviorSubject<SetupGuideGoToSection>({
    link: null
  });

  goToSection$ = this._goToSection$.asObservable();

  constructor(private _angulartics2: Angulartics2) {
    _setupGuideCommService = this;
  }

  goToSection(
    link: string,
    trackingParams?: SetupGuideLinkTrackingParams,
    scrollOffset?: number,
    highlightTitle?: boolean,
  ): void {
    const scrollStrategy = trackingParams.scrollStrategy || 'auto';

    this._angulartics2.eventTrack.next({
      action: '[SBS Docs] navigate to section',
      properties: trackingParams ? {
        sourceSection: trackingParams.sourceSection,
        medium: trackingParams.medium,
        label: trackingParams.label,
        scrollStrategy: scrollStrategy,
        ...(trackingParams.properties || {})
      } : null
    });

    this._goToSection$.next({
      link,
      scrollStrategy,
      scrollOffset,
      highlightTitle,
    });
  }
}
