// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dialogContent__FLOk {\n  margin-top: 60px !important;\n  width: 780px !important;\n  max-height: 570px;\n  height: calc(100% - 120px);\n  display: flex;\n  flex-direction: column;\n}\n\n.dialogBody__xBp1 {\n  overflow-y: auto;\n  flex: 1;\n}\n\n.dialog-header__Spma {\n  flex-shrink: 0;\n}\n\n.shortcutKeyCombo___zui {\n  display: flex;\n  align-items: center;\n}\n\n.shortcutKey__EWFd {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 25px;\n  height: 25px;\n  flex-shrink: 0;\n  border-radius: 6px;\n  border: solid 1px var(--divider-color);\n  margin-right: 12px;\n  color: var(--text-default-color);\n}\n.shortcutKey__EWFd:last-child {\n  margin-right: 0;\n}\n\n.shortcut_ZUjTl {\n  margin-bottom: 16px;\n  align-items: center;\n}\n.shortcut_ZUjTl:last-child {\n  margin-bottom: 0;\n}\n\n.shortcutGroup_Lqor_ {\n  margin-bottom: 32px;\n}\n.shortcutGroup_Lqor_:last-child {\n  margin-bottom: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": "dialogContent__FLOk",
	"dialogBody": "dialogBody__xBp1",
	"dialog-header": "dialog-header__Spma",
	"shortcutKeyCombo": "shortcutKeyCombo___zui",
	"shortcutKey": "shortcutKey__EWFd",
	"shortcut": "shortcut_ZUjTl",
	"shortcutGroup": "shortcutGroup_Lqor_"
};
export default ___CSS_LOADER_EXPORT___;
