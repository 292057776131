import React, { useMemo } from 'react';
import { Field } from 'formik';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import { HdFormikTextField } from '../../../components/FormikElements';
import { FREQUENCY_TYPE_HOURS } from '../../../../app/dialog/execution-policy-dialog/constants';
import styles from '../styles.module.scss';

export default function FrequencyForm({ policyObj, setPolicyObj, className = '' }) {
  const getFrequencyDisplayText = (frequency: any): string => {
    if (frequency === Infinity) {
      return undefined;
    }

    if (!frequency || frequency < FREQUENCY_TYPE_HOURS.minSeconds) {
      return '1 hr';
    }

    const displayFrequency = frequency / FREQUENCY_TYPE_HOURS.minSeconds;
    if (displayFrequency > 1) {
      return `${displayFrequency} hrs`;
    }

    return `${displayFrequency} hr`;
  };

  const helperText = useMemo(() => {
    let text = '';
    if (getFrequencyDisplayText(policyObj.minFrequency)) {
      text += `Minimum ${getFrequencyDisplayText(policyObj.minFrequency)}`;
    }
    if (getFrequencyDisplayText(policyObj.maxFrequency)) {
      text += ` and Maximum ${getFrequencyDisplayText(policyObj.maxFrequency)}`;
    }

    return text;
  }, [policyObj.minFrequency, policyObj.maxFrequency]);

  return (
    <HdFormControl className={`mb-0 ${styles.frequencyForm} ${className}`}>
      <Field
        name='frequencyValue'
        component={HdFormikTextField}
        label='Hours'
        helperText={helperText}
        required
        min={1}
        type='number'
        onChange={e => {
          const { valueAsNumber, value } = e.target;
          setPolicyObj(prev => ({
            ...prev,
            frequencyType: FREQUENCY_TYPE_HOURS,
            frequencyValue: Number.isNaN(valueAsNumber) ? value : valueAsNumber
          }));
        }}
      />
    </HdFormControl>
  );
}
