// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.scrollToTopButtonWrapper_S_w1U {\n  position: absolute;\n  bottom: 20px;\n  right: 20px;\n  z-index: 970;\n}\n.scrollToTopButtonWrapper_S_w1U .scrollToTopButton_dsWGP {\n  padding: 4px;\n  color: var(--light-text);\n  background-color: var(--primary-color);\n  border: none;\n  border-radius: 50%;\n  outline: none;\n  display: flex;\n  cursor: pointer;\n  opacity: 1 !important;\n  box-shadow: var(--shadow-default);\n  transition: all 0.2s ease;\n}\n.scrollToTopButtonWrapper_S_w1U .scrollToTopButton_dsWGP:hover, .scrollToTopButtonWrapper_S_w1U .scrollToTopButton_dsWGP:focus {\n  background-color: var(--primary-dark-color) !important;\n  box-shadow: var(--shadow-elevated-primary) !important;\n}\n.scrollToTopButtonWrapper_S_w1U .scrollToTopButton_dsWGP .icon_uhlkV {\n  color: var(--light-text);\n}\n.scrollToTopButtonWrapper_S_w1U.hidden_HpI5g {\n  display: none;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollToTopButtonWrapper": "scrollToTopButtonWrapper_S_w1U",
	"scrollToTopButton": "scrollToTopButton_dsWGP",
	"icon": "icon_uhlkV",
	"hidden": "hidden_HpI5g"
};
export default ___CSS_LOADER_EXPORT___;
