import { Injectable } from '@angular/core';
import { AbExperiment, AbPipelineDestinationAutoFillSnowflakeFields } from '../../../core/models/ab-experiments';
import { AbExperimentService } from '../../../core/service/ab-experiment.service';
import { TrialDetailsService } from '../../../core/service/trial-details.service';
import { VWOService } from '../../../core/service/vwo.service';


@Injectable({ providedIn: 'root' })
export class SnowflakeAutoFillExperimentService extends AbExperimentService {
  constructor(
    protected _vwoService: VWOService,
    protected _trialDetailsService: TrialDetailsService
  ) {
    super(_vwoService, _trialDetailsService, AbExperiment.PIPELINE_DESTINATION_AUTO_FILL_SNOWFLAKE_FIELDS);
  }

  protected _isExperimentApplicable() {
    return this._isUserInTrial;
  }

  isExperimentVariant(variant = AbPipelineDestinationAutoFillSnowflakeFields.AUTO_FILL_FIELDS) {
    return this._vwoService.isExperimentVariant(this._experimentName, variant);
  }

  isExperimentNodeType(nodeTypeIdentifier: string) {
    return nodeTypeIdentifier === 'SNOWFLAKE';
  }
}
