import React from 'react';
import { HdPane } from '../../UIElements';
import styles from '../styles.module.scss';

function SingleLevelSelectorItemShimmer({ dimension, dataId }) {
  return (
    <tr data-id={dataId}>
      <td className='center-flex-row'>
        <span className='shimmer shimmer-square mr-4' />
        <span className='shimmer shimmer-line' style={{ width: dimension }} />
      </td>
    </tr>
  );
}

function SingleLevelSelectorDefaultItem({ item }) {
  return (
    <div>
      <span className='mr-1 text-ellipsis'>{item.name}</span>
      <span className='text-secondary mr-2'> #{item.id} </span>
    </div>
  );
}

function SingleLevelSelectorEmptyFilteredListError({
  dataId,
  NoItemsMatchingFilterError,
  selectableItemNameLabel
}) {
  return (
    <tr data-id={dataId}>
      <td>
        {NoItemsMatchingFilterError ? (
          <NoItemsMatchingFilterError />
        ) : (
          <>No {selectableItemNameLabel} found for above search criteria.</>
        )}
      </td>
    </tr>
  );
}

function SingleLevelSelectorEmptyListError({ dataId, NoItemsFoundError, selectableItemNameLabel }) {
  return (
    <tr data-id={dataId}>
      <td>
        {NoItemsFoundError ? <NoItemsFoundError /> : <>No {selectableItemNameLabel} found.</>}
      </td>
    </tr>
  );
}

function SingleLevelSelectorPartialFilteredList({ dataId, message }) {
  return (
    <tr data-id={dataId}>
      <td>
        <HdPane
          className={styles.loader}
          variant='warning-faded'
          icon='testing'
          iconClasses={styles.icon}
        >
          {message}
        </HdPane>
      </td>
    </tr>
  );
}

export {
  SingleLevelSelectorDefaultItem,
  SingleLevelSelectorItemShimmer,
  SingleLevelSelectorEmptyListError,
  SingleLevelSelectorPartialFilteredList,
  SingleLevelSelectorEmptyFilteredListError
};
