import { Params } from '@angular/router';

import { ListMeta, PaginatedListOptions } from '../models';

export function getQueryParamFromListMeta(options: PaginatedListOptions, listMeta: ListMeta): Params {
  const page = listMeta.pagination.page;
  return {
    [getQueryParamkey(options.queryParamsPrefix, 'page')]: page,
    ...(options.filterKeys || []).reduce((dict, key) => {
      return Object.assign(
        dict,
        {
          [getQueryParamkey(options.queryParamsPrefix, key)]: listMeta.filters[key]
        }
      );
    }, {}),
    ...(options.orderByKeys || []).reduce((dict, key) => {
      return Object.assign(
        dict,
        {
          [getQueryParamkey(options.queryParamsPrefix, key)]: listMeta.sortOptions[key]
        }
      );
    }, {})
  };
}

export function getListMetaFromQueryParams(listMeta: ListMeta, { params, options }: { params: Params, options: PaginatedListOptions}) {
  const clonedListMeta = { ...listMeta };
  clonedListMeta.pagination = { ...clonedListMeta.pagination };
  clonedListMeta.pagination.page = parseInt(params[getQueryParamkey(options.queryParamsPrefix, 'page')], 10) || 0;
  clonedListMeta.pagination.page = clonedListMeta.pagination.page < 0 ? 0 : clonedListMeta.pagination.page;
  clonedListMeta.filters = groupPropertiesByKey(params, options.queryParamsPrefix, options.filterKeys);
  clonedListMeta.sortOptions = groupPropertiesByKey(params, options.queryParamsPrefix, options.orderByKeys);
  return clonedListMeta;
}

export function groupPropertiesByKey(params: Params, prefix: string, keys: string[]) {
  if (!keys) {
    return {};
  }

  return keys.reduce((dict, key) => {
    const param = params[getQueryParamkey(prefix, key)];
    if (typeof param === 'undefined' || params === null) {
      return dict;
    }
    return Object.assign(
      dict,
      { [key]: params[getQueryParamkey(prefix, key)] }
    );
  }, {});
}

export function hasQueryParamsChanged(options: PaginatedListOptions, params: Params, listMeta: ListMeta): boolean {
  const newListMeta = getListMetaFromQueryParams(listMeta, { params, options });
  if (newListMeta.pagination.page !== listMeta.pagination.page) {
    return true;
  }

  return compareObjects(listMeta.filters, newListMeta.filters)
    || compareObjects(listMeta.sortOptions, newListMeta.sortOptions);
}

function compareObjects(a, b) {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return true;
  }

  return Object.keys(a).some((key) => {
    return a[key] !== b[key];
  });
}

export function getQueryParamkey(prefix: string, key: string) {
  return `${prefix}_${key}`;
}
