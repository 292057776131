export const MEMBERS_SHIMMER_DIMENSIONS = [
  {
    name: '126px',
    email: '86px'
  },
  {
    name: '106px',
    email: '66px'
  },
  {
    name: '86px',
    email: '103px'
  }
];

export const TRACKER_MEMBERS_PAGE_INVITE_MEMBER_CLICK = '[Members Page] invite click';
export const TRACKER_MEMBERS_PAGE_INVITE_MEMBER_ERROR = '[Members Page] invite error';
export const TRACKER_MEMBERS_PAGE_INVITE_MEMBER_SUCCESS = '[Members Page] invite success';

export const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const RBAC_SCHEMA = {
  Administrator: {
    id: 'ADMINISTRATOR',
    title: 'Administrator',
    description:
      'Administrators have create, edit, and delete access to the entity types, such as, Team, Billing, and Pipelines.',
    roles: []
  },
  Collaborator: {
    id: 'COLLABORATOR',
    title: 'Collaborator',
    description:
      'Collaborators can edit the entity types such as Team settings and Pipelines, but cannot delete them.',
    roles: []
  },
  Observer: {
    id: 'OBSERVER',
    title: 'Observer',
    description:
      'Observers have read-only access to all the entity types, such as Team and Pipelines. The Observer role is assigned by default to all team members.',
    roles: []
  }
};
