import { useEffect, useState } from 'react';
import { localHostValidator } from '../utils/hostNameHandler';
import useAnalyticsTracker from './useAnalyticsTracker';

interface LocalHostTrackerProps {
  value: string;
  hdLocalHostValidatorDisabled: boolean;
  localHostTrackerLabel?: string;
  localHostTrackerSection?: string;
  localHostTrackingParams?: any;
}

const useLocalHostTracker = ({
  value = '',
  hdLocalHostValidatorDisabled,
  localHostTrackerLabel,
  localHostTrackerSection,
  localHostTrackingParams = {}
}: LocalHostTrackerProps) => {
  const [hostName, setHostName] = useState(value);
  const [usingNgrok, setUsingNgrok] = useState(value.indexOf('ngrok') >= 0);
  const analyticsTracker = useAnalyticsTracker();
  const isValidLocalHost = localHostValidator(value, hdLocalHostValidatorDisabled);

  useEffect(() => {
    if (value !== hostName) {
      if (!isValidLocalHost) {
        analyticsTracker.eventTrack({
          action: '[Local Host] validation error',
          properties: {
            label: localHostTrackerLabel,
            section: localHostTrackerSection,
            ...localHostTrackingParams
          }
        });
      }

      if (value.indexOf('ngrok') >= 0) {
        setUsingNgrok(true);
      } else {
        setUsingNgrok(false);
      }
    }
    setHostName(value);
  }, [value]);

  useEffect(() => {
    if (usingNgrok) {
      analyticsTracker.eventTrack({
        action: '[Local Host] ngrok used',
        properties: {
          label: localHostTrackerLabel,
          section: localHostTrackerSection,
          ...localHostTrackingParams
        }
      });
    }
  }, [usingNgrok]);
};

export default useLocalHostTracker;
