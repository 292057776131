import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, Input, Optional } from '@angular/core';
import { MenuItem } from '../menu-item';
import { MENU_PANEL, MenuPanel } from '../menu-panel';

@Component({
  selector: 'hd-menu-item, [hd-menu-item]',
  templateUrl: './hd-menu-item.component.html',
  styleUrls: [ 'hd-menu-item.component.scss', '../menu-item.scss' ],
  providers: [ {
    provide: MenuItem, useExisting: forwardRef(() => HdMenuItemComponent)
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HdMenuItemComponent extends MenuItem {
  @Input() iconName: string;

  constructor(
    protected _elementRef: ElementRef,
    protected _cdRef: ChangeDetectorRef,
    @Inject(MENU_PANEL) @Optional() public _parentMenu?: MenuPanel<MenuItem>) {
    super(_elementRef, _cdRef, _parentMenu);
  }
}
