import { Router } from '@angular/router';

export function redirectOnResolveError(errStatus: number, router: Router, redirectPath: any[]) {
  if (shouldRedirectOnResolveError(errStatus)) {
    router.navigate(redirectPath);
  }
}

export function shouldRedirectOnResolveError(errStatus: number) {
  // errStatus 401 is already handled by requestService
  const excludedStatuses: number[] = [401, 403];

  return excludedStatuses.indexOf(errStatus) < 0;
}

export function getUrlWithoutParams(router: Router) {
  const currentUrlTree = router.parseUrl(router.url);
  currentUrlTree.queryParams = {};
  return currentUrlTree.toString();
}
