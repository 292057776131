import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { DESTINATION_TYPES } from '../../../../../../app/nodes/destination-type/model';
import { TRACKER_DESTINATION_SQL_HOST_NAME_BLUR } from '../../../../../../app/nodes/tracking';
import { HdFormikPasswordField, HdFormikTextField } from '../../../../../components/FormikElements';
import SSHConfig from '../../../../../components/FormikElements/SSHConfig';
import SSHConfigFields from '../../../../../components/FormikElements/SSHConfig/SSHConfigFields';
import { SSHConfigHelpBanner } from '../../../../../components/FormikElements/SSHConfig/SSHConfigHelpBanner';
import SSLConfig from '../../../../../components/FormikElements/SSLConfig';
import { SanitizeName } from '../../../../../components/NodeConfigOptions';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import useLocalHostTracker from '../../../../../hooks/useLocalHostTracker';
import { ConfigDestinationTypeBaseProps } from '../interface';
// eslint-disable-next-line import/no-cycle
import { getHostName } from './experimentUtils';

export default function Sql({
  hdLocalHostValidatorDisabled,
  isEditing,
  maskSecretFields,
  destinationTypeIdentifier,
  destinationTypeMetaData,
  hevoEntityFor,
  onInviteMember,
  rawDestinationType,
  isDestinationModeWarehouse
}: ConfigDestinationTypeBaseProps) {
  const destinationTypeTrackingProps = {
    destinationType: destinationTypeIdentifier,
    context: hevoEntityFor
  };

  const analyticsTracker = useAnalyticsTracker();

  const formikProps = useFormikContext<{
    host: string;
    isConnectionUri: string;
    connectionString: string;
    port: string;
    databaseName: string;
    useSSH: boolean;
  }>();

  useLocalHostTracker({
    value: formikProps.values.host,
    hdLocalHostValidatorDisabled,
    localHostTrackerLabel: 'db-host-field-local-host-steps',
    localHostTrackerSection: 'destination_settings',
    localHostTrackingParams: {
      destinationType: destinationTypeIdentifier,
      context: hevoEntityFor.toString()
    }
  });

  const isDestinationTypeRedshift = () =>
    DESTINATION_TYPES.REDSHIFT.name === destinationTypeIdentifier;

  const onHostNameBlur = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_DESTINATION_SQL_HOST_NAME_BLUR,
      properties: {
        hostName: formikProps.values.host,
        destinationType: destinationTypeIdentifier
      }
    });
  };

  const canProvideSchemaName = () => destinationTypeMetaData.provideSchemaName;

  const canUseSSL = () => destinationTypeMetaData.canUseSSL;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='host'
              label={isDestinationTypeRedshift() ? 'Database Hostname' : 'Database Host'}
              required
              placeholder={
                isDestinationTypeRedshift() ? 'IP (1.2.3.4) or host(your.server.com)' : 'DB Host'
              }
              component={HdFormikTextField}
              onBlur={onHostNameBlur}
              helperText={getHostName(destinationTypeIdentifier, destinationTypeTrackingProps)}
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='port'
              label='Database Port'
              required
              component={HdFormikTextField}
              type='number'
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field name='user' label='Database User' required component={HdFormikTextField} />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='password'
              label='Database Password'
              required
              masked={maskSecretFields}
              component={HdFormikPasswordField}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name='databaseName'
              label='Database Name'
              required
              component={HdFormikTextField}
              helperText='Must be an existing database.'
            />
          </HdFormControl>
        </Grid>

        {canProvideSchemaName() ? (
          <Grid item xs={12} md={6}>
            <HdFormControl>
              <Field name='schemaName' label='Schema Name' component={HdFormikTextField} />
            </HdFormControl>
          </Grid>
        ) : null}
      </Grid>

      <SSHConfig className='mb-7' entityTypeIdentifier='database'>
        {formikProps.values.useSSH ? (
          <SSHConfigHelpBanner
            destinationTypeDisplayName={rawDestinationType.dest_type_display}
            defaultExpanded
            onInviteMember={onInviteMember}
          />
        ) : null}

        <SSHConfigFields />
      </SSHConfig>

      {canUseSSL() ? (
        <SSLConfig sslCARequired={!!destinationTypeMetaData.sslCARequired} />
      ) : null}

      {!isDestinationModeWarehouse ? (
        <div className='mb-5'>
          <SanitizeName
            disabled={!!isEditing}
          />
        </div>
      ) : null}
    </>
  );
}
