export const SOURCE_TYPES_DISPLAY_ORDER = [
  'AWS_RDS_MYSQL',
  'AURORA',
  'MYSQL',
  'GCP_MYSQL',
  'AZURE_MYSQL',
  'MARIA_DB',
  'AWS_RDS_ORACLE',
  'ORACLE',
  'AWS_RDS_POSTGRES',
  'AWS_AURORA_POSTGRES',
  'POSTGRES',
  'GCP_POSTGRES',
  'AZURE_POSTGRES',
  'SF_HEROKU_POSTGRES',
  'AWS_RDS_MSSQL',
  'MS_SQL',
  'GCP_MS_SQL',
  'AZURE_SQL',
  'ATLAS_MONGODB',
  'MONGODB',
  'DYNAMODB',
  'SALESFORCE',
  'GOOGLE_ADS',
  'GOOGLE_ANALYTICS_V2',
  'FACEBOOK_ADS',
  'GOOGLE_SHEETS',
  'MICROSOFT_ADS',
  'TWITTER_ADS',
  'LINKEDIN_ADS',
  'GOOGLE_SEARCH_CONSOLE',
  'GOOGLE_CM',
  'HUBSPOT',
  'STRIPE',
  'ZENDESK',
  'MAILCHIMP',
  'MARKETO',
  'SENDGRID',
  'SENDGRID_WEBHOOK',
  'S3',
  'DRIVE',
  'WEBHOOK',
  'GCS',
  'FTP',
  'RESTAPI',
  'APPSFLYER',
  'MIXPANEL',
  'FRESHDESK',
  'CLEVERTAP',
  'REDSHIFT',
  'SEGMENT',
  'GITHUB',
  'INTERCOM_WEBHOOK',
  'CONFLUENT_CLOUD',
  'KAFKA',
  'MOCK_LOAD',
  'SHOPIFY',
  'SHOPIFY_WEBHOOK',
  'ANDROID_SDK',
  'AWS_ELASTICSEARCH',
  'ELASTICSEARCH',
  'GOOGLE_PLAY_CONSOLE',
  'INTERCOM',
  'ADROLL',
  'AMPLITUDE_ANALYTICS',
  'JIRA_CLOUD',
  'OUTBRAIN',
  'FACEBOOK_PAGES',
  'KLAVIYO',
  'PARDOT',
  'YOUTUBE_ANALYTICS',
  'CRITEO',
  'SALESFORCE_MARKETING_CLOUD',
  'APPLE_SEARCH_ADS',
  'INSTAGRAM_BUSINESS',
  'GOOGLE_ANALYTICS_4',
  'GOOGLE_ANALYTICS_360',
  'BIGQUERY',
  'FIREBASE_ANALYTICS',
  'XERO',
  'TABOOLA',
  'ASANA',
  'WOOCOMMERCE_VIA_MYSQL',
  'WORDPRESS_VIA_MYSQL',
  'MAGENTO_VIA_MYSQL',
  'PRESTASHOP_VIA_MYSQL'
];
