import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'form-loading-overlay',
  templateUrl: './form-loading-overlay.component.html',
  styleUrls: ['./form-loading-overlay.component.scss']
})
export class FormLoadingOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
