import { ENTER } from '@angular/cdk/keycodes';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

// WARNING: Ensure keydown is the proper event.
// keyup causes error, when focus is shifted to other element before keyup fires, happens with buttons
// convert this to use a debounce method
@Directive(
  {
    selector: '[hdFocusable]'
  }
)
export class FocusableDirective implements OnInit, OnChanges {

  @Input() hdFocusable = 0;
  @Input() @HostBinding('class.disabled') disabled = false;
  @Input() addFocusClass = true;
  @Output() select = new EventEmitter<MouseEvent | KeyboardEvent>();

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }

    if (changes.focusable || changes.disabled) {
      this.updateTabIndex();
    }

    if (changes.addFocusClass || changes.disabled) {
      this.updateFocusableClass();
    }
  }

  ngOnInit() {
    this.updateTabIndex();
    this.updateFocusableClass();
  }

  updateTabIndex() {
    if (this.disabled) {
      this.el.nativeElement.removeAttribute('tabindex');
    } else {
      (this.el.nativeElement as HTMLElement).setAttribute('tabindex', this.hdFocusable + '');
    }
  }

  updateFocusableClass() {
    if (this.addFocusClass && !this.disabled) {
      this.el.nativeElement.classList.add('focusable');
    } else {
      this.el.nativeElement.classList.remove('focusable');
    }
  }

  @HostListener('keypress', [ '$event' ]) onEnterKey(e: KeyboardEvent) {
    if (e.keyCode === ENTER) {
      this.triggerOnSelect(e);
    }
  }

  @HostListener('click', [ '$event' ]) onClick(e: MouseEvent) {
    this.triggerOnSelect(e);
  }

  triggerOnSelect(e: KeyboardEvent | MouseEvent) {
    if (this.disabled) {
      return;
    }

    this.select.emit(e);
  }
}
