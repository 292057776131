export const KEYMAP = {
  keyMaps: {
    'pipeline': {
      name: 'Pipelines',
      map: {
        'create': {
          'command': 'Mod.C',
          'description': 'Create Pipeline'
        },
        'overview': {
          'command': 'Mod.Shift.O',
          'description': 'Open Overview'
        },
        'transformations': {
          'command': 'Mod.Shift.I',
          'description': 'Open Transformations'
        },
        'mapper': {
          'command': 'Mod.Shift.M',
          'description': 'Open Schema Mapper'
        },
        'replayQueue': {
          'command': 'Mod.Shift.Y',
          'description': 'Open Replay Queue'
        },
        'activityLog': {
          'command': 'Mod.Shift.L',
          'description': 'Open Activity Log'
        },
        'destinationTable': {
          'command': 'Mod.Shift.D',
          'description': 'Open Destination Tables'
        },
        'testTransformationCode': {
          'command': 'Mod.Shift.Enter',
          'description': 'Test Transformation code'
        },
        'deployTransformationCode': {
          'command': 'Mod.Shift.G',
          'description': 'Deploy Transformation code'
        },
         'pipelineJobsList': {
          'command': 'Mod.Shift.E',
          'description': 'Pipeline Jobs List'
        }
      }
    },
    'model': {
      name: 'Models',
      map: {
        'create': {
          'command': 'Mod.C',
          'description': 'Create Model'
        },
        'preview': {
          'command': 'Mod.Shift.O',
          'description': 'Open Preview'
        },
        'sourceQuery': {
          'command': 'Mod.Shift.Y',
          'description': 'Open Source Query'
        },
        'history': {
          'command': 'Mod.Shift.H',
          'description': 'Open Query History'
        },
        'activityLog': {
          'command': 'Mod.Shift.L',
          'description': 'Open Activity Log'
        },
        'runSourceQuery': {
          'command': 'Mod.Shift.Enter',
          'description': 'Run Source Query'
        }
      }
    },
    'dbtModel': {
      name: 'dbt™ Models',
      map: {
        'create': {
          'command': 'Mod.C',
          'description': 'Create Model'
        },
        'overview': {
          'command': 'Mod.Shift.O',
          'description': 'Open Overview'
        },
        'history': {
          'command': 'Mod.Shift.H',
          'description': 'Open Execution History'
        },
        'activityLog': {
          'command': 'Mod.Shift.L',
          'description': 'Open Activity Log'
        }
      }
    },
    'workflow': {
      name: 'Workflows',
      map: {
        'create': {
          'command': 'Mod.C',
          'description': 'Create Workflow'
        },
        'preview': {
          'command': 'Mod.Shift.O',
          'description': 'Open Preview'
        },
        'history': {
          'command': 'Mod.Shift.H',
          'description': 'Open History'
        },
        'activityLog': {
          'command': 'Mod.Shift.L',
          'description': 'Open Activity Log'
        }
      }
    },
    'destination': {
      name: 'Destinations',
      map: {
        'add': {
          'command': 'Mod.C',
          'description': 'Create Destination'
        },
        'overview': {
          'command': 'Mod.Shift.O',
          'description': 'Open Overview'
        },
        'workbench': {
          'command': 'Mod.Shift.Y',
          'description': 'Open Workbench'
        },
        'activityLog': {
          'command': 'Mod.Shift.L',
          'description': 'Open Activity Log'
        },
        'runSourceQuery': {
          'command': 'Mod.Shift.Enter',
          'description': 'Run Source Query'
        }
      }
    },
    'activation': {
      name: 'Activations',
      map: {
        'create': {
          'command': 'Mod.C',
          'description': 'Create Activation'
        },
        'overview': {
          'command': 'Mod.Shift.O',
          'description': 'Open Overview'
        },
        'history': {
          'command': 'Mod.Shift.H',
          'description': 'Open History'
        },
        'activityLog': {
          'command': 'Mod.Shift.L',
          'description': 'Open Activity Log'
        },
        'runSourceQuery': {
          'command': 'Mod.Shift.Enter',
          'description': 'Run Source Query'
        },
        'runQueryAndContinue': {
          'command': 'Mod.Enter',
          'description': 'Run Query & Continue'
        }
      }
    },
    'target': {
      name: 'Targets',
      map: {
        'create': {
          'command': 'Mod.C',
          'description': 'Create Target'
        }
      }
    },
    'global': {
      name: 'Global',
      map: {
        'keyboardShortcuts': {
          'command': 'Mod./',
          'description': 'List Keyboard Shortcuts'
        },
        'searchDialog': {
          'command': 'Mod.J',
          'description': 'Search Dialog'
        },
        'pipelineList': {
          'command': 'Mod.P',
          'description': 'Pipelines List'
        },
        'modelList': {
          'command': 'Mod.E',
          'description': 'Models List'
        },
        'workflowList': {
          'command': 'Mod.K',
          'description': 'Workflows List'
        },
        'activateList': {
          'command': 'Mod.U',
          'description': 'Activate List'
        },
        'warehouseList': {
          'command': 'Mod.I',
          'description': 'Destinations List'
        },
        'team': {
          'command': 'Mod.Y',
          'description': 'Team'
        },
        'payment': {
          'command': 'Mod.B',
          'description': 'Payments'
        }
      }
    },
    'common': {
      map: {
        'esc': { 'command': 'Esc' },
        'refresh': { 'command': 'Mod.Shift.U' },
        'search': { 'command': 'Mod.Shift.F' }
      }
    }
  }
};
