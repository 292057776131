import { SwitchProps } from '@mui/material/Switch';
import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { HdSwitch } from '../HdSwitch';
import { HdTooltip } from '../HdTooltip';

interface HdSwitchWrapperProps {
  id: any;
  dataId: string;
  disabled?: boolean;
  label: string;
  switchToolTipProps?: any;
  labelClasses?: string;
  children?: React.ReactNode;
  switchProps?: SwitchProps;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export default function HdSwitchWrapper({
  id,
  dataId,
  children,
  label,
  switchToolTipProps,
  disabled = false,
  switchProps,
  labelClasses = '',
  onChange,
  className
}: HdSwitchWrapperProps) {
  return (
    <Box className={`${styles.item} ${className}`}>
      {!!switchToolTipProps ? (
        <HdTooltip {...switchToolTipProps}>
          <span>
            <HdSwitch
              {...switchProps}
              id={id}
              dataId={dataId}
              disabled={disabled}
              onChange={onChange}
            />
          </span>
        </HdTooltip>
      ) : (
        <HdSwitch
          {...switchProps}
          id={id}
          dataId={dataId}
          disabled={disabled}
          onChange={onChange}
        />
      )}

      <div className={`${styles.text} ml-4 ${disabled ? styles.disabled : ''}`}>
        <Typography className={labelClasses} variant='body2' component='label' htmlFor={id}>
          {label}
        </Typography>

        <Typography component='label' htmlFor={id} className='mt-1'>
          {children}
        </Typography>
      </div>
    </Box>
  );
}
