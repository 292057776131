import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoMappingStatus } from '../../core/models/auto-mapping-status';
import { RbacPermissions } from '../../core/models/user';
import { SOURCE_TYPES } from '../../nodes/source-type/source-type';
import { DisableAutoMappingConfirmationService } from '../../pipeline/service/disable-auto-mapping-confirmation.service';
import { AUTO_MAPPING_DOC_LINK } from './constants';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'automapping-control-pane',
  templateUrl: './automapping-control-pane.component.html',
  styleUrls: [ './automapping-control-pane.component.scss' ],
  providers: [
    DisableAutoMappingConfirmationService
  ]
})
export class AutoMappingControlPaneComponent {

  @Input() status: AutoMappingStatus;
  @Input() sourceType: string;
  @Input() sourceDisplayName: string;
  @Input() rbacPermission: RbacPermissions;

  @Output() change = new EventEmitter<any>();

  autoMappingDocLink: string = AUTO_MAPPING_DOC_LINK;
  autoMappingStatuses = AutoMappingStatus;
  sourceTypes = SOURCE_TYPES;

  destroyed$ = new Subject<void>();

  constructor(
    private _disableAutoMappingConfirmationService: DisableAutoMappingConfirmationService
  ) {
  }

  getSchemaDocLink() {
    if (this.sourceTypes[this.sourceType].canShowViewSchemaOption) {
      return `${this.sourceTypes[this.sourceType].docLink}#schema-and-primary-keys`;
    }
  }

  onAutoMappingStatusChange(checked) {
    if (!checked) {
      this.onDisableAutoMapping();
    } else {
      this.change.emit({
        checked: true
      });
    }
  }

  onDisableAutoMapping() {
    this.status = this.autoMappingStatuses.NO_MAP;

    this._disableAutoMappingConfirmationService.confirm().pipe(
      takeUntil(this.destroyed$)
    ).subscribe((confirm) => {
      if (confirm.autoMappingStatus === this.autoMappingStatuses.NO_MAP) {
        this.change.emit({
          checked: false
        });
      } else {
        this.status = confirm.autoMappingStatus;
      }
    });
  }
}
