import { Component, HostBinding, Input } from '@angular/core';


@Component({
  selector: 'button[alert-toggle-button]',
  templateUrl: './alert-toggle-button.component.html',
  styleUrls: [ './alert-toggle-button.component.scss' ]
})
export class AlertToggleButtonComponent {
  failurePluralMap = {
    '=1': '1 Failure', 'other': '# Failures'
  };

  warningPluralMap = {
    '=1': '1 Warning', 'other': '# Warnings'
  };

  @Input() active = false;
  @Input() alertType: 'warning' | 'failure' | 'normal' = 'warning';
  @Input() count: number;

  @HostBinding('attr.type') buttonType = 'button';

  @HostBinding('class') get classes() {
    return `${ this.active ? 'active' : '' } ${ this.alertType }`;
  }
}
