import { useHistory } from '@useHistory/useHistoryCompat';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { RecentEntitiesService } from '../../../app/core/service/recent-entities.service';
import useService from '../../hooks/useService';

export function useDrawerDestinationGuard() {
  const history = useHistory();
  const recentEntitiesService = useService(RecentEntitiesService);

  return () => {
    const recent = recentEntitiesService.getRecent(HevoEntity.DESTINATION.value);
    if (!recent) {
      history.push('/destination');
      return false;
    }
    return true;
  };
}
