import React, { useState } from 'react';
import { HdButton, HdPane } from '../../../../../components/UIElements';
import { getPolicyMessage } from '../../../../scheduler/utils';
import { getDataIdsFromContract } from '../../../../../utils/generateDataId';
import { DATA_ID_CONTRACTS } from './dataIds';
import PipelinesAPI from '../../../PipelinesAPI';
import styles from './styles.module.scss';
import { getErrorMessageFromObj } from '../../../../../legacy-utils/request';

const dataIds = getDataIdsFromContract(DATA_ID_CONTRACTS);

export function ContactSalesForStreaming({ isEdit, policyObj, entityDetails, onSuccessCallback }) {
  const [salesContacted, setSalesContacted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const contactSales = async () => {
    try {
      setLoading(true);
      await PipelinesAPI.requestStreamingMode({ source_type: entityDetails.source_type });
      setSalesContacted(true);
      setLoading(false);
      onSuccessCallback();
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  return (
    <>
      <div className='text-body-1 text-secondary mt-5'>
        Hevo will load your data continuously when streaming is enabled. Contact Hevo Sales to
        enable streaming
      </div>
      {!salesContacted ? (
        <HdButton
          dataId={dataIds.contactSalesCTA}
          variation='faded'
          className={styles.contactSalesCTA}
          disabled={loading}
          onClick={() => {
            contactSales();
          }}
        >
          contact sales
        </HdButton>
      ) : null}

      {salesContacted ? (
        <HdPane dataId={dataIds.infoPane} icon='info' variant='info-faded' className='mt-5'>
          Sales team will reach out to you soon.
          {!isEdit ? (
            <>
              {' '}
              Click <span className='text-medium'>CONTINUE</span> to create Pipeline with frequency{' '}
              <span className='text-medium'>{getPolicyMessage(policyObj)}</span>
            </>
          ) : null}
        </HdPane>
      ) : null}

      {error ? (
        <HdPane
          dataId={dataIds.errorPane}
          icon='error-filled'
          variant='error-faded'
          className='mt-5'
        >
          {getErrorMessageFromObj(error) || 'Something went wrong, Please try again'}
        </HdPane>
      ) : null}
    </>
  );
}
