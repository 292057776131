import clsx from 'clsx';
import React, { useCallback } from 'react';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdCodeEditor } from '../index';
import styles from './styles.module.scss';

require('codemirror/mode/javascript/javascript');

interface JsonEditorProps {
  dataId: string;
  className?: string;
  code: string;
  autoHeight?: boolean;
  allowCopy?: boolean;
  readOnly?: boolean;
  resizeCtrl?: boolean;
  showLineNumbers?: boolean;
  onChange?: (string: string) => void;
  getCodeMirror?: Function;
  editorOptions?: Object;
}

export default function JsonEditor({
  dataId,
  className = '',
  code,
  autoHeight = false,
  allowCopy = false,
  readOnly = true,
  resizeCtrl = false,
  showLineNumbers = false,
  onChange = () => {},
  getCodeMirror,
  editorOptions
}: JsonEditorProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  return (
    <HdCodeEditor
      dataId={dataIdGenerator('json-editor')}
      className={`${styles.jsonEditor} ${className}`}
      codeEditorClassName={clsx(!showLineNumbers && styles.jsonEditorCodeEditorWithoutLineNumber)}
      value={code}
      autoHeight={autoHeight}
      allowCopy={allowCopy}
      showLineNumbers={showLineNumbers}
      readonly={readOnly}
      resizeCtrl={resizeCtrl}
      onChange={onChange}
      editorOptions={{
        mode: { name: 'javascript', json: true },
        ...editorOptions
      }}
      getCodeMirror={getCodeMirror}
      copyTooltipContent='Copy JSON'
    />
  );
}
