export class MembersTrackingActions {
  static SEARCH_ACTION = '[Members] search filter';

  static INVITE_USER_CTA_CLICK = '[Members] invite users click';

  static INVITE_USER_CANCEL = '[Members] invite users cancel click';

  static INVITE_USER_CONFIRM = '[Members] invite users confirm click';

  static USER_PERMISSION_UPDATE_SUCCESS = '[Members] invite users permission updated';

  static USER_PERMISSION_UPDATE_FAILED = '[Members] invite users permission update failed';

  static DELETE_USER_BUTTON_CLICK = '[Members] user delete click';

  static ROLES_STATISTICS = '[Members] roles statistics';
}
