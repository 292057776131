import React from 'react';
import { ActivationData } from '../../models';
import { NotificationView } from '../notification-view';
import useEntityLogo from '../../../../hooks/useEntityLogo';
import { HdIcon } from '../../../UIElements';
import styles from './styles.module.scss';

export function ActivationView({ data }: NotificationView<ActivationData>) {
  const warehouseThumbnail = useEntityLogo(
    data.warehouseTypeLogoUrl,
    data.warehouseTypeDarkModeLogoUrl
  );
  const targetThumbnail = useEntityLogo(data.targetTypeLogoUrl, data.targetTypeDarkModeLogoUrl);

  return (
    <div className={styles.activationViewContainer}>
      <div className={styles.nodeInfo}>
        {data.warehouseTypeLogoUrl ? (
          <div className={styles.nodeImage}>
            <img src={warehouseThumbnail} alt='' />
          </div>
        ) : null}

        <div className={styles.nodeName}>{data.warehouseName}</div>
      </div>

      <HdIcon name='back' size={0} className={styles.connectionIndicator} />

      <div className={styles.nodeInfo}>
        {data.targetTypeLogoUrl ? (
          <div className={styles.nodeImage}>
            <img src={targetThumbnail} alt='' />
          </div>
        ) : null}

        <div className={styles.nodeName}>{data.targetName}</div>
      </div>
    </div>
  );
}
