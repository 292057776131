// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.jobModeRadioGroup__t8LD {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-bottom: 32px;\n  border: solid 1px var(--divider-color);\n  border-radius: 4px;\n}\n.jobModeRadioGroup__t8LD > :not(:first-child) {\n  border-top: solid 1px var(--divider-color);\n}\n\n.jobModeSelectorBox_oWEyF {\n  width: 100%;\n  border: solid 1px var(--divider-color);\n  border-radius: 4px;\n  transition: all 0.2s ease-in;\n  margin-bottom: 32px;\n}\n.jobModeSelectorBox_oWEyF .header_z_65x {\n  padding: 32px 14px 16px;\n  width: 100%;\n}\n.jobModeSelectorBox_oWEyF .header_z_65x .seeMoreBtn_RIGA_ {\n  text-decoration: none !important;\n  box-shadow: none;\n}\n.jobModeSelectorBox_oWEyF .jobModeRadioGroupExperiment_q_yh8 {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.jobModeSelectorBox_oWEyF .jobModeRadioGroupExperiment_q_yh8 {\n  border-top: none;\n  padding-left: 12px !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobModeRadioGroup": "jobModeRadioGroup__t8LD",
	"jobModeSelectorBox": "jobModeSelectorBox_oWEyF",
	"header": "header_z_65x",
	"seeMoreBtn": "seeMoreBtn_RIGA_",
	"jobModeRadioGroupExperiment": "jobModeRadioGroupExperiment_q_yh8"
};
export default ___CSS_LOADER_EXPORT___;
