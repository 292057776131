<div class="mw-0">
  {{ data.reason }}

  <a
    *ngIf="data.docLink && data.docLink.url"
    hdLink
    icon="new-window"
    class="ml-1"
    [hdDocLink]="data.docLink.url"
    section="pipeline_snackbar">
    {{ data.docLink.text }}
  </a>
</div>

<div class="center-flex-row">
  <button
    *ngIf="data.troubleShootingActions && data.troubleShootingActions.docLink"
    type="button"
    class="ml-2"
    hdButton
    size="sm"
    color="error"
    variation="outline"
    icon="troubleshoot"
    [inlineText]="true"
    (click)="showTroubleShootingDrawer()">
    Troubleshoot
  </button>

  <button
    *ngIf="showSupportBtn"
    type="button"
    hdButton
    size="sm"
    variation="outline"
    color="error"
    icon="support"
    [inlineText]="true"
    class="ml-2"
    [message]="supportMessage"
    (click)="supportClick()"
    supportBtn>
    Chat with Support
  </button>
</div>
