<div
  class="tour-container"
  #container
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="dialogAnimationDone($event)">

  <div #content></div>

  <div #reactContainer></div>
</div>
