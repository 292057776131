import { FieldTypes, SourceFormFieldDefinition } from '../models';


export const SENTRY_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'authToken',
      fieldId: 'auth-token',
      widget: FieldTypes.PASSWORD_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'authToken', label: 'Auth Token', required: true },
      hint: 'Enter the Auth Token obtained from the API → Create New Token → Select all the scopes → Create Token → Copy and Save the Auth Token.',
      buildParamsKey: 'auth_token',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
