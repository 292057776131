// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.switchLabel_VL4UB {\n  font-weight: 700 !important;\n  margin-bottom: 4px !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchLabel": "switchLabel_VL4UB"
};
export default ___CSS_LOADER_EXPORT___;
