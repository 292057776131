import * as Yup from 'yup';
import { AwsConfig } from '../../../pipeline/create/ConfigSourceType/AWS/config';
import { AwsConfigHelper } from '../../../pipeline/create/ConfigSourceType/AWS/configHelper';
import {
  AWSAuthenticationType,
  AWSFormField
} from '../../../pipeline/create/ConfigSourceType/AWS/model';

export function getInitialValues(rawData: any, regions: any[]) {
  if (rawData) {
    return {
      ...AwsConfigHelper.getConfigFromRawData(rawData),
      region: regions.find(region => region.value === rawData.region),
      logGroup: rawData.log_group
    };
  }

  return {
    [AWSFormField.AUTH_TYPE]: AWSAuthenticationType.IAM_ROLE,
    [AWSFormField.IAM_ROLE_ARN]: '',
    [AWSFormField.IAM_EXTERNAL_ID]: '',
    [AWSFormField.ACCESS_KEY]: '',
    [AWSFormField.SECRET_KEY]: '',
    region: null,
    logGroup: ''
  };
}

export const VALIDATION_SCHEMA = AwsConfig.validationSchema.concat(
  Yup.object({
    logGroup: Yup.string().required(),
    region: Yup.object().required()
  })
);

export function getConfigRawData(formData: any) {
  return {
    ...AwsConfigHelper.buildConnectionParams(formData, false),
    log_group: formData.logGroup,
    region: formData.region.value
  };
}
