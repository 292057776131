import React, { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Popper } from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss';
import { HdTextField } from '../HdTextField';
import {
  formatTime,
  formatTimeToString,
  getDoubleDigitTime,
  getValidTime,
  Time,
  getTimeUnit
} from './utils';
import { HdIconButton } from '../HdIconButton';
import { HdIcon } from '../index';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export const HdPopper = styled(Popper)(() => ({
  boxShadow: 'var(--shadow-dropdown)',
  borderRadius: 'var(--border-radius-md)',
  width: 100,
  zIndex: 'var(--zindex-modal)',
  background: 'var(--surface-bg-secondary-color)'
}));

export interface TimeSelectorProps {
  dataId: string;
  id?: string;
  helperText?: React.ReactNode;
  placeholder: string;
  label: string;
  value?: Time;
  error?: boolean;
  iconSize?: number;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (time: Time) => void;
  showDropdownOptions?: boolean;
}

const hourTimeArr = new Array(24).fill(0);
const minTimeArr = new Array(60).fill(0);

export default function TimeSelector({
  dataId,
  placeholder,
  label,
  value = { hour: null, minute: null },
  iconSize = 2,
  onChange,
  error,
  showDropdownOptions = true,
  ...inputTextProps
}: TimeSelectorProps) {
  const [time, setTime] = useState<Time>(value);
  const [timeInputText, setTimeInputText] = useState(formatTimeToString(value));
  const [open, setOpen] = useState(false);
  const inputRef = useRef<any>();

  const shouldUpdateTime = value?.hour !== time?.hour || value?.minute !== time?.minute;

  useEffect(() => {
    onChange({ hour: getTimeUnit(time?.hour), minute: getTimeUnit(time?.minute) });
  }, [time]);

  useEffect(() => {
    if (shouldUpdateTime) {
      setTime({ ...value });
      setTimeInputText(formatTimeToString(value));
    }
  }, [value]);

  const handleClick = () => {
    if (showDropdownOptions) {
      setOpen(prev => !prev);
    }
  };

  const handleChange = e => {
    const formatedTime = formatTime({
      eventData: e.nativeEvent?.data,
      targetValue: e.target.value
    });
    setTimeInputText(formatedTime || '');

    if (formatedTime) {
      let [hrs, mins] = formatedTime.split(' : ');
      hrs = getTimeUnit(parseInt(hrs, 10));
      mins = getTimeUnit(parseInt(mins, 10));

      setTime({ hour: hrs, minute: mins });
    } else {
      setTime({ hour: null, minute: null });
    }
  };

  const handleHourClick = (hour: number) => {
    const [hrs, min] = timeInputText.split(' : ');
    const timeArr = getValidTime(hrs, min);

    setTimeInputText(`${getDoubleDigitTime(hour)} : ${timeArr[1]}`);
    setTime({ hour, minute: parseInt(timeArr[1], 10) });
  };

  const handleMinClick = (mins: number) => {
    const [hrs, min] = timeInputText.split(' : ');
    const timeArr = getValidTime(hrs, min);

    setTimeInputText(`${timeArr[0]} : ${getDoubleDigitTime(mins)}`);
    setTime({ hour: parseInt(timeArr[0], 10), minute: mins });
  };

  const dataIdGenerator = getDataIdGenerator(dataId, 'TimeSelector');

  return (
    <>
      <HdTextField
        autoComplete='off'
        inputRef={inputRef}
        dataId={dataIdGenerator('')}
        label={label}
        value={timeInputText}
        onChange={e => handleChange(e)}
        onClick={handleClick}
        suffixElement={
          <HdIconButton dataId={dataIdGenerator('')}>
            <HdIcon name='time' size={iconSize} />
          </HdIconButton>
        }
        error={error}
        {...inputTextProps}
      />

      <HdPopper
        data-id={dataIdGenerator('popper')}
        open={open}
        anchorEl={inputRef.current}
        placement='bottom-start'
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={styles.timeSelectorDropdown}>
            <div className={clsx(styles.dropdown, styles.dropdownScrollbar)}>
              {hourTimeArr.map((val, index) => (
                <div
                  data-id={dataIdGenerator(`hour-option-${index + 1}`)}
                  key={index}
                  role='button'
                  className={clsx(
                    'center-flex-row justify-center',
                    styles.dropdownItem,
                    index === time?.hour ? styles.active : null
                  )}
                  onClick={() => handleHourClick(index)}
                >
                  {getDoubleDigitTime(index)}
                </div>
              ))}
            </div>

            <div className={clsx(styles.dropdown, styles.dropdownScrollbar)}>
              {minTimeArr.map((val, index) => (
                <div
                  data-id={dataIdGenerator(`min-option-${index + 1}`)}
                  key={index}
                  role='button'
                  className={clsx(
                    'center-flex-row justify-center',
                    styles.dropdownItem,
                    index === time?.minute ? styles.active : null
                  )}
                  onClick={() => handleMinClick(index)}
                >
                  {getDoubleDigitTime(index)}
                </div>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      </HdPopper>
    </>
  );
}
