export class EnumVal {
  constructor(public name: string) {}
  toString() {
    return this.name;
  }
}

export class Enum {
  static toArray() {
    return Object
    .keys(this)
    .map(key => this[key])
    .filter(val => val instanceof EnumVal);
  }
}
