<svg class="progress-container" [attr.viewBox]="getViewBox()" [attr.width]="size" [attr.height]="size">
  <circle
    class="backdrop"
    [class.complete]="progress === 1"
    [attr.cx]="getCircleCenter()"
    [attr.cy]="getCircleCenter()"
    [attr.r]="getCircleRadius()"
    [attr.stroke-width]="strokeWidth">
  </circle>

  <circle
    class="progress"
    [class.complete]="progress === 1"
    [attr.cx]="getCircleCenter()"
    [attr.cy]="getCircleCenter()"
    [attr.r]="getCircleRadius()"
    [attr.stroke-dasharray]="getProgressDashArray(getCircleRadius())"
    [attr.stroke-dashoffset]="getProgressOffset(getCircleRadius())"
    [attr.stroke-width]="strokeWidth">
  </circle>

  <path
    class="tick"
    stroke="#fff"
    fill="none"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    [attr.d]="getCheckPolyline()"
    [attr.stroke-dasharray]="size + ' ' +  size"
    [attr.stroke-dashoffset]="progress === 1 ? 0 : size"
    stroke-linejoin="round"/>
</svg>
