import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[dataId]'
})
export class DataIdDirective {
  @Input() dataId: string;

  @HostBinding('attr.data-id') get dataIdAttribute() {
    return this.dataId.replace(/_/g, '-').toLowerCase();
  }
}
