import { SidelineGroupReplayProgress } from '../models/sideline-group-replay-progress';

export function SidelineGroupReplayProgressFactory(sourceObjectId: number, rawData: any): SidelineGroupReplayProgress {
  return Object.assign(
    new SidelineGroupReplayProgress(),
    {
      sourceObjectId: sourceObjectId,
      totalNoOfRecords: rawData.total,
      noOfRecordsReplayed: rawData.replayed
    }
  );
}
