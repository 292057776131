// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.optionBody_HsT_Z {\n  font-size: 12px;\n  font-weight: 600;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  color: var(--text-default-color);\n  box-sizing: border-box;\n  margin-bottom: 20px;\n}\n\n.radioGroup_pu59N {\n  width: 50%;\n}\n\n.textArea_W9VWb {\n  width: 53% !important;\n}\n\n.shimmerOption_ahbph {\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n  margin-bottom: 20px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionBody": "optionBody_HsT_Z",
	"radioGroup": "radioGroup_pu59N",
	"textArea": "textArea_W9VWb",
	"shimmerOption": "shimmerOption_ahbph"
};
export default ___CSS_LOADER_EXPORT___;
