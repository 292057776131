import { Observable } from 'rxjs';
import { AppConfig } from '../../../app/core/app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../../../app/core/constants';

const appConfig = new AppConfig();

export const StatsAPI = {
  statsUrl: `${appConfig.getStatsURL()}`,

  getRateLimit(): Observable<any> {
    const requestUrl = `${ this.statsUrl }/rate-limit/violations/source`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
};
