import { AccountEntityTypes, AuthAccountProvider } from '../select-auth-account/interface';


export enum ConnectorAuthTypeEnum {
  SERVICE = 'SERVICE_ACCOUNT',
  OAUTH = 'USER_ACCOUNT'
}

export interface AuthType {
  name: string;
  description?: string;
  value: ConnectorAuthTypeEnum;
  usedBy?: string;
  icon?: string;
  isRecommended?: boolean;
  docLink?: string;
}

export function getAuthTypeDetails(provider: AuthAccountProvider) {
  const AUTH_TYPES: {[key: string]: AuthType} = {
    [ConnectorAuthTypeEnum.OAUTH]: {
      name: 'User Account',
      description: `The User OAuth authentication method provides Hevo <i>${
        provider.entityType === AccountEntityTypes.SOURCE ? 'Read' : 'Write'
      }</i> access to the ${ getDataset(provider) } your user has access to.`,
      value: ConnectorAuthTypeEnum.OAUTH,
      usedBy: 'Individuals',
      icon: 'user',
      isRecommended: true,
      docLink: '/getting-started/connection-options/google-account-authentication/#using-user-accounts'
    },
    [ConnectorAuthTypeEnum.SERVICE]: {
      name: 'Service Account',
      description: `The Service Account authentication method provides better control over access to your
        ${ provider.typeDisplay } data. You can add a unique service account as a user
        to specific ${ getDataset(provider) } that you want to load Events
        ${ provider.entityType === AccountEntityTypes.SOURCE ? 'from' : 'to' }.`,
      value: ConnectorAuthTypeEnum.SERVICE,
      usedBy: 'Teams',
      icon: 'team',
      docLink: '/getting-started/connection-options/google-account-authentication/#using-google-service-accounts'
    }
  };

  return AUTH_TYPES;
}

function getDataset(provider: AuthAccountProvider) {
  if (provider.entityType === AccountEntityTypes.DESTINATION) {
    if (provider.type === 'BIGQUERY') {
      return 'datasets';
    }
  }

  if (provider.entityType === AccountEntityTypes.TARGET) {
    return 'objects';
  }

  if (provider.type === 'GOOGLE_SHEETS') {
    return 'Sheets';
  } else if (provider.type === 'DRIVE') {
    return 'folders';
  }

  return 'datasets';
}
