import { Box, Grid } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { DestinationMode } from '../../../../../app/core/models/destination-mode';
import { isPromiseCancelError } from '../../../../legacy-utils/promise';
import FormApiError from '../../../../components/FormApiError';
import FormApiSuccess from '../../../../components/FormApiSuccess';
import HdFormikTextFieldValidator from '../../../../components/FormikElements/HdFormikTextFieldValidator';
import styles from '../../../../components/ReusableStyles/FormikWrapperStyles/styles.module.scss';
import { HdTooltip } from '../../../../components/UIElements';
import HdButton from '../../../../components/UIElements/HdButton';
import HdFormControl, {
  HdFormButtonWrapper
} from '../../../../components/UIElements/HdFormControl';
import useDontMountAtFirst from '../../../../hooks/useDontMountAtFirst';
import useQueue from '../../../../hooks/useQueue';
import useTeamSettingsService from '../../../../hooks/services/useTeamSettingsService';
import ErrorFocus from '../../../../utils/ErrorFocus';
import FormObserver from '../../../../utils/FormObserver';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import DestinationsAPI from '../../DestinationsAPI';
import { ConfigByType } from './configByType';
import { DestinationType } from '../../../../../app/nodes/destination-type/model';
import { WordDefinition } from '../../../../../app/core/models/word-definition';
import { HevoEntityItem } from '../../../../../app/core/models/hevo-entity';

export const dataIdGenerator = getDataIdGenerator('destinationConfig');

interface ConfigDestinationTypeProps {
  destinationTypeIdentifier: string;
  rawDestinationType: any;
  formData: any;
  formMetaData: any;
  formError: string;
  formSuccess: string;
  submitBtnText: string;
  testBtnText: string;
  submitting: boolean;
  connecting: boolean;
  canShowSubmissionOverlay: boolean;
  destinationMode: string;
  destinationTypeMetaData: DestinationType;
  destinationModeKeywords: WordDefinition;
  destinationNameFieldLabel: string;
  frozenFields: any;
  onFormChanges: Function;
  onContinueClick: Function;
  onContinueFormError: Function;
  onTestConnectionClick: Function;
  onTestConnection: Function;
  onTestConnectionFormError?: Function;
  onContinue: Function;
  markFormDirty: Function;
  isEditing: boolean;
  setFormError: Function;
  setFormSuccess: Function;
  savedDestinationName: string;
  formErrorCode: number;
  formErrorClick: Function;
  canReauthoriseAccount?: boolean;
  reauthoriseRequest: Function;
  formInteraction: Function;
  formFieldFocusIn: Function;
  hevoEntityFor: HevoEntityItem;
  draftDestination?: any;
  selectedExistingDestination?: any;
  onInviteMember?: Function;
  experiments?: { [key: string]: any };
}

const DESTINATION_NAME = 'DESTINATION_NAME';

export default function ConfigDestinationType({
  formData,
  formMetaData,
  destinationTypeIdentifier,
  rawDestinationType,
  destinationMode,
  destinationTypeMetaData,
  destinationModeKeywords,
  destinationNameFieldLabel,
  frozenFields = {},
  onFormChanges,
  onContinueFormError,
  formError,
  submitBtnText,
  testBtnText,
  submitting,
  connecting,
  onTestConnection,
  formSuccess,
  canShowSubmissionOverlay,
  onContinueClick,
  onTestConnectionClick,
  onTestConnectionFormError,
  onContinue,
  markFormDirty,
  isEditing,
  setFormError,
  reauthoriseRequest,
  canReauthoriseAccount,
  setFormSuccess,
  savedDestinationName,
  formErrorCode,
  formErrorClick,
  formInteraction,
  formFieldFocusIn,
  hevoEntityFor,
  draftDestination,
  selectedExistingDestination,
  onInviteMember,
  experiments = {}
}: ConfigDestinationTypeProps) {
  const formikRef = useRef<FormikProps<{}>>();
  // This ref is used to keep track of the callback < if any >  we want to invoke once the processQueue is empty
  const scheduleCallback = useRef<any>();
  const [isConnecting, setIsConnecting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [_, addProcess, removeProcess, queueLength] = useQueue();
  const showOverLay = isConnecting || isSaving || canShowSubmissionOverlay;
  const FormComponent: any = ConfigByType[destinationTypeIdentifier].formComponent;

  const { getVPCPeeringConfig } = useTeamSettingsService()

  useDontMountAtFirst(() => {
    if (formData) {
      let formattedDestinationData = { ...formData };

      if (ConfigByType[destinationTypeIdentifier].getFormData) {
        formattedDestinationData = {
          ...ConfigByType[destinationTypeIdentifier].getFormData(formData, formMetaData)
        };
      }

      formikRef.current.setValues({ ...formikRef.current.values, ...formattedDestinationData });
    }
  }, [selectedExistingDestination]);

  useEffect(() => {
    if (queueLength === 0 && formikRef.current.isSubmitting) {
      formikRef.current.submitForm();
    }
  }, [queueLength]);

  useEffect(() => {
    // validating the form as soon as the component mounts
    // this was done for an exp where we disable the CTAs if there are errors in the form
    // with this, form initial state would be valid
    if (formikRef.current && !destinationTypeMetaData.submitCTAEnabledOnInvalidForm) {
      formikRef.current.validateForm().then(errors => {
        formikRef.current.setTouched(
          Object.keys(errors)
            .filter(fieldPath => {
              const fieldValue = formikRef.current.getFieldMeta(fieldPath).value;

              return !(fieldValue === null || fieldValue === undefined || fieldValue === '');
            })
            .reduce(
              (touchedValues, fieldPath) => {
                return {
                  ...touchedValues,
                  [fieldPath]: true
                };
              },
              {
                ...formikRef.current.touched
              }
            )
        );
      });
    }

    if (!destinationTypeMetaData.submitCTAEnabledOnInvalidForm) {
      document.documentElement.style.setProperty('--error-color-asterisk', 'var(--error-color)');
      return () => document.documentElement.style.setProperty('--error-color-asterisk', undefined);
    }

    return () => {};
  }, [destinationTypeMetaData.submitCTAEnabledOnInvalidForm]);

  /**
   * If there are items in the queue, don't do anything
   * If there are any scheduleCallbacks, invoke them
   * Otherwise just call the onContinue function.
   */
  const handleSubmit = (values, { setSubmitting }) => {
    if (queueLength) {
      return;
    }

    if (scheduleCallback.current) {
      scheduleCallback.current();
    } else {
      setSubmitting(false);
      onContinue();
      setIsSaving(false);
    }
  };

  const destinationNameValidator = (inputValue: string, options) => {
    /**
     *  while editing destination, we won't validate api call for the  current destination's name.
     */
    if (isEditing && inputValue === savedDestinationName) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return DestinationsAPI.validateDestinationName(inputValue, options).catch(e => {
      if (isPromiseCancelError(e)) {
        return;
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        ...e,
        customMessage: `A Destination with name, <b>"${inputValue}"</b> already exists.`
      };
    });
  };

  const handleTestConnection = () => {
    scheduleCallback.current = undefined;
    formikRef.current.setSubmitting(false);
    onTestConnection();
    setIsConnecting(false);
  };

  const resetDefaults = () => {
    scheduleCallback.current = undefined;
    setIsConnecting(false);
    setIsSaving(false);
  };

  /**
   * submit methods for test connection & save events
   * trigger callback for tracking analytics of click event
   * if error found, call the form error tracking analytics
   */

  const onTestConnectionButtonClick = props => {
    onTestConnectionClick();

    props.validateForm().then(errorFields => {
      const keys = Object.keys(errorFields);

      if (keys.length) {
        onTestConnectionFormError(
          ConfigByType.getErroredFieldNames(destinationTypeIdentifier, keys)
        );
      } else {
        setIsConnecting(true);
      }
    });

    scheduleCallback.current = handleTestConnection;
    props.handleSubmit();
  };

  const onSaveButtonClick = props => {

    onContinueClick();

    props.validateForm().then(errorFields => {
      const keys = Object.keys(errorFields);
      if (keys.length) {
        onContinueFormError(ConfigByType.getErroredFieldNames(destinationTypeIdentifier, keys));
      } else {
        setIsSaving(true);
      }
    });
  };

  const initialValues = {
    ...ConfigByType.getInitialValues(destinationTypeIdentifier),
    ...formData,
    vpcPeeringEnabled: getVPCPeeringConfig().enabled,
    destinationName: formData?.name
  };

  const validationSchema = ConfigByType.getValidationSchema(
    destinationTypeIdentifier,
    destinationTypeMetaData
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {props => {
        const { destinationName, ...values } = props.values;

        const isFormSubmitCTADisabled = () => {
          if (!destinationTypeMetaData.submitCTAEnabledOnInvalidForm) {
            return !props.isValid || connecting || submitting;
          }

          return connecting || submitting;
        };

        const showToolTipOnDisabledCTAs =
          !destinationTypeMetaData.submitCTAEnabledOnInvalidForm && !props.isValid;

        onFormChanges({
          ...values,
          name: destinationName
        }); // informing state machine about the form changes

        if (props.dirty) {
          markFormDirty();
        }

        return (
          <Box
            data-id={dataIdGenerator('form')}
            component='form'
            noValidate
            onSubmit={props.handleSubmit}
            className={`${styles.formWrapper} ${
              (queueLength !== 0 && props.isSubmitting) || showOverLay ? styles.backdrop : ''
            }`}
          >
            <ErrorFocus formik={props} onFormError={resetDefaults} />

            <Box className={styles.formCard} data-id={dataIdGenerator('form-card')}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HdFormControl>
                    <Field
                      name='destinationName'
                      label={destinationNameFieldLabel}
                      component={HdFormikTextFieldValidator}
                      asyncValidator={destinationNameValidator}
                      helperText={`A unique name for your ${destinationModeKeywords.singular}`}
                      autoFocus
                      required
                      maxLength={255}
                      asyncMonitor={(isProcessGoingOn: boolean) => {
                        if (isProcessGoingOn) {
                          addProcess(DESTINATION_NAME);
                        } else {
                          removeProcess(DESTINATION_NAME);
                        }
                      }}
                    />
                  </HdFormControl>
                </Grid>
              </Grid>

              <FormComponent
                setFormError={setFormError}
                isEditing={isEditing}
                maskSecretFields={isEditing || (draftDestination && draftDestination.isDraft)}
                formMetaData={formMetaData}
                destinationTypeMetaData={destinationTypeMetaData}
                isDestinationModeWarehouse={destinationMode === DestinationMode.WAREHOUSE}
                destinationTypeIdentifier={destinationTypeIdentifier}
                rawDestinationType={rawDestinationType}
                destinationMode={destinationMode}
                hevoEntityFor={hevoEntityFor}
                reauthoriseRequest={reauthoriseRequest}
                canReauthoriseAccount={canReauthoriseAccount}
                formErrorCode={formErrorCode}
                draftDestination={draftDestination}
                experiments={experiments}
                frozenFields={frozenFields}
                selectedExistingDestination={selectedExistingDestination}
                onInviteMember={onInviteMember}
              />

              {formError ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} className={styles.infoWrapper}>
                    <FormApiError
                      message={formError}
                      errorCode={formErrorCode}
                      nodeType={destinationTypeIdentifier}
                      nodeCategory={destinationMode.toString()}
                      docLink={ConfigByType.getDocLink(destinationMode, destinationTypeIdentifier)}
                      onClick={formErrorClick}
                      dataId={dataIdGenerator('')}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {formSuccess ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} className={styles.infoWrapper}>
                    <FormApiSuccess
                      message={formSuccess}
                      onClose={() => setFormSuccess(undefined)}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container direction='row' justifyContent='center' alignItems='center'>
                <HdFormButtonWrapper>
                  <HdTooltip
                    disabled={!showToolTipOnDisabledCTAs}
                    title='Please fill out all required fields.'
                  >
                    <span>
                      <HdButton
                        style={showToolTipOnDisabledCTAs ? { pointerEvents: 'none' } : undefined}
                        variation='outline'
                        palette='secondary'
                        showProgress={isConnecting || connecting}
                        onClick={() => onTestConnectionButtonClick(props)}
                        disabled={isFormSubmitCTADisabled()}
                        dataId={dataIdGenerator('test')}
                      >
                        {isConnecting ? 'Connecting' : testBtnText}
                      </HdButton>
                    </span>
                  </HdTooltip>
                </HdFormButtonWrapper>

                <HdFormButtonWrapper>
                  <HdTooltip
                    disabled={!showToolTipOnDisabledCTAs}
                    title='Please fill out all required fields.'
                  >
                    <span>
                      <HdButton
                        style={showToolTipOnDisabledCTAs ? { pointerEvents: 'none' } : undefined}
                        type='submit'
                        showProgress={isSaving || submitting}
                        onClick={() => onSaveButtonClick(props)}
                        disabled={isFormSubmitCTADisabled()}
                        dataId={dataIdGenerator('save')}
                      >
                        {isSaving ? 'Saving' : submitBtnText}
                      </HdButton>
                    </span>
                  </HdTooltip>
                </HdFormButtonWrapper>
              </Grid>
            </Box>

            <FormObserver
              formInteraction={formInteraction}
              formFieldFocusIn={formFieldFocusIn}
              fieldNameMapping={ConfigByType.getFieldNamesMapping(destinationTypeIdentifier)}
            />
          </Box>
        );
      }}
    </Formik>
  );
}
