import { HdInclusionList } from '../../../../app/core/models/inclusion-list';
import { ListConductor } from '../../../../app/pagination/list-conductor/list-conductor';

export interface ManagerProfile {
  customerId: number;
  descriptiveName: string;
  canManageClients: boolean;
  totalNumEntries: number;
}

export interface CustomerProfile {
  customerId: number;
  descriptiveName: string;
}

export function CustomerProfilesFactory(rawData: any[]): CustomerProfile[] {
  if (!rawData) {
    return [];
  }

  return rawData.map((item) => {
    return CustomerProfileFactory(item);
  });
}

export function CustomerProfileFactory(rawData: any): CustomerProfile {
  if (!rawData) {
    return;
  }

  return {
    customerId: rawData.customer_id,
    descriptiveName: rawData.descriptive_name
  };
}

export function CustomerProfilesFactoryV2(rawData: any[]): CustomerProfile[] {
  if (!rawData) {
    return [];
  }

  return rawData.map((item) => {
    return CustomerProfileFactoryV2(item);
  });
}

export function CustomerProfileFactoryV2(rawData: any): CustomerProfile {
  if (!rawData) {
    return;
  }

  return {
    customerId: rawData.id,
    descriptiveName: rawData.name
  };
}

export function ManagerProfilesFactory(rawData: any[]): ManagerProfile[] {
  if (!rawData) {
    return;
  }

  return rawData.map((item) => {
    return ManagerProfileFactory(item);
  });
}

export function ManagerProfileFactory(rawData: any): ManagerProfile {
  if (!rawData) {
    return;
  }

  return {
    customerId: rawData.customer_id,
    descriptiveName: rawData.descriptive_name,
    canManageClients: rawData.can_manage_clients,
    totalNumEntries: rawData.total_num_entries
  };
}

export function ManagerProfilesFactoryV2(rawData: any[]): ManagerProfile[] {
  if (!rawData) {
    return;
  }

  return rawData.map((item) => {
    return ManagerProfileFactoryV2(item);
  });
}

export function ManagerProfileFactoryV2(rawData: any): ManagerProfile {
  if (!rawData) {
    return;
  }

  return {
    customerId: rawData.basic_info ? rawData.basic_info.id : null,
    descriptiveName: rawData.basic_info ? rawData.basic_info.name : null,
    canManageClients: rawData.managing_clients,
    totalNumEntries: rawData.managed_customers_count
  };
}

export interface SelectedAccountsConfig {
  customerId: number;
  includedList: number[];
  excludedList: number[];
  canManage: boolean;
  selected: boolean;
}

export function SelectAccountsConfigsFactory(rawData: any[]) {
  if (!rawData) {
    return;
  }

  return rawData.map((config) => SelectAccountsConfigFactory(config));
}

export function SelectAccountsConfigFactory(rawData: any) {
  return {
    customerId: rawData.customer_id,
    includedList: rawData.included_list,
    excludedList: rawData.excluded_list,
    canManage: rawData.can_manage,
    selected: rawData.selected
  };
}

export function getSelectedAccountsConfigRawData(config: SelectedAccountsConfig) {
  return {
    customer_id: config.customerId,
    included_list: config.includedList,
    excluded_list: config.excludedList,
    can_manage: config.canManage,
    selected: config.selected
  };
}

export type ManagerProfileWithListData = ManagerProfile & { inclusionList: HdInclusionList, listConductor: ListConductor };

