import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../../../../app/core/constants';
import { AppConfig } from '../../../../app/core/app.config';
import { courier } from '../../../../app/core/service/courier.service';
import { NetworkRequestOptions } from '../../../../app/core/models/request';

const appConfig = new AppConfig();

const WebhooksAPI = {
  webhooksURL: appConfig.getWebhookEvents(),

  fetchWebhooks(destinationId = null) {
    const params: any = {
      connector_lineage: true
    };

    if (destinationId) {
      params.destination_id = destinationId;
    }
    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      networkOptions: {
        params
      }
    };

    return courier.get(this.webhooksURL, options);
  },

  fetchWebhook(seqId: number) {
    const requestUrl = `${this.webhooksURL}/by-seq-id/${seqId}`;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  validateWebhookName(webhookName, config) {
    const requestUrl = `${this.webhooksURL}/pre-validation`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      ...config
    };

    const params: any = {
      webhook_event_name: webhookName
    };

    return courier.put(requestUrl, options, params);
  },

  testConnection(params) {
    const url = `${this.webhooksURL}/test`;
    return courier.post(url, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  pauseWebhook(id) {
    const url = `${this.webhooksURL}/${id}/pause`;
    return courier.put(url, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  resumeWebhook(id) {
    const url = `${this.webhooksURL}/${id}/resume`;
    return courier.put(url, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  updateWebhook(webhookId, params) {
    return courier.put(`${this.webhooksURL}/${webhookId}`, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  createWebhook(params) {
    return courier.post(this.webhooksURL, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  deleteWebhook(id) {
    return courier.delete(`${this.webhooksURL}/${id}`, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
};

export default WebhooksAPI;
