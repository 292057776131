import { Enum, EnumVal } from './object-enum';


export class HevoEntityItem extends EnumVal {
  constructor(name, public value) {
    super(name);
  }
}

export class HevoEntity extends Enum {
  static PIPELINE                         = new HevoEntityItem('Pipeline', 'PIPELINE');
  static MODEL                            = new HevoEntityItem('Model', 'MODEL');
  static DBT_PROJECT                      = new HevoEntityItem('Dbt Model', 'DBT_PROJECT');
  static WORKFLOW                         = new HevoEntityItem('Workflow', 'WORKFLOW');
  static SOURCE                           = new HevoEntityItem('Source', 'SOURCE');
  static DESTINATION                      = new HevoEntityItem('Destination', 'DESTINATION');
  static CLIENT_ACTIVITY_LOGGER           = new HevoEntityItem('Activity Logger', 'CLIENT_ACTIVITY_LOGGER');
  static ACTIVATION                       = new HevoEntityItem('Activation', 'ACTIVATION');
  static ACTIVATION_TARGET                = new HevoEntityItem('Activation Target', 'ACTIVATION_TARGET');
  static ACCOUNT = new HevoEntityItem('Usage Report', 'USAGE_REPORT');
  static SYNC_FREQUENCY                   = new HevoEntityItem('Sync Frequency', 'SYNC_FREQUENCY')
}

export type AuthenticationEntityType = 'source' | 'destination' | 'target';

export interface EntityIndependentRunStatus {
  runsOnlyInWorkflows: boolean;
  workflowIds: number[];
  totalCount: number;
}
