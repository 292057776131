<div
  class="tab-nav-link"
  [popupTrigger]="popup"
  [popupDisabled]="!shortcut && !tooltipExtraContent && expanded"
  [class.active]="active"
  [class.focused]="focused">
  <hd-icon
    class="tab-nav-link-icon"
    [name]="iconName"
    [hdOverlayBadge]="badgeContent"
    [hdOverlayBadgeHidden]="!badgeContent || expanded"
    [hdOverlayBadgeColor]="badgeColor">
  </hd-icon>

  <span class="tab-nav-link-text" *ngIf="expanded">
    {{ label }}

    <span class="badge overlay-badge overlay-badge-{{ badgeColor }} ml-2" *ngIf="badgeContent">
      {{ badgeContent }}
    </span>
  </span>
</div>

<popup #popup>
  <div class="center-flex-col">
    <tab-nav-label-tooltip
      [showLabel]="!expanded"
      [command]="shortcut"
      [label]="label">
    </tab-nav-label-tooltip>

    <ng-container [ngTemplateOutlet]="tooltipExtraContent"></ng-container>
  </div>
</popup>
