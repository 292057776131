import { TextField, TextFieldProps, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HdIcon from '../HdIcon';

interface HdTextFieldAdornmentProps {
  prefixIcon?: string;
  prefixText?: string;
  suffixText?: string;
  prefixElement?: React.ReactElement;
  suffixElement?: React.ReactElement;
  forceActiveLegend?: boolean;
}

export function HdTextFieldWithAdornment({
  prefixIcon,
  prefixElement,
  prefixText,
  suffixText,
  suffixElement,
  forceActiveLegend,
  ...props
}: HdTextFieldAdornmentProps & TextFieldProps) {
  const [value, setValue] = useState(props.value || props.defaultValue);
  const [fieldFocused, setFieldFocused] = useState(false);
  const [labelLegendActive, setLabelLegendActive] = useState(!!value);
  const prefix = forceActiveLegend ? false : prefixIcon || prefixText;

  useEffect(() => {
    if (prefix && props.inputProps && typeof props.inputProps.value !== 'undefined') {
      setValue(props.inputProps.value);
    }
  }, [props.inputProps]);

  useEffect(() => {
    if (fieldFocused) {
      setLabelLegendActive(true);
    } else {
      setLabelLegendActive(!!value);
    }
  }, [value]);

  const onBlurEvent = event => {
    setFieldFocused(false);
    if (prefix) {
      setLabelLegendActive(!!value);
    }
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const onFocusEvent = event => {
    setFieldFocused(true);
    if (prefix) {
      setLabelLegendActive(true);
    }
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const onChangeEvent = event => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const getInputLabelProps = () => {
    let classes = '';
    if (!labelLegendActive) {
      classes = prefixIcon ? 'custom-icon-shrinkable' : 'custom-shrinkable';
    }

    return {
      className: classes,
      shrink: labelLegendActive
    };
  };

  const getAdornmentElement = (adornment: string) => (
    <Typography variant='body2' className='hd-adornment'>
      {prefixIcon ? <HdIcon name={adornment} /> : adornment}
    </Typography>
  );

  let conditionalInputProps = { ...props.InputProps };

  if (prefixIcon || prefixText || prefixElement) {
    conditionalInputProps = {
      ...conditionalInputProps,
      startAdornment: prefixElement || getAdornmentElement(prefixIcon || prefixText)
    };
  }

  if (suffixElement || suffixText) {
    conditionalInputProps = {
      ...conditionalInputProps,
      endAdornment: suffixElement || getAdornmentElement(suffixText)
    };
  }

  return (
    <TextField
      {...props}
      InputProps={{ ...conditionalInputProps }}
      placeholder={prefix && !labelLegendActive ? '' : props.placeholder}
      InputLabelProps={prefix ? getInputLabelProps() : props.InputLabelProps}
      onBlur={onBlurEvent}
      onFocus={onFocusEvent}
      onChange={onChangeEvent}
    />
  );
}
