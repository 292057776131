// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.heading__hDEv {\n  color: var(--default-secondary-color);\n}\n\n.content__obBV {\n  color: var(--text-default-color);\n  font-weight: 600;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading__hDEv",
	"content": "content__obBV"
};
export default ___CSS_LOADER_EXPORT___;
