// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.helpItemList_ReZ1b {\n  list-style: none;\n}\n.helpItemList_ReZ1b li {\n  position: relative;\n  padding-left: 1.16rem;\n}\n.helpItemList_ReZ1b li::before {\n  content: \"\";\n  position: absolute;\n  display: inline-block;\n  border: 1px solid #6b63ff;\n  border-radius: 50%;\n  left: 0;\n  top: 0.5rem;\n  height: 6px;\n  width: 6px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpItemList": "helpItemList_ReZ1b"
};
export default ___CSS_LOADER_EXPORT___;
