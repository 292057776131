export const TRACKER_CREATE_ACTIVATION_CLICK = '[Activations] create activation click';
export const TRACKER_CREATE_ACTIVATION_SHORTCUT_CLICK = '[Activations] create activation shortcut key';
export const TRACKER_INTRODUCTION_CREATE_ACTIVATION_CLICK = '[Activations Introduction] create activation click';
export const TRACKER_CREATE_ACTIVATION_INIT = '[Activations] create activation init';
export const TRACKER_ACTIVATE_TOUR_TAB_CLICK = '[Activations] activation tour tab click';

export const TRACKER_ACTIVATION_SYNC_SCHEDULE_SUBMIT = '[Activations] sync schedule submit';

export const TRACKER_ACTIVATION_TEST_AND_CONTINUE_CLICK = '[Activations] test query and continue click';
export const TRACKER_ACTIVATION_TEST_AND_CONTINUE_SUCCESS = '[Activations] test query and continue success';

export const TRACKER_ACTIVATION_SELECT_TARGET_OBJECT_CLICK = '[Activations] select target object click';
export const TRACKER_ACTIVATION_REFRESH_TARGET_OBJECT_CLICK = '[Activations] refresh target object click';
export const TRACKER_ACTIVATION_SYNC_BEHAVIOUR_TOUR_TOGGLE = '[Activations] sync behaviour tour toggle';
export const TRACKER_ACTIVATION_SELECT_SYNC_BEHAVIOUR = '[Activations] select sync behaviour';
export const TRACKER_ACTIVATION_MAPPING_BOX_MENU_ACTION_CLICK = '[Activations] mapping box menu action click';

export const TRACKER_ACTIVATION_SAVE_MAPPING_SUBMIT_CLICK = '[Activations] save mapping submit click';
export const TRACKER_ACTIVATION_SAVE_MAPPING_SUBMIT_SUCCESS = '[Activations] save mapping submit success';
export const TRACKER_ACTIVATION_SAVE_MAPPING_FORM_ERROR = '[Activations] save mapping form error';
export const TRACKER_NEW_ACTIVATION_SUBMIT_CLICK = '[Activations] new activation submit click';
export const TRACKER_NEW_ACTIVATION_SUCCESS = '[Activations] new activation success';
export const TRACKER_NEW_ACTIVATION_FAILED = '[Activations] new activation failed';

export const TRACKER_ACTIVATION_TEST_QUERY_CLICK = '[Activations] test query click';
export const TRACKER_ACTIVATION_TEST_QUERY_SUCCESS = '[Activations] test query success';
export const TRACKER_ACTIVATION_TEST_QUERY_FAILED = '[Activations] test query failed';

export const TRACKER_ACTIVATION_CHANGE_MAPPING_CANCEL_CLICK = '[Activations] change mapping cancel click';
export const TRACKER_ACTIVATION_CHANGE_MAPPING_CLICK = '[Activations] change mapping click';
export const TRACKER_ACTIVATION_CHANGE_MAPPING_SUBMIT = '[Activations] change mapping confirm';

export const TRACKER_ACTIVATION_OVERVIEW_SELECT_TAB_CLICK = '[Activate Overview] select tab click';
export const TRACKER_ACTIVATION_OVERVIEW_SAVE_QUERY_CLICK = '[Activate Overview] save query click';
export const TRACKER_ACTIVATION_OVERVIEW_SAVE_QUERY_SUCCESS = '[Activate Overview] save query success';
export const TRACKER_ACTIVATION_OVERVIEW_CHANGE_MAPPING_OPEN = '[Activate Overview] open change mapping dialog';
export const TRACKER_ACTIVATION_OVERVIEW_SAVE_DRAFT_QUERY_SUCCESS = '[Activate Overview] save draft query success';
export const TRACKER_ACTIVATION_OVERVIEW_SAVED_QUERY_REVERT_CLICK = '[Activate Overview] last saved query revert click';
export const TRACKER_ACTIVATION_OVERVIEW_DRAFT_QUERY_MAPPING_CLICK = '[Activate Overview] draft query update mapping click';

export const TRACKER_ACTIVATION_GOOGLE_SHEETS_NEW_WORKSHEET_SUBMIT = '[Activation Google Sheets] new worksheet submit';
export const TRACKER_ACTIVATION_GOOGLE_SHEETS_SYNC_BEHAVIOUR_SELECT = '[Activation Google Sheets] sync behaviour select';
export const TRACKER_ACTIVATION_GOOGLE_SHEETS_CUSTOM_RANGE_CLICK = '[Activation Google Sheets] custom range click';

export const TRACKER_ACTIVATION_INTERCOM_TAGS_NEW_TAG_SUBMIT = '[Activation Intercom Tags] new tag submit';

/*
 * Sync History Page
 * */

export const TRACKER_ACTIVATION_SYNC_HISTORY_HISTORY_FETCH_INIT = '[Activation Sync History] history fetch init';
export const TRACKER_ACTIVATION_SYNC_HISTORY_VIEW_DETAILS_CLICK = '[Activation Sync History] view details click';

export const TRACKER_ACTIVATION_SYNC_HISTORY_RECORDS_IGNORED_BUTTON_CLICK =
  '[Activation Sync History] records ignored button click';
export const TRACKER_ACTIVATION_SYNC_HISTORY_RECORDS_IGNORED_FETCH_SUCCESS =
  '[Activation Sync History] records ignored fetch success';
export const TRACKER_ACTIVATION_SYNC_HISTORY_RECORDS_IGNORED_FETCH_FAILED =
  '[Activation Sync History] records ignored fetch failed';
export const TRACKER_ACTIVATION_SYNC_HISTORY_DUPLICATE_RECORDS_SHOW_SAMPLE_CLICK =
  '[Activation Sync History] duplicate records show sample click';

export const TRACKER_ACTIVATION_SYNC_HISTORY_SYNC_FAILURES_BUTTON_CLICK =
  '[Activation Sync History] sync failures button click';
export const TRACKER_ACTIVATION_SYNC_HISTORY_SYNC_FAILURES_FETCH_SUCCESS =
  '[Activation Sync History] sync failures fetch success';
export const TRACKER_ACTIVATION_SYNC_HISTORY_SYNC_FAILURES_FETCH_FAILED =
  '[Activation Sync History] sync failures fetch failed';

export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILED_RECORD_SHOW_SAMPLE_CLICK =
  '[Activation Sync History] failed record show sample click';
export const TRACKER_ACTIVATION_SYNC_HISTORY_SYNC_FAILURES_DOWNLOAD_CLICK =
  '[Activation Sync History] sync failures download click';

export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILURE_DETAILS_FETCH_SUCCESS = '[Activation Sync History] failure' +
  ' details fetch success';
export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILURE_DETAILS_FETCH_FAILED = '[Activation Sync History] failure' +
  ' details fetch failed';
export const TRACKER_ACTIVATION_SYNC_HISTORY_QUERY_FAILED_RECORDS_CLICK = '[Activation Sync History] query failed records click';
export const TRACKER_ACTIVATION_SYNC_HISTORY_RETRY_FAILED_RECORDS_CLICK = '[Activation Sync History] retry failed records click';

export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILED_RECORDS_DIALOG_OPEN = '[Activation Sync History] failed records dialog open';
export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILED_RECORDS_DIALOG_CLOSE = '[Activation Sync History] failed records' +
  ' dialog close';
export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILED_RECORDS_DIALOG_QUERY_CLICK = '[Activation Sync History] failed' +
  ' records dialog query click';
export const TRACKER_ACTIVATION_SYNC_HISTORY_FAILED_RECORDS_DIALOG_DOWNLOAD_CLICK = '[Activation Sync History] failed' +
  ' records dialog download click';

