import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class ChargebeeConfigHelper implements SourceTypeConfigHelper {

  getConfigFromRawData(rawData: any) {
    return {
      domainName: rawData.chargebee_site,
      apiKey: rawData.chargebee_api_key,
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      chargebee_site: data.domainName,
      chargebee_api_key: data.apiKey,
    };
  }
}
