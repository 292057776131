import React, { useCallback } from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import HdIcon from '../HdIcon';
import styles from './styles.module.scss';

export interface HdLinkProps {
  dataId?: string;
  to?: string;
  tag?: 'a' | 'button';
  icon?: string;
  iconOnly?: boolean;
  iconSrc?: string;
  iconClasses?: string;
  direction?: 'left' | 'right';
  size?: 'sm' | 'md';
  color?: 'error' | 'light';
  [key: string]: any;
}

export default function HdLink({
  dataId,
  to,
  tag,
  icon,
  iconOnly,
  iconSrc,
  size,
  direction = 'right',
  iconClasses = '',
  color,
  ...props
}: HdLinkProps) {
  const history = useHistory();

  let classes = `text-link`;

  if (icon || iconSrc) {
    classes += ` thumbnail-${direction}`;
  }

  if (props.className) {
    classes += ` ${props.className}`;
  }

  if (icon || iconSrc) {
    classes += ` ${styles.linkIcon}`;
  }

  if (size) {
    classes += ` text-link-${size}`;
  }

  if (color) {
    classes += ` text-link-${color}`;
  }

  if (iconOnly) {
    classes += ' text-link-icon-only';
  }

  const LinkTag = tag || 'a';

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  return (
    <LinkTag
      type={tag === 'button' ? 'button' : undefined}
      {...props}
      className={classes}
      data-id={dataIdGenerator(tag === 'button' ? 'button' : 'link')}
      {...(to ? { href: to } : {})}
      onClick={e => {
        if (props.onClick) {
          props.onClick(e);
        }

        if (to) {
          e.preventDefault();
          history?.push(to);
        }
      }}
    >
      {icon && direction === 'left' ? (
        <HdIcon size={props.iconSize || 1} name={icon} className={`thumb ${iconClasses}`} />
      ) : null}

      {iconSrc && direction === 'left' ? <img src={iconSrc} className='thumb' alt='icon' /> : null}

      {props.children}

      {icon && direction !== 'left' ? (
        <HdIcon size={props.iconSize || 1} name={icon} className={`thumb ${iconClasses}`} />
      ) : null}

      {iconSrc && direction !== 'left' ? <img src={iconSrc} className='thumb' alt='icon' /> : null}
    </LinkTag>
  );
}
