import { ListKeyManagerOption } from '@angular/cdk/a11y';

export abstract class NestedKeyboardNavigationItem implements ListKeyManagerOption {
  disabled: boolean;

  abstract value: any;

  abstract isParent(): boolean;
  abstract isExpanded(): boolean;
  abstract onFocus(): void;
  abstract onUnFocus(): void;
  abstract getElementTop(): number;
  abstract getHeight(): number;
  abstract onEnter(event: KeyboardEvent): void;
}
