import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { PipelineStatResponse, ProductEntityStats } from '../models';
import {
  DestinationStatsType,
  DEFAULT_DESTINATION_STATS,
  DEFAULT_PIPELINE_STATS,
  DESTINATION_STATS_ICONS,
  PIPELINE_STATS_ICONS,
  PIPELINES_STATS_TYPES
} from '../constants';
import PipelinesAPI, { PIPELINE_STATS_TIME_INTERVAL } from '../../../../../pipeline/PipelinesAPI';
import { numFormatter } from '../../../../../../legacy-utils/number';
import DestinationsAPI, {
  DESTINATION_STATS_TIME_INTERVAL
} from '../../../../../destination/DestinationsAPI';
import { getLatencyTimeLabel } from '../../../../../../utils/timeUtils';
import styles from '../styles.module.scss';
import { HdIcon } from '../../../../../../components/UIElements';
import { GlobalSearchEntity } from '../../../GlobalSearchReducer';
import { ABORT_SENTRY_ERROR_MESSAGE } from '../../../../../../constants';

const STATS_API_CALL_DELAY = 300; // in ms

function ProductEntityStatsComponent({ entity, dataId }) {
  const { entityType } = entity;
  const canRenderStats =
    entityType === GlobalSearchEntity.PIPELINES || entityType === GlobalSearchEntity.DESTINATIONS;

  if (!canRenderStats) {
    return null;
  }

  let Component = PipelineStats;
  if (entityType === GlobalSearchEntity.DESTINATIONS) {
    Component = DestinationStats;
  }

  return <Component entity={entity} dataId={dataId} />;
}

function PipelineStats({ entity, dataId }) {
  const [loadingStats, setLoadingStats] = useState<boolean>(false);
  const [stats, setStats] = useState<ProductEntityStats[]>(DEFAULT_PIPELINE_STATS);
  const abortController = useRef(new AbortController());

  useEffect(() => {
    setLoadingStats(true);

    const timer = setTimeout(() => {
      fetchStats();
    }, STATS_API_CALL_DELAY);

    return () => {
      clearTimeout(timer);
      abortController.current.abort();
    };
  }, [entity]);

  const updateState = _stats => {
    setStats(_stats);
    setLoadingStats(false);
  };

  const fetchStats = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await PipelinesAPI.getIntegrationStats(
        entity.id,
        PIPELINE_STATS_TIME_INTERVAL,
        20,
        {
          signal: abortController.current.signal
        }
      );

      const _stats: ProductEntityStats[] = (res.data.stats as PipelineStatResponse[])
        .filter(stat => PIPELINES_STATS_TYPES.includes(stat.description))
        .map(stat => ({
          description: stat.description,
          displayValue: numFormatter(stat.total_events),
          icon: stat.icon || PIPELINE_STATS_ICONS[stat.description],
          value: stat.total_events
        }));

      updateState(_stats);
    } catch (e) {
      if (e.message === ABORT_SENTRY_ERROR_MESSAGE) {
        return;
      }

      updateState(DEFAULT_PIPELINE_STATS);
    }
  };

  return (
    <EntityStats
      loading={loadingStats}
      stats={stats}
      subtext='Pipeline activity in last 24 hours'
      dataId={dataId}
    />
  );
}

function DestinationStats({ entity, dataId }) {
  const [loadingStats, setLoadingStats] = useState<boolean>(false);
  const [stats, setStats] = useState<ProductEntityStats[]>(DEFAULT_DESTINATION_STATS);
  const abortController = useRef(new AbortController());

  useEffect(() => {
    setLoadingStats(true);

    const timer = setTimeout(() => {
      fetchStats();
    }, STATS_API_CALL_DELAY);

    return () => {
      clearTimeout(timer);
      abortController.current.abort();
    };
  }, [entity]);

  const updateState = _stats => {
    setStats(_stats);
    setLoadingStats(false);
  };

  const fetchStats = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    const loadStatsPromise = await DestinationsAPI.getDestinationStats(
      entity.id,
      DESTINATION_STATS_TIME_INTERVAL,
      40,
      {
        signal: abortController.current.signal
      }
    );

    const latencyPromise = await DestinationsAPI.getLatency(
      entity.id,
      DESTINATION_STATS_TIME_INTERVAL,
      40,
      {
        signal: abortController.current.signal
      }
    );

    try {
      const [loadStats, latency] = await Promise.all([loadStatsPromise, latencyPromise]);

      const _stats: ProductEntityStats[] = [];

      loadStats.forEach(stat => {
        if (stat.description === DestinationStatsType.LOAD) {
          _stats.push({
            description: stat.description,
            icon: DESTINATION_STATS_ICONS[DestinationStatsType.LOAD],
            displayValue: numFormatter(stat.total_events),
            value: stat.total_events
          });
        }
      });

      _stats.push({
        description: DestinationStatsType.LATENCY,
        icon: DESTINATION_STATS_ICONS[DestinationStatsType.LATENCY],
        displayValue: getLatencyTimeLabel(latency.avg_latency),
        value: latency.avg_latency
      });

      updateState(_stats);
    } catch (e) {
      if (e.message === ABORT_SENTRY_ERROR_MESSAGE) {
        return;
      }

      updateState(DEFAULT_DESTINATION_STATS);
    }
  };

  return (
    <EntityStats
      loading={loadingStats}
      stats={stats}
      subtext='Destination activity in last 24 hours'
      dataId={dataId}
    />
  );
}

function EntityStats({ stats, loading, subtext, dataId }) {
  return (
    <div data-id={`${dataId}-entity-stats`}>
      <div className='center-flex-row mt-5'>
        {stats.map((stat: ProductEntityStats, index) => (
          <div className={clsx(['center-flex-row', styles.entityStats])} key={index}>
            <HdIcon size={2} name={stat.icon} />

            <span className='ml-2 text-medium text-default'>{stat.description}</span>

            <span className={clsx('ml-3', 'text-bold', styles.statsCounter)}>
              {loading ? (
                <span className={clsx('shimmer', styles.statsLoadingShimmer)} />
              ) : (
                <span>{stat.displayValue}</span>
              )}
            </span>
          </div>
        ))}
      </div>

      <div className='text-secondary mb-5 mt-1'>{subtext}</div>
    </div>
  );
}

export const productEntityStatsMemoCheck = (prevProps, nextProps) =>
  prevProps.entity.seq_id === nextProps.entity.seq_id;

export default React.memo(ProductEntityStatsComponent, productEntityStatsMemoCheck);
