import React, { useCallback } from 'react';
import RetryApiAlert from '../../../components/RetryApiAlert';
import HdIcon from '../../../components/UIElements/HdIcon';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import ActivityLogList from '../ActivityLogList';
import ActivityLogSkeleton from '../ActivityLogSkeleton';

export function ActivityLogListContainer({
  dataId = '',
  className = '',
  state,
  sentryRef,
  noActivitiesFound,
  showSkeleton,
  showActivities,
  loadingMore,
  refresh
}) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'activityLog'), [dataId]);

  return (
    <div className={className}>
      {showSkeleton ? <ActivityLogSkeleton /> : null}

      {showActivities ? (
        <>
          <ActivityLogList
            activities={state.activities}
            loadingMore={loadingMore}
            dataIdGenerator={dataIdGenerator}
          />

          {loadingMore && (
            <div ref={sentryRef}>
              <ActivityLogSkeleton loadMore />
            </div>
          )}
        </>
      ) : null}

      {noActivitiesFound ? (
        <div className='no-item-box'>
          <span className='no-item-box-icon-container'>
            <HdIcon name='activity-log' className='no-item-box-icon' />
          </span>

          <span className='no-item-box-title'>No Activities Found</span>

          <span className='no-item-box-desc'>
            No recent activities found for above search criteria.
          </span>
        </div>
      ) : null}

      {state.listFetchError ? (
        <RetryApiAlert
          actionHandler={refresh}
          error={state.listFetchError}
          dataId={dataIdGenerator('')}
        />
      ) : null}
    </div>
  );
}
