import { Pipe, PipeTransform } from '@angular/core';
import { nounPluralFormatter } from '../../react/legacy-utils/string';
import { NounPluralFormatterOptions } from './interface';

/*
 * For any advanced use cases, we should use
 * {@link https://angular.io/api/common/I18nPluralPipe}
 * This pipe is specific to handle case
 * when count is greater than 1 or equal to 0
 * */

@Pipe({
  name: 'nounPluralFormatter'
})
export class NounPluralFormatterPipe implements PipeTransform {
  transform(value: string, options: NounPluralFormatterOptions): string {
    return nounPluralFormatter(value, options);
  }
}
