import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NetworkRequestOptions } from '../models/request';
import { AuthService } from '../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const requestOptions: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse: false,
        showLoading: false
      }
    };

    if (route.queryParams.hasOwnProperty('session_token')) {

      return this._authService.loginWithSessionToken(route.queryParams['session_token']).pipe(
        map(() => {
          // remove the query_param from the URL
          return this._router.parseUrl('/');
        }),
        catchError((error) => {
          if (error.status === 401) {
            return this._authService
            .onUnAuthorisedEvent(false).pipe(
              map((res) => {
                return false;
              })
            );
          }
          return of(false);
      }));

    } else {
      return this._authService.getLoggedInUser(
        requestOptions,
        true
      ).pipe(
        map((res) => {
          return true;
        }),
        catchError((error: any) => {
          if (error.status === 401) {
            return this._authService.onUnAuthorisedEvent(true).pipe(
              map(() => {
                return false;
              })
            );
          }
          return of(false);
        })
      );
    }
  }
}
