import { NgZone } from '@angular/core';
import { take } from 'rxjs/operators';


export function executeOnStable(zone: NgZone, fn: () => any): void {
  if (zone.isStable) {
    fn();
  } else {
    zone.onStable.pipe(
      take(1)
    ).subscribe(fn);
  }
}
