import { GENERIC_VARIATION } from '../../../../../../app/nodes/nodes-settings/constants';
import { EntityVariationData } from '../../../../../../app/nodes/nodes-settings/models';
import { ElasticSearchVariations } from './models';


export const ELASTICSEARCH_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Generic Elasticsearch',
    value: GENERIC_VARIATION,
    description: '',
    displayName: 'Elasticsearch',
    docLink: '/sources/databases/elasticsearch/'
  },
  {
    name: 'AWS Elasticsearch',
    value: ElasticSearchVariations.AWS_ELASTICSEARCH,
    description: '',
    displayName: 'AWS Elasticsearch',
    docLink: '/sources/databases/elasticsearch/'
  },
  {
    name: 'AWS OpenSearch',
    value: ElasticSearchVariations.AWS_OPENSEARCH,
    description: '',
    displayName: 'AWS OpenSearch',
    docLink: '/sources/databases/elasticsearch/'
  }
];
