import { ElementRef } from '@angular/core';
import { AbstractControl, FormGroup, NgForm, NgModelGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';


export function markFormElementsAsTouched(control: AbstractControl) {
  control.markAsTouched();
  const children = control['controls'];

  if (children) {
    const keys = Object.keys(children);
    keys.forEach((key: any) => {
      const childControl = children[key];
      if (childControl !== undefined) {
        markFormElementsAsTouched(childControl);
      }
    });
  }
}

export function getAllInvalidChildren(control: AbstractControl) {
  const children = control['controls'];
  if (children) {
    return Object.keys(children).filter(key => children[key].invalid);
  } else {
    return undefined;
  }
}

export function findFirstInvalidFormElement(control: AbstractControl) {
  const children = control['controls'];
  if (children) {
    const keys = Object.keys(children);
    return keys.find(key => {
      const childControl = children[key];
      if (childControl.invalid) {
        return findFirstInvalidFormElement(childControl);
      }
    });
  } else if (control.invalid) {
    return control;
  }
}

export function isElementAutoFilled(element: HTMLElement) {
  try {
    if (element.matches(':autofill')) {
      return true;
    }
  } catch (e) {
  }

  try {
    if (element.matches(':-webkit-autofill')) {
      return true;
    }
  } catch (e) {
  }

  return false;
}

export function focusFirstInvalidElement(el: ElementRef, control: AbstractControl) {
  markFormElementsAsTouched(control);
  const firstInvalidElement = findFirstInvalidFormElement(control);
  const childNativeElement = el.nativeElement.querySelector('[name=' + firstInvalidElement + ']');
  if (childNativeElement) {
    childNativeElement.focus();
  }
}

export function focusFirstGroupInvalidElement(el: ElementRef, formGroup: AbstractControl) {
  markFormElementsAsTouched(formGroup);
  const firstInvalidElement = findFirstInvalidFormElement(formGroup);
  const childNativeElement = el.nativeElement.querySelector('[name=' + firstInvalidElement + ']');
  if (childNativeElement) {
    childNativeElement.focus();
  }
}

export function validateFormAndFocusFirstInvalidElement(
  formReference: NgModelGroup | NgForm,
  elementRef: ElementRef,
  canFocusOnInvalidElement = true,
  isFormGroup = false
): Observable<string[]> {
  const formRef: FormGroup = isFormGroup ? formReference.control : formReference['form'];

  return validateForm(formRef).pipe(
    switchMap((isFormValid) => {
      if (!isFormValid) {
        if (canFocusOnInvalidElement) {
          setTimeout(() => {
            focusFirstInvalidElement(elementRef, formReference.control);
          }, 0);
        }

        const invalidFields = getAllInvalidChildren(formReference.control);
        return of(invalidFields);
      }

      return of(null);
    })
  );
}

export function validateForm(formReference: FormGroup) {
  return formReference.statusChanges.pipe(
    startWith(formReference.status),
    filter(status => status !== 'PENDING'),
    switchMap((status: string) => {
      if (status === 'INVALID') {
        return of(false);
      }

      return of(true);
    })
  );
}
