// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.fullScreenContent_ORqof {\n  width: 100%;\n  height: 100%;\n  background: var(--surface-bg-color);\n  overflow: auto;\n}\n\n.fullScreenContentInner_P9kWz {\n  height: 100%;\n  width: 100%;\n  min-width: 1280px;\n  min-height: 588px;\n  background: var(--surface-bg-color);\n  min-width: 1280px;\n  min-height: 588px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreenContent": "fullScreenContent_ORqof",
	"fullScreenContentInner": "fullScreenContentInner_P9kWz"
};
export default ___CSS_LOADER_EXPORT___;
