import { useState } from 'react';

export const useLoadItems = (limit = 20, filteredList = []) => {
  const [{ hasNextPage, loading, disabled, listItems }, setState] = useState({
    hasNextPage: true,
    loading: false,
    disabled: false,
    listItems: []
  });

  const setDisabled = _disabled => {
    setState(state => ({
      ...state,
      disabled: _disabled
    }));
  };

  const setHasNextPage = _hasNextPage => {
    setState(state => ({
      ...state,
      hasNextPage: _hasNextPage
    }));
  };

  const appendList = () => {
    const slicedList = filteredList.slice(listItems.length, listItems.length + limit);

    const newList = [...listItems, ...slicedList];

    setState(state => ({
      ...state,
      loading: false,
      listItems: [...newList],
      hasNextPage: newList.length < filteredList.length
    }));
  };

  const resetList = () => {
    const slicedList = filteredList.slice(0, limit);

    setState(state => ({
      ...state,
      loading: false,
      listItems: [...slicedList],
      hasNextPage: slicedList.length < filteredList.length
    }));
  };

  const updateList = () => {
    const slicedList = filteredList.slice(0, Math.max(listItems.length, limit));
    setState(state => ({
      ...state,
      listItems: [...slicedList]
    }));
  };

  return {
    hasNextPage,
    loading,
    disabled,
    setHasNextPage,
    setDisabled,
    listItems,
    appendList,
    resetList,
    updateList
  };
};
