import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { AlertDialogComponent } from './alert-dialog.component';

@Injectable()
export class AlertDialogService {
  constructor(private _dialogOverlayService: DialogOverlayService) {
  }

  alert(title?: string, body?: string, positiveButton?: string) {
    return this._dialogOverlayService.open(
      AlertDialogComponent,
      {
        title: title,
        body: body,
        positiveButton: positiveButton
      }
    ).onClose();
  }

  alertWithSupportButton(title?: string, body?: string, customerSupportMessage?: string, supportButton: string = '') {
    return this._dialogOverlayService.open(
      AlertDialogComponent,
      {
        title,
        body,
        supportButton,
        isCustomerSupportBtn: true,
        customerSupportMessage
      }
    ).onClose();
  }
}
