// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.jobsContainer__bra2 {\n  margin: 20px;\n}\n.jobsContainer__bra2 .filtersContainer_axxHv {\n  display: flex;\n  max-height: 32px;\n}\n.jobsContainer__bra2 .filtersContainer_axxHv .listSearch_nQu_0 {\n  width: 240px;\n}\n\n.filterBtn_w4qK7 {\n  position: relative;\n}\n.filterBtn_w4qK7.active_GUCO0::before {\n  content: \"\";\n  width: 12px;\n  height: 12px;\n  background-color: var(--surface-bg-color);\n  border-radius: 50%;\n  position: absolute;\n  top: -4px;\n  right: -4px;\n}\n.filterBtn_w4qK7.active_GUCO0::after {\n  content: \"\";\n  width: 8px;\n  height: 8px;\n  background-color: var(--primary-color);\n  border-radius: 50%;\n  position: absolute;\n  top: -2px;\n  right: -2px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobsContainer": "jobsContainer__bra2",
	"filtersContainer": "filtersContainer_axxHv",
	"listSearch": "listSearch_nQu_0",
	"filterBtn": "filterBtn_w4qK7",
	"active": "active_GUCO0"
};
export default ___CSS_LOADER_EXPORT___;
