import { Injectable } from '@angular/core';
import { DialogOverlayService } from '../dialog-overlay.service';
import { GenericDialogComponent } from './generic-dialog.component';
import { GenericDialogConfig } from './model';


@Injectable({
  providedIn: 'root'
})
export class GenericDialogService {

  constructor(
    private _dialogOverlayService: DialogOverlayService
  ) {
  }

  open<T>(dialogData: GenericDialogConfig<T>) {
    return this._dialogOverlayService.open(
      GenericDialogComponent,
      dialogData
    );
  }
}
