import React from 'react';
import { TitleGroupProps } from './shared/interfaces';

import Months from './YearView/Months';

export default function YearView<T>(props: TitleGroupProps<T>) {
  function renderMonths() {
    return <Months {...props} />;
  }

  return <div className='react-calendar__year-view'>{renderMonths()}</div>;
}
