import { AnsibleBoolean } from '../../app/core/models/ansible-config';
import { isIPAddress, isLocalhost, isLocal } from '../legacy-utils/networking';

declare const __PROD__: AnsibleBoolean;
declare const __PRE_PROD__: AnsibleBoolean;

const isValidDomain = require('is-valid-domain');

export const hostNameValidator = (host: string): boolean => {
  if (!host) {
    return true;
  }

  if (isIPAddress(host) || isLocalhost(host)) {
    return true;
  }

  return isValidDomain(host, {
    domainName: false,
    wildcard: true,
    allowUnicode: true,
    topLevel: true
  });
};

export const localHostValidator = (host: string, validatorDisabled = false): boolean => {
  if (__PRE_PROD__ === AnsibleBoolean.TRUE) {
    return true;
  }

  if (typeof __PROD__ === 'undefined' || __PROD__ === AnsibleBoolean.FALSE) {
    return true;
  }

  if (validatorDisabled || !host) {
    return true;
  }

  return !isLocal(host);
};
