import React from 'react';
import clsx from 'clsx';
import useUserService from '../../../hooks/services/useUserService';
import { HdRbacButton } from '../../../components/UIElements';
import { RbacPermissions } from '../../../../app/core/models/user';

export default function DeleteAccountStep1({ onDeleteAccount, dataId }) {
  const { isCurrentUserOwnerWithRbac } = useUserService();

  return (
    <div className='box box--table' data-id={`${dataId}-step-1`}>
      <div className='box__header'>
        <span className='box__title'>Delete Account</span>
      </div>

      <div className='box__body'>
        <div className='settings-row'>
          <div className='left'>
            <div className='text-body-3'>
              All of your Pipelines, Models and Workflows will be permanently deleted.
            </div>

            <p className='text-secondary mt-1'>
              We will remove all your Pipelines, Models, and Workflows and the associated metadata
              with them. This operation can not be undone later.
            </p>
          </div>

          <div className='right'>
            <HdRbacButton
              dataId='delete-account'
              className={clsx('btn-outline btn-thumbnail-left btn-secondary-error')}
              disabled={!isCurrentUserOwnerWithRbac}
              variation='outline'
              icon='delete'
              onClick={() => onDeleteAccount()}
              rbacPermission={RbacPermissions.TEAM_DELETE}
              disabledToolTipContent='Only workspace owners can delete the account'
            >
              Delete Account
            </HdRbacButton>
          </div>
        </div>
      </div>
    </div>
  );
}
