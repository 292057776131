import React from 'react';
import { attachListener } from '../utils/keyboardShortcuts';

export function useKeyboardShortcutListeners(bindings, options) {
  React.useEffect(() => {
    const keyboardUnlisten = attachListener(
      bindings,
      options
    );

    return () => {
      if (typeof keyboardUnlisten === 'function') {
        keyboardUnlisten();
      }
    };
  }, [ bindings ]);
}
