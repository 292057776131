import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

declare const __WEBSITE_URL__: string;

@Injectable({ providedIn: 'root' })
export class PostLoginDataUpdateGuard implements CanActivate {

  protected constructor(
    private _http: HttpClient,
    private _router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this._http.get(
      `${ __WEBSITE_URL__ }/auth/post-login-updates`,
      {
        headers: { 'Anonymous': 'True' },
        withCredentials: true
      }
    ).pipe(
      map((res: any) => {
        if (res.data.store_subscription_meta) {
          const accountId = res.data.marketplace_metadata.account_id;
          const accountUrl = `/subscribe-marketplace?marketplace_provider=${res.data.marketplace_metadata.account_provider}&account_id=${accountId}`;
          this._router.navigateByUrl(accountUrl);
          return false;
        }

        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}
