export const BRAZE_REGIONS = [
  {
    name: 'US-01',
    id: 'us-01',
  },
  {
    name: 'US-02',
    id: 'us-02',
  },
  {
    name: 'US-03',
    id: 'us-03',
  },
  {
    name: 'US-04',
    id: 'us-04',
  },
  {
    name: 'US-05',
    id: 'us-05',
  },
  {
    name: 'US-06',
    id: 'us-06',
  },
  {
    name: 'US-08',
    id: 'us-08',
  },
  {
    name: 'EU-01',
    id: 'eu-01',
  },
  {
    name: 'EU-02',
    id: 'eu-02',
  }
];

export const DEFAULT_BRAZE_REGION = BRAZE_REGIONS[0];
