export const DEFAULT_CLICK_ATTRIBUTION_WINDOW = '7d_click';
export const DEFAULT_VIEW_ATTRIBUTION_WINDOW = '1d_view';
export const CLICK_SUFFIX = '_click';
export const VIEW_SUFFIX = '_view';
export const DEFAULT_REPORT_TIME = 'mixed';
export const DEFAULT_AGGREGATION_LEVEL = 'ad';

export const AGGREGATION_LEVEL_NAMES = {
  ad: 'Ad ID',
  adset: 'Ad set ID',
  account: 'Account ID',
  campaign: 'Campaign ID'
};

export const DYNAMIC_CREATIVE_ASSET_SUPPORTED_FIELDS = [
  'impressions',
  'clicks',
  'spend',
  'reach',
  'actions',
  'action_values'
];

export const ATTRIBUTION_SETTINGS = {
  custom: 'use_custom_attribution_setting',
  account: 'use_account_attribution_setting',
  unified: 'use_unified_attribution_setting'
};

export const FB_LOGO_SRC =
  'https://res.cloudinary.com/hevo/image/upload/v1672294644/dashboard/fb-logo-thumb_dy9whp.svg';
