import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnsubscribeNotificationsPageReactComponent } from './unsubscribe-notifications-page-react.component';

const routes: Routes = [
  {
    path: 'notification-preference/unsubscribe/:unsubscriptionType/:identifier',
    component: UnsubscribeNotificationsPageReactComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class NotificationSubscriptionRoutingModule {
}
