import React from 'react';
import styles from './styles.module.scss';
import HdIcon from '../UIElements/HdIcon';
import { getDataIdGenerator } from '../../utils/generateDataId';

export default function FormError({
  className,
  dataId = '',
  variant = 'default',
  children,
}: {
  className?: string;
  dataId?: string;
  variant?: 'default' | 'faded';
  children: React.ReactNode;
}) {
  const dataIdGenerator = getDataIdGenerator(dataId, 'formError');

  return (
    <div
      data-id={dataIdGenerator('')}
      className={`${className} ${styles.formErrorWrapper} ${
        variant === 'faded' ? styles.fadedError : ''
      }`}
    >
      <HdIcon name='error-filled' className={styles.thumbnail} />

      <div className={styles.content}>{children}</div>
    </div>
  );
}
