import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'fixed-footer',
  templateUrl: './fixed-footer.component.html',
  styleUrls: ['./fixed-footer.component.scss']
})
export class FixedFooterComponent implements OnInit {
  @HostBinding('class.fixed-footer') readonly fixedFooterClass = true;

  constructor() { }

  ngOnInit() {
  }

}
