import { Component, HostListener, Input, NgZone, OnInit, Optional } from '@angular/core';
import { SecondaryNavResizeService } from './secondary-nav-resize.service';

@Component({
  selector: 'hd-secondary-nav',
  templateUrl: './secondary-nav.component.html',
  styleUrls: ['./secondary-nav.component.scss']
})
export class SecondaryNavComponent implements OnInit {
  @Input() forceExpanded = false;
  @Input() expandThreshold = 1440;
  @Input() topSpacing = 7;

  expanded = false;

  constructor(
    @Optional() private _resizeService: SecondaryNavResizeService,
    private _ngZone: NgZone
  ) {
  }

  ngOnInit() {
    this.handleExpansion();
  }

  @HostListener('window:resize')
  handleExpansion() {
    if (this.forceExpanded) {
      this.setExpanded(true);
      return;
    }

    const screenWidth = document.body.getBoundingClientRect().width;
    if (screenWidth >= this.expandThreshold) {
      this.setExpanded(true);
    } else {
      this.setExpanded(false);
    }
  }

  setExpanded(expanded) {
    if (this.expanded === expanded) {
      return;
    }

    this.expanded = expanded;

    if (!this._resizeService) {
      return;
    }

    // Notify size change after timeout, as the layout change will occur after microtasks are complete
    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this._resizeService.onResize();
      }, 0);
    });
  }
}
