export const GOOGLE_LOGIN_ERROR_QUERY_PARAM = 'gl_error';

export const TRACKER_LOGIN_CLICK = '[Auth] login click';
export const TRACKER_LOGIN_FORM_ERROR = '[Auth] Login form error';
export const TRACKER_LOGIN_SUBMISSION_ERROR = '[Auth] Login submission error';
export const TRACKER_LOGIN_SUBMISSION_SUCCESS = '[Auth] Login submission success';
export const TRACKER_GOOGLE_LOGIN_CLICK = '[Auth] Google login click';

export const TRACKER_FORGOT_PASSWORD_CLICK = '[Auth] forgot password click';
export const TRACKER_FORGOT_PASSWORD_SUBMIT_CLICK = '[Auth] forgot password submit click';
export const TRACKER_FORGOT_PASSWORD_SUBMIT_ERROR = '[Auth] forgot password submit error';
export const TRACKER_FORGOT_PASSWORD_SUBMIT_SUCCESS = '[Auth] forgot password submit success';

export const TRACKER_ACCOUNT_SETUP_GOOGLE_LOGIN_CLICK = '[Auth] Account setup google login click';
export const TRACKER_ACCOUNT_SETUP_SET_PASSWORD_CLICK = '[Auth] Account setup set password click';
export const TRACKER_ACCOUNT_SETUP_SET_PASSWORD_SUBMIT_ERROR = '[Auth] Account setup set password submit error';
export const TRACKER_ACCOUNT_SETUP_SET_PASSWORD_SUBMIT_SUCCESS = '[Auth] Account setup set password submit success';

export const GOOGLE_LOGIN_ENFORCED_ERROR = 'One of your team owners has enforced Google authentication. ' +
  'Please click CONTINUE WITH GOOGLE to log in.';

export const POLL_USER_DATA_INTERVAL = 900000;
