export const QUANTIVE_REGIONS = [
  {
    name: 'United States',
    value: '.us',
    id: 'us'
  },
  {
    name: 'Europe',
    value: '',
    id: 'default'
  },
  {
    name: 'South America',
    value: '.sa',
    id: 'sa'
  },
  {
    name: 'Asia',
    value: '.as',
    id: 'as'
  }
];

export const NO_SPACE_REGEX = [/^[^ \/]+$/, ''];
export const ROOT_DOMAIN_REGEX = /.+\/\/|www.|\..+/g;

export const FRESHDESK_DEFAULT_HISTORICAL_IMPORT_DAYS = 30;
