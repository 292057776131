import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const BRAZE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPIKeyField({
        hint: 'Enter the API token generated from Settings → Developer Console → Rest API Keys → Create New API Key → Set Permissions → Name it & Save. Copy the token which will be generated.',
      })
    },
    {
      key: 'instanceURL',
      fieldId: 'instance-url',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'instanceURL', label: 'Instance URL', required: true },
      hint: 'Check your Braze instance from your dashboard URL and find out the respective rest endpoint from.',
      sbsDocLink: '/sources/fna-analytics/harvest/#account-id',
      buildParamsKey: 'instance_url',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
