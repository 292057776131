import React from 'react';
import { getDocLink, trackDocLink } from '../../utils/docLinkHandler';
import { HdIcon } from '../UIElements';
import HdButton from '../UIElements/HdButton';

export interface DocsLoadingErrorProps {
  title: string;
  docLink: string;
}

const DOC_LINK_SECTION = 'source_settings_side-by-side_guide';

export default function DocsLoadingError({ title, docLink }: DocsLoadingErrorProps) {
  return (
    <div className='no-item-box errored h-100 m-0'>
      <div className='no-item-box-icon-container'>
        <HdIcon name='error-filled' className='no-item-box-icon' />
      </div>

      <span className='no-item-box-title mb-4'>
        {title}
      </span>

      <HdButton
        icon='new-window'
        variation='outline'
        direction='right'
        tag='a'
        href={getDocLink(docLink, DOC_LINK_SECTION)}
        onClick={e => {
          trackDocLink(docLink, DOC_LINK_SECTION);
        }}
        target='_blank'
        dataId='setup-guide-error'
      >
        Go To Documentation
      </HdButton>
    </div>
  );
}
