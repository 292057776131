import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';
import { getRootDomain } from './utils/helpers';

export const FRESHDESK_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'domain',
      fieldId: 'domain',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: {
        name: 'domain',
        label: 'Subdomain',
        suffixText: '.freshdesk.com',
        required: true
      },
      hint: 'The subdomain of your freshdesk account. e.g. for https://xyz.freshdesk.com the domain will be **xyz**',
      buildParamsKey: 'freshdesk_domain',
      validations: [
        {
          type: 'required'
        }
      ],
      getRawValue: value => getRootDomain(value)
    },
    {
      ...getAPIKeyField({
        label: 'API Token',
        hint: 'Your personal Freshdesk API Key.',
        sbsDocLink: '/sources/crm/freshdesk/#creating-the-api-token',
        buildParamsKey: 'freshdesk_key'
      })
    }
  ],
  [
    {
      key: 'historicalImportDays',
      fieldId: 'historicalImportDays',
      fieldSize: 6,
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'number',
      initialValue: 30,
      widgetOptions: {
        name: 'historicalImportDays',
        label: 'Historical Sync Duration',
        placeholder: 'Historical Sync Duration in Days',
        type: 'number',
        required: true
      },
      validations: [
        {
          type: 'min',
          params: [0]
        },
        {
          type: 'max',
          params: [15000]
        },
        {
          type: 'required'
        }
      ],
      disableEditing: true,
      buildParamsKey: 'historical_import_days',
      hint: 'The duration for which the existing data must be ingested.'
    }
  ]
];
