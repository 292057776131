import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class TaboolaConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      clientId: rawData.client_id,
      clientSecret: rawData.client_secret,
      accounts: rawData.accounts
    };
  }

  getDefaultConfig(metaData: any) {}

  buildConnectionParams(data: any) {
    return {
      client_id: data.clientId,
      client_secret: data.clientSecret,
      accounts: data.accounts
    };
  }
}
