// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.noItemBox_s91E4 {\n  padding: 20px;\n}\n\n.brandLogo_ycIIx {\n  margin-bottom: 32px;\n  width: 174px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noItemBox": "noItemBox_s91E4",
	"brandLogo": "brandLogo_ycIIx"
};
export default ___CSS_LOADER_EXPORT___;
