import React from 'react';
import { Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { getDataIdGenerator } from '../../utils/generateDataId';
import HdButton from '../UIElements/HdButton';
import styles from './styles.module.scss';
import { getErrorMessageFromObj } from '../../legacy-utils/request';
import HdLink from '../UIElements/HdLink';

export interface RetryAPIAlertProps {
  dataId: string;
  variant?: 'default' | 'compact';
  actionHandler?: Function;
  error: { status: number };
  classes?: string;
  compactInlineAction?: boolean;
  errorMessageClassName?: string;
}

function RetryApiAlert({
  dataId,
  variant = 'default',
  error,
  actionHandler,
  classes,
  compactInlineAction = false,
  errorMessageClassName = ''
}: RetryAPIAlertProps) {
  const generatedDataId = getDataIdGenerator(dataId, 'retryConnection');

  return (
    <Box
      className={`${styles.alertWrapper} ${styles[variant]} no-item-box errored ${classes}`}
      data-id={generatedDataId('')}
    >
      <div className={`no-item-box-icon-container ${styles.iconBox}`}>
        <div
          className={`no-item-box-icon ${styles.icon} hevo-${
            error && error.status <= 0 ? 'no-internet' : 'broken'
          }  hevo-icon`}
        />
      </div>

      <div className={styles.text}>
        <Typography variant='subtitle2' component='h4' className={`text-ellipsis ${styles.title}`}>
          Sorry, Something Went Wrong!
        </Typography>

        <Typography component='span' className={clsx(styles.subtext, errorMessageClassName)}>
          {getErrorMessageFromObj(error)} &nbsp;
          {compactInlineAction && variant === 'compact' && actionHandler ? (
            <HdLink tag='button' onClick={actionHandler} dataId={generatedDataId('compactInline')}>
              Try Again
            </HdLink>
          ) : null}
        </Typography>

        {!compactInlineAction && variant === 'compact' && actionHandler ? (
          <HdLink tag='button' onClick={actionHandler} dataId={generatedDataId('compact')}>
            Try Again
          </HdLink>
        ) : null}
      </div>

      {variant === 'default' && actionHandler ? (
        <HdButton
          variation='outline'
          className='mt-4'
          filledBg
          size='sm'
          onClick={actionHandler}
          dataId={generatedDataId('default')}
        >
          Try Again
        </HdButton>
      ) : null}
    </Box>
  );
}

export default RetryApiAlert;
