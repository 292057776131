import React from 'react';
import { DESTINATION_TYPES } from '../../../app/nodes/destination-type/model';
import { NodeLogoWithText } from '../../containers/drawer/Shared/NodeLogoWithText';
import { OptionsType } from '../UIElements/HdDropDown';

export interface DestinationFilterCustomOptionProps {
  option: OptionsType;
}
export function DestinationFilterCustomOption({ option }: DestinationFilterCustomOptionProps) {
  const warehouseLogoInfo = {
    logoURL: option.img,
    darkModeLogoURL: option.darkModeImg,
    primaryColor: DESTINATION_TYPES[option.type].primaryColor,
    darkModePrimaryColor: DESTINATION_TYPES[option.type].darkModePrimaryColor
  };
  return (
    <NodeLogoWithText
      option={warehouseLogoInfo}
      name={option.name}
      size={2}
      logoClassName='mr-3'
      roundedBorders
      zIndex={1305}
    />
  );
}
