export class ModelStrings {
  static deleteModelTitle = 'Delete';
  static deleteModelMessage = 'Are you sure you want to delete this model?';
  static deleteModelPositiveBtn = 'Yes, delete this model';
  static deleteModelNegativeBtn = 'No';
  static deleteModelErrorTitle = 'Error Deleting Model';
  static deleteModelErrorPositiveBtn = 'Ok';

  static deleteDbtProjectTitle = 'Delete the dbt™ Project 😞'
  static deleteDbtProjectMessage = 'This action CANNOT be undone. The dbt™ Project will be permanently deleted from your account.'
  static deleteDbtProjectPositiveBtn = 'Yes, delete this dbt™ Project';
  static deleteDbtProjectNegativeBtn = 'Cancel';

  static defaultModelName = 'Untitled Model';
  static incrementalModelDescription = 'Export only new or changed Source query data on each run.';

  static reauthoriseWarningBody = 'Do you have the required permissions to read and write data for this Destination?' +
    ' Your Models may fail to execute properly if you do not have the required permissions.';

  static modelUnsavedAlertMsg = 'Changes in the Query haven\'t been saved yet. Do you wish to leave and discard the changes?';
}
