import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input, OnInit,
  Output,
} from '@angular/core';
import { elementHasSelection } from '../../../react/legacy-utils/selection';
import { ListKeyManagerOption } from '@angular/cdk/a11y';
import { NestedKeyboardNavigationItem } from '../../a11y/nested-keyboard-navigation/nested-keyboard-navigation-item';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fa-option',
  template: `
      <mat-checkbox
        *ngIf="multi && !isSelectAll"
        class="mr-2"
        [checked]="isActive"
        color="primary"
        onclick="return false"
        [disabled]="disabled">
      </mat-checkbox>
      <ng-content></ng-content>
  `,
  styleUrls: ['./fa-option.component.scss'],
  providers: [{ provide: NestedKeyboardNavigationItem, useExisting: FaOptionComponent }]
})
export class FaOptionComponent implements OnInit,  ListKeyManagerOption {
  @Input() option: any;
  @Input() isSelectAll: boolean; // Custom checkbox is added for Select All case
  @HostBinding('class.active') @Input() isActive = false;
  @HostBinding('class.option-disabled') @Input() disabled = false;
  @HostBinding('class.focused') isFocused = false;
  @HostBinding('class.flexible-checkbox') @Input() multi = false;

  @Output() select = new EventEmitter<MouseEvent | KeyboardEvent>();
  @Output() keyboardFocus = new EventEmitter<void>();

  value: any;

  constructor(
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this.value = this.option;
  }

  @HostListener('click', ['$event']) onSelect(e: any) {
    if (elementHasSelection(this._el.nativeElement)) {
      e.stopPropagation();
      return;
    }
    if (!this.disabled) { this.select.emit(this.option); }
  }

  onEnter(event: KeyboardEvent) {
    this.onSelect(event);
  }

  isParent() {
    return false;
  }

  onFocus() {
    this.isFocused = true;
    this.keyboardFocus.emit();
  }

  onUnFocus() {
    this.isFocused = false;
  }

  getElementTop() {
    return this._el.nativeElement.offsetTop;
  }

  getHeight() {
    return this._el.nativeElement.offsetHeight;
  }
}
