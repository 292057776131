<ng-template>

  <div class="popup-wrapper {{ popupContainerClass }}">
    <div
      class="popup {{ popupClasses }}"
      [style.height]="troubleshootPopupHeight + 'px'"
      [style.width]="troubleshootPopupWidth + 'px'"
      [style.right]="troubleshootPopupRight + 'px'"
      #container>

      <ng-content></ng-content>
    </div>
  </div>

</ng-template>
