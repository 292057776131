import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivityLogDialogContent, ActivityLogDialogContentProps } from '../../../react/containers/activity-log/ActivityLogDialog/ActivityLogDialogContent';
import { fadeAnim, popupAnim } from '../../dialog/animations';
import { DialogBase } from '../../dialog/dialog-base';
import { DialogOverlayRef } from '../../dialog/dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../../dialog/dialog-overlay.tokens';
import { Dialog } from '../../dialog/models';
import { BridgeService } from '../../react-bridge/services/bridge.service';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { ActivityLogDialogData } from './interface';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'activity-log-dialog-react',
  templateUrl: './activity-log-dialog-react.component.html',
  styleUrls: [ './activity-log-dialog-react.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ],
  providers: [ BridgeService ]
})
export class ActivityLogDialogReactComponent extends DialogBase implements OnInit, Dialog {
  entityFilters: any;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: ActivityLogDialogData,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _bridgeService: BridgeService
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();
    this.entityFilters = this._data.entityFilters;
    super.show();
    this.renderReactComponent();
  }

  renderReactComponent() {
    this._bridgeService.mount<ActivityLogDialogContentProps>(
      ActivityLogDialogContent,
      this.contentRef.nativeElement,
      {
        entityFilters: this.entityFilters,
        onCloseDialog: () => {
          this.hide();
        }
      }
    );
  }
}
