<div
  class="cta-option"
  (mouseover)="arrowAnim = 'open'"
  (mouseleave)="checkDropdownMenuFlag($event)"
  (onMenuOpen)="onCTAMenuOpen()"
  (onMenuClose)="onCTAMenuCloseWithButton()"
  [hdMenuTrigger]="arrowOptions"
  *ngIf="count > 0 || (isWidgetOpen ? hasUncertainCount && sidelineMessagesFound : hasUncertainCount)">
  <div
    [ngClass]="{'cta-text-border-onmenuopen': ctaMenuOpenFlag}"
    class="cta-text cta-text-border">
    <ng-container *ngIf="hasUncertainCount && count >= 1000">
      {{ count | numFormatter }}+ Events Not Loaded
    </ng-container>

    <ng-container *ngIf="hasUncertainCount && count < 1000">
      A Few Events Not Loaded
    </ng-container>

    <ng-container *ngIf="!hasUncertainCount">
      {{ count | numFormatter }} {{ 'Event' | nounPluralFormatter: { count: count } }} Not Loaded
    </ng-container>
  </div>

  <button
    [@hoverButtonAnim]="arrowAnim"
    #ctaButton
    type="button"
    class="btn cta-button">
    <hd-icon name="dropdown-arrow"></hd-icon>
  </button>
</div>

<hd-menu #arrowOptions (close)="onCTAMenuCloseWithoutButton()">
  <hd-menu-item
    *ngFor="let action of sidelineMessageDisplayActions"
    class="menu-icon"
    [disabled]="action.disabled"
    [iconName]="action.icon"
    [tooltip]="action.disabledMessage"
    [tooltipDisabled]="!action.disabledMessage"
    (select)="actionClick(action.value, sidelineMessage)">
    {{ action.name }}
  </hd-menu-item>

  <hd-menu-item
    *ngIf="!isWidgetOpen"
    iconName="view-password"
    (select)="actionClick('VIEW_DETAILS', sidelineMessage)">
    View Details
  </hd-menu-item>

  <hd-menu-item
    *ngIf="isWidgetOpen"
    iconName="no-preview"
    (select)="actionClick('VIEW_DETAILS', sidelineMessage)">
    Hide Details
  </hd-menu-item>
</hd-menu>
