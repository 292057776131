export enum CommunicationMessageEvents {
  DASH_COLOR_MODE_CHANGE = 'DASH_COLOR_MODE_CHANGE',
  DASH_SUBTEXT_LINK_CLICK = 'DASH_SUBTEXT_LINK_CLICK',
  DASH_LOAD_DOCS = 'DASH_LOAD_DOCS',
  DASH_LOAD_TROUBLESHOOT = 'DASH_LOAD_TROUBLESHOOT',
  DASH_LOAD_SHARE_BUTTON = 'DASH_LOAD_SHARE_BUTTON',
  DASH_SKIP_TO_SECTION = 'DASH_SKIP_TO_SECTION',
  DASH_LOAD_HELP_NOTES = 'DASH_LOAD_HELP_NOTES',
  DASH_GET_HELP_NOTES_DIMENSION = 'DASH_GET_HELP_NOTES_DIMENSION',
  DASH_HELP_NOTES_LINK_CLICK = 'DASH_HELP_NOTES_LINK_CLICK',
  DASH_GET_INTRO_MODE_DIMENSIONS = 'DASH_GET_INTRO_MODE_DIMENSIONS',
  DOCS_LINK_CLICK = 'DOCS_LINK_CLICK',
  DOCS_SCROLL_EVENT = 'DOCS_SCROLL_EVENT',
  DOCS_SCROLL_TOP_ClICK = 'DOCS_SCROLL_TOP_ClICK',
  DOCS_SHARE_BUTTON_ClICK = 'DOCS_SHARE_BUTTON_ClICK',
  DOCS_SKIP_TO_SECTION_CLICK = 'DOCS_SKIP_TO_SECTION_CLICK',
  DOCS_HEADER_CLICK = 'DOCS_HEADER_CLICK',
  DOCS_TABS_LOADED = 'DOCS_TABS_LOADED',
  DOCS_TABS_INTERACTION = 'DOCS_TABS_INTERACTION',
}
