import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DestinationMode } from '../../../../../app/core/models/destination-mode';
import { AuthService } from '../../../../../app/core/service/auth.service';
import { DestinationService } from '../../../../../app/core/service/destination.service';
import { MutationService } from '../../../../../app/core/service/mutation.service';
import { SettingsStorageService } from '../../../../../app/core/service/settings-storage.service';
import {
  getBackendErrorCodeFromObj,
  getErrorMessageFromObj
} from '../../../../legacy-utils/request';
import { DestinationConnectionActionableErrorStatus } from '../../../../../app/destination/models';
import { ConfirmDialogService } from '../../../../../app/dialog/confirm-dialog/confirm-dialog.service';
import { ConfirmationDialogVersions } from '../../../../../app/dialog/models';
import { DestinationConfigHelperService } from '../../../../../app/nodes/destination-settings-form/destination-config-helper.service';
import { DestinationSettingsService } from '../../../../../app/nodes/destination-settings-form/destination-settings.service';
import { DestinationTypeConfigHelper } from '../../../../../app/nodes/destination-settings-form/destination-type-config-helper';
import { getDestinationConfigFlatModel } from '../../../../../app/nodes/destination-settings-form/utils';
import { DESTINATION_TYPES } from '../../../../../app/nodes/destination-type/model';
import { TRACKER_DEST_CONFIG_UPDATE_WARNING } from '../DestinationUpdateWarningDialog/constants';
import { SnowflakeAutoFillExperimentService } from '../../../../../app/nodes/nodes-settings/experiments';
import { SetupGuideLinkTrackingParams } from '../../../../../app/nodes/nodes-settings/setup-guide-link';
import { getDestinationModeKeywords } from '../../../../../app/nodes/nodes-settings/utils';
import { SetupGuideCommService } from '../../../../../app/nodes/services/setup-guide-comm.service';
import { SettingsFormSubmissionMode } from '../../../../../app/nodes/submission-mode';
import {
  TRACKER_DEST_CONFIG_SUBMIT,
  TRACKER_DEST_CONFIG_SUBMIT_FAILED,
  TRACKER_DEST_CONFIG_SUBMIT_FORM_ERROR,
  TRACKER_DEST_CONFIG_SUBMIT_START,
  TRACKER_DEST_CONFIG_SUBMIT_SUCCESS,
  TRACKER_DEST_CONFIG_TEST,
  TRACKER_DEST_CONFIG_TEST_FAIL,
  TRACKER_DEST_CONFIG_TEST_FORM_ERROR,
  TRACKER_DEST_CONFIG_TEST_START,
  TRACKER_DEST_CONFIG_TEST_SUCCESS,
  TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR_CLICK
} from '../../../../../app/nodes/tracking';
import { StorageKey } from '../../../../../app/pipeline/storage-key';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import useService from '../../../../hooks/useService';
import useToaster from '../../../../hooks/useToaster';
import ConfigDestinationType from '../ConfigDestinationType/ConfigDestinationType';
import { Action, ActionKind, SettingFormProps, State } from './model';
import { DestinationUpdateWarningDialog } from '../DestinationUpdateWarningDialog';
import useTeamSettingsService from '../../../../hooks/services/useTeamSettingsService';

const reducer = (state: State, action: Action): State => {
  const { type, data } = action;

  switch (type) {
    case ActionKind.SET_FORM_SUCCESS: {
      return { ...state, formSuccess: data };
    }

    case ActionKind.SET_FORM_ERROR: {
      return { ...state, formError: data };
    }

    case ActionKind.CLEAR_FORM_ERROR_METADATA: {
      return {
        ...state,

        formError: undefined,
        formErrorCode: undefined,
        formErrorFor: undefined
      };
    }

    case ActionKind.UPDATE_FORM_ERROR_METADATA: {
      return { ...state, ...data };
    }

    case ActionKind.SET_CONNECTING: {
      return { ...state, connecting: data };
    }
    case ActionKind.SET_SUBMITTING: {
      return { ...state, submitting: data };
    }

    case ActionKind.CONNECTION_SUCCESS: {
      return { ...state, connecting: false, formSuccess: 'Connection successful' };
    }

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
};

export default function DestinationSettingsForm({
  destinationType,
  destinationMode,
  updateConnection,
  hevoEntityFor,
  destination,
  draftDestination,
  selectedExistingDestination,
  frozenFields,
  onConnectionError,
  onConnectionSuccess,
  onSubmit,
  onFormDirty,
  onFormFieldChange,
  onFormFieldFocusIn,
  onFormDataChanges,
  onInviteMember
}: SettingFormProps) {
  const [destinationUpdateWarningConfig, setDestinationUpdateWarningConfig] = useState<any>({
    visible: false
  });

  const _destroyed$ = useRef(new Subject());
  const _destinationUpdateWarningResponse$ = useRef(new Subject());
  const _destinationService = useService(DestinationService);
  const _confirmDialogService = useService(ConfirmDialogService);
  const _mutationService = useService(MutationService);
  const _analyticsTracker = useAnalyticsTracker();
  const _authService = useService(AuthService);

  const _configHelperService = useService(DestinationConfigHelperService);
  const _destinationSettingService = useService(DestinationSettingsService);
  const _snowflakeAutoFillExperimentService = useService(SnowflakeAutoFillExperimentService);
  const _setupGuideCommService = useService(SetupGuideCommService);
  const _settingsStorageService = useService(SettingsStorageService);
  const { isDraftPipelineEnabled } = useTeamSettingsService();

  const toaster = useToaster();

  const _isHevoEntityPipeline = hevoEntityFor.value === 'PIPELINE';

  const destinationTypeIdentifier = destinationType.dest_type;
  const [state, dispatch] = useReducer(reducer, {
    ..._setApplicableExperimentVariants()
  });
  const {
    connecting,
    submitting,
    isSnowflakeAutoFillExperimentVariant,
    formError,
    formErrorFor,
    formErrorCode,
    formSuccess
  } = state;

  function _setApplicableExperimentVariants() {
    const experimentVariants: any = {};
    if (_isHevoEntityPipeline && !updateConnection) {
      if (_snowflakeAutoFillExperimentService.isExperimentNodeType(destinationTypeIdentifier)) {
        experimentVariants.isSnowflakeAutoFillExperimentVariant =
          _snowflakeAutoFillExperimentService.isExperimentVariant();
      }
    }

    return experimentVariants;
  }

  useEffect(() => {
    _setApplicableExperimentVariants();
  }, []);

  useEffect(() => {
    const destroyed = _destroyed$.current;
    return () => {
      destroyed.next();
      destroyed.complete();
    };
  }, []);

  const getSubmissionErrorTrackingData = error => ({
    reason: getErrorMessageFromObj(error),
    ...getDestinationTypeTrackingProps(),
    ...getHevoContextTrackingProps()
  });

  const getDestinationTypeTrackingProps = () => ({
    destinationType: destinationTypeIdentifier
  });

  const getHevoContextTrackingProps = () => ({
    context: hevoEntityFor.toString()
  });

  const onTestConnectionClick = () => {
    dispatch({ type: ActionKind.SET_FORM_SUCCESS, data: undefined });
    _clearFormErrorMetaData();

    _analyticsTracker.eventTrack({
      action: TRACKER_DEST_CONFIG_TEST,
      properties: {
        ...getDestinationTypeTrackingProps(),
        ...getHevoContextTrackingProps()
      }
    });
  };

  const onTestConnectionFormError = (invalidFields: string[]) => {
    if (invalidFields?.length) {
      _analyticsTracker.eventTrack({
        action: TRACKER_DEST_CONFIG_TEST_FORM_ERROR,
        properties: {
          invalidFields: invalidFields.join(','),
          ...getDestinationTypeTrackingProps(),
          ...getHevoContextTrackingProps()
        }
      });
    }
  };

  const testConnection = () => {
    _testConnection().pipe(take(1), takeUntil(_destroyed$.current)).subscribe();
  };

  const onSettingsSubmitClick = () => {
    dispatch({ type: ActionKind.SET_FORM_SUCCESS, data: undefined });
    _clearFormErrorMetaData();

    _analyticsTracker.eventTrack({
      action: TRACKER_DEST_CONFIG_SUBMIT,
      properties: {
        ...getDestinationTypeTrackingProps(),
        ...getHevoContextTrackingProps()
      }
    });
  };

  const onSettingsSubmitFormError = useCallback(
    (invalidFields: string[]) => {
      if (invalidFields?.length) {
        _analyticsTracker.eventTrack({
          action: TRACKER_DEST_CONFIG_SUBMIT_FORM_ERROR,
          properties: {
            invalidFields: invalidFields.join(','),
            ...getDestinationTypeTrackingProps(),
            ...getHevoContextTrackingProps()
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_analyticsTracker]
  );

  const settingsSubmit = () => {
    const request$ = updateConnection ? _confirmAndEditDestination() : _createDestination();

    request$.pipe(take(1), takeUntil(_destroyed$.current)).subscribe();
  };

  const formErrorClick = () => {
    _analyticsTracker.eventTrack({
      action: TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR_CLICK,
      properties: {
        ...getSubmissionErrorTrackingData(formError),
        formErrorFor
      }
    });
  };

  const reauthorise = () => {
    _authService
      .reAuthoriseAccount(
        destinationTypeIdentifier,
        destination.formData.oauthTokenId,
        window.location.href,
        window.location.href
      )
      .pipe(takeUntil(_destroyed$.current))
      .subscribe((res: any) => {
        if (res && res.data && res.data.consent_url) {
          window.location.assign(res.data.consent_url);
          return;
        }
        toaster.pop('error', undefined, 'Couldn\'t reauthorize this account');
      });
  };

  const _getDropdownList = () => {
    const configHelper: DestinationTypeConfigHelper = _configHelperService.getConfigHelper(
      destinationType.dest_type
    );
    if (configHelper && typeof configHelper.getDropdownList === 'function') {
      return configHelper.getDropdownList();
    }

    return [];
  };

  const _clearFormErrorMetaData = () => {
    dispatch({
      type: ActionKind.CLEAR_FORM_ERROR_METADATA
    });
  };

  const _updateFormErrorMetaData = async (error: any, errorFor: SettingsFormSubmissionMode) => {
    dispatch({
      type: ActionKind.UPDATE_FORM_ERROR_METADATA,
      data: {
        formError: error,
        formErrorCode: getBackendErrorCodeFromObj(error),
        formErrorFor: errorFor
      }
    });
  };

  const _createDestination = (skipErrors = false) => {
    dispatch({ type: ActionKind.SET_SUBMITTING, data: true });

    _analyticsTracker.eventTrack({
      action: TRACKER_DEST_CONFIG_SUBMIT_START,
      properties: {
        ...getDestinationTypeTrackingProps(),
        ...getHevoContextTrackingProps()
      }
    });

    return _destinationSettingService
      .resolveDependenciesBeforeSaving(destinationTypeIdentifier, destination.formData)
      .pipe(
        switchMap((updatedFormData: object) => {
          onFormDataChanges({ ...destination.formData, ...updatedFormData });

          return _destinationService
            .addDestination(
              getDestinationConfigFlatModel(destination),
              destinationTypeIdentifier,
              skipErrors,
              isDestinationModeWarehouse,
              draftDestination?.id || getDraftDestinationDataFromStorage()?.id
            )
            .pipe(
              tap((resp: any) => {
                dispatch({ type: ActionKind.SET_SUBMITTING, data: false });

                // delete draft if there is one and clear local storage for draftDest
                _settingsStorageService.applySettings(
                  StorageKey.CREATE_DRAFT_PIPELINE_DESTINATION,
                  null
                );

                _destinationConfigSubmitSuccess(resp.data);
              }),
              catchError((error: any) => {
                if (destination.type === 'BIGQUERY') {
                  scrollDocOnBigQueryPermissionError(error);
                }
                dispatch({ type: ActionKind.SET_SUBMITTING, data: false });

                const testErrorsHandled = _handleTestConnectionError(
                  error,
                  SettingsFormSubmissionMode.CONTINUE
                );

                if (!testErrorsHandled) {
                  _updateFormErrorMetaData(error, SettingsFormSubmissionMode.CONTINUE);

                  _analyticsTracker.eventTrack({
                    action: TRACKER_DEST_CONFIG_SUBMIT_FAILED,
                    properties: getSubmissionErrorTrackingData(error)
                  });
                }

                onConnectionError(error);

                return of(null);
              })
            );
        }),
        catchError(err => {
          dispatch({ type: ActionKind.SET_SUBMITTING, data: false });

          _updateFormErrorMetaData(err, SettingsFormSubmissionMode.CONTINUE);
          onConnectionError(err);

          return of(null);
        })
      );
  };

  const _editDestination = (skipErrors = false, skipWarnings = false) =>
    of([]).pipe(
      switchMap(() => {
        dispatch({ type: ActionKind.SET_SUBMITTING, data: true });

        _analyticsTracker.eventTrack({
          action: TRACKER_DEST_CONFIG_SUBMIT_START,
          properties: {
            ...getDestinationTypeTrackingProps(),
            ...getHevoContextTrackingProps()
          }
        });

        return _destinationService.editDestination(
          getDestinationConfigFlatModel(destination),
          destinationTypeIdentifier,
          skipErrors,
          skipWarnings
        );
      }),
      switchMap(resp => {
        dispatch({ type: ActionKind.SET_SUBMITTING, data: false });

        if (resp && resp.modified_fields?.length) {
          _analyticsTracker.eventTrack({
            action: TRACKER_DEST_CONFIG_UPDATE_WARNING
          });

          setDestinationUpdateWarningConfig({
            visible: true,
            modifiedFields: resp.modified_fields,
            destinationFlatModel: getDestinationConfigFlatModel(destination),
            destinationTypeIdentifier,
            skipErrors
          });

          const warningSubject: Observable<any> = _destinationUpdateWarningResponse$.current;

          return warningSubject.pipe(
            switchMap(res => {
              if (typeof res === 'boolean' || typeof res === 'undefined') {
                if (res) {
                  _destinationConfigSubmitSuccess();
                }

                return of(null);
              }

              return throwError(res);
            })
          );
        }
        _destinationConfigSubmitSuccess();
        return of(null);
      }),
      catchError((error: any) => {
        if (destination.type === 'BIGQUERY') {
          scrollDocOnBigQueryPermissionError(error);
        }
        dispatch({ type: ActionKind.SET_SUBMITTING, data: false });

        const testErrorsHandled = _handleTestConnectionError(
          error,
          SettingsFormSubmissionMode.EDIT
        );
        if (!testErrorsHandled) {
          _updateFormErrorMetaData(error, SettingsFormSubmissionMode.EDIT);

          _analyticsTracker.eventTrack({
            action: TRACKER_DEST_CONFIG_SUBMIT_FAILED,
            properties: getSubmissionErrorTrackingData(error)
          });
        }

        onConnectionError(error);

        return of(null);
      })
    );

  const _testConnection = (skipErrors = false) => {
    dispatch({ type: ActionKind.SET_CONNECTING, data: true });

    _analyticsTracker.eventTrack({
      action: TRACKER_DEST_CONFIG_TEST_START,
      properties: {
        ...getDestinationTypeTrackingProps(),
        ...getHevoContextTrackingProps()
      }
    });

    return _destinationSettingService
      .resolveDependenciesBeforeTesting(
        destinationTypeIdentifier,
        destination.formData,
        updateConnection
      )
      .pipe(
        switchMap((updatedFormData: object) => {
          onFormDataChanges({ ...destination.formData, ...updatedFormData });
          return _destinationService
            .testConnection(
              getDestinationConfigFlatModel(destination),
              destinationTypeIdentifier,
              skipErrors,
              isDestinationModeWarehouse,
              draftDestination?.id || getDraftDestinationDataFromStorage()?.id
            )
            .pipe(
              tap((resp: any) => {
                dispatch({
                  type: ActionKind.CONNECTION_SUCCESS
                });

                _analyticsTracker.eventTrack({
                  action: TRACKER_DEST_CONFIG_TEST_SUCCESS,
                  properties: {
                    ...getDestinationTypeTrackingProps(),
                    ...getHevoContextTrackingProps()
                  }
                });
                onConnectionSuccess();
              }),
              catchError((error: any) => {
                if (destination.type === 'BIGQUERY') {
                  scrollDocOnBigQueryPermissionError(error);
                }
                dispatch({ type: ActionKind.SET_CONNECTING, data: false });

                const testErrorsHandled = _handleTestConnectionError(
                  error,
                  SettingsFormSubmissionMode.TEST
                );

                if (!testErrorsHandled) {
                  _updateFormErrorMetaData(error, SettingsFormSubmissionMode.TEST);

                  _analyticsTracker.eventTrack({
                    action: TRACKER_DEST_CONFIG_TEST_FAIL,
                    properties: getSubmissionErrorTrackingData(error)
                  });
                }

                onConnectionError(error);

                return of(null);
              })
            );
        }),
        catchError(err => {
          dispatch({ type: ActionKind.SET_CONNECTING, data: false });
          _updateFormErrorMetaData(err, SettingsFormSubmissionMode.TEST);
          onConnectionError(err);

          return of(null);
        })
      );
  };

  const _handleTestConnectionError = (error: any, submissionMode: SettingsFormSubmissionMode) => {
    const errorMsg = getErrorMessageFromObj(error);
    const isBadRequest = error.status === 400;

    let errorMsgParsed;
    try {
      errorMsgParsed = JSON.parse(errorMsg);
    } catch (e) {
      errorMsgParsed = {};
    }

    if (isBadRequest && _isErrorStatusActionable(errorMsgParsed.status)) {
      _doHandleTestConnectionError(errorMsgParsed, submissionMode);
      return true;
    }

    return false;
  };

  const _isErrorStatusActionable = (status: any) =>
    Object.values(DestinationConnectionActionableErrorStatus).includes(status);

  const _doHandleTestConnectionError = (
    errorMessage: any,
    submissionMode: SettingsFormSubmissionMode
  ) => {
    let dropdownElements;

    if (errorMessage.data === undefined) {
      dropdownElements = undefined;
    } else if (errorMessage.data[0] === 'LOCATION') {
      dropdownElements = _getDropdownList();
    }

    _confirmDialogService
      .confirm(
        errorMessage.message.title,
        errorMessage.message.description,
        'Yes, Go ahead',
        'No',
        false,
        dropdownElements
      )
      .subscribe((res: any) => {
        if (res) {
          onFormDataChanges({ ...destination.formData, preferredLocation: res.selected_value });

          if (submissionMode === SettingsFormSubmissionMode.CONTINUE) {
            _createDestination(true).subscribe();
          }

          if (submissionMode === SettingsFormSubmissionMode.EDIT) {
            _editDestination(true, true).subscribe();
          }

          if (submissionMode === SettingsFormSubmissionMode.TEST) {
            _testConnection(true).subscribe();
          }
        }
      });
  };

  const _destinationConfigSubmitSuccess = (data?: any) => {
    onConnectionSuccess();

    _analyticsTracker.eventTrack({
      action: TRACKER_DEST_CONFIG_SUBMIT_SUCCESS,
      properties: {
        ...getDestinationTypeTrackingProps(),
        ...getHevoContextTrackingProps()
      }
    });

    onSubmit(data);
  };

  const _confirmAndEditDestination = () =>
    _mutationService.alertAndMutate(
      _editDestination(),
      {
        title: 'Need your attention',
        body:
          'An update in the configuration will be reflected in' +
          ' all the associated Pipelines/Activations wherever it is being used.'
      },
      null,
      ConfirmationDialogVersions.VERSION_2
    );

  const scrollDocOnBigQueryPermissionError = (error: any) => {
    if (getBackendErrorCodeFromObj(error) === 1011) {
      let setupGuideLink;
      const trackingParams: SetupGuideLinkTrackingParams = {
        medium: 'program',
        sourceSection: 'big-query-destination',
        label: 'big-query-auth-permission-error',
        properties: {
          ...getDestinationTypeTrackingProps(),
          ...getHevoContextTrackingProps()
        }
      };

      if (destination.formData.oauthTokenId) {
        setupGuideLink = '/destinations/data-warehouses/google-bigquery/#using-user-accounts';
      } else {
        setupGuideLink =
          '/destinations/data-warehouses/google-bigquery/#using-google-service-accounts';
      }

      _setupGuideCommService.goToSection(setupGuideLink, {
        ...trackingParams
      });
    }
  };

  const getDraftDestinationDataFromStorage = () => {
    if (!isDraftPipelineEnabled) {
      return null;
    }

    return _settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_DESTINATION);
  };

  const onUpdateWarningDialogClose = (res: any) => {
    _destinationUpdateWarningResponse$.current.next(res);
    setDestinationUpdateWarningConfig({
      ...destinationUpdateWarningConfig,
      visible: false
    });
  };

  const testBtnText = connecting ? 'Connecting' : 'Test Connection';
  const submitBtnText = submitting ? `Saving` : `Save & Continue`;
  const canShowSubmissionOverlay = connecting || submitting;

  const destinationTypeMetaData = DESTINATION_TYPES[destinationTypeIdentifier];
  const destinationModeKeywords = getDestinationModeKeywords(destinationMode);
  const destinationNameFieldLabel = `${destinationModeKeywords.singular} Name`;
  const isDestinationModeWarehouse = destinationMode === DestinationMode.WAREHOUSE;
  const canReauthoriseAccount = 'oauthTokenId' in destination.formData && updateConnection;

  return (
    <>
      <ConfigDestinationType
        destinationTypeIdentifier={destination.type}
        rawDestinationType={destinationType}
        formData={destination.formData || {}}
        formMetaData={destination.metaData}
        isEditing={updateConnection}
        formError={formError ? getErrorMessageFromObj(formError) : null}
        formSuccess={formSuccess}
        submitBtnText={submitBtnText}
        testBtnText={testBtnText}
        submitting={submitting}
        reauthoriseRequest={reauthorise}
        canReauthoriseAccount={canReauthoriseAccount}
        connecting={connecting}
        canShowSubmissionOverlay={canShowSubmissionOverlay}
        destinationMode={destinationMode}
        destinationTypeMetaData={destinationTypeMetaData}
        destinationModeKeywords={destinationModeKeywords}
        destinationNameFieldLabel={destinationNameFieldLabel}
        frozenFields={frozenFields}
        onFormChanges={onFormDataChanges}
        onContinue={settingsSubmit}
        onContinueClick={onSettingsSubmitClick}
        onContinueFormError={onSettingsSubmitFormError}
        onTestConnectionFormError={onTestConnectionFormError}
        onTestConnectionClick={onTestConnectionClick}
        onTestConnection={testConnection}
        markFormDirty={onFormDirty}
        setFormError={data => dispatch({ type: ActionKind.SET_FORM_ERROR, data })}
        setFormSuccess={data => {
          dispatch({ type: ActionKind.SET_FORM_SUCCESS, data });
        }}
        savedDestinationName={destination.formData.name}
        formErrorCode={formErrorCode}
        formErrorClick={formErrorClick}
        formInteraction={onFormFieldChange}
        formFieldFocusIn={onFormFieldFocusIn}
        hevoEntityFor={hevoEntityFor}
        draftDestination={draftDestination}
        selectedExistingDestination={selectedExistingDestination}
        onInviteMember={onInviteMember}
        experiments={{
          isSnowflakeAutoFillExperimentVariant
        }}
      />

      <DestinationUpdateWarningDialog
        destinationUpdateWarningConfig={destinationUpdateWarningConfig}
        onClose={res => onUpdateWarningDialogClose(res)}
      />
    </>
  );
}
