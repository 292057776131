// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dialogContainer_AYAzu {\n  align-items: flex-start;\n}\n\n.dialogHeader_k1jMJ {\n  padding: 24px 24px 16px;\n}\n\n.dialogBody__YbWt {\n  padding: 20px;\n}\n\n.dialogFooter_sdldv {\n  padding: 20px 24px 24px;\n}\n\n.dialogContent_GRHwO {\n  max-width: 100%;\n}\n\n.closeDialogBtn_qaVrU {\n  position: absolute !important;\n  top: 8px;\n  right: 8px;\n}\n\n.schemaDropdown_Sc3w1 {\n  width: 248px !important;\n}\n\n.inputBox_DgkDi {\n  width: 164px !important;\n}\n\n.frequencyForm_iRJEv {\n  max-width: 490px;\n}\n\n.cronTimeList_Xfn1B {\n  max-height: 220px;\n  max-width: 490px;\n  overflow: auto;\n}\n\n.schemaDropdown_Sc3w1 {\n  width: 248px !important;\n}\n\n.multiCronButton_CTSdh {\n  align-self: center;\n  max-height: fit-content;\n}\n\n.w50__uErl {\n  width: 50% !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": "dialogContainer_AYAzu",
	"dialogHeader": "dialogHeader_k1jMJ",
	"dialogBody": "dialogBody__YbWt",
	"dialogFooter": "dialogFooter_sdldv",
	"dialogContent": "dialogContent_GRHwO",
	"closeDialogBtn": "closeDialogBtn_qaVrU",
	"schemaDropdown": "schemaDropdown_Sc3w1",
	"inputBox": "inputBox_DgkDi",
	"frequencyForm": "frequencyForm_iRJEv",
	"cronTimeList": "cronTimeList_Xfn1B",
	"multiCronButton": "multiCronButton_CTSdh",
	"w50": "w50__uErl"
};
export default ___CSS_LOADER_EXPORT___;
