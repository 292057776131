import moment from 'moment-mini-ts';
import { IStackedBarPoints } from '../../app/graph/model';
import { EventsBarGraphDataPoint } from '../../app/core/models/events-bar-graph-data-point';


export function convertEventsBarGraphDataPointsToStackedData(dataPoints: EventsBarGraphDataPoint[]): IStackedBarPoints {
  return dataPoints.map((dataPoint) => ({
    name: moment(dataPoint.startTime).unix(),
    value: dataPoint.events.reduce((sum, event) => sum + event, 0),
    stackedData: dataPoint.events,
    extra: {
      tooltipData: {
        billableEvents: dataPoint.billableEvents,
        historicalEvents: dataPoint.historicalEvents,
        displayString: dataPoint.displayString,
        startTime: dataPoint.startTime,
        endTime: dataPoint.endTime
      }
    }
  }));
}

export function groupStatsRawDataOnObjectKey(objectStatsMap: { [key: string]: any }, stats): { [key: string]: any } {
  const { description: key, points, ...objectStatData } = stats;

  return {
    ...objectStatsMap,
    [key]: {
      pointsData: points,
      additionalData: objectStatData
    }
  };
}
