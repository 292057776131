import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnsibleBoolean } from '../../../app/core/models/ansible-config';
import { HevoCustomersFactory, HevoTestimonialsFactory } from '../../legacy-utils/customers';
import { CLIENTS, TESTIMONIALS } from './TestimonialSection/constants';
import useService from '../../hooks/useService';

declare const __WEBSITE_URL__: string;
declare const __DEPLOYMENT_ENV__: string;
declare const __LOAD_FEATURED_CUSTOMERS_FROM_WEBSITE__: AnsibleBoolean;

export function useFeaturedCustomers() {
  const http = useService(HttpClient);

  const getFeaturedCustomers = () => {
    const customersUrl = `${ __WEBSITE_URL__ }/hevo-utility/dashboard-data?env=${__DEPLOYMENT_ENV__}`;

    const defaultData = {
      customers: CLIENTS,
      testimonials: TESTIMONIALS
    };

    if (__LOAD_FEATURED_CUSTOMERS_FROM_WEBSITE__ !== AnsibleBoolean.TRUE) {
      return of(defaultData);
    }


    return http.get(customersUrl, {
      headers: { 'Anonymous': 'True' }
    }).pipe(
      map((res: any) => {
        return {
          customers: res.customers?.length ? HevoCustomersFactory(res.customers) : CLIENTS,
          testimonials: res.testimonials?.length ? HevoTestimonialsFactory(res.testimonials) : TESTIMONIALS
        };
      }),
      catchError((err) => {
        return of(defaultData);
      })
    ).toPromise();
  };

  return {
    getFeaturedCustomers
  };
}
