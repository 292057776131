import React from 'react';
import { TableHeaderType } from '../../models';
import styles from './styles.module.scss';

const headerTypes = TableHeaderType;

export function NotificationTableView({ data }) {
  return (
    <div className={styles.notificationTableView}>
      <table className={styles.notificationTable}>
        <tbody>
          {data.rows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={
                data.headerType === headerTypes.ROW_1 && rowIndex === 0 ? styles.header : ''
              }
            >
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={`${cellIndex > 0 ? 'pl-2' : ''}  ${styles.notificationTableCell} ${
                    data.headerType === headerTypes.COLUMN_1 && cellIndex === 0 ? styles.header : ''
                  } `}
                >
                  <span>
                    {cell}
                    {data.headerType === headerTypes.COLUMN_1 &&
                    row.length === 2 &&
                    cellIndex === 0 ? (
                      <span>:</span>
                    ) : null}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
