import { EntityStatus } from '../../core/models/entity-status';
import { Enum } from '../../core/models/object-enum';
import { getCalendarDateWithTimezone } from '../../../react/legacy-utils/date';

export class FileStatusEnum extends Enum {
  static NEW = new EntityStatus(
    'New',
    'NEW',
    'info'
  );
  static DOWNLOADING = new EntityStatus(
    'Downloading',
    'DOWNLOADING',
    'info-1'
  );
  static DOWNLOADED = new EntityStatus(
    'Downloaded',
    'DOWNLOADED',
    'info-2'
  );
  static INGESTING = new EntityStatus(
    'Ingesting',
    'INGESTING',
    'success-1'
  );
  static INGESTED = new EntityStatus(
    'Ingested',
    'INGESTED',
    'success'
  );
  static FAILED = new EntityStatus(
    'Failed',
    'FAILED',
    'error'
  );
  static SKIPPED = new EntityStatus(
    'Skipped',
    'SKIPPED',
    'default'
  );
  static NEW_COMPRESSED_FILE = new EntityStatus(
    'Decompressed',
    'NEW_COMPRESSED_FILE',
    'info'
  );
}

export class SourceFile {
  id: number;
  integrationId: number;
  name: string;
  size: number;
  numRecords: number;
  status: FileStatusEnum;
  failureReason: string;
  failureReasonDisplay: string;
  failedTs: Date;
  ingestedTs: Date;
  createdTs: Date;
  downloadedTs: Date;
  modifiedTs: Date;

  getStatusDisplayTime() {
    switch (this.status) {
      case FileStatusEnum.DOWNLOADED: {
        return this.downloadedTs;
      }
      case FileStatusEnum.INGESTED: {
        return this.ingestedTs;
      }
      case FileStatusEnum.FAILED: {
        return this.failedTs;
      }
      case FileStatusEnum.SKIPPED: {
        return this.failedTs;
      }
      default: {
        return undefined;
      }
    }
  }

  getStatusDisplayTimeFormatted() {
    const time = this.getStatusDisplayTime();
    if (!time) {
      return;
    }
    return getCalendarDateWithTimezone(time.getTime());
  }
}

export function SourceFileFactory(rawData: any): SourceFile {
  const file = new SourceFile();
  file.id = rawData.id;
  file.integrationId = rawData.integration_id;
  file.name = rawData.file_name;
  file.size = rawData.file_size;
  file.numRecords = rawData.num_records;
  file.status = FileStatusEnum[rawData.status];
  file.failureReason = rawData.failure_reason;
  file.failureReasonDisplay = file.failureReason ? file.failureReason.substring(0, 500) : undefined;
  file.failedTs = rawData.failed_at ? new Date(rawData.failed_at) : undefined;
  file.ingestedTs = rawData.ingested_at ? new Date(rawData.ingested_at) : undefined;
  file.createdTs = rawData.created_ts ? new Date(rawData.created_ts) : undefined;
  file.downloadedTs = rawData.downloaded_at ? new Date(rawData.downloaded_at) : undefined;
  file.modifiedTs = rawData.modified_time;
  return file;
}

export function SourceFileListFactory(rawData: any): SourceFile[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((rawFile: any) => {
    return SourceFileFactory(rawFile);
  });
}

export interface IntegrationLag {
  effectiveLag: number;
  minimumLag: number;
  mandatoryLag: number;
}

export function IntegrationLagFactory(rawData: any): IntegrationLag {
  if (!rawData) {
    return undefined;
  }

  return {
    effectiveLag: rawData.effective_lags.SINK,
    minimumLag: rawData.minimum_lags.SINK,
    mandatoryLag: rawData.mandatory_lags.SINK
  };
}

export function MockIntegrationLagFactory(): IntegrationLag {
  return {
    effectiveLag: 86400001,
    minimumLag: 1000,
    mandatoryLag: 1000
  };
}

export interface PipelineStatus {
  name: string;
  colorType?: string;
  value: string | string[];
}

export enum PipelineErrorActions {
  RESTART_HISTORICAL_LOAD = 'RESTART_HISTORICAL_LOAD',
  CHANGE_FREQUENCY = 'CHANGE_FREQUENCY',
  MANUAL_ACTION = 'MANUAL_ACTION',
  SKIP_OBJECTS = 'SKIP_OBJECTS',
  RESUME_OBJECTS = 'RESUME_OBJECTS',
  PAUSE_OBJECTS = 'PAUSE_OBJECTS',
  RESTART_OBJECTS = 'RESTART_OBJECTS',
  INCLUDE_OBJECTS = 'INCLUDE_OBJECTS',
  RUN_NOW_OBJECTS = 'RUN_NOW_OBJECTS',
  CONFIG_SOURCE = 'CONFIG_SOURCE',
  OPTIMIZE_QUERY_MODE = 'OPTIMIZE_QUERY_MODE'
}

export enum PipelinePriorityEnum {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM'
}

export enum PipelineRelatedTaskFilterOptionsEnum {
  ALL = 'ALL',
  FAILED = 'FAILED',
  INGESTING = 'INGESTING',
  SCHEDULED = 'SCHEDULED',
}

export interface ExitPipelineFeedback {
  pipeline: any,
  reasons: string,
  reasonExplanation?: string,
  exitPipeline: boolean
}
