import { Component, ElementRef, OnInit } from '@angular/core';
import { DrawersContainer } from '../../react/containers/drawer/DrawersContainer';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'react-drawers-container',
  template: '',
  providers: [ BridgeService ]
})
export class ReactDrawersContainerComponent implements OnInit {
  constructor(
    private _el: ElementRef,
    private _bridgeService: BridgeService
  ) {
  }

  ngOnInit() {
    this._bridgeService.mount(
      DrawersContainer,
      this._el.nativeElement,
      {}
    );
  }
}
