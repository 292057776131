import {
  Component,
  OnDestroy,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
  AfterContentInit,
  NgZone,
  OnChanges
} from '@angular/core';

import { MenuItem } from '../menu-item';
import { ESCAPE, UP_ARROW, DOWN_ARROW } from '@angular/cdk/keycodes';
import { FocusKeyManager, FocusOrigin } from '@angular/cdk/a11y';
import { Subscription, Observable, Subject, merge } from 'rxjs';
import { OverlayPosition } from '../../overlay/types';
import { MENU_FALLBACK_POSITIONS} from '../../overlay/constants';
import { startWith, switchMap } from 'rxjs/operators';
import { executeOnStable } from '../../../react/legacy-utils/zone';
import { TOOLTIP_FALLBACK_POSITIONS } from '../../popup/constants';

@Component({
  selector: 'hd-menu',
  templateUrl: './hd-menu.component.html',
  styleUrls: [ 'hd-menu.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HdMenuComponent implements AfterContentInit, OnDestroy, OnChanges {
  visible = false;
  trigger: any;

  menuItemsClickObs: any;

  private _keyManager: FocusKeyManager<MenuItem>;
  private _tabSubscription = Subscription.EMPTY;

  parentMenu: HdMenuComponent;

  /** Emits whenever the amount of menu items changes. */
  private _itemChanges = new Subject<MenuItem[]>();

  private _focusOrigin: FocusOrigin = 'program';

  /** Menu items inside the current menu. */
  @ContentChildren(MenuItem, {descendants : true}) private _items: QueryList<MenuItem> = new QueryList<MenuItem>();

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() offsetX = 0;
  @Input() offsetY = 0;

  @Input() displayType: string;
  @Input() theme = 'default';
  @Input() classes = '';
  @Input() maxHeight = '220px';

  @Output() close = new EventEmitter<string>();


  constructor(private eRef: ElementRef, private _zone: NgZone) {}

  overlayPosition: OverlayPosition = MENU_FALLBACK_POSITIONS[0];
  fallbackPositions: OverlayPosition[] = MENU_FALLBACK_POSITIONS;

  _handleKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case ESCAPE:
        this.close.emit('keydown');
        event.stopPropagation();
      break;
      default:
      if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
          this._keyManager.setFocusOrigin('keyboard');
        }
        this._keyManager.onKeydown(event);
    }
  }

  ngAfterContentInit() {
    this.initKeyManagerAndFocus();

    this._tabSubscription = this._keyManager.tabOut.subscribe(() => {
      this.close.emit();
    });

    this._items.changes
    .pipe(startWith(null))
    .subscribe(() => {
      this.focusFirstItem(this._focusOrigin);

      executeOnStable(this._zone, () => {
        this.updateSeparatorIndices();
      });
    });
  }

  initKeyManagerAndFocus(): void {
    this._keyManager = new FocusKeyManager<MenuItem>(this._items).withWrap();
  }

  focusFirstItem(origin: FocusOrigin = 'program'): void {
    this._focusOrigin = origin;
    this._keyManager.setFocusOrigin(origin).setFirstItemActive();
  }

  ngOnDestroy() {
    this._tabSubscription.unsubscribe();
  }

  ngOnChanges(changes: any) {
    if (changes.displayType) {
      if (this.displayType === 'tooltip') {
        this.overlayPosition = {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'bottom'
        };
        this.fallbackPositions = TOOLTIP_FALLBACK_POSITIONS;
      } else {
        this.overlayPosition = MENU_FALLBACK_POSITIONS[0];
        this.fallbackPositions = MENU_FALLBACK_POSITIONS;
      }
    }
  }

  /** Stream that emits whenever the hovered menu item changes. */
  _hovered(): Observable<MenuItem> {
    return this._items.changes.pipe(
      startWith(this._items),
      switchMap(items => merge<MenuItem>(...items.map(item => item._hovered)))
    );
  }

  /**
   * Registers a menu item with the menu.
   * @docs-private
   */
  addItem(item: MenuItem) {
    // We register the items through this method, rather than picking them up through
    // `ContentChildren`, because we need the items to be picked up by their closest
    // `mat-menu` ancestor. If we used `@ContentChildren(MatMenuItem, {descendants: true})`,
    // all descendant items will bleed into the top-level menu in the case where the consumer
    // has `mat-menu` instances nested inside each other.
    // if (this._items.indexOf(item) === -1) {
    //   this._items.push(item);
    //   this._itemChanges.next(this._items);
    // }
  }

  /**
   * Removes an item from the menu.
   * @docs-private
   */
  removeItem(item: MenuItem) {
    // const index = this._items.indexOf(item);

    // if (this._items.indexOf(item) > -1) {
    //   this._items.splice(index, 1);
    //   this._itemChanges.next(this._items);
    // }
  }

  updateSeparatorIndices() {
    this._items.forEach((item, index) => {
      if (item.withSeparator && index > 0) {
        item.hasSeparator = true;
      } else {
        item.hasSeparator = false;
      }
    });
  }
}
