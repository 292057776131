import { InclusionItemKey } from '../../../../../../app/core/models/inclusion-list-v2';
import { addSuffixWhenNounPlural, capitalise } from '../../../../../legacy-utils/string';
import { getSelectorInclusionStateFromRawData } from '../../../../../../app/shared/single-level-selector/model';

import { AGGREGATION_LEVEL_NAMES } from './constants';
import { FBField } from './interface';

export function FieldListFactory(rawData: string[], fieldsDisplay: FBField[]) {
  return rawData.map(field => ({
    value: field,
    id: field,
    ...fieldsDisplay?.[field]
  }));
}

export function getAggregationTimeDisplayName(aggregation: any) {
  return `${aggregation.value} ${addSuffixWhenNounPlural(
    capitalise(aggregation.unit),
    aggregation.value
  )}`;
}

export function AggregationLevelsFactory(rawData: string[]) {
  return rawData.map(level => ({
    displayName: AGGREGATION_LEVEL_NAMES[level],
    value: level
  }));
}

export function AggregationTimesFactory(rawData: any[]) {
  return rawData.map(aggregation => ({
    ...aggregation,
    displayName: getAggregationTimeDisplayName(aggregation),
    valueWithUnit: aggregation.value + aggregation.unit
  }));
}

/**
   * Check the incompatible map and remove the fields from options.
   * When formData contains entire if_selected, we remove the un_selectable fields
   * incompatibleSelections example:
   * "incompatible_selections": [
      {
          "if_selected": {
              "report_times": [
                "impression"
              ]
          },
          "un_selectable": {
              "fields": [
                  "instant_experience_clicks_to_open",
                  "instant_experience_clicks_to_start"
              ]
          }
      },
     ]
   */

export const getIncompatibleFields = (formValues, formMetaData, isEditing) => {
  const fieldsToRemove = [];
  if (isEditing) {
    return fieldsToRemove;
  }
  const { reportTime, level, breakdownsList, clickAttributionWindow, viewAttributionWindow } =
    formValues;

  formMetaData.incompatibleSelections.forEach(incompatibleEntriesPair => {
    const ifSelectedCustomReportEntries = incompatibleEntriesPair.if_selected;
    // Null or undefined check
    if (ifSelectedCustomReportEntries == null) {
      return;
    }
    let currentSelectedEntriesMatchesIncompatibleEntries = true;
    // Check for report_times.
    if (ifSelectedCustomReportEntries.report_times) {
      // this.formData.reportTime is string. If it will be array we would change names to this.formData.reportTimes
      currentSelectedEntriesMatchesIncompatibleEntries =
        currentSelectedEntriesMatchesIncompatibleEntries &&
        ifSelectedCustomReportEntries.report_times.every(val => reportTime === val);
    }
    // Check for levels.
    if (ifSelectedCustomReportEntries.levels) {
      // this.formData.level is string. If it will be array we would change names to this.formData.levels
      currentSelectedEntriesMatchesIncompatibleEntries =
        currentSelectedEntriesMatchesIncompatibleEntries &&
        ifSelectedCustomReportEntries.levels.every(val => level === val);
    }
    // Check for breakdowns.
    if (ifSelectedCustomReportEntries.breakdowns) {
      // this.formData.breakdowns is FBBreakdown[].
      currentSelectedEntriesMatchesIncompatibleEntries =
        currentSelectedEntriesMatchesIncompatibleEntries &&
        ifSelectedCustomReportEntries.breakdowns.every(val =>
          (breakdownsList.entities || []).find(value => value?.key === val)
        );
    }
    // Check for click attribution window
    if (ifSelectedCustomReportEntries.click_attribution_windows) {
      // this.formData.clickAttributionWindow is string. If it will be array we would change names to this.formData.clickAttributionWindows
      currentSelectedEntriesMatchesIncompatibleEntries =
        currentSelectedEntriesMatchesIncompatibleEntries &&
        ifSelectedCustomReportEntries.click_attribution_windows.every(
          val => clickAttributionWindow === val
        );
    }
    // Check for view attribution window
    if (ifSelectedCustomReportEntries.view_attribution_windows) {
      // this.formData.viewAttributionWindow is string. If it will be array we would change names to this.formData.viewAttributionWindows
      currentSelectedEntriesMatchesIncompatibleEntries =
        currentSelectedEntriesMatchesIncompatibleEntries &&
        ifSelectedCustomReportEntries.view_attribution_windows.every(
          val => viewAttributionWindow === val
        );
    }
    // TODO: Above cases will mostly affect the incompatible for fields.
    //  If there are new selections we can add them below just like previous ones

    // If selected entry matches entirely with incompatible map
    if (currentSelectedEntriesMatchesIncompatibleEntries) {
      const unSelectableCustomReportEntries = incompatibleEntriesPair.un_selectable;
      if (unSelectableCustomReportEntries.fields != null) {
        fieldsToRemove.push(...unSelectableCustomReportEntries.fields);
      }
    }
  });

  return fieldsToRemove;
};

export const isCustomReport = (customReport: string) => customReport === 'true';

export const getSelectorInclusionState = (adAccounts: InclusionItemKey[], allSelect: boolean) =>
  getSelectorInclusionStateFromRawData({
    all_select: allSelect,
    entities: adAccounts
  });
