import { useForkRef } from '@mui/material';
import React, { useRef } from 'react';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';

export interface HdHoverTrackerProps {
  dataId?: string;
  trackingAction: string;
  trackingProps?: any;
  children: any;
  [key: string]: any;
}

function composeEventHandler(handler, eventHandler) {
  return (event) => {
    if (eventHandler) {
      eventHandler(event);
    }
    handler(event);
  };
}


export const HdHoverTracker = React.forwardRef(({
  dataId = '',
  children,
  trackingAction,
  trackingProps,
  ...props
}: HdHoverTrackerProps, ref) => {
  const { eventTrack } = useAnalyticsTracker();
  const handleRef = useForkRef(children.ref, ref);

  const childrenProps = {
    ...props,
    ...children.props,
    style: {
      ...children.props.style,
      ...props.style
    },
    ref: handleRef
  };

  const timeoutRef = useRef(null);

  const minHoverDuration = 1000;

  const onMouseEnter = () => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      eventTrack({
        action: trackingAction,
        properties: trackingProps
      });
    }, minHoverDuration);
  };

  const onMouseLeave = () => {
    clearTimeout(timeoutRef.current);
  };

  if (!!trackingAction) {
    childrenProps.onMouseEnter = composeEventHandler(onMouseEnter, childrenProps.onMouseEnter);
    childrenProps.onMouseLeave = composeEventHandler(onMouseLeave, childrenProps.onMouseLeave);
  }

  return (
    <>{React.cloneElement(children, childrenProps)}</>
  );
});
