<button
  *ngIf="!expanded && collapsible"
  hd-icon-btn
  (click)="expand()"
  [disabled]="disabled"
  #searchTrigger>
  <hd-icon name="search"></hd-icon>
</button>

<div class="searcharea" *ngIf="expanded">
  <hd-icon name="search" class="search-icon"></hd-icon>

  <!-- We are using attr.value to avoid cursor jumps to end of input in Safari -->
  <input
      #ip
      type="text"
      class="search-box"
      [class.with-clear-gutter]="shouldShowClearIcon()"
      [attr.value]="currentValue || ''"
      [placeholder]="placeholder"
      [disabled]="disabled"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"/>

  <button
    *ngIf="shouldShowClearIcon()"
    hd-icon-btn
    size="2"
    class="clear-search"
    (click)="onClear()">
    <hd-icon name="close"></hd-icon>
  </button>
</div>
