import React from 'react';

import TileGroup from '../TileGroup';
import Month from './Month';

import { TitleGroupProps } from '../shared/interfaces';
import { calendarViews } from '../shared/const';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';

export default function Months<T>(props: TitleGroupProps<T>) {
  const { activeStartDate } = props;
  const { dateTimeAdapter } = useDateTimeAdapter<T>();
  const start = 0;
  const end = 11;
  const year = dateTimeAdapter.getYear(activeStartDate);

  return (
    <TileGroup
      {...props}
      cellRatio={3 / 7}
      className='react-calendar__year-view__months'
      dateTransform={(monthIndex: any) =>
        dateTimeAdapter.createDate(
          year,
          monthIndex,
          1,
          dateTimeAdapter.getHours(activeStartDate),
          dateTimeAdapter.getMinutes(activeStartDate),
          dateTimeAdapter.getSeconds(activeStartDate)
        )
      }
      dateType={calendarViews.MONTH}
      end={end}
      start={start}
      tile={Month}
    />
  );
}
