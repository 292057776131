import { useHistory } from '@useHistory/useHistoryCompat';
import { of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { first } from 'rxjs/internal/operators/first';
import { catchError, switchMap } from 'rxjs/operators';
import { HevoEntity } from '../../../app/core/models/hevo-entity';
import { RecentEntitiesService } from '../../../app/core/service/recent-entities.service';
import { TeamSettingsService } from '../../../app/core/service/team-settings.service';
import useService from '../../hooks/useService';
import PipelinesAPI from '../pipeline/PipelinesAPI';

export function useDrawerPipelineGuard() {
  const history = useHistory();
  const recentEntitiesService = useService(RecentEntitiesService);
  const teamSettingsService = useService(TeamSettingsService);

  return () => {
    const recent = recentEntitiesService.getRecent(HevoEntity.PIPELINE.value);
    const draftPipelineSettingsEnabled =
      !!teamSettingsService.getTeamSettingsValue()?.['draft_pipeline_enabled'] || false;
    /**
     * we don't want ot delay drawer open by making api call for draft pipeline everytime user click on it,
     * so we will check if there is anything in recent which is pipeline or not. if there is still one pipeline we will
     * open the drawer instantly but if there is not pipeline we can make an api call to show draft pipelines.
     */
    if (!recent) {
      if (draftPipelineSettingsEnabled) {
        return fromPromise(PipelinesAPI.getDraftSourceList())
          .pipe(
            switchMap((res: any) => {
              if (res?.data?.length) {
                return of(true);
              }

              history.push('/pipeline');
              return of(false);
            }),
            catchError(() => {
              history.push('/pipeline');
              return of(false);
            }),
            first()
          )
          .toPromise();
      } else {
        history.push('/pipeline');
        return false;
      }
    }

    return true;
  };
}
