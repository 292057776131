import { ActivationRunStageEnum } from './run-stages';
import { SyncHistoryEntityStatus } from './sync-history-status';


export interface ActivationStepRunStats {
  startedTs?: number;
  runTime?: number;
  recordsSuccess?: number;
  recordsFailed?: number;
}

export interface TransformationRunStats extends ActivationStepRunStats {
  recordsFetchedFromQuery?: number;
  recordsIgnored?: {
    duplicateRecords?: number;
    permanentFailures?: number;
    alreadySyncedRecords?: number;
  };
}

export enum RecordsIgnoredType {
  DUPLICATE_RECORDS,
  PERMANENT_FAILURES,
  ALREADY_SYNCED_RECORDS
}

export enum ActivationRunType {
  INSTANT_RUN = 'INSTANT_RUN',
  RETRY_TEMP_ERRORS = 'RETRY_TEMP_ERRORS',
  RESTART = 'RESTART',
  SCHEDULED_RUN = 'SCHEDULED_RUN'
}

export class SyncHistoryItem {
  id: number;
  status: SyncHistoryEntityStatus;
  runType: ActivationRunType;
  transformationRunStats: TransformationRunStats = {};
  loadingRunStats: ActivationStepRunStats = {};
  failureMsg?: string;
  query: string;
  updatedTs?: number;

  private _recordsIgnored: number | null;
  set recordsIgnored(count) {
    this._recordsIgnored = count;
  }

  get recordsIgnored() {
    return this._recordsIgnored ? this._recordsIgnored : null;
  }

  private _recordsProcessed: number | null;
  set recordsProcessed(count) {
    this._recordsProcessed = count;
  }

  get recordsProcessed() {
    return this._recordsProcessed ? this._recordsProcessed : null;
  }

  getBadgeType() {
    return this.status.colorType;
  }

  getStartedTs() {
    return this.transformationRunStats.startedTs;
  }

  getRunTimeMs() {
    if (this.status.isTerminalStatus) {
      return (this.transformationRunStats?.runTime || 0) + (this.loadingRunStats?.runTime || 0);
    }

    return 0;
  }

  getQueryResultRecords() {
    return this.transformationRunStats?.recordsFetchedFromQuery;
  }

  getRecordsSuccess() {
    if (this.status.activationRunStages.includes(ActivationRunStageEnum.TRANSFORMATION)) {
      return this.transformationRunStats.recordsSuccess;
    }

    return this.loadingRunStats.recordsSuccess;
  }

  getRecordsFailed() {
    if (this.status.activationRunStages.includes(ActivationRunStageEnum.TRANSFORMATION)) {
      return this.transformationRunStats.recordsFailed;
    }

    return this.transformationRunStats.recordsFailed + this.loadingRunStats.recordsFailed;
  }

  isRetriedRun() {
    return this.runType === ActivationRunType.RETRY_TEMP_ERRORS;
  }
}
