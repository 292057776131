export const GCS_FILE_FORMATS: any[] = [
  {
    name: 'JSON',
    value: 'JSON'
  },
  {
    name: 'XML',
    value: 'XML'
  },
  {
    name: 'CSV',
    value: 'CSV'
  },
  {
    name: 'AVRO',
    value: 'AVRO'
  }
];
