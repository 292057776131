import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RxRequestService } from './rx-request.service';


export interface UpdateActivationQueryParams {
  queryUpdated?: boolean;
  restartActivation?: boolean;
}

@Injectable()
export class ActivationService {
  activationUrl = this._appConfig.getActivationsURL();

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  getActivations(): Observable<any> {
    const requestUrl: string = this.activationUrl;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  updateActivation(
    activationId: number,
    body: any,
    queryParams: UpdateActivationQueryParams = null,
    uiOptions = BACKGROUND_NETWORK_REQ_UI_OPTIONS
  ) {
    const requestUrl = this.activationUrl + '/' + activationId;

    const params = {};
    if (queryParams && Object.keys(queryParams).length) {
      if (queryParams.queryUpdated) {
        params['query_changed'] = true;
      }

      if (queryParams.restartActivation) {
        params['restart'] = true;
      }
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...uiOptions,
        successMsg: uiOptions?.showSuccessMsg ? 'Activation was updated successfully' : ''
      },
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return this._rxRequestService.put(requestUrl, options, body);
  }

  validateActivationName(activationName: string) {
    const requestUrl = this.activationUrl + '/update-validations';

    const params = {
      name: activationName
    };

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  // ------------ SCHEMA RELATED CALLS ------------------

  /**
   * Fetch mapping based on the query
   *
   * @param query
   * @param activationId
   */
  getQueryMappings(query: string, activationId: number) {
    const requestUrl = this.activationUrl + `/${ activationId }/query-mappings`;

    const params = {
      query: query
    };

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  /**
   * Fetch mapping based on the activation id
   *
   * @param activationId
   */
  getMappings(activationId: number) {
    const requestUrl = this.activationUrl + `/${ activationId }/mappings`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  /*
   * Validate the mapping between Warehouse and Target fields
   * */
  validateMapping(fieldMappings: any[], warehouseFields, targetFields) {
    const requestUrl = this.activationUrl + '/validate-field-mappings-compatibility-request';

    const params = {
      warehouse_fields: warehouseFields,
      target_fields: targetFields,
      field_mappings: fieldMappings
    };

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  /**
   * Fetch fields for the given
   * query and selected warehouse
   *
   * @param params
   * @returns an observable with the list of query fields
   */
  getWarehouseQueryFields(params: any) {
    const requestUrl = this.activationUrl + '/warehouse/fetch-query-fields';

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }
}
