<div
  (mouseenter)="replayProgressTracking()"
  (mouseleave)="checkHoverTime()"
  class="center-flex-row">
  <hd-icon
    class="mr-1 sync-icon-style"
    name="syncing"
    size="1">
  </hd-icon>
  Replaying {{ replayProgress.getRemainingFormatted() }}
  {{ 'Event' | nounPluralFormatter: { count: replayProgress.getRemainingRecordsCount() } }}
  <hd-icon
    name="info"
    size="1"
    class="ml-1 info-icon"
    [tooltip]="replayProgress ? replayProgress.getDisplayString() : ''"
    tooltipPlacement="right">
  </hd-icon>
</div>