import { Directionality } from '@angular/cdk/bidi';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { animationFrameScheduler, Subject } from 'rxjs';
import { auditTime, startWith, takeUntil } from 'rxjs/operators';

import { HdScrollTopComponent } from '../hd-scroll-top/hd-scroll-top.component';

@Component({
  selector: 'hd-scroll-viewport',
  templateUrl: './scroll-viewport.component.html',
  styleUrls: ['./scroll-viewport.component.scss']
})
export class HdScrollViewportComponent extends CdkScrollable implements AfterViewInit, OnDestroy {
  @Output() scrolledToEdge = new EventEmitter<EdgeDir>();
  @Input() edgeBufferTop = 0;
  @Input() edgeBufferBottom = 0;
  @Input() scrollTopButtonWithPadding = true;
  @Input() compact = false;
  @Input() renderScrollTopBtn = true;

  scrollDirection: ScrollDir;
  lastScrollTop = 0;

  scrollTopInstance: ComponentRef<HdScrollTopComponent>;

  private _destroyed$ = new Subject<void>();
  scrollDirectionChange = new Subject<ScrollDir>();

  private _programmedScroll = false;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    ngZone: NgZone,
    @Optional() dir: Directionality,
    scrollDispatcher: ScrollDispatcher,
    private _viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) {
    super(elementRef, scrollDispatcher, ngZone, dir);
  }

  ngAfterViewInit() {
    this.elementScrolled()
    .pipe(
      // Start off with a fake scroll event so we properly detect our initial position.
      startWith<Event | null>(null!),
      // Collect multiple events into one until the next animation frame. This way if
      // there are multiple scroll events in the same frame we only need to recheck
      // our layout once.
      auditTime(0, animationFrameScheduler),
      takeUntil(this._destroyed$)
    )
    .subscribe((e: any) => {
      this._calculateScrollPosition();
      if (this.renderScrollTopBtn) {
        this.ngZone.run(() => {
          this._renderScrollTopBtnIfRequired();
        });
      }
      if (this._programmedScroll) {
        this._programmedScroll = false;
        return;
      }
      this._checkEdgeScroll();
    });
  }

  private _calculateScrollPosition() {
    const scrollTop: number = this.elementRef.nativeElement.scrollTop;

    const currentScrollDirection = this.scrollDirection;

    if (scrollTop === this.lastScrollTop) {
      this.scrollDirection = undefined;
      return;
    }

    this.scrollDirection = scrollTop - this.lastScrollTop > 0 ? 'down' : 'up';

    if (currentScrollDirection !== this.scrollDirection) {
      this.scrollDirectionChange.next(this.scrollDirection);
    }

    this.lastScrollTop = scrollTop;
  }

  private _checkEdgeScroll() {
    const offsetFrom: EdgeDir = this.scrollDirection === 'down' ? 'bottom' : 'top';

    const edgeOffset: number = this.measureScrollOffset(
      offsetFrom
    );

    if (offsetFrom === 'top' && edgeOffset <= this.edgeBufferTop) {
      this.emitScrollToEdgeEvent(offsetFrom);
    }

    if (offsetFrom === 'bottom' && edgeOffset <= this.edgeBufferBottom) {
      this.emitScrollToEdgeEvent(offsetFrom);
    }
  }

  emitScrollToEdgeEvent(offsetFrom: EdgeDir) {
    this.ngZone.run(() => {
      this.scrolledToEdge.emit(offsetFrom);
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();

    if (this.scrollTopInstance) {
      this.scrollTopInstance.instance.clear();
      this.scrollTopInstance.destroy();
      this._viewContainerRef.clear();
      this.scrollTopInstance = null;
    }

    super.ngOnDestroy();
  }

  programmedScrollTop(yPos: number) {
    this._programmedScroll = true;
    this.scrollTo({
      top: yPos
    });
  }

  scrollTop(yPos: number) {
    this.scrollTo({
      top: yPos
    });
  }

  scrollBottom() {
    setTimeout(() => {
      this.scrollTo({
        top: this.elementRef.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  }

  getViewBoxSize() {
    return {
      height: this.elementRef.nativeElement.clientHeight,
      width: this.elementRef.nativeElement.clientWidth
    };
  }

  private _renderScrollTopBtnIfRequired() {
    if (this.lastScrollTop >= this.elementRef.nativeElement.clientHeight / 2) {
      this._addScrollTopBtn();
    } else {
      this._removeScrollTopBtn();
    }
  }

  private _addScrollTopBtn() {
    if (this.scrollTopInstance && this.scrollTopInstance.instance.attached) {
      return;
    }

    if (!this.scrollTopInstance) {
      const factory = this.resolver.resolveComponentFactory(HdScrollTopComponent);
      this.scrollTopInstance = this._viewContainerRef.createComponent(factory);
      this.scrollTopInstance.instance.registerScrollableContainer(this);
      this.scrollTopInstance.instance.anchor = this.elementRef.nativeElement;
      this.scrollTopInstance.instance.compact = this.compact;
      this.scrollTopInstance.instance.withPadding = this.scrollTopButtonWithPadding;
      this.scrollTopInstance.instance.onAttach();
    }

    if (!this.scrollTopInstance.instance.attached) {
      this._viewContainerRef.insert(this.scrollTopInstance.hostView);
      this.scrollTopInstance.instance.onAttach();
    }

    this.scrollTopInstance.instance.position();
  }

  private _removeScrollTopBtn() {
    if (!this.scrollTopInstance || !this.scrollTopInstance.instance.attached) {
      return;
    }

    const index = this._viewContainerRef.indexOf(this.scrollTopInstance.hostView);
    this._viewContainerRef.detach(index);
    this.scrollTopInstance.instance.onDetach();
  }
}

export type EdgeDir = 'top' | 'bottom';

export type ScrollDir = 'up' | 'down';
