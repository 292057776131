import { SidelineMessageAction } from './sideline-message';

export enum SidelineReasonType {
  CODE_USAGE_EXHAUSTED = 108
}

export const OwnerSidelineReasonTypes = {
  [SidelineReasonType.CODE_USAGE_EXHAUSTED]: {
    actions: new Set<SidelineMessageAction>()
  }
};
