import { useRef } from 'react';
import { usePQ } from './usePQ';
import useForceUpdate from './useForceUpdate';

export interface BannerNotifier {
  id: string;
  component?: any;
  priority?: number;
}

export interface BannerNotification<T> {
  id: string;
  data: T;
  replace?: boolean;
}

export const DEFAULT_BANNER_PRIORITY = 0;

export default function useBanner() {
  const pq = usePQ<BannerNotification<any>>();
  const notifiers = useRef<BannerNotifier[]>([]);

  const forceUpdate = useForceUpdate();

  const registerNotifier = (notifier: BannerNotifier) => {
    if (!notifier.component) {
      throw new Error('BannerNotifier expects a templateRef or component');
    }
    const n = { ...notifier };
    if (!notifier.priority) {
      n.priority = DEFAULT_BANNER_PRIORITY;
    }
    notifiers.current.push(n);
  };

  const registerNotifiers = (bannerNotifiers: BannerNotifier[]) => {
    bannerNotifiers.forEach(notifier => {
      registerNotifier(notifier);
    });
  };

  const removeNotificationIfQueued = (id: string) => {
    const currentItem = pq.peek();
    if (currentItem && currentItem.data.id === id) {
      pq.pop();
      forceUpdate();
      return;
    }

    const existingRef = pq.items().findIndex(item => item.data.id === id);

    if (existingRef >= 0) {
      pq.removeAt(existingRef);
    }
    forceUpdate();
  };

  const removeNotification = (id: string) => {
    removeNotificationIfQueued(id);
  };

  const getNotifierById = (id: string) => notifiers.current.find(n => n.id === id);

  const notify = <T>(notification: BannerNotification<T>) => {
    /**
     * If current notification has same id as the requested notification
     * and replace is false do nothing
     */
    const currentItem = pq.peek();
    if (notification.replace === false && currentItem && currentItem.data.id === notification.id) {
      return;
    }

    /**
     * If new notification's id is same as the one that's visible
     * or one that is in queue remove current item or the queue ref.
     * This will ensure that new notification
     * replaces the previously opened/queued one.
     */
    removeNotificationIfQueued(notification.id);

    pq.insert({
      data: notification,
      priority: getNotifierById(notification.id).priority
    });
    forceUpdate();
  };

  const notificationData = pq.peek()?.data;
  let NotificationComponent = null;
  if (notificationData) {
    NotificationComponent = getNotifierById(notificationData?.id)?.component;
  }
  return {
    notify,
    removeNotification,
    registerNotifiers,
    notificationData,
    NotificationComponent
  };
}
