import React, { useEffect, useState } from 'react';
import { RbacPermissions } from '../../../app/core/models/user';
import { getDaysDifferenceFromCurrentTimestamp } from '../../legacy-utils/date';
import { DestinationCompactData, DestinationRecommendationsData } from '../../../app/destination/models';
import { SETTINGS_DESTINATION_RECOMMENDATIONS_BANNER_SHOWN } from '../../../app/pipeline/constants';
import {
  TRACKER_DESTINATION_RECOMMENDATIONS_BANNER_CLICK_IN_DESTINATION,
  TRACKER_DESTINATION_RECOMMENDATIONS_BANNER_CLICK_IN_SOURCE,
  TRACKER_DESTINATION_RECOMMENDATIONS_DISMISS_CLICK_IN_DESTINATION,
  TRACKER_DESTINATION_RECOMMENDATIONS_DISMISS_CLICK_IN_SOURCE
} from '../../../app/pipeline/tracking';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import useSettingsStorageService from '../../hooks/services/useSettingsStorageService';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { DestinationOptimisationDialog } from '../DestinationOptimisationDialog';
import { HdIcon, HdRbacButton } from '../UIElements';

export interface DestinationOptimisationExtensionPaneProps {
  destination: DestinationCompactData;
  recommendationsConfig: DestinationRecommendationsData;
  pipelineId?: number;
  onDialogOpen?: Function;
  onPaneDismiss?: Function;
}

export function DestinationOptimisationExtensionPane({
  destination,
  pipelineId,
  recommendationsConfig,
  onDialogOpen,
  onPaneDismiss
}: DestinationOptimisationExtensionPaneProps) {
  const [ paneVisible, setPaneVisible ] = useState(false);
  const [ dialogConfig, setDialogConfig ] = useState<{
    visible: boolean;
    destination?: DestinationCompactData;
    recommendationsConfig?: DestinationRecommendationsData;
  }>({
    visible: false
  });

  const { eventTrack } = useAnalyticsTracker();
  const settingsStorageService = useSettingsStorageService();

  useEffect(() => {
    let canShowPane = true;

    const destinationRecommendationMap = settingsStorageService.getSettings(
      SETTINGS_DESTINATION_RECOMMENDATIONS_BANNER_SHOWN);
    if (destinationRecommendationMap && destinationRecommendationMap?.[destination.destinationId]) {
      const diffDays = Math.ceil(
        Math.abs(
          getDaysDifferenceFromCurrentTimestamp(destinationRecommendationMap[destination.destinationId])
        ));

      if (diffDays < 30) {
        canShowPane = false;
      }
    }
    setPaneVisible(canShowPane);
  }, []);

  const onOpenDialogClick = () => {
    if (pipelineId) {
      eventTrack({
        action: TRACKER_DESTINATION_RECOMMENDATIONS_BANNER_CLICK_IN_SOURCE,
        properties: {
          sourceId: pipelineId
        }
      });
    } else {
      eventTrack({
        action: TRACKER_DESTINATION_RECOMMENDATIONS_BANNER_CLICK_IN_DESTINATION,
        properties: {
          destinationId: destination?.destinationId
        }
      });
    }

    if (typeof onDialogOpen === 'function') {
      onDialogOpen();
    }

    setDialogConfig({
      visible: true,
      destination,
      recommendationsConfig
    });
  };

  const onDismissClick = () => {
    if (pipelineId) {
      eventTrack({
        action: TRACKER_DESTINATION_RECOMMENDATIONS_DISMISS_CLICK_IN_SOURCE,
        properties: {
          pipeline_id: pipelineId
        }
      });
    } else {
      eventTrack({
        action: TRACKER_DESTINATION_RECOMMENDATIONS_DISMISS_CLICK_IN_DESTINATION
      });
    }

    if (typeof onPaneDismiss === 'function') {
      onPaneDismiss();
    }

    hidePane();
  };

  const onDialogClose = (res) => {
    setDialogConfig({
      ...dialogConfig,
      visible: false
    });

    if (res.isUpdated) {
      hidePane();
    }
  };

  const hidePane = () => {
    const hideDestinationRecommendationList = settingsStorageService.getSettings(
      SETTINGS_DESTINATION_RECOMMENDATIONS_BANNER_SHOWN
    );

    settingsStorageService.applySettings(
      SETTINGS_DESTINATION_RECOMMENDATIONS_BANNER_SHOWN,
      {
        ...hideDestinationRecommendationList,
        [destination.destinationId]: new Date().getTime()
      }
    );

    setPaneVisible(false);
  };

  const dataIdGenerator = getDataIdGenerator('destination-optimisation-extension-pane');

  if (!paneVisible) {
    return null;
  }

  return (
    <div className='border-top p-2 w-100'>
      <div className='center-flex-row justify-between text-body-1'>
        <div className='center-flex-row justify-around'>
          <HdIcon
            name='idea'
            size={3}
            className='text-primary mr-1 icon-with-text'
          />

          <b className='mr-1'>
            Destination Cost Advisor:
          </b>

          Reduce the Destination costs by modifying your data ingestion and loading preferences.
        </div>

        <div>
          <HdRbacButton
            className='mr-3'
            size='sm'
            rbacPermission={RbacPermissions.DESTINATION_EDIT}
            dataId={dataIdGenerator('view')}
            onClick={onOpenDialogClick}
          >
            View Recommendations
          </HdRbacButton>

          <HdRbacButton
            className='mr-3'
            size='sm'
            rbacPermission={RbacPermissions.DESTINATION_EDIT}
            dataId={dataIdGenerator('dismiss')}
            onClick={onDismissClick}
            palette='info-secondary-faded'
          >
            Dismiss
          </HdRbacButton>
        </div>
      </div>

      <DestinationOptimisationDialog
        showModal={dialogConfig.visible}
        onClose={onDialogClose}
        destination={dialogConfig.destination}
        recommendationsConfig={dialogConfig.recommendationsConfig}
      />
    </div>
  );
}
