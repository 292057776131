import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional } from '@angular/core';

import { DrawerRoutingService } from './drawer-routing.service';
import { ReactDrawersContainerComponent } from './react-drawers-container.component';

const DIRECTIVES = [
  ReactDrawersContainerComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ ...DIRECTIVES ],
  exports: DIRECTIVES
})
export class DrawerModule {
  constructor(@Optional() drawerRoutingService: DrawerRoutingService) {
  }

  static forRoot(): ModuleWithProviders<DrawerModule> {
    return {
      ngModule: DrawerModule,
      providers: [
        DrawerRoutingService
      ]
    };
  }
}
