import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';


export interface NodeTypeConfigHelper {
  /**
   * Get default config to preload the form.
   * @param metaData: The node type metadata resolved before the form loads.
   *                  This is combination of data fetched from metaDataResolver & staticDependenciesResolver
   */
  getDefaultConfig: (metaData?: any) => any;
  /**
   * Get the config to preload the form from the provided rawData
   * @param rawData: values cached in local storage,
   *                 or existing data in case of edit destination
   *                 or value from sample settings
   *                 or value from existing destinations dropdown
   * @param metaData: The node type metadata resolved before the form loads.
   *                  This is combination of data fetched from metaDataResolver & staticDependenciesResolver
   * @param isUpdateMode: When editing the node, this will be true
   */
  getConfigFromRawData: (rawData: any, metaData?: any, isUpdateMode?: boolean) => any;
  /**
   * Get the config to preload the form from the provided initial data
   * @param rawData: initial value provided when the form loads during fast travel
   * @param metaData: The destination type metadata resolved before the form loads.
   *                  This is combination of data fetched from metaDataResolver & staticDependenciesResolver
   */
  getConfigFromInitialData?: (rawData: any, metaData?: any, isUpdateMode?: boolean) => any;
  /**
   * Convert the form data model into a model that can be sent to backend for test connection/create and similar APIs.
   * @param data: Data Model that was returned from the form
   */
  buildConnectionParams?: (data: any, isUpdateMode?: boolean) => Observable<any> | Promise<any> | any;
  /**
   * Hook to fetch any form dependencies before the form is shown in the UI. This is specifically used to fetch data
   * based on the current state of form
   * @param rawData: values cached in local storage,
   *                 or existing data in case of edit destination
   *                 or value from sample settings
   *                 or value from existing destinations dropdown
   */
  metaDataResolver?: (nodeType: string, rawData: any) => Observable<any> | Promise<any> | any;
  /**
   * Hook to fetch any form dependencies before the form is shown in the UI. This is specifically used to fetch
   * data that does'nt depend on the form state.
   */
  staticDependenciesResolver?: (nodeType: string) => Observable<any> | Promise<any> | any;
  /**
   * Get the fields that are supposed to be frozen if they were autofilled from 3rd party apps.
   */
  getInitialFrozenFields?: () => any;
}

export interface NodeTypeConfigHelperConstructor {
  new(...args: any[]): NodeTypeConfigHelper;
}

export const NODE_TYPE_IDENTIFIER = new InjectionToken<string>('');
