import { Field, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import * as Yup from 'yup';
import {
  TRACKER_FORGOT_PASSWORD_SUBMIT_CLICK,
  TRACKER_FORGOT_PASSWORD_SUBMIT_ERROR,
  TRACKER_FORGOT_PASSWORD_SUBMIT_SUCCESS
} from '../constants';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikTextField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdIcon, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import ErrorFocus from '../../../utils/ErrorFocus';
import { FormValueChangesObserver } from '../../../utils/FormValueChangesObserver';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AuthAPI from '../AuthAPI';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useLoginGuard } from '../useLoginGuard';
import { useRecaptchaService } from '../useRecaptchaService';

export interface ForgotPasswordProps {
  featuredCustomers: FeaturedCustomers;
}

const validationSchema = Yup.object({
  email: Yup.string().email().required('Email is required')
});

export function ForgotPassword() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeLoginGuard } = useLoginGuard();

  return (
    <HdResolvedComponent
      Component={ForgotPasswordInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[ executeLoginGuard ]}
    />
  );
}

export function ForgotPasswordInner({
  featuredCustomers
}: ForgotPasswordProps) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ emailSubmitted, setEmailSubmitted ] = useState(false);
  const [ formError, setFormError ] = useState(null);
  const [ formSuccess, setFormSuccess ] = useState(null);

  const history = useHistory();
  const { eventTrack } = useAnalyticsTracker();
  const formikRef = useRef<FormikProps<{}>>();
  const { search } = useLocation();
  const { isRecaptchaReady, validateWithRecaptchaCommon } = useRecaptchaService('recaptcha_container_forgot_password');

  const email = new URLSearchParams(search).get('email');
  const initialValues = {
    email
  };

  const handleSubmit = values => {
    if (emailSubmitted) {
      history.push(`/login${search}`);
      return;
    }

    if (!formikRef.current.isSubmitting) {
      return;
    }

    const trackingParams = {
      email: values.email
    };

    eventTrack({
      action: TRACKER_FORGOT_PASSWORD_SUBMIT_CLICK,
      properties: trackingParams
    });

    setIsSubmitting(true);
    setFormError(null);
    setFormSuccess(null);

    validateWithRecaptchaCommon(
      (payload) => AuthAPI.forgotPassword(payload),
      {
        email: values.email
      }
    ).then(() => {
      setIsSubmitting(false);
      setEmailSubmitted(true);

      setFormSuccess(`An email with password reset url has been sent to ${values.email}`);

      eventTrack({
        action: TRACKER_FORGOT_PASSWORD_SUBMIT_SUCCESS,
        properties: trackingParams
      });
    }, (error) => {
      setIsSubmitting(false);

      const errorMessage = getErrorMessageFromObj(error);
      setFormError(errorMessage);

      eventTrack({
        action: TRACKER_FORGOT_PASSWORD_SUBMIT_ERROR,
        properties: {
          ...trackingParams,
          reason: errorMessage
        }
      });
    });
  };

  const dataIdGenerator = getDataIdGenerator('forgot-password');

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.FORGOT_PASSWORD}
      featuredCustomers={featuredCustomers}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleSubmit(values)}
        validateOnMount
        innerRef={formikRef}
      >
        {props => (
          <form
            noValidate
            onSubmit={props.handleSubmit}
          >
            <ErrorFocus formik={props} onFormError={() => {}} />

            <HdFormControl className='mb-0'>
              <Field
                name='email'
                label='Email Address'
                component={HdFormikTextField}
                startAdornment={<HdIcon name='email' />}
                autoFocus
                required
              />
            </HdFormControl>

            {!!formError && (
              <HdPane
                dataId={dataIdGenerator('form-error')}
                className='mb-1 w-100'
                variant='error-faded'
                icon='error-filled'
                iconClasses='text-error'
                disableHide
              >
                <div className='text-default'>
                  {formError}
                </div>
              </HdPane>
            )}

            {!!formSuccess && (
              <HdPane
                dataId={dataIdGenerator('form-success')}
                className='mb-1 w-100'
                variant='success-faded'
                icon='check-circle'
                iconClasses='text-success'
                disableHide
              >
                <div className='text-default'>
                  {formSuccess}
                </div>
              </HdPane>
            )}

            <HdButton
              dataId={dataIdGenerator('submit')}
              type='submit'
              size='lg'
              className='w-100 mt-4'
              showProgress={isSubmitting}
              disabled={!props.isValid || isSubmitting}
            >
              { emailSubmitted ? 'Back To Login' : 'Continue' }
            </HdButton>

            {!emailSubmitted ? (
              <NavLink
                to={`/login${search}`}
                className='text-link text-center btn-thumbnail-left w-100 mt-4'
              >
                <HdIcon name='back' className='thumb' size={3} />
                Back to Login
              </NavLink>
            ) : null}

            <FormValueChangesObserver
              onChange={() => setEmailSubmitted(false)}
            />
          </form>
        )}
      </Formik>

      <div id='recaptcha_container_forgot_password' />
    </HdAuth>
  );
}
