import React, { createContext, useMemo, useState } from 'react';
import { HdIcon } from '../UIElements';
import styles from './styles.module.scss';

const HelpBannerContext = createContext(null);
HelpBannerContext.displayName = 'HelpBannerContext';

export default function HelpBanner({ defaultExpanded, children }) {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || true);

  const toggle = () => {
    setExpanded(prev => !prev);
  };

  const value = useMemo(() => ({ expanded, toggle }), [expanded]);
  return (
    <HelpBannerContext.Provider value={value}>
      <div className={styles.helpNotesMain}>
        {children}
        <HdIcon
          size={2}
          className={`ml-8 ${styles.expandIcon} ${expanded ? styles.expanded : ''}`}
          name='dropdown-arrow'
          onClick={toggle}
        />
      </div>
    </HelpBannerContext.Provider>
  );
}
export function useHelpBanner() {
  const context = React.useContext(HelpBannerContext);
  if (context === undefined) {
    throw new Error('useHelpBanner must be used within a <HelpBanner />');
  }
  return context;
}
