// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.jobCard_cgEj7 {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px 20px;\n  border-bottom: 1px solid var(--default-border-color);\n}\n\n.jobDetailsBtncontainer__pGjc {\n  display: flex;\n  justify-content: end;\n  flex-grow: 1;\n}\n\n.jobDetailsBtn_AWlhG {\n  border: 1px solid var(--default-border-color) !important;\n  border-radius: 6px !important;\n  padding: 4px !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobCard": "jobCard_cgEj7",
	"jobDetailsBtncontainer": "jobDetailsBtncontainer__pGjc",
	"jobDetailsBtn": "jobDetailsBtn_AWlhG"
};
export default ___CSS_LOADER_EXPORT___;
