import React from 'react';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import { HdIcon, HdIconButton } from '../../../components/UIElements';

export function SchedulerHeader({ title, subTitle, dataIdGenerator, onClose, className = '' }) {
  return (
    <div className={clsx('dialog-header', styles.dialogHeader, className)}>
      <div className='flex-col'>
        <div className='dialog-title text-transform-none text-subheading-3'>{title}</div>

        <div className='text-body-1 text-secondary mt-1'>{subTitle}</div>
      </div>

      <HdIconButton
        dataId={dataIdGenerator('close')}
        className={styles.closeDialogBtn}
        onClick={() => onClose()}
      >
        <HdIcon size={3} name='close' />
      </HdIconButton>
    </div>
  );
}
