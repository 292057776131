import PipelineAPI from '../../../PipelinesAPI';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class GoogleCampaignManagerConfigHelperReact implements SourceTypeConfigHelper {
  getDefaultConfig() {
    return {
      existingReports: false
    };
  }

  getConfigFromRawData(rawData: any, metaData: any) {
    const config: any = {
      existingReports: rawData.poll_existing,
      reports: [],
      metrics: rawData.metrics || [],
      dimensions: rawData.dimensions || []
    };

    if (!config.existingReports) {
      config.reportType = rawData.report_type?.[0];
    } else {
      config.reportType = rawData.report_type;
    }

    if ('profile_ids' in rawData) {
      config.profiles = this._fetchListFromRawData(rawData.profile_ids, metaData.profiles);
    }

    if ('report_ids' in rawData && 'reports' in metaData) {
      config.reports = this._fetchListFromRawData(rawData.report_ids, metaData.reports);
    }

    return config;
  }

  buildConnectionParams(data: any) {
    const config: any = {
      profile_ids: (data.profiles || []).map(profile => profile.id),
      poll_existing: data.existingReports,
      report_type: data.reportType
    };

    if (!data.existingReports && data.reportType) {
      config.report_type = [data.reportType];
    }

    if (data.existingReports === true) {
      config.report_ids = (data.reports || []).map(report => report.id);
    }

    if (data.existingReports === false) {
      config.dimensions = data.dimensions;
      config.metrics = data.metrics;
    }

    return config;
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    let pollExisting: boolean;
    let profiles: any;
    let reportType: any;

    if (rawData.isSample || rawData.id) {
      const config: any = rawData.id ? rawData.source_config : rawData;
      pollExisting = config.poll_existing;

      if (pollExisting) {
        profiles = config.profile_ids.map(profileId => ({
          id: profileId
        }));

        reportType = config.report_type;
      }
    }

    const promiseList = [PipelineAPI.getGCMProfilesAndReportTypes(authData.oauthTokenId)];

    if (pollExisting) {
      promiseList.push(PipelineAPI.getGCMReports(authData.oauthTokenId, profiles, reportType));
    }

    return Promise.all(promiseList).then((res: any[]) => {
      const config: any = {
        profiles: res[0].profiles,
        reportTypes: res[0].report_types
      };

      if (res[1] && Object.keys(res[1]).length > 0) {
        config.reports = Object.entries(res[1]).map(([key, value]) => ({
          id: key,
          name: value
        }));
      }

      return config;
    });
  }

  /**
   * Create a list of objects filtered based on ids
   *
   * @param list - list of ids
   * @param rawList - list of objects
   */
  private _fetchListFromRawData(list: any[], rawList: any[]) {
    return rawList.filter(obj => list.find(elem => obj.id === elem));
  }
}
