import { ThemeProvider } from '@mui/material';
import React from 'react';
import { useAppSelector } from './hooks/redux';
import { activeTheme } from './utils/themeProvider';

export default function ThemeWrapper({ children }) {
  const theme = useAppSelector(state => state.global.theme);
  return (
    <ThemeProvider theme={activeTheme(theme)}>
      {children}
    </ThemeProvider>
  );
}
