/* eslint-disable import/no-cycle */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { HdFormikPasswordField, HdFormikTextField } from '../../../../../components/FormikElements';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import { SSHConfigHelpBanner } from '../../../../../components/FormikElements/SSHConfig/SSHConfigHelpBanner';
import { HdFormControl, HdRadio, HdRadioGroup } from '../../../../../components/UIElements';
import useTeamSettings from '../../../../../hooks/services/useTeamSettingsService';
import styles from './styles.module.scss';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import {
  TRACKER_DESTINATION_REDSHIFT_CONNECTION_STRING,
  TRACKER_DESTINATION_SQL_HOST_NAME_BLUR
} from '../../../../../../app/nodes/tracking';
import { stringToBool } from '../../../../../utils/helper';
import { destructURL, isValidConnectionString } from './config';
import SSHConfig from '../../../../../components/FormikElements/SSHConfig';
import SSHConfigFields from '../../../../../components/FormikElements/SSHConfig/SSHConfigFields';
import { LoadedAt, SanitizeName } from '../../../../../components/NodeConfigOptions';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import { getHostName } from '../Sql/experimentUtils';

export default function Redshift({
  destinationTypeIdentifier,
  destinationTypeMetaData,
  isDestinationModeWarehouse,
  isEditing,
  hevoEntityFor,
  onInviteMember,
  rawDestinationType
}) {
  const formikProps = useFormikContext<{
    host: string;
    isConnectionUri: string;
    connectionString: string;
    port: string;
    databaseName: string;
    useSSH: boolean;
  }>();
  const canConfigureLoadedAt = destinationTypeMetaData.populateLoadedAt;
  const enableAdvancedConfig = canConfigureLoadedAt && !isDestinationModeWarehouse;
  const destinationTypeTrackingProps = {
    destinationType: destinationTypeIdentifier,
    context: hevoEntityFor
  };

  const analyticsTracker = useAnalyticsTracker();

  const canProvideSchemaName = destinationTypeMetaData.provideSchemaName;

  const { isServerlessAllowedForTeam } = useTeamSettings();

  const isServerlessAllowed = isServerlessAllowedForTeam() && !isDestinationModeWarehouse;

  const onHostNameBlur = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_DESTINATION_SQL_HOST_NAME_BLUR,
      properties: {
        hostName: formikProps.values.host,
        destinationType: destinationTypeIdentifier
      }
    });
  };

  return (
    <>
      {isServerlessAllowed ? (
        <HdFormikSwitchWrapper
          className='mb-7'
          fieldName='isServerless'
          disabled={isEditing}
          label='Conect to Redshift Serverless'
        >
          Set this toggle to ON if your destination is a Redshift Serverless instance.
        </HdFormikSwitchWrapper>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className='collapsible-group-box collapsible-group pb-0'>
            <HdFormControl>
              <Field
                name='isConnectionUri'
                label='General Connection Settings'
                component={ConnectionSettingsRadioGroup}
                destinationTypeIdentifier={destinationTypeIdentifier}
              />
            </HdFormControl>

            {formikProps.values.isConnectionUri === 'true' ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <HdFormControl>
                    <Field
                      name='connectionString'
                      label='Connection String'
                      placeholder='jdbc:redshift://endpoint:port/database'
                      required
                      component={HdFormikTextField}
                      helperText='A connection string in URI form. Eg - jdbc:redshift://endpoint:port/database.'
                      onChange={e => {
                        const connectionString = e.target.value;

                        if (isValidConnectionString(connectionString)) {
                          const { host, port, databaseName } = destructURL(connectionString);

                          formikProps.setValues({
                            ...formikProps.values,
                            connectionString,
                            host,
                            port,
                            databaseName
                          });
                        } else {
                          formikProps.setValues({
                            ...formikProps.values,
                            connectionString,
                            host: '',
                            databaseName: ''
                          });
                        }
                      }}
                    />
                  </HdFormControl>
                </Grid>
              </Grid>
            ) : null}

            {formikProps.values.connectionString?.length &&
            !formikProps.errors.connectionString &&
            formikProps.values.isConnectionUri === 'true' ? (
              <div className='info-box__secondary mb-5'>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container className='mb-4'>
                      <Grid item xs={4} md={3} className={styles.heading}>
                        Database Host
                      </Grid>

                      <Grid item xs={8} md={9} className={styles.content}>
                        {formikProps.values.host}
                      </Grid>
                    </Grid>

                    <Grid container className='mb-4'>
                      <Grid item xs={4} md={3} className={styles.heading}>
                        Port
                      </Grid>

                      <Grid item xs={8} md={9} className={styles.content}>
                        {formikProps.values.port}
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={4} md={3} className={styles.heading}>
                        Database Name
                      </Grid>

                      <Grid item xs={8} md={9} className={styles.content}>
                        {formikProps.values.databaseName}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : null}

            {formikProps.values.isConnectionUri === 'false' ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='host'
                      label='Database Cluster Identifier'
                      required
                      component={HdFormikTextField}
                      onBlur={onHostNameBlur}
                      placeholder='IP (1.2.3.4) or host(your.server.com)'
                      helperText={getHostName(
                        destinationTypeIdentifier,
                        destinationTypeTrackingProps
                      )}
                    />
                  </HdFormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='port'
                      label='Database Port'
                      required
                      component={HdFormikTextField}
                      type='number'
                    />
                  </HdFormControl>
                </Grid>
              </Grid>
            ) : null}

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field name='user' label='Database User' required component={HdFormikTextField} />
                </HdFormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='password'
                    label='Database Password'
                    required
                    component={HdFormikPasswordField}
                  />
                </HdFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {formikProps.values.isConnectionUri === 'false' ? (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='databaseName'
                      label='Database Name'
                      required
                      component={HdFormikTextField}
                      helperText='Must be an existing database.'
                    />
                  </HdFormControl>
                </Grid>
              ) : null}

              {canProvideSchemaName ? (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field name='schemaName' label='Schema Name' component={HdFormikTextField} />
                  </HdFormControl>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </Grid>
      </Grid>

      <SSHConfig className='mt-7' entityTypeIdentifier='database'>
        {formikProps.values.useSSH ? (
          <SSHConfigHelpBanner
            defaultExpanded
            onInviteMember={onInviteMember}
            destinationTypeDisplayName={rawDestinationType.dest_type_display}
          />
        ) : null}

        <SSHConfigFields />
      </SSHConfig>

      {!isDestinationModeWarehouse ? (
        <SanitizeName
          className='mt-7'
          disabled={!!isEditing}
        />
      ) : null}

      {enableAdvancedConfig ? (
        <AdvancedConfig className='mt-7' showBorderBottom>
          <LoadedAt />
        </AdvancedConfig>
      ) : null}
    </>
  );
}

function ConnectionSettingsRadioGroup({ field, form, isEditing, destinationTypeIdentifier }) {
  const analyticsTracker = useAnalyticsTracker();

  const connectionURIModeChange = e => {
    const isConnectionURI = stringToBool(e);

    analyticsTracker.eventTrack({
      action: TRACKER_DESTINATION_REDSHIFT_CONNECTION_STRING,
      properties: {
        selectedOption: isConnectionURI ? 'paste_connectiong_string' : 'enter_fields_manually',
        destinationType: destinationTypeIdentifier
      }
    });

    let values = {
      ...form.values,
      isConnectionUri: e,
      connectionString: ''
    };

    /**
     * If user selects manual field option then we will check if the connectionString is valid or not if its valid then we preserve host,
     * port, and database along with userName and password otherwise we will clear the fields
     */
    if (!isConnectionURI && !isValidConnectionString(form.values.connectionString)) {
      values = {
        ...values,
        databaseName: '',
        host: ''
      };
    }

    /**
     * If user selection connection string mode then we check if all the fields which are required to form a connection string are valid
     * or not, if they are then we form a connection string other just set the connectString to empty string
     */
    if (isConnectionURI && !form.errors.host && !form.errors.port && !form.errors.databaseName) {
      values = {
        ...values,
        connectionString: `jdbc:redshift://${form.values.host}:${form.values.port}/${form.values.databaseName}`
      };
    }

    form.setValues(values);
  };

  return (
    <HdRadioGroup
      {...field}
      name='isConnectionUri'
      label='General Connection Settings'
      onChange={connectionURIModeChange}
      className='mb-5'
    >
      <HdRadio
        key='connectionString'
        value='true'
        disabled={isEditing}
        dataId='connection-settings-paste'
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Paste Connection String</Typography>
        </div>
      </HdRadio>

      <HdRadio
        key='manual'
        value='false'
        className='ml-5'
        disabled={isEditing}
        dataId='connection-settings-manual'
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Enter Connection Fields Manually</Typography>
        </div>
      </HdRadio>
    </HdRadioGroup>
  );
}
