import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

import { BannerService } from '../banner/service/banner.service';
import { AuthService } from '../core/service/auth.service';
import { OverageDataService } from '../core/service/overage-data.service';
import { PlanChatSupportService } from '../core/service/plan-chat-support.service';
import { TrialDetailsService } from '../core/service/trial-details.service';
import { UserTimezoneService } from '../core/service/user-timezone.service';
import { PCNASupportService } from '../pipeline/service/pcna-support.service';
import { KeyboardShortcuts, Unlisten } from '../shortcuts/service/keyboard-shortcuts.service';
import { ShortcutsDialogHandlerReactService } from '../shortcuts/service/shortcuts-dialog-handler-react.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
  providers: [
    BannerService,
    OverageDataService
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  private _keyboardUnlisten: Unlisten;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _keyboardShortcuts: KeyboardShortcuts,
    private _trialDetailsService: TrialDetailsService,
    private _authService: AuthService,
    private _userTimezoneService: UserTimezoneService,
    private _pcnaSupportService: PCNASupportService,
    private _route: ActivatedRoute,
    private _router: Router,
    public shortcutsDialogHandlerReactService: ShortcutsDialogHandlerReactService,
    private _planChatSupportService: PlanChatSupportService
  ) {
  }

  ngOnInit() {
    // logout user if team status of account is deleted.
    this._trialDetailsService.accountDeleted$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe();

    this._listenKeyboardEvents();
    this._pollUserData();
    this._setDefaultTimeZone();

    this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(null),
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this._redirectOnHomePageNav();
    });

    this._pcnaSupportService.init();

    this._planChatSupportService.liveChatDisabled$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  private _pollUserData() {
    this._authService.pollUserData().pipe(
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  private _setDefaultTimeZone() {
    this._userTimezoneService.setDefaultTimezone().pipe(
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  private _listenKeyboardEvents() {
    this._keyboardUnlisten = this._keyboardShortcuts.listen(
      {
        'global.keyboardShortcuts': (event: KeyboardEvent): void => {
          this.shortcutsDialogHandlerReactService.closeGlobalSearchDialog();
          this.shortcutsDialogHandlerReactService.openKeyboardShortcutDialog();
          event.preventDefault();
        },
        'global.searchDialog': (event: KeyboardEvent): void => {
          this.shortcutsDialogHandlerReactService.closeKeyboardShortcutDialog();
          this.shortcutsDialogHandlerReactService.openGlobalSearchDialog();
          event.preventDefault();
        }
      }, {
        priority: 0,
        terminal: 'match'
      }
    );
  }

  private _redirectOnHomePageNav() {
    const currentUrl = new URL(window.location.href);

    if (currentUrl.pathname === '/') {
      let redirectUrl = '/pipeline';

      const teamDetails = this._route.snapshot.data.teamDetails;

      if (teamDetails?.sign_up_source === 'ACTIVATE') {
        redirectUrl = '/activation';
      }

      this._router.navigate(
        [ redirectUrl ],
        {
          replaceUrl: true
        }
      );
    }
  }

  ngOnDestroy() {
    if (typeof this._keyboardUnlisten === 'function') {
      this._keyboardUnlisten();
    }

    this._planChatSupportService.reset();

    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
