import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import * as Yup from 'yup';
import { HdAuthRequestType } from '../HdAuth/models';
import { GoogleAuthRequestType, ResetPasswordRequestType } from '../models';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikPasswordField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import ErrorFocus from '../../../utils/ErrorFocus';
import { GoogleLoginButton } from '../GoogleLoginButton';
import { HdAuth } from '../HdAuth';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useGoogleLoginError } from '../useGoogleLoginError';
import { useResetPassword } from '../useResetPassword';
import useService from '../../../hooks/useService';
import { LocalStorageService } from '../../../../app/core/service/local-storage.service';
import { POST_LOGIN_REDIRECT_URL, SETUP_PERSONA_REQUIRED } from '../SetupPersona/constants';
import { useVerifyEmail } from '../useVerifyEmail';
import { StrongPasswordRulesWrapper } from '../../../components/StrongPasswordRulesWrapper';
import { PASSWORD_RULES_SET } from '../../../components/StrongPasswordRulesWrapper/contants';
import { StrongPasswordFieldWrapper } from '../../../components/StrongPasswordRulesWrapper/StrongPasswordFieldWrapper';
import { strongPasswordRegex } from '../../../components/StrongPasswordRulesWrapper/ruleValidators';

export interface AcceptInviteProps {
  featuredCustomers: FeaturedCustomers;
  email: string;
}

const initialValues = {
  password: ''
};

const validationSchema = Yup.object({
  password: Yup.string()
    .strict(true)
    .trim('Password cannot start or end with whitespaces')
    .matches(strongPasswordRegex)
    .required('Password is required')
});

export function AcceptInvite() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { verifyEmail } = useVerifyEmail();

  return (
    <HdResolvedComponent
      Component={AcceptInviteInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers,
        email: verifyEmail
      }}
    />
  );
}

export function AcceptInviteInner({ featuredCustomers, email }: AcceptInviteProps) {
  const [formError, setFormError] = useState(null);
  const [googleLoginError, setGoogleLoginError] = useState(useGoogleLoginError());

  const formikRef = useRef<FormikProps<{ password: string }>>();
  const localStorage = useService(LocalStorageService);
  const { resetPassword } = useResetPassword();
  const { search } = useLocation();
  const history = useHistory();

  const code = new URLSearchParams(search).get('code');

  useEffect(() => {
    /**
     * setting the local storage variable
     * which can define if user has signed up via invite flow
     * so that we can force user to setup persona page
     *
     * and router adding the query param next to handle the
     * page user should land post successful auth
     *
     * if any existing next param is set then capturing that in LS
     */
    const searchParams = new URLSearchParams(search);
    const next = searchParams.get('next');
    searchParams.delete(next);
    searchParams.set('next', '/setup-persona');
    localStorage.set(SETUP_PERSONA_REQUIRED, true);
    localStorage.set(POST_LOGIN_REDIRECT_URL, next);

    history.replace({ search: searchParams.toString() });
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    if (!formikRef.current.isSubmitting) {
      return;
    }

    setFormError(null);
    setGoogleLoginError(null);

    resetPassword(code, values.password, ResetPasswordRequestType.ACCEPT_INVITE).then(
      () => {},
      error => {
        setSubmitting(false);

        const errorMessage = getErrorMessageFromObj(error);
        setFormError(errorMessage);
      }
    );
  };

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.ACCEPT_INVITE}
      featuredCustomers={featuredCustomers}
    >
      <GoogleLoginButton code={code} requestType={GoogleAuthRequestType.ACCEPT_INVITE} />

      {!!googleLoginError && (
        <HdPane
          className='mt-4 w-100'
          variant='error-faded'
          icon='error-filled'
          iconClasses='text-error'
          disableHide
        >
          <div className='text-default'>{googleLoginError}</div>
        </HdPane>
      )}

      <div className='separator-with-content social-login-separator'>
        <span className='separator-content'>OR</span>
      </div>

      <div className='email-display text-center'>
        Log in with your email ID &nbsp;
        {!!email && <span className='text-default text-bold'>{email}</span>}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
        innerRef={formikRef}
      >
        {props => {
          const { isValid, isSubmitting } = props;
          const disableSubmit = !isValid || isSubmitting;

          return (
            <form noValidate onSubmit={props.handleSubmit}>
              <ErrorFocus formik={props} />

              <HdFormControl className='mb-0'>
                <StrongPasswordFieldWrapper
                  title={StrongPasswordRulesWrapper}
                  rules={PASSWORD_RULES_SET}
                  value={formikRef.current?.values?.password}
                >
                  <Field
                    name='password'
                    label='Password'
                    placeholder='Enter Password'
                    component={HdFormikPasswordField}
                    prefixIcon='password'
                    autoFocus
                    required
                  />
                </StrongPasswordFieldWrapper>
              </HdFormControl>

              {!!formError && (
                <HdPane
                  className='mb-1 w-100'
                  variant='error-faded'
                  icon='error-filled'
                  iconClasses='text-error'
                  disableHide
                >
                  <div className='text-default'>{formError}</div>
                </HdPane>
              )}

              <HdButton
                type='submit'
                size='lg'
                className='w-100 mt-4'
                dataId='accept-invite-set-password'
                showProgress={isSubmitting}
                disabled={disableSubmit}
              >
                {isSubmitting ? 'Setting Password' : 'Set Password'}
              </HdButton>
            </form>
          );
        }}
      </Formik>
    </HdAuth>
  );
}
