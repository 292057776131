import React, { useEffect, useState } from 'react';
import useService from '../../../hooks/useService';
import Step1 from './deleteAccountStep1';
import Shimmer from './shimmer';
import TeamAPI from '../TeamAPI';
import AccountDeletionInProgress from './accountDeletionInProgress';
import PaidUserFeedback from './paidUserFeedback';
import useUserService from '../../../hooks/services/useUserService';
import FeedbackForm from './FeedbackForm';
import ConfirmStep from './confirmStep';
import { DELAY_BEFORE_DELETING_ACCOUNT } from '../../../../app/core/constants';
import { FeedbackPages } from './model';
import { EntityUIState } from '../../../../app/core/models/entitiy-ui-state';
import { AuthService } from '../../../../app/core/service/auth.service';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { getDataIdGenerator } from '../../../utils/generateDataId';

export const dataIdGenerator = getDataIdGenerator('deleteAccount');

interface DeleteAccountState {
  data?: {
    subscriptionEndDate?: number;
    isPaidUser?: boolean;
    accountDeletionInProgress?: boolean;
  };
  state: EntityUIState;
  step?: FeedbackPages;
  err?: any;
}

const DELETE_ACCOUNT_INITIAL_STATE: DeleteAccountState = {
  data: {
    isPaidUser: false,
    subscriptionEndDate: null,
    accountDeletionInProgress: false
  },
  step: FeedbackPages.STEP1,
  state: EntityUIState.IDLE,
  err: null
};

export default function DeleteAccount() {
  const _authService = useService(AuthService);
  const { user } = useUserService();

  const [state, setState] = useState<DeleteAccountState>(DELETE_ACCOUNT_INITIAL_STATE);

  useEffect(() => {
    getPageData();
  }, []);

  const logoutAfterDelay = () => {
    setTimeout(() => {
      _authService.logout().subscribe();
    }, DELAY_BEFORE_DELETING_ACCOUNT);
  };

  const getPageData = () => {
    setState({
      ...state,
      err: null,
      state: EntityUIState.LOADING
    });

    Promise.all([
      TeamAPI.getTeamSubscriptions().catch(err => {
        if (err.status === 404) {
          return Promise.resolve({ data: { success: false } });
        }
        return Promise.reject(err);
      }),
      TeamAPI.getTeamDetails()
    ])
      .then((res: any) => {
        setState({
          ...state,
          state: EntityUIState.LOADING
        });

        const teamSubscriptions = res[0];
        const deleteTeamDetails = res[1];

        const markedForDeletion: boolean =
          deleteTeamDetails.success && deleteTeamDetails.data.status === 'MARKED_FOR_DELETION';

        const subscription: boolean =
          teamSubscriptions.success && teamSubscriptions.data.current_end_ts;

        if (markedForDeletion && subscription) {
          setState({
            data: {
              isPaidUser: true,
              subscriptionEndDate: teamSubscriptions.data.current_end_ts
            },
            state: EntityUIState.IDLE,
            step: FeedbackPages.PAID_USER_FEEDBACK
          });
        } else if (markedForDeletion && !subscription) {
          setState({
            data: { accountDeletionInProgress: true },
            state: EntityUIState.IDLE,
            step: FeedbackPages.ACCOUNT_DELETION_IN_PROGRESS
          });
          logoutAfterDelay();
        } else if (subscription && !markedForDeletion) {
          setState({
            data: {
              isPaidUser: true,
              subscriptionEndDate: teamSubscriptions.data.current_end_ts
            },
            state: EntityUIState.IDLE,
            step: FeedbackPages.STEP1
          });
        } else {
          // trial user
          setState({
            data: {},
            state: EntityUIState.IDLE,
            step: FeedbackPages.STEP1
          });
        }
      })
      .catch(error => {
        setState({
          state: EntityUIState.ERRORED,
          err: error
        });
      });
  };

  const pageNavigationHandler = (nextPage: FeedbackPages) => {
    setState(prevState => ({
      ...prevState,
      step: nextPage
    }));
  };

  return (
    <div>
      {state.state === EntityUIState.LOADING && <Shimmer dataId={dataIdGenerator('')} />}

      {state.state === EntityUIState.ERRORED && (
        <RetryApiAlert error={state.err} actionHandler={getPageData} dataId={dataIdGenerator('')} />
      )}

      {state.state === EntityUIState.IDLE && (
        <>
          {state.step === FeedbackPages.STEP1 && (
            <Step1
              onDeleteAccount={() => pageNavigationHandler(FeedbackPages.FEEDBACK_FORM)}
              dataId={dataIdGenerator('')}
            />
          )}

          {state.step === FeedbackPages.FEEDBACK_FORM && (
            <FeedbackForm
              proceedClick={() => pageNavigationHandler(FeedbackPages.CONFIRMATION_PAGE)}
              userEmail={user.email}
              backClick={() => pageNavigationHandler(FeedbackPages.STEP1)}
              dataId={dataIdGenerator('')}
            />
          )}

          {state.step === FeedbackPages.CONFIRMATION_PAGE && (
            <ConfirmStep
              onDeleteEvent={() => pageNavigationHandler(FeedbackPages.PAID_USER_FEEDBACK)}
              isPaidUser={state.data?.isPaidUser}
              onConfirmationCancel={() => pageNavigationHandler(FeedbackPages.STEP1)}
              subscriptionEndDate={state.data?.subscriptionEndDate}
              dataId={dataIdGenerator('')}
            />
          )}

          {state.step === FeedbackPages.PAID_USER_FEEDBACK && (
            <PaidUserFeedback
              subscriptionEndDate={state.data.subscriptionEndDate}
              dataId={dataIdGenerator('')}
            />
          )}

          {state.step === FeedbackPages.ACCOUNT_DELETION_IN_PROGRESS && (
            <AccountDeletionInProgress />
          )}
        </>
      )}
    </div>
  );
}
