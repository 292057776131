import { Component, ElementRef, OnInit } from '@angular/core';
import { ReactPlayground } from '../../react/components/ReactPlayground';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'react-playground',
  template: '',
  providers: [ BridgeService ]
})
export class ReactPlaygroundComponent implements OnInit {
  constructor(
    private _bridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._bridgeService.mount(
      ReactPlayground,
      this._el.nativeElement
    );
  }
}
