import { AnimationEvent } from '@angular/animations';
import { PortalInjector } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Component, ComponentFactory, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { fadeAnim, popupAnim } from '../animations';
import { DialogBase } from '../dialog-base';
import { DialogCommunicationService } from '../dialog-communication.service';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DIALOG_BODY_DATA, DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';
import { DialogContainerPosition, GenericDialogConfig } from './model';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: [ './generic-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class GenericDialogComponent extends DialogBase implements OnInit {
  private readonly _dialogCommService: DialogCommunicationService;

  private _onEscapeClickFn: Function;
  private _onOutsideClickFn: Function;

  @ViewChild('dialogBodyContainer', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

  get containerPosition() {
    if (this.data.stylingConfig?.containerPosition === DialogContainerPosition.START) {
      return 'flex-start';
    }

    if (this.data.stylingConfig?.containerPosition === DialogContainerPosition.END) {
      return 'flex-end';
    }

    return 'center';
  }

  get contentWidth() {
    const dialogWidth = this.data.stylingConfig?.contentWidth;
    if (dialogWidth && typeof dialogWidth === 'number') {
      return dialogWidth + 'px';
    }

    return '500px';
  }

  get marginTop() {
    const dialogMarginTop = this.data.stylingConfig?.marginTop;
    if (dialogMarginTop && typeof dialogMarginTop === 'number') {
      return dialogMarginTop + 'px';
    }

    return '0';
  }

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) public data: GenericDialogConfig,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(document, _dialogRef, data, _keyboardShortcuts);

    this._dialogCommService = new DialogCommunicationService();

    this._dialogCommService.onEscapeClickFunction$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((fn) => {
      this._onEscapeClickFn = fn;
    });

    this._dialogCommService.onOutsideClickFunction$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((fn) => {
      this._onOutsideClickFn = fn;
    });

    this._dialogCommService.onPositiveClick$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((positiveData) => {
      super.onPositiveClick(positiveData);
    });

    this._dialogCommService.onNegativeClick$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((negativeData) => {
      super.onNegativeClick(negativeData);
    });

    this._dialogCommService.hideDialog$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((hideData) => {
      super.hide(hideData);
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
  }

  show() {
    if (this.data.componentRef) {
      const portalInjector = this._createInjector();

      this.viewContainerRef.createComponent(
        this._getNewComponentFactory(this.data.componentRef),
        null,
        portalInjector
      );
    }

    if (this.data.templateRef) {
      this.viewContainerRef.createEmbeddedView(this.data.templateRef, this.data.bodyData);
    }

    super.show();
  }

  private _createInjector() {
    const injectionTokens = new WeakMap();
    injectionTokens.set(DIALOG_BODY_DATA, this.data.bodyData || {});
    injectionTokens.set(DialogCommunicationService, this._dialogCommService);

    return new PortalInjector(this.viewContainerRef.injector, injectionTokens);
  }

  private _getNewComponentFactory(component: any): ComponentFactory<any> {
    return this._componentFactoryResolver.resolveComponentFactory(component);
  }

  onPopupAnimationDone(e: AnimationEvent) {
    this._dialogCommService.popupAnimationDone(e);
  }

  onEscapeClick() {
    if (this._onEscapeClickFn) {
      this._onEscapeClickFn();
    }

    super.onEscapeClick();
  }

  onOutsideClick() {
    if (this._onOutsideClickFn) {
      this._onOutsideClickFn();
    }

    super.onOutsideClick();
  }
}
