import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output
} from '@angular/core';

import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { MenuItemSeparator } from './menu-item-separator';
import { MENU_PANEL, MenuPanel } from './menu-panel';

@Directive()
export class MenuItem implements FocusableOption, OnDestroy, MenuItemSeparator {
  /** Stream that emits when the menu item is hovered. */
  readonly _hovered = new Subject<MenuItem>();

  private _document: Document;
  private _hasSeparator = false;

  @Input() label = '';
  @Input() closeOnClick = true;
  @Input() withSeparator = false;

  @HostBinding('class.disabled') @Input() disabled = false;

  @Output() select = new EventEmitter<MouseEvent | KeyboardEvent>();
  @Output() close = new EventEmitter<void>();

  @HostBinding('class.active') @Input() active = false;
  @HostBinding('class.dark') @Input() dark = false;
  @HostBinding('attr.role') role = 'menuItem';
  @HostBinding('attr.tabindex') tabindex = -1;
  @HostBinding('class.triggers-submenu') _triggersSubmenu = false;

  @HostBinding('class.has-separator')
  set hasSeparator(value) {
    if (this._hasSeparator !== value) {
      this._cdRef.markForCheck();
    }
    this._hasSeparator = value;
  }

  get hasSeparator() {
    return this._hasSeparator;
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this.select.emit(e);
    if (!this.closeOnClick) {
      e.stopPropagation();
    }
  }

  @HostListener('keypress', ['$event']) onEnterKeypress(e: KeyboardEvent) {
    if (this.disabled) {
      return;
    }

    if (e.keyCode === ENTER) {
      this.select.emit(e);
    }
  }

  constructor(
    protected _elementRef: ElementRef,
    protected _cdRef: ChangeDetectorRef,
    @Inject(MENU_PANEL) @Optional() public _parentMenu?: MenuPanel<MenuItem>
  ) {
    if (_parentMenu && _parentMenu.addItem) {
      _parentMenu.addItem(this);
    }
  }

  focus(origin: FocusOrigin) {
    this._elementRef.nativeElement.focus();
    if (origin === 'mouse') {
      this._elementRef.nativeElement.classList.add('origin-mouse');
    } else {
      this._elementRef.nativeElement.classList.remove('origin-mouse');
    }
  }

  getLabel(): string {
    return this.label;
  }

  /** Emits to the hover stream. */
  @HostListener('mouseenter', ['$event']) _handleMouseEnter() {
    this._hovered.next(this);
  }

  triggersSubmenu(): boolean {
    return this._triggersSubmenu;
  }

  ngOnDestroy() {
    if (this._parentMenu && this._parentMenu.removeItem) {
      this._parentMenu.removeItem(this);
    }

    this._hovered.complete();
  }
}
