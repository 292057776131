import { Component, ElementRef, OnInit } from '@angular/core';
import { AccountSuspended } from '../../../react/containers/dashboard/AccountSuspended';
import { BridgeService } from '../../react-bridge/services/bridge.service';

@Component({
  selector: 'account-suspended-react',
  template: '',
  providers: [ BridgeService ]
})
export class AccountSuspendedReactComponent implements OnInit {
  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnInit() {
    this._reactBridgeService.mount(
      AccountSuspended,
      this._el.nativeElement,
      {}
    );
  }
}
