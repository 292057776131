import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './user.service';


declare var Headway: any;
declare var __HEADWAY_ACCOUNT_ID__: any;

@Injectable()
export class HeadwayService {
  user: User;

  constructor(
    private _zone: NgZone,
    private _userService: UserService
  ) { }

  unreadCountSubject = new BehaviorSubject<number>(0);

  init() {
    this.user = this._userService.getUser();

    if (typeof Headway !== 'undefined') {
      Headway.init({
        selector: '#headway_widget_trigger',
        trigger: '#headway_widget_trigger',
        account: __HEADWAY_ACCOUNT_ID__,
        position: {
          x: 'right',
          y: 'top'
        },
        callbacks: {
          onWidgetReady: (widget: any) => {
            this._zone.run(() => {
              this.emitUnreadCount(widget);
            });
          }
        }
      });
    }
  }

  emitUnreadCount(widget: any) {
    const changeLogs: any[] = widget.getChangelogs();

    if (!changeLogs || !changeLogs.length) {
      return;
    }

    const latestPost: any = changeLogs[0];
    const latestPostCreatedTs: number = new Date(latestPost.date_time || latestPost.dateTime).getTime();

    if (latestPostCreatedTs <= new Date(this.user.createdTs).getTime()) {
      return;
    }

    this.unreadCountSubject.next(widget.getUnseenCount());
  }

  markAllSeen() {
    if (typeof Headway !== 'undefined') {
      Headway.markAllSeen();
      this.unreadCountSubject.next(0);
    }
  }
}
