import React, { useEffect } from 'react';
import { Banner } from '../../../components/Banner';
import { BannerColorPalette } from '../../../../app/banner/interface';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import {
  TRACKER_NEW_FEATURES_BANNER_CLOSE,
  TRACKER_NEW_FEATURES_BANNER_CLOSE_CLICK,
  TRACKER_NEW_FEATURES_BANNER_OPEN,
  TRACKER_NEW_FEATURES_BANNER_VIEW_CHANGES_CLICK
} from './constants';
import { HeadwayService } from '../../../../app/core/service/headway.service';
import useService from '../../../hooks/useService';
import { CHANGE_LOG_URL } from '../../../../app/core/constants';
import { HdLink } from '../../../components/UIElements';

export default function NewFeaturesBanner(props) {
  const analyticsTracker = useAnalyticsTracker();
  const headwayService = useService(HeadwayService);

  useEffect(() => {
    analyticsTracker.eventTrack({
      action: TRACKER_NEW_FEATURES_BANNER_OPEN
    });
  }, []);

  const onClose = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_NEW_FEATURES_BANNER_CLOSE_CLICK
    });

    markSeenAndClose();
  };

  const markSeenAndClose = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_NEW_FEATURES_BANNER_CLOSE
    });

    markAllSeen();
    props.close();
  };

  const changeLogClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_NEW_FEATURES_BANNER_VIEW_CHANGES_CLICK
    });

    markSeenAndClose();
  };

  const markAllSeen = () => {
    headwayService.markAllSeen();
  };

  return (
    <Banner
      color={BannerColorPalette.INFO}
      dataId='new-features-banner'
      open
      onClose={onClose}
      canClose
      iconSrc='https://res.cloudinary.com/hevo/image/upload/v1661186305/dashboard/version-update_kecs5g.svg'
      Title={<span>Updates Available!</span>}
    >
      We have exciting updates for you. Take a moment to check out what’s new.{' '}
      <HdLink
        target='_blank'
        dataId='banner-changelog'
        href={CHANGE_LOG_URL}
        color='light'
        onClick={changeLogClick}
      >
        Click Here
      </HdLink>
    </Banner>
  );
}
