import {
  CursorPaginationData,
  CursorPaginationStrategy
} from '../../pagination/list-conductor/cursor-pagination-strategy';
import { ListConductor } from '../../pagination/list-conductor/list-conductor';
import { OffsetPaginationStrategy } from '../../pagination/list-conductor/offset-pagination-strategy';
import { SingleLevelSelectorPageData, SingleLevelSelectorPaginationHelper } from './interface';


export class SingleLevelSelectorOffsetPaginationHelper implements SingleLevelSelectorPaginationHelper {
  getStrategy(pageSize = 10) {
    return new OffsetPaginationStrategy(pageSize);
  }

  shouldAppendPage(listConductor: ListConductor) {
    return false;
  }

  getListResponseData(pageData: SingleLevelSelectorPageData) {
    return {};
  }

  getListSize(pageData: SingleLevelSelectorPageData, currentListSize: number) {
    return pageData.totalCount;
  }
}

export class SingleLevelSelectorCursorPaginationHelper implements SingleLevelSelectorPaginationHelper {
  getStrategy(pageSize = 10) {
    return new CursorPaginationStrategy(pageSize);
  }

  shouldAppendPage(listConductor: ListConductor) {
    const isFirstPage = !(listConductor.getListMeta().pagination as CursorPaginationData).total;

    return !isFirstPage;
  }

  getListResponseData(pageData: SingleLevelSelectorPageData) {
    return { page: pageData.metaData.cursor, hasMore: pageData.metaData.hasMore };
  }

  getListSize(pageData: SingleLevelSelectorPageData, currentListSize: number) {
    if (pageData.totalCount) {
      return pageData.totalCount;
    }

    return pageData.metaData.hasMore ? null : currentListSize;
  }
}
