import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAccessTokenField } from './utils/access-token';


export const CONTENTFUL_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'spaceID',
      fieldId: 'space-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'spaceID', label: 'Space ID', required: true },
      hint: 'Enter the Space ID from “Space Home” → “Get your Parameters” → “Space ID” to identify the Space ID.',
      buildParamsKey: 'space_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      ...getAccessTokenField({
        hint: 'Enter the Access Token from “Space Home” → “Get your Parameters” → “Content Delivery API - Access token” to authenticate.',
      })
    }
  ],
  [
    {
      key: 'EnvironmentID',
      fieldId: 'environment-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'EnvironmentID', label: 'Environment ID', required: true },
      hint: 'Enter the Environment ID from “Settings” → “Environments”  to obtain the desired Environment ID.',
      buildParamsKey: 'environment_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
