import React from 'react';
import { BaseEntityData } from '../../models';
import { NotificationView } from '../notification-view';
import { HdIcon } from '../../../UIElements';
import styles from './styles.module.scss';

export function WorkflowView({ data }: NotificationView<BaseEntityData>) {
  return (
    <div className={styles.workflowViewContainer}>
      <HdIcon name='workflow' size={1} className={styles.workflowIcon} />

      <div className='text-ellipsis text-caption-1'>{data.name}</div>
    </div>
  );
}
