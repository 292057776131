import React from 'react';
import HdPane from '../UIElements/HdPane';

interface FormSuccessInfoProps {
  message: string;
  onClose: Function;
  className?: string;
}

export default function FormApiSuccess({ message, onClose, className }: FormSuccessInfoProps) {
  return (
    <HdPane variant='success' icon='check-circle' onClose={onClose} className={className || ''}>
      <div className='text-body-2'>
        {message}
      </div>
    </HdPane>
  );
}
