import clsx from 'clsx';
import { Papa } from 'ngx-papaparse';
import React from 'react';
import { HdButton, HdIcon, HdIconButton, HdModal } from '../../../../components/UIElements';
import useClipboard from '../../../../hooks/services/useClipboardService';
import useService from '../../../../hooks/useService';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import { isEven } from '../../../../utils/mathUtil';
import styles from './styles.module.scss';

export interface ReplayReasonDetailsDialogProps {
  open: boolean;
  onClose: Function;
  messageReason?: string;
  messageDetails?: any;
}

export function ReplayReasonDetailsDialog(props: ReplayReasonDetailsDialogProps) {
  const { open, onClose } = props;
  return (
    <HdModal open={open} onClose={() => onClose()} styling={{ width: '720px' }}>
      <ReplayReasonDetailsDialogContent {...props} />
    </HdModal>
  );
}

function ReplayReasonDetailsDialogContent({
  onClose,
  messageReason,
  messageDetails
}: ReplayReasonDetailsDialogProps) {
  const clipboardService = useClipboard();
  const papaService = useService(Papa);

  const copyReasonDetails = () => {
    clipboardService.copy(
      _convertReasonDetailToCSV(messageDetails.headers, messageDetails.rows),
      'Object failure reason successfully copied to clipboard'
    );
  };

  const _convertReasonDetailToCSV = (headers: any, rowArr: any) => {
    return papaService.unparse(
      {
        fields: headers,
        data: rowArr
      },
      { quotes: true }
    );
  };

  const dataIdGenerator = getDataIdGenerator('replayReasonDetailsDialog');

  return (
    <>
      <div className='dialog-header dialog-header--absolute'>
        <HdIconButton
          onClick={() => onClose()}
          className='ml-2 pure-ripple'
          dataId={dataIdGenerator('close')}
        >
          <HdIcon name='close' size={3} />
        </HdIconButton>
      </div>

      <div className='dialog-body p-7'>
        <h2 className='text-subheading-3 text-uppercase mb-7 center-flex-row justify-center'>
          <HdIcon name='error-filled' className='text-error' />
          <span className='text-bold ml-1 mr-3 text-error'>Failure Reason</span>
        </h2>

        <div className='box box--table box--no-shadow'>
          <div className='box__header'>
            <div>
              <span className='text-bold text-uppercase'>Summary</span>
            </div>
            <div className='box__header__actions'>
              <HdButton
                variation='outline'
                icon='copy'
                onClick={() => copyReasonDetails()}
                dataId={dataIdGenerator('copy')}
              >
                Copy
              </HdButton>
            </div>
          </div>

          <div className={clsx('box__body', styles.failureReasonsWrapper)}>
            <div className='mx-5 my-4'>
              <span className='text-bold mr-1'>Reason: </span>
              <span>{messageReason}</span>
            </div>

            <table className='box-table'>
              <thead className='border-top'>
                <tr>
                  {messageDetails.headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {messageDetails.rows.map((detailRow, rowInd) => (
                  <tr className={isEven(rowInd) ? 'even' : ''} key={rowInd}>
                    {messageDetails.headers.map((header, cellInd) => (
                      <td key={`${rowInd}-${cellInd}`}>{detailRow[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
