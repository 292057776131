import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { from, Subscription } from 'rxjs';
import { concatMap, delay, first, tap } from 'rxjs/operators';
import { DrawerDir } from '../../../app/drawer/models';
import { wrapIntoObservable } from '../../../app/hd-wizard/utils';
import { NotificationDrawer } from '../../components/NotificationDrawer';
import { SearchActivate } from './SearchActivate';
import { SearchDestination } from './searchDestination';
import { SearchModel } from './searchModel';
import { SearchPipeline } from './SearchPipeline';
import { SearchWorkflow } from './searchWorkflow';
import { TroubleshootDrawer } from './TroubleshootDrawer/TroubleshootDrawer';
import styles from './styles.module.scss';
import { animated } from '@react-spring/web';
import { useDrawerActivationGuard } from './useDrawerActivationGuard';
import { useDrawerAnimation } from './useDrawerAnimation';
import { useDrawerDestinationGuard } from './useDrawerDestinationGuard';
import { useDrawerEmailVerificationGuard } from './useDrawerEmailVerificationGuard';
import { useDrawerModelGuard } from './useDrawerModelGuard';
import { useDrawerPipelineGuard } from './useDrawerPipelineGuard';
import { useDrawerRouteCheckGuard } from './useDrawerRouteCheckGuard';
import { useDrawerWorkflowGuard } from './useDrawerWorkflowGuard';

export interface DrawerDefinition {
  path: string;
  component: React.FC<any>;
  guards?: Array<() => Promise<boolean> | boolean>;
  direction?: DrawerDir;
}

export function DrawersContainer() {
  const location = useLocation();
  const drawerRouteCheckGuard = useDrawerRouteCheckGuard();
  const drawerEmailVerificationGuard = useDrawerEmailVerificationGuard();
  const drawerPipelineGuard = useDrawerPipelineGuard();
  const drawerModelGuard = useDrawerModelGuard();
  const drawerWorkflowGuard = useDrawerWorkflowGuard();
  const drawerDestinationGuard = useDrawerDestinationGuard();
  const drawerActivationGuard = useDrawerActivationGuard();

  const DRAWERS: DrawerDefinition[] = [
    // {
    //   path: 'pipelines',
    //   component: SearchPipeline,
    //   guards: [ drawerRouteCheckGuard, drawerEmailVerificationGuard, drawerPipelineGuard ]
    // },
    // {
    //   path: 'model',
    //   component: SearchModel,
    //   guards: [ drawerRouteCheckGuard, drawerEmailVerificationGuard, drawerModelGuard ]
    // },
    // {
    //   path: 'transform',
    //   component: SearchModel,
    //   guards: [ drawerRouteCheckGuard, drawerEmailVerificationGuard, drawerModelGuard ]
    // },
    // {
    //   path: 'workflow',
    //   component: SearchWorkflow,
    //   guards: [ drawerRouteCheckGuard, drawerEmailVerificationGuard, drawerWorkflowGuard ]
    // },
    // {
    //   path: 'destinations',
    //   component: SearchDestination,
    //   guards: [ drawerRouteCheckGuard, drawerEmailVerificationGuard, drawerDestinationGuard ]
    // },
    // {
    //   path: 'activate',
    //   component: SearchActivate,
    //   guards: [ drawerRouteCheckGuard, drawerEmailVerificationGuard, drawerActivationGuard ]
    // },
    {
      path: 'alerts',
      component: NotificationDrawer,
      direction: 'right'
    },
    {
      path: 'troubleshoot',
      component: TroubleshootDrawer,
      direction: 'right'
    }
  ];

  const [ activeDrawerDefinition, setActiveDrawerDefinition ] = useState<DrawerDefinition>(null);

  const activeDrawerPath = new URLSearchParams(location.search).get('drawer');

  const guardsCheckSub = useRef(Subscription.EMPTY);

  useEffect(() => {
    if (!activeDrawerPath) {
      setActiveDrawerDefinition(null);
      return;
    }

    const drawerDefinition = DRAWERS.find((def) => def.path === activeDrawerPath);

    guardsCheckSub.current = from(drawerDefinition.guards || []).pipe(
      delay(0),
      concatMap((guard) => {
        const guardResp = guard();

        return wrapIntoObservable(guardResp).pipe(first());
      }),
      first(result => {
        return result !== true;
      }, true),
      tap((guardsResult) => {
        if (!guardsResult) {
          setActiveDrawerDefinition(null);
        } else {
          setActiveDrawerDefinition({
            ...drawerDefinition,
            direction: drawerDefinition.direction || 'left'
          });
        }
      })
    ).subscribe();

    return () => {
      guardsCheckSub.current.unsubscribe();
    };
  }, [ activeDrawerPath ]);

  const {
    containerSpringScaleValues,
    containerSpringOpacityValues,
    drawerTransition
  } = useDrawerAnimation(activeDrawerDefinition);

  return (
    <animated.div
      className={styles.drawersContainer}
      style={{
        ...containerSpringScaleValues,
        ...containerSpringOpacityValues
      }}
    >
      {drawerTransition((style, drawerDefinition) => {
        return (
          <drawerDefinition.component
            drawerDirection={drawerDefinition.direction}
            springValues={style}
            closeInitiated={false}
            disableRestoreFocus={false}
            usingReactRouter={true}
          />
        );
      })}
    </animated.div>
  );
}
