import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import { SqlFormState } from './interfaces';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import LoadAllSchema from './LoadAllSchema';
import { HdButton, HdFormControl, HdPane } from '../../../../../components/UIElements';
import { SqlFormFields } from './models';
import { HdFormikTextField } from '../../../../../components/FormikElements';
import { HdSupportButton } from '../../../../../components/SupportButton';

interface RedologAdvancedConfigProps {
  isEditing: boolean;
  isOnlineCatalog: boolean;
}

function RedologAdvancedConfig({ isEditing, isOnlineCatalog }: RedologAdvancedConfigProps) {
  const { isSubmitting, values } = useFormikContext<SqlFormState>();

  return (
    <AdvancedConfig
      className='mb-7'
      label='Redo Log Advanced Settings'
      forceExpand={isSubmitting}
      showBorderBottom
    >
      <HdPane icon='warning' variant='warning-faded' className='mb-7 align-items-start'>
        <div className='d-flex flex-col align-items-start break-word justify-between'>
          <div className='text-default' data-id='redolog-advance-settings-warning-message'>
            Improper configurations of the redo log settings may impact your database
            performance. We recommend that you contact Support before making any changes.
          </div>

          <HdSupportButton
            className='mt-4'
            tag='button'
            icon='human-chat'
            direction='left'
            dataId='chat-support'
            Component={HdButton}
            variation='outline'
            filledBg
            size='md'
          >
            CONTACT SUPPORT
          </HdSupportButton>
        </div>
      </HdPane>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.POLL_INTERVAL}
              label='Poll Interval (in ms)'
              component={HdFormikTextField}
              type='number'
              required
              helperText={
                <Typography variant='caption'>
                  The delay (in milliseconds) between checks for new transactions in redo logs.
                  Applicable only if pipeline is in streaming mode.
                </Typography>
              }
            />
          </HdFormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.QUERY_FETCH_SIZE}
              label='Query Fetch Size'
              required
              component={HdFormikTextField}
              type='number'
              helperText={
                <Typography variant='caption'>
                  The maximum number of rows that Hevo must fetch from the logs in each cycle.
                </Typography>
              }
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HdFormControl>
            <Field
              name={SqlFormFields.LONG_TRANSACTION_WINDOW}
              label='Long Transaction Window (in mins)'
              component={HdFormikTextField}
              type='number'
              required
              value={values[SqlFormFields.LONG_TRANSACTION_WINDOW]?.toString()}
              helperText={
                <Typography variant='caption'>
                  The duration for which Hevo must traverse back from the latest transaction to
                  fetch the data from a long-running transaction.
                </Typography>
              }
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <LoadAllSchema isEditing={isEditing} />

      <HdFormikSwitchWrapper
        fieldName={SqlFormFields.ONLINE_CATALOG}
        label='Online Catalog'
        disabled={isEditing && isOnlineCatalog}
      >
        If enabled, Hevo retrieves the latest schema information on tables and columns from the
        specified Oracle database. This setting is ideal when schema changes are infrequent or
        nonexistent in the database tables.
      </HdFormikSwitchWrapper>

      <HdFormikSwitchWrapper
        fieldName={SqlFormFields.ARCHIVE_LOG_ONLY}
        label='Archive Log Only'
      >
        If enabled, Hevo ingests data only from archived logs.
      </HdFormikSwitchWrapper>
    </AdvancedConfig>
  );
}

export default RedologAdvancedConfig;
