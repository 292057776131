<div
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)"
  #container>

  <div
    class="dialog-content"
    #content
    [@popupAnim]="{
      value: visibleState,
      params: {
      initialPositionX: initialPositionX,
      finalPositionX: finalPositionX,
      initialPositionY: initialPositionY,
      finalPositionY: finalPositionY
      }
    }"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>
    <div class="dialog-body">
      <figure class="support-executives">
        <img src="https://res.cloudinary.com/hevo/image/upload/v1611139170/thumbnails/dashboard-support-executives_qcqkq7.png">
      </figure>

      <h3 class="concern">
        {{ askSupportData.concern }}
      </h3>

      <div class="d-flex align-items-start mt-7">
        <button
          type="button"
          hdButton
          variation="flat"
          (click)="onCancelClick()">
          I'll fix it myself
        </button>

        <div class="center-flex-col ml-4">
          <button
            type="button"
            cdkFocusInitial
            hdButton
            (click)="onPositiveClick()">
            Take Me To Live Chat
          </button>

          <span class="text-secondary mt-2">
            Get reply within seconds
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
