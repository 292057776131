import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  ReplayReasonDetailsDialog
} from '../../react/containers/pipeline/Sideline/ReplayReasonDetailsDialog/ReplayReasonDetailsDialog';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'replay-reason-details-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class ReplayReasonDetailsDialogReactComponent implements OnChanges {
  @Input() open = false;
  @Input() messageReason: string;
  @Input() messageDetails: any;

  @Output() onClose = new EventEmitter<any>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount(
      ReplayReasonDetailsDialog,
      this._el.nativeElement,
      {
        open: this.open,
        messageReason: this.messageReason,
        messageDetails: this.messageDetails,
        onClose: this.onClose.emit.bind(this.onClose)
      }
    );
  }
}
