// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.iframeContainer_BTaKB {\n  height: 100%;\n  background-color: var(--secondary-bg-color);\n  border-left: solid 1px var(--divider-color);\n  overflow: hidden;\n}\n.iframeContainer_BTaKB.backdrop_pSeVa::after {\n  content: \" \";\n  background: var(--surface-bg-60-color);\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  padding: 10.4px 0px;\n  top: 0;\n  left: 0;\n  z-index: 4;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeContainer": "iframeContainer_BTaKB",
	"backdrop": "backdrop_pSeVa"
};
export default ___CSS_LOADER_EXPORT___;
