import React from 'react';
import { HdIcon, HdIconButton, HdTooltip } from '../../../UIElements';
import HdModal from '../../../UIElements/HdModal';
import styles from './styles.module.scss';
import { TreeView } from './TreeSchema';

interface NestedFieldExplorerProps {
  open: boolean;
  onClose: Function;
  field: any;
  parentPath: string;
  childAccessor: string;
}

export function NestedFieldExplorer({
  open,
  onClose,
  field,
  parentPath,
  childAccessor
}: NestedFieldExplorerProps) {
  return (
    <HdModal onClose={() => onClose()} open={open} styling={{ width: '550px' }} placement='top'>
      <>
        <div className='dialog-header'>
          <div className='dialog-title text-transform-none'>
            <HdTooltip title={field.name} enableOnTextOverflow>
              <span>{field.name}</span>
            </HdTooltip>

            <span className={styles.fieldDataType}>
              {field.type}

              {field.value_type && <span>({field.value_type})</span>}
            </span>
          </div>

          <HdIconButton dataId={`${parentPath}-${field.name}-close`} onClick={() => onClose()}>
            <HdIcon name='close' />
          </HdIconButton>
        </div>

        <div className={`dialog-body ${styles.treeViewPadding}`}>
          {(field[childAccessor] || []).map((node, i) => (
            <TreeView key={i} node={node} childrenAccessor={childAccessor} />
          ))}
        </div>
      </>
    </HdModal>
  );
}
