import { Directive, ElementRef, NgZone, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { CoordinatedHeightAnchorDirective } from './coordinated-height-anchor.directive';

declare var ResizeObserver: any;

@Directive({
  selector: '[hdCoordinatedHeight]'
})
export class CoordinatedHeightDirective implements OnInit, OnDestroy {
  private _resizeObserver: any;
  private _anchorResizeObserver: any;

  constructor(
    private _el: ElementRef,
    @SkipSelf() private _heightAnchor: CoordinatedHeightAnchorDirective,
    private _ngZone: NgZone
  ) {
  }

  ngOnInit() {
    this.createResizeObservers();
    this._resizeObserver.observe(this._el.nativeElement);
    this._anchorResizeObserver.observe(this._heightAnchor.el.nativeElement);
  }

  updateHeight() {
    this._ngZone.runOutsideAngular(() => {
      const anchorHeight = this._heightAnchor.getAnchorHeight();

      const property = this._el.nativeElement.offsetHeight < anchorHeight ? 'maxHeight' : 'height';

      if (property === 'maxHeight') {
        this._el.nativeElement.style.height = 'auto';
      } else {
        this._el.nativeElement.style.maxHeight = 'none';
      }

      this._el.nativeElement.style[property] = anchorHeight + 'px';
    });
  }

  createResizeObservers() {
    this._resizeObserver = new ResizeObserver(entries => {
      this.updateHeight();
    });

    this._anchorResizeObserver = new ResizeObserver(entries => {
      this.updateHeight();
    });
  }

  ngOnDestroy() {
    this._resizeObserver.unobserve(this._el.nativeElement);
    this._anchorResizeObserver.unobserve(this._heightAnchor.el.nativeElement);
  }
}
