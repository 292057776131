import * as Yup from 'yup';
import {
  CRON_TYPE_DAILY,
  CRON_TYPE_FIXED_INTERVAL,
  CRON_TYPE_WEEKLY,
  FREQUENCY_TYPE_HOURS
} from '../../../app/dialog/execution-policy-dialog/constants';
import { CronTime } from '../../../app/dialog/execution-policy-dialog/models';
import { SchedulerType, Policy } from './models';
import { getCronTimeDisplayValue } from './utils';

const CRON_TYPE_FORM_INITIAL_VALUES = {
  selectedCustomOption: CRON_TYPE_FIXED_INTERVAL,
  frequencyValue: null,
  cronDays: [],
  cronTime: null
};

const MULTI_CRON_FORM_INITIAL_VALUES = {
  selectedCustomOption: CRON_TYPE_FIXED_INTERVAL.value,
  frequencyValue: null,
  cronTimeList: [null]
};

const FREQUENCY_TYPE_FORM_INITIAL_VALUES = {
  frequencyValue: null
};

const isFixedInterval = option => option?.value === CRON_TYPE_FIXED_INTERVAL.value;
const isDailyCron = option => option?.value === CRON_TYPE_DAILY.value;
const isWeeklyCron = option => option?.value === CRON_TYPE_WEEKLY.value;

const frequencySchema = (policyObj: Policy) =>
  Yup.number()
    .min(getValidationFrequency(policyObj.minFrequency))
    .max(getValidationFrequency(policyObj.maxFrequency))
    .required()
    .integer();

const frequencyValidationSchema = (policyObj: Policy) =>
  Yup.object().shape({
    frequencyValue: frequencySchema(policyObj)
  });

const cronValidationSchema = policyObj =>
  Yup.lazy((values: any) =>
    Yup.object().shape({
      selectedCustomOption: Yup.object().required(),
      frequencyValue: Yup.number().when('selectedCustomOption', {
        is: isFixedInterval,
        then: () => frequencySchema(policyObj),
        otherwise: () => Yup.number().notRequired().nullable()
      }),
      cronDays: Yup.mixed().when('selectedCustomOption', {
        is: isWeeklyCron,
        then: () => Yup.array().min(1).required(),
        otherwise: () => Yup.array().notRequired()
      }),
      cronTime:
        isDailyCron(values.selectedCustomOption) || isWeeklyCron(values.selectedCustomOption)
          ? Yup.object()
              .test({
                name: 'time-test',
                message: 'time is required',
                test: (value: any) =>
                  value && typeof value.hour === 'number' && typeof value.minute === 'number'
              })
              .required()
          : Yup.object().nullable()
    })
  );

export const multiCronValidationSchema = (policyObj: Policy) =>
  Yup.object().shape({
    selectedCustomOption: Yup.string().required(),
    frequencyValue: Yup.number().when('selectedCustomOption', {
      is: selectedCustomOption => selectedCustomOption === CRON_TYPE_FIXED_INTERVAL.value,
      then: () => frequencySchema(policyObj),
      otherwise: () => Yup.number().notRequired().nullable()
    }),
    cronTimeList: Yup.array().when('selectedCustomOption', {
      is: selectedCustomOption => selectedCustomOption === CRON_TYPE_DAILY.value,
      then: () =>
        Yup.array().of(
          Yup.object()
            .required(' ')
            .nullable()
            .test('validate-time', 'Value is already added.', function (value: CronTime) {
              const cronTimeList = this.parent || [];
              const duplicateArr = cronTimeList.filter(
                x => x && value && value.hour === x.hour && value.minute === x.minute
              );
              if (duplicateArr.length > 1) {
                const duplicateVal = duplicateArr[0];
                const lastIndex = cronTimeList.findLastIndex(
                  x => x && duplicateVal.hour === x.hour && duplicateVal.minute === x.minute
                );
                if (this.path === `cronTimeList[${lastIndex}]`) {
                  return this.createError({
                    message: `${getCronTimeDisplayValue(value)} is already added.`
                  });
                }
              }

              return true;
            })
        ),
      otherwise: () => Yup.array().notRequired()
    })
  });

export function validationSchema(
  schedulerType: SchedulerType,
  isCustomSelected: boolean,
  policyObj: Policy
) {
  if (!isCustomSelected) {
    return Yup.object().shape({});
  }

  if (schedulerType === SchedulerType.CronType) {
    return cronValidationSchema(policyObj);
  }

  if (schedulerType === SchedulerType.MultiCronType) {
    return multiCronValidationSchema(policyObj);
  }

  return frequencyValidationSchema(policyObj);
}

export function getExecutionPolicyFormInitialValues(schedulerType) {
  if (schedulerType === SchedulerType.CronType) {
    return CRON_TYPE_FORM_INITIAL_VALUES;
  }

  if (schedulerType === SchedulerType.MultiCronType) {
    return MULTI_CRON_FORM_INITIAL_VALUES;
  }

  return FREQUENCY_TYPE_FORM_INITIAL_VALUES;
}

function getValidationFrequency(frequency: any): number {
  if (frequency === Infinity) {
    return frequency;
  }

  if (frequency >= FREQUENCY_TYPE_HOURS.minSeconds) {
    return frequency / FREQUENCY_TYPE_HOURS.minSeconds;
  }

  return 1;
}
