import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const WORKABLE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPITokenField({
        hint: 'The API key generated from Settings → Integrations → Generate new token.',
        sbsDocLink: '/sources/fna-analytics/workable/#obtaining-the-access-token-and-subdomain',
      })
    }
  ],
  [
    {
      key: 'subdomain',
      fieldId: 'subdomain',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'subdomain', label: 'Subdomain', required: true },
      hint: 'The Subdomain generated from Settings → Company Profile → Subdomain.',
      buildParamsKey: 'subdomain',
      sbsDocLink: '/sources/fna-analytics/workable/#obtaining-the-access-token-and-subdomain',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
