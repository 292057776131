import { Component, Input } from '@angular/core';
import { RBAC_DISABLED_MESSAGE } from '../../core/models/rbac';
import { RbacPermissions } from '../../core/models/user';
import { RbacService } from '../../core/service/rbac.service';

import { PipelinePriorityEnum } from '../../pipeline/models/models';
import { isPipelineInStreamingMode } from '../../../react/containers/drawer/SearchPipeline/Utils';

@Component({
  selector: 'hd-pipeline-priority',
  templateUrl: './pipeline-priority.component.html',
  styleUrls: ['./pipeline-priority.component.scss']
})
export class PipelinePriorityComponent {
  constructor(
    private _rbacService: RbacService
  ) {}
  @Input() pipeline: any;
  @Input() disabled = false;
  @Input() rbacPermission: RbacPermissions;
  priorityTypes = PipelinePriorityEnum;

  getTooltipMessage() {
    if (!this._rbacService.canTakeAction(this.rbacPermission)){
      return RBAC_DISABLED_MESSAGE;
    }

    if (isPipelineInStreamingMode(this.pipeline)) {
      return 'The priority of streaming mode Pipelines cannot be changed'
    }

    if (this.disabled) {
      return 'Configure this Pipeline to change priority.';
    }

    if (this.pipeline.priority === PipelinePriorityEnum.HIGH) {
      return 'This Pipeline will be processed at a higher priority. Click to assign a lower priority';
    }

   

    return 'Click to assign a higher priority.';
  }

  isDisabled() {
    return this.disabled || !this._rbacService.canTakeAction(this.rbacPermission);
  }
}
