import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  PipelinePermissionFailureDialog,
  PipelinePermissionFailureDialogProps
} from '../../react/components/Dialog/PipelinePermissionFailureDialog/PipelinePermissionFailureDialog';
import { HevoEntity } from '../core/models/hevo-entity';
import { BridgeService } from '../react-bridge/services/bridge.service';

@Component({
  selector: 'pipeline-permission-failure-dialog-react',
  template: '',
  providers: [ BridgeService ]
})
export class PipelinePermissionFailureDialogReactComponent implements OnChanges {
  @Input() open = false;
  @Input() docLink: string;
  @Input() entityDisplayName: string;
  @Input() entity: HevoEntity;
  @Input() entityName: string;

  @Output() onClose = new EventEmitter<any>();

  constructor(
    private _reactBridgeService: BridgeService,
    private _el: ElementRef
  ) {
  }

  ngOnChanges() {
    this._reactBridgeService.mount<PipelinePermissionFailureDialogProps>(
      PipelinePermissionFailureDialog,
      this._el.nativeElement,
      {
        open: this.open,
        docLink: this.docLink,
        entityDisplayName: this.entityDisplayName,
        entity: this.entity,
        entityName: this.entityName,
        onClose: this.onClose.emit.bind(this.onClose)
      }
    );
  }
}
