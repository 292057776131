import { animate, state, style, transition, trigger } from '@angular/animations';

export function askSupportPopupAnimation() {
  return trigger('popupAnim', [
    state('in', style({ transform: 'translate(0, 0) scale(1)', opacity: 1 })),
    state('out', style({
      transform: 'translate({{ finalPositionX }}px, {{ finalPositionY }}px) scale(0) ',
      opacity: 0
    }), { params: { finalPositionX: 0, finalPositionY: 0 } }),
    transition('void => in',
      [
        style({
          transform: 'translate({{ initialPositionX }}px, {{ initialPositionY }}px) scale(0) ',
          opacity: 0
        }),
        animate('.25s cubic-bezier(1, .8, .25, 1)')
      ],
      { params: { initialPositionX: 0, initialPositionY: 0 } }
    ),
    transition('in => out', animate('.25s cubic-bezier(.25, .8, .25, 1)'))
  ]);
}
