// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.checkbox__HuS4 {\n  margin-right: 8px !important;\n}\n\n.text_XCszZ {\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n}\n.text_XCszZ label {\n  cursor: pointer;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "checkbox__HuS4",
	"text": "text_XCszZ"
};
export default ___CSS_LOADER_EXPORT___;
