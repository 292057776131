export enum AWSAuthenticationType {
  IAM_ROLE = 'IAM_ROLE',
  ACCESS_CREDENTIALS = 'ACCESS_CREDENTIALS'
}

export enum AWSFormField {
  AUTH_TYPE = 'authenticationType',
  ACCESS_KEY = 'accessKey',
  SECRET_KEY = 'secretKey',
  IAM_ROLE_ARN = 'iamRoleARN',
  IAM_EXTERNAL_ID = 'iamExternalId'
}

export const IAM_ROLE_ARN_REGEX =
  /^arn:(aws|aws-us-gov|aws-cn):iam::\d{12}:role\/[a-zA-Z0-9-_\/.]+$/;

export const IAM_ROLE_ARN_SBS_LINK = {
  'S3': '#obtain-the-arn-and-external-id',
  'DYNAMODB': '#create-an-iam-role-and-obtain-its-arn-and-external-id'
}
