import React from 'react';
import { MEMBERS_SHIMMER_DIMENSIONS } from './constants';
import styles from './styles.module.scss';

export default function MembersListShimmer() {
 return (
   <>
     {MEMBERS_SHIMMER_DIMENSIONS.map((dimension, index) => (
       <div className={`${styles.member} alternate-table-row`} key={index}>
         <div className={styles.memberNameWrapper}>
           <div
             className='shimmer shimmer-circle mr-4'
             style={{ width: '35px', height: '35px' }}
           >
           </div>

           <div>
             <div
               className='shimmer shimmer-line'
               style={{ width: dimension.name }}
             >
             </div>

             <div
               className='shimmer shimmer-line mt-1'
               style={{ width: dimension.email, height: '8px' }}
             >
             </div>
           </div>
         </div>

         <div className={styles.memberActions}>
           <div
             className='shimmer shimmer-line'
             style={{ width: '126px' }}
           >
           </div>
         </div>
       </div>
     ))}
   </>
 );
}
