import { Injectable } from '@angular/core';
import { TrackerConfig, UITracker } from '../models/tracking';
import { User } from '../models/user';
import { ExecIfDefined } from '../../../react/legacy-utils/decorators';

declare var analytics: any;
declare var __SEGMENT_CONFIG__: TrackerConfig;

@Injectable({ providedIn: 'root' })
export class SegmentService implements UITracker {
  config = __SEGMENT_CONFIG__;

  @ExecIfDefined('analytics')
  init() {
  }

  @ExecIfDefined('analytics')
  identifyUser(user: User) {
    analytics.identify(user.email, {
      'userId': user.id,
      'company': user.teamName,
      'companyId': user.teamId,
      'env': user.clusterId,
      'email': user.email,
      'name': user.name,
      'created': user.createdTs
    });
  }

  updateIdentity(data: User) {
    this.identifyUser(data);
  }

  @ExecIfDefined('analytics')
  updateUserProperties(user: User, data: any) {
    this.identifyUser({ ...user, ...data });
  }

  @ExecIfDefined('analytics')
  reset() {
    analytics.reset();
  }

  @ExecIfDefined('analytics')
  stop() {

  }
}
