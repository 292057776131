/**
 * Imported from lodash
 */
const nativeKeys = Object.keys;

const nativeIsArray = Array.isArray;
const isArray = nativeIsArray || function(obj) {
  return toString.call(obj) === '[object Array]';
};

export function isEqual(a, b, aStack?: any[], bStack?: any[]) {
  // Identical objects are equal. `0 === -0`, but they aren't identical.
  // See the [Harmony `egal` proposal](https://wiki.ecmascript.org/doku.php?id=harmony:egal).
  if (a === b) { return a !== 0 || 1 / a === 1 / b; }
  // `null` or `undefined` only equal to itself (strict comparison).
  if (a == null || b == null) { return false; }
  // `NaN`s are equivalent, but non-reflexive.
  if (a !== a) { return b !== b; }
  // Exhaust primitive checks
  const type = typeof a;
  if (type !== 'function' && type !== 'object' && typeof b !== 'object') { return false; }
  return deepEq(a, b, aStack, bStack);
}

// Internal recursive comparison function for `isEqual`.
export function deepEq(a, b, aStack, bStack) {

  const className = a.toString();
  
  if (className !== b.toString()) { return false; }
  switch (className) {
    // Strings, numbers, regular expressions, dates, and booleans are compared by value.
    case '[object RegExp]':
    // RegExps are coerced to strings for comparison (Note: '' + /a/i === '/a/i')
    case '[object String]':
      // Primitives and their corresponding object wrappers are equivalent; thus, `"5"` is
      // equivalent to `new String("5")`.
      
      return '' + a === '' + b;
    case '[object Number]':
      // `NaN`s are equivalent, but non-reflexive.
      // Object(NaN) is equivalent to NaN.
      if (+a !== +a) { return +b !== +b; }
      // An `egal` comparison is performed for other numeric values.
      return +a === 0 ? 1 / +a === 1 / b : +a === +b;
    case '[object Date]':
    case '[object Boolean]':
      // Coerce dates and booleans to numeric primitive values. Dates are compared by their
      // millisecond representations. Note that invalid dates with millisecond representations
      // of `NaN` are not equivalent.
      return +a === +b;
    case '[object Symbol]':
      return Symbol.prototype.valueOf.call(a) === Symbol.prototype.valueOf.call(b);
  }

  const areArrays = className === '[object Array]';
  if (!areArrays) {
    if (typeof a !== 'object' || typeof b !== 'object') { return false; }

    // Objects with different constructors are not equivalent, but `Object`s or `Array`s
    // from different frames are.
    const aCtor = a.constructor, bCtor = b.constructor;
    if (aCtor !== bCtor && !(isFunction(aCtor) && aCtor instanceof aCtor &&
      isFunction(bCtor) && bCtor instanceof bCtor)
      && ('constructor' in a && 'constructor' in b)) {
      return false;
    }
  }
  // Assume equality for cyclic structures. The algorithm for detecting cyclic
  // structures is adapted from ES 5.1 section 15.12.3, abstract operation `JO`.

  // Initializing stack of traversed objects.
  // It's done here since we only need them for objects and arrays comparison.
  aStack = aStack || [];
  bStack = bStack || [];
  let length = aStack.length;
  while (length--) {
    // Linear search. Performance is inversely proportional to the number of
    // unique nested structures.
    if (aStack[length] === a) { return bStack[length] === b; }
  }

  // Add the first object to the stack of traversed objects.
  aStack.push(a);
  bStack.push(b);

  // Recursively compare objects and arrays.
  if (areArrays) {
    // Compare array lengths to determine if a deep comparison is necessary.
    length = a.length;
    if (length !== b.length) { return false; }
    // Deep compare the contents, ignoring non-numeric properties.
    while (length--) {
      if (!isEqual(a[length], b[length], aStack, bStack)) { return false; }
    }
  } else {
    // Deep compare objects.
    const objKeys = keys(a);
    let key;
    length = objKeys.length;
    // Ensure that both objects contain the same number of properties before comparing deep equality.
    if (keys(b).length !== length) { return false; }
    while (length--) {
      // Deep compare each member
      key = objKeys[length];
      if (!(has(b, key) && isEqual(a[key], b[key], aStack, bStack))) { return false; }
    }
  }
  // Remove the first object from the stack of traversed objects.
  aStack.pop();
  bStack.pop();
  return true;
}

export function keys(obj) {
  if (!isObject(obj)) { return []; }
  if (nativeKeys) { return nativeKeys(obj); }
  const objKeys = [];
  for (const key in obj) { if (has(obj, key)) { objKeys.push(key); } }
}

function isFunction(obj) {
  return typeof obj === 'function' || false;
}

function has(obj, path) {
  return obj != null && obj.hasOwnProperty(path);
}

function isObject(obj) {
  const type = typeof obj;
  return type === 'function' || type === 'object' && !!obj;
}
