export class BigqueryDestinationTrackingActions {
  static PROJECT_LIST_EMPTY = '[Bigquery Dest] project list empty';

  static DATASET_LIST_EMPTY = '[Bigquery Dest] dataset list empty';

  static BUCKET_LIST_EMPTY = '[Bigquery Dest] bucket list empty';

  static CREATE_PROJECT_DOC_LINK_CLICK = '[Bigquery Dest] create project doc link click';

  static DATASET_CHECK_PERMISSION_CLICK = '[Bigquery Dest] dataset check permission click';

  static BUCKET_CHECK_PERMISSION_CLICK = '[Bigquery Dest] bucket check permission click';

  static PROJECT_REFRESH_LIST_CLICK = '[Bigquery Dest] project refresh list click';

  static DATASET_REFRESH_LIST_CLICK = '[Bigquery Dest] dataset refresh list click';

  static BUCKET_REFRESH_LIST_CLICK = '[Bigquery Dest] bucket refresh list click';

  static DATASET_CREATE_NEW_CLICK = '[Bigquery Dest] dataset create new click';

  static BUCKET_CREATE_NEW_CLICK = '[Bigquery Dest] bucket create new click';

  static DATASET_AUTO_CREATE_TOGGLE = '[Bigquery Dest] dataset auto create toggle';

  static BUCKET_AUTO_CREATE_TOGGLE = '[Bigquery Dest] bucket auto create toggle';

  static DATASET_FETCHING_PERMISSION = '[Bigquery Dest] dataset fetching permission';

  static BUCKET_FETCHING_PERMISSION = '[Bigquery Dest] bucket fetching permission';

  static DATASET_PERMISSION_FETCHED = '[Bigquery Dest] dataset permission fetched';

  static BUCKET_PERMISSION_FETCHED = '[Bigquery Dest] bucket permission fetched';
}
