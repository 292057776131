import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TeamSettingsService } from '../service/team-settings.service';

@Injectable({
  providedIn: 'root'
})
export class TeamSettingsGuard implements CanActivate {

  protected constructor(
    private _teamSettingsService: TeamSettingsService,
  ) {}

  // The order of execution is: BaseGuard, ChildGuard, BaseDataResolver, ChildDataResolver.
  // https://angular.io/api/router/Resolve#usage-notes
  // RBAC GUARDS use permissions and team-settings value to determine the permission check
  // since angular resolves guards before resolvers the team-settings might not be resolved, so moving this to guard
  // this guard always returns true
  canActivate(): Promise<boolean> | boolean {
    return this._teamSettingsService.getTeamSettings(false, false, false).pipe(
      map(() => true),
      catchError(() => {
        return of(true);
      })
    ).toPromise();
  }
}
