import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
import { HdA11yModule } from '../a11y/a11y.module';
import { ActivityLoggerModule } from '../activity-logger/activity-logger.module';
import { BadgeModule } from '../badge/badge.module';
import { BannerModule } from '../banner/banner.module';
import { DialogModule } from '../dialog/dialog.module';
import { DrawerModule } from '../drawer/drawer.module';
import { HdFormModule } from '../hd-form/hd-form.module';
import { IpModule } from '../ip/ip.module';
import { MenuModule } from '../menu/menu.module';
import { HdMomentModule } from '../moment/moment.module';
import { PipeModule } from '../pipe/pipe.module';
import { PopupModule } from '../popup/popup.module';
import { ProductTourModule } from '../product-tour/product-tour.module';
import { SharedModule } from '../shared/shared.module';
import { ShortcutsModule } from '../shortcuts/shortcuts.module';
import { MatSlideToggleModule } from '../slide-toggle/slide-toggle-module';
import { SnackbarModule } from '../snackbar/snackbar.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { AppbarReactComponent } from './appbar-react/appbar-react.component';
import { AccountSuspendedReactComponent } from './account-suspended-react/account-suspended-react.component';
import { CreateEntityRedirection } from './create-entity-redirection/create-entity-redirection';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ProductTourV2Component } from './tour/product-tour-v2.component';
import { DashboardComponent } from './dashboard.component';
import { DownloadPageReactComponent } from './download-page-react/download-page-react.component';
import { EmailVerificationReactComponent } from './email-verification-react/email-verification-react.component';
import { TopBarReactComponent } from './top-bar-react/top-bar-react.component';
import { TrialExpiredReactComponent } from './trial-expired-react/trial-expired-react.component';
import { JobMonitoringReactComponent } from './job-monitoring-react/job-monitoring-react.component';

@NgModule({
  imports: [
    CommonModule,
    HdMomentModule,
    FormsModule,
    SharedModule,
    SnackbarModule,
    IpModule,
    MenuModule,
    PopupModule,
    HdA11yModule,
    TooltipModule,
    BadgeModule,
    ShortcutsModule.forRoot(),
    DialogModule.forRoot(),
    DrawerModule.forRoot(),
    ActivityLoggerModule.forRoot(),
    DashboardRoutingModule,
    PipeModule,
    HdFormModule,
    BannerModule,
    MatSlideToggleModule,
    Angulartics2Module,
    ProductTourModule.forRoot()
  ],
  declarations: [
    DashboardComponent,
    AppbarReactComponent,
    EmailVerificationReactComponent,
    ProductTourV2Component,
    AccountSuspendedReactComponent,
    DownloadPageReactComponent,
    TrialExpiredReactComponent,
    CreateEntityRedirection,
    TopBarReactComponent,
    JobMonitoringReactComponent
  ]
})
export class DashboardModule {
}
