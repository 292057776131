import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HdA11yModule } from '../a11y/a11y.module';
import { BridgeService } from '../react-bridge/services/bridge.service';

import { ActivityLogDialogReactComponent } from './activity-log-dialog-react/activity-log-dialog-react.component';

import { ActivityLogDialogService } from './activity-log-dialog-react/activity-log-dialog.service';
import { ActivityLogPageReactComponent } from './activity-log-page-react.component';

@NgModule({
  imports: [
    CommonModule,
    HdA11yModule,
    RouterModule.forChild([])
  ],
  declarations: [
    ActivityLogPageReactComponent,
    ActivityLogDialogReactComponent
  ],
  exports: [
    ActivityLogDialogReactComponent
  ]
})
export class ActivityLoggerModule {
  static forRoot(): ModuleWithProviders<ActivityLoggerModule> {
    return {
      ngModule: ActivityLoggerModule,
      providers: [
        ActivityLogDialogService,
        BridgeService
      ]
    };
  }
}
