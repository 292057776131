import React from 'react';
import styles from '../ActivityLogList/styles.module.scss';

const activityCount = 4;

export default function ActivityLogSkeleton({ loadMore }: { loadMore?: boolean }) {
  const activityCardList = Array.from(Array(loadMore ? 1 : activityCount).keys());
  return (
    <div className={`${styles.activityContainer} ${loadMore ? styles.loadMoreContainer : ''}`}>
      {activityCardList.map(item => (
        <div className={styles.activity} key={item}>
          <div className={`${styles.activityCard} ${styles.shimmerContainer}`}>
            <div className={styles.activityCardLeft}>
              <div className={`shimmer shimmer-line my-2 ${styles.shimmerBig}`} />
            </div>

            <div className={styles.activityCardRight}>
              <div className={`shimmer shimmer-line ${styles.shimmerSmall}`} />
              <div className={`shimmer shimmer-circle ${styles.shimmerCircle}`} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
