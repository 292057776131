// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dialogBody_EyjLx {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 24px;\n}\n\n.confirmationTitle__deV2 {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 24px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  text-align: center;\n}\n\n.confirmationBody_TLV2z {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  text-align: center;\n  margin-top: 8px;\n}\n\n.confirmationSubText___JFV {\n  text-align: center;\n  margin-top: 8px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogBody": "dialogBody_EyjLx",
	"confirmationTitle": "confirmationTitle__deV2",
	"confirmationBody": "confirmationBody_TLV2z",
	"confirmationSubText": "confirmationSubText___JFV"
};
export default ___CSS_LOADER_EXPORT___;
