import { NgZone } from '@angular/core';
import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { useHistory } from '@useHistory/useHistoryCompat';
import * as Yup from 'yup';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikDropDown, HdFormikTextField } from '../../../components/FormikElements';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import { HdPane, HdTooltip } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import AuthAPI from '../AuthAPI';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useAuthGuard } from '../useAuthGuard';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { DESIGNATIONS, POST_LOGIN_REDIRECT_URL, SETUP_PERSONA_REQUIRED } from './constants';
import useUserService from '../../../hooks/services/useUserService';
import useService from '../../../hooks/useService';
import { LocalStorageService } from '../../../../app/core/service/local-storage.service';

export interface SetupPersonaProps {
  featuredCustomers: FeaturedCustomers;
}

const initialValues = {
  email: '',
  userRole: '',
  role: undefined
};

const isOtherRoleSelected = role => role?.id === 'other';

const validationSchema = Yup.object({
  email: Yup.string().required('Email is required'),
  role: Yup.object().required('Role is required'),
  userRole: Yup.string().when(['role'], {
    is: isOtherRoleSelected,
    then: () =>
      Yup.string()
        .required()
        .max(100)
        .matches(/^[A-Za-z_.-\s]*$/),
    otherwise: () => Yup.string().notRequired()
  })
});

export function SetupPersona() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeAuthGuard } = useAuthGuard();

  return (
    <HdResolvedComponent
      Component={SetupPersonaInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[executeAuthGuard]}
    />
  );
}

export function SetupPersonaInner({ featuredCustomers }: SetupPersonaProps) {
  const [formError, setFormError] = useState(null);

  const formikRef = useRef<FormikProps<{}>>();
  const localStorage = useService(LocalStorageService);
  const { user } = useUserService();
  const history = useHistory();
  const ngZone = useService(NgZone);

  useEffect(() => {
    if (user.role) {
      localStorage.remove(SETUP_PERSONA_REQUIRED);
      ngZone.run(() => {
        history.push('/');
      });
    }
  }, [user]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (!formikRef.current.isSubmitting) {
      return;
    }

    setFormError(null);

    const params = {
      user_role: isOtherRoleSelected(values.role) ? values.userRole : values.role?.id
    };

    AuthAPI.userAdditionalDetails(params)
      .then(() => {
        const nextUrl = localStorage.get(POST_LOGIN_REDIRECT_URL);
        if (!!nextUrl && nextUrl !== 'null') {
          localStorage.remove(POST_LOGIN_REDIRECT_URL);
          history.push(nextUrl);
        } else {
          history.push('/');
        }
      })
      .catch(error => {
        setSubmitting(false);

        const errorMessage = getErrorMessageFromObj(error);
        setFormError(errorMessage);
      });
  };

  return (
    <HdAuth
      selectedAuthType={HdAuthRequestType.SETUP_PERSONA}
      featuredCustomers={featuredCustomers}
    >
      <Formik
        initialValues={{ ...initialValues, email: user.email }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
        innerRef={formikRef}
      >
        {props => {
          const { isValid, isSubmitting } = props;
          const disableSubmit = !isValid || isSubmitting;

          return (
            <form noValidate onSubmit={props.handleSubmit}>
              <HdFormControl className='mb-0'>
                <Field
                  name='email'
                  label='Email'
                  placeholder='Email'
                  component={HdFormikTextField}
                  disabled
                  required
                />
              </HdFormControl>

              <HdFormControl>
                <Field
                  name='role'
                  label='Role'
                  placeholder='Select Role'
                  helperText='Select the role that most closely resembles your work'
                  component={HdFormikDropDown}
                  options={DESIGNATIONS}
                  required
                />
              </HdFormControl>

              {isOtherRoleSelected(props.values?.role) ? (
                <HdFormControl>
                  <Field
                    name='userRole'
                    label='Your Role'
                    placeholder='Enter Role'
                    helperText='Enter your role here'
                    component={HdFormikTextField}
                    required
                  />
                </HdFormControl>
              ) : null}

              {!!formError && (
                <HdPane
                  className='mb-1 w-100'
                  variant='error-faded'
                  icon='error-filled'
                  iconClasses='text-error'
                  disableHide
                >
                  <div className='text-default'>{formError}</div>
                </HdPane>
              )}

              <div className='center-flex-row justify-center mt-5'>
                <HdTooltip title='Select your role to proceed' disabled={isValid}>
                  <span>
                    <HdButton
                      type='submit'
                      size='lg'
                      icon='right'
                      direction='right'
                      className={disableSubmit ? 'disabled-button' : ''}
                      showProgress={isSubmitting}
                      disabled={disableSubmit}
                    >
                      Get Started
                    </HdButton>
                  </span>
                </HdTooltip>
              </div>
            </form>
          );
        }}
      </Formik>
    </HdAuth>
  );
}
