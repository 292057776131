import moment from 'moment-mini-ts';
import React from 'react';

export interface HdDateFormatProps {
  value: Date | moment.Moment | string | number;
  format?: string;
  isUTC?: boolean;
}

export function HdDateFormat({ value, format = 'll', isUTC }: HdDateFormatProps) {
  if (!value) {
    return null;
  }

  if (isUTC) {
    return <React.Fragment>{moment(value).utc().format(format)}</React.Fragment>;
  }

  return <React.Fragment>{moment(value).format(format)}</React.Fragment>;
}
