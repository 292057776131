import { Enum, EnumVal } from '../../core/models/object-enum';


export const DEFAULT_SYNC_BEHAVIOUR_TYPES = [ 'UPSERT', 'UPDATE', 'INSERT' ];
export const INTERCOM_TAGS_SYNC_BEHAVIOUR_TYPES = [ 'ATTACH_TAG', 'DETACH_TAG' ];

export class ActivationSyncBehaviourType extends EnumVal {
  constructor(
    public name: string,
    public value: string,
    public title?: string,
    public description?: string
  ) {
    super(name);
  }
}

export class ActivationSyncBehaviour extends Enum {
  static UPSERT = new ActivationSyncBehaviourType(
    'Upsert',
    'UPSERT',
    'Insert New and Update Existing Records',
    'Update the matching records in the Target and insert missing ones'
  );
  static UPDATE = new ActivationSyncBehaviourType(
    'Update',
    'UPDATE',
    'Update Existing Records',
    'Update fields on the records already in the Target'
  );
  static INSERT = new ActivationSyncBehaviourType(
    'Insert',
    'INSERT',
    'Add New Records',
    'Insert records if they don\'t already exist in the Target'
  );
  static DELETE = new ActivationSyncBehaviourType(
    'Delete',
    'DELETE',
    'Delete Records',
    'Delete records which are already present in the Target'
  );
  static ARCHIVE = new ActivationSyncBehaviourType(
    'Archive',
    'ARCHIVE',
    'Archive Records',
    'Archive records which are already present in the Target'
  );
  static ATTACH_TAG = new ActivationSyncBehaviourType(
    'Attach Tag',
    'ATTACH_TAG',
    'Attach Tag to Records'
  );
  static DETACH_TAG = new ActivationSyncBehaviourType(
    'Detach Tag',
    'DETACH_TAG',
    'Detach Tag(s) from Records'
  );
  static FULLREFRESH = new ActivationSyncBehaviourType(
    'Full Sync',
    'FULLREFRESH',
    'Full Sync'
  );
}
