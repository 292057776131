import { AppConfig } from '../../../app/core/app.config';
import { BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../../../app/core/constants';
import { NetworkRequestOptions } from '../../../app/core/models/request';
import { courier } from '../../../app/core/service/courier.service';
import { createHttpParams } from '../../legacy-utils/request';
import { EnterpriseNotificationListFactory, NotificationListFactory } from './factory';
import { NotificationOffset } from './models';

const appConfig = new AppConfig();

export const NotificationAPI = {
  notificationUrl: appConfig.getNotificationV2URL(),
  alertsV2Url: appConfig.getAlertsV2URL(),

  getNotifications(offset: NotificationOffset, config = {}) {
    const url: string = this.notificationUrl;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          limit: offset.pageSize.toString(),
          to: offset.timestamp ? new Date(offset.timestamp).toJSON() : new Date().toJSON(),
          excludeId: offset.id
        })
      },
      ...config
    };

    return courier
      .get(url, options)
      .then(res => NotificationListFactory(res))
      .catch(() => []);
  },

  getEnterpriseNotifications(offset: NotificationOffset, config = {}) {
    const url: string = this.alertsV2Url;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          limit: offset.pageSize.toString(),
          cursor: offset.cursor
        })
      },
      ...config
    };

    return courier.get(url, options).then(res => EnterpriseNotificationListFactory(res.data));
  }
};
