import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class FrontConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig() {
    return {};
  }

  getConfigFromRawData(rawData: any) {
    return {
      apiToken: rawData.api_token
    };
  }

  buildConnectionParams(data: any) {
    return {
      api_token: data.apiToken
    };
  }
}
