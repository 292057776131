import moment from 'moment-mini-ts';
import { DEFAULT_CALENDAR_FORMATS } from '../../moment/hdm-calendar.pipe';
import { timezoneAbbr } from '../../moment/tz-abbr';
import {
  RelatedTaskCategoryType,
  RelatedTaskCategoryTypeActionSuffix
} from '../task/models/related-task-category-type';
import { SourceObjectActionEnum } from './models/source-object-action-enum';


export class SourceObjectStrings {
  static restartNegativeBtn = 'No';

  static actionPstBtn = 'Yes';
  static actionNgtBtn = 'No';

  static skippedObjectFailedEventsMsg = 'Failed Events for skipped Objects will be cleared in some time.';

  static bulkRestartTitle = 'Restart Objects from the beginning?';

  static bulkRestartPositiveBtn = 'Yes, Restart Objects';

  static restartTitle(categoryType: RelatedTaskCategoryType) {
    return `Restart ${ categoryType ? RelatedTaskCategoryTypeActionSuffix[categoryType] : 'Object' } from the beginning?`;
  }

  static restartPositiveBtn(categoryType?: RelatedTaskCategoryType) {
    return `Restart ${ categoryType ? RelatedTaskCategoryTypeActionSuffix[categoryType] : 'Object' }`;
  }

  static getDeferredMessage(deferredTill: number, reason: string) {
    const displayTime = moment(deferredTill).calendar(
      Date.now(), DEFAULT_CALENDAR_FORMATS
    ) + ' (' + timezoneAbbr(new Date(deferredTill)) + ')';

    return `Ingestion for this object is deferred till ${ displayTime }. Reason: ${ reason }`;
  }

  static applyActionTitle(action: SourceObjectActionEnum, categoryType?: RelatedTaskCategoryType) {
    const categoryString = categoryType ? RelatedTaskCategoryTypeActionSuffix[categoryType] + ' for ' : '';

    switch (action) {
      case SourceObjectActionEnum.PAUSE:
        return `Are you sure you want to pause ${ categoryString } this Object?`;
      case SourceObjectActionEnum.SKIP:
        return `Are you sure you want to skip ${ categoryString } this Object?`;
      default:
        return `Are you sure you want to perform this action?`;
    }
  }
}
