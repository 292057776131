import { LogExpiryDetails } from '../models/log-expiry-details';

export const LOG_EXPIRY_DETAILS: LogExpiryDetails = {
  BinLog: {
    name: 'BinLog',
    bannerText: 'We recommend that you read the Troubleshooting documentation before proceeding.',
    bannerDocLink: '/sources/dbfs/databases/mysql/troubleshooting-mysql/errors-post-pipeline-creation/binlog-expiry-error/',
    dialogTitle: 'FIX BINLOG?',
    dialogMessage: 'Hevo was unable to resume reading the Binlog. We will instead start reading from the latest available Binlog file. ' +
      'To recover any lost Events, Hevo will re-run the historical load for all the objects. ' +
      'You can disable the historical load or limit it to a few objects using the options provided below.',
    afterFixingJob : {
      dialogTitle: 'FIXED BINLOG!',
      dialogMessage: 'BinLog has been fixed. Historical Load will be restarted in a few seconds.',
    }
  },
  'Change Stream': {
    name: 'Change Stream',
    dialogTitle: 'FIX CHANGE STREAM?',
    dialogMessage: 'Hevo was unable to continue reading the OpLog since it was purged before we could read it. ' +
    'We will start reading the OpLog again from the latest available OpLog file. To recover the lost Events in between, ' +
    'Hevo will run the historical load for all the affected Objects. You can disable historical load or limit it to a few Objects from below.',
    afterFixingJob : {
      dialogTitle: 'FIXED CHANGE STREAM!',
      dialogMessage: 'Change Stream has been fixed. Historical Load will be restarted in a few seconds.',
    }
  },
  'local.oplog.rs': {
    name: 'OpLog',
    dialogTitle: 'FIX OPLOG?',
    dialogMessage: 'Hevo was unable to continue reading the OpLog since it was purged before we could read it. ' +
    'We will start reading the OpLog again from the latest available OpLog file. To recover the lost Events in between, ' +
    'Hevo will run the historical load for all the affected Objects. You can disable historical load or limit it to a few Objects from below.',
    afterFixingJob : {
      dialogTitle: 'FIXED OPLOG!',
      dialogMessage: 'OpLog has been fixed. Historical Load will be restarted in a few seconds.',
    }
  },
  'RedoLog': {
    name: 'RedoLog',
    dialogTitle: 'FIX REDOLOG?',
    dialogMessage: 'Hevo was unable to resume reading the RedoLog. We will instead start reading from the latest available RedoLog file. ' +
      'To recover any lost Events, Hevo will re-run the historical load for all the objects. ' +
      'You can disable the historical load or limit it to a few objects using the options provided below.',
    afterFixingJob: {
      dialogTitle: 'FIXED REDOLOG!',
      dialogMessage: 'RedoLog has been fixed. Historical Load will be restarted in a few seconds.'
    }
  },
  'WAL': {
    name: 'WAL',
    dialogTitle: 'FIX Logical Replication?',
    bannerText: 'We recommend that you read the Troubleshooting documentation before proceeding.',
    dialogMessage: 'Data ingestion can now be continued. Hevo recommends you restart the Historical Load to ensure no data loss.',
    afterFixingJob: {
      dialogTitle: 'Fixed Logical Replication',
      dialogMessage: 'Logical Replication has been fixed. Historical Load will be restarted in a few seconds.'
    }
  }
};

export const SOURCE_OBJECT_LIST_PAGE_LIMIT = 5;
