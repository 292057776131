import { OverlayPosition } from '../overlay/types';

export const TOOLTIP_OVERLAY_POSITION_MAPPING: {[ key: string ]: OverlayPosition } = {
  'left': {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'top'
  },
  'right': {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'top'
  },
  'above': {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom'
  },
  'below': {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
  }
};

export const EDGE_TOOLTIP_OVERLAY_POSITION_MAPPING: {[ key: string ]: OverlayPosition } = {
  'left': {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top'
  },
  'right': {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top'
  },
  'above': {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  'below': {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  }
};
export const TOOLTIP_FALLBACK_POSITIONS: OverlayPosition[] = [
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'top'
  },
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'bottom'
  },
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'top'
  },
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom'
  },
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom'
  },
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  }
];

export const EDGE_TOOLTIP_FALLBACK_POSITIONS: OverlayPosition[] = [
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top'
  },
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom'
  },
  {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top'
  },
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom'
  },
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  },
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  }
];
