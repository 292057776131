import React from 'react';
import styles from './styles.module.scss';

interface HdCodeProps {
  variant?: 'code' | 'ip';
  children: React.ReactNode;
}

export default function HdCode({ variant = 'code', children }: HdCodeProps) {
  return <code className={`${styles.codeWrapper} ${styles[variant]}`}>{children}</code>;
}
