import { Component, Inject, OnInit } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { TroubleshootGuideService } from '../../core/service/troubleshoot-guide.service';
import { ALERT_SNACKBAR_COMPONENT_DATA } from '../../snackbar/alert-snackbar/interface';
import { DocLink } from '../../snackbar/models';
import { ERROR_SNACKBAR_SUPPORT_CLICK, ERROR_SNACKBAR_TROUBLESHOOT_BUTTON_CLICK, ERROR_SNACKBAR_TROUBLESHOOT_BUTTON_SHOWN } from './constants';


export interface ErrorSnackbarMessageData {
  reason: string;
  getSupportMessage?: (data: any) => string;
  [key: string]: any;
  docLink?: DocLink;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'error-snackbar-message',
  templateUrl: './error-snackbar-message.component.html',
  styleUrls: ['./error-snackbar-message.component.scss']
})
export class ErrorSnackbarMessageComponent implements OnInit {
  supportMessage: string;
  showSupportBtn = true;

  constructor(
    @Inject(ALERT_SNACKBAR_COMPONENT_DATA) public data: ErrorSnackbarMessageData,
    private _troubleshootGuideService: TroubleshootGuideService,
    private _angulartics: Angulartics2
  ) {
    this.supportMessage = data.getSupportMessage
      ? data.getSupportMessage(data)
      : this.getDefaultSupportMessage();
    this.showSupportBtn = typeof data.showSupportBtn === 'boolean' ? data.showSupportBtn : true;
  }

  getDefaultSupportMessage() {
    return `Hey I am getting following error \`${this.data.reason}\`. Can you help?`;
  }

  ngOnInit(): void {
    if (this.data?.troubleShootingActions?.docLink) {
      this._angulartics.eventTrack.next({
        action: ERROR_SNACKBAR_TROUBLESHOOT_BUTTON_SHOWN,
        properties: this.data.troubleShootingActions
      });
    }
  }

  showTroubleShootingDrawer() {
    this._troubleshootGuideService.showTroubleshootGuide(this.data.troubleShootingActions.docLink);
    this._angulartics.eventTrack.next({
      action: ERROR_SNACKBAR_TROUBLESHOOT_BUTTON_CLICK,
      properties: this.data.troubleShootingActions
    });
  }

  supportClick() {
    this._angulartics.eventTrack.next({
      action: ERROR_SNACKBAR_SUPPORT_CLICK,
      properties: this.data
    });
  }
}
