import { JobModeEnum } from '../../source-type/job-mode';
import { GENERIC_VARIATION } from '../constants';
import { EntityVariationData } from '../models';
import { SqlVariations } from './models';

export const MYSQL_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Generic MySQL',
    value: GENERIC_VARIATION,
    description: 'If you manage a MySQL database on your own, or use a MySQL variant that is not explicitly listed ' +
      'on this page, use this option.',
    displayName: 'MySQL',
    docLink: '/sources/databases/mysql/generic-mysql/',
  },
  {
    name: 'Amazon RDS',
    value: SqlVariations.AMAZON_RDS,
    description: 'Amazon RDS MySQL makes it easy to set up, operate, and scale MySQL deployments in the cloud. ' +
      'You can deploy scalable MySQL servers in minutes with cost-efficient and resizable hardware capacity.',
    displayName: 'Amazon RDS MySQL',
    docLink: '/sources/databases/mysql/amazon-rds-mysql/',
  },
  {
    name: 'Amazon Aurora',
    value: SqlVariations.AURORA,
    description: 'Amazon Aurora is a MySQL-compatible relational database built for the cloud. It combines the performance and ' +
      'availability of traditional enterprise databases with the simplicity and cost-effectiveness of open source databases.',
    displayName: 'Amazon Aurora MySQL',
    docLink: '/sources/databases/mysql/amazon-aurora-mysql/',
  },
  {
    name: 'Azure',
    value: SqlVariations.AZURE,
    description: 'Microsoft Azure provides a managed instance of MySQL, which is easy to set up, operate, and scale. ' +
      'It also provides advanced security and high availability with up to 99.99 percent SLA.',
    displayName: 'Azure MySQL',
    docLink: '/sources/databases/mysql/azure-mysql/',
  },
  // {
  //   name: 'Percona TokuDB',
  //   value: SqlVariations.TOKUDB,
  //   description: 'Percona TokuDB for MySQL provides better performance and concurrency for demanding workloads. ' +
  //     'It delivers greater value to MySQL Server users, with its optimized performance, greater performance scalability ' +
  //     'and availability, enhanced backups, and increased visibility.',
  //   displayName: 'Percona TokuDB MySQL'
  // },
  // {
  //   name: 'Oracle Cloud',
  //   value: SqlVariations.ORACLE,
  //   description: 'Oracle Cloud MySQL is a fully-managed database service that lets developers quickly develop and deploy secure ' +
  //     'cloud-native applications using MySQL.',
  //   displayName: 'Oracle Cloud MySQL'
  // },
  {
    name: 'Google Cloud',
    value: SqlVariations.GOOGLE_CLOUD,
    description: 'Google Cloud SQL offers fully-managed MySQL database hosting on Google Cloud Platform to handle administrative ' +
      'tasks such a replication, patch management, and database management for it.',
    displayName: 'Google Cloud MySQL',
    docLink: '/sources/databases/mysql/gcloud-mysql/',
  }
];

export const MARIADB_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Generic MariaDB',
    value: GENERIC_VARIATION,
    description: 'If you manage a MariaDB database on your own, or use a MariaDB variant that is not explicitly listed on this page, ' +
      'use this option.',
    displayName: 'MariaDB',
    docLink: '/sources/databases/mysql/mariadb-mysql/',
  },
  {
    name: 'Amazon RDS',
    value: SqlVariations.AMAZON_RDS,
    description: 'Amazon RDS makes it easy to set up, operate, and scale MariaDB server deployments in the cloud. With Amazon RDS, ' +
      'you can deploy scalable MariaDB cloud databases in minutes with cost-efficient and resizable hardware capacity.',
    displayName: 'Amazon RDS MariaDB',
    docLink: ''
  },
  {
    name: 'Azure',
    value: SqlVariations.AZURE,
    description: 'Microsoft Azure provides a managed instance of MariaDB, which is easy to set up, operate, and scale. ' +
      'It also provides advanced security and high availability with up to 99.99 percent SLA.',
    displayName: 'Azure MariaDB',
    docLink: ''
  }
];

export const POSTGRE_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Generic PostgreSQL',
    value: GENERIC_VARIATION,
    description: 'Select this option if you use a different cloud provider or if you manage PostgreSQL  databases on your own.',
    displayName: 'PostgreSQL',
    docLink: '/sources/databases/postgresql/generic-postgresql/',
  },
  {
    name: 'Amazon Aurora',
    value: SqlVariations.AURORA,
    description: 'Amazon Aurora is a PostgreSQL-compatible relational database built for the cloud. ' +
      'It combines the performance and availability of traditional enterprise databases with the simplicity ' +
      'and cost-effectiveness of open source databases.',
    displayName: 'Amazon Aurora PostgreSQL',
    docLink: '/sources/databases/postgresql/amazon-aurora-postgresql/',
  },
  {
    name: 'Amazon RDS',
    value: SqlVariations.AMAZON_RDS,
    description: 'Amazon RDS makes it easy to set up, operate, and scale PostgreSQL deployments in the cloud. ' +
      'With Amazon RDS, you can deploy scalable PostgreSQL deployments in minutes with cost-efficient and resizable hardware capacity.',
    displayName: 'Amazon RDS PostgreSQL',
    docLink: '/sources/databases/postgresql/amazon-rds-postgresql/',
  },
  {
    name: 'Azure',
    value: SqlVariations.AZURE,
    description: 'Microsoft Azure provides a managed instance of PostgreSQL, which is easy to set up, operate, and scale. ' +
      'It also provides advanced security and high availability with up to 99.99 percent SLA.',
    disabledDescription: 'Azure PostgreSQL does not support Log-based Incremental Replication. ' +
      'Use Table or Custom SQL mode in the previous step to setup Azure.',
    disabledDocLink: '',
    displayName: 'Azure PostgreSQL',
    docLink: '/sources/databases/postgresql/azure-postgresql/',
  },
  {
    name: 'Google Cloud',
    value: SqlVariations.GOOGLE_CLOUD,
    description: 'Google Cloud PostgreSQL offers fully-managed PostgreSQL database hosting on Google Cloud Platform to ' +
      'handle administrative tasks such a replication, patch management, and database management for it.',
    displayName: 'Google Cloud PostgreSQL',
    docLink: '/sources/databases/postgresql/gcloud-postgresql/',
  },
  {
    name: 'Heroku',
    value: SqlVariations.HEROKU,
    description: 'Heroku PostgreSQL enables developers to build, run, and operate databases entirely in the cloud, ' +
      'and save time save time on database setup and maintenance.',
    disabled: (jobMode) => {
      return jobMode?.value === JobModeEnum.LOGIC_REPL.value;
    },
    disabledDescription: 'Heroku PostgreSQL does not support Log-based Incremental Replication. ' +
      'Use Table or Custom SQL mode in the previous step to setup Heroku.',
    disabledDocLink: '/sources/databases/postgresql/heroku-postgresql/#limitations',
    displayName: 'Heroku PostgreSQL',
    docLink: '/sources/databases/postgresql/heroku-postgresql/',
  }
];

export const ORACLE_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Generic Oracle',
    value: GENERIC_VARIATION,
    description: 'If you manage a Oracle database on your own, or use a Oracle variant that is not explicitly listed on ' +
      'this page, use this option.',
    displayName: 'Oracle',
    docLink: '/sources/databases/oracle/generic-oracle/',
  },
  {
    name: 'Amazon RDS',
    value: SqlVariations.AMAZON_RDS,
    description: 'Amazon RDS Oracle makes it easy to set up, operate, and scale Oracle deployments in the cloud. ' +
      'It manages time-consuming database administration tasks including provisioning, backups, software ' +
      'patching, monitoring, and hardware scaling.',
    displayName: 'Amazon RDS Oracle',
    docLink: '/sources/databases/oracle/amazon-rds-oracle/',
  }
];

export const MSSQL_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Generic SQL Server',
    value: GENERIC_VARIATION,
    description: 'If you manage a MS SQL database on your own, or use a MS SQL variant that is not explicitly ' +
      'listed on this page, use this option.',
    displayName: 'SQL Server',
    docLink: '/sources/databases/mssql/generic-mssql/',
  },
  {
    name: 'Amazon RDS',
    value: SqlVariations.AMAZON_RDS,
    description: 'Amazon RDS makes it easy to set up, operate, and scale SQL Server deployments in the cloud.' +
      'Amazon RDS frees you up to focus on application development by managing time-consuming database administration tasks.',
    displayName: 'Amazon RDS SQL Server',
    docLink: '/sources/databases/mssql/amazon-rds-mssql/',
  },
  {
    name: 'Google Cloud',
    value: SqlVariations.GOOGLE_CLOUD,
    description: 'Google Cloud MS SQL offers fully-managed MSSQL database hosting on Google Cloud Platform to ' +
      'handle administrative tasks such a replication, patch management, and database management for it.',
    displayName: 'Google Cloud SQL Server',
    docLink: '/sources/databases/mssql/generic-mssql/'
  },
  {
    name: 'Azure',
    value: SqlVariations.AZURE,
    description: 'Azure SQL Database is the intelligent, scalable, relational database service built for the cloud. ' +
      'It provides serverless compute and hyperscale storage options to automatically scale resources on demand.',
    displayName: 'Azure SQL Server',
    docLink: '/sources/databases/mssql/azure-mssql/',
  }
];
