<div
  class="dialog-container"
  [@fadeAnim]="visibleState"
  (@fadeAnim.done)="onDialogAnimationDone($event)"
  #container>
  <div class="dialog-content"
    #content
    [@popupAnim]="visibleState"
    cdkTrapFocus
    cdkTrapFocusAutoCapture>

    <div class="dialog-header" tabindex="0">
      <div class="dialog-title">
        {{ title }}
      </div>

      <button
        hd-icon-btn
        (click)="onNegativeClick()">
        <hd-icon name="close"></hd-icon>
      </button>
    </div>

    <div class="dialog-body">
      <ng-container *ngIf="!dialogBody && !bodyComponent">
        <div [innerHTML]="body"></div>

        <mat-form-field class="form-field-full-width my-5" *ngIf="dropdownElement">
          <mat-label>
            {{ dropdownElement.label }}
          </mat-label>
          <flexible-auto-complete
            #dropdownElementRef="ngModel"
            [(ngModel)]="selectedValue"
            placeholder="{{ dropdownElement.placeholder }}"
            [options]="dropdownElement.dropdown"
            accessor="{{ dropdownElement.accessor }}"
            name="{{ dropdownElement.name }}"
            required>
          </flexible-auto-complete>
          <mat-hint>
            {{ dropdownElement.hint }}
          </mat-hint>
          <mat-error *ngIf="dropdownElementRef.hasError('required')">
            {{ dropdownElement.placeholder }} is required
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="dialogBody">
        <ng-container *ngTemplateOutlet="dialogBody; context: { $implicit: bodyData }"></ng-container>
      </ng-container>

      <ng-container *ngIf="bodyComponent">
        <ng-template
          [ngComponentOutlet]="bodyComponent"
          [ngComponentOutletInjector]="bodyComponentInjector">
        </ng-template>
      </ng-container>

      <form-error *ngIf="confirmJobError" class="mt-5 mb-0">
        {{ confirmJobError }}
      </form-error>
    </div>

    <div class="dialog-footer">
      <div>
        <mat-checkbox
          [(ngModel)]="checkBoxVal"
          color="primary"
          *ngIf="showCheckBox">
          Don't show this again
        </mat-checkbox>
      </div>

      <div>
        <button
          type="button"
          hdButton
          variation="outline"
          [disabled]="runningConfirmJob"
          (click)="onNegativeClick()">
          {{ negativeButton }}
        </button>

        <button
          type="button"
          hdButton
          [disabled]="(requiresExtraConfirmation && !extraConfirmationProvided) || runningConfirmJob"
          [showProgress]="runningConfirmJob"
          (click)="onPositiveClick()">
          {{ positiveButton }}
        </button>
      </div>
    </div>
  </div>
</div>
