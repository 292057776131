import { GlobalSearchEntity } from '../../GlobalSearchReducer';
import { ProductEntityStats, ProductEntityRoutesType } from './models';

export enum PipelineStatType {
  INGESTION = 'Ingestion',
  LOAD = 'Load'
}

export enum DestinationStatsType {
  LOAD = 'Load',
  LATENCY = 'Latency'
}

export const PIPELINES_STATS_TYPES = [PipelineStatType.INGESTION, PipelineStatType.LOAD];

export const PIPELINE_STATS_ICONS = {
  [PipelineStatType.INGESTION]: 'database',
  [PipelineStatType.LOAD]: 'destinations'
};

export const DESTINATION_STATS_ICONS = {
  [DestinationStatsType.LOAD]: 'database',
  [DestinationStatsType.LATENCY]: 'time'
};

export const DEFAULT_PIPELINE_STATS: ProductEntityStats[] = [
  {
    description: 'Ingestion',
    displayValue: 0,
    icon: 'database',
    value: 0
  },
  {
    description: 'Load',
    displayValue: 0,
    icon: 'destinations',
    value: 0
  }
];

export const DEFAULT_DESTINATION_STATS: ProductEntityStats[] = [
  {
    description: 'Load',
    displayValue: 0,
    icon: 'database',
    value: 0
  },
  {
    description: 'Latency',
    displayValue: 0,
    icon: 'time',
    value: 0
  }
];

export const GLOBAL_SEARCH_ENTITY_PRODUCT_ROUTES_DATA: ProductEntityRoutesType = {
  [GlobalSearchEntity.PIPELINES]: [
    {
      displayName: 'Transformations',
      path: '/transformation',
      icon: 'transformation'
    },
    {
      displayName: 'Schema Mapper',
      path: '/schema-mapper?events_sort_order=MAPPED_FIRST&events_page=0',
      icon: 'schema-mapper'
    },
    {
      displayName: 'Load Status',
      path: '/destination-tables',
      icon: 'load-status'
    }
  ],
  [GlobalSearchEntity.DESTINATIONS]: [
    {
      displayName: 'Workbench',
      path: '/workbench',
      icon: 'source-query'
    },
    {
      displayName: 'Activity Logs',
      path: '/activity-log',
      icon: 'activity-log'
    }
  ],
  [GlobalSearchEntity.MODELS]: [
    {
      displayName: 'Query History',
      path: '/history',
      icon: 'query-history'
    },
    {
      displayName: 'Preview',
      path: '/preview',
      icon: 'overview'
    },
    {
      displayName: 'Activity Log',
      path: '/activity-log',
      icon: 'activity-log'
    }
  ],
  [GlobalSearchEntity.ACTIVATIONS]: [
    {
      displayName: 'Sync History',
      path: '/history',
      icon: 'history'
    },
    {
      displayName: 'Activity Log',
      path: '/activity-log',
      icon: 'activity-log'
    }
  ],
  [GlobalSearchEntity.TARGETS]: [],
  [GlobalSearchEntity.WORKFLOWS]: [],
  [GlobalSearchEntity.SOURCE_TYPES]: [],
  [GlobalSearchEntity.DESTINATION_TYPES]: [],
  [GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]: [],
  [GlobalSearchEntity.FILTERS]: []
};

export const EXCERPT_HEADER_TEXT = {
  [GlobalSearchEntity.PIPELINES]: 'Pipeline',
  [GlobalSearchEntity.MODELS]: 'Model',
  [GlobalSearchEntity.DESTINATIONS]: 'Destination',
  [GlobalSearchEntity.WORKFLOWS]: 'Workflow',
  [GlobalSearchEntity.TARGETS]: 'Target',
  [GlobalSearchEntity.ACTIVATIONS]: 'Activation'
};

export const EXCERPT_ENTITY_ID_FIELD = {
  [GlobalSearchEntity.PIPELINES]: 'seq_id',
  [GlobalSearchEntity.DESTINATIONS]: 'seq_id',
  [GlobalSearchEntity.MODELS]: 'seq_id',
  [GlobalSearchEntity.ACTIVATIONS]: 'seqId',
  [GlobalSearchEntity.WORKFLOWS]: 'seqId',
  [GlobalSearchEntity.TARGETS]: 'seqId'
};
