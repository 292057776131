export const CREATE_MODEL_PROGRESS_STORAGE_KEY = 'model.general.create.wizard';

export const MODEL_UPDATING_TOOLTIP = 'The Model update is in progress.';
export const MODEL_COMPLETED_TOOLTIP = 'Please schedule the Model to run it.';
export const MODEL_SCHEDULED_TOOLTIP = 'The Model run is scheduled successfully.';
export const MODEL_QUEUED_TOOLTIP = 'The Model run is queued for execution.';

export const TRACKER_CREATE_MODEL_CLICK = '[Models] create model click';
export const TRACKER_CREATE_MODEL_CLICK_ON_EMPTY = '[Models] create model click on empty';
export const TRACKER_CREATE_MODEL_MENU_ITEM_CLICK = '[Models] create model menu item click';
export const TRACKER_CREATE_DBT_MODEL_CLICK = '[Models] create dbt model click';
export const TRACKER_CREATE_DBT_MODEL_MENU_ITEM_CLICK = '[Models] create dbt model menu item click';
export const TRACKER_CREATE_DBT_MODEL_CLICK_ON_EMPTY = '[Models] create dbt model click on empty';
export const TRACKER_MODEL_INTRO_CREATE_CLICK = '[Models Introduction] create click';

export const SCHEDULE_MODEL_SUBMIT_CLICK = '[Schedule Model] submit click';
export const SCHEDULE_MODEL_SUBMIT_SUCCESS = '[Schedule Model] submit success';
export const SCHEDULE_MODEL_SUBMIT_FAILURE = '[Schedule Model] submit failure';
