import { Typography } from '@mui/material';
import React from 'react';
import { Filter } from '../../../../../../app/filter/models/filter';
import HdTag from '../../../../../components/UIElements/HdTag';

export function AppliedFilters({ appliedFilters, resetFilter }) {
  const getAppliedFiltersLength = (): number =>
    appliedFilters.filter((filter: Filter<any>) => filter.isFilterActive() && filter.renderTag)
      .length;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {getAppliedFiltersLength() ? (
        <>
          <Typography variant='body3'>Showing Activities in</Typography>

          {appliedFilters.map(filter => (
            <HdTag
              dataId={`activity-log-filter-${filter.name}`}
              key={filter.name}
              onClose={() => resetFilter(filter.name === 'action_by' ? 0 : 1)}
            >
              {filter.value.name}
            </HdTag>
          ))}
        </>
      ) : null}
    </>
  );
}
