import { Injectable, Injector } from '@angular/core';
import { GenericSourceConfigHelper } from '../../../react/containers/pipeline/create/ConfigSourceType/GenericSource/configHelper';
import { JobMode } from '../source-type/job-mode';
import { SOURCE_TYPES } from '../source-type/source-type';
import { JOB_MODE, SOURCE_TYPE_IDENTIFIER, SourceTypeConfigHelper, SourceTypeConfigHelperConstructor } from './config-helper';


@Injectable({ providedIn: 'root' })
export class SourceConfigHelperService {
  private _configHelpers: { [key: string]: SourceTypeConfigHelper } = {};


  constructor(
    private _injector: Injector
  ) {
  }

  getConfigHelper(sourceTypeIdentifier: string, jobMode?: JobMode): SourceTypeConfigHelper {
    const existingRef = this._configHelpers[sourceTypeIdentifier];

    if (existingRef) {
      return existingRef;
    }

    const sourceTypeMetaData = SOURCE_TYPES[sourceTypeIdentifier];
    const formRenderingLibrary = sourceTypeMetaData.formRenderingLibrary;
    const configHelperClass: SourceTypeConfigHelperConstructor =
      formRenderingLibrary === 'REACT' && sourceTypeMetaData.genericReactComponent
      ? GenericSourceConfigHelper
      : sourceTypeMetaData.configHelper;

    let newRef: SourceTypeConfigHelper;

    if (formRenderingLibrary === 'REACT') {
      newRef = new configHelperClass(sourceTypeIdentifier, jobMode);
    } else {
      const newInjector = Injector.create(
        [
          {
            provide: configHelperClass
          },
          {
            provide: SOURCE_TYPE_IDENTIFIER,
            useValue: sourceTypeIdentifier
          },
          {
            provide: JOB_MODE,
            useValue: jobMode
          }
        ],
        this._injector
      );

      newRef = newInjector.get(configHelperClass);
    }

    this._configHelpers[sourceTypeIdentifier] = newRef;

    return newRef;
  }
}
