import React from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { HdResolvedComponent } from '../../components/Routing/HdResolvedComponent';
import { HdSupportButton } from '../../components/SupportButton';
import { HdButton, HdLink } from '../../components/UIElements';
import HdIcon from '../../components/UIElements/HdIcon';
import useUserService from '../../hooks/services/useUserService';
import { useValidUserCheckGuard } from '../../hooks/useValidUserCheckGuard';
import { getDataIdGenerator } from '../../utils/generateDataId';

export function AccountSuspended() {
  const { executeValidUserCheckGuard } = useValidUserCheckGuard();

  return (
    <HdResolvedComponent
      Component={AccountSuspendedInner}
      canActivate={[executeValidUserCheckGuard]}
    />
  );
}

export function AccountSuspendedInner() {
  const userIsOwner = useUserService().isCurrentUserOwner;
  const history = useHistory();

  const dataIdGenerator = getDataIdGenerator('account-suspended');

  const redirectToBillingHistory = () => {
    history.push('/payment/payment-history');
  };

  return (
    <div className='no-item-box errored h-100 m-0'>
      <div className='no-item-box-icon-container'>
        <HdIcon className='no-item-box-icon' name='error-filled' />
      </div>

      <div className='primary-header-title'>Your account is suspended! 😞</div>

      <div className='no-item-box-desc mt-2'>
        Please clear any unpaid invoices and contact Support to restore your account.
      </div>

      {userIsOwner ? (
        <HdButton
          dataId={dataIdGenerator('pay-invoices')}
          icon='cards-payment'
          className='mt-4'
          onClick={redirectToBillingHistory}
        >
          Pay Invoices
        </HdButton>
      ) : null}

      <HdSupportButton
        className='mt-5'
        tag='button'
        icon='support'
        direction='left'
        dataId={dataIdGenerator('chat-support')}
        Component={HdLink}
      >
        Talk to Support
      </HdSupportButton>
    </div>
  );
}
