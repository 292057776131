import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_ACC_CREATED_QUERY_FLAG } from '../constants';
import { AUTH_ERROR_MESSAGE_PARAM_NAME } from '../select-auth-account/constants';
import { ConnectorAuthTypeEnum } from '../source-type/auth-type';
import { AuthorisedAccount, ServiceAccount } from './models';

export function getAddAccountSuccessRedirectionPath(router: Router) {
  const redirectUrlTree = router.parseUrl(router.url);
  delete redirectUrlTree.queryParams[AUTH_ERROR_MESSAGE_PARAM_NAME];
  redirectUrlTree.queryParams[AUTH_ACC_CREATED_QUERY_FLAG] = true;
  const redirectUrl = redirectUrlTree.toString();

  return window.location.origin + redirectUrl;
}

export function removeUrlQueryParam(router: Router, queryParamKey: string) {
  const redirectUrlTree = router.parseUrl(router.url);
  delete redirectUrlTree.queryParams[queryParamKey];
  return redirectUrlTree.toString();
}

export function authorizedAccountsFactory(rawData: any[], authType: ConnectorAuthTypeEnum): (ServiceAccount | AuthorisedAccount)[] {
  if (!rawData || rawData.length === 0) {
    return [];
  }

  return rawData.map((account) => {
    if (authType === ConnectorAuthTypeEnum.OAUTH) {
      return new AuthorisedAccount(account);
    } else {
      return new ServiceAccount(account);
    }
  });
}

export function fetchErrorMessageAndRedirect(router: Router, route: ActivatedRoute) {
  let redirectedErrorMessage: string;
  if (route && route.snapshot && AUTH_ERROR_MESSAGE_PARAM_NAME in route.snapshot.queryParams) {
    const encodedErrorMessage = route.snapshot.queryParams[AUTH_ERROR_MESSAGE_PARAM_NAME];
    redirectedErrorMessage = window.atob(encodedErrorMessage);
    const url = removeUrlQueryParam(router, AUTH_ERROR_MESSAGE_PARAM_NAME);
    router.navigateByUrl(
      url,
      { replaceUrl: true}
    );
  }

  return redirectedErrorMessage;
}

export function removeParamAndFetchUrl(router: Router, param: string) {
  return window.location.origin + removeUrlQueryParam(router, param);
}
