import { Subject } from 'rxjs';

export interface InclusionItemState {
  key?: InclusionItemKey;
  selected: boolean;
  metaData?: object;
}

export type InclusionItemKey = string | number;
export type IncludedChildState = InclusionListState | InclusionItemState;

export interface InclusionListState extends InclusionItemState {
  size?: number;
  entities: IncludedChildState[];
  metaData?: object;
}

export const DEFAULT_INCLUSION_ITEM_STATE: InclusionItemState = {
  key: null,
  selected: false
};

export const DEFAULT_INCLUSION_LIST_STATE: InclusionListState = {
  selected: false,
  entities: []
};

export const DEFAULT_SELECTED_INCLUSION_LIST_STATE: InclusionListState = {
  selected: true,
  entities: []
}

export function listIncluded(state: IncludedChildState) {
  if (!('entities' in state)) {
    return state.selected;
  }

  if (!isSizeDefined(state)) {
    return state.selected || state.entities.length;
  }

  if (!state.selected) {
    return state.entities.length > 0;
  }

  if (state.entities.length < state.size) {
    return true;
  }

  return state.entities.some(entity => listIncluded(entity));
}

export function listIndeterminate(state: IncludedChildState) {
  if (!('entities' in state)) {
    return false;
  }

  if (!isSizeDefined(state)) {
    return state.entities.length > 0;
  }

  const indeterminateChildrenCount = state.entities.filter(listIndeterminate).length;

  return !!indeterminateChildrenCount || (state.entities.length > 0 && state.entities.length !== state.size);
}

function isSizeDefined(state) {
  return typeof state.size !== 'undefined' && state.size !== null;
}

export class InclusionItem {
  private _parent: InclusionList;
  private _changes = new Subject<InclusionItemState>();

  changes = this._changes.asObservable();

  get included() {
    return !!this.state.selected;
  }

  get indeterminate() {
    return false;
  }

  get checked() {
    return !!this.state.selected;
  }

  constructor(public key: InclusionItemKey, public state: InclusionItemState = DEFAULT_INCLUSION_ITEM_STATE) {
    this.state = {
      ...this.state,
      key
    };
  }

  setState(state: InclusionItemState) {
    this.state = state;
  }

  setMetaData(metaData: any) {
    this.state = {
      ...this.state,
      metaData
    }
  }

  toggleCheckedState(emitChanges = true) {
    this.setCheckedState(!this.state.selected, emitChanges);
  }

  setCheckedState(selected: boolean, emitChanges = true, updateParent = true) {
    this.state.selected = selected;

    if (emitChanges) {
      this._changes.next(this.state);
    }

    if (updateParent) {
      this._updateParentInclusionState(emitChanges);
    }
  }

  setParent(parent: InclusionList) {
    this._parent = parent;
  }

  _updateParentInclusionState(emitChanges = true) {
    if (this._parent) {
      this._parent.updateInclusionState(emitChanges);
    }
  }
}

export class InclusionList {
  private _parent: InclusionList;
  private _changes = new Subject<InclusionListState>();
  private _items: (InclusionItem | InclusionList)[] = [];

  changes = this._changes.asObservable();

  get pageIndeterminate() {
    const indeterminateChildrenCount = this._items.filter((item) => {
      return listIndeterminate(item.state);
    }).length;

    const excludedChildrenCount = this._items.filter((item) => {
      return !listIncluded(item.state);
    }).length;

    return indeterminateChildrenCount || (excludedChildrenCount > 0 && excludedChildrenCount < this._items.length);
  }

  get pageIncluded() {
    return this._items.some((item) => {
      return listIncluded(item.state);
    });
  }

  get included() {
    return listIncluded(this.state);
  }

  get indeterminate() {
    return listIndeterminate(this.state);
  }

  get checked() {
    return this.included && !this.indeterminate;
  }

  get pageChecked() {
    return this.pageIncluded && !this.pageIndeterminate;
  }

  constructor(public key: InclusionItemKey, public size: number, public state: InclusionListState = DEFAULT_INCLUSION_LIST_STATE) {
    this.state = {
      ...this.state,
      key,
      size
    };
  }

  setState(state: InclusionListState) {
    this.state = {
      ...this.state,
      ...state,
      size: this.size
    };
    this.setItemsState();
  }

  setMetaData(metaData: any) {
    this.state = {
      ...this.state,
      metaData
    }
  }

  setSize(size: number, emitChanges = true, updateParent = true) {
    this.size = size;
    this.state.size = size;
    this.updateInclusionState();

    if (updateParent) {
      this._updateParentInclusionState(emitChanges);
    }
  }

  toggleCheckedState(emitChanges = true) {
    this.setCheckedState(!this.included, emitChanges);
  }

  setCheckedState(selected: boolean, emitChanges = true, updateParent = true) {
    this.state.selected = selected;
    this.state.entities = [];

    this._items.forEach((item) => {
      item.setCheckedState(selected, emitChanges, false);
    });

    if (emitChanges) {
      this._changes.next(this.state);
    }

    if (updateParent) {
      this._updateParentInclusionState(emitChanges);
    }
  }

  togglePageCheckedState(emitChanges = true) {
    this.setPageCheckedState(!this.pageIncluded, emitChanges);
  }

  setPageCheckedState(selected: boolean, emitChanges = true, updateParent = true) {
    this._items.forEach((item) => {
      item.setCheckedState(selected, emitChanges, false);
    });

    this.calculateInclusionState();

    if (emitChanges) {
      this._changes.next(this.state);
    }

    if (updateParent) {
      this._updateParentInclusionState(emitChanges);
    }
  }

  setItems(items: (InclusionItem | InclusionList)[]) {
    this._items = items;
    this._items.forEach((item) => {
      item.setParent(this);
    });

    this.setItemsState();
  }

  addItems(items: (InclusionItem | InclusionList)[]) {
    items.forEach((item) => {
      item.setParent(this);
    });
    this._items = [...this._items, ...items];
    this.setItemsState();
    this.updateInclusionState();
  }

  setItemsState() {
    this._items.forEach((item) => {
      const itemStateRef = this.state.entities.find(entity => entity.key === item.key);

      if (typeof itemStateRef !== 'undefined') {
        if (item instanceof InclusionItem) {
          item.setState(itemStateRef);
        } else {
          item.setState(<InclusionListState>itemStateRef);
        }
      } else {
        item.setCheckedState(this.state.selected, false, false);
      }
    });
  }

  setParent(parent: InclusionList) {
    this._parent = parent;
  }

  updateInclusionState(emitChanges = true) {
    this.calculateInclusionState();

    this._updateParentInclusionState();

    if (emitChanges) {
      this._changes.next(this.state);
    }
  }

  calculateInclusionState() {
    this._items.forEach((item) => {
      const itemStateRefIndex = this.state.entities.findIndex((entity) => entity.key === item.key);
      const included = listIncluded(item.state);
      const indeterminate = listIndeterminate(item.state);

      if (this.state.selected) {
        if (included && !indeterminate) {
          if (itemStateRefIndex >= 0) {
            this.state.entities.splice(itemStateRefIndex, 1);
          }
        } else {
          if (itemStateRefIndex >= 0) {
            this.state.entities[itemStateRefIndex] = item.state;
          } else {
            this.state.entities.push(item.state);
          }
        }
      } else {
        if (itemStateRefIndex >= 0 && !included) {
          this.state.entities.splice(itemStateRefIndex, 1);
        } else if (included) {
          if (itemStateRefIndex >= 0) {
            this.state.entities[itemStateRefIndex] = item.state;
          } else {
            this.state.entities.push(item.state);
          }
        }
      }
    });

    if (!listIncluded(this.state)) {
      this.state.selected = false;
      this.state.entities = [];
    }
  }

  _updateParentInclusionState(emitChanges = true) {
    if (this._parent) {
      this._parent.updateInclusionState(emitChanges);
    }
  }

  getIncludedItemsCount() {
    if (!this.state.selected) {
      return this.state.entities.length;
    }

    if (!isSizeDefined(this.state)) {
      return;
    }

    return this.state.size
      - this.state.entities.filter((entity) => !listIncluded(entity)).length;
  }

  getExcludedItemsCount() {
    if (this.state.selected) {
      return this.state.entities.filter((entity) => !listIncluded(entity)).length;
    }

    if (!isSizeDefined(this.state)) {
      return;
    }

    return this.state.size
      - this.state.entities.filter((entity) => listIncluded(entity)).length;
  }

  getPageIncludedItemsCount() {
    return this._items.filter((item) => item.included).length;
  }

  getPageExcludedItemsCount() {
    return this._items.length - this._items.filter((item) => item.included).length;
  }

  areIncludedItemsSubsetOfPage() {
    if (this.state.selected) {
      if (this._items.length !== this.size) {
        return false;
      }
    } else {
      return !(this.state.entities || []).some((entity) => {
        return this._items.findIndex((item) => item.key === entity.key) === -1;
      });
    }
  }

  mapIncludedItemsToPageData(inputList: any[], selector: (item: any, key) => boolean) {
    if (this.state.selected) {
      return [ ...inputList ];
    } else {
      return (this.state.entities || []).map((entity) => {
        return inputList.find(item => selector(item, entity.key));
      }).filter((item) => !!item);
    }
  }

  isSelectionEmpty() {
    if (!this.state.selected) {
      return this.state.entities.filter((entity) => listIncluded(entity)).length === 0;
    }

    if (!isSizeDefined(this.state)) {
      return;
    }

    return this.state.entities.filter((entity) => !listIncluded(entity)).length === this.state.size;
  }

  isAllIncluded() {
    if (this.state.selected) {
      return this.state.entities.filter((entity) => !listIncluded(entity)).length === 0;
    }

    if (!isSizeDefined(this.state)) {
      return;
    }

    return this.state.entities.filter((entity) => listIncluded(entity)).length === this.state.size;
  }

  clearSelection() {
    this.setCheckedState(false);
  }
}
