import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'primary-keys-selector',
  styleUrls: ['./primary-keys-selector.component.scss'],
  templateUrl: './primary-keys-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrimaryKeysSelectorComponent),
      multi: true
    }
  ],
})
export class PrimaryKeysSelectorComponent implements OnChanges, ControlValueAccessor {
  columns: any[] = [];
  @Input() suggestions: any[] = [];
  @Input() showSuggestions = true;
  @Input() placeholder = 'Select Field';
  @Input() editable = true;
  @Input() canUseCustom = false;
  @Input() loadingSuggestions = false;

  @Output() needSuggestions = new EventEmitter<void>();

  selectionMode = true;
  dropdownSelectedKey: string;
  availableSuggestions = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.suggestions) {
      this.buildApplicableSuggestions();
    }
  }

  removeKey(index) {
    this.columns.splice(index, 1);
    if (!this.columns.length) {
      this.selectNewKey();
    }

    this.buildApplicableSuggestions();
    this.propagateChange([...this.columns]);
  }

  selectNewKey() {
    if (this.selectionMode && !this.showSuggestions && this.dropdownSelectedKey) {
      this.insertKey(this.dropdownSelectedKey);
    }
    this.selectionMode = true;
  }

  onSelect(primaryKey) {
    if (!primaryKey) {
      return;
    }
    const key = this.getValueFromAccessor(primaryKey);
    this.insertKey(key);
    this.buildApplicableSuggestions();
  }

  getValueFromAccessor(value) {
    if (typeof value === 'object' && value !== null) {
      return value.name;
    }

    return value;
  }

  onEnter(key: string) {
    this.insertKey(key);
  }

  insertKey(key: any) {
    this.columns.push(key);
    this.selectionMode = false;
    this.dropdownSelectedKey = null;
    this.propagateChange([...this.columns]);
  }

  writeValue(obj: any[]): void {
    if (obj) {
      this.columns = [ ...obj ];
    } else {
      this.columns = [];
    }

    this.buildApplicableSuggestions();
  }

  buildApplicableSuggestions() {
    if (!this.columns || !this.columns.length) {
      this.availableSuggestions = this.suggestions ? [ ...this.suggestions ] : [];
      return;
    }

    this.availableSuggestions = (this.suggestions || []).filter((suggestion) => {
      const suggestionValue = this.getValueFromAccessor(suggestion);
      return this.columns.findIndex((column) => {
        return suggestionValue === column;
      }) === -1;
    });
  }

  onDropdownOpen() {
    if (!this.suggestions || !this.suggestions.length) {
      this.needSuggestions.next();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  propagateChange = (_: any) => {};
  _onTouched = () => {};
}
