import moment from 'moment-mini-ts';

const momentConstructor = moment;

export function getSecondsUntilUpdate(momentInstance: moment.Moment) {
  const howOldDays = Math.abs(momentConstructor().diff(momentInstance, 'days'));

  if (howOldDays > 0) {
    return 24 * 60 * 60 * 10000;
  }

  const howOld = Math.abs(momentConstructor().diff(momentInstance, 'minute'));

  if (howOld < 1) {
    return 1;
  }

  if (howOld < 60) {
    return 30;
  }

  if (howOld < 180) {
    return 300;
  }

  return 3600;
}
