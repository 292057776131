import React, { useCallback } from 'react';
import clsx from 'clsx';
import { getDataId, getDataIdGenerator } from '../../../utils/generateDataId';
import styles from './styles.module.scss';
import HdIcon from '../HdIcon';
import { HdIconButton } from '../HdIconButton';

type HdPaneVariant =
  | 'success'
  | 'warning'
  | 'deferred'
  | 'secondary'
  | 'error'
  | 'success-faded'
  | 'warning-faded'
  | 'warning-faded-1'
  | 'error-faded'
  | 'error-faded-borderless'
  | 'error-faded-2'
  | 'info-secondary-faded'
  | 'info-secondary-faded-2'
  | 'info-faded'
  | 'info-secondary'
  | 'info-secondary-1'
  | 'info-secondary-2';

export interface HdPaneProps {
  dataId?: string;
  variant: HdPaneVariant;
  children: React.ReactNode;
  className?: string;
  icon?: string;
  disableHide?: boolean;
  iconClasses?: string;
  closeIconSize?: number;
  onClick?: Function;
  onClose?: Function;
  iconSize?: number;
  withoutBorder?: boolean;
}

export default function HdPane({
  dataId = '',
  variant,
  children,
  className = '',
  icon,
  disableHide,
  iconClasses,
  closeIconSize = 2,
  onClick = () => {},
  onClose,
  withoutBorder,
  iconSize = 3
}: HdPaneProps) {
  const dataIdGenerator = useCallback(getDataIdGenerator(dataId), [dataId]);

  return (
    <div
      className={clsx(
        styles.pane,
        variant && styles[variant],
        withoutBorder && styles.withoutBorder,
        className
      )}
      data-id={dataIdGenerator('hd-pane')}
      onClick={() => onClick()}
      aria-hidden='true'
    >
      {icon ? (
        <HdIcon
          name={icon}
          size={iconSize}
          className={`${styles.icon} ${iconClasses}`}
          data-id={dataIdGenerator('icon')}
        />
      ) : null}

      <div className='w-100'>{children}</div>

      {!disableHide && onClose ? (
        <HdIconButton
          className={`${styles.close}`}
          onClick={e => {
            onClose();
            e.stopPropagation();
          }}
          verticalEdge='both'
          edge='end'
          dataId={getDataId('close')(dataId)('pane')}
          data-testid='hdPaneCloseBtn'
        >
          <HdIcon size={closeIconSize} name='close' />
        </HdIconButton>
      ) : null}
    </div>
  );
}
