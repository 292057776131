import { EntityStatus } from '../../core/models/entity-status';
import { Enum } from '../../core/models/object-enum';


export class ActivationStatusEnum extends Enum {
  static ACTIVE = new EntityStatus('Active', 'ACTIVE', 'success');
  static PAUSED = new EntityStatus('Paused', 'PAUSED', 'warning');
  static FAILED = new EntityStatus('Failed', 'FAILED', 'error');
}

export class ActivationStateEnum extends Enum {
  static ACTIVE = new EntityStatus('Resume', 'ACTIVE');
  static PAUSED = new EntityStatus('Paused', 'PAUSED');
}
