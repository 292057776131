import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';
import { Field, Formik, FormikProps } from 'formik';
import React, { useState, useRef } from 'react';
import { HdTimeFormat } from '../../../components/UIElements/HdCalendar';
import useService from '../../../hooks/useService';
import ErrorFocus from '../../../utils/ErrorFocus';
import { HdButton } from '../../../components/UIElements';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import TeamAPI from '../TeamAPI';
import { HdFormikTextField } from '../../../components/FormikElements';
import { PostPCSNotifierService } from '../../../../app/core/service/post-pcs-notifier.service';
import { PostPCSNotificationType } from '../../../../app/core/models/post-pcs-notification';
import { AuthService } from '../../../../app/core/service/auth.service';

const formValidation = Yup.object({
  confirmText: Yup.string().trim().oneOf(['DELETE']).required()
});

export default function ConfirmStep({
  isPaidUser,
  subscriptionEndDate,
  onDeleteEvent,
  onConfirmationCancel,
  dataId
}) {
  const [deleting, setDeleting] = useState(false);
  const _postPCSNotifierService = useService(PostPCSNotifierService);
  const _authService = useService(AuthService);
  const formikRef = useRef<FormikProps<any>>();

  const handleDeleteAccount = () => {
    setDeleting(true);

    TeamAPI.deleteTeam()
      .then(_ => {
        _postPCSNotifierService.notify({
          type: PostPCSNotificationType.DELETE_ACCOUNT
        });

        if (isPaidUser) {
          onDeleteEvent();
        } else {
          _authService.logout().subscribe();
        }
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const dataIdGenerator = getDataIdGenerator(dataId, 'dialog');

  return (
    <div className='box' data-id={dataIdGenerator('')}>
      <div className='box__header'>
        <span className='box__title'>Are you sure?</span>
      </div>

      <div className='box__body'>
        {isPaidUser && (
          <p className='text-bold mb-6' data-id={dataIdGenerator('subscription-end-date')}>
            Your current subscription will end on{' '}
            <HdTimeFormat value={subscriptionEndDate} options={{ formats: 'MMM DD, YYYY' }} />. Even
            after your account is deleted, you will be able to access till then. For any further
            queries contact our support.
          </p>
        )}

        <div className='text-secondary'>
          Type DELETE in the input below to move ahead with account deletion.
        </div>

        <Formik
          initialValues={{}}
          validationSchema={formValidation}
          onSubmit={handleDeleteAccount}
          innerRef={formikRef}
        >
          {props => (
            <Box>
              <ErrorFocus formik={props} />

              <Grid className='mt-6' container spacing={2}>
                <Grid className='pt-0 mt-1' item sm={12} md={5}>
                  <Field
                    name='confirmText'
                    label='Type DELETE here'
                    component={HdFormikTextField}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </div>

      <div className='box__footer center-flex-row justify-end'>
        <HdButton
          className='submit-btn mr-3'
          palette='secondary'
          variation='outline'
          disabled={deleting}
          onClick={() => onConfirmationCancel()}
          dataId={dataIdGenerator('cancel')}
        >
          Cancel
        </HdButton>

        <HdButton
          onClick={() => formikRef.current?.submitForm()}
          icon='delete'
          palette='secondary-error'
          className='submit-btn'
          variation='outline'
          disabled={deleting}
          showProgress={deleting}
          dataId={dataIdGenerator('confirm')}
        >
          {deleting ? 'Deleting Account' : 'Yes, Delete my account'}
        </HdButton>
      </div>
    </div>
  );
}
