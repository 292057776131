import { FocusMonitor } from '@angular/cdk/a11y';
import { ESCAPE } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { QUERY_DEBOUNCE_TIME } from '../../core/constants';
import { debounce } from '../../../react/legacy-utils/debounce';
import { IconBtnComponent } from '../../icon/icon-btn/icon-btn.component';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'searcharea',
  templateUrl: './searcharea.component.html',
  styleUrls: [ './searcharea.component.scss' ],
  host: {
    '[tabindex]': '-1'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchareaComponent),
      multi: true
    }
  ]
})
export class SearchareaComponent implements ControlValueAccessor, OnInit, OnDestroy {
  currentValue: any;
  @HostBinding('class.expanded') expanded = true;

  private _destroyed$: Subject<void> = new Subject();

  @Input() placeholder: string;
  @Input() expandOnInit = false;
  @Input() autofocus = false;
  @HostBinding('class.collapsible') @Input() collapsible = false;
  @HostBinding('class.collapsible-full-width') @Input() fillAvailableSpace = false;
  @HostBinding('class.disabled') @Input() disabled = false;

  @ViewChild('ip') inputRef: any;
  @ViewChild('searchTrigger') searchTrigger: IconBtnComponent;

  @Output() ipKeydown = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  propagateChange = debounce(this, (_: any) => { }, QUERY_DEBOUNCE_TIME);

  constructor(
    private _el: ElementRef,
    private _focusMonitor: FocusMonitor,
    private _ngZone: NgZone,
    private _cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    let collapsed = false;

    if (this.collapsible && !this.expandOnInit) {
      collapsed = true;
    }

    if (typeof this.currentValue === 'string' && this.currentValue.length) {
      collapsed = false;
    }

    this.expanded = !collapsed;

    if (this.collapsible && this.expanded) {
      setTimeout(() => {
        this.open.emit();
      }, 0);
    }

    if (this.autofocus) {
      setTimeout(() => {
        this.focusIp();
      }, 0);
    }
  }

  writeValue(value: any) {
    this.currentValue = value;

    if (this.inputRef) {
      this.inputRef.nativeElement.value = typeof this.currentValue === 'undefined' ? '' : this.currentValue;
    }

    if (this.currentValue && this.collapsible) {
      this.expand();
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = debounce(this, fn, QUERY_DEBOUNCE_TIME);
  }

  registerOnTouched(fn: any) {
  }

  onKeyUp(e: any) {
    this.applyNewValue(e.target.value);
  }

  onKeyDown(e: any) {
    if (e.keyCode === ESCAPE && !this.currentValue && this.collapsible) {
      this.collapse();
      return;
    }

    this.ipKeydown.next(e);
  }

  onClear() {
    if (!this.currentValue) {
      this.collapse();
      return;
    }
    this.applyNewValue(undefined);
    this.clearInput();
  }

  clearInput() {
    this.inputRef.nativeElement.value = null;
    this.focusIp();
  }

  applyNewValue(value: string) {
    if ((this.currentValue === undefined && !value) || this.currentValue === value) {
      return;
    }
    this.currentValue = value;
    this.propagateChange(this.currentValue);
  }

  @HostListener('focus') onFocus() {
    this.focusIp();
  }

  collapse() {
    if (!this.expanded) {
      return;
    }

    this.expanded = false;
    this.close.emit();
  }

  expand() {
    if (this.expanded) {
      return;
    }

    this.expanded = true;

    this._ngZone.onStable.pipe(
      take(1)
    ).subscribe(() => {
      this.focusIp();
      this.open.emit();
    });
  }

  focusIp() {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus();
    } else if (this.searchTrigger) {
      this.searchTrigger._getHostElement().focus();
    }
  }

  shouldShowClearIcon() {
    return (this.currentValue || (this.collapsible && this.expanded)) && !this.disabled;
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
