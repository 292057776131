import { ComponentType, PortalInjector } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RefreshService } from '../../core/service/refresh.service';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { FlexibleAutoCompleteComponent } from '../../ip/flexible-auto-complete/flexible-auto-complete.component';
import { KeyboardShortcuts } from '../../shortcuts/service/keyboard-shortcuts.service';
import { fadeAnim, popupAnim } from '../animations';
import { DialogBase } from '../dialog-base';
import { DialogOverlayRef } from '../dialog-overlay-ref';
import { DIALOG_OVERLAY_DATA } from '../dialog-overlay.tokens';

import { Dialog } from '../models';
import { CONFIRM_DIALOG_COMPONENT_DATA } from './constants';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ],
  animations: [ popupAnim(), fadeAnim() ]
})
export class ConfirmDialogComponent extends DialogBase implements OnInit, Dialog {
  title = 'Alert';
  body = 'Are you Sure?';
  positiveButton = 'Confirm';
  negativeButton = 'Cancel';
  showCheckBox = false;
  confirmJob$: Observable<any>;
  checkBoxVal = false;
  dropdownElement: any;
  selectedValue = '';
  runningConfirmJob = false;
  confirmJobError: string;
  dialogBody: TemplateRef<any>;
  bodyComponent: ComponentType<any>;
  bodyData: any;
  bodyComponentInjector: Injector;
  requiresExtraConfirmation = false;
  extraConfirmationProvided = false;

  @ViewChild('dropdownElementRef', { read: FlexibleAutoCompleteComponent }) dropdownElementRef: FlexibleAutoCompleteComponent;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected _dialogRef: DialogOverlayRef,
    @Inject(DIALOG_OVERLAY_DATA) _data: any,
    private _refreshService: RefreshService,
    protected _keyboardShortcuts: KeyboardShortcuts,
    private _injector: Injector
  ) {
    super(document, _dialogRef, _data, _keyboardShortcuts);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this._data.bodyComponent) {
      const injectionTokens = new WeakMap();

      this._data.bodyData = {
        ...this._data.bodyData,
        setExtraConfirmationStatus: this.setExtraConfirmationStatus.bind(this)
      };

      injectionTokens.set(CONFIRM_DIALOG_COMPONENT_DATA, this._data.bodyData);

      this.bodyComponentInjector = new PortalInjector(this._injector, injectionTokens);
    }

    this.show();
  }

  show() {
    super.show();
    this.title = this._data.title || this.title;
    this.body = this._data.body || this.body;
    this.positiveButton = this._data.positiveButton || this.positiveButton;
    this.negativeButton = this._data.negativeButton || this.negativeButton;
    this.showCheckBox = this._data.showCheckBox || this.showCheckBox;
    this.dropdownElement = this._data.dropdownElement || this.dropdownElement;
    this.confirmJob$ = this._data.confirmJob$ || of(null);
    this.dialogBody = this._data.dialogBody;
    this.bodyData = this._data.bodyData;
    this.bodyComponent = this._data.bodyComponent;
    this.requiresExtraConfirmation = this._data.requiresExtraConfirmation;
  }

  onPositiveClick() {
    super.onPositiveClick(this._dialogResponse);

    this.runningConfirmJob = true;
    this.confirmJobError = null;

    this.confirmJob$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this.runningConfirmJob = false;

      this._refreshService.refreshChatSupportDetails();

      if (this.checkBoxVal) {
        this._dialogResponse = 'hide';
      } else if (this.dropdownElement) {
        if (!this.selectedValue) {
          this.dropdownElementRef.focusIp();
          return;
        }

        this._dialogResponse = {
          confirmation: true,
          selected_value: this.selectedValue['value']
        };
      } else {
        this._dialogResponse = true;
      }

      this.hide(this._dialogResponse);
    }, (err) => {
      this.runningConfirmJob = false;
      this.confirmJobError = getErrorMessageFromObj(err);
    });
  }

  setExtraConfirmationStatus(confirm: boolean) {
    this.extraConfirmationProvided = confirm;
  }
}
