// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.progressBar_tZErF {\n  width: 312px;\n  height: 10px;\n  display: inline-block;\n  border-radius: 5px;\n  background: var(--secondary-bg-color);\n  overflow: hidden;\n  position: relative;\n}\n.progressBar_tZErF::before {\n  content: \"\";\n  position: absolute;\n  background-color: var(--primary-color);\n  top: 0;\n  left: 0;\n  bottom: 0;\n  border-radius: 5px;\n  will-change: left, right;\n  animation: indeterminate__57vS 2s linear infinite;\n}\n\n.reverseBg__ViyS .progressBar_tZErF {\n  background: var(--divider-color) !important;\n}\n\n.grey_VTPt8 .progressBar_tZErF::before {\n  background-color: var(--text-default-color);\n}\n\n.extra__vg6X {\n  margin-top: 16px;\n}\n\n@keyframes indeterminate__57vS {\n  0% {\n    left: -35%;\n    right: 100%;\n  }\n  100% {\n    left: 100%;\n    right: -35%;\n  }\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "progressBar_tZErF",
	"indeterminate": "indeterminate__57vS",
	"reverseBg": "reverseBg__ViyS",
	"grey": "grey_VTPt8",
	"extra": "extra__vg6X"
};
export default ___CSS_LOADER_EXPORT___;
