import React, { useEffect, useState } from 'react';
import { EnvConfig } from '../../containers/core/env-config';
import { NotificationsListType, NotificationsTab } from './models';
import { DrawerWrapper } from '../../containers/drawer/DrawerWrapper';
import { ReactDrawerBaseProps } from '../../containers/drawer/interface';
import { useHideDrawer } from '../../containers/drawer/useHideDrawer';
import { HdIcon, HdIconButton, HdTab, HdTabHead, HdTabLabel } from '../UIElements';
import styles from './styles.module.scss';
import { StandardNotificationsList } from './StandardNotificationsList';
import { EnterpriseNotificationsList } from './EnterpriseNotificationsList';
import { useHistory } from '../../hooks/useHistoryCompat/useHistoryCompat';
import useTeamSettings from '../../hooks/services/useTeamSettingsService';
import { NotificationService } from '../../../app/core/service/notification.service';
import useService from '../../hooks/useService';

export function NotificationDrawer({
  drawerDirection,
  disableRestoreFocus,
  usingReactRouter,
  closeInitiated,
  springValues
}: ReactDrawerBaseProps) {
  const history = useHistory();

  const [activeTabIndex, setActiveTabIndex] = useState(NotificationsTab.REGULAR);
  const [unreadCount, setUnreadCount] = useState<{ [key: string]: number }>({
    [NotificationsListType.REGULAR]: 0,
    [NotificationsListType.ENTERPRISE]: 0
  });
  const _teamSettingsService = useTeamSettings();
  const isEnterpriseEnabled =
    _teamSettingsService.isH2OEnabled() && EnvConfig.isH20FeatureEnabled('ALERTS');
  const _notificationService = useService(NotificationService);
  const { hideDrawer } = useHideDrawer();

  useEffect(() => {
    const unreadCountSubscription = _notificationService.unreadCount$
      .pipe()
      .subscribe(_unreadCount => {
        setUnreadCount({ ..._unreadCount });
      });
    return () => {
      unreadCountSubscription.unsubscribe();
    };
  }, []);

  const hide = () => {
    hideDrawer();
  };

  const redirectToAlerts = () => {
    history.push('/alerts');
  };

  const handleTabChange = (value: NotificationsTab) => {
    setActiveTabIndex(value);
  };

  return (
    <DrawerWrapper
      drawerDirection={drawerDirection}
      hide={hide}
      closeInitiated={closeInitiated}
      disableRestoreFocus={disableRestoreFocus}
      usingReactRouter={usingReactRouter}
      className={styles.drawer}
      springValues={springValues}
    >
      <div className={`${styles.drawerHeader} drawer-header`}>
        <div className='drawer-title center-flex-row'>
          Alerts
          <HdIconButton dataId='settings' className='ml-1' onClick={redirectToAlerts}>
            <HdIcon name='settings' size={2} />
          </HdIconButton>
          <HdIconButton dataId='drawer-close' className='drawer-close' onClick={hide}>
            <HdIcon name='close' size={3} />
          </HdIconButton>
        </div>
      </div>

      <div className='drawer-body'>
        {isEnterpriseEnabled ? (
          <HdTab onChange={handleTabChange} currentActiveTab={activeTabIndex}>
            <HdTabHead
              aria-label='lab API tabs example'
              indicatorColor='var(--surface-bg-color)'
              className='tab-nav'
            >
              <HdTabLabel
                id={0}
                value={NotificationsTab.REGULAR}
                className='text-transform-none alerts-tab'
                data-id='regular-tab'
              >
                <span>Standard</span>

                {unreadCount[NotificationsListType.REGULAR] ? (
                  <div
                    data-id='unread-count-regular'
                    className='badge-solid error-badge badge-solid-md ml-3'
                  >
                    {unreadCount[NotificationsListType.REGULAR]}
                  </div>
                ) : null}
              </HdTabLabel>

              <HdTabLabel
                id={1}
                value={NotificationsTab.ENTERPRISE}
                className={`text-transform-none alerts-tab`}
                data-id='enterprise-tab'
              >
                <span>Edge</span>
                {unreadCount[NotificationsListType.ENTERPRISE] ? (
                  <div
                    data-id='unread-count-enterprise'
                    className='badge-solid error-badge badge-solid-md ml-3'
                  >
                    {unreadCount[NotificationsListType.ENTERPRISE]}
                  </div>
                ) : null}
              </HdTabLabel>
            </HdTabHead>
          </HdTab>
        ) : null}

        {activeTabIndex === NotificationsTab.REGULAR ? (
          <StandardNotificationsList />
        ) : (
          <EnterpriseNotificationsList />
        )}
      </div>
    </DrawerWrapper>
  );
}
