import { EntityStatus } from '../../../../app/core/models/entity-status';

export class Invoice {
  id: string;
  number: string;
  amount: number;
  createdTimestamp: number;
  status: EntityStatus;
  invoiceUrl: string;
  paymentUrl: string;
  failureMessage?: string;
}
