import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';


export function getFileContent(file: Blob): Observable<string> {
  return new Observable((observer: Observer<string>) => {
    if (!(file instanceof Blob)) {
      return observer.error('Only Blob types are supported');
    }

    const fileReader = new FileReader();

    fileReader.onload = function(e) {
      const text: any = fileReader.result;
      observer.next(text);
      observer.complete();
    };

    fileReader.onerror = function(e) {
      observer.error(e);
    };

    fileReader.onabort = function(e) {
      observer.error(e);
    };

    fileReader.readAsText(file);
  });
}

export function isFileList(files: FileList) {
  if (!files || !files.length) {
    return false;
  }

  return files[0] instanceof Blob;
}

export function isMac() {
  return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
}

export function downloadFileWithProgress(request$: Observable<any>, options: any) {
  const downloadPercent$ = new BehaviorSubject<number>(0);
  const downloading$ = new BehaviorSubject<boolean>(false);

  let downloadIntervalId;
  let downloadSubscription = Subscription.EMPTY;

  const startDownloadProgress = () => {
    downloading$.next(true);

    // using random download progress logic
    const intervalId = setInterval(() => {
      let currentDownloadPercent = downloadPercent$.getValue();

      currentDownloadPercent = currentDownloadPercent < 80
        ? currentDownloadPercent + 1
        : currentDownloadPercent;

      downloadPercent$.next(currentDownloadPercent);
    }, 1000);

    downloadIntervalId = intervalId;
  };

  const cleanUp = () => {
    downloading$.next(false);
    clearInterval(downloadIntervalId);
    downloadIntervalId = null;
    downloadPercent$.next(0);
  };

  const start = (afterEffects?: Function) => {
    startDownloadProgress();

    downloadSubscription = request$.subscribe((res) => {
      downloadPercent$.next(100);
      setTimeout(() => {
        const blob = new Blob([ res ], { type: options.type });
        saveAs(blob, options.filename);

        if (afterEffects) {
          afterEffects();
        }
        cleanUp();
      }, 2000);

    }, () => {
      
      if (afterEffects) {
        afterEffects();
      }
      cleanUp();
    });
  };

  const cancel = (afterEffects?: Function) => {
    downloadSubscription.unsubscribe();
    downloadSubscription = Subscription.EMPTY;

    if (afterEffects) {
      afterEffects();
    }
    cleanUp();
  };

  return { downloadPercent$, downloading$, start, cancel };
}
