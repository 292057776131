import React, { useState } from 'react';
import HdDropDown, { HdDropDownProps } from '../HdDropDown';
import styles from '../HdDropDown/styles.module.scss';
import HdIcon from '../HdIcon';
import HdLink from '../HdLink';

interface TopAdornmentProps {
  creatableLabel: string;
  showAddCustomOption: boolean;
  creatableValue: string;
  onClickCallback: () => void;
}

export default function HdCreateNewOptionsDropDownV2(props: HdDropDownProps) {
  const { selected, creatableLabel, creatableCallback, onChangeEventHandler } = props;

  const [customOptionValue, setCustomOptionValue] = useState('');
  const [resetInputValue, setResetInputValue] = useState(0);
  const [showAddCustomOption, setShowAddCustomOption] = useState(false);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <HdDropDown
        {...props}
        resetInputValue={resetInputValue}
        inputValueChangeCallback={newValue => {
          setCustomOptionValue(newValue);
          !newValue &&  setShowAddCustomOption(false);
        }}
        filterOptionsCallback={({ filteredItems, ...pros }) => {
          setShowAddCustomOption(false);
          if (filteredItems.length === 0 && customOptionValue) {
            setShowAddCustomOption(true);
          }
          return filteredItems;
        }}
        TopAdornment={TopAdornment}
        topAdornmentProps={{
          creatableLabel,
          showAddCustomOption,
          creatableValue: customOptionValue,
          onClickCallback: () => {
            if (typeof props.valueAccessor === 'string') {
              creatableCallback({
                  name: customOptionValue,
                  [props.valueAccessor]: customOptionValue,
                }
              );
              onChangeEventHandler([
                ...(selected || []),
                {
                  name: customOptionValue,
                  [props.valueAccessor]: customOptionValue,
                }]
              );
            } else {
              creatableCallback({
                ...selected,
                customOptionValue,
              });
              onChangeEventHandler([
                ...selected,
                customOptionValue,
              ]);
            }

            setResetInputValue(prev => prev + 1);
            setShowAddCustomOption(false);
            setCustomOptionValue('');
          },
        }}
      />
    </>
  );
}

function TopAdornment({
  creatableLabel = 'option in the list',
  showAddCustomOption,
  creatableValue,
  onClickCallback,
}: TopAdornmentProps) {
  if (!showAddCustomOption) {
    return null;
  }

  return (
    <div
      role='button'
      tabIndex={0}
      className={styles.adornmentWrapper}
      onMouseDown={onClickCallback}
    >
      <HdLink dataId='createNewOption' tag='button' className={`dropdown-fa-bottom-link ${styles.link}`}>
        <HdIcon name='plus' className='mr-2' />
        Add
        <span className='text-bold mx-1'>"{creatableValue}"</span>
        as a new {creatableLabel}
      </HdLink>
    </div>
  );
}
