import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';

export class AwsDocumentdbConfigHelperReact implements SourceTypeConfigHelper {
  getDefaultConfig() {
    return {
      port: 27017,
      authDatabaseName: 'admin',
      sshPort: 22,
      loadAllDb: true,
      mergeCollections: true,
      useSSL: true,
      importHistoricalData: true
    };
  }

  getConfigFromRawData(rawData: any) {
    const config: any = {
      host: rawData.db_host,
      port: rawData.db_port,
      user: rawData.db_user,
      databaseNames: rawData.db_names,
      loadAllDb: !!rawData.load_all_db,
      authDatabaseName: rawData.auth_db,
      password: rawData.db_password,
      sshIp: rawData.ssh_host,
      sshPort: rawData.ssh_port,
      sshUser: rawData.ssh_user,
      useSSL: !!rawData.ssl_enabled,
      useSSH: rawData.ssh_host || rawData.ssh_port || rawData.ssh_user,
      mergeCollections: rawData.merge_collections,
      importHistoricalData: rawData.import_historical_data
    };

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_user: data.user,
      db_password: data.password,
      auth_db: data.authDatabaseName || undefined,
      db_host: data.host,
      db_port: data.port,
      merge_collections: !!data.mergeCollections,
      load_all_db: !!data.loadAllDb,
      db_names: data.loadAllDb ? undefined : data.databaseNames,
      import_historical_data: !!data.importHistoricalData,
      ssl_enabled: !!data.useSSL,
      ssh_host: data.sshIp,
      ssh_port: data.sshPort,
      ssh_user: data.sshUser
    };

    return params;
  }
}
