/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-cycle */
import { _sshService } from '../../../../../../app/core/service/ssh.service';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { ElasticSearchVariations } from './models';

export class ElasticSearchConfigHelper implements SourceTypeConfigHelper {
  constructor(
    public sourceTypeIdentifier: string
  ) {
  }

  getConfigFromRawData(rawData: any) {
    return {
      host: rawData.db_host,
      user: rawData.db_user ?? '',
      port: rawData.db_port,
      dbPassword: rawData.db_password ?? '',
      sshIp: rawData.ssh_host,
      sshPort: rawData.ssh_port,
      sshUser: rawData.ssh_user,
      useSSH: !!(rawData.ssh_host || rawData.ssh_port || rawData.ssh_user),
      isHttpsClient: rawData.is_https_client
    };
  }

  getDefaultConfig() {
    const config = {
      sshPort: 22,
      port: 9200,
      isHttpsClient: false
    };

    if (this.sourceTypeIdentifier === ElasticSearchVariations.AWS_ELASTICSEARCH || this.sourceTypeIdentifier === ElasticSearchVariations.AWS_OPENSEARCH) {
      config.port = 443;
      config.isHttpsClient = true;
    }

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_host: data.host.replace(/^https?:\/\//, ''),
      db_port: data.port,
      db_user: data.user || undefined,
      db_password: data.dbPassword || undefined,
      import_historical_data: !!data.importHistoricalData,
      is_https_client: data.isHttpsClient
    };

    _sshService.buildSSHParams(params, data);

    return params;
  }
}
