import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { BehaviorSubject } from 'rxjs';
import { onThemeChange } from '../../../react/actions/global';
import { TRACKER_UPDATE_COLOR_MODE } from '../constants';
import { ColorMode } from '../models/color-mode';
import { LocalStorageService } from './local-storage.service';
import { store } from './redux.service';


@Injectable({ providedIn: 'root' })
export class ColorModeService {
  private _colorMode$ = new BehaviorSubject<ColorMode>(ColorMode.LIGHT);

  colorMode$ = this._colorMode$.asObservable();

  constructor(
    private _localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private _document: Document,
    protected _angulartics: Angulartics2
  ) {
    this._colorMode$.next(this._getColorMode());
  }

  toggleColorMode() {
    const mode = this._colorMode$.getValue();
    let newMode;

    if (mode === ColorMode.DARK) {
      newMode = ColorMode.LIGHT;
    } else {
      newMode = ColorMode.DARK;
    }

    store.dispatch(onThemeChange(newMode));
    this.setColorMode(newMode);
  }

  setColorMode(mode: ColorMode = ColorMode.LIGHT) {
    this._document.body.classList.remove(`color-mode-${ this._colorMode$.getValue() }`);

    setTimeout(() => {
      this._colorMode$.next(mode);
      this._localStorageService.set('colorMode', mode);
      this._document.body.classList.add(`color-mode-${ mode }`);

    }, 0);

    this._angulartics.eventTrack.next({
      action: TRACKER_UPDATE_COLOR_MODE,
      properties: {
        colorMode: mode
      }
    });
  }

  private _getColorMode(): ColorMode {
    return this._localStorageService.get('colorMode') as ColorMode;
  }

  getColorModeValue() {
    return this._colorMode$.getValue();
  }
}
