import { SidelineSummary } from '../models/sideline-summary-group';
import { SidelineGroupReplayProgressFactory } from './replay-progress-factory';
import { DETACHED_SIDELINE_SUMMARY_ID } from '../constants';

export function SidelineSummaryFactory(pipelineId: number, rawData: any): SidelineSummary {
  return {
    actionable: rawData.actionable,
    pipelineId,
    refreshedAt: rawData.refreshed_at,
    sourceObjects: rawData.source_objects,
    totalEvents: rawData.total,
    summaries: Array.prototype.reduce.call((rawData.summaries || {}), (dict, summary: any) => {
      return {
        ...dict,
        [summary.source_object_id || DETACHED_SIDELINE_SUMMARY_ID]: {
          sourceObjectId: summary.source_object_id,
          actionable: summary.actionable,
          totalEvents: summary.total,
          progress: SidelineGroupReplayProgressFactory(summary.source_object_id, summary.progress),
          ctaAction: summary.cta,
          hasEagerEvents: summary.has_eager_events,
          hasUncertainCount: summary.has_uncertain_count
        }
      };
    }, {}),
    hasSourceObjectsWithUncertainCount: rawData.has_source_objects_with_uncertain_count
  };
}
