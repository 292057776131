import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPITokenField } from './utils/api-token';


export const USERVOICE_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      ...getAPITokenField({
        hint: 'Enter the API token generated from Settings → Integrations → UserVoice API Keys → Create. Copy the token which will be generated.'
      })
    }
  ],
  [
    {
      key: 'subdomain',
      fieldId: 'subdomain',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'subdomain', label: 'Subdomain', required: true },
      hint: 'Enter the subdomain which you have used while creating the account. For example, <i>https://yoursubdomain.uservoice.com</i>. Here, <b>yoursubdomain</b> is the required name.',
      buildParamsKey: 'subdomain',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ]
];
