// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\nTheme variables\n */\n.dbtViewContainer_gRu0e {\n  display: flex;\n  overflow: hidden;\n  align-items: center;\n}\n\n.icon_o_c8g {\n  flex-shrink: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dbtViewContainer": "dbtViewContainer_gRu0e",
	"icon": "icon_o_c8g"
};
export default ___CSS_LOADER_EXPORT___;
