import { ConnectorAuthTypeEnum } from '../../../app/nodes/source-type/auth-type';
import AuthAPI from '../../containers/auth/AuthAPI';

export const fetchAuthorisedAccounts = (
  authType,
  provider
): Promise<{ tokens: any[]; urls?: any }> => {
  if (ConnectorAuthTypeEnum.OAUTH === authType) {
    return AuthAPI.getAuthTokens(provider, window.location.href, window.location.href).then(
      res => ({
        tokens: res?.data?.tokens,
        urls: res?.data?.urls
      })
    );
  }

  if (ConnectorAuthTypeEnum.SERVICE === authType) {
    return AuthAPI.fetchServiceAccounts(provider).then(res => ({
      tokens: res?.data
    }));
  }
  return Promise.reject(new Error('unknown auth type'));
};
