export const PIPELINE_FILTER_KEY = 'pipeline';
export const DESTINATION_FILTER_KEY = 'destination';
export const STATUS_FILTER_KEY = 'status';
export const PIPELINE_SEARCH_FILTER_KEY = 'search';
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 20;

export const DEFAULT_FILTERS_SELECTION = {
  [PIPELINE_FILTER_KEY]: [],
  [DESTINATION_FILTER_KEY]: [],
  [STATUS_FILTER_KEY]: [],
  [PIPELINE_SEARCH_FILTER_KEY]: ''
};

export const LIST_FILTERS_INDEX = {
  [PIPELINE_FILTER_KEY]: 0,
  [DESTINATION_FILTER_KEY]: 1,
  [STATUS_FILTER_KEY]: 2
};

export const SYNC_FREQUENCY_GTM_SHOWN_KEY = 'sync_frequency_gtm_shown';
