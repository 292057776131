import { InjectFlags, Injector, ProviderToken, StaticProvider } from '@angular/core';
import { useContext, useMemo } from 'react';
import { AngularDIContext } from '../../app/react-bridge/di-context';

export default function useService<T>(service: ProviderToken<T>, notFoundValue?: T, flags?: InjectFlags): T {
  const angularDIRef = useContext(AngularDIContext);
  return angularDIRef?.get(service, notFoundValue, flags);
}

export function useRegisterProviders(providers: StaticProvider[]) {
  const angularDIRef = useContext(AngularDIContext);

  return useMemo(() => {
    return Injector.create(
      providers,
      angularDIRef
    );
  }, [ angularDIRef ]);
}
