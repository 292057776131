import { ActivationRunStageEnum } from './models/run-stages';
import { SyncRecordFailure } from './models/sync-failure';
import { ActivationRunType, SyncHistoryItem, TransformationRunStats } from './models/sync-history-item';
import { SyncHistoryStatusEnum } from './models/sync-history-status';


export function SyncHistoryItemsFactory(rawData: any[]): SyncHistoryItem[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((data) => SyncHistoryItemFactory(data));
}

export function SyncHistoryItemFactory(rawData: any): SyncHistoryItem {
  if (!rawData) {
    return;
  }

  const syncHistoryItem = new SyncHistoryItem();

  syncHistoryItem.id = rawData.id;
  syncHistoryItem.status = SyncHistoryStatusEnum[rawData.status];
  syncHistoryItem.runType = ActivationRunType[rawData.run_type];
  syncHistoryItem.failureMsg = rawData.failure_message || null;
  syncHistoryItem.query = rawData.query;
  syncHistoryItem.updatedTs = rawData.updated_ts || null;

  if (rawData.transformation_stats) {
    const rawTransformationStats = rawData.transformation_stats;

    syncHistoryItem.transformationRunStats = {
      startedTs: rawTransformationStats.start_ts || null,
      runTime: rawTransformationStats.run_time_ms || null,
      recordsSuccess: rawTransformationStats.records_transformed || null,
      recordsFailed: rawTransformationStats.records_failed || null,
      recordsFetchedFromQuery: rawTransformationStats.total_records_fetched_from_user_query || null,
      recordsIgnored: {
        duplicateRecords: rawTransformationStats.duplicate_records || null,
        permanentFailures: rawTransformationStats.permanent_failures_ignored || null,
        alreadySyncedRecords: rawTransformationStats.synced_records_ignored || null
      }
    };
  }

  if (rawData.run_stats) {
    syncHistoryItem.loadingRunStats = {
      startedTs: rawData.run_stats.start_ts || null,
      runTime: rawData.run_stats.run_time_ms || null,
      recordsSuccess: rawData.run_stats.total_records_synced || null,
      recordsFailed: rawData.run_stats.records_failed || null
    };
  }

  syncHistoryItem.recordsIgnored = getRecordsIgnoredInRun(syncHistoryItem.transformationRunStats);
  syncHistoryItem.recordsProcessed = getRecordsProcessedInRun(syncHistoryItem.transformationRunStats);

  return syncHistoryItem;
}

export function getRecordsProcessedInRun(transformationStats: TransformationRunStats) {
  return (transformationStats.recordsSuccess || 0) + (transformationStats.recordsFailed || 0);
}

export function getRecordsIgnoredInRun(transformationStats: TransformationRunStats) {
  if (!transformationStats.recordsIgnored) {
    return 0;
  }

  return Object.values(transformationStats.recordsIgnored).reduce((sum: number, count: number) => {
    return sum + (count || 0);
  }, 0);
}

export function SyncRecordFailuresFactory(rawData: any[]): SyncRecordFailure[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((data) => SyncRecordFailureFactory(data));
}

export function SyncRecordFailureFactory(rawData: any): SyncRecordFailure {
  if (!rawData) {
    return;
  }

  return {
    recordCount: rawData.record_count || rawData.duplicate_count || 0,
    phase: rawData.phase ? ActivationRunStageEnum[rawData.phase.toUpperCase()] : null,
    error: rawData.error || null,
    sampleData: rawData.sample_record
  };
}
