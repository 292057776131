import React, { useEffect, useMemo, useRef } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import clsx from 'clsx';
import { CronTime } from '../../../../app/dialog/execution-policy-dialog/models';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import {
  TRACKER_CHANGE_SCHEDULE_DIALOG_ADD_MORE_CRON_TIME_CLICK,
  TRACKER_CHANGE_SCHEDULE_DIALOG_DELETE_CRON_TIME_CLICK
} from '../constants';
import styles from '../styles.module.scss';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import { HdFormikDropDown } from '../../../components/FormikElements';
import { HdButton } from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { getCronTimeDisplayValue } from '../utils';


export default function MultiCronTimeListComponent({ policyObj, setPolicyObj, entity }) {
  const { eventTrack } = useAnalyticsTracker();
  const scrollViewport = useRef(null);
  const { values, setFieldValue, errors } = useFormikContext<{
    cronTimeList: CronTime[]
  }>();

  const onNewCronTimeAdd = () => {
    eventTrack({
      action: TRACKER_CHANGE_SCHEDULE_DIALOG_ADD_MORE_CRON_TIME_CLICK,
      properties: {
        entity
      }
    });

    setTimeout(() => {
      scrollViewport.current?.scrollIntoView(false);
    }, 0, scrollViewport);
  };

  const onCronTimeDelete = () => {
    eventTrack({
      action: TRACKER_CHANGE_SCHEDULE_DIALOG_DELETE_CRON_TIME_CLICK,
      properties: {
        entity
      }
    });
  };

  const disableAddNewOption = useMemo(() => {
    const time = values.cronTimeList[values.cronTimeList.length - 1];
    return !time || time.hour === null || time.minute === null || Object.keys(errors).length > 0;
  }, [values.cronTimeList, errors]);

  useEffect(() => {
    setPolicyObj((prev) => ({
      ...prev,
      cronTimeList: values.cronTimeList
    }));
  }, [values.cronTimeList]);

  const updateCronTimeList = (time: CronTime, index: number) => {
    const timeList = policyObj.cronTimeList;
    let updatedTimeList: CronTime[];

    if (index < timeList?.length) {
      updatedTimeList = timeList.map((value, idx) => idx === index ? time : value);
    } else {
      updatedTimeList = [ ...timeList, time ];
    }

    setPolicyObj((prev) => ({
      ...prev,
      cronTimeList: updatedTimeList
    }));
  };

  const dataIdGenerator = getDataIdGenerator('MultiCronTimeListComponent');

  return (
    <div className={styles.cronTimeList}>
      <div className='my-2' ref={scrollViewport}>
        <FieldArray
          name='cronTimeList'
          render={({ push, remove }) =>
            values.cronTimeList.map((val, index) => (
              <div className='d-flex' key={index}>
                <HdFormControl
                  className={clsx(
                    errors.cronTimeList?.[index] && values.cronTimeList.length !== index + 1
                      ? 'mb-5'
                      : 'mb-0',
                    styles.w50
                  )}
                >
                  <Field
                    name={`cronTimeList.${index}`}
                    options={policyObj.suggestedCronTimeList}
                    label='At (in UTC)'
                    placeholder='At (in UTC)'
                    required
                    hideClearable
                    hideHelperMessageIfEmpty
                    component={HdFormikDropDown}
                    prefixIcon='time'
                    onChangeEventHandler={(time: CronTime) => {
                      setFieldValue(`cronTimeList.${index}`, { ...time });
                      updateCronTimeList({ ...time }, index);
                    }}
                    displayAccessor={option => getCronTimeDisplayValue(option)}
                    valueAccessor={option => getCronTimeDisplayValue(option)}
                  />
                </HdFormControl>

                {index > 0 && (
                  <HdButton
                    dataId={dataIdGenerator('delete')}
                    onClick={() => {
                      remove(index);
                      onCronTimeDelete();
                    }}
                    variation='flat'
                    palette='error'
                    className={clsx(
                      errors.cronTimeList?.[index] && values.cronTimeList.length !== index + 1
                        ? 'mb-8'
                        : 'mb-5',
                      styles.multiCronButton,
                      'ml-5'
                    )}
                    icon='delete'
                  >
                    Delete
                  </HdButton>
                )}

                {index === values.cronTimeList.length - 1 && values.cronTimeList.length < 24 && (
                  <HdButton
                    dataId={dataIdGenerator('add')}
                    onClick={() => {
                      push(null);
                      onNewCronTimeAdd();
                    }}
                    variation='flat'
                    color='error'
                    icon='plus'
                    className={clsx(
                      styles.multiCronButton,
                      errors.cronTimeList?.[index] && values.cronTimeList.length !== index + 1
                        ? 'mb-8'
                        : 'mb-5',
                      index === 0 ? 'ml-5' : null
                    )}
                    disabled={disableAddNewOption}
                  >
                    Add More
                  </HdButton>
                )}
              </div>
            ))
          }
        />
      </div>
    </div>
  );
}
