import { Component, ElementRef, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'hd-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})

export class IconComponent {
  @HostBinding('class.hevo-icon') hevoIcon = true;

  private _name: string;
  @Input()
  set name(value: string) {
    this._setName(value);
    this._name = value;
  }
  get name(): string {
    return this._name;
  }

  private _size: number;
  @Input()
  set size(value: number) {
    this._setSize(value);
    this._size = value;
  }
  get size(): number {
    return this._size;
  }

  constructor(private _elRef: ElementRef) {
  }

  private _setName(name: string) {
    const classList: DOMTokenList = (<HTMLElement>this._elRef.nativeElement).classList;
    if (this._name) {
      classList.remove(`hevo-${this._name}`);
    }
    if (name) {
      classList.add(`hevo-${name}`);
    }
  }

  private _setSize(size: number) {
    const classList: DOMTokenList = (<HTMLElement>this._elRef.nativeElement).classList;
    if (this._size) {
      classList.remove(`icon-size-${this._size}`);
    }
    if (size) {
      classList.add(`icon-size-${size}`);
    }
  }
}
