export interface SchemaTableTypeData {
  imgLightModeSrc: string;
  imgDarkModeSrc: string;
  title: string;
  description: string;
  docLink: string;
  tableType: FireboltTableType;
}

export enum FireboltTableTypeEnum {
  FACT_TABLE = 'FACT',
  DIMENSION_TABLE = 'DIMENSION',
}

export interface FireboltTableType {
  tableType: FireboltTableTypeEnum;
  displayText: string;
  description: string;
}
