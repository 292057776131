import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[hdCoordinatedHeightAnchor]'
})
export class CoordinatedHeightAnchorDirective {
  @Input() heightOffset: number;

  constructor(
    public el: ElementRef
  ) { }

  public getAnchorHeight() {
    // The height without padding / border / margin
    return this.el.nativeElement.clientHeight - this.heightOffset;
  }
}
