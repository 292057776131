<div
  class="hd-accordion__item"
  [class.active]="isExpanded()">
  <div
    #accordionHeader
    class="hd-accordion__item-header"
    [class.focused]="isFocused()"
    (click)="onClickHeaderElement($event)"
    (mouseenter)="onFocus()"
    (mouseleave)="onUnFocus()">
    <span class="hd-accordion__item-header-title">{{ title }}</span>

    <hd-icon [class]="'toggle-icon'" name="dropdown-arrow"></hd-icon>
  </div>

  <div
    class="hd-accordion__item-content"
    [class.expanded]="isExpanded()"
    [@contentExpansion]="isExpanded() ? 'expanded' : 'collapsed'">
    <ng-content select="[accordionContent]"></ng-content>
  </div>
</div>
