import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountAccessService } from './account-access.service';


@Injectable()
export class AccountAccessResolver implements Resolve<any> {
  constructor(
    private _accountAccessService: AccountAccessService
  ) {
  }

  resolve(): Observable<any> {
    return this._accountAccessService.fetchAccountAccessConsentStatus().pipe(
      map((res: any) => res.data),
      catchError((err: any) => {
        return of(null);
      })
    );
  }
}
