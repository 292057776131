import { ObjectsSelector } from './interface';
import { InclusionItemState, InclusionListState } from '../../core/models/inclusion-list-v2';
import { NamespaceRequestPayload } from '../../pipeline/source-objects/models/namespace-request-payload';

export function getObjectsSelectorFromInclusionList(inclusionListState: InclusionListState): ObjectsSelector {
  return {
    all_select: inclusionListState.selected,
    entities: Array.from(inclusionListState.entities).map((state: InclusionItemState) => {
      let entityObject;

      if (typeof state.key === 'string') {
        entityObject = JSON.parse(state.key);
      }

      return {
        object_name: entityObject.name,
        group_name: entityObject.group
      };
    })
  };
}

export function getInclusionListStateFromObjectsSelector(selector: ObjectsSelector): InclusionListState {
  return {
    selected: selector.all_select,
    entities: selector.entities.map((entity) => {
      return {
        selected: !selector.all_select,
        key: getObjectConfigSelectorKey(entity)
      };
    })
  };
}

export function getObjectConfigSelectorKey(namespace: any) {
  return JSON.stringify({ name: namespace.object_name, group: namespace.group_name });
}

export function getNamespaceRequestPayload(sourceObject: string | number): NamespaceRequestPayload {
  return {
    id: typeof sourceObject === 'number' ? sourceObject : undefined,
    namespace: typeof sourceObject === 'string' ? {
      full_name: sourceObject
    } : undefined
  };
}

export function appendFieldDataType(data) {
  Object.keys(data)?.forEach(field => {
    if (typeof data[field] === 'object' &&
    !Array.isArray(data[field]) &&
    (field === 'id.field.name' || field === 'incrementing.field.name') ) {
      let type = field.substr(0, field.lastIndexOf('name')) + 'type';
      data[type] = data[field]?.type;
      data[field] = data[field]?.value;
    }
  });
  return data;
}

export function joinFieldValues(data) {
  Object.keys(data)?.forEach(field => {
    if (Array.isArray(data[field])) {
      data[field] = data[field].join(',');
    }
  });

  return data;
}
