import { Injectable } from '@angular/core';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';
import { ExecIfDefined } from '../../../react/legacy-utils/decorators';

declare var clarity: any;

@Injectable({ providedIn: 'root' })
export class ClarityService implements UITracker {
  @ExecIfDefined('clarity')
  init(trackingConfig) {
  }

  @ExecIfDefined('clarity')
  identifyUser(user: User) {
    clarity('identify', user.email, undefined, undefined, user.email);

    clarity('set', 'userName', user.name);
    clarity('set', 'userEmail', user.email);
    clarity('set', 'userId', user.id);
    clarity('set', 'role', user.role);
  }

  updateIdentity(user: User) {
    this.identifyUser(user);
  }

  updateUserProperties (user: User, data: any) {
  }

  @ExecIfDefined('clarity')
  reset() {
  }

  @ExecIfDefined('clarity')
  stop() {
  }
}
