import { RbacPermissions } from '../core/models/user';
import { Directive, OnInit } from '@angular/core';
import { RbacService } from '../core/service/rbac.service';
import { UserService } from '../core/service/user.service';
import { RBAC_DISABLED_MESSAGE } from '../core/models/rbac';

@Directive({
  selector: '[hdRbac]',
  exportAs: 'hdRbac'
})
export class RbacDirective implements OnInit{
  rbacPermissions = RbacPermissions;
  rbacDisabledTooltip = RBAC_DISABLED_MESSAGE;
  isRbacEnabled = false;

  constructor(
    private _rbacService: RbacService,
    private _userService: UserService
  ) {
  }

  isActionBlocked(permission: RbacPermissions) {
    return !this._rbacService.canTakeAction(permission);
  }

  ngOnInit(): void {
    this.isRbacEnabled = this._rbacService.isRbacEnabled();
  }

  isOwnerOrHasPermission(permission: RbacPermissions) {
    return this.isRbacEnabled ?
      !this.isActionBlocked(permission) :
      this._userService.getUser()?.isOwner;
  }

}
