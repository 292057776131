import * as Yup from 'yup';
import { S3_BUCKET_REGIONS } from '../../../../../../app/nodes/nodes-settings/s3-base/constants';
import { DestinationConfigMetaData } from '../interface';
import FireBolt from './index';

export const FIREBOLT_CONFIG: DestinationConfigMetaData = {
  formComponent: FireBolt,
  initialValues: {
    username: '',
    password: '',
    database: null,
    engine: null,
    bucket: '',
    bucketRegion: null,
    awsAccessKey: '',
    awsSecretAccessKey: ''
  },
  validationSchema: Yup.object({
    username: Yup.string().trim().required(),
    password: Yup.string().trim().required(),
    database: Yup.object().required(),
    engine: Yup.object().required(),
    bucket: Yup.string().trim().required(),
    bucketRegion: Yup.object().required(),
    awsAccessKey: Yup.string().trim().required(),
    awsSecretAccessKey: Yup.string().trim().required()
  }),
  fieldNameMapping: {
    bucketRegion: 'bucket-region',
    awsAccessKey: 'aws-access-key',
    awsSecretAccessKey: 'aws-secret-access-key'
  },
  regionList: () =>
    S3_BUCKET_REGIONS.map(elem => ({
      ...elem,
      subtext: elem.value
    })),
  getFormData(formData) {
    const formattedDestinationData = { ...formData };
    if (formData.bucketRegion) {
      const matchedRegion = this.regionList().find(
        region => region.value === formData.bucketRegion.value
      );
      if (matchedRegion) {
        formattedDestinationData.bucketRegion = { ...matchedRegion };
      }
    }
    return formattedDestinationData;
  }
};
