import { useCallback } from 'react';
import useHasPermission from '../../../hooks/useHasPermission';
import { Role } from '../models';
import { getResolvedRoles, resolverRolesToPermissions } from './utils';
import { MemberActionables } from './models';
import { RbacPermissions } from '../../../../app/core/models/user';

export const useRolesResolver = (allRoles: Role[]) => {
  const { hasPermission } = useHasPermission();

  const checkIfMemberHasMorePermissionsThanUser = useCallback(
    (roles: Role[]) =>
      !resolverRolesToPermissions(roles).every(permission => hasPermission(permission)),
    [hasPermission]
  );

  const checkForRolesAccessOnMember = (roles: Role[], minimumPermission: RbacPermissions) => {
    const rolesActionsMap: { [role: string]: MemberActionables } = {};

    const { resolvedRolesSet, assignedRolesSet } = getResolvedRoles(allRoles, roles);

    let hasMinimumPermissions = true;
    if (minimumPermission) {
      hasMinimumPermissions = hasPermission(minimumPermission);
    }

    const memberHasMorePermisionsThanCurrentUser = checkIfMemberHasMorePermissionsThanUser(roles);

    if (
      !hasMinimumPermissions ||
      (hasMinimumPermissions && memberHasMorePermisionsThanCurrentUser)
    ) {

      allRoles.forEach(role => {
        rolesActionsMap[role.roleName] = MemberActionables.CAN_NOT_ACT_ON_ROLE;
      });
    } else {
      allRoles.forEach(role => {
        if (
          !role.permissions.every(rolePermission => hasPermission(rolePermission.permission_name))
        ) {
          rolesActionsMap[role.roleName] = MemberActionables.CAN_NOT_ACT_ON_ROLE;
        } else if (resolvedRolesSet.has(role.roleName)) {
          rolesActionsMap[role.roleName] = MemberActionables.IS_RESOLVED_ROLE;
        } else if (assignedRolesSet.has(role.roleName)) {
          rolesActionsMap[role.roleName] = MemberActionables.IS_ASSIGNED_ROLE;
        } else {
          rolesActionsMap[role.roleName] = MemberActionables.CAN_ACT_ON_ROLE;
        }
      });
    }

    const disabledRoles = new Map<string, boolean>([['OBSERVER', true]]);

    rolesActionsMap.OBSERVER = MemberActionables.CAN_NOT_ACT_ON_ROLE;

    Object.entries(rolesActionsMap).forEach(([roleName, actionStatus]) => {
      if (
        actionStatus === MemberActionables.CAN_NOT_ACT_ON_ROLE ||
        actionStatus === MemberActionables.IS_RESOLVED_ROLE
      ) {
        disabledRoles.set(roleName, true);
      }
    });

    const allSelectedRoles = allRoles
      .filter(
        role =>
          assignedRolesSet.has(role.roleName) ||
          resolvedRolesSet.has(role.roleName) ||
          role.roleName === 'OBSERVER'
      )
      .map(role => ({
        ...role,
        isResolvedViaNesting: resolvedRolesSet.has(role.roleName)
      }));

    return {
      rolesActionsMap,
      disabledRoles,
      allSelectedRoles
    };
  };

  return {
    checkIfMemberHasMorePermissionsThanUser,
    checkForRolesAccessOnMember
  };
};
