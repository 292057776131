import { FieldTypes, SourceFormFieldDefinition } from '../models';
import { getAPIKeyField } from './utils/api-key';


export const SWELL_SOURCE_TEMPLATE: Array<Array<SourceFormFieldDefinition>> = [
  [
    {
      key: 'accountId',
      fieldId: 'account-id',
      widget: FieldTypes.TEXT_FIELD,
      fieldType: 'string',
      widgetOptions: { name: 'accountId', label: 'Store ID', required: true },
      hint: 'Enter the store ID to identify the store. ',
      sbsDocLink: '/sources/e-commerce/swell/#obtaining-the-store-id-and-api-key',
      buildParamsKey: 'store_id',
      validations: [
        {
          type: 'required'
        }
      ]
    }
  ],
  [
    {
      ...getAPIKeyField({
        label: 'API Secret',
        hint: 'Enter the API secret to authenticate. ',
        sbsDocLink: '/sources/e-commerce/swell/#obtaining-the-store-id-and-api-key',
        buildParamsKey: 'secret_key'
      })
    }
  ]
];
