import { InclusionListState } from '../../../../../../app/core/models/inclusion-list-v2';
import { MSAdsConfigPayload, MSAdsConfigResponse } from './models';

export function getSelectedAdProfilesPayload(
  data: InclusionListState
): Pick<MSAdsConfigPayload, 'all_select' | 'selected_customers'> {
  const allSelect = data?.selected || false;
  const selectedCustomers = (data?.entities || []).map(manager => ({
    id: manager.key,
    all_select: manager?.selected,
    entities: ((<InclusionListState>manager)?.entities || []).map(customer => customer.key)
  }));

  return {
    all_select: allSelect,
    selected_customers: selectedCustomers
  };
}

export function getSelectedCustomersInclusionListState(
  rawData: MSAdsConfigResponse
): InclusionListState {
  return {
    selected: !!rawData.all_select,
    entities: rawData.selected_customers.map(manager => ({
      key: manager.id,
      selected: manager.all_select,
      entities: manager.entities.map(adName => ({
        key: adName,
        selected: !manager.all_select
      }))
    }))
  };
}
