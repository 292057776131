import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AccountAccessStatus } from '../../../react/containers/team/AccountAccess/models';
import { DataPrivacyService } from './data-privacy.service';
import { UserService } from './user.service';

@Injectable()
export class AccountAccessService implements OnDestroy {

  private _destroyed$ = new Subject<void>();

  private _accountAccessStatus$ = new BehaviorSubject<boolean>(null);

  constructor(
    private _dataPrivacyService: DataPrivacyService,
    private _userService: UserService
  ) {
  }

  getAccountAccessConsentStatus() {

    if (this._userService.getUser().isHidden) {
      return this._accountAccessStatus$.getValue();
    }

    return true;
  }

  fetchAccountAccessConsentStatus() {
    return this._dataPrivacyService
      .getCurrentAccountAccessConsentStatus().pipe(
      takeUntil(this._destroyed$),
      tap((res) => {
        this._accountAccessStatus$.next(res.data?.consent_status === AccountAccessStatus.GRANTED.toString());
      })
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
