import { useRef } from 'react';

export interface PriorityQueueItem<T> {
  data: T;
  priority: number;
}

export function usePQ<T>() {
  const store = useRef<Array<PriorityQueueItem<T>>>([]);

  const insert = (item: PriorityQueueItem<T>) => {
    let insertIndex = store.current.findIndex(i => item.priority < i.priority);

    if (insertIndex === -1) {
      insertIndex = store.current.length;
    }
    const copyStore = [...store.current];

    copyStore.splice(insertIndex, 0, item);
    store.current = copyStore;
  };

  const pop = (): PriorityQueueItem<T> | undefined => {
    const copyStore = [...store.current];
    const poppedEl = copyStore.pop();
    store.current = copyStore;
    return poppedEl;
  };

  const removeAt = (index: number) => {
    const copyStore = [...store.current];
    copyStore.splice(index, 1);
    store.current = copyStore;
  };

  const peek = (): PriorityQueueItem<T> => store.current[store.current.length - 1];

  const items = () => store.current.slice();

  const clear = () => {
    store.current = [];
  };

  return {
    insert,
    pop,
    removeAt,
    peek,
    items,
    clear
  };
}
